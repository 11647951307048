import { ApolloClient, InMemoryCache, split, ApolloLink } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'

//WebSockets
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

const httpLink = createUploadLink({
  //uri: 'http://127.0.0.1:4000/graphql', //LOCALHOST
  uri: 'https://suite.vinosamerica.com/graphql', //PRODUCTIVO
})
const httpLink2 = createUploadLink({
  // uri: 'http://127.0.0.1:4000/graphql', //LOCALHOST
  uri: 'https://reports.enigmasuite.com/graphql', //PRODUCTIVO
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  const requestPath = window.location.pathname
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      requestPath,
    },
  }
})

//Web sockets
const wsLink = new WebSocketLink({
  uri: 'wss://suite.vinosamerica.com/graphql',
  options: {
    reconnect: true,
  },
})

const link = ApolloLink.split(
  (operation) => {
    const definition = getMainDefinition(operation.query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(
    ApolloLink.split(
      (operation) => operation.getContext().clientName === 'httpLinkReports',
      httpLink2,
      httpLink
    )
  )
)
//End Web sockets

const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache({ addTypename: false }),
  link,
})

export default client
