const TotalPickingQuantities = ({ quantities = {} }) => {

    if (!quantities) {
        return null
    }
    const { total_bottles, total_bottles_by_boxes, total_boxes, total_picked } = quantities
    const bottles = total_bottles_by_boxes > 0 && total_bottles > 0 ? total_bottles + total_bottles_by_boxes : total_bottles > 0 ? total_bottles : total_bottles_by_boxes


    return (
        <div className="d-flex justify-content-around">
            <h5>Botellas: {bottles} </h5>
            {total_boxes > 0 && (
                <h5>Cajas: {total_boxes}</h5>
            )}
            <h5>Cantidad surtida: {total_picked} </h5>

        </div>

    )

}

export default TotalPickingQuantities;