import { gql } from '@apollo/client'

export const GET_PENDING_ORDERS = gql`
  query GetPendingOrders(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $platform: Int
    $date: Int
  ) {
    getPendingOrders(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      platform: $platform
      date: $date
    ) {
      rows {
        id
        platform {
          name
        }
        type {
          name
        }
        payment {
          platform
          payment_id
        }
        shipping {
          first_name
          last_name
        }
        order_id
        order_date
        store {
          name
        }
        warehouse {
          name
        }
        object_type
        object_type_name
      }
      count
    }
  }
`

export const GET_ORDER_DETAILS_BY_ID = gql`
  query GetOrderById($id: Int!) {
    getOrderById(id: $id) {
      id
      platform {
        name
      }
      type_id
      type {
        name
      }
      method_id
      method {
        name
      }
      payment {
        platform
        payment_id
      }
      shipping {
        first_name
        last_name
        email
        phone
        address_1
        postcode
        state
        city
        id_file_receipt
        receipt {
          id
          url
        }
      }
      order_id
      store_id
      store {
        name
      }
      uber_id
      total_price
      shipping_price
      product_quantity
      products {
        id
        # product_id
        name
        sku
        quantity
        price
        total
        img_url
      }
      warehouse_id
      warehouse {
        name
      }
      status_id
      status {
        id
      }
      reason {
        id
        reason
        issusse_id
        user_id
        userDetails {
          name
        }
        issusesDetails {
          id
          name
        }
        createdAt
      }
      order_date
      shipping_company_id
      shippingCompany {
        id
        name
      }
    }
  }
`

export const GET_ORDER_BY_ID_STATUS = gql`
  query GetOrderByIdAndStatus($id: Int!, $statusId: [Int], $typeId: Int) {
    getOrderByIdAndStatus(id: $id, status_id: $statusId, type_id: $typeId) {
      id
      platform {
        name
      }
      type_id
      type {
        name
      }
      method_id
      method {
        name
      }
      payment {
        platform
        payment_id
      }
      shipping {
        first_name
        last_name
        email
        phone
        address_1
        postcode
        state
        city
        id_file_receipt
        receipt {
          id
          url
        }
      }
      order_id
      store_id
      store {
        name
      }
      uber_id
      total_price
      shipping_price
      product_quantity
      products {
        id
        name
        sku
        quantity
        price
        total
        img_url
      }
      warehouse_id
      warehouse {
        name
      }
      status_id
      status {
        id
      }
      reason {
        id
        reason
        issusse_id
        user_id
        userDetails {
          name
        }
        issusesDetails {
          id
          name
        }
        createdAt
      }
      order_date
      shipping_company_id
      shippingCompany {
        id
        name
      }
    }
  }
`

export const EXPORT_PENDING_ORDERS = gql`
  mutation GetAllPendingExport {
    getAllPendingExport {
      id
      order_date
      platform_name
      type_name
      id_payment
      platform
      shipping_first_name
      shipping_last_name
      order_id
      store_name
      warehouse_name
    }
  }
`

export const CHANGE_TO_PROCESS = gql`
  mutation ChangeToProcess($orderId: String!, $storeId: Int!) {
    changeToProcess(order_id: $orderId, store_id: $storeId) {
      id
      status_id
    }
  }
`

export const CHANGE_TO_BILLING = gql`
  mutation changeToBilling(
    $orderId: String!
    $shippingCompanyId: Int!
    $uploadReceipt: Upload
  ) {
    changeToBilling(
      order_id: $orderId
      shipping_company_id: $shippingCompanyId
      uploadReceipt: $uploadReceipt
    ) {
      id
      shipping_company_id
      status {
        id
        name
      }
    }
  }
`

export const BILLING_PROCESS = gql`
  mutation BillingProcess($orderId: String!) {
    billingProcess(order_id: $orderId)
  }
`

export const CHANGE_TO_PICKING = gql`
  mutation ChangeToPicking($orderId: String!) {
    changeToPicking(order_id: $orderId) {
      order {
        id
      }
    }
  }
`
