import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { CREATE_SHIPPING_COMPANY, GET_ALL_SHIPPING_COMPANIES_ROWS, GET_ONE_SHIPPING_COMPANY, UPDATE_SHIPPING_COMPANY } from "../../../graphql/Catalog/ShippingCompanies/shippingCompanies"
import ContentHeader from "../../Layout/ContentHeader"
import * as Yup from 'yup'
import Box from "../../Global/Box"
import { useMutation, useQuery } from "@apollo/client"
import InputController from "../../Global/InputController"
import { toastSweetAlert } from "../../Helpers/ToastSweetAlert"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { GET_VEHICLE_OPTIONS } from "../../../graphql/Documents/Schedule/ScheduleVehicle"

const ReceiptParcelsRegistersNew =()=> {
    const { id: _id, show } = useParams()
    const history = useHistory()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Este campo es obligatorio'),
    })

    //--------- Hooks
    const [loading, setLoading] = useState(false)
    
    const {
        methods,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({ resolver: yupResolver(validationSchema) })

    //--------- Queries
    const { 
        data:dataShippingCompany, 
        loading:loadingDataShippingCompany, 
        error:errorDataShippingCompany 
    } = useQuery(
        GET_ONE_SHIPPING_COMPANY,
        {
          variables: {
            id: parseInt(_id),
          },
          fetchPolicy: 'no-cache',
        }
    )

    //--------- Mutations
    const [CreateShippingCompany] = useMutation(CREATE_SHIPPING_COMPANY, {
        refetchQueries: [
            {
              query: GET_ALL_SHIPPING_COMPANIES_ROWS,
              variables: {
                limit: 10,
                offset: 0,
                searchQuery: null,
              },
            },
            {
              query: GET_VEHICLE_OPTIONS,
            },
        ],
    })

    const [UpdateShippingCompany] = useMutation(UPDATE_SHIPPING_COMPANY, {
        refetchQueries: [
            {
              query: GET_ALL_SHIPPING_COMPANIES_ROWS,
              variables: {
                limit: 10,
                offset: 0,
                searchQuery: null,
              },
            },
            {
              query: GET_VEHICLE_OPTIONS,
            },
        ],
    })

    //--------- useEffects
    useEffect(() => {
        if (_id) {
          if (
            dataShippingCompany !== null &&
            dataShippingCompany !== undefined
          ) {
            const data = dataShippingCompany.getOneShippingCompany
    
            const dataFormated = {
              name: data.name,
            }
    
            reset(dataFormated)
          }
        }
    }, [dataShippingCompany])

    //--------- functions
    const onSubmit = async (data) => {
        try {
            const {name} = data
            //1) Check if name is not null
            if(name.trim().length == 0) return toastSweetAlert({
                mode: 'error',
                message: 'Debes completar los campos restantes en el formulario'
            })

            setLoading(true)

            //2) //Submit
            let resSubmit
            if(_id)//Update
                resSubmit = await UpdateShippingCompany({
                    variables: {
                        id: parseInt(_id),
                        name: name.trim().toLowerCase(),
                    }
                })
            else//Create
                resSubmit = await CreateShippingCompany({
                    variables: {
                        name: name.trim().toLowerCase(),
                    }
                })
            
            setLoading(false)

            if(resSubmit){
                toastSweetAlert({
                    mode: 'ok',
                    message: `Compañia de paqueteria ${_id ? 'actualizado' : 'creado'} con éxito`
                })
                history.push(`/receiptRegisters/parcel`)
            }

        } catch (error) {
            setLoading(false)
            return toastSweetAlert({
                mode: 'error',
                message: error.message
            })
        }
    }

    return (
        <>
        <ContentHeader
          title="Compañia de paqueteria de recibo"
          breadcrumb="Paqueteria"
          windowTitle={`${_id ? 'Editar' : 'Agregar'} compañia de paqueteria`}
        />
        <FormProvider {...methods}>
          <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
            <Box
                title={`${_id ? 'Editar' : 'Agregar'} compañia de paqueteria`}
                btnRedPath="/receiptRegisters/parcel"
                btnRedTxt="Cancelar"
                btnSubmit={true}
                btnState={loading}
                errors={errors}
                content={
                    <>
                    <div className="row">
                      <div className="col-12 mb-3 ">
                        <InputController
                          label="Nombre de la compañia de paqueteria"
                          type="text"
                          name="name"
                          placeholder="Ingrese el nombre de la compañia de paqueteria"
                          control={control}
                        />
                      </div>
                    </div>
                    </>
                }
            />
          </form>
        </FormProvider>
      </>
    )
}

export default ReceiptParcelsRegistersNew