import { useEffect, useRef, useState } from 'react'
import PrintCheckCoverages from './PrintCheckCoverages/PrintCheckCoverages'
import ReactToPrint from 'react-to-print'
import { GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE_BY_INVOICE_NUMBER_PAYMENTS } from '../../../../../graphql/Catalog/Notes/payments'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import { useHistory } from 'react-router-dom'

const DisplayInfoForPrintCoverage = ({
  invoiceNumberPayments,
  setFlag,
  handlePreviousStep,
}) => {
  const componentRef = useRef()

  const history = useHistory()
  const [infoPrintCoverage, setInfoPrintCoverage] = useState([])

  const [printCheckCoverages] = useLazyQuery(
    GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE_BY_INVOICE_NUMBER_PAYMENTS
  )

  useEffect(() => {
    if (invoiceNumberPayments) {
      console.log('invoiceNumberPayments', invoiceNumberPayments)
      handlePrintCoverage()
    }
  }, [invoiceNumberPayments])

  const handlePrintCoverage = async () => {
    const { data: dataCoverage } = await printCheckCoverages({
      variables: {
        invoiceNumberPayments: invoiceNumberPayments,
      },
      fetchPolicy: 'no-cache',
    })

    let infoCoverage = []

    const data =
      dataCoverage?.getInfoPaymentForPrintCoverageByInvoiceNumberPayments

    console.log('data', data)

    infoCoverage.push({
      checkNumber: data?.check_number,
      invoicePay: data?.invoice_number_payments,
      dateCheck: moment(data?.date_check, 'YYYY-MM-DD hh:mm:ss a').format(
        'DD/MM/YYYY'
      ),
      total: data?.amount_check_payment,
      provider: data?.payment_nominative
        ? data?.checksToNotes?.partnerNotes?.provider_name
          ? data?.checksToNotes?.partnerNotes?.provider_name
          : data?.checksToNotes?.bills[0]?.provider_name_nm
        : 'Al portador',
      store: data?.storesPayment?.name,
    })

    setInfoPrintCoverage(infoCoverage)
    document.getElementById('PrintCoverage').click()
    // setFlag(1)
    history.push('/generatenewpayment/executedpayment')
    // document.getElementById('handlePreviousStep').click()
  }

  return (
    <>
      <div className="hide">
        <PrintCheckCoverages
          infoCoverage={infoPrintCoverage}
          componentRef={componentRef}
        />

        <ReactToPrint
          documentTitle={`PolizaDeCheque-${infoPrintCoverage?.checkNumber}`}
          trigger={() => <button id="PrintCoverage">Print this out!</button>}
          content={() => componentRef.current}
          pageStyle="@page { size: A4; margin: 10mm;}"
        />

        {/* <button id="handlePreviousStep" onClick={handlePreviousStep}></button> */}
      </div>
    </>
  )
}

export default DisplayInfoForPrintCoverage
