import LayoutTable from '../../../Global/LayoutTable'

const ControlDeskRoutesDetails = ({
  data,
  handleClickDetails,
  total,
  loading,
}) => {
  const dataTable = [
    {
      id: 1,
      doc_num: 15200081,
      doc_entry: 16293,
      status: 2,
      from_warehouse: 'Bodega tlajomulco',
      to_warehouse: 'tienda andares',
    },
  ]

  return (
    <>
      {data ? (
        <div className="container-fluid">
          <LayoutTable
            data={data}
            withCard={false}
            exportImportButton={false}
            hideBtnErase={true}
            hideBtnEdit={true}
            actionBtnDetail={handleClickDetails}
            hideAddNew={true}
            loadingData={loading}
            totalCount={total}
          />
        </div>
      ) : (
        <h4 className="text-center">No se ha seleccionado una ruta</h4>
      )}
    </>
  )
}

export default ControlDeskRoutesDetails
