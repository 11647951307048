import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import numeral from 'numeral'

import Box from '../../Global/Box'
import OrderCol from '../../Global/OrderCol'

import { document_statuses_esp } from '../../Helpers/StatusesLanguages'
import Loader from '../../Global/Spinner'
import VehicleReceipt from '../VehiclesReceipt/VehiclesReceipt'
import VehicleRegisterReceiptModal from '../VehiclesReceipt/VehicleReceiptRegisterModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import { START_END_DOWNLOAD_HOUR_VALIDATION } from '../../../graphql/Documents/Schedule/ScheduleVehicle'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ReceptionActionsButtons from './ReceptionActionsButtons'
import LayoutTable from '../../Global/LayoutTable'
import Accordion from './CardCollapse'
import ModalContent from '../../Global/ModalContent'

export const Step1 = ({
  schedule,
  orders,
  lines,
  evidence,
  onCancel,
  nextStep,
  onFinish,
  linesQuantity,
  onDeleteLine,
  handlePrintLine,
  loadingData,
}) => {
  const { show, calendar } = useParams()
  const history = useHistory()
  const { show: _show, id: _id } = useParams()
  const [productLines, setProductLines] = useState([])
  const [loading, setLoading] = useState(false)
  const refModal = useRef()
  const statusSpanish = document_statuses_esp
  const [dataToTable, setDataToTable] = useState([])
  const [productScanDetails, setproductScanDetails] = useState({})

  // GETTING VEHICLE BY SCHEDULE IDS
  const [vehicleIds, setvehicleIds] = useState([])

  //   const handleChangeScanStep = (itemCode) => {
  //     setGlobalData(itemCode)
  //     nextStep()
  //   }

  const [getDownloadHoursValidation] = useLazyQuery(
    START_END_DOWNLOAD_HOUR_VALIDATION
  )

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const scan = urlParams.has('scan')
    if (scan) {
      nextStep()
    }
  }, [])

  useEffect(() => {
    let list = []
    for (const i of lines) {
      let listDetails = []
      let totalQuantityReceipt = 0
      let purchaseOrderLineId = 0
      for (const j of linesQuantity) {
        if (i.id === j.line_id) {
          listDetails.push({
            ...j,
            anada: i.anada,
            expiration_date: i.expiration_date,
          })
          purchaseOrderLineId =
            i.SapPurchasesOrdersLinesCommodityReceipt
              .sap_purchases_orders_lines_id
          totalQuantityReceipt += j.quantity
        }
      }
      const vals = window.location.search
      const urlParams = new URLSearchParams(vals)
      var calendar = urlParams.get('calendar')

      if (show && totalQuantityReceipt && !calendar) {
        list.push({
          ...i,
          quantity: i.line_status === 'O' ? i.open_quantity : i.quantity,
          details: listDetails,
          totalQuantityReceipt,
          purchaseOrderLineId,
          anada: i.anada,
          expiration_date: i.expiration_date,
        })
      }
      if (show && calendar === '1') {
        list.push({
          ...i,
          quantity: i.line_status === 'O' ? i.open_quantity : i.quantity,
          details: listDetails,
          totalQuantityReceipt,
          purchaseOrderLineId,
          anada: i.anada,
          expiration_date: i.expiration_date,
        })
      }

      if (!show) {
        list.push({
          ...i,
          quantity: i.line_status === 'O' ? i.open_quantity : i.quantity,
          details: listDetails,
          totalQuantityReceipt,
          purchaseOrderLineId,
          anada: i.anada,
          expiration_date: i.expiration_date,
        })
      }
    }
    const listData = list.map((item) => {
      return {
        id: item?.id,
        sku: item?.item_code,
        producto: item?.PurchasesLinesItemInfo?.item_name,
        'cantidad esperada': item?.quantity,
        'cantidad recibida': item?.totalQuantityReceipt,
      }
    })
    // console.log(listData, 'DATA FORMATEADA')
    setDataToTable(listData)
    setProductLines(list)

    // console.log(list)
  }, [lines, linesQuantity])

  const handleFinish = async () => {
    // const { data } = await getDownloadHoursValidation({
    //   variables: {
    //     getStartEndDownloadHourAssignationId: +_id,
    //   },
    // })
    // if (!data?.getStartEndDownloadHourAssignation) {
    //   return toastSweetAlert({
    //     mode: 'error',
    //     message:
    //       'La hora de inicio de descarga  y fin de descarga debe ser asignada',
    //   })
    // }

    return setLoading(true), onFinish()
  }
  const headerBtnHandler = () => {
    refModal.current.click()
  }

  const handleStart = async () => {
    const { data } = await getDownloadHoursValidation({
      variables: {
        getStartEndDownloadHourAssignationId: +_id,
      },
    })
    if (!data?.getStartEndDownloadHourAssignation) {
      return toastSweetAlert({
        mode: 'error',
        message:
          'La hora de inicio de descarga  y fin de descarga debe ser asignada',
      })
    }
    nextStep()
  }

  const handleDetailsModal = (data) => {
    // console.log(data, 'PRODUCT')
    const findScan = productLines.find((item) => item?.id == data?.id)
    // console.log(findScan, 'PRODUCT FIND')
    setproductScanDetails(findScan)
    refModal.current.click()
  }
  return (
    <div className="row">
      <div className="mb-3 col-12">
        <Box
          title={`Detalles de cita`}
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="boxQuotesProvider">
                <div className="row">
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Fecha de cita:{' '}
                      <span className="resultData">
                        {`${schedule?.document_date}`}
                      </span>{' '}
                    </h4>
                  </div>

                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Código proveedor:{' '}
                      <span className="resultData">{schedule?.card_name}</span>{' '}
                    </h4>
                  </div>
                </div>
                {(!show || schedule?.status?.id == 1) && (
                  <div className="col-12 text-right">
                    <span
                      onClick={onCancel}
                      className="btn StandarModalCancelButtonStandar mt-5"
                    >
                      Cancelar cita
                    </span>
                  </div>
                )}
              </div>
            )
          }
        />
        <Box
          title="Órdenes de compra"
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="boxQuotesProvider">
                  {orders &&
                    orders?.map((item) => (
                      <>
                        <Accordion
                          key={item?.id}
                          containerCssClass="my-3"
                          idAccordion={`accordion-${item?.id}`}
                          idCollapse={`collapse-${item?.id}`}
                          idContent={`content-${item?.id}`}
                          // title={` `}
                          title={
                            <h5 className="fw-bold">
                              No. Documento: {item?.document_number}
                            </h5>
                          }
                          content={
                            <>
                              <div className="row">
                                <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                                  <h4>
                                    Entradas de documento:{' '}
                                    <span className="resultData">
                                      {item?.document_entry}
                                    </span>{' '}
                                  </h4>
                                </div>
                                <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                                  <h4>
                                    Serie:{' '}
                                    <span className="resultData">
                                      {item?.series}
                                    </span>{' '}
                                  </h4>
                                </div>
                                <div className="mb-3 col-sm-12 col-md-12 col-lg-12">
                                  <h4>
                                    Comentarios:{' '}
                                    <span className="resultData">
                                      {item?.comments || 'Sin comentarios'}
                                    </span>{' '}
                                  </h4>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </>
                    ))}
                </div>
              </>
            )
          }
        />

        {/* <div className="vehicle-receipt">
          <Box
            title="Recibo de vehículos"
            // headerBtn={true}
            // headerBtnText="Agregar vehículo"
            // headerBtnHandler={headerBtnHandler}
            content={
              <>
                <VehicleReceipt
                  vehicleIds={vehicleIds}
                  setvehicleIds={setvehicleIds}
                  id={_id}
                />
              </>
            }
          />
        </div> */}
        <ReceptionActionsButtons
          show={show}
          history={history}
          nextStep={nextStep}
          handleFinish={handleFinish}
          loading={loading}
          schedule={schedule}
          cssClass="responsive-bottom-actions-buttons-scan"
        />
        <Box
          // title="Desglose de productos"
          withCard={false}
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <>
                <ReceptionActionsButtons
                  show={show}
                  history={history}
                  nextStep={nextStep}
                  handleFinish={handleFinish}
                  loading={loading}
                  schedule={schedule}
                  cssClass="hide-actions-buttons-scan"
                />
                <LayoutTable
                  withCard={false}
                  title="Desglose de productos"
                  layoutCardMobile
                  data={dataToTable}
                  paginationServer={false}
                  classes="mt-3 responsive-products-reception-title"
                  // hideActions={true}
                  hideDetails={true}
                  customActionButtons={[
                    {
                      className: 'btn btn-accept btn-sm mt-3',
                      handleButton: handleDetailsModal,
                      buttonText: 'Detalles',
                    },
                  ]}
                />

                <span
                  ref={refModal}
                  className="btn btn-accept btn-sm invisible"
                  data-toggle="modal"
                  data-target="#productsDetails"
                  data-backdrop="static"
                  data-keyboard="false"
                  data-dismiss="modal"
                />
                <ModalContent
                  idModal="productsDetails"
                  title="Escaneos realizados"
                  size="large"
                  // onClose={handleCancelButton}
                  content={
                    <>
                      {productScanDetails?.details &&
                      productScanDetails?.details.length > 0 ? (
                        productScanDetails?.details.map((item, index) => (
                          <>
                            <div className="row">
                              <div className="col-6">Escaneo: {index + 1}</div>
                              <div className="col-6">Pallet: {item?.id}</div>
                            </div>
                          </>
                        ))
                      ) : (
                        <h5>No se han realizados escaneos</h5>
                      )}
                      {/* Escaneo {index + 1}, Pallet {detail?.id} */}
                    </>
                  }
                />
              </>
            )
          }
        />
        {/* <Box
          title="Evidencias"
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="row ">
                {evidence &&
                  evidence?.map((item, index) => (
                    <div key={index} className="col-4 gallery-item">
                      <img src={item} alt="img" className="" />
                    </div>
                  ))}
              </div>
            )
          }
        /> */}
      </div>
      {/* <VehicleRegisterReceiptModal refModal={refModal} /> */}
    </div>
  )
}
const RowTableReceipt = ({ item, handlePrintLine, cssClass }) => {
  return (
    <>
      {/* <td className="d-block d-md-none"> */}
      <td className={cssClass}>
        {item?.details.length > 0 && (
          <i
            type="button"
            className="fa fa-print"
            onClick={() => handlePrintLine(1, item, item?.details)}
          />
        )}
      </td>
    </>
  )
}
const ColTableReceipt = ({ cssClass }) => {
  return (
    <>
      <th className={cssClass}>Etiquetas</th>
    </>
  )
}

const RowprintTableReceipt = ({
  item,
  detail,
  index,
  cssClass,
  handlePrintLine,
}) => {
  return (
    <>
      <td className={cssClass}>
        <i
          type="button"
          className="fa fa-print"
          onClick={() => handlePrintLine(2, item, detail, index + 1)}
        />
      </td>
    </>
  )
}
