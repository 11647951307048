import { gql } from "@apollo/client";

export const GET_ALL_KARDEX_PAGINATION = gql`
  query GetAllKardexPagination($searchQuery: String, $limit: Int, $offset: Int, $documentType: Int, $start: String, $end: String, $rackId: Int) {
    getAllKardexPagination(searchQuery: $searchQuery, limit: $limit, offset: $offset, documentType: $documentType, start: $start, end: $end, rackId: $rackId) {
      count
      rows {
        id
        sku
        quantity
        documentName
        documentId
        movementTypeId
        rackName
        date
        user
      }
    }
  }
`

export const GET_ALL_KARDEX = gql`
  mutation ExportKardex {
    exportKardex {
      sku
      cantidad
      documento
      id
      tipo
      rack
      fecha
      usuario
    }
  }
`