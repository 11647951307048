import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  CREATE_RACK,
  GET_ALL_RACKS,
  GET_ALL_RACK_ZONES,
  GET_ONE_RACK,
  GET_RACK_COUNT,
  UPDATE_RACK,
} from '../../../../graphql/Catalog/Stowing/racks'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_SAP_WAREHOUSES } from '../../../../graphql/Catalog/SAP/sapWarehouses'

export const RacksNew = () => {
  const { id: _id, show } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [warehousesOptions, setWarehousesOptions] = useState([])
  const [rackZoneOptions, setRackZoneOptions] = useState([])

  const {
    data: dataRack,
    loading: loadingRack,
    error: errorRack,
  } = useQuery(GET_ONE_RACK, {
    variables: {
      getOneRackByIdId: parseInt(_id),
    },
  })

  const {
    data: rackCount,
    loading: rackCountLoading,
    error: rackCountError,
  } = useQuery(GET_RACK_COUNT)

  const {
    data: sapWarehousesData,
    loading: sapWarehousesLoading,
    error: sapWarehousesError,
  } = useQuery(GET_SAP_WAREHOUSES, {
    variables: {
      limit: null,
      offset: null,
    },
  })

  const {
    data: rackZonesData,
    loading: rackZonesLoading,
    error: rackZonesError,
  } = useQuery(GET_ALL_RACK_ZONES)

  const [createRack] = useMutation(CREATE_RACK, {
    refetchQueries: [
      {
        query: GET_ALL_RACKS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateRack] = useMutation(UPDATE_RACK, {
    refetchQueries: [
      {
        query: GET_ALL_RACKS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_ONE_RACK,
        variables: { getOneRackByIdId: parseInt(_id) },
      },
    ],
  })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Este campo es obligatorio'),
    code: Yup.string().required('Este campo es obligatorio'),
    circuit: Yup.number().typeError('Debe ser un numero').integer(),
    capacity: Yup.number().typeError('Debe ser un numero').integer().required(),
    reorder: Yup.number().typeError('Debe ser un numero').integer().required(),
    warehouse_id: Yup.number()
      .typeError('Debe ser un numero')
      .integer()
      .required(),
    zone_id: Yup.number().typeError('Debe ser un numero').integer().required(),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) })

  useEffect(() => {
    if (_id) {
      if (!loadingRack) {
        if (errorRack)
          return toastSweetAlert({
            mode: 'error',
            message: errorRack.message,
          })
        if (dataRack) {
          setValue('name', dataRack.getOneRackById.name)
          setValue('code', dataRack.getOneRackById.code)
          setValue('capacity', dataRack.getOneRackById.capacity)
          setValue('circuit', dataRack.getOneRackById.circuit)
          setValue('reorder', dataRack.getOneRackById.reorder)
          setValue('warehouse_id', dataRack.getOneRackById.warehouse_id)
          setValue('zone_id', dataRack.getOneRackById.zone_id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataRack, loadingRack])

  useEffect(() => {
    if (!sapWarehousesLoading && !sapWarehousesError) {
      const list = sapWarehousesData.getSapWarehouses.map((warehouse) => {
        return {
          value: warehouse.id,
          label: warehouse.warehouse_name,
        }
      })
      setWarehousesOptions(list)
    }
  }, [sapWarehousesData, sapWarehousesLoading])

  useEffect(() => {
    if (!rackZonesLoading && !rackZonesError) {
      const list = rackZonesData.getAllRackZones.map((rack) => {
        return {
          value: rack.id,
          label: rack.name,
        }
      })
      setRackZoneOptions(list)
    }
  }, [rackZonesData, rackZonesLoading])

  console.log(dataRack)
  const onSubmit = async (data) => {
    setLoading(true)
    try {
      if (_id) {
        const { data: updateRackData } = await updateRack({
          variables: {
            updateRackId: parseInt(_id),
            rack: {
              name: data.name,
              code: data.code,
              capacity: data.capacity,
              circuit: data.circuit,
              reorder: data.reorder,
              warehouse_id: data.warehouse_id,
              zone_id: data.zone_id,
            },
          },
        })
        if (updateRackData.updateRack) {
          return (
            toastSweetAlert({
              mode: 'ok',
              message: 'Rack actualizado correctamente',
            }),
            history.push(`/stowing/rack`)
          )
        }
      } else {
        const { data: createRackData } = await createRack({
          variables: {
            rack: {
              name: data.name,
              code: data.code,
              capacity: data.capacity,
              circuit: data.circuit,
              reorder: data.reorder,
              warehouse_id: data.warehouse_id,
              zone_id: data.zone_id,
            },
          },
        })
        if (createRackData.createRack) {
          return (
            toastSweetAlert({
              mode: 'ok',
              message: 'Rack creado correctamente',
            }),
            history.push(`/stowing/rack`)
          )
        }
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const getCircuitOptions = () => {
    if (rackCount?.getRackCount > 0) {
      let rackOptions = []
      for (let index = 1; index < rackCount?.getRackCount; index++) {
        rackOptions.push({
          value: index,
          label: index,
        })
      }
      return rackOptions
    }
    return []
  }

  return (
    <>
      <ContentHeader
        title="Control de racks"
        breadcrumb="Rack"
        windowTitle={`${_id ? 'Editar' : 'Agregar'} Rack `}
      />
      <FormProvider {...methods}>
        <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`${_id ? 'Editar' : 'Agregar'} rack`}
            btnRedPath="/stowing/rack"
            btnRedTxt="Cancelar"
            btnSubmit={true}
            btnState={loading}
            errors={errors}
            content={
              <>
                <div className="row">
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Nombre"
                      type="text"
                      name="name"
                      placeholder="Ingrese el nombre del rack"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Código"
                      type="text"
                      name="code"
                      placeholder="Ingrese el código del rack"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Posición en el circuito"
                      type="number"
                      inputType="choosen"
                      name="circuit"
                      control={control}
                      options={getCircuitOptions()}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Capacidad"
                      type="text"
                      name="capacity"
                      placeholder="Ingrese la capacidad para el rack"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Punto de reorden"
                      type="number"
                      name="reorder"
                      placeholder="Ingrese el punto de reorden para el rack"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Almacén"
                      type="number"
                      inputType="choosen"
                      name="warehouse_id"
                      control={control}
                      options={warehousesOptions}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Zona"
                      type="number"
                      inputType="choosen"
                      name="zone_id"
                      control={control}
                      options={rackZoneOptions}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default RacksNew
