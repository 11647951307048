import moment from "moment"

export function validateDatesForCreateReports({
    rangeStart = '',
    rangeEnd = '',
}){
    try {
        //---- Dates validations start ----
        if( (rangeStart.length == 0 || rangeEnd.length == 0) ) 
            return { error: true, msg: 'Seleccione una fecha de inicio y final para el reporte' }

        if( (!moment(rangeStart).format() || !moment(rangeEnd).format()) ) 
            return { error: true, msg: 'Seleccione una fecha de inicio y final correctos' }

        if( ( moment(rangeStart).format() > moment(rangeEnd).format() )) 
            return { error: true, msg: 'Seleccione una fecha de inicio que sea menor o igual a la fecha final' }


        //---- Dates validations end ----

        //All correct
        return { error: false, msg: '' }
    } catch (error) {
        return { error: true, msg: 'Error en las fechas' }
    }

}
