import { FormProvider, set, useForm } from 'react-hook-form'
import { GET_PICKERS_AND_TRANSFER_PICKERS_USERS } from '../../../../graphql/Catalog/UsersApp/usersApp'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { ASSIGN_PICKER_TO_ORDER_PICKING_STORE } from '../../../../graphql/Catalog/Orders/pickingOrdersStore'

const AssignPickerForPickingOrdersStore = ({
  idOrderWarehouse,
  closeModal,
  setCloseModal,
  helperCleanField,
  setHelperCleanField,
  refModal,
  refetchGetOneOrder,
}) => {
  const [optionsPickers, setOptionsPickers] = useState([])
  const [cleanField, setCleanField] = useState(false)
  const [flag, setFlag] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)

  const validationForm = yup.object().shape({
    select_picker: yup.string().required('El campo es requerido'),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationForm),
  })

  const {
    data: dataPickers,
    loading: loadingPickers,
    error: errorPickers,
  } = useQuery(GET_PICKERS_AND_TRANSFER_PICKERS_USERS, {
    variables: {
      type: ['Picking', 'PickingTransfer'],
    },
    fetchPolicy: 'no-cache',
  })

  const [assignPickerToOrder] = useMutation(
    ASSIGN_PICKER_TO_ORDER_PICKING_STORE
  )

  useEffect(() => {
    if (!flag && helperCleanField) {
      setCleanField(false)
    }
    if (flag && !helperCleanField) {
      setCleanField(false)
    }
    if (flag && helperCleanField) {
      setCleanField(true)
    }
  }, [flag, helperCleanField])

  useEffect(() => {
    if (closeModal) {
      handleCloseModal()
    }
  }, [closeModal])

  const handleCloseModal = () => {
    return (
      setCloseModal(false),
      setFlag(true),
      reset({
        select_picker: '',
      })
    )
  }

  const handleSetHelperCleanField = (id) => {
    if (id === '') return

    setHelperCleanField(false)
  }

  useEffect(() => {
    if (!loadingPickers) {
      if (errorPickers)
        return toastSweetAlert({
          mode: 'error',
          message: errorPickers.message,
        })
      let list = []
      dataPickers.getPickersAndTransferPickersUsers.rows.map((picker) => {
        return list.push({
          value: picker.id,
          label: `${picker.name} ${picker.first_name} ${picker.last_name}`,
        })
      })
      setOptionsPickers(list)
    }
  }, [dataPickers, loadingPickers, errorPickers])

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)

      const { data: dataAssignPicker } = await assignPickerToOrder({
        variables: {
          idOrderWarehouse,
          idUserPicker: parseInt(data.select_picker),
        },
      })

      if (dataAssignPicker.assignPickerToOrderStore) {
        if (refetchGetOneOrder) await refetchGetOneOrder()
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Se ha asignado el picker correctamente',
          },
          setLoadingBtn(false),
          setFlag(true),
          reset({
            select_picker: '',
          }),
          refModal.current.click()
        )
      }
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          withCard={false}
          errors={errors}
          btnSubmit
          btnSubmitText={'Asignar'}
          content={
            <>
              <div className="row">
                <div className="col-lg-12">
                  <InputController
                    name="select_picker"
                    inputType="choosen"
                    label="Asignar a"
                    placeHolderChoosen="Elija un picker"
                    control={control}
                    options={optionsPickers}
                    menuPosition="fixed"
                    valueManual={cleanField ? '' : false}
                    changeAction={(e) => handleSetHelperCleanField(e.value)}
                  />
                </div>
              </div>
            </>
          }
        />
      </form>
    </FormProvider>
  )
}

export default AssignPickerForPickingOrdersStore
