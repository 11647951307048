import React, { useEffect, useState } from 'react'
import ContentHeader from './../Layout/ContentHeader'
import Box from '../Global/Box'
import MainStats from './Components/MainStats'
import SalesComparison from './Components/SalesComparison'
import { useQuery } from '@apollo/client'
import { VERIFYING_CURRENT_LEVEL } from '../../graphql/Catalog/ProvidersDashboard/providersDashboard'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import SalesByChannel from './Reports/components/SalesbyChannel'
import LowerPackages from './Reports/components/LowerPackages'
import InventoryDisplacemntsGraphics from './Reports/graphics/allDisplacementsInventoryGraphics'

export const ProvidersDashboard = () => {
  //Hook to save reports date using ContentHeader component
  const [reportDates, setReportDates] = useState({
    start: '',
    end: '',
  })
  const [showSalesReports, setShowSaleReports] = useState(true)
  const {
    data: verifyingLevelData,
    error: verifyingLevelError,
    loading: verifyingLevelLoading,
  } = useQuery(VERIFYING_CURRENT_LEVEL)

  const [title, setTitle] = useState('Al último corte')

  useEffect(() => {
    if (!verifyingLevelLoading) {
      if (verifyingLevelError) {
        return toastSweetAlert({
          mode: 'error',
          message: verifyingLevelError.message,
        })
      }
      setShowSaleReports(verifyingLevelData.verifyingUserLevel)
    }
  }, [verifyingLevelData, verifyingLevelError, verifyingLevelLoading])

  //Handle to save reports dates
  const handleChangeDates = (e, type) => {
    setReportDates({
      ...reportDates,
      [type]: e.target.value,
    })
  }

  //Handle to download report
  const handleReport = () => {
    //Download report
    console.log('setting...')
  }

  //filter by dates
  const [filters, seFilters] = useState([
    { value: 1, label: 'Al último corte' },
    { value: 2, label: 'Quincena pasada' },
    { value: 3, label: 'Año a la fecha ' },
    { value: 4, label: 'Últimos 12 meses' },
    { value: 5, label: 'Tiempo real' },
    { value: 6, label: 'Ver todo' },
  ])

  const [type, setType] = useState(1)

  const handleFilter = (e) => {
    setType(+e.target.value)
    const reportType = filters.find((item) => item.value == +e.target.value)

    setTitle(reportType.label)
  }

  return (
    <>
      {/* <ContentHeader
        title="Inicio"
        // reportFilter
        // reportFilterHandleButton={handleReport}
        // handleChangeDates={handleChangeDates}
        // reportFilterHandleButtonText="Aplicar"
        selectFilter={showSalesReports}
        selectFilterOptionData={filters}
        handleSelectFilter={handleFilter}
      /> */}
      {verifyingLevelData?.verifyingUserLevel ? (
        <>
          <Box
            title={` ${title}`}
            content={
              <>
                <InventoryDisplacemntsGraphics type={type} title={title} />
              </>
            }
          />

          <div className="row justify-content-between">
            <div className="col-xl-8 col-12">
              {/* <div className="col-xl-4  col-12"> */}
              <SalesByChannel title={title} type={type} />
            </div>
            {/* <div className="col-xl-8 col-12 ">
              <ComparativeByCategory />
            </div> */}
          </div>
        </>
      ) : (
        !verifyingLevelLoading && <LowerPackages />
      )}
    </>
  )
}

export default ProvidersDashboard
