import React, { useContext, useEffect, useState, useRef } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  GET_PRODUCTS_BY_TRANSFER_ID_REQUEST,
  GET_TRANSFER_REQUEST_BY_EDITION_ID,
  GET_TRANSFER_REQUEST_BY_ID,
  DELETE_ORDER_LINE,
  REMOVE_LINE_UBICATION,
} from '../../../graphql/Catalog/Transfers/Transfers'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import moment from 'moment'
import LayoutTable from '../../Global/LayoutTable'
import { document_statuses_esp } from '../../Helpers/StatusesLanguages'
import DetailsTransfer from './DetailsTransfer'
import ModalContent from '../../Global/ModalContent'
import { GET_TOTAL_PICKING_QUANTITY } from '../../../graphql/Catalog/Orders/pickingOrdersStore'
import TotalPickingQuantities from './TotalPickingQuantities'
import AddTransferRequest from './AddTransferRequest'
import { TransferContextProvider } from './AddTransferRequestComponents/TransferContext'

export const TransfersStoresNew = (IsNotDetails) => {
  const { id: _id, show } = useParams()
  const refModalDetails = useRef()
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [isPicking, setIsPicking] = useState(false)
  const [dataRequest, setDataRequest] = useState([])
  const statusDictionary = document_statuses_esp
  const [locationBack, setLocationBack] = useState()
  const [warehousesCodes, setWarehousesCodes] = useState({
    from_whs_code: null,
    to_whs_code: null,
    branch_code: null,
  })
  const [products, setProducts] = useState([])
  const [orderId, setOrderId] = useState(0)
  const [productCount, setProductCount] = useState(0)
  const [totalPickingQuantity, setTotalPickingQuantity] = useState({})
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const location = useLocation()
  const history = useHistory()

  const {
    data: dataTransferRequestId,
    loading: loadingTransferRequestId,
    error: errorTransferRequestId,
  } = useQuery(GET_TRANSFER_REQUEST_BY_EDITION_ID, {
    variables: {
      getOneTransferRequestByEditionIdId: parseInt(_id),
      details: true,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getProductsByTransferIdRequest,
    { loading: loadingproductsByTrasferIdRequest },
  ] = useMutation(GET_PRODUCTS_BY_TRANSFER_ID_REQUEST, {
    fetchPolicy: 'no-cache',
  })

  const {
    data: dataOneTransferRequestId,
    loading: loadingOneTransferRequestId,
    error: errorOneTransferRequestId,
    refetch: refetchOneTransferRequestId,
  } = useQuery(GET_TRANSFER_REQUEST_BY_ID, {
    variables: {
      id: parseInt(_id),
      validation: false,
    },
  })

  const [getTotalPickedQuantity] = useLazyQuery(GET_TOTAL_PICKING_QUANTITY, {
    fetchPolicy: 'no-cache',
  })

  const [removeLineUbication] = useMutation(REMOVE_LINE_UBICATION)
  const [deleteOrderLine] = useMutation(DELETE_ORDER_LINE)

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const dateNow = new Date()
  const dateLimit = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)

  useEffect(() => {
    if (_id) {
      if (
        !loadingTransferRequestId &&
        !location.pathname.includes('/transfers/picking')
      ) {
        const data = dataTransferRequestId?.getOneTransferRequestByEditionId

        let list = {
          // date: moment(data?.doc_date).format('DD/MMMM/YYYY'),
          date: moment(data?.doc_date).format('YYYY-MM-DD'),
          date_due: moment(data?.doc_due_date).format('DD/MMMM/YYYY'),
          doc_entry: data?.TransferlineToTransferRequest[0]?.doc_entry,
          comments: data?.comments ? data?.comments : '---',
          origin: data?.warehouse_origin_name?.warehouse_name,
          destiny: data?.warehouse_destiny_name?.warehouse_name,
          status: statusDictionary[data?.status],
          from_whs_code: data?.from_whs_code,
          to_whs_code: data?.to_whs_code,
          // branch_code: warehousesCodes.branch_code,
        }

        const status = data?.status

        const products = data?.TransferlineToTransferRequest?.map((product) => {
          return {
            id: product?.id,
            sku: product?.item_code,
            nombre: product?.name_product?.item_name,
            Solicitado: product?.quantity,
            Aprobado:
              status === 4 || status === 5
                ? product?.quantity - product?.open_quantity
                : 'Pendiente',
            Faltante:
              status === 4 || status === 5
                ? product?.open_quantity
                : 'Pendiente',
          }
        })
        list.products = products

        setDataRequest(list)
      }
      if (
        !loadingOneTransferRequestId &&
        location.pathname.includes('/transfers/picking')
      ) {
        const dataOneRequestId = dataOneTransferRequestId?.getOneTransferRequest
        let list = {}
        if (
          dataOneRequestId?.stage_status !== 1 ||
          dataOneRequestId?.stage_status !== 6
        ) {
          setIsPicking(true)
          list = {
            date: moment(dataOneRequestId?.doc_date).format('DD/MMMM/YYYY'),
            date_due: moment(dataOneRequestId?.doc_due_date).format(
              'DD/MMMM/YYYY'
            ),
            doc_entry:
              dataOneRequestId?.TransferlineToTransferRequest[0]?.doc_entry,
            comments: dataOneRequestId?.comments
              ? dataOneRequestId?.comments
              : '---',
            origin: dataOneRequestId?.warehouse_origin_name?.warehouse_name,
            destiny: dataOneRequestId?.warehouse_destiny_name?.warehouse_name,
            status: statusDictionary[dataOneRequestId?.status],
            // branch_code: warehousesCodes.branch_code,
          }

          const products = dataOneRequestId?.OrderToTransfer?.map((item) => {
            return {
              id: item?.id,
              nombre: `${
                item?.UserToOrder
                  ? `${item?.UserToOrder?.name} ${item?.UserToOrder?.first_name} ${item?.UserToOrder?.last_name}`
                  : 'Sin asignar'
              } `,
              // estado: item?.open ? 'Abierto' : 'Cerrado',
              activo: item?.is_active ? 'Activo' : 'Inactivo',
              surtido: item?.picked ? 'Surtida' : 'Sin Surtir',
              // tipo: item?.type,
              conditional_hide_button:
                dataOneRequestId.status === 9
                  ? item?.picked
                    ? true
                    : false
                  : dataOneRequestId.status === 11 && item.reprocessed
                  ? item?.picked
                    ? true
                    : false
                  : true,
              reprocesado: item.reprocessed ? 'Si' : 'No',
            }
          })
          list.products = products
        }

        setDataRequest(list)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataTransferRequestId, dataOneTransferRequestId])

  useEffect(() => {
    try {
      const getProductsByOrderRequest = async () => {
        getDataProductsDetails(orderId)
      }
      getProductsByOrderRequest()
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [pagePagination])

  const getDataProductsDetails = async (orderId) => {
    try {
      const { data } = await getProductsByTransferIdRequest({
        variables: {
          id: parseInt(orderId),
          searchQuery: pagePagination.searchQuery,
          limit: pagePagination.limit,
          offset: pagePagination.offset,
        },
      })

      const list = data?.getProductsByTransferRequest?.rows.map((product) => ({
        id: product?.id,
        codigo:
          product?.OrdersWarehouseProductsToInventoryTransferLine
            ?.transferLinesSapItems?.item_code,
        nombre:
          product?.OrdersWarehouseProductsToInventoryTransferLine
            ?.transferLinesSapItems?.item_name,
        cantidad: product?.quantity,
        surtido: product?.picked,
        cajas: product?.boxes,
        tipo: product?.type,
        'Ubicación de pickeo': product?.RackInfoPicking
          ? product?.RackInfoPicking.name
          : 'Sin ubicación',
        conditional_hide_button:
          product?.picked < 1 && product?.RackInfoPicking ? false : true,
        conditional_hide_custom_action_buttons:
          product?.picked > 0 ? true : false,
      }))
      setProductCount(data?.getProductsByTransferRequest?.count)

      setProducts(list)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const detailsProducts = async (id, noClick) => {
    try {
      setOrderId(+id)

      getDataProductsDetails(id)
      const { data } = await getTotalPickedQuantity({
        variables: {
          idOrderWarehouse: +id,
        },
      })
      if (data.getAllPickedQuantity) {
        setTotalPickingQuantity(data?.getAllPickedQuantity)
      }

      // console.log(data?.getAllPickedQuantity, "total picked quantity")

      if (!noClick) refModalDetails.current.click()
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  useEffect(() => {
    const windowLocation = `/${location.pathname.split('/')[1]}/${
      location.pathname.split('/')[2]
    }`
    // console.log(windowLocation)
    if (
      location.pathname === '/transfers/new' ||
      location.pathname.includes('/transfers/edit')
    ) {
      setLocationBack('/transfers')
    } else if (location.pathname.includes('/transfers/picking')) {
      setLocationBack('/transfers/picking')
    } else {
      setLocationBack('/transfers/general')
    }
  }, [])

  const hadleRemoveUbicationLine = async (id, line) => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'info',
        title: '¿Eliminar ubicación?',
        message:
          'Este proceso libera la orden de surtido de la posición asignada para buscarle una nueva posición donde pickear. Esta acción no podra ser revertida, ¿Deseas continuar?',
      })

      if (result?.isConfirmed) {
        setLoadingBtn(true)
        await removeLineUbication({
          variables: {
            removeLineUbicationId: parseInt(line.id),
            itemCode: line.codigo,
            transferId: parseInt(_id),
          },
        })
        await getDataProductsDetails(parseInt(orderId))
        await detailsProducts(parseInt(orderId), true)
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Se ha removido la ubicación de surtido correctamente',
          },
          setLoadingBtn(false)
        )
      }
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleDeleteOrderLine = async (line) => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'info',
        title: '¿Eliminar linea de surtido?',
        message:
          'Este proceso excluira esta cantidad de producto del traspaso y no podra ser revertido, ¿Deseas continuar?',
      })

      if (result?.isConfirmed) {
        setLoadingBtn(true)
        await deleteOrderLine({
          variables: {
            deleteLineOrderId: parseInt(line.id),
            itemCode: line.codigo,
            transferId: parseInt(_id),
          },
        })
        await getDataProductsDetails(parseInt(orderId))
        await detailsProducts(parseInt(orderId), true)
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Se ha eliminado la orden de surtido correctamente',
          },
          setLoadingBtn(false)
        )
      }
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      {/* <ContentHeader
        title={`${_id ? 'Detalles' : 'Nuevo'} traspaso`}
        breadcrumb={`${_id ? 'Detalles' : 'Nuevo'} traspaso`}
        windowTitle={`${_id ? 'Detalles' : 'Nuevo'} traspaso`}
      /> */}
      <div className={` ${loadingBtn ? 'processing-file' : ''}`}>
        {show ? (
          <DetailsTransfer
            dataRequest={dataRequest}
            loadingData={loadingOneTransferRequestId}
            detailsProducts={detailsProducts}
            isPicking={isPicking}
            refetch={refetchOneTransferRequestId}
            setLoadingBtn={setLoadingBtn}
          />
        ) : (
          <TransferContextProvider>
            <AddTransferRequest _id={_id} show={show} />
          </TransferContextProvider>
        )}

        <span
          ref={refModalDetails}
          className="btn btn-accept btn-sm invisible"
          data-toggle="modal"
          data-target="#detailsProducts"
          data-backdrop="static"
          data-keyboard="false"
          data-dismiss="modal"
        />
        <ModalContent
          idModal="detailsProducts"
          title="Detalles de Productos"
          size="extra"
          content={
            <>
              {/* <h5>Cantidad total: {totalPickingQuantity} </h5> */}
              <TotalPickingQuantities quantities={totalPickingQuantity} />
              <LayoutTable
                data={products}
                hideId
                // hideActions
                hideAddNew
                hideDetails
                hideBtnErase
                // hideFilterBar
                // paginationServer={false}
                loadingData={loadingproductsByTrasferIdRequest}
                exportImportButton={false}
                setPagePagination={setPagePagination}
                pagePagination={pagePagination}
                totalCount={productCount}
                handleConditionalButton={hadleRemoveUbicationLine}
                handleConditionalButtonTitle="Remover ubicación"
                customActionButtons={[
                  // {
                  //   className: 'btn btn-accept btn-sm',
                  //   handleButton: hadleRemoveUbicationLine,
                  //   buttonText: 'Remover ubicación',
                  // },
                  {
                    className: 'btn btn-danger btn-sm ',
                    handleButton: handleDeleteOrderLine,
                    buttonText: <i className="fa fa-trash"></i>,
                  },
                ]}
              />
            </>
          }
        />
      </div>
    </>
  )
}

export default TransfersStoresNew
