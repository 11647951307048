import { gql } from '@apollo/client'

export const GET_SHIPPED_ORDERS = gql`
  query GetShippedOrders(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $platform: Int
    $date: Int
  ) {
    getShippedOrders(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      platform: $platform
      date: $date
    ) {
      rows {
        id
        platform_id
        platform {
          name
          id
        }
        type {
          name
        }
        payment {
          platform
          payment_id
        }
        store {
          name
        }
        warehouse {
          name
        }
        shipping {
          first_name
          last_name
        }
        order_id
        order_date
        object_type
        object_type_name
      }
      count
    }
  }
`
export const CHANGE_TO_SHIPPED = gql`
  mutation ChangeToShipped($orderId: String!) {
    changeToShipped(order_id: $orderId) {
      id
      status {
        id
        name
      }
    }
  }
`
export const EXPORT_SHIPPED_ORDERS = gql`
  mutation GetAllShippedExport {
    getAllShippedExport {
      id
      order_date
      platform_name
      type_name
      id_payment
      platform
      shipping_first_name
      shipping_last_name
      order_id
      store_name
      warehouse_name
    }
  }
`
