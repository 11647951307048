import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_ALL_WAREHOUSE_ASSIGNATION_BY_USER_ID } from '../../../graphql/Catalog/SAP/ProvidersBusinessPartner'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

const StoreWarehouseAssignationDetails = ({ show, _id }) => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { data, error, loading } = useQuery(
    GET_ALL_WAREHOUSE_ASSIGNATION_BY_USER_ID,
    {
      variables: {
        getAllWarehouseAssignationByUserIdId: +_id,
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    try {
      if (_id && !loading) {
        if (error)
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        console.log(data)
        const list = data.getAllWarehouseAssignationByUserId.rows.map(
          (item) => {
            return {
              id: item.id,
              almacen:
                item.SapWarehousesToCatalogUserWarehouseReports.warehouse_name,
              codigo:
                item.SapWarehousesToCatalogUserWarehouseReports.warehouse_code,
            }
          }
        )

        setDataToTable(list)
        setTotal(data.getAllWarehouseAssignationByUserId.count)
        return
      }
    } catch (error) {
      return toastSweetAlert({
        message: error.message,
        mode: 'error',
      })
    }
  }, [data, error, loading, _id])

  return (
    <>
      <h2 className=" font-weight-bold text-dark business-partner-mb-title">
        Almacenes asignados
      </h2>
      <Box
        title="Almacenes asignados"
        withCard={false}
        content={
          <>
            <LayoutTable
              withCard={false}
              data={dataToTable}
              hideActions={show ? true : false}
              setPagePagination={setPagePagination}
              pagePagination={pagePagination}
              totalCount={total}
              loadingData={loading}
            />
          </>
        }
      />
    </>
  )
}

export default StoreWarehouseAssignationDetails
