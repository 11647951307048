import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import LayoutTable from '../Global/LayoutTable'
import ContentHeader from '../Layout/ContentHeader'
import InputController from '../Global/InputController'
import { CLOSE_SAP_ORDER } from '../../graphql/Catalog/SAP/sapWarehouses'
import {
  GET_PURCHASE_ORDERS,
  GET_REPORT_DOC_RETURNS_DETAILS,
  GET_REPORT_COMMODITY_RECEIPT_BY,
  GET_DETAIL_COMMODITY_RECEIPT,
} from '../../graphql/Documents/CommodityReceipt/commodityReceipt'
import { getEstatus, canCloseOrder } from '../../helpers/HelperComodityReport'
import HelperGenerateExcel from '../../helpers/HelperGenerateExcel'
import { GET_RETURN_DOCUMENT_BY_PURCHASE_ORDER } from '../../graphql/Documents/Inventories/Returns'

const ReceiptReportsNew = (props) => {
  const { id } = useParams()
  //console.log({id})
  //const { control, setValue } = useForm({})

  //STATES :
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [returnDocument, setReturnDocument] = useState([])
  const [summary, setSummary] = useState({
    document: '--',
    status: '--',
    sent: {
      provider: '--',
      sendedItems: {
        skus: 0,
        units: 0,
      },
      //'12 SKUs - 458 unidades',
    },
    received: {
      provider: '--',
      warehouse: '--',
      sendedItems: {
        skus: 0,
        units: 0,
      },
      //'12 SKUs - 458 unidades',
    },
    difference: 0,
    sapPurchasesOrdersId: 0,
    docStatus: null,
  })
  const [data, setData] = useState({
    rows: [],
    count: 0,
  })
  const [pagePagination, setPagePagination] = useState({
    limit: 10,
    offset: 0,
    scheduleId: parseInt(id),
    onlyWithDifferences: null,
  })
  const [downloadReport, setDownloadReport] = useState(false)
  const [helperExcel, setHelperExcel] = useState(null)

  //QUERY & MUTATIONS:
  const products = useQuery(GET_DETAIL_COMMODITY_RECEIPT, {
    variables: pagePagination,
  })

  const dataSummary = useQuery(GET_REPORT_COMMODITY_RECEIPT_BY, {
    variables: {
      scheduleId: parseInt(id),
    },
  })

  const [closeOrder] = useMutation(CLOSE_SAP_ORDER, {
    onCompleted: () => {
      products.refetch()
    },
  })

  const dataReturn = useQuery(GET_RETURN_DOCUMENT_BY_PURCHASE_ORDER)
  const [getReturns] = useLazyQuery(GET_RETURN_DOCUMENT_BY_PURCHASE_ORDER, {fetchPolicy: 'no-cache'})

  //USEEFFECTS :
  useEffect(() => {
    if (!products.loading) {
      setLoadingBtn(false)

      if (products.error) {
        return toastSweetAlert({
          mode: 'error',
          message: products.error.message,
        })
      }

      const rows = products?.data?.getReportCommodityReceiptDetails?.rows.map(
        (item) => {
          let expectedQty = item?.quantity
          let receiptQty = item?.LineCommodityReceipt?.receipt_quantity
          let sapQuantity = 0

          if (item?.open_quantity) {
            sapQuantity = parseInt(item?.open_quantity)
          }

          let diff = parseInt(expectedQty) - parseInt(receiptQty)
          let diffSap = parseInt(expectedQty) - parseInt(sapQuantity)

          return {
            id: item?.id,
            sku: item?.item_code,
            'nombre': item?.LineProduct?.item_name,
            'Cant Esperada': expectedQty,
            'Cant Recibida': receiptQty,
            Discrepancia: diff + '/' + diffSap,
            'SAP Recibida': sapQuantity,
            Estado: getEstatus(item?.line_status),
          }
        }
      )

      //console.log({ rows })

      if (downloadReport) {
        //console.log({ downloadReport })

        setHelperExcel(
          <HelperGenerateExcel
            id="ExportExcel"
            excelData={[
              {
                bookName: 'Detale de recibo',
                data: rows,
                columnsWidth: [25, 25, 25, 25, 25, 25, 25],
              },
            ]}
            fileName="Reporte de recibo detalle"
          />
        )
      } else {
        setData({
          rows,
          count: products?.data?.getReportCommodityReceiptDetails?.count,
        })
      }
    } else {
      setLoadingBtn(true)
    }
  }, [products?.loading, products?.data, products?.error, downloadReport])

  useEffect(() => {
    if (!dataSummary.loading) {
      setLoading(false)

      if (dataSummary.error) {
        return toastSweetAlert({
          mode: 'error',
          message: dataSummary.error.message,
        })
      }

      if (dataSummary?.data?.getReportCommodityReceiptBy?.count === 1) {
        const item = dataSummary.data.getReportCommodityReceiptBy.rows[0]
        const skus = item?.items

        let expected = item?.expectedQuantity?.quantity

        let receipt = item?.receipt_quantity

        let sapReceipt = 0
        for (const row of item?.sap_purchases_orders?.OrdersDeleveryNotesRows) {
          sapReceipt += row?.quantity
        }

        let diff = parseInt(expected) - parseInt(receipt)
        let diffSap = parseInt(expected) - parseInt(sapReceipt)

        setSummary({
          row: {
            status: item?.sap_purchases_orders?.document_status,
          },
          document: item?.sap_purchases_orders?.document_number,
          status: getEstatus(item?.sap_purchases_orders?.document_status),
          sent: {
            provider: item?.sap_purchases_orders?.business_partner?.card_name,
            sendedItems: {
              skus: skus,
              units: expected,
            },
          },
          received: {
            provider: item?.CommodityReceiptUser?.name
              ? item?.CommodityReceiptUser?.name
              : '--',
            warehouse:
              item?.sap_purchases_orders_lines?.OrdersSapWarehouse
                ?.warehouse_name,
            sendedItems: {
              skus: skus,
              //units: data?.DocumentReturnOrder?.OrderBelongsCommodityReceipt[0]?.receipt_quantity,
              units: expected,
            },
          },
          difference: diff + '/' + diffSap,
          sapPurchasesOrdersId: item?.sap_purchases_orders?.id,
          docStatus: item?.sap_purchases_orders?.document_status,
        })

        // Get return document
        const getReturnDoc = async () => {
          
          try {
            const returnDocumentrequest = await getReturns({
              variables: {
                docEntry: item?.sap_purchases_orders?.document_entry,
              },
            })
  
            const oDocument =
            returnDocumentrequest.data.getOneReturnDocumentByPurchaseOrder
            if(oDocument ){
              const listReturn = []
              oDocument.Receipts.map((item) => {
                const row = oDocument.Rows.find((sku) => sku.id === item.return_row_id)

                console.log({ row })

                if (row) {
                  
                  listReturn.push({
                    '#': item.line_number,
                    SKU: row.item_code,
                    Descripcion: row.item_name,
                    Recibido: row.quantity,
                    Devolucion: item.quantity,
                  })
                }

                return null
              })
              setReturnDocument(listReturn)
            }
           
           
            console.log({returnDocument})

          } catch (error) {
            console.log(error.message)
          }
        }

        getReturnDoc()
      }
    } else {
      setLoading(true)
    }
  }, [dataSummary?.loading, dataSummary?.data, dataSummary?.error])

  useEffect(() => {
    if (downloadReport) {
      document.getElementById('ExportExcel').click()

      setDownloadReport(false)
    }
  }, [helperExcel])

  //FUNCTIONS :
  const toggleDiff = (e) => {
    if (e.target.checked) {
      setPagePagination({
        scheduleId: parseInt(id),
        onlyWithDifferences: true,
      })
    } else {
      setPagePagination({
        scheduleId: parseInt(id),
        onlyWithDifferences: null,
      })
    }
  }

  const handleCloseOrder = async (id) => {
    //console.log({id})

    const result = await toastSweetAlert({
      mode: 'modalConfirmCancel',
      message: '¿Estas seguro de cerrar la orden?',
    })

    if (result.isConfirmed) {
      //console.log({ row })

      const data = await closeOrder({
        variables: {
          sapPurchaesOrdersId: id,
        },
      })

      if (data?.data?.closeOrder) {
        await toastSweetAlert({
          mode: 'ok',
          message: 'Se ha cancelado la orden correctamente!',
        })
      }

      //console.log({data})
    }
  }

  const handleDownloadReport = () => {
    setPagePagination({
      limit: null,
      offset: 0,
      scheduleId: parseInt(id),
    })

    setDownloadReport(true)
  }

  return (
    <>
      <ContentHeader
        title="Detalles del recibo"
        breadcrumb="Detalles del recibo"
        windowTitle={`Detalles del recibo`}
      />

      {helperExcel}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-10 d-flex align-items-center justify-content-start">
                  <h3 className="card-title">Datos del envío</h3>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                  <Link to="/receipt-reports" className="btn btn-accept">
                    Regresar
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body col-12 p-3">
              <div className="row">
                <div className="col-12 col-md-3 pl-3 pr-3 border-right">
                  <div className="row">
                    <div className="col-6">
                      <h6 className="text-uppercase">Documento</h6>
                      <p>{summary.document}</p>
                    </div>
                    <div className="col-6">
                      <h6 className="text-uppercase">Estatus</h6>
                      <p>{summary.status}</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3 pl-3 pr-3 border-right">
                  <div className="row">
                    <div className="col-12">
                      <h6 className="text-uppercase">Envía</h6>
                      <p className="mb-0 text-uppercase">
                        {summary.sent.provider}
                      </p>
                      <p className="text-text-uppercase">
                        {summary.sent.sendedItems.skus} SKUs -{' '}
                        {summary.sent.sendedItems.units} unidades
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3 pl-3 pr-3 border-right">
                  <div className="row">
                    <div className="col-12">
                      <h6 className="text-uppercase">Recibe</h6>
                      <p className="mb-0 text-uppercase">
                        {summary.received.provider} -{' '}
                        {summary.received.warehouse}
                      </p>
                      <p className="text-text-uppercase">
                        {summary.received.sendedItems.skus} SKUs -{' '}
                        {summary.received.sendedItems.units} unidades
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3 pl-3 pr-3">
                  <div className="row">
                    <div className="col-12 mb-1">
                      <h6 className="text-uppercase">Discrepancias</h6>
                      <p className="mb-0">{summary.difference}</p>
                    </div>

                    {canCloseOrder(summary.row) && (
                      <div className="col-12">
                        <button
                          className="btn btn-accept"
                          onClick={() => {
                            handleCloseOrder(summary.sapPurchasesOrdersId)
                          }}
                        >
                          Cerrar Orden
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${loadingBtn ? 'processing-file' : ''}`}>
        <LayoutTable
          loadingData={loading}
          title="Lista de SKUs"
          hideActions={true}
          data={data.rows}
          totalCount={data.count}
          hideFilterBar={true}
          pagePagination={pagePagination}
          setPagePagination={setPagePagination}
          customFilterButtonInput={
            <>
              <div className="w-100">
                <div className="row pl-0 pr-0">
                  <div className="col-12 col-md-6 d-flex justify-content-start">
                    {/* <div className="row">
                                    <div className="col-auto align-self-center pl-0 pr-0">
                                        <input
                                            type="checkbox"
                                            name="difference_yes"
                                            onClick={toggleDiff}
                                        />
                                    </div>
                                    <div className="col-auto align-self-center">Mostrar solo discrepancias</div>
                                </div> */}
                  </div>

                  <div className="col-12 col-md-6 pl-0 pr-0 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={handleDownloadReport}
                      className="btn btn-accept"
                    >
                      Descargar reporte
                    </button>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
      {returnDocument.length > 0 && (
        <div className={`${loadingBtn ? 'processing-file' : ''}`}>
          <LayoutTable
            loadingData={loading}
            title="Lista de SKUs Devueltos"
            hideActions={true}
            hideFilterBar={true}
            data={returnDocument}
          ></LayoutTable>
        </div>
      )}
    </>
  )
}

export default ReceiptReportsNew
