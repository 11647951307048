import { gql } from '@apollo/client'

export const GET_COUNT_PAYMENTS_FINANCES_COUNTER_CHARGES = gql`
  query GetCountForConsecutiveNumberForPaymentsFinances(
    $idBank: Int
    $nomenclatureDate: String
  ) {
    getCountForConsecutiveNumberForPaymentsFinances(
      idBank: $idBank
      nomenclatureDate: $nomenclatureDate
    ) {
      rows {
        id_bank
        nomenclature_date
        consecutive_number_of_payments
      }
      count
    }
  }
`

export const GENERATE_PAYMENTES_FINANCES_AND_UPDATE_NOTES = gql`
  mutation GeneratePaymentsFinancesAndUpdateNotes(
    $noteInput: [noteInputUpdate]
    $inputPayments: generatePaymentsFinancesInput
    $partialPayment: Boolean
    $closeWithAdjustment: Boolean
    $paymentContability: Boolean
  ) {
    generatePaymentsFinancesAndUpdateNotes(
      noteInput: $noteInput
      inputPayments: $inputPayments
      partialPayment: $partialPayment
      closeWithAdjustment: $closeWithAdjustment
      paymentContability: $paymentContability
    )
  }
`

export const GET_ALL_PAYMENTS_BY_ID_FINANCES_COUNTER_CHARGES = gql`
  query GetAllPaymentesByIdFinancesCounterCharges(
    $getAllPaymentesByIdFinancesCounterChargesId: Int
  ) {
    getAllPaymentesByIdFinancesCounterCharges(
      id: $getAllPaymentesByIdFinancesCounterChargesId
    ) {
      rows {
        id
        consecutive_number_of_payments
        paymentsFinancesToBanks {
          bank_name
        }
        payment_amount
        payment_date
        comments
      }
    }
  }
`

export const DELETE_PAYMENTS_FINANCES_COUNTER_CHARGES = gql`
  mutation DeletePaymentsFinancesCounterCharges($consecutiveNumber: String) {
    deletePaymentsFinancesCounterCharges(consecutiveNumber: $consecutiveNumber)
  }
`

export const DELETE_TRASNFERS_FINANCES_COUNTER_CHARGES = gql`
  mutation DeleteTransfersFinancesCounterCharges($id: Int) {
    deleteTransfersFinancesCounterCharges(id: $id)
  }
`

export const GET_ALL_PAYMENTS_BY_CONSECUTIVE_NUMBER = gql`
  mutation GetAllPaymentsByConsecutiveNumber($consecutiveNumber: String) {
    getAllPaymentsByConsecutiveNumber(consecutiveNumber: $consecutiveNumber) {
      consecutive_number_of_payments
      paymentsFinancesToNotes {
        id
        consecutive_number
      }
    }
  }
`

export const GET_ALL_PAYMENTS_AND_TRANSFERS_FINANCES_COUNTER_CHARGES_TO_EXPORT = gql`
  mutation GetAllPaymentsAndTransfersFinancesCounterChargesToExport(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $dateFrom: String
    $dateTo: String
    $idBankOrigin: Int
  ) {
    getAllPaymentsAndTransfersFinancesCounterChargesToExport(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      dateFrom: $dateFrom
      dateTo: $dateTo
      idBankOrigin: $idBankOrigin
    ) {
      # rows {
      id_bank
      payment_date
      consecutive_number_of_payments
      nomenclature_bank
      nomenclature_date
      consecutive_number
      payment_amount
      transfer_concept
      is_transfer
      is_active
      generatePaymentsFinancesToNotes {
        partnerNotes {
          provider_name
        }
        bills {
          invoice_bill
        }
      }
      transfersToBankOrigin {
        bank_name
        bank_nomenclature
      }
      # }
    }
  }
`

export const GENERATE_PAYMENTS_FOR_FINANCES_CONTABILITY_COUNTER_CHARGES = gql`
  mutation GeneratePaymentsFinancesContability(
    $noteInput: [noteInputUpdate]
    $inputPayments: generatePaymentsFinancesInput
  ) {
    generatePaymentsFinancesContability(
      noteInput: $noteInput
      inputPayments: $inputPayments
    )
  }
`

export const GET_INFORMATION_ONE_PAYMENT_FOR_EDIT = gql`
  mutation GetInformationPaymentForEdit($getInformationPaymentForEditId: Int) {
    getInformationPaymentForEdit(id: $getInformationPaymentForEditId) {
      consecutive_number_of_payments
      id_bank
      payment_amount
      payment_date
    }
  }
`

export const UPDATE_INFORMATION_PAYMENT = gql`
  mutation UpdateInformationPayment(
    $consecutiveNumber: String
    $paymentAmount: String
    $paymentDate: String
  ) {
    updateInformationPayment(
      consecutiveNumber: $consecutiveNumber
      paymentAmount: $paymentAmount
      paymentDate: $paymentDate
    )
  }
`
