import { FormProvider, useForm } from 'react-hook-form'
import Box from '../../../../../Global/Box'
import * as yup from 'yup'
import InputController from '../../../../../Global/InputController'
import ContentHeader from '../../../../../Layout/ContentHeader'
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_RETENTION_GLOBAL_COUNTERCHARGES,
  GET_RETENTION_GLOBAL_COUNTERCHARGES,
  UPDATE_RETENTION_GLOBAL_COUNTERCHARGES,
} from '../../../../../../graphql/Catalog/Notes/BusinessRetention/businessretention'
import { toastSweetAlert } from '../../../../../Helpers/ToastSweetAlert'
import { useHistory } from 'react-router-dom'

const RetentionGlobalCounterCharges = ({ handlePrevStep }) => {
  const history = useHistory()
  const [loadingBtn, setLoadingBtn] = useState(false)

  const { data: dataGlobalRetention } = useQuery(
    GET_RETENTION_GLOBAL_COUNTERCHARGES,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const [createRetentionGlobal] = useMutation(
    CREATE_RETENTION_GLOBAL_COUNTERCHARGES
  )
  const [updateRetentionGlobal] = useMutation(
    UPDATE_RETENTION_GLOBAL_COUNTERCHARGES
  )

  const validationSchema = yup.object().shape({
    globalRetention: yup
      .number('Este campo es obligatorio')
      .typeError('Este campo es obligatorio')
      .negative('Solo números positivos')
      .max(1, 'El porcentaje debe ser de 0-1')
      .min(0.0, 'El porcentaje debe ser de 0-1'),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (
      dataGlobalRetention?.getRetentionGlobal !== null &&
      dataGlobalRetention?.getRetentionGlobal !== undefined
    ) {
      const data = dataGlobalRetention?.getRetentionGlobal
      console.log(data)
      const dataFormated = {
        globalRetention: data?.retention_global,
      }
      reset(dataFormated)
    }
  }, [dataGlobalRetention])

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)
      if (dataGlobalRetention?.getRetentionGlobal) {
        await updateRetentionGlobal({
          variables: {
            retentionGlobalInput: data.globalRetention.toString(),
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Retención Global Actualizada',
          },
          history.push(`/catalog/notes/businessretention`)
        )
      }

      await createRetentionGlobal({
        variables: {
          retentionGlobalInput: data.globalRetention.toString(),
        },
      })
      setLoadingBtn(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Retención Global Creada',
        },
        history.push(`/catalog/notes/businessretention`)
      )
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  return (
    <>
      <ContentHeader
        title="Retención Manual"
        windowTitle="Retención Manual"
        breadcrumb="Retención Manual"
      />
      <FormProvider>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title="Retención Manual"
            btnSubmit="Guardar"
            btnState={loadingBtn}
            btnSecondFunction={handlePrevStep}
            btnSecondFunctionTitle="Cancelar"
            content={
              <>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputController
                      name="globalRetention"
                      label="Asignar Retención Global"
                      control={control}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default RetentionGlobalCounterCharges
