import React, { useState } from 'react'
import ShopifyChannel from './Channels/Shopify/ShopifyChannel'
import Box from '../Global/Box'

const Omnichannel = () => {
  const [loading, setLoading] = useState(false)
  const [platformSelected, setPlatformSelected] = useState(5)

  return (
    <Box
      content={
        <>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="shopify"
                data-bs-toggle="tab"
                data-bs-target="#shopify-pane"
                type="button"
                role="tab"
                aria-controls="shopify-pane"
                aria-selected="true"
              >
                <i className="fab fa-shopify"></i> Shopify
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="woocomerce"
                data-bs-toggle="tab"
                data-bs-target="#woocomerce-pane"
                type="button"
                role="tab"
                aria-controls="woocomerce-pane"
                aria-selected="false"
                disabled
              >
                Woocomerce
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Amazon"
                data-bs-toggle="tab"
                data-bs-target="#Amazon-pane"
                type="button"
                role="tab"
                aria-controls="Amazon-pane"
                aria-selected="false"
                disabled
              >
                Amazon
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="disabled-tab"
                data-bs-toggle="tab"
                data-bs-target="#disabled-tab-pane"
                type="button"
                role="tab"
                aria-controls="disabled-tab-pane"
                aria-selected="false"
                disabled
              >
                Mercado Libre
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex="0"
            >
              <ShopifyChannel platformSelected={platformSelected} />
            </div>
            <div
              className="tab-pane fade"
              id="woocomerce-pane"
              role="tabpanel"
              aria-labelledby="woocomerce"
              tabIndex="0"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="Amazon-pane"
              role="tabpanel"
              aria-labelledby="Amazon"
              tabIndex="0"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="disabled-tab-pane"
              role="tabpanel"
              aria-labelledby="disabled-tab"
              tabIndex="0"
            >
              ...
            </div>
          </div>
        </>
      }
    />
  )
}

export default Omnichannel
