import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { GET_ALL_JOB_PARAMETERS, GET_ALL_JOB_STATES, GET_JOB_BY_ID } from '../../graphql/HangFire/ReportTransactions'
import ContentHeader from '../Layout/ContentHeader'
import Box from '../Global/Box'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'

const TransactionReportNew = () => {
  const { id: _id } = useParams()

  const [getJobParameters] = useLazyQuery(
    GET_ALL_JOB_PARAMETERS
  )
  const [getJobStates] = useLazyQuery(
    GET_ALL_JOB_STATES
  )
  const [getJob] = useLazyQuery(
    GET_JOB_BY_ID
  )
  const [JobData, setJobData] = useState([])
  const [listOfParameters, setListOfParameters] = useState([])
  const [listOfStates, setListOfStates] = useState([])
  const [title, setTitle] = useState('Estado de la transacción')
  useEffect(async () => {
    try {
      const jobRequest = await getJob({
        variables: {
          jobId: parseInt(_id),
        },
      })
      setTitle(jobRequest.data.getJob.Detail.method)
      setJobData(jobRequest.data.getJob)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [_id])
  useEffect(async () => {
    try {
      const parameters = await getJobParameters({
        variables: {
          jobId: parseInt(_id),
        },
      })
      let list = []

      parameters.data.getAllGetJobParameters.rows.map((item) => {
        return list.push({
          JobId: item.jobId,
          Name: item.name,
          Value: item.value,
        })
      })

      setListOfParameters(list)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [_id])

  useEffect(async () => {
    try {
      const sates = await getJobStates({
        variables: {
          jobId: parseInt(_id),
        },
      })

      let list = []


      sates.data.getAllJobStates.rows.map((item) => {
        const dataJson = JSON.parse(item.data)

        let jobState = {
          id: item.id,
          jobId: item.jobId,
          name: item.name,
          reason: item.reason,
          createdAt: item.createdAt,
          data: item.data,
          dataList: dataJson
        }
        list.push(jobState)
      })
      setListOfStates(list)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [_id])

  return (
    <>
      {/* <ContentHeader
        title="Detalles de la Transacción"
        breadcrumb="Detalles de la Transacción"
        windowTitle={`Detalles de la Transacción`}
      /> */}

      <Box
        title={title}
        btnGreenTxt="Volver"
        btnGreenPath="/Hangfire/transactionReport"
        content={<>
          <div className='row'>
            <h3>Detalles</h3>

            <table className="table table-condensed job-snippet-properties">
              <tbody>
                <tr>
                  <td className="width-20 word-break">Argumentos</td>
                  <td><pre>{JobData.arguments ? JSON.stringify(JSON.parse(JobData.arguments)) : ""}</pre></td>                  
                </tr>
                <tr>
                  <td className="width-20 word-break">Fecha de creacion</td>
                  <td><pre><code>{JobData.createdAt}</code></pre></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='row'>
            <h3>Parametros</h3>
            <table className="table table-condensed job-snippet-properties">
              <tbody>
                {listOfParameters.map((item) => {
                  return (
                    <tr>
                      <td className="width-20 word-break">{item.Name}</td>
                      <td><pre><code>{item.Value}</code></pre></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='row'>
            <h3>Estados</h3>
            <hr></hr>
            <table className="table table-condensed job-snippet-properties">
              <tbody>
                {listOfStates.map((item) => {
                  return (
                    <tr>
                      <td className="width-20 word-break">{item.name}</td>
                      <td className="width-20 word-break" >{item.reason}</td>
                      <td className="width-20 word-break" >{item.data ? JSON.stringify(JSON.parse(item.data)) : ""}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>

        }
      ></Box>
    </>
  )
}

export default TransactionReportNew
