import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useState } from 'react'
import { useEffect } from 'react'
import Box from '../../../../Global/Box'
import InputController from '../../../../Global/InputController'
import OrderCol from '../../../../Global/OrderCol'
import * as yup from 'yup'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import {
  GET_ONE_NOTE,
  GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER,
  GET_ONE_NOTE_BY_UUID_FOR_BILL,
  UPDATE_NOTES_AMOUNT,
} from '../../../../../graphql/Catalog/Notes/notes'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { GET_STORES } from '../../../../../graphql/Catalog/Stores/stores'
import { AuthContext } from '../../../../../Auth/AuthContext'
import { GET_INFO_STORE_COUNTER_CHARGES } from '../../../../../graphql/Catalog/Notes/StoreHelperCounterCharges/storehelpercountercharges'
import {
  GET_COUNT_PAYMENTS_FOR_REFERENCE,
  GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE_BY_INVOICE_NUMBER_PAYMENTS,
} from '../../../../../graphql/Catalog/Notes/payments'
import PrintCheckCoverages from './PrintCheckCoverages/PrintCheckCoverages'
import ReactToPrint from 'react-to-print'
import { useRef } from 'react'
import LayoutTable from '../../../../Global/LayoutTable'
import { GET_ROLES } from '../../../../../graphql/Catalog/Roles/roles'
import DisplayInfoForPrintCoverage from './DisplayInfoForPrintCoverage'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'
import {
  GET_ALL_BANKS_FINANCES_COUNTER_CHARGES,
  GET_ONE_BANK_FINANCES_COUNTER_CHARGE,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/banksfinancescountercharges'
import {
  GENERATE_PAYMENTS_FOR_FINANCES_CONTABILITY_COUNTER_CHARGES,
  GET_COUNT_PAYMENTS_FINANCES_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/generatepaymentsfinancescountercharges'
import ContentHeader from '../../../../Layout/ContentHeader'

const BankPaymentsContability = () => {
  const { user } = useContext(AuthContext)
  // const componentRef = useRef()
  // console.log(user)
  const regexID = new RegExp('id(¡|¿|=)[^/ | &]+', 'i')
  const regexEmisor = new RegExp('re(¡|¿|=)[^/ | &]+', 'i')
  const regexReceptor = new RegExp('rr(¡|¿|=)[^/ | &]+', 'i')
  const regexTotal = new RegExp('tt(¡|¿|=)[^/ | &]+', 'i')

  const billArray = []
  // const infoIdNote = idNote
  const history = useHistory()
  const [totalSum, setTotalSum] = useState(0.0)
  const [valueSearchNote, setValueSearchNote] = useState(null)
  const [flag, setFlag] = useState(1)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [options, setOptions] = useState([])
  const [infoStore, setInfoStore] = useState(null)
  const [idRole, setIdRole] = useState(null)
  const [dateInfo, setDateInfo] = useState('')
  const [valueIdBank, setValueIdBank] = useState(null)
  const [valueConsecutive, setValueConsecutive] = useState(null)
  const [infoBank, setInfoBank] = useState(null)
  const [valueCountReference, setValueCountReference] = useState(null)
  const [checkNumber, setCheckNumber] = useState('')
  const [dataTable, setDataTable] = useState([])
  const [dataBack, setDataBack] = useState([])
  const [infoNote, setInfoNote] = useState(null)

  const [getRoles] = useLazyQuery(GET_ROLES)

  const [getOneNoteByUUIDForBill] = useLazyQuery(GET_ONE_NOTE_BY_UUID_FOR_BILL)

  const [getInfoStore] = useLazyQuery(GET_INFO_STORE_COUNTER_CHARGES)

  const [updateNotesAmount] = useMutation(UPDATE_NOTES_AMOUNT)
  const [getOneNoteByConsecutive] = useLazyQuery(
    GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER
  )
  const [generatePaymentsFinancesForContability] = useMutation(
    GENERATE_PAYMENTS_FOR_FINANCES_CONTABILITY_COUNTER_CHARGES
  )

  const {
    loading: loadingOneBank,
    data: dataOneBank,
    error: errorOneBank,
  } = useQuery(GET_ONE_BANK_FINANCES_COUNTER_CHARGE, {
    variables: {
      getOneBankFinancesCounterChargesId: parseInt(valueIdBank),
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingCountForConsecutive,
    data: dataCountForConsecutive,
    error: errorCountForConsecutive,
  } = useQuery(GET_COUNT_PAYMENTS_FINANCES_COUNTER_CHARGES, {
    variables: {
      idBank: parseInt(valueIdBank),
      nomenclatureDate: dateInfo,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingBank,
    data: dataBank,
    error: errorBank,
  } = useQuery(GET_ALL_BANKS_FINANCES_COUNTER_CHARGES, {
    fetchPolicy: 'no-cache',
  })

  const validationSchema = yup.object().shape({
    bank_origin: yup
      .string()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),
    // bank_destination: yup
    //   .string()
    //   .required('Este campo es obligatorio')
    //   .typeError('Este campo es obligatorio'),
    check_date: yup.date().typeError('Este campo es obligatorio'),
    reference: yup.string().required('Este campo es obligatorio'),
    // nominative: yup.boolean().typeError('Este campo es obligatorio'),
    // carrier: yup.boolean().typeError('Este campo es obligatorio'),
    // bills: yup.array().of(
    //   yup.object().shape({
    //     amount_bill: yup.number().typeError('Este campo es obligatorio'),
    //   })
    // ),
  })
  // .test((obj) => {
  //   if (obj.nominative && obj.carrier) {
  //     return true
  //   }
  //   return new yup.ValidationError('Debes marcar almenos una opción')
  // })

  const {
    methods,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'bills',
  })

  useEffect(() => {
    if (dataOneBank !== null && dataOneBank !== undefined) {
      if (!loadingOneBank) {
        // setLoadingBtn(true)
        if (errorOneBank)
          return toastSweetAlert(
            {
              mode: 'error',
              message: errorOneBank?.message,
            }
            // setLoadingBtn(false)
          )

        setInfoBank(dataOneBank?.getOneBankFinancesCounterCharges)
        // setLoadingBtn(false)
      }
    }
  }, [dataOneBank, loadingOneBank, errorOneBank])

  useEffect(() => {
    if (!loadingCountForConsecutive) {
      // setLoadingBtn(true)
      if (errorCountForConsecutive)
        return toastSweetAlert(
          {
            mode: 'error',
            message: errorCountForConsecutive?.message,
          }
          // setLoadingBtn(false)
        )

      const dataCount =
        dataCountForConsecutive?.getCountForConsecutiveNumberForPaymentsFinances
          ?.rows

      let count = 0
      let consecutiveNumber = null
      for (let data of dataCount) {
        if (consecutiveNumber !== data?.consecutive_number_of_payments) {
          count++
        }
        consecutiveNumber = data?.consecutive_number_of_payments
      }

      // console.log('count', count)
      // setValueConsecutive(
      //   dataCountForConsecutive?.getCountForConsecutiveNumberForPaymentsFinances
      //     ?.count + 1
      // )
      setValueConsecutive(count + 1)
      // setLoadingBtn(false)
    }
    // setLoadingBtn(false)
  }, [
    loadingCountForConsecutive,
    dataCountForConsecutive,
    errorCountForConsecutive,
  ])

  useEffect(() => {
    if (!loadingBank) {
      if (errorBank)
        return toastSweetAlert({
          mode: 'error',
          message: errorBank?.message,
        })

      const banks = dataBank?.getAllBanksFinancesCounterCharges?.rows?.map(
        (item) => {
          return {
            value: item?.id,
            label: item?.bank_name + ' - ' + item?.bank_nomenclature,
          }
        }
      )
      setOptions(banks)
      setValue('check_date', moment().format('YYYY-MM-DD'))
      handleChangeDate(moment().format('YYYY-MM-DD'))
    }
  }, [loadingBank, dataBank, errorBank])

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const amountTotal = () => {
    let total = 0.0
    for (const amount of billArray) {
      // console.log('Monto', amount.amount_bill)
      total += parseFloat(amount.amount_bill)
      // }
      // console.log(total.toFixed(2))
    }
    setTotalSum(total.toFixed(2))
  }

  const handleChangeDate = (date) => {
    // console.log(date)
    const dateFormated = moment(date, 'YYYY-MM-DD').format('MMYY')
    setDateInfo(dateFormated)
  }

  useEffect(() => {
    if (valueSearchNote) {
      // if (handleCheckbox(getValues('nominative'), getValues('carrier'))) return
      // console.log('valueSearchNote', valueSearchNote.length)
      // let valueCheckNumber = getValues('check_number')
      // console.log('valueCheckNumber', valueCheckNumber.length)
      const valueSearchNoteUpper = valueSearchNote.toUpperCase()

      if (valueSearchNoteUpper.includes('ID' || 'ID¡' || 'ID¿' || 'ID=')) {
        // console.log('valueSearchNoteUpper', valueSearchNoteUpper)
        return (
          handleSearchNoteForUUID(valueSearchNoteUpper),
          setValueSearchNote(null)
        )
      }

      handleSearchNote()
      setValueSearchNote(null)
    }
  }, [valueSearchNote])

  useEffect(() => {
    if (infoNote) {
      handleAddNote(infoNote)
      // setInfoNote(null)
    }
  }, [infoNote])

  const handleSearchNoteForUUID = async (uuid) => {
    try {
      setLoadingBtn(true)

      let stringFormat
      let replaceBefore = uuid.replace(/HTTPS:\/\//, '')
      if (replaceBefore.includes('/') || replaceBefore.includes('&')) {
        stringFormat = replaceBefore.replace(/HTTPSÑ/, '')
      } else {
        stringFormat = replaceBefore
          .replace(/HTTPSÑ/, '')
          .replace(/RE(¡|¿|=|&|)/, '/RE¿')
          .replace(/RR(¡|¿|=|&|)/, '/RR¿')
          .replace(/ID(¡|¿|=|&|)/, '/ID¿')
          .replace(/TT(¡|¿|=|&|)/, '/TT¿')
      }

      // console.log('stringFormat', stringFormat)

      const uuidExec = regexID.exec(stringFormat)
      let uuidForm = uuidExec[0].replace(/ID(¡|¿|=|)/, '').replace(/('|-)/g, '')
      let includesCharacters = uuidForm.includes('_')
      if (includesCharacters) {
        const splitUUID = uuidForm.split('_')
        console.log('splitUUID', splitUUID)
        uuidForm = splitUUID[0]
      }
      console.log('uuidForm', uuidForm)
      const { data: dataOneNote, error: errorOneNote } =
        await getOneNoteByUUIDForBill({
          variables: {
            uuid: uuidForm,
            notesForContability: false,
          },
          fetchPolicy: 'no-cache',
        })

      if (errorOneNote) {
        return toastSweetAlert(
          {
            mode: 'error',
            message: errorOneNote.message,
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      }

      if (dataOneNote?.getOneNoteByUUIDForBill?.full_payed)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'Este contrarecibo no se puede agregar a la lista porque ya se encuentra pagado',
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )

      if (dataOneNote?.getOneNoteByUUIDForBill?.status_note >= 4)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'Este contrarecibo no se puede agregar a la lista porque ya se encuentra pagado o en proceso de pago',
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )

      // if (idStoreRegister !== null) {
      //   if (
      //     dataOneNote?.getOneNoteByUUIDForBill?.id_store_register !==
      //     idStoreRegister
      //   ) {
      //     return toastSweetAlert(
      //       {
      //         mode: 'error',
      //         message:
      //           'Este contrarecibo no se puede agregar a la lista porque no pertenece a la misma tienda',
      //       },
      //       setLoadingBtn(false),
      //       setValueSearchNote(null),
      //       setInfoNote(null),
      //       setValue('invoice_countercharges', '')
      //     )
      //   }
      // }

      // console.log(dataOneNote?.getOneNoteByUUIDForBill)

      setInfoNote(dataOneNote?.getOneNoteByUUIDForBill)
      // setIdStoreRegister(
      //   dataOneNote?.getOneNoteByUUIDForBill?.id_store_register
      // )
      // setIdStore(dataOneNote?.getOneNoteByUUIDForBill?.id_store_register)
      // setValue(
      //   'id_store_payment',
      //   dataOneNote?.getOneNoteByUUIDForBill?.id_store_register?.toString()
      // )
      setLoadingBtn(false)
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Error al leer el código escaneado',
        },
        setValueSearchNote(null),
        setInfoNote(null),
        setValue('invoice_countercharges', '')
      )
    }
  }

  const handleSearchNote = async () => {
    try {
      if (valueSearchNote === null || valueSearchNote === '') return
      setLoadingBtn(true)
      const { data: dataOneNote, error: errorOneNote } =
        await getOneNoteByConsecutive({
          variables: {
            consecutiveNumber: valueSearchNote,
            notesForContability: false,
          },
          fetchPolicy: 'no-cache',
        })

      if (errorOneNote) {
        return toastSweetAlert(
          {
            mode: 'error',
            message: errorOneNote.message,
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      }

      if (dataOneNote?.getOneNoteByConsecutiveNumber?.full_payed)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'Este contrarecibo no se puede agregar a la lista porque ya se encuentra pagado',
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      if (dataOneNote?.getOneNoteByConsecutiveNumber?.status_note >= 4)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'Este contrarecibo no se puede agregar a la lista porque ya se encuentra pagado o en proceso de pago',
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      // if (idStoreRegister !== null) {
      //   if (
      //     dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register !==
      //     idStoreRegister
      //   ) {
      //     return toastSweetAlert(
      //       {
      //         mode: 'error',
      //         message:
      //           'Este contrarecibo no se puede agregar a la lista porque no pertenece a la misma tienda',
      //       },
      //       setLoadingBtn(false),
      //       setValueSearchNote(null),
      //       setInfoNote(null),
      //       setValue('invoice_countercharges', '')
      //     )
      //   }
      // }

      // console.log(dataOneNote?.getOneNoteByConsecutiveNumber)

      setInfoNote(dataOneNote?.getOneNoteByConsecutiveNumber)
      // setIdStoreRegister(
      //   dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register
      // )
      // setIdStore(dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register)
      // setValue(
      //   'id_store_payment',
      //   dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register?.toString()
      // )
      setLoadingBtn(false)
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Error al buscar el contrarecibo',
        },
        setValueSearchNote(null),
        setInfoNote(null),
        setValue('invoice_countercharges', '')
      )
    }
  }

  const handleAddNote = (data) => {
    // console.log(data)
    if (!data) return
    const amountToPay = parseFloat(data.amount_note) - parseFloat(data.pay_note)
    // console.log('dataTable INICIO', dataTable)
    // console.log('dataBack INICIO', dataBack)
    const listTable = dataTable.map((item) => {
      return {
        id: item.id,
        consecutivo: item.consecutivo,
        importe: item.importe,
        retención: item.retención,
        proveedor: item.proveedor,
      }
    })

    const listBack = dataBack.map((item) => {
      return {
        id: item.id,
        consecutive: item.consecutive,
        amount: item.amount,
        retention: item.retention,
        rfc: item.rfc,
      }
    })

    const findNote = listBack.find((item) => item.id === data?.id)
    // console.log('findNote', findNote)
    if (findNote) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Este contrarecibo ya se encuentra agregado en la tabla',
        },
        setValueSearchNote(null),
        setInfoNote(null),
        setValue('invoice_countercharges', '')
      )
    }

    let sumAmount = sumTotalAmount(data.amount_note)

    listBack.push({
      id: data?.id,
      consecutive: data?.consecutive_number,
      amount: data?.amount_note,
      retention: data?.retention_note,
      rfc: data?.partnerNotes?.provider_rfc
        ? data?.partnerNotes?.provider_rfc
        : 'XAXX010101000',
    })

    listTable.push({
      id: data?.id,
      consecutivo: data?.consecutive_number,
      importe: formmatterCurrency(data?.amount_note),
      retención: formmatterCurrency(data?.retention_note),
      proveedor: data?.partnerNotes?.provider_name
        ? data?.partnerNotes?.provider_name
        : data?.bills[0]?.provider_name_nm,
    })

    setTotalSum(sumAmount)
    setDataBack(listBack)
    setDataTable(listTable)
    setValue('invoice_countercharges', null)
    // handleNominative(listBack)
    // setValueProviderNameNm(data?.bills[0]?.provider_name_nm)
    // console.log('dataBack FINAL', dataBack)
    // console.log('dataTable FINAL', dataTable)
  }

  const sumTotalAmount = (amount) => {
    let sum = 0.0
    sum = totalSum + parseFloat(amount)
    return sum
  }

  const handleDeleteNote = async (id) => {
    try {
      setLoadingBtn(true)
      // console.log('flag Inicio', flag)
      const findNoteTable = dataTable.find((item) => item.id === id)
      const findNoteBack = dataBack.find((item) => item.id === id)
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        title: '¿Estas seguro de eliminar este contrarecibo?',
        message: `No podrás revertir esta acción`,
      })

      if (result.isConfirmed) {
        const newData = dataTable.filter((item) => item.id !== id)
        const newDataBack = dataBack.filter((item) => item.id !== id)
        const substractionAmount =
          parseFloat(totalSum) - parseFloat(findNoteBack.amount)
        // console.log(newDataBack)
        // handleNominative(newDataBack)
        setTotalSum(substractionAmount)
        setDataBack(newDataBack)
        setDataTable(newData)
        setLoadingBtn(false)
        toastSweetAlert({
          mode: 'ok',
          message: 'Contrarecibo eliminado correctamente',
        })
      }
      setLoadingBtn(false)
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  // const handleNominative = (data, ignore) => {
  //   // let valueNominative = e.target.checked
  //   console.log(data)
  //   console.log(ignore)
  //   let partnerCount = 0

  //   console.log('partnerCount', partnerCount)
  //   // if(data.length === 0) return setFlag(3)
  //   // if (e) {
  //   let partnerBefore = data.map((item) => item.rfc)[0]
  //   for (let dataNote of data) {
  //     console.log('partnerBefore', partnerBefore)
  //     let partnerAcual = dataNote.rfc
  //     console.log('partnerAcual', partnerAcual)
  //     if (partnerBefore !== partnerAcual) {
  //       // if (
  //       //   partnerAcual === 'XAXX010101000' ||
  //       //   partnerBefore === 'XAXX010101000'
  //       // ) {
  //       partnerCount++
  //       // }
  //     }
  //     partnerBefore = partnerAcual
  //     console.log('partnerCount', partnerCount)
  //   }
  //   // }

  //   if (partnerCount === 0) {
  //     setValue('carrier', false)
  //     setValueCarrier(false)
  //     setValue('nominative', true)
  //     setValueNominative(true)
  //   }
  //   // if (!ignore) {
  //   if (partnerCount > 0) {
  //     // setFlag(2)
  //     setValue('nominative', false)
  //     setValueNominative(false)
  //     setValue('carrier', true)
  //     setValueCarrier(true)
  //   }
  // }

  // const assignIdStoreAndName = (e) => {
  //   console.log('Store', e)
  //   let id = e?.value
  //   let name = e?.label
  //   setIdStore(id)
  //   setNameStore(name)
  // }

  // useEffect(() => {
  //   if (valueStore) {
  //     assignIdStoreAndName(valueStore)
  //   }
  // }, [valueStore])

  // const assignIdStoreAndNameStore = (id) => {
  //   // console.log('Store', id)
  //   const findStore = dataStores?.getAllStores?.rows?.find(
  //     (item) => item.id === id
  //   )
  //   console.log('findStore', findStore)
  //   setNameStore(findStore?.name)
  //   setIdStore(findStore?.id)
  // }

  // useEffect(() => {
  //   if (user.store) {
  //     assignIdStoreAndNameStore(user.store)
  //   }
  // }, [infoBills, dataStores])

  const onSubmit = async (Data) => {
    try {
      setLoadingBtn(true)
      // console.log('Data', Data)
      // console.log('valueNominative', valueNominative)
      if (dataBack.length === 0)
        return toastSweetAlert(
          {
            mode: 'error',
            message: 'Debes agregar al menos un contrarecibo',
          },
          setLoadingBtn(false)
        )

      if (totalSum <= 0)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'El total de los contrarecibos no puede ser menor o igual a 0. Agregue un contrarecibo con un monto mayor al total de los contrarecibos',
          },
          setLoadingBtn(false)
        )

      const noteData = dataBack.map((item) => {
        return {
          id: item.id,
          pay_note: parseFloat(item.amount),
        }
      })

      await generatePaymentsFinancesForContability({
        variables: {
          noteInput: noteData,
          inputPayments: {
            id_bank: parseInt(Data?.bank_origin),
            id_bank_destination: 0,
            consecutive_number_of_payments: `${
              infoBank?.bank_nomenclature
            } ${dateInfo} ${
              parseFloat(infoBank?.bank_invoice_number) +
              parseFloat(valueConsecutive)
            }`,
            payment_amount: totalSum?.toString(),
            payment_date: moment(Data?.check_date).format('YYYY-MM-DD'),
            nomenclature_date: dateInfo,
            nomenclature_bank: infoBank?.bank_nomenclature,
            consecutive_number: `${
              parseFloat(infoBank?.bank_invoice_number) +
              parseFloat(valueConsecutive)
            }`,
            comments: Data?.reference,
          },
        },
      })

      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: 'El pago se ha realizado con éxito',
        },
        history.push('/generatenewpayment/bankpaymentscontability')
        // setInvoiceNumberPayment(
        //   `${infoStore?.invoice_payments}${valueCountReference.toString()}`
        // ),
        // setFlag(2)
        // document.getElementById('handleNextStep').click()
      )
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        title="Generar pago contabilidad"
        windowTitle="Generar pago contabilidad"
        breadcrumb="Generar pago contabilidad"
      />
      <FormProvider {...methods}>
        <form
          className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            // title="Pagos con contabilidad"
            btnLoading={loadingBtn}
            errors={errors}
            // btnSubmit={true}
            // btnSubmitText="Generar Pago"
            // btnSecondFunction={handlePreviousStep}
            btnSubmitNoEnter={handleSubmit(onSubmit)}
            btnSubmitNoEnterTxtSave="Generar Pago"
            content={
              <>
                <div className="row">
                  <div
                    className={`${
                      parseInt(idRole) === parseInt(user.role)
                        ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                        : 'col-12 col-sm-4 col-md-4 col-lg-4'
                    }`}
                  >
                    <InputController
                      label="Folio contrarecibo o factura"
                      name="invoice_countercharges"
                      control={control}
                      // blurAction={(e) => setValueSearchNote(e.target.value)}
                      // changeAction = {({ target: {value} }) =>{
                      //   setValueSearchNote(value)
                      //   console.log('value', value)
                      // }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          // console.log('event', event)
                          setValueSearchNote(event.target.value)
                        }
                      }}
                    />
                  </div>
                  <div
                    className={`${
                      parseInt(idRole) === parseInt(user.role)
                        ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                        : 'col-12 col-sm-4 col-md-4 col-lg-4'
                    }`}
                    style={{
                      zIndex: '2',
                    }}
                  >
                    <InputController
                      label="Bancos"
                      name="bank_origin"
                      control={control}
                      inputType="choosen"
                      options={options}
                      changeAction={(e) => setValueIdBank(e.value)}
                      limitCharacters={4}
                      debounceTime={0}
                      // onKeyDown={(event) => {
                      //   handleLimitCharacters(event.target.value, 4)
                      // }}
                    />
                  </div>
                  {/* <div
                    className={`${
                      parseInt(idRole) === parseInt(user.role)
                        ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                        : 'col-12 col-sm-4 col-md-4 col-lg-4'
                    }`}
                    style={{
                      zIndex: '2',
                    }}
                  >
                    <InputController
                      label="Banco destino"
                      name="bank_destination"
                      inputType="choosen"
                      control={control}
                      options={options}
                      // changeAction={(e) => setCheckNumber(e.target.value)}
                      limitCharacters={4}
                      debounceTime={0}
                      // onKeyDown={(event) => {
                      //   handleLimitCharacters(event.target.value, 4)
                      // }}
                    />
                  </div> */}
                  <div
                    className={`${
                      parseInt(idRole) === parseInt(user.role)
                        ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                        : 'col-12 col-sm-4 col-md-4 col-lg-4'
                    }`}
                  >
                    <InputController
                      label="Fecha"
                      name="check_date"
                      type="date"
                      // disabled
                      control={control}
                      changeAction={(e) => handleChangeDate(e.target.value)}
                    />
                  </div>
                  <div
                    className={`${
                      parseInt(idRole) === parseInt(user.role)
                        ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                        : 'col-12 col-sm-4 col-md-4 col-lg-4'
                    }`}
                  >
                    <InputController
                      label="Referencia"
                      name="reference"
                      inputType="textarea"
                      control={control}
                    />
                  </div>
                </div>
                <br />
                <LayoutTable
                  data={dataTable}
                  hideId
                  withCard={false}
                  headersButtons={false}
                  hideFilterBar={true}
                  titleActions="Borrar"
                  hideBtnEdit
                  hideDetails
                  paginationServer={false}
                  actionBtnErase={(e) => handleDeleteNote(e)}
                />
                <br />

                <div className="col-12 d-flex flex-column justify-content around">
                  <div className="row">
                    <div className="col-lg-8">
                      <OrderCol
                        title="Referencia:"
                        content={
                          infoBank
                            ? `${`${infoBank?.bank_nomenclature} ${dateInfo} ${
                                parseFloat(infoBank?.bank_invoice_number) +
                                parseFloat(valueConsecutive)
                              }`}`
                            : '---'
                        }
                        mediumColSize="8"
                      />
                    </div>

                    <div className="col-lg-4">
                      <OrderCol
                        title="Total:"
                        content={formmatterCurrency(totalSum)}
                        mediumColSize="4"
                      />
                    </div>
                  </div>

                  {/* <div className="d-flex flex-row-reverse">
                    <OrderCol
                      title="Folio Pago:"
                      content={
                        infoStore
                          ? `${infoStore?.invoice_payments}.${valueCountReference}`
                          : '---'
                      }
                      mediumColSize="4"
                    />
                  </div> */}
                </div>
              </>
            }
          />
          {/* <button
              id="handleNextStep"
              onClick={handleNextStep}
              className="invisible"
              // setInvoiceNumberPayment={`${
              //   infoStore?.invoice_payments
              // }${valueCountReference?.toString()}`}
            ></button> */}
        </form>
      </FormProvider>
    </>
  )
}

export default BankPaymentsContability
