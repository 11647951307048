import { gql } from '@apollo/client'

export const GET_COUNT_NOTES_FOR_CONSECUTIVE = gql`
  query Query($storeId: Int, $isManualNote: Boolean) {
    getCountForConsecutiveNumber(storeId: $storeId, isManualNote: $isManualNote)
  }
`

export const GET_INFO_PARTNER_FOR_CREATE_NOTE = gql`
  query GetInfoPartnerForCreateNote($rfcSearch: String!) {
    getInfoPartnerForCreateNote(rfcSearch: $rfcSearch) {
      id
      provider_name
      provider_rfc
      percentage_retention
      second_ticket
      retention_global
      retentionGlobalToBusinessRetention {
        retention_global
      }
    }
  }
`

export const GET_NOTES = gql`
  query GetNotes(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $date: Int
    $dateFrom: String
    $dateTo: String
    $fullPayed: Boolean
    $withOutPay: Boolean
    $shopRegister: Int
    $statusNote: Int
    $idProvider: Int
    $notesForContability: Boolean
    $notesForFinances: Boolean
    $searchForChecks: Boolean
  ) {
    getNotes(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      date: $date
      dateFrom: $dateFrom
      dateTo: $dateTo
      fullPayed: $fullPayed
      withOutPay: $withOutPay
      shopRegister: $shopRegister
      statusNote: $statusNote
      idProvider: $idProvider
      notesForContability: $notesForContability
      notesForFinances: $notesForFinances
      searchForChecks: $searchForChecks
    ) {
      rows {
        providerNameNm
        id
        consecutive_number
        amount_note
        date_note
        pay_note
        retention_note
        full_payed
        id_partner
        status_note
        date_stamp
        date_programmed_to_pay
        amount_programmed_to_pay_note
        partnerNotes {
          provider_name
          provider_rfc
        }
        bills {
          id
          date_bill
          amount_bill
          provider_name_nm
          invoice_bill
          retail
          description
        }
        notesToBills {
          id_note
        }
        storesRegister {
          alias
        }
      }
      count
    }
  }
`

export const GET_ALL_PARTNERS_NOTES = gql`
  query GetAllPartnersNotes($searchQuery: String, $limit: Int, $offset: Int) {
    getAllPartnersNotes(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        provider_name
        provider_rfc
      }
    }
  }
`
export const GET_ONE_NOTE = gql`
  query GetOneNote($getOneNoteId: Int!) {
    getOneNote(id: $getOneNoteId) {
      id
      date_note
      consecutive_number
      id_store_register
      amount_note
      retention_note
      pay_note
      full_payed
      date_programmed_to_pay
      date_stamp
      status_note
      amount_programmed_to_pay_note
      id_partner
      bills {
        id
        date_bill
        amount_bill
        invoice_bill
        description
        retail
        provider_name_nm
        pay_bill
        id_partner
        full_payed
        conceptBills {
          id
          name
        }
      }
      partnerNotes {
        provider_rfc
        provider_name
        second_ticket
      }
      storesRegister {
        alias
      }
      payNoteChecks {
        id
        check_number
        reference_pay
        invoice_number_payments
        date_check
        date_payment
        amount_check_payment
        is_active
        storesPayment {
          alias
        }
      }
      userRegisterNotes {
        name
      }
      userUpdateNotes {
        name
      }
      notesToGeneratePaymentsFinances {
        consecutive_number_of_payments
        payment_amount
        payment_date
        is_active
        paymentsFinancesToBanks {
          bank_name
        }
        paymentsFinancesToNotes {
          consecutive_number
        }
      }
    }
  }
`

export const GET_ALL_NOTES_BY_ID_PARTNER = gql`
  query GetAllNotesByIdPartner(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $idPartner: Int!
  ) {
    getAllNotesByIdPartner(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      idPartner: $idPartner
    ) {
      rows {
        id
        date_note
        amount_note
        pay_note
      }
      count
    }
  }
`

export const VALIDATE_BILL = gql`
  query ValidateBill($idCaptured: String!) {
    validateBill(idCaptured: $idCaptured) {
      id
      id_bill
    }
  }
`
export const VALIDATE_INVOICE = gql`
  query ValidateInvoice($invoiceCaptured: String!, $idPartner: Int!) {
    validateInvoice(invoiceCaptured: $invoiceCaptured, idPartner: $idPartner) {
      id
    }
  }
`

export const GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER = gql`
  query GetOneNoteByConsecutiveNumber(
    $consecutiveNumber: String!
    $notesForContability: Boolean
  ) {
    getOneNoteByConsecutiveNumber(
      consecutiveNumber: $consecutiveNumber
      notesForContability: $notesForContability
    ) {
      id
      consecutive_number
      date_note
      full_payed
      pay_note
      retention_note
      amount_note
      pay_note
      status_note
      id_store_register
      partnerNotes {
        provider_name
        provider_rfc
      }
      bills {
        provider_name_nm
      }
    }
  }
`

export const GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER_FOR_PRINT = gql`
  query GetOneNoteBysConsecutiveNumberForPrint($consecutiveNumber: String!) {
    getOneNoteBysConsecutiveNumberForPrint(
      consecutiveNumber: $consecutiveNumber
    ) {
      id
      consecutive_number
      amount_note
      date_note
      pay_note
      retention_note
      full_payed
      partnerNotes {
        provider_name
        provider_rfc
        second_ticket
      }
      bills {
        id
        date_bill
        amount_bill
        invoice_bill
        provider_name_nm
        retail
        description
      }
    }
  }
`

export const GET_ONE_NOTE_BY_UUID_FOR_BILL = gql`
  query GetOneNoteByUUIDForBill($uuid: String!, $notesForContability: Boolean) {
    getOneNoteByUUIDForBill(
      uuid: $uuid
      notesForContability: $notesForContability
    ) {
      id
      consecutive_number
      date_note
      full_payed
      pay_note
      retention_note
      amount_note
      pay_note
      id_store_register
      status_note
      partnerNotes {
        provider_name
        provider_rfc
      }
      bills {
        provider_name_nm
      }
    }
  }
`

export const CREATE_NOTE = gql`
  mutation CreateNote($noteInput: noteInput!, $billInput: [billInput]!) {
    createNote(noteInput: $noteInput, billInput: $billInput) {
      consecutive_number
      date_note
      amount_note
      retention_note
      pay_note
      id_partner
      note_for_contability
      note_for_finances
    }
  }
`

export const UPDATE_NOTES_AMOUNT = gql`
  mutation UpdateNotesAmount(
    $noteInput: [noteInputUpdate!]!
    $totalPayed: Float!
    $dateCheck: String!
    $invoiceNumberPayments: String!
    $numberCheck: String!
    $referencePay: String!
    $idStorePayment: Int!
    $payNominative: Boolean!
  ) {
    updateNotesAmount(
      noteInput: $noteInput
      totalPayed: $totalPayed
      dateCheck: $dateCheck
      invoiceNumberPayments: $invoiceNumberPayments
      numberCheck: $numberCheck
      referencePay: $referencePay
      idStorePayment: $idStorePayment
      payNominative: $payNominative
    )
  }
`

export const DELETE_NOTES = gql`
  mutation DeleteNoteById($id: Int!) {
    deleteNoteById(id: $id)
  }
`

export const IMPORT_PAYMENTS = gql`
  mutation PaymentsNotesImport($files: [Upload!]!) {
    PaymentsNotesImport(files: $files)
  }
`

export const EXPORT_ALL_NOTES = gql`
  mutation GetAllNotesExport(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $dateFrom: String
    $dateTo: String
    $fullPayed: Boolean
    $withOutPay: Boolean
    $shopRegister: Int
    $statusNote: Int
    $idProvider: Int
    $notesForContability: Boolean
    $notesForFinances: Boolean
    $searchForChecks: Boolean
    $isFinances: Boolean
  ) {
    getAllNotesExport(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      dateFrom: $dateFrom
      dateTo: $dateTo
      fullPayed: $fullPayed
      withOutPay: $withOutPay
      shopRegister: $shopRegister
      statusNote: $statusNote
      idProvider: $idProvider
      notesForContability: $notesForContability
      notesForFinances: $notesForFinances
      searchForChecks: $searchForChecks
      isFinances: $isFinances
    ) {
      consecutive_number
      status_note
      date_note
      date_stamp
      date_programmed_to_pay
      amount_note
      amount_programmed_to_pay_note
      userRegisterNotes {
        name
      }
      userUpdateNotes {
        name
      }
      partnerNotes {
        provider_name
        provider_rfc
      }
      bills {
        id_bill
        invoice_bill
        date_bill
        amount_bill
        description
        retail
        provider_name_nm
        billsPayments {
          is_active
          paymentsBillsToChecks {
            reference_pay
          }
        }
        billsToBillsPaymentsFinances {
          is_active
          billsPaymentsToPaymentsFinances {
            consecutive_number
            nomenclature_bank
            nomenclature_date
            payment_date
            payment_amount
            consecutive_number_of_payments
            is_active
          }
        }
      }
      payNoteChecks {
        reference_pay
      }
    }
  }
`

export const UPDATE_STATUS_RECEIVED_NOTE = gql`
  mutation UpdateStatusReceivedNote(
    $updateInputStatusReceivedNotes: [UpdateInputStatusReceivedNotes]!
  ) {
    updateStatusReceivedNote(
      UpdateInputStatusReceivedNotes: $updateInputStatusReceivedNotes
    )
  }
`

export const UPDATE_STATUS_PROGRAMMED_NOTE = gql`
  mutation UpdateStatusProgrammedNote(
    $updateInputStatusProgrammedNotes: [UpdateInputStatusProgrammedNotes]!
  ) {
    updateStatusProgrammedNote(
      UpdateInputStatusProgrammedNotes: $updateInputStatusProgrammedNotes
    )
  }
`

export const UPDATE_STATUS_PAYED_NOTE = gql`
  mutation UpdateStatusNotes(
    $updateStatusNote: [UpdateInputStatusReceivedNotes]!
  ) {
    updateStatusNotes(UpdateStatusNote: $updateStatusNote)
  }
`

export const MOVE_NOTE_FINANCES_TO_CONTABILITY = gql`
  mutation MoveNoteFinancesToContability($idNote: Int!) {
    moveNoteFinancesToContability(idNote: $idNote)
  }
`

export const MOVE_NOTE_CONTABILITY_TO_FINANCES = gql`
  mutation MoveNoteContabilityToFinances($idNote: Int!) {
    moveNoteContabilityToFinances(idNote: $idNote)
  }
`

export const GET_NOTES_RANGE_BY_ID = gql`
  mutation GetNotesForRangeById($idNote: [Int!]) {
    getNotesForRangeById(idNote: $idNote) {
      amount_note
      id
      consecutive_number
      amount_note
      amount_programmed_to_pay_note
      status_note
      full_payed

      notesToGeneratePaymentsFinances {
        consecutive_number_of_payments
        payment_amount
        payment_date
        is_active
        paymentsFinancesToBanks {
          bank_name
        }
        paymentsFinancesToNotes {
          consecutive_number
        }
      }
    }
  }
`
