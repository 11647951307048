import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  DELETE_RETENTION_PARTNER,
  EXPORT_RETENTION_PARTNERS,
  GET_ALL_RETENTION_PARTNERS,
  IMPORT_RETENTION_PARTNERS,
} from '../../../../../graphql/Catalog/Notes/BusinessRetention/businessretention'
import LayoutTable from '../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'
import RetentionGlobalCounterCharges from './RetentionGlobal/RetentionGlobalCounterCharges'

export const BusinessRetention = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [flag, setFlag] = useState(1)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_RETENTION_PARTNERS,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const handleNextStep = () => {
    setFlag(flag + 1)
  }

  const handlePrevStep = () => {
    setFlag(flag - 1)
  }

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []

      data.getAllRetentionPartners.rows.map((item) => {
        return list.push({
          id: item.id,
          Nombre: item.provider_name,
          RFC: item.provider_rfc,
          Retención: item?.retention_global
            ? item?.retentionGlobalToBusinessRetention?.retention_global
            : item.percentage_retention,
          'Segundo Ticket': item.second_ticket ? 'Si' : 'No',
        })
      })
      setDataToTable(list)
      setTotal(data.getAllRetentionPartners.count)
    }
  }, [loading, error, data])

  return (
    <>
      {flag === 1 && (
        <>
          {/* <ContentHeader
            title="Control de retención de proveedores"
            windowTitle="Retención proveedores"
            breadcrumb="Retención proveedores"
          /> */}
          <LayoutTable
            title="Control de retención de proveedores"
            hideId={true}
            gql={DELETE_RETENTION_PARTNER}
            refetchFunctionQueries={async () => await refetch()}
            desactiveBOM={false}
            templateDataImport={[
              {
                provider_name: '',
                provider_rfc: '',
                percentage_retention: '',
                second_ticket: '',
              },
            ]}
            // requery={GET_ALL_RETENTION_PARTNERS}
            data={dataToTable}
            totalCount={total}
            loadingData={loading}
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}
            FilterBarPlaceholder="Buscar por Nombre proveedor, código o RFC"
            exportQuery={EXPORT_RETENTION_PARTNERS}
            importQuery={IMPORT_RETENTION_PARTNERS}
            labelExportCSV="Descargar Proveedores"
            labelImportCSV="Cargar Proveedores"
          />
        </>
      )}
      {flag === 2 && (
        <RetentionGlobalCounterCharges handlePrevStep={handlePrevStep} />
      )}
    </>
  )
}

export default BusinessRetention
