import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import moment from 'moment'
import LiveCycleCountsSkus from './Components/LiveCycleCountsSkus'
import LiveCycleCountsUbications from './Components/LiveCycleCountsUbications'
import LiveTFI from './Components/LiveTFI'
import { GET_INVENTORY_ACTIVITY_ASSIGMENT_BY_ID } from '../../../graphql/InventoryActivities/InventoryActivitiesAssignments'

const InventoryActivitiesLiveNew = () => {
  const { id } = useParams()

  const [dataAssignment, setDataAssignment] = useState({
    almacen: null,
    fecha: null,
  })

  /* USEQUERY & USEMUTATION */
  const assigment = useQuery(GET_INVENTORY_ACTIVITY_ASSIGMENT_BY_ID, {
    variables: {
      id: parseInt(id) > 0 ? parseInt(id) : 0,
    },
  })

  useEffect(() => {
    if (assigment?.loading) return
    if (assigment.error) {
      return toastSweetAlert({
        mode: 'error',
        message: assigment.error.message,
      })
    }
    const data = assigment.data.getInventoryActivityAssigmentById

    setDataAssignment({
      almacen: data?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
      fecha: moment(data.start_date).format('lll'),
      ...data,
    })
  }, [assigment?.loading, assigment?.data, assigment?.error, id])

  return dataAssignment.type_schedule === 2 ? (
    <LiveTFI dataAssignment={dataAssignment} />
  ) : dataAssignment.ubications_or_skus === 1 ? (
    <LiveCycleCountsUbications dataAssignment={dataAssignment} />
  ) : (
    <LiveCycleCountsSkus dataAssignment={dataAssignment} />
  )
}

export default InventoryActivitiesLiveNew
