import ContentHeader from '../Layout/ContentHeader'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALL_COUNTED_REPORT_TRANSACTIONS } from '../../graphql/HangFire/ReportTransactions'
import SucceededRequest from './StateRequest'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'

const TransactionReport = () => {
  const [failedRequestsCount, setFailedRequestsCount] = useState(0)
  const [deletedRequestsCount, setDeletedRequestsCount] = useState(0)
  const [scheduledRequestsCount, setScheduledRequestsCount] = useState(0)
  const [succeedRequestsCount, setSucceedRequestsCount] = useState(0)
  const [processingRequestsCount, setProcessingRequestsCount] = useState(0)
  const [enqueuedRequestsCount, setEnqueuedRequestsCount] = useState(0)
  const [title, setTitle] = useState('En Cola')

  const { data, loading, error } = useQuery(GET_ALL_COUNTED_REPORT_TRANSACTIONS)
  const [pagePagination, setPagePagination] = useState({
    limit: 100,
    offset: 0,
  })
  //COUNTER REQUEST
  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      try {
        setFailedRequestsCount(
          data?.getAllCountedReportTransactions?.countFailed
        )
        setDeletedRequestsCount(
          data?.getAllCountedReportTransactions?.countDeleted
        )
        setScheduledRequestsCount(
          data?.getAllCountedReportTransactions?.countScheduled
        )
        setSucceedRequestsCount(
          data?.getAllCountedReportTransactions?.countSucceeded
        )
        setProcessingRequestsCount(
          data?.getAllCountedReportTransactions?.countProcessing
        )
        setEnqueuedRequestsCount(
          data?.getAllCountedReportTransactions?.countEnqueued
        )
      } catch (error) {
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      }
    }
  }, [data, error, loading])

  const getAllFailed = async () => {
    setTitle('Con Errores')
  }

  const getAllDeleted = async () => {
    setTitle('Eliminando')
  }

  const getAllCompleted = async () => {
    setTitle('Completadas')
  }
  const getAllProccessing = async () => {
    setTitle('Procesando')
  }
  const getAllSchedule = async () => {
    setTitle('Programadas')
  }
  const getAllEnQueued = async () => {
    setTitle('En Cola')
  }

  return (
    <>
      <ContentHeader
        title="Reporte de Transacciones"
        breadcrumb="Reporte de Transacciones"
        windowTitle={`Reporte de Transacciones`}
      />

      <div className="row">
        <div className="col-md-3 col-lg-2 col-sm-12">
          <ul className="list-group list-group-numbered menu-hangfire-container listNoWhite">
            <li
              className={`list-group-item d-flex justify-content-between align-items-start pointer menu-hangfire ${title == 'En Cola' && 'menu-active-hangfire'
                } `}
              onClick={getAllEnQueued}
            >
              <div className=" ">
                <div className="fw-bold">En cola</div>
              </div>
              <span className="badge badge-color right ">
                {enqueuedRequestsCount}
              </span>
            </li>

            <li
              className={`list-group-item d-flex justify-content-between align-items-start pointer menu-hangfire ${title == 'Programadas' && 'menu-active-hangfire'
                } `}
              onClick={getAllSchedule}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Programadas</div>
              </div>
              <span className="badge badge-color right">
                {scheduledRequestsCount}
              </span>
            </li>

            <li
              className={`list-group-item d-flex justify-content-between align-items-start pointer menu-hangfire ${title == 'Procesando' && 'menu-active-hangfire'
                } `}
              onClick={getAllProccessing}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Procesando</div>
              </div>
              <span className="badge badge-color right">
                {processingRequestsCount}
              </span>
            </li>

            <li
              className={`list-group-item d-flex justify-content-between align-items-start pointer menu-hangfire ${title == 'Completadas' && 'menu-active-hangfire'
                } `}
              onClick={getAllCompleted}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Completadas</div>
              </div>
              <span className="badge badge-color right">
                {succeedRequestsCount}
              </span>
            </li>

            <li
              className={`list-group-item d-flex justify-content-between align-items-start pointer menu-hangfire ${title == 'Con Errores' && 'menu-active-hangfire'
                } `}
              onClick={getAllFailed}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Con Errores</div>
              </div>
              <span className="badge badge-color right">
                {failedRequestsCount}
              </span>
            </li>

            <li
              className={`list-group-item d-flex justify-content-between align-items-start pointer menu-hangfire ${title == 'Eliminando' && 'menu-active-hangfire'
                } `}
              onClick={getAllDeleted}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Eliminando</div>
              </div>
              <span className="badge badge-color right">
                {deletedRequestsCount}
              </span>
            </li>
          </ul>
        </div>

        <div className="col-md-9 col-lg-10 col-sm-12">
          {title == 'En Cola' && <SucceededRequest stateName="EnQueued" setPagePagination= {setPagePagination} pagePagination={pagePagination} />}
          {title == 'Programadas' && <SucceededRequest stateName="Scheduled" setPagePagination= {setPagePagination} pagePagination={pagePagination} />}
          {title == 'Procesando' && <SucceededRequest stateName="Processing" setPagePagination= {setPagePagination} pagePagination={pagePagination}  />}
          {title == 'Completadas' && <SucceededRequest stateName="Succeeded" setPagePagination= {setPagePagination} pagePagination={pagePagination} />}
          {title == 'Con Errores' && <SucceededRequest stateName="Failed" setPagePagination= {setPagePagination} pagePagination={pagePagination}  />}
          {title == 'Eliminando' && <SucceededRequest stateName="Deleted" setPagePagination= {setPagePagination} pagePagination={pagePagination} />}
        </div>
      </div>
    </>
  )
}

export default TransactionReport
