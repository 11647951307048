import { useEffect, useState } from 'react'
import Box from '../../../../Global/Box'
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputController from '../../../../Global/InputController'
import OrderCol from '../../../../Global/OrderCol'
import {
  GET_ALL_BANKS_FINANCES_COUNTER_CHARGES,
  GET_ONE_BANK_FINANCES_COUNTER_CHARGE,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/banksfinancescountercharges'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import { GET_COUNT_PAYMENTS_FINANCES_COUNTER_CHARGES } from '../../../../../graphql/Catalog/FinancesCounterCharges/generatepaymentsfinancescountercharges'
import {
  CREATE_TRANSFERS_FINANCES_COUNTER_CHARGES,
  GET_ONE_TRANSFERS_FINANCES_COUNTER_CHARGES_BY_ID,
  UPDATE_TRANSFERS_FINANCES_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/transfersfinancescountercharges'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'

const CreateTransfersFinancesCounterCharges = ({
  id,
  refModal,
  refetch,
  setIdTransfer,
  closeModal,
  setCloseModal,
}) => {
  const history = useHistory()
  // const _id = id
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [valueConsecutive, setValueConsecutive] = useState(null)
  const [invoiceTransfer, setInvoiceTransfer] = useState(null)
  const [infoBank, setInfoBank] = useState(null)
  const [dateInfo, setDateInfo] = useState(null)
  const [transferInformation, setTransferInformation] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const [options, setOptions] = useState([])
  const [valueIdBank, setValueIdBank] = useState(null)

  const {
    loading: loadingBank,
    data: dataBank,
    error: errorBank,
  } = useQuery(GET_ALL_BANKS_FINANCES_COUNTER_CHARGES, {
    variables: {},
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingOneBank,
    data: dataOneBank,
    error: errorOneBank,
  } = useQuery(GET_ONE_BANK_FINANCES_COUNTER_CHARGE, {
    variables: {
      getOneBankFinancesCounterChargesId: parseInt(valueIdBank),
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingCountForConsecutive,
    data: dataCountForConsecutive,
    error: errorCountForConsecutive,
  } = useQuery(GET_COUNT_PAYMENTS_FINANCES_COUNTER_CHARGES, {
    variables: {
      idBank: parseInt(valueIdBank),
      nomenclatureDate: dateInfo,
    },
    fetchPolicy: 'no-cache',
  })

  const [getOneTransfersFinancesCounterCharges] = useLazyQuery(
    GET_ONE_TRANSFERS_FINANCES_COUNTER_CHARGES_BY_ID
  )

  const [createTransferFinances] = useMutation(
    CREATE_TRANSFERS_FINANCES_COUNTER_CHARGES
  )

  const [updateTransferFinances] = useMutation(
    UPDATE_TRANSFERS_FINANCES_COUNTER_CHARGES
  )

  const validationSchema = yup.object().shape({
    origin_bank: yup.string().required('Este campo es requerido'),
    destination_bank: yup.string().required('Este campo es requerido'),
    amount_to_pay: yup.string().required('Este campo es requerido'),
    date: yup
      .date()
      .required('Este campo es requerido')
      .typeError('Este campo es requerido'),
    concept: yup.string().required('Este campo es requerido'),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const formattedNumber = (number) => {
    if (number === '') return
    let cleanNumber = number.replace(/[,]/g, '')
    cleanNumber = Number(cleanNumber)
    if (isNaN(cleanNumber)) {
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'Solo se permiten números',
        },
        setValue('amount_to_pay', '')
      )
    }
    let numberFormat = new Intl.NumberFormat('es-MX', {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cleanNumber)
    return numberFormat === '0'
      ? setValue('amount_to_pay', '')
      : setValue('amount_to_pay', numberFormat)
  }
  const formattedNumberForUpdate = (number) => {
    if (number === '') return
    let cleanNumber = number.replace(/[,]/g, '')
    let numberFormat = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cleanNumber)
    return numberFormat
  }

  useEffect(() => {
    if (!loadingBank) {
      if (errorBank)
        return toastSweetAlert({
          mode: 'error',
          message: errorBank?.message,
        })

      const banks = dataBank?.getAllBanksFinancesCounterCharges?.rows?.map(
        (item) => {
          return {
            value: item?.id,
            label: item?.bank_name + ' - ' + item?.bank_nomenclature,
          }
        }
      )
      setOptions(banks)
      setValue('date', moment().format('YYYY-MM-DD'))
      handleChangeDate(moment().format('YYYY-MM-DD'))
    }
  }, [loadingBank, dataBank, errorBank])

  useEffect(() => {
    if (dataOneBank !== null && dataOneBank !== undefined) {
      if (!loadingOneBank) {
        if (errorOneBank)
          return toastSweetAlert({
            mode: 'error',
            message: errorOneBank?.message,
          })

        setInfoBank(dataOneBank?.getOneBankFinancesCounterCharges)
        // setValue('date', moment().format('YYYY-MM-DD'))
        // handleChangeDate(moment().format('YYYY-MM-DD'))
      }
    }
  }, [dataOneBank, loadingOneBank, errorOneBank])

  useEffect(() => {
    if (!loadingCountForConsecutive) {
      if (errorCountForConsecutive)
        return toastSweetAlert({
          mode: 'error',
          message: errorCountForConsecutive?.message,
        })

      const dataCount =
        dataCountForConsecutive?.getCountForConsecutiveNumberForPaymentsFinances
          ?.rows
      let count = 0
      let consecutiveNumber = null
      for (let data of dataCount) {
        if (consecutiveNumber !== data?.consecutive_number_of_payments) {
          count++
        }
        consecutiveNumber = data?.consecutive_number_of_payments
      }
      // setValueConsecutive(
      //   dataCountForConsecutive?.getCountForConsecutiveNumberForPaymentsFinances
      //     ?.count + 1
      // )
      setValueConsecutive(count + 1)
      // setValue('date', moment().format('YYYY-MM-DD'))
      // handleChangeDate(moment().format('YYYY-MM-DD'))
    }
  }, [
    loadingCountForConsecutive,
    dataCountForConsecutive,
    errorCountForConsecutive,
  ])

  useEffect(() => {
    //     const getBills = async () => {
    // const bills = await getBillsById({
    //   variables: { idNote: parseInt(idNote) },
    //   fetchPolicy: 'no-cache',
    // })

    const getTransferById = async () => {
      const getTransfer = await getOneTransfersFinancesCounterCharges({
        variables: {
          getOneTransfersFinancesCounterChargesId: parseInt(id),
        },
        fetchPolicy: 'no-cache',
      })
      const dataOneTransfer =
        getTransfer?.data?.getOneTransfersFinancesCounterCharges

      const dataFormated = {
        // origin_bank: dataOneTransfer?.id_bank,
        // destination_bank: dataOneTransfer?.id_bank_destination,
        amount_to_pay: formattedNumberForUpdate(
          dataOneTransfer?.payment_amount
        ),
        date: moment(dataOneTransfer?.payment_date).format('YYYY-MM-DD'),
        concept: dataOneTransfer?.transfer_concept,
      }

      reset(dataFormated)
      // setIdValue(id)
      setValue('origin_bank', dataOneTransfer?.id_bank.toString())
      setValue(
        'destination_bank',
        dataOneTransfer?.id_bank_destination.toString()
      )
      setInvoiceTransfer(dataOneTransfer?.consecutive_number_of_payments)
      setTransferInformation(dataOneTransfer)
    }
    if (id) {
      getTransferById()
    }
  }, [id])

  useEffect(() => {
    if (closeModal) {
      handleCloseModal()
    }
  }, [closeModal])

  useEffect(() => {
    if (dateValue) {
      handleChangeDate(dateValue)
    }
  }, [dateValue])

  const handleChangeDate = (date) => {
    const dateFormated = moment(date, 'YYYY-MM-DD').format('MMYY')
    if (id) {
      // const handleChangeDate = (date) => {
      let dateCurrent = moment(date, 'YYYY-MM-DD hh:mm:ss a').format('MM')

      let dateDataBase = moment(
        transferInformation?.payment_date,
        'YYYY-MM-DD hh:mm:ss a'
      ).format('MM')

      if (dateCurrent !== dateDataBase) {
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message: `No puedes cambiar la fecha de pago, ya que la fecha seleccionada es distinto a otro mes, no coincide con el mes del pago registrado`,
          },
          setValue('date', transferInformation?.payment_date)
        )
      }
      // }
    }
    setDateInfo(dateFormated)
  }

  const handleCloseModal = (e) => {
    return (
      reset({
        origin_bank: 0,
        destination_bank: 0,
        amount_to_pay: '',
        concept: '',
      }),
      // (document.getElementById('origin_bank').value = 2),
      // (document.getElementById('destination_bank').value = 3),
      setInfoBank(null),
      // setOptions(null),
      setValueIdBank(null),
      setIdTransfer(null),
      // setValue('origin_bank', ''),
      // setValue('destination_bank', ''),
      setCloseModal(false),
      setInvoiceTransfer(null),
      setTransferInformation(null),
      history.push('/financescountercharges/bankingtransfersfinances')
      // refModal.current.click()
    )
  }

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)

      const cleanNumber = data?.amount_to_pay?.replace(/,/g, '')

      if (id) {
        await updateTransferFinances({
          variables: {
            updateTransfersFinancesCounterChargesId: parseInt(id),
            transferAmount: cleanNumber,
            transferConcept: data?.concept,
            transferDate: data?.date,
            transferDestinationBank: parseInt(data?.destination_bank),
          },
        })
        setLoadingBtn(false)
        if (refetch) await refetch()
        return (
          setInfoBank(null),
          setValueIdBank(null),
          setValue('origin_bank', ''),
          setValue('destination_bank', ''),
          setIdTransfer(null),
          setInvoiceTransfer(null),
          setTransferInformation(null),
          reset({
            origin_bank: '',
            destination_bank: '',
            amount_to_pay: '',
            concept: '',
          }),
          refModal.current.click(),
          toastSweetAlertNotifications({
            mode: 'ok',
            message: 'Transferencia actualizada correctamente',
          })
        )
      }

      await createTransferFinances({
        variables: {
          inputTransfers: {
            id_bank: parseInt(data?.origin_bank),
            id_bank_destination: parseInt(data?.destination_bank),
            transfer_amount: cleanNumber,
            transfer_concept: data?.concept,
            transfer_consecutive_number: `${
              infoBank?.bank_nomenclature
            } ${dateInfo} ${
              parseFloat(infoBank?.bank_invoice_number) +
              parseFloat(valueConsecutive)
            }`,
            transfer_date: moment(data?.date).format('YYYY-MM-DD'),
            nomenclature_bank: infoBank?.bank_nomenclature,
            consecutive_number: `${
              parseFloat(infoBank?.bank_invoice_number) +
              parseFloat(valueConsecutive)
            }`,
            nomenclature_date: `${dateInfo}`,
          },
        },
      })
      setLoadingBtn(false)
      if (refetch) await refetch()
      return (
        setInfoBank(null),
        setValueIdBank(null),
        setValue('origin_bank', ''),
        setValue('destination_bank', ''),
        setIdTransfer(null),
        setInvoiceTransfer(null),
        setTransferInformation(null),
        reset({
          origin_bank: '',
          destination_bank: '',
          amount_to_pay: '',
          date: '',
          concept: '',
        }),
        refModal.current.click(),
        toastSweetAlertNotifications(
          {
            mode: 'ok',
            message: 'Transferencia creada correctamente',
          },
          history.push('/financescountercharges/bankingtransfersfinances')
        )
      )
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlertNotifications({
        mode: 'error',
        message: e?.message,
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title="Datos del Traspaso"
            btnSubmit={true}
            btnSubmitText="Guardar"
            withCard={false}
            btnSecondFunction={(e) => handleCloseModal(e)}
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={true}
            errors={errors}
            content={
              <>
                <div className="col">
                  <div className="row">
                    <div className="col-12 d-flex flex-column justify-content-around">
                      {/* <div className="row"> */}
                      <div className="col-md-12 col-lg-12">
                        <OrderCol
                          title="Folio:"
                          content={
                            invoiceTransfer
                              ? invoiceTransfer
                              : infoBank
                              ? `${infoBank?.bank_nomenclature} ${dateInfo} ${
                                  parseFloat(infoBank?.bank_invoice_number) +
                                  parseFloat(valueConsecutive)
                                }`
                              : '---'
                          }
                          mediumColSize="6"
                        />
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="col-lg-12">
                      <InputController
                        label="Origen"
                        id="origin_bank"
                        name="origin_bank"
                        control={control}
                        placeholder="Origen"
                        inputType="choosen"
                        options={options}
                        changeAction={(e) => setValueIdBank(e?.value)}
                        debounceTime={700}
                        placeHolderChoosen="Elija un banco origen"
                        disabled={id ? true : false}
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputController
                        label="Destino"
                        id="destination_bank"
                        name="destination_bank"
                        control={control}
                        placeholder="Destino"
                        inputType="choosen"
                        options={options}
                        debounceTime={700}
                        placeHolderChoosen="Elija un banco destino"
                        // disabled={id ? true : false}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputController
                        label="Monto a pagar"
                        name="amount_to_pay"
                        control={control}
                        placeholder="Monto a pagar"
                        changeAction={(e) => formattedNumber(e.target.value)}
                        debounceTime={800}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputController
                        label="Fecha"
                        name="date"
                        type="date"
                        control={control}
                        placeholder="Fecha"
                        changeAction={(e) => setDateValue(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputController
                        label="Beneficiario"
                        name="concept"
                        control={control}
                        placeholder="Concepto"
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default CreateTransfersFinancesCounterCharges
