import moment from 'moment'

const RegisterHours = ({ itemFounded, handleButton, data, isClicked }) => {
  return (
    <div className="pl-5 pt-3">
      <h6 className="my-2 text-uppercase progress-bar-text">
        Registro de horas
      </h6>
      <div className="progress">
        <div
          className="progress-bar  bg-progress-bar"
          role="progressbar"
          style={{ width: `${itemFounded?.percentage}%` }}
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex mt-2 mb-5">
        <div style={{ width: '16.66%' }}>
          <p className="register-hours">Arribo de instalaciones</p>
          {itemFounded?.hours?.time_arrival_at_facilities?.completed ? (
            <span className="font-weight-bold ">
              {moment(
                +itemFounded?.hours?.time_arrival_at_facilities?.createdAt
              ).format('hh:mm:ss')}
            </span>
          ) : (
            <button
              className={`btn-progress-bar `}
              onClick={(e) => handleButton(data?.id, e)}
              data-field={1}
              style={{
                padding: '5px 15px',
                marginTop: '10px',
                pointerEvents: isClicked ? 'none' : 'auto',
              }}
              disabled={isClicked}
            >
              Registrar hora
            </button>
          )}
        </div>
        <div style={{ width: '16.66%' }}>
          <p className="register-hours">Ingreso a anden</p>
          {itemFounded?.hours?.time_arrival_at_facilities?.completed &&
            (itemFounded?.hours?.plataform_entry_time?.completed ? (
              <span className="font-weight-bold m-0 p-0">
                {moment(
                  +itemFounded?.hours?.plataform_entry_time?.createdAt
                ).format('hh:mm:ss')}
              </span>
            ) : (
              <button
                className={`btn-progress-bar ${isClicked ? 'not-allowed' : ''}`}
                onClick={(e) => handleButton(data?.id, e)}
                data-field={2}
                style={{
                  padding: '5px 15px',
                  marginTop: '10px',
                  pointerEvents: isClicked ? 'none' : 'auto',
                }}
                disabled={isClicked}
              >
                Registrar hora
              </button>
            ))}
        </div>
        <div style={{ width: '16.66%' }}>
          <p className="register-hours">Enrampe</p>
          {itemFounded?.hours?.plataform_entry_time?.completed &&
            (itemFounded?.hours?.ramp_time?.completed ? (
              <span className="font-weight-bold ">
                {moment(+itemFounded?.hours?.ramp_time?.createdAt).format(
                  'hh:mm:ss'
                )}
              </span>
            ) : (
              <button
                className="btn-progress-bar"
                onClick={(e) => handleButton(data?.id, e)}
                data-field={3}
                style={{
                  padding: '5px 15px',
                  marginTop: '10px',
                  pointerEvents: isClicked ? 'none' : 'auto',
                }}
                disabled={isClicked}
              >
                Registrar hora
              </button>
            ))}
        </div>
        <div style={{ width: '16.66%' }}>
          <p className="register-hours">Inicio de descarga</p>
          {itemFounded?.hours?.start_download?.completed && (
            <span className="font-weight-bold ">
              {moment(+itemFounded?.hours?.start_download?.createdAt).format(
                'hh:mm:ss'
              )}
            </span>
          )}
        </div>
        <div style={{ width: '16.66%' }}>
          <p className="register-hours">Fin de descarga</p>
          {itemFounded?.hours?.end_download?.completed && (
            <span className="font-weight-bold ">
              {moment(+itemFounded?.hours?.end_download?.createdAt).format(
                'hh:mm:ss'
              )}
            </span>
          )}
        </div>
        <div style={{ width: '16.66%' }}>
          <p className="register-hours">Hora de salida</p>

          {itemFounded?.hours?.time_arrival_at_facilities?.completed &&
            itemFounded?.hours?.plataform_entry_time?.completed &&
            itemFounded?.hours?.ramp_time?.completed &&
            itemFounded?.hours?.start_download?.completed &&
            itemFounded?.hours?.end_download?.completed &&
            (itemFounded?.hours?.departure_time?.completed ? (
              <span className="font-weight-bold">
                {moment(+itemFounded?.hours?.departure_time?.createdAt).format(
                  'hh:mm:ss'
                )}
              </span>
            ) : (
              <button
                className="btn-progress-bar"
                onClick={(e) => handleButton(data?.id, e)}
                data-field={6}
                style={{
                  padding: '5px 15px',
                  marginTop: '10px',
                  pointerEvents: isClicked ? 'none' : 'auto',
                }}
                disabled={isClicked}
              >
                Registrar hora
              </button>
            ))}
        </div>
      </div>
    </div>
  )
}

export default RegisterHours
