import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../Auth/AuthContext";
import InputController from "../../../Global/InputController";
import { useTransferContext } from "./TransferContext";
import { toastSweetAlert } from "../../../Helpers/ToastSweetAlert";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useMutation } from "@apollo/client";
import { DELETE_ALL_TRANSFER_LINE, GET_TRANSFER_LINES_BY_TRANSFER_REQUEST } from "../../../../graphql/Catalog/Transfers/Transfers";
import { GET_SAP_WAREHOUSES_MUTATION, GET_SAP_WAREHOUSES_TRANSFERS } from "../../../../graphql/Catalog/SAP/sapWarehouses";
import { document_statuses_esp } from "../../../Helpers/StatusesLanguages";

const TransferRequestForm = ({ control, setValue, getValues, _id }) => {
    const { user } = useContext(AuthContext)
    const MySwal = withReactContent(Swal)
    const [warehouses, setWarehouses] = useState([])
    const [allWarehousesStores, setAllWarehousesStores] = useState(null)
    const [idWarehouse, setIdWarehouse] = useState(false)
    const statusDictionary = document_statuses_esp

    //ESTADOS COMPARTIDOS
    const {
        warehouseOriginSelected,
        dataRequest,
        warehousesCodes,
        dataLines,
        setWarehouseOriginSelected,
        setFlagWarehouse,
        setWarehousesCodes,
        setQuery,
        setFindProducts
    } = useTransferContext()

    let warehouseOriginValidation = ''
    let flagChangeWarehouseOrigin = false

    const [deleteAllTransferLine] = useMutation(DELETE_ALL_TRANSFER_LINE, {
        refetchQueries: [
            GET_TRANSFER_LINES_BY_TRANSFER_REQUEST,
            {
                variables: {
                    getTransferLinesByTransferRequestId: parseInt(_id),
                    warehouseCode: warehouseOriginSelected
                        ? warehouseOriginSelected
                        : dataRequest?.from_whs_code,
                },
            },
        ],
    })
    const [warehousesData, { loading: loadingWarehouses }] = useMutation(
        user.store ? GET_SAP_WAREHOUSES_TRANSFERS : GET_SAP_WAREHOUSES_MUTATION
    )

    useEffect(() => {
        async function getWarehouses() {
            const warehouses = await warehousesData()
            if (!loadingWarehouses) {
                if (user.store) {
                    const data = warehouses?.data?.getSapWarehousesTransfers
                    setAllWarehousesStores(data)
                    let list = []
                    // eslint-disable-next-line array-callback-return
                    data.map((warehouse) => {
                        //console.log(warehouse)
                        if (user.store === warehouse.store_id) {
                            list.push({
                                label: warehouse.name,
                                value: warehouse.from_whs_code,
                                id: warehouse.id,
                                // from_whs_code: warehouse.from_whs_code,
                                to_whs_code: warehouse.to_whs_code,
                            })
                        }
                    })
                    // console.log(list)
                    return setWarehouses(list)
                }
                const data = warehouses?.data?.getSapWarehouses
                const list = data.map((warehouse) => {
                    return {
                        label: warehouse.warehouse_name,
                        value: warehouse.warehouse_code,
                    }
                })
                return setWarehouses(list)
            }
        }
        getWarehouses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehousesData])

    useEffect(() => {
        if (idWarehouse) {
            const data = allWarehousesStores.find((warehouse) => {
                // console.log(idWarehouse)
                return warehouse.id === idWarehouse
            })
            setWarehousesCodes({
                from_whs_code: data.from_whs_code,
                to_whs_code: data.to_whs_code,
                branch_code: data.branch_code,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idWarehouse])


    const handleDeleteAll = async (originalWarehouse, val) => {

        try {
            await deleteAllTransferLine({
                variables: {
                    deleteAllTransferLineRequestId: parseInt(_id),
                    dataRequest: {
                        doc_date: '',
                        from_whs_code: getValues('source_warehouse_sap'),
                        to_whs_code: getValues('destination_warehouse_sap'),
                        comments: getValues('comments_sap'),
                    },
                },
            })
            // user.store
            //   ? setWarehouseOriginSelected(warehousesCodes.from_whs_code)
            //   : setWarehouseOriginSelected(val.value)
            setWarehouseOriginSelected(val.value)
            setValue('flagSourceWarehouseSelected', true)
            setValue('destination_warehouse_sap', val.to_whs_code)
            // remove()
            setQuery(null)
            setFindProducts([])
            setValue('product_sap', '')
        } catch (error) {
            console.log(originalWarehouse)
            setValue('source_warehouse_sap', originalWarehouse)
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }

    const handleSetWarehouseCodes = (id) => {
        setIdWarehouse(id)
    }


    const handleChangeOrigin = ({ val, id }) => {
        if (
            !flagChangeWarehouseOrigin &&
            !getValues('flagSourceWarehouseSelected')
        ) {
            warehouseOriginValidation = val.value
            flagChangeWarehouseOrigin = true
            if (user.store) {
                setWarehouseOriginSelected(warehousesCodes.from_whs_code)
                setValue('flagSourceWarehouseSelected', warehousesCodes.from_whs_code)
                setValue('destination_warehouse_sap', val.to_whs_code)
                handleSetWarehouseCodes(val.id)
            } else {
                setWarehouseOriginSelected(val.value)
                setValue('flagSourceWarehouseSelected', warehousesCodes.from_whs_code)
                setValue('destination_warehouse_sap', val.to_whs_code)
            }
            return setFlagWarehouse(false)
        }
        setFlagWarehouse(false)

        MySwal.fire({
            showCloseButton: true,
            icon: 'warning',
            title: '¿Cambiar almacén origen?',
            html: (
                <>
                    <div className="message-container">
                        Al modificar el almacén origen, perderas toda la información que ya
                        has definido, ¿Deseas continuar?
                    </div>
                </>
            ),
            allowOutsideClick: false,
            buttonsStyling: false,
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar',
            reverseButtons: true,
            customClass: {
                title: 'StandarModalTitle',
                htmlContainer: 'StandarModalHtmlContainer',
                confirmButton: 'StandarModalConfirmButtonLogOut',
                denyButton: 'StandarModalCancelButtonLogOut',
                footer: 'StandarModalFooterLogOut',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                warehouseOriginValidation = val.value
                try {
                    await handleDeleteAll(getValues('flagSourceWarehouseSelected'), val)
                } catch (error) {
                    console.log(warehouseOriginValidation)
                    setValue('source_warehouse_sap', warehouseOriginValidation)
                    toastSweetAlert({
                        mode: 'error',
                        message: error.message,
                    })
                }
            } else {
                setValue(
                    'source_warehouse_sap',
                    getValues('flagSourceWarehouseSelected')
                )
            }
        })
    }


    return (

        <>

            {user.store ? (
                <div className="mb-3 col-lg-10 col-md-12 col-sm-12">
                    <InputController
                        label="Almacén"
                        inputType="choosen"
                        type="text"
                        options={warehouses}
                        control={control}
                        name="source_warehouse_sap"
                        changeAction={(e) =>
                            handleChangeOrigin({ val: e, id: e.value })
                        }
                        placeholder="Ingresa un almacén origen"
                        disabled={
                            statusDictionary[8] === dataRequest?.status &&
                            dataLines?.length > 0
                        }
                    />
                    <InputController
                        id="destination_warehouse_sap"
                        name="destination_warehouse_sap"
                        hidden
                        control={control}
                    />
                </div>
            ) : (
                <>
                    <div className="mb-3 col-lg-5 col-md-12 col-sm-12">
                        <InputController
                            label="Almacén origen"
                            inputType="choosen"
                            type="text"
                            options={warehouses}
                            control={control}
                            name="source_warehouse_sap"
                            changeAction={(e) =>
                                handleChangeOrigin({ val: e, id: false })
                            }
                            placeholder="Ingresa un almacén origen"
                        />
                    </div>
                    <div className="mb-3 col-lg-5 col-md-12 col-sm-12">
                        <InputController
                            label="Almacén destino"
                            inputType="choosen"
                            type="text"
                            options={warehouses}
                            control={control}
                            name="destination_warehouse_sap"
                            placeholder="Ingresa un almacén de destino"
                        />
                    </div>
                </>
            )}
            <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                <InputController
                    label="Comentarios"
                    inputType="textarea"
                    control={control}
                    name="comments_sap"
                    placeholder="Ingresa un comentario"
                />
            </div>


        </>

    )

}

export default TransferRequestForm;