import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_TRANSFER_REQUEST_BY_ID } from '../../../../graphql/Catalog/Transfers/Transfers'
import { useHistory } from 'react-router-dom'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import moment from 'moment'
import LayoutTable from '../../../Global/LayoutTable'
import OrderCol from '../../../Global/OrderCol'
import { document_statuses_esp } from '../../../Helpers/StatusesLanguages'
import OrderButtons from '../../../OrderButtons'

export const DetailsTransfer = ({ _id }) => {
  const [dataRequest, setDataRequest] = useState(false)
  const statusDictionary = document_statuses_esp
  const history = useHistory()

  const {
    data: dataTransferRequestId,
    loading: loadingTransferRequestId,
    error: errorTransferRequestId,
  } = useQuery(GET_TRANSFER_REQUEST_BY_ID, {
    variables: {
      id: parseInt(_id),
      validation: false,
      details: true,
    },
  })

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  useEffect(() => {
    if (_id) {
      if (!loadingTransferRequestId) {
        if (errorTransferRequestId) {
          return toastSweetAlert(
            {
              mode: 'error',
              message: errorTransferRequestId.message,
            },
            history.push('/catalog/transfers')
          )
        }

        const data = dataTransferRequestId?.getOneTransferRequest
        let list = {
          date: moment(data?.doc_date).format('DD/MMMM/YYYY'),
          date_due: moment(data?.doc_due_date).format('DD/MMMM/YYYY'),
          comments: data?.comments ? data?.comments : '---',
          origin: data?.warehouse_origin_name?.warehouse_name,
          destiny: data?.warehouse_destiny_name?.warehouse_name,
          status: statusDictionary[data?.status],
        }

        const status = data?.status

        const products = data?.TransferlineToTransferRequest?.map((product) => {
          return {
            id: product?.id,
            sku: product?.item_code,
            nombre: product?.name_product?.item_name,
            Solicitado: product?.quantity,
            Aprobado:
              status === 4 || status === 5
                ? product?.quantity - product?.open_quantity
                : 'Pendiente',
            Faltante:
              status === 4 || status === 5
                ? product?.open_quantity
                : 'Pendiente',
          }
        })
        list.products = products

        setDataRequest(list)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataTransferRequestId, errorTransferRequestId])

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Detalles</h3>
          </div>
          <div className="card-body col-12 p-5">
            <div className="box-separator">
              <div className="row">
                <OrderCol
                  title="Fecha"
                  content={dataRequest?.date}
                  mediumColSize="2"
                />
                <OrderCol
                  title="Fecha vencimiento"
                  content={dataRequest?.date_due}
                  mediumColSize="2"
                />
                <OrderCol
                  title="Estatus"
                  content={dataRequest?.status}
                  mediumColSize="2"
                />
                <OrderCol
                  title="Almacén origen"
                  content={dataRequest?.origin}
                  mediumColSize="3"
                />
                <OrderCol
                  title="Almacén destino"
                  content={dataRequest?.destiny}
                  mediumColSize="3"
                />
                <OrderCol
                  title="Comentarios"
                  content={dataRequest?.comments}
                  mediumColSize="12"
                />
              </div>
            </div>
            <div className="box-separator">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <h5 className="header-text-details">Productos</h5>

                  <LayoutTable
                    hideId
                    data={dataRequest?.products}
                    paginationServer={false}
                    hideActions={true}
                  />
                </div>
              </div>
            </div>
            <OrderButtons
              oneButton={true}
              onProcess={() => history.goBack()}
              processBtn="Regresar"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsTransfer
