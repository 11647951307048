import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'
import ContentHeader from '../../../Layout/ContentHeader'
import LayoutTable from '../../../Global/LayoutTable'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import moment from 'moment'
import {
  CREATE_NOTE,
  GET_COUNT_NOTES_FOR_CONSECUTIVE,
  GET_INFO_PARTNER_FOR_CREATE_NOTE,
  VALIDATE_INVOICE,
} from '../../../../graphql/Catalog/Notes/notes'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { VALIDATE_BUSINESS_PARTNER_FOR_CREATE_NOTE } from '../../../../graphql/Catalog/Notes/BusinessRetention/businessretention'
import { useContext } from 'react'
import { AuthContext } from '../../../../Auth/AuthContext'
import { GET_INFO_STORE_COUNTER_CHARGES } from '../../../../graphql/Catalog/Notes/StoreHelperCounterCharges/storehelpercountercharges'
import { GET_STORES } from '../../../../graphql/Catalog/Stores/stores'
import OrderCol from '../../../Global/OrderCol'
import BusinessRetentionNew from './BusinessRetention/BusinessRetentionNew'
import { generateTicketCounterCharges } from '../../../Helpers/pdfGenerator'
import { GET_ALL_CONCEPT_COUNTER_CHARGES } from '../../../../graphql/Catalog/Notes/ConceptCounterCharges/ConceptCounterCharges'
import DisplayInfoNoteForPrint from './PrintTicketsNotes/DisplayInfoNoteForPrint'
import { GET_ROLES } from '../../../../graphql/Catalog/Roles/roles'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'

export const ManualNote = () => {
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const RFCVA = 'VAM870320RI3'
  const [valueInvoice, setValueInvoice] = useState(null)
  const [valueCountConsecutive, setValueCountConsecutive] = useState(null)
  const [isManualNote, setIsManualNote] = useState(true)
  const [consecutiveNumber, setConsecutiveNumber] = useState(null)
  const [idRole, setIdRole] = useState(null)
  const [infoStore, setInfoStore] = useState(null)
  const [flag, setFlag] = useState(1)
  const [supplier, setSupplier] = useState(null)
  const [idStore, setIdStore] = useState(null)
  const [idPartner, setIdPartner] = useState(null)
  const [idConcept, setIdConcept] = useState(null)
  const [valueCheckBox, setValueCheckBox] = useState(false)
  const [valueChoosen, setValueChoosen] = useState('')
  const [labelConcept, setLabelConcept] = useState(null)
  const [options, setOptions] = useState([])
  const [optionsConcepts, setOptionsConcepts] = useState([])
  const [dataPartner, setDataPartner] = useState([])
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [sum, setSum] = useState(0)
  const [sumTotalRetention, setSumTotalRetention] = useState(0)
  const [dataTable, setDataTable] = useState([])
  const [dataBack, setDataBack] = useState([])
  const [consecutiveStore, setConsecutiveStore] = useState(null)

  const [getPartner] = useLazyQuery(GET_INFO_PARTNER_FOR_CREATE_NOTE)

  const [getInvoice] = useLazyQuery(VALIDATE_INVOICE)

  const [createNote] = useMutation(CREATE_NOTE)

  // const {
  //   loading: loadingGetInfoStore,
  //   error: errorGetInfoStore,
  //   data: dataGetInfoStore,
  // } = useQuery(GET_INFO_STORE_COUNTER_CHARGES, {
  //   variables: {
  //     id: parseInt(idStore),
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const [getInfoStore] = useLazyQuery(GET_INFO_STORE_COUNTER_CHARGES)

  const {
    loading: loadingCountConsecutive,
    error: errorCountConsecutive,
    data: dataCountConsecutive,
  } = useQuery(GET_COUNT_NOTES_FOR_CONSECUTIVE, {
    variables: {
      storeId: parseInt(idStore),
      isManualNote: true,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingStores,
    error: errorStores,
    data: dataStores,
  } = useQuery(GET_STORES, {
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
  } = useQuery(GET_ALL_CONCEPT_COUNTER_CHARGES, {
    fetchPolicy: 'no-cache',
  })

  const [getRoles] = useLazyQuery(GET_ROLES)

  const validationSchema = yup.object().shape({
    invoice_bill: yup
      .string()
      .min(2, 'Folio debe incluir por lo menos 2 digitos')
      .required('Este campo es obligatorio'),
    rfc_emitter: yup.string().required('Este campo es obligatorio'),
    // rfc_motive: yup.string().required('Este campo es obligatorio'),
    // id_bill: yup.string().required('Este campo es obligatorio'),
    // rfc_receiver: yup.string().required('Este campo es obligatorio'),
    date_bill: yup.date().typeError('Este campo es obligatorio').required(),
    amount_bill: yup.string().required('Este campo es obligatorio'),
    id_store: yup
      .string()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),
    id_concept: yup
      .string()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),
    description: yup.string().required('Este campo es obligatorio'),
  })

  const valSchema = yup.object().shape({
    invoice_bill: yup.string(),
  })

  const {
    trigger,
    methods,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const formattedNumber = (number) => {
    if (number === '') return
    let cleanNumber = number.replace(/[,]/g, '')
    cleanNumber = Number(cleanNumber)
    if (isNaN(cleanNumber)) {
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'Solo se permiten números',
        },
        setValue('amount_bill', '')
      )
    }
    let numberFormat = new Intl.NumberFormat('es-MX', {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cleanNumber)
    return numberFormat === '0'
      ? setValue('amount_bill', '')
      : setValue('amount_bill', numberFormat)
  }

  // const { handleSubmit } = useForm({
  //   resolver: yupResolver(valSchema),
  // })

  const sumTotalNote = (amountNote, noteCredit) => {
    let total = 0.0
    if (noteCredit) {
      total = sum - parseFloat(amountNote)
      return total
    }
    total = sum + parseFloat(amountNote)
    return total
  }

  const retention = (valueRetention, noteCredit) => {
    let total = 0.0

    if (noteCredit) {
      total = sumTotalRetention - parseFloat(valueRetention)

      return total
    }
    total = sumTotalRetention + parseFloat(valueRetention)

    return total
  }

  const calculateRetention = (amountBill, valueRetention) => {
    let total = 0.0

    if (valueRetention) {
      total = 0
    }

    // let percentage = valueRetention / 100
    let retention = (parseFloat(amountBill) / 1.16) * 0.9 * 0.025

    // total = retention * amountBill
    total = retention

    return total.toFixed(2)
  }

  const validarChk = () => {
    if (getValues('note_credit')) {
      setValue('note_credit', true)
    } else {
      setValue('note_credit', false)
    }

    if (getValues('contability')) {
      setValue('contability', true)
    } else {
      setValue('contability', false)
    }
  }

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const handleGetPartner = async (rfc) => {
    try {
      if (rfc === null || rfc === '') return
      setLoadingBtn(true)
      validarChk()
      setValue('id_store', user?.store)
      const { data: dataPartner, error: errorPartner } = await getPartner({
        variables: {
          rfcSearch: rfc,
        },
        fetchPolicy: 'no-cache',
      })

      if (user.store) {
        if (!dataPartner) {
          setLoadingBtn(false)
          return toastSweetAlertNotifications({
            mode: 'error',
            message:
              'Contacte al administrador para dar de alta a este proveedor',
          })
        }
      }

      if (!dataPartner) {
        setLoadingBtn(false)
        setSupplier(rfc)
        return setFlag(2)
      }
      setDataPartner(dataPartner)
      setLoadingBtn(false)
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  const handleValueInvoice = async (data) => {
    try {
      setLoadingBtn(true)
      const date = getValues('date_bill')
      const amountBillFormat = data.amount_bill.replace(/[,]/g, '')
      const { data: dataInvoice, error: errorInvoice } = await getInvoice({
        variables: {
          invoiceCaptured: data.invoice_bill,
          idPartner: 0,
        },
        fetchPolicy: 'no-cache',
      })

      if (errorInvoice) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: errorInvoice.message,
        })
      }

      const listTable = dataTable.map((item) => {
        return {
          id: item.id,
          concepto: item.concepto,
          Folio: item.Folio,
          Beneficiario: item.Beneficiario,
          Monto: item.Monto,
          Fecha: item.Fecha,
          descripción: item.descripción,
        }
      })
      const listBack = dataBack.map((item) => {
        return {
          id: item.id,
          invoice_bill: item.invoice_bill,
          id_concept: item.id_concept,
          amount_bill: item.amount_bill,
          date_bill: item.date_bill,
          note_credit: item.note_credit,
          description: item.description,
          provider_name_nm: item.provider_name_nm,
        }
      })

      if (listBack.find((item) => item.invoice_bill === data?.invoice_bill)) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'El No. de folio ya se ha añadido',
        })
      }

      const sumNote = sumTotalNote(amountBillFormat, getValues('note_credit'))
      const sumRetention = calculateRetention(
        amountBillFormat,
        dataPartner?.getInfoPartnerForCreateNote?.percentage_retention
      )

      const sumValueRetention = retention(
        sumRetention,
        getValues('note_credit')
      )

      listTable.push({
        id: data?.invoice_bill,
        concepto: labelConcept,
        Folio: data.invoice_bill,
        Beneficiario: data?.rfc_emitter,
        Monto: data.note_credit
          ? '- ' + formmatterCurrency(amountBillFormat)
          : formmatterCurrency(amountBillFormat),
        Fecha: moment(data.date_bill).format('DD/MM/YYYY'),
        descripción: data.description,
      })

      listBack.push({
        id: data?.invoice_bill,
        invoice_bill: data?.invoice_bill,
        id_concept: parseInt(data?.id_concept),
        amount_bill: amountBillFormat,
        note_credit: valueCheckBox,
        retention_bill: sumRetention,
        date_bill: moment(data.date_bill, 'YYYY-MM-DD hh:mm:ss a').format(
          'YYYY-MM-DD'
        ),
        description: data.description,
        provider_name_nm: data?.rfc_emitter,
      })

      setSumTotalRetention(null)
      // setIdPartner(dataPartner?.getInfoPartnerForCreateNote?.id)
      setSum(sumNote)
      setDataBack(listBack)
      setDataTable(listTable)
      // setSecondTicket(dataPartner?.getInfoPartnerForCreateNote?.second_ticket)
      // setSum(
      //   data.note_credit
      //     ? sum - parseFloat(data.amount_bill)
      //     : sum + parseFloat(data.amount_bill)
      // )
      setValue('invoice_bill', '')
      // setValue('rfc_emitter', '')
      // setValue('rfc_motive', '')
      setValue('amount_bill', '')
      setValue('rfc_emitter', '')
      setValue('description', '')
      setValue('id_concept', null)
      setValueInvoice(null)
      setValueChoosen('')
      setIdConcept(null)
      setLabelConcept(null)
      setValue('note_credit', false)
      setValue('contability', false)
      setLoadingBtn(false)
      setValue('date_bill', moment().format('YYYY-MM-DD'))
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  const handleDeleteRowBill = async (id) => {
    try {
      setLoadingBtn(true)
      const findBillTable = dataTable.find((item) => item.id === id)
      const findBillBack = dataBack.find((item) => item.id === id)
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        title: '¿Estas seguro de eliminar esta factura?',
        message: 'No podrás revertir esta acción',
      })
      if (result.isConfirmed) {
        // const findBillBack = dataBack.find((item) => item.id === id)
        const newData = dataTable.filter((item) => item.id !== id)
        const newDataBack = dataBack.filter((item) => item.id !== id)
        const substration =
          parseFloat(sum) - parseFloat(findBillBack.amount_bill)
        const substrationRetention =
          parseFloat(sumTotalRetention) -
          parseFloat(findBillBack.retention_bill)
        setSumTotalRetention(substrationRetention)
        setSum(substration)
        setDataBack(newDataBack)
        setDataTable(newData)
        setLoadingBtn(false)
        toastSweetAlertNotifications({
          mode: 'ok',
          message: 'Factura eliminada correctamente',
        })
      }
      setLoadingBtn(false)
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  const handleGetValuesLabelandIdConcept = (e) => {
    const idConcept = e.value
    const labelConcept = e.label
    setValueChoosen(e.value)
    setIdConcept(idConcept)
    setLabelConcept(labelConcept)
  }

  // useEffect(() => {
  //   // if (dataGetInfoStore !== null && dataGetInfoStore !== undefined) {
  //   if (idStore !== null && idStore !== undefined) {
  //     if (!loadingGetInfoStore) {
  //       if (errorGetInfoStore)
  //         return toastSweetAlert(
  //           {
  //             mode: 'error',
  //             message: errorGetInfoStore.message,
  //           },
  //           history.push('/catalog/notes/shownotes')
  //         )
  //       setInfoStore(dataGetInfoStore.getInfoStoreCounterCharges)
  //     }
  //   }
  // }, [loadingGetInfoStore, errorGetInfoStore, dataGetInfoStore])

  useEffect(async () => {
    if (user.store || idStore) {
      const { data } = await getInfoStore({
        variables: {
          id: idStore ? idStore : null,
        },
        fetchPolicy: 'no-cache',
      })

      if (data.getInfoStoreCounterCharges === null) {
        return toastSweetAlert(
          {
            mode: 'error',
            message: 'La tienda no tiene información para crear contrarecibos',
          },
          history.push('/shownotes')
        )
      }

      setInfoStore(data.getInfoStoreCounterCharges)
      // setConsecutiveNumber(consecutiveNumber)
    }
  }, [user.store, idStore])

  useEffect(() => {
    if (infoStore) {
      let consecutiveStore = infoStore.consecutive_number_manual_note

      let consecutiveSplit = consecutiveStore?.split(' ')

      let consecutiveNumber = consecutiveSplit[1]

      setConsecutiveStore(consecutiveNumber)
    }
  }, [infoStore])

  useEffect(() => {
    if (dataCountConsecutive !== null && dataCountConsecutive !== undefined) {
      if (!loadingCountConsecutive) {
        if (errorCountConsecutive)
          return toastSweetAlert({
            mode: 'error',
            message: errorCountConsecutive.message,
          })
      }
      setValueCountConsecutive(
        dataCountConsecutive.getCountForConsecutiveNumber + 1
      )
    }
  })

  useEffect(async () => {
    if (!loadingStores) {
      if (errorStores)
        return toastSweetAlert({
          mode: 'error',
          message: errorStores.message,
        })
      // if (user.role === 1 || user.role === 5) {
      const shops = await dataStores?.getAllStores?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.alias,
        }
      })

      // await handleRoleForStore(shops)
      setOptions(shops)
      setValue('id_store', user.store)
      setIdStore(user.store)
      setValue('date_bill', moment().format('YYYY-MM-DD'))
    }
  }, [loadingStores, errorStores, dataStores])

  // const handleRoleForStore = async (shops) => {
  //   const { data: roles } = await getRoles({
  //     variables: {},
  //     fetchPolicy: 'no-cache',
  //   })

  //   console.log('roles', roles)

  //   const dataRoles = roles?.getAllRoles?.rows

  //   const findRoleShop = await dataRoles?.find(
  //     (item) => item?.role_name === 'Tienda'
  //   )

  //   const findRoleAdmin = await dataRoles?.find(
  //     (item) => item?.role_name === 'Administrador'
  //   )

  //   console.log('findRoleAdmin', findRoleAdmin)
  //   console.log('user.role', user.role)

  //   if (parseInt(findRoleAdmin?.id) !== parseInt(user.role)) {
  //     if (findRoleShop?.id === user.role) {
  //       setValue('id_store', user?.store?.toString())
  //       setIdStore(user?.store)
  //     }

  //     if (parseInt(findRoleShop?.id) !== parseInt(user.role)) {
  //       const findShopMatriz = await shops?.find(
  //         (item) => item.label === 'Matriz'
  //       )

  //       console.log('findShopMatriz', findShopMatriz)

  //       setValue('id_store', findShopMatriz?.value?.toString())
  //       setIdStore(findShopMatriz?.value)

  //       console.log('findRoleShop', findRoleShop)
  //     }
  //   }
  //   setIdRole(findRoleAdmin?.id)
  // }

  useEffect(() => {
    if (!loadingConcepts) {
      if (errorConcepts) {
        return toastSweetAlert({
          mode: 'error',
          message: errorConcepts.message,
        })
      }
      const concepts = dataConcepts?.getAllConceptsCounterCharges?.rows?.map(
        (item) => {
          return {
            value: item.id,
            label: item.name,
          }
        }
      )
      setOptionsConcepts(concepts)
    }
  }, [loadingConcepts, errorConcepts, dataConcepts])

  useEffect(() => {
    if (dataPartner) {
    }
  }, [dataPartner])

  const onSubmit = async (Data) => {
    try {
      setLoadingBtn(true)
      if (dataBack.length === 0) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'Aun no hay información registrada',
        })
      }

      // generateTicketCounterCharges(dataPrint, 1, secondTicket)

      const item = dataBack.map((item) => {
        return {
          // id_bill: item.id_bill,
          amount_bill: item?.amount_bill?.toString(),
          retention_bill: item.retention_bill?.toString(),
          id_partner: item.id_partner,
          id_concept: item.id_concept,
          date_bill: item.date_bill,
          invoice_bill: item.invoice_bill,
          note_credit: item.note_credit,
          provider_name_nm: item.provider_name_nm,
          description: item.description,
          retention_bill: '0',
        }
      })
      await createNote({
        variables: {
          noteInput: {
            amount_note: sum?.toFixed(2)?.toString(),
            retention_note: '0',
            pay_note: '0',
            date_note: moment().format('YYYY-MM-DD HH:mm:ss'),
            id_store_register: idStore,
            consecutive_number: `NM ${
              parseFloat(consecutiveStore) + parseFloat(valueCountConsecutive)
            }`,
            note_for_contability: true,
            note_for_finances: false,
            is_manual_note: true,
          },
          billInput: item,
        },
      })
      setLoadingBtn(false)
      setConsecutiveNumber(
        `NM ${parseFloat(consecutiveStore) + parseFloat(valueCountConsecutive)}`
      )
      return setFlag(3), setIsManualNote(true)
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      {flag === 1 && (
        <>
          <ContentHeader
            title="Generación de Nota Manual"
            windowTitle="Generación de Nota Manual"
            // breadcrumb="Nota Manual"
          />
          <FormProvider {...methods}>
            <form
              className={` ${loadingBtn ? 'processing-file' : ''}`}
              onSubmit={handleSubmit(handleValueInvoice)}
            >
              <Box
                // title="Carga de facturas"
                errors={errors}
                btnLoading={loadingBtn}
                btnSubmit={true}
                btnSubmitText="Añadir Factura"
                btnOnClick={(e) => onSubmit(e)}
                content={
                  <>
                    <div className="row">
                      <div className="mb-3 col-md-6 col-lg-4">
                        <InputController
                          label="Beneficiario"
                          name="rfc_emitter"
                          // blurAction={(e) => handleGetPartner(e.target.value)}
                          control={control}
                        />
                      </div>
                      {/* <div className="mb-3 col-md-6 col-lg-4">
                        <InputController
                          label="Razón Social"
                          valueManual={
                            dataPartner?.getInfoPartnerForCreateNote
                              ?.provider_name
                          }
                          defaultValue={
                            dataPartner?.getInfoPartnerForCreateNote
                              ?.provider_name
                          }
                          readOnly={
                            dataPartner?.getInfoPartnerForCreateNote
                              ?.provider_name
                              ? true
                              : false
                          }
                          name="rfc_motive"
                          control={control}
                        />
                      </div> */}
                      {/* <div className="mb-3 col-md-6 col-lg-3">
              <InputController 
                label="ID Factura"
                name = "id_bill"
                control={control}
              />
            </div> */}
                      <div className="mb-3 col-md-6 col-lg-4">
                        <InputController
                          label="Monto"
                          name="amount_bill"
                          control={control}
                          changeAction={(e) => formattedNumber(e.target.value)}
                          debounceTime={800}
                        />
                      </div>
                      {/* <div className="mb-3 col-md-6 col-lg-4">
                        <InputController
                          valueManual={RFCVA}
                          defaultValue={RFCVA}
                          readOnly={true}
                          label="RFC Receptor"
                          name="rfc_receiver"
                          control={control}
                        />
                      </div> */}
                      <div className="mb-3 col-md-6 col-lg-4">
                        <InputController
                          label="Fecha"
                          type="date"
                          name="date_bill"
                          control={control}
                        />
                      </div>
                      <div className="mb-3 col-md-6 col-lg-4">
                        {/* <Controller
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange, ...field } }) => ( */}
                        <InputController
                          // {...field}
                          label="Folio"
                          name="invoice_bill"
                          control={control}
                          // changeAction={({ target: { value } }) => {
                          //   setValueInvoice(value)
                          // }}
                          // onKeyDown={(event) => {
                          //   if (event.key === 'ArrowDown') {
                          //     onChange(valueInvoice)
                          //     handleValueInvoice(valueInvoice)
                          //   }
                          // }}
                          //   />
                          // )}
                        />
                      </div>
                      <div
                        style={{ zIndex: '2' }}
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-4"
                      >
                        <InputController
                          label="Concepto"
                          name="id_concept"
                          control={control}
                          inputType="choosen"
                          options={optionsConcepts}
                          valueManual={valueChoosen}
                          changeAction={(e) =>
                            handleGetValuesLabelandIdConcept(e)
                          }
                          // disabled={user.role === 6 ? false : true}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <InputController
                          label="Descripción"
                          name="description"
                          inputType="textarea"
                          control={control}
                          options={optionsConcepts}

                          // disabled={user.role === 6 ? false : true}
                        />
                      </div>
                      {user.store === null && (
                        <div
                          style={{ zIndex: '2' }}
                          className="mb-3 col-xs-12 col-sm-12 col-md-12 col-lg-4"
                        >
                          <InputController
                            label="Tiendas"
                            name="id_store"
                            control={control}
                            inputType="choosen"
                            options={options}
                            changeAction={(e) => setIdStore(e.value)}
                            // disabled={user.role === 6 ? false : true}
                          />
                        </div>
                      )}
                    </div>
                    <br />
                    {/* <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex flex-column align-items-center justify-content-center">
                        <InputController
                          label="Nota de crédito"
                          name="note_credit"
                          inputType="checkbox"
                          control={control}
                          changeAction={(e) =>
                            setValueCheckBox(e.target.checked)
                          }
                        />
                      </div> */}
                    {/* <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex flex-column align-items-center justify-content-start">
                        <InputController
                          label="Contabilidad"
                          name="contability"
                          inputType="checkbox"
                          control={control}
                        />
                      </div> */}
                    {/* </div> */}
                    <LayoutTable
                      title=""
                      data={dataTable}
                      withCard={false}
                      titleActions="Borrar"
                      hideId
                      actionBtnErase={(e) => handleDeleteRowBill(e)}
                      hideBtnEdit={true}
                      hideDetails={true}
                      hideFilterBar={true}
                      headersButtons={false}
                      paginationServer={false}
                    />
                    <div className="col-12 d-flex flex-column justify-content-around">
                      <div className="row">
                        <div className="col-lg-6">
                          <OrderCol
                            title="Operación:"
                            content={
                              infoStore
                                ? `NM ${
                                    parseFloat(consecutiveStore) +
                                    parseFloat(valueCountConsecutive)
                                  }`
                                : '---'
                            }
                            mediumColSize="4"
                          />
                        </div>

                        <div className="col-lg-6 ">
                          <OrderCol
                            title="Total:"
                            content={formmatterCurrency(sum)}
                            mediumColSize="4"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            </form>
          </FormProvider>
        </>
      )}

      {/* {flag === 2 && <BusinessRetentionNew valueRFC={supplier} />} */}
      {flag === 3 && (
        <DisplayInfoNoteForPrint
          consecutiveNumber={consecutiveNumber}
          isManualNote={isManualNote}
          setIsManualNote={setIsManualNote}
        />
      )}
    </>
  )
}

export default ManualNote
