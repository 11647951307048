const ToogleDetailReturnsReceipt = ({ ReturnDocumentRow }) => {
        return (

            <div className="pl-5 pr-5 pt-2 pb-2">
    
                <div className="" style={{ marginLeft: '500px' }}>
                    <table className="table-bordered ">
                        <thead className="text-center" style={{ width: '100%' }}>
                            <tr>
                                <th style={{ width: '40%' }}>Escaneado</th>
                                <th style={{ width: '40%' }}>Motivo de devolucion</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {console.log(ReturnDocumentRow)}
                            { ReturnDocumentRow.DetailRow.map((line) =>                         
                                 (
                                    <tr key={line.id}>
                                        <td>{line.quantity}</td>
                                        <td>{line.Reason.name}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )   
}


export default ToogleDetailReturnsReceipt

