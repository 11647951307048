import { gql } from '@apollo/client'

export const GET_ALL_SAP_BUSINESS_PARTNER_COUNTER_CHARGES = gql`
  query GetAllSapBusinessPartnerCounterCharges(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllSapBusinessPartnerCounterCharges(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        card_code
        card_name
        card_type
        lic_trad_number
        id
        phone1
        email
        group_code
        group_number
        list_number
      }
      count
    }
  }
`

export const GET_ONE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES = gql`
  query GetOneSapBusinessPartnerCounterCharges(
    $getOneSapBusinessPartnerCounterChargesId: Int!
  ) {
    getOneSapBusinessPartnerCounterCharges(
      id: $getOneSapBusinessPartnerCounterChargesId
    ) {
      card_code
      card_name
      card_type
      credit_line
      debit_line
      discount
      group_code
      group_number
      lic_trad_number
      list_number
      phone1
      phone2
      email
      id
    }
  }
`

export const CREATE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES = gql`
  mutation CreateSapBusinessPartnerCounterCharges(
    $sapBusinessPartnerInput: SapBusinessPartnerCounterChargesInput
  ) {
    createSapBusinessPartnerCounterCharges(
      SapBusinessPartnerInput: $sapBusinessPartnerInput
    ) {
      card_code
      card_name
      group_code
      list_number
      group_number
      credit_line
      debit_line
      discount
      phone1
      phone2
      email
      lic_trad_number
    }
  }
`

export const UPDATE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES = gql`
  mutation UpdateSapBusinessPartnerCounterCharges(
    $updateSapBusinessPartnerCounterChargesId: Int!
    $sapBusinessPartnerInput: SapBusinessPartnerCounterChargesInput
  ) {
    updateSapBusinessPartnerCounterCharges(
      id: $updateSapBusinessPartnerCounterChargesId
      SapBusinessPartnerInput: $sapBusinessPartnerInput
    )
  }
`
export const DELETE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES = gql`
  mutation DeleteSapBusinessPartnerCounterCharges($id: Int!) {
    deleteSapBusinessPartnerCounterCharges(id: $id)
  }
`
