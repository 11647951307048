import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'

import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import Box from '../Global/Box'
import ContentHeader from '../Layout/ContentHeader'
import InputController from '../Global/InputController'

// apollo
import {
    GET_ONE_WAREHOUSE_MASTER_DATA,
    CREATE_MASTER_DATA_WAREHOUSE,
    UPDATE_MASTER_DATA_WAREHOUSE
} from '../../graphql/Catalog/Definitions/WarehouseMasterData'
import { GET_STORES } from '../../graphql/Catalog/Stores/stores'
import { GET_SAP_WAREHOUSES_MUTATION } from '../../graphql/Catalog/SAP/sapWarehouses'
import { FIND_BUSINESS_PARTNERS } from '../../graphql/Documents/Inventories/Returns'

export const ReturnsConfigurationNew = () => {
    const { id: _id, show } = useParams()
    const history = useHistory()
    const [canAddCard, setCanAddCard] = useState(false)
    const [itIsBranchR1, setItIsBranchR1] = useState(false)
    const [businessPartnerID, setBusinessPartnerID] = useState(0)
    const [listOfStores, setListOfStores] = useState([])
    const [listOfWarehouses, setListOfWarehouses] = useState([])
    const [onProcess, setOnProcess] = useState(false)
    const schema = yup
        .object()
        .shape({
            storeID: yup.number().required('La sucursal es obligatoria'),
            trsWhsCode: yup.number().required('El almacén de tránsito es obligatorio')
                .test("is-greater", "El almacén de tránsito no puede ser igual al almacén de destino o almacén de origen", function (value) {
                    const { fromWhsCode, wasteWhsCode, trsWhsCode } = this.parent;
                    return (fromWhsCode != wasteWhsCode && trsWhsCode != fromWhsCode && trsWhsCode != wasteWhsCode)
                }),
            wasteWhsCode: yup.number().required('El almacén de destino es obligatorio')
                .test("is-greater", "El almacén de origen no puede ser igual al almacén de tránsito o almacén destino", function (value) {
                    const { fromWhsCode, wasteWhsCode } = this.parent;
                    return (fromWhsCode != wasteWhsCode)
                }),
            inputCardCode: yup.string().required('El código de socio de negocio es obligatorio'),
            inputCardName: yup.string().required('El nombre de socio de negocio es obligatorio'),
            fromWhsCode: yup.number().required('El almacén de origen es obligatorio')
                .test("is-greater", "El almacén de destino no puede ser igual al almacén de tránsito o al almacén de origen", function (value) {
                    const { fromWhsCode, toWhsCode } = this.parent;
                    return (fromWhsCode != toWhsCode)
                }),
        })
        .required()

    const {
        methods,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        getValues,
    } = useForm({ resolver: yupResolver(schema) })

    const {
        data: dataStores,
        loading: loadingStores,
        error: errorStores,
    } = useQuery(GET_STORES, {
        variables: {
            searchQuery: null,
            limit: null,
            offset: null
        },
        fetchPolicy: 'no-cache',
    })

    const {
        data: data,
        loading: loading,
        error: error,
    } = useQuery(GET_ONE_WAREHOUSE_MASTER_DATA, {
        variables: {
            getOneWarehouseMasterDataId: parseInt(_id),
        },
        fetchPolicy: 'no-cache',
    })

    const [getWarehouses] = useMutation(GET_SAP_WAREHOUSES_MUTATION)
    const [findBusinessPartners] = useLazyQuery(FIND_BUSINESS_PARTNERS)
    const [createFlow] = useMutation(CREATE_MASTER_DATA_WAREHOUSE)
    const [updateFlow] = useMutation(UPDATE_MASTER_DATA_WAREHOUSE)

    // OnLoad data
    useEffect(async () => {
        if (!loading) {
            if (error)
                return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })
        }
        try {
            if (!_id) {
                // Create

            } else {
                // Update
                if (data) {
                    const definition = data.getOneWarehouseMasterData
                    setValue('storeID', definition.store_id)
                    setValue('inputCardCode', definition.BusinessPartnerWhsMasterData.card_code)
                    setBusinessPartnerID(definition.BusinessPartnerWhsMasterData.id)
                    setValue('inputCardName', definition.BusinessPartnerWhsMasterData.card_name)

                    setValue('trsWhsCode', definition.TransitWhsWarehouseMasterData.id)
                    setValue('fromWhsCode', definition.WarehouseWhsMasterData.id)
                    setValue('wasteWhsCode', definition.WasteWhsWarehouseMasterData.id)

                    setValue('inputSO1', definition.branch_code)
                    setItIsBranchR1(definition.it_is_branch_R1)
                }
            }
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }, [_id, data, loading, error])
    // Load Options
    useEffect(async () => {
        if (!loadingStores) {
            if (errorStores)
                return toastSweetAlert({
                    mode: 'error',
                    message: errorStores.message,
                })
        }
        try {
            if (dataStores) {
                const optionsStore = []

                dataStores.getAllStores.rows.map((element) => {
                    return optionsStore.push({
                        ['value']: element.id,
                        ['label']: `${element.id} - ${element.name}`,
                    })
                })

                setListOfStores(optionsStore)

                // Get Warehouses Content
                const warehouses = await getWarehouses()
                const optionsWhs = []
                warehouses.data.getSapWarehouses.map((element) => {
                    return optionsWhs.push({
                        ['value']: element.id,
                        ['label']: `${element.warehouse_code} - ${element.warehouse_name}`
                    })
                })

                setListOfWarehouses(optionsWhs)
            }

        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }, [_id, dataStores, loadingStores, errorStores])

    const onFindBusinessPartner = async (value) => {
        try {
            var cardCode = getValues('inputCardCode')

            if (cardCode.trim().length > 0 && !canAddCard) {
                const result = await findBusinessPartners({
                    variables: {
                        searchQuery: cardCode,
                    },
                    fetchPolicy: 'no-cache',
                })

                if (result.data.findSapBusinessPartner.count > 0) {
                    const businessPartner = result.data.findSapBusinessPartner.rows[0]
                    console.log(businessPartner)
                    setValue('inputCardCode', businessPartner.card_code)
                    setValue('inputCardName', businessPartner.card_name)
                    setBusinessPartnerID(businessPartner.id)
                } else {
                    setBusinessPartnerID(0)
                }
                if (result.data.findSapBusinessPartner.count == 0) {
                    setValue('inputCardCode', "")
                    return toastSweetAlert({
                        mode: 'error',
                        message: 'No se encontraron resultados',
                    })
                }
            }
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }

    const onSubmit = async (data) => {
        try {
            if (!_id) {
                // Create
                setOnProcess(true)
                const warehouseMasterData = {
                    business_partner_id: businessPartnerID,
                    card_code: data.inputCardCode,
                    card_name: data.inputCardName,
                    warehouse_id: data.fromWhsCode,
                    store_id: data.storeID,
                    branch_code: data.inputSO1,
                    it_is_branch_R1: itIsBranchR1,
                    transit_warehouse_id: data.trsWhsCode,
                    waste_warehouse_id: data.wasteWhsCode,
                }
                const result = await createFlow({
                    variables: { warehouseMasterData },
                })


                if (result.data.createWarehouseMasterData) {
                    history.push(`/Definitions/ReturnsConfiguration`)
                }

            } else {
                // Update
                setOnProcess(true)
                const warehouseMasterData = {
                    id: parseInt(_id),
                    business_partner_id: businessPartnerID,
                    card_code: data.inputCardCode,
                    card_name: data.inputCardName,
                    warehouse_id: data.fromWhsCode,
                    store_id: data.storeID,
                    branch_code: data.inputSO1,
                    it_is_branch_R1: itIsBranchR1,
                    transit_warehouse_id: data.trsWhsCode,
                    waste_warehouse_id: data.wasteWhsCode,
                }
                const result = await updateFlow({
                    variables: { warehouseMasterData },
                })


                if (result.data.updateWarehouseMasterData) {
                    history.push(`/Definitions/ReturnsConfiguration`)
                }
            }
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        } finally {
            setOnProcess(false)
        }
    }
    return <>
        <ContentHeader
            title="Agregar nuevo flujo de devolucion"
            breadcrumb="Flujo de devolucion"
            windowTitle={`Configuracion de flujo devolucion`}
        />
        <div className={`${onProcess ? 'processing-file' : ''}`}>
            <Box
                title={`Flujo de traslado`}
                btnSubmit={!show ? handleSubmit(onSubmit) : false}
                loading={onProcess}
                btnRedPath="/Definitions/ReturnsConfiguration"
                btnRedTxt={show ? "Regresar" : "Cancelar"}
                content={
                    <>
                        <FormProvider>
                            <form>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Sucursal"
                                                name="storeID"
                                                type="number"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfStores}
                                                disabled={show}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Código de socio de negocio"
                                                type="text"
                                                inputType="text"
                                                name="inputCardCode"
                                                placeholder="Buscar por codigo o nombre de socio de negocio"
                                                control={control}
                                                blurAction={onFindBusinessPartner}
                                                disabled={show}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-8">
                                            <InputController
                                                label="Nombre de socio de negocio"
                                                type="text"
                                                inputType="text"
                                                name="inputCardName"
                                                disabled={!canAddCard || show}
                                                placeholder="Nombre socio de negocio"
                                                control={control}
                                            />
                                        </div>

                                        <div className="col-sm-12 col-md-4 form-check">
                                            <input
                                                label="Crear un nuevo socio para este flujo"
                                                type="checkbox"
                                                name="checkboxNewCard"
                                                checked={canAddCard}
                                                onChange={(e) =>
                                                    setCanAddCard(e.target.checked)
                                                }
                                                disabled={show}
                                                hidden={show}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="close_with_adjustment"
                                                hidden={show}
                                            >
                                                &nbsp; Crear un nuevo socio para este flujo
                                            </label>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Almacén de origen del traspaso"
                                                name="fromWhsCode"
                                                type="number"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfWarehouses}
                                                disabled={show}
                                            />
                                        </div>

                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Almacén tránsito del traspaso"
                                                name="trsWhsCode"
                                                type="number"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfWarehouses}
                                                disabled={show}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Almacén tránsito de mermas"
                                                name="wasteWhsCode"
                                                type="number"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfWarehouses}
                                                disabled={show}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Código de Sucursal en Retail One"
                                                type="text"
                                                name="inputSO1"
                                                placeholder="Código de Sucursal en Retail One"
                                                disabled={!itIsBranchR1 || show}
                                                control={control}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-12 form-check">
                                            <input
                                                label="El almacen de origen es una sucursal de Retail One"
                                                type="checkbox"
                                                name="checkboxNewCard"
                                                checked={itIsBranchR1}
                                                onChange={(e) =>
                                                    setItIsBranchR1(e.target.checked)
                                                }
                                                disabled={show}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="close_with_adjustment"
                                            >
                                                &nbsp; El almacén de origen es una sucursal de Retail One
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </>
                }
            ></Box>
        </div>

    </>
}

export default ReturnsConfigurationNew