import { useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { GET_BILLS } from '../../../../../graphql/Catalog/Notes/bills'
import Box from '../../../../Global/Box'
import DatesOptions from '../../../../Global/DatesOptions'
import LayoutTable from '../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'

export const ShowTableBills = ({ handleChkBoxPrevious }) => {
  const [total, setTotal] = useState(0)
  const [dataBillsTable, setDataBillsTable] = useState([])
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    idNote: null,
  })

  const {
    loading: loadingBill,
    error: errorBill,
    data: dataBill,
    refetch,
  } = useQuery(GET_BILLS, {
    variables: {
      searchQuery: pagePagination?.searchQuery,
      limit: pagePagination?.limit,
      offset: pagePagination?.offset,
      idNote: pagePagination?.idNote,
      date: pagePagination?.date,
    },
    fetchPolicy: 'no-cache',
  })

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  useEffect(() => {
    if (!loadingBill) {
      if (errorBill)
        return toastSweetAlert({
          mode: 'error',
          message: errorBill.message,
        })
      let list = []
      dataBill.getBills.rows.map((item) => {
        return list.push({
          id: item.id,
          Factura: item.invoice_bill,
          'Contra-recibo': item?.notes?.consecutive_number,
          tienda: item?.notes?.storesRegister?.name,
          Fecha: item.date_bill,
          Monto: formmatterCurrency(item.amount_bill),
          retención: formmatterCurrency(item?.retention_bill),
          Abonado: formmatterCurrency(item.pay_bill),
        })
      })
      setDataBillsTable(list)
      setTotal(dataBill.getBills.count)
    }
  }, [loadingBill, errorBill, dataBill])

  const filterOnChangeSecond = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  return (
    <>
      <Box
        title="Listado de contrarecibos"
        content={
          <>
            <LayoutTable
              data={dataBillsTable}
              withCard={false}
              hideAddNew={true}
              exportImportButton={false}
              totalCount={total}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              refetchFunctionQueries={async () => await refetch()}
              hideId={true}
              hideBtnEdit={true}
              actionBtnErase={false}
              filterCheckBox
              // filterCheckBox1
              filterCheckBox2
              lblChkBox="Ocultar Comentarios"
              lblChkBox2="Todas las facturas"
              hideActions={true}
              valueChkBox2={true}
              // filterOnChangeBox={(e) =>
              //   setChkBoxValueWithoutComments(e.target.checked)
              // }
              filterOnChangeBox2={(e) => handleChkBoxPrevious(e.target.checked)}
              filterSecond
              filterOnChangeSecond={filterOnChangeSecond}
              filterOptionsSecond={[<DatesOptions daysBefore={true} />]}
              filterLabelTextSecond="Por fecha"
            />
          </>
        }
      />
    </>
  )
}

export default ShowTableBills
