import React from 'react'
import Box from '../../../Global/Box'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import DetailsCustomer from '../../Shared/DetailsCustomer'
import DetailsProducts from '../../Shared/DetailsProducts'
import DetailsPaymentDelivery from '../../Shared/DetailsPaymentDelivery'


export const OrderSummary = ({
    idCustomer,
    dataCart = [],
    totalCart = 0,
    handleCancelOrderSummary = () => {},
    paymentMethodSelected,
    deliveryDateSelected,
    deliveryTimeSelected,
}) => {

    const onSubmitOrder = async() => {
        toastSweetAlert({
            mode: 'finishOrder',
            message: 'El nuevo pedido se ha agregado exitosamente, presiona finalizar para regresar a pedidos o ver orden para ver el resumen de la misma.',
        })
    }

    return(
        <>
            <Box
                title="Resumen"
                btnSecondFunctionTitle="Cancelar"
                btnSecondFunction={() => { 
                    handleCancelOrderSummary({
                        comesFromSummary: true,
                        paymentMethodSelected: paymentMethodSelected,
                        deliveryDateSelected: deliveryDateSelected,
                        deliveryTimeSelected: deliveryTimeSelected
                    })
                }}
                btnFunctionTitle="Finalizar pedido"
                btnFunction={onSubmitOrder}
                content={
                    <>
                        <div className='row mb-3'>
                            <div className='card-body'>
                                <div className="col-12">
                                    <h4>Cliente</h4>
                                    <hr/>
                                    <DetailsCustomer 
                                        key={1}
                                        _id={idCustomer}
                                        withBox={false}
                                        headers={["Nombre", "Apellidos", "Teléfono", "Correo electrónico", "Dirección", "Referencia", "Entre", "Código postal", "Colonia", "Estado", "RFC"]} 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='card-body'>
                                <div className="col-12">
                                    <h4>Productos</h4>
                                    <hr/>
                                    <DetailsProducts 
                                        key={2}
                                        title={""}
                                        withBox={false}
                                        customDataCart={dataCart}
                                        customTotalCart={{
                                            total_products: totalCart,
                                            total_shipping: 0,
                                            total: totalCart
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='card-body'>
                                <div className="col-12">
                                    <h4>Pago y entrega</h4>
                                    <hr/>
                                    <DetailsPaymentDelivery
                                        key={3}
                                        withBox={false}
                                        paymentMethodSelected={paymentMethodSelected}
                                        deliveryDateSelectedDefault={deliveryDateSelected}
                                        deliveryTimeSelectedDefault={deliveryTimeSelected}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default OrderSummary