import { gql } from '@apollo/client'

export const GET_INVENTORY = gql`
  query GetInventory($limit: Int, $offset: Int, $searchQuery: String) {
    getInventory(limit: $limit, offset: $offset, searchQuery: $searchQuery) {
      count
      rows {
        id
        item_code
        on_hand
        SapItem {
          item_code
          item_name
        }
        RackPosition {
          reorder
        }
      }
    }
  }
`
export const GET_STORE_INVENTORY = gql`
  query GetStoreInventory($searchQuery: String, $offset: Int, $limit: Int) {
    getStoreInventory(
      searchQuery: $searchQuery
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        id
        item_code
        on_hand
        SapItem {
          item_code
          item_name
        }
      }
    }
  }
`
export const GET_INVENTORY_BY_ID = gql`
  query GetInventoryById($id: Int) {
    getInventoryById(id: $id) {
      id
      rack_id
      on_hand
      SapItem {
        item_code
        item_name
      }
      RackPosition {
        id
        name
        code
        capacity
        reorder
        RackZone {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_OR_CREATE_INVENTORY_REORDER = gql`
  mutation UpdateOrCreateInventoryReorder($sku: Int!, $reorder: Int!) {
    updateOrCreateInventoryReorder(sku: $sku, reorder: $reorder)
  }
`

export const UPDATE_INVENTORY_RACK_REORDER_BY_ID = gql`
  mutation UpdateInventoryRackReorderById($rack_id: Int!, $newReorder: Int!) {
    updateInventoryRackReorderById(rack_id: $rack_id, newReorder: $newReorder)
  }
`

export const GET_INVENTORY_REORDER_BY_SKU = gql`
  mutation GetInventoryReorderBySku($sku: Int!) {
    getInventoryReorderBySku(sku: $sku) {
      reorder
    }
  }
`
