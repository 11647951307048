import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import esLocale from '@fullcalendar/core/locales/es'

import '@fullcalendar/daygrid/main.css'

export const FullCalendarController = ({
  eventsList = [],
  handleEvent,
  renderEvent,
  dayHeaderClassNames = '',
  dayHeaderFormat = null,
  idContainer = 'calendar',
  classNameEventContainer = '',
}) => {
  const renderEventItem = (item) => {
    return (
      <div className={`eventBox text-wrap ${classNameEventContainer} `}>
        {item.event._def.extendedProps.type_schedule && (
          <h6>
            <b>
              {item.event._def.extendedProps.type_schedule === 1
                ? 'Conteo cíclico'
                : 'TFI'}
            </b>
          </h6>
        )}
        <h6>
          {/* <b>
            {item.timeText.match(/\:/g) ? item.timeText : item.timeText + ':00'}
          </b>{' '}
          -  */}
          {item.event.title}
          {!item.event._def.extendedProps.type_schedule
            ? ` - ${item.event.title}`
            : ''}
          {item.event._def.extendedProps.documents_txt && (
            <p>
              <b>Docs: {item.event._def.extendedProps.documents_txt}</b>
            </p>
          )}
        </h6>
      </div>
    )
  }

  return (
    <div className="FullCalendar" id={idContainer}>
      <FullCalendar
        titleFormat={{ month: 'long', day: 'numeric' }}
        locales={esLocale}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          resourceTimelinePlugin,
          timeGridPlugin,
        ]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView="dayGridMonth"
        eventClick={handleEvent}
        locale={'es'}
        displayEventTime={true}
        forceEventDuration={true}
        events={eventsList}
        eventContent={renderEvent || renderEventItem}
        themeSystem="bootstrap5"
        // views={{
        //   dayGridMonth: {
        //     titleFormat: {
        //       year: 'numeric',
        //       month: 'long',
        //     },
        //   },
        // }}
        dayHeaderClassNames={dayHeaderClassNames}
        dayHeaderFormat={dayHeaderFormat}
      />
    </div>
  )
}
