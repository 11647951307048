import React, { useEffect, useState, Link } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'
import ContentHeader from '../../Layout/ContentHeader'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_SCHEDULE } from '../../../graphql/Documents/Schedule/schedule'
import {
  docks_esp,
  document_statuses_esp,
} from '../../Helpers/StatusesLanguages'
import { FullCalendarController } from '../../Global/FullCalendar/FullCalendar'
import { GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID } from '../../../graphql/Documents/ScheduleDocks/scheduleDocks'
import withReactContent from 'sweetalert2-react-content'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { GET_ALL_COMMODITY_RECEIPT } from '../../../graphql/Documents/CommodityReceipt/commodityReceipt'
import Loader from '../../Global/Spinner'
// import {
//   GET_VEHICLES_BY_SCHEDULE_ID,
//   START_END_DOWNLOAD_HOUR_VALIDATION,
// } from '../../../graphql/Documents/Schedule/ScheduleVehicle'

const CommodityReceipt = () => {
  const statusEsp = document_statuses_esp
  const docksEsp = docks_esp
  const history = useHistory()
  const MySwal = withReactContent(Swal)

  const [docks, setDocks] = useState([])
  const [datesHistory, setDatesHistory] = useState([])
  const [countHistory, setCountHistory] = useState(0)
  const [datesHistoryOpen, setDatesHistoryOpen] = useState([])
  const [countHistoryOpen, setCountHistoryOpen] = useState(0)
  const [calendarEvents, setCalendarEvents] = useState([])
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [GetDocks] = useMutation(GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID)
  const { data, loading, error } = useQuery(GET_SCHEDULE)
  const {
    data: dataCommodity,
    loading: loadingCommodity,
    error: errorCommodity,
  } = useQuery(GET_ALL_COMMODITY_RECEIPT, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      status: 2,
    },
    fetchPolicy: 'no-cache',
  })

  // const [getDownloadHoursValidation] = useLazyQuery(
  //   START_END_DOWNLOAD_HOUR_VALIDATION
  // )

  useEffect(() => {
    if (!loading && !error) {
      async function SetDataEvents() {
        try {
          let datesList = []
          let nextSchedules = []
          let docksList = []
          for (const item of data.getSchedule) {
            const { ScheduleScheduleOrdersLines } = item
            let docEntriesTxt = ''
            const docEntries = ScheduleScheduleOrdersLines.map(
              (order, index) => {
                docEntriesTxt += `${order.ScheduleOrdersLinesSapPurchasesOrders.document_entry}`
                if (ScheduleScheduleOrdersLines.length - 1 > index)
                  docEntriesTxt += ', '
                return order.ScheduleOrdersLinesSapPurchasesOrders
                  .document_entry
              }
            )
            nextSchedules.push({
              id: item.id,
              'Hora y fecha':
                moment(
                  item.document_date + 'T' + item.document_time_start
                ).format('L hh:mm a') +
                ' - ' +
                moment(
                  item.document_date + 'T' + item.document_time_end
                ).format('hh:mm a'),

              almacén: item.warehouse.warehouse_name,
              proveedor:
                ScheduleScheduleOrdersLines[0]
                  ?.ScheduleOrdersLinesSapBusinessPartner?.card_name,
            })

            datesList.push({
              id: item.id,
              title:
                'Proveedor: ' +
                ScheduleScheduleOrdersLines[0]
                  ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
                  ? ScheduleScheduleOrdersLines[0]
                      ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
                  : item.comments
                  ? item.comments
                  : 'CITA',
              date: item.document_date,
              start: item.document_date + 'T' + item.document_time_start,
              end: item.document_date + 'T' + item.document_time_end,
              provider: {
                card_code:
                  ScheduleScheduleOrdersLines[0]
                    ?.ScheduleOrdersLinesSapBusinessPartner?.card_name,
                card_name:
                  ScheduleScheduleOrdersLines[0]
                    ?.ScheduleOrdersLinesSapBusinessPartner?.card_name &&
                  ScheduleScheduleOrdersLines?.ScheduleOrdersLinesSapBusinessPartner?.card_name?.toLowerCase(),
              },
              status_id: item.document_status_id ? item.document_status_id : 1,
              warehouse_name:
                item.warehouse.warehouse_name &&
                item.warehouse.warehouse_name.toLowerCase(),
              comments: item.comments,
              documents_txt:
                docEntries.length > 2
                  ? `${docEntries[0]}, ${docEntries[1]}....  ${
                      docEntries.length - 2
                    } más`
                  : `${docEntriesTxt}`,
            })
            const docks = await GetDocks({
              variables: {
                scheduleId: item.id,
              },
            })
            if (docks.data.getScheduleDocksByScheduleId.length > 0) {
              docks.data.getScheduleDocksByScheduleId.map((dockItem) => {
                docksList.push({
                  schedule_id: item.id,
                  id: dockItem.dock.id,
                  name: dockItem.dock.name,
                })
              })
            }
          }
          setDocks(docksList)
          setCalendarEvents(datesList)
          setDatesHistoryOpen(nextSchedules)
          setCountHistoryOpen(nextSchedules.length)
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
      SetDataEvents()
    }
  }, [data, loading, error])

  useEffect(() => {
    if (!loadingCommodity && !errorCommodity) {
      let list = []
      if (dataCommodity.getAllCommodityReceipt.rows) {
        for (const item of dataCommodity.getAllCommodityReceipt.rows) {
          // const { scheduleLines } = item.schedules
          // const isData = list.find((i) => i.id === item.schedule_id)
          // if (!isData) {
          // let btnCheck = (
          //   <a href="/" target="_blank" download>
          //     <i className="fa fa-file-pdf"></i> Entrada de mercancia
          //   </a>
          // )
          // if (item.sap_purchases_orders.goods_receipt_pdf_id > 0) {
          //   btnCheck = (
          //     <a
          //       href={item.sap_purchases_orders.purchase_order_pdf.url}
          //       target="_blank"
          //       download
          //     >
          //       <i className="fa fa-file-pdf"></i> Entrada de mercancia
          //     </a>
          //   )
          // }

          list.push({
            id: item.schedule_id,
            'Hora y fecha':
              moment(
                item.schedules.document_date +
                  'T' +
                  item.schedules.document_time_start
              ).format('L hh:mm a') +
              ' - ' +
              moment(
                item.schedules.document_date +
                  'T' +
                  item.schedules.document_time_end
              ).format('hh:mm a'),
            almacén: item.schedules.warehouse.warehouse_name,
            proveedor: item?.sap_purchases_orders.business_partner?.card_name,
          })
        }
        // }
      }
      //NO REPEATS
      const clean = list.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id)) {
          unique.push(o)
        }
        return unique
      }, [])

      setDatesHistory(clean)
      setCountHistory(dataCommodity.getAllCommodityReceipt.count)
    }
  }, [dataCommodity, loadingCommodity, errorCommodity])

  // useEffect(() => {
  //   if (!loadingCommodityOpen && !errorCommodityOpen) {
  //     let list = []
  //     if (dataCommodityOpen.getAllCommodityReceipt.rows) {
  //       for (const item of dataCommodityOpen.getAllCommodityReceipt.rows) {
  //         const { scheduleLines } = item.schedules
  //         // const isData = list.find((i) => i.id === item.schedule_id)
  //         // console.log(isData)
  //         // if (!isData) {
  //         list.push({
  //           id: item.schedule_id,
  //           'Hora y fecha':
  //             moment(
  //               item.schedules.document_date +
  //                 'T' +
  //                 item.schedules.document_time_start
  //             ).format('L hh:mm a') +
  //             ' - ' +
  //             moment(
  //               item.schedules.document_date +
  //                 'T' +
  //                 item.schedules.document_time_end
  //             ).format('hh:mm a'),

  //           almacén: item.schedules.warehouse.warehouse_name,
  //           proveedor: scheduleLines[0].business_partner.card_name,
  //         })
  //       }
  //       // }
  //     }
  //     setDatesHistoryOpen(list)
  //     setCountHistoryOpen(dataCommodityOpen.getAllCommodityReceipt.count)
  //   }
  // }, [dataCommodityOpen, loadingCommodityOpen, errorCommodityOpen])

  const handleEvent = (item) => {
    const eventId = item.event.id
    const fdata = calendarEvents.find((item) => item.id === parseInt(eventId))
    const docksList = docks.filter(
      (dock) => dock.schedule_id === parseInt(eventId)
    )
    const { date, start, end, provider, comments, status_id, warehouse_name } =
      fdata

    return MySwal.fire({
      showCloseButton: true,
      showDenyButton: true,
      title: 'Detalles de cita',
      html: (
        <div id="quotesModalBox">
          {date && start && end && (
            <h4>
              <i className="fas fa-clock"></i>{' '}
              {moment(start).format('LL hh:mm a')} -{' '}
              {moment(end).format('hh:mm a')}
            </h4>
          )}
          {provider.card_code && (
            <h5>
              <span className="quoteAttribute">Código de proveedor: </span>
              <span style={{ float: 'right' }}>{provider.card_code}</span>
            </h5>
          )}
          {provider.card_name && (
            <h5 style={{ textTransform: 'capitalize' }}>
              <span className="quoteAttribute">Proveedor: </span>
              <span style={{ float: 'right', textTransform: 'capitalize' }}>
                {provider.card_name}
              </span>
            </h5>
          )}
          {status_id && (
            <h5>
              <span className="quoteAttribute">Estado de cita: </span>
              <span style={{ float: 'right', textTransform: 'capitalize' }}>
                {document_statuses_esp[status_id]}
              </span>
            </h5>
          )}
          {warehouse_name && (
            <h5>
              <span className="quoteAttribute">Almacén: </span>
              <span style={{ float: 'right', textTransform: 'capitalize' }}>
                {warehouse_name}
              </span>
            </h5>
          )}
          {docksList.length > 0 && (
            <h5>
              <span className="quoteAttribute">Puertas: </span>
              <span className="docksBox">
                {docksList.map((dock) => (
                  <span className="docksItem">{docksEsp[dock.id]}</span>
                ))}
              </span>
            </h5>
          )}
          {comments && (
            <h5>
              <span className="quoteAttribute">Observaciones: </span>
              <span style={{ display: 'block', paddingTop: '5px' }}>
                {comments}
              </span>
            </h5>
          )}
          <div>
            <hr />
          </div>
        </div>
      ),
      confirmButtonText: 'Ver más detalles',
      denyButtonText: 'Comenzar recibo',
      focusConfirm: false,
      buttonsStyling: false,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonStandar',
        denyButton: 'StandarModalConfirmButtonStandar',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // if (status_id === 1) {
        //   return history.push(`/commodity/receipt/edit/${eventId}`)
        // }
        return history.push(
          `/commodity/receipt/details/${eventId}/show?calendar=1`
        )
      }
      if (result.isDenied) {
        if (status_id === 1) {
          return history.push(`/commodity/receipt/edit/${eventId}`)
        }
        return history.push(`/commodity/receipt/details/${eventId}/show`)
        // getDownloadHoursValidation({
        //   variables: {
        //     getStartEndDownloadHourAssignationId: +eventId,
        //   },
        // }).then(({ data }) => {
        //   if (!data?.getStartEndDownloadHourAssignation) {
        //     return history.push(`/commodity/receipt/details/${eventId}/show`)
        //   } else {
        //     return history.push(`/commodity/receipt/edit/${eventId}?q=&scan`)
        //   }
        // })
      }
    })
  }

  const handleDetails = (id) => {
    history.push(`/commodity/receipt/details/${id}/show`)
  }

  return (
    <>
      <ContentHeader
        title="Recibo mercancía"
        breadcrumb="Recibo mercancía"
        windowTitle="Recibo"
      />
      <div className="d-flex flex-column">
        <LayoutTable
          classes="order-2 order-md-0"
          data={datesHistoryOpen}
          title="Citas próximas a recibir"
          totalCount={countHistoryOpen}
          paginationServer={false}
          // pagePagination={pagePaginationOpen}
          // setPagePagination={setPagePaginationOpen}
          loadingData={loading}
          handleButton={(e) => handleDetails(e)}
          handleButtonTitle="Detalles"
          hideFilterBar
          titleActions=""
        />
        <Box
          className="order-1"
          title={`Agenda`}
          content={
            loading ? (
              <div className="text-center align-middle ">
                <Loader />
              </div>
            ) : (
              // <div
              //   style={{
              //     width: '100%',
              //     display: 'grid',
              //     gridTemplateColumns: '1fr',
              //   }}
              // >

              // </div>
              <FullCalendarController
                eventsList={calendarEvents}
                handleEvent={handleEvent}
              />
            )
          }
        />
        <LayoutTable
          classes="order-3 order-md-0"
          data={datesHistory}
          title="Historial de recepciones"
          totalCount={countHistory}
          loadingData={loading}
          pagePagination={pagePagination}
          setPagePagination={setPagePagination}
          // hideFilterBar
          titleActions=""
          handleButton={(e) => handleDetails(e)}
          handleButtonTitle="Detalles"
          FilterBarPlaceholder="Buscar por Almacén o Proveedor"
        />
      </div>
    </>
  )
}

export default CommodityReceipt
