import { useEffect, useState } from 'react'
import LayoutTable from '../../Global/LayoutTable'
import { GET_PENDING_ORDERS_STORE } from '../../../graphql/Catalog/Orders/pendingOrdersStore'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { GET_ALL_PICKING_ORDERS_STORE } from '../../../graphql/Catalog/Orders/pickingOrdersStore'
import DatesOptions from '../../Global/DatesOptions'
import StatusBox from '../../Global/StatusBox/StatusBox'

const Picking = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    platformId: 5,
    statusId: 9,
  })

  const { loading, error, data } = useQuery(GET_ALL_PICKING_ORDERS_STORE, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      date: pagePagination.date,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      let list = data.getAllPickingOrdersStore.rows.map((order) => {
        return {
          id: order.id,
          'número de orden': order.order_id,
          'fecha y hora': moment(
            order.order_date,
            'YYYY-MM-DD HH:mm:ss'
          ).format('DD/MM/YYYY HH:mm:ss'),
          almacen: order.warehouse.name,
          'mdo. de pago': order?.paymentPicking?.platform,
          envio: order.method.name,
          estatus: <StatusBox mode={order.status_id} />,
        }
      })

      setDataToTable(list)
      setTotal(data.getAllPickingOrdersStore.count)
    }
  }, [loading, error, data])

  const filterOnChangeSecond = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  return (
    <>
      <LayoutTable
        hideId
        data={dataToTable}
        total={total}
        loadingData={loading}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        filter
        filterOnChange={filterOnChangeSecond}
        filterOptions={[<DatesOptions daysBefore={true} />]}
        filterLabelText="Por fecha"
      />
    </>
  )
}

export default Picking
