import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { GET_DOCKS } from '../../../../graphql/Catalog/Docks/docks'
import { GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID_QUERY } from '../../../../graphql/Documents/ScheduleDocks/scheduleDocks'
import { GET_SCHEDULE_LINES_BY_SCHEDULE_ID } from '../../../../graphql/Documents/ScheduleOrdersLines/scheduleOrdersLines'
import Box from '../../../Global/Box'
import Loader from '../../../Global/Spinner'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { validationSchemaQuote } from '../../../Helpers/validatorQuotes'
import ContentHeader from '../../../Layout/ContentHeader'
import ProvidersQuotesTable from './ProvidersScheduleTable'

const ProvidersScheduleDetails = ({ scheduleData }) => {
  //DEFAULT VALUES
  const someProductsNotFound = 'Algunos productos no se encontraron'
  const docksEsp = {
    1: 'Puerta 1',
    2: 'Puerta 2',
    3: 'Puerta 3',
    4: 'Puerta 4',
  }

  //PROVIDERS INFORMATION
  const [infoProvider, setInfoProvider] = useState()
  //ARRAY FOR PURCHASES ORDERS Ids
  const [purchasesOrdersId, setPurchasesOrdersId] = useState(null)
  //ARRAY FOR ORDERS TO SHOW IN TABLE
  const [orders, setOrders] = useState(null)
  //ARRAY FOR SCHEDULES FORM TO SHOW IN INPUT
  const [schedule, setSchedule] = useState(null)
  //ARRAY FOR SCHEDULES DOCKS
  const [schedulesDocks, setSchedulesDocks] = useState(null)
  //ARRAY FOR DOCKS OPTIONS
  const [docks, setDocks] = useState(null)
  //HISTORY
  const history = useHistory()

  //QUERY TO SEARCH SCHEDULE USING OUR SCHEDULEID
  const { data, loading, error } = useQuery(GET_SCHEDULE_LINES_BY_SCHEDULE_ID, {
    variables: {
      scheduleId: scheduleData.idVar,
      isRecieving: true,
      details: false,
    },
  })

  //GET ALL DOCKS BY ID
  const {
    data: dataDocks,
    loading: loadingDocks,
    error: errorDocks,
  } = useQuery(GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID_QUERY, {
    variables: {
      scheduleId: scheduleData.idVar,
    },
  })

  //GETT ALL DOCKS OF DB
  const {
    data: dataDocksDb,
    loading: loadingDocksDb,
    error: errorDocksDb,
  } = useQuery(GET_DOCKS)

  //YUP FOR EDIT IN A FUTURE
  const {
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchemaQuote),
  })

  //USE EFFECT TO FILL DATA ORDER
  useEffect(() => {
    //SET SCROLL TO TOP
    window.scrollTo(0, 0)

    if (!loading) {
      if (error)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/')
        )
      console.log(data)
      const { scheduleInfo, ScheduleOrdersLines } =
        data?.getScheduleOrdersLinesByScheduleId

      //GET PROVIDER INFO
      const { business_partner } =
        ScheduleOrdersLines[0].ScheduleOrdersLinesSapPurchasesOrders
      setInfoProvider({
        card_code: business_partner.card_code,
        card_name: business_partner.card_name,
        lic_: business_partner.lic_trad_number,
        phone1: business_partner.phone1,
        phone2: business_partner.phone2,
        email: business_partner.email,
        documents: scheduleData?.findData?.document_txt_omitField,
      })

      //GET SCHEDULE FORM INFO
      setSchedule({
        document_date: scheduleInfo?.document_date,
        document_time_start: scheduleInfo?.document_time_start,
        document_time_end: scheduleInfo?.document_time_end,
        comments: scheduleInfo?.comments,
        warehouse_name: scheduleInfo?.warehouse.warehouse_name,
        status: scheduleInfo?.ScheduleDocumentStatus?.name,
      })

      let flagNotFound = false
      let arrayIds = []
      const list = []
      ScheduleOrdersLines.map((orderLine) => {
        const {
          document_date,
          comments,
          number_at_card,
          ScheduleOrdersLinesSapPurchasesOrders,
          document_number,
        } = orderLine
        let purchases_order_id = 0

        //Add id for array
        const itemLines = []
        ScheduleOrdersLinesSapPurchasesOrders.BusinessSapPurchasesPendingOrdersLines.map(
          (line) => {
            purchases_order_id = line.purchases_order_id
            if (line.PurchasesLinesItemInfo) {
              itemLines.push({
                id: line.id,
                purcharse_order_id: line.purchases_order_id
                  ? line.purchases_order_id
                  : '',
                purcharse_document_date: document_date ? document_date : '',
                purchase_number_at_card: number_at_card ? number_at_card : '',
                purcharse_comments: comments ? comments : 'Sin comentarios',
                item_code: line?.PurchasesLinesItemInfo?.item_code
                  ? line?.PurchasesLinesItemInfo?.item_code
                  : '',
                item_name: line?.PurchasesLinesItemInfo?.item_name
                  ? line?.PurchasesLinesItemInfo?.item_name
                  : '',
                quantity: line.quantity ? line.quantity : '',
                total: line.total ? line.total : '',
                all_correct: true,
                document_number: document_number,
              })
              return null
            } else {
              //ITEM NOT FOUND
              flagNotFound = true
              itemLines.push({
                id: line.id,
                purcharse_order_id: line.purchases_order_id
                  ? line.purchases_order_id
                  : '',
                purcharse_document_date: document_date ? document_date : '',
                purchase_number_at_card: number_at_card ? number_at_card : '',
                purcharse_comments: comments ? comments : 'Sin comentarios',

                item_code: line?.PurchasesLinesItemInfo?.item_code
                  ? line?.PurchasesLinesItemInfo?.item_code
                  : '',
                item_name: 'PRODUCTO NO ENCONTRADO',
                quantity: line.quantity ? line.quantity : '',
                all_correct: false,
              })
              return null
            }
          }
        )
        if (arrayIds.includes(purchases_order_id)) return null
        if (arrayIds.indexOf(purchases_order_id) === -1) {
          arrayIds.push(purchases_order_id)
        }
        list.push(itemLines)
        return null
      })

      if (flagNotFound)
        toastSweetAlert({
          mode: 'error',
          message: someProductsNotFound,
        })
      setPurchasesOrdersId(arrayIds)
      setOrders(list)
    }
  }, [loading, data, error])

  //GET SCHEDULES DOCKS
  useEffect(() => {
    if (!loadingDocks) {
      if (errorDocks)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/')
        )

      const res = dataDocks.getScheduleDocksByScheduleIdQuery

      let list = res.map((item) => {
        return { label: docksEsp[item.dock.id], value: item.dock.id }
      })
      setSchedulesDocks(list)
    }
  }, [dataDocks, loadingDocks, errorDocks])

  //GET ALL DOCKS
  useEffect(() => {
    if (!loadingDocksDb) {
      if (errorDocksDb)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/')
        )

      const res = dataDocksDb.getDocks

      const list = res.map((item) => {
        return { value: item.id, label: docksEsp[item.id] }
      })
      setDocks(list)
    }
  }, [dataDocksDb, loadingDocksDb, errorDocksDb])

  if (document.getElementById('commentTextarea'))
    document.getElementById('commentTextarea').readOnly = true

  return (
    <>
      <ContentHeader
        title="Detalle cita"
        breadcrumb="DETALLE DE CITAS DE PROVEEDORES"
        windowTitle="Detalle una cita"
      />

      <div className="row">
        <div className="mb-3 col-md-12 col-lg-6 card-stretch">
          <Box
            title="Detalles de la orden de compra"
            content={
              infoProvider ? (
                <div className="boxQuotesProvider">
                  {infoProvider && (
                    <>
                      <h4>
                        Proveedor:{' '}
                        <span className="resultData">
                          {infoProvider.card_name
                            ? infoProvider.card_name
                            : '---'}
                        </span>{' '}
                      </h4>
                      <hr />
                      <div className="row">
                        {infoProvider.card_code && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Código proveedor:{' '}
                                <span className="resultData">
                                  {infoProvider.card_code}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {infoProvider.lic_ && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                RFC:{' '}
                                <span className="resultData">
                                  {infoProvider.lic_}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {infoProvider.phone1 && !infoProvider.phone2 && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Teléfono:{' '}
                                <span className="resultData">
                                  {infoProvider.phone1}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {!infoProvider.phone1 && infoProvider.phone2 && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Teléfono:{' '}
                                <span className="resultData">
                                  {infoProvider.phone2}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {infoProvider.phone1 && infoProvider.phone2 && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Teléfonos:{' '}
                                <span className="resultData">
                                  {infoProvider.phone1 + ' / '}
                                  {infoProvider.phone2}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {infoProvider.email && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Correo:{' '}
                                <span className="resultData">
                                  {infoProvider.email}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {infoProvider.documents && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Documentos:{' '}
                                <span className="resultData">
                                  {infoProvider.documents}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="text-center">
                  <Loader />
                </div>
              )
            }
          />
        </div>
        <div className="mb-3 col-md-12 col-lg-6 card-stretch">
          <Box
            title="Detalles de cita"
            btnFunction={() => history.push('/schedule/providers')}
            btnFunctionTitle="Regresar"
            withIcons={false}
            content={
              schedule ? (
                <div className="boxQuotesProvider">
                  <div className="row">
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <div className="boxItem">
                        <h4>
                          Almacén:{' '}
                          <span className="resultData">
                            {schedule?.warehouse_name
                              ? schedule.warehouse_name
                              : ''}
                          </span>{' '}
                        </h4>
                      </div>
                    </div>

                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <div className="boxItem">
                        <h4>
                          Puertas:{' '}
                          <span className="resultData">
                            {schedulesDocks?.map((dock) => {
                              return (
                                <span className="docksItem">{dock.label} </span>
                              )
                            })}
                          </span>{' '}
                        </h4>
                      </div>
                    </div>

                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <div className="boxItem">
                        <h4>
                          Fecha de cita:{' '}
                          <span className="resultData">
                            {schedule?.document_date
                              ? schedule.document_date
                              : ''}
                          </span>{' '}
                        </h4>
                      </div>
                    </div>

                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <div className="boxItem">
                        <h4>
                          Hora:{' '}
                          <span className="resultData">
                            {schedule?.document_time_start
                              ? `${schedule.document_time_start} hrs`
                              : ''}{' '}
                            -{' '}
                            {schedule?.document_time_end
                              ? `${schedule.document_time_end} hrs`
                              : ''}
                          </span>{' '}
                        </h4>
                      </div>
                    </div>

                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <div className="boxItem">
                        <h4>
                          Observaciones:{' '}
                          <span className="resultData">
                            {schedule?.comments
                              ? schedule.comments
                              : 'Sin observaciones'}
                          </span>{' '}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <Loader />
                </div>
              )
            }
          />
        </div>
        <div className="mb-3 col-12">
          <Box
            title="Desglose de orden"
            content={
              orders ? (
                orders.map((order, index) => {
                  return (
                    <>
                      <div className="boxQuotesProvider">
                        <div className="boxItem">
                          <h4>
                            {/* Número de orden: {filter[0]?.document_number} */}
                            <span className="resultData"></span>{' '}
                          </h4>
                        </div>
                      </div>
                      <ProvidersQuotesTable
                        key={index}
                        data={order}
                        onlyShow={true}
                        ordersReceivedStep1Hook={true}
                      />
                    </>
                  )
                })
              ) : (
                <div className="text-center">
                  <Loader />
                </div>
              )
            }
          />
        </div>
      </div>
    </>
  )
}

export default ProvidersScheduleDetails
