import { useState } from "react"
import ModalContent from "../../Global/ModalContent"
import { toastSweetAlert } from "../../Helpers/ToastSweetAlert"

const ModalWarehouseReports =({
    refModal, //Ref to interact Modal
    handleSubmit = ()=> {}, //Submit Function.
    setForm, //Hook to save reports data
    form, //Hook to save reports data
})=> {
    const [showDateRange, setShowDateRange] = useState(false)

    //To listen radio buttons changes
    const handleRadioOnChange = (e,date = 0) => {
        try {
            if (e.target.id == 'custom-report' || date != 0) setShowDateRange(true)
            else setShowDateRange(false)
      
            if(date == 1 || date == 2)//Date start or end
                setForm({
                    ...form,
                    start: date == 1 ? e.target.value : form.start,
                    end: date == 2 ? e.target.value : form.end
                })
            else
                setForm({
                    ...form,
                    reportsType: Number(e.target.value)
                })
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }

    //When user touch cancel button
    const handleCancelButton = () => {
        //Reset hook
        setForm({
            start: '',
            end: '',
            reportsType: 0,
        })
        setShowDateRange(false)
        refModal.current.click()
    }

    return (
        <>
            <span
                ref={refModal}
                className="btn btn-accept btn-sm invisible"
                data-toggle="modal"
                data-target="#detailsReports"
                data-backdrop="static"
                data-keyboard="false"
                data-dismiss="modal"
            />
            <ModalContent
                idModal="detailsReports"
                title="Generar reporte"
                size="large"
                onClose={handleCancelButton}
                content={
                <>
                    {/* {dataType != 2 && ( */}
                    <>
                        <h5 className="">Tipo de Reporte</h5>
                        <hr />

                        <div className="my-4">
                        <div className="d-flex align-items-center mb-2">
                            <input
                                type="radio"
                                id="last-seven-days-ago"
                                name="report"
                                value={1}
                                checked={form.reportsType == 1 ? true : false}
                                onChange={(e) => handleRadioOnChange(e,0)}
                            />
                            <label
                                className="m-0 ml-2 font-weight-normal"
                                htmlFor="last-seven-days-ago"
                            >
                            Últimos 7 días
                            </label>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <input
                                type="radio"
                                id="last-month"
                                name="report"
                                value={2}
                                checked={form.reportsType == 2 ? true : false}
                                onChange={(e) => handleRadioOnChange(e,0)}
                            />
                            <label
                                className="m-0 ml-2 font-weight-normal"
                                htmlFor="last-month"
                            >
                            Último mes
                            </label>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <input
                                type="radio"
                                id="year-to-date"
                                name="report"
                                value={3}
                                checked={form.reportsType == 3 ? true : false}
                                onChange={(e) => handleRadioOnChange(e,0)}
                            />
                            <label
                                className="m-0 ml-2 font-weight-normal"
                                htmlFor="year-to-date"
                            >
                            Año a la fecha
                            </label>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <input
                                type="radio"
                                id="rolling-year"
                                name="report"
                                value={4}
                                checked={form.reportsType == 4 ? true : false}
                                onChange={(e) => handleRadioOnChange(e,0)}
                            />
                            <label
                                className="m-0 ml-2 font-weight-normal"
                                htmlFor="rolling-year"
                            >
                            Últimos 12 meses
                            </label>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <input
                                type="radio"
                                id="custom-report"
                                name="report"
                                value={5}
                                checked={form.reportsType == 5 ? true : false}
                                onChange={(e) => handleRadioOnChange(e,0)}
                            />
                            <label
                                className="m-0 ml-2 font-weight-normal"
                                htmlFor="custom-report"
                            >
                            Rango de tiempo personalizado
                            </label>
                        </div>
                        </div>
                    </>
                    {/* )} */}

                    {showDateRange && (
                    <div className="row justify-content-evenly">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex align-items-center">
                        <p className="m-0">Desde: </p>
                        <input
                            className="inputDate mx-auto date-picker-size"
                            value={form.start}
                            onChange={(e) => handleRadioOnChange(e,1)}
                            type="date"
                        />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex align-items-center ">
                        <p className="m-0">Hasta:</p>
                        <input
                            className="inputDate mx-auto date-picker-size"
                            value={form.end}
                            onChange={(e) => handleRadioOnChange(e,2)}
                            type="date"
                        />
                        </div>
                    </div>
                    )}

                    <div className="d-flex justify-content-end mt-5">
                    <button
                        className="btn StandarModalCancelButtonLogOut text-capitalize mr-3"
                        onClick={handleCancelButton}
                    >
                        Cancelar
                    </button>
                    <button
                        className="btn StandarModalConfirmButtonLogOut"
                        onClick={handleSubmit}
                    >
                        Generar reporte
                    </button>
                    </div>
                </>
                }
            />
        </>

    )
}

export default ModalWarehouseReports