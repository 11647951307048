import { gql } from '@apollo/client'

export const FIND_BUSINESS_PARTNERS = gql`
  query Rows($searchQuery: String) {
    findSapBusinessPartner(searchQuery: $searchQuery) {
      rows {
        id
        card_code
        card_name
        card_type
        group_code
        partner_group {
          id
          group_code
          group_name
          group_type
          price_list
        }
        list_number
        list {
          id
          list_number
          list_name
          base_number
          round_system
        }
        group_number
        group {
          id
          group_number
          payment_group
          pay_due_month
          extra_month
          extra_days
          payments_number
          installments_number
          open_reception
        }
        credit_line
        debit_line
        discount
        phone1
        phone2
        email
        lic_trad_number
      }
      count
    }
  }
`
export const GET_RETURN_DOCUMENT = gql`
  query GetOneReturnDocument($getOneReturnDocumentId: Int!) {
    getOneReturnDocument(id: $getOneReturnDocumentId) {
      id
      document_entry
      document_number
      document_type
      canceled
      document_status
      object_type
      document_date
      document_due_date
      business_partner_id
      card_code
      card_name
      number_at_card
      document_total
      discount_percet
      paid_to_date
      comments
      transaction_id
      series
      warehouses_id
      Rows {
        id
        return_document_id
        line_number
        target_document_type
        target_entry
        target_number
        base_entry
        base_number
        base_type
        line_status
        item_id
        item_code
        item_name
        quantity
        open_quantity
        price
        currency
        rate
        discount_percet
        line_total
        open_sum
        warehouses_code
        tree_type
        price_before_discount
        codebars
        vat_percent
        tax_code
        ieps
        iva
      }
      Receipts {
        id
        user_id
        return_row_id
        return_document_id
        reason_return_id
        quantity
        Reason {
          id
          name
        }
      }
    }
  }
`
export const GET_ALL_RETURN_DOCUMENTS = gql`
  query GetAllReturnDocuments(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $status: String
    $objectType: String
  ) {
    getAllReturnDocuments(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      status: $status
      object_type: $objectType
    ) {
      rows {
        id
        document_entry
        document_number
        document_type
        canceled
        document_status
        object_type
        document_date
        document_due_date
        business_partner_id
        card_code
        card_name
        number_at_card
        document_total
        discount_percet
        paid_to_date
        comments
        transaction_id
        series
        warehouses_id
        Rows {
          id
          return_document_id
          line_number
          target_document_type
          target_entry
          target_number
          base_entry
          base_number
          base_type
          line_status
          item_id
          item_code
          item_name
          quantity
          open_quantity
          price
          currency
          rate
          discount_percet
          line_total
          open_sum
          warehouses_code
          tree_type
          price_before_discount
          codebars
          vat_percent
          tax_code
          ieps
          iva
        }
      }
      count
    }
  }
`
export const GET_SBO_RETURN_DOCUMENT = gql`
  mutation GetReturnFromSBO(
    $documentNumber: String!
    $businessPartnerId: Int!
  ) {
    getReturnFromSBO(
      document_number: $documentNumber
      business_partner_id: $businessPartnerId
    ) {
      id
      document_entry
      document_number
      document_type
      canceled
      document_status
      object_type
      document_date
      document_due_date
      business_partner_id
      card_code
      card_name
      number_at_card
      document_total
      discount_percet
      paid_to_date
      comments
      transaction_id
      series
      warehouses_id
    }
  }
`
export const GET_SBO_RETURN_DOCUMENT_LINES = gql`
  mutation GetReturnLinesFromSBO($documentNumber: Int, $documentType: String) {
    getReturnLinesFromSBO(
      document_number: $documentNumber
      document_type: $documentType
    ) {
      id
      return_document_id
      line_number
      target_document_type
      target_entry
      target_number
      base_entry
      base_number
      base_type
      line_status
      item_id
      item_code
      item_name
      quantity
      open_quantity
      price
      currency
      rate
      discount_percet
      line_total
      open_sum
      warehouses_code
      tree_type
      price_before_discount
      codebars
      vat_percent
      tax_code
      ieps
      iva
    }
  }
`

export const GET_ALL_RETURN_REASON = gql`
  query GetAllReasonsForReturn(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $objectType: String
  ) {
    getAllReasonsForReturn(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      object_type: $objectType
    ) {
      name
      id
      for_object_type
      action
    }
  }
`
export const GET_OPTIONS_CARDS_RETURN = gql`
  query GetOptionsCardsReturn($documentType: String!) {
    getOptionsCardsReturn(document_type: $documentType) {
      id
      card_code
      card_name
    }
  }
`

export const CREATE_RETURN_REQUEST_DOCUMENT = gql`
  mutation CreateReturRequest($input: ReturnDocumentInput!) {
    createReturRequest(input: $input)
  }
`
export const CREATE_DETAIL_RETURN_RECEIPT = gql`
  mutation CreateDetailReturnsReceipt($input: DetailReturnsReceiptInput!) {
    createDetailReturnsReceipt(input: $input)
  }
`
export const ADD_SBO_RETURN_REQUEST = gql`
  mutation AddSboReturnRequest($addSboReturnRequestId: Int!) {
    addSboReturnRequest(id: $addSboReturnRequestId)
  }
`
export const DELETE_DETAIL_RETURN_RECEIPT = gql`
  mutation DeleteDetailReturnsReceipt($deleteDetailReturnsReceiptId: Int) {
    deleteDetailReturnsReceipt(id: $deleteDetailReturnsReceiptId)
  }
`
export const CANCEL_RETURN_REQUEST = gql`
  mutation CancelReturnRequest($cancelReturnRequestId: Int) {
    cancelReturnRequest(id: $cancelReturnRequestId)
  }
`

export const UPDATE_RETURN_REQUEST = gql`
  mutation UpdateReturRequest(
    $updateReturRequestId: Int!
    $input: ReturnDocumentUpdateInput!
  ) {
    updateReturRequest(id: $updateReturRequestId, input: $input)
  }
`
export const GET_RETURN_DOCUMENT_BY_PURCHASE_ORDER = gql`
  query GetOneReturnDocumentByPurchaseOrder($docEntry: Int!) {
    getOneReturnDocumentByPurchaseOrder(docEntry: $docEntry) {
      Receipts {
        id
        return_document_id
        return_row_id
        user_id
        reason_return_id
        line_number
        base_entry
        base_number
        base_type
        line_status
        quantity
        warehouse_code
        from_warehouse_code
        to_warehouse_code
      }
      Rows {
        id
        return_document_id
        line_number
        target_document_type
        target_entry
        target_number
        base_entry
        base_number
        base_type
        line_status
        item_id
        item_code
        item_name
        quantity
        open_quantity
        price
        currency
        rate
        discount_percet
        line_total
        open_sum
        warehouses_code
        tree_type
        price_before_discount
        codebars
        vat_percent
        tax_code
        ieps
        iva
      }
      business_partner_id
      canceled
      card_code
      card_name
      comments
      discount_percet
      document_date
      document_due_date
      document_entry
      document_number
      document_status
      document_total
      document_type
      id
      number_at_card
      object_type
      paid_to_date
      series
      transaction_id
      warehouses_id
    }
  }
`
export const EXPORT_ALL_RETURN_DOCUMENTS = gql`
  mutation ExportAllReturnDocuments(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $status: String
  ) {
    exportAllReturnDocuments(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      status: $status
    ) {
      id
      object_type
      document_number
      canceled
      document_status
      document_date
      card_code
      card_name
      comments
    }
  }
`
