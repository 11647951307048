import { useEffect, useRef, useState } from 'react'
import InputController from '../../../Global/InputController'
import LayoutTable from '../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_PRODUCTS_SAP } from '../../../../graphql/Catalog/Transfers/Transfers'
import { useMutation } from '@apollo/client'
import { useTransferContext } from './TransferContext'

const ProductsFound = ({ control, getValues }) => {
  //ESTADOS COMPARTIDOS
  const {
    warehouseOriginSelected,
    flagWarehouse,
    query,
    findProducts,
    setQuery,
    setFindProducts,
    setDataItem,
    refModal,
  } = useTransferContext()
  /////

  const [
    getProductsSap,
    {
      data: dataProductsSap,
      loading: loadingProductsSap,
      error: errorProductsSap,
    },
  ] = useMutation(GET_PRODUCTS_SAP, { fetchPolicy: 'no-cache' })

  // const [mutationGetTransferLines] = useMutation(MUTATION_GET_TRANSFER_LINES_BY_TRANSFER_REQUEST)

  useEffect(() => {
    try {
      if (!query) return
      if (loadingProductsSap) return
      if (errorProductsSap)
        return toastSweetAlert({
          mode: 'error',
          message: errorProductsSap.message,
        })

      const list = dataProductsSap.getProductSap.map((findItem) => {
        let stockOriginName
        let stockDestinyName

        if (findItem?.WarehouseToProducts?.warehouse_name.includes('TIENDA')) {
          stockOriginName = 'Stock Tienda'
          stockDestinyName = 'Stock Tlajomulco'
        } else {
          stockOriginName = 'Stock Tlajomulco'
          stockDestinyName = 'Stock Tienda'
        }

        return {
          // 'Código de barras': findItem.SapItem_item_code_bar,
          id: findItem.id,
          SKU: findItem.item_code,
          Nombre: findItem.item_name,
          [stockOriginName]: findItem.on_hand,
          [stockDestinyName]: findItem.on_hand_destiny,
          'Código de barras': findItem.barcode,
          CajasHide: parseInt(
            parseInt(findItem.on_hand ? findItem.on_hand : 0) /
              parseInt(findItem.sal_pack_un ? findItem.sal_pack_un : 0)
          ),
          UnidadHide:
            parseInt(findItem.sal_pack_un) > findItem.on_hand
              ? findItem.on_hand + 1
              : parseInt(findItem.sal_pack_un),
          stock_omitField: findItem.on_hand,
        }
      })

      setFindProducts(list)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [dataProductsSap, loadingProductsSap, errorProductsSap])

  useEffect(() => {
    try {
      async function getProduct() {
        try {
          await getProductsSap({
            variables: {
              searchQuery: query,
              // warehouseCode: getValues('source_warehouse_sap'),
              warehouseCode: warehouseOriginSelected
                ? warehouseOriginSelected
                : getValues('source_warehouse_sap'),
              warehouseCodeDestiny: getValues('destination_warehouse_sap'),
            },
          })
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }

      if (query?.length === 0) {
        return toastSweetAlert({
          mode: 'error',
          message:
            'Digite el código de barras, sku o nombre para buscar un producto',
        })
      }

      if (query) {
        getProduct()
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const handleSearchProductSap = async (value) => {
    try {
      if (value.key === 'Enter') {
        if (!getValues('source_warehouse_sap'))
          return toastSweetAlert({
            mode: 'error',
            message: 'No has seleccionado un almacén de origen',
          })

        const query = value.target.value.toLowerCase()

        if (!query) setFindProducts([])

        setQuery(getValues('product_sap'))
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <div className="mb-3 col-lg-10 col-md-12 col-sm-12 ">
        <div className="input-icons mb-3">
          <InputController
            id="searchBar"
            label="Buscar producto"
            type="text"
            control={control}
            name="product_sap"
            onKeyDown={handleSearchProductSap}
            disabled={flagWarehouse && !warehouseOriginSelected}
            placeholder="Buscar por código, nombre..."
          />
          <InputController
            id="flagSourceWarehouseSelected"
            name="flagSourceWarehouseSelected"
            hidden
            control={control}
          />
        </div>
      </div>
      <div className="mb-3 col-lg-12 col-md-12 col-sm-12 ">
        <LayoutTable
          hideId
          data={findProducts}
          title="Productos encontrados"
          // handleButton={handleAddProduct}
          handleButtonGetCompleteRow={true}
          handleButton={(row) => {
            setDataItem(row)
            refModal.current.click()
          }}
          handleButtonTitle={'Agregar producto'}
          //paginationServer={flagWarehouse}
          paginationServer={false}
          loadingData={loadingProductsSap}
          hideFilterBar
          titleActions=""
        />
      </div>
    </>
  )
}

export default ProductsFound
