import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  DELETE_ROUTE,
  GET_ALL_ROUTES,
  EXPORT_ROUTES,
} from '../../../../graphql/Catalog/Routes/routes'
import LayoutTable from '../../../Global/LayoutTable'
import ContentHeader from '../../../Layout/ContentHeader'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'

export const Routes = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_ALL_ROUTES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
  })
  const [dataToTable, setDataToTable] = useState([])

  useEffect(() => {
    if (!loading) {
      if (error)
        toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data?.getAllRoutes?.rows.map((item) => {
        return list.push({
          id: item.id,
          Nombre: item.name,
          Descripción: item.description,
        })
      })
      setDataToTable(list)
      setTotal(data?.getAllRoutes?.count)
    }
  }, [loading, error, data])

  return (
    <>
      {/* <ContentHeader title="Rutas" breadcrumb="Rutas" windowTitle="Rutas" /> */}
      <LayoutTable
        data={dataToTable}
        title="Rutas"
        gql={DELETE_ROUTE}
        requery={GET_ALL_ROUTES}
        exportQuery={EXPORT_ROUTES}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
      />
    </>
  )
}

export default Routes
