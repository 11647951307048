import React, { useState } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import { useParams } from 'react-router-dom'
import OrderDetails from './Sections/OrderDetails'
import OrderDetailsStatus from './Sections/OrderDetailsStatus'
import OrderSummary from './Sections/OrderSummary'
import NewOrder from './Sections/NewOrder'

export const TelemarketingOrderHistoryNew = () => {
  const { id: _id, show } = useParams()

  const [dataOrder, setDataOrder] = useState({})

  const [showOrderSummaryCard, setShowOrderSummaryCard] = useState(false)

  const [comesFromSummary, setComesFromSummary]  = useState(false)
  const [paymentMethodSelected, setPaymentMethodSelected]  = useState(false)
  const [deliveryDateSelected, setDeliveryDateSelected]  = useState(false)
  const [deliveryTimeSelected, setDeliveryTimeSelected]  = useState(false)

  const handleCancelOrderSummary = (data) => {
    setComesFromSummary(data.comesFromSummary ? true : false)
    setPaymentMethodSelected(data.paymentMethodSelected)
    setDeliveryDateSelected(data.deliveryDateSelected)
    setDeliveryTimeSelected(data.deliveryTimeSelected)
    setShowOrderSummaryCard(false)
  }

  const getDataOrder = (dataOrder) => {
    setDataOrder(dataOrder)
    setShowOrderSummaryCard(true)
  }

  return (
    <>
      <ContentHeader
        title={show ? "Detalles del pedido" : "Nuevo pedido"}
        breadcrumb={`Pedidos / ${show ? "Detalles del pedido" : "Nuevo pedido"}`}
        windowTitle={show ? "Detalles del pedido" : "Nuevo pedido"}
      />
      {show == "status" ?
          <OrderDetailsStatus />  
        : show ? 
            <OrderDetails _id={_id} /> 
          : showOrderSummaryCard ? 
              <OrderSummary 
                idCustomer={dataOrder.idCustomer}
                dataCart={dataOrder.dataCart}
                totalCart={dataOrder.totalCart}
                handleCancelOrderSummary={( e ) => { handleCancelOrderSummary(e) }}
                paymentMethodSelected={dataOrder.paymentMethodSelected}
                deliveryDateSelected={dataOrder.deliveryDateSelected}
                deliveryTimeSelected={dataOrder.deliveryTimeSelected}
              /> 
            : <NewOrder
                comesFromSummary={comesFromSummary}
                paymentMethod={paymentMethodSelected}
                deliveryDate={deliveryDateSelected}
                deliveryTime={deliveryTimeSelected}
                getDataOrder={( e ) => { getDataOrder(e) }} 
              />
      }
    </>
  )
}

export default TelemarketingOrderHistoryNew
