export const STYLES_TAGS_20 = `
              @media print{
                @page {
                  size: 21.5cm 28.3cm;
                  margin-left: 0.5cm;
                  margin-right: 1cm;
                  margin-top: 1.7cm;
                  margin-button: 2cm;
                }

                .page-print-labels {
                    display: grid;
                    grid-template-columns: repeat(4, calc(20cm / 4)); /* Tamaño de las columnas */
                    grid-template-rows: repeat(5, calc(25cm / 5)); /* Tamaño de las filas */
                    // gap: 1mm; 
                    // margin-top: 15mm;
                    // margin-bottom: 0cm;
                    // border: 1px solid #000;
                }

                  .card-label-item {
                      width: 5cm;
                      height: 5cm;
                      // margin: 0;
                      // padding: 5mm;
                      margin-top: 0.5cm;
                      // margin-bottom: 0.5cm;
                      // border: 1px solid #000;
                  }

                  .label-item-sku-container {
                    display: flex;
                    justify-content: flex-end;
                    height: 10%;
                    // border: 1px solid green;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                    .label-item-sku {
                      margin-right: 15px;
                      font-size: 10px;
                      margin-top: 7px;
                      color: #000;
                    }

                  .label-item-container{
                      display: flex;
                      flex-direction: column;
                      flex-wrap: wrap;
                      justify-content: center;
                      align-items: center;
                      // border: 1px solid red;
                      height: 30%;
                      width: 90%;
                      margin-left: 5%;
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-style: normal;
                      font-display: swap;
                  }

                  .label-item {
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                    color: #000;
                  }

                  .label-item-price-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid blue;
                    height: 20%;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }


                  .card-label-item .label-item-price {
                    font-size: 26px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                  }

                  .container-barcode-date{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid blue;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                  .barcode-image-container {
                    // margin-top: 5px;
                    // border: 1px solid blue;
                    height: 30%;
                  }

                  .card-label-item .label-item-barcode {
                    width: 110px;
                    height: 25px;
                  }

                  .card-label-item .label-item-date {
                    font-size: 7px;
                    color: #000;
                  }
                  .new-page{
                    page-break-after: always;
                  }
                  .marginForPageBreak{
                    margin-bottom: -2cm;
                    margin-top: 2cm;
                    margin-left: 0.3cm;
                    margin-right: 0.75cm;
                    page-break-inside: avoid;
                  }
            }
            `
export const STYLES_TAGS_24 = `
            @media print{
                @page {
                  size: 21.5cm 27.9cm;
                  margin-left: 0.5cm;
                  margin-right: 1cm;
                  margin-top: 1.7cm;
                  // margin-bottom: 2cm;
                }

                .page-print-labels {
                    display: grid;
                    grid-template-columns: repeat(4, calc(20cm / 4)); /* Tamaño de las columnas */
                    grid-template-rows: repeat(6, calc(24cm / 6)); /* Tamaño de las filas */
                    // gap: 1mm; 
                    // margin-top: 15mm;
                    // margin-bottom: 0cm;
                    // border: 1px solid #000;
                }

                  .card-label-item {
                      width: 4cm;
                      height: 4.4cm;
                      transform: rotate(90deg);
                      // margin: 0;
                      // padding: 5mm;
                      // margin-top: 0.5cm;
                      // margin-bottom: 0.5cm;
                      // border: 1px solid red;
                  }

                  .label-item-sku-container {
                    display: flex;
                    justify-content: flex-end;
                    height: 10%;
                    width: 80%;
                    // border: 1px solid green;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                    .label-item-sku {
                      margin-right: 15px;
                      font-size: 10px;
                      // margin-top: 7px;
                      color: #000;
                    }

                  .label-item-container{
                      display: flex;
                      flex-direction: column;
                      flex-wrap: wrap;
                      justify-content: center;
                      align-items: center;
                      // border: 1px solid red;
                      height: 30%;
                      width: 80%;
                      margin-left: 10%;
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-style: normal;
                      font-display: swap;
                  }

                  .label-item {
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                    color: #000;
                  }

                  .label-item-price-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10%;
                    // border: 1px solid blue;
                    height: 20%;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }


                  .card-label-item .label-item-price {
                    font-size: 26px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                  }

                  .container-barcode-date{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid blue;
                    // width: 80%;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                    margin-left: 10%;
                  }

                  .barcode-image-container {
                    // margin-top: 5px;
                    // border: 1px solid blue;
                    height: 25%;
                  }

                  .card-label-item .label-item-barcode {
                    width: 98px;
                    height: 25px;
                  }

                  .card-label-item .label-item-date {
                    font-size: 7px;
                    color: #000;
                  }
                  .new-page{
                    page-break-after: always;
                  }
                  .marginForPageBreak{
                    margin-bottom: -2cm;
                    margin-top: 2cm;
                    margin-left: 0.3cm;
                    margin-right: 0.75cm;
                    page-break-inside: avoid;
                  }
                }
            }
            `

export const DISCOUNT_STYLES_TAGS_20 = `
   @media print{
                @page {
                  size: 21.5cm 28.3cm;
                  margin-left: 0.5cm;
                  margin-right: 1cm;
                  margin-top: 1.7cm;
                  // margin-bottom: 2cm;
                }

                .page-print-labels {
                  display: grid;
                  grid-template-columns: repeat(4, calc(20cm / 4)); /* Tamaño de las columnas */
                  grid-template-rows: repeat(5, calc(25cm / 5)); /* Tamaño de las filas */
                  // gap: 1mm; 
                  //width: 21.5cm;
                  //height: 27.9cm;
                  // border: 1px solid #ff8000;
                  // margin-top: 15mm;
                }

              .card-label-item{
                  // display: flex;
                  // flex-direction: column;
                  // align-items: center;
                  width: 5cm;
                  height: 5cm;
                  // border: 1px solid blue;
                }

                .label-item-sku-date-container{
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 90%;
                  height: 10%;
                  // border: 1px solid red;
                  margin-left: 5%;
                  margin-right: 5%;
                  margin-top: 10px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }

                .label-item-sku-date-container .label-item-sku{
                  // margin-right: 15px;
                  font-size: 12px;
                  // margin-top: 5px;
                  font-weight: bold;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }

                .label-item-sku-date-container .label-item-date-until{
                  font-size: 12px;
                  // margin-top: 5px;
                  font-weight: bold;
                }

                .label-item-container{
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 90%;
                  height: 30%;
                  // border: 1px solid green;
                  // margin-left: 10%;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }
                

                .label-item {
                  font-size: 14px;
                  font-weight: bold;
                  text-align: center;
                  color: #000;
                }

                  .label-item-price-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid brown;
                    height: 10%;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }


                  .label-item-price {
                    font-size: 28px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                  }

                  .label-item-price-normal {
                    font-size: 15px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                    text-decoration: line-through;
                    text-decoration-color: red;
                    text-decoration-style: solid;
                    text-decoration-thickness: 1px;
                  }

                  .label-item-discount-price-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid violet;
                    height: 20%;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                  .label-item-price-discount {
                    font-size: 24px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                  }

                  .container-barcode-date{
                    display: flex;
                    justify-content: center;
                    // align-items: center;
                    // border: 1px solid yellow;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                  .barcode-image-container {
                    // margin-top: 5px;
                    // border: 1px solid #408080;
                    height: 30%;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                  .card-label-item .label-item-barcode {
                    width: 110px;
                    height: 25px;
                    // border: 1px solid #408080;
                    margin-bottom: 0px;
                  }

                  .card-label-item .label-item-date {
                    font-size: 7px;
                    color: #000;
                    // margin-top: 25px;
                  }

                  .new-page{
                    page-break-after: always;
                  }

                  .marginForPageBreak{
                    margin-bottom: -2cm;
                    margin-top: 2cm;
                    margin-left: 0.3cm;
                    margin-right: 0.75cm;
                    page-break-inside: avoid;
                  }
                }
            }
            `
export const DISCOUNT_STYLES_TAGS_24 = `
            @media print{
                @page {
                  size: 21.5cm 27.9cm;
                  margin-left: 0.5cm;
                  margin-right: 1cm;
                  margin-top: 1.7cm;
                  // margin-bottom: 2cm;
                }

                .page-print-labels {
                  display: grid;
                  grid-template-columns: repeat(4, calc(20cm / 4)); /* Tamaño de las columnas */
                  grid-template-rows: repeat(6, calc(24cm / 6)); /* Tamaño de las filas */
                  gap: 1mm;
                  margin-left: 0.5cm;
                  //width: 21.5cm;
                  //height: 27.9cm;
                  // border: 1px solid #ff8000;
                  // margin-top: 15mm;
                }

              .card-label-item{
                  display: flex;
                  flex-direction: column;
                  // align-items: center;
                  width: 4cm;
                  height: 4.4cm;
                  transform: rotate(90deg);
                  // border: 1px solid blue;
                }

                .label-item-sku-date-container{
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 80%;
                  height: 10%;
                  // border: 1px solid red;
                  margin-left: 10%;
                  // margin-right: 5%;
                  // margin-top: 10px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }

                .label-item-sku-date-container .label-item-sku{
                  // margin-right: 15px;
                  margin-left: 5%;
                  font-size: 10px;
                  // margin-top: 5px;
                  font-weight: bold;
                }

                .label-item-sku-date-container .label-item-date-until{
                  font-size: 10px;
                  // margin-top: 5px;
                  font-weight: bold;
                }

                .label-item-container{
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 80%;
                  height: 30%;
                  // border: 1px solid green;
                  margin-left: 5%;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }
                

                .label-item {
                  font-size: 12px;
                  font-weight: bold;
                  text-align: center;
                  color: #000;
                }

                  .label-item-price-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid brown;
                    height: 10%;
                    width: 85%;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }


                  .label-item-price {
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                  }

                  .label-item-price-normal {
                    font-size: 15px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                    text-decoration: line-through;
                    text-decoration-color: red;
                    text-decoration-style: solid;
                    text-decoration-thickness: 1px;
                  }

                  .label-item-discount-price-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid violet;
                    width: 85%;
                    height: 25%;
                    margin-top: 5px;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                  .label-item-price-discount {
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                  }

                  .container-barcode-date{
                    display: flex;
                    justify-content: center;
                    // align-items: center;
                    // border: 1px solid yellow;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    font-display: swap;
                  }

                  .barcode-image-container {
                    // margin-top: 5px;
                    // border: 1px solid #408080;
                    height: 10%;
                  }

                  .card-label-item .label-item-barcode {
                    width: 85px;
                    height: 25px;
                    // border: 1px solid #408080;
                    margin-bottom: 0px;
                  }

                  .card-label-item .label-item-date {
                    font-size: 7px;
                    color: #000;
                    // margin-top: 25px;
                  }

                  .new-page{
                    page-break-after: always;
                  }

                  .marginForPageBreak{
                    margin-bottom: -2cm;
                    margin-top: 2cm;
                    margin-left: 0.3cm;
                    margin-right: 0.75cm;
                    page-break-inside: avoid;
                  }
                }
            }
            `

//Labels for frezeer 2.8cm x 8.4cm
/* 
       DATA EXAMPLE
       LOGO VA, SKU, FECHA

        DESCRIPCION

        codigo de barras y precio
    */

export const FRIDGE_STYLES_TAGS = `
            @media print{
                @page {
                  size: 21.5cm 27.9cm;
                }

                .page-print-labels{
                  display: grid;
                  grid-template-columns: repeat(2, calc(20cm / 2)); /* Tamaño de las columnas */
                  grid-template-rows: repeat(9, calc(22.7cm / 9)); /* Tamaño de las filas */
                  gap: 4mm;
                  // border: 1px solid black;
                }

                .card-label-item{
                  display: flex;
                  flex-direction: column;
                  width: 8cm;
                  height: 2.85cm;
                  border: 1px dashed black;
                  // margin: 3px;
                }

                .logo-sku-date-container{
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  width: 50%;
                  height: 20%;
                  // border: 1px solid green;
                  // margin-right: px;
                  margin-left: 75px;
                  margin-top: 5px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }

                .logo-va-container{
                  width: 70%;
                  height: 100%;
                  // border: 1px solid red;
                }

                .logo-va{
                  width: 100%;
                  height: 100%;
                }

                .label-item-sku{
                  font-size: 12px;
                  margin-top: 5px;
                  font-weight: bold;
                }

                .label-item-date{
                  font-size: 12px;
                  margin-top: 5px;
                  font-weight: bold;
                }

                .label-item-description-cotainer{
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  height: 40%;
                  // border: 1px solid blue;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }

                .label-item-description{
                  font-size: 12px;
                  font-weight: bold;
                  text-align: center;
                }

                .label-item-barcode-price-container{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: start;
                  width: 100%;
                  height: 40%;
                  // border: 1px solid violet;
                  margin-bottom: 10px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-style: normal;
                  font-display: swap;
                }

                .barcode-image-container{
                  width: 70%;
                  height: 80%;
                }

                .label-item-barcode{
                  width: 65%;
                  height: 100%;
                  margin-left: 40px;
                }

                .label-item-price-container{
                  width: 30%;
                  height: 100%;
                  // border: 1px solid brown;
                }

                .label-item-price{
                  font-size: 18px;
                  font-weight: bold;
                  text-align: center;
                }

                .container-push-up{
                  width: 100%;
                  height: 10%;
                  // border: 1px solid yellow;
                }

                .new-page{
                  page-break-after: always;
                }

                .marginForPageBreak{
                  // margin-bottom: -2cm;
                  // margin-top: 2cm;
                  // margin-left: 0.3cm;
                  // margin-right: 0.75cm;
                  page-break-inside: avoid;
                }
            }
`
