import { gql } from '@apollo/client'

export const GET_SAP_ITEMS = gql`
  query GetSapItems($limit: Int, $offset: Int) {
    getSapItems(limit: $limit, offset: $offset) {
      id
      item_code
      item_name
      item_group_code
      item_code_bar
      purchase_pack_msr
      purchase_pack_un
      sal_pack_msr
      sal_pack_un
      indirect_tax
      card_code
      tax_code_ar
      tax_code_ap
      inventory_item
      sell_item
      purchase_item
      business_partner_id
    }
  }
`
export const GET_ALL_ITEM_GROUPS = gql`
  query GetAllSapItemGroups {
    getAllSapItemGroups {
      id
      items_group_code
      items_group_name
      locked
    }
  }
`
export const GET_ITEM_MASTER_DATA = gql`
  query GetOneSapItem($itemCode: String!) {
    getOneSapItem(item_code: $itemCode) {
      id
      item_code
      item_name
      item_group_code
      item_code_bar
      purchase_pack_msr
      purchase_pack_un
      sal_pack_msr
      sal_pack_un
      indirect_tax
      card_code
      tax_code_ar
      tax_code_ap
      inventory_item
      sell_item
      purchase_item
      business_partner_id
      hasHarvest
      expires
    }
  }
`

export const GET_ITEMS_IN_STORE = gql`
  query GetItemsInStore($searchQuery: String, $limit: Int, $offset: Int) {
    getItemsInStore(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      count
      rows {
        id
        item_code
        item_name
        item_code_bar
        item_group_code
        purchase_item
        purchase_pack_msr
        purchase_pack_un
        sal_pack_msr
        sal_pack_un
        sell_item
        tax_code_ap
        tax_code_ar
        inventory_item
        indirect_tax
        business_partner_id
        card_code
        expires
        hasHarvest
        ItemPrices {
          item_code
          price_list
          price
          factor
          base_num
        }
        SapItemsToStockOne {
          item_code
          on_hand
          warehouse_code
        }
        # SapItemsToSalesTaxCodes {
        #   code
        #   rate
        # }
      }
    }
  }
`

export const GET_ITEMS_SELECTED_FOR_PRINT_LABELS = gql`
  mutation GetSapItemsSelectedForPrintLabels(
    $items: [String]
    $listPrice: Int
  ) {
    getSapItemsSelectedForPrintLabels(items: $items, listPrice: $listPrice) {
      ItemPrices {
        item_code
        price
        price_list
      }
      item_code
      item_code_bar
      tax_code_ar
      tax_code_ap
      item_name
      # SapItemsToSalesTaxCodes {
      #   code
      #   rate
      # }
    }
  }
`

export const GET_ITEMS_WITH_DIFFERENCE_PRICE_FOR_PRINT_LABELS = gql`
  mutation GetSapItemsWithDifferentPrices($listPrice: [Int]) {
    getSapItemsWithDifferentPrices(listPrice: $listPrice) {
      id
      item_code
      item_name
      item_code_bar
      tax_code_ar
      ItemPrices {
        item_code
        price_list
        price
        factor
        base_num
      }
      SapItemsToSapBarCodeData {
        barcode
      }

      # SapItemsToSalesTaxCodes {
      #   code
      #   rate
      # }
    }
  }
`

export const GET_ITEM_BY_ITEM_CODE_BARCODE_ITEM_NAME = gql`
  mutation GetSapItemsByItemCodeBarcodeItemName($value: String) {
    getSapItemsByItemCodeBarcodeItemName(value: $value) {
      id
      item_code
      item_name
      item_code_bar
      item_group_code
      purchase_item
      purchase_pack_msr
      purchase_pack_un
      sal_pack_msr
      sal_pack_un
      sell_item
      tax_code_ap
      tax_code_ar
      inventory_item
      indirect_tax
      business_partner_id
      card_code
      expires
      hasHarvest
      ItemPrices {
        item_code
        price_list
        price
        factor
        base_num
      }
      SapItemsToStockOne {
        item_code
        on_hand
        warehouse_code
      }
      SapItemsToSapBarCodeData {
        barcode
      }
      # SapItemsToSalesTaxCodes {
      #   code
      #   rate
      # }
    }
  }
`
