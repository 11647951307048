import XLSX from 'sheetjs-style'

const HelperGenerateExcel = ({
  id,
  excelData,
  fileName,
  optionsSubHeaders = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
  },
  configPage = {
    skipHeader: true,
    origin: 'A2',
    s: { font: { bold: true, sz: 10, alignment: { horizontal: 'right' } } },
  },
}) => {
  const FILE_EXTENSION = '.xlsx'

  const exportToExcel = async () => {
    const wb = XLSX.utils.book_new()
    for (const excel of excelData) {
      const { data, columnsWidth, bookName } = excel
      let ws = XLSX.utils.json_to_sheet(data)

      ws = XLSX.utils.json_to_sheet(data, configPage)

      // Create headers
      Object.entries(data[0]).map((item, index) => {
        ws[String.fromCharCode(65 + index) + `1`] = {
          t: 's',
          v: item[0].toUpperCase(),
          s: optionsSubHeaders,
        }
        return null
      })
      // Cols widths
      const wsCols = columnsWidth.map((column) => {
        return { wch: column }
      })

      ws['!cols'] = wsCols
      XLSX.utils.book_append_sheet(wb, ws, bookName)
    }
    XLSX.writeFile(wb, `${fileName} ${FILE_EXTENSION}`)
  }

  return <button id={id} className="hide" onClick={exportToExcel}></button>
}

export default HelperGenerateExcel
