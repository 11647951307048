import { useEffect, useState } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import { useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import moment from 'moment'
import Box from '../../Global/Box'
import { useParams } from 'react-router-dom'
import TableAssignments from './Components/TableAssignments'
import TableAssignmentsRacks from './Components/TableAssignmentsRacks'
import TableAssignmentsSKU from './Components/TableAssignmentsSKU'
import TableAssignmentAccountants from './Components/TableAssignmentAccountants'
import { useHistory } from 'react-router-dom'
import {
  GET_INVENTORY_ACTIVITY_ASSIGMENT_BY_ID,
  VALIDATE_ELEMENTS_ASSIGNMENTS,
  CREATE_INVENTORY_ACTIVITIES_ASSIGNMENT,
} from '../../../graphql/InventoryActivities/InventoryActivitiesAssignments'

const InventoryActivitiesAssignmentsNew = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [steps, setSteps] = useState(1)
  const history = useHistory()

  const [elementsToAssign, setElementsToAssign] = useState([])
  // FOR LABELS-RACKS
  const [selectedLabels, setSelectedLabels] = useState([])
  const [pagePaginationLabel, setPagePaginationLabel] = useState({
    limit: 30,
    offset: 0,
    searchQuery: '',
    warehouseId: 0,
    excludeIds: [],
    count: null,
    scheduleId: parseInt(id),
  })

  // FOR SKUS
  const [selectedSkus, setSelectedSkus] = useState([])
  const [pagePaginationSku, setPagePaginationSku] = useState({
    searchQuery: '',
    limit: 30,
    offset: 0,
    excludeSkus: [],
    onlyAssigned: false,
    scheduleId: parseInt(id),
  })

  // FOR ACCOUNTANTS
  const [accountantsOneSelected, setAccountantsOneSelected] = useState([])
  const [accountantsTwoSelected, setAccountantsTwoSelected] = useState([])
  const [pagePaginationAccountants, setPagePaginationAccountants] = useState({
    searchQuery: '',
    limit: 30,
    offset: 0,
    excludeIds: [],
    onlyAssigned: false,
    scheduleId: parseInt(id),
  })

  const [infoSchedule, setInfoSchedule] = useState(true) //set info schedule
  const [dataAssign, setDataAssign] = useState({
    almacen: null,
    fecha: null,
  })

  const [showResume, setShowResume] = useState(false)
  const [typeCycleCountSelected, setTypeCycleCountSelected] = useState(1)

  //useQuery & mutations
  const assigment = useQuery(GET_INVENTORY_ACTIVITY_ASSIGMENT_BY_ID, {
    variables: {
      id: parseInt(id),
    },
    refetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  })

  const [createAssignments] = useMutation(
    CREATE_INVENTORY_ACTIVITIES_ASSIGNMENT
  )

  const [validateElementsAssigments] = useMutation(
    VALIDATE_ELEMENTS_ASSIGNMENTS
  )

  //useEffects
  useEffect(() => {
    try {
      if (assigment?.loading) return
      if (assigment.error) {
        return toastSweetAlert({
          mode: 'error',
          message: assigment.error.message,
        })
      }

      let data = assigment.data.getInventoryActivityAssigmentById
      setDataAssign({
        almacen: data?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
        fecha: moment(data.start_date).format('lll'),
        ...data,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [assigment?.loading, assigment?.data, assigment?.error, id])

  //functions
  const resetOnBack = () => {
    setSteps(2)
    setSelectedLabels([])
    setSelectedSkus([])
    setPagePaginationLabel({ ...pagePaginationLabel, excludeIds: [] })
    setPagePaginationSku({ ...pagePaginationLabel, excludeSkus: [] })
    setElementsToAssign([])
  }

  //click finalizar asignacion
  const handleFinish = async () => {
    const count1 = accountantsOneSelected.map((count) => {
      return count.id
    })
    const count2 = accountantsTwoSelected.map((count) => {
      return count.id
    })

    try {
      setLoading(true)
      await createAssignments({
        variables: {
          input: {
            accountantsOne: count1,
            accountantsTwo: count2,
            elementsToAssign,
            scheduleId: dataAssign.id,
          },
        },
      })

      setLoading(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Se han creado las asignaciones correctamente',
        },
        setSteps(1),
        resetOnBack(),
        setAccountantsOneSelected([]),
        setAccountantsTwoSelected([]),
        setPagePaginationLabel({ ...pagePaginationLabel, excludeIds: [] }),
        setPagePaginationSku({ ...pagePaginationLabel, excludeSkus: [] }),
        setPagePaginationAccountants({
          ...pagePaginationAccountants,
          excludeIds: [],
        })
      )
    } catch (error) {
      setLoading(false)

      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const validateElementsAssigned = async () => {
    try {
      setLoading(true)
      const numberCounts = []
      if (accountantsOneSelected.length > 0) numberCounts.push(1)
      if (accountantsTwoSelected.length > 0) numberCounts.push(2)

      const res = await validateElementsAssigments({
        variables: {
          numberCounts,
          elements: elementsToAssign,
          scheduleId: parseInt(id),
          fromUbications:
            infoSchedule?.type_schedule === 2
              ? true
              : typeCycleCountSelected === 1
              ? true
              : false,
        },
      })
      console.log(res)
      await handleFinish()
      setLoading(false)
      // setSteps(4)
    } catch (error) {
      console.log(error)
      setLoading(false)
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const determinateWhatCountUbicationsShow = () => {
    try {
      setSteps(3)
      setPagePaginationLabel({
        ...pagePaginationLabel,
        count:
          accountantsOneSelected.length > 0 && accountantsTwoSelected.length > 0
            ? null
            : accountantsOneSelected.length > 0
            ? 1
            : accountantsTwoSelected.length > 0 && 2,
      })
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const tfiSelected = (
    <>
      <div className="row d-flex align-middle">
        <div className="col-12 col-md-3">
          <h6>ALMACEN</h6>
          <p className="lead">{dataAssign.almacen}</p>
        </div>
        <div className="col-12 col-md-3">
          <h6>FECHA DE INICIO</h6>
          <p className="lead">{dataAssign.fecha}</p>
        </div>
      </div>
    </>
  )

  return (
    <>
      <ContentHeader
        title="Asignaciones"
        breadcrumb="Asignaciones"
        windowTitle={`Asignaciones`}
      />
      <div className={`${loading ? 'processing-file' : ''}`}>
        {!showResume && steps !== 4 && (
          <Box
            title={`${
              infoSchedule?.type_schedule === 1
                ? 'Conteo cíclico seleccionado'
                : 'TFI seleccionado'
            }`}
            content={tfiSelected}
            enableCollapse={true}
            collapsed={false}
          />
        )}

        {/* STEP 1 */}
        {steps === 1 && (
          <TableAssignments
            id={id}
            setInfoSchedule={setInfoSchedule}
            setTypeCycleCountSelected={setTypeCycleCountSelected}
            setLoadingBtn={setLoading}
            setSteps={setSteps}
          />
        )}

        {/* STEP 2 */}
        {steps === 2 && (
          <TableAssignmentAccountants
            pagePaginationAccountants={pagePaginationAccountants}
            setPagePaginationAccountants={setPagePaginationAccountants}
            accountantsOneSelected={accountantsOneSelected}
            setAccountantsOneSelected={setAccountantsOneSelected}
            accountantsTwoSelected={accountantsTwoSelected}
            setAccountantsTwoSelected={setAccountantsTwoSelected}
            justOneTableOfAccountants={
              infoSchedule?.type_schedule === 1 ? true : false
            }
          />
          // <></>
        )}

        {/* STEP 3 */}
        {/* FOR TFI */}
        {steps === 3 && infoSchedule?.type_schedule === 2 && (
          <TableAssignmentsRacks
            elementsToAssign={elementsToAssign}
            setElementsToAssign={setElementsToAssign}
            selectedLabels={selectedLabels}
            setSelectedLabels={setSelectedLabels}
            pagePaginationLabel={pagePaginationLabel}
            setPagePaginationLabel={setPagePaginationLabel}
          />
        )}

        {/* FOR CYCLE COUNTS */}
        {steps === 3 && infoSchedule?.type_schedule === 1 && (
          <Box
            withCard={false}
            content={
              <>
                {typeCycleCountSelected === 1 && (
                  <TableAssignmentsRacks
                    elementsToAssign={elementsToAssign}
                    setElementsToAssign={setElementsToAssign}
                    selectedLabels={selectedLabels}
                    setSelectedLabels={setSelectedLabels}
                    pagePaginationLabel={pagePaginationLabel}
                    setPagePaginationLabel={setPagePaginationLabel}
                  />
                )}
                {typeCycleCountSelected === 2 && (
                  <TableAssignmentsSKU
                    setElementsToAssign={setElementsToAssign}
                    elementsToAssign={elementsToAssign}
                    selectedSkus={selectedSkus}
                    setSelectedSkus={setSelectedSkus}
                    pagePaginationSku={pagePaginationSku}
                    setPagePaginationSku={setPagePaginationSku}
                  />
                )}
              </>
            }
          />
        )}

        {/* STEP 4 */}
        {steps === 4 && (
          <Box
            title="Resumen"
            content={
              <>
                <div className="row mb-3 mt-3">
                  <div className="col-12">
                    <h4>
                      {infoSchedule?.type_schedule === 1
                        ? 'Conteo cíclico'
                        : 'TFI'}
                    </h4>
                    <hr className="mt-0 mb-2 pt-0 border-top border border-2" />
                    {tfiSelected}
                  </div>
                </div>

                <TableAssignmentAccountants
                  pagePaginationAccountants={pagePaginationAccountants}
                  setPagePaginationAccountants={setPagePaginationAccountants}
                  accountantsOneSelected={accountantsOneSelected}
                  setAccountantsOneSelected={setAccountantsOneSelected}
                  accountantsTwoSelected={accountantsTwoSelected}
                  setAccountantsTwoSelected={setAccountantsTwoSelected}
                  showJustResume
                  justOneTableOfAccountants={
                    infoSchedule?.type_schedule === 1 ? true : false
                  }
                />
                {typeCycleCountSelected === 1 && (
                  <TableAssignmentsRacks
                    elementsToAssign={elementsToAssign}
                    setElementsToAssign={setElementsToAssign}
                    selectedLabels={selectedLabels}
                    setSelectedLabels={setSelectedLabels}
                    pagePaginationLabel={pagePaginationLabel}
                    setPagePaginationLabel={setPagePaginationLabel}
                    showJustResume
                  />
                )}

                {typeCycleCountSelected === 2 && (
                  <TableAssignmentsSKU
                    setElementsToAssign={setElementsToAssign}
                    elementsToAssign={elementsToAssign}
                    selectedSkus={selectedSkus}
                    setSelectedSkus={setSelectedSkus}
                    pagePaginationSku={pagePaginationSku}
                    setPagePaginationSku={setPagePaginationSku}
                    showJustResume
                  />
                )}
              </>
            }
          />
        )}

        {/* SECTION BUTTONS */}
        <div className="actions-buttons-general">
          {steps === 1 && (
            <button
              className="StandarModalCancelButtonLogOut"
              onClick={() => history.push('/inventory/assignments')}
            >
              Cancelar
            </button>
          )}
          {steps === 2 && (
            <>
              <button
                className="StandarModalCancelButtonLogOut"
                onClick={() => {
                  setAccountantsOneSelected([])
                  setAccountantsTwoSelected([])
                  resetOnBack()
                  setPagePaginationAccountants({
                    ...pagePaginationAccountants,
                    excludeIds: [],
                    searchQuery: '',
                  })
                  setSteps(1)
                }}
              >
                Cancelar
              </button>

              {(accountantsOneSelected.length > 0 ||
                accountantsTwoSelected.length > 0) && (
                <button
                  className="StandarModalConfirmButtonLogOut"
                  onClick={() => determinateWhatCountUbicationsShow()}
                >
                  Continuar
                </button>
              )}
            </>
          )}

          {steps === 3 && (
            <>
              <button
                className="StandarModalCancelButtonLogOut"
                onClick={() => resetOnBack()}
              >
                Regresar
              </button>

              {elementsToAssign.length > 0 && (
                <button
                  className="StandarModalConfirmButtonLogOut"
                  onClick={() => {
                    validateElementsAssigned()
                    // handleFinish()
                  }}
                >
                  Finalizar asignación
                </button>
              )}
            </>
          )}

          {/* UNCOMMENT THIS AND COMMENT THE BUTTON FROM ABOVE TO ACTIVATE RESUME VIEW*/}
          {/* {steps === 3 && (
            <>
              <button
                className="StandarModalCancelButtonLogOut"
                onClick={() => resetOnBack()}
              >
                Regresar
              </button>

              {elementsToAssign.length > 0 && (
                <button
                  className="StandarModalConfirmButtonLogOut"
                  onClick={() => {
                    validateElementsAssigned()
                  }}
                >
                  Continuar
                </button>
              )}
            </>
          )}

          {steps === 4 && (
            <>
              <button
                className="StandarModalCancelButtonLogOut"
                onClick={() => {
                  setSteps(3)
                  setShowResume(false)
                }}
              >
                Regresar
              </button>
              <button
                className="StandarModalConfirmButtonLogOut"
                onClick={handleFinish}
              >
                Finalizar asignación
              </button>
            </>
          )} */}
        </div>
      </div>
    </>
  )
}

export default InventoryActivitiesAssignmentsNew
