import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'

export const PieGraphic = ({
  labelsPending,
  labelsComplete,
  totalLabels,
  totalAccountants,
}) => {
  const options = {
    colors: ['#2CCB6C', '#FFD186'],
    exporting: {
      enabled: false, //context menu
    },
    caption: {
      align: 'center',
      text: 100,
      verticalAlign: 'middle',
      useHTML: true,
      floating: true,
      style: {
        backgroundColor: '#2CCB6C',
        color: '#fff',
        fontSize: '1.5em',
        padding: '0.20em',
        borderRadius: 50,
      },
    },
    chart: {
      height: '40%',
      type: 'pie',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },

    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        startAngle: 0,
        endAngle: 360,
      },
    },

    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            chart: {
              height: '345px',
            },
            subtitle: {
              text: null,
            },
            navigator: {
              enabled: false,
            },
          },
        },
      ],
    },
    xAxis: {
      //categories: ['Amazon', 'E-commerce']
    },
    series: [
      {
        innerSize: '80%',
        data: [labelsComplete, labelsPending],
        fillOpacity: 0,
      },
    ],
  }

  let percentage = 0
  if (totalLabels > 0) {
    percentage = parseInt((100 * labelsPending) / totalLabels)
  }

  return (
    <div className="card">
      <div className="card-title pt-2">
        <div className="col-12 border border-top-0 border-left-0 border-right-0 border-2 border-dark">
          <h6>Progreso</h6>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-7">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
          <div className="col-12 col-md-5 p-3" style={{ lineHeight: '1em' }}>
            <div className="row mb-2">
              <div className="col-12">
                <h1 className="pt-3 mb-0 pb-0">
                  <strong>{totalLabels}</strong>
                </h1>
                <p className="pb-0 mb-0">
                  <strong>Ubicaciones totales</strong>
                </p>
                <p className="pt-0">{totalAccountants} contadores asignados</p>
              </div>
            </div>

            <div className="row">
              <div className="col-2">
                <div
                  style={{ background: '#FFD186', width: 20, height: 20 }}
                ></div>
              </div>
              <div className="col-10">
                <p className="mb-0">
                  <small>
                    <strong>Ubicaciones pendientes</strong>
                  </small>
                </p>
                <p>
                  <small>
                    {labelsPending} ({percentage}%)
                  </small>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-2">
                <div
                  style={{ background: '#2CCB6C', width: 20, height: 20 }}
                ></div>
              </div>

              <div className="col-10">
                <p className="mb-0">
                  <small>
                    <strong>Ubicaciones finalizadas</strong>
                  </small>
                </p>
                <p>
                  <small>
                    {labelsComplete} ({100 - percentage}%)
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PieGraphic
