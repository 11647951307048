import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import {
  CREATE_DOCUMENT_ASSIGNATION,
  DELETE_REGISTER_VEHICLE,
  GET_ALL_REGISTER_VEHICLES,
} from '../../../graphql/Documents/Schedule/ScheduleVehicle'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import RegisterHours from './RegisterHours'

const ScheduleRegister = () => {
  const [total, setTotal] = useState(0)
  const [vehiclesRegisterCopy, setVehiclesRegisterCopy] = useState([])
  const [vehicleRegisters, setVehiclesRegisters] = useState([])
  const [dataToTable, setDataToTable] = useState([])
  const [isClicked, setisClicked] = useState(false)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const { data, loading, error } = useQuery(GET_ALL_REGISTER_VEHICLES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      isGuard: true,
    },
  })
  const [
    createAssignation,
    {
      loading: createDocumentAssinationLoading,
      data: createDocumentAssignationData,
    },
  ] = useMutation(CREATE_DOCUMENT_ASSIGNATION)

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      settingDataToTable(data?.getAllVehicleRegister?.rows)
      setVehiclesRegisters(data?.getAllVehicleRegister?.rows)
      setVehiclesRegisterCopy(data?.getAllVehicleRegister?.rows)
      setTotal(data?.getAllVehicleRegister?.count)
    }
  }, [data, loading, error])

  useEffect(() => {
    if (createDocumentAssinationLoading) {
      setisClicked(true)
    } else if (
      !createDocumentAssinationLoading &&
      createDocumentAssignationData?.createDocumentAssination
    ) {
      setisClicked(false)
    }
  }, [createDocumentAssinationLoading, createDocumentAssignationData])

  const settingDataToTable = (itemsArray) => {
    const list = itemsArray.map((item) => {
      const status_id = +item?.LoadingDockControlToDocumentStatus?.id
      const stage_status_id = +item?.LoadingDockControlToOrderStatus?.id

      let status_name = ''
      let stage_status_name = ''

      // showing status name translation
      switch (status_id) {
        case 1:
          status_name = 'Abierto'
          break

        case 2:
          status_name = 'Cerrado'
          break
        case 8:
          status_name = 'Borrado'
          break
        case 9:
          status_name = 'Procesando'
          break
        default:
          break
      }
      //showing stage_status name translation
      switch (stage_status_id) {
        case 14:
          stage_status_name = 'Hora de arribo a instalaciones'
          break

        case 15:
          stage_status_name = 'Hora de ingreso a anden'
          break
        case 16:
          stage_status_name = 'Hora de enrampe'
          break

        case 17:
          stage_status_name = 'Hora de salida'
          break
        case 22:
          stage_status_name = 'Hora inicio de descarga'
          break
        case 23:
          stage_status_name = 'Hora fin de descarga'
          break
        default:
          break
      }

      return {
        id: item?.id,
        proveedor: item?.LoadingDockControlToSapBusinessPartner?.card_name,
        placas: item?.plates_number,
        paqueteria: item?.LoadingDockControlToShippingCompanies?.name,
        'tipo de vehiculo': item?.LoadingDockControlToDocumentVehicleType?.name,
        estado: status_name,
        etapa: stage_status_name,
        usuario: item?.user?.name,
      }
    })
    setDataToTable(list)
  }

  const handleButton = async (id, e) => {
    const { field } = e.target.dataset
    setisClicked(true)

    try {
      const { data } = await createAssignation({
        variables: {
          createDocumentAssinationId: +id,
          action: +field,
        },
        refetchQueries: [
          {
            query: GET_ALL_REGISTER_VEHICLES,
            variables: {
              searchQuery: null,
              limit: 10,
              offset: 0,
              isGuard: true,
            },
          },
          {
            query: GET_ALL_REGISTER_VEHICLES,
            variables: {
              searchQuery: null,
              limit: 10,
              offset: 0,
              isGuard: false,
            },
          },
        ],
      })

      //   history.push('/scheduleRegister')
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const expandedComponent = ({ data }) => {
    const itemFounded = vehicleRegisters.find((item) => item.id == data.id)

    return (
      <>
        <div className="container-fluid">
          <RegisterHours
            itemFounded={itemFounded}
            handleButton={handleButton}
            data={data}
            isClicked={isClicked}
          />
        </div>
      </>
    )
  }

  const filterOnChange = (e) => {
    const value = +e.target.value

    if (value !== 0) {
      const filterOptions = vehiclesRegisterCopy.filter(
        (item) => value == item?.LoadingDockControlToDocumentStatus?.id
      )

      settingDataToTable(filterOptions)
      setVehiclesRegisterCopy(vehicleRegisters)
    } else {
      settingDataToTable(vehicleRegisters)
    }
  }

  return (
    <>
      {/* <ContentHeader title="Registro de vehículos" /> */}

      <LayoutTable
        title="Registro de vehículos"
        exportImportButton={false}
        totalCount={total}
        loadingData={loading}
        data={dataToTable}
        setPagePagination={setPagePagination}
        pagePagination={pagePagination}
        hideDetails={true}
        requery={GET_ALL_REGISTER_VEHICLES}
        gql={DELETE_REGISTER_VEHICLE}
        expandableRows={true}
        expandableRowsComponent={expandedComponent}
        filter
        filterOnChange={filterOnChange}
        filterOptions={[
          <option value={1}>Abiertas</option>,
          <option value={9}>Procesando</option>,
          <option value={2}>Cerradas</option>,
        ]}
        filterLabelText="Por Estado"
      />
    </>
  )
}

export default ScheduleRegister
