import { gql } from '@apollo/client'

export const GET_INSTANT_SALES_REPORT = gql`
  query GetInstantSalesReport {
    getInstantSalesReport {
      totalOrders
      orderedUnits
      salesOfOrderedProducts
      averageUnitsPerOrder
      averageSalesPerOrder
    }
  }
`
export const VERIFYING_CURRENT_LEVEL = gql`
  query Query {
    verifyingUserLevel
  }
`

export const CREATE_PROVIDERS_REPORT = gql`
  mutation CreateProvidersReport($reportsType: Int!,$reportsTypeRangeStart: String!,$reportsTypeRangeEnd: String!,$dataType: [Int!]!,$documentsType: [Int!]!) {
    createProvidersReport(
      reportsType: $reportsType,
      reportsTypeRangeStart: $reportsTypeRangeStart,
      reportsTypeRangeEnd: $reportsTypeRangeEnd,
      dataType: $dataType,
      documentsType: $documentsType
    ) 
  }
`

export const CREATE_PROVIDERS_REPORTS_SUBSCRIPTION = gql`
  subscription ProviderReportsCreated {
    providerReportsCreated {
      userId
    }
  }
`
export const GET_SALES_BY_CHANNEL = gql`
  query GetSalesByChannel($type: Int) {
    getSalesByChannel(type: $type) {
      totalSales
      warehouses {
        sale
        name
        percentage
      }
    }
  }
`
export const GET_COMPARATIVE_GRAPHICS = gql`
  query GetComparativeGraphicsInventoryDisplacements($type: Int) {
    getComparativeGraphicsInventoryDisplacements(type: $type) {
      displacements {
        name
        id
        quantity
      }
      inventory {
        name
        id
        quantity
      }
      all {
        displacements {
          lastMonth {
            name
            id
            quantity
          }
          lastBiweekly {
            name
            id
            quantity
          }
          yearToDate {
            name
            id
            quantity
          }
          rollingYear {
            name
            id
            quantity
          }
        }
        inventory {
          lastMonth {
            name
            id
            quantity
          }
          lastBiweekly {
            name
            id
            quantity
          }
          yearToDate {
            name
            id
            quantity
          }
          rollingYear {
            name
            id
            quantity
          }
        }
      }
    }
  }
`
