import ModalContent from '../../../Global/ModalContent'

const GenerateReportModal = ({ refModal }) => {
  const handleButton = () => {
    refModal.current.click()
  }

  return (
    <>
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#reportGenerated"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="reportGenerated"
        title="Reporte Generándose"
        size="large"
        content={
          <>
            <p className="mt-3">
              Estamos generando tu reporte, te enviaremos una correo cuando
              esté listo.
            </p>

            <div className="d-flex justify-content-end mt-5">
              <button
                className="btn StandarModalConfirmButtonLogOut"
                onClick={handleButton}
              >
                Finalizar
              </button>
            </div>
          </>
        }
      />
    </>
  )
}

export default GenerateReportModal
