import React from 'react'
import OrderProductCard from '../OrderProductCard'

function OrderProducts({ productsArray = [], totalQuantity }) {
  return (
    <>
      <div className="col-12 box-separator overflow-auto pb-3  ordersTableBox">
        <table
          id="ordersTable"
          className="table table-striped table-bordered nowrap"
        >
          <thead>
            <tr>
              <th className='text-left'>IMAGEN</th>
              <th className='text-left'>SKU</th>
              <th className='text-left'>NOMBRE</th>
              <th className='text-left'>PRECIO</th>
              <th className='text-left'>CANTIDAD</th>
              <th className='text-right'>SUBTOTAL</th>
            </tr>
          </thead>
          <tbody>
            {productsArray.map((product) => {
              return (
                <tr>
                  <OrderProductCard
                    key={product.id}
                    name={product.name}
                    id_product={product.product_id}
                    price={product.price}
                    sku={product.sku}
                    total={product.total}
                    quantity={product.quantity}
                    image={product.img_url}
                  />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="col-12 mt-3">
        <div className="row justify-content-end">
          <p className="cost-header">Botellas totales: {totalQuantity}</p>
        </div>
      </div>
    </>
  )
}

export default OrderProducts
