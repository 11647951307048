import React, { useState, useEffect } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useQuery } from '@apollo/client'
import { GET_ALL_PRODUCTS_SAP } from '../../../graphql/Catalog/Telemarketing/Orders/orders'


export const TelemarketingOrderHistory = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { loading, error, data } = useQuery(GET_ALL_PRODUCTS_SAP, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
  })

  const [dataToTable, setDataToTable] = useState([])

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!loading) {
      if (error) toastSweetAlert({
        mode:'error',
        message:error.message,
      })
      
      let list = []
      data.getAllOrdersSap.rows.map((item) => {
        return list.push({
          id: item.id,
          cobro: item.paymentTelemarketing?.platform ?? "indefinido",
          'no. cobro': item.payment_id,
          cliente: item.BusinessPartner[0]?.card_name,
          'no. venta': item.order_id
        })
      })
      setDataToTable(list)
      setTotal(data.getAllOrdersSap.count)
    }
  }, [error, data])

  const handleErase = (id) => {
    toastSweetAlert({
        mode:'error',
        message:'Acción de borrado, por definir...',
      })
  }

  return (
    <>
      {/* <ContentHeader
        title="Historial de pedidos"
        breadcrumb="Historial de pedidos"
        windowTitle="Historial de pedidos"
      /> */}

      <LayoutTable
        data={dataToTable}
        title="Historial de pedidos"
        headersButtons={false}
        hideBtnEdit={true}
        changeCustomActionErase={true}
        actionBtnErase={(e)=>{handleErase(e)}}
        requery={GET_ALL_PRODUCTS_SAP}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        FilterBarPlaceholder= "Buscar"
      />
    </>
  )
}

export default TelemarketingOrderHistory
