import CountGraphic from './CountGraphic'
import PieGraphic from './PieGraphic'

export const Graphics = ({ progressIndicatorsLive }) => {
  return (
    <div className="card p-0" style={{ background: 'none', boxShadow: 'none' }}>
      <div className="card-body p-1" style={{}}>
        <div className="row flex-wrap">
          <div className="col-12 col-lg-6 col-md-4">
            <PieGraphic
              totalLabels={progressIndicatorsLive.totalLabels}
              labelsPending={progressIndicatorsLive.labelsPending}
              labelsComplete={progressIndicatorsLive.labelsComplete}
              totalAccountants={progressIndicatorsLive.totalAccountants}
            />
          </div>
          <div className="col-12 col-lg-3 col-md-4">
            <CountGraphic
              title="1er conteo"
              data={[
                {
                  title: 'Pendientes',
                  total: progressIndicatorsLive?.firstCount?.totalLabels,
                  num: progressIndicatorsLive?.firstCount?.labelsPending,
                },
                {
                  title: 'Finalizadas',
                  total: progressIndicatorsLive?.firstCount.totalLabels,
                  num: progressIndicatorsLive?.firstCount?.labelsComplete,
                },
              ]}
            />
          </div>
          <div className="col-12 col-lg-3 col-md-4">
            <CountGraphic
              title="2do conteo"
              data={[
                {
                  title: 'Pendientes',
                  total: progressIndicatorsLive.secondCount?.totalLabels,
                  num: progressIndicatorsLive.secondCount?.labelsPending,
                },
                {
                  title: 'Finalizadas',
                  total: progressIndicatorsLive.secondCount?.totalLabels,
                  num: progressIndicatorsLive.secondCount?.labelsComplete,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Graphics
