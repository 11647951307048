import { gql } from '@apollo/client'

export const GET_ALL_ITEMS = gql`
  query GetAllItems(
    $searchQuery: String
    $offset: Int
    $limit: Int
    $zoneId: Int
    $rackId: Int
  ) {
    getAllItems(
      searchQuery: $searchQuery
      offset: $offset
      limit: $limit
      zone_id: $zoneId
      rack_id: $rackId
    ) {
      count
      rows {
        id
        rack_id
        RackPosition {
          name
          code
          reorder
          zone_id
        }
        item_code
        on_hand
        on_commit
        lote
        expiration_date
        vintage
        SapItem {
          item_code
          item_name
        }
      }
    }
  }
`

export const GET_ITEM_BY_ID = gql`
  query GetOneItemById($getOneItemByIdId: Int!) {
    getOneItemById(id: $getOneItemByIdId) {
      id
      rack_id
      static
      RackPosition {
        name
        code
        reorder
        zone_id
        capacity
      }
      item_code
      on_hand
      on_commit
      lote
      expiration_date
      SapItem {
        item_code
        item_name
      }
    }
  }
`

export const CREATE_ITEM = gql`
  mutation CreateItem($item: ItemInput!) {
    createItem(Item: $item) {
      id
      rack_id
      item_code
      on_hand
      on_commit
      expiration_date
      lote
      static
    }
  }
`

export const UPDATE_ITEM = gql`
  mutation UpdateItem($updateItemId: Int!, $item: ItemInput!) {
    updateItem(id: $updateItemId, Item: $item) {
      id
      rack_id
      item_code
      on_hand
      on_commit
      expiration_date
      lote
      static
    }
  }
`

export const GET_NEXT_RACK_PRODUCT = gql`
  mutation GetNextRackForProduct(
    $itemCode: String!
    $zoneId: Int!
    $index: Int
  ) {
    getNextRackForProduct(
      item_code: $itemCode
      zone_id: $zoneId
      index: $index
    ) {
      on_hand
      id
      rack_id
      item_code
      on_commit
      lote
      expiration_date
      static
      RackPosition {
        capacity
        name
        id
        code
        zone_id
      }
      SapItem {
        id
        item_code
        item_name
      }
    }
  }
`

export const GET_ALL_ITEMS_EXPORT = gql`
  mutation GetAllItemsExport($searchQuery: String) {
    getAllItemsExport(searchQuery: $searchQuery) {
      RACK
      SKU
      ON_HAND
      EXPIRATION_DATE
      VINTAGE
      LOTE
    }
  }
`

export const DELETE_POSITION = gql`
  mutation DeleteItem($id: Int!) {
    deleteItem(id: $id)
  }
`
