import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  DELETE_STORE_HELPER_COUNTER_CHARGES,
  GET_ALL_STORE_HELPER_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/Notes/StoreHelperCounterCharges/storehelpercountercharges'
import LayoutTable from '../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'

export const StoreHelperCounterCharges = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_STORE_HELPER_COUNTER_CHARGES,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: error,
          message: error.message,
        })

      let list = []

      data.getAllStoresCounterCharges.rows.map((item) => {
        return list.push({
          id: item.id,
          nombre: item?.storesCounterCharges?.alias,
          nomenclatura: item.nomenclature_initials,
          consecutivo: item.consecutive_number,
          'consecutivo NM': item.consecutive_number_manual_note,
          'Folio de pago': item.invoice_payments,
        })
      })
      console.log('list', list)
      setDataToTable(list)
      setTotal(data.getAllStoresCounterCharges.count)
    }
  }, [loading, error, data, refetch])

  return (
    <>
      {/* <ContentHeader
        title="Control de tiendas"
        windowTitle="Control de tiendas"
        breadcrumb="Control de tiendas"
      /> */}

      <LayoutTable
        title="Control de tiendas"
        hideId={true}
        data={dataToTable}
        gql={DELETE_STORE_HELPER_COUNTER_CHARGES}
        requery={GET_ALL_STORE_HELPER_COUNTER_CHARGES}
        totalCount={total}
        loadingData={loading}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        exportImportButton={false}
        refetchFunctionQueries={async () => await refetch()}
      />
    </>
  )
}

export default StoreHelperCounterCharges
