import { createContext, useState, useContext, useRef } from "react";

const TransferContext = createContext();

export const TransferContextProvider = ({ children }) => {
    const [flagWarehouse, setFlagWarehouse] = useState(true)
    const [warehousesCodes, setWarehousesCodes] = useState({
        from_whs_code: null,
        to_whs_code: null,
        branch_code: null,
    })
    const [warehouseOriginSelected, setWarehouseOriginSelected] = useState(null)
    const [dataRequest, setDataRequest] = useState([])
    const [dataLines, setDataLines] = useState([])
    const [query, setQuery] = useState(null)
    const [findProducts, setFindProducts] = useState([])
    const [dataItem, setDataItem] = useState({})
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })
    const [total, setTotal] = useState(0)
    const [resetTransferQuantityForm, setResetTransferQuantityForm] = useState(false)
    const refModal = useRef()

    return <TransferContext.Provider value={{
        flagWarehouse, setFlagWarehouse,
        warehousesCodes, setWarehousesCodes,
        warehouseOriginSelected, setWarehouseOriginSelected,
        dataRequest, setDataRequest,
        query, setQuery,
        findProducts, setFindProducts,
        dataItem, setDataItem,
        dataLines, setDataLines,
        pagePagination, setPagePagination,
        total, setTotal,
        resetTransferQuantityForm, setResetTransferQuantityForm,
        refModal
    }}>
        {children}
    </TransferContext.Provider>
}
export const useTransferContext = () => {
    const context = useContext(TransferContext);

    if (context === undefined) { throw new Error("context was used outside of the provider") }

    return context
}


