import { useLazyQuery, useQuery } from '@apollo/client'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import {
  GET_ALL_BILLS_PAYMENTS_BY_ID_CHECK,
  GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE,
} from '../../../../../../graphql/Catalog/Notes/payments'
import Box from '../../../../../Global/Box'
import LayoutTable from '../../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../../Helpers/ToastSweetAlert'
import ReactToPrint from 'react-to-print'
import PrintCheckCoverages from '../../GeneratePayments/PrintCheckCoverages/PrintCheckCoverages'
import { useParams } from 'react-router-dom'

export const PaymentsHistoryNew = ({ idCheck, handleHistoryPrevious }) => {
  const id = idCheck
  const { id: _id } = useParams()
  const componentRef = useRef()
  const [dataTableBillsPayments, setDataTableBillsPayments] = useState([])
  const [infoCheck, setInfoCheck] = useState([])
  const [infoPrintCoverage, setInfoPrintCoverage] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    limit: 10,
    offset: 0,
    idCheck: id,
  })

  const {
    loading: loadingGetAllBillsPaymentsByIdCheck,
    error: errorGetAllBillsPaymentsByIdCheck,
    data: dataGetAllBillsPaymentsByIdCheck,
  } = useQuery(GET_ALL_BILLS_PAYMENTS_BY_ID_CHECK, {
    variables: {
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      idCheck: pagePagination.idCheck,
    },
  })

  const [getInfoForPrintCoverage] = useLazyQuery(
    GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE
  )

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  useEffect(() => {
    if (!loadingGetAllBillsPaymentsByIdCheck) {
      if (errorGetAllBillsPaymentsByIdCheck)
        return toastSweetAlert({
          mode: 'error',
          message: errorGetAllBillsPaymentsByIdCheck.message,
        })

      let list = []
      // const data = dataGetAllBillsPaymentsByIdCheck.getAllBillsPaymentsByIdCheck
      dataGetAllBillsPaymentsByIdCheck.getAllBillsPaymentsByIdCheck.rows.map(
        (item) => {
          return list.push({
            Factura: item?.paymentsBillsToBills?.invoice_bill,
            Monto: formmatterCurrency(item?.paymentsBillsToBills?.amount_bill),
            'Monto Pagado': formmatterCurrency(item.amount_payment_bill),
            'Fecha Pago': moment(
              item.date_bill_pay,
              'YYYY-MM-DD hh:mm:ss a'
            ).format('DD/MM/YYYY hh:mm:ss a'),
          })
        }
      )
      setDataTableBillsPayments(list)
      setTotal(
        dataGetAllBillsPaymentsByIdCheck.getAllBillsPaymentsByIdCheck.count
      )
    }
  }, [
    loadingGetAllBillsPaymentsByIdCheck,
    errorGetAllBillsPaymentsByIdCheck,
    dataGetAllBillsPaymentsByIdCheck,
  ])

  const handlePrintCoverage = async () => {
    const { data: dataCoverage } = await getInfoForPrintCoverage({
      variables: {
        idCheck: idCheck,
      },
      fetchPolicy: 'no-cache',
    })

    let infoCoverage = []

    const data = dataCoverage?.getInfoPaymentForPrintCoverage

    console.log('dataCoverage', data)

    infoCoverage.push({
      checkNumber: data?.check_number,
      invoicePay: data?.invoice_number_payments,
      dateCheck: moment(data?.date_check, 'DD/MM/YYYY hh:mm:ss a').format(
        'DD/MM/YYYY'
      ),
      total: data?.amount_check_payment,
      provider: data?.payment_nominative
        ? data?.checksToNotes?.partnerNotes?.provider_name
          ? data?.checksToNotes?.partnerNotes?.provider_name
          : data?.checksToNotes?.bills[0]?.provider_name_nm
        : 'Al portador',
      store: data?.storesPayment?.name,
    })

    setInfoPrintCoverage(infoCoverage)
    document.getElementById('PrintCoverage').click()
  }

  return (
    <Box
      title="Detalles Pagos de Factura"
      btnSecondFunctionTitle="Atras"
      btnSecondFunction={handleHistoryPrevious}
      btnFunctionTitle="Imprimir Poliza"
      btnFunction={handlePrintCoverage}
      content={
        <>
          {' '}
          <div className="mt-3 col-12">
            {infoCheck && (
              <div className="boxQuotesProvider">
                {/* <div className="row">
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                    <h4>
                      Proveedor: &nbsp;
                      <span className="resultData">
                        {infoCheck?.partner?.card_name}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <div className="boxItem">
                      <h4>
                        RFC: &nbsp;
                        <span className="resultData">
                          {infoCheck?.partner?.lic_trad_number}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Referencia de Pago: &nbsp;
                      <span className="resultData">
                        {infoCheck?.reference_pay}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                    <h4>
                      Fecha de Cheque: &nbsp;
                      <span className="resultData">
                        {moment(
                          infoCheck?.date_check,
                          'DD/MM/YYYY hh:mm:ss a'
                        ).format('DD/MM/YYYY')}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Total Cheque: &nbsp;
                      <span className="resultData">
                        {formmatterCurrency(infoCheck?.amount_check_payment)}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                    <h4>
                      Tienda: &nbsp;
                      <span className="resultData">
                        {infoCheck?.id_store_payment
                          ? infoCheck?.storesPayment?.name
                          : 'No registrada'}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row"></div>
              </div>
            )}
          </div>
          <LayoutTable
            data={dataTableBillsPayments}
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}
            totalCount={total}
            hideActions={true}
            hideFilterBar={true}
            withCard={false}
            hideAddNew
            exportImportButton={false}
          />
          <div className="hide">
            <PrintCheckCoverages
              infoCoverage={infoPrintCoverage}
              componentRef={componentRef}
            />

            <ReactToPrint
              documentTitle={`PolizaDeCheque-${infoPrintCoverage?.checkNumber}`}
              trigger={() => <button id="PrintCoverage"></button>}
              content={() => componentRef.current}
              pageStyle="@page { size: A4; margin: 10mm;}"
            />
          </div>
        </>
      }
    />
  )
}

export default PaymentsHistoryNew
