import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'

// apollo
import {
  GET_ALL_RETURN_DOCUMENTS,
  EXPORT_ALL_RETURN_DOCUMENTS,
  CANCEL_RETURN_REQUEST,
} from '../../../graphql/Documents/Inventories/Returns'
export const ReturnRequest = () => {
  const history = useHistory()
  const [listOfReturns, setListOfReturns] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    status: 'O',
    objectType: '',
  })
  const {
    loading,
    error,
    data,
    refetch: refetchAllReturns,
  } = useQuery(GET_ALL_RETURN_DOCUMENTS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      status: pagePagination.status,
      objectType: pagePagination.objectType,
    },
    fetchPolicy: 'network-only',
  })

  const [exportQuery] = useMutation(EXPORT_ALL_RETURN_DOCUMENTS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      status: pagePagination.status,
    },
    fetchPolicy: 'network-only',
  })

  const [cancelReturnRequest] = useMutation(CANCEL_RETURN_REQUEST)

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getAllReturnDocuments.rows.map((document) => {
        let btnDelete = (
          <button
            key={document.id + '-deleteButton'}
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => OnDelete(document)}
          >
            <i className="fas fas fa-trash"></i>
          </button>
        )

        let btnEdit = (
          <button
            key={document.id + '-editButton'}
            type="button"
            className="btn btn-accept btn-sm"
            onClick={() =>
              history.push(`/Returns/ReturnRequest/edit/${document.id}`)
            }
          >
            <i className="fas fas fa-edit"></i>
          </button>
        )
        return list.push({
          id: document.id,
          alert: document.document_status === 'O' ? false : true,
          ['Fecha']: document.document_date,
          ['Nº documento']: document.document_number,
          ['Codigo socio']: document.card_code,
          ['Nombre socio']: document.card_name,
          ['Estatus']: document.document_status === 'O' ? 'Abierto' : 'Cerrado',
          ['Comentarios']: document.comments,
          ['']: document.document_status === 'O' ? [btnDelete, btnEdit] : null,
        })
      })
      setListOfReturns(list)
      setTotal(data.getAllReturnDocuments.count)
    }
  }, [loading, error, data])

  const filterOnChange = async (e) => {
    const value = e.target.value
    setPagePagination({ ...pagePagination, status: value })
  }
  const OnDelete = async (e) => {
    if (e.Estatus == 'Cerrado') {
      return toastSweetAlert({
        mode: 'error',
        message: 'El documento indicado esta cerrado',
      })
    }

    const response = await toastSweetAlert({
      mode: 'modalConfirmCancel',
      title: 'Cancelar Devolución',
      message: `La cancelacion de la devolución es permanente ¿Desea continuar?`,
    })

    if (response.isConfirmed) {
      try {
        const resultCancel = await cancelReturnRequest({
          variables: { cancelReturnRequestId: e.id },
          refetchQueries: refetchAllReturns,
        })
        if (resultCancel.data.cancelReturnRequest) {
          return toastSweetAlert({
            mode: 'ok',
            message: 'El documento se a cancelado',
          })
        }
      } catch (error) {
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      }
    }
  }

  const handleObjectTypeSelect = (e) => {
    setPagePagination((prevState) => ({
      ...prevState,
      objectType: e.target.value,
    }))
  }

  return (
    <>
      {/* <ContentHeader
        title="Devolución de mercancías"
        breadcrumb="Inventario"
        windowTitle="Devoluciones"
      /> */}
      <LayoutTable
        title="Devolución de mercancías"
        addBtnTxt={'Crear solicitud de devolución'}
        data={listOfReturns}
        requery={refetchAllReturns}
        exportQuery={EXPORT_ALL_RETURN_DOCUMENTS}
        exportQueryCustom={exportQuery}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        filter={true}
        hideBtnErase={true}
        hideBtnEdit={true}
        filterDefaultOptionText={'Seleccionar una opcion'}
        defaultValueSelect={'O'}
        filterOptions={[
          <option value={''}>Todos</option>,
          <option value={'O'}>Pendientes</option>,
          <option value={'C'}>Cerradas </option>,
        ]}
        filterOnChange={filterOnChange}
        filterSecond={true}
        filterOptionsSecond={[
          <option value={'67'}>Bodega</option>,
          <option value={'20'}>Proveedor </option>,
        ]}
        filterLabelText="Estatus"
        filterLabelTextSecond="Tipo de devolucion"
        filterOnChangeSecond={handleObjectTypeSelect}
        // actionBtnErase={(e) => OnDelete(e)}
      />
    </>
  )
}

export default ReturnRequest
