import { gql } from '@apollo/client'
export const GET_ALL_SO1_PROMO_PRICE_UNIQUE = gql`
  query GetAllUniquePrices($searchQuery: String, $limit: Int, $offset: Int) {
    getAllUniquePrices(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        u_so1_amount
        u_so1_article
        u_so1_promo_string
        id
        PromoItemToSouPromo {
          id
          Code
          Name
          u_so1_datefrom
          u_so1_dateuntil
          PromoToPromoUniquePrices {
            code
            name
            u_so1_amount
          }
        }
        PromoItemToPromoBranch {
          u_so1_branch
          u_so1_promo_string
        }
        PromoItemToSapItems {
          item_code
          item_name
          item_code_bar
          SapItemsToStockOne {
            on_hand
          }
          # SapItemsToSalesTaxCodes {
          #   rate
          #   name
          #   code
          # }
        }
      }
      priceListItems {
        item_code
        price
        price_list
      }
    }
  }
`

export const GET_ALL_ITEMS_CODE_UNIQUE_PRICE_FOR_PRINT = gql`
  mutation Mutation {
    getAllSkusUniquePricesForPrintLabels
  }
`

export const GET_ALL_ITEMS_CODE_SELECTEDS_UNIQUE_PRICE_FOR_PRINT = gql`
  mutation GetAllSkusSelectedsForPrintLablesUniquePrices(
    $idsUniquePricesSelecteds: [Int]
  ) {
    getAllSkusSelectedsForPrintLablesUniquePrices(
      idsUniquePricesSelecteds: $idsUniquePricesSelecteds
    ) {
      u_so1_amount
      u_so1_article
      id
      PromoItemToSouPromo {
        Code
        Name
        u_so1_datefrom
        u_so1_dateuntil
        PromoToPromoUniquePrices {
          u_so1_amount
        }
      }
      PromoItemToSapItems {
        item_code
        item_name
        item_code_bar
        ItemPrices {
          price
          price_list
        }
        SapItemsToSapBarCodeData {
          barcode
        }
        # SapItemsToSalesTaxCodes {
        #   rate
        #   name
        #   code
        # }
      }
    }
  }
`

export const GET_PROMO_UNIQUE_BY_ITEM_CODE_BARCODE = gql`
  mutation GetItemPromoUniqueByItemCodeBarcode($value: String!) {
    getItemPromoUniqueByItemCodeBarcode(value: $value) {
      promo {
        u_so1_amount
        u_so1_article
        u_so1_promo_string
        id
        PromoItemToSouPromo {
          id
          Code
          Name
          u_so1_datefrom
          u_so1_dateuntil
          PromoToPromoUniquePrices {
            code
            name
            u_so1_amount
          }
        }
        PromoItemToPromoBranch {
          u_so1_branch
          u_so1_promo_string
        }
        PromoItemToSapItems {
          item_code
          item_name
          item_code_bar
          SapItemsToStockOne {
            on_hand
          }
          SapItemsToSapBarCodeData {
            barcode
          }
          # SapItemsToSalesTaxCodes {
          #   rate
          #   name
          #   code
          # }
        }
      }
      priceListItems {
        item_code
        price
        price_list
      }
    }
  }
`
