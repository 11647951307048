import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import React, { useEffect, useState, useRef } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import LayoutTable from '../../../Global/LayoutTable'
import Box from '../../../Global/Box'
import moment from 'moment'
import ModalContent from '../../../Global/ModalContent'
import { useHistory, useParams, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  FINISH_INVENTORY_ACTIVITY_SCHEDULE,
  GET_ALL_INVENTORY_ACTIVITIES_LIVE_COUNTING,
  GET_INVENTORY_ACTIVITIES_PROGRESS_LIVE,
  GET_ONE_INVENTORY_ACTIVITIES_LIVE_COUNTING_DETAILS,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesLive'
import _ from 'lodash'
import Graphics from '../../Global/Graphics'
import { GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_SCHEDULE } from '../../../../graphql/InventoryActivities/InventoryActivitiesAssignments'
import { LiveStatus } from '../../Global/LiveStatus'
import SimpleTable from '../../Global/SimpleTable'
import { GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS } from '../../../../graphql/InventoryActivities/InventoryActivitiesAccountants'
import { ASSIGN_THIRD_COUNT } from '../../../../graphql/InventoryActivities/InventoryActivitiesCount'

const LiveTFI = ({ dataAssignment }) => {
  const allowedStatusToThirdCount = [7, 5]
  const statusDeviated = [1, 5]
  const { id, show } = useParams()
  const history = useHistory()
  const refModal = useRef()
  const MySwal = withReactContent(Swal)
  const [loadingBtn, setLoading] = useState(false)
  const [toggledClearRows, setToggleClearRows] = useState(false)
  const [infoExpandableRows, setInfoExpandableRows] = useState([])
  const [to3rdCount, setTo3rdCounts] = useState(false)
  const [activeAddGroupBtn, setActiveAddGroupBtn] = useState(false)
  const [asignmentInfoTable, setAsignmentInfo] = useState({
    items: [],
    total: 0,
  })

  const [progressIndicatorsLive, setProgressIndicatorsLive] = useState({
    totalLabels: 0,
    labelsComplete: 0,
    labelsPending: 0,
    totalAccountants: 0,
    firstCount: {
      labelsComplete: 0,
      labelsPending: 0,
    },
    secondCount: {
      labelsComplete: 0,
      labelsPending: 0,
    },
  })

  const [
    pagePaginationLiveCountingDetails,
    setPagePaginationLiveCountingDetails,
  ] = useState({
    limit: 30,
    offset: 0,
    searchQuery: null,
    elementAssigned: show,
    idSchedule: parseInt(id) > 0 ? parseInt(id) : 0,
  })

  const [LiveCountingData, setLiveCountingData] = useState({
    items: [],
    total: 0,
  })

  const [detailsData, setDetailsData] = useState({
    items: [],
    total: 0,
  })

  const [dataAcc, setDataAcc] = useState({
    items: [],
    total: 0,
  })

  const [pagePaginationAccountants, setPagePaginationAccountants] = useState({
    searchQuery: '',
    limit: 30,
    offset: 0,
    excludeIds: [],
    onlyAssigned: false,
    scheduleId: parseInt(id),
  })

  const [
    pagePaginationAssigmentsInfoProgress,
    setPagePaginationAssigmentsInfoProgress,
  ] = useState({
    searchQuery: '',
    limit: 10,
    offset: 0,
    scheduleId: parseInt(id),
  })

  const progressLive = useQuery(GET_INVENTORY_ACTIVITIES_PROGRESS_LIVE, {
    variables: {
      idSchedule: parseInt(id) > 0 ? parseInt(id) : 0,
    },
    fetchPolicy: 'no-cache',
  })

  const assigmentsInfoProgress = useQuery(
    GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_SCHEDULE,
    {
      variables: pagePaginationAssigmentsInfoProgress,
    }
  )

  const liveCounting = useQuery(GET_ALL_INVENTORY_ACTIVITIES_LIVE_COUNTING, {
    variables: pagePaginationLiveCountingDetails,
    fetchPolicy: 'no-cache',
  })

  const [liveCountingDetails] = useLazyQuery(
    GET_ONE_INVENTORY_ACTIVITIES_LIVE_COUNTING_DETAILS,
    {
      variables: pagePaginationLiveCountingDetails,
      fetchPolicy: 'no-cache',
    }
  )

  const accountants = useQuery(GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS, {
    variables: pagePaginationAccountants,
  })

  const [finishTFI] = useMutation(FINISH_INVENTORY_ACTIVITY_SCHEDULE)

  const [assignThirdCount] = useMutation(ASSIGN_THIRD_COUNT)

  /* USEEFFECT */
  useEffect(() => {
    const interval = setInterval(async () => {
      await liveCounting.refetch()
      await progressLive.refetch()
      await assigmentsInfoProgress.refetch()
    }, 1 * 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  //FOR ACCOUNTANTS PROGRESS
  useEffect(() => {
    try {
      if (progressLive?.loading) return
      if (progressLive.error) {
        return toastSweetAlert({
          mode: 'error',
          message: progressLive.error.message,
        })
      }
      const data = progressLive.data.getInventoryActivitiesProgressLive
      setProgressIndicatorsLive({
        firstCount: data.firstCount,
        secondCount: data.secondCount,
        labelsComplete: data.labelsComplete,
        labelsPending: data.labelsPending,
        totalAccountants: data.totalAccountants,
        totalLabels: data.totalLabels,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [progressLive?.loading, progressLive?.data, progressLive?.error, id])

  //FOR ASSIGNMENT INFO
  useEffect(() => {
    try {
      if (assigmentsInfoProgress?.loading) return
      if (assigmentsInfoProgress.error) {
        return toastSweetAlert({
          mode: 'error',
          message: assigmentsInfoProgress.error.message,
        })
      }

      const data =
        assigmentsInfoProgress.data.getAllInventoryActivitiesAssignmentBySchedule.rows.map(
          (assignment) => {
            return {
              id: assignment.id,
              Nombre: assignment.InventoryActivitieLabelAccountant.full_name,
              Total: assignment.pendingLabels + assignment.completedLabels,
              'Pends./Cerradas': `${assignment.pendingLabels} / ${assignment.completedLabels}`,
              conditional_status: assignment.pendingLabels === 0 ? 3 : 2,
            }
          }
        )

      setAsignmentInfo({
        items: data,
        total:
          assigmentsInfoProgress.data
            .getAllInventoryActivitiesAssignmentBySchedule.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    assigmentsInfoProgress.loading,
    assigmentsInfoProgress.data,
    assigmentsInfoProgress.error,
  ])

  //FOR LIVE COUNTING INFO
  useEffect(() => {
    try {
      if (liveCounting?.loading) return
      if (liveCounting.error) {
        return toastSweetAlert({
          mode: 'error',
          message: liveCounting.error.message,
        })
      }

      const data =
        liveCounting?.data?.getAllInventoryActivitiesLiveCounting?.rows

      const list = data.map((item) => {
        // GET COUNTS BY COUNT_NUMBER
        const firstCount =
          item.find((element) => element.count_number === 1) || null
        const secondCount =
          item.find((element) => element.count_number === 2) || null
        const thirdCount =
          item.find((element) => element.count_number === 3) || null

        const element = item[0]?.InventoryActivitiesLabelRacks

        // SUM ALL QUANTITYS OF EACH COUNT_NUMBER
        const totalFirstCount = !firstCount
          ? 0
          : firstCount.InventoryActivitiesLabelsCounts.reduce(
              (accumulator, count) => {
                return accumulator + count.quantity
              },
              0
            )

        const totalSecondCount = !secondCount
          ? 0
          : secondCount.InventoryActivitiesLabelsCounts.reduce(
              (accumulator, count) => {
                return accumulator + count.quantity
              },
              0
            )

        const totalThirdCount = !thirdCount
          ? 0
          : thirdCount.InventoryActivitiesLabelsCounts.reduce(
              (accumulator, count) => {
                return accumulator + count.quantity
              },
              0
            )

        // IF SHOWING DETAILS, FILL THE OBJECT TO SHOW DETAILS
        if (show) {
          getAndFillDetails({
            firstCount,
            secondCount,
            thirdCount,
          })
        }

        return {
          id: item[0].id,
          element_assigned_omitField: item[0].element_assigned,
          Código: element?.code,
          Etiqueta: element?.name,
          userIdCountOne_omitField:
            firstCount?.InventoryActivitieLabelAccountant?.user_id,
          userIdCountTwo_omitField:
            secondCount?.InventoryActivitieLabelAccountant?.user_id,
          userIdCountThree_omitField:
            thirdCount?.InventoryActivitieLabelAccountant?.user_id,
          'Conteos 1er - 2do': `${totalFirstCount} / ${totalSecondCount}`,
          'Contadores 1er - 2do': `${
            firstCount
              ? firstCount.InventoryActivitieLabelAccountant.full_name
              : 'N/A'
          } / ${
            secondCount
              ? secondCount.InventoryActivitieLabelAccountant.full_name
              : 'N/A'
          }`,
          Estatus: LiveStatus(
            thirdCount
              ? thirdCount.status_deviated
              : firstCount && secondCount
              ? firstCount.completed && secondCount.completed
                ? firstCount.status_deviated
                : 0
              : 0
          ),
          conditional_status: thirdCount
            ? thirdCount.completed
              ? 1
              : 2
            : firstCount && secondCount
            ? firstCount.completed && secondCount.completed
              ? statusDeviated.includes(firstCount.status_deviated)
                ? 1
                : 0
              : 2
            : 2,
          status: thirdCount
            ? thirdCount.completed && thirdCount.status_deviated
            : firstCount && secondCount
            ? firstCount.completed && secondCount.completed
              ? firstCount.status_deviated
              : 0
            : 0,
          '3er conteo': thirdCount ? totalThirdCount : 'N/A',
          '3er contador': thirdCount
            ? thirdCount.InventoryActivitieLabelAccountant.full_name
            : 'N/A',
        }
      })

      setLiveCountingData({
        items: list,
        total: liveCounting?.data?.getAllInventoryActivitiesLiveCounting?.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    liveCounting?.loading,
    liveCounting?.data,
    liveCounting?.error,
    id,
    show,
    liveCountingDetails.loading,
    liveCountingDetails.data,
    liveCountingDetails.error,
  ])

  //FILLING THIRD ACCOUNTANT SELECTION
  useEffect(() => {
    try {
      if (accountants?.loading) return
      if (accountants.error)
        return toastSweetAlert({ mode: 'error', message: accountants.error })

      const data = accountants.data.getAllInventoryActivitiesAccountant.rows
      let items = []
      for (const item of data) {
        items.push({
          id: item.user_id,
          Nombre: item.full_name,
          user_id: item.user_id,
        })
      }

      setDataAcc({
        items: items,
        total: accountants.data.getAllInventoryActivitiesAccountant.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [accountants?.loading, accountants?.data, accountants?.error, id])

  // GET DETAILS OF A COUNT
  const getAndFillDetails = async ({ firstCount, secondCount, thirdCount }) => {
    try {
      const { data } = await liveCountingDetails()
      const groupBySKU = _.groupBy(
        data.getOneInventoryActivitiesLiveCountingDetails.rows,
        'element_found'
      )

      setInfoExpandableRows(groupBySKU)

      const detailList = Object.values(groupBySKU).map((element) => {
        const groupByLabel = _.groupBy(element, 'count_number')

        const groupByLabelOneOrdered = _.orderBy(
          groupByLabel['1'],
          ['quantity'],
          ['asc']
        )

        const groupByLabelTwoOrdered = _.orderBy(
          groupByLabel['2'],
          ['quantity'],
          ['asc']
        )

        const groupByLabelThreeOrdered = _.orderBy(
          groupByLabel['3'],
          ['quantity'],
          ['asc']
        )

        let individualStatus = true

        const totalFirstCount = groupByLabelOneOrdered.reduce(
          (accumulator, count) => {
            if (count.item_position_suite < 0) {
              individualStatus = false
            }
            return accumulator + count.quantity
          },
          0
        )

        const totalSecondCount = groupByLabelTwoOrdered.reduce(
          (accumulator, count) => {
            if (count.item_position_suite < 0) {
              individualStatus = false
            }
            return accumulator + count.quantity
          },
          0
        )

        const totalThirdCount = groupByLabelThreeOrdered.reduce(
          (accumulator, count) => {
            return accumulator + count.quantity
          },
          0
        )

        if (firstCount.completed && secondCount.completed && !totalSecondCount)
          individualStatus = false

        return {
          sku: element[0].element_found,
          Nombre: element[0].InventoryActivitiesCountsSapItems.item_name,
          Estatus: LiveStatus(
            thirdCount
              ? thirdCount.status_deviated
              : firstCount && secondCount
              ? firstCount.completed && secondCount.completed
                ? individualStatus
                  ? 1
                  : firstCount.status_deviated
                : 0
              : 0
          ),

          'Totales conteos': `${totalFirstCount} - ${totalSecondCount} - ${totalThirdCount}`,
          'Contador 1': firstCount
            ? firstCount.InventoryActivitieLabelAccountant.full_name
            : 'N/A',
          'Contador 2': secondCount
            ? secondCount.InventoryActivitieLabelAccountant.full_name
            : 'N/A',
          'Contador 3': thirdCount
            ? thirdCount.InventoryActivitieLabelAccountant.full_name
            : 'N/A',
          conditional_status: thirdCount
            ? thirdCount.completed
              ? 1
              : 0
            : firstCount && secondCount
            ? firstCount.completed && secondCount.completed
              ? individualStatus
                ? 1
                : 0
              : 2
            : 2,
          status: thirdCount
            ? thirdCount.completed && thirdCount.status_deviated
            : firstCount && secondCount
            ? firstCount.completed && secondCount.completed
              ? firstCount.status_deviated
              : 0
            : 0,
          statusLabel:
            firstCount && secondCount
              ? firstCount.completed && secondCount.completed === true
              : false,
        }
      })
      setDetailsData({
        items: detailList,
        count: data.getOneInventoryActivitiesLiveCountingDetails.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  // COMPONENT TO SHOW LIST OF ALL COUNTINGS IN UBICATION
  const ExpandedComponentLive = ({ data }) => {
    const dataSet = infoExpandableRows[data.sku]
    const groupByCountNumber = _.groupBy(dataSet, 'count_number')

    const count1 = groupByCountNumber['1'] || []
    const count2 = groupByCountNumber['2'] || []
    const count3 = groupByCountNumber['3'] || []

    let list1 = []
    let list2 = []
    let principalCount = data.statusLabel
      ? count1.length > count2.length
        ? count1
        : count2
      : count1
    let secondaryCount = data.statusLabel
      ? count2.length < count1.length
        ? count2
        : count1
      : count2

    for (const [index, count] of principalCount.entries()) {
      let oppositeCount = data.statusLabel
        ? secondaryCount[index]
          ? secondaryCount[index]
          : count
        : secondaryCount[index]

      if (!secondaryCount[index] && data.statusLabel) {
        oppositeCount = {
          // id: 1,
          vintage: null,
          expiration: null,
          quantity: 0,
          item_position_suite: -9,
        }
      }

      list1.push({
        // id: count.id,
        Cantidad: count.quantity,
        Añada: count.vintage ? count.vintage : 'N/A',
        Expiración: count.expiration
          ? moment(count.expiration).format('YYYY-MM-DD')
          : 'N/A',
        Estatus: LiveStatus(
          data.statusLabel
            ? !secondaryCount[index]
              ? oppositeCount.item_position_suite
              : count.item_position_suite === null
              ? -9
              : count.item_position_suite > 0
              ? 1
              : count.item_position_suite
            : 0
        ),
        conditionalStatus: data.statusLabel
          ? count.item_position_suite > 0
            ? 1
            : 2
          : 0,
      })

      if (oppositeCount)
        list2.push({
          // id: oppositeCount.id,
          Cantidad: oppositeCount.quantity,
          Añada: oppositeCount.vintage ? oppositeCount.vintage : 'N/A',
          Expiración: oppositeCount.expiration
            ? moment(oppositeCount.expiration).format('YYYY-MM-DD')
            : 'N/A',
          Estatus: LiveStatus(
            data.statusLabel
              ? oppositeCount.item_position_suite === null
                ? -9
                : oppositeCount.item_position_suite > 0
                ? 1
                : oppositeCount.item_position_suite
              : 0
          ),
          conditionalStatus: data.statusLabel
            ? oppositeCount.item_position_suite > 0
              ? 1
              : 2
            : 0,
        })
    }

    const list3 = count3.map((count) => {
      return {
        // id: count.id,
        Cantidad: count.quantity,
        Añada: count.vintage ? count.vintage : 'N/A',
        Expiración: count.expiration
          ? moment(count.expiration).format('YYYY-MM-DD')
          : 'N/A',
        Estatus: LiveStatus(count.completed_cycle_count_sku_ubication ? 5 : 6),
        conditionalStatus: count.completed_cycle_count_sku_ubication ? 1 : 0,
      }
    })

    return (
      <div className="row">
        <div className="col-4 d-flex align-items-stretch mt-3">
          <div class="card w-100">
            <div class="card-body">
              <SimpleTable
                data={
                  data.statusLabel
                    ? count1.length > count2.length
                      ? list1
                      : list2
                    : list1
                }
                noDataComponentText="No se han capturado conteos"
                title="Conteo 1"
                omitFields={['conditionalStatus']}
              />
            </div>
          </div>
        </div>
        <div className="col-4 d-flex align-items-stretch mt-3">
          <div class="card w-100">
            <div class="card-body">
              <SimpleTable
                data={
                  data.statusLabel
                    ? count2.length < count1.length
                      ? list2
                      : list1
                    : list2
                }
                noDataComponentText="No se han capturado conteos"
                title="Conteo 2"
                omitFields={['conditionalStatus']}
              />
            </div>
          </div>
        </div>
        {count3.length > 0 && (
          <div className="col-4 d-flex align-items-stretch mt-3">
            <div class="card w-100">
              <div class="card-body">
                <SimpleTable
                  data={list3}
                  noDataComponentText="No se han capturado conteos"
                  title="Conteo 3"
                  omitFields={['conditionalStatus']}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  /* FUNCTIONS */
  const handleFinishTFI = async (data) => {
    try {
      setLoading(true)
      await finishTFI({
        variables: {
          scheduleId: data.id,
        },
      })
      setLoading(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Se ha finalizado correctamente!',
        },
        history.push(`/inventory/reports/edit/${data.id} `)
      )
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleFinish = (e, data) => {
    MySwal.fire({
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      reverseButtons: true,
      title: 'Finalizar almacén',
      html: (
        <div className="message-container">
          ¿Estás seguro de querer finalizar la operación de inventario?
        </div>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
      focusDeny: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleFinishTFI(data)
      }
    })
  }

  //click en btn asignar tercer conteo
  const handleThirdCount = (e, row) => {
    const excludeIds = []
    if (row.userIdCountOne_omitField)
      excludeIds.push(row.userIdCountOne_omitField)
    if (row.userIdCountTwo_omitField)
      excludeIds.push(row.userIdCountTwo_omitField)
    if (row.userIdCountThree_omitField)
      excludeIds.push(row.userIdCountThree_omitField)
    setPagePaginationAccountants({ ...pagePaginationAccountants, excludeIds })
    e.preventDefault()
    refModal.current.click()
    refModal.current.setAttribute('rack', JSON.stringify([row]))
  }

  //click dentro de modal - seleccionar contador
  const handleSelectAcc = async (e, row) => {
    e.preventDefault()
    let racks = JSON.parse(refModal.current.getAttribute('rack'))
    const idsRacks = racks.map((rack) => {
      return parseInt(rack.element_assigned_omitField)
    })

    try {
      setLoading(true)
      await assignThirdCount({
        variables: {
          idRacks: idsRacks,
          idUser: row.user_id,
          scheduleId: parseInt(id),
        },
      })
      await liveCounting.refetch()

      refModal.current.click()
      setLoading(false)

      return toastSweetAlert({
        mode: 'ok',
        message: 'Tercer conteo asignado correctamente!',
      })
      //history.push(`/addresses/stores`)
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const refreshAsignationAcountantList = () => {
    const input = document.querySelector(`#accountantSearch`)
    input.value = ''

    setPagePaginationAccountants((prevState) => ({
      ...prevState,
      searchQuery: '',
    }))
  }

  const handleCheckCount = (obj) => {
    if (obj.selectedRows.length > 0) setActiveAddGroupBtn(true)
    else setActiveAddGroupBtn(false)
    setTo3rdCounts(obj.selectedRows)
  }

  const handleSelectGroup = () => {
    // console.log(to3rdCount)
    refModal.current.click()
    refModal.current.setAttribute('rack', JSON.stringify(to3rdCount))
  }

  const handleFilter = async (obj) => {
    setPagePaginationLiveCountingDetails({
      ...pagePaginationLiveCountingDetails,
      filter: parseInt(obj.target.value),
    })
  }

  const disabledCheckBox = (row) => {
    return !allowedStatusToThirdCount.includes(row.status)
  }

  /* MODES */
  const liveView = () => {
    return (
      <>
        <Box
          title="TFI activo"
          content={
            <>
              <div className="row">
                <div className="card col-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h6 className="lead">ALMACEN</h6>
                        <p className="bg-gray-light pt-1 pb-0">
                          {dataAssignment.almacen}
                        </p>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <h6 className="lead">FECHA DE INICIO</h6>
                        <p className="bg-gray-light pt-1 pb-0 text-uppercase">
                          {dataAssignment.fecha}
                        </p>
                      </div>
                      <div className="col-12 text-right">
                        <button
                          className="btn btn-accept"
                          onClick={(e) => {
                            handleFinish(e, dataAssignment)
                          }}
                        >
                          Finalizar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-lg-4 col-md-12">
                  <LayoutTable
                    title={null}
                    withCard={false}
                    hideId
                    hideAddNew
                    exportImportButton={false}
                    hideActions
                    data={asignmentInfoTable.items}
                    totalCount={asignmentInfoTable.total}
                    pagePagination={pagePaginationAssigmentsInfoProgress}
                    setPagePagination={setPagePaginationAssigmentsInfoProgress}
                    paginationPerPage={30}
                    hideBtnEdit={false}
                    hideBtnErase
                    hideDetails
                    alertColor="#fff"
                    alertSecondaryColor="#fff"
                    alertTextColor="#559615"
                    textFontWeight="bold"
                    collapsed={true}
                  />
                </div>
                <div className="col-lg-8 col-md-12">
                  <Graphics progressIndicatorsLive={progressIndicatorsLive} />
                </div>
              </div>
            </>
          }
        />

        <Box
          title="Conteo"
          withHeader={false}
          content={
            <>
              <LayoutTable
                title={
                  <>
                    <div className="row">
                      <div className="col-12 border border-1 border-dark border-top-0 border-left-0 border-right-0 pb-1">
                        <h3 className="card-title">Conteo</h3>
                      </div>
                    </div>
                  </>
                }
                withCard={false}
                hideAddNew
                exportImportButton={false}
                data={LiveCountingData.items}
                totalCount={LiveCountingData.total}
                loadingData={LiveCountingData?.loading}
                pagePagination={pagePaginationLiveCountingDetails}
                setPagePagination={setPagePaginationLiveCountingDetails}
                paginationPerPage={30}
                hideBtnEdit={true}
                hideBtnErase
                hideDetails
                hideColumns={['status']}
                filter={true}
                filterDefaultOptionText="Seleccione una opción"
                defaultValueSelect
                selectableRows={true}
                selectableRowsClick={handleCheckCount}
                selectableRowsDisabled={true}
                clearSelectedRows={toggledClearRows}
                rowDisabledCriteria={disabledCheckBox}
                alertColor="#fff"
                alertSecondaryColor="#fff"
                alertTextColor="#559615"
                alertSecondaryTextColor="#AF2525"
                alertThirdTextColor="#bab8b8"
                textFontWeight="bold"
                extraCompFilter={
                  <>
                    {activeAddGroupBtn && (
                      <button
                        className="btn btn-accept btn-thin"
                        onClick={() => handleSelectGroup()}
                      >
                        Asignar grupo 3er conteo
                      </button>
                    )}
                  </>
                }
                filterOptions={[
                  <option value="0" key="0">
                    Todos
                  </option>,
                  <option value="1" key="1">
                    Pendientes
                  </option>,
                  <option value="3" key="3">
                    Correctos
                  </option>,
                  <option value="4" key="4">
                    Con desviación cantidad
                  </option>,
                  <option value="5" key="5">
                    Con desviación añada
                  </option>,
                  <option value="6" key="6">
                    Con desviación caducidad
                  </option>,
                ]}
                filterOnChange={handleFilter}
                componentAction={(row) => {
                  return (
                    <>
                      <Link
                        to={
                          '/inventory/live/details/' +
                          id +
                          '/' +
                          row.element_assigned_omitField
                        }
                        className="btn btn-accept"
                      >
                        Detalles
                      </Link>
                      {allowedStatusToThirdCount.includes(row.status) && (
                        <span
                          className="btn btn-accept"
                          onClick={(e) => handleThirdCount(e, row)}
                        >
                          {row.status === 5
                            ? 'Volver hacer 3er conteo'
                            : 'Realizar 3er conteo'}
                        </span>
                      )}
                    </>
                  )
                }}
              />
            </>
          }
        />
      </>
    )
  }

  const liveDetailsView = () => {
    return (
      <>
        <LayoutTable
          title="Detalle del marbete"
          data={LiveCountingData.items}
          totalCount={LiveCountingData.total}
          loadingData={liveCounting.loading}
          paginationPerPage={30}
          paginationServer={false}
          hideFilterBar
          hideDetails
          hidePagination
          hideColumns={['status']}
          noDataComponent="Ubicación vacía"
          alertColor="#fff"
          alertSecondaryColor="#fff"
          alertTextColor="#559615"
          alertSecondaryTextColor="#AF2525"
          alertThirdTextColor="#bab8b8"
          textFontWeight="bold"
          componentAction={(row) => {
            return (
              <>
                {allowedStatusToThirdCount.includes(row.status) && (
                  <span
                    className="btn btn-accept"
                    onClick={(e) => handleThirdCount(e, row)}
                  >
                    {row['3er contador'] !== 'N/A'
                      ? 'Volver hacer 3er conteo'
                      : 'Realizar 3er conteo'}
                  </span>
                )}

                {/* {row.status === 0 && (
                  <span
                    className="btn btn-accept"
                    onClick={(e) => {
                      handleThirdCount(e, row)
                    }}
                  >
                    {row['3er contador'] !== 'N/A'
                      ? 'Volver hacer 3er conteo'
                      : 'Realizar 3er conteo'}
                  </span>
                )} */}
              </>
            )
          }}
        />
        <LayoutTable
          title="Conteo por SKU"
          data={detailsData.items}
          totalCount={detailsData.total}
          expandableRows
          expandableRowsComponent={ExpandedComponentLive}
          loadingData={liveCountingDetails.loading}
          paginationPerPage={30}
          hideFilterBar
          hideDetails
          hideActions
          hideColumns={['status', 'statusLabel']}
          noDataComponent="Ubicación vacía"
          alertColor="#fff"
          alertSecondaryColor="#fff"
          alertTextColor="#559615"
          alertSecondaryTextColor="#AF2525"
          alertThirdTextColor="#bab8b8"
          textFontWeight="bold"
          append={
            <div className="row mt-5">
              <div className="col-12 text-right">
                <button
                  className="btn btn-border-1"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Atrás
                </button>
              </div>
            </div>
          }
        />
      </>
    )
  }

  return (
    <>
      <div className={` ${loadingBtn ? 'processing-file' : ''}`}>
        {id && show === undefined && liveView()}
        {id && show !== undefined && liveDetailsView()}

        <>
          <span
            ref={refModal}
            className="btn btn-accept btn-sm invisible"
            data-toggle="modal"
            data-target="#modal-third-counting"
            data-backdrop="static"
            data-keyboard="false"
            data-dismiss="modal"
          ></span>
          <ModalContent
            idModal="modal-third-counting"
            title="Asignar 3er contador"
            onClose={refreshAsignationAcountantList}
            content={
              <LayoutTable
                title={null}
                withCard={false}
                searchId="accountantSearch"
                data={dataAcc.items}
                totalCount={dataAcc.total}
                // loadingData={accountants?.loading}
                pagePagination={pagePaginationAccountants}
                setPagePagination={setPagePaginationAccountants}
                paginationPerPage={30}
                hideBtnEdit
                hideDetails
                hideColumns={['user_id']}
                componentAction={(row) => {
                  return (
                    <div className="w-100 pr-4 text-center">
                      <span
                        className="btn btn-accept"
                        onClick={(e) => {
                          handleSelectAcc(e, row)
                        }}
                      >
                        Asignar 3er conteo
                      </span>
                    </div>
                  )
                }}
              />
            }
          />
        </>
      </div>
    </>
  )
}

export default LiveTFI
