import { gql } from '@apollo/client'

export const GET_ALL_STORE_HELPER_COUNTER_CHARGES = gql`
  query GetAllStoresCounterCharges(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllStoresCounterCharges(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        consecutive_number
        consecutive_number_manual_note
        nomenclature_initials
        invoice_payments
        storesCounterCharges {
          alias
        }
      }
      count
    }
  }
`

export const GET_ONE_STORE_HELPER_COUNTER_CHARGES = gql`
  query GetOneStoreCounterChargesById($id: Int!) {
    getOneStoreCounterChargesById(id: $id) {
      id_store
      consecutive_number
      consecutive_number_manual_note
      nomenclature_initials
      invoice_payments
    }
  }
`

export const GET_INFO_STORE_COUNTER_CHARGES = gql`
  query GetInfoStoreCounterCharges($id: Int) {
    getInfoStoreCounterCharges(id: $id) {
      id
      consecutive_number
      consecutive_number_manual_note
      nomenclature_initials
      invoice_payments
      id_store
    }
  }
`

export const CREATE_STORE_HELPER_COUNTER_CHARGES = gql`
  mutation CreateStoreCounterCharges(
    $storeCounterChargesInput: storeCounterChargesInput!
  ) {
    createStoreCounterCharges(
      storeCounterChargesInput: $storeCounterChargesInput
    ) {
      id_store
      consecutive_number
      consecutive_number_manual_note
      nomenclature_initials
      invoice_payments
    }
  }
`

export const UPDATE_STORE_HELPER_COUNTER_CHARGES = gql`
  mutation UpdateStoreCounterCharges(
    $updateStoreCounterChargesId: Int!
    $storeCounterChargesInput: storeCounterChargesInput!
  ) {
    updateStoreCounterCharges(
      id: $updateStoreCounterChargesId
      storeCounterChargesInput: $storeCounterChargesInput
    )
  }
`

export const DELETE_STORE_HELPER_COUNTER_CHARGES = gql`
  mutation DeleteStoreCounterCharges($id: Int!) {
    deleteStoreCounterCharges(id: $id)
  }
`
