import { gql } from '@apollo/client'

export const GET_ALL_OMNICHANNEL_USER_CREDENTIALS = gql`
  query GetOmnichannelCredentials {
    getOmnichannelCredentials {
      id
      user_id
      shop_url
      token
      public_key
      secret_key
      refresh_token
      expire
      initial_sync
      api_version
      productive_link
      EcommerceClientCredentialsPlatform {
        id
        name
      }
    }
  }
`
