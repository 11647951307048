import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { GET_POWER_BI_TOKEN } from '../../../graphql/Documents/PowerBi/powerBi'
import ModalWarehouseReports from '../Global/ModalWarehouseReports'
import { validateDatesForCreateReports } from '../../WarehouseDashboards/warehouseOperationValidations'
import { CREATE_WAREHOUSES_REPORTS } from '../../../graphql/Catalog/WarehouseDashboard/warehouseDashboard'

const ShipmentDashboard = () => {
  /**-------- Hooks */
  const [loading, setLoading] = useState(true)
  const [accessToken, setAccessToken] = useState('')
  const refModal = useRef()
  const [form, setForm] = useState({
    start: '',
    end: '',
    reportsType: 0,
  })
  /**------------ Queries ------  */
  const {
    data: dataPowerBiToken,
    loading: loadingPowerBiToken,
    error: errorPowerBiToken,
  } = useQuery(GET_POWER_BI_TOKEN)

  const [CreateWarehousesReports] = useMutation(CREATE_WAREHOUSES_REPORTS)

  /**------------ useEffects */
  useEffect(() => {
    if (errorPowerBiToken)
      return toastSweetAlert({
        mode: 'error',
        message: errorPowerBiToken.message,
      })

    if (!loadingPowerBiToken) {
      //Get Access token
      const token = dataPowerBiToken?.getPowerBiToken?.token
        ? dataPowerBiToken.getPowerBiToken.token
        : null
      setAccessToken(token)

      setLoading(false)
    }
  }, [dataPowerBiToken, loadingPowerBiToken, errorPowerBiToken])

  const reportFilterHandleButton = async () => {
    try {
      const { start, end, reportsType } = form

      if (reportsType == 5) {
        //Custom range
        //Validate data
        const getValidation = validateDatesForCreateReports({
          rangeStart: start,
          rangeEnd: end,
        })
        if (getValidation.error)
          return toastSweetAlert({
            mode: 'error',
            message: getValidation.msg,
          })
      }

      //Reset hook
      setForm({
        start: '',
        end: '',
        reportsType: 0,
      })
      refModal.current.click()

      //All correct - proceed
      toastSweetAlert({
        mode: 'loading',
        message: 'Iniciando proceso, puede tardar algunos minutos',
      })

      //Create mutation to send information
      const res = await CreateWarehousesReports({
        variables: {
          start,
          end,
          reportsType,
          mode: 4, //SHIPMENT
        },
      })
      const { status, message } = res.data.createWarehousesReports

      if (!status)
        return toastSweetAlert({
          mode: 'error',
          message: message,
        })

      return toastSweetAlert({
        mode: 'ok',
        message: message,
        // message: 'Creado con exito',
      })
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <div className="content-header">
        <div className="container-fluid">
          <div className="py-2 d-flex justify-content-between">
            <h1 className="font-weight-bold">Embarques</h1>
            <button
              type="button"
              className="btn StandarModalConfirmButtonLogOut"
              onClick={() => {
                refModal.current.click()
              }}
            >
              Generar reporte
            </button>
          </div>
        </div>
      </div>
      <ModalWarehouseReports
        refModal={refModal}
        handleSubmit={reportFilterHandleButton}
        setForm={setForm}
        form={form}
      />

      {!loading && (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {/* <PickingDashboardSection 
              formmatNumber={formmatNumber}
              firstSection={firstSection}
              secondSection={secondSection}
            /> */}

            <PowerBIEmbed
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                id: '2a47c536-56d4-41f8-be61-405be1dc7138',
                embedUrl:
                  'https://app.powerbi.com/reportEmbed?reportId=8c030dcb-b8ae-4dfd-9796-1caf5592a145&groupId=57164b8e-8e11-4618-a744-30ca22b26d35&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUNBTkFEQS1DRU5UUkFMLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
                accessToken,
                tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    function () {
                      console.log('Report loaded')
                    },
                  ],
                  [
                    'rendered',
                    function () {
                      console.log('Report rendered')
                    },
                  ],
                  [
                    'error',
                    function (event) {
                      console.log(event.detail)
                    },
                  ],
                ])
              }
              cssClassName={'embed-container'}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ShipmentDashboard
