import { gql } from '@apollo/client'

export const GET_INVENTORY_ACTIVITIES_PROGRESS_LIVE = gql`
  query GetInventoryActivitiesProgressLive($idSchedule: Int!) {
    getInventoryActivitiesProgressLive(idSchedule: $idSchedule) {
      totalLabels
      labelsComplete
      labelsPending
      totalAccountants
      firstCount {
        totalLabels
        labelsComplete
        labelsPending
      }
      secondCount {
        totalLabels
        labelsComplete
        labelsPending
      }
    }
  }
`

export const GET_ALL_INVENTORY_ACTIVITIES_LIVE_COUNTING = gql`
  query GetAllInventoryActivitiesLiveCounting(
    $idSchedule: Int!
    $elementAssigned: String
    $limit: Int
    $offset: Int
    $searchQuery: String
    $filter: Int
  ) {
    getAllInventoryActivitiesLiveCounting(
      idSchedule: $idSchedule
      elementAssigned: $elementAssigned
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      filter: $filter
    ) {
      count
      rows {
        id
        inventory_schedule_id
        user_id
        count_number
        status_deviated
        element_assigned
        completed
        InventoryActivitieLabelAccountant {
          user_id
          full_name
        }
        InventoryActivitiesLabelRacks {
          code
          id
          name
          ItemPositionToRackPosition {
            on_hand
          }
        }
        InventoryActivitiesLabelSKU {
          id
          item_name
        }
        InventoryActivitiesLabelsCounts {
          vintage
          element_found
          expiration
          id
          inventory_activity_label_id
          quantity
          InventoryActivitiesCountsRacks {
            code
            id
            name
          }
        }
      }
    }
  }
`

export const GET_ONE_INVENTORY_ACTIVITIES_LIVE_COUNTING_DETAILS = gql`
  query GetOneInventoryActivitiesLiveCountingDetails(
    $idSchedule: Int!
    $elementAssigned: String!
    $limit: Int
    $offset: Int
    $searchQuery: String
    $filter: Int
  ) {
    getOneInventoryActivitiesLiveCountingDetails(
      idSchedule: $idSchedule
      elementAssigned: $elementAssigned
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      filter: $filter
    ) {
      count
      rows {
        InventoryActivitiesCountsSapItems {
          item_code
          item_name
        }
        InventoryActivitiesCountsItemPosition {
          on_hand
        }
        vintage
        element_found
        expiration
        id
        inventory_activity_label_id
        quantity
        count_number
        item_position_suite
        completed_cycle_count_sku_ubication
      }
    }
  }
`

export const FINISH_INVENTORY_ACTIVITY_SCHEDULE = gql`
  mutation FinishInventoryActivitySchedule($scheduleId: Int!) {
    finishInventoryActivitySchedule(scheduleId: $scheduleId)
  }
`

export const ASSIGN_INVENTORY_ACTIVITY_LABEL = gql`
  mutation AsignInventoryActivitiesLabel(
    $input: AsignInventoryActivitiesLabelInput!
  ) {
    AsignInventoryActivitiesLabel(input: $input)
  }
`
