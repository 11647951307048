import { gql } from '@apollo/client'

export const GET_SAP_PURCHASES_ORDERS_LINES = gql`
  query GetSapPurchasesOrdersLines($limit: Int, $offset: Int) {
    getSapPurchasesOrdersLines(limit: $limit, offset: $offset) {
      id
      purchases_order_id
      line_number
      item_code
      target_type
      document_entry
      target_entry
      line_status
      quantity
      open_quantity
      price
      tax_code
      disc_prcnt
      line_total
      warehouses_code
      ieps
      iva
      total
    }
  }
`

export const GET_SAP_PURCHASES_ORDERS_LINES_BY_PURCHASE_ORDERS_ID = gql`
  mutation GetSapPurchasesOrdersLinesByPurchaseOrdersId($purchasesOrdersId: [Int!]!) {
    getSapPurchasesOrdersLinesByPurchaseOrdersId(purchasesOrdersId: $purchasesOrdersId) {
      OrderLines {
        id
        purchases_order_id
        quantity
        price
        warehouses_code
        SapPurchasesOrdersLinesWarehouses {
          id
        }
        PurchasesLinesItemInfo {
          item_code
          item_name
        }
        item_code
      }
      PurchaseOrders {
        id
        document_date
        comments
        document_number
        number_at_card
      }
    }
  }
`
