import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useState } from 'react'
import {
  DELETE_PAYMENTS_CHECK,
  GET_ALL_CHECKS_NOTE_BY_ID,
} from '../../../../../../graphql/Catalog/Notes/payments'
import Box from '../../../../../Global/Box'
import LayoutTable from '../../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../../Layout/ContentHeader'

export const PaymentsHistory = ({
  idNote,
  handleToBillsByIdCheck,
  handleBillsHistoryPrevious,
}) => {
  const id = idNote

  const [dataTable, setDataTable] = useState([])
  const [total, setTotal] = useState()
  const [pagePagination, setPagePagination] = useState({
    limit: 10,
    offset: 0,
    idNote: id,
  })

  const {
    loading: loadingGetAllChecksNoteById,
    error: errorGetAllChecksNoteById,
    data: dataGetAllChecksNoteById,
    refetch: refetchGetAllChecksNoteById,
  } = useQuery(GET_ALL_CHECKS_NOTE_BY_ID, {
    variables: {
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      idNote: pagePagination.idNote,
    },
    fetchPolicy: 'no-cache',
  })

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  useEffect(() => {
    if (!loadingGetAllChecksNoteById) {
      if (errorGetAllChecksNoteById)
        return toastSweetAlert({
          mode: 'error',
          messasge: errorGetAllChecksNoteById.messasge,
        })
      let list = []
      dataGetAllChecksNoteById.getAllPaymentsNoteById.rows.map((item) => {
        return list.push({
          id: item.id,
          'Folio de pago': item.invoice_number_payments,
          Referencia: item.reference_pay,
          fecha: item.date_check,
          Monto: formmatterCurrency(item.amount_check_payment),
        })
      })
      setDataTable(list)
      setTotal(dataGetAllChecksNoteById.getAllPaymentsNoteById.count)
    }
  }, [
    loadingGetAllChecksNoteById,
    errorGetAllChecksNoteById,
    dataGetAllChecksNoteById,
  ])

  return (
    <>
      <ContentHeader
        windowTitle="Historial de Pagos"
        breadcrumb="Historial de Pagos"
        title="Historial de Pagos"
      />

      <Box
        title="Elija un Cheque"
        btnSecondFunctionTitle="Atras"
        btnSecondFunction={handleBillsHistoryPrevious}
        content={
          <>
            <LayoutTable
              data={dataTable}
              totalCount={total}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              gql={DELETE_PAYMENTS_CHECK}
              hideId={true}
              hideFilterBar={true}
              withCard={false}
              hideAddNew
              exportImportButton={false}
              hideBtnEdit={true}
              noDataComponent="Aún no se han registrado pagos para está nota"
              actionBtnDetail={(id) => handleToBillsByIdCheck(id)}
              refetchFunctionQueries={async () =>
                await refetchGetAllChecksNoteById()
              }
            />
          </>
        }
      />
    </>
  )
}

export default PaymentsHistory
