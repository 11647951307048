import { useContext, useEffect, useState } from 'react'
import LayoutTable from '../../../Global/LayoutTable'
import { useLazyQuery, useQuery, useSubscription } from '@apollo/client'
import { GET_ALL_GENERATED_REPORTS_BY_USER_ID } from '../../../../graphql/Catalog/ProvidersDashboard/historialReport'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import moment from 'moment/moment'
import { CREATE_PROVIDERS_REPORTS_SUBSCRIPTION } from '../../../../graphql/Catalog/ProvidersDashboard/providersDashboard'
import { AuthContext } from '../../../../Auth/AuthContext'

const Reports = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [dataToGetLinks, setDataToGetLinks] = useState([])
  const { user } = useContext(AuthContext)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)

  function stringToBytes(text) {
    const length = text.length
    const result = new Uint8Array(length)
    for (let i = 0; i < length; i++) {
      const code = text.charCodeAt(i)
      const byte = code > 255 ? 32 : code
      result[i] = byte
    }
    return result
  }

  const handleDropDownButton = (e, id) => {
    let files = []
    let mimeType = ''
    const data = dataToGetLinks.find((item) => item.id == id)

    // console.log(data)
    let flagError = false
    if (e.target.dataset.download == 'csv') {
      const filesCSV = data.files.filter((file) => {
        if (file.url == null && file.type == 'csv') {
          flagError = true
        }

        return file.type == 'csv'
      })

      files = filesCSV
      mimeType = 'text/csv; charset=utf-8'
    } else {
      const filesPDF = data.files.filter((file) => {
        if (file.url == null && file.type == 'pdf') {
          flagError = true
        }

        return file.type === 'pdf'
      })
      files = filesPDF
      mimeType = 'application/pdf; charset=utf-8'
    }
    // for (const file of data.files) {
    if (flagError || files.length == 0) {
      toastSweetAlert({
        mode: 'error',
        message: 'No se encontraron todos los documentos',
      })
    }

    for (const file of files) {
      const originalString = file.url
      if (originalString == null) {
        continue
      }

      const bytes = stringToBytes(originalString)

      const url = window.URL.createObjectURL(
        new Blob([bytes.buffer], {
          type: mimeType,
        })
      )

      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', '')
      document.body.appendChild(a)
      a.click()
    }
  }

  const { data, error, loading } = useQuery(
    GET_ALL_GENERATED_REPORTS_BY_USER_ID,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )
  const [getAllGenerateReports] = useLazyQuery(
    GET_ALL_GENERATED_REPORTS_BY_USER_ID
  )

  const { data: reportSuscriptionData, loading: reportSuscriptionLoading } =
    useSubscription(CREATE_PROVIDERS_REPORTS_SUBSCRIPTION)

  useEffect(() => {
    if (!loading) {
      if (error) {
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      }
      // console.log(data)
      const list = data.getAllReportsByUserId.rows.map((item) => {
        // const date = moment(+item.createdAt).format('DD MMMM, YYYY')

        return {
          reporte: item.name,
          ['tipo de reporte']:
            item.BusinessPartnerReportFilesToBusinessPartnerReportFileTypes
              ?.name,
          id: item.id,
        }
      })

      // console.log(reportSuscriptionData, 'SUSCRIPCION')
      // console.log(reportSuscriptionLoading, 'CARGA DE SUSCRIPCION')

      setDataToTable(list)
      setTotal(data.getAllReportsByUserId.count)
      setDataToGetLinks(data.getAllReportsByUserId.rows)
    }
  }, [data, error, loading])

  // useEffect(() => {
  //   if (reportSuscriptionData) {
  //     console.log('SI ENTRA ')
  //     console.log(reportSuscriptionData)
  //     // refetchReports()
  //   }
  //   console.log(reportSuscriptionData, 'FUERA DEL IF')
  // }, [reportSuscriptionData])

  const refetchReports = async () => {
    try {
      const { data, error } = await getAllGenerateReports()

      const list = data.getAllReportsByUserId.rows.map((item) => {
        return {
          reporte: item.name,
          ['tipo de reporte']:
            item.BusinessPartnerReportFilesToBusinessPartnerReportFileTypes
              ?.name,
          id: item.id,
        }
      })
      setDataToTable(list)
      setTotal(data.getAllReportsByUserId.count)
      setDataToGetLinks(data.getAllReportsByUserId.rows)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <LayoutTable
            title="Lista de reportes"
            hideBtnEdit={true}
            requery={GET_ALL_GENERATED_REPORTS_BY_USER_ID}
            hideBtnErase={true}
            dropDownButton={true}
            data={dataToTable}
            hideAddNew={true}
            hideDetails={true}
            exportImportButton={false}
            handleDropDownButton={handleDropDownButton}
            setPagePagination={setPagePagination}
            pagePagination={pagePagination}
            totalCount={total}
            loadingData={loading}
            hideId

            // paginationServer={false}
            // setPagePagination={() => {}}
            // searchByProperty="tipo de reporte"
          ></LayoutTable>
        </div>
      </div>

      {/* <div className="mt-5">
        <h3 className="text-center  mb-4">Reportes anteriores</h3>
        <p className="text-center mb-4">
          para ver reportes pasados y mas cambia tu paquete
        </p>
        <div className="d-flex justify-content-center">
          <button className="btn btn-secondary py-2 px-4 " type="button">
            Ver paquetes
          </button>
        </div>
      </div> */}
    </>
  )
}

export default Reports
