import React from 'react'

const ModalContent = ({
  content,
  title = 'Modal title',
  idModal = 'defaultModalId',
  size = 'normal',
  onClose = () => {},
}) => {
  return (
    <div
      className="modal fade"
      id={idModal}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`${idModal}Title`}
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-dialog-scrollable modal-dialog-centered ${
          size === 'extra'
            ? 'modal-xl'
            : size === 'large'
            ? 'modal-lg'
            : size === 'small'
            ? 'modal-sm'
            : ''
        }`}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
            <button
              type="button"
              className="modal-close-button swal2-close align-items-end"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true" onClick={onClose}>
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">{content}</div>
        </div>
      </div>
    </div>
  )
}

export default ModalContent
