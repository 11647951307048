import { gql } from "@apollo/client";

export const GET_DISPLACEMENTS_AND_INVENTORY_REPORT = gql`
    query GetDisplacementsAndInventoryReports($searchQuery: String, $limit: Int, $offset: Int, $filter: Int, $type: Int) {
        getDisplacementsAndInventoryReports(searchQuery: $searchQuery, limit: $limit, offset: $offset, filter: $filter, type: $type) {
            rows {
                id
                item_code
                item_name
                obj
            }
            count
            filterOptions
            haveDetailsPermission
        }
    }
`

export const GET_DISPLACEMENTS_AND_INVENTORY_COUNTERS_REPORT = gql`
    query GetDisplacementsAndInventoryCountersReport($idSapItem: Int!, $type: Int!, $filter: Int!) {
        getDisplacementsAndInventoryCountersReport(idSapItem: $idSapItem, type: $type, filter: $filter) {
            item_name
            item_code
            counters {
                id
                warehouse_name
                count
                date
            }
        }
    }
`