import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../../../../Auth/AuthContext'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_PASSWORD } from '../../../../../graphql/Catalog/Users/user'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { passwordValidator } from '../../../../Helpers/validatorCustomerForm'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import InputController from '../../../../Global/InputController'
import { types } from '../../../../../Types/types'

const MySwal = withReactContent(Swal)

export const UserInfo = ({ info }) => {
  const { dispatch } = useContext(AuthContext)
  const [_data, set_data] = useState(null)
  const [display, setDisplay] = useState(false)
  const history = useHistory()

  const [updatePassword] = useMutation(UPDATE_USER_PASSWORD)
  const data = info
  useEffect(() => {
    if (data) {
      set_data(data.GetUserById)
    }
  }, [data])

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(passwordValidator),
  })

  const handleLogout = () => {
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('requestPath')
    localStorage.removeItem('token')
    localStorage.removeItem('configSys')
    dispatch({
      type: types.logout,
    })
  }

  const handleUpdatePassword = async (data) => {
    MySwal.fire({
      showCloseButton: true,
      icon: 'question',
      title: 'Actualizar contraseña',
      html: (
        <div className="message-container">¿Seguro que desea continuar?</div>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showDenyButton: true,
      confirmButtonText: 'Actualizar',
      denyButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updatePassword({
            variables: {
              idUser: parseInt(_data.id),
              currentPassword: data.currentPassword,
              password: data.password,
            },
          })
          await handleLogout()
          return toastSweetAlert(
            {
              mode: 'okModal',
              message:
                'Contraseña actualizada correctamente, por favor inicie sesión nuevamente',
            },
            history.push(`/`)
          )
        } catch (errors) {
          toastSweetAlert({
            mode: 'error',
            message: errors.message,
          })
        }
      }
    })
  }

  return (
    <div className="col-lg-9 col-md-6 col-sm-12">
      <div className="card">
        <div className="card-header p-2">
          <h3 className="card-title" style={{ marginLeft: '15px' }}>
            Información General
          </h3>
        </div>
        <div className="card-body">
          <div className="d-lg-flex d-md-block row">
            {_data && (
              <>
                <div className="col-lg-4 col-md-12 col-sm-6">
                  <b className="cardTitle">Nombre</b>
                  <p className="cardContent">
                    {_data.name + ' ' + _data.first_name}
                  </p>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-6">
                  <b className="cardTitle">Usuario</b>
                  <p className="cardContent">{_data.username}</p>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <b className="cardTitle">Correo</b>
                  <p className="cardContent">{_data.email}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header p-2">
          <h3 className="card-title" style={{ marginLeft: '15px' }}>
            Información de dirección
          </h3>
        </div>
        <div className="card-body">
          <div className="row">
            {_data && (
              <>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">Calle</b>
                  <p className="cardContent">{_data.address.street}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">Ext.</b>{' '}
                  <p className="cardContent">{_data.address.external_number}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">Int</b>{' '}
                  <p className="cardContent">{_data.address.internal_number}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">C.P.</b>
                  <p className="cardContent">{_data.address.zip_code}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">Colonia</b>{' '}
                  <p className="cardContent">{_data.address.colony.name}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">Ciudad</b>{' '}
                  <p className="cardContent">{_data.address.city.name}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">Municipio</b>{' '}
                  <p className="cardContent">{_data.address.colony.name}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <b className="cardTitle">Estado</b>{' '}
                  <p className="cardContent">{_data.address.colony.name}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header p-2">
          <h3
            className="card-title"
            style={{ marginLeft: '16px', marginTop: '5px' }}
          >
            Actualizar Contraseña
          </h3>
          {!display && (
            <button
              className="btn btn-accept btn-sm float-right"
              style={{ marginTop: '3px' }}
              onClick={() => setDisplay(true)}
            >
              Actualizar
            </button>
          )}
        </div>
        {display && (
          <div className="card-body">
            <form onSubmit={handleSubmit(handleUpdatePassword)}>
              <InputController
                label="Contraseña actual"
                type="password"
                name="currentPassword"
                placeholder="Ingrese la contraseña actual"
                control={control}
              />
              <br />
              <InputController
                label="Nueva Contraseña"
                type="password"
                name="password"
                placeholder="Ingrese la nueva contraseña"
                control={control}
              />
              <br />
              <InputController
                label="Confirmar nueva contraseña"
                type="password"
                name="passwordConfirm"
                placeholder="Confirme la contraseña"
                control={control}
              />
              <div className="row mt-3">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  {display && (
                    <button
                      type="submit"
                      className="buttonFirstAction"
                      onClick={() => setDisplay(false)}
                    >
                      <b>Cancelar</b>
                    </button>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button type="submit" className="buttonSecondAction">
                    <b>Actualizar contraseña</b>
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}
