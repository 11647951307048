import { useState } from "react"

const DownloadReports =()=>{
    const [modalData,setModalData] = useState({
        dateStart: '',
        dateEnd: '',
    })

    const handleChange =(e,type)=>{
        setModalData({
            ...modalData,
            [type]: e.target.value
        })
    }

    const handleDownload =()=>{
        try {
            console.log("downloading...")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="DownloadReportsMain mt-5">
            <h5>Fecha</h5>
            <hr />

            <div className="row datesBox mb-4">
                <div className="col-12">
                    <label className='title-filter mr-2' htmlFor='dateStart'>Desde:</label>
                    <input 
                        id="dateStart" 
                        onChange={e => handleChange(e,"dateStart")} 
                        className='inputDate' 
                        type="date" 
                    />
                    <label className='title-filter mr-2' htmlFor='dateEnd'>&nbsp;Hasta:&nbsp;</label>
                    <input
                        id="dateEnd" 
                        onChange={e => handleChange(e,"dateEnd")} 
                        className='inputDate mr-3' 
                        type="date" 
                    />
                </div>
            </div>

            <h5>Datos a incluir</h5>
            <hr />

            <div className="row dataCheckBox">

                <div className="col-6">
                    <input id="inventaryChbx" name="inventaryChbx" type="checkbox" />
                    <label htmlFor="inventaryChbx">Inventarios</label>
                </div>
                <div className="col-6">
                    <input id="monthSalesChbx" name="monthSalesChbx" type="checkbox" />
                    <label htmlFor="monthSalesChbx">Ventas por mes</label>
                </div>

                <div className="col-6">
                    <input id="displacementsChbx" name="displacementsChbx" type="checkbox" />
                    <label htmlFor="displacementsChbx">Desplazamientos</label>
                </div>
                <div className="col-6">
                    <input id="salesUnitsChbx" name="salesUnitsChbx" type="checkbox" />
                    <label htmlFor="salesUnitsChbx">Unidades vendidas</label>
                </div>

                <div className="col-6">
                    <input id="avarageTicketChbx" name="avarageTicketChbx" type="checkbox" />
                    <label htmlFor="avarageTicketChbx">Ticket promedio</label>
                </div>
                <div className="col-6">
                    <input id="inventoryDaysChbx" name="inventoryDaysChbx" type="checkbox" />
                    <label htmlFor="inventoryDaysChbx">Días de inventario</label>
                </div>

                <div className="col-12" style={{textAlign:'left'}}>
                    <input id="centerSalesChbx" name="centerSalesChbx" type="checkbox" />
                    <label htmlFor="centerSalesChbx">Ventas por centro de consumo</label>
                </div>

            </div>

            <hr />

            {/* <div className="row buttonsBox">
                <span className="btn btn-accept btn-sm cancel">Cancelar</span>
                <span className="btn btn-accept btn-sm" onClick={handleDownload}>Descargar</span>
            </div> */}
        </div>
    )
}

export default DownloadReports