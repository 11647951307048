import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  CREATE_INTERNAL_NOTE,
  GET_INTERNAL_NOTES,
} from '../../../graphql/Catalog/InternalNotes/internalNotes'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { AuthContext } from '../../../Auth/AuthContext'
import pdfIcon from '../../../Assets/icons/pdf.svg'
import { useRef } from 'react'
import moment from 'moment'

const InternalNotes = ({ idOrder }) => {
  const { user } = useContext(AuthContext)
  const [values, setValues] = useState({
    text: '',
    document: '',
  })
  const [messages, setMessages] = useState(null)
  const [previewFile, setPreviewFile] = useState(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const { text, document } = values
  const refResetDocument = useRef()
  const boxScroll = useRef()
  const [createNote] = useMutation(CREATE_INTERNAL_NOTE, {
    refetchQueries: [
      {
        query: GET_INTERNAL_NOTES,
        variables: { orderId: idOrder },
        fetchPolicy: 'no-cache',
      },
    ],
  })
  const { data, loading, error } = useQuery(GET_INTERNAL_NOTES, {
    variables: { orderId: idOrder },
  })
  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      //PINTAR DATOS EN INTERNAL
      let list = []
      data.getInternalNotes.map((item) => {
        list.push({
          user: item?.user?.name ? item.user.name : '',
          text: item?.text ? item.text : '',
          file: item?.fileInternal?.url ? item.fileInternal.url : '',
          type: item?.type ? item.type : '',
          date: item?.createdAt
            ? moment(item.createdAt).format('DD/MM/YY hh:mm a')
            : '',
        })
        return true
      })
      setMessages(list)
    }
  }, [data, loading, error])
  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    })
  }
  const sendInternalNotes = async () => {
    //Validate inputs data
    if (!text && !document)
      return toastSweetAlert({
        mode: 'error',
        message: 'Debe escribir o subir un documento',
      })

    setLoadingUpload(true)
    let type = null
    if (document) {
      type = document.type.split('/')
      if (
        type[1] !== 'pdf' &&
        type[0] !== 'image' &&
        type[1] !== 'jpg' &&
        type[1] !== 'png' &&
        type[1] !== 'jpeg'
      ) {
        setLoadingUpload(false)

        return toastSweetAlert({
          mode: 'error',
          message: 'Archivo incompatible',
        })
      }
      if (document.size > 5242880) {
        console.log(document)
        setLoadingUpload(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'Archivo es demasiado pesado',
        })
      }
    }
    //Insert data
    try {
      await createNote({
        variables: {
          inputInternalNote: {
            user_id: user.idUser,
            order_id: idOrder,
            text: text === '' ? null : text,
            file: document === '' ? null : document,
            type: document ? type[1] : null,
          },
        },
      })
      //If the Insert was a successfull
      setValues({
        text: '',
        document: '',
      })
      setLoadingUpload(false)
      return (refResetDocument.current.value = '')
    } catch (e) {
      setLoadingUpload(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleSetUpload = (e) => {
    setValues({ ...values, document: e.target.files[0] })
    putPreview(e)
  }

  const putPreview = (e) => {
    var fileToLoad = e.target.files[0]
    var fileReader = new FileReader()
    var base64File
    fileReader.onload = function (event) {
      base64File = event.target.result
      setPreviewFile(base64File)
    }

    fileReader.readAsDataURL(fileToLoad)
  }

  /* set Scroll to bottom position */
  useEffect(() => {
    if (boxScroll) {
      const element = boxScroll.current
      element.scrollTop = element.scrollHeight
    }
  }, [boxScroll, data, loading, error])

  const handleDeleteDoc = () => {
    return (
      setValues({ text: '', document: '' }),
      setPreviewFile(null),
      (refResetDocument.current.value = '')
    )
    // document.getElementById('internalNotesDocument').value = ''
    // document.getElementById('').value = ''
  }
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Notas internas</h3>
          </div>
          <div
            className={`card-body internalNotes-body ${
              loadingUpload ? 'processing-file' : ''
            }`}
          >
            {/* Show history */}
            <div ref={boxScroll} className="boxHistory">
              {messages?.length > 0 ? (
                <ul>
                  {messages.map((item, index) => (
                    <React.Fragment key={index}>
                      <div>
                        <div className="mt-4">
                          <b className="user">{item.user}&nbsp;&nbsp;</b>
                          {item.text === '' && item.file && (
                            <i className="fas fa-file"></i>
                          )}
                          {item.text && <i className="fas fa-comment"></i>}
                          <capion className="float-right mr-2">
                            {item.date}
                          </capion>
                        </div>

                        <li>
                          {item.text && <p>{item.text}</p>}
                          {item.type && item.type === 'image' && (
                            <a
                              href={item.file}
                              target="_blank"
                              rel="noreferrer"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Ver imágen completa"
                            >
                              <img
                                className="img-internal-notes hover-overlay"
                                src={item.file}
                                alt="Internal Notes images"
                              />
                            </a>
                          )}
                          {item.type && item.type === 'pdf' && (
                            <div className="files">
                              <a
                                className="documentLink"
                                href={item.file}
                                target="_blank"
                                rel="noreferrer"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Descargar documento"
                              >
                                Documento PDF &nbsp;
                                <i className="fas fa-file"></i>
                              </a>
                            </div>
                          )}
                        </li>
                      </div>
                    </React.Fragment>
                  ))}
                </ul>
              ) : (
                <ul>
                  <div className="text-center">
                    <label>Sin historial</label>
                  </div>
                </ul>
              )}
            </div>
            {/* Inputs */}
            <div className="boxInput">
              <input
                type="text"
                placeholder="Escribe un mensaje..."
                id="internalNotesText"
                name="text"
                value={values.text}
                onChange={handleChange}
                autoComplete="off"
              />
              <input
                type="file"
                id="internalNotesDocument"
                name="document"
                onChange={(e) => handleSetUpload(e)}
                ref={refResetDocument}
                accept=".jpg, .jpeg, .png, .pdf"
              />
              <label htmlFor="internalNotesDocument" id="labelDocument">
                <i className="fas fa-paperclip"></i>
              </label>
              <button onClick={sendInternalNotes} disabled={loadingUpload}>
                <i className="fas fa-paper-plane"></i>
              </button>
            </div>
            {values.document && (
              <div className="preview-docs">
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  className="icon-delete-document"
                  onClick={handleDeleteDoc}
                >
                  <i class="fas fa-trash"></i>
                </span>
                {values.document.type.includes('image') ? (
                  <img
                    src={previewFile}
                    style={{ width: '50px' }}
                    alt="previewUploadImg"
                  />
                ) : (
                  <img
                    src={pdfIcon}
                    style={{ width: '50px' }}
                    alt="previewUploadDoc"
                  />
                )}
                <label>{values.document.name}</label>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default InternalNotes
