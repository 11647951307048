import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Box from '../../../../Global/Box'
import InputController from '../../../../Global/InputController'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'

const VerifyCashedChecks = ({ dataNote, refModal }) => {
  const [loadingBtn, setLoadingBtn] = useState(false)

  const validationSchema = yup.object().shape({
    consecutives: yup.array().of(
      yup.object().shape({
        date_cashed_check: yup.string().required('Este campo es requerido'),
      })
    ),
  })

  // console.log('dataNote', dataNote)

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'consecutives',
  })

  const onSubmit = (data) => {
    try {
      console.log('data', data)
    } catch (e) {
      console.log('e', e)
    }
  }

  return (
    <>
      <FormProvider>
        <form
          className={`${loadingBtn ? 'provessing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            withCard={false}
            btnSubmit
            btnState={loadingBtn}
            content={
              <>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Consecutivo</th>
                        <th>Fecha cheque cobrado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataNote?.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: '50%' }}>
                            {item?.consecutive_number}
                          </td>
                          <td style={{ width: '50%' }}>
                            <InputController
                              id={`date_cashed_check_${item?.id}`}
                              name={`consecutives[${index}].date_cashed_check`}
                              type="date"
                              control={control}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default VerifyCashedChecks
