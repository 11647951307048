import { gql } from '@apollo/client'

export const GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS = gql`
  query getAllInventoryActivitiesAccountant(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $warehouseCode: String
    $scheduleId: Int
    $excludeIds: [Int]
  ) {
    getAllInventoryActivitiesAccountant(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      warehouseCode: $warehouseCode
      scheduleId: $scheduleId
      excludeIds: $excludeIds
    ) {
      rows {
        id
        user_id
        full_name
        warehouse_code
        WarehouseToAccountant {
          warehouse_name
        }
        InventoryActivitiesAccountantHasLabels {
          id
        }
      }
      count
    }
  }
`
export const GET_ONE_INVENTORY_ACTIVITIES_ACCOUNTANT = gql`
  query getOneInventoryActivitiesAccountant($id: Int!) {
    getOneInventoryActivitiesAccountant(id: $id) {
      id
      user_id
      full_name
      warehouse_code
      UserToAccountant {
        name
        first_name
        last_name
        warehouse_id
        user_name
      }
    }
  }
`
export const CREATE_INVENTORY_ACTIVITIES_ACCOUNTANT = gql`
  mutation CreateInventoryActivitiesAccountant(
    $input: InventoryActivitiesAccountantInput!
  ) {
    createInventoryActivitiesAccountant(input: $input)
  }
`
export const UPDATE_INVENTORY_ACTIVITIES_ACCOUNTANT = gql`
  mutation UpdateInventoryActivitiesAccountant(
    $id: Int!
    $input: InventoryActivitiesAccountantInput!
  ) {
    UpdateInventoryActivitiesAccountant(id: $id, input: $input)
  }
`
export const DELETE_INVENTORY_ACTIVITIES_ACCOUNTANT = gql`
  mutation deleteInventoryActivitiesAccountant($id: Int!) {
    deleteInventoryActivitiesAccountant(id: $id)
  }
`

export const EXPORT_INVENTORY_ACTIVITIES_ACCOUNTANTS = gql`
  mutation getAllInventoryActivitiesAccountantExport {
    getAllInventoryActivitiesAccountantExport {
      id
      nombre
      almacen
    }
  }
`

// NEEDS REFACTOR
// export const IMPORT_INVENTORY_ACTIVITIES_ACCOUNTANT = gql`
//   mutation importInventoryActivitiesAccountant($files: [Upload!]!) {
//     importInventoryActivitiesAccountant(files: $files)
//   }
// `

// export const GET_ONE_ACCOUNTANT_BY_USER_ID = gql`
//   query getOneTfiAccountantUserId($id: Int!) {
//     getOneTfiAccountantUserId(id: $id) {
//       id
//       id_user
//       full_name
//       warehouse_code
//     }
//   }
// `

// export const UPDATE_TFI_ACCOUNTANT = gql`
//   mutation updateTfiAccountant($id: Int!, $input: TfiAccountantInput!) {
//     updateTfiAccountant(id: $id, input: $input)
//   }
// `

// export const GET_ACCOUNTANT_BY_ID = gql`
//   query GetAccountantById($getAccountantByIdId: Int!) {
//     getAccountantById(id: $getAccountantByIdId) {
//       id
//       user_id
//       name
//       first_name
//       last_name
//       username
//       warehouse_code
//       is_active
//     }
//   }
// `
