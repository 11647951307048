import React, { createElement, useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import LayoutTable from '../Global/LayoutTable'
import ContentHeader from '../Layout/ContentHeader'
import InputController from '../Global/InputController'
import { GET_SAP_WAREHOUSES, CLOSE_SAP_ORDER } from '../../graphql/Catalog/SAP/sapWarehouses'
import { GET_PURCHASE_ORDERS, GET_REPORT_COMMODITY_RECEIPT_BY, GET_RECEIPT_REPORTS_PROVIDERS } from '../../graphql/Documents/CommodityReceipt/commodityReceipt'
import { getEstatus, getActiveStatus, canCloseOrder } from '../../helpers/HelperComodityReport'
import HelperGenerateExcel from '../../helpers/HelperGenerateExcel'
import moment from 'moment'
import Tooltip from '../Global/Tooltip'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../Auth/AuthContext'


const ReceiptReports = () => {    
    const { user } = useContext(AuthContext)
    const location = useLocation()
    
    //USESTATE :
    const [permissions, setPermissions] = useState({
        enable: false,
        create: false,
        edit: false,
        erase: false,
        exportBtn: false,
        importBtn: false,
        permissionsBtn: false,
        cycleCountcycleOperationBtn: false,
        path: '',
    })

    const [data, setData] = useState({
        rows: [],
        count: 0,
    })
    const [pagePagination, setPagePagination] = useState({
        offset: 0,
        limit: 10,
        warehouseCode: null,
        searchQuery: null,
        status: null,
        init: null,
        end: null,
        idProvider: null,
    })
    const [paginationExcel, setPaginationExcel] = useState({
        offset: 0,
        limit: 0,
        warehouseCode: null,
        searchQuery: null,
        status: null,
        init: null,
        end: null,
    })
    const [warehouses, setWarehouses] = useState([])
    const { control, setValue, getValues } = useForm({})
    const [helperExcel, setHelperExcel] = useState(null)
    const [downloadReport, setDownloadReport] = useState(false)
    const [initDate, setInitDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [query, setQuery] = useState(null)
    const [estatus, setEstatus] = useState(getActiveStatus())
    const [loading, setLoading] = useState(true)
    const [refreshData, setRefreshData] = useState(null)
    const [dataProviders, setDataProviders] = useState([])
    const [pagProviders, setPagProviders] = useState({
        limit: 10,
        query: null,
    })
    
    //QUERY :
    const providers = useQuery(GET_RECEIPT_REPORTS_PROVIDERS, {
        variables: pagProviders,
    })
    const qWare = useQuery(GET_SAP_WAREHOUSES)
    const report = useQuery(GET_REPORT_COMMODITY_RECEIPT_BY, {
        variables: pagePagination,
        fetchPolicy: "no-cache",
    })
    
    const dataExcel = useQuery(GET_REPORT_COMMODITY_RECEIPT_BY, {
        variables: paginationExcel,
        fetchPolicy: "no-cache",
    })
    
    const [loadingBtn, setLoadingBtn] = useState(false)

    const [closeOrder] = useMutation(CLOSE_SAP_ORDER, {
        onCompleted: () => {
            //report.refetch()
        }
    })
    
    //FUNCTIONS :
    const handleDownloadReport = () => {

        setPaginationExcel({
            ...pagePagination,
            limit: null,
            offset: 0,
        })

        setDownloadReport(true)
    }

    const handleReset = () => {
        setValue('query', '')
        setValue('warehouse', -1)
        setValue('estatus', -1)
        setValue('provider', -1)
        document.querySelector('input[name="date_init"]').value = ''
        document.querySelector('input[name="date_end"]').value = ''
        
        setPagePagination({
            offset: 0,
            limit: 10,
            scheduleId: null,
            warehouseCode: null,
            searchQuery: null,
            status: null,//array
            init: null,
            end: null,
            idProvider: null,
        })
    }

    const handleCloseOrder = async (row) => {

        try {
            const btn = document.querySelector('#btn-close-' + row?.omitField_id)
            btn.disabled = true

            const result = await toastSweetAlert({
                mode: 'modalConfirmCancel',
                message: '¿Estas seguro de cerrar la orden?',
            })

            if (result.isConfirmed) {
                setLoadingBtn(true)
                //console.log({ row })

                const resp = await closeOrder({
                    variables: {
                        sapPurchaesOrdersId: row?.omitField_sap_purchases_orders_id,
                    }
                })

                if (resp?.data?.closeOrder) {

                    const div = document.querySelector('#cell-13-' + row?.omitField_id + ' div')
                    if (div !== null) {
                        div.innerHTML = 'En proceso...'
                    }

                    await toastSweetAlert({
                        mode: 'ok',
                        message: 'Se envió el proceso correctamente!',
                    })

                }

            }
            
        } catch (error) {

            //console.log({error})

            await toastSweetAlert({
                mode: 'error',
                message: error.message,
            })

        } finally {
            setLoadingBtn(false)
        }
        
    }

    const getInitVal = () => {
        let val = document.querySelector('input[name="date_init"]').value

        return (val === '' ? null : val)
    }

    const getEndVal = () => {
        let val = document.querySelector('input[name="date_end"]').value

        return (val === '' ? null : val)
    }

    //USEEFFECTS :
    useEffect(() => {
        const permissions = user?.userPermissions

        let perm = []
        permissions.map((item) => {
            let moduleName = `${item?.module_info?.relative_link}`
            if (moduleName === location.pathname) {
                return (perm = {
                    create: item.access_retrieve,
                    edit: item.access_edit,
                    erase: item.access_delete,
                    exportBtn: item.access_export,
                    importBtn: item.access_import,
                    path: item.module_info.relative_link,
                })
            }
            return null
        })

        setPermissions({
            ...perm,
            enable: true,
        })

    }, [location.pathname, user])

    useEffect(() => {

        if (!qWare.loading) {
            
            if (qWare.error) {
                return toastSweetAlert({
                    mode: 'error',
                    message: qWare.error.message,
                })
            }

            //console.log({ data: qWare.data?.getSapWarehouses })
            let arr = [{
                value: -1,
                label: 'Todos los almacenes'
            }]

            qWare.data?.getSapWarehouses.forEach((item) => {
                arr.push({
                    value: item.warehouse_code,
                    label: item.warehouse_name,
                })
            })

            setWarehouses(arr)
        }

    }, [qWare?.loading, qWare?.data, qWare?.error])

    useEffect(() => {

        if (!report.loading && permissions.enable) {

            setLoading(false)

            if (report.error) {
                return toastSweetAlert({
                    mode: 'error',
                    message: report.error.message,
                })
            }

            if (report.data.getReportCommodityReceiptBy) {

                data.count = report?.data?.getReportCommodityReceiptBy?.count

                data.rows = report.data.getReportCommodityReceiptBy.rows.map((item) => {

                    const skus = item?.items

                    let expected = item?.expectedQuantity?.quantity

                    let receipt = item?.receipt_quantity
                    
                    let sapReceipt = 0
                    for (const row of item?.sap_purchases_orders?.OrdersDeleveryNotesRows) {
                        sapReceipt += row?.quantity
                    }

                    let diff = parseInt(expected) - parseInt(receipt)
                    let diffSap = parseInt(expected) - parseInt(sapReceipt)
                    let estatus = getEstatus(item?.sap_purchases_orders?.document_status)
                    let getCompEstatus = () => {

                        if (item?.sap_purchases_orders?.closing_order_error !== null) {

                            return (
                                <Tooltip
                                    content={estatus}
                                    text={item?.sap_purchases_orders?.closing_order_error}
                                />
                            )    
                        } else {
                            return (<>{ estatus }</>)
                        }
                    }
                    
                    let row = {
                        omitField_id: item?.schedule_id,
                        'Fecha': item?.sap_purchases_orders?.document_date,
                        //'Doc Entrada': item?.sap_purchases_orders?.document_entry,
                        'Doc número': item?.sap_purchases_orders?.document_number,
                        'Proveedor': item?.sap_purchases_orders?.business_partner?.card_name,
                        'Recibio': (item?.CommodityReceiptUser?.name ? item?.CommodityReceiptUser?.name : '--'),
                        'Almacén': item?.sap_purchases_orders_lines?.OrdersSapWarehouse?.warehouse_name,
                        'skus': skus + '/' + item?.sap_purchases_orders?.OrdersDeleveryNotesRows.length,
                        'Esperada': expected,
                        'Recibida': receipt,
                        'Sap Recibida': sapReceipt,
                        'Discrepancia': diff + '/' + diffSap,
                        //'Estatus': getEstatus(item?.sap_purchases_orders?.document_status),
                        //'Estatus': getCompEstatus(),
                        'Estatus': getCompEstatus(),
                        omitField_sap_purchases_orders_id: item?.sap_purchases_orders?.id,
                        omitField_status: item?.sap_purchases_orders?.document_status,
                        omitField_error: item?.closing_order_error,
                        
                    }
                    
                    if (item?.sap_purchases_orders?.closing_order_error !== null) { 
                        row.alert = 'x'
                    }

                    return row
                })

                setData({
                    rows: data.rows,
                    count: data.count,
                })

            }

        } else {

            setLoading(true)
        }
    }, [report?.loading, report?.data, report?.error, permissions, location.pathname, user])

    useEffect(() => {

        if (!dataExcel.loading) {
            setLoadingBtn(false)

            if (dataExcel.error) {
                return toastSweetAlert({
                    mode: 'error',
                    message: dataExcel.error.message,
                })
            }

            let rows = []

            if (dataExcel.data.getReportCommodityReceiptBy) {

                rows = dataExcel.data.getReportCommodityReceiptBy.rows.map((item) => {

                    const skus = item?.items

                    let expected = item?.expectedQuantity?.quantity

                    let receipt = item?.receipt_quantity

                    let sapReceipt = 0
                    for (const row of item?.sap_purchases_orders?.OrdersDeleveryNotesRows) {
                        sapReceipt += row?.quantity
                    }

                    let diff = parseInt(expected) - parseInt(receipt)
                    let diffSap = parseInt(expected) - parseInt(sapReceipt)

                    let row = {
                        //id: item?.schedule_id,
                        'Fecha': item?.sap_purchases_orders?.document_date,
                        //'Doc Entrada': item?.sap_purchases_orders?.document_entry,
                        'Doc número': item?.sap_purchases_orders?.document_number,
                        'Proveedor': item?.sap_purchases_orders?.business_partner?.card_name,
                        'Recibio': (item?.CommodityReceiptUser?.name ? item?.CommodityReceiptUser?.name : '--'),
                        'Almacén': item?.sap_purchases_orders_lines?.OrdersSapWarehouse?.warehouse_name,
                        'skus': skus + '/' + item?.sap_purchases_orders?.OrdersDeleveryNotesRows.length,
                        'Esperada': expected,
                        'Recibida': receipt,
                        'Sap Recibida': sapReceipt,
                        'Discrepancia': diff + '/' + diffSap,
                        'Estatus': getEstatus(item?.sap_purchases_orders?.document_status),
                    }

                    return row
                })

                setHelperExcel(<HelperGenerateExcel
                    id="ExportExcel"
                    excelData={[
                        {
                            bookName: 'Reportes de recibo',
                            data: rows,
                            columnsWidth: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25],
                        },
                    ]}
                    fileName="Reporte de recibo"
                />)

            }

        } else {

            setLoadingBtn(true)
        }
    }, [dataExcel?.loading, dataExcel?.data, dataExcel?.error])

    useEffect(() => {
        if (downloadReport) {

            document.getElementById('ExportExcel').click()

            setDownloadReport(false)
        }
    }, [helperExcel])

    useEffect(() => {
        //console.log({ initDate, endDate })
        
        if (initDate && endDate) {

            let ini = new Date(moment(initDate).format('YYYY-MM-DD'))
            let end = new Date(moment(endDate).format('YYYY-MM-DD'))

            if (ini < end) {
                //console.log({ ini, end })
                
                setPagePagination({
                    ...pagePagination,
                    init: initDate,
                    end: endDate,
                    searchQuery: (getValues('query') === '' ? null : getValues('query')),
                    idProvider: (getValues('provider') === '' || getValues('provider') === -1 ? null : getValues('provider')),
                    status: (getValues('estatus') === '' || getValues('estatus') === -1 ? null : getValues('estatus')),
                    warehouseCode: (getValues('warehouse') === '' || getValues('warehouse') === -1 ? null : getValues('warehouse')),
                    offset: 0,
                })

            } else {
                toastSweetAlert({
                    mode: 'error',
                    message: 'La fecha inicial debe ser menor que la final',
                })
            }

        }

    }, [initDate, endDate])

    useEffect(() => {

        //console.log({query, isnan: isNaN(query)})

        setPagePagination({
            ...pagePagination,
            searchQuery: (query === '' ? null : query),
            idProvider: (getValues('provider') === '' || getValues('provider') === -1 ? null : getValues('provider')),
            status: (getValues('estatus') === '' || getValues('estatus') === -1 ? null : getValues('estatus')),
            init: getInitVal(),
            end: getEndVal(),
            warehouseCode: (getValues('warehouse') === '' || getValues('warehouse') === -1 ? null : getValues('warehouse')),
            offset: 0,
        })

    }, [query])

    useEffect(() => {

        if (!providers?.loading) {

            if (providers.error) {

                return toastSweetAlert({
                    mode: 'error',
                    message: providers.error.message,
                })
            }
            
            if (providers?.data?.getReceiptReportsProviders) {
                let arr = [
                    {
                        value: -1,
                        label: 'Todos los proveedores',
                    }
                ]

                arr.push(...providers?.data?.getReceiptReportsProviders?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.card_name,
                    }
                }))
                
                setDataProviders(arr)
            }
        }

    }, [providers?.loading, providers?.data, providers?.error])

    return (
        <>
            <ContentHeader
                title="Órdenes de compra recibidas"
                breadcrumb="Órdenes de compra recibidas"
                windowTitle={`Órdenes de compra recibidas`}
            />

            {helperExcel}

            <div className={`${loadingBtn ? 'processing-file' : ''}`}>

                <LayoutTable
                    loadingData={ loading }
                    title='Lista de recibos'
                    exportImportButton={ false }
                    hideAddNew={true}
                    hideBtnEdit={true}
                    hideFilterBar={true}
                    hideBtnErase={true}
                    hideDetails={true}
                    customFilterButtonInput={
                        <div className='container-fluid'>
                            <div className="row mb-4">
                                <div className="col-12 col-md-6 mb-3 mb-md-0 pl-0">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <InputController
                                                id="field-query"
                                                type="text"
                                                name="query"
                                                placeholder="Buscar"
                                                control={control}
                                                changeAction={(e) => {
                                                    //console.log(e)
                                                    setQuery(e.target.value)
                                                }}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6" style={{ zIndex: 99 }}>
                                            {/* warehouses */}
                                            <InputController
                                                id="field-estatus"
                                                name="warehouse"
                                                inputType="choosen"
                                                placeHolderChoosen="Selecciona un almacén"
                                                options={warehouses}
                                                control={control}
                                                changeAction={async (e) => {
                                                    //console.log({ id: e.value })
                                                    let val = (e.value !== -1 ? e.value : null)

                                                    setPagePagination({
                                                        ...pagePagination,
                                                        searchQuery: (getValues('query') === '' ? null : getValues('query')),
                                                        idProvider: (getValues('provider') === '' || getValues('provider') === -1 ? null : getValues('provider')),
                                                        status: (getValues('estatus') === '' || getValues('estatus') === -1 ? null : getValues('estatus')),
                                                        init: getInitVal(),
                                                        end: getEndVal(),
                                                        warehouseCode: val,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3 mb-md-0 pl-0 pr-0">
                                    <div className="row">

                                        <div className="col-12 col-md-6" style={{ zIndex: 99 }}>
                                            {/* providers */}
                                            <InputController
                                                id="field-providers"
                                                name="provider"
                                                placeHolderChoosen="Selecciona un proveedor"
                                                inputType="choosen"
                                                onInputChange={(val, action) => {
                                                    //console.log({val, action})
                                                    if (val.length > 1) {
                                                        setPagProviders({
                                                            limit: 35,
                                                            query: val.trim(),
                                                        })

                                                    } else if (val.length === 0) {
                                                        setPagProviders({
                                                            limit: 10,
                                                            query: null,
                                                        })
                                                    }
                                                }}
                                                options={dataProviders}
                                                control={control}
                                                changeAction={async (e) => {
                                                    let val = (e.value !== -1 ? e.value : null)
                                                    //console.log({val})
                                                    setPagePagination({
                                                        ...pagePagination,
                                                        searchQuery: (getValues('query') === '' ? null : getValues('query')),
                                                        status: (getValues('estatus') === '' || getValues('estatus') === -1 ? null : getValues('estatus')),
                                                        init: getInitVal(),
                                                        end: getEndVal(),
                                                        warehouseCode: (getValues('warehouse') === '' || getValues('warehouse') === -1 ? null : getValues('warehouse')),
                                                        idProvider: val,
                                                    })
                                                }}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6" style={{ zIndex: 99 }}>
                                            {/* estatus */}
                                            <InputController
                                                id="field-estatus"
                                                name="estatus"
                                                placeHolderChoosen="Selecciona un estatus"
                                                inputType="choosen"
                                                options={estatus}
                                                control={control}
                                                changeAction={async (e) => {

                                                    let val = (e.value !== -1 ? e.value : null)
                                                    setPagePagination({
                                                        ...pagePagination,
                                                        searchQuery: (getValues('query') === '' ? null : getValues('query')),
                                                        init: getInitVal(),
                                                        end: getEndVal(),
                                                        warehouseCode: (getValues('warehouse') === '' || getValues('warehouse') === -1 ? null : getValues('warehouse')),
                                                        idProvider: (getValues('provider') === '' || getValues('provider') === -1 ? null : getValues('provider')),
                                                        status: val,
                                                    })
                                                }}
                                            />
                                        </div>

                                    </div>{/* row */}

                                </div>
                            </div>
                            
                            <div className="row mb-2">
                                <div className="col-12 col-md-6 pl-0">
                                    <div className="row">

                                        <div className="col-12 col-md-6">
                                            <div className="input-group">
                                                <div class="input-group-prepend align-self-center text-center">
                                                    <strong className='text-center mr-1'>De</strong>
                                                </div>
                                                <input
                                                    type="date"
                                                    name="date_init"
                                                    control={control}
                                                    className='form-control'
                                                    onChange={(e) => { setInitDate(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="input-group">
                                                <div class="input-group-prepend align-self-center text-center">
                                                    <strong className='text-center mr-1'>A</strong>
                                                </div>
                                                <input
                                                    type="date"
                                                    name="date_end"
                                                    control={control}
                                                    className='form-control'
                                                    onChange={(e) => { setEndDate(e.target.value) }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">
                                    <button type='button' onClick={() => {handleReset()}} className="btn btn-accept mr-1">Limpiar filtros</button>
                                    <button type="button" onClick={handleDownloadReport} className='btn btn-accept'>Descargar reporte</button>
                                </div>
                            </div>
                        </div>
                    }
                    componentAction={
                        (row) => {

                            let btnDetail = () => { 
                                return <Link to={'receipt-reports/details/' + row?.omitField_id + '/show'} className='btn btn-accept'>Detalle</Link>
                            }

                            if (canCloseOrder(row?.omitField_status)
                                && permissions.edit
                            ) {
                                return (
                                    <>
                                        <button id={'btn-close-' + row?.omitField_id} className='btn btn-accept btn-sm' onClick={() => { handleCloseOrder(row) }}>Cerrar orden</button>
                                        { btnDetail() }
                                    </>
                                )
                            } else {
                                return btnDetail()
                            }
                            
                        }
                    }
                    data={data.rows}
                    totalCount={data.count}
                    pagePagination={pagePagination}
                    setPagePagination={setPagePagination}
                />
            </div>
        </>
    )
}

export default ReceiptReports