import { useContext, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { AuthContext } from '../../../../Auth/AuthContext'
import {
  DELETE_NOTES,
  EXPORT_ALL_NOTES,
  GET_NOTES,
  GET_NOTES_RANGE_BY_ID,
  GET_ONE_NOTE,
  UPDATE_STATUS_PAYED_NOTE,
  UPDATE_STATUS_RECEIVED_NOTE,
} from '../../../../graphql/Catalog/Notes/notes'
import { GET_STORES } from '../../../../graphql/Catalog/Stores/stores'
import { GET_ALL_INFO_FOR_EXPORT_TO_EXCEL } from '../../../../graphql/Catalog/Notes/payments'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import LayoutTable from '../../../Global/LayoutTable'
import ModalContent from '../../../Global/ModalContent'
import GeneratePaymentsFinances from './GeneratePaymentsFinances/GeneratePaymentsFinances'
import ProgramPayments from './ProgramPayments'
import ExportExcel from '../../../Helpers/ExportExcel'
import { GET_ROLES } from '../../../../graphql/Catalog/Roles/roles'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import PrintFirstTicket from '../Notes/PrintTicketsNotes/PrintFirstTicket'
import ShowBillsToggle from '../Notes/ShowTableBills/ShowBillsToggle'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_ALL_PAYMENTS_BY_CONSECUTIVE_NUMBER } from '../../../../graphql/Catalog/FinancesCounterCharges/generatepaymentsfinancescountercharges'
import { GET_BILLS } from '../../../../graphql/Catalog/Notes/bills'
import { GET_ALL_RETENTION_PARTNERS } from '../../../../graphql/Catalog/Notes/BusinessRetention/businessretention'
import PrintSecondTicket from '../Notes/PrintTicketsNotes/PrintSecondTicket'
import OrderCol from '../../../Global/OrderCol'
import { GENERATE_REPORT_COUNTERCHARGES } from '../../../../graphql/Catalog/Reports/counterchargesreport'

const FinancesCounterCharges = () => {
  const refModalFinances = useRef()
  const refModalForButton = useRef()
  const componentRef = useRef()
  const refModalGeneratePayments = useRef()
  const componentRefSecondTicket = useRef()
  const { user } = useContext(AuthContext)
  const [dataToTable, setDataToTable] = useState([])
  const [dataToProgram, setDataToProgram] = useState([])
  const [dataNoteExport, setDataNoteExport] = useState([])
  const [optionsProviders, setOptionsProviders] = useState([])
  const [dataHistoryPayments, setDataHistoryPayments] = useState([])
  const [flagProgramedForButton, setFlagProgramedForButton] = useState(null)
  const [closeModal, setCloseModal] = useState(false)
  const [idStore, setIdStore] = useState(null)
  const [idRole, setIdRole] = useState(null)
  const [dataNote, setDataNote] = useState(null)
  const [valueSecondTicket, setValueSecondTicket] = useState(null)
  const [paymentsContability, setPaymentsContability] = useState(null)
  const [total, setTotal] = useState(0)
  const [ids, setIds] = useState([])
  const [idNote, setIdNote] = useState(null)
  const [totalRetention, setTotalRetention] = useState(null)
  const [consecutiveNumber, setConsecutiveNumber] = useState(null)
  const [consecutiveNumberPayment, setConsecutiveNumberPayment] = useState([])
  const [clearSelected, setClearSelected] = useState(false)
  const [options, setOptions] = useState([])
  const [response, setResponse] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [countNotes, setCountNotes] = useState(0)
  const [totalNotes, setTotalNotes] = useState(0)
  const [infoSelected, setInfoSelected] = useState()
  const [programmedPayments, setProgrammedPayments] = useState(false)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 30,
    offset: 0,
    statusNote: null,
    notesForFinances: true,
  })

  const optionsStatusNote = [
    {
      value: 1,
      label: 'Pendiente',
    },
    {
      value: 2,
      label: 'Recibida',
    },
    {
      value: 3,
      label: 'Recibida y Programada',
    },
    {
      value: 4,
      label: 'Pago parcial',
    },
    {
      value: 5,
      label: 'Pago con ajuste',
    },
    {
      value: 6,
      label: 'Pago completo',
    },
    {
      value: 7,
      label: 'Pago contabilidad',
    },
    {
      value: 8,
      label: 'Entregado a contabilidad',
    },
  ]

  const {
    loading: loadingGetNotes,
    error: errorGetNotes,
    data: dataGetNotes,
    refetch,
  } = useQuery(GET_NOTES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      // date: pagePagination.date,
      fullPayed: pagePagination.fullPayed,
      withOutPay: pagePagination.withOutPay,
      dateFrom: pagePagination.dateFrom,
      dateTo: pagePagination.dateTo,
      notesForFinances: pagePagination.notesForFinances,
      idProvider: pagePagination.idProvider,
      shopRegister: pagePagination.shopRegister,
      statusNote: pagePagination.statusNote,
    },
    fetchPolicy: 'no-cache',
  })

  // const {
  //   loading: loadingBills,
  //   error: errorBills,
  //   data: dataBills,
  //   refetch: refetchBills,
  // } = useQuery(GET_BILLS, {
  //   variables: {
  //     searchQuery: pagePagination.searchQuery,
  //     limit: pagePagination.limit,
  //     offset: pagePagination.offset,
  //     dateFrom: pagePagination.dateFrom,
  //     dateTo: pagePagination.dateTo,
  //     fullPayed: pagePagination.fullPayed,
  //     withOutPay: pagePagination.withOutPay,
  //     shopRegister: pagePagination.shopRegister,
  //     idProvider: pagePagination.idProvider,
  //     statusNote: pagePagination.statusNote,
  //     notesForFinances: pagePagination.notesForFinances,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const {
    loading: loadingStores,
    error: errorStores,
    data: dataStores,
  } = useQuery(GET_STORES, {
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingProviders,
    error: errorProviders,
    data: dataProviders,
  } = useQuery(GET_ALL_RETENTION_PARTNERS, {
    fetchPolicy: 'no-cache',
  })

  const [updateStatusReceivedNote] = useMutation(UPDATE_STATUS_RECEIVED_NOTE, {
    refetchQueries: {
      query: GET_NOTES,
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    },
  })

  const [getOneNote] = useLazyQuery(GET_ONE_NOTE)
  const [getAllInfoForExportToExcel] = useLazyQuery(
    GET_ALL_INFO_FOR_EXPORT_TO_EXCEL
  )

  const [getRoles] = useLazyQuery(GET_ROLES)

  const [getAllPaymentsByConsecutiveNumber] = useMutation(
    GET_ALL_PAYMENTS_BY_CONSECUTIVE_NUMBER
  )

  const [updateStatusAsPayed] = useMutation(UPDATE_STATUS_PAYED_NOTE)

  const [getNotesRangeById] = useMutation(GET_NOTES_RANGE_BY_ID)

  const [generateReport] = useMutation(GENERATE_REPORT_COUNTERCHARGES)

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      signDisplay: 'negative',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  useEffect(() => {
    if (!loadingGetNotes) {
      if (errorGetNotes)
        return toastSweetAlertNotifications({
          mode: 'error',
          message: errorGetNotes.message,
        })
      let list = []

      dataGetNotes?.getNotes?.rows?.map((item) => {
        let providerNameManual = 'Nota Manual - Sin Proveedor'

        if (item?.id_partner === 0) {
          providerNameManual = item.providerNameNm
        }

        return list.push({
          id: item?.id,
          Estatus:
            item.status_note === 1 ? (
              <label>
                {' '}
                <i
                  className="far fa-clock"
                  style={{ fontSize: '0.9rem' }}
                ></i>{' '}
                Pendiente
              </label>
            ) : item.status_note === 2 ? (
              <label>
                {' '}
                <i
                  className="far fa-check-circle"
                  style={{ color: '#4ea93b', fontSize: '0.9rem' }}
                ></i>{' '}
                Recibida
              </label>
            ) : item.status_note === 3 ? (
              <label>
                {' '}
                <i
                  className="far fa-check-circle"
                  style={{ color: '#4ea93b', fontSize: '0.9rem' }}
                ></i>{' '}
                Recibida y Programada
              </label>
            ) : item.status_note === 4 ? (
              <label>
                {' '}
                <i
                  className="far fa-check-circle"
                  style={{ color: '#4ea93b', fontSize: '0.9rem' }}
                ></i>{' '}
                Pago parcial
              </label>
            ) : item.status_note === 5 ? (
              <label>
                {' '}
                <i
                  className="far fa-check-circle"
                  style={{ color: '#4ea93b', fontSize: '0.9rem' }}
                ></i>{' '}
                Pago con ajuste
              </label>
            ) : item.status_note === 6 ? (
              <label>
                {' '}
                <i
                  className="far fa-check-circle"
                  style={{ color: '#4ea93b', fontSize: '0.9rem' }}
                ></i>{' '}
                Pago Completo
              </label>
            ) : item.status_note === 7 ? (
              <label>
                {' '}
                <i
                  className="far fa-check-circle"
                  style={{ color: '#4ea93b', fontSize: '0.9rem' }}
                ></i>{' '}
                Pago contabilidad
              </label>
            ) : (
              <label>
                {' '}
                <i
                  className="far fa-check-circle"
                  style={{ color: '#4ea93b', fontSize: '0.9rem' }}
                ></i>{' '}
                Entregado a contabilidad
              </label>
            ),
          consecutivo: item?.consecutive_number,
          // tienda: item?.storesRegister?.alias,
          'F. contrarecibo': moment(
            item?.date_note,
            'YYYY-MM-DD hh:mm:ss a'
          ).format('DD/MM/YYYY hh:mm:ss a'),
          sello: item?.date_stamp
            ? moment(item?.date_stamp).format('DD/MM/YYYY')
            : 'Aun no programado',
          'f. programado': item?.date_programmed_to_pay
            ? moment(item?.date_programmed_to_pay).format('DD/MM/YYYY')
            : 'Aun no programado',
          // rfc:
          //   item?.id_partner !== 0
          //     ? item?.partnerNotes?.provider_rfc
          //     : 'XAXX010101000',
          proveedor:
            item?.id_partner !== 0
              ? item?.partnerNotes?.provider_name
              : providerNameManual,
          monto: formmatterCurrency(item?.amount_note),
          // abonado: formmatterCurrency(item?.pay_note),
          'm. programado': formmatterCurrency(
            item?.amount_programmed_to_pay_note
          )
            ? formmatterCurrency(item?.amount_programmed_to_pay_note)
            : 'Sin Programar',
          pagado: formmatterCurrency(item?.pay_note),
          // 'A pagar': item.full_payed
          //   ? 'Pagada'
          //   : formmatterCurrency(
          //       item?.amount_programmed_to_pay_note - item?.pay_note
          //     ),
          alert: item?.full_payed && item.status_note !== 8 ? true : false,
          conditional_alert:
            item?.full_payed && item.status_note === 8 ? false : null,
          // facturas: item?.bills?.length,
        })
      })
      // let listWithOutDuplicates = []
      // list.forEach((item) => {
      //   let x = listWithOutDuplicates.find((i) => i.id === item.id)
      //   if (!x) {
      //     listWithOutDuplicates.push(item)
      //   }
      // })
      setDataToTable(list)
      setTotal(dataGetNotes?.getNotes?.count)
    }
  }, [loadingGetNotes, errorGetNotes, dataGetNotes])

  useEffect(() => {
    if (!loadingProviders) {
      if (errorProviders)
        return toastSweetAlert({
          mode: 'error',
          message: errorProviders.message,
        })

      let providers = []

      providers = dataProviders?.getAllRetentionPartners?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.provider_name,
        }
      })
      setOptionsProviders(providers)
    }
  }, [dataProviders, loadingProviders, errorProviders])

  // useEffect(async () => {
  //   if (!loadingBills) {
  //     if (errorBills)
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: errorBills?.message,
  //       })
  //     let listBills = []
  //     dataBills?.getBills?.rows?.map((item) => {
  //       return listBills.push({
  //         id: item?.id_note,
  //         id_bill: item?.id,
  //         Estatus:
  //           item?.notes?.status_note === 1 ? (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-clock"
  //                 style={{ fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Pendiente
  //             </label>
  //           ) : item?.notes?.status_note === 2 ? (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-check-circle"
  //                 style={{ color: '#4ea93b', fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Recibida
  //             </label>
  //           ) : item?.notes?.status_note === 3 ? (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-check-circle"
  //                 style={{ color: '#4ea93b', fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Recibida y Programada
  //             </label>
  //           ) : item?.notes?.status_note === 4 ? (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-check-circle"
  //                 style={{ color: '#4ea93b', fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Pago parcial
  //             </label>
  //           ) : item?.notes?.status_note === 5 ? (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-check-circle"
  //                 style={{ color: '#4ea93b', fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Pago con ajuste
  //             </label>
  //           ) : item?.notes?.status_note === 6 ? (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-check-circle"
  //                 style={{ color: '#4ea93b', fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Pago Completo
  //             </label>
  //           ) : item?.notes?.status_note === 7 ? (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-check-circle"
  //                 style={{ color: '#4ea93b', fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Pago contabilidad
  //             </label>
  //           ) : (
  //             <label>
  //               {' '}
  //               <i
  //                 className="far fa-check-circle"
  //                 style={{ color: '#4ea93b', fontSize: '0.9rem' }}
  //               ></i>{' '}
  //               Entregado a contabilidad
  //             </label>
  //           ),
  //         consecutivo: item?.notes?.consecutive_number,
  //         // tienda: item?.notes?.storesRegister?.alias,
  //         'F. contrarecibo': moment(
  //           item?.notes?.date_note,
  //           'YYYY-MM-DD hh:mm:ss a'
  //         ).format('DD/MM/YYYY'),
  //         sello: item?.notes?.date_stamp
  //           ? moment(item?.notes?.date_stamp).format('DD/MM/YYYY')
  //           : 'Aun no programado',
  //         'f. programado': item?.notes?.date_programmed_to_pay
  //           ? moment(item?.notes?.date_programmed_to_pay).format('DD/MM/YYYY')
  //           : 'Aun no programado',
  //         proveedor:
  //           item?.notes?.id_partner === 0
  //             ? item?.provider_name_nm
  //             : item?.notes?.partnerNotes?.provider_name,
  //         monto: formmatterCurrency(item?.notes?.amount_note),
  //         // abonado: formmatterCurrency(item?.pay_note),
  //         'm. programado': formmatterCurrency(
  //           item?.notes?.amount_programmed_to_pay_note
  //         )
  //           ? formmatterCurrency(item?.notes?.amount_programmed_to_pay_note)
  //           : 'Sin Programar',
  //         pagado: formmatterCurrency(item?.notes?.pay_note),
  //         alert:
  //           item?.notes?.full_payed && item?.notes?.status_note !== 8
  //             ? true
  //             : false,
  //         conditional_alert:
  //           item?.notes?.full_payed && item?.notes?.status_note === 8
  //             ? false
  //             : null,
  //         // ? false
  //         // : null,
  //         // conditional_alert: item?.notes?.full_payed ? true : false,
  //         // hideColumns: 'id_bill',
  //         // omitField: true,
  //       })
  //     })

  //     let listWithOutDuplicates = []
  //     listBills.forEach((item) => {
  //       let x = listWithOutDuplicates.find((i) => i.id === item.id)
  //       if (!x) {
  //         listWithOutDuplicates.push(item)
  //       }
  //     })

  //     setDataToTable(listWithOutDuplicates)
  //     setTotal(dataGetNotes?.getNotes?.count)
  //     if (programmedPayments) {
  //       setLoadingBtn(true)

  //       handleAfterProgrammed(ids)
  //       // setTimeout(() => {
  //       //   setLoadingBtn(false)
  //       // }, ids.length * 50)
  //     }
  //   }
  // }, [
  //   loadingBills,
  //   errorBills,
  //   dataBills,
  //   dataGetNotes,
  //   loadingGetNotes,
  //   errorGetNotes,
  // ])

  useEffect(async () => {
    if (!loadingStores) {
      if (errorStores)
        return toastSweetAlertNotifications({
          mode: 'error',
          message: errorStores.message,
        })

      let shops = []
      if (user.store === null) {
        shops = dataStores?.getAllStores?.rows?.map((item) => {
          return {
            value: item.id,
            label: item.alias,
          }
        })
      }
      await handleRoleForStore(shops)
      setOptions(shops)
    }
  }, [loadingStores, errorStores, dataStores])

  const handleRoleForStore = async (shops) => {
    const { data: roles } = await getRoles({
      variables: {},
      fetchPolicy: 'no-cache',
    })

    const dataRoles = roles?.getAllRoles?.rows

    const findRoleShop = await dataRoles?.find(
      (item) => item?.role_name === 'Tienda'
    )

    const findRoleAdmin = await dataRoles?.find(
      (item) => item?.role_name === 'Administrador'
    )

    if (parseInt(findRoleAdmin?.id) !== parseInt(user.role)) {
      if (parseInt(findRoleShop?.id) !== parseInt(user.role)) {
        const findShopMatriz = await shops?.find(
          (item) => item.label === 'Matriz'
        )
        setIdStore(findShopMatriz?.value)
      }
    }
    setIdRole(findRoleAdmin?.id)
  }

  useEffect(async () => {
    if (idStore) {
      filterOnChangeShopRegister(idStore)
    }
  }, [idStore])

  const handleProgramPayments = async () => {
    setLoadingBtn(true)
    if (ids.length === 0)
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'No hay registros seleccionados',
        },
        setLoadingBtn(false),
        setClearSelected(!clearSelected),
        setIds([])
      )

    let list = []
    // for (let id of ids) {
    const { data: dataNotesByRange } = await getNotesRangeById({
      variables: {
        idNote: ids,
      },
      fetchPolicy: 'no-cache',
    })
    for (let note of dataNotesByRange?.getNotesForRangeById) {
      let statusValue = note?.status_note
      if (statusValue === 1) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message:
              'No se puede programar pagos de contrarecibos que no esten recibidas',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      if (
        statusValue === 4 ||
        statusValue === 5 ||
        statusValue === 6 ||
        statusValue === 7
      ) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message:
              'No se puede programar pagos de contrarecibos que ya esten pagadas , pagadas parcialmente, cerradas con ajuste o pagadas por contabilidad',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      if (statusValue === 8) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message:
              'No se puede programar pagos de contrarecibos que ya esten entregados',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      list.push(note)
    }
    // }
    setDataToProgram(list)
    document.getElementById('spanModalHeader').click()
    // setClearSelected(!clearSelected)
    // setIds([])
    // setCountNotes(0)
    // setTotalNotes(0)
    // handleAfterProgrammed(ids)
    setLoadingBtn(false)
    setProgrammedPayments(true)
  }

  const handleAfterProgrammed = async (idsNotes) => {
    try {
      setProgrammedPayments(false)
      let sum = 0
      const { data: dataNotesByRange } = await getNotesRangeById({
        variables: {
          idNote: idsNotes,
        },
        fetchPolicy: 'no-cache',
      })
      for (let note of dataNotesByRange?.getNotesForRangeById) {
        // const dataNote = await dataGetOneNote?.getOneNote
        // if (isNaN(dataNote?.amount_programmed_to_pay_note)) {
        // if(totalNotes > 0) sum = totalNotes

        if (
          note?.amount_programmed_to_pay_note === '' ||
          note?.amount_programmed_to_pay_note === null
        ) {
          return setLoadingBtn(false)
        }

        sum += parseFloat(note?.amount_programmed_to_pay_note)
        // }
      }
      setTotalNotes(sum.toFixed(2))
      setLoadingBtn(false)
    } catch (e) {
      console.log('e', e)
    }
  }

  const handleDocumentsReceived = async () => {
    setLoadingBtn(true)
    if (ids.length === 0)
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'No hay registros seleccionados',
        },
        setLoadingBtn(false),
        setClearSelected(!clearSelected),
        setIds([])
      )

    const result = await toastSweetAlert({
      mode: 'modalConfirmCancel',
      message:
        '¿Estas seguro de marcar estos consecutivos seleccionados como recibidas?',
    })

    let arrayUpdateNotes = []

    if (result.isConfirmed) {
      // for (let id of ids) {

      const { data: dataNotesByRange } = await getNotesRangeById({
        variables: {
          idNote: ids,
        },
        fetchPolicy: 'no-cache',
      })
      for (let note of dataNotesByRange?.getNotesForRangeById) {
        let statusValue = note?.status_note
        //  let consecutiveNumber =
        //    dataGetOneNote?.getOneNote?.consecutive_number
        if (statusValue === 2) {
          setLoadingBtn(false)
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message:
                'No se puede marcar como recibida un consecutivo que ya lo esta',
            }
            //  setClearSelected(!clearSelected),
            //  setIds([])
          )
        }

        if (statusValue === 3) {
          setLoadingBtn(false)
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message:
                'No se puede marcar como recibida un consecutivo que ya esta programado',
            }
            //  setClearSelected(!clearSelected),
            //  setIds([]),
            //  setCountNotes(0),
            //  setTotalNotes(0)
          )
        }

        if (
          statusValue === 4 ||
          statusValue === 5 ||
          statusValue === 6 ||
          statusValue === 7
        ) {
          setLoadingBtn(false)
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message:
                'No se puede marcar como recibida contrarecibos que ya esten pagadas , pagadas parcialmente, cerradas con ajuste o pagadas por contabilidad',
            }
            //  setClearSelected(!clearSelected),
            //  setIds([]),
            //  setCountNotes(0),
            //  setTotalNotes(0)
          )
        }

        if (statusValue === 8) {
          setLoadingBtn(false)
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message:
                'No se puede marcar como recibida contrarecibos que esten entregados',
            }
            //  setClearSelected(!clearSelected),
            //  setIds([]),
            //  setCountNotes(0),
            //  setTotalNotes(0)
          )
        }

        arrayUpdateNotes.push({
          id: note?.id,
          status_note: 2,
        })
      }

      // }

      const result = await updateStatusReceivedNote({
        variables: {
          updateInputStatusReceivedNotes: arrayUpdateNotes,
        },
        fetchPolicy: 'no-cache',
      })

      if (result?.data?.updateStatusReceivedNote) {
        if (refetch) {
          //  await refetchBills()
          await refetch()
        }

        toastSweetAlertNotifications({
          mode: 'ok',
          message: 'Los contrarecibos se marcaron como recibidos',
        })

        // toastSweetAlertNotifications({
        //   mode: 'ok',
        //   message: 'Se actualizaron los registros',
        // })
      }

      // console.log('result', result?.data?.updateStatusReceivedNote)
      setTimeout(() => {
        handleProgramPayments()
        setLoadingBtn(false)
      }, 400)
      // setClearSelected(!clearSelected)
      // setIds([])
    } else {
      setLoadingBtn(false)
      // setClearSelected(!clearSelected)
      // setIds([])
      // setCountNotes(0)
      // setTotalNotes(0)
    }
  }

  // console.log('dataToTable', dataToTable)

  const handleSelectedRows = async (e) => {
    setLoadingBtn(true)
    let sum = 0
    let arrayIds = []

    // if (totalNotes > 0) sum = parseFloat(totalNotes)
    for (let row of e.selectedRows) {
      // console.log('totalNotes', totalNotes)
      arrayIds.push(row.id)
      // sum += parseFloat(row.total)
    }
    setIds(arrayIds)
    handleAfterProgrammed(arrayIds)
    // setTimeout(() => {
    // setLoadingBtn(false)
    setCountNotes(e.selectedCount)
    // setTotalNotes(sum.toFixed(2))
    // setInfoSelected(e)
    // }, arrayIds.length * 50)
  }

  const handleGeneratePayments = async () => {
    setLoadingBtn(true)
    if (ids.length === 0) {
      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'No hay registros seleccionados',
        },
        setClearSelected(!clearSelected),
        setIds([])
      )
    }
    let idNote = ids[0]
    let list = []
    let listPayments = []
    let partnerPrevious = null
    let invoicePaymentPrevious = null
    let paymentsEquals = false

    let isPaymentShared = null
    let consecutivesNumbersOfPayments = []

    isPaymentShared = await haandleSharedPayments(idNote, true)

    // for (let id of ids) {

    // const { data: dataGetOneNote } = await getOneNote({
    //   variables: {
    //     getOneNoteId: id,
    //   },
    //   fetchPolicy: 'no-cache',
    // })

    const { data: dataNotesByRange } = await getNotesRangeById({
      variables: {
        idNote: ids,
      },
      fetchPolicy: 'no-cache',
    })
    for (let note of dataNotesByRange?.getNotesForRangeById) {
      // console.log('dataGetOneNote', dataGetOneNote?.getOneNote)
      //getAllPaymentsByConsecutiveNumber

      let statusValue = note?.status_note
      let partnerCurrent = note?.id_partner
      let amountNote = note?.amount_note

      if (parseFloat(amountNote) < 0 && ids.length === 1) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications({
          mode: 'error',
          message:
            'No se puede pagar un contrarecibo a credito solo. Debe seleccionar al menos 2 o mas contrarecibos para no generar un pago negativo',
        })
      }
      // partnerPrevious = partnerCurrent
      if (partnerPrevious === null) partnerPrevious = partnerCurrent
      // console.log('partnerCurrent', partnerCurrent)
      // console.log('partnerPrevious', partnerPrevious)
      if (statusValue === 1) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message:
              'No se puede pagar contrarecibos que no esten recibidas y programdas',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      if (statusValue === 2) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message:
              'No se puede pagar un contrarecibo que aun no esta programado',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      if (partnerPrevious !== partnerCurrent) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message:
              'No se puede pagar contrarecibos de diferentes proveedores',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      if (statusValue === 5 || statusValue === 6 || statusValue === 7) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message: 'No se puede pagar contrarecibos que ya esten pagadas',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      if (statusValue === 8) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message: 'No se puede pagar contrarecibos que ya esten entregados',
          }
          // setClearSelected(!clearSelected),
          // setIds([]),
          // setCountNotes(0),
          // setTotalNotes(0)
        )
      }

      if (isPaymentShared) {
        // console.log('Pago compartido', isPaymentShared)
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: `El contrarecibo seleccionado esta compartiendo pago con otro contrarecibo. No se puede generar el pago, favor de seleccionar los contrarecibos con los id:  ${isPaymentShared}. Para continuar con el proceso.`,
        })
      }

      if (
        note?.notesToGeneratePaymentsFinances !== undefined &&
        note?.notesToGeneratePaymentsFinances.length > 0
      ) {
        for (let payment of note?.notesToGeneratePaymentsFinances) {
          if (payment?.is_active) {
            let consecutiveNumberOfPaymentsCurrent =
              payment?.consecutive_number_of_payments

            paymentsEquals = consecutivesNumbersOfPayments.includes(
              consecutiveNumberOfPaymentsCurrent
            )
            if (!paymentsEquals) {
              consecutivesNumbersOfPayments.push(
                consecutiveNumberOfPaymentsCurrent
              )
            }
          }
        }

        if (idNote !== note?.id && !paymentsEquals) {
          isPaymentShared = await haandleSharedPayments(note?.id, true)
        }
      }

      if (isPaymentShared) {
        // console.log('Pago compartido', isPaymentShared)
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: `El contrarecibo seleccionado esta compartiendo pago con otro contrarecibo. No se puede generar el pago, favor de seleccionar los contrarecibos con los id:  ${isPaymentShared}. Para continuar con el proceso.`,
        })
      }

      const dataPayments = note?.notesToGeneratePaymentsFinances

      for (let payment of dataPayments) {
        // console.log('invoucePaymentPrevious', invoicePaymentPrevious)
        // console.log(
        //   'invoicePaymentCurrent',
        //   payment.consecutive_number_of_payments
        // )
        let active = payment?.is_active
        let invoicePaymentCurrent = payment.consecutive_number_of_payments

        if (active) {
          if (invoicePaymentCurrent !== invoicePaymentPrevious) {
            const findPayment = listPayments.find(
              (item) =>
                item.consecutive_number_of_payments === invoicePaymentCurrent
            )
            if (!findPayment) {
              listPayments.push(payment)
              invoicePaymentPrevious = invoicePaymentCurrent
            }
          }
        }
      }
      // console.log('listPayments', listPayments)

      list.push(note)
      partnerPrevious = partnerCurrent
    }
    // }
    // let listPaymentsWithoutDuplicates = []
    // let invoiceEqual = null
    // for (let paymentEqual of listPayments) {
    //   if (listPayments.length === 1) {
    //     listPaymentsWithoutDuplicates.push(paymentEqual)
    //   } else {
    //     if (invoiceEqual === null)
    //       invoiceEqual = paymentEqual.consecutive_number_of_payments
    //     if (invoiceEqual !== paymentEqual.consecutive_number_of_payments) {
    //       listPaymentsWithoutDuplicates.push(paymentEqual)
    //     }
    //     invoiceEqual = paymentEqual.consecutive_number_of_payments
    //   }
    // }

    list.sort((a, b) => {
      return a.amount_programmed_to_pay_note - b.amount_programmed_to_pay_note
    })
    // console.log('list', list)
    setPaymentsContability(false)
    setDataToProgram(list)
    setDataHistoryPayments(listPayments)
    // setClearSelected(!clearSelected)
    document.getElementById('spanModalGeneratePayments').click()
    // setIds([])
    // setCountNotes(0)
    // setTotalNotes(0)
    setLoadingBtn(false)
  }

  const haandleSharedPayments = async (idNote, reOpen) => {
    try {
      let idsPayment = []
      let dataPayments = []
      let consecutiveNumberOfPayments = []

      const { data: dataGetOneNote } = await getOneNote({
        variables: {
          getOneNoteId: idNote,
        },
        fetchPolicy: 'no-cache',
      })
      const dataNote = await dataGetOneNote?.getOneNote
        ?.notesToGeneratePaymentsFinances

      if (dataNote) {
        for (let paymentConsecutiveNumber of dataNote) {
          if (paymentConsecutiveNumber?.is_active) {
            const paymentEquals = consecutiveNumberPayment.includes(
              paymentConsecutiveNumber?.consecutive_number_of_payments
            )
            if (!paymentEquals) {
              const { data: dataPaymentByConsecutiveNumber } =
                await getAllPaymentsByConsecutiveNumber({
                  variables: {
                    consecutiveNumber:
                      paymentConsecutiveNumber?.consecutive_number_of_payments,
                  },
                })
              dataPayments.push(
                dataPaymentByConsecutiveNumber?.getAllPaymentsByConsecutiveNumber
              )
            }
          }
        }

        for (let payments of dataPayments) {
          for (let payment of payments) {
            for (let notePayment of payment?.paymentsFinancesToNotes) {
              // console.log('notePayment', notePayment)
              let idIsSelected = ids.includes(notePayment.id)
              // console.log('idIsSelected', idIsSelected)
              if (!idIsSelected) {
                const findId = idsPayment.find(
                  (item) => item === notePayment.id
                )
                if (!findId) {
                  idsPayment.push(notePayment.id)
                }
              }
              // // console.log('idsPayment', idsPayment)
              // let existsPayment = consecutiveNumberOfPayments.includes(
              //   payment?.consecutive_number_of_payments
              // )

              // if (!existsPayment) {
              //   consecutiveNumberOfPayments.push(
              //     payment?.consecutive_number_of_payments
              //   )
              // }
            }
          }
          // console.log('payment', payment)
        }
        // }
      }

      // setConsecutiveNumberPayment(consecutiveNumberOfPayments)
      return idsPayment.length > 0 ? idsPayment : false
    } catch (e) {
      setLoadingBtn(false)
      console.log('e', e)
    }
  }

  const handleGeneratePaymentsForContability = async () => {
    if (ids.length === 0)
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'No hay registros seleccionados',
        },
        setClearSelected(!clearSelected),
        setIds([])
      )

    let list = []
    let listPayments = []
    let partnerPrevious = null
    let invoicePaymentPrevious = null
    for (let id of ids) {
      // console.log('id', id)
      // console.log('partnerCurrent', partnerCurrent)

      const { data: dataGetOneNote } = await getOneNote({
        variables: {
          getOneNoteId: id,
        },
        fetchPolicy: 'no-cache',
      })

      // console.log('dataGetOneNote', dataGetOneNote?.getOneNote)
      let statusValue = dataGetOneNote?.getOneNote?.status_note

      if (statusValue === 4) {
        return toastSweetAlertNotifications({
          mode: 'error',
          message:
            'No se pueden pagar contrarecibos que se encuentre en proceso de pago',
        })
      }

      if (statusValue === 5 || statusValue === 6 || statusValue === 7) {
        return toastSweetAlertNotifications({
          mode: 'error',
          message: 'No se puede pagar contrarecibos que ya esten pagadas',
        })
      }

      // const dataPayments =
      //   dataGetOneNote?.getOneNote?.notesToGeneratePaymentsFinances
      // // console.log(dataPayments)

      // for (let payment of dataPayments) {
      //   // console.log('invoucePaymentPrevious', invoicePaymentPrevious)
      //   // console.log(
      //   //   'invoicePaymentCurrent',
      //   //   payment.consecutive_number_of_payments
      //   // )
      //   let invoicePaymentCurrent = payment.consecutive_number_of_payments
      //   if (invoicePaymentPrevious === null)
      //     invoicePaymentPrevious = invoicePaymentCurrent
      //   if (invoicePaymentCurrent !== invoicePaymentPrevious) {
      //     // console.log('Pago agregado', invoicePaymentCurrent)
      //     listPayments.push(payment)
      //   }
      //   invoicePaymentPrevious = invoicePaymentCurrent
      // }
      // // console.log('listPayments', listPayments)

      list.push(dataGetOneNote?.getOneNote)
    }
    // let listPaymentsWithoutDuplicates = []
    // let invoiceEqual = null
    // for (let paymentEqual of listPayments) {
    //   if (listPayments.length === 1) {
    //     listPaymentsWithoutDuplicates.push(paymentEqual)
    //   } else {
    //     if (invoiceEqual === null)
    //       invoiceEqual = paymentEqual.consecutive_number_of_payments
    //     if (invoiceEqual !== paymentEqual.consecutive_number_of_payments) {
    //       listPaymentsWithoutDuplicates.push(paymentEqual)
    //     }
    //     invoiceEqual = paymentEqual.consecutive_number_of_payments
    //   }
    // }

    list.sort((a, b) => {
      return a.amount_note - a.pay_note - (b.amount_note - b.pay_note)
    })

    setPaymentsContability(true)
    setDataToProgram(list)
    // setDataHistoryPayments(listPaymentsWithoutDuplicates)
    setClearSelected(!clearSelected)
    document.getElementById('spanModalGeneratePayments').click()
    setIds([])
  }

  const handleMarkAsPaid = async () => {
    try {
      setLoadingBtn(true)
      let arrayUpdateNotes = []
      let consecutivesNumbersOfPayments = []
      let paymentsEquals = false

      if (ids.length === 0) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications({
          mode: 'error',
          message: 'No hay registros seleccionados',
        })
      }

      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message:
          '¿Estas seguro de marcar como pagado los contrarecibos seleccionados?',
      })

      if (result.isConfirmed) {
        let idNote = ids[0]
        const isPaymentShared = await haandleSharedPayments(idNote, true)

        const { data: dataNotesByRange } = await getNotesRangeById({
          variables: {
            idNote: ids,
          },
          fetchPolicy: 'no-cache',
        })
        for (let note of dataNotesByRange?.getNotesForRangeById) {
          // for (let id of ids) {
          // const { data: dataGetOneNote } = await getOneNote({
          //   variables: {
          //     getOneNoteId: id,
          //   },
          //   fetchPolicy: 'no-cache',
          // })

          // console.log('note', note)

          let statusValue = note?.status_note

          if (statusValue === 1 || statusValue === 2 || statusValue === 3) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message:
                  'Los contrarecibos con el estatus de "Pendiente, Recibida y Recibida y Programada" no se pueden marcar como pagados',
              }
              // setClearSelected(!clearSelected),
              // setIds([]),
              // setCountNotes(0),
              // setTotalNotes(0)
            )
          }

          if (statusValue === 5 || statusValue === 6 || statusValue === 7) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message:
                  'No se pueden marcar contrarecibos que ya se encuentre pagados como pagados',
              }
              // setClearSelected(!clearSelected),
              // setIds([]),
              // setCountNotes(0),
              // setTotalNotes(0)
            )
          }

          if (statusValue === 8) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message:
                  'No se pueden marcar contrarecibos que ya se encuentren entregados como pagados',
              }
              // setClearSelected(!clearSelected),
              // setIds([]),
              // setCountNotes(0),
              // setTotalNotes(0)
            )
          }

          if (isPaymentShared) {
            // console.log('Pago compartido', isPaymentShared)
            setLoadingBtn(false)
            return toastSweetAlert({
              mode: 'error',
              message: `El contrarecibo seleccionado esta compartiendo pago con otros contrarecibos. No se pueden marcar como pagados, favor de seleccionar los contrarecibos con los id:  ${isPaymentShared}. Para continuar con el proceso.`,
            })
          }

          for (let payment of note?.notesToGeneratePaymentsFinances) {
            if (payment?.is_active) {
              let consecutiveNumberOfPaymentsCurrent =
                payment?.consecutive_number_of_payments

              paymentsEquals = consecutivesNumbersOfPayments.includes(
                consecutiveNumberOfPaymentsCurrent
              )
              if (!paymentsEquals) {
                consecutivesNumbersOfPayments.push(
                  consecutiveNumberOfPaymentsCurrent
                )
              }
            }
          }

          if (idNote !== note?.id && !paymentsEquals) {
            isPaymentShared = await haandleSharedPayments(note?.id, true)
          }

          if (isPaymentShared) {
            // console.log('Pago compartido', isPaymentShared)
            setLoadingBtn(false)
            return toastSweetAlert({
              mode: 'error',
              message: `El contrarecibo seleccionado esta compartiendo pago con otros contrarecibos. No se pueden marcar como pagados, favor de seleccionar los contrarecibos con los id:  ${isPaymentShared}. Para continuar con el proceso.`,
            })
          }

          arrayUpdateNotes.push({
            id: note?.id,
            status_note: 6,
          })
          // console.log('arrayUpdateNotes', arrayUpdateNotes)
          // }
        }

        const resultMutation = await updateStatusAsPayed({
          variables: {
            updateStatusNote: arrayUpdateNotes,
          },
          fetchPolicy: 'no-cache',
        })

        // console.log('resultMutation', resultMutation)
        if (resultMutation.data.updateStatusNotes) {
          if (refetch) {
            // await refetchBills()
            await refetch()
          }
          setLoadingBtn(false)
          return toastSweetAlertNotifications(
            {
              mode: 'ok',
              message:
                'Se marcaron como pagados los contrarecibos seleccionados',
            },
            setClearSelected(!clearSelected),
            setIds([]),
            setCountNotes(0),
            setTotalNotes(0)
          )
        }
      } else {
        setLoadingBtn(false)
        // setClearSelected(!clearSelected)
        // setIds([])
        // setCountNotes(0)
        // setTotalNotes(0)
      }
    } catch (e) {
      setLoadingBtn(false)
      // setClearSelected(!clearSelected)
      // setIds([])
      // setCountNotes(0)
      // setTotalNotes(0)
      console.log('e', e)
    }
  }

  const handleReOpenNotesInPartialPayment = async () => {
    try {
      setLoadingBtn(true)
      let arrayUpdateNotes = []
      let consecutivesNumbersOfPayments = []
      let paymentsEquals = false

      if (ids.length === 0) {
        setLoadingBtn(false)
        return toastSweetAlertNotifications({
          mode: 'error',
          message: 'No hay registros seleccionados',
        })
      }

      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message: '¿Estas seguro de reabrir los contrarecibos seleccionados?',
      })

      if (result.isConfirmed) {
        let idNote = ids[0]
        const isPaymentShared = await haandleSharedPayments(idNote, false)
        const { data: dataNotesByRange } = await getNotesRangeById({
          variables: {
            idNote: ids,
          },
          fetchPolicy: 'no-cache',
        })
        for (let note of dataNotesByRange?.getNotesForRangeById) {
          let statusValue = note?.status_note

          if (statusValue === 1 || statusValue === 2 || statusValue === 3) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message:
                  'Los contrarecibos con el estatus de "Pendiente, Recibida y Recibida y Programada" no se pueden reabrir',
              }
              // setClearSelected(!clearSelected),
              // setIds([]),
              // setCountNotes(0),
              // setTotalNotes(0)
            )
          }

          // if (statusValue === 5 || statusValue === 6 || statusValue === 7) {
          //   return toastSweetAlertNotifications(
          //     {
          //       mode: 'error',
          //       message:
          //         'No se pueden reabrir contrarecibos que ya esten pagadas',
          //     },
          //     setClearSelected(!clearSelected),
          //     setIds([])
          //   )
          // }

          if (isPaymentShared) {
            // console.log('Pago compartido', isPaymentShared)
            setLoadingBtn(false)
            return toastSweetAlert({
              mode: 'error',
              message: `El contrarecibo seleccionado esta compartiendo pago con otros contrarecibos. No se pueden reabrir, favor de seleccionar los contrarecibos con los id:  ${isPaymentShared}. Para continuar con el proceso.`,
            })
          }

          for (let payment of note?.notesToGeneratePaymentsFinances) {
            if (payment?.is_active) {
              let consecutiveNumberOfPaymentsCurrent =
                payment?.consecutive_number_of_payments

              paymentsEquals = consecutivesNumbersOfPayments.includes(
                consecutiveNumberOfPaymentsCurrent
              )
              if (!paymentsEquals) {
                consecutivesNumbersOfPayments.push(
                  consecutiveNumberOfPaymentsCurrent
                )
              }
            }
          }

          if (idNote !== note?.id && !paymentsEquals) {
            isPaymentShared = await haandleSharedPayments(note?.id, true)
          }

          if (isPaymentShared) {
            // console.log('Pago compartido', isPaymentShared)
            setLoadingBtn(false)
            return toastSweetAlert({
              mode: 'error',
              message: `El contrarecibo seleccionado esta compartiendo pago con otros contrarecibos. No se pueden reabrir, favor de seleccionar los contrarecibos con los id:  ${isPaymentShared}. Para continuar con el proceso.`,
            })
          }

          arrayUpdateNotes.push({
            id: note?.id,
            status_note: 4,
          })
          // console.log('arrayUpdateNotes', arrayUpdateNotes)
          // }
        }

        const resultMutation = await updateStatusAsPayed({
          variables: {
            updateStatusNote: arrayUpdateNotes,
          },
          fetchPolicy: 'no-cache',
        })

        // console.log('resultMutation', resultMutation)

        if (resultMutation.data.updateStatusNotes) {
          if (refetch) await refetch()
          setLoadingBtn(false)
          return toastSweetAlertNotifications(
            {
              mode: 'ok',
              message: 'Se reabrieron los contrarecibos seleccionados',
            }
            // setClearSelected(!clearSelected),
            // setIds([]),
            // setCountNotes(0),
            // setTotalNotes(0)
          )
        }
      } else {
        setLoadingBtn(false)
        // setClearSelected(!clearSelected)
        // setIds([])
        // setCountNotes(0)
        // setTotalNotes(0)
      }
    } catch (e) {
      setLoadingBtn(false)
      // setClearSelected(!clearSelected)
      // setIds([])
      // setCountNotes(0)
      // setTotalNotes(0)
      console.log('e', e)
    }
  }

  const handleMarkAsCommited = async () => {
    try {
      setLoadingBtn(true)
      let arrayUpdateNotes = []

      if (ids.length === 0) {
        return toastSweetAlertNotifications({
          mode: 'error',
          message: 'No hay registros seleccionados',
        })
      }

      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message:
          '¿Estas seguro de marcar como entregados los contrarecibos seleccionados?',
      })

      if (result.isConfirmed) {
        const { data: dataNotesByRange } = await getNotesRangeById({
          variables: {
            idNote: ids,
          },
          fetchPolicy: 'no-cache',
        })
        for (let note of dataNotesByRange?.getNotesForRangeById) {
          // for (let id of ids) {
          // const { data: dataGetOneNote } = await getOneNote({
          //   variables: {
          //     getOneNoteId: id,
          //   },
          //   fetchPolicy: 'no-cache',
          // })

          // const isPaymentShared = await haandleSharedPayments(
          //   dataGetOneNote?.getOneNote?.notesToGeneratePaymentsFinances,
          //   id,
          //   true
          // )

          // if (isPaymentShared) {
          //   return toastSweetAlert(
          //     {
          //       mode: 'error',
          //       message:
          //         'No se puede marcar como pagado un contrarecibo que tiene pagos compartidos. Por favor, seleccione los contrarecibos con los siguientes id para marcarlos como pagados: ' +
          //         isPaymentShared,
          //     },
          //     setClearSelected(!clearSelected),
          //     setIds([])
          //   )
          // }

          let statusValue = note?.status_note

          let fullPayed = note?.full_payed

          if (!fullPayed) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: `Los contrarecibos que no esten con el estatus de "Pago con ajuste, pago completo y pago contabilidad", no se pueden marcar como entregados`,
              }
              // setClearSelected(!clearSelected),
              // setIds([]),
              // setCountNotes(0),
              // setTotalNotes(0)
            )
          }

          if (statusValue === 8) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: `Los contrarecibos con el estatus de "Entregado" no se pueden volver a marcar como entregados`,
              }
              // setClearSelected(!clearSelected),
              // setIds([]),
              // setCountNotes(0),
              // setTotalNotes(0)
            )
          }

          arrayUpdateNotes.push({
            id: note?.id,
            status_note: 8,
          })
          // console.log('arrayUpdateNotes', arrayUpdateNotes)
          // }
        }

        const resultMutation = await updateStatusAsPayed({
          variables: {
            updateStatusNote: arrayUpdateNotes,
          },
          fetchPolicy: 'no-cache',
        })

        // console.log('resultMutation', resultMutation)
        if (resultMutation.data.updateStatusNotes) {
          if (refetch) await refetch()
          return toastSweetAlertNotifications(
            {
              mode: 'ok',
              message:
                'Los contrarecibos seleccionados se marcaron como entregados',
            },
            setLoadingBtn(false),
            setClearSelected(!clearSelected),
            setIds([])
          )
        }
      } else {
        setLoadingBtn(false)
        // setClearSelected(!clearSelected)
        // setIds([])
        // setCountNotes(0)
        // setTotalNotes(0)
      }
    } catch (e) {
      setLoadingBtn(false)
      // setClearSelected(!clearSelected)
      // setIds([])
      // setCountNotes(0)
      // setTotalNotes(0)
      console.log('e', e)
    }
  }

  const handleStatusReturnPending = async () => {
    try {
      setLoadingBtn(true)
      let arrayUpdateNotes = []

      if (ids.length === 0) {
        return toastSweetAlertNotifications({
          mode: 'error',
          message: 'No hay registros seleccionados',
        })
      }

      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message:
          '¿Estas seguro de marcar como entregados los contrarecibos seleccionados?',
      })

      if (result.isConfirmed) {
        const { data: dataNotesByRange } = await getNotesRangeById({
          variables: {
            idNote: ids,
          },
          fetchPolicy: 'no-cache',
        })
        for (let note of dataNotesByRange?.getNotesForRangeById) {
          let statusValue = note?.status_note

          let fullPayed = note?.full_payed

          if (statusValue === 1) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: `Los contrarecibos con el estatus de "Pendiente" no se pueden a marcar como pendientes`,
              },
              setClearSelected(!clearSelected),
              setIds([]),
              setCountNotes(0),
              setTotalNotes(0)
            )
          }

          if (statusValue === 8) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: `Los contrarecibos con el estatus de "Entregado" no se pueden a marcar como pendientes`,
              },
              setClearSelected(!clearSelected),
              setIds([]),
              setCountNotes(0),
              setTotalNotes(0)
            )
          }

          if (statusValue === 4) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: `Los contrarecibos con el estatus de "Pago parcial", no se pueden a marcar como pendientes`,
              },
              setClearSelected(!clearSelected),
              setIds([]),
              setCountNotes(0),
              setTotalNotes(0)
            )
          }

          if (fullPayed) {
            setLoadingBtn(false)
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: `Los contrarecibos que esten con el estatus de "Pago con ajuste, pago completo y pago contabilidad", no se pueden marcar como pendientes`,
              },
              setClearSelected(!clearSelected),
              setIds([]),
              setCountNotes(0),
              setTotalNotes(0)
            )
          }

          arrayUpdateNotes.push({
            id: note?.id,
            status_note: 1,
          })
          // console.log('arrayUpdateNotes', arrayUpdateNotes)
          // }
        }

        const resultMutation = await updateStatusAsPayed({
          variables: {
            updateStatusNote: arrayUpdateNotes,
          },
          fetchPolicy: 'no-cache',
        })

        // console.log('resultMutation', resultMutation)
        if (resultMutation.data.updateStatusNotes) {
          if (refetch) await refetch()
          return toastSweetAlertNotifications(
            {
              mode: 'ok',
              message:
                'Los contrarecibos seleccionados regresaron a estatus pendiente',
            },
            setLoadingBtn(false),
            setClearSelected(!clearSelected),
            setIds([])
          )
        }
      } else {
        setLoadingBtn(false)
        // setClearSelected(!clearSelected)
        // setIds([])
        // setCountNotes(0)
        // setTotalNotes(0)
      }
    } catch (e) {
      setLoadingBtn(false)
      // setClearSelected(!clearSelected)
      // setIds([])
      // setCountNotes(0)
      // setTotalNotes(0)
      console.log('e', e)
    }
  }

  const handlePrintReport = async () => {
    try {
      console.log('pagePagination', pagePagination)

      //         searchQuery: pagePagination.searchQuery,
      // dateFrom: pagePagination.dateFrom,
      // dateTo: pagePagination.dateTo,
      // fullPayed: pagePagination.fullPayed,
      // withOutPay: pagePagination.withOutPay,
      // shopRegister: pagePagination.shopRegister,
      // idProvider: pagePagination.idProvider,
      // statusNote: pagePagination.statusNote,
      // notesForFinances: pagePagination.notesForFinances,

      const { data } = await generateReport({
        variables: {
          searchInput: pagePagination.searchQuery,
          startDate: pagePagination.dateFrom,
          endDate: pagePagination.dateTo,
          fullPayed: pagePagination.fullPayed,
          withOutPay: pagePagination.withOutPay,
          shopRegister: pagePagination.shopRegister,
          idProvider: pagePagination.idProvider,
          notesForFinances: pagePagination.notesForFinances,
          statusNote: pagePagination.statusNote,
          mode: 2,
        },
      })

      return toastSweetAlert({
        mode: 'ok',
        message: data?.createCounterchargesReports?.message,
      })
    } catch (error) {
      console.log(error)
      return toastSweetAlert({
        mode: 'error',
        message: error?.message,
      })
    }
  }

  // const handlePrintReport = async () => {
  //   try {
  //     setLoadingBtn(true)
  //     let dataExcel = []
  //     const { data } = await getAllNotesExport()
  //     // console.log(data?.getAllNotesExport)

  //     const dataExport = data?.getAllNotesExport

  //     for (let notes of dataExport) {
  //       for (let bills of notes?.bills) {
  //         let referencesPay = ''
  //         let nomenclatureDate = ''
  //         let nomenclatureBank = ''
  //         let consecutiveNumber = ''
  //         let datePayment = ''
  //         let totalPayment = 0
  //         // for (let billPayments of bills?.billsPayments) {
  //         //   for (let referencePay of billPayments?.paymentsBillsToChecks) {
  //         //     // referencesPay += `${referencePay?.reference_pay}, `
  //         //   }
  //         // }
  //         for (let billBankPayments of bills?.billsToBillsPaymentsFinances) {
  //           for (let referencePay of billBankPayments?.billsPaymentsToPaymentsFinances) {
  //             if (billBankPayments?.is_active) {
  //               nomenclatureDate = referencePay?.nomenclature_date
  //               nomenclatureBank = referencePay?.nomenclature_bank
  //               consecutiveNumber = referencePay?.consecutive_number
  //               datePayment = referencePay?.payment_date
  //               totalPayment += parseFloat(referencePay?.payment_amount)
  //               referencesPay += `${referencePay?.consecutive_number_of_payments}, `
  //             }
  //           }
  //         }
  //         dataExcel.push({
  //           finances: notes?.status_note >= 3 ? 'Si' : 'No',
  //           Nomenclatura: nomenclatureDate,
  //           'Nom. Fecha': nomenclatureBank,
  //           Consecutivo: consecutiveNumber,
  //           counterCharges: notes?.consecutive_number,
  //           rfc: notes?.partnerNotes?.provider_rfc
  //             ? notes?.partnerNotes?.provider_rfc
  //             : 'XAXX010101000',
  //           provider_name: notes?.partnerNotes?.provider_name
  //             ? notes?.partnerNotes?.provider_name
  //             : bills?.provider_name_nm,
  //           invoice_bill: bills?.invoice_bill,
  //           date_note: moment(notes?.date_note, 'YYYY-MM-DD hh:mm:ss a').format(
  //             'DD/MM/YYYY'
  //           ),
  //           date_bill: moment(bills?.date_bill, 'YYYY-MM-DD hh:mm:ss a').format(
  //             'DD/MM/YYYY'
  //           ),
  //           // date_stamp: notes?.date_stamp
  //           //   ? notes?.date_stamp
  //           //   : 'Aun no programado',
  //           date_programmed_to_pay: notes?.date_programmed_to_pay
  //             ? moment(
  //                 notes?.date_programmed_to_pay,
  //                 'YYYY-MM-DD hh:mm:ss a'
  //               ).format('DD/MM/YYYY')
  //             : 'Aun no programado',
  //           amount_bill: formmatterCurrency(bills?.amount_bill),
  //           amount_note: formmatterCurrency(notes?.amount_note),
  //           amount_programmed_to_pay_note: formmatterCurrency(
  //             notes?.amount_programmed_to_pay_note
  //           ),
  //           payed: formmatterCurrency(totalPayment),
  //           payment_date:
  //             datePayment === ''
  //               ? 'Aun no pagado'
  //               : moment(datePayment, 'YYYY-MM-DD hh:mm:ss a').format(
  //                   'DD/MM/YYYY'
  //                 ),
  //           references: referencesPay,
  //           retail: bills?.retail ? bills?.retail : 'Sin retail',
  //           comments: bills?.description
  //             ? bills?.description
  //             : 'Sin comentarios',
  //           user: notes?.userUpdateNotes?.name,
  //         })
  //       }
  //     }

  //     // setLoadingBtn(false)
  //     setDataNoteExport(dataExcel)
  //     // console.log('dataExport', dataExport)
  //     document.getElementById('ExportExcel').click()
  //   } catch (e) {
  //     setLoadingBtn(false)
  //     return toastSweetAlertNotifications({
  //       mode: 'error',
  //       message: `Error al generar el reporte: ${e}`,
  //     })
  //   }
  // }

  const filterOnChangeShopRegister = (id) => {
    // console.log(id)
    setPagePagination({
      ...pagePagination,
      shopRegister: parseInt(id),
    })
  }

  const filterOnChangeRangeDateFrom = (date) => {
    // setDateFrom(date)
    setPagePagination({
      ...pagePagination,
      dateFrom: date,
    })
  }

  const filterOnChangeRangeDateTo = (date) => {
    // setDateTo(date)
    setPagePagination({
      ...pagePagination,
      dateTo: date,
    })
  }

  const filterOnChangeForStatusNote = (value) => {
    const statusNote = value
    setPagePagination({
      ...pagePagination,
      statusNote: parseInt(statusNote),
    })
  }

  const filterOnChangeForProviders = (value) => {
    const provider = value
    setPagePagination({
      ...pagePagination,
      idProvider: parseInt(provider),
    })
  }

  const filterOnChangeBox = (e) => {
    const checked = e.target.checked
    setPagePagination({
      ...pagePagination,
      fullPayed: checked,
      withOutPay: false,
    })
  }

  const filterOnChangeBox2 = (e) => {
    const checked = e.target.checked
    setPagePagination({
      ...pagePagination,
      withOutPay: checked,
      fullPayed: false,
    })
  }

  const handlePrintTicket = async (id) => {
    const { data } = await getOneNote({
      variables: {
        getOneNoteId: parseInt(id),
      },
      fetchPolicy: 'no-cache',
    })

    const dataNote = data?.getOneNote

    const secondTicketStatus = data?.getOneNote?.partnerNotes?.second_ticket
    const totalSecondTicket = Number(
      parseFloat(dataNote?.amount_note) - parseFloat(dataNote?.retention_note)
    )
    const consecutiveNumber = dataNote?.consecutive_number
    data?.getOneNote?.bills?.sort((a, b) => {
      return b?.amount_bill - a?.amount_bill
    })

    setIdNote(id)
    setDataNote(data?.getOneNote)
    setValueSecondTicket(secondTicketStatus)
    setConsecutiveNumber(consecutiveNumber)
    setTotalRetention(totalSecondTicket)
    document.getElementById('PrintTicket').click()
  }

  const handlePrintSecondTicket = async () => {
    if (valueSecondTicket) {
      document.getElementById('PrintSecondTicket').click()
    } else {
      handleRePrintTicket()
    }
  }

  useEffect(() => {
    if (response === null || response === undefined) return
    if (response) {
      setResponse(null)
      setLoadingBtn(false)
      return toastSweetAlertNotifications({
        mode: 'ok',
        message: `Reporte generado correctamente`,
      })
    }

    if (!response) {
      setResponse(null)
      setLoadingBtn(false)
      return toastSweetAlertNotifications({
        mode: 'error',
        message: `Error al generar el reporte`,
      })
    }
  }, [response])

  const handleRePrintTicket = async () => {
    const reponseRePrint = await toastSweetAlert({
      mode: 'modalConfirmCancel',
      icon: 'info',
      message: '¿Desea re-imprimir el contrarecibo?',
    })

    if (reponseRePrint.isConfirmed) {
      handlePrintTicket(idNote)
    }
  }

  // console.log('user', user)

  const ExpandableRows = ({ data }) => {
    // console.log(data)
    return (
      <div>
        <ShowBillsToggle idNote={data?.id} />
      </div>
    )
  }

  // console.log('dataToProgram', dataToProgram)

  // console.log('flag', flag)
  // console.log('data', dataToTable)

  return (
    <div className={loadingBtn ? 'processing-file' : ''}>
      <ContentHeader
        title="Programación de pagos"
        windowTitle="Programación de pagos"
        // breadcrumb="Programación de pagos"
      />

      <Box
        withCard={false}
        content={
          <>
            <LayoutTable
              data={dataToTable}
              // title="Lista de consecutivos"
              alertSecondaryColor="#a9fba9"
              hideId
              totalCount={total}
              loadingData={loadingGetNotes}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              hideAddNew={true}
              actionsInFirstColumn
              exportImportButton={false}
              refetchFunctionQueries={async () => await refetch()}
              gql={DELETE_NOTES}
              customFilterButtonInput={
                <>
                  <br />
                  <br />
                  <div
                    className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ${
                      loadingBtn ? 'processing-file' : ''
                    } `}
                  >
                    <label>Acciones:</label>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={handleDocumentsReceived}
                    >
                      Marcar como recibidas
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleProgramPayments()}
                    >
                      Programar
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleGeneratePayments()}
                    >
                      Pagar
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleMarkAsPaid()}
                    >
                      Marcar como pagadas
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleReOpenNotesInPartialPayment()}
                    >
                      Reabrir contrarecibos
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleMarkAsCommited()}
                    >
                      Marcar como entregados
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleStatusReturnPending()}
                    >
                      Regresar a pendiente
                    </span>
                    {/* {parseInt(idRole) === parseInt(user.role) && (
      <Box
        withCard={false}
        content={
          <>
            <LayoutTable
              data={dataToTable}
              title="Lista de consecutivos"
              hideId
              totalCount={total}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              hideAddNew={true}
              actionsInFirstColumn
              exportImportButton={false}
              refetchFunctionQueries={async () => await refetchBills()}
              gql={DELETE_NOTES}
              customFilterButtonInput={
                <>
                  <br />
                  <br />
                  <div
                    className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ${
                      loadingBtn ? 'processing-file' : ''
                    } `}
                  >
                    <label>Acciones:</label>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={handleDocumentsReceived}
                    >
                      Marcar como recibidas
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleProgramPayments()}
                    >
                      Programar
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleGeneratePayments()}
                    >
                      Pagar
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleMarkAsPaid()}
                    >
                      Marcar como pagadas
                    </span>
                    <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleReOpenNotesInPartialPayment()}
                    >
                      Reabrir contrarecibos
                    </span>
                    {/* <span
                      className="btn btn-sm btn-actions-bulk-counter-charges"
                      onClick={() => handleSelectAllOrDeselectAll()}
                    >
                      {selectAll ? 'Deseleccionar todo' : 'Seleccionar todo'}
                    </span> */}
                    <hr />
                    <OrderCol
                      mediumColSize="12"
                      content={
                        <>
                          <label
                            style={{
                              marginLeft: '100px',
                            }}
                          >
                            Seleccionados: {countNotes}{' '}
                          </label>

                          <label
                            style={{
                              marginLeft: '60px',
                            }}
                          >
                            Total: {formmatterCurrency(totalNotes)}
                          </label>
                        </>
                      }
                    />
                    <hr />
                    {/* {parseInt(idRole) === parseInt(user.role) && (
                        <span
                          className="btn btn-sm btn-actions-bulk-counter-charges"
                          onClick={() => handleGeneratePaymentsForContability()}
                        >
                          Pagos Contabilidad
                        </span>
                      )} */}
                  </div>
                </>
              }
              selectableRows
              selectableRowsClick={(e) => handleSelectedRows(e)}
              selectableRowSelected={true}
              expandableRows
              expandableRowsComponent={ExpandableRows}
              clearSelectedRows={clearSelected}
              // filter={user.store === null ? true : false}
              // filterOnChange={(e) => setIdStore(e.target.value)}
              // filterLabelText="Por tienda"
              // filterOptions={options.map((item) => {
              //   return (
              //     <option key={item.value} value={item.value}>
              //       {item.label}
              //     </option>
              //   )
              // })}
              filterChoosen={[
                {
                  id: 'statusNote',
                  label: 'Por estatus',
                  name: 'statusNote',
                  options: optionsStatusNote,
                  placeholder: 'Seleccione un estatus',
                  onChange: (e) => filterOnChangeForStatusNote(e?.value),
                  isClearable: true,
                },
                {
                  id: 'providers',
                  label: 'Por proveedor',
                  name: 'providers',
                  className: 'position-searchInput',
                  options: optionsProviders,
                  placeholder: 'Selecciona un proveedor',
                  onChange: (e) => filterOnChangeForProviders(e?.value),
                  isClearable: true,
                },
              ]}
              filterRangeDateFrom={true}
              filterRangeDateTo={true}
              filterOnChangeRangeDateFrom={(e) =>
                filterOnChangeRangeDateFrom(e.target.value)
              }
              filterOnChangeRangeDateTo={(e) =>
                filterOnChangeRangeDateTo(e.target.value)
              }
              // editText={'Programar'}
              // actionBtnEdit={(e) => handleProgramPaymentsForButton(e)}
              importCustomVariables={[
                {
                  searchQuery: pagePagination.searchQuery,
                  limit: pagePagination.limit,
                  offset: pagePagination.offset,
                  dateFrom: pagePagination.dateFrom,
                  dateTo: pagePagination.dateTo,
                  notesForFinances: pagePagination.notesForFinances,
                  shopRegister: pagePagination.shopRegister,
                  statusNote: pagePagination.statusNote,
                },
              ]}
              // exportQuery={EXPORT_ALL_NOTES}
              // exportQueryCustom={getAllNotesExport}
              extraHeaderButtonFunction={handlePrintReport}
              extraHeaderButtonFunctionText="Descargar reporte"
              editText={
                <label>
                  <i className="fas fa-print"></i>
                </label>
              }
              actionBtnEdit={(e) => handlePrintTicket(e)}
              detailsText={
                <label>
                  <i className="fas fa-search-plus"></i>{' '}
                </label>
              }
              deleteText={
                <label>
                  <i
                    // style={{ color: 'black', }}
                    className="fas fa-trash-alt"
                  ></i>{' '}
                </label>
              }
              convertActionsToIcons
              widthCustomActions="150px"
              hideColumns={['id_bill']}
              filterCheckBox
              filterCheckBox1
              // filterCheckBox2
              lblChkBox="Contrarecibos pagados"
              lblChkBox2="contrarecibos sin pagar"
              filterOnChangeBox={(e) => filterOnChangeBox(e)}
              filterOnChangeBox2={(e) => filterOnChangeBox2(e)}
            />
          </>
        }
      />
      <ModalContent
        idModal="modalFinancesCounterCharges"
        title="Programar"
        size="large"
        onClose={() => setCloseModal(true)}
        content={
          <>
            <ProgramPayments
              dataProgramPayments={dataToProgram}
              refModal={refModalFinances}
              refetch={refetch}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
            />
          </>
        }
      />

      <ModalContent
        idModal="modalFinancesCounterChargesForButton"
        title="Programar"
        size="large"
        onClose={() => setCloseModal(true)}
        content={
          <>
            <ProgramPayments
              dataProgramPayments={dataToProgram}
              refModal={refModalForButton}
              refetch={refetch}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
              setProgrammedPayments={setProgrammedPayments}
              programmedPayments={programmedPayments}
            />
          </>
        }
      />

      <ModalContent
        idModal="modalFinancesCounterChargesGeneratePayments"
        title="Pagos"
        size="normal"
        onClose={() => setCloseModal(true)}
        content={
          <>
            <GeneratePaymentsFinances
              dataToGeneratePayments={dataToProgram}
              dataHistoryPayments={dataHistoryPayments}
              refModal={refModalGeneratePayments}
              refetch={refetch}
              paymentsContability={paymentsContability}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
            />
          </>
        }
      />
      <span
        id="spanModalForButton"
        ref={refModalForButton}
        className="btn btn-sm invisible"
        data-toggle="modal"
        data-target="#modalFinancesCounterChargesForButton"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
        // onClick={handleProgramPaymentsForButton}
      ></span>
      <span
        id="spanModalHeader"
        ref={refModalFinances}
        className="btn btn-sm invisible"
        data-toggle="modal"
        data-target="#modalFinancesCounterCharges"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
        // onClick={handleProgramPaymentsForButton}
      ></span>
      <span
        id="spanModalGeneratePayments"
        ref={refModalGeneratePayments}
        className="btn btn-sm invisible"
        data-toggle="modal"
        data-target="#modalFinancesCounterChargesGeneratePayments"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
        // onClick={handleProgramPaymentsForButton}
      ></span>

      <ExportExcel
        id="ExportExcel"
        excelData={dataNoteExport}
        fileName={'Historial Contra-recibos'}
        setResponse={setResponse}
        response={response}
        headers={[
          'Recibido Finanzas',
          'Nomenclatura',
          'Nom. Fecha',
          'Consecutivo',
          'Consecutivo Contrarecibo',
          'RFC',
          'Proveedor',
          'Folio Factura',
          'Fecha CR',
          'Fecha Factura',
          'Fecha de pago programado',
          'Monto Factura',
          'Monto Contra-recibo',
          'Monto programado',
          'Pagado',
          'Fecha de pago',
          'Forma de pago',
          'Retail',
          'Detalles',
          'Usuario que modifico',
        ]}
        skipHeader={true}
        stylePayments
        colPayments={3}
        stylesToData={{
          font: { bold: false, sz: 10 },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          // border: { top: { style: 'thin' } },
        }}
      />
      <div className="hide">
        <PrintFirstTicket
          componentRef={componentRef}
          dataNote={dataNote}
          secondTicket={valueSecondTicket}
        />
        <ReactToPrint
          trigger={() => <button id="PrintTicket">Print this out!</button>}
          content={() => componentRef.current}
          copyStyles={false}
          pageStyle={`@page { size: 80mm auto; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 0mm; }`}
          onAfterPrint={() => handlePrintSecondTicket()}
        />
      </div>
      <div className="hide">
        <PrintSecondTicket
          componentRef={componentRefSecondTicket}
          TotalRetention={totalRetention}
          consecutiveNumber={consecutiveNumber}
        />
        <ReactToPrint
          trigger={() => (
            <button id="PrintSecondTicket">Print this out!</button>
          )}
          content={() => componentRefSecondTicket.current}
          pageStyle="@page { size: 80mm auto; page-break-after: always; }"
          onAfterPrint={() => handleRePrintTicket()}
        />
      </div>
    </div>
  )
}

export default FinancesCounterCharges
