import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { document_statuses_esp as statusDictionary } from '../../Helpers/StatusesLanguages'
import LayoutTable from '../../Global/LayoutTable'
import OrderCol from '../../Global/OrderCol'
import Loader from '../../Global/Spinner'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useMutation, useQuery } from '@apollo/client'
import {
  ASSIGN_PICKER_TO_TRANSFER,
  GET_APP_USERS_BY_TYPE,
  GET_PICKERS_AND_TRANSFER_PICKERS_USERS,
} from '../../../graphql/Catalog/UsersApp/usersApp'
import Swal from 'sweetalert2'
//import OrderButtons from '../../OrderButtons'

const DetailsTransfer = ({
  dataRequest,
  loadingData,
  detailsProducts,
  isPicking,
  refetch,
  setLoadingBtn,
}) => {
  const history = useHistory()
  const location = useLocation()

  const [btnRetakeRequest, setBtnRetakeRequest] = useState('')
  const [pickingUsers, setPickingUsers] = useState([])

  // const { data, loading, error } = useQuery(GET_APP_USERS_BY_TYPE, {
  //   variables: { type: 'Picking' },
  // })
  const { data, loading, error } = useQuery(
    GET_PICKERS_AND_TRANSFER_PICKERS_USERS,
    {
      variables: {
        type: ['Picking', 'PickingTransfer'],
      },
    }
  )

  const [assignPicker] = useMutation(ASSIGN_PICKER_TO_TRANSFER)

  useEffect(() => {
    if (loading) return
    if (error)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    const list = data?.getPickersAndTransferPickersUsers.rows?.map((item) => ({
      value: item?.id,
      label: `${item?.name} ${item?.first_name} ${item?.last_name}`,
    }))
    setPickingUsers(list)
  }, [data, loading, error])

  useEffect(() => {
    if (dataRequest?.status) {
      if (statusDictionary[6] === dataRequest?.status) {
        if (location.pathname.includes('details')) {
          const pathEdit = location.pathname
            .replace('details', 'edit')
            .replace('/show', '')
          setBtnRetakeRequest(pathEdit)
        }
      }
    }
  }, [dataRequest?.status, location.pathname])

  const handleAssignPicker = async (id) => {
    const result = await toastSweetAlert({
      mode: 'modalConfirmContent',
      title: 'Asignación de picker',
      content: contentAssignPicker,
      validator: validatorAssignPicker,
    })
    if (result.isConfirmed) {
      try {
        setLoadingBtn(true)
        await assignPicker({
          variables: {
            idUser: parseInt(result.value),
            orderWarehouseId: parseInt(id),
          },
        })
        await refetch()
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'ok',
          message: 'Picker asignado correctamente',
        })
      } catch (error) {
        setLoadingBtn(false)
        return toastSweetAlert({ mode: 'error', message: error.message })
      }
    }
    setLoadingBtn(false)
  }

  const contentAssignPicker = (
    <div className="message-container mt-3">
      <div>
        <h4>Seleccione un picker</h4>
        <select
          id="picking_user"
          className="form-control rounded-2"
          defaultValue=""
        >
          <option disabled hidden value="">
            Selecciona un opción
          </option>
          {pickingUsers.map((user) => (
            <option key={1} value={user.value}>
              {user.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )

  const validatorAssignPicker = () => {
    const picker = document.getElementById('picking_user').value
    if (!picker) {
      Swal.showValidationMessage(`Debes seleccionar un picker`)
    }

    return picker
  }

  return (
    <div className={`row ${loading ? 'processing-file' : ''}`}>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Detalles</h3>
          </div>
          <div className="card-body col-12 p-5">
            <div className="box-separator">
              <div className="row">
                <OrderCol
                  title="Fecha"
                  content={dataRequest?.date}
                  mediumColSize="2"
                />
                <OrderCol
                  title="Número de traspaso"
                  content={dataRequest?.doc_entry}
                  mediumColSize="2"
                />
                <OrderCol
                  title="Estatus"
                  content={dataRequest?.status}
                  mediumColSize="2"
                />
                <OrderCol
                  title="Almacén origen"
                  content={dataRequest?.origin}
                  mediumColSize="3"
                />
                <OrderCol
                  title="Almacén destino"
                  content={dataRequest?.destiny}
                  mediumColSize="3"
                />
                <OrderCol
                  title="Comentarios"
                  content={dataRequest?.comments}
                  mediumColSize="12"
                />
                <OrderCol
                  title="PDF"
                  content={dataRequest?.comments}
                  mediumColSize="12"
                />
              </div>
            </div>
            <div className="box-separator">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <h5 className="header-text-details">Productos</h5>
                  {!dataRequest?.products ? (
                    <div className="row justify-content-center align-items-center">
                      <Loader />
                    </div>
                  ) : (
                    <LayoutTable
                      // hideId
                      data={dataRequest?.products}
                      loadingData={loadingData}
                      paginationServer={false}
                      hideFilterBar
                      hideActions={!isPicking}
                      handleButtonTitle="Detalles"
                      handleButton={detailsProducts}
                      handleConditionalButton={handleAssignPicker}
                      handleConditionalButtonTitle="Asignar picker"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 action_orders_one_button">
              {btnRetakeRequest !== '' && (
                <Link
                  className="btn StandarModalConfirmButtonLogOut"
                  to={`${btnRetakeRequest}`}
                >
                  Retomar solicitud
                </Link>
              )}
              <button
                className="btn StandarModalConfirmButtonLogOut"
                onClick={() => history.goBack()}
              >
                Regresar
              </button>
            </div>
            {/* <OrderButtons
              oneButton={true}
              onProcess={() => history.goBack()}
              processBtn="Regresar"
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsTransfer
