import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '../../../Global/Box'
import ContentHeader from '../../../Layout/ContentHeader'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_VEHICLE,
  GET_VEHICLES,
  GET_VEHICLE,
  UPDATE_VEHICLE,
} from '../../../../graphql/Catalog/Vehicles/vehicles'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import InputController from '../../../Global/InputController'

export const VehiclesNew = () => {
  const { id: _id } = useParams()

  const [createVehicle] = useMutation(CREATE_VEHICLE, {
    refetchQueries: [
      {
        query: GET_VEHICLES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateVehicle] = useMutation(UPDATE_VEHICLE, {
    refetchQueries: [
      {
        query: GET_VEHICLES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      { query: GET_VEHICLE, variables: { getOneVehicleId: parseInt(_id) } },
    ],
  })

  const {
    data: dataVehicleId,
    loading: loadingVehicleId,
    error: errorVehicleId,
  } = useQuery(GET_VEHICLE, {
    variables: {
      getOneVehicleId: parseInt(_id),
    },
  })

  const [loadingBtn, setLoadingBtn] = useState(false)
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    brand: Yup.string().required('Este campo es obligatorio'),
    model: Yup.string().required('Este campo es obligatorio'),
    motor_number: Yup.string().required('Este campo es obligatorio'),
    plates_number: Yup.string().required('Este campo es obligatorio'),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!loadingVehicleId) {
      if (_id) {
        if (errorVehicleId) {
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'error',
            message: errorVehicleId.message,
          })
        }

        reset(dataVehicleId?.getOneVehicle)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataVehicleId, errorVehicleId])

  const onSubmit = async (Data) => {
    setLoadingBtn(true)
    try {
      if (_id) {
        await updateVehicle({
          variables: {
            vehicleId: parseInt(_id),
            input: {
              brand: Data.brand,
              model: Data.model,
              motor_number: Data.motor_number,
              plates_number: Data.plates_number,
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Vehículo actualizado correctamente',
          }),
          history.push(`/vehicles`)
        )
      } else {
        await createVehicle({
          variables: {
            input: {
              brand: Data.brand,
              model: Data.model,
              motor_number: Data.motor_number,
              plates_number: Data.plates_number,
            },
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Vehículo registrado correctamente',
          },
          history.push(`/vehicles`)
        )
      }
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  return (
    <>
      <ContentHeader
        title="Vehículo"
        breadcrumb="Vehículo"
        windowTitle={`${_id ? 'Editar' : 'Agregar'} Vehículo `}
      />
      <FormProvider {...methods}>
        <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`${_id ? 'Editar' : 'Agregar'} Vehículo`}
            btnRedPath="/vehicles"
            btnRedTxt="Cancelar"
            errors={errors}
            btnSubmit={true}
            btnLoading={loadingBtn}
            content={
              <>
                <div className="row">
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="Marca"
                      name="brand"
                      type="text"
                      placeholder="Marca del Vehículo"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="Modelo"
                      name="model"
                      inputType="text"
                      placeholder="Modelo del Vehículo"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="No. motor"
                      name="motor_number"
                      inputType="text"
                      placeholder="Número de motor"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="No. placas"
                      name="plates_number"
                      inputType="text"
                      placeholder="Número de placas"
                      control={control}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}
export default VehiclesNew
