import { gql } from '@apollo/client'

export const GET_ALL_USERS_OPERATORS_APP = gql`
  query GetAllAppOperatorsUsers(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllAppOperatorsUsers(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        name
        first_name
        last_name
        phone
        username
        id_type
        warehouse_id
        userType {
          id
          name
        }
        vehicle {
          brand
          model
        }
      }
      count
    }
  }
`
export const GET_ONE_USER_OPERATORS_APP = gql`
  query GetAppOperatorUser($getAppOperatorUserId: Int!) {
    getAppOperatorUser(id: $getAppOperatorUserId) {
      id
      name
      first_name
      last_name
      phone
      username
      email
      id_vehicle
      id_type
      warehouse_id
      id_avatar_file
      userType {
        name
        id
      }
      avatar {
        url
        id
      }
      id_address
      address {
        id
        id_colony
        street
        external_number
        internal_number
        country {
          name
          id
        }
        state {
          id
          name
          id_country
        }
        city {
          id
          name
          id_state
        }
        municipality {
          id
          name
          id_city
        }
        colony {
          id
          name
          id_municipality
          zip_code
        }
        zip_code
      }
      contacts {
        id
        id_user
        id_contact
        contact_data {
          id
          name
          lastname
          second_lastname
          phone
          mobile
          ext
          email
        }
      }
      warehouse {
        warehouse_code
        warehouse_name
        id
      }
    }
  }
`
export const CREATE_USER_OPERATORS_APP = gql`
  mutation CreateAppUserOperator(
    $input: AppUserOperatorInput!
    $inputAvatar: Upload!
    $inputAddress: addressInput!
    $inputContact: [contactInput!]!
  ) {
    createAppUserOperator(
      input: $input
      inputAvatar: $inputAvatar
      inputAddress: $inputAddress
      inputContact: $inputContact
    )
  }
`
export const UPDATE_USER_OPERATORS_APP = gql`
  mutation UpdateAppUserOperator(
    $userId: Int!
    $input: AppUserOperatorInput!
    $addressId: Int!
    $inputAddress: addressInput!
    $inputContact: [contactInput!]!
    $inputAvatar: Upload
  ) {
    updateAppUserOperator(
      userID: $userId
      input: $input
      addressId: $addressId
      inputAddress: $inputAddress
      inputContact: $inputContact
      inputAvatar: $inputAvatar
    )
  }
`
