import React from 'react'
import { Link } from 'react-router-dom'
import Loader from './Spinner'

export const SimpleBox = ({
    content = '',
    headerButton = false,
    headerButtonTitle = 'Ver reporte',
    headerButtonRedirect = '#',
    cardTitle = '',
    loading = false,
    error
}) => {
    return (
        <div className='col col-md-6 d-flex'>
            <div className="card layout-configs container-card">
                <div className="card-header">
                    <h3 className="card-title">{cardTitle}</h3>
                    <div className="control-btn-box">
                        <div
                        className="btn-group actions-btn"
                        role="group"
                        aria-label="Button group with nested dropdown"
                        >
                        {
                            headerButton && 
                                <Link
                                    to={headerButtonRedirect}
                                    className="btn btn-accept btn-sm"
                                >
                                    {/* <i className="fa fa-clipboard"></i> Ver reporte */}
                                    {headerButtonTitle}
                                </Link>
                        }
                    </div>
                </div>
                </div>
                <div className="card-body text-center">
                    {!error 
                        ? loading 
                            ? <Loader/> 
                        : content 
                    : "No se ha podido cargar el contenido"}
                </div>
            </div>
        </div>
    )
}

export default SimpleBox