import { gql } from '@apollo/client'

export const GET_ALL_WAREHOUSES = gql`
  query GetAllAddressWarehouses(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllAddressWarehouses(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        warehouse_name
        warehouse_code
        addressWarehouse {
          street
          external_number
          internal_number
          country {
            name
          }
          state {
            name
          }
          city {
            name
          }
          municipality {
            name
          }
          colony {
            name
          }
          zip_code
        }
      }
      count
    }
  }
`

export const GET_ONE_WAREHOUSE = gql`
  query GetAddressWarehouses($getAddressWarehousesId: Int!) {
    getAddressWarehouses(id: $getAddressWarehousesId) {
      id
      warehouse_name
      warehouse_code
      addressWarehouse {
        id
        street
        external_number
        internal_number
        country {
          id
          name
        }
        state {
          id
          name
        }
        city {
          id
          name
        }
        municipality {
          id
          name
        }
        colony {
          id
          name
        }
        zip_code
        latitude
        longitude
      }
    }
  }
`

export const UPDATE_WAREHOUSE = gql`
  mutation UpdateAddressWarehouses(
    $warehouseId: Int!
    $input: addressInputStoresWarehouses!
  ) {
    updateAddressWarehouses(warehouseId: $warehouseId, input: $input)
  }
`
