import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { GET_TRANSFER_REQUEST } from '../../../graphql/Catalog/Transfers/Transfers'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import {
  document_statuses_esp,
  // status_transfers,
} from '../../Helpers/StatusesLanguages'
import { GET_DOCUMENT_STATUS } from '../../../graphql/Documents/Status/status'
import DatesOptions from '../../Global/DatesOptions'
import StatusBox from '../../Global/StatusBox/StatusBox'

export const TransfersSchedule = () => {
  /**Get all status enable */
  const {
    data: dataStatus,
    loading: loadingStatus,
    error: errorStatus,
  } = useQuery(GET_DOCUMENT_STATUS)
  const [allStatus, setAllStatus] = useState(null)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_TRANSFER_REQUEST, {
    variables: {
      onlyWhs: null,
      status: 5,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      searchQuery: pagePagination.searchQuery,
      type: pagePagination.type,
      date: pagePagination.date,
    },
    fetchPolicy: 'no-cache',
  })

  const [dataToTable, setDataToTable] = useState([])
  // const transfersStatus = status_transfers
  const statusDictionary = document_statuses_esp

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getAllTransferRequest.rows.map((transfer) => {
        return list.push({
          id: transfer.id,
          'Doc Num': transfer.doc_num ? transfer.doc_num : 'Sin número',
          'Doc Entry': transfer?.doc_entry ? transfer?.doc_entry : 'Sin número',
          Recibido: moment(transfer.doc_date).format('DD/MMMM/YYYY'),
          // Vencimiento: moment(transfer.doc_due_date).format('DD/MMMM/YYYY'),
          Estatus: <StatusBox mode={transfer.status}/>,
          //Ubicación: transfersStatus[transfer.stage_status],
          'Origen-Destino': `${transfer?.warehouse_origin_name?.warehouse_name} - ${transfer?.warehouse_destiny_name?.warehouse_name}`,
          // Destino: transfer?.warehouse_destiny_name?.warehouse_name,
        })
      })
      setDataToTable(list)
      setTotal(data.getAllTransferRequest.count)
    }
    // eslint-disable-next-liconsole.log(e)ne react-hooks/exhaustive-deps
  }, [loading, error, data, total, pagePagination])

  useEffect(() => {
    if (!loadingStatus) {
      if (errorStatus)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      /**Set data to hook */
      let list = []
      dataStatus.getDocumentStatus.map((item) => {
        list.push({
          id: item.id,
          name: document_statuses_esp[item.id]
            ? document_statuses_esp[item.id]
            : '',
        })
      })
      setAllStatus(list)
    }
  }, [dataStatus, loadingStatus, errorStatus])

  const filterOnChange = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      type: parseInt(filterSelected),
    })
  }

  const filterOnChangeSecond = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  return (
    <>
      {/* <ContentHeader
        title="Órdenes de traspasos"
        breadcrumb="Traspasos citas"
        windowTitle="Traspasos"
      /> */}
      <LayoutTable
        data={dataToTable}
        title="Órdenes de traspasos"
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        // hideFilterBar

        FilterBarPlaceholder="Buscar por Origen o Destino"
        /** First filter */
        filter
        filterOnChange={filterOnChange}
        filterOptions={[
          allStatus
            ? allStatus.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                )
              })
            : null,
        ]}
        filterLabelText="Por estatus"
        /** Second filter */
        filterSecond
        filterOnChangeSecond={filterOnChangeSecond}
        filterOptionsSecond={[<DatesOptions daysBefore={true} />]}
        filterLabelTextSecond="Por fecha"
      />
    </>
  )
}

export default TransfersSchedule
