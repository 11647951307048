import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_TRANSFER_REQUEST_BY_ID } from '../../../graphql/Catalog/Transfers/Transfers'
import { useHistory, useParams } from 'react-router-dom'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { document_statuses_esp } from '../../Helpers/StatusesLanguages'
import Loader from '../../Global/Spinner'
//TABS
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import Box from '../../Global/Box'
import ContentHeader from '../../Layout/ContentHeader'
import PalletByPackingList from './pallet/PalletByPackingList'
import BoxesByPackingList from './PackingListBoxes/BoxesByPackingList'
import moment from 'moment'

export const TransfersValidationNew = () => {
  const { id: _id } = useParams()
  const statusDictionary = document_statuses_esp
  const [dataRequest, setDataRequest] = useState([])
  const [packingLists, setPackingLists] = useState([])
  const [loadingBtn, setLoadingBtn] = useState(false)

  const history = useHistory()

  const {
    data: dataTransferRequestId,
    loading: loadingTransferRequestId,
    error: errorTransferRequestId,
  } = useQuery(GET_TRANSFER_REQUEST_BY_ID, {
    variables: {
      id: parseInt(_id),
      validation: true,
    },
  })

  useEffect(() => {
    if (_id) {
      if (!loadingTransferRequestId) {
        if (errorTransferRequestId) {
          setLoadingBtn(false)
          return toastSweetAlert(
            {
              mode: 'error',
              message: errorTransferRequestId.message,
            },
            history.push('/transfers/validation')
          )
        }

        if (!dataTransferRequestId?.getOneTransferRequest) {
          setDataRequest({ products: [] })
          return toastSweetAlert({
            mode: 'error',
            message:
              'La órden de verificación esta vacia o fueron eliminadas todas sus lineas',
          })
        }

        let data = dataTransferRequestId?.getOneTransferRequest
        const user = data.packinglist?.UserValidate

        setDataRequest({
          date: moment(data?.doc_date).format('DD/MMMM/YYYY'),
          date_due: moment(data?.doc_due_date).format('DD/MMMM/YYYY'),
          comments: data?.comments ? data?.comments : '---',
          origin: data?.warehouse_origin_name?.warehouse_name,
          destiny: data?.warehouse_destiny_name?.warehouse_name,
          status: statusDictionary[data?.status],
          validatedBy: user
            ? `${user.name} ${user.first_name} ${user.last_name}`
            : 'Desconocido',
        })
        setPackingLists(data.PackingList)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataTransferRequestId, errorTransferRequestId])

  return (
    <>
      <ContentHeader
        title={`${_id ? 'Detalles' : 'Nuevo'} traspaso`}
        breadcrumb={`${_id ? 'Detalles' : 'Nuevo'} traspaso`}
        windowTitle={`${_id ? 'Detalles' : 'Nuevo'} traspaso`}
      />

      <div className={`row ${loadingBtn ? 'processing-file' : ''}`}>
        <div className="mb-3 col-12">
          <Box
            content={
              <div className="boxQuotesProvider">
                <div className="row">
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Fecha de cita:
                      <span className="resultData"> {dataRequest?.date}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Fecha de vencimiento:
                      <span className="resultData"> {dataRequest?.date}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Almacén origen:
                      <span className="resultData"> {dataRequest?.origin}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Almacén destino:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.destiny}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Estatus:
                      <span className="resultData"> {dataRequest?.status}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Comentarios:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.comments}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="mb-3 col-12">
          {loadingTransferRequestId ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : (
            <Tabs>
              <TabList>
                {packingLists?.map((packing) => {
                  return (
                    <Tab>
                      <span
                        className={
                          packing.validated
                            ? 'tab-complete-verification'
                            : 'tab-uncomplete-verification'
                        }
                      >
                        Packing {packing?.id}
                      </span>
                    </Tab>
                  )
                })}
              </TabList>

              {packingLists?.map((packing) => {
                return (
                  <TabPanel>
                    <div className="mb-3 col-12">
                      <div className="card-header">
                        <h3 className="card-title">
                          {packing?.UserValidate ? (
                            <>
                              Validado por{' '}
                              <b>
                                {packing?.UserValidate?.name}{' '}
                                {packing?.UserValidate?.first_name}{' '}
                                {packing?.UserValidate?.last_name}
                              </b>
                            </>
                          ) : (
                            <b>Aún no se ha asignado un verificador</b>
                          )}
                        </h3>
                      </div>
                      <Tabs>
                        <TabList>
                          <Tab>Productos</Tab>
                          <Tab>Pallets Asignados</Tab>
                        </TabList>

                        <TabPanel>
                          <BoxesByPackingList
                            requestInfo={packing}
                            packingListId={packing?.id}
                            setLoadingBtn={setLoadingBtn}
                          />
                        </TabPanel>

                        <TabPanel>
                          <PalletByPackingList packingListId={packing?.id} />
                        </TabPanel>
                      </Tabs>
                    </div>
                  </TabPanel>
                )
              })}
            </Tabs>
          )}
        </div>
      </div>
    </>
  )
}

export default TransfersValidationNew
