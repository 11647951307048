import React, { useContext, useEffect, useRef, useState } from 'react'
import InputController from '../../Global/InputController'
import Box from '../../Global/Box'
import { useForm } from 'react-hook-form'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useLazyQuery, useMutation } from '@apollo/client'

import LayoutTable from '../../Global/LayoutTable'
import {
  INVENTORY_ACTIVITY_COUNTINGS_ASSIGNMENT,
  CREATE_INVENTORY_ACTIVITY_COUNT,
  DELETE_INVENTORY_ACTIVITY_COUNT,
  FIND_INVENTORY_ACTIVITY_PRODUCTS_BY_BARCODE_OR_ITEM_CODE,
  END_INVENTORY_ACTIVITY_COUNT,
} from '../../../graphql/InventoryActivities/InventoryActivitiesCount'
import { useHistory, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import NumPad from 'react-numpad'
import moment from 'moment'
import ModalContent from '../../Global/ModalContent'
import { AuthContext } from '../../../Auth/AuthContext'

export const InventoryCountsNew = () => {
  const { id: _id, show } = useParams()
  const { user } = useContext(AuthContext)
  const location = useLocation()
  const history = useHistory()

  const [years, setYears] = useState([])
  const [scannedElementsInfo, setScannedElementsInfo] = useState({
    items: [],
    count: 0,
  })

  const [countAssignedInfo, setCountAssignedInfo] = useState({
    scheduleInfo: '',
    nextElement: '',
  })
  const [loading, setLoading] = useState(false)
  const [hasHarvest, setHasHarvest] = useState('')
  const [expires, setExpires] = useState('')

  const [infoConfigSKU, setInfoConfigSKU] = useState({
    expiration: false,
    vintage: false,
  })

  const [quantityProduct, setQuantityProduct] = useState('')
  const { control, getValues, setValue } = useForm({})
  const [elementScanned, setElementScanned] = useState({})
  const refModal = useRef(null)
  const refInput = useRef(null)

  const [pagePaginationScannedProducts, setPagePaginationScannedProducts] =
    useState({
      limit: 30,
      offset: 0,
      searchQuery: '',
      scheduleId: 0,
      labelId: 0,
      countNumber: 0,
      isCycleCountSKUS: 0,
    })

  const [
    inventoryCounts,
    {
      data: dataInventoryCounts,
      loading: loadingInventoryCounts,
      error: errorInventoryCounts,
      refetch: refetchInventoryCounts,
    },
  ] = useLazyQuery(INVENTORY_ACTIVITY_COUNTINGS_ASSIGNMENT)

  const [findProduct] = useMutation(
    FIND_INVENTORY_ACTIVITY_PRODUCTS_BY_BARCODE_OR_ITEM_CODE
  )
  const [createCount] = useMutation(CREATE_INVENTORY_ACTIVITY_COUNT)
  const [deleteCount] = useMutation(DELETE_INVENTORY_ACTIVITY_COUNT)
  const [closeCount] = useMutation(END_INVENTORY_ACTIVITY_COUNT)

  useEffect(() => {
    // getting actual year
    const actualYear = new Date().getFullYear()
    // define how many years want to show
    const lastYearsQuantity = actualYear - 1990
    // create an array of years
    const lastYears = []
    // Getting list of prev years
    for (let i = actualYear - lastYearsQuantity; i <= actualYear; i++) {
      lastYears.push(i)
    }
    setYears(lastYears.reverse())
  }, [])

  useEffect(() => {
    document.body.style.overflowY = 'auto'
    if (
      (!location?.state?.scheduleInfo ||
        !location?.state?.labelId ||
        !location?.state?.countNumber ||
        !location?.state?.nextElement) &&
      !show
    )
      return toastSweetAlert(
        {
          mode: 'errorToast',
          message: 'Acción no permitida',
        },
        history.push('/counts')
      )
    setCountAssignedInfo({
      nextElement: location?.state?.nextElement,
      scheduleInfo: location?.state?.scheduleInfo,
    })

    handleGetCounts()
    setPagePaginationScannedProducts({
      ...pagePaginationScannedProducts,
      labelId: parseInt(location?.state?.labelId),
      scheduleId: parseInt(location?.state?.scheduleInfo?.id),
      countNumber: parseInt(location?.state?.countNumber),
      isCycleCountSKUS:
        location?.state?.scheduleInfo.ubications_or_skus === 2
          ? location?.state?.nextElement?.elementAssigned
          : '',
    })
  }, [])

  useEffect(() => {
    try {
      if (loadingInventoryCounts) return
      if (errorInventoryCounts)
        return toastSweetAlert({
          mode: 'error',
          message: errorInventoryCounts.message,
        })
      const scheduleInfo = location?.state?.scheduleInfo
      const list =
        dataInventoryCounts?.InventoryActivityCountingsAssignment?.rows.map(
          (product) => {
            if (
              scheduleInfo.type_schedule === 2 ||
              scheduleInfo.ubications_or_skus === 1
            )
              return {
                id: product?.id,
                'Sku/Barcode': `${product?.element_found} - ${product?.barcode}`,
                Nombre: product?.InventoryActivitiesCountsSapItems?.item_name,
                Cantidad: product?.quantity,
                'añada/expiración': `${
                  product?.vintage ? product?.vintage : 'N/A'
                } - ${
                  product?.expiration
                    ? moment(product?.expiration).format('YYYY-MM-DD')
                    : 'N/A'
                }`,
              }
            return {
              id: product?.id,
              Barcode: product.barcode ? product.barcode : 'No capturado',
              Cantidad: product?.quantity,
              'añada/expiración': `${
                product?.vintage ? product?.vintage : 'N/A'
              } - ${
                product?.expiration
                  ? moment(product?.expiration).format('YYYY-MM-DD')
                  : 'N/A'
              }`,
            }
          }
        )
      setScannedElementsInfo({
        items: list,
        count: dataInventoryCounts?.InventoryActivityCountingsAssignment?.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'errorToast', message: error.message })
    }
  }, [dataInventoryCounts, loadingInventoryCounts, errorInventoryCounts])

  const handleGetCounts = async () => {
    try {
      await inventoryCounts({
        variables: {
          ...pagePaginationScannedProducts,
          scheduleId: parseInt(location?.state?.scheduleInfo?.id),
          labelId: parseInt(location?.state?.labelId),
          countNumber: parseInt(location?.state?.countNumber),
          isCycleCountSKUS:
            location?.state?.scheduleInfo.ubications_or_skus === 2
              ? location?.state?.nextElement?.elementAssigned
              : '',
        },
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'errorToast', message: error.message })
    }
  }

  const handleScanFind = async () => {
    try {
      setLoading(true)
      const elementScanned = getValues('elementScanned')

      const { data } = await findProduct({
        variables: {
          query: elementScanned.trim(),
          isCycleCountSKUSItemCode:
            location?.state?.scheduleInfo.ubications_or_skus === 2
              ? location?.state?.nextElement?.skuOrRackId
              : '',
        },
      })

      setInfoConfigSKU({
        expiration: data.findInventoryActivityProductByBarcodes.expires,
        vintage: data.findInventoryActivityProductByBarcodes.hasHarvest,
      })

      await handleSubmitQuantity(data.findInventoryActivityProductByBarcodes)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setValue('elementScanned', '')
      return toastSweetAlert({ mode: 'errorToast', message: error.message })
    }
  }

  const handleSubmitQuantity = async (scan) => {
    refModal.current.click()
    refInput.current.click()
    setElementScanned(scan)
  }

  const handleSubmit = async () => {
    const onlyPositiveInteger = /^\d*$/ //

    if (quantityProduct === '') {
      return toastSweetAlert({
        mode: 'error',
        message: 'Debes ingresar una cantidad',
      })
    }

    if (!onlyPositiveInteger.test(quantityProduct)) {
      return toastSweetAlert({
        mode: 'error',
        message: 'Cantidad inválida',
      })
    }

    if (infoConfigSKU.vintage && !hasHarvest) {
      return toastSweetAlert({
        mode: 'error',
        message: 'Debes seleccionar una añada',
      })
    }

    if (infoConfigSKU.expiration && !expires) {
      return toastSweetAlert({
        mode: 'error',
        message: 'Debes seleccionar una fecha de caducidad',
      })
    }

    try {
      setLoading(true)
      await createCount({
        variables: {
          scheduleId: parseInt(location?.state?.scheduleInfo.id),
          input: {
            label_id: parseInt(location?.state?.labelId),
            element_scanned:
              location?.state?.scheduleInfo.ubications_or_skus === 2
                ? countAssignedInfo?.nextElement?.elementAssigned
                : elementScanned?.item_code,
            barcode: getValues('elementScanned'),
            quantity: parseInt(quantityProduct),
            vintage: hasHarvest,
            expiration: expires,
            count_number: location?.state?.countNumber,
          },
          skuConfigurationInput: {
            expiresConfig: infoConfigSKU.expiration,
            vintageConfig: infoConfigSKU.vintage,
          },
        },
      })
      await refetchInventoryCounts()
      handleReset()
      setValue('elementScanned', '')
      refModal.current.click()
      return toastSweetAlert({
        mode: 'okToast',
        message: 'Conteo guardado correctamente',
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      return toastSweetAlert({ mode: 'errorToast', message: error.message })
    }
  }

  const handleDeleteCount = async (row) => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        title: '¿Eliminar conteo?',
        icon: 'warning',
        message: 'Esta acción no se puede revertir',
      })
      if (!result.isConfirmed) {
        return
      }
      setLoading(true)
      await deleteCount({
        variables: {
          id: parseInt(row.id),
          scheduleId: parseInt(_id),
        },
      })
      setLoading(false)
      await refetchInventoryCounts()
      return toastSweetAlert({
        mode: 'okToast',
        message: 'Conteo eliminado correctamente',
      })
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({ mode: 'errorToast', message: error.message })
    }
  }

  const handleCloseCount = async () => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        title: '¿Finalizar ubicación?',
        icon: 'warning',
        message:
          'Una vez que se cierre el conteo de esta ubicación no se podra retomar',
      })
      if (!result.isConfirmed) return
      setLoading(true)
      await closeCount({
        variables: {
          labelId: parseInt(location?.state?.labelId),
          scheduleId: parseInt(location?.state?.scheduleInfo.id),
          isCycleCountSKUS:
            location?.state?.scheduleInfo.ubications_or_skus === 2
              ? location?.state?.nextElement?.elementAssigned
              : '',
        },
      })
      setLoading(false)
      return history.goBack()
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({ mode: 'errorToast', message: error.message })
    }
  }

  const onChangeNumericKeyboard = (value) => {
    // document.getElementById('quantity_product').value = value
    setQuantityProduct(value)
  }

  const handleCancelModalButton = () => {
    handleReset()
    refModal.current.click()
  }

  const handleReset = () => {
    setValue('elementScanned', '')
    setLoading(false)
    setExpires('')
    setHasHarvest('')
    setQuantityProduct('')
    if (document.getElementById('expiration_date'))
      document.getElementById('expiration_date').value = ''
    if (document.getElementById('anada'))
      document.getElementById('anada').value = ''
  }

  return (
    <>
      <div className={`${loading ? 'processing-file' : ''}`}>
        <div className="row mb-2 pt-4">
          <div className="col-sm-12">
            <h1 className="header-title">
              {show ? 'Detalles de escaneos' : 'Panel de escaneo'} - {user.name}
            </h1>
          </div>
          <div className="col-sm-12">
            <p>
              {
                countAssignedInfo.scheduleInfo
                  ?.InventoryActivitiesWarehouseSchedule?.warehouse_name
              }
            </p>
            <p>
              {countAssignedInfo.scheduleInfo?.type_schedule === 2
                ? 'TFI'
                : `Conteo cíclico - ${
                    countAssignedInfo?.scheduleInfo?.ubications_or_skus === 2
                      ? 'SKUS'
                      : 'Ubicaciones'
                  }`}
            </p>
          </div>
        </div>
        {!show && (
          <Box
            title={null}
            btnFunction={handleCloseCount}
            btnFunctionTitle="Cerrar ubicación"
            btnSecondFunction={() => {
              history.goBack()
            }}
            btnSecondFunctionTitle="Regresar"
            content={
              <>
                <div className="row mb-3">
                  {countAssignedInfo?.scheduleInfo?.ubications_or_skus ===
                    2 && (
                    <div className="col-6">
                      <b>
                        {countAssignedInfo?.nextElement?.skuOrRackId} -{' '}
                        {countAssignedInfo?.nextElement?.itemName}
                      </b>
                    </div>
                  )}

                  <div className="col-6">
                    Ubicación:{' '}
                    <b>{countAssignedInfo?.nextElement?.elementAssigned}</b>
                  </div>

                  {countAssignedInfo?.scheduleInfo?.type_schedule === 2 && (
                    <div className="col-6">
                      Conteo:{' '}
                      <b> {countAssignedInfo?.nextElement?.countNumber}</b>
                    </div>
                  )}
                </div>
                <InputController
                  label="Escanear producto"
                  control={control}
                  name="elementScanned"
                  placeHolder="Escanear código de barras del SKU"
                  changeAction={handleScanFind}
                />
              </>
            }
          />
        )}
        <LayoutTable
          title={`${show ? 'Detalles de escaneos' : 'Escaneo de productos'}`}
          data={scannedElementsInfo.items}
          totalCount={scannedElementsInfo.count}
          pagePagination={pagePaginationScannedProducts}
          setPagePagination={setPagePaginationScannedProducts}
          paginationPerPage={30}
          layoutCardMobile
          hideDetails
          hideId
          hideActions={!show ? false : true}
          customActionButtons={[
            {
              className: 'btn btn-danger btn-sm',
              handleButton: handleDeleteCount,
              buttonText: <i className="fa fa-trash"></i>,
            },
          ]}
          headerButtonsNoPermissions={
            show ? (
              <span
                className="btn btn-accept btn-sm"
                onClick={() => history.goBack()}
              >
                Regresar
              </span>
            ) : (
              false
            )
          }
        />

        {/* MODAL FOR SET QUANTITIES */}
        <span
          ref={refModal}
          className="btn btn-accept btn-sm invisible"
          data-toggle="modal"
          data-target="#scanElement"
          data-backdrop="static"
          data-keyboard="false"
          data-dismiss="modal"
        />

        <ModalContent
          idModal="scanElement"
          title="Producto encontrado"
          size="large"
          onClose={handleCancelModalButton}
          content={
            // <div className="message-container mt-3 w-100 ">
            <div className=" mt-1 w-100 container-fluid ">
              <div className="row">
                <div>
                  <b>
                    {elementScanned?.item_code} - {getValues('elementScanned')}
                  </b>
                  <h4>
                    <p className="m-0 p-0">{elementScanned?.item_name}</p>
                  </h4>
                </div>

                <hr></hr>
                <div className="mt-2 col-12">
                  <h5>Ingrese una cantidad</h5>
                  <NumPad.Number
                    onChange={onChangeNumericKeyboard}
                    decimal={false}
                    negative={false}
                    position="flex-end"
                    selected="dsa"
                    value={quantityProduct}
                    onKeyDown={(changedVal) => console.log(changedVal)}
                  >
                    <input
                      id="quantity_product"
                      className="form-control rounded-2"
                      autoFocus={true}
                      readOnly={true}
                      ref={refInput}
                    />
                  </NumPad.Number>
                  {infoConfigSKU.vintage && (
                    <>
                      <h5 className="mt-3">Añada</h5>
                      <select
                        name="anada"
                        id="anada"
                        className="form-control rounded-2"
                        onChange={(e) => setHasHarvest(e.target.value)}
                      >
                        <option value="" selected={hasHarvest === ''}>
                          N/A
                        </option>
                        {years.map((year) => (
                          <option value={year} key={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>

                <div className="col-12">
                  {infoConfigSKU.expiration && (
                    <>
                      <h5 className="mt-3">Caducidad</h5>
                      <input
                        type="date"
                        id="expiration_date"
                        className="form-control"
                        onChange={(e) => setExpires(e.target.value)}
                      />
                    </>
                  )}
                </div>

                <div className="hide">
                  <input id="anada_hide" value={infoConfigSKU.vintage} />
                  <input
                    id="expiration_date_hide"
                    value={infoConfigSKU.expiration}
                  />
                </div>

                <div className=" d-flex justify-content-between mt-3 w-100 ">
                  <button
                    className="StandarModalCancelButtonLogOut mr-3"
                    type="button"
                    onClick={handleCancelModalButton}
                  >
                    Cancelar
                  </button>

                  <button
                    type="submit"
                    className="StandarModalConfirmButtonLogOut"
                    onClick={handleSubmit}
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  )
}

export default InventoryCountsNew
