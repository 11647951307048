import { gql } from '@apollo/client'

export const GET_ALL_STORES = gql`
  query GetAllAddressStores($searchQuery: String, $limit: Int, $offset: Int) {
    getAllAddressStores(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        name
        addressStore {
          street
          external_number
          internal_number
          country {
            name
          }
          state {
            name
          }
          city {
            name
          }
          municipality {
            name
          }
          colony {
            name
          }
          zip_code
        }
      }
    }
  }
`

export const GET_ONE_STORE = gql`
  query GetAddressStore($getAddressStoreId: Int!) {
    getAddressStore(id: $getAddressStoreId) {
      name
      addressStore {
        street
        external_number
        internal_number
        country {
          name
          id
        }
        state {
          id
          name
        }
        city {
          id
          name
        }
        municipality {
          id
          name
        }
        colony {
          id
          name
        }
        zip_code
        latitude
        longitude
      }
    }
  }
`

export const UPDATE_STORE = gql`
  mutation UpdateAddressStores(
    $storeId: Int!
    $input: addressInputStoresWarehouses!
  ) {
    updateAddressStores(storeId: $storeId, input: $input)
  }
`
