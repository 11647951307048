import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  GET_ONE_ORDER_PICKING_STORE,
  GET_PRODUCTS_ORDER_PICKING_STORE_BY_ID_ORDER_WAREHOUSE,
} from '../../../graphql/Catalog/Orders/pickingOrdersStore'
import Box from '../../Global/Box'
import { useEffect, useRef, useState } from 'react'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import moment from 'moment'
import LayoutTable from '../../Global/LayoutTable'
import DetailsProducts from './DetailsProducts/DetailsProducts'
import ModalContent from '../../Global/ModalContent'
import AssignPickerForPickingOrdersStore from './AssignPicker/AssignPickerForPickingOrdersStore'

const PickingNew = () => {
  const { id: _id } = useParams()

  const btnModalDetailsPickingProducts = useRef()
  const refAssignPicker = useRef()
  const [dataOrder, setDataOrder] = useState(null)
  const [dataToTable, setDataToTable] = useState([])
  const [idOrderWarehouse, setIdOrderWarehouse] = useState(null)
  const [closeModal, setCloseModal] = useState(false)
  const [helperCleanField, setHelperCleanField] = useState(false)

  const {
    data: dataOrderPicking,
    loading: loadingOrderPicking,
    error: errorOrderPicking,
    refetch: refetchOrderPicking,
  } = useQuery(GET_ONE_ORDER_PICKING_STORE, {
    variables: {
      idOrder: parseInt(_id),
    },
    fetchPolicy: 'no-cache',
  })

  const formatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  useEffect(() => {
    if (!loadingOrderPicking) {
      if (errorOrderPicking) {
        return toastSweetAlert({
          mode: 'error',
          message: errorOrderPicking.message,
        })
      }
      const orderData = dataOrderPicking.getOneOrderPickingStore
      console.log(orderData)
      let list = []
      orderData?.ordersWarehouseToOrdersPicking.map((orderWarehouse) => {
        return list.push({
          id: orderWarehouse.id,
          nombre: orderWarehouse.picking_user_id
            ? `${orderWarehouse.UserToOrder.name} ${orderWarehouse.UserToOrder.first_name} ${orderWarehouse.UserToOrder.last_name}`
            : 'Sin asignar',
          activo: orderWarehouse.is_active ? 'Activo' : 'Inactivo',
          surtido: orderWarehouse.picked ? 'Surtido' : 'Sin surtir',
          conditional_hide_button: orderWarehouse.picked ? true : false,
        })
      })
      setDataToTable(list)
      setDataOrder(dataOrderPicking.getOneOrderPickingStore)
    }
  }, [dataOrderPicking, loadingOrderPicking, errorOrderPicking])

  const showDetailsForPickingProdcuts = (id) => {
    setIdOrderWarehouse(id)
    document.getElementById('btnModalDetailsPickingProducts').click()
  }

  const handleAssignPicker = (id) => {
    setIdOrderWarehouse(id)
    setHelperCleanField(true)
    document.getElementById('btnModalAssignPicker').click()
  }

  return (
    <>
      <Box
        title={`Detalles orden - #${dataOrder?.order_id}`}
        loading={loadingOrderPicking}
        btn
        content={
          <>
            {dataOrder && (
              <div className="boxQuotesProvider">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Fecha:{' '}
                      <span className="resultData">
                        {moment(
                          dataOrder?.order_date,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD/MM/YYYY HH:mm:ss')}
                      </span>
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Tipo de envío:{' '}
                      <span className="resultData">
                        {dataOrder?.method?.name
                          ? dataOrder?.method?.name
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Mdo. de pago:{' '}
                      <span className="resultData">
                        {dataOrder?.paymentPicking?.platform
                          ? dataOrder?.paymentPicking?.platform
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Compañia de entrega:{' '}
                      <span className="resultData">
                        {dataOrder?.shippingCompany?.name
                          ? dataOrder?.shippingCompany?.name
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Cantidad:{' '}
                      <span className="resultData">
                        {dataOrder?.product_quantity
                          ? dataOrder?.product_quantity
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Precio total:{' '}
                      <span className="resultData">
                        {dataOrder?.total_price
                          ? formatterCurrency(dataOrder?.total_price)
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Costo de envío:{' '}
                      <span className="resultData">
                        {dataOrder?.shipping_price
                          ? formatterCurrency(dataOrder?.shipping_price)
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Usuario:{' '}
                      <span className="resultData">
                        {dataOrder?.userToOrderStore?.name
                          ? dataOrder?.userToOrderStore?.name
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Cliente:{' '}
                      <span className="resultData">
                        {dataOrder?.shipping?.first_name
                          ? dataOrder?.shipping?.first_name
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Almacen:{' '}
                      <span className="resultData">
                        {dataOrder?.warehouse?.name
                          ? dataOrder?.warehouse?.name
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Dirección entrega:{' '}
                      <span className="resultData">
                        {dataOrder?.shipping?.address_1
                          ? dataOrder?.shipping?.address_1
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            )}
          </>
        }
      />
      <Box
        title="Productos"
        loading={loadingOrderPicking}
        btnRedPath="/storeOrders/ordersPicking"
        btnRedTxt="Regresar"
        content={
          <>
            <LayoutTable
              data={dataToTable}
              paginationServer={false}
              hideFilterBar
              actionBtnDetail={showDetailsForPickingProdcuts}
              handleConditionalButtonTitle="Asignar picker"
              handleConditionalButton={handleAssignPicker}
              refetchFunctionQueries={refetchOrderPicking}
            />
            {/* MODAL FOR DETAILS FOR ORDER*/}
            <div>
              <ModalContent
                title="Detalles de productos"
                idModal="modalDetailsPickingProducts"
                size="large"
                onClose={() => setCloseModal(true)}
                content={
                  <DetailsProducts
                    idOrderWarehouse={idOrderWarehouse}
                    closeModal={closeModal}
                    setCloseModal={setCloseModal}
                  />
                }
              />
              <span
                id="btnModalDetailsPickingProducts"
                ref={btnModalDetailsPickingProducts}
                className="btn btn-sm invisible"
                data-toggle="modal"
                data-target="#modalDetailsPickingProducts"
                data-backdrop="static"
                data-keyboard="false"
                data-dismiss="modal"
              />
            </div>
            {/* MODAL FOR ASSIGN PICKER*/}
            <div>
              <ModalContent
                title="Asignar picker"
                idModal="modalAssignPicker"
                size="large"
                onClose={() => setCloseModal(true)}
                content={
                  <AssignPickerForPickingOrdersStore
                    idOrderWarehouse={idOrderWarehouse}
                    closeModal={closeModal}
                    setCloseModal={setCloseModal}
                    helperCleanField={helperCleanField}
                    setHelperCleanField={setHelperCleanField}
                    refModal={refAssignPicker}
                    refetchGetOneOrder={refetchOrderPicking}
                  />
                }
              />
              <span
                id="btnModalAssignPicker"
                ref={refAssignPicker}
                className="btn btn-sm invisible"
                data-toggle="modal"
                data-target="#modalAssignPicker"
                data-backdrop="static"
                data-keyboard="false"
                data-dismiss="modal"
              />
            </div>
          </>
        }
      />
    </>
  )
}

export default PickingNew
