import { gql } from "@apollo/client"

export const GET_PENDING_SCHEDULE = gql`
  query GetPendingSchedule {
    getPendingSchedule {
        quantity
    }
  }
`
export const GET_NEXT_SCHEDULES = gql`
  query GetNextSchedules {
    getNextSchedules {
        quantity
    }
  }
`

export const GET_TOP_PROVIDERS = gql`
  query GetTopProviders {
    getTopProviders {
        id
        name
    }
  }
`

export const GET_RAMPS_DOWNLOADS = gql`
  query GetRampsDownload {
    getRampsDownload {
        id
        name
        quantity
    }
  }
`

export const GET_BOXES_TOTAL = gql`
  query GetBoxesTotal {
    getBoxesTotal {
        quantity
        boxesDataSevenDaysAgo {
            date
            quantity
        }
    }
  }
`

export const GET_PALLETS_TOTAL = gql`
  query GetPalletsTotal {
    getPalletsTotal {
        quantity
        palletsDataSevenDaysAgo {
            date
            quantity
        }
    }
  }
`

export const GET_SCHEDULES_TOTAL = gql`
  query GetSchedulesTotal {
    getSchedulesTotal {
        quantity
        schedulesDataSevenDaysAgo {
            date
            quantity
        }
    }
  }
`