import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Box from '../../Global/Box'
import OrderCol from '../../Global/OrderCol'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { AuthContext } from '../../../Auth/AuthContext'
import { useHistory, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import numeral from 'numeral'
import { BarCodeScannerV2 } from '../../Global/BarCodeScanner/BarCodeScannerV2'

export const Step2 = ({ data, lines, setData, nextStep, previousStep }) => {
  const [manualCode, setManualCode] = useState('')
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const { id: _id } = useParams()
  const MySwal = withReactContent(Swal)

  const handleOnScanner = (code) => {
    let codeGlobal = ''
    let check = false
    let quantity = 0
    let productLinesText = ''
    let productLines = []

    if (manualCode) {
      codeGlobal = manualCode
    } else if (code) {
      codeGlobal = code
    } else {
      return toastSweetAlert({
        mode: 'error',
        message: `Por favor ingrese un codigo de barras para continuar`,
      })
    }

    let findProduct = lines.filter((product) => {
      const { PurchasesLinesItemInfo } = product
      let sapItemBarcode = PurchasesLinesItemInfo.item_code_bar
      let res = sapItemBarcode === codeGlobal
      if (!res) {
        res = PurchasesLinesItemInfo.ItemReceipt.some(
          (SapBarcode) => SapBarcode.barcode === codeGlobal
        )
      }
      return res
    })

    if (findProduct.length < 1) {
      return toastSweetAlert({
        mode: 'error',
        message: `Producto no encontrado, \n Codigo de barras: ${codeGlobal}`,
      })
    }

    findProduct.map((product) => {
      check = { ...product }
      quantity += product.open_quantity

      productLines.push({
        id: product.id,
        quantity: numeral(product.open_quantity).format('0,0'),
      })
      return null
    })

    productLines.map((item, index) => {
      productLinesText += `Linea ${index + 1}: ${item.quantity}. `
    })

    check.productLines = {
      lines: productLines,
      productLinesText: `Total: ${numeral(quantity).format(
        '0,0'
      )}. ${productLinesText}`,
    }

    check.quantity = quantity

    return (
      setManualCode(''),
      setData({
        product: { ...check },
        photos: [],
        openQuantity: 0,
        issueQuantity: 0,
        issue: null,
        issueCustom: null,
      }),
      nextStep()
    )

    // OLD LOGIC
    // lines.forEach((product) => {
    //   if (product.item.item_code_bar === codeGlobal) {
    //     check = { ...product }
    //     quantity += product.quantity
    //     productLines.push({
    //       id: product.id,
    //       quantity: numeral(product.quantity).format('0,0'),
    //     })
    //   }
    // })

    // if (productLines.length > 1) {
    //   productLines.map((item, index) => {
    //     productLinesText += `Linea ${index + 1}: ${item.quantity}. `
    //   })
    // }
    // check.productLines = {
    //   lines: productLines,
    //   productLinesText: `Total: ${numeral(quantity).format(
    //     '0,0'
    //   )}. ${productLinesText}`,
    // }

    // if (check) {
    //   return (
    //     setManualCode(''),
    //     setData({
    //       product: { ...check },
    //       photos: [],
    //       openQuantity: 0,
    //       issueQuantity: 0,
    //       issue: null,
    //       issueCustom: null,
    //     }),
    //     nextStep()
    //   )
    // }
    // return toastSweetAlert({
    //   mode: 'error',
    //   message: `Producto no encontrado, \n Codigo de barras: ${codeGlobal}`,
    // })
  }

  const handleScanReturn = () => {
    // const queryString = window.location.search
    // const urlParams = new URLSearchParams(queryString)
    // console.log(urlParams.has('scan'))
    // urlParams.delete('scan')
    // console.log(urlParams.has('scan'))
    // setTimeout(200)
    // previousStep()
    return history.push(`/commodity/receipt/edit/${_id}`)
  }

  // useEffect(() => {
  //   if (manualCode.length === 0) {
  //     return;
  //   }
  //   if (user.role !== 1) {
  //     if (manualCode.length < 3) {
  //       setManualCode('')
  //       // return toastSweetAlert({
  //       //   mode: 'error',
  //       //   message: `Ingreso manual no es permitido`,
  //       // })
  //       console.log("Validacion");
  //     }
  //   }
  // }, [manualCode])

  return (
    <Box
      title="Escanear código de barras"
      content={
        <div className="row">
          <div className="col-12 text-center">
            <BarCodeScannerV2
              active={true}
              fullScreen={false}
              handleResult={handleOnScanner}
            />
          </div>
          <div className="col-12 inptu-manual-content">
            <input
              autoFocus
              value={manualCode}
              className="col-12 col-md-6 form-control rounded-2"
              onChange={({ target }) => setManualCode(target.value)}
            />
          </div>
          <OrderCol
            mediumColSize="12"
            content={
              <div className="col-12 actions-buttons-scan pt-5">
                <span
                  className="btn StandarModalCancelButtonLogOut"
                  onClick={handleScanReturn}
                >
                  {' '}
                  Cancelar{' '}
                </span>
                <button
                  type="submit"
                  className="btn StandarModalConfirmButtonLogOut"
                  onClick={() => handleOnScanner(false)}
                >
                  {' '}
                  Siguiente{' '}
                </button>
              </div>
            }
          />
          {user.role === 1 && (
            <div className="col-6">
              <label className="col-12 text-left">
                <b>Inserción manual</b>
              </label>
              <input
                value={manualCode}
                className="col-12 col-md-6 form-control rounded-2"
                onChange={({ target }) => setManualCode(target.value)}
              />
              <span
                className="btn btn-accept btn-sm my-2 mx-2"
                onClick={() => handleOnScanner(false)}
              >
                Siguiente
              </span>
            </div>
          )}
        </div>
      }
    />
  )
}
