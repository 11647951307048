import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CUSTOMERS } from '../../../graphql/Catalog/Telemarketing/Customers/customers'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import { useHistory } from 'react-router-dom'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'


export const TelemarketingOrders = () => {
  const history = useHistory()

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { loading, error, data } = useQuery(GET_CUSTOMERS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
  })

  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0);
  
  useEffect(() => {
    if (!loading) {
      if (error) toastSweetAlert({
        mode:'error',
        message:error.message,
      })
      
      let list = []
      data.getAllCustomers.rows.map((item) => {
        return list.push({
          id: item.id,
          Nombre: item.first_name,
          Apellidos: item.last_name,
          'Correo electrónico': item.email,
          Teléfono: item.phone,
        })
      })
      setDataToTable(list)
      setTotal(data.getAllCustomers.count)
    }
  }, [error, data])

  const handleDetails = (id) => {
    history.push(`/telemarketingOrderHistory/new`, {id: id});
  }

  return (
    <>
      {/* <ContentHeader
        title="Pedidos"
        breadcrumb="Pedidos"
        windowTitle="Pedidos"
      /> */}

      <LayoutTable
        data={dataToTable}
        title="Pedidos"
        exportImportButton={false}
        hideActions={false}
        handleButton={(e) => handleDetails(e)}
        handleButtonTitle="Agregar orden"
        requery={GET_CUSTOMERS}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        FilterBarPlaceholder= "Buscar"
      />
    </>
  )
}

export default TelemarketingOrders
