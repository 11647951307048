import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { useEffect, useState } from 'react'
import {
  GET_INFORMATION_ONE_PAYMENT_FOR_EDIT,
  UPDATE_INFORMATION_PAYMENT,
} from '../../../../../../graphql/Catalog/FinancesCounterCharges/generatepaymentsfinancescountercharges'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '../../../../../Global/Box'
import { yupResolver } from '@hookform/resolvers/yup'
import InputController from '../../../../../Global/InputController'
import moment from 'moment'
import { toastSweetAlertNotifications } from '../../../../../Helpers/ToastSweetAlertNotifications'
import OrderCol from '../../../../../Global/OrderCol'

const EditInformationPaymentRef = ({
  idPayment,
  consecutiveNumberPayment,
  refModal,
  refetch = async () => {},
}) => {
  const [datePayment, setDatePayment] = useState(null)
  const [infoPayment, setInfoPayment] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)

  const [getInformationPaymentForEdit] = useMutation(
    GET_INFORMATION_ONE_PAYMENT_FOR_EDIT
  )

  const [updateInformationPayment] = useMutation(UPDATE_INFORMATION_PAYMENT)

  const validationSchema = yup.object().shape({})

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const formattedNumber = (number) => {
    if (number === '') return
    let cleanNumber = number.replace(/[,]/g, '')
    cleanNumber = Number(cleanNumber)
    if (isNaN(cleanNumber)) {
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'Solo se permiten números',
        },
        setValue('payment_amount', '')
      )
    }
    let numberFormat = new Intl.NumberFormat('es-MX', {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cleanNumber)
    return numberFormat === '0'
      ? setValue('payment_amount', '')
      : setValue('payment_amount', numberFormat)
  }

  const formattedNumberForUpdate = (number) => {
    if (number === '') return
    let cleanNumber = number.replace(/[,]/g, '')
    let numberFormat = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cleanNumber)
    return numberFormat
  }

  useEffect(() => {
    if (idPayment) {
      getInformationPayment(idPayment)
    }
  }, [idPayment])

  const getInformationPayment = async (id) => {
    try {
      const { data } = await getInformationPaymentForEdit({
        variables: {
          getInformationPaymentForEditId: parseInt(id),
        },
        fetchPolicy: 'no-cache',
      })
      const dataOnePayment = data.getInformationPaymentForEdit

      const dataFormated = {
        payment_date: dataOnePayment.payment_date,
        payment_amount: formattedNumberForUpdate(dataOnePayment.payment_amount),
      }
      setInfoPayment(dataOnePayment)
      reset(dataFormated)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (datePayment) {
      handleChangeDate(datePayment)
    }
  }, [datePayment])

  const handleChangeDate = (date) => {
    let dateCurrent = moment(date, 'YYYY-MM-DD hh:mm:ss a').format('MM')

    let dateDataBase = moment(
      infoPayment?.payment_date,
      'YYYY-MM-DD hh:mm:ss a'
    ).format('MM')

    if (dateCurrent !== dateDataBase) {
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: `No puedes cambiar la fecha de pago, ya que la fecha seleccionada es distinto a otro mes, no coincide con el mes del pago registrado`,
        },
        setValue('payment_date', infoPayment?.payment_date)
      )
    }
  }

  const handleCloseModal = (e) => {
    refModal.current.click()
  }

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)

      const cleanNumber = data.payment_amount.replace(/[,]/g, '')

      const { data: dataUpdate } = await updateInformationPayment({
        variables: {
          consecutiveNumber: consecutiveNumberPayment,
          paymentAmount: cleanNumber,
          paymentDate: moment(
            data.payment_date,
            'YYYY-MM-DD hh:mm:ss a'
          ).format('YYYY-MM-DD'),
        },
      })

      if (dataUpdate.updateInformationPayment) {
        if (refetch) await refetch()

        return toastSweetAlertNotifications(
          {
            mode: 'ok',
            message: `Se actualizo la información del pago ${infoPayment?.consecutive_number_of_payments} correctamente`,
          },
          handleCloseModal(),
          setLoadingBtn(false)
        )
      }
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title={`Información del pago ${infoPayment?.consecutive_number_of_payments}
            `}
            btnSubmit
            btnSubmitText="Acutalizar información"
            btnSecondFunction={(e) => handleCloseModal(e)}
            withCard={false}
            content={
              <>
                <div className="col">
                  <div className="row">
                    {/* <div> */}
                    <div className="col-md-12 col-lg-12">
                      <OrderCol
                        title="Folio:"
                        content={
                          infoPayment
                            ? infoPayment?.consecutive_number_of_payments
                            : '---'
                        }
                        mediumColSize="12"
                      />
                    </div>
                    {/* </div> */}
                    <hr />
                    <div className="col-md-6">
                      <InputController
                        label="Fecha de pago"
                        name="payment_date"
                        control={control}
                        type="date"
                        changeAction={(e) => setDatePayment(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputController
                        label="Monto de pago"
                        name="payment_amount"
                        control={control}
                        changeAction={(e) => formattedNumber(e.target.value)}
                        debounceTime={700}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default EditInformationPaymentRef
