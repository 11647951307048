import { gql } from '@apollo/client'

export const GET_ALL_PICKING_ORDERS_STORE = gql`
  query GetAllPickingOrdersStore(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $date: Int
  ) {
    getAllPickingOrdersStore(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      date: $date
    ) {
      count
      rows {
        id
        order_id
        order_date
        status_id
        warehouse {
          name
        }
        paymentPicking {
          platform
        }
        method {
          name
        }
      }
    }
  }
`

export const GET_ONE_ORDER_PICKING_STORE = gql`
  query GetOneOrderPickingStore($idOrder: Int!) {
    getOneOrderPickingStore(idOrder: $idOrder) {
      id
      order_id
      total_price
      method {
        name
      }
      order_date
      paymentPicking {
        platform
      }
      shippingCompany {
        name
      }
      product_quantity
      shipping {
        first_name
        address_1
      }
      warehouse {
        name
      }
      shipping_price
      ordersWarehouseToOrdersPicking {
        id
        order_id
        rack_id
        picking_user_id
        type_id
        picked
        open
        is_active
        UserToOrder {
          name
          first_name
          last_name
        }
      }
      userToOrderStore {
        name
      }
    }
  }
`

export const GET_PRODUCTS_ORDER_PICKING_STORE_BY_ID_ORDER_WAREHOUSE = gql`
  query GetProductsForPickingOrdersStoreByidOrderWarehouse(
    $idOrderWarehouse: Int!
    $limit: Int
    $offset: Int
    $searchQuery: String
  ) {
    getProductsForPickingOrdersStoreByidOrderWarehouse(
      idOrderWarehouse: $idOrderWarehouse
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      count
      rows {
        id
        boxes
        quantity
        type
        picked
        is_active
        productToOrdersWarehouseProducts {
          name
          order_id
          quantity
          sku
        }
      }
    }
  }
`

export const ASSIGN_PICKER_TO_ORDER_PICKING_STORE = gql`
  mutation AssignPickerToOrderStore(
    $idOrderWarehouse: Int!
    $idUserPicker: Int!
  ) {
    assignPickerToOrderStore(
      idOrderWarehouse: $idOrderWarehouse
      idUserPicker: $idUserPicker
    )
  }
`
export const GET_TOTAL_PICKING_QUANTITY = gql`
  query GetAllPickedQuantity($idOrderWarehouse: Int!) {
    getAllPickedQuantity(idOrderWarehouse: $idOrderWarehouse) {
      total_bottles
      total_picked
      total_boxes
      total_bottles_by_boxes
    }
  }
`
