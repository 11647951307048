import { gql } from '@apollo/client'

export const GET_SAP_BUSSINESS_PARTNER_SELLER_BY_ID = gql`
  mutation GetSapBusinessPartnerSellerById($idBusinessPartner: Int) {
    getSapBusinessPartnerSellerById(idBusinessPartner: $idBusinessPartner) {
      id
      card_code
      card_name
      card_type
      group_code
      discount
      phone1
      phone2
      email
      lic_trad_number
    }
  }
`

export const GET_SAP_BUSSINESS_PARTNER = gql`
  query GetSapBusinessPartner($limit: Int, $offset: Int, $searchQuery: String) {
    getSapBusinessPartner(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      rows {
        id
        card_code
        card_name
        card_type
        group_code
        list_number
        group_number
        credit_line
        debit_line
        discount
        phone1
        phone2
        email
        lic_trad_number
        purchase_order {
          id
          business_partner_id
          document_number
        }
      }
      count
    }
  }
`

export const GET_SAP_BUSINESS_PARTNER_PENDING_ORDERS = gql`
  query GetSapBusinessPartnersPendingOrders(
    $limit: Int
    $searchQuery: String
    $offset: Int
  ) {
    getSapBusinessPartnersPendingOrders(
      limit: $limit
      searchQuery: $searchQuery
      offset: $offset
    ) {
      count
      rows {
        id
        business_partner_id
        business_partner {
          card_code
          card_name
          card_type
          phone1
          phone2
          email
          lic_trad_number
        }
      }
    }
  }
`
export const IMPORT_BUSINESS = gql`
  mutation ImportBusinessPartner($files: [Upload!]!) {
    importBusinessPartner(files: $files)
  }
`
