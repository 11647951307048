export const document_statuses_esp = {
  1: 'Abierto',
  2: 'Cerrado',
  3: 'Cancelado',
  4: 'Aprobado',
  5: 'Parcialmente aprobado',
  6: 'Inventario negativo',
  7: 'Error',
  8: 'Borrador',
  9: 'Procesado',
  10: 'En Transito',
  11: 'Reprocesado',
}
export const transfer_filter_by_status = {
  1: 'Abierto',
  2: 'Cerrado',
  3: 'Cancelado',
  4: 'Aprobado',
  5: 'Parcialmente aprobado',
  6: 'Inventario negativo',
  7: 'Error',
  9: 'Procesado',
  10: 'En Transito',
  11: 'Reprocesado',
}

export const order_statuses_esp = {
  1: 'Pendiente',
  2: 'Procesando',
  3: 'Facturacion',
  4: 'Envíos locales',
  5: 'Envíos nacionales',
  6: 'Surtiendo',
  7: 'En ruta',
  8: 'Por recolectar',
  9: 'Picking',
  10: 'Packing',
  11: 'Completado',
  12: 'Rechazado',
  13: 'Regresado',
}

export const docks_esp = {
  1: 'Puerta 1',
  2: 'Puerta 2',
  3: 'Puerta 3',
  4: 'Puerta 4',
}

export const status_transfers = {
  1: 'Pendiente',
  2: 'En picking',
  3: 'En validación',
  4: 'Completado',
  5: 'Listo para generar cita',
  6: 'Borrador',
}

export const marketing_documents_and_receipts = {
  O: 'Abierto',
  C: 'Cerrado',
  L: 'Cancelado',
  D: 'Borrado',
}
