import { gql } from '@apollo/client'

export const GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS = gql`
  query GetAllDocumentsToBillingConcepts(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $idDocument: Int
  ) {
    getAllDocumentsToBillingConcepts(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      idDocument: $idDocument
    ) {
      count
      rows {
        id
        sku
        description
        document_billing_id
        quantity
        sat_code
        subtotal
        tax
        tax_rate
        total
        unit_price
      }
    }
  }
`
