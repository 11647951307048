import { gql } from '@apollo/client'

export const GET_RACK_DETAILS = gql`
  mutation GetRackInfo($getRackInfoId: Int!) {
    getRackInfo(id: $getRackInfoId) {
      zone_id
      id
      capacity
      circuit
      reorder
      warehouse_id
      name
      code
    }
  }
`

export const GET_ALL_RACKS = gql`
  query GetAllRacks(
    $limit: Int
    $offset: Int
    $searchQuery: String
    $assignedCount: Boolean
    $cycleCounts: Boolean
    $inUse: Int
  ) {
    getAllRacks(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      assignedCount: $assignedCount
      cycleCounts: $cycleCounts
      inUse: $inUse
    ) {
      count
      rows {
        id
        name
        code
        capacity
        circuit
        reorder
        in_use
        warehouse_id
        SapWarehouse {
          warehouse_name
        }
        RackZone {
          name
        }
      }
    }
  }
`

export const GET_ALL_UNUSED_RACKS = gql`
  query GetAllUnusedRacks($zoneId: Int) {
    getAllUnusedRacks(zone_id: $zoneId) {
      id
      name
      code
      capacity
      reorder
      warehouse_id
      zone_id
    }
  }
`

export const GET_ONE_RACK = gql`
  query GetOneRackById($getOneRackByIdId: Int!) {
    getOneRackById(id: $getOneRackByIdId) {
      id
      name
      code
      circuit
      capacity
      reorder
      warehouse_id
      zone_id
    }
  }
`

export const DELETE_RACK = gql`
  mutation DeleteRack($deleteRackId: Int!) {
    deleteRack(id: $deleteRackId)
  }
`

export const CREATE_RACK = gql`
  mutation CreateRack($rack: RackInput!) {
    createRack(rack: $rack) {
      name
      code
      circuit
      capacity
      reorder
      warehouse_id
      zone_id
    }
  }
`

export const UPDATE_RACK = gql`
  mutation UpdateRack($updateRackId: Int!, $rack: RackInput!) {
    updateRack(id: $updateRackId, rack: $rack) {
      id
      name
      code
      circuit
      capacity
      reorder
      warehouse_id
      zone_id
    }
  }
`

export const GET_ALL_RACK_ZONES = gql`
  query GetAllRackZones {
    getAllRackZones {
      id
      name
    }
  }
`

export const GET_RACK_COUNT = gql`
  query GetRackCount {
    getRackCount
  }
`
export const GET_ALL_RACKS_NO_PAGINATION = gql`
  query GetAllRacksNoPagination {
    getAllRacksNoPagination {
      id
      name
    }
  }
`

export const GET_RACKS_COUNTING = gql`
  query GetRackCountingByIdScheduleOrRackId(
    $idSchedule: Int!
    $idRack: Int
    $limit: Int
    $offset: Int
    $searchQuery: String
    $filter: Int
  ) {
    getRackCountingByIdScheduleOrRackId(
      idSchedule: $idSchedule
      idRack: $idRack
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      filter: $filter
    ) {
      count
      rows {
        labels {
          id
          status
          rack_id
          user_id
          deviated
          deviated_count
          label_id
          item_code
          item_name
          rack_code
          rack_name
          user_name
          count_number
          quantity_counted
          expiration_date
          anada
        }
      }
    }
  }
`

export const GET_DETAILS_RACK = gql`
  query GetDetailsRack(
    $idRack: Int!
    $limit: Int!
    $offset: Int!
    $searchQuery: String
  ) {
    getDetailsRack(
      idRack: $idRack
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      count
      rows {
        image {
          src
          width
          height
        }
        sku
        name
        counting {
          first
          second
        }
        accountants {
          first_counting
          second_counting
        }
        status
        third_counting
        third_counting_accountant
      }
    }
  }
`

export const CREATE_RACK_BY_SERIE = gql`
  mutation CreateRacksBySerie(
    $warehouseId: Int!
    $codeInit: String!
    $codeEnd: String!
    $labelInit: String!
    $labelEnd: String!
  ) {
    createRacksBySerie(
      warehouseId: $warehouseId
      codeInit: $codeInit
      codeEnd: $codeEnd
      labelInit: $labelInit
      labelEnd: $labelEnd
    )
  }
`

export const GET_ALL_RACKS_ACTIVES = gql`
  query GetAllRacksActives(
    $limit: Int
    $offset: Int
    $searchQuery: String
    $warehouseId: Int
  ) {
    getAllRacksActives(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      warehouseId: $warehouseId
    ) {
      rows {
        id
        code
        name
      }
      count
    }
  }
`

export const WAREHOUSE_IS_STORE = gql`
  mutation WarehouseIsStore($warehouseId: Int!) {
    warehouseIsStore(warehouse_id: $warehouseId)
  }
`
export const EXPORT_ALL_RACKS = gql`
  mutation ExportAllRacks(
    $inUse: Int
    $searchQuery: String
    $cycleCounts: Boolean
    $assignedCount: Boolean
    $offset: Int
    $limit: Int
  ) {
    exportAllRacks(
      inUse: $inUse
      searchQuery: $searchQuery
      cycleCounts: $cycleCounts
      assignedCount: $assignedCount
      offset: $offset
      limit: $limit
    ) {
      id
      nombre
      codigo
      almacen
      zona
      en_uso
    }
  }
`
