import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LayoutLogged } from '../Components/Layout/LayoutLogged'
import { AuthContext } from '../Auth/AuthContext'
import { types } from '../Types/types'
import { useHistory } from 'react-router-dom'

export const PrivateRoute = ({ isAuth, component: Component, ...rest }) => {
  const { dispatch, user } = useContext(AuthContext)
  const history = useHistory()
  const [checkAccountant, setCheckAccountant] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      dispatch({
        type: types.logout,
      })
      return <Redirect to="/login" />
    }
  }, [])

  //redirect to counts
  useEffect(() => {
    if (checkAccountant && user.role === 19) {
      history.push('/counts')
      setCheckAccountant(false)
    }
  }, [checkAccountant])

  return (
    <Route
      {...rest}
      component={(props) =>
        isAuth ? (
          <LayoutLogged enableSidebar={user.role !== 19}>
            <Component {...props} />
          </LayoutLogged>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

PrivateRoute.prototype = {
  isAuth: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
}
