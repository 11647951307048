import { document_statuses_esp } from '../../Helpers/StatusesLanguages'

/**Status Box
 * Show a box with warning and ok status
 *
 * ----- Props ----
 * 1- max: Maximum number that will be compare with status
 * 2- status: Number that will be compare with max
 * 3- mode: If mode is on, display mode's texts instead max and status,
 * these are all differents mode that you will put on mod param:
 *  type INT:   1: Active
 *              2: Inactive
 */
const StatusBox = ({ max = 0, status = 0, mode = false }) => {
  // const modeText = {
  //     1: 'Abierto',
  //     2: 'Aprobado',
  //     3: 'Procesando',
  //     4: 'Cerrado'
  // }

  const statusBoxDefaultStyle = {
    1: 'statusBox-open',
    2: 'statusBox-closed',
    3: 'statusBox-cancelled',
    4: 'statusBox-approved',
    5: 'statusBox-partially-approved',
    6: 'statusBox-negative-inventory',
    7: 'statusBox-error',
    8: 'statusBox-draft',
    9: 'statusBox-processing',
    10: 'statusBox-draft',
    11: 'statusBox-partially-approved',
  }

  return (
    <>
      {mode && parseInt(mode) ? (
        <>
          <div className={`statusBox ${statusBoxDefaultStyle[parseInt(mode)]}`}>
            <span>{document_statuses_esp[parseInt(mode)]}</span>
          </div>
        </>
      ) : (
        <>
          {max <= 1000 && status <= 1000 && (
            <div
              className={`statusBox ${
                max === status ? 'statusBox-checked' : 'statusBox-pending'
              }`}
            >
              <span>
                Checked {status}/{max}
              </span>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default StatusBox
