import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import ContentHeader from '../../../../Layout/ContentHeader'
import {
  BILLING_PROCESS,
  CHANGE_TO_PICKING,
  GET_ORDER_BY_ID_STATUS,
} from '../../../../../graphql/Catalog/Orders/pendingOrders'
import { useQuery, useMutation } from '@apollo/client'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import OrderGeneral from '../../../../OrderGeneral'
import OrderDetailStore from '../../../../OrderDetailStore'
import OrderShippingDetails from '../../../../OrderShippingDetails'
import OrderButtons from '../../../../OrderButtons'
import OrderProducts from '../../../../OrderProducts'
import Timeline from '../../../../Global/Timeline/Timeline'
import InternalNotes from '../../../../Global/InternalNotes/InternalNotes'
import { RejectOrder } from '../../../../Helpers/RejectOrder'
import { CREATE_REASON } from '../../../../../graphql/Catalog/Reasons/reasons'
import { GET_COUNTERS_ORDERS } from '../../../../../graphql/Catalog/Orders/countOrders'
import { AuthContext } from '../../../../../Auth/AuthContext'
import Swal from 'sweetalert2'
import { GET_ALL_ISSUES } from '../../../../../graphql/Catalog/Issues/issues'

export const BillingOrdersNew = () => {
  const { id: _id, show } = useParams()
  const [storeId, setStoreId] = useState('')
  const [idOrder, setIdOrder] = useState('')
  const [dataOrder, setDataOrder] = useState([])
  const history = useHistory()
  const [storeFromDB, setStoreFromDB] = useState(false)
  // const [dataInvoice, setDataInvoice] = useState({
  //   num_doc: null,
  //   num_invoice: null,
  //   invoice: null,
  // })
  const [issuses, setIssuses] = useState({})
  const { user } = useContext(AuthContext)
  const [processing, setProcessing] = useState(false)

  const { data, loading, error } = useQuery(GET_ORDER_BY_ID_STATUS, {
    variables: {
      id: parseInt(_id),
      statusId: [3],
      typeId: null,
    },
  })

  /* GET ALL ISSUSES DATA */
  const {
    data: dataIssuses,
    loading: loadingIssuses,
    error: errorIssuses,
  } = useQuery(GET_ALL_ISSUES)

  const [createReason] = useMutation(CREATE_REASON, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })

  const [changeToPicking] = useMutation(CHANGE_TO_PICKING, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })

  const [billingProcess] = useMutation(BILLING_PROCESS, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })

  useEffect(() => {
    if (!loading) {
      if (error)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/dashboard')
        )

      const orderData = data.getOrderByIdAndStatus
      if (orderData.store?.name || orderData.warehouse?.name)
        setStoreFromDB(true)
      setStoreId(orderData.store_id)
      setIdOrder(orderData.order_id)
      setDataOrder(orderData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  /* SET DATA TO NEW OBJ */
  useEffect(() => {
    if (!loadingIssuses && !errorIssuses) {
      let list = {}
      dataIssuses.getAllIssusses.map((item) => {
        list = Object.assign(list, { [item.id]: item.name })
        return true
      })
      setIssuses(list)
    }
  }, [dataIssuses, loadingIssuses, errorIssuses])

  const onSubmit = async () => {
    // if (!dataInvoice.invoice) {
    //   return toastSweetAlert({
    //     mode: 'error',
    //     message: 'Debe subir la factura del pedido',
    //   })
    // }

    // if (dataInvoice.num_doc && isNaN(dataInvoice.num_doc)) {
    //   return toastSweetAlert({
    //     mode: 'error',
    //     message: 'El número del documento debe ser numérico',
    //   })
    // }

    try {
      setProcessing(true)
      const { data: processBilling } = await billingProcess({
        variables: {
          orderId: `${_id}`,
          // inputBilling: {
          //   num_doc: dataInvoice.num_doc ? parseInt(dataInvoice.num_doc) : null,
          //   invoice: dataInvoice.invoice,
          //   num_invoice: dataInvoice.num_invoice,
          // },
        },
      })
      if (processBilling.billingProcess) {
        // const { data: pickingData } = await changeToPicking({
        //   variables: {
        //     orderId: `${_id}`,
        //   },
        // })
        // if (pickingData.changeToPicking) {
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Pedido procesado',
          },
          history.goBack()
        )
        // }
      } else {
        setProcessing(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'Algo salio mal, vuelve a intentar',
        })
      }
    } catch (error) {
      setProcessing(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleStoreChange = (e) => {
    setStoreId(e.value)
  }

  moment.locale('es') //Change dates languages to spanish

  return (
    <>
      <ContentHeader
        title="Facturar pedido"
        breadcrumb="Facturación"
        windowTitle={`${_id ? 'Editar' : 'Agregar'} pedido `}
      />
      <div className={`row ${processing ? 'processing-file' : ''}`}>
        <div className="col-8">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles</h3>
                </div>
                <div className="card-body">
                  <OrderGeneral
                    orderDate={moment(dataOrder?.order_date).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                    orderId={dataOrder?.order_id}
                    paymentId={dataOrder?.payment?.payment_id}
                    platform={dataOrder?.platform?.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de pago</h3>
                </div>
                <div className="card-body">
                  <OrderDetailStore
                    orderType={dataOrder?.type?.name}
                    orderStore={
                      dataOrder?.store?.name || dataOrder?.warehouse?.name
                    }
                    orderPaymentPlatform={dataOrder?.payment?.platform}
                    orderMethod={dataOrder?.method?.name}
                    withStore={storeFromDB}
                    totalPrice={dataOrder?.total_price}
                    shippingPrice={dataOrder?.shipping_price}
                    handleStoreChange={handleStoreChange}
                    id_store={storeId}
                    id_shippingCompany={dataOrder?.shippingCompany?.id}
                    shippingCompanyName={dataOrder?.shippingCompany?.name}
                    status={dataOrder?.status?.id}
                    show={show}
                    shippingReceipt={dataOrder?.shipping?.receipt?.url}
                    // setBillingForm={setDataInvoice}
                    // billingForm={dataInvoice}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Desglose de productos</h3>
                </div>
                <div className="card-body">
                  <OrderProducts
                    productsArray={dataOrder?.products}
                    totalQuantity={dataOrder?.product_quantity}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de envío</h3>
                </div>
                <div className="card-body">
                  <OrderShippingDetails
                    city={dataOrder?.shipping?.city}
                    address={dataOrder?.shipping?.address_1}
                    postcode={dataOrder?.shipping?.postcode}
                    email={dataOrder?.shipping?.email}
                    phone={dataOrder?.shipping?.phone}
                    firstName={dataOrder?.shipping?.first_name}
                    lastName={dataOrder?.shipping?.last_name}
                    state={dataOrder?.shipping?.state}
                  />
                </div>
              </div>
            </div>

            {/* {!show && (
              <OrderButtons
                oneButton={true}
                onProcess={printSomething}
                processBtn={'Imprimir'}
              />
            )} */}
            {!show ? (
              <OrderButtons
                goBack
                cancelBtn={'Rechazar'}
                onCancel={async () =>
                  RejectOrder(
                    issuses,
                    user.idUser,
                    createReason,
                    idOrder,
                    history
                  )
                }
                processBtn={'Facturar'}
                onProcess={onSubmit}
              />
            ) : (
              <OrderButtons
                oneButton={true}
                onProcess={() => history.goBack()}
                firstButtonGray={true}
                processBtn="Volver"
              />
            )}
          </div>
        </div>

        {/* TIMELINE & INTERNAL NOTES */}
        <div className="col-4">
          <Timeline idOrder={idOrder} />
          <InternalNotes idOrder={idOrder} />
        </div>
      </div>
    </>
  )
}

export default BillingOrdersNew
