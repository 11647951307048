import { gql } from '@apollo/client'

export const GET_SAP_PARTNER_GROUP = gql`
query GetSapPartnerGroup($limit: Int, $offset: Int) {
  getSapPartnerGroup(limit: $limit, offset: $offset) {
    id
    group_name
    price_list
    group_type
    group_code
  }
}
`