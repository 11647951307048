import React, { useEffect, useState } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import { useQuery } from '@apollo/client'
import { GET_ONE_POSITION_ORDER } from '../../../graphql/Catalog/PositionOrders/positionOrders'
import { useHistory, useParams } from 'react-router-dom'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import moment from 'moment'
import LayoutTable from '../../Global/LayoutTable'
import OrderCol from '../../Global/OrderCol'
import { document_statuses_esp } from '../../Helpers/StatusesLanguages'
import OrderButtons from '../../OrderButtons'

export const TransfersStoresNew = () => {
  const { id: _id } = useParams()
  const [data, setData] = useState(false)
  const [products, setProducts] = useState([])

  const history = useHistory()

  const {
    data: dataPositionOrder,
    loading: loadingPositionOrder,
    error: errorPositionOrder,
  } = useQuery(GET_ONE_POSITION_ORDER, {
    variables: {
      getOnePositionOrderId: parseInt(_id),
    },
  })

  useEffect(() => {
    if (_id) {
      if (!loadingPositionOrder) {
        if (errorPositionOrder) {
          return toastSweetAlert(
            {
              mode: 'error',
              message: errorPositionOrder.message,
            },
            history.push('/position/orders')
          )
        }

        const data = dataPositionOrder?.getOnePositionOrder
        const status =
          data?.is_open === true && data?.user_id !== null
            ? 'Completado'
            : data?.is_open === false && data?.user_id !== null
            ? 'En proceso'
            : data?.user_id === null && 'Pendiente'
        let list = {
          // date: moment(data?.creation_date).format('DD/MMMM/YYYY'),
          date: data?.creation_date,
          status: status,
          rack: data?.RackPosition?.name,
          totalProducts: data?.total_products,
          type: data?.type_id === 1 ? 'Rellenado' : 'Posicionado',
        }

        const products = data?.ProductPositionOrders?.map((product) => {
          return {
            id: product?.id,
            sku: product?.item_code,
            Nombre: product?.SapItem?.item_name,
            'Rack origen':
              // STAGGINRACK HARDCODED
              product?.RackPosition?.name || product?.rack_id === 4340
                ? 'Staging'
                : product?.rack_id,
            Pendiente: product?.quantity,
            Tomado: product?.took,
          }
        })
        list.products = products
        setData(list)
        setProducts(products)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataPositionOrder, errorPositionOrder, loadingPositionOrder])

  return (
    <>
      <ContentHeader
        title="Detalles de la orden de posición"
        breadcrumb="Posición orden"
        windowTitle="Posición orden"
      />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Detalles</h3>
            </div>
            <div className="card-body col-12 p-5">
              <div className="box-separator">
                <div className="row">
                  <OrderCol
                    title="Fecha creación"
                    content={data?.date}
                    mediumColSize="2"
                  />
                  <OrderCol
                    title="Estatus"
                    content={data?.status}
                    mediumColSize="2"
                  />
                  <OrderCol
                    title="Rack destino"
                    content={data?.rack}
                    mediumColSize="2"
                  />
                  <OrderCol
                    title="Total a rellenar"
                    content={data?.totalProducts}
                    mediumColSize="3"
                  />
                  <OrderCol
                    title="Tipo"
                    content={data?.type}
                    mediumColSize="3"
                  />
                </div>
              </div>
              <div className="box-separator">
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <h5 className="header-text-details">Productos</h5>
                    <LayoutTable
                      hideId
                      data={products}
                      paginationServer={false}
                      hideActions={true}
                      hideFilterBar
                    />
                  </div>
                </div>
              </div>
              <OrderButtons
                oneButton={true}
                onProcess={() => history.goBack()}
                processBtn="Regresar"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransfersStoresNew
