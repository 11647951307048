import { gql } from "@apollo/client";

export const GET_JOB_BY_ID =gql`
query GetJob($jobId: Int) {
  getJob(JobId: $jobId) {
    id
    stateId
    stateName
    invocationData
    arguments
    createdAt
    expireAt
    Detail {
      namespace
      method
    }
  }
}`

export const GET_ALL_JOBS = gql`
query GetAllJobs($searchQuery: String, $limit: Int, $offset: Int) {
  getAllJobs(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
    count
    rows {
      stateName
      stateId
      invocationData
      id
      expireAt
      createdAt
      arguments
      Detail {
        namespace
        method
      }
    }
  }
}
`
export const GET_ALL_JOBS_BY_STATE = gql`
query GetAllJobsByStateName($stateName: String, $limit: Int, $offset: Int) {
  getAllJobsByStateName(stateName: $stateName, limit: $limit, offset: $offset) {
    rows {
      id
      stateId
      stateName
      invocationData
      arguments
      createdAt
      expireAt
      Detail {
        namespace
        method
      }
    }
    count
  }
}`

export const GET_ALL_JOB_PARAMETERS = gql`
query GetAllGetJobParameters($jobId: Int) {
  getAllGetJobParameters(JobId: $jobId) {
    rows {
      jobId
      name
      value
    }
    count
  }
}
`

export const GET_ALL_JOB_STATES = gql`
query GetAllJobStates($jobId: Int) {
  getAllJobStates(JobId: $jobId) {
    rows {
      id
      jobId
      name
      reason
      createdAt
      data
    }
    count
  }
}
`

export const GET_ALL_COUNTED_REPORT_TRANSACTIONS = gql`
query getAllCountedReportTransactions{
  getAllCountedReportTransactions{
    countFailed,
    countEnqueued,
    countProcessing,
    countSucceeded,
    countScheduled,
    countDeleted
  }
}`