import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { GET_BILLS } from '../../../../graphql/Catalog/Notes/bills'
import {
  GET_ONE_NOTE,
  MOVE_NOTE_FINANCES_TO_CONTABILITY,
} from '../../../../graphql/Catalog/Notes/notes'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import LayoutTable from '../../../Global/LayoutTable'
import {
  DELETE_PAYMENTS_FINANCES_COUNTER_CHARGES,
  GET_ALL_PAYMENTS_BY_CONSECUTIVE_NUMBER,
  GET_ALL_PAYMENTS_BY_ID_FINANCES_COUNTER_CHARGES,
} from '../../../../graphql/Catalog/FinancesCounterCharges/generatepaymentsfinancescountercharges'
import { toOrdinal } from '../../../Helpers/ConvertNumbersToOrdinals'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import moment from 'moment'
import { AuthContext } from '../../../../Auth/AuthContext'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'
import ModalContent from '../../../Global/ModalContent'
import EditInformationPaymentRef from './GeneratePaymentsFinances/EditInformationPayment/EditInformationPayment'

export const FinancesCounterChargesNew = () => {
  const { id: _id } = useParams()
  const modalEditInformationPaymentRef = useRef()
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const [infoNote, setInfoNote] = useState([])
  const [dataBillTable, setDataBillTable] = useState([])
  const [dataPayments, setDataPayments] = useState([])
  const [consecutives, setConsecutives] = useState([])
  const [infoBill, setInfoBill] = useState([])
  const [deletePayment, setDeletePayment] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [consecutiveNumberPayment, setConsecutiveNumberPayment] = useState(null)
  const [idPayment, setIdPayment] = useState(null)
  const [total, setTotal] = useState(0)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    idNote: parseInt(_id),
  })

  // const {
  //   loading: loadingAllBillsByIdNote,
  //   error: errorAllBillsByIdNote,
  //   data: dataAllBillsByIdNote,
  //   refetch: refetchAllBillsByIdNote,
  // } = useQuery(GET_BILLS, {
  //   variables: {
  //     searchQuery: pagePagination.searchQuery,
  //     limit: pagePagination.limit,
  //     offset: pagePagination.offset,
  //     idNote: pagePagination.idNote,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const {
    loading: loadingGetOneNote,
    error: errorGetOneNote,
    data: dataGetOneNote,
  } = useQuery(GET_ONE_NOTE, {
    variables: {
      getOneNoteId: parseInt(_id),
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingAllPaymentsByIdNote,
    error: errorAllPaymentsByIdNote,
    data: dataAllPaymentsByIdNote,
    refetch: refetchAllPaymentsByIdNote,
  } = useQuery(GET_ALL_PAYMENTS_BY_ID_FINANCES_COUNTER_CHARGES, {
    variables: {
      getAllPaymentesByIdFinancesCounterChargesId: parseInt(_id),
    },
    fetchPolicy: 'no-cache',
  })

  const [moveNoteFinancesToContability] = useMutation(
    MOVE_NOTE_FINANCES_TO_CONTABILITY
  )

  const [deletePaymentFinancesCounterCharges] = useMutation(
    DELETE_PAYMENTS_FINANCES_COUNTER_CHARGES
  )

  const [getAllPaymentsByConsecutiveNumber] = useMutation(
    GET_ALL_PAYMENTS_BY_CONSECUTIVE_NUMBER
  )

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const validationForm = yup.object().shape({
    invoice_input: yup.string(),
  })

  const { methods, control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationForm),
  })

  useEffect(() => {
    if (!loadingGetOneNote) {
      if (errorGetOneNote)
        return toastSweetAlert({
          mode: 'error',
          message: errorGetOneNote.message,
        })

      const dataBills = dataGetOneNote?.getOneNote?.bills
      let list = []
      const partner = dataBills.map((item) => item.id_partner)[0]

      const paymentsInfo = dataPayments?.rows
      let active
      let sumPayments = 0

      if (paymentsInfo?.length > 0) {
        let sum = 0
        for (let payment of paymentsInfo) {
          sum += parseFloat(payment?.payment_amount)
        }
        sumPayments = sum.toFixed(2)
      }

      partner !== 0
        ? dataBills.map((item) => {
            list.push({
              id: item.id,
              Factura: item.invoice_bill,
              fecha: moment(item.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              monto: formmatterCurrency(item.amount_bill),
              'monto pagado': formmatterCurrency(sumPayments),
              estatus: item.full_payed ? 'Pagada' : 'No pagada',
            })
          })
        : dataBills.map((item) => {
            list.push({
              id: item.id,
              concepto: item?.conceptBills?.name,
              beneficiario: item.provider_name_nm,
              Factura: item.invoice_bill,
              fecha: moment(item.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              monto: formmatterCurrency(item.amount_bill),
              descripcion: item.description,
              'monto pagado': formmatterCurrency(sumPayments),
              estatus: item.full_payed ? 'Pagada' : 'No pagada',
            })
          })
      setDataBillTable(list)
      setInfoBill(list)
      // setTotal(dataAllBillsByIdNote.getBills.count)

      setInfoNote(dataGetOneNote?.getOneNote)
      // console.log(dataGetOneNote?.getOneNote)
    }
  }, [loadingGetOneNote, errorGetOneNote, dataGetOneNote])

  useEffect(() => {
    if (!loadingAllPaymentsByIdNote) {
      if (errorAllPaymentsByIdNote)
        return toastSweetAlert({
          mode: 'error',
          message: errorAllPaymentsByIdNote.message,
        })
      setDataPayments(
        dataAllPaymentsByIdNote?.getAllPaymentesByIdFinancesCounterCharges
      )
    }
  }, [
    loadingAllPaymentsByIdNote,
    errorAllPaymentsByIdNote,
    dataAllPaymentsByIdNote,
  ])

  // useEffect(() => {
  //   if (!loadingAllBillsByIdNote) {
  //     if (errorAllBillsByIdNote)
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: errorAllBillsByIdNote.message,
  //       })

  //   }
  // }, [loadingAllBillsByIdNote, errorAllBillsByIdNote, dataAllBillsByIdNote])

  // useEffect(() => {
  //   if (deletePayment) {
  //     console.log('refetchAllPaymentsByIdNote')
  //     console.log('data', dataAllPaymentsByIdNote)
  //     setDeletePayment(false)
  //   }
  // }, [deletePayment])

  const deletePaymentFinances = async (consecutiveNumber, index, array) => {
    try {
      setLoadingBtn(true)
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message: '¿Estas seguro de eliminar el pago?',
        title: 'Eliminar pago',
        icon: 'warning',
      })

      const findPayment = array?.rows?.filter((item, i) => i !== index)
      if (result.isConfirmed) {
        await deletePaymentFinancesCounterCharges({
          variables: {
            consecutiveNumber,
          },
          fetchPolicy: 'no-cache',
        })
        toastSweetAlertNotifications({
          mode: 'ok',
          message: 'El pago se elimino correctamente',
        })
        await refetchAllPaymentsByIdNote()
        setDataPayments(findPayment)
        setDeletePayment(true)
        setLoadingBtn(false)
      } else {
        setLoadingBtn(false)
      }
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  const handleToogleNotes = async (consecutiveNumber, idPayment) => {
    try {
      const { data } = await getAllPaymentsByConsecutiveNumber({
        variables: {
          consecutiveNumber: consecutiveNumber,
        },
      })
      let list = []
      for (let dataPayments of data?.getAllPaymentsByConsecutiveNumber) {
        dataPayments?.paymentsFinancesToNotes?.map((item) => {
          return list.push(`${item?.consecutive_number}, `)
        })
      }
      setConsecutives({
        ...consecutives,
        [idPayment]: list,
      })
    } catch (e) {
      console.log(e)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  const handleEditPaymentInfromation = (paymentId, paymentConsecutive) => {
    setConsecutiveNumberPayment(paymentConsecutive)
    setIdPayment(paymentId)
    document.getElementById('spanModalEditInformationPayment').click()
  }

  return (
    <div className={`${loadingBtn ? 'processing-file' : ''}`}>
      <ContentHeader
        windowTitle={`Detalles ${
          infoNote?.id_partner === 0 ? 'de la nota manual' : 'del contrarecibo'
        }`}
        title={`Detalles ${
          infoNote?.id_partner === 0 ? 'de la nota manual' : 'del contrarecibo'
        }`}
      />
      {/* <FormProvider {...methods}>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        > */}
      <Box
        // title="Detalles Control de pagos"
        btnRedPath="/financescountercharges/financescountercharges"
        btnRedTxt="Atras"
        // btnSubmit
        // btnSubmitText="Mover a contabilidad"
        content={
          <>
            <div className="col-12">
              {infoNote && (
                <div className="boxQuotesProvider">
                  <div className="row">
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                      <h4>
                        Proveedor: &nbsp;
                        <span className="resultData">
                          {infoNote?.partnerNotes?.provider_name
                            ? infoNote?.partnerNotes?.provider_name
                            : infoBill[0]?.beneficiario}
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <div className="boxItem">
                        <h4>
                          RFC: &nbsp;
                          <span className="resultData">
                            {infoNote?.partnerNotes?.provider_rfc
                              ? infoNote?.partnerNotes?.provider_rfc
                              : 'XAXX010101000'}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <h4>
                        Consecutivo: &nbsp;
                        <span className="resultData">
                          {infoNote?.consecutive_number}
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <h4>
                        Fecha de Sello: &nbsp;
                        <span className="resultData">
                          {infoNote?.date_stamp
                            ? moment(infoNote?.date_stamp, 'YYYY-MM-DD').format(
                                'DD/MM/YYYY'
                              )
                            : 'Aun no se registra información'}
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <h4>
                        Fecha de Pago: &nbsp;
                        <span className="resultData">
                          {infoNote?.date_programmed_to_pay
                            ? moment(
                                infoNote?.date_programmed_to_pay,
                                'YYYY-MM-DD'
                              ).format('DD/MM/YYYY')
                            : 'Aun no se registra información'}
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <h4>
                        Monto programado: &nbsp;
                        <span className="resultData">
                          {infoNote?.amount_programmed_to_pay_note
                            ? formmatterCurrency(
                                infoNote?.amount_programmed_to_pay_note
                              )
                            : 'Aun no se registra información'}
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3 col-sm-12 col-md-12 col-lg-12">
                      <h4>
                        Estatus: &nbsp;
                        <span className="resultData">
                          {infoNote?.status_note === 1
                            ? 'Pendiente'
                            : infoNote?.status_note === 2
                            ? 'Recibida'
                            : infoNote?.status_note === 3
                            ? 'Recibida y programada'
                            : infoNote?.status_note === 4
                            ? 'Pago parcial'
                            : infoNote?.status_note === 5
                            ? 'Pago con ajuste'
                            : infoNote?.status_note === 6
                            ? 'Pago completo'
                            : infoNote?.status_note === 7
                            ? 'Pago contabilidad'
                            : infoNote?.status_note === 8
                            ? 'Entregado a contabilidad'
                            : 'Aun no se registra información'}
                        </span>
                      </h4>
                    </div>
                    {user.store === null && (
                      <div className="col-12">
                        <div className="row">
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <h4>
                              Usuario que registro: &nbsp;
                              <span className="resultData">
                                {infoNote?.userRegisterNotes?.name
                                  ? infoNote?.userRegisterNotes?.name
                                  : 'Información no registrada'}
                              </span>
                            </h4>
                          </div>
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <h4>
                              Usuario que modifico: &nbsp;
                              <span className="resultData">
                                {infoNote?.userUpdateNotes?.name
                                  ? infoNote?.userUpdateNotes?.name
                                  : 'Información no registrada'}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="col-12">
              {/* <div className="col"> */}
              {/* <div className="col-lg-12"> */}
              {dataPayments?.rows?.map((item, index) => (
                <div
                  key={item?.id}
                  style={{ marginLeft: '60px' }}
                  className="col-md-12 col-lg-10 display-info-payments-finances"
                >
                  <h4 className="title-display-info-payments-finances">
                    {toOrdinal(index + 1).toUpperCase() + ' PAGO'}
                  </h4>
                  <div>
                    <div
                      style={{ marginTop: '10px' }}
                      className="btn-add-header"
                    >
                      <span
                        style={{
                          cursor: 'pointer',
                          float: 'right',
                        }}
                        className="btn btn-sm btn-outline-danger fas fa-trash-alt"
                        onClick={() =>
                          deletePaymentFinances(
                            item?.consecutive_number_of_payments,
                            index,
                            dataPayments
                          )
                        }
                      ></span>
                      <span
                        style={{
                          cursor: 'pointer',
                          float: 'right',
                          border: '1px solid #000',
                          marginRight: '10px',
                          ':hover': '#fff',
                        }}
                        className="btn btn-sm btn-actions-bulk-counter-charges fas fa-edit"
                        onClick={() =>
                          handleEditPaymentInfromation(
                            item?.id,
                            item?.consecutive_number_of_payments
                          )
                        }
                      ></span>
                    </div>
                    <br />
                    <table id="" className="">
                      <thead>
                        <tr style={{ marginLeft: '100px' }}>
                          <th style={{ width: '300px' }}>Folio &nbsp;</th>
                          <th style={{ width: '300px' }}>Banco</th>
                          <th style={{ width: '200px' }}>Monto Pagado</th>
                          <th style={{ width: '150px' }}>Fecha</th>
                          <th style={{ width: '200px' }}>Comentarios</th>
                          {/* <th>Borrar</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item?.consecutive_number_of_payments}&nbsp;</td>
                          <td>
                            {item?.paymentsFinancesToBanks?.bank_name}&nbsp;
                          </td>
                          <td>
                            {formmatterCurrency(item?.payment_amount)}&nbsp;
                          </td>
                          <td>
                            {moment(item?.payment_date, 'YYYY-MM-DD').format(
                              'DD/MM/YYYY'
                            )}
                          </td>
                          <td style={{ alignContent: 'center' }}>
                            {item?.comments
                              ? item?.comments
                              : 'Sin comentarios'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <span
                      style={{ cursor: 'pointer', color: 'black' }}
                      className="btn btn-sm fas fa-chevron-circle-down"
                      aria-expanded="false"
                      data-toggle="collapse"
                      data-target={`#collapse${item?.id}`}
                      aria-controls={`collapse${item?.id}`}
                      onClick={(e) =>
                        handleToogleNotes(
                          item?.consecutive_number_of_payments,
                          item?.id
                        )
                      }
                    >
                      <i className="fas fa-chevron-circle-down"></i>
                    </span>

                    <div
                      className="collapse"
                      id={`collapse${item?.id}`}
                      key={index}
                    >
                      <h6>Consecutivos:</h6>
                      {/* {consecutives[item?.id]?.map((consecutive, index) => ( */}
                      <div style={{ marginLeft: '20px' }}>
                        {consecutives[item?.id]}
                      </div>
                      {/* ))} */}
                    </div>
                  </div>
                </div>
              ))}
              {/* </div> */}
            </div>

            <LayoutTable
              data={dataBillTable}
              // totalCount={total}
              // loadingData={}
              withCard={false}
              hideId
              // setPagePagination={setPagePagination}
              // pagePagination={pagePagination}
              exportImportButtons={false}
              paginationServer={false}
              hideFilterBar
              hideActions
            />
          </>
        }
      />

      <ModalContent
        idModal="modalEditInformationPayment"
        title="Editar información de pago"
        size="normal"
        content={
          <EditInformationPaymentRef
            idPayment={idPayment}
            consecutiveNumberPayment={consecutiveNumberPayment}
            refModal={modalEditInformationPaymentRef}
            refetch={refetchAllPaymentsByIdNote}
          />
        }
      />

      <span
        id="spanModalEditInformationPayment"
        ref={modalEditInformationPaymentRef}
        className="btn btn-sm invisible"
        data-toggle="modal"
        data-target="#modalEditInformationPayment"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
        // onClick={handleProgramPaymentsForButton}
      ></span>

      {/* </form>
      </FormProvider> */}
    </div>
  )
}

export default FinancesCounterChargesNew
