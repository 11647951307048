import moment from 'moment'
import { useEffect } from 'react'

const ProvidersQuotesTable = ({
  id_Order,
  data,
  ordersReceived,
  setOrdersReceived,
  ordersReceivedStep1Hook,
  onlyShow,
  setPurchasesOrdersId,
}) => {
  const handleClick = (idCheck, idExtra, idOrder, purchasesOrderId, sku) => {
    if (document.getElementById(idCheck).checked) {
      setOrdersReceived([
        ...ordersReceived,
        {
          purcharse_order_id: purchasesOrderId,
          id_order: idOrder,
          sku: sku,
        },
      ])
    } else {
      /* Delete order with idOrder */
      let newList = ordersReceived.filter((item) => item.id_order !== idOrder)
      setOrdersReceived(newList)
    }
    // document.getElementById(idCheck).click();
  }

  const handleDeleteOrder = (id) => {
    const list = ordersReceived.filter(
      (order) => order.purcharse_order_id !== id
    )
    setPurchasesOrdersId(list)
  }

  const showTable = () => {
    var currencyFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })
    return (
      <>
        <div className="boxQuotesProvider">
          <div className="row">
            {data[0] && data[0].purcharse_comments && (
              <div className="col-sm-12 col-md-10 col-lg-10">
                <div className="boxItem">
                  <h4>
                    Comentarios:{' '}
                    <span className="resultData">
                      {/* {data[0].purchase_number_at_card} /{' '} */}
                      {data[0].purcharse_comments}
                    </span>{' '}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total productos:{' '}
                    <span className="resultData">{data.length}</span>{' '}
                  </h4>
                </div>
              </div>
            )}
            {data[0] && data[0].purcharse_document_date && (
              <div className="col-sm-12 col-md-2 col-lg-2">
                <div className="boxItem">
                  <h4 style={{ textAlign: 'right' }}>
                    <span className="resultData">
                      {moment(data[0].purcharse_document_date).format(
                        'DD/MM/YYYY'
                      )}
                    </span>{' '}
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="providersTableBox">
          {/* <button
            type="button"
            className="btn btn-danger btn-sm float-right"
            onClick={() => handleDeleteOrder(id_Order)}
          >
            <i className="fas fas fa-trash"></i>
          </button> */}
          <table
            id="providersTable"
            className="table table-striped table-bordered nowrap"
          >
            <thead>
              <tr>
                <th>SKU</th>
                <th>Producto</th>
                <th>Cantidad</th>
                {!onlyShow && <th className="text-center">Seleccionar</th>}
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr
                  key={item.id + '-keyTable'}
                  className={!item.all_correct ? 'rowError' : 'rowCorrect'}
                >
                  <td>{item.item_code ? item.item_code : ''}</td>
                  <td>{item.item_name ? item.item_name : ''}</td>
                  <td>{item.quantity ? item.quantity : ''}</td>
                  {item.all_correct && !onlyShow && (
                    <>
                      <td>
                        <input
                          id={item.id + '-chck'}
                          type="checkbox"
                          checked={true}
                          disabled={true}
                          onClick={() =>
                            handleClick(
                              item.id + '-chck',
                              item.id + '-extraCamp',
                              item.id,
                              item.purcharse_order_id,
                              item.item_code
                            )
                          }
                        />
                      </td>
                    </>
                  )}
                  {!item.all_correct && (
                    <>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return <>{data && ordersReceivedStep1Hook && showTable()}</>
}

export default ProvidersQuotesTable
