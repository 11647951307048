import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'

import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import Box from '../Global/Box'
import ContentHeader from '../Layout/ContentHeader'
import InputController from '../Global/InputController'

// apollo
import { GET_ONE_FLOW_TRANSFER, CREATE_TRANSFER_FLOW, UPDATE_TRANSFER_FLOW } from '../../graphql/Catalog/Definitions/FlowForTransfers'
import { GET_STORES } from '../../graphql/Catalog/Stores/stores'
import { GET_SAP_WAREHOUSES_MUTATION } from '../../graphql/Catalog/SAP/sapWarehouses'

export const TransferConfigurationNew = () => {
    const { id: _id, show } = useParams()
    const history = useHistory()
    const [listOfStores, setListOfStores] = useState([])
    const [listOfWarehouses, setListOfWarehouses] = useState([])
    const [onProcess, setOnProcess] = useState(false)
    const schema = yup
        .object()
        .shape({
            storeID: yup.string().required('La sucursal es obligatoria'),
            trsWhsCode: yup.string().required('El almacén de tránsito es obligatorio')
                .test("is-greater", "El almacén de tránsito no puede ser igual al almacén de destino o almacén de origen", function (value) {
                    const { fromWhsCode, toWhsCode, trsWhsCode } = this.parent;
                    return (fromWhsCode != toWhsCode && trsWhsCode != fromWhsCode && trsWhsCode != toWhsCode)
                }),
            toWhsCode: yup.string().required('El almacén de destino es obligatorio')
                .test("is-greater", "El almacén de origen no puede ser igual al almacén de tránsito o almacén destino", function (value) {
                    const { fromWhsCode, toWhsCode } = this.parent;
                    return (fromWhsCode != toWhsCode)
                }),
            inputName: yup.string().required('La descripción del flujo es obligatorio'),
            fromWhsCode: yup.string().required('El almacén de origen es obligatorio')
                .test("is-greater", "El almacén de destino no puede ser igual al almacén de tránsito o al almacén de origen", function (value) {
                    const { fromWhsCode, toWhsCode } = this.parent;
                    return (fromWhsCode != toWhsCode)
                }),

        })
        .required()
    const {
        methods,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        getValues,
    } = useForm({ resolver: yupResolver(schema) })

    const {
        data: data,
        loading: loading,
        error: error,
    } = useQuery(GET_ONE_FLOW_TRANSFER, {
        variables: {
            getOneFlowForTransfersId: parseInt(_id),
        },
        fetchPolicy: 'no-cache',
    })

    const {
        data: dataStores,
        loading: loadingStores,
        error: errorStores,
    } = useQuery(GET_STORES, {
        variables: {
            searchQuery: null,
            limit: null,
            offset: null
        },
        fetchPolicy: 'no-cache',
    })
    const [getWarehouses] = useMutation(GET_SAP_WAREHOUSES_MUTATION)
    const [createFlow] = useMutation(CREATE_TRANSFER_FLOW)
    const [updateFlow] = useMutation(UPDATE_TRANSFER_FLOW)

    // OnLoad data
    useEffect(async () => {
        if (!loading) {
            if (error)
                return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })
        }
        try {
            if (!_id) {
                // Create

            } else {
                // Update
                if (data) {
                    const definition = data.getOneFlowForTransfers

                    setValue('storeID', definition.store_id)

                    setValue('fromWhsCode', definition.OriginWhsSapWhsTransfers.warehouse_code)
                    setValue('toWhsCode', definition.DestinationWhsSapWhsTransfers.warehouse_code)
                    setValue('trsWhsCode', definition.TransferWhsSapWhsTransfers.warehouse_code)

                    setValue('inputName', definition.name)
                    setValue('inputSO1', definition.branch_code)


                }
            }
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }, [_id, data, loading, error])
    // Load Options
    useEffect(async () => {
        if (!loadingStores) {
            if (errorStores)
                return toastSweetAlert({
                    mode: 'error',
                    message: errorStores.message,
                })
        }
        try {
            if (dataStores) {
                const optionsStore = []

                dataStores.getAllStores.rows.map((element) => {
                    return optionsStore.push({
                        ['value']: element.id,
                        ['label']: `${element.id} - ${element.name}`,
                    })
                })

                setListOfStores(optionsStore)

                // Get Warehouses Content
                const warehouses = await getWarehouses()
                const optionsWhs = []
                warehouses.data.getSapWarehouses.map((element) => {
                    return optionsWhs.push({
                        ['value']: element.warehouse_code,
                        ['label']: `${element.warehouse_code} - ${element.warehouse_name}`,
                        ['name']: `${element.warehouse_name}`,
                    })
                })

                setListOfWarehouses(optionsWhs)
            }

        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }, [_id, dataStores, loadingStores, errorStores])

    const setDescription = () => {
        try {
            const fromWhsCode = getValues('fromWhsCode')
            const toWhsCode = getValues('toWhsCode')

            if (fromWhsCode && toWhsCode) {
                setValue('inputName', `${listOfWarehouses.find((element) => element.value == fromWhsCode).name} - ${listOfWarehouses.find((element) => element.value == toWhsCode).name}`)
            }

        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }

    }
    const onSubmit = async (data) => {
        try {
            if (!_id) {
                // Create
                setOnProcess(true)
                const flowForTransfer = {
                    store_id: parseInt(data.storeID),
                    name: data.inputName,
                    from_whs_code: data.fromWhsCode,
                    to_whs_code: data.toWhsCode,
                    whs_transfer: data.trsWhsCode,
                    branch_code: data.inputSO1,
                    origin_destination_code: data.U_ORIGENDESTINO,
                }
                const result = await createFlow({
                    variables: { flowForTransfer },
                })


                if (result.data.createFlowForTransfer) {
                    history.push(`/Definitions/TransferConfiguration`)
                }

            } else {
                // Update
                setOnProcess(true)
                const flowForTransfer = {
                    id: parseInt(_id),
                    store_id: parseInt(data.storeID),
                    name: data.inputName,
                    from_whs_code: data.fromWhsCode,
                    to_whs_code: data.toWhsCode,
                    whs_transfer: data.trsWhsCode,
                    branch_code: data.inputSO1,
                    origin_destination_code: data.U_ORIGENDESTINO,
                }
                const result = await updateFlow({
                    variables: { flowForTransfer },
                })


                if (result.data.updateFlowForTransfer) {
                    history.push(`/Definitions/TransferConfiguration`)
                }
            }
        } catch (error) {

            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        } finally {
            setOnProcess(false)
        }
    }
    return <>
        <ContentHeader
            title="Agregar nuevo flujo de traslado"
            breadcrumb="Flujo de Traslado"
            windowTitle={`Configuracion de Transferencias`}
        />
        <div className={`${onProcess ? 'processing-file' : ''}`}>
            <Box
                title={`Flujo de traslado`}
                btnSubmit={!show ? handleSubmit(onSubmit) : false}
                loading={onProcess}
                btnRedPath="/Definitions/TransferConfiguration"
                btnRedTxt={show ? "Regresar" : "Cancelar"}                
                content={
                    <>
                        <FormProvider>
                            <form className="p-0" onSubmit={handleSubmit(onSubmit)}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Sucursal"
                                                name="storeID"
                                                type="number"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfStores}
                                                disabled={show}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Almacen de Origen"
                                                name="fromWhsCode"
                                                type="text"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfWarehouses}
                                                blurAction={setDescription}
                                                disabled={show}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Almacen destino"
                                                name="toWhsCode"
                                                type="text"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfWarehouses}
                                                blurAction={setDescription}
                                                disabled={show}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Almacen Transito"
                                                name="trsWhsCode"
                                                type="text"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfWarehouses}
                                                disabled={show}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row' >
                                        <div className="col-sm-12 col-md-6">
                                            <InputController
                                                label="Descripción del flujo"
                                                type="text"
                                                name="inputName"
                                                placeholder="Descripción del flujo entre los almacenes"
                                                control={control}
                                                disabled={show}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <InputController
                                                label="Código de sucursal en Retail One"
                                                type="text"
                                                name="inputSO1"
                                                placeholder="Código de sucursal en Retail One"
                                                control={control}
                                                disabled={show}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row' >
                                        <div className="col-sm-12 col-md-6">
                                            <InputController
                                                label="Código de origen y destino en SAP B1"
                                                name="U_ORIGENDESTINO"
                                                type="number"
                                                inputType="choosen"
                                                control={control}
                                                options={listOfStores}
                                                disabled={show}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </>
                }
            ></Box>
        </div>

    </>
}

export default TransferConfigurationNew