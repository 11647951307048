import React, { useState } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import { useHistory } from 'react-router-dom'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

export const TelemarketingPacked = () => {

  const history = useHistory()

  const dataToTable = [
    {
        id: 2,
        'fecha': "06/07/2022 15:23",
        'cobro': "Tarjeta",
        'no. cobro': "090344",
        cliente: "Ricardo Magaña",
        'no. venta': "1232",
    },
    {
        id: 3,
        'fecha': "10/08/2022 10:11",
        'cobro': "Efectivo",
        'no. cobro': "090349",
        cliente: "Ricardo Solis",
        'no. venta': "7401",
    }
  ]

  const [total, ] = useState(dataToTable.length);

  const handleDetails = (id) => {
    history.push(`/telemarketingOrderHistory/details/${id}/status`)
  }

  const handleErase = (id) => {
    toastSweetAlert({
        mode:'error',
        message:'Acción de borrado, por definir...',
      })
  }

  return (
    <>
      {/* <ContentHeader
        title="Empacado"
        breadcrumb="Empacado"
        windowTitle="Empacado"
      /> */}

      <LayoutTable
        data={dataToTable}
        title="Empacado"
        headersButtons={false}
        handleButtonRowCondition={true}
        handleButton={(e) => handleDetails(e)}
        handleButtonClassName={'mr-1'}
        handleButtonTitle="Detalles"
        actionBtnErase={(e)=>{handleErase(e)}}
        // hidePagination={true}
        // gql={DELETE_ROLE}
        // requery={GET_ROLES}
        // exportQuery={EXPORT_ROLES}
        totalCount={total}
        // pagePagination={pagePagination}
        // setPagePagination={setPagePagination}
        // loadingData={loading}
        FilterBarPlaceholder= "Buscar"
      />
    </>
  )
}

export default TelemarketingPacked
