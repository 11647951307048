import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import LayoutTable from '../Global/LayoutTable'
import ContentHeader from '../Layout/ContentHeader'

// apollo
import { GET_FLOW_FOR_TRANSFERS, DELETE_TRANSFER_FLOW } from '../../graphql/Catalog/Definitions/FlowForTransfers'

export const TransferConfiguration = () => {
    const [listOFlows, setListOfFlows] = useState([])
    const [total, setTotal] = useState(0)
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })

    const { loading, error, data, refetch: refetchAllFlowsTransfer, } = useQuery(GET_FLOW_FOR_TRANSFERS, {
        variables: {
            searchQuery: pagePagination.searchQuery,
            limit: pagePagination.limit,
            offset: pagePagination.offset,
        },
        fetchPolicy: 'no-cache',
    })

    const [deleteFlow] = useMutation(DELETE_TRANSFER_FLOW)

    useEffect(() => {
        if (!loading) {
            if (error)
                return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })

            let list = []

            data.getFlowForTransfers.rows.map((element) => {
                return list.push({
                    id: element.id,
                    ['Sucursal']: element.StoreSapWhsTransfers.name,
                    ['Codigo SO1']: element.branch_code,
                    ['Origen']: element.from_whs_code.concat('-', element.OriginWhsSapWhsTransfers?.warehouse_name),
                    ['Destino']: element.to_whs_code.concat('-', element.DestinationWhsSapWhsTransfers?.warehouse_name),
                    ['Traslado']: element.whs_transfer.concat('-', element.TransferWhsSapWhsTransfers?.warehouse_name),
                    ['Descripcion']: element.name,
                })
            })
            setListOfFlows(list)
            setTotal(data.getFlowForTransfers.count)
        }
    }, [loading, error, data])

    const onDelete = async (id) => {
        try {
            const response = await toastSweetAlert({
                mode: 'modalConfirmCancel',
                title: 'Eliminar registro',
                message: `La eliminacion del registro es permanente ¿Desea continuar?`,
            })
            if (response.isConfirmed) {
                const result = await deleteFlow({
                    variables: { deleteFlowForTransferId: id },
                    refetchQueries: refetchAllFlowsTransfer,
                })


                if (result.data.createFlowForTransfer) {
                    refetchAllFlowsTransfer()
                }
            }

        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }
    return <>
        <ContentHeader
            title="Configuracion de Transferencias"
            breadcrumb="Configuracion de Transferencias"
            windowTitle={`Configuracion de Transferencias`}
        />
        <LayoutTable
            title="Flujos de traslados"
            data={listOFlows}
            hideId={true}
            hideFilterBar={true}
            totalCount={total}
            actionBtnErase={(e) => { onDelete(e) }}
            requery={refetchAllFlowsTransfer}            
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}           
        />
    </>
}

export default TransferConfiguration