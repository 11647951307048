import { gql } from '@apollo/client'

export const GET_STATUS_ORDERS = gql`
  query GetStatusOrders {
    getStatusOrders {
      ordersToFill
      ordersToFillSevenDaysAgo
      ordersStowing
      ordersStowingTimePerUnit
      ordersPicking
      ordersPickingTimePerUnit
      ordersVerification
      ordersVerificationTimePerUnit
    }
  }
`
export const GET_LOCATION_SUMMARY = gql`
  query GetLocationSummary {
    getLocationSummary {
      emptyLocations
      locatedLocations
    }
  }
`
export const GET_PURCHASE_ORDERS_TO_RECIEVE = gql`
  query GetPurchaseOrdersToRecieve {
    getPurchaseOrdersToRecieve {
      ordersToReceive
      ordersToReceiveSevenDaysAgo
    }
  }
`
export const GET_TOP_TEN_HIGHER = gql`
  query GetTopHigher {
    getTopHigher {
      topHigherRotations {
        sku
        product
      }
    }
  }
`
export const GET_TOP_TEN_LOWER = gql`
  query GetTopLower {
    getTopLower {
      topLessRotations {
        sku
        product
      }
    }
  }
`

export const GET_INVENTORY_AGE = gql`
  query GetInventoryAge {
    getInventoryAge {
      antiquityInventory {
        units
        range
      }
      refreshInventory {
        units
        range
      }
    }
  }
`
export const WAREHOUSE_REPORT = gql`
  query GetWarehouseReport {
    getWarehouseReport {
      percentageWarehouse
      usedStorageCapacity
      warehouseUnitsSevenDaysAgo
      warehouseTotalUnits
      unitsReceived
      unitsReceivedSevenDaysAgo
      assortedUnits
      assortedUnitsSevenDaysAgo
      returnedUnits
      returnedUnitsSevenDaysAgo
      unitsDecrease
    }
  }
`
export const GET_RESTOCKED_POINTS = gql`
  query GetRestockedPoints {
    getRestockedPoints {
      locationsUnderFromLevel
    }
  }
`

export const GET_LAST_TFI = gql`
  query GetLastTfi {
    getLastTfi {
      lastDateTFI
      lastPercentageTFI
    }
  }
`


export const CREATE_WAREHOUSES_REPORTS = gql`
  mutation CreateWarehousesReports(
    $start: String
    $end: String
    $reportsType: Int!
    $mode: Int!
  ) {
    createWarehousesReports( start: $start, end: $end, reportsType: $reportsType, mode: $mode ) {
      status 
      message
    }
  }
`