import { gql } from '@apollo/client'

export const GET_LABELS_WITH_DISCOUNT_FOR_PRINT = gql`
  mutation GetSkusSelectedsForPrintLabelsWithDiscount(
    $skusSelecteds: [String]
    $listPrice: Int
  ) {
    getSkusSelectedsForPrintLabelsWithDiscount(
      skusSelecteds: $skusSelecteds
      listPrice: $listPrice
    ) {
      u_so1_article
      u_so1_amount
      PromoItemToSouPromo {
        Code
        Name
        u_so1_datefrom
        u_so1_dateuntil
        PromoToPromoDiscountFor {
          Code
          Name
          u_so1_discount1
          u_so1_discount2
        }
      }
      PromoItemToSapItems {
        item_name
        item_code_bar
        item_code
        tax_code_ar
        ItemPrices {
          price
          price_list
        }
        SapItemsToSapBarCodeData {
          barcode
        }
        # SapItemsToSalesTaxCodes {
        #   code
        #   rate
        # }
      }
    }
  }
`

export const GET_ITEMS_SAP_WITH_DISCOUNT_FOR_PRINT = gql`
  query GetItemsSapWithPromotion(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getItemsSapWithPromotion(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        u_so1_amount
        Code
        Name
        PromoItemToSouPromo {
          Code
          Name
          u_so1_datefrom
          u_so1_dateuntil
          PromoToPromoDiscountFor {
            Code
            Name
            u_so1_discount1
            u_so1_discount2
          }
        }
        PromoItemToSapItems {
          item_code_bar
          item_code
          item_name
          tax_code_ar
          ItemPrices {
            price
            price_list
          }
          SapItemsToStockOne {
            on_hand
          }
          # SapItemsToSalesTaxCodes {
          #   code
          #   rate
          # }
        }
      }
      priceListItems {
        item_code
        price
        price_list
      }
    }
  }
`

export const GET_ALL_ITEMS_CODE_FOR_PRINT = gql`
  mutation Mutation {
    getAllSkusSelectedsForPrintLabels
  }
`
export const GET_LAST_SYNC_SAP_PROMOS = gql`
  query GetLastSapSyncPromos {
    getLastSapSyncPromos {
      date
      status
      lastSuccessfulSync {
        date
      }
    }
  }
`
export const SYNC_SAP_PROMOS = gql`
  mutation Mutation {
    manualSapSyncPromos
  }
`

export const GET_PROMOS_DISCOUNT_BY_ITEMCODE_BARCODE = gql`
  mutation GetItemPromoByItemCodeBarcode($value: String!) {
    getItemPromoByItemCodeBarcode(value: $value) {
      promo {
        id
        u_so1_amount
        Code
        Name
        PromoItemToSouPromo {
          Code
          Name
          u_so1_datefrom
          u_so1_dateuntil
          PromoToPromoDiscountFor {
            Code
            Name
            u_so1_discount1
            u_so1_discount2
          }
        }
        PromoItemToSapItems {
          item_code_bar
          item_code
          item_name
          tax_code_ar
          SapItemsToStockOne {
            on_hand
          }
          SapItemsToSapBarCodeData {
            barcode
          }
          # SapItemsToSalesTaxCodes {
          #   code
          #   rate
          # }
        }
      }
      priceListItems {
        price_list
        price
      }
    }
  }
`
