import { gql } from '@apollo/client'

export const GET_TRANSFER_REQUEST = gql`
  query GetAllTransferRequest(
    $status: Int
    $statuses: [Int]
    $stageStatus: Int
    $onlyFromWhs: [String]
    $onlyToWhs: [String]
    $searchQuery: String
    $limit: Int
    $offset: Int
    $type: Int
    $date: Int
    $validation: Boolean
    $inRoutes: Boolean
    $porcentaje: Boolean
  ) {
    getAllTransferRequest(
      status: $status
      statuses: $statuses
      stageStatus: $stageStatus
      onlyFromWhs: $onlyFromWhs
      onlyToWhs: $onlyToWhs
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      type: $type
      date: $date
      validation: $validation
      inRoutes: $inRoutes
      porcentaje: $porcentaje
    ) {
      rows {
        id
        createdAt
        doc_date
        doc_due_date
        doc_num
        num_at_card
        status
        from_whs_code
        comments
        doc_entry

        TransferlineToTransferRequest {
          picked
        }

        warehouse_origin_name {
          id
          warehouse_name
        }
        to_whs_code
        warehouse_destiny_name {
          id
          warehouse_name
        }
        stage_status
        PackingList {
          id
          security_checked
          type_id
          order_id
          validated
          UserValidate {
            id
            name
            first_name
            last_name
          }
          PackingListBoxes {
            id
            security_checked
            validated
            is_virtual
            packing_list_id
            is_active
            PackingListBoxesLines {
              id
              box_id
              quantity
              item_code
              validated
              is_active
              # SapItem {
              #   item_code
              #   item_name
              # }
            }
          }
        }
      }
      count
    }
  }
`
export const GET_TRANSFER_REQUEST_BY_ID = gql`
  query GetOneTransferRequest(
    $id: Int!
    $validation: Boolean
    $details: Boolean
  ) {
    getOneTransferRequest(id: $id, validation: $validation, details: $details) {
      id
      doc_date
      doc_due_date
      comments
      num_at_card
      status
      stage_status
      PackingList {
        id
        order_id
        type_id
        validated
        security_checked
        UserValidate {
          id
          name
          first_name
          last_name
        }
        PackingListBoxes {
          id
          security_checked
          validated
          is_virtual
          packing_list_id
          PackingListBoxesLines {
            id
            box_id
            quantity
            item_code
            validated
            is_active
          }
          is_active
        }
        is_active
      }
      warehouse_origin_name {
        id
        warehouse_name
      }
      warehouse_destiny_name {
        id
        warehouse_name
      }

      TransferlineToTransferRequest {
        id
        inventory_transfer_id
        line_num
        item_code
        name_product {
          item_code
          item_name
        }
        doc_entry
        target_type
        target_entry
        line_status
        quantity
        open_quantity
        price
        disc_prcnt
        line_total
        whs_code
        total
        iva
        ieps
        tax_code
        status_modification
      }

      OrderToTransfer {
        id
        order_id
        picking_user_id
        open
        UserToOrder {
          name
          first_name
          last_name
        }
        rack_id
        picked
        type
        type_id
        is_active
        reprocessed
      }
    }
  }
`
export const GET_PRODUCTS_BY_TRANSFER_ID_REQUEST = gql`
  mutation GetProductsByTransferRequest(
    $id: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getProductsByTransferRequest(
      id: $id
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        type
        quantity
        picked
        id
        boxes
        position_id
        RackInfoPicking {
          id
          name
        }
        OrdersWarehouseProductsToInventoryTransferLine {
          transferLinesSapItems {
            item_code
            item_name
          }
        }
      }
    }
  }
`

export const CREATE_TRANSFER_REQUEST = gql`
  mutation CreateTransferRequest(
    $inputTransferRequest: transferRequestInput!
    $inputProducts: [inputTransfersProducts]!
  ) {
    createTransferRequest(
      inputTransferRequest: $inputTransferRequest
      inputProducts: $inputProducts
    )
  }
`
// export const UPDATE_TRANSFER_REQUEST = gql`
//   mutation UpdateTransferReques(
//     $updateTransferRequestId: Int!
//     $InputTransferRequest: transferRequesInput!
//   ) {
//     updateTransferRequest(
//       id: $updateTransferRequestId
//       InputTransferRequest: $InputTransferRequest
//     )
//   }
// `
export const GET_PRODUCTS_SAP = gql`
  mutation GetProductSap(
    $searchQuery: String!
    $warehouseCode: String!
    $warehouseCodeDestiny: String
  ) {
    getProductSap(
      searchQuery: $searchQuery
      warehouseCode: $warehouseCode
      warehouseCodeDestiny: $warehouseCodeDestiny
    ) {
      id
      item_code
      item_name
      on_hand
      sal_pack_un
      on_hand_destiny
      warehouse_destiny
      warehouse_code
      WarehouseToProducts {
        warehouse_name
        warehouse_code
      }
      barcode
      barcode_name
    }
  }
`

export const EXPORT_TRANSFERS = gql`
  mutation GetAllTransferRequestExport {
    getAllTransferRequestExport {
      id
      doc_date
      doc_due_date
      # num_at_card
      # doc_total
      comments
      status
      doc_entry
      series
      # series_name
      # price_list
      # disc_prcnt
      from_whs_code
      to_whs_code
      # warehouse_origin_name {
      #   warehouse_name
      #   id
      # }
      # warehouse_destiny_name {
      #   id
      #   warehouse_name
      # }
    }
  }
`

export const IMPORT_TRANSFERS = gql`
  mutation TransferRequestImport($files: [Upload!]!) {
    TransferRequestImport(files: $files)
  }
`

export const PROCESS_TRANSFERS_PICKING_SCHEDULES = gql`
  mutation ProcessTransfersPicking(
    $transferId: Int!
    $fromTransferNew: Boolean
  ) {
    processTransfersPicking(
      transferId: $transferId
      fromTransferNew: $fromTransferNew
    )
  }
`
export const CREATE_TRANSFER_REQUEST_SUBSCRIPTION = gql`
  subscription TransfersRequestCreated {
    transfersRequestCreated {
      item_code
      quantity
      from_whs_code
    }
  }
`
export const PROCESS_TRANSFERS_PICKING_FROM_TRANSFERS_ORDERS = gql`
  mutation ProcessTransfersPickingFromTransfersOrders($transferId: Int!) {
    processTransfersPickingFromTransfersOrders(transferId: $transferId)
  }
`
export const GET_TRANSFER_REQUEST_BY_EDITION_ID = gql`
  query GetOneTransferRequestByEditionId(
    $getOneTransferRequestByEditionIdId: Int
    $details: Boolean
  ) {
    getOneTransferRequestByEditionId(
      id: $getOneTransferRequestByEditionIdId
      details: $details
    ) {
      id
      doc_date
      doc_due_date
      comments
      num_at_card
      to_whs_code
      from_whs_code
      status
      PackingList {
        id
        order_id
        type_id
        validated
        security_checked
        UserValidate {
          id
          name
          first_name
          last_name
        }
        PackingListBoxes {
          id
          security_checked
          validated
          is_virtual
          packing_list_id
          PackingListBoxesLines {
            id
            box_id
            quantity
            item_code
            validated
          }
        }
      }
      warehouse_origin_name {
        id
        warehouse_name
      }
      warehouse_destiny_name {
        id
        warehouse_name
      }
      TransferlineToTransferRequest {
        id
        inventory_transfer_id
        line_num
        item_code
        name_product {
          item_code
          item_name
        }
        doc_entry
        target_type
        target_entry
        line_status
        quantity
        open_quantity
        price
        disc_prcnt
        line_total
        whs_code
        total
        iva
        ieps
        tax_code
      }
    }
  }
`

export const CREATE_TRANSFER_REQUEST_ERASER = gql`
  mutation {
    createTransferRequest(
      inputTransferRequest: {
        comments: ""
        from_whs_code: ""
        to_whs_code: ""
        branch_code: ""
      }
    )
  }
`
export const CREATE_TRANSFER_LINE = gql`
  mutation CreateTransferLineRequest(
    $createTransferLineRequestId: Int!
    $product: InventoryLineProduct!
    $warehouseCode: String!
    $dataRequest: InventoryTransferRequest!
  ) {
    createTransferLineRequest(
      id: $createTransferLineRequestId
      product: $product
      warehouse_code: $warehouseCode
      dataRequest: $dataRequest
    )
  }
`
export const UPDATE_TRANSFER_LINE = gql`
  mutation UpdateTransferLineRequest(
    $updateTransferLineRequestId: Int!
    $product: InventoryLineProduct!
    $warehouseCode: String!
    $dataRequest: InventoryTransferRequest!
  ) {
    updateTransferLineRequest(
      id: $updateTransferLineRequestId
      product: $product
      warehouse_code: $warehouseCode
      dataRequest: $dataRequest
    )
  }
`

export const GET_TRANSFER_LINES_BY_TRANSFER_REQUEST = gql`
  query GetTransferLinesByTransferRequest(
    $getTransferLinesByTransferRequestId: Int!
    $warehouseCode: String!
  ) {
    getTransferLinesByTransferRequest(
      id: $getTransferLinesByTransferRequestId
      warehouse_code: $warehouseCode
    ) {
      itemCode
      itemName
      quantity
      stock
      cajasHide
      unidadHide
      quantityBoxes
      quantityBottles
    }
  }
`
export const DELETE_TRANSFER_LINE = gql`
  mutation DeleteTransferLineRequest(
    $itemCode: String!
    $deleteTransferLineRequestId: Int!
  ) {
    deleteTransferLineRequest(
      item_code: $itemCode
      id: $deleteTransferLineRequestId
    )
  }
`
export const DELETE_ALL_TRANSFER_LINE = gql`
  mutation DeleteAllTransferLineRequest(
    $deleteAllTransferLineRequestId: Int!
    $dataRequest: InventoryTransferRequest!
  ) {
    deleteAllTransferLineRequest(
      id: $deleteAllTransferLineRequestId
      dataRequest: $dataRequest
    )
  }
`
export const UPDATE_TRANSFER_REQUEST = gql`
  mutation UpdateTransferRequest(
    $updateTransferRequestId: Int!
    $dataRequest: InventoryTransferRequest!
    $products: [InventoryLineProducts]!
  ) {
    updateTransferRequest(
      id: $updateTransferRequestId
      dataRequest: $dataRequest
      products: $products
    )
  }
`
export const MUTATION_GET_TRANSFER_LINES_BY_TRANSFER_REQUEST = gql`
  mutation MutationGetTransferLinesByTransferRequest(
    $mutationGetTransferLinesByTransferRequestId: Int!
    $warehouseCode: String!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    mutationGetTransferLinesByTransferRequest(
      id: $mutationGetTransferLinesByTransferRequestId
      warehouse_code: $warehouseCode
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        itemCode
        itemName
        quantity
        stock
        cajasHide
        unidadHide
        quantityBoxes
        quantityBottles
      }
      count
    }
  }
`

export const VALID_PROCESS_TRANSFERS_EXISTS_PACKING_LIST = gql`
  mutation ValidProcessTransfersExistsPackingList($transferId: Int!) {
    validProcessTransfersExistsPackingList(transferId: $transferId)
  }
`

export const REMOVE_PACKING_LIST_ID = gql`
  mutation RemoveTransferLinePackingList($transferLineId: Int!) {
    RemoveTransferLinePackingList(transferLineId: $transferLineId)
  }
`

export const MODIFY_PACKING_LIST = gql`
  mutation ModifyQuantitiesTransferLinePackingList(
    $transferLineId: Int!
    $packingListId: Int!
    $quantityBoxes: Int!
    $quantityBottles: Int!
  ) {
    modifyQuantitiesTransferLinePackingList(
      transferLineId: $transferLineId
      packingListId: $packingListId
      quantityBoxes: $quantityBoxes
      quantityBottles: $quantityBottles
    )
  }
`
export const MODIFYING_LABEL = gql`
  mutation ChangingBoxesAndQuantitiesFromTransferLinePackingList(
    $packingListId: Int!
    $quantityBoxes: Int!
    $quantityLines: Int!
    $quantityBottles: Int!
    $itemCode: String!
  ) {
    changingBoxesAndQuantitiesFromTransferLinePackingList(
      packingListId: $packingListId
      quantityBoxes: $quantityBoxes
      quantityLines: $quantityLines
      quantityBottles: $quantityBottles
      itemCode: $itemCode
    )
  }
`
export const TRANSFER_REQUEST_SUSBCRIPTION = gql`
  subscription TransferRequestSubscription {
    transferRequestSubscription {
      id
      doc_entry
      status
    }
  }
`

export const GET_PACKING_LIST_BY_PALLET_ID = gql`
  query GetPackingListByPalletId(
    $packingListId: Int!
    $limit: Int
    $offset: Int
    $searchQuery: String
  ) {
    getPackingListByPalletId(
      packingListId: $packingListId
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      count
      rows {
        id
      }
    }
  }
`
export const GET_PACKING_LIST_BOXES_BY_PALLET_ID = gql`
  query GetPackingListBoxesByPalletId($palletId: Int!) {
    getPackingListBoxesByPalletId(palletId: $palletId) {
      count
      rows {
        id
        is_virtual
        packing_list_id
        security_checked
        validated
        is_active
        PackingListBoxesLines {
          item_code
          is_active
          id
          quantity
          validated
          box_id
          SapItem {
            item_name
          }
        }
      }
    }
  }
`

export const REMOVE_LINE_UBICATION = gql`
  mutation RemoveLineUbication(
    $removeLineUbicationId: Int!
    $transferId: Int!
    $itemCode: String!
  ) {
    removeLineUbication(
      id: $removeLineUbicationId
      transferId: $transferId
      itemCode: $itemCode
    )
  }
`
export const DELETE_ORDER_LINE = gql`
  mutation DeleteLineOrder(
    $deleteLineOrderId: Int!
    $transferId: Int!
    $itemCode: String!
  ) {
    deleteLineOrder(
      id: $deleteLineOrderId
      transferId: $transferId
      itemCode: $itemCode
    )
  }
`
export const REPROCESS_TRANSFER = gql`
  mutation ReprocessingPartialTransfer($transferId: Int!) {
    reprocessingPartialTransfer(transferId: $transferId)
  }
`
export const GET_ALL_PACKING_LIST_BOXES_TRANSFERS = gql`
  query GetAllPackingListBoxes(
    $orderId: Int!
    $packingListId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
    $status: Int
  ) {
    getAllPackingListBoxes(
      orderId: $orderId
      packingListId: $packingListId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      status: $status
    ) {
      rows {
        id
        inventory_transfer_id
        line_num
        item_code
        doc_entry
        target_entry
        line_status
        quantity
        open_quantity
        partial_picked
        status_modification
        PackingListBoxes {
          id
          security_checked
          validated
          is_virtual
          is_active
          packing_list_id
          PackingListBoxesLines {
            id
            box_id
            quantity
            item_code
            validated
            is_active
          }
        }
        UserValidate {
          name
          first_name
          last_name
        }
        name_product {
          item_code
          item_name
        }
      }
      customBoxes {
        id
        box_id
        quantity
        item_code
        validated
        is_active
      }
      count
    }
  }
`
