import React, { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import LayoutTable from '../../../Global/LayoutTable'
import { useLazyQuery } from '@apollo/client'
import { GET_ALL_INVENTORY_ACTIVITIES_SAP_ITEMS } from '../../../../graphql/InventoryActivities/InventoryActivitiesLabels'
import InputController from '../../../Global/InputController'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '../../../Global/Box'

const TableAssignmentsSKU = ({
  elementsToAssign,
  setElementsToAssign,
  selectedSkus,
  setSelectedSkus,
  pagePaginationSku,
  setPagePaginationSku,
  showJustResume,
}) => {
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [toggledClearRows, setToggleClearRows] = useState(true)
  const [checkedSkus, setCheckedSkus] = useState(true)
  const [activeAddGroupBtn, setActiveAddGroupBtn] = useState(false)
  const [querySearch, setQuerySearch] = useState('')

  const [skuDataTable, setSkuDataTable] = useState({
    items: [],
    total: 0,
  })

  const [skus, { loading: skusLoading, refetch: skusRefetch }] = useLazyQuery(
    GET_ALL_INVENTORY_ACTIVITIES_SAP_ITEMS
  )

  // const [skusToSearch] = useMutation(
  //   GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_TO_EXCLUDE
  // )

  //FOR SKUS
  useEffect(() => {
    try {
      const getProduct = async () => {
        try {
          const { data, error } = await skus({ variables: pagePaginationSku })
          if (error)
            return toastSweetAlert({ mode: 'error', message: error.message })

          const list = data?.getAllInventoryActivitiesSapItems?.rows.map(
            (item) => {
              return {
                id_omitField: item?.id,
                SKU: item?.item_code,
                Nombre: item?.SapStocksToSapItems?.item_name,
              }
            }
          )

          setSkuDataTable({
            items: list,
            total: data?.getAllInventoryActivitiesSapItems?.count,
          })
        } catch (error) {
          return toastSweetAlert({ mode: 'error', message: error.message })
        }
      }

      if (!querySearch) return

      getProduct()
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [pagePaginationSku, querySearch])

  const handleCheckSKU = (selections) => {
    setCheckedSkus(selections.selectedRows)
    if (selections.selectedRows.length > 1) setActiveAddGroupBtn(true)
    if (selections.selectedRows.length < 1) setActiveAddGroupBtn(false)
  }

  const handleAddSku = async (row) => {
    try {
      setLoadingProcess(true)
      setSelectedSkus((labels) => [
        ...labels,
        {
          SKU: row.SKU,
          Nombre: row.Nombre,
        },
      ])
      const { excludeSkus } = pagePaginationSku
      excludeSkus.push(row.SKU)
      setElementsToAssign((elements) => [...elements, row.SKU])
      setPagePaginationSku({ ...pagePaginationSku, excludeSkus: excludeSkus })
      await skusRefetch({
        variables: { ...pagePaginationSku, excludeSkus: excludeSkus },
      })
      setLoadingProcess(false)
      handleResetSelectedRows()
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleAddSelectGroup = async (rowsFromAddAllSkus) => {
    try {
      setLoadingProcess(true)
      const { excludeSkus } = pagePaginationSku
      for (const sku of rowsFromAddAllSkus ? rowsFromAddAllSkus : checkedSkus) {
        setSelectedSkus((Skus) => [
          ...Skus,
          {
            id: sku.id,
            SKU: sku.SKU,
            Nombre: sku.Nombre,
          },
        ])
        excludeSkus.push(sku.SKU)
        setElementsToAssign((elements) => [...elements, sku.SKU])
      }
      setPagePaginationSku({ ...pagePaginationSku, excludeSkus: excludeSkus })
      await skusRefetch({
        variables: { ...pagePaginationSku, excludeSkus: excludeSkus },
      })
      setLoadingProcess(false)
      await skusRefetch()
      handleResetSelectedRows()
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleRemoveSku = async (row) => {
    try {
      setLoadingProcess(true)
      const { excludeSkus } = pagePaginationSku
      const newExcludeSkus = excludeSkus.filter((sku) => sku !== row.SKU)
      const newElementsToAdd = elementsToAssign.filter(
        (element) => element !== row.SKU
      )
      setSelectedSkus(selectedSkus.filter((sku) => sku.SKU !== row.SKU))
      setElementsToAssign(newElementsToAdd)
      setPagePaginationSku({
        ...pagePaginationSku,
        excludeSkus: newExcludeSkus,
      })
      setLoadingProcess(false)
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleResetSelectedRows = () => {
    setToggleClearRows(!toggledClearRows)
    setActiveAddGroupBtn(false)
  }

  const handleSearchProduct = async (value) => {
    try {
      if (value.target.value === '')
        setSkuDataTable({
          items: [],
          total: 0,
        })
      if (value.key === 'Enter') {
        setPagePaginationSku({
          ...pagePaginationSku,
          searchQuery: value.target.value.toLowerCase(),
        })
        setQuerySearch(value.target.value.toLowerCase())
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const { control } = useForm({
    defaultValues: {},
    resolver: yupResolver(
      Yup.object().shape({
        source_warehouse_sap: Yup.string().required(
          'Este campo es obligatorio'
        ),
      })
    ),
  })

  return (
    <div className={`${loadingProcess ? 'processing-file' : ''}`}>
      {!showJustResume && (
        <Box
          hide
          content={
            <>
              <div className="mb-4 col-lg-10 col-md-12 col-sm-12 ">
                <div className="input-icons mb-3">
                  <InputController
                    id="searchBar"
                    label="Buscar producto"
                    type="text"
                    control={control}
                    name="search_product"
                    onKeyDown={handleSearchProduct}
                    placeholder="Buscar por sku, barcode, nombre..."
                  />
                </div>
              </div>
              <LayoutTable
                withCard={false}
                title="SKUS encontrados"
                hideAddNew
                hideFilterBar
                searchId="searchSKUS"
                exportImportButton={false}
                loadingData={skusLoading}
                data={skuDataTable.items}
                totalCount={skuDataTable.total}
                pagePagination={pagePaginationSku}
                setPagePagination={setPagePaginationSku}
                paginationPerPage={30}
                hideBtnEdit={true}
                hideBtnErase
                hideDetails
                selectableRows={true}
                selectableRowsClick={handleCheckSKU}
                clearSelectedRows={toggledClearRows}
                enableCollapse={false}
                collapsed={false}
                noDataComponent="No se encontrarón coincidencias, intente con otro termino"
                customActionButtons={[
                  {
                    className: 'btn btn-accept',
                    handleButton: handleAddSku,
                    buttonText: 'Seleccionar',
                  },
                ]}
                extraCompFilter={
                  <>
                    {/* <button
                className="btn btn-accept btn-thin"
                onClick={() => skuDataTable.total > 0 && handleAddAllSkus()}
                disabled={activeAddGroupBtn}
              >
                {pagePaginationSku.searchQuery === ''
                  ? 'Agregar todo'
                  : skuDataTable.total === 0
                  ? 'Nada que agregar'
                  : `Agregar ${skuDataTable.total} ubicaciones`}
              </button> */}

                    {activeAddGroupBtn && (
                      <button
                        className="btn btn-accept btn-thin"
                        onClick={() => handleAddSelectGroup()}
                      >
                        Asignar SKUS
                      </button>
                    )}
                  </>
                }
              />
            </>
          }
        />
      )}
      <LayoutTable
        withCard={!showJustResume}
        title={
          !showJustResume ? (
            'SKUS seleccionados'
          ) : (
            <div className="row">
              <div className="col-12">
                <p className="pl-4 pt-3 mb-2">
                  <h6>SKUS seleccionados</h6>
                </p>
                <hr className="mt-0 mb-2 pt-0 border-top border border-2" />
              </div>
            </div>
          )
        }
        hideAddNew
        hideFilterBar={showJustResume}
        hideActions={showJustResume}
        exportImportButton={false}
        data={selectedSkus}
        totalCount={selectedSkus.length}
        paginationPerPage={30}
        hideBtnEdit={true}
        paginationServer={false}
        hideBtnErase
        hideDetails
        // selectableRows={!showJustResume ? true : false}
        // selectableRowsClick={handleRemoveSku}
        // clearSelectedRows={toggledClearRows}
        // enableCollapse={false}
        // collapsed={false}
        noDataComponent="Aún no se han seleccionado skus"
        customActionButtons={
          showJustResume
            ? false
            : [
                {
                  className: 'btn btn-danger btn-trash',
                  handleButton: handleRemoveSku,
                  buttonText: <i className="fa fa-trash"></i>,
                },
              ]
        }
      />
    </div>
  )
}

export default TableAssignmentsSKU
