import { useEffect, useState } from 'react'
import LayoutTable from '../../Global/LayoutTable'
import { useQuery } from '@apollo/client'
import { GET_ALL_VERIFICATION_ORDERS } from '../../../graphql/Catalog/Orders/verificationOrders'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

const StoreOrdersVerification = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const { data, loading, error } = useQuery(GET_ALL_VERIFICATION_ORDERS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
  })

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      // console.log(data?.getAllVerificationOrders?.rows, 'DATA  ')

      const list = data?.getAllVerificationOrders?.rows.map((item) => {
        // const boxes = item?.PackingListToOrder?.PackingListBoxes[0] || []
        const product = item?.products?.map((row) => row.PackingListToOrder)
        const packingListBox = product.map((box) => box[0])
        // console.log(product, 'boxes')
        // console.log(packingListBox, 'boxes list')
        let boxesLinesValid = 0
        let boxValid = 0

        packingListBox[0]?.PackingListBoxes.forEach((boxItem) => {
          if (boxItem.validated) {
            boxValid += 1
          }
          boxItem?.PackingListBoxesLines.forEach((line) => {
            if (line?.validated) {
              boxesLinesValid += 1
            }
          })
        })

        // console.log(boxes, 'CAJAS')
        // boxes.forEach((box) => {
        //   if (box?.validated) {
        //     boxValid += 1
        //   }
        //   box.forEach((line) => {
        //     if (line?.validated) {
        //       boxesLinesValid += 1
        //     }
        //   })
        // })
        // boxValid = boxes.validated ? 1 : 0
        // boxes?.PackingListBoxesLines.forEach((line) => {
        //   if (line?.validated) {
        //     boxesLinesValid += 1
        //   }
        // })

        // const percentage =
        //   (boxValid * 100) / packingListBox[0]?.PackingListBoxes.length
        const percentage = (boxValid * 100) / item?.products.length

        return {
          id: item?.id,
          'fecha de orden': item?.order_date || 'N/A',
          'compañia de envio': item?.shippingCompany?.name || 'N/A',
          almacen: item?.warehouse?.name || 'N/A',
          status: item?.status?.name || 'N/A',
          metodo: item?.method?.name || 'N/A',
          progreso: (
            <>
              <div className="progress" style={{ width: '100px' }}>
                <div
                  className="progress-bar progress-bar-striped bg-success"
                  role="progressbar"
                  style={{
                    width: percentage ? `${percentage}%` : '0%',
                  }}
                  aria-valuenow="10"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </>
          ),
        }
      })
      setDataToTable(list)
      setTotal(data?.getAllVerificationOrders?.count)
    }
  }, [data, loading, error])

  return (
    <>
      <LayoutTable
        data={dataToTable}
        title="Ordenes Verificadas"
        loadingData={loading}
        setPagePagination={setPagePagination}
        pagePagination={pagePagination}
        totalCount={total}
        exportImportButton={false}
        hideBtnEdit={true}
        hideBtnErase={true}
        hideAddNew={true}
      />
    </>
  )
}

export default StoreOrdersVerification
