import { useForm } from 'react-hook-form'
import InputController from '../../Global/InputController'
import ModalContent from '../../Global/ModalContent'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import {
  GET_TRANSFER_REQUEST_BY_ID,
  MODIFYING_LABEL,
} from '../../../graphql/Catalog/Transfers/Transfers'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useEffect, useState } from 'react'

const ChangeLabelModal = ({ refModal, data, id, refetch }) => {
  const validationSchema = Yup.object().shape({
    boxes: Yup.string().required('La cantidad de cajas es requerida'),
    boxes_bottles: Yup.string().required('La cantidad por caja es requerida'),
  })

  const [editLabel] = useMutation(MODIFYING_LABEL)
  const [boxes, setBoxes] = useState(0)
  const [quantityByBoxes, setQuantityByBoxes] = useState(0)
  const [submitFormloaded, setSubmitFormLoaded] = useState(false)

  useEffect(() => {}, [data])

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (dataForm) => {
    setSubmitFormLoaded(true)
    try {
      const quantityBottles =
        parseInt(data?.row?.Total - boxes * quantityByBoxes) > 0
          ? data?.row?.Total - boxes * quantityByBoxes
          : 0

      const totalQuantity = +boxes * +quantityByBoxes + +quantityBottles

      if (totalQuantity !== data?.row?.Total) {
        setSubmitFormLoaded(false)
        reset()
        return toastSweetAlert({
          mode: 'error',
          message: 'La cantidad ingresada es excedente a lo solicitado',
        })
      }

      const { data: dataLabel } = await editLabel({
        variables: {
          packingListId: +data?.row?.packingListID_omitField,
          quantityBoxes: +dataForm.boxes,
          quantityLines: +dataForm.boxes_bottles,
          quantityBottles: +quantityBottles,
          itemCode: data?.row?.sku,
        },
        fetchPolicy: 'no-cache',
        // refetchQueries: [
        //   {
        //     query: GET_TRANSFER_REQUEST_BY_ID,
        //     variables: {
        //       id: +id,
        //       validation: true,
        //     },
        //   },
        // ],
      })

      if (dataLabel) {
        await refetch()
        setSubmitFormLoaded(false)
        refModal.current.click()
        setBoxes(0)
        setQuantityByBoxes(0)
        reset()
        toastSweetAlert({
          mode: 'ok',
          message: 'La etiqueta se actualizo con exito!',
        })
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleCancelButton = () => {
    reset()
    setBoxes(0)
    setQuantityByBoxes(0)
    refModal.current.click()
  }

  const handleCloseModal = () => {
    setBoxes(0)
    setQuantityByBoxes(0)
    reset()
  }

  return (
    <>
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#changeLabel"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="changeLabel"
        title="Editar Etiqueta"
        size="large"
        onClose={handleCloseModal}
        content={
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={`row ${submitFormloaded ? 'processing-file' : ''} `}
              >
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Producto"
                    type="text"
                    name="product"
                    valueManual={data?.row?.nombre}
                    control={control}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Número de cajas"
                    type="text"
                    name="boxes"
                    control={control}
                    changeAction={(e) => setBoxes(e.target.value)}
                  />
                  {/* <small className="form-text text-muted">
                    Cajas actuales: {data?.row?.Cajas}
                  </small> */}
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Piezas por caja"
                    type="text"
                    name="boxes_bottles"
                    control={control}
                    changeAction={(e) => setQuantityByBoxes(e.target.value)}
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Botellas"
                    type="text"
                    name="bottles"
                    control={control}
                    disabled
                    valueManual={
                      boxes > 0 && quantityByBoxes > 0
                        ? data?.row?.Total - boxes * quantityByBoxes > 0
                          ? data?.row?.Total - boxes * quantityByBoxes
                          : '0'
                        : '0'
                    }
                  />
                </div>
              </div>
              <div className="row mt-3 justify-content-between ">
                <button
                  type="button"
                  className="btn StandarModalCancelButtonLogOut mr-3"
                  onClick={handleCancelButton}
                >
                  Cancelar
                </button>

                <button
                  className="btn  StandarModalConfirmButtonLogOut"
                  type="submit"
                  disabled={submitFormloaded}
                >
                  Actualizar etiqueta
                </button>
              </div>
            </form>
          </>
        }
      />
    </>
  )
}

export default ChangeLabelModal
