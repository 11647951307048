import React from 'react'
import { useHistory } from 'react-router-dom'

export const ActionButtonsNewOrder = ({
    handleCleanOrder,
    handleContinue,
    dataCart
}) => {
    const history = useHistory()

    return(
        <div className='row justify-content-end'>
            <button 
                className="btn StandarDangerButtonLarge mr-2" 
                onClick={handleCleanOrder}>
                Vaciar pedido
            </button>
            <button 
                className="btn StandarModalCancelButtonLogOut mr-2" 
                onClick={() => { history.push('/telemarketingOrders') }}>
                    Cancelar
            </button>
            <button 
                className="btn StandarModalConfirmButtonLogOut" 
                onClick={handleContinue}
                disabled={dataCart.length == 0 ? true : false}>
                    Continuar
            </button>
        </div>
    )
}
export default ActionButtonsNewOrder