import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import {
  CREATE_STORE_HELPER_COUNTER_CHARGES,
  GET_ALL_STORE_HELPER_COUNTER_CHARGES,
  GET_ONE_STORE_HELPER_COUNTER_CHARGES,
  UPDATE_STORE_HELPER_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/Notes/StoreHelperCounterCharges/storehelpercountercharges'
import { GET_STORES } from '../../../../../graphql/Catalog/Stores/stores'
import Box from '../../../../Global/Box'
import InputController from '../../../../Global/InputController'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'

export const StoreHelperCounterChargesNew = () => {
  const { id: _id } = useParams()
  const history = useHistory()
  const [options, setOptions] = useState([])
  const [loadingBtn, setLoadingBtn] = useState(false)

  const { data: dataStoreCounterCharges } = useQuery(
    GET_ONE_STORE_HELPER_COUNTER_CHARGES,
    {
      variables: {
        id: parseInt(_id),
      },
      fetchPolicy: 'no-cache',
    }
  )

  const {
    loading: loadingStores,
    error: errorStores,
    data: dataStores,
  } = useQuery(GET_STORES)

  const [createStoreCounterCharges] = useMutation(
    CREATE_STORE_HELPER_COUNTER_CHARGES,
    {
      refetchQueries: [
        {
          query: GET_ALL_STORE_HELPER_COUNTER_CHARGES,
          variables: {
            limit: 10,
            offset: 0,
            searchQuery: null,
          },
        },
      ],
    }
  )

  const [updateStoreCounterCharges] = useMutation(
    UPDATE_STORE_HELPER_COUNTER_CHARGES,
    {
      refetchQueries: [
        {
          query: GET_ALL_STORE_HELPER_COUNTER_CHARGES,
          variables: {
            limit: 10,
            offset: 0,
            searchQuery: null,
          },
        },
      ],
    }
  )

  useEffect(() => {
    if (!loadingStores) {
      if (errorStores)
        return toastSweetAlert({
          mode: 'error',
          message: errorStores.message,
        })
      // if (user.role === 1 || user.role === 5) {
      const shops = dataStores?.getAllStores?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.alias,
        }
      })
      setOptions(shops)
    }
  }, [loadingStores, errorStores, dataStores])

  const validationSchema = Yup.object().shape({
    nomenclature: Yup.string()
      .required('Este campo es requerido')
      .min(3, 'Este campo debe tener al menos 3 caracteres')
      .max(5, 'Este campo debe tener máximo 5 caracteres'),
    shopChoosen: Yup.string().required('Este campo es requerido'),
    consecutive: Yup.number().typeError('Ete campo es requerido'),
    consecutive_nm: Yup.string().required('Este campo es requerido'),
    invoice: Yup.string().required('Este campo es requerido'),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (_id) {
      if (
        dataStoreCounterCharges !== null &&
        dataStoreCounterCharges !== undefined
      ) {
        const data = dataStoreCounterCharges.getOneStoreCounterChargesById

        const dataFormated = {
          shopChoosen: data.id_store,
          nomenclature: data.nomenclature_initials,
          consecutive_nm: data.consecutive_number_manual_note,
          consecutive: data.consecutive_number,
          invoice: data.invoice_payments,
        }
        reset(dataFormated)
      }
    }
  }, [dataStoreCounterCharges, reset, _id])

  const onSubmit = async (data) => {
    try {
      console.log(data)
      setLoadingBtn(true)
      if (_id) {
        await updateStoreCounterCharges({
          variables: {
            updateStoreCounterChargesId: parseInt(_id),
            storeCounterChargesInput: {
              id_store: parseInt(data.shopChoosen),
              consecutive_number: data.consecutive.toString().trim(),
              consecutive_number_manual_note: data.consecutive_nm.trim(),
              nomenclature_initials: data.nomenclature.toString().trim(),
              invoice_payments: data.invoice.toString().trim(),
            },
          },
        })

        return toastSweetAlertNotifications(
          {
            mode: 'ok',
            message:
              'La asignación de la tienda se ha actualizado correctamente',
          },
          history.push(`/catalogsforcountercharges/storehelpercountercharges`)
        )
      }

      await createStoreCounterCharges({
        variables: {
          storeCounterChargesInput: {
            id_store: parseInt(data.shopChoosen),
            consecutive_number: data.consecutive.toString().trim(),
            consecutive_number_manual_note: data.consecutive_nm.trim(),
            nomenclature_initials: data.nomenclature.toString().trim(),
            invoice_payments: data.invoice.toString().trim(),
          },
        },
      })

      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: 'La asignación se ha creado correctamente',
        },
        history.push(`/catalogsforcountercharges/storehelpercountercharges`)
      )
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      {/* <ContentHeader
        windowTitle="Control de tiendas"
        breadcrumb={`${
          _id
            ? 'Editar asignación de folios para tienda'
            : 'Asignar folios para tienda'
        }`}
        title={`${
          _id
            ? 'Editar asignación de folios para tienda'
            : 'Asignar folios para tienda'
        }`}
      /> */}
      <FormProvider {...methods}>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title={`${
              _id
                ? 'Editar asignación de folios para tienda'
                : 'Asignar folios para tienda'
            }`}
            btnRedPath="/catalogsforcountercharges/storehelpercountercharges"
            btnRedTxt="Cancelar"
            btnSubmit={true}
            btnState={loadingBtn}
            errors={errors}
            content={
              <>
                <div className="col">
                  <div className="row">
                    <div className="col-lg-3">
                      <InputController
                        name="shopChoosen"
                        label="Elija una tienda"
                        inputType="choosen"
                        control={control}
                        options={options}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputController
                        name="nomenclature"
                        label="Nomenclatura"
                        control={control}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputController
                        name="consecutive"
                        label="No. Consecutivo"
                        control={control}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputController
                        name="consecutive_nm"
                        label="No. Consecutivo Nota Manual"
                        control={control}
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputController
                        name="invoice"
                        label="Folio registro pago"
                        control={control}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default StoreHelperCounterChargesNew
