import { useEffect, useRef, useState } from 'react'
import LayoutTable from '../../../../Global/LayoutTable'
import ContentHeader from '../../../../Layout/ContentHeader'
import ModalContent from '../../../../Global/ModalContent'
import CreateTransfersFinancesCounterCharges from './CreateTransfersFinancesCounterCharges'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_ALL_TRANSFERS_FINANCES_COUNTER_CHARGES,
  GET_ALL_TRANSFERS_TO_EXPORT,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/transfersfinancescountercharges'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import moment from 'moment'
import {
  DELETE_TRASNFERS_FINANCES_COUNTER_CHARGES,
  GET_ALL_PAYMENTS_AND_TRANSFERS_FINANCES_COUNTER_CHARGES_TO_EXPORT,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/generatepaymentsfinancescountercharges'
import ExportExcel from '../../../../Helpers/ExportExcel'
import _ from 'lodash'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'
import { GET_ALL_BANKS_FINANCES_COUNTER_CHARGES } from '../../../../../graphql/Catalog/FinancesCounterCharges/banksfinancescountercharges'
import { GENERATE_REPORT_COUNTERCHARGES } from '../../../../../graphql/Catalog/Reports/counterchargesreport'

const BankingTransfersFinances = () => {
  const refModal = useRef()

  const [options, setOptions] = useState([])
  const [closeModal, setCloseModal] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [response, setResponse] = useState(null)
  const [idTransfer, setIdTransfer] = useState(null)
  const [dataToTable, setDataToTable] = useState([])
  const [dataTransferExport, setDataTransferExport] = useState([])
  const [dataPaymentsAndTransfers, setDataPaymentsAndTransfers] = useState([])
  const [nameSheets, setNameSheets] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    isTransfer: true,
  })

  const {
    loading: loadingAllTransfers,
    data: dataAllTransfers,
    error: errorAllTransfers,
    refetch: refetchAllTransfers,
  } = useQuery(GET_ALL_TRANSFERS_FINANCES_COUNTER_CHARGES, {
    variables: {
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      searchQuery: pagePagination.searchQuery,
      isTransfer: pagePagination.isTransfer,
      dateTo: pagePagination.dateTo,
      dateFrom: pagePagination.dateFrom,
      idBankOrigin: pagePagination.idBankOrigin,
    },
    fetchPolicy: 'no-cache',
  })

  const [getAllTransfersToExport] = useMutation(GET_ALL_TRANSFERS_TO_EXPORT, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      // limit: pagePagination.limit,
      // offset: pagePagination.offset,
      isTransfer: pagePagination.isTransfer,
      dateTo: pagePagination.dateTo,
      dateFrom: pagePagination.dateFrom,
      idBankOrigin: pagePagination.idBankOrigin,
    },
    fetchPolicy: 'no-cache',
  })

  const [getAllPaymentsAndTransfersToExport] = useMutation(
    GET_ALL_PAYMENTS_AND_TRANSFERS_FINANCES_COUNTER_CHARGES_TO_EXPORT,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        // limit: pagePagination.limit,
        // offset: pagePagination.offset,
        dateTo: pagePagination.dateTo,
        dateFrom: pagePagination.dateFrom,
        idBankOrigin: pagePagination.idBankOrigin,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const {
    loading: loadingBank,
    data: dataBank,
    error: errorBank,
  } = useQuery(GET_ALL_BANKS_FINANCES_COUNTER_CHARGES, {
    variables: {},
    fetchPolicy: 'no-cache',
  })

  const [generateReport] = useMutation(GENERATE_REPORT_COUNTERCHARGES)

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  useEffect(() => {
    if (!loadingAllTransfers) {
      if (errorAllTransfers)
        return toastSweetAlert({
          mode: 'error',
          message: errorAllTransfers.message,
        })
      let list = []

      dataAllTransfers?.getAllTransfersFinancesCounterCharges?.rows?.map(
        (item) => {
          return list.push({
            id: item?.id,
            Nomenclatura: item?.nomenclature_bank,
            'Nom. Fecha': item?.nomenclature_date,
            Consecutivo: item?.consecutive_number,
            // 'Folio TR': item?.consecutive_number_of_payments,
            origen: item?.id_bank ? item?.transfersToBankOrigin?.bank_name : '',
            destino: item?.id_bank_destination
              ? item?.transfersToBankDestination?.bank_name
              : '',
            monto: formmatterCurrency(item?.payment_amount),
            fecha: moment(item?.payment_date, 'YYYY-MM-DD').format(
              'DD/MM/YYYY'
            ),
            Beneficiario: item?.transfer_concept,
          })
        }
      )
      setDataToTable(list)
      setTotal(dataAllTransfers?.getAllTransfersFinancesCounterCharges?.count)
    }
  }, [loadingAllTransfers, dataAllTransfers, errorAllTransfers])

  useEffect(() => {
    if (!loadingBank) {
      // console.log('Entre a dataBank')
      if (errorBank)
        return toastSweetAlert({
          mode: 'error',
          message: errorBank?.message,
        })

      const banks = dataBank?.getAllBanksFinancesCounterCharges?.rows?.map(
        (item) => {
          return {
            value: item?.id,
            label: item?.bank_name + ' - ' + item?.bank_nomenclature,
          }
        }
      )
      setOptions(banks)
    }
  }, [loadingBank, dataBank, errorBank])

  const handleCreateNewBankingTransfer = () => {
    document.getElementById('spanModalCreateNewBankingTransfer').click()
  }

  const handleEditTransfer = (id) => {
    setIdTransfer(id)
    document.getElementById('spanModalCreateNewBankingTransfer').click()
  }

  const handlePrintReport = async (mode) => {
    try {
      console.log('pagePagination', pagePagination)
      const { data } = await generateReport({
        variables: {
          searchInput: pagePagination.searchQuery,
          startDate: pagePagination.dateFrom,
          endDate: pagePagination.dateTo,
          idBankOrigin: pagePagination.idBankOrigin,
          isTransfer: pagePagination.isTransfer,
          mode: mode,
        },
        fetchPolicy: 'no-cache',
      })

      return toastSweetAlert({
        mode: 'ok',
        message: data?.createCounterchargesReports?.message,
      })
    } catch (err) {
      console.log(err)
      return toastSweetAlert({
        mode: 'error',
        message: err.message,
      })
    }
  }

  // const handlePrintReport = async () => {
  //   try {
  //     setLoadingBtn(true)
  //     const { data: dataTransfer } = await getAllTransfersToExport()

  //     const data =
  //       dataTransfer?.getAllTransfersFinancesCounterChargesToExport?.rows

  //     // console.log(data)
  //     let list = []
  //     let sheetList = []
  //     let count = 0

  //     for (let i = 0; i < data.length; i++) {
  //       // let isTransfer = data[i]?.is_transfer
  //       let findBank = list.find((item) => item === data[i]?.id_bank)

  //       list.push(data[i].id_bank)
  //       if (!findBank) {
  //         count++
  //         let lengthString = data[i]?.transfersToBankOrigin?.bank_name.length
  //         // console.log(
  //         //   'data[i]?.transfersToBankOrigin?.bank_name ',
  //         //   data[i]?.transfersToBankOrigin?.bank_name.length
  //         // )
  //         if (lengthString >= 19) {
  //           sheetList.push(data[i]?.transfersToBankOrigin?.bank_name)
  //         } else {
  //           sheetList.push(
  //             data[i]?.transfersToBankOrigin?.bank_name +
  //               '-' +
  //               data[i]?.transfersToBankOrigin?.bank_nomenclature
  //           )
  //         }
  //       }
  //       // }
  //     }

  //     sheetList.sort()
  //     setNameSheets(sheetList)

  //     list = []

  //     data?.map((item) => {
  //       return list.push({
  //         Nomenclatura: item?.nomenclature_bank,
  //         'Nom. Fecha': item?.nomenclature_date,
  //         Consecutivo: item?.consecutive_number,
  //         // 'Folio TR': item?.consecutive_number_of_payments,
  //         origen: item?.id_bank ? item?.transfersToBankOrigin?.bank_name : '',
  //         destino: item?.id_bank_destination
  //           ? item?.transfersToBankDestination?.bank_name
  //           : '',
  //         monto: formmatterCurrency(item?.payment_amount),
  //         fecha: moment(item?.payment_date, 'YYYY-MM-DD').format(
  //           'DD/MM/YYYY'
  //         ),
  //         Beneficiario: item?.transfer_concept,
  //         Estatus: item?.is_active ? 'Pago activo' : 'Pago cancelado',
  //       })
  //     })

  //     list.sort((a, b) =>
  //       a.origen + a.Nomenclatura > b.origen + b.Nomenclatura
  //         ? 1
  //         : b.origen + b.Nomenclatura > a.origen + a.Nomenclatura
  //         ? -1
  //         : 0
  //     )
  //     // console.log('list', list)
  //     const groupedTransferByIdBank = _.groupBy(
  //       list,
  //       (transfer) => transfer.Nomenclatura
  //     )
  //     // console.log('sheetlist.length', sheetList.length)
  //     // console.log('sheetList > 1', sheetList.length > 1)
  //     setDataTransferExport(
  //       sheetList.length > 1 ? Object.values(groupedTransferByIdBank) : list
  //     )
  //     document.getElementById('ExportExcel').click()
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const handlePrintReportPayments = async () => {
    try {
      setResponse(null)
      setLoadingBtn(true)
      const { data: dataTransfer } = await getAllPaymentsAndTransfersToExport()

      // console.log(
      //   'data',
      //   dataTransfer?.getAllPaymentsAndTransfersFinancesCounterChargesToExport
      //     ?.rows
      // )

      let list = []
      let information = []
      let sheetList = []
      let count = 0
      // let previousBank = 0
      const data =
        dataTransfer?.getAllPaymentsAndTransfersFinancesCounterChargesToExport
      // console.log('data', data)
      for (let i = 0; i < data.length; i++) {
        let findBank = list.find((item) => item === data[i]?.id_bank)

        list.push(data[i].id_bank)
        if (!findBank) {
          count++
          let lengthString = data[i]?.transfersToBankOrigin?.bank_name.length
          // console.log(
          //   'data[i]?.transfersToBankOrigin?.bank_name ',
          //   data[i]?.transfersToBankOrigin?.bank_name.length
          // )
          if (lengthString >= 19) {
            sheetList.push(data[i]?.transfersToBankOrigin?.bank_name)
          } else {
            sheetList.push(
              data[i]?.transfersToBankOrigin?.bank_name +
                '-' +
                data[i]?.transfersToBankOrigin?.bank_nomenclature
            )
          }
        }
        // }
      }
      sheetList.sort()
      setNameSheets(sheetList)

      for (let transfer of data) {
        let isTransfer = transfer?.is_transfer
        if (!isTransfer) {
          for (let bill of transfer?.generatePaymentsFinancesToNotes?.bills) {
            const findEquals = information.find(
              (item) =>
                item.full_nomenclature ===
                transfer.consecutive_number_of_payments
            )
            // console.log('findEquals', findEquals)
            // console.log('information', information)
            if (!findEquals) {
              information.push({
                date_payment: moment(
                  transfer?.payment_date,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY'),
                bank_name: transfer?.transfersToBankOrigin?.bank_name,
                Nomenclatura: transfer?.nomenclature_bank,
                'Nom. Fecha': transfer?.nomenclature_date,
                Consecutivo: transfer?.consecutive_number,
                full_nomenclature: transfer?.consecutive_number_of_payments,
                provider:
                  transfer?.generatePaymentsFinancesToNotes?.partnerNotes
                    ?.provider_name,
                invoice_bill: bill?.invoice_bill,
                amount: formmatterCurrency(transfer?.payment_amount),
                is_active: transfer?.is_active
                  ? 'Pago activo'
                  : 'Pago cancelado',
              })
            } else {
              findEquals.invoice_bill =
                findEquals.invoice_bill + ', ' + bill?.invoice_bill
            }
          }
        } else {
          information.push({
            date_payment: moment(transfer?.payment_date, 'YYYY-MM-DD').format(
              'DD/MM/YYYY'
            ),
            bank_name: transfer?.transfersToBankOrigin?.bank_name,
            Nomenclatura: transfer?.nomenclature_bank,
            'Nom. Fecha': transfer?.nomenclature_date,
            Consecutivo: transfer?.consecutive_number,
            provider: transfer?.transfer_concept,
            full_nomenclature: transfer?.consecutive_number_of_payments,
            invoice_bill: 'Transferencia',
            amount: formmatterCurrency(transfer?.payment_amount),
            is_active: transfer?.is_active ? 'Pago activo' : 'Pago cancelado',
          })
        }
      }

      information.sort((a, b) =>
        a.bank_name + a.Nomenclatura > b.bank_name + b.Nomenclatura
          ? 1
          : b.bank_name + b.Nomenclatura > a.bank_name + a.Nomenclatura
          ? -1
          : 0
      )

      // console.log('information', information)

      const groupedByIdBank = _.groupBy(
        information,
        (payment) => payment.Nomenclatura
      )
      setDataPaymentsAndTransfers(
        sheetList.length > 1 ? Object.values(groupedByIdBank) : information
      )
      document.getElementById('ExportExcelPayments').click()
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  useEffect(() => {
    if (response === null || response === undefined) return
    if (response) {
      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: `Reporte generado correctamente`,
        },
        setResponse(null)
      )
    }

    if (!response) {
      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: `Error al generar el reporte`,
        },
        setResponse(null)
      )
    }
  }, [response])

  const filterOnChangeRangeDateFrom = (date) => {
    // console.log(date)
    // setDateFrom(date)
    setPagePagination({
      ...pagePagination,
      dateFrom: date,
    })
  }

  const filterOnChangeRangeDateTo = (date) => {
    // console.log(date)
    // setDateTo(date)
    setPagePagination({
      ...pagePagination,
      dateTo: date,
    })
  }

  const filterOnChangeBankOrigin = (idBankOrigin) => {
    setPagePagination({
      ...pagePagination,
      idBankOrigin: idBankOrigin,
    })
  }

  return (
    <div className={`${loadingBtn ? 'processing-file' : ''}`}>
      <ContentHeader
        title="Transferencias"
        windowTitle="Transferencias"
        breadcrumb="Transferencias"
      />

      <LayoutTable
        // title="Listado de Transferencias"
        data={dataToTable}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        gql={DELETE_TRASNFERS_FINANCES_COUNTER_CHARGES}
        loading={loadingBtn}
        hideAddNew
        hideDetails
        hideId
        filterRangeDateFrom={true}
        filterRangeDateTo={true}
        filterOnChangeRangeDateFrom={(e) =>
          filterOnChangeRangeDateFrom(e.target.value)
        }
        filterOnChangeRangeDateTo={(e) =>
          filterOnChangeRangeDateTo(e.target.value)
        }
        actionBtnEdit={(e) => handleEditTransfer(e)}
        exportImportButton={false}
        FilterBarPlaceholder="Buscar por folio transferencia"
        extraHeaderButtonFunction={handleCreateNewBankingTransfer}
        extraHeaderButtonFunctionText="Nueva Transferencia"
        refetchFunctionQueries={async () => refetchAllTransfers()}
        exportQueryCustom={getAllTransfersToExport}
        headerButtonsNoPermissions={
          <>
            <span
              className="btn btn-accept btn-sm"
              onClick={() => handlePrintReport(3)}
            >
              Descargar Reporte
            </span>

            <span
              className="btn btn-accept btn-sm"
              onClick={() => handlePrintReport(4)}
            >
              Descargar reporte pagos
            </span>
          </>
        }
        filterChoosen={[
          {
            id: 'banks',
            label: 'Por banco',
            name: 'banks',
            className: 'position-searchInput',
            options: options,
            placeholder: 'Buscar por banco',
            onChange: (e) => filterOnChangeBankOrigin(e?.value),
            isClearable: true,
          },
        ]}
      />
      <ModalContent
        idModal="modalCreateNewBankingTransfer"
        title="Transferencia"
        size="normal"
        onClose={() => setCloseModal(true)}
        content={
          <>
            <CreateTransfersFinancesCounterCharges
              id={idTransfer}
              setIdTransfer={setIdTransfer}
              refModal={refModal}
              refetch={refetchAllTransfers}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
            />
          </>
        }
      />
      <span
        id="spanModalCreateNewBankingTransfer"
        ref={refModal}
        className="btn btn-sm invisible"
        data-toggle="modal"
        data-target="#modalCreateNewBankingTransfer"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
        // onClick={handleProgramPaymentsForButton}
      ></span>

      <ExportExcel
        id="ExportExcel"
        excelData={dataTransferExport}
        fileName={'Historial Transferencias'}
        setResponse={setResponse}
        response={response}
        headers={[
          'Nomenclatura',
          'Nom. Fecha',
          'Consecutivo',
          'Origen',
          'Destino',
          'Monto',
          'Fecha',
          'Beneficiario',
          'Estatus',
        ]}
        // sheets={nameSheets}
        skipHeader={true}
        stylesToData={{
          font: { bold: false, sz: 10 },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          // border: { top: { style: 'thin' } },
        }}
        sheets={nameSheets}
      />

      <ExportExcel
        id="ExportExcelPayments"
        excelData={dataPaymentsAndTransfers}
        fileName={'Historial Pagos y Transferencias'}
        setResponse={setResponse}
        response={response}
        headers={[
          'Fecha de pago',
          'Banco',
          'Nomenclatura',
          'Nom. Fecha',
          'Consecutivo',
          'Nom. Completa',
          'Proveedor',
          'Folio factura',
          'Monto pagado',
          'Estatus',
        ]}
        skipHeader={true}
        sheets={nameSheets}
        stylesToData={{
          font: { bold: false, sz: 10 },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          // border: { top: { style: 'thin' } },
        }}
      />
    </div>
  )
}

export default BankingTransfersFinances
