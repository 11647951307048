import { gql } from '@apollo/client'

export const GET_PENDING_ORDERS_STORE = gql`
  query GetPendingOrdersStore(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $platformId: Int!
    $statusId: Int!
    $date: Int
  ) {
    getPendingOrdersStore(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      platform_id: $platformId
      status_id: $statusId
      date: $date
    ) {
      rows {
        id
        platform {
          name
        }
        type {
          name
        }
        payment {
          platform
          payment_id
        }
        shipping {
          first_name
          last_name
        }
        order_id
        order_date
        store {
          name
        }
        warehouse {
          name
        }
        object_type
        object_type_name
      }
      count
    }
  }
`

export const VALID_PROCESS_EXISTS_PENDING_ORDER_PACKING_LIST = gql`
  mutation PendingOrderValidProcessExistsPackingList($orderId: Int!) {
    pendingOrderValidProcessExistsPackingList(orderId: $orderId)
  }
`
export const CREATE_PENDING_ORDERS_PACKING_LIST = gql`
  mutation PendingOrderCreatePackingList($orderId: Int!) {
    pendingOrderCreatePackingList(orderId: $orderId)
  }
`

export const EXPORT_PENDING_ORDERS_STORE = gql`
  mutation GetAllPendingOrdersStoreExport(
    $searchQuery: String
    $date: Int
    $platformId: Int!
    $statusId: Int!
  ) {
    getAllPendingOrdersStoreExport(
      searchQuery: $searchQuery
      date: $date
      platform_id: $platformId
      status_id: $statusId
    ) {
      id
      order_date
      platform_name
      type_name
      id_payment
      platform
      shipping_first_name
      shipping_last_name
      order_id
      store_name
      warehouse_name
    }
  }
`
