import { gql } from "@apollo/client";

export const GET_ORDERS_ASSORTED = gql`
  query GetOrdersAssorted {
    getOrdersAssorted {
        quantity
    }
  }
`

export const GET_ORDERS_ASSORTED_COMPARATIVE = gql`
  query GetOrdersAssortedComparative {
    getOrdersAssortedComparative {
        quantity
        ordersAssortedDataSevenDaysAgo {
            date
            quantity
        }
    }
  }
`