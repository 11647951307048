import trashIcon from '../../../../Assets/icons/trash.svg'
import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import {
  GET_ALL_PACKING_LIST_BOXES_TRANSFERS,
  REMOVE_PACKING_LIST_ID,
} from '../../../../graphql/Catalog/Transfers/Transfers'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import LayoutTable from '../../../Global/LayoutTable'
import OrderButtons from '../../../OrderButtons'
import { useHistory, useParams } from 'react-router-dom'
import ChangeLabelModal from '../ChangeLabelModal'
import ModalContent from '../../../Global/ModalContent'
import FormEditOrderWarehouse from '../FormEditOrderWarehouse'

const BoxesByPackingList = ({ requestInfo, packingListId, setLoadingBtn }) => {
  const { id: _id } = useParams()
  const history = useHistory()
  const refModal = useRef()
  const refModalChangeLabel = useRef()
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [dataDetails, setDataDetails] = useState(false)
  const [dataBoxesBottles, setDataBoxesBottles] = useState(false)
  const [showBoxesBottlesDetails, setShowBoxesBottlesDetails] = useState({})
  const [dataToEdit, setDataToEdit] = useState(false)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    status: null,
    packingListId: parseInt(packingListId),
    orderId: parseInt(_id),
    limit: 10,
    offset: 0,
  })

  const {
    data: dataPackingListBoxes,
    loading: loadingPackingListBoxes,
    error: errorPackingListBoxes,
    refetch: refetchPackingListBoxes,
  } = useQuery(GET_ALL_PACKING_LIST_BOXES_TRANSFERS, {
    variables: pagePagination,
  })

  const [removeLinePackingList] = useMutation(REMOVE_PACKING_LIST_ID)

  useEffect(() => {
    if (!_id)
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Acción no permitida',
        },
        history.push('/transfers/validation')
      )
    if (loadingPackingListBoxes) return
    if (errorPackingListBoxes)
      return toastSweetAlert(
        {
          mode: 'error',
          message: errorPackingListBoxes.message,
        },
        history.push('/transfers/validation')
      )

    let boxesBottlesDetails = {}
    const listProductsPackings = []
    const data = dataPackingListBoxes.getAllPackingListBoxes.rows
    const count = dataPackingListBoxes.getAllPackingListBoxes.count

    const customBoxes = dataPackingListBoxes.getAllPackingListBoxes.customBoxes
    console.log(data)
    for (const product of data) {
      const skuBottlesInCustom = customBoxes?.filter(
        (line) => line?.item_code === product?.item_code
      )

      const dataToTreatment = product.PackingListBoxes
      let customBoxesToTreatment = []

      if (skuBottlesInCustom.length > 0)
        customBoxesToTreatment = skuBottlesInCustom

      const dataTreated = dataTreatment(dataToTreatment, customBoxesToTreatment)

      const { bottles, boxes, isValidated, totalQuantity } = dataTreated
      boxesBottlesDetails[product?.item_code] = dataTreated

      let element = {
        packingListID_omitField: packingListId,
        id: product?.id,
        sku: product?.item_code,
        nombre: product?.name_product?.item_name,
        Solicitado: product?.quantity,
        Cajas:
          boxes.length > 0
            ? `${boxes.length} X ${boxes[0]?.quantity} U c/u`
            : 'N/A',
        Botellas: bottles.length > 0 ? `${bottles[0].quantity}` : 'N/A',
        conditional_status:
          product?.status_modification === 3 ? 2 : isValidated ? 1 : 0,
        bottlesByBox_omitField: boxes[0]?.quantity,
      }

      if (requestInfo.validated) element['Total enviado'] = totalQuantity

      if (!requestInfo.validated) {
        element.Total = totalQuantity
        element.Aprobado = product?.quantity - product?.open_quantity
        element.Faltante = product?.open_quantity
        element.Modificado =
          product?.status_modification === 2
            ? 'Partida modificada'
            : product?.status_modification === 3
            ? 'Partida eliminada'
            : 'Partida original'
      }
      listProductsPackings.push(element)
    }

    setShowBoxesBottlesDetails(boxesBottlesDetails)
    setDataToTable(listProductsPackings)
    setTotal(count)
  }, [
    dataPackingListBoxes,
    errorPackingListBoxes,
    loadingPackingListBoxes,
    packingListId,
  ])

  const dataTreatment = (data, customBoxes) => {
    //MAKE GROUPS OF DATA BY SKU, SEPARATE BY BOXES, BOTTLES AND INFO
    const concatProducts = data.concat(customBoxes)
    const boxes = []
    const bottles = []
    let totalQuantity = 0
    let isValidated = true
    concatProducts?.map((boxesLines) => {
      if (boxesLines?.PackingListBoxesLines?.length > 0) {
        boxes.push({
          is_virtual: boxesLines.is_virtual,
          packing_list_id: boxesLines.packing_list_id,
          security_checked: boxesLines.security_checked,
          validated: boxesLines.validated,
          box_id: boxesLines?.PackingListBoxesLines[0].box_id,
          id: boxesLines?.PackingListBoxesLines[0].id,
          item_code: boxesLines?.PackingListBoxesLines[0].item_code,
          quantity: boxesLines?.PackingListBoxesLines[0].quantity,
          is_active: boxesLines?.is_active,
          isBox: true,
        })
        totalQuantity += boxesLines?.PackingListBoxesLines[0].quantity

        if (!boxesLines.validated) isValidated = false
      }

      if (!boxesLines.PackingListBoxesLines) {
        bottles.push({
          is_virtual: true,
          validated: boxesLines.validated,
          box_id: boxesLines?.box_id,
          id: boxesLines?.id,
          item_code: boxesLines?.item_code,
          quantity: boxesLines?.quantity,
          is_active: boxesLines?.is_active,
          isBox: false,
        })
        totalQuantity += boxesLines?.quantity
        if (!boxesLines.validated) isValidated = false
      }
      return null
    })
    setDataDetails({ boxes, bottles, totalQuantity, isValidated })
    return { boxes, bottles, totalQuantity, isValidated }
  }

  const ExpandedComponent = ({ data }) => {
    const boxesDetails = showBoxesBottlesDetails[data.sku]
    const concatArraysProducts = boxesDetails.boxes.concat(boxesDetails.bottles)

    const list = concatArraysProducts.map((line) => {
      return {
        id: line?.id,
        packingListID_omitField: packingListId,
        box_id: line?.box_id,
        cantidad: line?.quantity,
        Tipo: line?.isBox ? 'Caja' : 'Botellas',
        conditional_status: !line.is_active ? 2 : line.validated ? 1 : 0,
        Estado: !line.is_active ? (
          <span>&nbsp;&nbsp;Partida eliminada&nbsp;&nbsp;</span>
        ) : line.validated ? (
          <span>&nbsp;&nbsp;Validado&nbsp;&nbsp;</span>
        ) : (
          <span>&nbsp;&nbsp;Sin validar&nbsp;&nbsp;</span>
        ),
      }
    })

    return (
      <LayoutTable
        hideId
        hidePagination
        title="Detalles"
        data={list}
        paginationServer={false}
        hideActions={true}
        handleButtonRowCondition
        hideAddNew
        exportImportButton={false}
        hideFilterBar
        alertColor="#fff"
        alertSecondaryColor="#fff"
        alertTextColor="#559615"
        alertSecondaryTextColor="#AF2525"
        alertThirdTextColor="#bab8b8"
        textFontWeight="bold"
      />
    )
  }

  const handleModify = (row) => {
    setDataToEdit({ row: row })
    refModal.current.click()
  }
  const handleButton = (row) => {
    setDataToEdit({ row: row })
    refModalChangeLabel.current.click()
  }

  const removePackingList = async (row) => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        title: 'Eliminar partida',
        message: `Al eliminar una partida, la orden de surtido se afectara, ¿Deseas continuar?`,
      })
      if (result.isConfirmed) {
        setLoadingBtn(true)
        await removeLinePackingList({
          variables: { transferLineId: parseInt(row.id) },
        })
        await refetchPackingListBoxes()
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'ok',
          message: 'Partida eliminada correctamente',
        })
      }
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const filterOnChangeSecond = (option) => {
    console.log(option.target.value)
    setPagePagination({
      ...pagePagination,
      status: parseInt(option.target.value),
    })
  }

  return (
    <div className="card">
      <div className="card-body col-12 p-5">
        <div className="box-separator">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <LayoutTable
                hideId
                title="Productos"
                data={dataToTable}
                loadingData={loadingPackingListBoxes}
                totalCount={total}
                pagePagination={pagePagination}
                setPagePagination={setPagePagination}
                expandableRows
                hideBtnErase
                hideBtnEdit
                hideAddNew
                filter={requestInfo.validated ? false : true}
                filterOnChange={filterOnChangeSecond}
                filterOptions={[
                  <>
                    <option value="1" key="1">
                      Partidas originales
                    </option>
                    ,
                    <option value="2" key="2">
                      Partidas modificadas
                    </option>
                    ,
                    <option value="3" key="3">
                      Partidas eliminadas
                    </option>
                  </>,
                ]}
                exportImportButton={false}
                expandableRowsComponent={ExpandedComponent}
                alertColor="#fff"
                alertSecondaryColor="#fff"
                alertTextColor="#559615"
                alertSecondaryTextColor="#AF2525"
                alertThirdTextColor="#17a2b8"
                textFontWeight="bold"
                hideActions={requestInfo.validated}
                customActionButtons={[
                  {
                    className: 'btn btn-accept btn-sm',
                    handleButton: handleModify,
                    buttonText: 'Modificar',
                  },
                  {
                    className: 'btn btn-accept btn-sm',
                    handleButton: handleButton,
                    buttonText: 'Modificar Etiqueta',
                  },
                  {
                    className: 'btn btn-sm btn-accept-white',
                    handleButton: removePackingList,
                    buttonText: (
                      <img src={trashIcon} alt="trash" className="icon3" />
                    ),
                  },
                ]}
                hideDetails
              />
            </div>
          </div>
        </div>
        <OrderButtons
          oneButton={true}
          onProcess={() => history.goBack()}
          processBtn="Regresar"
        />
      </div>

      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#createDateTfi"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="createDateTfi"
        title="Editar orden"
        content={
          <FormEditOrderWarehouse
            data={dataToEdit}
            refetch={refetchPackingListBoxes}
            setLoadingBtn={setLoadingBtn}
          />
        }
      />
      <ChangeLabelModal
        refModal={refModalChangeLabel}
        data={dataToEdit}
        id={_id}
        refetch={refetchPackingListBoxes}
      />
    </div>
  )
}

export default BoxesByPackingList
