import { gql } from '@apollo/client'

export const GET_CUSTOMERS = gql`
  query GetAllCustomers($searchQuery: String, $offset: Int, $limit: Int) {
    getAllCustomers(searchQuery: $searchQuery, offset: $offset, limit: $limit) {
      rows {
        id
        first_name
        last_name
        phone
        email
        id_address
        id_user_register
        id_user_update
      }
      count
    }
  }
`

export const GET_CUSTOMER_BY_ID = gql`
  query GetCustomerById($getCustomerByIdId: Int!) {
    GetCustomerById(id: $getCustomerByIdId) {
      first_name
      last_name
      phone
      email
      id_address
      id_user_register
      rfc
      CustomerAddress {
        direction
        zip_code
        direction_reference
        between_streets
        CustomerColony {
          name
        }
        CustomerState {
          name
        }
      }
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      id
      first_name
      last_name
      phone
      email
      id_address
      rfc
    }
  }
`
