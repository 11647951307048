import { useEffect, useRef, useState } from 'react'
import Box from '../../Global/Box'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_ONE_VERIFICATION_ORDER } from '../../../graphql/Catalog/Orders/verificationOrders'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import Loader from '../../Global/Spinner'
import LayoutTable from '../../Global/LayoutTable'
import ChangeOrderLabelModal from './ChangeOrderLabelModal'

const StoreOrdersVerificationNew = () => {
  const { id: _id, show } = useParams()
  const [dataRequest, setDataRequest] = useState(null)
  const [dataToTable, setDataToTable] = useState([])
  const [dataBoxesBottles, setDataBoxesBottles] = useState(false)
  const [dataDetails, setDataDetails] = useState(false)
  const [skipedBox, setSkipedBox] = useState(false)
  const [showBoxesBottlesDetails, setShowBoxesBottlesDetails] = useState({})
  const { data, error, loading } = useQuery(GET_ONE_VERIFICATION_ORDER, {
    variables: {
      getOneVerificationOrderId: +_id,
    },
  })
  const refModal = useRef()
  const [dataToEdit, setDataToEdit] = useState(false)
  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      // console.log(data)

      let newPackingListBoxes = []
      let dataPackingList = data?.getOneVerificationOrder
      // dataPackingList?.PackingListToOrder?
      // newPackingListBoxes.push(
      //   ...dataPackingList?.PackingListToOrder?.PackingListBoxes
      // )
      // const dataTreated = dataTreatment(newPackingListBoxes)

      //HAS ONE RELATIONSHIP
      // const dataTreated = dataTreatment(
      //   dataPackingList?.PackingListToOrder?.PackingListBoxes
      // )

      // console.log(dataPackingList?.products[0]?.PackingListToOrder[0]?.PackingListBoxes)
      //box_id
      setSkipedBox(
        dataPackingList?.products[0]?.PackingListToOrder[0]?.PackingListBoxes[0]
          .id
      )
      // console.log(dataPackingList?.PackingListToOrder, 'PRODUCTOS')

      // HAS MANY RELATIONSHIP
      const dataTreated = dataTreatment(
        dataPackingList?.products[0]?.PackingListToOrder[0]?.PackingListBoxes
      )
      const appUserWebUser = data?.getOneVerificationOrder?.AppUserToOrder
        ? data?.getOneVerificationOrder?.AppUserToOrder
        : data?.getOneVerificationOrder?.UserToOrder

      const fullName =
        appUserWebUser &&
        `${appUserWebUser?.name || ''} ${appUserWebUser?.first_name || ''} ${appUserWebUser?.last_name || ''
        }`
      const orderDate = data?.getOneVerificationOrder?.order_date.split('T')
      const details = {
        order_date: `${orderDate[0]} - ${orderDate[1]}`,
        shippingCompany: data?.getOneVerificationOrder?.shippingCompany?.name
          ? data?.getOneVerificationOrder?.shippingCompany?.name
          : 'N/A',
        status: data?.getOneVerificationOrder?.status?.name
          ? data?.getOneVerificationOrder?.status?.name
          : 'N/A',
        warehouse: data?.getOneVerificationOrder?.warehouse?.name
          ? data?.getOneVerificationOrder?.warehouse?.name
          : 'N/A',
        type:
          data?.getOneVerificationOrder?.type_id == 1 ? 'Nacional' : 'Local',
        method: data?.getOneVerificationOrder?.method?.name || 'N/A',
        payment: data?.getOneVerificationOrder?.payment?.platform || 'N/A',
        quantity: data?.getOneVerificationOrder?.product_quantity || 'N/A',
        total: data?.getOneVerificationOrder?.total_price,
        shippingPrice: data?.getOneVerificationOrder?.shipping_price,
        store: data?.getOneVerificationOrder?.store?.name || 'N/A',
        fullName: fullName ? fullName : 'N/A',
      }
      // console.log(data?.getOneVerificationOrder?.products, 'PRODUCTS ')
      const items = data?.getOneVerificationOrder?.products.map((item) => {
        // console.log(item, 'items ')
        const isValidated = verifyIfValidate(
          item?.sku,
          dataTreated.newPackingListBoxes
        )

        // console.log(dataTreated?.newPackingListBoxes?.bottles, 'VALIDADO')
        // console.log(isValidated, 'VALIDADO')
        // console.log(newPackingListBoxes.length, 'VALIDADO')

        // item.PackingListToOrder.PackingListBoxes

        const complement = dataTreated?.newPackingListBoxesLines.boxes?.filter(
          (line) => {
            return line.item_code === item?.sku
          }
        )

        const filterTotalBoxes =
          dataTreated?.newPackingListBoxesLines.boxes?.filter((line) => {
            return line?.item_code === item?.sku
          })

        const totalBoxes =
          dataTreated.newPackingListBoxesLines?.boxes?.length > 0
            ? complement[0]?.quantity / filterTotalBoxes[0]?.bottlesByBox
            : // dataTreated.newPackingListBoxesLines?.boxes[0]?.bottlesByBox
            0

        const totalBottles =
          dataTreated?.newPackingListBoxesLines.bottles?.filter((line) => {
            return line?.item_code === item?.sku
          })

        const grandTotal =
          dataTreated.newPackingListBoxesLines.boxes.length > 0
            ? complement[0]?.quantity
              ? complement[0]?.quantity
              : 0
            : 0

        return {
          packingListID_omitField: dataTreated?.newPackingListBoxesLines
            ?.packing_list_id
            ? dataTreated?.newPackingListBoxesLines?.packing_list_id
            : dataTreated?.newPackingListBoxesLines?.boxes[0]?.packing_list_id,
          id: item?.id,
          sku: item?.sku,
          nombre: item?.name,
          Solicitado: item?.quantity,
          // Aprobado:
          //   status === 4 || status === 5
          //     ? item?.quantity - item?.open_quantity
          //     : 'Pendiente',
          // Faltante:
          //   status === 4 || status === 5
          //     ? item?.open_quantity
          //     : 'Pendiente',
          Cajas: `${totalBoxes
              ? totalBoxes + ' X ' + filterTotalBoxes[0]?.bottlesByBox + 'U c/u'
              : 'N/A'
            }`,
          Botellas:
            totalBottles?.length > 0 ? totalBottles[0]?.quantity : 'N/A',
          Total:
            grandTotal +
            (totalBottles?.length > 0 ? totalBottles[0].quantity : 0),
          // alert:
          //   newPackingListBoxes.length < 1
          //     ? false
          //     : grandTotal + totalBottles < item?.quantity
          //     ? false
          //     : !isValidated,
          alert:
            newPackingListBoxes.length < 0 ||
              grandTotal + totalBottles < item?.quantity
              ? false
              : !isValidated,
          bottlesByBox_omitField: filterTotalBoxes[0]?.bottlesByBox,
          // Modificado:
          //   item?.status_modification === 2
          //     ? 'Partida modificada'
          //     : item?.status_modification === 3
          //     ? 'Partida eliminada'
          //     : 'Partida original',
        }

        // return {
        //   id: item?.item_id,
        //   'Codigo de producto': item?.sku,
        //   'nombre de producto': item?.name,
        //   cantidad: item?.quantity,
        //   precio: item?.price,
        //   total: item?.total,
        // }
      })

      // console.log(dataTreated, 'DATA TRATADA')

      setShowBoxesBottlesDetails(dataTreated)
      setDataRequest(details)
      setDataToTable(items)
    }
  }, [data, error, loading])

  const dataTreatment = (data) => {
    const packingList = data
    let newPackingListBoxes = []
    let newPackingListBoxesLines = []

    //MAKE GROUPS OF DATA BY SKU, SEPARATE BY BOXES, BOTTLES AND INFO
    packingList?.map((boxesLines) => {
      // console.log(boxesLines, 'BOX LINES ')
      if (!boxesLines.is_virtual) {
        newPackingListBoxes.push({
          is_virtual: boxesLines.is_virtual,
          packing_list_id: boxesLines.packing_list_id,
          security_checked: boxesLines.security_checked,
          validated: boxesLines.validated,
          box_id: boxesLines?.PackingListBoxesLines[0].box_id,
          id: boxesLines?.PackingListBoxesLines[0].id,
          item_code: boxesLines?.PackingListBoxesLines[0].item_code,
          quantity: boxesLines?.PackingListBoxesLines[0].quantity,
          is_active: boxesLines?.is_active,
        })
      } else {
        newPackingListBoxesLines.bottles = boxesLines.PackingListBoxesLines
        newPackingListBoxesLines.packing_list_id = boxesLines.packing_list_id
      }
      return null
    })

    // REMOVE AND SUM QUANTITIES FROM DUPLICATES
    const boxes = Object.values(
      newPackingListBoxes.reduce((acc, item) => {
        acc[item.item_code] = acc[item.item_code]
          ? {
            ...item,
            quantity: item.quantity + acc[item.item_code].quantity,
            bottlesByBox: item.quantity,
          }
          : { ...item, bottlesByBox: item.quantity }
        return acc
      }, {})
    )

    newPackingListBoxes.bottles = newPackingListBoxesLines.bottles
    newPackingListBoxesLines.boxes = boxes
    setDataBoxesBottles(newPackingListBoxesLines)
    setDataDetails(newPackingListBoxes)

    return { newPackingListBoxesLines, newPackingListBoxes }
  }

  const verifyIfValidate = (sku, data) => {
    const items = groupBySku(sku, data)

    const isValidated = items.some((item) => {
      return item.validated === false
    })
    return isValidated
  }

  const groupBySku = (sku, data = false) => {
    let skuLines = []
    let skuLinesBottles = []

    if (data) {
      skuLines = data?.filter((line) => {
        return line.item_code === sku
      })
    } else {
      skuLines = dataDetails?.filter((line) => {
        return line.item_code === sku
      })
    }

    if (dataDetails.bottles) {
      skuLinesBottles = dataDetails.bottles.filter((line) => {
        return line.item_code === sku
      })
      skuLines.push(...skuLinesBottles)
    }

    if (data.bottles) {
      skuLinesBottles = data.bottles.filter((line) => {
        return line.item_code === sku
      })
      skuLines.push(...skuLinesBottles)
    }

    return skuLines
  }

  const ExpandedComponent = ({ data }) => {
    // console.log(dataBoxesBottles, 'BOXES BOXES')
    let skuLines = []
    let skuLinesBottles = []

    // console.log(dataBoxesBottles, 'DETALLES')
    dataBoxesBottles?.boxes?.forEach((line) => {
      if (line.item_code === data.sku) {
        skuLines.push({
          ...line,
          // is_virtual: true,
        })
      }
    })
    dataBoxesBottles?.bottles?.forEach((line) => {
      if (line.item_code === data.sku) {
        skuLinesBottles.push({
          ...line,
          is_virtual: true,
        })
      }
    })
    // console.log(skuLinesBottles, 'BOTELLAS')
    // console.log(skuLines, 'CAJAS')
    const boxesDetails = skuLines.length > 0 ? skuLines : skuLinesBottles

    const list = boxesDetails.map((line, index) => {
      // console.log(line, 'LINEA')
      return {
        id: line?.id,
        packingListID_omitField: line?.packing_list_id,
        box_id: line?.box_id,
        cantidad: line?.quantity,
        Tipo: line?.is_virtual ? 'Botellas' : 'Caja',
        Estado: !line.is_active ? (
          <span className="badge-danger badge-conf">
            &nbsp;&nbsp;Partida eliminada&nbsp;&nbsp;
          </span>
        ) : line.validated ? (
          <span className="badge-success badge-conf">
            &nbsp;&nbsp;Validado&nbsp;&nbsp;
          </span>
        ) : (
          <span className="badge-danger badge-conf">
            &nbsp;&nbsp;Sin validar&nbsp;&nbsp;
          </span>
        ),
      }
    })
    return (
      <LayoutTable
        hideId
        hidePagination
        title="Detalles"
        // title={`${
        //   list.length < 2
        //     ? 'Linea individual'
        //     : `Linea dividida en ${list.length} partes`
        // }`}
        data={list}
        paginationServer={false}
        hideActions={true}
        handleButtonRowCondition
        hideFilterBar
      />
    )
  }

  const handleButton = (row) => {
    setDataToEdit({ row: row })
    refModal.current.click()
  }

  return (
    <>
      <Box
        title="Detalles de orden"
        content={
          <>
            {!dataRequest ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="boxQuotesProvider">
                <div className="row">
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Fecha de cita:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.order_date.split('T')[0]}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Compañia de envio:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.shippingCompany}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Status:
                      <span className="resultData"> {dataRequest?.status}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Almacen:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.warehouse
                          ? dataRequest?.warehouse
                          : 'N/A'}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Tipo:
                      <span className="resultData"> {dataRequest?.type}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Metodo:
                      <span className="resultData"> {dataRequest?.method}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Informacion de pago:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.payment}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Cantidad:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.quantity}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Total:
                      <span className="resultData"> ${dataRequest?.total}</span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Costo de envio:
                      <span className="resultData">
                        ${dataRequest?.shippingPrice}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Tienda:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.store ? dataRequest?.store : 'N/A'}
                      </span>
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Usuario:
                      <span className="resultData">
                        {' '}
                        {dataRequest?.fullName ? dataRequest?.fullName : 'N/A'}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            )}
          </>
        }
      />

      <LayoutTable
        title="Productos"
        data={dataToTable}
        paginationServer={false}
        // hideActions={true}
        hideDetails={true}
        loadingData={loading}
        searchByProperty="sku"
        FilterBarPlaceholder="Buscar por sku..."
        alertColor="#42d54d"
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        customActionButtons={[
          {
            className: 'btn btn-accept btn-sm',
            handleButton: handleButton,
            buttonText: 'Modificar Etiqueta',
          },

          // {
          //   className: 'btn btn-danger btn-sm',
          //   handleButton: removePackingList,
          //   buttonText: <i className="fa fa-trash"></i>,
          // },
        ]}
      />
      <ChangeOrderLabelModal
        refModal={refModal}
        data={dataToEdit}
        id={_id}
        box_id={skipedBox}
      />
    </>
  )
}

export default StoreOrdersVerificationNew
