import { gql } from '@apollo/client'

export const GET_ALL_TRANSFERS_FINANCES_COUNTER_CHARGES = gql`
  query GetAllTransfersFinancesCounterCharges(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $isTransfer: Boolean
    $dateFrom: String
    $dateTo: String
    $idBankOrigin: Int
  ) {
    getAllTransfersFinancesCounterCharges(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      isTransfer: $isTransfer
      dateFrom: $dateFrom
      dateTo: $dateTo
      idBankOrigin: $idBankOrigin
    ) {
      count
      rows {
        id
        consecutive_number_of_payments
        id_bank
        id_bank_destination
        payment_amount
        payment_date
        transfer_concept
        nomenclature_bank
        consecutive_number
        nomenclature_date
        transfersToBankOrigin {
          bank_name
        }
        transfersToBankDestination {
          bank_name
        }
      }
    }
  }
`

export const GET_ONE_TRANSFERS_FINANCES_COUNTER_CHARGES_BY_ID = gql`
  query GetOneTransfersFinancesCounterCharges(
    $getOneTransfersFinancesCounterChargesId: Int
  ) {
    getOneTransfersFinancesCounterCharges(
      id: $getOneTransfersFinancesCounterChargesId
    ) {
      id_bank
      id_bank_destination
      consecutive_number_of_payments
      payment_amount
      payment_date
      transfer_concept
      transfersToBankOrigin {
        bank_name
      }
      transfersToBankDestination {
        bank_name
      }
    }
  }
`

export const CREATE_TRANSFERS_FINANCES_COUNTER_CHARGES = gql`
  mutation CreateTransfersFinancesCounterCharges(
    $inputTransfers: createTransfersInput
  ) {
    createTransfersFinancesCounterCharges(inputTransfers: $inputTransfers)
  }
`

export const UPDATE_TRANSFERS_FINANCES_COUNTER_CHARGES = gql`
  mutation UpdateTransfersFinancesCounterCharges(
    $updateTransfersFinancesCounterChargesId: Int
    $transferAmount: String
    $transferDate: String
    $transferConcept: String
    $transferDestinationBank: Int
  ) {
    updateTransfersFinancesCounterCharges(
      id: $updateTransfersFinancesCounterChargesId
      transfer_amount: $transferAmount
      transfer_date: $transferDate
      transfer_concept: $transferConcept
      transfer_destination_bank: $transferDestinationBank
    )
  }
`

export const GET_ALL_TRANSFERS_TO_EXPORT = gql`
  mutation GetAllTransfersFinancesCounterChargesToExport(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $isTransfer: Boolean
    $dateFrom: String
    $dateTo: String
    $idBankOrigin: Int
  ) {
    getAllTransfersFinancesCounterChargesToExport(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      isTransfer: $isTransfer
      dateFrom: $dateFrom
      dateTo: $dateTo
      idBankOrigin: $idBankOrigin
    ) {
      count
      rows {
        id
        consecutive_number_of_payments
        id_bank
        id_bank_destination
        payment_amount
        payment_date
        transfer_concept
        nomenclature_bank
        consecutive_number
        nomenclature_date
        is_active
        transfersToBankOrigin {
          bank_name
          bank_nomenclature
        }
        transfersToBankDestination {
          bank_name
        }
      }
    }
  }
`
