import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import {
  CREATE_DOCUMENT_TO_BILLING,
  DOWNLOAD_DOCUMENT_XML,
  GET_ALL_DOCUMENTS_TO_BILLING,
  RETRY_BILLING_DOCUMENT,
} from '../../graphql/Catalog/Billing/documenttobilling'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import ContentHeader from '../Layout/ContentHeader'
import Box from '../Global/Box'
import LayoutTable from '../Global/LayoutTable'
import Swal from 'sweetalert2'
import moment from 'moment'
import ModalContent from '../Global/ModalContent'
import FormToUploadDocumentBilling from './FormToUploadDocument/FormToUploadDocumentBilling'
import { currencyFormatToAny } from '../Helpers/HelperCurrencyFormat'
const DocumentToBilling = () => {
  const refModal = useRef(null)

  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [urlFile, setUrlFile] = useState(null)
  const [closeModal, setCloseModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { data, loading, error, refetch } = useQuery(
    GET_ALL_DOCUMENTS_TO_BILLING,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  // const [uploadCSV] = useMutation(CREATE_DOCUMENT_TO_BILLING)
  const [retryBilling] = useMutation(RETRY_BILLING_DOCUMENT)
  const [downloadFile] = useMutation(DOWNLOAD_DOCUMENT_XML)

  useEffect(() => {
    try {
      if (loading) return
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const documents = data.getAllDocumentsToBilling

      let listTable = documents.rows.map((item) => {
        let concatInvoice = `${item.serie}-${item.invoice}`
        return {
          id: item.id,
          Folio: item.invoiced ? concatInvoice : 'N/A',
          'Fecha timbrado':
            moment(item.invoice_stamped_date).format('DD/MM/YYYY HH:mm:ss') ||
            'N/A',
          subtotal:
            currencyFormatToAny({
              number: item.subtotal_invoice,
            }) || 'N/A',
          impuestos:
            currencyFormatToAny({
              number: item.tax_total,
            }) || 'N/A',
          total:
            currencyFormatToAny({
              number: item.total_invoice,
            }) || 'N/A',
          conditional_hide_button: item.invoiced,
        }
      })

      setDataTable(listTable)
      setTotalCount(documents.count)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [data, loading, error, refetch])

  const handleDownloadFile = async (id) => {
    try {
      console.log(id)
      toastSweetAlert({
        mode: 'loading',
        message: 'Descargando archivo, por favor espere...',
      })

      const { data } = await downloadFile({
        variables: {
          documentId: +id,
        },
        fetchPolicy: 'no-cache',
      })

      if (data.getFileInvoice) {
        setUrlFile(data.getFileInvoice)
        toastSweetAlert(
          {
            mode: 'ok',
            message: 'Archivo descargado correctamente',
          },
          document.getElementById('fileDownload').click()
        )

        return setUrlFile(null)
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleRetryBilling = async (id) => {
    try {
      toastSweetAlert({
        mode: 'loading',
        message: 'Reintentando facturar documento, por favor espere...',
      })

      const { data } = await retryBilling({
        variables: {
          documentId: +id,
        },
        fetchPolicy: 'no-cache',
      })

      if (data.retryBillingDocument) {
        await refetch()
        return toastSweetAlert({
          mode: 'ok',
          message: 'El documento se ha reintentado facturar correctamente',
        })
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message ? error.message : 'Error al reintentar facturar',
      })
    }
  }

  const HandleImportFile = async () => {
    try {
      document.getElementById('spanModalUploadFile').click()
      setOpenModal(true)
      // let optionsYears = []
      // let currentMonth = moment().format('MM')
      // let currentYear = moment().format('YYYY')
      // let selectedYear = currentYear
      // let beforeYear = moment().subtract(1, 'year').format('YYYY')
      // console.log(currentYear, beforeYear)
      // optionsYears.push(
      //   {
      //     value: currentYear,
      //     label: currentYear,
      //   },
      //   {
      //     value: beforeYear,
      //     label: beforeYear,
      //   }
      // )
      // let findMonth = OPTIONS_MONT.find((item) => item.value === currentMonth)
      // const handleValidateField = (e) => {
      //   const file = document.getElementById('file')
      //   if (!file.files[0]) {
      //     Swal.showValidationMessage('Seleccione un archivo')
      //   }
      //   return file.files[0]
      // }
      // const response = await toastSweetAlert({
      //   mode: 'modalConfirmContent',
      //   icon: 'info',
      //   message: '¿Desea importar el archivo?',
      //   content: (
      //     <>
      //       <div
      //         className="row"
      //         style={{
      //           display: 'flex',
      //           justifyContent: 'center',
      //           alignItems: 'center',
      //         }}
      //       >
      //         <label>
      //           <b>Seleccione el mes y año de facturación</b>
      //         </label>
      //         <div className="col-lg-5 ml-2">
      //           <Select
      //             id="monthId"
      //             menuPortalTarget={document.body}
      //             placeholder="Seleccione el mes de facturación"
      //             styles={{
      //               menu: (provided) => ({ ...provided, zIndex: 9999 }),
      //               menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      //             }}
      //             // value={findMonth}
      //             defaultValue={findMonth}
      //             options={OPTIONS_MONT}
      //             menuPosition="fixed"
      //             onChange={(e) => {
      //               currentMonth = e.value
      //               findMonth = e
      //               // selectedMonth = e
      //               // setSelectedMonth(e)
      //             }}
      //           />
      //         </div>
      //         <div className="col-lg-5 mr-2">
      //           <Select
      //             id="yearId"
      //             menuPortalTarget={document.body}
      //             placeholder="Seleccione el año de facturación"
      //             styles={{
      //               menu: (provided) => ({ ...provided, zIndex: 9999 }),
      //               menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      //             }}
      //             // value={findMonth}
      //             defaultValue={optionsYears[0]}
      //             options={optionsYears}
      //             menuPosition="fixed"
      //             onChange={(e) => {
      //               selectedYear = e.value
      //             }}
      //           />
      //         </div>
      //       </div>
      //       <hr />
      //       <div
      //         className="col-lg-11"
      //         style={{
      //           marginTop: '10px',
      //         }}
      //       >
      //         <input id="file" type="file" />
      //         <label htmlFor="file">
      //           <i className="fas fa-upload"></i> Seleccionar archivo
      //         </label>
      //       </div>
      //     </>
      //   ),
      //   validator: handleValidateField,
      // })
      // if (response.isConfirmed) {
      //   // toastSweetAlert({
      //   //   mode: 'loading',
      //   //   message: 'Procesando archivo, por favor espere...',
      //   // })
      //   const files = document.getElementById('file')
      //   const upload = await uploadCSV(
      //     {
      //       variables: {
      //         file: files.files[0],
      //         month: currentMonth,
      //         year: selectedYear,
      //       },
      //       fetchPolicy: 'no-cache',
      //     },
      //     toastSweetAlert({
      //       mode: 'loading',
      //       message: 'Procesando archivo, por favor espere...',
      //     })
      //   )
      //   if (upload.data.createDocumentToBilling) {
      //     await refetch()
      //     return toastSweetAlert(
      //       {
      //         mode: 'ok',
      //         message: 'El archivo se ha procesado correctamente',
      //       }
      //       // setFileUpload(true)
      //       // setTimeout(() => {
      //       //   setFileUpload(true)
      //       // }, 1000)
      //     )
      //   }
      // }
    } catch (error) {
      await refetch()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <ContentHeader title="" windowTitle="Facturación" />

      <Box
        title="Documentos a facturar"
        content={
          <>
            <LayoutTable
              withCard={false}
              hideId
              data={dataTable}
              totalCount={totalCount}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              handleConditionalButtonTitle={<i class="fas fa-sync"></i>}
              handleConditionalButton={handleRetryBilling}
              actionBtnEdit={handleDownloadFile}
              editText={<i class="far fa-file-code fa-lg"></i>}
              extraButtonsImportExport={
                <>
                  <span
                    className="dropdown-item item-click"
                    onClick={(row) => HandleImportFile(row)}
                  >
                    <i class="fas fa-file-upload"></i> Subir archivo para
                    facturación
                  </span>
                </>
              }
              templateDataImport={[
                {
                  sku: '',
                  descripcion: '',
                  cantidad: '',
                  precio_unitario: '',
                  valor_impuesto: '',
                  subtotal: '',
                  impuestos: '',
                  total: '',
                  codigo_sat: '',
                },
              ]}
              desactiveBOM={false}
            />
          </>
        }
      />
      <a id="fileDownload" href={urlFile} _target="blank"></a>

      <ModalContent
        idModal="modalUploadFile"
        title="Subir archivo para facturación"
        size="extra"
        onClose={() => setCloseModal(true)}
        content={
          <>
            <FormToUploadDocumentBilling
              refModal={refModal}
              refetch={refetch}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </>
        }
      />
      <span
        id="spanModalUploadFile"
        ref={refModal}
        className="btn btn-sm invisible"
        data-toggle="modal"
        data-target="#modalUploadFile"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
        // onClick={handleProgramPaymentsForButton}
      ></span>
    </>
  )
}

export default DocumentToBilling
