import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER_FOR_PRINT } from '../../../../../graphql/Catalog/Notes/notes'
import Box from '../../../../Global/Box'
import ContentHeader from '../../../../Layout/ContentHeader'
import LayoutTable from '../../../../Global/LayoutTable'
import PrintFirstTicket from './PrintFirstTicket'
import ReactToPrint from 'react-to-print'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import PrintSecondTicket from './PrintSecondTicket'

const DisplayInfoNoteForPrint = ({
  consecutiveNumber,
  isManualNote,
  setIsManualNote,
}) => {
  console.log('Consecutive Number: ', consecutiveNumber)

  const componentRef = useRef()
  const componentRefSecondTicket = useRef()
  const history = useHistory()
  const [infoNote, setInfoNote] = useState(null)
  const [infoNoteBills, setInfoNoteBills] = useState([])
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [valueSecondTicket, setValueSecondTicket] = useState(null)
  const [totalRetention, setTotalRetention] = useState(null)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [getOneNoteByConsecutiveForPrint] = useLazyQuery(
    GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER_FOR_PRINT
  )

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const handleGetInfoNote = async () => {
    const { data } = await getOneNoteByConsecutiveForPrint({
      variables: {
        consecutiveNumber: consecutiveNumber,
      },
      fetchPolicy: 'no-cache',
    })

    let bills = []
    let second =
      data?.getOneNoteBysConsecutiveNumberForPrint?.partnerNotes?.second_ticket
    console.log(second)
    let totalSecondTicket = Number(
      parseFloat(data?.getOneNoteBysConsecutiveNumberForPrint?.amount_note) -
        parseFloat(data?.getOneNoteBysConsecutiveNumberForPrint?.retention_note)
    ).toFixed(2)
    data.getOneNoteBysConsecutiveNumberForPrint.bills.map((item) => {
      return bills.push({
        id: item.id,
        Folio: item?.invoice_bill,
        Fecha: moment(item?.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        Monto: formmatterCurrency(item?.amount_bill),
        Descripción: item?.description ? item?.description : item?.retail,
        // provider: item?.provider_name_nm,
      })
    })

    setInfoNoteBills(bills)
    setValueSecondTicket(second)
    setTotalRetention(totalSecondTicket)
    setInfoNote(data?.getOneNoteBysConsecutiveNumberForPrint)
    console.log(data?.getOneNoteBysConsecutiveNumberForPrint)
    document.getElementById('PrintTicket').click()
  }

  const handlePrintSecondTicket = async () => {
    if (valueSecondTicket) {
      document.getElementById('PrintSecondTicket').click()
    } else {
      handleRePrintTicket()
    }
  }

  const handleRePrintTicket = async () => {
    const responseRePrint = await toastSweetAlert({
      mode: 'modalConfirmCancel',
      icon: 'info',
      message: '¿Desea re-imprimir el contrarecibo?',
    })

    if (responseRePrint.isConfirmed) {
      handleGetInfoNote()
    } else {
      const response = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'success',
        message: isManualNote
          ? 'La nota manual se ha creado correctamente. ¿Desea generar otra nota manual?'
          : 'El contrarecibo se ha creado correctamente. ¿Desea generar otro contrarecibo?',
      })
      if (response.isConfirmed) {
        if (isManualNote) {
          history.push('/catalog/notes/manualnote')
        } else {
          history.push('/catalog/notes/notenew')
        }
      }
    }
  }

  // const handlePrint = () => {
  //   setData
  // }

  useEffect(() => {
    if (consecutiveNumber) {
      handleGetInfoNote()
    }
  }, [consecutiveNumber])

  return (
    <>
      <ContentHeader
        title="Información de Contra-Recibo"
        windowTitle="Información de Contra-Recibo"
        breadcrumb="Información de Contra-Recibo"
      />
      <Box
        // title="Información de Contra-Recibo"
        btnLoading={loadingBtn}
        // btnFunctionTitle="Generar nuevo contrarecibo"
        // btnFunction={() => history.push('/catalog/notes/notenew')}
        content={
          <>
            <div className="mt-3 col-12">
              {infoNote && (
                <div className="boxQuotesProvider">
                  <div className="row">
                    {infoNote?.partnerNotes && (
                      <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                        <h4
                          style={{
                            color: '#000',
                          }}
                        >
                          Proveedor: &nbsp;
                          <span className="resultData">
                            {infoNote?.partnerNotes?.provider_name}
                          </span>
                        </h4>
                      </div>
                    )}
                    {infoNote?.partnerNotes && (
                      <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                        <div className="boxItem">
                          <h4
                            style={{
                              color: '#000',
                            }}
                          >
                            RFC: &nbsp;
                            <span className="resultData">
                              {infoNote?.partnerNotes?.provider_rfc}
                            </span>
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <h4
                        style={{
                          color: '#000',
                        }}
                      >
                        No. Consecutivo: &nbsp;
                        <span className="resultData">
                          {infoNote?.consecutive_number}
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                      <h4
                        style={{
                          color: '#000',
                        }}
                      >
                        Fecha de Nota: &nbsp;
                        <span className="resultData">
                          {moment(
                            infoNote?.date_note,
                            'YYYY-MM-DD hh:mm:ss a'
                          ).format('DD/MM/YYYY hh:mm:ss a')}
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                      <h4
                        style={{
                          color: '#000',
                        }}
                      >
                        Monto Total: &nbsp;
                        <span className="resultData">
                          {infoNote?.amount_note
                            ? formmatterCurrency(infoNote?.amount_note)
                            : 'No registrado'}
                        </span>
                      </h4>
                    </div>
                    {infoNote?.retention_note > 0 && (
                      <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                        <h4
                          style={{
                            color: '#000',
                          }}
                        >
                          Monto retencion: &nbsp;
                          <span className="resultData">
                            {infoNote?.retention_note
                              ? formmatterCurrency(infoNote?.retention_note)
                              : 'No registrado'}
                          </span>
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <LayoutTable
              withCard={false}
              hideId
              data={infoNoteBills}
              hideActions
              hideFilterBar
              exportImportButton={false}
              hideAddNew
              paginationServer={false}
            />

            <div className="hide">
              <PrintFirstTicket
                componentRef={componentRef}
                dataNote={infoNote}
                secondTicket={valueSecondTicket}
              />
              <ReactToPrint
                trigger={() => (
                  <button id="PrintTicket">Print this out!</button>
                )}
                content={() => componentRef.current}
                copyStyles={false}
                pageStyle={`@page { size: 80mm auto; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 0mm; }`}
                onAfterPrint={() => handlePrintSecondTicket()}
              />
            </div>
            <div className="hide">
              <PrintSecondTicket
                componentRef={componentRefSecondTicket}
                TotalRetention={totalRetention}
                consecutiveNumber={consecutiveNumber}
              />
              <ReactToPrint
                trigger={() => (
                  <button id="PrintSecondTicket">Print this out!</button>
                )}
                content={() => componentRefSecondTicket.current}
                pageStyle="@page { size: 80mm auto; page-break-after: always; }"
                onAfterPrint={() => handleRePrintTicket()}
              />
            </div>
          </>
        }
      />
    </>
  )
}

export default DisplayInfoNoteForPrint
