import { gql } from '@apollo/client'

export const GET_POSITION_ORDERS = gql`
  query GetAllPositionOrders(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $type: Int
    $date: Int
  ) {
    getAllPositionOrders(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      type: $type
      date: $date
    ) {
      count
      rows {
        id
        item_code
        type_id
        rack_destination_id
        RackPosition {
          id
          name
          capacity
          code
          reorder
        }
        is_open
        total_products
        creation_date
        warehouse_id
        user_id
        ProductPositionOrders {
          id
        }
      }
    }
  }
`
export const GET_ONE_POSITION_ORDER = gql`
  query GetOnePositionOrder($getOnePositionOrderId: Int!) {
    getOnePositionOrder(id: $getOnePositionOrderId) {
      id
      type_id
      item_code
      rack_destination_id
      RackPosition {
        id
        name
        code
        capacity
        reorder
        warehouse_id
        zone_id
      }
      is_open
      total_products
      creation_date
      warehouse_id
      user_id
      ProductPositionOrders {
        id
        position_order_id
        rack_id
        quantity
        item_code
        took
        RackPosition {
          id
          name
          code
          zone_id
          RackZone {
            id
            name
          }
          warehouse_id
          reorder
          capacity
        }
        SapItem {
          item_name
        }
      }
    }
  }
`
