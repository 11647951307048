import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { DELETE_SHIPPING_COMPANY, GET_ALL_SHIPPING_COMPANIES_ROWS } from "../../../graphql/Catalog/ShippingCompanies/shippingCompanies"
import LayoutTable from "../../Global/LayoutTable"
import { toastSweetAlert } from "../../Helpers/ToastSweetAlert"
import ContentHeader from "../../Layout/ContentHeader"

const ReceiptParcelsRegisters =()=> {
    //---------- Hooks
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })

    //Get Vehicles
    const [shippingCompany,setShippingCompany] = useState([])
    //Get total count
    const [total,setTotal] = useState(0)

    //Queries
    const { data, loading, error } = useQuery(GET_ALL_SHIPPING_COMPANIES_ROWS, {
        variables: {
          searchQuery: pagePagination.searchQuery,
          limit: pagePagination.limit,
          offset: pagePagination.offset,
        },
    })

    //---------- useEffects
    useEffect(()=> {
        if(!loading){
            if(error) return toastSweetAlert({
                mode: 'error',
                message: error,
            })

            //Get Vehicles data
            let list = []
            data.getAllShippingCompaniesPagination.rows.map((itemShippingCompany) => {
                list.push({
                    id: itemShippingCompany.id,
                    nombre: itemShippingCompany.name,
                })
            })

            //Set data
            setShippingCompany(list)
            //Set total
            setTotal(data.getAllShippingCompaniesPagination.count)
        }
    },[loading,data,error])

    return (
        <>
            {/* <ContentHeader 
                title="Paqueteria de recibo" 
                breadcrumb="Paqueteria" 
                windowTitle="Paqueteria" 
            /> */}
            <LayoutTable
                data={shippingCompany}
                title="Paqueteria de recibo"
                gql={DELETE_SHIPPING_COMPANY}
                requery={GET_ALL_SHIPPING_COMPANIES_ROWS}
                totalCount={total}
                pagePagination={pagePagination}
                setPagePagination={setPagePagination}
                loadingData={loading}
                hideDetails={true}
                FilterBarPlaceholder="Buscar por Nombre"
            />
        </>
    )
}

export default ReceiptParcelsRegisters