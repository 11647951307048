import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { commodityReceiptValidator } from '../../Helpers/validatorCustomerForm'
import { yupResolver } from '@hookform/resolvers/yup'

import moment from 'moment'
import InputController from '../../Global/InputController'
import Box from '../../Global/Box'
import OrderCol from '../../Global/OrderCol'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import numeral from 'numeral'

export const Step4 = ({
  data,
  setData,
  handleSave,
  previousStep,
  resetStep,
  evidenceStep,
}) => {
  const [finish, setFinish] = useState(false)
  const [years, setYears] = useState([])
  const [valueExpDate, setValueExpDate] = useState(false)
  const [valueAñada, setValueAñada] = useState(false)
  const [valueSerialNumber, setValueSerialNumber] = useState(true)
  const [addSerialNumbers, setAddSerialNumbers] = useState([])
  const [checkRepeat, setCheckRepeat] = useState(null)

  const [loading, setLoading] = useState({
    state: false,
    finish: false,
    continue: false,
  })
  const schemaValidator = commodityReceiptValidator(valueExpDate, valueAñada)
  const {
    reset,
    methods,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schemaValidator) })

  useEffect(() => {
    if (data.product) {
      const { hasHarvest, expires, serial_number_management } =
        data.product.PurchasesLinesItemInfo

      setValueExpDate(expires)
      setValueAñada(hasHarvest)
      setValueSerialNumber(serial_number_management === 'Y' ? true : false)
    }
  }, [data.product])

  useEffect(() => {
    let year = moment().format('YYYY')
    let list = []
    for (let index = 1995; index <= year; index++) {
      list.unshift({ label: index, value: index })
    }
    list.unshift({ label: 'N/A', value: 100 })
    setValue('items', data.product.PurchasesLinesItemInfo.purchase_pack_un)
    setYears(list)
  }, [])

  useEffect(() => {
    if (checkRepeat) {
      handleScanSerie(checkRepeat)
      setCheckRepeat(null)
    }
  }, [checkRepeat])

  const onSubmit = (Data, isFinish) => {
    const {
      quantity,
      expDate,
      añada,
      items,
      boxes,
      beds,
      pallets,
      residueBottles,
      residueBox,
    } = Data
    try {
      // if (!añada) {
      //   throw new Error('Añada es requerida')
      // }
      if (pallets < 1 && !valueSerialNumber) {
        throw new Error('Se debe recibir al menos 1 pallet')
      }

      if (valueSerialNumber) {
        if (addSerialNumbers.length !== parseInt(quantity))
          throw new Error(
            `Se necesitan escanear ${quantity} números de serie. El total de números de serie escaneados fue de ${addSerialNumbers.length}`
          )
      }

      if (expDate) {
        const today = moment().startOf('day')
        const end = moment().add(179, 'days')
        if (!moment(expDate).isValid()) {
          throw new Error('Fecha de caducidad inválida')
        }
        if (moment(expDate).isBefore(today)) {
          throw new Error(
            'Fecha de caducidad no puede ser menor a la fecha actual'
          )
        }
        if (!moment(expDate).isAfter(end, 'days')) {
          throw new Error(
            'Fecha de caducidad no puede ser menor a 6 meses de la fecha actual'
          )
        }
      }
      const newPallets = pallets ? pallets : 1
      // const newExpDate = expDate ? expDate : moment().format('L')
      setLoading({
        ...loading,
        state: true,
        finish: finish ? true : false,
        continue: !finish ? true : false,
      })

      if (isFinish) setFinish(true)

      return handleSave(
        finish,
        quantity,
        expDate,
        añada,
        items,
        boxes,
        beds,
        newPallets,
        data.product.PurchasesLinesItemInfo.item_code,
        residueBottles,
        residueBox,
        valueSerialNumber ? addSerialNumbers : null
      )
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const calculateItems = () => {
    const beds = getValues('beds') ? getValues('beds') : 0
    const boxes = getValues('boxes') ? getValues('boxes') : 0
    const items = getValues('items') ? getValues('items') : 0
    const pallets = getValues('pallets') ? getValues('pallets') : 1

    // Residue
    const residueBox = getValues('residueBox') ? getValues('residueBox') : 0
    const residueBottles = getValues('residueBottles')
      ? getValues('residueBottles')
      : 0
    const residueTotal = items * residueBox + parseInt(residueBottles)
    const total = items * boxes * beds * pallets + residueTotal

    setValue('quantity', total)
  }

  // const calculateResidue = () => {
  //   const beds = getValues('beds')
  //   const items = getValues('items')
  //   const boxes = getValues('boxes')
  //   const residueBox = getValues('residueBox')
  //   const residueBottles = getValues('residueBottles')
  //   if (beds && boxes && items && residueBox && residueBottles) {
  //     const total =
  //       items * boxes * beds + items * residueBox + parseInt(residueBottles)
  //     return setValue('quantity', total)
  //   } else {
  //     calculateItems()
  //   }
  // }

  const handleScanSerie = (value) => {
    try {
      const isRepeated = addSerialNumbers.find(
        (serialNumber) => serialNumber === value
      )

      if (isRepeated) {
        setValue('scan_serial_number', '')
        return toastSweetAlert({
          mode: 'error',
          message: `El número de serie ${value} ya ha sido agregado`,
        })
      }
      setAddSerialNumbers((serialNumbers) => [...serialNumbers, value])
      setValue('scan_serial_number', '')
    } catch (error) {
      setValue('scan_serial_number', '')
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleDeleteSerie = (serialNumberToDelete) => {
    try {
      const newSerialNumbersToAdd = addSerialNumbers.filter(
        (serialNumber) => serialNumber !== serialNumberToDelete
      )
      setAddSerialNumbers(newSerialNumbersToAdd)
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  return (
    <FormProvider {...methods}>
      <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
        <Box
          title={'Cantidad recibida'}
          btnSubmitNoEnter={handleSubmit(() => onSubmit(getValues(), false))}
          btnSubmitNoEnterTxtUpdate="Continuar escaneo"
          btnSubmitNoEnterTxtSave="Continuar escaneo"
          btnSecondFunction={() => previousStep()}
          btnFunction={handleSubmit(() => onSubmit(getValues(), true))}
          btnFunctionTitle="Finalizar escaneo"
          content={
            <>
              <div className="row">
                {/* <OrderCol
                title="Sku"
                content={data.product.PurchasesLinesItemInfo.item_code}
                mediumColSize="3"
              /> */}
                <OrderCol
                  title=" SKU  / Nombre"
                  content={`${data.product.PurchasesLinesItemInfo.item_code}  / ${data.product.PurchasesLinesItemInfo.item_name}`}
                  mediumColSize="6"
                />
                {/* <OrderCol
                title="Código de barras"
                content={data.product.PurchasesLinesItemInfo.item_code_bar}
                mediumColSize="3"
              /> */}

                <OrderCol
                  title="Cantidad esperada"
                  content={
                    data.product.productLines.lines.length > 1
                      ? data.product.productLines.productLinesText
                      : numeral(data.product.open_quantity).format('0,0')
                  }
                  mediumColSize="3"
                />
                {/* <OrderCol
                title="Evidencias"
                mediumColSize="4"
                content={
                  <span
                    className="btn StandarModalConfirmButtonLogOut"
                    onClick={evidenceStep}
                  >
                    Tomar de evidencias
                  </span>
                }
              />
              <OrderCol
                title="Galería"
                mediumColSize="12"
                content={
                  <div className="gallery">
                    {data.photos.map((item, index) => (
                      <div key={index} className="col-3 gallery-item">
                        <img src={item} alt="img" className="" />
                      </div>
                    ))}
                  </div>
                }
              /> */}
                <div className="row">
                  <div className="col-6">
                    <InputController
                      label="Piezas / caja"
                      type="number"
                      name="items"
                      changeAction={calculateItems}
                      placeholder="Ingrese cantidad"
                      control={control}
                    />
                  </div>
                  <div className="col-6">
                    <InputController
                      label="Cajas / cama"
                      type="number"
                      name="boxes"
                      changeAction={calculateItems}
                      placeholder="Ingrese valor"
                      control={control}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <InputController
                      label="Camas"
                      type="number"
                      name="beds"
                      changeAction={calculateItems}
                      placeholder="Ingrese cantidad"
                      control={control}
                    />
                  </div>
                  <div className="col-6">
                    <InputController
                      label="Residuo cajas"
                      type="number"
                      name="residueBox"
                      changeAction={calculateItems}
                      placeholder="Ingrese cantidad"
                      control={control}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-6">
                    <InputController
                      label="Residuo botellas"
                      type="number"
                      name="residueBottles"
                      changeAction={calculateItems}
                      placeholder="Ingrese cantidad"
                      control={control}
                    />
                  </div>
                  {!valueSerialNumber && (
                    <div className="col-6">
                      <InputController
                        min="1"
                        label="Pallets"
                        type="number"
                        name="pallets"
                        placeholder="Ingrese cantidad"
                        changeAction={calculateItems}
                        control={control}
                      />
                    </div>
                  )}
                </div>

                {/* <OrderCol mediumColSize="4" content={<></>} /> */}
                <OrderCol
                  mediumColSize="4"
                  content={
                    <InputController
                      label="Cantidad"
                      type="number"
                      name="quantity"
                      value={numeral(data.quantity).format('0,0')}
                      placeholder="Ingrese cantidad"
                      control={control}
                      readOnly
                    />
                  }
                />

                {valueExpDate && (
                  <OrderCol
                    mediumColSize="4"
                    content={
                      <InputController
                        label="Fecha de caducidad"
                        type="date"
                        name="expDate"
                        placeholder="Ingrese cantidad recibida"
                        control={control}
                      />
                    }
                  />
                )}
                {valueAñada && (
                  <OrderCol
                    mediumColSize="4"
                    content={
                      <InputController
                        label="Añada"
                        type="text"
                        name="añada"
                        inputType="choosen"
                        options={years}
                        control={control}
                      />
                    }
                  />
                )}
              </div>

              {valueSerialNumber && (
                <>
                  <h3 className="header-title mt-3">Numeros de serie</h3>
                  <div className="row mt-3">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <InputController
                        label="Escanear series"
                        type="text"
                        name="scan_serial_number"
                        inputType="text"
                        control={control}
                        changeAction={(e) =>
                          setCheckRepeat(`${e.target.value}`)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div
                      className={`col-12 col-sm-12 col-md-12 col-lg-4 border ${
                        addSerialNumbers.length > 0 && 'providersTableBox'
                      }`}
                    >
                      {addSerialNumbers.length > 0 ? (
                        <>
                          <ul className="list-group list-group-flush">
                            {addSerialNumbers
                              .toReversed()
                              .map((serialNumber, index) => {
                                return (
                                  <>
                                    <li
                                      key={`serial_number${index}`}
                                      className="list-group-item d-flex justify-content-between align-items-center"
                                    >
                                      {serialNumber}
                                      <span
                                        className="btn btn-danger"
                                        onClick={() =>
                                          handleDeleteSerie(serialNumber)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </span>
                                    </li>
                                  </>
                                )
                              })}
                          </ul>
                        </>
                      ) : (
                        <h5 className="text-center mt-5 mb-5">
                          Sin escaneos que mostrar
                        </h5>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 text-right">
                      <span>{`${addSerialNumbers.length} números de serie escaneados`}</span>
                    </div>
                  </div>
                </>
              )}
            </>
          }
        />
      </form>
    </FormProvider>
  )
}
