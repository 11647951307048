import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_SALES_BY_CHANNEL } from '../../../../graphql/Catalog/ProvidersDashboard/providersDashboard'
import Box from '../../../Global/Box'
import PercentageBar from '../../../Global/PercentageBar/PercentageBar'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'

const SalesByChannel = ({ title, type }) => {
  const [data, setData] = useState([
    {
      sale: 15458,
      name: 'Telemarketing',
      percentage: 74,
    },
    {
      sale: 5482,
      name: 'Centros de consumo',
      percentage: 14,
    },
    {
      sale: 2458,
      name: 'E-commerce',
      percentage: 6,
    },
    {
      sale: 2458,
      name: 'Mercado libre',
      percentage: 2,
    },
    {
      sale: 2458,
      name: 'Amazon',
      percentage: 2,
    },
  ])

  const {
    data: salesByChannelData,
    loading: salesByChannelLoading,
    error: salesByChannelError,
  } = useQuery(GET_SALES_BY_CHANNEL, { variables: { type } })

  useEffect(() => {
    if (!salesByChannelLoading) {
      if (salesByChannelError) {
        return toastSweetAlert({
          mode: 'error',
          message: salesByChannelError.message,
        })
      }
      // console.log(salesByChannelData.getSalesByChannel)
      // setData(salesByChannelData.getSalesByChannel)
    }
  }, [salesByChannelData, salesByChannelLoading, salesByChannelError])

  return (
    <>
      <Box
        title={`Ventar por canal:  ${title}`}
        content={
          <>
            {/* <h3 className="font-weight-bold text-dark">{data.totalSales}</h3> */}
            <h3 className="font-weight-bold text-dark">23,214</h3>
            <p className="font-weight-bold">Total de ventas</p>

            <div className="mt-3  table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Ventas</th>
                    <th>Canal</th>
                    <th>Porcentaje</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // data.warehouses &&
                    //data.warehouses.map
                    data.map((item, i) => (
                      <tr key={`${item.sale}-${i}`}>
                        <td style={{ width: '0%' }}> {item.sale}</td>
                        {/* <td style={{ width: '10%' }}>{item.name}</td> */}
                        <td style={{ width: '30%' }}>{item.name}</td>
                        <td style={{ width: '40%' }}>
                          <PercentageBar
                            percentage={item.percentage}
                            smallBar={true}
                          />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        }
      />
    </>
  )
}

export default SalesByChannel
