import React, { useEffect, useState } from 'react'
import OrderCol from '../Global/OrderCol'

function OrderGeneral({
  platform = '',
  paymentId = '',
  orderId = '',
  orderDate = '',
  withPlataform = true,
  mediumColSize = '3',
}) {
  return (
    <div className="d-lg-flex d-md-block row">
      {withPlataform && (
        <OrderCol title="Plataforma" content={platform ? platform : '-'} />
      )}
      <OrderCol
        mediumColSize={mediumColSize}
        title="Número de cobro"
        content={paymentId ? paymentId : '-'}
      />
      <OrderCol
        mediumColSize={mediumColSize}
        title="Número de venta"
        content={orderId ? orderId : '-'}
      />
      <OrderCol
        mediumColSize={mediumColSize}
        title="Fecha y hora"
        content={orderDate ? orderDate : '-'}
      />
    </div>
  )
}

export default OrderGeneral
