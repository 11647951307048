import { useQuery, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
  GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE,
  CHANGE_INVENTORY_ACTIVITIES_STATUS_SCHEDULE,
} from '../../../graphql/InventoryActivities/InventoryActivitiesSchedule'

/*
    ===Status===
    1: Pendiente
    2: In review
    3: finalizado
    4: Cancelado
*/

const InventoryActivitiesLive = () => {
  const history = useHistory()
  const [loadingBtn, setLoading] = useState(false)
  const [dataTable, setDataTable] = useState({
    items: [],
    total: 0,
  })
  const [pageDatesHistoricPagination, setPageDatesHistoricPagination] =
    useState({
      searchQuery: null,
      limit: 30,
      offset: 0,
      status: [1, 2],
      typeSchedule: null,
    })
  const inventorySchedule = useQuery(
    GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE,
    {
      variables: pageDatesHistoricPagination,
      fetchPolicy: 'no-cache',
    }
  )

  const [changeStatus] = useMutation(
    CHANGE_INVENTORY_ACTIVITIES_STATUS_SCHEDULE
  )

  /* USEEFFECTS */
  useEffect(() => {
    try {
      if (inventorySchedule.loading) return
      if (inventorySchedule.error) {
        return toastSweetAlert({
          mode: 'error',
          message: inventorySchedule.error.message,
        })
      }
      const list =
        inventorySchedule?.data?.getAllInventoryActivitiesHistoricSchedule?.rows.map(
          (item) => {
            return {
              id: item?.id,
              Almacén:
                item?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
              'Fecha Inicio': moment(item?.start_date).format('LLL'),
              Estatus: getStatus(item?.status),
              status: item?.status,
              Tipo:
                item.type_schedule === 1
                  ? `Conteo cíclico / ${
                      item.ubications_or_skus === 1 ? 'Ubicaciones' : 'SKUS'
                    }`
                  : 'TFI',
              type_schedule_omitField: item.type_schedule,
            }
          }
        )
      setDataTable({
        items: list,
        total: list.length,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    inventorySchedule?.loading,
    inventorySchedule?.data,
    inventorySchedule?.error,
  ])

  /* FUNCTIONS */
  const getStatus = (status) => {
    return (
      <span
        className={
          status === 1
            ? 'badge status-assignment bg-pendiente'
            : 'badge status-assignment bg-iniciada'
        }
      >
        {status === 1 ? 'PENDIENTE' : 'INICIADA'}
      </span>
    )
  }

  const getActionBtn = (row) => {
    if (row.status === 2)
      return (
        <span
          onClick={() => history.push(`/inventory/live/edit/${row.id}`)}
          className="btn btn-accept btn-sm"
        >
          Ver detalles
        </span>
      )

    return (
      <span onClick={() => handleStart(row)} className="btn btn-accept btn-sm">
        Iniciar
      </span>
    )
  }

  const handleStart = async (row) => {
    try {
      setLoading(true)
      await changeStatus({
        variables: {
          id: parseInt(row.id),
          status: 2,
        },
      })

      setLoading(false)
      await inventorySchedule.refetch()
      return await toastSweetAlert(
        {
          mode: 'ok',
          message: `Se ha iniciado ${
            row.type_schedule_omitField === 2 ? 'la TFI' : 'el conteo cíclico'
          } correctamente`,
        },
        history.push(`/inventory/live/edit/${row.id}`)
      )
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <ContentHeader title="Live" breadcrumb="Live" windowTitle={`Live`} />
      <div className={`${loadingBtn ? 'processing-file' : ''}`}>
        <LayoutTable
          title="Lista de TFIs/Conteos cíclicos"
          hideId
          hideAddNew
          exportImportButton={false}
          data={dataTable.items}
          totalCount={dataTable.total}
          pagePagination={pageDatesHistoricPagination}
          setPagePagination={setPageDatesHistoricPagination}
          paginationPerPage={30}
          hideBtnEdit={true}
          hideBtnErase
          hideDetails
          componentAction={(row) => {
            return <>{getActionBtn(row)}</>
          }}
          hideColumns={['status']}
          filter
          filterOptions={[
            <option value="1" key="1">
              Conteos Cíclicos
            </option>,
            <option value="2" key="2">
              Toma física de inventario
            </option>,
          ]}
          filterOnChange={(e) =>
            setPageDatesHistoricPagination({
              ...pageDatesHistoricPagination,
              typeSchedule: parseInt(e.target.value),
            })
          }
        />
      </div>
    </>
  )
}

export default InventoryActivitiesLive
