import { gql } from '@apollo/client'

export const GET_ALL_USERS_APP = gql`
  query GetAllAppUsers($searchQuery: String, $limit: Int, $offset: Int) {
    getAllAppUsers(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        name
        first_name
        last_name
        id
        username
        userType {
          name
        }
        warehouse {
          id
          warehouse_name
        }
      }
      count
    }
  }
`

export const DELETE_USER_APP = gql`
  mutation DeleteAppUser($id: Int!) {
    deleteAppUser(id: $id)
  }
`

export const CREATE_USER_APP = gql`
  mutation CreateAppUser($input: AppUserInput!) {
    createAppUser(input: $input)
  }
`

export const UPDATE_USER_APP = gql`
  mutation UpdateAppUser($updateAppUserId: Int!, $input: AppUserUpdateInput!) {
    updateAppUser(id: $updateAppUserId, input: $input)
  }
`

export const GET_APP_USER = gql`
  query GetAppUser($getAppUserId: Int!) {
    getAppUser(id: $getAppUserId) {
      id
      name
      first_name
      last_name
      phone
      email
      username
      id_type
      warehouse_id
    }
  }
`
export const GET_APP_USER_TYPES = gql`
  query GetAppUsersTypes {
    getAppUsersTypes {
      id
      name
    }
  }
`

export const GET_APP_USERS_BY_TYPE = gql`
  query GetAllUserByType(
    $type: String!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllUserByType(
      type: $type
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        email
        first_name
        id_type
        id
        last_name
        name
        userType {
          id
          name
        }
      }
    }
  }
`

export const ASSIGN_PICKER_TO_TRANSFER = gql`
  mutation AssignPickerToTransfer($idUser: Int!, $orderWarehouseId: Int!) {
    assignPickerToTransfer(idUser: $idUser, orderWarehouseId: $orderWarehouseId)
  }
`

export const GET_PICKERS_AND_TRANSFER_PICKERS_USERS = gql`
  query GetPickersAndTransferPickersUsers(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $type: [String!]
  ) {
    getPickersAndTransferPickersUsers(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      type: $type
    ) {
      count
      rows {
        name
        id
        id_type
        last_name
        first_name
        userType {
          id
          name
        }
      }
    }
  }
`
