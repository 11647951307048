import moment from 'moment'
import { useState } from 'react'
import ModalContent from '../../../Global/ModalContent'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import GenerateReportModal from './GenerateReportAlertModal'
import { validateCustomReport } from './validateForm'

const ReportModal = ({ 
  refModal, 
  refGenerateModal, 
  reports, //Hooks value
  setReports, //Hook to save data
  handleSubmit = ()=> {}, //Submit Function.
  dataType = 1,
}) => {

  /**Hook to show inputs dates */
  const [showDateRange, setShowDateRange] = useState(false)

  /**------- Handle functions ------ */

  //To listen radio buttons changes
  const handleRadioOnChange = (e,date = 0) => {
    try {
      if (e.target.id == 'custom-report' || date != 0) {
        setShowDateRange(true)
      } else {
        setShowDateRange(false)
      }

      if(date == 1 || date == 2)//Date start or end
        setReports({
          ...reports,
          reportsTypeRangeStart: date == 1 ? e.target.value : reports.reportsTypeRangeStart,
          reportsTypeRangeEnd: date == 2 ? e.target.value : reports.reportsTypeRangeEnd
        })
      else
        setReports({
          ...reports,
          reportsType: Number(e.target.value)
        })
    } catch (error) {
      return
    }
  }

  //To listen inputs from kind of data and documents
  const handleChange = (e,type) => {
    try {
      let list = type == 1 ? reports.dataType :reports.documentsType

      let findValue = list.find((item) => item == e.target.value)

      if(findValue) list = list.filter((item) => item != e.target.value)
      else list.push(Number(e.target.value))

      setReports({
        ...reports,
        [type == 1 ? 'dataType' : 'documentsType']: list,
      })
    } catch (error) {
      return
    }
  }

  //When user touch cancel button
  const handleCancelButton = () => {
    //Reset hook
    setReports({
      reportsType: dataType == 2 ? 4 : 0,
      reportsTypeRangeStart: '',
      reportsTypeRangeEnd: '',
      dataType: dataType == 1 || dataType == 2 ? [dataType]: [],
      documentsType: [],
    })
    setShowDateRange(false)
    refModal.current.click()
  }

  //Submit form
  const handleDownloadButton = () => {
    try {
      const res = validateCustomReport(reports)
      if(res.error)
        return toastSweetAlert({
          mode: 'error',
          message: res.msg,
        })

      let newReports = reports

      //Reset hook
      setReports({
        reportsType: dataType == 2 ? 4 : 0,
        reportsTypeRangeStart: '',
        reportsTypeRangeEnd: '',
        dataType: dataType == 1 || dataType == 2 ? [dataType]: [],
        documentsType: [],
      })
      setShowDateRange(false)

      refGenerateModal.current.click()
      refModal.current.click()
      handleSubmit(newReports)

    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: 'Ingrese valores correctos',
      })
    }
  }

  return (
    <>
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#detailsReports"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="detailsReports"
        title="Generar reporte"
        size="large"
        onClose={handleCancelButton}
        content={
          <>
            {dataType != 2 && (
              <>
                <h5 className="">Tipo de Reporte</h5>
                <hr />

                <div className="my-4">
                  <div className="d-flex align-items-center mb-2">
                    <input
                      type="radio"
                      id="year-to-date"
                      name="report"
                      value={1}
                      checked={reports.reportsType == 1 ? true : false}
                      onChange={(e) => handleRadioOnChange(e,0)}
                    />
                    <label
                      className="m-0 ml-2 font-weight-normal"
                      htmlFor="year-to-date"
                    >
                      Año a la fecha
                    </label>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <input
                      type="radio"
                      id="rolling-year"
                      name="report"
                      value={2}
                      checked={reports.reportsType == 2 ? true : false}
                      onChange={(e) => handleRadioOnChange(e,0)}
                    />
                    <label
                      className="m-0 ml-2 font-weight-normal"
                      htmlFor="rolling-year"
                    >
                      Últimos 12 meses
                    </label>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <input
                      type="radio"
                      id="custom-report"
                      name="report"
                      value={3}
                      checked={reports.reportsType == 3 ? true : false}
                      onChange={(e) => handleRadioOnChange(e,0)}
                    />
                    <label
                      className="m-0 ml-2 font-weight-normal"
                      htmlFor="custom-report"
                    >
                      Rango de tiempo personalizado
                    </label>
                  </div>
                </div>
              </>
            )}

            {showDateRange && (
              <div className="row justify-content-evenly items-center mx-auto">
                <div className="col-4 d-flex align-items-center">
                  <p className="m-0">Desde: </p>
                  <input
                    className="inputDate mx-auto date-picker-size"
                    value={reports.reportsTypeRangeStart}
                    onChange={(e) => handleRadioOnChange(e,1)}
                    type="date"
                  />
                </div>
                <div className="col-4 d-flex align-items-center ">
                  <p className="m-0">Hasta:</p>
                  <input
                    className="inputDate mx-auto date-picker-size"
                    value={reports.reportsTypeRangeEnd}
                    onChange={(e) => handleRadioOnChange(e,2)}
                    type="date"
                  />
                </div>
              </div>
            )}

            {
              dataType == 3 && (
                <>
                  <h5 className={`${dataType != 2 && 'mt-3'} `}>Tipo de información</h5>
                  <hr />
                  {/**Kind of data */}
                  <div className="d-flex align-items-center my-4">

                    <input
                      type="checkbox"
                      id="displacementsCheckBox"
                      value={1}
                      checked={reports.dataType.find((item) => item == 1) ? true : false}
                      onChange={(e) => handleChange(e,1)}
                    />
                    <label
                      className="m-0 ml-2 mr-3 font-weight-normal"
                      htmlFor="displacementsCheckBox"
                    >
                      Desplazamientos
                    </label>

                    {/* <input
                      type="checkbox"
                      id="inventoryCheckBox"
                      value={2}
                      checked={reports.dataType.find((item) => item == 2) ? true : false}
                      onChange={(e) => handleChange(e,1)}
                    />
                    <label
                      className="m-0 ml-2 mr-3 font-weight-normal"
                      htmlFor="inventoryCheckBox"
                    >
                      Inventarios
                    </label> */}

                  </div>
                </>
              )
            }

            <h5 className="mt-3">Tipo de documentos</h5>
            <hr />
            {/**Documents types */}
            <div className="d-flex align-items-center my-4">

              <input
                type="checkbox"
                id="csvCheckBox"
                value={1}
                checked={reports.documentsType.find((item) => item == 1) ? true : false}
                onChange={(e) => handleChange(e,2)}
              />
              <label
                className="m-0 ml-2 mr-3 font-weight-normal"
                htmlFor="csvCheckBox"
              >
                CSV
              </label>

              <input
                type="checkbox"
                id="pdfCheckBox"
                value={2}
                checked={reports.documentsType.find((item) => item == 2) ? true : false}
                onChange={(e) => handleChange(e,2)}
              />
              <label
                className="m-0 ml-2 mr-3 font-weight-normal"
                htmlFor="pdfCheckBox"
              >
                PDF
              </label>
              
            </div>

            <div className="d-flex justify-content-end mt-5">
              <button
                className="btn StandarModalCancelButtonLogOut text-capitalize mr-3"
                onClick={handleCancelButton}
              >
                Cancelar
              </button>
              <button
                className="btn StandarModalConfirmButtonLogOut"
                onClick={handleDownloadButton}
              >
                Generar reporte
              </button>
            </div>
          </>
        }
      />

      <GenerateReportModal refModal={refGenerateModal} />
    </>
  )
}

export default ReportModal
