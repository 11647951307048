import { gql } from '@apollo/client'

export const GET_ALL_OMNICHANNEL_PRODUCTS = gql`
  query GetAllOmnichannelProducts(
    $platformId: String
    $limit: Int
    $offset: Int
    $searchQuery: String
  ) {
    getAllOmnichannelProducts(
      platformId: $platformId
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      rows {
        id
        integration_product_id
        price
        principal_url_image
        sku
        status
        tags
        title
        vendor
        total_inventory
        handle
        compare_at_price
        EcommerceProductsStocksOmnichannel {
          location_id
          available
          EcommerceStocksLocationsOmnichannel {
            integration_location_name
            ecommerce_location_id
          }
        }
      }
      count
    }
  }
`

export const CREATE_OMNICHANNEL_CREDENTIALS = gql`
  mutation CreateOminchannelCredentials(
    $input: omnichannelCredentialsInput!
    $platformId: Int!
  ) {
    createOminchannelCredentials(input: $input, platformId: $platformId)
  }
`

export const OMNICHANNEL_INITIAL_SYNC = gql`
  mutation OmnichannelInitialSync($platformId: Int!) {
    omnichannelInitialSync(platformId: $platformId)
  }
`

export const OMNICHANNEL_MANUAL_SYNC = gql`
  mutation OmnichannelManualSync($platformId: Int!, $files: [Upload!]!) {
    omnichannelManualSync(platformId: $platformId, files: $files)
  }
`
export const OMNICHANNEL_AUTOMATIC_SYNC = gql`
  mutation OmnichannelAutomaticSync($platformId: Int!) {
    omnichannelAutomaticSync(platformId: $platformId)
  }
`
