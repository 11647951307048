// import TablePartner from './GeneratePayments/PaymentsTablePartnerSelect'
// import TableNote from './GeneratePayments/PaymentsTableNoteSelect'
// import TableBills from './GeneratePayments/PaymentsTableBillsSelect'
import { useState } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ExecutedPayment from './GeneratePayments/ExecutedPayment'
import ContentHeader from '../../../Layout/ContentHeader'
import DisplayInfoForPrintCoverage from './GeneratePayments/DisplayInfoForPrintCoverage'

const GenNewPayment = () => {
  const [step, setStep] = useState(1)
  const [partnerId, setPartnerId] = useState(false)
  const [idNote, setIdNote] = useState(false)
  const [invoiceNumberPayment, setInvoiceNumberPayment] = useState(false)
  const [infoBills, setInfoBills] = useState([])
  const billArray = []

  const handleButtonStepNext = () => {
    setStep(step + 1)
  }
  const handleButtonStepPrevious = () => {
    setStep(step - 1)
  }

  const handleSelectPartner = (id) => {
    setPartnerId(id)
    handleButtonStepNext()
  }

  const handleSelectNote = (id) => {
    setIdNote(id)
    handleButtonStepNext()
  }

  const handlePrintCoverage = (invoice) => {
    // console.log('invoiceGeneratePayment', invoiceNumberPayment)
    // setInvoiceNumberPayment(invoice)
    handleButtonStepNext()
  }

  const handleStepPayment = () => {
    if (billArray.length === 0)
      return toastSweetAlert({
        mode: 'error',
        message: 'Debes Seleccionar ' + 'al menos una factura para pagar',
      })
    setInfoBills(billArray)
    console.log(infoBills)
    handleButtonStepNext()
  }

  const handlePreviousStep = () => {
    handleButtonStepPrevious()
  }

  return (
    <>
      <ContentHeader
        windowTitle="Generar Pago"
        title="Generar Pago"
        breadcrumb="Generar Pago"
      />
      {/* {step === 1 && (
        <>
          <button onClick={handleButtonStepNext}>Nominativa</button>
          <button onClick={handleButtonStepNext}>Masiva</button>
        </>
      )} */}
      {/* {step === 2 && (
        <>
          <TableNote
            setPartnerId={partnerId}
            handleSelectNote={handleSelectNote}
            handlePreviousStep={handlePreviousStep}
          />
        </>
      )}
      {step === 3 && (
        <>
          <TableBills
            setIdNote={idNote}
            billArray={billArray}
            handleStepPayment={handleStepPayment}
            handlePreviousStep={handlePreviousStep}
          />
        </>
      )} */}
      {step === 1 && (
        <>
          <ExecutedPayment
            infoBills={infoBills}
            handleNextStep={handlePrintCoverage}
            idNote={idNote}
          />
        </>
      )}
      {/* {step === 2 && (
        <DisplayInfoForPrintCoverage
          invoiceNumberPayments={invoiceNumberPayment}
          handlePreviousStep={handlePreviousStep}
        />
      )} */}
    </>
  )
}

export default GenNewPayment
