import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '../../../../Global/Box'
import ContentHeader from '../../../../Layout/ContentHeader'
import * as Yup from 'yup'
import InputController from '../../../../Global/InputController'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_RETENTION_PARTNER,
  GET_ALL_RETENTION_PARTNERS,
  GET_ONE_RETENTION_PARTNER_BY_ID,
  UPDATE_RETENTION_PARTNER,
} from '../../../../../graphql/Catalog/Notes/BusinessRetention/businessretention'
import { useEffect } from 'react'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'
import { useHistory, useParams } from 'react-router'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'

export const BusinessRetentionNew = ({ valueRFC, reception }) => {
  console.log('recepcion', reception)
  const { id: _id } = useParams()
  const history = useHistory()
  const [infoProvider, setInfoProvider] = useState(null)
  const [searchProvider, setSearchProvider] = useState(null)
  const [idProvider, setIdProvider] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [chkBoxValue, setChkBoxValue] = useState(false)
  const [chkBoxValue2, setChkBoxValue2] = useState(false)

  const { data: dataPartnerRetention } = useQuery(
    GET_ONE_RETENTION_PARTNER_BY_ID,
    {
      variables: {
        getOneRetentionPartnerByIdId: parseInt(_id),
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [createRetentionPartner] = useMutation(CREATE_RETENTION_PARTNER, {
    refetchQueries: [
      {
        query: GET_ALL_RETENTION_PARTNERS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
        fetchPolicy: 'no-cache',
      },
    ],
  })

  const [updateRetentionPartner] = useMutation(UPDATE_RETENTION_PARTNER, {
    refetchQueries: [
      {
        query: GET_ALL_RETENTION_PARTNERS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
        fetchPolicy: 'no-cache',
      },
    ],
  })

  const validationSchema = chkBoxValue
    ? Yup.object().shape({
        provider_name: Yup.string('Este campo es obligatorio')
          .required('Este campo es obligatorio')
          .nullable('Este campo es obligatorio'),
        provider_rfc: Yup.string('Este campo es obligatorio')
          .required('Este campo es obligatorio')
          .min(12, 'Este campo debe tener al menos 12 caracteres')
          .max(15, 'Este campo debe tener máximo 15 caracteres')
          .nullable('Este campo es obligatorio'),
        percentageRetention: Yup.string(),
      })
    : Yup.object().shape({
        provider_name: Yup.string('Este campo es obligatorio').required(
          'Este campo es obligatorio'
        ),
        provider_rfc: Yup.string('Este campo es obligatorio')
          .required('Este campo es obligatorio')
          .min(12, 'Este campo debe tener al menos 12 caracteres')
          .max(15, 'Este campo debe tener máximo 15 caracteres')
          .nullable('Este campo es obligatorio'),
        percentageRetention: Yup.number('Este campo es obligatorio')
          .typeError('Este campo es obligatorio')
          .negative('Solo números positivos')
          .max(1, 'El porcentaje debe ser de 0-1')
          .min(0.0, 'El porcentaje debe ser de 0-1'),
      })
  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    setValue('provider_rfc', valueRFC)
  }, [valueRFC])

  useEffect(() => {
    if (_id) {
      if (dataPartnerRetention !== null && dataPartnerRetention !== undefined) {
        const data = dataPartnerRetention.getOneRetentionPartnerById

        const dataFormated = {
          provider_name: data?.provider_name,
          provider_rfc: data?.provider_rfc,
          percentageRetention: data?.percentage_retention,
          secondTicket: data?.second_ticket,
          chkBoxValue: data?.retention_global,
        }
        console.log(data?.retention_global)
        setChkBoxValue(data?.retention_global)
        setChkBoxValue2(data?.second_ticket)
        setSearchProvider(data?.partnerRetention?.card_name)
        reset(dataFormated)
      }
    }
  }, [dataPartnerRetention, reset, _id])

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)
      if (_id) {
        await updateRetentionPartner({
          variables: {
            idPercentageRetention: parseInt(_id),
            businessRetentionInput: {
              provider_name: data?.provider_name.trim().toUpperCase(),
              provider_rfc: data?.provider_rfc.trim().toUpperCase(),
              percentage_retention: data?.percentageRetention?.toString(),
              retention_global: chkBoxValue,
              second_ticket: chkBoxValue2,
            },
          },
        })
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'ok',
            message: 'El proveedor se ha actualizado correctamente',
          },
          history.push(`/catalogsforcountercharges/businesretention`)
        )
      }

      console.log(data)

      await createRetentionPartner({
        variables: {
          businessRetentionInput: {
            provider_name: data?.provider_name.trim().toUpperCase(),
            provider_rfc: data?.provider_rfc.trim().toUpperCase(),
            percentage_retention: data?.percentageRetention?.toString(),
            retention_global: chkBoxValue,
            second_ticket: chkBoxValue2,
          },
        },
      })
      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: 'El proveedor se ha creado correctamente',
        },
        reception
          ? history.push(`/catalog/notes/notenew`)
          : history.push(`/catalogsforcountercharges/businesretention`)
      )
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        windowTitle="Retención para proveedor"
        breadcrumb="Retención para proveedor"
        title={`${
          _id
            ? 'Editar asignación de retención a proveedor'
            : 'Asignar retención a proveedor'
        }`}
      />
      <FormProvider {...methods}>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            // title={`${
            //   _id
            //     ? 'Editar asignación de retención a proveedor'
            //     : 'Asignar retención a proveedor'
            // }`}
            btnRedPath="/catalogsforcountercharges/businesretention"
            btnRedTxt="Cancelar"
            btnSubmit={true}
            btnState={loadingBtn}
            errors={errors}
            content={
              <>
                <div className="col">
                  <div className="row">
                    <div className="mb-3 col-md-4 col-lg-4">
                      <InputController
                        name="provider_name"
                        label="Nombre Proveedor"
                        type="text"
                        placeholder="Nombre proveedor"
                        control={control}
                        // blurAction={(e) => setSearchProvider(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-3 col-lg-3">
                      <InputController
                        name="provider_rfc"
                        label="RFC Proveedor"
                        type="text"
                        placeholder="RFC proveedor"
                        control={control}
                        // valueManual={valueRFC ? valueRFC : ''}
                        // defaultValue={valueRFC ? valueRFC : ''}
                        // blurAction={(e) => setSearchProvider(e.target.value)}
                      />
                    </div>
                    {/* {!_id && ( */}
                    {/* <div className=" col-md-2 col-lg-2 d-flex flex-row align-items-around justify-content-start">
                      <input
                        name="chkBoxValue"
                        type="checkbox"
                        onChange={(e) => setChkBoxValue(e.target.checked)}
                        checked={chkBoxValue}
                      />
                      <label className="form-check-label d-flex flex-column justify-content-around">
                        &nbsp; Retención manual
                      </label>
                    </div> */}
                    <div className=" col-md-3 col-lg-3 d-flex flex-row align-items-around justify-content-start">
                      <input
                        name="secondTicket"
                        type="checkbox"
                        onChange={(e) => setChkBoxValue2(e.target.checked)}
                        checked={chkBoxValue2}
                      />
                      <label className="form-check-label d-flex flex-column justify-content-around">
                        &nbsp; Habilitar Segundo ticket
                      </label>
                    </div>
                    {/* )} */}
                  </div>
                </div>

                {/* <hr />
                <div className="boxQuotesProvider">
                  {infoProvider && (
                    <>
                      <h4>
                        Proveedor:{' '}
                        <span className="resultData">
                          {infoProvider?.card_name
                            ? infoProvider?.card_name
                            : '---'}
                        </span>{' '}
                      </h4>
                      <hr />
                      <div className="row">
                        {infoProvider?.card_code && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Código proveedor:{' '}
                                <span className="resultData">
                                  {infoProvider?.card_code}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {infoProvider.lic_trad_number && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                RFC:{' '}
                                <span className="resultData">
                                  {infoProvider.lic_trad_number}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {infoProvider.phone1 && !infoProvider.phone2 && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Teléfono:{' '}
                                <span className="resultData">
                                  {infoProvider.phone1}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {!infoProvider?.phone1 && infoProvider?.phone2 && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Teléfono:{' '}
                                <span className="resultData">
                                  {infoProvider?.phone2}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {infoProvider?.phone1 && infoProvider?.phone2 && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Teléfonos:{' '}
                                <span className="resultData">
                                  {infoProvider?.phone1 + ' / '}
                                  {infoProvider?.phone2}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                        {infoProvider?.email && (
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <div className="boxItem">
                              <h4>
                                Correo:{' '}
                                <span className="resultData">
                                  {infoProvider?.email}
                                </span>{' '}
                              </h4>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <hr />
                </div> */}

                {!chkBoxValue && (
                  <div className="mt-3 col-lg-6">
                    <InputController
                      name="percentageRetention"
                      label="Porcentaje a asignar"
                      type="text"
                      // disabled={infoProvider ? false : true}
                      control={control}
                    />
                  </div>
                )}
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default BusinessRetentionNew
