import { gql } from '@apollo/client'

export const GET_ALL_WAREHOUSE_REPORTS_BY_USER_ID = gql`
  query GetAllWarehouseReportsByUserId(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllWarehouseReportsByUserId(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        user_id
        warehouse_report_file_types_id
        part
        file_id
        file_type
        custom_rage_start
        custom_rage_end
        is_active
        completed
        WarehouseReportFilesToWarehouseReportFileTypes {
          id
          name
          is_active
        }
        file {
          id
          url
          type
        }
      }
    }
  }
`
