import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'

import ContentHeader from '../../../Layout/ContentHeader'
import ProvidersQuotesTable from './ProvidersScheduleTable'
import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'
import { validationSchemaQuote } from '../../../Helpers/validatorQuotes'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_SAP_PURCHASES_ORDERS_LINES_BY_PURCHASE_ORDERS_ID } from '../../../../graphql/Catalog/SAP/sapPurchasesOrdersLines'
import { GET_SCHEDULE_LINES_BY_SCHEDULE_ID } from '../../../../graphql/Documents/ScheduleOrdersLines/scheduleOrdersLines'
import { GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID_QUERY } from '../../../../graphql/Documents/ScheduleDocks/scheduleDocks'
import { GET_DOCKS } from '../../../../graphql/Catalog/Docks/docks'
import Loader from '../../../Global/Spinner'

const ProvidersQuotesStep2 = ({
  handleResetStep,
  handleAddOrder,
  handleSave,
  statusWarehouse,
  docks,
  ordersReceived,
  setOrdersReceived,
  ordersReceivedStep1Hook,
  ordersReceivedStep1,
  infoProvider,
  setFlagEdit,
  flagEdit,
  setInfoProvider,
  processing,
}) => {
  const defaultError = 'Algo salio mal, vuelve a intentar en unos minutos'
  const someProductsNotFound = 'Algunos productos no se encontrarons'
  const { id: _id } = useParams()

  const [orders, setOrders] = useState([])
  const [additionalInfo, setAdditionalInfo] = useState([])
  // let orders = [];
  const history = useHistory()

  const [getOrdersLines] = useMutation(
    GET_SAP_PURCHASES_ORDERS_LINES_BY_PURCHASE_ORDERS_ID
  )

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchemaQuote),
  })

  // useEffect(() => {
  //   setValue('warehouse_id', 149)
  // })

  useEffect(() => {
    //Consult data

    async function getOrderLinesChecks() {
      if (!ordersReceivedStep1Hook || ordersReceivedStep1Hook.length === 0) {
        return (
          toastSweetAlert({
            mode: 'error',
            message: defaultError,
          }),
          history.push('/schedule/providers')
        )
      }

      let flagNotFound = false
      try {
        const res = await getOrdersLines({
          variables: {
            purchasesOrdersId: ordersReceivedStep1Hook,
          },
        })
        const { OrderLines, PurchaseOrders } =
          res.data.getSapPurchasesOrdersLinesByPurchaseOrdersId
        /* FIRST WE NEED TO SAVE EACH ITEMS PER ORDER */
        if (OrderLines[0].SapPurchasesOrdersLinesWarehouses.id) {
          setValue(
            'warehouse_id',
            OrderLines[0].SapPurchasesOrdersLinesWarehouses.id
          )
        }

        let list = OrderLines.map((orderLine) => {
          const { PurchasesLinesItemInfo } = orderLine

          const purchaseOrder = PurchaseOrders.find(
            ({ id }) => id === orderLine.purchases_order_id
          )
          const { document_date, comments, number_at_card } = purchaseOrder
          if (PurchasesLinesItemInfo) {
            return {
              id: orderLine.id,
              purcharse_order_id: orderLine.purchases_order_id,
              purcharse_document_date: document_date ? document_date : '',
              purchase_number_at_card: number_at_card ? number_at_card : '',
              purcharse_comments: comments ? comments : 'Sin comentarios',
              item_code: PurchasesLinesItemInfo.item_code,
              item_name: PurchasesLinesItemInfo.item_name,
              quantity: orderLine.quantity ? orderLine.quantity : '',
              all_correct: true,
              document_number: purchaseOrder?.document_number,
            }
          }
          //  else {
          //   //ITEM NOT FOUND
          //   flagNotFound = true
          //   return {
          //     id: orderLine.id,
          //     purcharse_order_id: orderLine.purchases_order_id,
          //     purcharse_document_date: document_date ? document_date : '',
          //     purchase_number_at_card: number_at_card ? number_at_card : '',
          //     purcharse_comments: comments ? comments : 'Sin comentarios',
          //     item_code: PurchasesLinesItemInfo.item_code
          //       ? PurchasesLinesItemInfo.item_code
          //       : '',
          //     item_name: PurchasesLinesItemInfo?.item_name
          //       ? PurchasesLinesItemInfo.item_name
          //       : 'PRODUCTO NO ENCONTRADO',
          //     quantity: orderLine.quantity ? orderLine.quantity : '',
          //     all_correct: false,
          //   }
          // }
        })

        //ALL PRODUCTS HAVE TO BE IN ORDER
        let listOrdersArray = []
        ordersReceivedStep1Hook.map((id, index) => {
          let filter = list.filter((item) => item.purcharse_order_id === id)
          listOrdersArray.push({
            purcharse_order_id: filter[0].purcharse_order_id,
            id_order: filter[0].id,
            sku: filter[0].item_code,
          })
          return true
        })
        setOrdersReceived(listOrdersArray)
        setOrders(list)

        // if (flagNotFound)
        //   return toastSweetAlert({
        //     mode: 'error',
        //     message: someProductsNotFound,
        //   })
      } catch (error) {
        setOrders([])
        console.log(error)
        return toastSweetAlert(
          {
            mode: 'error',
            message: error.message,
          },
          history.push('/schedule/providers')
        )
      }
    }
    if (!_id || flagEdit) {
      getOrderLinesChecks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersReceivedStep1Hook])

  //================================== FOR EDIT ==================================
  const docksEsp = {
    1: 'Puerta 1',
    2: 'Puerta 2',
    3: 'Puerta 3',
    4: 'Puerta 4',
  }

  //PROVIDERS INFORMATION
  //ARRAY FOR PURCHASES ORDERS Ids
  const [purchasesOrdersId, setPurchasesOrdersId] = useState(null)
  //ARRAY FOR DOCKS OPTIONS
  const [dsocks, setDocks] = useState(null)
  //HISTORY

  //QUERY TO SEARCH SCHEDULE USING OUR SCHEDULEID
  const { data, loading, error } = useQuery(GET_SCHEDULE_LINES_BY_SCHEDULE_ID, {
    variables: {
      scheduleId: parseInt(_id),
      isRecieving: null,
      details: false,
    },
  })

  //GET ALL DOCKS BY ID
  const {
    data: dataDocks,
    loading: loadingDocks,
    error: errorDocks,
  } = useQuery(GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID_QUERY, {
    variables: {
      scheduleId: parseInt(_id),
    },
  })

  //GETT ALL DOCKS OF DB
  const {
    data: dataDocksDb,
    loading: loadingDocksDb,
    error: errorDocksDb,
  } = useQuery(GET_DOCKS)

  //USE EFFECT TO FILL DATA ORDER
  useEffect(() => {
    //SET SCROLL TO TOP
    window.scrollTo(0, 0)
    if (_id) {
      if (!loading) {
        if (error)
          return (
            toastSweetAlert({
              mode: 'error',
              message: error.message,
            }),
            history.push('/schedule/providers')
          )
        //GET SCHEDULE FORM INFO
        const { scheduleInfo, ScheduleOrdersLines } =
          data?.getScheduleOrdersLinesByScheduleId
        if (ScheduleOrdersLines.length === 0)
          return (
            toastSweetAlert({
              mode: 'error',
              message: 'Esta cita ha concluido o ha sido cancelada',
            }),
            history.push('/schedule/providers')
          )

        let docksId = []
        //GET DOCKS
        if (!loadingDocks) {
          if (errorDocks)
            return (
              toastSweetAlert({
                mode: 'error',
                message: error.message,
              }),
              history.push('/')
            )

          const res = dataDocks.getScheduleDocksByScheduleIdQuery

          docksId = res.map((item) => {
            return {
              label: docksEsp[item.dock.id],
              value: item.dock.id,
            }
          })
        }

        const dataFormated = {
          document_date: scheduleInfo?.document_date,
          document_time_start: scheduleInfo?.document_time_start,
          document_time_end: scheduleInfo?.document_time_end,
          comments: scheduleInfo?.comments,
          warehouse_name: scheduleInfo?.warehouse.warehouse_name,
          status: scheduleInfo?.ScheduleDocumentStatus.name,
          dock_ids: docksId,
          warehouse_id: scheduleInfo?.warehouse?.id,
        }
        reset(dataFormated)

        let flagNotFound = false
        if (!flagEdit) {
          let arrayIds = []
          const list = []

          ScheduleOrdersLines.map((orderLine) => {
            const { comments, ScheduleOrdersLinesSapPurchasesOrders } =
              orderLine

            const { number_at_card, document_number, document_date } =
              ScheduleOrdersLinesSapPurchasesOrders
            let purchases_order_id = 0

            //Add id for array
            const itemLines = []
            // if (arrayIds.indexOf(id) === -1) arrayIds.push(id)
            ScheduleOrdersLinesSapPurchasesOrders.BusinessSapPurchasesPendingOrdersLines.map(
              (line) => {
                purchases_order_id = line.purchases_order_id
                if (line.PurchasesLinesItemInfo) {
                  itemLines.push({
                    id: line.id,
                    purcharse_order_id: line.purchases_order_id
                      ? line.purchases_order_id
                      : '',
                    purcharse_document_date: document_date ? document_date : '',
                    purchase_number_at_card: number_at_card
                      ? number_at_card
                      : '',
                    purcharse_comments: comments ? comments : 'Sin comentarios',
                    item_code: line?.PurchasesLinesItemInfo?.item_code
                      ? line?.PurchasesLinesItemInfo?.item_code
                      : '',
                    item_name: line?.PurchasesLinesItemInfo?.item_name
                      ? line?.PurchasesLinesItemInfo?.item_name
                      : '',
                    quantity: line.quantity ? line.quantity : '',
                    total: line.total ? line.total : '',
                    all_correct: true,
                    document_number: document_number,
                  })
                  return null
                }
                // else {
                //   //ITEM NOT FOUND
                //   flagNotFound = true
                //   itemLines.push({
                //     id: line.id,
                //     purcharse_order_id: line.purchases_order_id
                //       ? line.purchases_order_id
                //       : '',
                //     purcharse_document_date: document_date ? document_date : '',
                //     purchase_number_at_card: number_at_card
                //       ? number_at_card
                //       : '',
                //     purcharse_comments: comments ? comments : 'Sin comentarios',

                //     item_code: line?.PurchasesLinesItemInfo?.item_code
                //       ? line?.PurchasesLinesItemInfo?.item_code
                //       : '',
                //     item_name: 'PRODUCTO NO ENCONTRADO',
                //     quantity: line.quantity ? line.quantity : '',
                //     all_correct: false,
                //   })
                //   return null
                // }
              }
            )
            if (arrayIds.includes(purchases_order_id)) return null
            if (arrayIds.indexOf(purchases_order_id) === -1) {
              arrayIds.push(purchases_order_id)
            }
            list.push(itemLines)
            return null
          })
          setAdditionalInfo(data.getScheduleOrdersLinesByScheduleId)
          setInfoProvider(
            ScheduleOrdersLines[0].ScheduleOrdersLinesSapPurchasesOrders
              .business_partner
          )

          //ALL PRODUCTS HAVE TO BE IN ORDER
          let listOrdersArray = []
          list.map((order) => {
            return listOrdersArray.push({
              purcharse_order_id: order[0].purcharse_order_id,
              id_order: order[0].id,
              sku: order[0].item_code,
            })
          })
          setOrdersReceived(listOrdersArray)
          setPurchasesOrdersId(arrayIds)
          setOrders(list)
        }

        // if (flagNotFound)
        //   toastSweetAlert({
        //     mode: 'error',
        //     message: someProductsNotFound,
        //   })

        setFlagEdit(true)
      }
    }
  }, [loading, data, error, loadingDocks, errorDocks, dataDocks])

  //GET ALL DOCKS
  useEffect(() => {
    if (!loadingDocksDb) {
      if (errorDocksDb)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/')
        )

      const res = dataDocksDb.getDocks

      const list = res.map((item) => {
        return { value: item.id, label: docksEsp[item.id] }
      })
      setDocks(list)
    }
  }, [dataDocksDb, loadingDocksDb, errorDocksDb])

  if (document.getElementById('commentTextarea'))
    document.getElementById('commentTextarea').readOnly = true

  return (
    <>
      {orders && ordersReceivedStep1Hook && (
        <div className="container-fluid">
          {/* <ContentHeader
            title={`${_id ? 'Editar' : 'Crear '} cita`}
            breadcrumb={`${_id ? 'Editar' : 'Crear '} cita de proveedores`}
            windowTitle={`${_id ? 'Editar' : 'Crear una'} cita `}
          /> */}

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(handleSave)}
              className={`row ${processing ? 'processing-file' : ''}`}
            >
              <div className="row">
                <div className="mb-3 col-12">
                  <Box
                    title="Detalles de la orden de compra"
                    whiteHeader={false}
                    content={
                      <div className="boxQuotesProvider">
                        {infoProvider ? (
                          <>
                            <h4>
                              Proveedor:{' '}
                              <span className="resultData">
                                {infoProvider.card_name
                                  ? infoProvider.card_name
                                  : '---'}
                              </span>{' '}
                            </h4>
                            <hr />
                            <div className="row">
                              {infoProvider.card_code && (
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                                  <div className="boxItem">
                                    <h4>
                                      Código proveedor:{' '}
                                      <span className="resultData">
                                        {infoProvider.card_code}
                                      </span>{' '}
                                    </h4>
                                  </div>
                                </div>
                              )}
                              {infoProvider.lic_trad_number && (
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                                  <div className="boxItem">
                                    <h4>
                                      RFC:{' '}
                                      <span className="resultData">
                                        {infoProvider.lic_trad_number}
                                      </span>{' '}
                                    </h4>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="row">
                              {infoProvider.phone1 && !infoProvider.phone2 && (
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                                  <div className="boxItem">
                                    <h4>
                                      Teléfono:{' '}
                                      <span className="resultData">
                                        {infoProvider.phone1}
                                      </span>{' '}
                                    </h4>
                                  </div>
                                </div>
                              )}
                              {!infoProvider.phone1 && infoProvider.phone2 && (
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                                  <div className="boxItem">
                                    <h4>
                                      Teléfono:{' '}
                                      <span className="resultData">
                                        {infoProvider.phone2}
                                      </span>{' '}
                                    </h4>
                                  </div>
                                </div>
                              )}
                              {infoProvider.phone1 && infoProvider.phone2 && (
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                                  <div className="boxItem">
                                    <h4>
                                      Teléfonos:{' '}
                                      <span className="resultData">
                                        {infoProvider.phone1 + ' / '}
                                        {infoProvider.phone2}
                                      </span>{' '}
                                    </h4>
                                  </div>
                                </div>
                              )}
                              {infoProvider.email && (
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                                  <div className="boxItem">
                                    <h4>
                                      Correo:{' '}
                                      <span className="resultData">
                                        {infoProvider.email}
                                      </span>{' '}
                                    </h4>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="text-center">
                            <Loader />
                          </div>
                        )}
                      </div>
                    }
                  />
                </div>
                <div className="mb-3 col-12">
                  <Box
                    title="Desglose de orden"
                    content={
                      !loading ? (
                        ordersReceivedStep1Hook.length > 0 ? (
                          <>
                            {ordersReceivedStep1Hook.map((id, index) => {
                              let filter = orders.filter(
                                (item) => item.purcharse_order_id === id
                              )
                              return (
                                <>
                                  <div className="boxQuotesProvider">
                                    <div className="boxItem">
                                      <h4>
                                        Número de orden:{' '}
                                        {filter[0]?.document_number}
                                        <span className="resultData"></span>{' '}
                                      </h4>
                                    </div>
                                  </div>
                                  <ProvidersQuotesTable
                                    key={index}
                                    data={filter}
                                    ordersReceived={ordersReceived}
                                    setOrdersReceived={setOrdersReceived}
                                    ordersReceivedStep1Hook={
                                      ordersReceivedStep1Hook
                                    }
                                    setPurchasesOrdersId={setPurchasesOrdersId}
                                    ordersReceivedStep1={ordersReceivedStep1}
                                    history={history}
                                    onlyShow={true}
                                  />
                                  <hr />
                                </>
                              )
                            })}
                            {!_id && (
                              <span
                                onClick={handleResetStep}
                                className="btn StandarModalCancelButtonLogOut float-right"
                              >
                                Cambiar proveedor
                              </span>
                            )}
                          </>
                        ) : purchasesOrdersId?.length > 0 ? (
                          <>
                            {orders.map((order, index) => {
                              return (
                                <>
                                  <div className="boxQuotesProvider">
                                    <div className="boxItem">
                                      <h4>
                                        Número de orden:{' '}
                                        {order[0]?.document_number}
                                        <span className="resultData"></span>{' '}
                                      </h4>
                                    </div>
                                  </div>
                                  <ProvidersQuotesTable
                                    id_Order={order[0].purcharse_order_id}
                                    key={index}
                                    data={order}
                                    ordersReceived={ordersReceived}
                                    setOrdersReceived={setOrdersReceived}
                                    ordersReceivedStep1Hook={purchasesOrdersId}
                                    setPurchasesOrdersId={setPurchasesOrdersId}
                                    history={history}
                                    onlyShow={true}
                                  />
                                  <hr />
                                </>
                              )
                            })}
                            {/* <span
                            onClick={() =>
                              handleAddOrder(purchasesOrdersId, infoProvider.id)
                            }
                            className="btn StandarModalCancelButtonLogOut float-right"
                          >
                            Agregar orden de compra
                          </span> */}

                            {!_id && (
                              <span
                                onClick={handleResetStep}
                                className="btn StandarModalCancelButtonLogOut float-right"
                              >
                                Cambiar proveedor
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="text-center p-5">
                              No se han agregado órdenes de compra
                            </div>
                            {/* <span
                            onClick={() =>
                              handleAddOrder(purchasesOrdersId, infoProvider.id)
                            }
                            className="btn StandarModalCancelButtonLogOut float-right"
                          >
                            Agregar orden de compra
                          </span> */}
                            <span
                              onClick={handleResetStep}
                              className="btn StandarModalCancelButtonLogOut float-right"
                            >
                              Cambiar proveedor
                            </span>
                          </>
                        )
                      ) : (
                        <div className="text-center">
                          <Loader />
                        </div>
                      )
                    }
                  />
                </div>
                <div className="mb-3 col-12">
                  <Box
                    title="Elige los detalles de la cita"
                    // btnRedPath="/citas/proveedores"
                    // btnRedTxt="Cancelar"
                    btnSecondFunction={() =>
                      history.push('/schedule/providers')
                    }
                    btnSecondFunctionTitle="Cancelar"
                    btnIcon="fa fa-arrow-left"
                    btnSubmit={true}
                    btnState={false}
                    errors={errors}
                    content={
                      <>
                        <div className="row">
                          <div className="mb-3 col-lg-12 col-md-12 col-sm-12"></div>

                          <div className="mb-3 col-lg-4 col-md-4 col-sm-12">
                            {/* <InputController
                              label="Almacén"
                              inputType="choosen"
                              options={statusWarehouse}
                              name="warehouse_id"
                              placeholder="Confirme código de almacén o tienda"
                              control={control}
                            /> */}
                            <InputController
                              label="Almacén"
                              inputType="choosen"
                              options={statusWarehouse}
                              name="warehouse_id"
                              placeholder="Confirme código de almacén o tienda"
                              disabled={true}
                              control={control}
                            />
                          </div>
                          <div className="mb-3 col-lg-4 col-md-4 col-sm-12">
                            <InputController
                              label="Puerta"
                              inputType="choosen"
                              options={docks}
                              isMulti={true}
                              name="dock_ids"
                              placeholder="Elije la puerta"
                              control={control}
                            />
                          </div>
                          <div className="mb-3 col-lg-4 col-md-4 col-sm-12">
                            <InputController
                              label="Fecha de cita"
                              type="date"
                              name="document_date"
                              placeholder="Confirme fecha de la cita"
                              control={control}
                            />
                          </div>
                          <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                            <InputController
                              label="Hora de inicio"
                              type="time"
                              name="document_time_start"
                              placeholder="Confirme hora de cita"
                              control={control}
                            />
                          </div>
                          <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                            <InputController
                              label="Hora de fin"
                              type="time"
                              name="document_time_end"
                              placeholder="Confirme hora de cita"
                              control={control}
                            />
                          </div>
                          <div className="mb-3 col-12">
                            <InputController
                              label="Observaciones"
                              inputType="textarea"
                              name="comments"
                              placeholder=""
                              control={control}
                            />
                          </div>
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </>
  )
}

export default ProvidersQuotesStep2
