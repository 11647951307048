import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import Swal from 'sweetalert2'
import ContentHeader from '../../Layout/ContentHeader'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { GET_SCHEDULE_LINES_BY_SCHEDULE_ID } from '../../../graphql/Documents/ScheduleOrdersLines/scheduleOrdersLines'
import {
  CLOSE_COMMODITY_RECEIPT,
  CREATE_COMMODITY_RECEIPT,
  CREATE_COMMODITY_RECEIPT_EVIDENCE,
  CREATE_COMMODITY_RECEIPT_ISSUES,
  DELETE_COMMODITY_RECEIPT_LINE,
  GET_EVIDENCES_COMMODITY_RECEIPT_BY_SCHEDULE_ID,
  GET_ISSUES_COMMODITY_RECEIPT,
} from '../../../graphql/Documents/CommodityReceipt/commodityReceipt'
import {
  CANCELL_SCHEDULE,
  GET_SCHEDULE,
} from '../../../graphql/Documents/Schedule/schedule'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import withReactContent from 'sweetalert2-react-content'
import { masterLabel, masterLabelsGenerator } from '../../Helpers/pdfGenerator'
import moment from 'moment'
import { useBarcode } from 'next-barcode'

const ProductReceptionNew = () => {
  const defaultError = 'Algo salio mal, vuelve a intentar en unos minutos'

  const { id: _id, show } = useParams()
  const history = useHistory()
  const MySwal = withReactContent(Swal)
  const [steps, setSteps] = useState(1)
  const [barcodeValue, setBarcodeValue] = useState('1')
  const [dataToPrint, setDataToPrint] = useState([])
  const [fileName, setFileName] = useState()
  const [typeValue, setTypeValue] = useState()

  const { inputRef } = useBarcode({
    value: barcodeValue,
  })

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [issues, setIssues] = useState({})
  const [linesQuantity, setLinesQuantity] = useState([])
  const [evidence, setEvidence] = useState([])
  const [selected, setSelected] = useState({
    product: {},
    photos: [],
    openQuantity: 0,
    expDate: 0,
    añada: 0,
    issueQuantity: 0,
    issue: null,
    issueCustom: null,
  })
  const [data, setData] = useState({
    schedule: {},
    orders: [],
    lines: [],
    loading: true,
  })

  const [cancellSchedule] = useMutation(CANCELL_SCHEDULE)
  const [closeCommodityReceipt] = useMutation(CLOSE_COMMODITY_RECEIPT)
  const [createCommodityReceipt] = useMutation(CREATE_COMMODITY_RECEIPT)
  const [deleteCommodityReceiptLine] = useMutation(
    DELETE_COMMODITY_RECEIPT_LINE
  )
  const [createCommodityReceiptIssues] = useMutation(
    CREATE_COMMODITY_RECEIPT_ISSUES
  )
  const [createCommodityReceiptEvidence] = useMutation(
    CREATE_COMMODITY_RECEIPT_EVIDENCE
  )

  const {
    data: dataIssues,
    loading: loadingIssues,
    error: errorIssues,
  } = useQuery(GET_ISSUES_COMMODITY_RECEIPT)
  const {
    data: dataSchema,
    loading: loadingSchema,
    error: errorSchema,
    refetch: refetchSchema,
  } = useQuery(GET_SCHEDULE_LINES_BY_SCHEDULE_ID, {
    variables: {
      scheduleId: parseInt(_id),
      isRecieving: true,
      details: show ? true : false,
    },
  })
  const {
    data: dataCommodityEvidences,
    loading: loadingCommodityEvidences,
    error: errorCommodityEvidences,
    refetch: refetchCommodityEvidences,
  } = useQuery(GET_EVIDENCES_COMMODITY_RECEIPT_BY_SCHEDULE_ID, {
    variables: {
      scheduleId: parseInt(_id),
    },
  })

  const handleNextStep = () => setSteps(steps + 1)
  const handleResetStep = () => setSteps(1)
  const handlePreviousStep = () => setSteps(steps - 1)

  useEffect(() => {
    if (!loadingCommodityEvidences) {
      if (!data.lines) {
        return null
      }
      if (dataCommodityEvidences.getEvidencesCommodityReceiptByScheduleId) {
        let list = []
        let listItemQuantity = []
        for (const item of dataCommodityEvidences.getEvidencesCommodityReceiptByScheduleId) {
          if (item.evidences?.fileInternal) {
            list.push(item.evidences.fileInternal.url)
          }
          listItemQuantity.push({
            id: item.id,
            quantity: item.receipt_quantity,
            line_id: item.sap_purchases_orders_lines_id,
            items: item.items,
            boxes: item.boxes,
            beds: item.beds,
            positionId: item.position_order_id,
          })
        }
        setEvidence(list)
        setLinesQuantity(listItemQuantity)
      }
    }
  }, [
    dataCommodityEvidences,
    loadingCommodityEvidences,
    errorCommodityEvidences,
  ])

  useEffect(() => {
    let listOrder = []
    let listLines = []
    if (!loadingSchema) {
      async function SetData() {
        try {
          if (dataSchema.getScheduleOrdersLinesByScheduleId) {
            if (
              dataSchema.getScheduleOrdersLinesByScheduleId.ScheduleOrdersLines
                .length === 0
            )
              return toastSweetAlert(
                {
                  mode: 'error',
                  message:
                    'Esta órden de compra ha sido recepcionada y se encuentra cerrada',
                },
                history.push('/productsReception')
              )
            // console.log(dataSchema.getScheduleOrdersLinesByScheduleId, 'DATA')
            const scheduleInfo =
              dataSchema.getScheduleOrdersLinesByScheduleId.scheduleInfo
            const businesPartnerInfo =
              dataSchema.getScheduleOrdersLinesByScheduleId
                .ScheduleOrdersLines[0].ScheduleOrdersLinesSapPurchasesOrders
                .business_partner

            for (const item of dataSchema.getScheduleOrdersLinesByScheduleId
              .ScheduleOrdersLines) {
              const { ScheduleOrdersLinesSapPurchasesOrders } = item
              const { BusinessSapPurchasesPendingOrdersLines } =
                ScheduleOrdersLinesSapPurchasesOrders

              const findOrder = listOrder.find(
                (order) =>
                  ScheduleOrdersLinesSapPurchasesOrders.document_number ===
                  order.document_number
              )

              if (!findOrder) {
                listOrder.push({ ...ScheduleOrdersLinesSapPurchasesOrders })
              }
              for (const productLine of BusinessSapPurchasesPendingOrdersLines) {
                const findLine = listLines.find(
                  (order) => productLine.id === order.id
                )

                if (!findLine) {
                  listLines.push({
                    ...productLine,
                    purchasesOrder: ScheduleOrdersLinesSapPurchasesOrders.id,
                    document_number:
                      ScheduleOrdersLinesSapPurchasesOrders.document_number,
                    numberAtCard:
                      ScheduleOrdersLinesSapPurchasesOrders.number_at_card,
                    anada:
                      productLine.SapPurchasesOrdersLinesCommodityReceipt
                        ?.anada,
                    expiration_date:
                      productLine.SapPurchasesOrdersLinesCommodityReceipt
                        ?.expiration_date,
                  })
                }
              }
            }
            setData({
              schedule: {
                ...businesPartnerInfo,
                ...scheduleInfo,
              },
              orders: listOrder,
              lines: listLines,
              loading: false,
            })
          }
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
      SetData()
    }
  }, [dataSchema, loadingSchema, errorSchema])

  useEffect(() => {
    if (!loadingIssues) {
      if (dataIssues.getAllIssuesCommodityReceipt) {
        let list = {}
        for (const item of dataIssues.getAllIssuesCommodityReceipt) {
          list = Object.assign(list, { [item.id]: item.name })
        }
        setIssues(list)
      }
    }
  }, [dataIssues, loadingIssues, errorIssues])

  const onSubmit = async (
    done,
    quantity,
    expDate,
    añada,
    items,
    boxes,
    beds,
    pallets,
    itemCode,
    residueBottles,
    residueBox,
    serialNumbers
  ) => {
    try {
      let newStep = 2
      let isError = false
      setLoadingBtn(true)
      for (const document of selected.photos) {
        if (document.size > 5242880) {
          throw new Error('Archivo es demasiado pesado')
        }
      }
      if (done) {
        newStep = 1
      }

      for (let index = 0; index < pallets; index++) {
        const receipt = await createCommodityReceipt({
          variables: {
            commodityReceiptInput: {
              scheduleId: parseInt(_id),
              purchasesOrdersId: parseInt(selected.product.purchasesOrder),
              purchasesOrdersLinesId: parseInt(selected.product.id),
              quantity: parseInt(quantity) / parseInt(pallets),
              expDate: expDate,
              anada: parseInt(añada) === 100 ? null : parseInt(añada),
              items: parseInt(items),
              boxes: parseInt(boxes),
              beds: parseInt(beds),
              itemCode: itemCode,
              residue_bottles: residueBottles ? parseInt(residueBottles) : 0,
              residue_boxes: residueBox ? parseInt(residueBox) : 0,
              serialNumbers,
            },
          },
        })
        if (receipt.data.createCommodityReceipt) {
          await refetchSchema()
          await refetchCommodityEvidences()
          for (const document of selected.photos) {
            try {
              const response = await fetch(document)
              const newDocument = await response.blob()
              await createCommodityReceiptEvidence({
                variables: {
                  commodityReceiptEvidenceInput: {
                    commodityReceiptId: parseInt(
                      receipt.data.createCommodityReceipt[0]
                    ),
                    file: newDocument,
                  },
                },
              })
            } catch (error) {
              return toastSweetAlert({ mode: 'error', message: error.message })
            }
          }
        } else {
          isError = true
        }
      }
      if (!isError) {
        setLoadingBtn(false)
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Mercancía recibida',
          }),
          setSteps(newStep)
        )
      } else {
        setLoadingBtn(false)
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Algo salio mal, vuelve a intentar en unos minutos',
          }),
          setSteps(newStep)
        )
      }
      throw new Error(defaultError)
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleCancel = () => {
    MySwal.fire({
      showCloseButton: true,
      showDenyButton: true,
      allowOutsideClick: false,
      buttonsStyling: false,
      reverseButtons: true,
      title: 'Motivo de cancelación',
      html: (
        <div className="message-container-input">
          Describe el motivo de cancelación
        </div>
      ),
      input: 'text',
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterRejectOrder',
      },
      confirmButtonText: 'Cancelar cita',
      denyButtonText: 'Volver',
      inputValidator: (value) => {
        if (!value) return 'Debe escribir el motivo de la cancelación'
      },
      // denyButtonText: '',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const reason = result.value
        /* INSERT AND UPDATE DATA */
        try {
          await cancellSchedule({
            variables: {
              scheduleId: parseInt(_id),
              reason: reason,
              isRecieving: true,
            },
            refetchQueries: [{ query: GET_SCHEDULE }],
          })
          return toastSweetAlert(
            {
              mode: 'ok',
              message: 'Cita cancelada',
            },
            history.push('/productsReception')
          )
        } catch (e) {
          return toastSweetAlert({
            mode: 'error',
            message: e.message,
          })
        }
      }
    })
  }

  const handleFinish = async () => {
    try {
      setLoadingBtn(true)
      let isIssue = false
      let lines = {}
      // for (const j of linesQuantity) {
      //   lines[i.id] = parseInt(lines[i.id]) + parseInt(j.quantity);
      // }
      // for (const i of data.lines) {
      //   lines.push(i.id)
      // }

      // console.log({
      //   "5": lines,
      // });

      // for (const item of data.lines) {
      //   if (item.quantity !== item.openQuantity) {
      //     isIssue = true
      //   }
      // }
      // if (isIssue) {
      //   return handleIssue()
      // }
      const finish = await closeCommodityReceipt({
        variables: {
          scheduleId: parseInt(_id),
        },
        refetchQueries: [{ query: GET_SCHEDULE }],
      })
      if (finish.data.closeCommodityReceipt) {
        setLoadingBtn(false)
        await refetchSchema()
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Mercancía recibida',
          }),
          history.push('/productsReception')
        )
      }
      setLoadingBtn(false)
    } catch (error) {
      setLoadingBtn(false)
      console.log(error)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleFinishIssue = async (issueId, custom) => {
    try {
      setLoadingBtn(true)
      await createCommodityReceiptIssues({
        variables: {
          commodityReceiptIssuesInput: {
            scheduleId: parseInt(_id),
            issuesId: parseInt(issueId),
            issue: custom ? `${custom}` : null,
          },
        },
      })
      const finish = await closeCommodityReceipt({
        variables: {
          scheduleId: parseInt(_id),
        },
      })
      if (finish.data.closeCommodityReceipt) {
        setLoadingBtn(false)

        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Mercancía recibida',
          }),
          history.push('/productsReception')
        )
      }
      setLoadingBtn(false)
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleIssue = async () => {
    MySwal.fire({
      showCloseButton: true,
      title: 'Incidencia en mercancía',
      text: `Seleccione la incidencia`,
      input: 'radio',
      confirmButtonText: 'Aceptar',
      html: (
        <div className="message-container-input pb-4">
          <h3>Seleccione una incidencia</h3>
        </div>
      ),
      buttonsStyling: false,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterRejectOrder',
      },
      inputOptions: issues,
      inputValidator: (issue) => {
        if (!issue) {
          return 'Debe elegir una razon'
        }
        if (issue == 13) {
          return MySwal.fire({
            showCloseButton: true,
            title: 'Escribe el motivo',
            input: 'text',
            buttonsStyling: false,
            html: (
              <div className="message-container-input">Describe el motivo</div>
            ),
            reverseButtons: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            customClass: {
              title: 'StandarModalTitle',
              htmlContainer: 'StandarModalContainer',
              confirmButton: 'StandarModalConfirmButtonLogOut',
              cancelButton: 'StandarModalCancelButtonLogOut',
              footer: 'StandarModalFooterRejectOrder',
            },
            showCancelButton: true,
            inputValidator: (custom) => {
              if (!custom) return 'Debe escribir el motivo'
              return handleFinishIssue(issue, custom)
            },
          })
        }
        return handleFinishIssue(issue)
      },
    })
  }

  const handleDeleteLine = async (lineId, purchaseOrderLineId) => {
    MySwal.fire({
      title: '¿Eliminar línea de escaneo?',
      icon: 'warning',
      html: (
        <div className="message-container-input">
          Esta acción no se podra revertir
        </div>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showDenyButton: true,
      reverseButtons: true,
      denyButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterRejectOrder',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoadingBtn(true)
          await deleteCommodityReceiptLine({
            variables: {
              commodityReceiptId: parseInt(lineId),
              purchaseOrderLineId: parseInt(purchaseOrderLineId),
            },
            // refetchQueries: [
            //   { GET_COMMODITY_RECEIPT_BY_SCHEDULE_ID },
            //   { GET_SCHEDULE_LINES_BY_SCHEDULE_ID },
            // ],
          })
          await refetchCommodityEvidences()
          await refetchSchema()
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'ok',
            message: 'Línea eliminada',
          })
        } catch (error) {
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
    })
  }

  const handlePrintLine = async (type, product, line, scanId) => {
    setTypeValue(type)
    // Print all label by product line
    // console.log(line, 'LINEA DE IMPRESION')
    if (type === 1) {
      let dataToPrint = []
      const fileName = `${moment().format('L')}-${
        product.PurchasesLinesItemInfo.item_code
      }-ALLLINES.pdf`

      for (let i = 0; i < line.length; i++) {
        const item = line[i]
        dataToPrint.push({
          warehouse: data.schedule.warehouse.warehouse_name,
          provider: {
            id: data.schedule.card_code,
            name: data.schedule.card_name,
          },
          product: {
            sku: product?.PurchasesLinesItemInfo?.item_code,
            name: product?.PurchasesLinesItemInfo?.item_name,
          },
          pallet: {
            id: i + 1,
            beds: item.beds,
            items: item.items,
            boxes: item.boxes,
            palletId: item.id,
            quantity: item.quantity,
          },
          order: {
            id: product?.document_number,
            type: 'Orden de compra',
            date: moment().format('L'),
            numberAtCard: product?.number_at_card,
            barcode: product?.PurchasesLinesItemInfo?.item_code_bar,
          },
          positionId: item.positionId,
          anada: item?.anada
            ? item?.anada
            : item?.expiration_date
            ? item?.expiration_date
            : 'N/A',
        })
      }
      // console.log(dataToPrint, 'DATA A IMPRIMIR')
      setDataToPrint(dataToPrint)
      setBarcodeValue(product.PurchasesLinesItemInfo?.item_code_bar)
      setFileName(fileName)
    }
    // Print label line
    if (type === 2) {
      const fileName = `${moment().format('L')}-${
        product.PurchasesLinesItemInfo.item_code
      }-${line.id}.pdf`

      const dataToPrint = {
        warehouse: data.schedule.warehouse.warehouse_name,
        product: {
          sku: product?.PurchasesLinesItemInfo?.item_code,
          name: product?.PurchasesLinesItemInfo?.item_name,
        },
        provider: {
          id: data.schedule.card_code,
          name: data.schedule.card_name,
        },
        pallet: {
          id: scanId,
          beds: line.beds,
          items: line.items,
          boxes: line.boxes,
          palletId: line.id,
          quantity: line.quantity,
        },
        order: {
          id: product?.document_number,
          type: 'Orden de compra',
          date: moment().format('L'),
          numberAtCard: product?.number_at_card,
        },
        positionId: line.positionId,
        anada: line?.anada
          ? line?.anada
          : line?.expiration_date
          ? line?.expiration_date
          : 'N/A',
      }
      setBarcodeValue(product.PurchasesLinesItemInfo?.item_code_bar)
      // setBarcodeValue(product?.PurchasesLinesItemInfo?.ItemReceipt[0]?.barcode)
      setDataToPrint(dataToPrint)
      setFileName(fileName)
    }
  }

  useEffect(() => {
    if (barcodeValue === '1') return

    if (typeValue === 1) {
      const fetchMasterLabels = async () => {
        const response = await masterLabelsGenerator(
          dataToPrint,
          fileName,
          inputRef
        )
        if (!response) {
          return toastSweetAlert({
            mode: 'error',
            message:
              'Oops, algo salió mal por favor intente de nuevo en unos momento.',
          })
        }
      }
      fetchMasterLabels()
      setTypeValue()
      setDataToPrint([])
      setFileName()
      setBarcodeValue('1')
    }

    if (typeValue === 2) {
      const fetchLabel = async () => {
        const response = await masterLabel(dataToPrint, fileName, inputRef)
        if (!response) {
          return toastSweetAlert({
            mode: 'error',
            message:
              'Oops, algo salió mal por favor intente de nuevo en unos momento.',
          })
        }
      }
      fetchLabel()
      setTypeValue()
      setDataToPrint([])
      setFileName()
      setBarcodeValue('1')
    }
  }, [barcodeValue])

  return (
    <>
      <ContentHeader
        title="Recibo de mercancía"
        breadcrumb="Recibo de mercancía"
        windowTitle="Recibo de mercancía"
      />
      {data && (
        <div className={`${loadingBtn ? 'processing-file' : ''}`}>
          {steps === 1 && (
            <Step1
              loadingData={data.loading}
              lines={data.lines}
              orders={data.orders}
              schedule={data.schedule}
              evidence={evidence}
              isLoading={loadingBtn}
              linesQuantity={linesQuantity}
              nextStep={handleNextStep}
              onFinish={handleFinish}
              onCancel={handleCancel}
              onDeleteLine={handleDeleteLine}
              handlePrintLine={handlePrintLine}
            />
          )}
          {steps === 2 && (
            <Step2
              lines={data.lines}
              data={selected}
              setData={setSelected}
              nextStep={handleNextStep}
              previousStep={handlePreviousStep}
            />
          )}
          {steps === 3 && (
            <Step4
              data={selected}
              setData={setSelected}
              issues={issues}
              handleSave={onSubmit}
              resetStep={handleResetStep}
              previousStep={handlePreviousStep}
              evidenceStep={() => setSteps(4)}
            />
          )}
          {steps === 4 && (
            <Step3
              data={selected}
              setData={setSelected}
              nextStep={handlePreviousStep}
              previousStep={handlePreviousStep}
            />
          )}
          {barcodeValue && (
            <img id="barcode" className="invisible" ref={inputRef}></img>
          )}
        </div>
      )}
    </>
  )
}

export default ProductReceptionNew
