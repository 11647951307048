import { gql } from '@apollo/client'

export const GET_SHIPPING_COMPANIES = gql`
  query Query {
    getAllShippingCompanies {
      id
      name
    }
  }
`

export const GET_ALL_SHIPPING_COMPANIES_ROWS = gql`
  query GetAllShippingCompaniesPagination($searchQuery: String, $limit: Int, $offset: Int) {
    getAllShippingCompaniesPagination(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        name
      }
      count
    }
  }
`

export const GET_ONE_SHIPPING_COMPANY = gql`
  query GetOneShippingCompany($id: Int!) {
    getOneShippingCompany(id: $id) {
      id
      name
    }
  }
`

export const DELETE_SHIPPING_COMPANY = gql`
  mutation DeleteShippingCompany($id: Int!) {
    deleteShippingCompany(id: $id)
  }
`

export const CREATE_SHIPPING_COMPANY = gql`
  mutation CreateShippingCompany($name: String!) {
    createShippingCompany(name: $name)
  }
`

export const UPDATE_SHIPPING_COMPANY = gql`
  mutation UpdateShippingCompany($id: Int!, $name: String!) {
    updateShippingCompany(id: $id, name: $name)
  }
`