import CounterItem from "./CounterItem"

const Counters =({
    counters = [],
    title = ""
})=>{
    return (
        <div className="row">

        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{title}</h3>
                </div>
                <div className="card-body col-12">
                    
                    <div className="row">
                        {counters && counters.map((counter,key)=>{
                            return <CounterItem
                                key={key} 
                                title={counter.title}
                                counter={counter.counter}
                            />
                        })}
                    </div>

                </div>
            </div>
        </div>

    </div>
    )
}

export default Counters