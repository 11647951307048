const MenuPaginationItem = ({ handleClick, data = [] }) => {
  const menuRoutes = [
    {
      id: 1,
      main: 'Vallarta',
      count: 3,
      subRoutes: [
        { name: 'SubAndares1', id: 4 },
        { name: 'SubAndares2', id: 5 },
        { name: 'SubAndares3', id: 6 },
      ],
    },
    {
      id: 3,
      main: 'Tlajomulco',
      count: 2,
      subRoutes: [
        { name: 'SubAndares1', id: 4 },
        { name: 'SubAndares2', id: 5 },
      ],
    },
    {
      id: 2,
      count: 1,
      main: 'Andares',
    },
  ]
  const handleActiveClass = (e, item) => {
    const activeLinks = document.querySelectorAll('.link-activo')
    const activeMenu = document.querySelectorAll('.item-menu-container')

    if (activeLinks.length > 0) {
      activeLinks.forEach((link) => {
        link.classList.remove('link-activo')
      })
      activeMenu.forEach((link) => {
        link.classList.remove('item-menu-container')
      })
    }

    if (!e.target.className.includes('link-activo')) {
      e.target.classList.add('link-activo')

      if (e.target.parentElement.parentElement.className.includes('pos-f-t')) {
        e.target.parentElement.parentElement.children[0].classList.add(
          'item-menu-container'
        )
      } else {
        activeMenu.forEach((link) => {
          link.classList.remove('item-menu-container')
        })
      }
    }
    handleClick(item.id)
  }

  const handleRotate = (e) => {
    const menu = e.target.dataset.menuid
    const collapseMenuItem = document.getElementById(menu)
    const arrow = document.querySelector(`i[id="arrow-${menu}"]`)

    if (!arrow.className.includes('toggle-up')) {
      arrow.classList.remove('toggle-down')
      arrow.classList.add('toggle-up')
    } else {
      arrow.classList.remove('toggle-up')
      arrow.classList.add('toggle-down')
    }

    if (!collapseMenuItem.className.includes('show')) {
      collapseMenuItem.classList.add('show')
    } else {
      collapseMenuItem.classList.remove('show')
    }
  }

  return (
    <>
      <div
        className="nav flex-column shipment-routes-menu"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        {data.map((item, i) => {
          return item.count == 0 ? (
            <>
              <a
                key={`${item.main}-${item.count}`}
                className="nav-link mb-2 item-border item-menu"
                id={`${item.main}-${item.count}`}
                data-toggle="pill"
                href={`"#v-pills-${item.main}-${i}"`}
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                onClick={(e) => {
                  handleActiveClass(e, item)
                }}
              >
                {item.main}
              </a>
            </>
          ) : (
            <div className="pos-f-t">
              <nav
                // style={{ color: '#000' }}
                className=" mb-2  nav-link item-border  d-flex justify-content-between collapse-items-menu"
                onClick={handleRotate}
                data-toggle="collapse"
                data-target={`#navbarToggleExternalContent-${item.id}`}
                id={item.id}
                data-menuid={item.id}
              >
                <p className="m-0" data-menuid={item.id}>
                  {item.main}
                </p>
                <div className="row align-items-center " data-menuid={item.id}>
                  <div className="col col-md-6 " data-menuid={item.id}>
                    <p
                      className=" m-0 badge badge-secondary text-white routes-menu-badge "
                      data-menuid={item.id}
                    >
                      {item.count}
                    </p>
                  </div>
                  <div className="col col-md-6" data-menuid={item.id}>
                    <i
                      data-menuid={item.id}
                      id={`arrow-${item.id}`}
                      className="right fas fa-angle-right toggle-down"
                    ></i>
                  </div>
                </div>
              </nav>
              <div
                className={`collapse `}
                id={`navbarToggleExternalContent-${item.id}`}
              >
                {item.subRoutes.map((subroutes, index) => {
                  return (
                    <a
                      key={`${subroutes.name}`}
                      className=" mb-2 nav-link item-border link-hover-color"
                      id={`${subroutes.name}`}
                      data-toggle="pill"
                      // href={`"#v-pills-${item.routes.main}-${index}"`}
                      href={`"#v-pills-home"`}
                      role="tab"
                      aria-controls="v-pills"
                      aria-selected="true"
                      onClick={(e) => {
                        handleActiveClass(e, subroutes)
                      }}
                    >
                      {subroutes.name}
                    </a>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default MenuPaginationItem
