import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import OrderCol from '../Global/OrderCol'
import { useQuery } from '@apollo/client'
import { GET_SHIPPING_COMPANIES } from '../../graphql/Catalog/ShippingCompanies/shippingCompanies'
import { GET_STORES } from '../../graphql/Catalog/Stores/stores'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import { fileValidator } from '../Helpers/GenericFunctions'

function OrderDetailStore({
  withStore,
  orderType,
  orderStore,
  orderMethod,
  orderPaymentPlatform,
  shippingPrice,
  totalPrice,
  id_store,
  status_id,
  uberAssignedId,
  id_shippingCompany,
  shippingCompanyName,
  show = false,
  handleStoreChange = () => {},
  handleShippingCompanyChange = () => {},
  setUploadReceipt = false,
  shippingReceipt = false,
  setBillingForm = false,
  billingForm = false,
}) {
  /*
      STATUS
      id  name
      ---------------
      1 = pending
      2 = processing
      3 = billing
      4 = local-shipping
      5 = national-shipping
      6 = supply
      7 = in-route
      8 = collect
      9 = picking
      10 = packing
      11 = completed
      12 = rejected
      13 = returned
  */

  const [storeOptions, setStoreOptions] = useState([])
  const [shippingCompaniesOptions, setShippingCompaniesOptions] = useState([])
  const [previewDoc, setPreviewDoc] = useState(false)

  var currencyFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  })

  const {
    data: dataStores,
    loading: loadingStores,
    error: errorStores,
  } = useQuery(GET_STORES)
  const {
    data: dataShippingCompanies,
    loading: loadingShippingCompanies,
    error: errorShippingCompanies,
  } = useQuery(GET_SHIPPING_COMPANIES)

  useEffect(() => {
    if (!loadingStores) {
      if (errorStores)
        return setStoreOptions([{ value: null, label: 'Ocurrio un error' }])
      const options = dataStores.getAllStores.rows.map((store) => {
        return {
          value: store.id,
          label: store.name,
        }
      })
      setStoreOptions(options)
    }
  }, [dataStores, loadingStores, errorStores])

  useEffect(() => {
    if (!loadingShippingCompanies) {
      if (errorShippingCompanies)
        return setStoreOptions([{ value: null, label: 'Ocurrio un error' }])
      const options = dataShippingCompanies.getAllShippingCompanies.map(
        (company) => {
          return {
            value: company.id,
            label: company.name,
          }
        }
      )
      setShippingCompaniesOptions(options)
    }
  }, [dataShippingCompanies, loadingShippingCompanies, errorShippingCompanies])

  const handleChangeDoc = async (e) => {
    if (!e.target.files[0]) {
      return
    }

    const typeDoc = e.target.files[0].type.split('/')[1]

    const validateTypeDoc =
      typeDoc === 'pdf' ||
      typeDoc === 'png' ||
      typeDoc === 'jpg' ||
      typeDoc === 'jpeg'

    console.log('validateTypeDoc', validateTypeDoc)

    if (!validateTypeDoc) {
      return toastSweetAlert({
        mode: 'error',
        message: 'Formato no válido',
      })
    }

    setPreviewDoc(false)

    const validate = await fileValidator(e.target.files[0], typeDoc)
    if (validate) {
      putPreview(e)
      setUploadReceipt(e.target.files[0])
    } else
      return toastSweetAlert({
        mode: 'error',
        message: 'Formato no válido',
      })
  }

  const handleChangeInvoice = async (e) => {
    if (!e.target.files[0]) {
      return
    }

    const typeDoc = e.target.files[0].type.split('/')[1]

    const validateTypeDoc =
      typeDoc === 'pdf' ||
      typeDoc === 'png' ||
      typeDoc === 'jpg' ||
      typeDoc === 'jpeg'

    console.log('validateTypeDoc', validateTypeDoc)

    if (!validateTypeDoc) {
      return toastSweetAlert({
        mode: 'error',
        message: 'Formato no válido',
      })
    }

    setPreviewDoc(false)

    const validate = await fileValidator(e.target.files[0], typeDoc)
    if (validate) {
      putPreview(e)
      setBillingForm({ ...billingForm, invoice: e.target.files[0] })
    } else
      return toastSweetAlert({
        mode: 'error',
        message: 'Formato no válido',
      })
  }

  const putPreview = (e) => {
    var fileToLoad = e.target.files[0]
    var fileReader = new FileReader()
    var base64File
    fileReader.onload = function (event) {
      base64File = event.target.result
      setPreviewDoc(base64File)
    }

    fileReader.readAsDataURL(fileToLoad)
  }

  const handleDeleteDoc = () => {
    setPreviewDoc(false)
    setUploadReceipt(false)
    document.getElementById('file').value = ''
  }

  return (
    <>
      <div className="col-12 box-separator">
        <div className="row">
          <OrderCol
            title="Tipo de pedido"
            content={orderType}
            mediumColSize="6"
          />
          {withStore || status_id !== 1 || show ? (
            <OrderCol title="Tienda" content={orderStore} mediumColSize="6" />
          ) : (
            <div className="col-6">
              <h5 className="header-text-details">Tienda</h5>
              <Select
                name={'store'}
                placeholder="Selecciona una tienda"
                NoOptionsMessage="No hay datos registrados"
                options={storeOptions}
                onChange={(e) => handleStoreChange(e)}
                value={storeOptions?.find((option) => {
                  if (parseInt(option.value) === parseInt(id_store)) {
                    return option
                  }
                  return null
                })}
              />
            </div>
          )}
          {(orderMethod === 'Fast' || orderMethod === 'Picking') && (
            <OrderCol
              title="Tipo de entrega"
              content={orderMethod}
              mediumColSize="6"
            />
          )}
          {orderMethod === 'UDirect' && (
            <>
              <OrderCol
                title="Tipo de entrega"
                content={orderMethod}
                mediumColSize="6"
              />
              <OrderCol
                title="Tipo de logística"
                content={orderMethod}
                mediumColSize="6"
              />
              <OrderCol
                title="Uber asignó un ID"
                content={uberAssignedId}
                mediumColSize="6"
              />
            </>
          )}
          {orderType === 'Nacional' &&
            (!show ? (
              <>
                {!shippingCompanyName ? (
                  <div className="col-6">
                    <h5 className="header-text-details">Logística</h5>
                    <Select
                      isDisabled={setBillingForm}
                      name={'logistictCompany'}
                      placeholder="Selecciona una compañia"
                      NoOptionsMessage="No hay datos registrados"
                      options={shippingCompaniesOptions}
                      onChange={(e) => handleShippingCompanyChange(e)}
                      value={shippingCompaniesOptions?.find((option) => {
                        if (
                          parseInt(option.value) ===
                          parseInt(id_shippingCompany)
                        ) {
                          return option
                        }
                        return null
                      })}
                    />
                  </div>
                ) : (
                  <OrderCol
                    title="Logística"
                    content={shippingCompanyName}
                    mediumColSize="6"
                  />
                )}
                {setBillingForm && (
                  <>
                    <div className="col-md-6 col-sm-12 mt-2">
                      <h5 className="header-text-details">No. Factura</h5>
                      <input
                        className="form-control"
                        onChange={(e) =>
                          setBillingForm({
                            ...billingForm,
                            num_invoice: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                      <h5 className="header-text-details">No. Documento</h5>
                      <input
                        className="form-control"
                        onChange={(e) =>
                          setBillingForm({
                            ...billingForm,
                            num_doc: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                      <h5 className="header-text-details">Factura</h5>
                      {previewDoc && (
                        <>
                          <span
                            style={{
                              cursor: 'pointer',
                              marginLeft: '400px',
                              marginBotton: '50px',
                            }}
                            className="icon-delete-document"
                            onClick={handleDeleteDoc}
                          >
                            <i className="fas fa-times"></i>
                          </span>
                          <embed
                            src={previewDoc + '#toolbar=0'}
                            width="330px"
                            height="250px"
                          />
                        </>
                      )}
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="uploadFile mx-auto"
                        accept=".pdf, .jpg, .jpeg, .png"
                        onChange={handleChangeInvoice}
                      />
                      <label
                        htmlFor="file"
                        className="process-btn process-btn__gray"
                      >
                        {!previewDoc ? 'Subir documento' : 'Cambiar documento'}
                        &nbsp;
                        <i className="fas fa-upload"></i>
                      </label>
                    </div>
                  </>
                )}
              </>
            ) : (
              <OrderCol
                title="Logística"
                content={shippingCompanyName}
                mediumColSize="6"
              />
            ))}

          {orderMethod === 'Fast' &&
            status_id === 6 &&
            (!show ? (
              <div className="col-6">
                <h5 className="header-text-details">Logística</h5>
                <Select
                  name={'logistictCompany'}
                  placeholder="Selecciona una compañia"
                  NoOptionsMessage="No hay datos registrados"
                  options={shippingCompaniesOptions}
                  onChange={(e) => handleShippingCompanyChange(e)}
                  value={shippingCompaniesOptions?.find((option) => {
                    if (
                      parseInt(option.value) === parseInt(id_shippingCompany)
                    ) {
                      return option
                    }
                    return null
                  })}
                />
              </div>
            ) : (
              <OrderCol
                title="Logística"
                content={shippingCompanyName}
                mediumColSize="6"
              />
            ))}

          <OrderCol
            title="Tipo de pago"
            content={orderPaymentPlatform}
            mediumColSize="6"
          />
          {shippingReceipt ? (
            <div className="col-sm-12 col-md-6">
              <h5 className="header-text-details">Ficha de envio</h5>
              <a
                className="documentLink"
                href={shippingReceipt}
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar etiqueta &nbsp; <i className="fas fa-file"></i>
              </a>
            </div>
          ) : (
            setUploadReceipt &&
            !show &&
            status_id === 1 &&
            orderType === 'Nacional' && (
              <>
                <div className="col-sm-12 col-md-6">
                  <h5 className="header-text-details">Ficha de envío</h5>
                  {previewDoc && (
                    <>
                      <span
                        style={{
                          cursor: 'pointer',
                          marginLeft: '400px',
                          marginBotton: '50px',
                        }}
                        className="icon-delete-document"
                        onClick={handleDeleteDoc}
                      >
                        <i class="fas fa-trash"></i>
                      </span>
                      <embed
                        src={previewDoc + '#toolbar=0'}
                        width="400px"
                        height="300px"
                      />
                    </>
                  )}
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="uploadFile mx-auto"
                    accept=".pdf, .png, .jpg, .jpeg"
                    onChange={handleChangeDoc}
                  />
                  <label
                    htmlFor="file"
                    className="process-btn process-btn__gray"
                  >
                    {!previewDoc ? 'Subir documento' : 'Cambiar documento'}
                    &nbsp;
                    <i className="fas fa-upload"></i>
                  </label>
                </div>
              </>
            )
          )}
        </div>
      </div>

      {/* DETAILS OF PRICES */}
      <div className="col-12">
        <div className="row justify-content-between">
          <p className="cost-header">Producto</p>
          <p className="cost-text">{`${currencyFormat.format(
            totalPrice - shippingPrice
          )}`}</p>
        </div>
        <div className="row justify-content-between">
          <p className="cost-header">Envío</p>
          <p className="cost-text">{`${currencyFormat.format(
            shippingPrice
          )}`}</p>
        </div>
        <div className="row justify-content-end">
          <p className="cost-header text-bold">
            Total: {`${currencyFormat.format(totalPrice)}`}
          </p>
        </div>
      </div>
    </>
  )
}

export default OrderDetailStore
