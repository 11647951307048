import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import LayoutTable from '../Global/LayoutTable'
import ContentHeader from '../Layout/ContentHeader'

// apollo
import { GET_ALL_WAREHOUSE_MASTER_DATA, DELETE_MASTER_DATA_WAREHOUSE } from '../../graphql/Catalog/Definitions/WarehouseMasterData'

export const ReturnsConfiguration = () => {
    const [listOFlows, setListOfFlows] = useState([])
    const [total, setTotal] = useState(0)
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })

    const { loading, error, data, refetch: refetchAllFlowsTransfer, } = useQuery(GET_ALL_WAREHOUSE_MASTER_DATA, {
        variables: {
            searchQuery: pagePagination.searchQuery,
            limit: pagePagination.limit,
            offset: pagePagination.offset,
        },
        fetchPolicy: 'no-cache',
    })

    const [deleteFlow] = useMutation(DELETE_MASTER_DATA_WAREHOUSE)

    useEffect(() => {
        if (!loading) {
            if (error)
                return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })

            let list = []
            try {
                data.getAllWarehouseMasterData.rows.map((element) => {
                    return list.push({
                        id: element.id,
                        ['Socio de negocio']: element.BusinessPartnerWhsMasterData.card_code.concat(' - ', element.BusinessPartnerWhsMasterData.card_name),
                        ['Almacen']: element.WarehouseWhsMasterData.warehouse_code.concat(' - ', element.WarehouseWhsMasterData.warehouse_name),
                        ['Traslado']: element.TransitWhsWarehouseMasterData.warehouse_code.concat(' - ', element.TransitWhsWarehouseMasterData.warehouse_name),
                        ['Mermas']: element.WasteWhsWarehouseMasterData.warehouse_code.concat(' - ', element.WasteWhsWarehouseMasterData.warehouse_name),
                    })
                })
            } catch (error) {
                console.log(error)
                return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })
            }
            setListOfFlows(list)
            setTotal(data.getAllWarehouseMasterData.count)
        }
    }, [loading, error, data])

    const onDelete = async (id) => {
        try {
            const response = await toastSweetAlert({
                mode: 'modalConfirmCancel',
                title: 'Eliminar registro',
                message: `La eliminacion del registro es permanente ¿Desea continuar?`,
            })
            if (response.isConfirmed) {
                const result = await deleteFlow({
                    variables: { deleteWarehouseMasterDataId: id },
                    refetchQueries: refetchAllFlowsTransfer,
                })


                if (result.data.deleteWarehouseMasterData) {
                    refetchAllFlowsTransfer()
                }
            }
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }
    return <>
        <ContentHeader
            title="Configuracion de Devoluciones"
            breadcrumb="Configuracion de Devoluciones"
            windowTitle={`Configuracion de Devoluciones`}
        />
        <LayoutTable
            title="Flujos de Devoluciones"
            data={listOFlows}
            hideId={false}
            totalCount={total}
            hideFilterBar={false}
            actionBtnErase={(e) => { onDelete(e) }}
            requery={refetchAllFlowsTransfer}           
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}
           
        />
    </>
}

export default ReturnsConfiguration