const DropDownButton = ({ handleDropDownButton }) => {
  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn StandarModalConfirmButtonLogOut dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Descargar Reporte
      </button>
      <div className="dropdown-menu">
        <p
          className="dropdown-item"
          data-download="csv"
          onClick={handleDropDownButton}
        >
          Excel
        </p>
        <p
          className="dropdown-item"
          data-download="pdf"
          onClick={handleDropDownButton}
        >
          PDF
        </p>
      </div>
    </div>
  )
}

export default DropDownButton
