import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import LayoutTable from '../Global/LayoutTable'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
// apollo
import { GET_ALL_JOBS_BY_STATE } from '../../graphql/HangFire/ReportTransactions'

const SucceededRequest = ( {stateName, setPagePagination , pagePagination}  ) => {
    const [dataToTable, setDataToTable] = useState([])
    const [total, setTotal] = useState(0)


    const { data, loading, error } = useQuery(GET_ALL_JOBS_BY_STATE, {
        variables: {
            stateName: String(stateName),
            limit: parseInt(pagePagination.limit),
            offset: parseInt(pagePagination.offset),
        },
    })

    // JOBS_BY_STATE
    useEffect(() => {
        if (!loading) {
            if (error)
              {
                
                  return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })}

            try {
                let list = []
                data.getAllJobsByStateName.rows.map((item) => {
                    return list.push({
                        id: item.id,
                        State: item.stateName,
                        Method: item.Detail.method,
                    })
                })
                setDataToTable(list)
                setTotal(data.getAllJobsByStateName.count)
            } catch (error) {
                console.log('ErrorERROR_JOBS_BY_STATE', error.message)
                return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })
            }
        }
    }, [data, loading, error])

    return (
        <LayoutTable
            data={dataToTable}
            title="Completadas"
            totalCount={total}
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}
            loadingData={loading}
            hideBtnEdit={true}
            hideBtnErase={true}
            hideFilterBar={true}
            paginationRowsPerPageOptions={[100, 200, 500, 1000,5000]}
        />
    )
}

export default SucceededRequest