import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_ALL_BANKS_FINANCES_COUNTER_CHARGES } from '../../../../../graphql/Catalog/FinancesCounterCharges/banksfinancescountercharges'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'
import LayoutTable from '../../../../Global/LayoutTable'

const BanksFinancesCounterCharges = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const {
    loading: loadingBanks,
    error: errorBanks,
    data: dataBanks,
  } = useQuery(GET_ALL_BANKS_FINANCES_COUNTER_CHARGES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!loadingBanks) {
      if (errorBanks)
        return toastSweetAlert({
          mode: 'error',
          message: 'Error al cargar los bancos',
        })

      let list = []

      dataBanks?.getAllBanksFinancesCounterCharges?.rows?.map((item) => {
        return list.push({
          id: item?.id,
          Nombre: item?.bank_name,
          Nomenclatura: item?.bank_nomenclature,
          'Fol. consecutivo': item?.bank_invoice_number,
        })
      })
      setDataToTable(list)
      setTotal(dataBanks?.getAllBanksFinancesCounterCharges?.count)
    }
  }, [loadingBanks, errorBanks, dataBanks])

  return (
    <>
      {/* <ContentHeader windowTitle="Bancos" title="Bancos" breadcrumb="Bancos" /> */}

      <LayoutTable
        title="Bancos"
        data={dataToTable}
        totalCount={total}
        loading={loadingBanks}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        exportImportButton={false}
        hideBtnErase
        hideId
        hideDetails
      />
    </>
  )
}

export default BanksFinancesCounterCharges
