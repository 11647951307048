import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { MODIFY_PACKING_LIST } from '../../../graphql/Catalog/Transfers/Transfers'

export const FormEditOrderWarehouse = ({ data, refetch, setLoadingBtn }) => {
  const refModal = useRef()
  const [modify] = useMutation(MODIFY_PACKING_LIST)

  const validationSchema = Yup.object().shape({
    boxes: Yup.string().test(
      'box-validation',
      'Debes ingresar una caja o una botella',
      (value, context) => {
        return context.parent.bottles > 0 || value > 0
      }
    ),
    bottles: Yup.string().test(
      'box-validation',
      'Debes ingresar una caja o una botella',
      (value, context) => {
        return context.parent.boxes > 0 || value > 0
      }
    ),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (dataForm) => {
    setLoadingBtn(true)
    try {
      await modify({
        variables: {
          transferLineId: data.row.id,
          packingListId: data.row.packingListID_omitField,
          quantityBoxes: dataForm.boxes ? parseInt(dataForm.boxes) : 0,
          quantityBottles: dataForm.bottles ? parseInt(dataForm.bottles) : 0,
        },
      })
      await refetch()
      refModal.current.click()
      reset()
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'ok',
        message: 'Orden modificada correctamente',
      })
    } catch (error) {
      setLoadingBtn(false)
      reset()
      refModal.current.click()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
          <Box
            btnSecondFunction={() => {}}
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={true}
            btnSubmitText="Guardar"
            errors={errors}
            refModal={refModal}
            btnSubmit={true}
            withCard={false}
            content={
              <div className="row">
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Producto"
                    type="text"
                    name="product"
                    valueManual={data?.row?.nombre}
                    control={control}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Cajas"
                    type="text"
                    name="boxes"
                    control={control}
                  />
                  <small className="form-text text-muted">
                    Cajas actuales: {data?.row?.Cajas}
                  </small>
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Botellas"
                    type="text"
                    name="bottles"
                    control={control}
                  />
                  <small className="form-text text-muted">
                    Botellas actuales: {data?.row?.Botellas}
                  </small>
                </div>
              </div>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default FormEditOrderWarehouse
