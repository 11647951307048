const CounterItem =({
    title = "",
    counter = 0,
    key = 0,
})=>{
    return (
        <div className="displacementItemMain col-3" key={key}>
            <h6>{title}</h6>
            <hr />
            {counter}
        </div>
    )
}

export default CounterItem