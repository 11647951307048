const LowerPackages = () => {
  return (
    <>
      {/* <Box
        title="Último reporte de inventario"
        content={
          <>
            <LastInventoryReport />
          </>
        }
      />
      <Box
        title="Último reporte de desplazamientos"
        content={
          <>
            <LastDisplacementReport />
          </>
        }
      /> */}
      <h1 className="text-center font-weight-bold pt-4">Actualiza tu suscripción</h1>

      <div className="row container-fluid mt-4">
        <div className="col-xl-3">
          <div className="card">
            <div className="card-header h3">Paquete 2</div>
            <div className="card-body">
              {/* <h5 class="card-title"></h5> */}
              <ul className="list-group">
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Reportes del último mes
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Conteos por almacenes
                </li>
              </ul>

              <div className="d-flex justify-content-center mt-3">
                <button className="btn  StandarModalConfirmButtonLogOut">
                  Ver más
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card">
            <div className="card-header h3">Paquete 3</div>
            <div className="card-body">
              {/* <h5 class="card-title"></h5> */}
              <ul className="list-group">
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Reportes quincenales y mensuales
                </li>

                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Conteos por almacenes
                </li>
              </ul>

              <div className="d-flex justify-content-center mt-3">
                <button className="btn  StandarModalConfirmButtonLogOut ">
                  Ver más
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-12">
          <div className="card">
            <div className="card-header h3">Paquete 4</div>
            <div className="card-body">
              {/* <h5 class="card-title"></h5> */}
              <ul className="list-group">
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Reportes quincenales y mensuales
                </li>

                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Conteos por almacenes
                </li>

                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Reportes del año hasta la fecha
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Reporte de los últimos 12 meses
                </li>

                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Consultas en tiempo real
                </li>

                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Gráficas comparativas
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Ver detalles de desplazamientos e inventarios
                </li>
              </ul>

              <div className="d-flex justify-content-center mt-3">
                <button className="btn  StandarModalConfirmButtonLogOut">
                  Ver más
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-12">
          <div className="card">
            <div className="card-header h3">
              Paquete personalizado
            </div>
            <div className="card-body">
              {/* <h5 class="card-title"></h5> */}
              <ul className="list-group">
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Icluye cualquier funcionalidad de los paquetes anteriores
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check-circle mr-2 icon-color-sucess"></i>
                  Ajustable a la necesidad del cliente
                </li>
              </ul>

              <div className="d-flex justify-content-center mt-3">
                <button className="btn  StandarModalConfirmButtonLogOut">
                  Ver más
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LowerPackages
