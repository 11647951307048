import React, { useEffect, useState } from 'react'
import LayoutTable from '../../../Global/LayoutTable'
import { useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS } from '../../../../graphql/InventoryActivities/InventoryActivitiesAccountants'

const TableAssignmentAccountants = ({
  pagePaginationAccountants,
  setPagePaginationAccountants,
  accountantsOneSelected,
  setAccountantsOneSelected,
  accountantsTwoSelected,
  setAccountantsTwoSelected,
  justOneTableOfAccountants,
  showJustResume,
}) => {
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [activeRemoveGroupOneBtn, setActiveRemoveGroupOneBtn] = useState(false)
  const [activeRemoveGroupTwoBtn, setActiveRemoveGroupTwoBtn] = useState(false)
  const [checkedAccountantsOne, setCheckedAccountantsOne] = useState([])
  const [checkedAccountantsTwo, setCheckedAccountantsTwo] = useState([])
  const [toggledClearRowsOne, setToggleClearRowsOne] = useState(true)
  const [toggledClearRowsTwo, setToggleClearRowsTwo] = useState(true)

  const [accountantsDataTable, setAccountantsDataTable] = useState({
    items: [],
    total: 0,
  })

  const accountants = useQuery(GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS, {
    variables: pagePaginationAccountants,
    refetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  })

  //FOR ACCOUNTANTS
  useEffect(() => {
    try {
      if (accountants?.loading) return

      if (accountants.error)
        return toastSweetAlert({
          mode: 'error',
          message: accountants.error.message,
        })

      let data = []
      accountants?.data?.getAllInventoryActivitiesAccountant?.rows?.forEach(
        (row) => {
          data.push({
            id: row.user_id,
            Nombre: row.full_name,
            conditional_status: row.InventoryActivitiesAccountantHasLabels
              ? 1
              : 2,
          })
        }
      )

      setAccountantsDataTable({
        items: data,
        total: accountants.data.getAllInventoryActivitiesAccountant.count,
      })
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    accountants.loading,
    accountants.data,
    accountants.error,
    pagePaginationAccountants,
  ])

  // FUNCTIONS
  const handleAddAccountant = async (row, count) => {
    try {
      setLoadingProcess(true)
      if (count === 1) {
        setAccountantsOneSelected((counts) => [
          ...counts,
          {
            id: row.id,
            Nombre: row.Nombre,
          },
        ])
      }

      if (count === 2) {
        setAccountantsTwoSelected((counts) => [
          ...counts,
          {
            id: row.id,
            Nombre: row.Nombre,
          },
        ])
      }
      const { excludeIds } = pagePaginationAccountants
      excludeIds.push(row.id)
      await accountants.refetch()
      if (count === 1) setToggleClearRowsOne(!toggledClearRowsOne)
      if (count === 2) setToggleClearRowsTwo(!toggledClearRowsTwo)
      setLoadingProcess(false)
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleRemoveAccountant = async (row, count) => {
    try {
      setLoadingProcess(true)
      const { excludeIds } = pagePaginationAccountants

      let newExcludeIds = []
      let newAccountants = []
      // TO REMOVE JUST ONE ELEMENT
      if (row) {
        newExcludeIds = excludeIds.filter((accountant) => accountant !== row.id)

        newAccountants =
          count === 1
            ? accountantsOneSelected.filter(
                (accountant) => accountant.id !== row.id
              )
            : accountantsTwoSelected.filter(
                (accountant) => accountant.id !== row.id
              )
        if (count === 1) setAccountantsOneSelected(newAccountants)
        if (count === 2) setAccountantsTwoSelected(newAccountants)
      }
      // TO REMOVE A GROUP
      if (!row && (count === 1 || count === 2)) {
        const accountantsToCheck =
          count === 1 ? checkedAccountantsOne : checkedAccountantsTwo

        for (const accountantTocheck of accountantsToCheck) {
          newAccountants.push(newAccountants.push(accountantTocheck.id))
        }
      }

      if (!row && count === 1) {
        newExcludeIds = excludeIds.filter(
          (accountantId) => !newAccountants.includes(accountantId)
        )

        setAccountantsOneSelected((accountantsSelected) => {
          return accountantsSelected.filter(
            (prev) => !newAccountants.includes(prev.id)
          )
        })
      }
      if (!row && count === 2) {
        newExcludeIds = excludeIds.filter(
          (accountantId) => !newAccountants.includes(accountantId)
        )
        setAccountantsTwoSelected((accountantsSelected) => {
          return accountantsSelected.filter(
            (prev) => !newAccountants.includes(prev.id)
          )
        })
      }

      setPagePaginationAccountants({
        ...pagePaginationAccountants,
        excludeIds: newExcludeIds,
      })
      if (count === 1) {
        setToggleClearRowsOne(!toggledClearRowsOne)
        setActiveRemoveGroupOneBtn(false)
      }
      if (count === 2) {
        setToggleClearRowsTwo(!toggledClearRowsTwo)
        setActiveRemoveGroupTwoBtn(false)
      }
      setLoadingProcess(false)
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleCheckAccountant = (selections, count) => {
    if (count === 1) {
      setCheckedAccountantsOne(selections.selectedRows)
      if (selections.selectedRows.length > 1) setActiveRemoveGroupOneBtn(true)
      if (selections.selectedRows.length < 1) setActiveRemoveGroupOneBtn(false)
    }
    if (count === 2) {
      setCheckedAccountantsTwo(selections.selectedRows)
      if (selections.selectedRows.length > 1) setActiveRemoveGroupTwoBtn(true)
      if (selections.selectedRows.length < 1) setActiveRemoveGroupTwoBtn(false)
    }
  }

  return (
    <div className={`${loadingProcess ? 'processing-file' : ''}`}>
      {!showJustResume && (
        <LayoutTable
          title="Selecciona contadores"
          hideAddNew
          exportImportButton={false}
          data={accountantsDataTable.items}
          totalCount={accountantsDataTable.total}
          pagePagination={pagePaginationAccountants}
          setPagePagination={setPagePaginationAccountants}
          paginationPerPage={30}
          hideBtnEdit={false}
          hideBtnErase
          hideDetails
          alertColor="#fff"
          alertSecondaryColor="#fff"
          alertTextColor="#559615"
          textFontWeight="bold"
          collapsed={true}
          customActionButtons={
            justOneTableOfAccountants
              ? [
                  {
                    className: 'btn btn-accept',
                    handleButton: (row) => handleAddAccountant(row, 1),
                    buttonText: 'Seleccionar',
                  },
                ]
              : [
                  {
                    className: 'btn btn-accept',
                    handleButton: (row) => handleAddAccountant(row, 1),
                    buttonText: 'Conteo 1',
                  },
                  {
                    className: 'btn btn-accept',
                    handleButton: (row) => handleAddAccountant(row, 2),
                    buttonText: 'Conteo 2',
                  },
                ]
          }
        />
      )}
      <div className="row">
        {showJustResume && accountantsOneSelected.length < 1 ? (
          <></>
        ) : (
          <div
            className={`${
              !justOneTableOfAccountants
                ? showJustResume
                  ? accountantsOneSelected.length > 0 &&
                    accountantsTwoSelected.length > 0
                    ? 'col-6'
                    : 'col-12'
                  : 'col-6'
                : 'col-12'
            }`}
          >
            <LayoutTable
              withCard={!showJustResume}
              title={
                !showJustResume ? (
                  !justOneTableOfAccountants ? (
                    'Contadores seleccionados para conteo 1'
                  ) : (
                    'Contadores seleccionados para conteos cíclicos'
                  )
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <p className="pl-4 pt-3 mb-2">
                        {!justOneTableOfAccountants ? (
                          <h6>Contadores seleccionados para conteo 1</h6>
                        ) : (
                          <h6>
                            Contadores seleccionados para conteos cíclicos
                          </h6>
                        )}
                      </p>
                      <hr className="mt-0 mb-2 pt-0 border-top border border-2" />
                    </div>
                  </div>
                )
              }
              hideAddNew
              hideFilterBar={showJustResume}
              hideActions={showJustResume}
              exportImportButton={false}
              data={accountantsOneSelected}
              totalCount={accountantsOneSelected.length}
              paginationPerPage={30}
              hideBtnEdit={true}
              paginationServer={false}
              hideBtnErase
              hideDetails
              selectableRows={!showJustResume ? true : false}
              selectableRowsClick={(selections) =>
                handleCheckAccountant(selections, 1)
              }
              clearSelectedRows={toggledClearRowsOne}
              paginationServerOptions={{
                persistSelectedOnSort: true,
                persistSelectedOnPageChange: false,
              }}
              enableCollapse={false}
              collapsed={false}
              noDataComponent="Aún no se han agregado contadores"
              customActionButtons={
                showJustResume
                  ? false
                  : [
                      {
                        className: 'btn btn-danger btn-trash',
                        handleButton: (row) => handleRemoveAccountant(row, 1),
                        buttonText: <i className="fa fa-trash"></i>,
                      },
                    ]
              }
              extraCompFilter={
                <>
                  {activeRemoveGroupOneBtn && (
                    <button
                      className="btn btn-accept btn-thin"
                      onClick={() => handleRemoveAccountant(null, 1)}
                    >
                      Remover grupo
                    </button>
                  )}
                </>
              }
            />
          </div>
        )}

        {showJustResume && accountantsTwoSelected.length < 1 ? (
          <></>
        ) : (
          !justOneTableOfAccountants && (
            <div
              className={`${
                showJustResume
                  ? accountantsOneSelected.length > 0 &&
                    accountantsTwoSelected.length > 0
                    ? 'col-6'
                    : 'col-12'
                  : 'col-6'
              }`}
            >
              <LayoutTable
                withCard={!showJustResume}
                title={
                  !showJustResume ? (
                    'Contadores seleccionados para conteo 2'
                  ) : (
                    <>
                      <p className="pl-4 pt-3 mb-2">
                        <h6>Contadores seleccionados para conteo 2</h6>
                      </p>
                      <hr className="mt-0 mb-2 pt-0 border-top border border-2" />
                    </>
                  )
                }
                hideAddNew
                hideFilterBar={showJustResume}
                hideActions={showJustResume}
                exportImportButton={false}
                data={accountantsTwoSelected}
                totalCount={accountantsTwoSelected.length}
                paginationPerPage={30}
                hideBtnEdit={true}
                paginationServer={false}
                hideBtnErase
                hideDetails
                selectableRows={!showJustResume ? true : false}
                selectableRowsClick={(selections) =>
                  handleCheckAccountant(selections, 2)
                }
                clearSelectedRows={toggledClearRowsTwo}
                paginationServerOptions={{
                  persistSelectedOnSort: true,
                  persistSelectedOnPageChange: false,
                }}
                enableCollapse={false}
                collapsed={false}
                noDataComponent="Aún no se han agregado contadores"
                customActionButtons={
                  showJustResume
                    ? false
                    : [
                        {
                          className: 'btn btn-danger btn-trash',
                          handleButton: (row) => handleRemoveAccountant(row, 2),
                          buttonText: <i className="fa fa-trash"></i>,
                        },
                      ]
                }
                extraCompFilter={
                  <>
                    {activeRemoveGroupTwoBtn && (
                      <button
                        className="btn btn-accept btn-thin"
                        onClick={() => handleRemoveAccountant(null, 2)}
                      >
                        Remover grupo
                      </button>
                    )}
                  </>
                }
              />
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default TableAssignmentAccountants
