import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import {
  CREATE_SCHEDULE,
  GET_SCHEDULE,
  UPDATE_SCHEDULE,
} from '../../../../graphql/Documents/Schedule/schedule'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import ProvidersQuotesStep1 from './ProvidersScheduleStep1'
import ProvidersQuotesStep2 from './ProvidersScheduleStep2'
import {
  GET_SAP_WAREHOUSES,
  GET_SAP_WAREHOUSES_SCHEDULE,
} from '../../../../graphql/Catalog/SAP/sapWarehouses'
import { GET_DOCKS } from '../../../../graphql/Catalog/Docks/docks'
import {
  GET_SAP_BUSSINESS_PARTNER,
  IMPORT_BUSINESS,
} from '../../../../graphql/Catalog/SAP/sapBusinessPartner'
import ContentHeader from '../../../Layout/ContentHeader'
import InputController from '../../../Global/InputController'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import Box from '../../../Global/Box'
import { GET_SCHEDULE_LINES_BY_SCHEDULE_ID } from '../../../../graphql/Documents/ScheduleOrdersLines/scheduleOrdersLines'
import { GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID_QUERY } from '../../../../graphql/Documents/ScheduleDocks/scheduleDocks'

const ProvidersQuotesNew = () => {
  const errorOrderNotSelected = 'Debe elegír por lo menos un producto'
  const { id: _id, form: _form } = useParams()

  const [idSelected, setIdSelected] = useState(null)

  const [createSchedule] = useMutation(CREATE_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_SCHEDULE,
      },
      {
        query: GET_SAP_BUSSINESS_PARTNER,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_SCHEDULE,
      },
      {
        query: GET_SAP_BUSSINESS_PARTNER,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [importBusinessPartner, { loading: loadingImport }] =
    useMutation(IMPORT_BUSINESS)

  const { data, loading, error } = useQuery(GET_SAP_WAREHOUSES_SCHEDULE)
  const {
    data: dataDocks,
    loading: loadingDocks,
    error: errorDocks,
  } = useQuery(GET_DOCKS)

  const history = useHistory()
  const [step, setStep] = useState(1)
  const [providerId, setProviderId] = useState(false)
  const [statusWarehouse, setStatusWarehouse] = useState()
  const [docks, setDocks] = useState()
  const [ordersReceived, setOrdersReceived] = useState([]) //Step2 when you select ordersReceived on de table
  const [infoProvider, setInfoProvider] = useState()
  const [flagEdit, setFlagEdit] = useState(false)
  const [processing, setProcessing] = useState(false)

  //ordersReceivedStep1 -> add PurchasesOrdersLines ID selected from checkbox
  const [ordersReceivedStep1Hook, setOrdersReceivedStep1Hook] = useState([]) //Step2 when you select ordersReceived
  let ordersReceivedStep1 = []

  //FOR MASSIVE IMPORT

  const [files, setFiles] = useState(null)

  const validationSchema = Yup.object().shape({
    providers: Yup.string().required('Este campo es obligatorio'),
    purchases_orders: Yup.string().required('Este campo es obligatorio'),
    purchases_orders_lines: Yup.string().required('Este campo es obligatorio'),
  })
  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  useEffect(() => {
    if (!loading) {
      if (error)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/schedule/providers')
        )

      const list = data.getSapWarehousesSchedule.map((item) => {
        return { label: item.warehouse_name, value: item.id }
      })
      setStatusWarehouse(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  useEffect(() => {
    if (!loadingDocks) {
      if (errorDocks)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/schedule/providers')
        )

      let docksEsp = {
        1: 'Puerta 1',
        2: 'Puerta 2',
        3: 'Puerta 3',
        4: 'Puerta 4',
      }

      const list = dataDocks.getDocks.map((item) => {
        return { value: item.id, label: docksEsp[item.id] }
      })
      setDocks(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDocks, loadingDocks, errorDocks])

  const handleSave = async (Data) => {
    setProcessing(true)
    const {
      dock_ids,
      document_date,
      document_time_start,
      document_time_end,
      comments,
      warehouse_id,
    } = Data

    if (ordersReceived.length === 0) {
      //User didn't select orders
      setProcessing(false)
      return toastSweetAlert({
        mode: 'error',
        message: errorOrderNotSelected,
      })
    }
    const { id } = infoProvider //GET PROVIDER ID

    /* INSERT AND UPDATE DATA */
    try {
      if (!_id) {
        await createSchedule({
          variables: {
            inputSchedule: {
              dock_ids,
              document_date: moment(document_date).format('YYYY-MM-DD'),
              document_time_start,
              document_time_end,
              comments,
              warehouse_id,
              document_status_id: 1,
              ordersReceived,
              provider_id: id,
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Has creado una cita',
          }),
          history.push('/schedule/providers')
        )
      } else {
        await updateSchedule({
          variables: {
            scheduleId: parseInt(_id),
            inputSchedule: {
              dock_ids,
              document_date: moment(document_date).format('YYYY-MM-DD'),
              document_time_start,
              document_time_end,
              comments,
              warehouse_id,
              document_status_id: 1,
              ordersReceived,
              provider_id: id,
            },
          },
          refetchQueries: [
            {
              query: GET_SCHEDULE_LINES_BY_SCHEDULE_ID,
              variables: {
                scheduleId: parseInt(_id),
                isRecieving: null,
                details: true,
              },
            },
            {
              query: GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID_QUERY,
              variables: {
                scheduleId: parseInt(_id),
              },
            },
            {
              query: GET_SCHEDULE,
            },
          ],
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Has actualizado la cita',
          }),
          history.push('/schedule/providers')
        )
      }
    } catch (e) {
      setProcessing(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  const handleAddOrder = (ids, providerId) => {
    setOrdersReceivedStep1Hook(ids)
    setStep(1)
    setProviderId(providerId)
  }

  const handleResetStep = () => {
    setOrdersReceived([])
    setOrdersReceivedStep1Hook([])
    ordersReceivedStep1 = []
    setStep(1)
    setIdSelected(0)
  }

  const handleFile = (e, from) => {
    if (!e.target.files[0]) {
      return
    }
    if (e.target.files[0].type !== 'text/csv')
      return toastSweetAlert({
        mode: 'error',
        message: 'Solo se admiten archivos CSV',
      })
    setFiles({ ...files, [from]: e.target.files[0] })
  }

  const onSubmit = async () => {
    const listFiles = Object.values(files).map((file) => {
      return file
    })
    try {
      toastSweetAlert({
        mode: 'loading',
        message: 'Cargando información, espere...',
      })
      await importBusinessPartner({
        variables: {
          files: listFiles,
        },
      })
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'La importación se hizo correctamente',
        },
        history.push('/schedule/providers')
      )
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  return (
    <>
      {_form ? (
        <>
          <ContentHeader
            title="Importación masiva"
            breadcrumb="Importación"
            windowTitle="Importación"
          />
          <FormProvider {...methods}>
            <form
              className={`p-5 ${loadingImport ? 'processing-file' : ''}`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                title="Selecciona los archivos a importar"
                btnRedPath="/schedule/providers"
                btnRedTxt="Cancelar"
                btnSubmitText="Iniciar importación"
                btnSubmit={true}
                btnState={loadingImport}
                errors={errors}
                content={
                  <>
                    <div className="row">
                      <div className="mb-3 col-12">
                        <hr />
                      </div>
                      <div className="mb-3 col-md-12 col-lg-4">
                        <InputController
                          label="Proveedores"
                          inputType="file"
                          formatAccept=".csv"
                          validateFormat="csv"
                          validateFormatMsg="Solo se admite CSV"
                          name="providers"
                          control={control}
                          changeAction={(e) => handleFile(e, 'providers')}
                        />
                      </div>
                      <div className="mb-3 col-md-12 col-lg-4">
                        <InputController
                          label="Órdenes de compra"
                          inputType="file"
                          formatAccept=".csv"
                          validateFormat="csv"
                          validateFormatMsg="Solo se admite CSV"
                          name="purchases_orders"
                          control={control}
                          changeAction={(e) =>
                            handleFile(e, 'purcharses_orders')
                          }
                        />
                      </div>
                      <div className="mb-3 col-md-12 col-lg-4">
                        <InputController
                          label="Líneas de órdenes de compra"
                          inputType="file"
                          formatAccept=".csv"
                          validateFormat="csv"
                          validateFormatMsg="Solo se admite CSV"
                          name="purchases_orders_lines"
                          control={control}
                          changeAction={(e) =>
                            handleFile(e, 'purchases_orders_lines')
                          }
                        />
                      </div>
                    </div>
                  </>
                }
              />
            </form>
          </FormProvider>
        </>
      ) : (
        statusWarehouse &&
        docks && (
          <>
            {step === 1 ? (
              <>
                <ProvidersQuotesStep1
                  setStep={setStep}
                  providerId={providerId}
                  ordersReceivedStep1={ordersReceivedStep1}
                  ordersReceivedStep1Hook={ordersReceivedStep1Hook}
                  setOrdersReceivedStep1Hook={setOrdersReceivedStep1Hook}
                  setInfoProvider={setInfoProvider}
                  flagEdit={flagEdit}
                  idSelected={idSelected}
                  setIdSelected={setIdSelected}
                />
              </>
            ) : (
              <>
                <ProvidersQuotesStep2
                  handleResetStep={handleResetStep}
                  handleAddOrder={handleAddOrder}
                  handleSave={handleSave}
                  statusWarehouse={statusWarehouse}
                  docks={docks}
                  ordersReceived={ordersReceived}
                  setOrdersReceived={setOrdersReceived}
                  ordersReceivedStep1Hook={ordersReceivedStep1Hook}
                  ordersReceivedStep1={ordersReceivedStep1}
                  infoProvider={infoProvider}
                  setFlagEdit={setFlagEdit}
                  flagEdit={flagEdit}
                  setInfoProvider={setInfoProvider}
                  processing={processing}
                />
              </>
            )}
          </>
        )
      )}
    </>
  )
}

export default ProvidersQuotesNew
