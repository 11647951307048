import { gql } from '@apollo/client'

export const GET_INVENTORY_ACTIVITY_ASSIGMENT_BY_ID = gql`
  query getInventoryActivityAssigmentById($id: Int!) {
    getInventoryActivityAssigmentById(id: $id) {
      id
      warehouse_id
      start_date
      status_sync
      type_schedule
      ubications_or_skus
      InventoryActivitiesWarehouseSchedule {
        id
        warehouse_name
      }
    }
  }
`

export const GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_SCHEDULE = gql`
  query getAllInventoryActivitiesAssignmentBySchedule(
    $scheduleId: Int!
    $limit: Int
    $offset: Int
    $searchQuery: String
  ) {
    getAllInventoryActivitiesAssignmentBySchedule(
      scheduleId: $scheduleId
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      count
      rows {
        id
        completedLabels
        pendingLabels
        InventoryActivitiesLabelScheduleInfo {
          id
          warehouse_id
          start_date
          status_sync
          type_schedule
          ubications_or_skus
        }
        InventoryActivitieLabelAccountant {
          full_name
          id
          user_id
        }
      }
      scheduleInfo {
        id
        reason
        start_date
        status
        status_sync
        type_schedule
        ubications_or_skus
        warehouse_id
      }
    }
  }
`

export const GET_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_USER_ID = gql`
  mutation getInventoryAcitivitiesAssigmentsByUserId(
    $userId: Int!
    $limit: Int
    $offset: Int
    $searchQuery: String
    $completedTag: Int
    $scheduleId: Int!
  ) {
    getInventoryAcitivitiesAssigmentsByUserId(
      userId: $userId
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      completedTag: $completedTag
      scheduleId: $scheduleId
    ) {
      rows {
        id
        count_number
        completed
        InventoryActivitiesLabelSapItem {
          item_code
          item_name
        }
        InventoryActivitiesLabelRackPosition {
          id
          name
          code
        }
        InventoryActivitiesLabelItemsFound {
          id
        }
      }
      count
    }
  }
`

export const DELETE_INVENTORY_ACTIVITIES_ASSIGNMENTS = gql`
  mutation DeleteInventoryActivitiesAssignmentLabel($ids: [Int!]!) {
    deleteInventoryActivitiesAssignmentLabel(ids: $ids)
  }
`

export const VALIDATE_ELEMENTS_ASSIGNMENTS = gql`
  mutation ValidateElementsAssignment(
    $elements: [String]!
    $numberCounts: [Int]!
    $scheduleId: Int!
    $fromUbications: Boolean!
  ) {
    ValidateElementsAssignment(
      elements: $elements
      numberCounts: $numberCounts
      scheduleId: $scheduleId
      fromUbications: $fromUbications
    )
  }
`

export const CREATE_INVENTORY_ACTIVITIES_ASSIGNMENT = gql`
  mutation CreateInventoryActivitiesAssignment(
    $input: InventoryActivitiesCreateAssignmentInput!
  ) {
    CreateInventoryActivitiesAssignment(input: $input)
  }
`

// export const GET_TFI_COUNTER_ASSIGNMENT = gql`
//   query GetTfiCounterAssignments(
//     $idAccountant: Int
//     $limit: Int
//     $searchQuery: String
//     $offset: Int
//     $status: Int
//   ) {
//     getTfiCounterAssignments(
//       idAccountant: $idAccountant
//       limit: $limit
//       searchQuery: $searchQuery
//       offset: $offset
//       status: $status
//     ) {
//       scheduleId
//       count
//       rows {
//         id
//         assignment_id
//         completed
//         count_number
//         LabelAssignments {
//           tif_schedule_id
//           completed
//           UserInfoAccountant {
//             full_name
//             user_id
//           }
//         }
//         racksTFILabels {
//           id
//           name
//           code
//         }
//       }
//     }
//   }
// `
// export const FIND_RACK_START_SCAN = gql`
//   mutation FindRackStartScan($rackName: String!, $scheduleId: Int!) {
//     findRackStartScan(rack_name: $rackName, scheduleId: $scheduleId) {
//       id
//       assignment_id
//       rack_id
//       completed
//       count_number
//       racksTFILabels {
//         id
//         name
//         code
//       }
//     }
//   }
// `

// // ESTA LA PUEDO USAR
// export const GET_ALL_ASSIGNMENTS = gql`
//   query GetAllTfiAssignment($searchQuery: String, $limit: Int, $offset: Int) {
//     getAllTfiAssignment(
//       searchQuery: $searchQuery
//       limit: $limit
//       offset: $offset
//     ) {
//       rows {
//         id
//         id_label
//         id_accountant
//         id_user
//         count_number
//         complete
//       }
//       count
//     }
//   }
// `

// export const GET_ALL_ACCOUNTANTS = gql`
//   query GetAllTfiAccountant(
//     $searchQuery: String
//     $limit: Int
//     $offset: Int
//     $warehouseCode: String
//     $scheduleId: Int
//   ) {
//     getAllTfiAccountant(
//       searchQuery: $searchQuery
//       limit: $limit
//       offset: $offset
//       warehouseCode: $warehouseCode
//       scheduleId: $scheduleId
//     ) {
//       rows {
//         id
//         user_id
//         full_name
//         warehouse_code
//       }
//     }
//   }
// `

// export const CREATE_EXTRA_ASSIGNMENT = gql`
//   mutation CreateExtraTfiAssignment($input: TfiCreateExtraAssignmentInput!) {
//     createExtraTfiAssignment(input: $input)
//   }
// `

// export const DELETE_ASSIGNMENT_LABEL = gql`
//   mutation deleteTfiAssignmentLabel($id: Int, $ids: [Int], $typeSchedule: Int) {
//     deleteTfiAssignmentLabel(id: $id, ids: $ids, typeSchedule: $typeSchedule)
//   }
// `
