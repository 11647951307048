import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import DatesOptions from '../../Global/DatesOptions'
import LayoutTable from '../../Global/LayoutTable'
import { groupBtnEditDisableOrEnable } from '../../Helpers/functionTransfers'

import {
  GET_PENDING_ORDERS_STORE,
  EXPORT_PENDING_ORDERS_STORE,
  VALID_PROCESS_EXISTS_PENDING_ORDER_PACKING_LIST,
  CREATE_PENDING_ORDERS_PACKING_LIST,
} from '../../../graphql/Catalog/Orders/pendingOrdersStore'

export const Pending = () => {
  const history = useHistory()
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    platformId: 5,
    statusId: 2,
    date: null,
  })
  const [total, setTotal] = useState(0)
  const [dataToTable, setDataToTable] = useState([])

  const { loading, error, data } = useQuery(GET_PENDING_ORDERS_STORE, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      platformId: pagePagination.platformId,
      statusId: pagePagination.statusId,
      date: pagePagination.date,
    },
    fetchPolicy: 'no-cache',
  })

  const [validExistsInPackingList] = useMutation(
    VALID_PROCESS_EXISTS_PENDING_ORDER_PACKING_LIST
  )
  const [createPendingOrdersPackingList] = useMutation(
    CREATE_PENDING_ORDERS_PACKING_LIST
  )

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = data.getPendingOrdersStore.rows.map((order) => {
        return {
          id: order.id,
          'Fecha y hora':
            moment(order?.order_date).format('L') +
              ', ' +
              moment(order?.order_date).format('LT') || '--',
          Plataforma: order.platform?.name || '#5',
          Tipo: order.type?.name,
          'Plataforma de cobro': order.payment?.platform,
          Cliente: `${order.shipping.first_name} ${order.shipping.last_name}`,
          'No. de Venta': order.order_id,
          'Tipo documento': order?.object_type_name
            ? order?.object_type_name
            : 'Orden de plataforma',
          Tienda: order?.store?.name || order?.warehouse?.name || '--',
        }
      })
      setTotal(data.getPendingOrdersStore.count)
      setDataToTable(list)
    }
  }, [loading, error, data])

  const filterOnChange = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  const handleProcessOrder = async (orderId) => {
    try {
      setLoadingProcess(true)
      await groupBtnEditDisableOrEnable({ type: 'disabled' })
      toastSweetAlert({
        mode: 'loading',
        message:
          'Procesando, espere por favor. Esto puede llevar unos minutos.',
      })

      await validExistsInPackingList({ variables: { orderId } })

      await createPendingOrdersPackingList({
        variables: {
          orderId,
        },
        refetchQueries: [
          {
            query: GET_PENDING_ORDERS_STORE,
            variables: {
              searchQuery: pagePagination.searchQuery,
              limit: pagePagination.limit,
              offset: pagePagination.offset,
              platformId: pagePagination.platformId,
              statusId: pagePagination.statusId,
              date: pagePagination.date,
            },
          },
        ],
      })
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Orden procesada correctamente',
        },
        setLoadingProcess(false),
        history.push('/storeOrders/pending')
      )
    } catch (error) {
      setLoadingProcess(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    } finally {
      await groupBtnEditDisableOrEnable({ type: 'enabled' })
    }
  }

  return (
    <div className={`${loadingProcess ? 'processing-file' : ''}`}>
      <LayoutTable
        data={dataToTable}
        title="Ordenes pendientes"
        requery={GET_PENDING_ORDERS_STORE}
        actionBtnEdit={(e) => handleProcessOrder(e)}
        editText={'Procesar Orden'}
        exportQuery={EXPORT_PENDING_ORDERS_STORE}
        platformSelector={true}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        dataToTable={dataToTable}
        dataToTableTitle="OrdenesPendientes"
        hideAddNew={true}
        FilterBarPlaceholder="Buscar por Cliente, No. de Venta o Tienda"
        /** First filter */
        filter
        filterOnChange={filterOnChange}
        filterOptions={[<DatesOptions daysBefore={true} />]}
        filterLabelText="Por fecha"
      />
    </div>
  )
}

export default Pending
