import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import numeral from 'numeral'

import Box from '../../Global/Box'
import OrderCol from '../../Global/OrderCol'

import { document_statuses_esp } from '../../Helpers/StatusesLanguages'
import Loader from '../../Global/Spinner'
import VehicleReceipt from '../VehiclesReceipt/VehiclesReceipt'
import VehicleRegisterReceiptModal from '../VehiclesReceipt/VehicleReceiptRegisterModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import { START_END_DOWNLOAD_HOUR_VALIDATION } from '../../../graphql/Documents/Schedule/ScheduleVehicle'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

export const Step1 = ({
  schedule,
  orders,
  lines,
  evidence,
  onCancel,
  nextStep,
  onFinish,
  linesQuantity,
  onDeleteLine,
  handlePrintLine,
  loadingData,
}) => {
  const { show, calendar } = useParams()
  const history = useHistory()
  const { show: _show, id: _id } = useParams()
  const [productLines, setProductLines] = useState([])
  const [loading, setLoading] = useState(false)
  const refModal = useRef()
  const statusSpanish = document_statuses_esp

  // GETTING VEHICLE BY SCHEDULE IDS
  const [vehicleIds, setvehicleIds] = useState([])

  //   const handleChangeScanStep = (itemCode) => {
  //     setGlobalData(itemCode)
  //     nextStep()
  //   }

  const [getDownloadHoursValidation] = useLazyQuery(
    START_END_DOWNLOAD_HOUR_VALIDATION
  )

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const scan = urlParams.has('scan')
    if (scan) {
      nextStep()
    }
  }, [])

  useEffect(() => {
    let list = []
    for (const i of lines) {
      let listDetails = []
      let totalQuantityReceipt = 0
      let purchaseOrderLineId = 0
      for (const j of linesQuantity) {
        if (i.id === j.line_id) {
          listDetails.push({
            ...j,
            anada: i.anada,
            expiration_date: i.expiration_date,
          })
          purchaseOrderLineId =
            i.SapPurchasesOrdersLinesCommodityReceipt
              .sap_purchases_orders_lines_id
          totalQuantityReceipt += j.quantity
        }
      }
      const vals = window.location.search
      const urlParams = new URLSearchParams(vals)
      var calendar = urlParams.get('calendar')

      if (show && totalQuantityReceipt && !calendar) {
        list.push({
          ...i,
          quantity: i.line_status === 'O' ? i.open_quantity : i.quantity,
          details: listDetails,
          totalQuantityReceipt,
          purchaseOrderLineId,
          anada: i.anada,
          expiration_date: i.expiration_date,
        })
      }
      if (show && calendar === '1') {
        list.push({
          ...i,
          quantity: i.line_status === 'O' ? i.open_quantity : i.quantity,
          details: listDetails,
          totalQuantityReceipt,
          purchaseOrderLineId,
          anada: i.anada,
          expiration_date: i.expiration_date,
        })
      }

      if (!show) {
        list.push({
          ...i,
          quantity: i.line_status === 'O' ? i.open_quantity : i.quantity,
          details: listDetails,
          totalQuantityReceipt,
          purchaseOrderLineId,
          anada: i.anada,
          expiration_date: i.expiration_date,
        })
      }
    }
    setProductLines(list)
  }, [lines, linesQuantity])

  const handleFinish = async () => {
    // const { data } = await getDownloadHoursValidation({
    //   variables: {
    //     getStartEndDownloadHourAssignationId: +_id,
    //   },
    // })
    // if (!data?.getStartEndDownloadHourAssignation) {
    //   return toastSweetAlert({
    //     mode: 'error',
    //     message:
    //       'La hora de inicio de descarga  y fin de descarga debe ser asignada',
    //   })
    // }

    return setLoading(true), onFinish()
  }
  const headerBtnHandler = () => {
    refModal.current.click()
  }

  const handleStart = async () => {
    const { data } = await getDownloadHoursValidation({
      variables: {
        getStartEndDownloadHourAssignationId: +_id,
      },
    })
    if (!data?.getStartEndDownloadHourAssignation) {
      return toastSweetAlert({
        mode: 'error',
        message:
          'La hora de inicio de descarga  y fin de descarga debe ser asignada',
      })
    }
    nextStep()
  }

  return (
    <div className="row">
      <div className="mb-3 col-12">
        <Box
          title={`Detalles de cita`}
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="boxQuotesProvider">
                <div className="row">
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Fecha de cita:{' '}
                      <span className="resultData">
                        {`${schedule?.document_date} ${schedule?.document_time_start} - ${schedule?.document_time_end}`}
                      </span>{' '}
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Estatus:{' '}
                      <span className="resultData">
                        {`${
                          statusSpanish[schedule?.ScheduleDocumentStatus?.id]
                        }`}
                      </span>{' '}
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Almacén:{' '}
                      <span className="resultData">
                        {schedule?.warehouse?.warehouse_name}
                      </span>{' '}
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Código de almacén:{' '}
                      <span className="resultData">
                        {schedule?.warehouse?.warehouse_code}
                      </span>{' '}
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Código proveedor:{' '}
                      <span className="resultData">{schedule?.card_name}</span>{' '}
                    </h4>
                  </div>
                  <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                    <h4>
                      Observaciones:{' '}
                      <span className="resultData">
                        {schedule?.comments
                          ? schedule?.comments
                          : 'Sin comentarios'}
                      </span>{' '}
                    </h4>
                  </div>
                </div>
                {(!show || schedule?.status?.id == 1) && (
                  <div className="col-12 text-right">
                    <span
                      onClick={onCancel}
                      className="btn StandarModalCancelButtonStandar"
                    >
                      Cancelar cita
                    </span>
                  </div>
                )}
              </div>
            )
          }
        />
        <Box
          title="Órdenes de compra"
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="boxQuotesProvider">
                  {orders &&
                    orders?.map((item) => (
                      <>
                        <div className="card" key={item?.id}>
                          <div className="card-body">
                            <div className="row">
                              <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                                <h4>
                                  No. Documento:{' '}
                                  <span className="resultData">
                                    {item?.document_number}
                                  </span>{' '}
                                </h4>
                              </div>
                              <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                                <h4>
                                  Entradas de documento:{' '}
                                  <span className="resultData">
                                    {item?.document_entry}
                                  </span>{' '}
                                </h4>
                              </div>
                              <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                                <h4>
                                  Serie:{' '}
                                  <span className="resultData">
                                    {item?.series}
                                  </span>{' '}
                                </h4>
                              </div>
                              <div className="mb-3 col-sm-12 col-md-12 col-lg-12">
                                <h4>
                                  Comentarios:{' '}
                                  <span className="resultData">
                                    {item?.comments || 'Sin comentarios'}
                                  </span>{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </>
            )
          }
        />
        {/* <div className="vehicle-receipt">
          <Box
            title="Recibo de vehículos"
            // headerBtn={true}
            // headerBtnText="Agregar vehículo"
            // headerBtnHandler={headerBtnHandler}
            content={
              <>
                <VehicleReceipt
                  vehicleIds={vehicleIds}
                  setvehicleIds={setvehicleIds}
                  id={_id}
                />
              </>
            }
          />
        </div> */}

        <Box
          title="Desglose de productos"
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="providersTableBox">
                  <table
                    id="providersTable"
                    className="table table-striped table-bordered nowrap"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sku</th>
                        <th>Producto</th>
                        <th className="text-center">Cantidad esperada</th>
                        <th className="text-center">Cantidad recibida</th>
                        <th>Etiquetas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productLines &&
                        productLines?.map((item, index) => (
                          <>
                            <tr key={`${item?.id}`}>
                              <td>
                                {item?.details.length > 0 && (
                                  <i
                                    type="button"
                                    aria-expanded="false"
                                    className="fa fa-eye"
                                    data-toggle="collapse"
                                    data-target={`#collapse${item.id}`}
                                    aria-controls={`#collapse${item.id}`}
                                  />
                                )}
                              </td>
                              <td>{`${item?.item_code}` || 'No disponible'}</td>
                              <td>
                                {`${item?.PurchasesLinesItemInfo?.item_name}` ||
                                  'No disponible'}
                              </td>
                              <td>
                                {`${numeral(item?.quantity).format('0,0')}` ||
                                  'No disponible'}
                              </td>
                              <td>
                                {`${numeral(item?.totalQuantityReceipt).format(
                                  '0,0'
                                )}` || 'No disponible'}
                              </td>
                              <td>
                                {item?.details.length > 0 && (
                                  <i
                                    type="button"
                                    className="fa fa-print"
                                    onClick={() =>
                                      handlePrintLine(1, item, item?.details)
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                            {item?.details &&
                              item?.details.map((detail, index) => (
                                <tr
                                  className="collapse"
                                  id={`collapse${item?.id}`}
                                  key={detail?.id}
                                >
                                  <td>
                                    {!_show && (
                                      <i
                                        type="button"
                                        className="fa fa-trash text-danger"
                                        onClick={() =>
                                          onDeleteLine(
                                            detail?.id,
                                            item?.purchaseOrderLineId
                                          )
                                        }
                                      />
                                    )}
                                  </td>
                                  <td></td>
                                  <td>
                                    Escaneo {index + 1}, Pallet {detail?.id}
                                  </td>
                                  <td></td>
                                  <td>
                                    {numeral(detail?.quantity).format('0,0')}
                                  </td>
                                  <td>
                                    <i
                                      type="button"
                                      className="fa fa-print"
                                      onClick={() =>
                                        handlePrintLine(
                                          2,
                                          item,
                                          detail,
                                          index + 1
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-12 actions-buttons-scan">
                  <button
                    type="btn"
                    className="btn StandarModalCancelButtonLogOut"
                    onClick={() => history.push('/commodity/receipt')}
                  >
                    Regresar
                  </button>
                  {(!show || schedule?.status?.id == 1) && (
                    <>
                      <button
                        type="btn"
                        className="btn StandarModalConfirmButtonLogOut"
                        onClick={() => nextStep()}
                      >
                        Comenzar escaneo
                      </button>
                      <button
                        type="btn"
                        className="btn StandarModalConfirmButtonLogOut"
                        onClick={() => (!loading ? handleFinish() : null)}
                      >
                        {!loading ? (
                          ' Finalizar recibo '
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </>
                  )}
                </div>
              </>
            )
          }
        />
        <Box
          title="Evidencias"
          content={
            loadingData ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="row">
                {evidence &&
                  evidence?.map((item, index) => (
                    <div key={index} className="col-4 gallery-item">
                      <img src={item} alt="img" className="" />
                    </div>
                  ))}
              </div>
            )
          }
        />
      </div>
      <VehicleRegisterReceiptModal refModal={refModal} />
    </div>
  )
}
