import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_SCHEDULE,
  GET_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_USER_ID,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesAssignments'
import { DELETE_INVENTORY_ACTIVITIES_ASSIGNMENTS } from '../../../../graphql/InventoryActivities/InventoryActivitiesAssignments'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import LayoutTable from '../../../Global/LayoutTable'
import BarProgress from './BarProgress'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from 'react-router-dom'

const TableAssignments = ({
  id,
  setInfoSchedule,
  setLoadingBtn,
  setSteps,
  setTypeCycleCountSelected,
}) => {
  const MySwal = withReactContent(Swal)
  const { id: _id } = useParams()

  /*USE STATE*/
  const [dataAccountants, setDataAccountants] = useState({
    items: [],
    total: 0,
    loading: true,
  })
  const [paginationRacks, setPaginationRacks] = useState({
    userId: 0,
    limit: 30,
    offset: 0,
    searchQuery: null,
    name: null,
    assignmentId: null,
    completedTag: null, //0 or null: all, 1: only completed, 2: no completed
    scheduleId: parseInt(_id),
  })
  const [userName, setUserName] = useState(null)
  const [deleteAssignationGroup, setDeleteAssignationGroup] = useState(false)
  const [deleteAssignationIds, setDeleteAssignationIds] = useState([])
  const [loadingAssignments, setLoadingAssignments] = useState(false)
  const [toggleClearAssignationRows, setToggleClearAssignationRows] =
    useState(true)
  const [dataRacks, setDataRacks] = useState({
    items: [],
    total: 0,
    loading: true,
  })
  const [pagePaginationAssigned, setPagePaginationAssigned] = useState({
    searchQuery: '',
    limit: 30,
    offset: 0,
    scheduleId: parseInt(_id),
  })

  /*USE QUERY MUTATION*/
  const accountantsAssigned = useQuery(
    GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_SCHEDULE,
    {
      variables: pagePaginationAssigned,
      refetchPolicy: 'no-cache',
      fetchPolicy: 'no-cache',
    }
  )

  const [getAssignments] = useMutation(
    GET_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_USER_ID
  )

  const [deleteAssignment] = useMutation(
    DELETE_INVENTORY_ACTIVITIES_ASSIGNMENTS
  )

  /*USE EFFECTS*/
  useEffect(() => {
    try {
      const query = async () => {
        setLoadingAssignments(true)
        const { data } = await getAssignments({
          variables: {
            ...paginationRacks,
          },
        })

        const typeSchedule =
          accountantsAssigned?.data
            ?.getAllInventoryActivitiesAssignmentBySchedule?.rows[0]
            .InventoryActivitiesLabelScheduleInfo?.type_schedule
        const typeCycleCount =
          accountantsAssigned?.data
            ?.getAllInventoryActivitiesAssignmentBySchedule?.rows[0]
            ?.InventoryActivitiesLabelScheduleInfo?.ubications_or_skus

        if (data?.getInventoryAcitivitiesAssigmentsByUserId?.rows) {
          const list =
            data?.getInventoryAcitivitiesAssigmentsByUserId?.rows.map(
              (item) => {
                return {
                  id: item?.id,
                  [typeSchedule === 2 || typeCycleCount === 1
                    ? 'Etiqueta'
                    : 'SKU']:
                    typeSchedule === 2 || typeCycleCount === 1
                      ? item?.InventoryActivitiesLabelRackPosition?.name
                      : item?.InventoryActivitiesLabelSapItem?.item_code,
                  [typeSchedule === 2 || typeCycleCount === 1
                    ? 'Código'
                    : 'Nombre']:
                    typeSchedule === 2 || typeCycleCount === 1
                      ? item?.InventoryActivitiesLabelRackPosition?.code
                      : item?.InventoryActivitiesLabelSapItem?.item_name,
                  [typeSchedule === 2 || typeCycleCount === 1
                    ? 'Num de conteo'
                    : 'Num de conteo_omitField']: item?.count_number,
                  Estatus: getStatus(item?.completed),
                  completed: item?.completed,
                  numCounts: item?.InventoryActivitiesLabelItemsFound
                    ? false
                    : true,
                  idLabel: item?.id,
                  disable_omitField: item?.InventoryActivitiesLabelItemsFound
                    ? true
                    : false,
                  status_omitField: item?.completed,
                }
              }
            )

          setDataRacks({
            items: list,
            total: data?.data?.getAssigmentsByUserId?.count,
          })

          setLoadingAssignments(false)

          setUserName('Asignaciones de ' + paginationRacks.name)
        }
      }

      if (paginationRacks.userId !== 0) {
        query()
      }
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [paginationRacks])

  useEffect(() => {
    try {
      if (accountantsAssigned?.loading) return

      if (accountantsAssigned.error) {
        return toastSweetAlert({
          mode: 'error',
          message: accountantsAssigned.error.message,
        })
      }

      const data =
        accountantsAssigned?.data?.getAllInventoryActivitiesAssignmentBySchedule?.rows?.map(
          (row) => {
            const total = row?.pendingLabels + row?.completedLabels
            return {
              id: row?.InventoryActivitieLabelAccountant?.user_id,
              Nombre: row?.InventoryActivitieLabelAccountant?.full_name,
              idAssignment_omitField:
                row?.InventoryActivitieLabelAccountant?.id,
              Avance: (
                <div
                  style={{
                    width: 150,
                  }}
                >
                  <BarProgress
                    title=""
                    total={total}
                    num={row?.completedLabels}
                  />
                </div>
              ),
            }
          }
        )

      // MANDAR SCHEDULE INFO APARTE
      const info =
        accountantsAssigned?.data?.getAllInventoryActivitiesAssignmentBySchedule
          ?.scheduleInfo

      setInfoSchedule(info)

      if (info?.type_schedule === 1)
        setTypeCycleCountSelected(info?.ubications_or_skus)

      setDataAccountants({
        items: data,
        total:
          accountantsAssigned?.data
            ?.getAllInventoryActivitiesAssignmentBySchedule?.count,
        loading: false,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    accountantsAssigned?.loading,
    accountantsAssigned?.data,
    accountantsAssigned?.error,
  ])

  /*FUNCTIONS */
  //Accion del filtro Completado / No completado
  const handleFilter = async (obj) => {
    setPaginationRacks({
      ...paginationRacks,
      completedTag: parseInt(obj.target.value),
    })
  }

  //click Seleccionar contador
  const handleShowAssigments = async (row) => {
    setPaginationRacks({
      ...paginationRacks,
      userId: row.id,
      name: row.Nombre,
      assignmentId: parseInt(row.idAssignment_omitField),
    })
  }

  const getStatus = (_completed) => {
    let status = (
      <span className="badge badge-danger rounded-0 p-1 text-white percentage">
        PENDIENTE
      </span>
    )

    if (_completed) {
      status = (
        <span className="badge badge-success rounded-0 p-1 percentage">
          COMPLETADO
        </span>
      )
    }

    return status
  }

  //Delete asignacion
  const handleDeleteAssignment = (row) => {
    MySwal.fire({
      showCloseButton: true,
      icon: 'warning',
      title: '¿Eliminar registro?',
      html: (
        <>
          <div className="message-container">
            Esta acción no se podra revertir
          </div>
        </>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingBtn(true)
        try {
          await deleteAssignment({
            variables: {
              ids:
                deleteAssignationIds.length > 0
                  ? deleteAssignationIds
                  : [parseInt(row.idLabel)],
              typeSchedule: parseInt(
                accountantsAssigned?.data?.getAllTfiAssignmentBySchedule
                  ?.scheduleInfo?.type_schedule
              ),
            },
          })

          setDeleteAssignationIds([])
          setDeleteAssignationGroup(false)

          setPaginationRacks({
            ...paginationRacks,
            searchQuery: null,
          })

          setToggleClearAssignationRows(!toggleClearAssignationRows)
          await accountantsAssigned.refetch()
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'ok',
            message: 'Asignaciones eliminadas',
          })
        } catch (error) {
          setLoadingBtn(false)
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
    })
  }

  const handleCheckLabel = (obj) => {
    const ids = obj.selectedRows.map((item) => +item.id)
    setDeleteAssignationIds(obj.selectedRows)
    if (obj.selectedRows.length > 1) setDeleteAssignationGroup(true)
    if (obj.selectedRows.length < 1) setDeleteAssignationGroup(false)
    setDeleteAssignationIds(ids)
  }

  const disabledCheckBox = (row) => {
    return row.disable_omitField || row.status_omitField
  }

  return (
    <LayoutTable
      title="Contadores y sus asignaciones"
      data={dataAccountants.items}
      totalCount={dataAccountants.total}
      pagePagination={pagePaginationAssigned}
      setPagePagination={setPagePaginationAssigned}
      loadingData={dataAccountants.loading}
      paginationPerPage={30}
      hideBtnEdit
      hideBtnErase
      hideDetails
      noDataComponent="Aún no se han hecho asignaciones"
      headerComponents={
        <>
          <button
            type="button"
            className="btn btn-accept"
            onClick={() => {
              setSteps(2)
            }}
          >
            <i className="fa fa-plus"></i> Nueva Asignación
          </button>
        </>
      }
      componentAction={(row) => {
        return (
          <>
            <button
              className="btn btn-accept"
              onClick={() => {
                handleShowAssigments(row)
              }}
            >
              Seleccionar
            </button>
          </>
        )
      }}
      append={
        paginationRacks.assignmentId && (
          <LayoutTable
            title={
              <div className="row mt-4 mb-4">
                <div className="col-12">
                  <h3 className="header-title">{userName}</h3>
                </div>
              </div>
            }
            noDataComponent="Este contador no cuenta con asignaciones"
            withCard={false}
            hideAddNew
            exportImportButton={false}
            data={dataRacks.items}
            totalCount={dataRacks.total}
            pagePagination={paginationRacks}
            loadingData={loadingAssignments}
            setPagePagination={setPaginationRacks}
            paginationPerPage={30}
            hideBtnEdit={false}
            hideBtnErase
            hideDetails
            selectableRows={true}
            selectableRowsClick={handleCheckLabel}
            rowDisabledCriteria={disabledCheckBox}
            componentAction={(row) => {
              return (
                <>
                  {!row.completed && row.numCounts > 0 && (
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        handleDeleteAssignment(row)
                      }}
                    >
                      Eliminar
                    </button>
                  )}
                </>
              )
            }}
            clearSelectedRows={toggleClearAssignationRows}
            paginationServerOptions={{
              persistSelectedOnSort: true,
              persistSelectedOnPageChange: false,
            }}
            filter
            filterDefaultOptionText="Seleccione una opción"
            defaultValueSelect
            filterOptions={[
              <option value="0" key="0">
                Todos
              </option>,
              <option value="1" key="1">
                Completados
              </option>,
              <option value="2" key="2">
                No Completados
              </option>,
            ]}
            filterOnChange={handleFilter}
            hideColumns={['completed', 'numCounts', 'idLabel']}
            extraCompFilter={
              <>
                {deleteAssignationGroup && (
                  <button
                    className="btn btn-accept btn-thin"
                    onClick={handleDeleteAssignment}
                  >
                    Seleccionar grupo
                  </button>
                )}
              </>
            }
          />
        )
      }
    />
  )
}

export default TableAssignments
