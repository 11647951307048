const ReceptionActionsButtons = ({
  history,
  schedule,
  show,
  nextStep,
  handleFinish,
  loading,
  cssClass = '',
}) => {
  return (
    <>
      <div
        className={`col-12 actions-buttons-scan hide-actions-buttons-scan ${cssClass}`}
      >
        <button
          type="btn"
          className="btn StandarModalCancelButtonLogOut "
          onClick={() => history.push('/productsReception')}
        >
          Regresar
        </button>
        {(!show || schedule?.status?.id == 1) && (
          <>
            <button
              type="btn"
              className="btn StandarModalConfirmButtonLogOut"
              onClick={() => nextStep()}
            >
              Comenzar escaneo
            </button>
            {/* <button
              type="btn"
              className="btn StandarModalConfirmButtonLogOut"
              onClick={() => (!loading ? handleFinish() : null)}
            >
              {!loading ? (
                ' Finalizar recibo '
              ) : (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button> */}
          </>
        )}
      </div>
    </>
  )
}

export default ReceptionActionsButtons
