import * as FileSaver from 'file-saver'
import { useState } from 'react'
import XLSX from 'sheetjs-style'
// import * as XLSX from 'xlsx'
const ExportExcel = ({
  id,
  excelData = [],
  fileName,
  setResponse,
  response,
  headers = [],
  sheets = ['Reporte'],
  skipHeader = false,
  origin = 'A2',
  stylesToData = false,
  stylesHeaders = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
  },
  stylePayments = false,
  colPayments = 0,
}) => {
  // const [response, setResponse] = useState(null)
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const FILE_EXTENSION = '.xlsx'

  const exportToExcel = async () => {
    try {
      console.log('excelData', excelData.length)
      let data = []
      if (sheets.length > 1) {
        console.log('no push')
        data = excelData
      } else {
        console.log('push')
        data.push(excelData)
      }
      // data = excelData
      console.log('data', data)
      const wb = XLSX.utils.book_new()
      let ws = XLSX.utils.json_to_sheet(data)

      // console.log('sheets', sheets)
      for (let s = 0; s < sheets.length; s++) {
        // console.log(`data${[s]}`, data[s])
        ws = XLSX.utils.json_to_sheet(data[s], {
          skipHeader: skipHeader,
          origin: origin,
        })

        //INSERT STYLES TO HEADERS AND HEADERS
        let count = 0
        for (let header of headers) {
          let letter = String.fromCharCode(65 + count)
          let cell = letter + 1
          ws[cell.toString()] = {
            t: 's',
            v: header,
            s: stylesHeaders,
          }
          count++

          //INSERT STYLES TO DATA
          for (let i = 0; i < data[s].length; i++) {
            for (let j = 0; j < headers.length; j++) {
              let letter = String.fromCharCode(65 + j)
              let lastCell = String.fromCharCode(
                65 + headers.length - colPayments
              )
              let cell = letter + [i + 2]
              const colReferences = lastCell + [i + 2]

              ws[cell?.toString()].s = stylesToData

              if (stylePayments) {
                ws[colReferences?.toString()].s = {
                  font: { bold: true, sz: 10 },
                  alignment: { wrapText: true },
                }
              }
            }
          }
        }
        XLSX.utils.book_append_sheet(wb, ws, sheets[s])
      }

      const merge = [
        {
          s: { r: 1, c: 1 },
          e: { r: 1, c: 1 },
        },
        {
          s: { r: 1, c: 2 },
          e: { r: 1, c: 2 },
        },
        {
          s: { r: 1, c: 3 },
          e: { r: 1, c: 3 },
        },
        {
          s: { r: 1, c: 4 },
          e: { r: 1, c: 4 },
        },
        {
          s: { r: 1, c: 5 },
          e: { r: 1, c: 5 },
        },
        {
          s: { r: 1, c: 6 },
          e: { r: 1, c: 6 },
        },
        {
          s: { r: 1, c: 7 },
          e: { r: 1, c: 7 },
        },
        {
          s: { r: 1, c: 8 },
          e: { r: 1, c: 8 },
        },
        {
          s: { r: 1, c: 9 },
          e: { r: 1, c: 9 },
        },
        {
          s: { r: 1, c: 10 },
          e: { r: 1, c: 10 },
        },
        {
          s: { r: 1, c: 11 },
          e: { r: 1, c: 11 },
        },
        {
          s: { r: 1, c: 12 },
          e: { r: 1, c: 12 },
        },
        {
          s: { r: 1, c: 13 },
          e: { r: 1, c: 13 },
        },
        {
          s: { r: 1, c: 14 },
          e: { r: 1, c: 14 },
        },
      ]
      ws['!merges'] = merge

      ws['!cols'] = [
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 30 },
        { wch: 12 },
        { wch: 18 },
        { wch: 18 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
        { wch: 18 },
        { wch: 20 },
      ]
      XLSX.writeFile(wb, `${fileName}${FILE_EXTENSION}`)
      setResponse(true)
      console.log('respuesta', response)
    } catch (e) {
      console.log(e)
      setResponse(false)
    }
  }

  return (
    <button id={id} className="hide" onClick={exportToExcel}>
      Reporte
    </button>
  )
}

export default ExportExcel
