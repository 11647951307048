import { gql } from '@apollo/client'

export const GET_VEHICLES = gql`
  query GetAllVehicles($searchQuery: String, $limit: Int, $offset: Int) {
    getAllVehicles(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      count
      rows {
        id
        brand
        model
        motor_number
        plates_number
      }
    }
  }
`

export const GET_VEHICLE = gql`
  query GetOneVehicle($getOneVehicleId: Int!) {
    getOneVehicle(id: $getOneVehicleId) {
      id
      brand
      model
      motor_number
      plates_number
    }
  }
`

export const EXPORT_VEHICLES = gql`
  mutation GetAllVehiclesExport {
    getAllVehiclesExport {
      id
      brand
      model
      motor_number
      plates_number
    }
  }
`

export const CREATE_VEHICLE = gql`
  mutation CreateVehicle($input: VehicleInput!) {
    createVehicle(input: $input) {
      id
      brand
      model
      motor_number
      plates_number
    }
  }
`

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($vehicleId: Int!, $input: VehicleInput!) {
    updateVehicle(vehicleId: $vehicleId, input: $input)
  }
`

export const DELETE_VEHICLE = gql`
  mutation DeleteVehicle($id: Int!) {
    deleteVehicle(id: $id)
  }
`
