import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import {
  GET_INVENTORY_AGE,
  GET_LAST_TFI,
  GET_LOCATION_SUMMARY,
  GET_PURCHASE_ORDERS_TO_RECIEVE,
  GET_RESTOCKED_POINTS,
  GET_STATUS_ORDERS,
  GET_TOP_TEN_HIGHER,
  GET_TOP_TEN_LOWER,
  WAREHOUSE_REPORT,
} from '../../graphql/Catalog/WarehouseDashboard/warehouseDashboard'
//Customer components
import ContentHeader from '../Layout/ContentHeader'
import OperationDashboardsFirstSection from './components/WarehouseOperationDashboard/OperationDashboardsFirstSection'
import { PowerBIEmbed } from 'powerbi-client-react'
import {models} from 'powerbi-client'
import { GET_POWER_BI_TOKEN } from '../../graphql/Documents/PowerBi/powerBi'

const WarehouseOperationDashboard = () => {

  /**------------ Hooks */
  const [accessToken,setAccessToken] = useState('')

  // const [firstSection, setFirstSection] = useState()
  // const [secondSection, setSecondSection] = useState()

  /**------------ Queries */
  // const {
  //   data: dataStatusOrder,
  //   error: errorStatusOrder,
  //   loading: loadingStatusOrder,
  // } = useQuery(GET_STATUS_ORDERS)

  // const {
  //   data: dataLocationSummary,
  //   error: errorLocationSummary,
  //   loading: loadingLocationSummary,
  // } = useQuery(GET_LOCATION_SUMMARY)

  // const {
  //   data: dataPurchaseOrderRecieve,
  //   error: errorPurchaseOrderRecieve,
  //   loading: loadingPurchaseOrderRecieve,
  // } = useQuery(GET_PURCHASE_ORDERS_TO_RECIEVE)

  // const {
  //   data: dataTopTenHigher,
  //   error: errorTopTenHigher,
  //   loading: loadingTopTenHigher,
  // } = useQuery(GET_TOP_TEN_HIGHER)
  // const {
  //   data: dataTopTenLower,
  //   error: errorTopTenLower,
  //   loading: loadingTopTenLower,
  // } = useQuery(GET_TOP_TEN_LOWER)

  // const {
  //   data: dataInventoryAge,
  //   error: errorInventoryAge,
  //   loading: loadingInventoryAge,
  // } = useQuery(GET_INVENTORY_AGE)

  // const {
  //   data: dataWarehouseReport,
  //   error: errorWarehouseReport,
  //   loading: loadingWarehouseReport,
  // } = useQuery(WAREHOUSE_REPORT)

  // const {
  //   data: dataRestockedPoint,
  //   error: errorRestockedPoint,
  //   loading: loadingRestockedPoint,
  // } = useQuery(GET_RESTOCKED_POINTS)

  // const {
  //   data: dataLastTfi,
  //   error: errorLastTfi,
  //   loading: loadingLastTfi,
  // } = useQuery(GET_LAST_TFI)

  const {
    data: dataPowerBiToken,
    loading: loadingPowerBiToken,
    error: errorPowerBiToken,
  } = useQuery(GET_POWER_BI_TOKEN)

  const [loading, setLoading] = useState(false)

  //-------- useEffects
  useEffect(() => {
    //Format date to show TFI

    if (
      errorPowerBiToken
      // errorStatusOrder ||
      // errorLocationSummary ||
      // errorPurchaseOrderRecieve ||
      // errorInventoryAge ||
      // errorWarehouseReport ||
      // errorRestockedPoint ||
      // errorLastTfi ||
      // errorTopTenHigher ||
      // errorTopTenLower
    )
      return toastSweetAlert({
        mode: 'error',
        message: 'something has happend',
      })

    if (
      !loadingPowerBiToken
      // !loadingStatusOrder &&
      // !loadingLocationSummary &&
      // !loadingPurchaseOrderRecieve &&
      // !loadingInventoryAge &&
      // !loadingWarehouseReport &&
      // !loadingRestockedPoint &&
      // !loadingLastTfi &&
      // !loadingTopTenHigher &&
      // !loadingTopTenLower
    ) {
      // //query data from  orderStatus
      // const {
      //   ordersToFill,
      //   ordersToFillSevenDaysAgo,
      //   ordersStowing,
      //   ordersStowingTimePerUnit,
      //   ordersPicking,
      //   ordersPickingTimePerUnit,
      //   ordersVerification,
      //   ordersVerificationTimePerUnit,
      // } = dataStatusOrder?.getStatusOrders

      // const { emptyLocations, locatedLocations } =
      //   dataLocationSummary?.getLocationSummary

      // const { ordersToReceive, ordersToReceiveSevenDaysAgo } =
      //   dataPurchaseOrderRecieve?.getPurchaseOrdersToRecieve

      // const { topHigherRotations } = dataTopTenHigher?.getTopHigher
      // const { topLessRotations } = dataTopTenLower?.getTopLower

      // const { antiquityInventory, refreshInventory } =
      //   dataInventoryAge?.getInventoryAge

      // const {
      //   percentageWarehouse,
      //   usedStorageCapacity,
      //   warehouseUnitsSevenDaysAgo,
      //   warehouseTotalUnits,
      //   unitsReceived,
      //   unitsReceivedSevenDaysAgo,
      //   assortedUnits,
      //   assortedUnitsSevenDaysAgo,
      //   returnedUnits,
      //   returnedUnitsSevenDaysAgo,
      //   unitsDecrease,
      // } = dataWarehouseReport?.getWarehouseReport

      // const { locationsUnderFromLevel } = dataRestockedPoint?.getRestockedPoints
      // const { lastDateTFI, lastPercentageTFI } = dataLastTfi?.getLastTfi

      // let day,month,year
      // let dateTFI = '---'
      // if(moment(lastDateTFI).format('YYYY-MM-DD') != 'Fecha inválida'){
      //   day = moment(lastDateTFI).format('D').toUpperCase().toString()
      //   month = moment(lastDateTFI).format('MMMM').toUpperCase().toString()
      //   year = moment(lastDateTFI).format('YYYY').toUpperCase().toString()

      //   dateTFI = day + ' ' + month.substring(0, 3) + ' ' + year
      // }

      // //1) First section
      // let listOne = {
      //   //--------- Almacen --------
      //   percentageWarehouse, //Porcentaje total
      //   usedStorageCapacity, // Capacidad de almacenamiento utilizada
      //   warehouseUnitsSevenDaysAgo, //Capacidad utilizada hace 7 días
      //   warehouseTotalUnits, //Capacidad utilizada
      //   unitsReceived, //Unidades recibidas hoy
      //   unitsReceivedSevenDaysAgo, //Unidades recibidas hace 7 días
      //   assortedUnits, //Unidades surtidas hoy
      //   assortedUnitsSevenDaysAgo, //Unidades surtidas hace 7 días
      //   returnedUnits, //Unidades devueltas hoy
      //   returnedUnitsSevenDaysAgo, //Unidades devueltas hace 7 días
      //   unitsDecrease, //Unidades de mermas

      //   //--------- Ordenes de compra por recibir -----------
      //   ordersToReceive,
      //   ordersToReceiveSevenDaysAgo,

      //   //--------- Porcentaje de utilizacion de puertas ---------
      //   doorsPercentage: 75, //Porcentaje de utilizacion de puertas
      //   doorsPercentageOneMonthAgo: 34, //Porcentaje de utilizacion de puertas en el ultimo mes

      //   //--------- Estatus de las ordenes --------
      //   ordersToFill,
      //   ordersToFillSevenDaysAgo,
      //   ordersStowing,
      //   ordersStowingTimePerUnit,
      //   ordersPicking,
      //   ordersPickingTimePerUnit,
      //   ordersVerification,
      //   ordersVerificationTimePerUnit,

      //   //--------- TFI ---------
      //   lastDateTFI: dateTFI,
      //   lastPercentageTFI,
      //   //--------- Antiguedad del inventario ---------
      //   antiquityInventory,

      //   //--------- Frescura del inventario ---------
      //   refreshInventory,

      //   //--------- Resumen de ubicaciones ---------
      //   emptyLocations,
      //   locatedLocations,

      //   //--------- Puntos de re-surtido ---------
      //   locationsUnderFromLevel,

      //   //--------- Top 10 SKUS de mayor rotacion ---------
      //   topHigherRotations,
      //   //--------- Top 10 SKUS de menor rotacion ---------
      //   topLessRotations,
      // }
      // setFirstSection(listOne)

      // //2) Second section
      // let listTwo = {
      //   shippingOrders: 122, //Ordenes de enbarque
      //   foreignRoutes: 11, //Rutas foraneas hoy
      //   foreignRoutesSevenDaysAgo: 10, //Rutas foraneas hace 7 días
      //   localRoutes: 1, //Rutas locales hoy
      //   localRoutesSevenDaysAgo: 15, //Rutas locales hace 7 días
      //   allAssortmentOrdersSevenDaysAgo: 346, //Total de ordenes surtidas hace 7 días
      // }
      // setSecondSection(listTwo)
      const token = dataPowerBiToken?.getPowerBiToken?.token ? dataPowerBiToken.getPowerBiToken.token : null
      setAccessToken(token)

      setLoading(true)
    }
  }, [
    // //status Order
    // loadingStatusOrder,
    // errorStatusOrder,
    // dataStatusOrder,

    // //Location Summary
    // dataLocationSummary,
    // loadingLocationSummary,
    // errorLocationSummary,

    // //purchaseOrder
    // dataPurchaseOrderRecieve,
    // loadingPurchaseOrderRecieve,
    // errorPurchaseOrderRecieve,

    // //topTen HIGHER
    // dataTopTenHigher,
    // errorTopTenHigher,
    // loadingTopTenHigher,
    // //topTen LOWER
    // dataTopTenLower,
    // errorTopTenLower,
    // loadingTopTenLower,

    // //InventoryAge
    // dataInventoryAge,
    // errorInventoryAge,
    // loadingInventoryAge,

    // //Warehouse Description
    // dataWarehouseReport,
    // errorWarehouseReport,
    // loadingWarehouseReport,

    // //Restocked Point
    // dataRestockedPoint,
    // errorRestockedPoint,
    // loadingRestockedPoint,

    // //Last TFI
    // dataLastTfi,
    // errorLastTfi,
    // loadingLastTfi,

    dataPowerBiToken,
    errorPowerBiToken,
    loadingPowerBiToken
  ])

  //-------- functions

  const formmatNumber = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  return (
    <>
      {/* <ContentHeader
        title="Indicadores"
        showBreadcrumb={false}
      /> */}
      {loading && (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {/* <OperationDashboardsFirstSection
              data={firstSection}
              formmatNumber={formmatNumber}
            /> */}

            <PowerBIEmbed
              embedConfig = {
                {
                  type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                  id: '685e6a9f-33cf-4dea-8aec-d62d84850376',
                  embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=8c030dcb-b8ae-4dfd-9796-1caf5592a145&groupId=57164b8e-8e11-4618-a744-30ca22b26d35&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUNBTkFEQS1DRU5UUkFMLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
                  accessToken,
                  tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false
                      }
                    },
                  }
                }
              }
                
              eventHandlers = {
                new Map([
                  ['loaded', function () {
                    console.log('Report loaded');
                  }],
                  ['rendered', function () {
                    console.log('Report rendered');
                  }],
                  ['error', function (event) {
                    console.log(event.detail);
                  }]
                ])
              }
                
              cssClassName = {
                "embed-container"
              }
                
              getEmbeddedComponent = {
                (embeddedReport) => {
                  window.report = embeddedReport;
                }
              }
            />
          </div>
          {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
            <OperationDashboardsSecondSection
              data={secondSection}
              formmatNumber={formmatNumber}
            />
          </div> */}
        </div>
      )}
    </>
  )
}

export default WarehouseOperationDashboard
