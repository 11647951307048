import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  DELETE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
  GET_ALL_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/SAP/sapBusinessPartnerCounterCharges'
import LayoutTable from '../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'

export const BusinessPartnerCounterCharges = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { loading, error, data } = useQuery(
    GET_ALL_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []

      data?.getAllSapBusinessPartnerCounterCharges?.rows?.map((item) => {
        return list.push({
          id: item.id,
          código: item.card_code,
          nombre: item.card_name,
          rfc: item.lic_trad_number,
        })
      })

      setDataToTable(list)
      setTotal(data.getAllSapBusinessPartnerCounterCharges.count)
    }
  }, [loading, error, data])

  return (
    <>
      {/* <ContentHeader
        windowTitle="Proveedores"
        title="Proveedores"
        breadcrumb="Proveedores"
      /> */}

      <LayoutTable
        title="Proveedores"
        hideId={true}
        gql={DELETE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES}
        requery={GET_ALL_SAP_BUSINESS_PARTNER_COUNTER_CHARGES}
        loadingData={loading}
        data={dataToTable}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
      />
    </>
  )
}

export default BusinessPartnerCounterCharges
