import Swal from 'sweetalert2'
import { toastSweetAlert } from './ToastSweetAlert'
import withReactContent from 'sweetalert2-react-content'

export const RejectOrder = async (
  dataIssuses,
  idUser,
  createReason,
  idOrder,
  history
) => {
  let clientsReason = null
  const MySwal = withReactContent(Swal)
  MySwal.fire({
    showCloseButton: true,
    title: 'Incidencia en mercancía',
    text: `Seleccione la incidencia`,
    input: 'radio',
    confirmButtonText: 'Aceptar',
    html: (
      <div className="message-container-input pb-4">
        <h3>Seleccione una incidencia</h3>
      </div>
    ),
    buttonsStyling: false,
    customClass: {
      title: 'StandarModalTitle',
      htmlContainer: 'StandarModalContainer',
      confirmButton: 'StandarModalConfirmButtonLogOut',
      denyButton: 'StandarModalCancelButtonLogOut',
      footer: 'StandarModalFooterRejectOrder',
    },
    inputOptions: dataIssuses,
    inputValidator: (reason) => {
      if (!reason) {
        return 'Debe elegir una razon'
      }
      if (reason === '6') {
        return MySwal.fire({
          showCloseButton: true,
          title: 'Escribe el motivo',
          input: 'text',
          buttonsStyling: false,
          html: (
            <div className="message-container-input">Describe el motivo</div>
          ),
          reverseButtons: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          customClass: {
            title: 'StandarModalTitle',
            htmlContainer: 'StandarModalContainer',
            confirmButton: 'StandarModalConfirmButtonLogOut',
            cancelButton: 'StandarModalCancelButtonLogOut',
            footer: 'StandarModalFooterRejectOrder',
          },
          showCancelButton: true,
          inputValidator: (custom) => {
            if (!custom) return 'Debe escribir el motivo'
            clientsReason = custom
          },
        }).then(async (result) => {
          if (result.isConfirmed) return handleCreateReason(reason)
        })
      }
      return handleCreateReason(reason)
    },
  })

  const handleCreateReason = async (reason) => {
    try {
      await createReason({
        variables: {
          inputReason: {
            order_id: `${idOrder}`,
            issusse_id: parseInt(reason),
            user_id: parseInt(idUser),
            reason: clientsReason,
          },
        },
      })
      return (
        toastSweetAlert({
          mode: 'ok',
          message: 'Orden rechazada',
        }),
        history.goBack()
      )
    } catch (e) {
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }
}
