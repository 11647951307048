import React from 'react'
import ContentHeader from './../../Layout/ContentHeader'

export const Content = () => {
  return (
    <>
      <ContentHeader
        title="Dashboard"
        showBreadcrumb={false}
        windowTitle="Dashboard"
      />
    </>
  )
}

export default Content
