import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '../../Global/Box'
import ContentHeader from '../../Layout/ContentHeader'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_ALL_STORES,
  UPDATE_STORE,
  GET_ONE_STORE,
} from '../../../graphql/Catalog/Addresses/addressesStores'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import InputController from '../../Global/InputController'
import { SEPOMEX_MUTATION } from '../../../graphql/Global/sepomex'
import { setZipNullValues, setZipValues } from '../../Helpers/setInputValues'

export const AddressStoresNew = () => {
  const { id: _id } = useParams()
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [colonies, setColonies] = useState([])
  const [nameStore, setNameStore] = useState('')
  const [colonyName, setColonyName] = useState('')
  const history = useHistory()
  const initialStateZip = {
    colonia: [],
    city: '',
    municipality: '',
    state: '',
    colony: '',
    idCountry: '',
    idState: '',
    idCity: '',
    idMuicipality: '',
  }
  const [zip, setZip] = useState(initialStateZip)

  const [sepomexAdress] = useMutation(SEPOMEX_MUTATION)

  const [updateStore] = useMutation(UPDATE_STORE, {
    refetchQueries: [
      {
        query: GET_ALL_STORES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      { query: GET_ONE_STORE, variables: { getAddressStoreId: parseInt(_id) } },
    ],
  })

  const {
    data: dataStore,
    loading: loadingStore,
    error: errorStore,
  } = useQuery(GET_ONE_STORE, {
    variables: {
      getAddressStoreId: parseInt(_id),
    },
  })

  const validationSchema = Yup.object().shape({
    direction: Yup.string().required('Este campo es obligatorio'),
    outdoorNumber: Yup.string().required('Este campo es obligatorio'),
    postalCode: Yup.string()
      .min(4, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
      .max(6, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
      .required('Este campo es obligatorio'),
    colonia: Yup.string().required('Este campo es obligatorio'),
    city: Yup.string().required('Este campo es obligatorio'),
    municipality: Yup.string().required('Este campo es obligatorio'),
    state: Yup.string().required('Este campo es obligatorio'),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!loadingStore) {
      if (_id) {
        if (errorStore) {
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'error',
            message: errorStore.message,
          })
        }
        setNameStore(dataStore?.getAddressStore?.name)
        const address = dataStore?.getAddressStore?.addressStore
        if (address) {
          const dataFormated = {
            direction: `${address?.street}`,
            outdoorNumber: `${address?.external_number}`,
            interiorNumber: `${address?.internal_number}`,
            city: `${address?.city?.name}`,
            municipality: `${address?.municipality?.name}`,
            state: `${address?.state?.name}`,
            colonia: `${address?.colony?.id}`,
            postalCode: `${address?.zip_code}`,
          }
          reset(dataFormated)
          getAddress(address?.zip_code)
          setValue('colonia_name', address?.colony?.name)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataStore, errorStore])

  const getAddress = async (zipCode) => {
    try {
      const { data } = await sepomexAdress({
        variables: {
          zipCode: parseInt(zipCode),
        },
      })
      setZipValues(setValue, setColonies, data.getFullAddressByZipcode, setZip)
    } catch (error) {
      setZipNullValues(setValue)
      toastSweetAlert({
        mode: 'error',
        message: 'Por favor ingrese un código postal válido',
      })
      setZip(initialStateZip)
    }
  }

  const onSubmit = async (Data) => {
    try {
      setLoadingBtn(true)
      if (_id) {
        console.log(Data)
        console.log(getValues('colonia'))
        await updateStore({
          variables: {
            storeId: parseInt(_id),
            input: {
              street: Data.direction,
              external_number: Data.outdoorNumber,
              internal_number: Data.interiorNumber,
              id_country: parseInt(zip.idCountry),
              id_state: parseInt(zip.idState),
              id_city: parseInt(zip.idCity),
              id_municipality: parseInt(zip.idMunicipality),
              id_colony: parseInt(Data.colonia),
              zip_code: parseInt(Data.postalCode),
              state: Data.state,
              city: Data.city,
              municipality: Data.municipality,
              colony: Data.colonia_name,
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Tienda actualizada correctamente',
          }),
          history.push(`/addresses/stores`)
        )
      }
      setLoadingBtn(false)
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  return (
    <>
      {/* <ContentHeader
        title="Tiendas"
        breadcrumb="Tiendas"
        windowTitle="Editar dirección"
      /> */}
      <FormProvider {...methods}>
        <form
          className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title={`${_id ? 'Editar' : 'Agregar'} dirección de tienda`}
            btnRedPath="/addresses/stores"
            btnRedTxt="Cancelar"
            errors={errors}
            btnSubmit={true}
            btnLoading={loadingBtn}
            content={
              <>
                <div className="row">
                  <div className="mb-3 mt-3 col-12">
                    <h2>{nameStore}</h2>
                  </div>
                  <div className="mb-3 col-12">
                    <hr />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Calle"
                      type="text"
                      name="direction"
                      placeholder="Ingrese una calle"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Numero exterior"
                      type="text"
                      name="outdoorNumber"
                      placeholder="# ext."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Numero interior"
                      type="text"
                      name="interiorNumber"
                      placeholder="# int."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="C. P."
                      type="text"
                      name="postalCode"
                      placeholder="Ingrese un C. P."
                      control={control}
                      blurAction={(e) => {
                        getAddress(e.target.value)
                      }}
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Colonia"
                      type="text"
                      name="colonia"
                      inputType="choosen"
                      changeAction={(e) => setValue('colonia_name', e.label)}
                      options={colonies}
                      control={control}
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Ciudad"
                      readOnly
                      type="text"
                      name="city"
                      placeholder="Ciudad"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Municipio"
                      readOnly
                      type="text"
                      name="municipality"
                      placeholder="Municipio"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Estado"
                      readOnly
                      type="text"
                      name="state"
                      placeholder="Estado"
                      control={control}
                    />
                  </div>
                  {/* hidden input */}
                  <InputController
                    label=""
                    type="text"
                    hidden
                    name="colonia_name"
                    control={control}
                  />
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}
export default AddressStoresNew
