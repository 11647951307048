import { gql } from "@apollo/client"

export const GET_ALL_RECEIPT_VEHICLES_TYPES = gql`
  query GetAllVehiclesTypes($searchQuery: String, $limit: Int, $offset: Int) {
    getAllVehiclesTypes(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        name
      }
      count
    }
  }
`

export const GET_ONE_RECEIPT_VEHICLE_TYPE = gql`
  query GetOneReceiptVehicleType($id: Int!) {
    getOneReceiptVehicleType(id: $id) {
      id
      name
    }
  }
`

export const DELETE_RECEIPT_VEHICLE_TYPE = gql`
  mutation DeleteReceiptVehicleType($id: Int!) {
    deleteReceiptVehicleType(id: $id)
  }
`

export const CREATE_RECEIPT_VEHICLE_TYPE = gql`
  mutation CreateReceiptVehicleType($name: String!) {
    createReceiptVehicleType(name: $name)
  }
`

export const UPDATE_RECEIPT_VEHICLE_TYPE = gql`
  mutation UpdateReceiptVehicleType($id: Int!, $name: String!) {
    updateReceiptVehicleType(id: $id, name: $name)
  }
`