import { gql } from '@apollo/client'

export const GET_ONE_WAREHOUSE_MASTER_DATA = gql`
query GetOneWarehouseMasterData($getOneWarehouseMasterDataId: Int) {
  getOneWarehouseMasterData(id: $getOneWarehouseMasterDataId) {
    id
    business_partner_id
    warehouse_id
    store_id
    branch_code
    it_is_branch_R1
    transit_warehouse_id
    waste_warehouse_id
    BusinessPartnerWhsMasterData {
      id
      card_code
      card_name
    }
    StoreWhsMasterData {
      id
      name
      alias
    }
    TransitWhsWarehouseMasterData {
      id
      warehouse_code
      warehouse_name
    }
    WarehouseWhsMasterData {
      id
      warehouse_code
      warehouse_name
    }
    WasteWhsWarehouseMasterData {
      id
      warehouse_code
      warehouse_name
    }
  }
}
`

export const GET_ALL_WAREHOUSE_MASTER_DATA = gql`
query GetAllWarehouseMasterData($limit: Int, $offset: Int, $searchQuery: String) {
  getAllWarehouseMasterData(limit: $limit, offset: $offset, searchQuery: $searchQuery) {
    rows {
      branch_code
      business_partner_id
      id
      it_is_branch_R1
      store_id
      transit_warehouse_id
      warehouse_id
      waste_warehouse_id
      BusinessPartnerWhsMasterData {
        card_code
        card_name
        id
      }
      StoreWhsMasterData {
        id
        name
        alias
      }
      TransitWhsWarehouseMasterData {
        id
        warehouse_code
        warehouse_name
      }
      WarehouseWhsMasterData {
        id
        warehouse_code
        warehouse_name
      }
      WasteWhsWarehouseMasterData {
        id
        warehouse_code
        warehouse_name
      }
    }
    count
  }
}
`

export const CREATE_MASTER_DATA_WAREHOUSE = gql`
mutation CreateWarehouseMasterData($warehouseMasterData: InputWarehouseMasterData!) {
  createWarehouseMasterData(warehouseMasterData: $warehouseMasterData)
}
`

export const UPDATE_MASTER_DATA_WAREHOUSE = gql`
mutation UpdateWarehouseMasterData($warehouseMasterData: InputPutWarehouseMasterData!) {
  updateWarehouseMasterData(warehouseMasterData: $warehouseMasterData)
}
`

export const DELETE_MASTER_DATA_WAREHOUSE = gql`
mutation DeleteWarehouseMasterData($deleteWarehouseMasterDataId: Int!) {
  deleteWarehouseMasterData(id: $deleteWarehouseMasterDataId)
}
`