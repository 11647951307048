import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

/**
 * Parameters
 *
 * reportFilter: active de second mode that show a filter dates and a button to download reports
 * reportFilterHandleButton: Function that will be executed to download report
 * reportFilterHandleButtonText: Text that show on reportFilterHandleButton
 * handleChangeDates: Function that change dates values, it will send two values ->
 *                    1) Inputs value
 *                    2) Inputs type (The name of a field that will be saved using hook)
 */

export const ContentHeader = ({
  title = 'Title',
  breadcrumb = 'breadcrumb',
  windowTitle = 'Window Title',

  /**Variables to display a report filter */
  reportFilter = false,
  reportFilterHandleButton = () => { },
  reportFilterHandleButtonText = 'Descargar reporte',
  handleChangeDates = () => { },

  /**Variables to display a date filter */
  dateFilter = false,
  dateFilterHandleChangeDates = () => { },
  dateFilterDataValues = null,
  showBreadcrumb = true,

  /**Variables to display warehouse choosen with datepicker*/
  warehouseChoosen = false,
  handleChangeWarehouse = () => { },
  //filter with select
  selectFilter = false,
  handleSelectFilter = () => { },
  selectFilterOptionData = [],
  selectFilterLabelName = 'Filtrar:',
}) => {
  const _title = JSON.parse(localStorage.getItem('configSys'))

  useEffect(() => {
    document.title = `${_title?.project_name} | ${windowTitle}`
  }, [title, windowTitle, _title?.project_name])

  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="header-title">{title}</h1>
          </div>
          {reportFilter ? (
            <>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <div>
                      <label className="title-filter" htmlFor="dateStart">
                        Desde:&nbsp;
                      </label>
                      <input
                        id="dateStart"
                        onChange={(e) => handleChangeDates(e, 'start')}
                        className="inputDateColorado"
                        type="date"
                      />
                      <label className="title-filter" htmlFor="dateEnd">
                        &nbsp;Hasta:&nbsp;
                      </label>
                      <input
                        id="dateEnd"
                        onChange={(e) => handleChangeDates(e, 'end')}
                        className="inputDateColorado mr-3"
                        type="date"
                      />
                    </div>
                  </li>
                  <li>
                    <button
                      className="btn btn-accept btn-sm"
                      onClick={reportFilterHandleButton}
                    >
                      {reportFilterHandleButtonText}
                    </button>
                  </li>
                </ol>
              </div>
            </>
          ) : selectFilter ? (
            <>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <div className="d-flex align-items-center mt-3  mt-xl-0 ">
                      <label className="title-filter mr-2" htmlFor="dateStart">
                        {selectFilterLabelName}
                      </label>
                      <select
                        name="month"
                        id="month"
                        className="form-control"
                        onChange={handleSelectFilter}
                      >
                        {selectFilterOptionData.map((item, i) => (
                          <option
                            value={item.value}
                            key={`${item.value}-${i} `}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </li>
                </ol>
              </div>
            </>
          ) : dateFilter ? (
            <>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <div>
                      <label className="title-filter" htmlFor="dateStart">
                        Desde:&nbsp;
                      </label>
                      {dateFilterDataValues !== null ? (
                        <input
                          id="dateStart"
                          onChange={(e) =>
                            dateFilterHandleChangeDates(e, 'start')
                          }
                          className="inputDateColorado"
                          type="date"
                          value={dateFilterDataValues?.start}
                        />
                      ) : (
                        <input
                          id="dateStart"
                          onChange={(e) =>
                            dateFilterHandleChangeDates(e, 'start')
                          }
                          className="inputDateColorado"
                          type="date"
                        />
                      )}

                      <label className="title-filter" htmlFor="dateEnd">
                        &nbsp;Hasta:&nbsp;
                      </label>
                      {dateFilterDataValues !== null ? (
                        <input
                          id="dateEnd"
                          onChange={(e) => dateFilterHandleChangeDates(e, 'end')}
                          className="inputDateColorado mr-3"
                          type="date"
                          value={dateFilterDataValues?.end}


                        />
                      ) : (
                        <input
                          id="dateEnd"
                          onChange={(e) => dateFilterHandleChangeDates(e, 'end')}
                          className="inputDateColorado mr-3"
                          type="date"


                        />

                      )}
                    </div>
                  </li>
                </ol>
              </div>
            </>
          ) : warehouseChoosen ? (
            <>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <div>
                      <label
                        className="title-filter"
                        htmlFor="warehouseChoosen"
                      >
                        {'Almacen(es) seleccionado(s):'}&nbsp;
                      </label>
                      <select
                        name="warehouseChoosen"
                        id="warehouseChoosen"
                        onChange={(e) => handleChangeWarehouse(e, 'warehouse')}
                        className="choosenHeader"
                      >
                        <option value="tlajomulco">Tlajomulco</option>
                        <option value="vallarta">Vallarta</option>
                      </select>
                      <label
                        className="title-filter labelWarehouseChoosen"
                        htmlFor="warehouseChoosenDate"
                      >
                        {'Fechas de operación'}&nbsp;
                      </label>
                      <input
                        id="warehouseChoosenDate"
                        name="warehouseChoosenDate"
                        onChange={(e) => handleChangeWarehouse(e, 'date')}
                        className="inputDateColorado mr-3"
                        type="date"
                      />
                    </div>
                  </li>
                </ol>
              </div>
            </>
          ) : (
            <>
              {/* {breadcrumb !== '' && (
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    {showBreadcrumb && (
                      <li className="breadcrumb-item active">{breadcrumb}</li>
                    )}
                  </ol>
                </div>
              )} */}
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default ContentHeader
