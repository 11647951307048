import { useRef, useState } from 'react'
//Customer components

import ReportModal from '../components/ReportModal'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
//Queries and Mutations
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_PROVIDERS_REPORT,
  VERIFYING_CURRENT_LEVEL,
} from '../../../../graphql/Catalog/ProvidersDashboard/providersDashboard'
import { GET_ALL_GENERATED_REPORTS_BY_USER_ID } from '../../../../graphql/Catalog/ProvidersDashboard/historialReport'

const GenerateReport = ({ 
  title = '',
  dataType = 1, //1= Displacements,2= Inventory,3= reports
}) => {
  /**-------- useMutations */
  const [createReport] = useMutation(CREATE_PROVIDERS_REPORT)

  const {
    data: verifyingLevelData,
    error: verifyingLevelError,
    loading: verifyingLevelLoading,
  } = useQuery(VERIFYING_CURRENT_LEVEL)

  /**-------- Hooks */
  const refModal = useRef()
  const refModalGeneratedAlert = useRef()

  const [reports, setReports] = useState({
    reportsType: dataType == 2 ? 4 : 0,
    reportsTypeRangeStart: '',
    reportsTypeRangeEnd: '',
    dataType: dataType == 1 || dataType == 2 ? [dataType]: [],
    documentsType: [],
  })

  const handleButton = (e) => {
    console.log(e)
    refModal.current.click()
  }

  const handleSubmit = async (data) => {
    try {
      const res = await createReport({
        variables: {
          reportsType: data.reportsType,
          reportsTypeRangeStart: data.reportsTypeRangeStart,
          reportsTypeRangeEnd: data.reportsTypeRangeEnd,
          dataType: data.dataType,
          documentsType: data.documentsType,
        },
        refetchQueries: [GET_ALL_GENERATED_REPORTS_BY_USER_ID],
      })
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <div className="container-fluid generate-report-container">
          <div className="py-2 d-flex justify-content-between">
            <h1 className="font-weight-bold">{title}</h1>

            {verifyingLevelData?.verifyingUserLevel && (
              <button
                type="button"
                className="btn StandarModalConfirmButtonLogOut"
                onClick={handleButton}
              >
                Generar reporte
              </button>
            )}
          </div>
      </div>

      <ReportModal
        refModal={refModal}
        refGenerateModal={refModalGeneratedAlert}
        reports={reports}
        setReports={setReports}
        handleSubmit={handleSubmit}
        dataType={dataType}
      />
    </>
  )
}

export default GenerateReport
