import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_ALL_WAREHOUSE_REPORTS_BY_USER_ID } from '../../graphql/Catalog/Reports/WarehouseReports'
import LayoutTable from '../Global/LayoutTable'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import ContentHeader from '../Layout/ContentHeader'

const WarehouseReports = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [dataToGetLinks, setDataToGetLinks] = useState([])

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)

  function stringToBytes(text) {
    const length = text.length
    const result = new Uint8Array(length)
    for (let i = 0; i < length; i++) {
      const code = text.charCodeAt(i)
      const byte = code > 255 ? 32 : code
      result[i] = byte
    }
    return result
  }

  const handleButton = async (id) => {
    let files = []
    let mimeType = ''
    const data = dataToGetLinks.find((item) => item?.id == +id)

    let flagError = false
    if (data?.file?.url == null && data?.file?.type == 'csv') {
      flagError = true
    }

    const originalString = data?.file?.url
    mimeType = 'text/csv; charset=utf-8'

    if (originalString) {
      const bytes = stringToBytes(originalString)

      const url = window.URL.createObjectURL(
        new Blob([bytes.buffer], {
          type: mimeType,
        })
      )

      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', '')
      document.body.appendChild(a)
      a.click()
    }

    if (flagError) {
      toastSweetAlert({
        mode: 'error',
        message: 'No se encontraron todos los documentos',
      })
    }
  }

  const { data, error, loading } = useQuery(
    GET_ALL_WAREHOUSE_REPORTS_BY_USER_ID,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )
  //   const [getAllGenerateReports] = useLazyQuery(
  //     GET_ALL_GENERATED_REPORTS_BY_USER_ID
  //   )

  useEffect(() => {
    if (!loading) {
      if (error) {
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      }
      const list = data.getAllWarehouseReportsByUserId.rows.map((item) => {
        // const date = moment(+item.createdAt).format('DD MMMM, YYYY')
        const reportTypeId =
          +item?.WarehouseReportFilesToWarehouseReportFileTypes?.id
        let reportTypeName = ''

        switch (reportTypeId) {
          case 1:
            reportTypeName = 'Recibo'
            break
          case 2:
            reportTypeName = 'Picking'
            break
          case 4:
            reportTypeName = 'Embarques'
            break
          default:
            break
        }

        return {
          ['rango de reporte']: `${item?.custom_rage_start} - ${item?.custom_rage_end}`,
          ['tipo de reporte']: reportTypeName,
          ['tipo de archivo']: item?.file_type,
          id: item?.id,
          progreso: (
            <>
              <div className="progress" style={{ width: '100px' }}>
                <div
                  className={`progress-bar progress-bar-striped ${
                    item?.completed
                      ? 'bg-success'
                      : 'bg-warning progress-bar-animated'
                  }   `}
                  role="progressbar"
                  style={{ width: item?.completed ? '100px' : '50px' }}
                  aria-valuenow="10"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </>
          ),
          [item?.completed ? 'show_button' : 'hide_button']: item?.completed,
        }
      })
      setDataToTable(list)
      setTotal(data.getAllWarehouseReportsByUserId.count)
      setDataToGetLinks(data.getAllWarehouseReportsByUserId.rows)
    }
  }, [data, error, loading])

  return (
    <>
      {/* <ContentHeader title="Reportes de almacén" /> */}

      <div className="row justify-content-center">
        <div className="col-md-12">
          <LayoutTable
            title="Reportes de almacén"
            hideBtnEdit={true}
            hideBtnErase={true}
            data={dataToTable}
            hideAddNew={true}
            hideDetails={true}
            exportImportButton={false}
            handleButton={handleButton}
            handleButtonTitle="Descargar reporte"
            // handleDropDownButton={handleDropDownButton}
            // dropDownButton={true}
            hideActions={true}
            setPagePagination={setPagePagination}
            pagePagination={pagePagination}
            totalCount={total}
            loadingData={loading}
            hideId
          ></LayoutTable>
        </div>
      </div>
    </>
  )
}

export default WarehouseReports
