import { gql } from '@apollo/client'

export const GET_ALL_VERIFICATION_ORDERS = gql`
  query GetAllVerificationOrders(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllVerificationOrders(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        platform_id
        order_id
        order_date
        status_id
        user_id
        type_id
        store_id
        warehouse_id
        method_id
        shipping_id
        payment_id
        innvoice_id
        total_price
        shipping_price
        order_doc_num
        num_at_card
        object_type
        object_type_name
        business_partner_id
        shipping_company_id
        warehouse {
          name
        }
        method {
          name
          id
        }
        status {
          name
        }
        shippingCompany {
          name
        }

        products {
          name
          sku
          quantity
          order_id
          id

          PackingListToOrder {
            id
            order_id
            security_checked
            validated
            type_id
            PackingListBoxes {
              PackingListBoxesLines {
                id
                item_code
                quantity
                box_id
                validated
                is_active
              }
              is_virtual
              id
              packing_list_id
              security_checked
              validated
              is_active
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_VERIFICATION_ORDER = gql`
  query GetOneVerificationOrder($getOneVerificationOrderId: Int!) {
    getOneVerificationOrder(id: $getOneVerificationOrderId) {
      id
      platform_id
      order_id
      product_quantity
      order_date
      status_id
      user_id
      type_id
      store_id
      warehouse_id
      method_id
      shipping_id
      payment_id
      innvoice_id
      total_price
      shipping_price
      order_doc_num
      num_at_card
      object_type
      object_type_name
      business_partner_id
      shipping_company_id
      warehouse {
        name
      }
      method {
        name
        id
      }
      status {
        name
      }
      shippingCompany {
        name
      }

      products {
        name
        sku
        quantity
        price
        total
        product_id
        partial_picked
        picked
        packed
        unavailable
        variation_id
        order_id
        id

        PackingListToOrder {
          id
          order_id
          security_checked
          validated
          type_id
          PackingListBoxes {
            PackingListBoxesLines {
              id
              item_code
              quantity
              box_id
              validated
              is_active
            }
            is_virtual
            id
            packing_list_id
            security_checked
            validated
            is_active
          }
        }
      }
      payment {
        payment_id
        platform
        order_id
        id
      }
      store {
        name
      }

      UserToOrder {
        id
        first_name
        last_name
        name
      }
      AppUserToOrder {
        first_name
        id
        last_name
        name
      }
    }

    # PackingListToOrder {
    #   id
    #   order_id
    #   security_checked
    #   validated
    #   type_id
    #   PackingListBoxes {
    #     PackingListBoxesLines {
    #       id
    #       item_code
    #       quantity
    #       box_id
    #       validated
    #       is_active
    #     }
    #     is_virtual
    #     id
    #     packing_list_id
    #     security_checked
    #     validated
    #     is_active
    #   }
    # }
  }
`
export const UPATE_VERIFICATION_ORDER_LABEL = gql`
  mutation UpdateOrderLabel($input: orderLabel!) {
    updateOrderLabel(input: $input)
  }
`
