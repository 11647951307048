import { useEffect, useState } from 'react'
import ModalContent from '../../Global/ModalContent'
import LayoutTable from '../../Global/LayoutTable'
import { useQuery } from '@apollo/client'
import {
  // GET_TFI_ASSIGMENT_BY_ID,
  GET_TFI_SYNC_ERRORS,
  // UPDATE_TFI_SCHEDULE_SYNC_STATUS,
} from '../../../graphql/InventoryActivities/InventoryActivitiesSchedule'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

const InventorySyncSapErrorsModal = ({
  refModal,
  idCalendar,
  setOpen,
  isOpen,
}) => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const { data, error, loading, refetch } = useQuery(GET_TFI_SYNC_ERRORS, {
    variables: {
      getTfiDataSyncErrorsId: +idCalendar > 0 ? +idCalendar : 0,
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })
  // const [updateTfiScheduleSyncStatus] = useMutation(
  //   UPDATE_TFI_SCHEDULE_SYNC_STATUS
  // )

  // const handleConfirmErrors = async () => {
  // //   try {
  // //     await updateTfiScheduleSyncStatus({
  // //       variables: {
  // //         updateTfiScheduleSyncStatusId: +idCalendar,
  // //       },
  // //       refetchQueries: [
  // //         {
  // //           query: GET_TFI_ASSIGMENT_BY_ID,
  // //           variables: {
  // //             getTfiAssigmentByIdId:
  // //               parseInt(idCalendar) > 0 ? parseInt(idCalendar) : 0,
  // //           },
  // //         },
  // //       ],
  // //     })
  // //     refModal.current.click()
  // //   } catch (error) {
  // //     return toastSweetAlert({
  // //       mode: 'error',
  // //       message: error.message,
  // //     })
  // //   }
  // }
  const handleCloseModalButton = () => {
    setOpen(false)
    refModal.current.click()
  }

  useEffect(() => {
    if (!isOpen) return
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const list = data?.getTfiDataSyncErrors?.rows.map((item) => {
        return {
          id: item?.id,
          mensaje: item?.message_error,
        }
      })
      setDataToTable(list)
      setTotal(data?.getTfiDataSyncErrors?.count)
    }
  }, [data, error, loading, isOpen])

  useEffect(() => {
    const refetchErrors = async () => {
      await refetch()
    }
    if (isOpen) refetchErrors()
  }, [isOpen])

  return (
    <>
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#tfiSyncSapErrors"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="tfiSyncSapErrors"
        title="Errores de sincronizacion con SAP"
        size="large"
        content={
          <>
            <LayoutTable
              data={dataToTable}
              totalCount={total}
              loadingData={loading}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              hideActions={true}
              withCard={false}
            />

            <div className="d-flex justify-content-end mt-5">
              {/* <button
                className="btn StandarModalCancelButtonLogOut mr-4"
                onClick={handleCancelModalButton}
              >
                Cancelar
              </button> */}
              <button
                className="btn StandarModalConfirmButtonLogOut"
                onClick={handleCloseModalButton}
              >
                Aceptar
              </button>
            </div>
          </>
        }
      />
    </>
  )
}

export default InventorySyncSapErrorsModal
