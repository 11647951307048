import { gql } from '@apollo/client'

export const GET_HISTORY_REPORTS_COUNTERCHARGES = gql`
  query GetAllCounterchargesReportsFilesByUserId(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllCounterchargesReportsFilesByUserId(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        custom_range_end
        custom_range_start
        id
        file_id
        file_type
        is_error
        completed
        CounterchargesReportFilesToCounterchargesFileType {
          id
          name
        }
      }
    }
  }
`

export const DOWNLOAD_REPORT_COUNTERCHARGES = gql`
  mutation DownloadReportCountercharges($fileId: Int) {
    downloadReportCountercharges(fileId: $fileId) {
      id
      url
    }
  }
`

export const GENERATE_REPORT_COUNTERCHARGES = gql`
  mutation CreateCounterchargesReports(
    $searchInput: String
    $startDate: String
    $endDate: String
    $mode: Int
    $shopRegister: Int
    $idProvider: Int
    $statusNote: Int
    $fullPayed: Boolean
    $withOutPay: Boolean
    $notesForContability: Boolean
    $notesForFinances: Boolean
    $searchForChecks: Boolean
    $isTransfer: Boolean
    $idBankOrigin: Int
  ) {
    createCounterchargesReports(
      searchInput: $searchInput
      startDate: $startDate
      endDate: $endDate
      mode: $mode
      shopRegister: $shopRegister
      idProvider: $idProvider
      statusNote: $statusNote
      fullPayed: $fullPayed
      withOutPay: $withOutPay
      notesForContability: $notesForContability
      notesForFinances: $notesForFinances
      searchForChecks: $searchForChecks
      isTransfer: $isTransfer
      idBankOrigin: $idBankOrigin
    ) {
      message
      status
    }
  }
`
