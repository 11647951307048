import { gql } from '@apollo/client'

// export const GET_QUOTES_ORDERS_LINES = gql`
//   query GetQuotesOrdersLines {
//     getQuotesOrdersLines {
//       id
//       business_partner_id
//       catalog_quotes_id
//       sap_purchases_orders_id
//       sap_purchases_orders_lines_id
//     }
//   }
// `

// export const GET_SCHEDULE_ORDERS_LINES_BY_SCHEDULE_ID = gql`
//   mutation GetScheduleOrdersLinesByScheduleId($scheduleId: Int) {
//     getScheduleOrdersLinesByScheduleId(scheduleId: $scheduleId) {
//       id
//       business_partner_id
//       business_partner {
//         id
//         card_code
//         card_name
//         phone1
//         phone2
//         email
//       }
//       schedule_id
//       sap_purchases_orders_id
//       purchases_orders {
//         id
//         comments
//       }
//       sap_purchases_orders_lines_id
//       purchases_orders_lines {
//         id
//         purchases_order_id
//         purchase_order {
//           id
//           comments
//         }
//         item {
//           id
//           item_code
//           item_name
//           item_code_bar
//         }
//         price
//         quantity
//         total
//       }
//     }
//   }
// `

export const GET_SCHEDULE_LINES_BY_SCHEDULE_ID = gql`
  query GetScheduleOrdersLinesByScheduleId(
    $scheduleId: Int
    $isRecieving: Boolean
    $details: Boolean
  ) {
    getScheduleOrdersLinesByScheduleId(
      scheduleId: $scheduleId
      isRecieving: $isRecieving
      details: $details
    ) {
      ScheduleOrdersLines {
        id
        schedule_id
        business_partner_id
        sap_purchases_orders_id
        ScheduleOrdersLinesSapPurchasesOrders {
          id
          document_number
          document_date
          document_due_date
          comments
          document_total
          document_status
          document_entry
          series
          number_at_card
          BusinessSapPurchasesPendingOrdersLines {
            id
            purchases_order_id
            PurchasesLinesItemInfo {
              id
              item_code
              item_name
              item_code_bar
              purchase_pack_un
              hasHarvest
              expires
              serial_number_management
              ItemReceipt {
                id
                barcode
                item_code
              }
            }
            price
            quantity
            open_quantity
            total
            item_code
            line_status
            SapPurchasesOrdersLinesCommodityReceipt {
              id
              beds
              items
              boxes
              receipt_quantity
              position_order_id
              sap_purchases_orders_lines_id
              anada
              expiration_date
              evidences {
                fileInternal {
                  url
                  id
                }
              }
            }
            scanned_quantity_counter
          }
          business_partner {
            id
            card_code
            card_name
            lic_trad_number
            phone1
            phone2
            email
          }
        }
      }
      scheduleInfo {
        id
        cancel_reason
        comments
        document_date
        document_status_id
        document_time_start
        document_time_end
        warehouse {
          id
          warehouse_code
          warehouse_name
        }
        ScheduleDocumentStatus {
          id
          name
        }
      }
    }
  }
`
