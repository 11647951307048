import { gql } from '@apollo/client'

export const GET_COUNT_PAYMENTS_FOR_REFERENCE = gql`
  query GetCountPaymentsForReference($idStore: Int) {
    getCountPaymentsForReference(idStore: $idStore) {
      count
      rows {
        invoice_number_payments
      }
    }
  }
`

export const GET_ALL_PAYMENTS_NOTE_BY_INVOICE_NUMBER_PAYMENTS = gql`
  query GetAllPaymentsNoteByInvoiceNumberPayments(
    $invoiceNumber: String
    $checkNumber: String
  ) {
    getAllPaymentsNoteByInvoiceNumberPayments(
      invoiceNumber: $invoiceNumber
      checkNumber: $checkNumber
    ) {
      checksToNotes {
        consecutive_number
      }
    }
  }
`

export const GET_ALL_INFO_FOR_EXPORT_TO_EXCEL = gql`
  query GetAllInfoForExportToExcel($idNote: Int) {
    getAllInfoForExportToExcel(idNote: $idNote) {
      id
      id_note
      check_number
      reference_pay
      date_check
      invoice_number_payments
      date_payment
      id_warehouse_payment
      id_store_payment
      amount_check_payment
      warehousePayment {
        id
        warehouse_name
      }
      storesPayment {
        id
        name
      }
      checksToNotes {
        consecutive_number
        amount_note
        pay_note
        date_note
        retention_note
        storesRegister {
          name
        }
        partnerNotes {
          provider_name
          provider_rfc
        }
        userRegisterNotes {
          name
        }
      }
      checksToBillsPayments {
        id_check
        id_bill
        paymentsBillsToBills {
          id
          date_bill
          amount_bill
          invoice_bill
          description
          retail
        }
      }
    }
  }
`

export const GET_ALL_CHECKS_NOTE_BY_ID = gql`
  query GetAllPaymentsNoteById($limit: Int, $offset: Int, $idNote: Int) {
    getAllPaymentsNoteById(limit: $limit, offset: $offset, idNote: $idNote) {
      rows {
        id
        check_number
        reference_pay
        date_check
        invoice_number_payments
        date_payment
        id_warehouse_payment
        id_store_payment
        amount_check_payment
        id_note
      }
      count
    }
  }
`

export const GET_ALL_BILLS_PAYMENTS_BY_ID_CHECK = gql`
  query GetAllBillsPaymentsByIdCheck($limit: Int, $offset: Int, $idCheck: Int) {
    getAllBillsPaymentsByIdCheck(
      limit: $limit
      offset: $offset
      idCheck: $idCheck
    ) {
      rows {
        amount_payment_bill
        date_bill_pay
        paymentsBillsToBills {
          invoice_bill
          full_payed
          amount_bill
          date_bill
        }
      }
      count
    }
  }
`

export const GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE = gql`
  query GetInfoPaymentForPrintCoverage($idNote: Int) {
    getInfoPaymentForPrintCoverage(idNote: $idNote) {
      check_number
      date_check
      invoice_number_payments
      payment_nominative
      storesPayment {
        name
      }
      amount_check_payment
      checksToNotes {
        partnerNotes {
          provider_name
        }
        bills {
          provider_name_nm
        }
      }
    }
  }
`
export const GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE_BY_INVOICE_NUMBER_PAYMENTS = gql`
  query GetInfoPaymentForPrintCoverageByInvoiceNumberPayments(
    $invoiceNumberPayments: String
  ) {
    getInfoPaymentForPrintCoverageByInvoiceNumberPayments(
      invoiceNumberPayments: $invoiceNumberPayments
    ) {
      check_number
      date_check
      invoice_number_payments
      payment_nominative
      storesPayment {
        name
      }
      amount_check_payment
      checksToNotes {
        partnerNotes {
          provider_name
        }
        bills {
          provider_name_nm
        }
      }
    }
  }
`

export const DELETE_PAYMENTS_CHECK = gql`
  mutation DeletePaymentCounterCharges($id: Int!) {
    deletePaymentCounterCharges(id: $id)
  }
`

// export const CREATE_PAYMENT = gql`
//   mutation CreatePayment(
//     $paymentNoteInput: paymentNoteInput!
//     $paymentBillsInput: [paymentBillsInput]!
//   ) {
//     createPayment(
//       paymentNoteInput: $paymentNoteInput
//       paymentBillsInput: $paymentBillsInput
//     ) {
//       number_check
//       id_note
//       date_check
//       shop_pay
//       total_payment
//       pay_note
//       date_pay_note
//     }
//   }
// `
