import { useEffect, useState } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import { useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import moment from 'moment'
import LayoutTable from '../../Global/LayoutTable'
import { GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE } from '../../../graphql/InventoryActivities/InventoryActivitiesSchedule'

export const InventoryActivitiesAssignments = () => {
  const [datesHistoricData, setDatesHistoricData] = useState([])
  const [datesHistoricCount, setDatesHistoricCount] = useState(0)
  const [pageDatesHistoricPagination, setPageDatesHistoricPagination] =
    useState({
      searchQuery: null,
      limit: 30,
      offset: 0,
      status: [1, 2],
      typeSchedule: null,
    })
  const {
    data: datesHistoric,
    loading: loadingHistoric,
    error: errorHistoric,
    refetch: refetchHistoric,
  } = useQuery(GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE, {
    variables: pageDatesHistoricPagination,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    try {
      if (loadingHistoric) return
      if (errorHistoric)
        return toastSweetAlert({
          mode: 'error',
          message: errorHistoric.message,
        })

      const list =
        datesHistoric?.getAllInventoryActivitiesHistoricSchedule?.rows.map(
          (date) => {
            return {
              id: date.id,
              Almacén:
                date?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
              'Fecha Inicio': moment(date.start_date).format('LLL'),
              Tipo:
                date.type_schedule === 1
                  ? `Conteo cíclico / ${
                      date.ubications_or_skus === 1 ? 'Ubicaciones' : 'SKUS'
                    }`
                  : 'TFI',
            }
          }
        )

      setDatesHistoricData(list)

      setDatesHistoricCount(
        datesHistoric?.getAllInventoryActivitiesHistoricSchedule?.count
      )
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    datesHistoric,
    errorHistoric,
    loadingHistoric,
    pageDatesHistoricPagination.status,
  ])

  const handleRefetch = async () => {
    await refetchHistoric()
  }

  return (
    <>
      <ContentHeader
        title="Control de asignaciones"
        breadcrumb="Asignaciones"
        windowTitle={`Asignaciones`}
      />

      <LayoutTable
        title="Selecciona una TFI/Conteo cíclico"
        hideAddNew
        hideId
        exportImportButton={false}
        loadingData={loadingHistoric}
        data={datesHistoricData}
        totalCount={datesHistoricCount}
        pagePagination={pageDatesHistoricPagination}
        setPagePagination={setPageDatesHistoricPagination}
        paginationPerPage={30}
        hideBtnEdit={false}
        hideBtnErase
        hideDetails
        refetchFunctionQueries={handleRefetch}
        editText="Seleccionar"
        filter
        filterOptions={[
          <option value="1" key="1">
            Conteos Cíclicos
          </option>,
          <option value="2" key="2">
            Toma física de inventario
          </option>,
        ]}
        filterOnChange={(e) =>
          setPageDatesHistoricPagination({
            ...pageDatesHistoricPagination,
            typeSchedule: parseInt(e.target.value),
          })
        }
      />
    </>
  )
}

export default InventoryActivitiesAssignments
