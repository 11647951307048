import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import {
  DELETE_RACK,
  EXPORT_ALL_RACKS,
  GET_ALL_RACKS,
} from '../../../../graphql/Catalog/Stowing/racks'
import { EXPORT_ROLES } from '../../../../graphql/Catalog/Roles/roles'
import ContentHeader from '../../../Layout/ContentHeader'
import LayoutTable from '../../../Global/LayoutTable'

export const RackPosition = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    inUse: 0
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_ALL_RACKS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      inUse: pagePagination.inUse
    },
    fetchPolicy: "no-cache"
  })
  const [getAllExportRacks] = useMutation(EXPORT_ALL_RACKS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      inUse: pagePagination.inUse
    },
    fetchPolicy: "no-cache"
  })

  const [dataToTable, setDataToTable] = useState([])

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      let list = []
      data.getAllRacks?.rows.map((item) => {
        return list.push({
          id: item.id,
          Nombre: item.name,
          Codigo: item.code,
          Capacidad: item.capacity,
          'Punto de Reorden': item.reorder,
          Almacen: item.SapWarehouse.warehouse_name,
          Zona: item.RackZone.name,
          "En uso": item.in_use ? "En uso" : "Sin Usar"
        })
      })
      setDataToTable(list)
      setTotal(data.getAllRacks?.count)
    }
  }, [loading, error, data])

  const filterOnChange = (e) => {
    const filterSelected = e.target.value !== "" ? +e.target.value : 0;

    setPagePagination(prevState => ({
      ...prevState,
      inUse: filterSelected
    }))

  }

  return (
    <>
      {/* <ContentHeader title="Racks" breadcrumb="Racks" windowTitle="Racks" /> */}
      <LayoutTable
        data={dataToTable}
        title="Racks"
        gql={DELETE_RACK}
        requery={GET_ALL_RACKS}
        exportQueryCustom={getAllExportRacks}
        exportQuery={EXPORT_ALL_RACKS}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        hideDetails={true}
        addBtnTxt="Agregar nuevo rack"
        FilterBarPlaceholder="Buscar por Nombre, Zona o Almacén"
        filter
        filterOnChange={filterOnChange}
        filterOptions={[
          <option value={1}>En uso</option>,
          <option value={2}>Sin usar</option>
        ]}
        filterLabelText="Filtrar por uso"
      />
    </>
  )
}

export default RackPosition
