import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
import OrderGeneral from '../../../OrderGeneral'
import moment from 'moment'
import OrderDetailStore from '../../../OrderDetailStore'
import OrderProducts from '../../../OrderProducts'
import OrderShippingDetails from '../../../OrderShippingDetails'
import Timeline from '../../../Global/Timeline/Timeline'
import OrderButtons from '../../../OrderButtons'

export const OrderDetailsStatus = () => {
  const history = useHistory()

  //have data order
  const [dataOrder, ] = useState({
      order_id: "123001",
      order_date: "10/08/2022 15:33",
      payment: {
          payment_id: "992832",
          platform: "account_money"
      },
      product_quantity: 2,
      products: [
          {
              id: 4,
              img_url: "http://http2.mlstatic.com/D_981113-MLM42366646822_062020-O.jpg",
              name: "Tequila Patron Roca Silver 750",
              price: 983,
              product_id: 789158673,
              quantity: 1,
              sku: "7994",
              total: 983,
          },
          {
              id: 95,
              img_url: "http://http2.mlstatic.com/D_791802-MLM49027250755_022022-O.jpg",
              name: "Mineo Marsala Seco 750 Ml",
              price: 298,
              product_id: 1374328723,
              quantity: 2,
              sku: "10265",
              total: 596,
          }
      ],
      shipping: {
          address_1: "16 De Septiembre 506",
          city: "Valle De Bravo",
          email: "jrodrig.yk9w72k+2-oguztqojxga4tanjv@mail.mercadolibre.com.mx",
          first_name: "Jaime Rodriguez",
          id_file_receipt: null,
          last_name: "",
          phone: "XXXXXXX",
          postcode: "51200",
          receipt: null,
          state: "MX-MEX"
      },
      shipping_price: 0,
      total_price: 1700,
      method: {name: 'Picking'},
      method_id: 1,
      type: {name: 'Local'},
      type_id: 2,
      warehouse: {name: 'CEDIS'},
      warehouse_id: 1
  })

  return(
    <div className='row'>
      <div className="col-8">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Detalles del pedido</h3>
              </div>
              <div className="card-body col-12">
                <OrderGeneral
                  orderDate={moment(dataOrder?.order_date).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                  orderId={dataOrder?.order_id}
                  paymentId={dataOrder?.payment?.payment_id}
                  withPlataform={false}
                  mediumColSize={'4'}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Detalles de pago</h3>
              </div>
              <div className="card-body">
                <OrderDetailStore
                  orderType={dataOrder?.type?.name}
                  orderStore={
                    dataOrder?.store?.name || dataOrder?.warehouse?.name
                  }
                  orderPaymentPlatform={dataOrder?.payment?.platform}
                  orderMethod={dataOrder?.method?.name}
                  totalPrice={dataOrder?.total_price}
                  shippingPrice={dataOrder?.shipping_price}
                  status={dataOrder?.status?.id}
                  uberAssignedId={dataOrder?.uber_id}
                  status_id={dataOrder?.status_id}
                  shippingCompanyName={
                    dataOrder?.shippingCompany?.name
                      ? dataOrder?.shippingCompany?.name
                      : null
                  }
                  shippingReceipt={
                    dataOrder?.shipping?.receipt?.url
                      ? dataOrder?.shipping?.receipt?.url
                      : false
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Desglose de productos</h3>
              </div>
              <div className="card-body">
                <OrderProducts
                  productsArray={dataOrder?.products}
                  totalQuantity={dataOrder?.product_quantity}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Detalles de envío</h3>
              </div>
              <div className="card-body">
                <OrderShippingDetails
                  city={dataOrder?.shipping?.city}
                  address={dataOrder?.shipping?.address_1}
                  postcode={dataOrder?.shipping?.postcode}
                  email={dataOrder?.shipping?.email}
                  phone={dataOrder?.shipping?.phone}
                  firstName={dataOrder?.shipping?.first_name}
                  lastName={dataOrder?.shipping?.last_name}
                  state={dataOrder?.shipping?.state}
                />
              </div>
            </div>
          </div>
          <OrderButtons
              oneButton={true}
              onProcess={() => history.goBack()}
              firstButtonGray={true}
              processBtn="Volver"
            />
          
        </div>
      </div>
      {/* TIMELINE & INTERNAL NOTES */}
        <div className="col-4">
            <Timeline idOrder={dataOrder?.order_id} />
        </div>
    </div>
  )
}

export default OrderDetailsStatus