import { gql } from '@apollo/client'

export const GET_VEHICLE_OPTIONS = gql`
  query GetLoadingDockControlOptions {
    getLoadingDockControlOptions {
      business_partners {
        id
        card_name
      }
      vehicles_types {
        id
        name
      }
      parcel_services {
        id
        name
      }
      schedules {
        id
        document_date
      }
    }
  }
`
export const CREATE_REGISTER_FOR_VEHICLE = gql`
  mutation createVehicleRegister($input: LoadingDockControlInput!) {
    createVehicleRegister(input: $input)
  }
`
export const GET_ALL_REGISTER_VEHICLES = gql`
  query getAllVehicleRegister(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $isGuard: Boolean
  ) {
    getAllVehicleRegister(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      isGuard: $isGuard
    ) {
      count
      rows {
        id
        business_partner_id
        percentage
        plates_number
        is_active

        user {
          id
          name
        }

        LoadingDockControlToSapBusinessPartner {
          id
          card_name
        }
        LoadingDockControlToShippingCompanies {
          id
          name
        }
        LoadingDockControlToDocumentVehicleType {
          id
          name
        }
        LoadingDockControlToDocumentStatus {
          id
          name
        }
        LoadingDockControlToOrderStatus {
          id
          name
        }

        hours {
          time_arrival_at_facilities {
            completed
            createdAt
          }
          plataform_entry_time {
            completed
            createdAt
          }
          ramp_time {
            completed
            createdAt
          }
          start_download {
            completed
            createdAt
          }
          end_download {
            completed
            createdAt
          }
          departure_time {
            completed
            createdAt
          }
        }
      }
    }
  }
`

export const DELETE_REGISTER_VEHICLE = gql`
  mutation DeleteVehicleRegister($id: Int!) {
    deleteVehicleRegister(id: $id)
  }
`
export const UPDATE_REGISTER_VEHICLE = gql`
  mutation UpdateVehicleRegister(
    $updateVehicleRegisterId: Int!
    $input: LoadingDockControlInput!
  ) {
    updateVehicleRegister(id: $updateVehicleRegisterId, input: $input)
  }
`
export const GET_ONE_REGISTER_VEHICLE = gql`
  query GetOneVehicleRegister($getOneVehicleRegisterId: Int!) {
    getOneVehicleRegister(id: $getOneVehicleRegisterId) {
      id
      business_partner_id
      plates_number
      is_active
      LoadingDockControlToSapBusinessPartner {
        id
        card_name
      }
      LoadingDockControlToShippingCompanies {
        id
        name
      }
      LoadingDockControlToDocumentVehicleType {
        id
        name
      }
    }
  }
`
export const GET_VEHICLES_BY_SCHEDULE_ID = gql`
  query GetVehiclesByScheduleId($getVehiclesByScheduleIdId: Int!) {
    getVehiclesByScheduleId(id: $getVehiclesByScheduleIdId) {
      assigned
      vehicles {
        schedule_id
        loading_dock_control_id
        is_active
        id
        LoadingDockControlScheduleToLoadingDockControl {
          LoadingDockControlToDocumentStatus {
            id
            name
          }
          LoadingDockControlToDocumentVehicleType {
            id
            name
          }
          LoadingDockControlToOrderStatus {
            id
            name
          }
          LoadingDockControlToSapBusinessPartner {
            card_name
            id
          }
          LoadingDockControlToShippingCompanies {
            id
            name
          }
          business_partner_id
          id
          is_active
          percentage
          plates_number
          user {
            id
            name
          }
          status_id
          stage_status_id
          hours {
            start_download {
              completed
              createdAt
            }
            end_download {
              completed
              createdAt
            }
          }
        }
      }
    }
  }
`

export const CREATE_DOCUMENT_ASSIGNATION = gql`
  mutation CreateDocumentAssination(
    $createDocumentAssinationId: Int!
    $action: Int!
  ) {
    createDocumentAssination(id: $createDocumentAssinationId, action: $action)
  }
`
export const UPDATE_START_END_DOWNLOAD_HOUR_ASSIGNATION = gql`
  mutation UpdateStartEndDownloadHourAssination(
    $input: [Int]
    $action: Int!
    $schedule_id: Int!
  ) {
    updateStartEndDownloadHourAssination(
      input: $input
      action: $action
      schedule_id: $schedule_id
    )
  }
`
export const START_END_DOWNLOAD_HOUR_VALIDATION = gql`
  query Query($getStartEndDownloadHourAssignationId: Int!) {
    getStartEndDownloadHourAssignation(
      id: $getStartEndDownloadHourAssignationId
    )
  }
`
