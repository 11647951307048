//Customer components
import Reports from './components/Reports'
import GenerateReport from './components/GenerateReport'

const ReportHistorial = () => {
  return (
    <>
      <GenerateReport title="" dataType={3} />

      <Reports />
    </>
  )
}

export default ReportHistorial
