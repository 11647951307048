import { gql } from '@apollo/client'

export const GET_ALL_ROUTE_TIME_BLOCKS = gql`
  query GetAllTimeBlocks {
    getAllTimeBlocks {
      id
      time
    }
  }
`
