import { gql } from '@apollo/client'

export const GET_ALL_DOCUMENTS_TO_BILLING = gql`
  query GetAllDocumentsToBilling(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllDocumentsToBilling(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        file_xml_id
        file_pdf_id
        invoice
        invoice_stamped_date
        invoice_status
        invoice_uid
        invoiced
        is_active
        serie
        subtotal_invoice
        tax_total
        total_invoice
        uuid
      }
    }
  }
`

export const GET_ONE_DOCUMENT_TO_BILLING = gql`
  query GetDocumentToBillingById($getDocumentToBillingByIdId: Int) {
    getDocumentToBillingById(id: $getDocumentToBillingByIdId) {
      file_pdf_id
      file_xml_id
      id
      invoice
      invoice_stamped_date
      invoice_status
      invoice_uid
      invoiced
      is_active
      serie
      subtotal_invoice
      tax_total
      total_invoice
      uuid
    }
  }
`
export const CREATE_DOCUMENT_TO_BILLING = gql`
  mutation CreateDocumentToBilling(
    $file: Upload!
    $input: inputOptionsToBilling
  ) {
    createDocumentToBilling(file: $file, input: $input)
  }
`

export const RETRY_BILLING_DOCUMENT = gql`
  mutation RetryBillingDocument($documentId: Int) {
    retryBillingDocument(id: $documentId)
  }
`
export const DOWNLOAD_DOCUMENT_XML = gql`
  mutation GetFileInvoice($documentId: Int) {
    getFileInvoice(id: $documentId)
  }
`

export const ADD_CONCEPT_TO_DOCUMENT = gql`
  mutation AddOrderToDocumentToBilling(
    $documentId: Int
    $orderId: Int
    $inputOrderToDocumentToBilling: InputOrderToDocumentToBilling
  ) {
    AddOrderToDocumentToBilling(
      documentId: $documentId
      orderId: $orderId
      inputOrderToDocumentToBilling: $inputOrderToDocumentToBilling
    ) {
      status
      id
    }
  }
`
