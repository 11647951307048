import { useLazyQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import {
  GET_PENDING_BOXES_TO_LOAD,
  GET_SHIPMENT_ROUTE_PACKING_LIST_DETAILS,
} from '../../../../graphql/Catalog/Routes/routes'
import LayoutTable from '../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import _ from 'lodash'
import BoxesPendingLoadedModal from './ControlDeskRoutesBoxesPendingLoadedModal'
const ControlDeskRoutesPackingListDetails = ({
  routeId,
  dataToTable,
  setdataToTable,
}) => {
  // const [dataToTable, setdataToTable] = useState([])
  const [shimpentDetails, setShipmentDetails] = useState([])
  const [total, setTotal] = useState(0)
  const refModal = useRef()
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [getPackingListShipmentRouteDetails, { loading, error }] = useLazyQuery(
    GET_SHIPMENT_ROUTE_PACKING_LIST_DETAILS
  )

  useEffect(() => {
    try {
      const packingListDetails = async () => {
        const { data } = await getPackingListShipmentRouteDetails({
          variables: {
            getShipmentRoutePackingListDetailsId: +routeId,
            searchQuery: pagePagination.searchQuery,
            // limit: pagePagination.limit,
            // offset: pagePagination.offset,
          },
        })

        if (error)
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })

        const shipmentsRoutesDetails =
          data?.getShipmentRoutePackingListDetails?.rows || []

        const list = shipmentsRoutesDetails
          .map((box) => {
            return box?.PackingListBoxesToRoutePakingBoxLoaded?.PackingListBoxesLines.map(
              (boxLine) => {
                return {
                  box_id: boxLine?.box_id,
                  ['sku']: boxLine?.item_code,
                  ['cantidad']: boxLine?.quantity,
                  ['nombre del producto']: boxLine?.SapItem?.item_name,
                }
              }
            )
          })
          .flat(Infinity)
        // shipmentsRoutesDetails.forEach((item) => {
        //   console.log(
        //     item?.PackingListBoxesToRoutePakingBoxLoaded,
        //     'PRIMERA ITERACION'
        //   )
        //   item?.PackingListBoxesToRoutePakingBoxLoaded?.PackingListBoxesLines.forEach(
        //     (line) => {
        //       console.log(line, 'SEGUNDA ITERACION')
        //     }
        //   )
        // })

        setdataToTable(list)
        // setData(list)
        setTotal(data?.getShipmentRoutePackingListDetails?.count)
        // setShipmentDetails(shipmentsRoutesDetails)
      }
      if (routeId) packingListDetails()
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [routeId, pagePagination.searchQuery])

  const showBoxesPendindLoadedModal = () => {
    refModal.current.click()
  }

  return (
    <>
      <div className="">
        <LayoutTable
          title="Detalles de Packing List"
          data={dataToTable}
          pagePagination={pagePagination}
          setPagePagination={setPagePagination}
          totalCount={total}
          hideActions={true}
          hideAddNew={true}
          loadingData={loading}
          exportImportButton={false}
          paginationServer={false}
          searchByProperty="sku"
          extraHeaderButtonFunction={
            routeId ? showBoxesPendindLoadedModal : false
          }
          extraHeaderButtonFunctionText="Cajas pendientes"
        />
      </div>
      <BoxesPendingLoadedModal refModal={refModal} routeId={routeId} />
    </>
  )
}

export default ControlDeskRoutesPackingListDetails
