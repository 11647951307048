import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  CREATE_TRANSFER_REQUEST_SHIPMENT_ASSIGNATION,
  GET_ALL_TRANSFER_REQUEST_BY_STORE_ID,
} from '../../../graphql/Catalog/Routes/routes'
import LayoutTable from '../../Global/LayoutTable'
import ModalContent from '../../Global/ModalContent'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

const ShipmentsRoutesPointsModalAssignation = ({ refModal, routeId }) => {
  const [dataToTable, setDataToTable] = useState([])
  const [transferRequest, setTransferRequest] = useState([])
  const [total, setTotal] = useState(0)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [createTransferRequestShipmentAssignation] = useMutation(
    CREATE_TRANSFER_REQUEST_SHIPMENT_ASSIGNATION
  )

  const { data, loading, error } = useQuery(
    GET_ALL_TRANSFER_REQUEST_BY_STORE_ID,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
    }
  )

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      console.log(data)
      const list = data?.getAllTransferRequestByStoreId?.rows.map((item) => {
        return {
          id: item?.id,
          doc_entry: item?.doc_entry,
          status: item?.status,
          ['origen-destino']: `${item?.warehouse_origin_name?.warehouse_name} - ${item?.warehouse_destiny_name?.warehouse_name}`,
        }
      })

      setDataToTable(list)
      setTotal(data?.getAllTransferRequestByStoreId?.count)
    }
  }, [data, loading, error])

  const handleCheckBox = async (id) => {
    setTransferRequest((prevState) => {
      const findItem = prevState.find((item) => item == id)

      if (findItem) {
        const list = prevState.filter((item) => item !== id)
        setTransferRequest(list)
      } else {
        return [...prevState, id]
      }
    })
  }
  const handleButton = async () => {
    console.log(transferRequest)
    console.log(routeId)
    // try {
    //   const { data } = await createTransferRequestShipmentAssignation({
    //     variables: {
    //       routeId: +routeId,
    //       transferRequest: transferRequest,
    //     },
    //   })

    //   if (data) {
    //     return toastSweetAlert({
    //       mode: 'ok',
    //       message: 'La asignacion se hizo correctamente',
    //     })
    //   }
    // } catch (error) {
    //   return toastSweetAlert({
    //     mode: 'error',
    //     message: error.message,
    //   })
    // }
  }

  return (
    <>
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#shipmentRoutesPoints"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="shipmentRoutesPoints"
        title="Asignar traspaso "
        size="large"
        content={
          <>
            <h5 className="mt-3">Selecciona una orden</h5>

            <LayoutTable
              data={dataToTable}
              loadingData={loading}
              hideAddNew={true}
              withCard={false}
              hideDetails={true}
              hideBtnEdit={true}
              hideBtnErase={true}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              totalCount={total}
              exportImportButton={false}
              checkbox={true}
              handleCheckbox={handleCheckBox}
            />

            <div className="d-flex justify-content-end ">
              <button
                className="btn StandarModalConfirmButtonLogOut"
                onClick={handleButton}
              >
                Asignar traspaso
              </button>
            </div>
          </>
        }
      />
    </>
  )
}

export default ShipmentsRoutesPointsModalAssignation
