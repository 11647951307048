import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_ALL_BILLS_BY_ID_NOTE } from '../../../../../graphql/Catalog/Notes/bills'
import LayoutTable from '../../../../Global/LayoutTable'
import moment from 'moment'

const ShowBillsToggle = ({ idNote }) => {
  const [showBills, setShowBills] = useState([])

  const [getBillsById] = useLazyQuery(GET_ALL_BILLS_BY_ID_NOTE)

  useEffect(() => {
    const getBills = async () => {
      const bills = await getBillsById({
        variables: { idNote: parseInt(idNote) },
        fetchPolicy: 'no-cache',
      })
      setShowBills(bills?.data?.getAllBillsByIdNote?.rows)
      console.log('bills', bills?.data?.getAllBillsByIdNote?.rows)
    }
    if (idNote) getBills()
    console.log('idNote', idNote)
  }, [idNote])

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  return (
    // <div style={{ marginTop: '0px' }}>
    //   <LayoutTable
    //     data={showBills}
    //     withCard={false}
    //     hideFilterBar
    //     hidePagination
    //     hideAddNew
    //     hideActions
    //   />
    // </div>
    <div className="pl-5 pr-5 pt-2 pb-2">
      {/* <div className="row"> */}
      <div className="" style={{ marginLeft: '500px' }}>
        <table className="table-bordered ">
          {/* <tr> */}
          <thead className="text-center" style={{ width: '100%' }}>
            <th style={{ width: '40%' }}>Folio</th>
            <th style={{ width: '40%' }}>Fecha</th>
            <th style={{ width: '50%' }}>Monto</th>
          </thead>
          {/* </tr> */}
          {showBills?.map((item, index) => (
            <tbody className="text-center">
              <tr key={index}>
                <td>{item?.invoice_bill}</td>
                <td>
                  {moment(item?.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </td>
                <td>{formmatterCurrency(item?.amount_bill)}</td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      {/* </div> */}
    </div>
  )
}

export default ShowBillsToggle
