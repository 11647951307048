import { gql } from '@apollo/client'

export const GET_ALL_RETENTION_PARTNERS = gql`
  query GetAllRetentionPartners(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllRetentionPartners(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        provider_name
        provider_rfc
        percentage_retention
        second_ticket
        retention_global
        retentionGlobalToBusinessRetention {
          retention_global
        }
      }
      count
    }
  }
`
// export const GET_ONE_SAP_BUSINESS_PARTNER_FOR_RETENTION = gql`
//   query GetOneSapBusinessPartnerForRetention($searchValue: String!) {
//     getOneSapBusinessPartnerForRetention(searchValue: $searchValue) {
//       id
//       card_code
//       card_name
//       lic_trad_number
//       email
//       phone1
//       phone2
//     }
//   }
// `

export const GET_ONE_RETENTION_PARTNER_BY_ID = gql`
  query GetOneRetentionPartnerById($getOneRetentionPartnerByIdId: Int!) {
    getOneRetentionPartnerById(id: $getOneRetentionPartnerByIdId) {
      id
      provider_name
      provider_rfc
      percentage_retention
      retention_global
      second_ticket
    }
  }
`

export const CREATE_RETENTION_PARTNER = gql`
  mutation CreateRetentionPartner(
    $businessRetentionInput: businessRetentionInput!
  ) {
    createRetentionPartner(businessRetentionInput: $businessRetentionInput) {
      provider_name
      provider_rfc
      percentage_retention
      retention_global
      second_ticket
    }
  }
`
export const UPDATE_RETENTION_PARTNER = gql`
  mutation UpdateRetentionPartner(
    $idPercentageRetention: Int!
    $businessRetentionInput: businessRetentionInput!
  ) {
    updateRetentionPartner(
      idPercentageRetention: $idPercentageRetention
      businessRetentionInput: $businessRetentionInput
    )
  }
`

export const DELETE_RETENTION_PARTNER = gql`
  mutation DeteleRetentionPartner($id: Int!) {
    deteleRetentionPartner(id: $id)
  }
`

export const EXPORT_RETENTION_PARTNERS = gql`
  mutation GetAllProvidersRetentionExport {
    getAllProvidersRetentionExport {
      provider_name
      provider_rfc
      percentage_retention
      second_ticket
    }
  }
`

export const IMPORT_RETENTION_PARTNERS = gql`
  mutation ImportAllProvidersRetention($files: [Upload!]!) {
    importAllProvidersRetention(files: $files)
  }
`

//RETENTION GLOBAL

export const GET_RETENTION_GLOBAL_COUNTERCHARGES = gql`
  query GetRetentionGlobal {
    getRetentionGlobal {
      retention_global
    }
  }
`
export const CREATE_RETENTION_GLOBAL_COUNTERCHARGES = gql`
  mutation CreateRetentionGlobal($retentionGlobalInput: String) {
    createRetentionGlobal(retentionGlobalInput: $retentionGlobalInput) {
      retention_global
    }
  }
`

export const UPDATE_RETENTION_GLOBAL_COUNTERCHARGES = gql`
  mutation UpdateRetentionGlobal($retentionGlobalInput: String) {
    updateRetentionGlobal(retentionGlobalInput: $retentionGlobalInput)
  }
`
