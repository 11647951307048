import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GET_ALL_WAREHOUSES } from '../../../graphql/Catalog/Addresses/addressesWarehouses'
import LayoutTable from '../../Global/LayoutTable'
import ContentHeader from '../../Layout/ContentHeader'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

export const AddressWarehouses = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_ALL_WAREHOUSES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })
  const [dataToTable, setDataToTable] = useState([])

  useEffect(() => {
    if (!loading) {
      if (error)
        toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getAllAddressWarehouses.rows.map((item) => {
        return list.push({
          id: item.id,
          Nombre: item.warehouse_name,
          Código: item.warehouse_code,
          Dirección: item.addressWarehouse
            ? `${item.addressWarehouse.street} ${item.addressWarehouse.external_number}`
            : '-',
          Ciudad: item.addressWarehouse ? item.addressWarehouse.city.name : '-',
          Estado: item.addressWarehouse
            ? item.addressWarehouse.state.name
            : '-',
          Municipio: item.addressWarehouse
            ? item.addressWarehouse.municipality.name
            : '-',
        })
      })
      setDataToTable(list)
      setTotal(data.getAllAddressWarehouses.count)
    }
  }, [loading, error, data])

  return (
    <>
      {/* <ContentHeader
        title="Almacenes"
        breadcrumb="Almacenes"
        windowTitle="Almacenes"
      /> */}
      <LayoutTable
        hideId
        titleActions=""
        data={dataToTable}
        title="Almacenes"
        requery={GET_ALL_WAREHOUSES}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        FilterBarPlaceholder="Buscar por Nombre"
      />
    </>
  )
}

export default AddressWarehouses
