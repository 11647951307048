import { useEffect, useState } from 'react'
import { GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS } from '../../graphql/Catalog/Billing/documenttobillingconcepts'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useQuery } from '@apollo/client'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import ContentHeader from '../Layout/ContentHeader'
import Box from '../Global/Box'
import LayoutTable from '../Global/LayoutTable'
import { currencyFormatToAny } from '../Helpers/HelperCurrencyFormat'

const DocumentToBillingNew = () => {
  const { id: _id } = useParams()

  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { data, loading, error, refetch } = useQuery(
    GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
        idDocument: +_id,
      },
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    try {
      if (loading) return
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const concepts = data.getAllDocumentsToBillingConcepts

      let listTable = concepts.rows.map((item) => {
        return {
          id: item.id,
          sku: item.sku,
          descripción: item.description,
          cantidad: item.quantity,
          'Precio unitario': currencyFormatToAny({
            number: item.unit_price,
          }),
          subtotal: currencyFormatToAny({ number: item.subtotal }),
          impuestos: currencyFormatToAny({ number: item.tax }),
          'Tasa de impuestos': item.tax_rate,
          total: currencyFormatToAny({ number: item.total }),
        }
      })

      setDataTable(listTable)
      setTotalCount(concepts.count)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [data, loading, error, refetch])

  return (
    <>
      <ContentHeader title="" windowTitle="Facturación" />

      <Box
        btnRedTxt="Regresar"
        btnRedPath="/documenttobilling"
        title="Conceptos de facturación"
        content={
          <>
            <LayoutTable
              withCard={false}
              hideId
              data={dataTable}
              totalCount={totalCount}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              hideActions
            />
          </>
        }
      />
    </>
  )
}

export default DocumentToBillingNew
