import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_ONE_NOTE,
  UPDATE_STATUS_PROGRAMMED_NOTE,
} from '../../../../graphql/Catalog/Notes/notes'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'
import Swal from 'sweetalert2'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'

const ProgramPayments = ({
  dataProgramPayments,
  refModal,
  refetch,
  closeModal,
  setCloseModal,
}) => {
  // console.log(refetch)

  const arrayNotesTemporal = []
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [arrayNotes, setArrayNotes] = useState([])
  const [getOneNote] = useLazyQuery(GET_ONE_NOTE)
  const [updateNoteForProgrammedPayments] = useMutation(
    UPDATE_STATUS_PROGRAMMED_NOTE
  )

  const validationSchema = yup.object().shape({
    consecutives: yup.array().of(
      yup.object().shape({
        amount_programmed_to_pay_note: yup
          .string()
          .test(
            'amount_note_invisible',
            'Este campo es obligatorio',
            (value, context) => {
              return (
                context.parent.amount_note_invisible < 0 ||
                value?.replace(/[,]/g, '') >= 0
              )
            }
          ),
        // amount_programmed_to_pay_note: yup
        //   .string()
        //   .test('negative', 'Este NEGATIVO', function (value) {
        //     const { amount_note_invisible } = this.parent
        //     console.log('value', value)
        //     console.log('amount_note_invisible', amount_note_invisible)
        //     return parseFloat(amount_note_invisible) < 0 ? true : false
        //   }),
        amount_note_invisible: yup.string(),
        date_programmed_to_pay: yup
          .date()
          .typeError('Este campo es obligatorio'),
        date_stamp: yup.date().typeError('Este campo es obligatorio'),
      })
    ),
  })
  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'consecutives',
  })

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const formattedNumber = (number) => {
    let cleanNumber = number
    let numberFormat = new Intl.NumberFormat('es-MX', {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cleanNumber)
    return numberFormat === '0' ? '' : numberFormat
  }
  // useEffect(() => {
  //   if(dataProgramPayments){
  //     for
  //   }
  // }, [dataProgramPayments])

  const handleChangePushToArray = (id, value, amountNote, index) => {
    let list = []
    if (value === '') return
    value = value.includes(',') ? value?.replace(/[,]/g, '') : value

    const valueConverted = Number(value)

    if (isNaN(valueConverted)) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Cantidad ingresada es inválida',
        },
        setValue(`consecutives[${index}].amount_programmed_to_pay_note`, '')
      )
    }

    if (valueConverted < 0) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Cantidad negativa o cero',
        },
        setValue(`consecutives[${index}].amount_programmed_to_pay_note`, '')
      )
    }

    // if (valueConverted > parseFloat(amountNote)) {
    //   return toastSweetAlert(
    //     {
    //       mode: 'error',
    //       message: 'Cantidad ingresada es mayor al monto de la nota',
    //     },
    //     setValue(`consecutives[${index}].amount_programmed_to_pay_note`, '')
    //   )
    // }

    const findNoteOnArray = arrayNotesTemporal.find((item) => item?.id === id)
    if (findNoteOnArray) {
      const findIndex = arrayNotesTemporal.findIndex((item) => item?.id === id)
      arrayNotesTemporal.splice(findIndex, 1)
    }

    arrayNotesTemporal.push({
      id: id,
      status_note: 3,
    })

    const numberFormat = formattedNumber(valueConverted)
    setArrayNotes(arrayNotesTemporal)
    setValue(
      `consecutives[${index}].amount_programmed_to_pay_note`,
      numberFormat
    )
    setValue(`consecutives[${index}].amount_note_invisible`, valueConverted)
  }

  useEffect(() => {
    if (dataProgramPayments) {
      dataProgramPayments.map((item, index) => {
        setValue(
          `consecutives[${index}].date_programmed_to_pay`,
          moment().format('YYYY-MM-DD')
        )
        setValue(
          `consecutives[${index}].date_stamp`,
          moment().format('YYYY-MM-DD')
        )
        setValue(
          `consecutives[${index}].amount_note_invisible`,
          item?.amount_note
        )
        const getAmountNoteFormat = formattedNumber(item?.amount_note)
        setValue(
          `consecutives[${index}].amount_programmed_to_pay_note`,
          getAmountNoteFormat
        )
      })
    }
  }, [dataProgramPayments])

  useEffect(() => {
    if (closeModal) {
      handleCloseModal('close')
    }
  }, [closeModal])

  const handleCloseModal = (e) => {
    dataProgramPayments = []
    setCloseModal(false)
    reset({
      consecutives: [
        {
          amount_programmed_to_pay_note: '',
          date_programmed_to_pay: '',
          date_stamp: '',
        },
      ],
    })
  }

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)

      // if (arrayNotes.length === 0) {
      //   return toastSweetAlert(
      //     {
      //       mode: 'error',
      //       message: 'Debes seleccionar al menos una nota',
      //     },
      //     setLoadingBtn(false)
      //   )
      // }

      let list = []

      // for (let note of arrayNotes) {
      const item = data?.consecutives?.map((item, index) => {
        return {
          id: dataProgramPayments[index]?.id,
          status_note: 3,
          date_stamp: moment(item?.date_stamp, 'YYYY-MM-DD hh:mm:ss a').format(
            'YYYY-MM-DD'
          ),
          date_programmed_to_pay: moment(
            item?.date_programmed_to_pay,
            'YYYY-MM-DD hh:mm:ss a'
          ).format('YYYY-MM-DD'),
          amount_programmed_to_pay_note:
            item?.amount_programmed_to_pay_note?.toString()
              ? item?.amount_programmed_to_pay_note
                  ?.replace(/[,]/g, '')
                  .toString()
              : item?.amount_note_invisible?.toString(),
        }
      })
      // }

      await updateNoteForProgrammedPayments({
        variables: {
          updateInputStatusProgrammedNotes: item,
        },
      })
      reset({
        consecutives: [
          {
            amount_programmed_to_pay_note: '',
            date_programmed_to_pay: '',
            date_stamp: '',
          },
        ],
      })

      if (refetch) await refetch()
      return (
        refModal.current.click(),
        setLoadingBtn(false),
        (dataProgramPayments = []),
        toastSweetAlertNotifications({
          mode: 'ok',
          message:
            'Se ha programado el pago de los contrarecibos correctamente',
        })
        // toastSweetAlert({
        //   mode: 'ok',
        //   message: 'Se ha programado el pago de las notas correctamente',
        // })
      )
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        title: e.message,
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={` ${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            withCard={false}
            errors={errors}
            btnSubmit={true}
            btnState={loadingBtn}
            btnSecondFunction={(e) => handleCloseModal(e)}
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={true}
            content={
              <>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Consecutivo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>Fecha Sello&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>Fecha Pago&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>Monto total&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>Monto a pagar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataProgramPayments?.map((item, index) => (
                        <tr key={item?.consecutive_number}>
                          <td>{item?.consecutive_number}</td>
                          <td>
                            <InputController
                              id={`date_stamp_${item?.id}`}
                              name={`consecutives[${index}].date_stamp`}
                              type="date"
                              control={control}
                              // changeAction={(e) => handleChange(item?.id)}
                            />
                          </td>
                          <td>
                            <InputController
                              name={`consecutives[${index}].date_programmed_to_pay`}
                              type="date"
                              control={control}
                            />
                          </td>
                          <td>
                            <div className="hide">
                              <InputController
                                id={`input_amount${item?.id}`}
                                name={`consecutives[${index}].amount_note_invisible`}
                                control={control}
                                valueManual={item?.amount_note}
                                disabled
                              />
                            </div>

                            <InputController
                              id={`input_amount${item?.id}`}
                              name={`consecutives[${index}].amount_note`}
                              control={control}
                              valueManual={formmatterCurrency(
                                item?.amount_note
                              )}
                              disabled
                            />
                          </td>
                          <td>
                            <InputController
                              id={`input_amount${item?.id}`}
                              name={`consecutives[${index}].amount_programmed_to_pay_note`}
                              // placeholder={`Máximo ${formmatterCurrency(
                              //   item?.amount_note
                              // )}`}
                              control={control}
                              changeAction={(e) =>
                                handleChangePushToArray(
                                  item?.id,
                                  e.target.value,
                                  item?.amount_note,
                                  index
                                )
                              }
                              debounceTime={1000}
                              disabled={item?.amount_note < 0 ? true : false}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default ProgramPayments
