import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { GET_ONE_CLIENT_TO_BILLING_QUERY } from '../../../graphql/Catalog/Billing/documenttobillingclients'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
const DocumentToBillingClientsNew = ({
  refModal,
  idClient,
  setIdClient,
  closeModal,
  setCloseModal,
  refetch,
  show,
  setShow,
}) => {
  const validationSchema = yup.object().shape({
    invoice_uid: yup.string(),
    name: yup.string(),
    rfc: yup.string(),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { loading, error, data } = useQuery(GET_ONE_CLIENT_TO_BILLING_QUERY, {
    variables: {
      getDocumentToBillingClientByIdId: idClient,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    try {
      if (!idClient) return
      if (loading) return
      if (error)
        return toastSweetAlert(
          {
            mode: 'error',
            message: error.message,
          },
          handleCloseModal()
        )

      const dataForm = {
        invoice_uid: data.getDocumentToBillingClientById.invoice_uid,
        name: data.getDocumentToBillingClientById.name,
        rfc: data.getDocumentToBillingClientById.rfc,
      }

      reset(dataForm)
    } catch (error) {
      handleCloseModal()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [loading, error, data])

  const handleCloseModal = () => {
    reset({
      invoice_uid: '',
      name: '',
      rfc: '',
    })
    setCloseModal(false)
    setIdClient(null)
    setShow(false)
    refModal.current.click()
  }

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Box
            // btnSubmit
            // btnSubmitText="Guardar"
            btnSecondFunction={handleCloseModal}
            btnSecondFunctionTitle="Cerrar"
            btnSecondFunctionDismissModal
            content={
              <>
                <div className="row p-2">
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="invoice_uid"
                      label="ID Factura"
                      disabled={show}
                    />
                  </div>
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="name"
                      label="Nombre"
                      disabled={show}
                    />
                  </div>
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="rfc"
                      label="RFC"
                      disabled={show}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default DocumentToBillingClientsNew
