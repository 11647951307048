import { gql } from '@apollo/client'

export const GET_ALL_CONCEPT_COUNTER_CHARGES = gql`
  query GetAllConceptsCounterCharges(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllConceptsCounterCharges(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        name
      }
      count
    }
  }
`

export const GET_ONE_CONCEPT_COUNTER_CHARGE = gql`
  query GetOneConceptCounterChargesById($id: Int!) {
    getOneConceptCounterChargesById(id: $id) {
      id
      name
    }
  }
`

export const CREATE_CONCEPT_COUNTER_CHARGE = gql`
  mutation CreateConceptCounterCharges(
    $conceptInput: conceptsCounterChargesInput!
  ) {
    createConceptCounterCharges(conceptInput: $conceptInput) {
      id
      name
    }
  }
`

export const UPDATE_CONCEPT_COUNTER_CHARGE = gql`
  mutation UpdateConceptCounterCharges(
    $id: Int!
    $conceptInput: conceptsCounterChargesInput!
  ) {
    updateConceptCounterCharges(id: $id, conceptInput: $conceptInput)
  }
`

export const DELETE_CONCEPT_COUNTER_CHARGE = gql`
  mutation DeleteConceptCounterCharges($id: Int!) {
    deleteConceptCounterCharges(id: $id)
  }
`
