import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { LiveStatus } from '../../Global/LiveStatus'
import {
  ASSIGN_INVENTORY_ACTIVITY_LABEL,
  FINISH_INVENTORY_ACTIVITY_SCHEDULE,
  GET_ALL_INVENTORY_ACTIVITIES_LIVE_COUNTING,
  GET_INVENTORY_ACTIVITIES_PROGRESS_LIVE,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesLive'
import { useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS } from '../../../../graphql/InventoryActivities/InventoryActivitiesAccountants'
import LayoutTable from '../../../Global/LayoutTable'
import Box from '../../../Global/Box'
import { GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_SCHEDULE } from '../../../../graphql/InventoryActivities/InventoryActivitiesAssignments'
import moment from 'moment'
import SimpleTable from '../../Global/SimpleTable'
import ModalContent from '../../../Global/ModalContent'
import CountGraphic from '../../Global/CountGraphic'

const LiveCycleCounts = ({ dataAssignment }) => {
  const { id, show } = useParams()
  const history = useHistory()
  const refModal = useRef()
  const MySwal = withReactContent(Swal)
  const [loadingBtn, setLoading] = useState(false)
  const [toggledClearRows, setToggleClearRows] = useState(false)
  // const [infoExpandableRows, setInfoExpandableRows] = useState([])
  const [to3rdCount, setTo3rdCounts] = useState(false)
  const [activeAddGroupBtn, setActiveAddGroupBtn] = useState(false)
  const [asignmentInfoTable, setAsignmentInfo] = useState({
    items: [],
    total: 0,
  })

  const [progressIndicatorsLive, setProgressIndicatorsLive] = useState({
    totalLabels: 0,
    labelsComplete: 0,
    labelsPending: 0,
    totalAccountants: 0,
    firstCount: {
      labelsComplete: 0,
      labelsPending: 0,
    },
    secondCount: {
      labelsComplete: 0,
      labelsPending: 0,
    },
  })

  const [
    pagePaginationLiveCountingDetails,
    setPagePaginationLiveCountingDetails,
  ] = useState({
    limit: 30,
    offset: 0,
    searchQuery: null,
    elementAssigned: show,
    idSchedule: parseInt(id) > 0 ? parseInt(id) : 0,
  })

  const [LiveCountingData, setLiveCountingData] = useState({
    items: [],
    total: 0,
  })

  const [dataAcc, setDataAcc] = useState({
    items: [],
    total: 0,
  })

  const [pagePaginationAccountants, setPagePaginationAccountants] = useState({
    searchQuery: '',
    limit: 30,
    offset: 0,
    excludeIds: [],
    onlyAssigned: false,
    scheduleId: parseInt(id),
  })

  const [
    pagePaginationAssigmentsInfoProgress,
    setPagePaginationAssigmentsInfoProgress,
  ] = useState({
    searchQuery: '',
    limit: 10,
    offset: 0,
    scheduleId: parseInt(id),
  })

  const progressLive = useQuery(GET_INVENTORY_ACTIVITIES_PROGRESS_LIVE, {
    variables: {
      idSchedule: parseInt(id) > 0 ? parseInt(id) : 0,
    },
    fetchPolicy: 'no-cache',
  })

  const assigmentsInfoProgress = useQuery(
    GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_BY_SCHEDULE,
    {
      variables: pagePaginationAssigmentsInfoProgress,
    }
  )

  const liveCounting = useQuery(GET_ALL_INVENTORY_ACTIVITIES_LIVE_COUNTING, {
    variables: pagePaginationLiveCountingDetails,
    fetchPolicy: 'no-cache',
  })

  const accountants = useQuery(GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS, {
    variables: pagePaginationAccountants,
  })

  const [finishCycleCountSkus] = useMutation(FINISH_INVENTORY_ACTIVITY_SCHEDULE)
  const [assignAccountant] = useMutation(ASSIGN_INVENTORY_ACTIVITY_LABEL)

  /* USEEFFECT */
  useEffect(() => {
    const interval = setInterval(async () => {
      // await liveCounting.refetch()
      await progressLive.refetch()
      await assigmentsInfoProgress.refetch()
    }, 1 * 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  //FOR ACCOUNTANTS PROGRESS
  useEffect(() => {
    try {
      if (progressLive?.loading) return
      if (progressLive.error) {
        return toastSweetAlert({
          mode: 'error',
          message: progressLive.error.message,
        })
      }
      const data = progressLive.data.getInventoryActivitiesProgressLive
      setProgressIndicatorsLive({
        firstCount: data.firstCount,
        secondCount: data.secondCount,
        labelsComplete: data.labelsComplete,
        labelsPending: data.labelsPending,
        totalAccountants: data.totalAccountants,
        totalLabels: data.totalLabels,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [progressLive?.loading, progressLive?.data, progressLive?.error, id])

  //FOR ASSIGNMENT INFO
  useEffect(() => {
    try {
      if (assigmentsInfoProgress?.loading) return
      if (assigmentsInfoProgress.error) {
        return toastSweetAlert({
          mode: 'error',
          message: assigmentsInfoProgress.error.message,
        })
      }

      const data =
        assigmentsInfoProgress.data.getAllInventoryActivitiesAssignmentBySchedule.rows.map(
          (assignment) => {
            return {
              id: assignment.id,
              Nombre: assignment?.InventoryActivitieLabelAccountant?.full_name
                ? assignment?.InventoryActivitieLabelAccountant?.full_name
                : 'Sin asignar',
              Total: assignment?.pendingLabels + assignment?.completedLabels,
              'Pends./Cerradas': `${assignment.pendingLabels} / ${assignment.completedLabels}`,
              conditional_status: assignment.pendingLabels === 0 ? 3 : 2,
            }
          }
        )

      setAsignmentInfo({
        items: data,
        total:
          assigmentsInfoProgress.data
            .getAllInventoryActivitiesAssignmentBySchedule.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    assigmentsInfoProgress.loading,
    assigmentsInfoProgress.data,
    assigmentsInfoProgress.error,
  ])

  //FOR LIVE COUNTING INFO
  useEffect(() => {
    try {
      if (liveCounting?.loading) return
      if (liveCounting.error) {
        return toastSweetAlert({
          mode: 'error',
          message: liveCounting.error.message,
        })
      }
      const data =
        liveCounting?.data?.getAllInventoryActivitiesLiveCounting?.rows

      const list = data.map((item) => {
        const assignment = item[0].InventoryActivitiesLabelSKU
        const totalCount = item[0].InventoryActivitiesLabelsCounts.reduce(
          (accumulator, count) => {
            return accumulator + count.quantity
          },
          0
        )

        return {
          id: item[0].id,
          SKU: item[0]?.element_assigned,
          Nombre: assignment?.item_name,
          Contador: item[0]?.InventoryActivitieLabelAccountant?.full_name
            ? item[0]?.InventoryActivitieLabelAccountant?.full_name
            : 'Sin asignar',
          userId_omitField: item[0]?.InventoryActivitieLabelAccountant?.user_id,
          'Conteo total': totalCount,
          Estatus: LiveStatus(item[0].status_deviated),
          status: item[0].status_deviated,
          countsData_omitField: item[0].InventoryActivitiesLabelsCounts,
        }
      })

      setLiveCountingData({
        items: list,
        total: liveCounting?.data?.getAllInventoryActivitiesLiveCounting?.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [liveCounting?.loading, liveCounting?.data, liveCounting?.error, id, show])

  //FILLING THIRD ACCOUNTANT SELECTION
  useEffect(() => {
    try {
      if (accountants?.loading) return
      if (accountants.error)
        return toastSweetAlert({ mode: 'error', message: accountants.error })

      const data = accountants.data.getAllInventoryActivitiesAccountant.rows
      let items = []
      for (const item of data) {
        items.push({
          id: item.user_id,
          Nombre: item.full_name,
          user_id: item.user_id,
        })
      }

      setDataAcc({
        items: items,
        total: accountants.data.getAllInventoryActivitiesAccountant.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [accountants?.loading, accountants?.data, accountants?.error, id])

  /* FUNCTIONS */

  // COMPONENT TO SHOW LIST OF ALL COUNTINGS IN UBICATION
  const ExpandedComponentLive = ({ data }) => {
    const dataSet = data.countsData_omitField
    const list = dataSet.map((info) => {
      return {
        Rack: info.element_found,
        Cantidad: info.quantity,
        Añada: info.anada ? info.anada : 'N/A',
        Expiración: info.expiration
          ? moment(info.expiration).format('YYYY-MM-DD')
          : 'N/A',
      }
    })
    return (
      <div className="row">
        <div className="col-12 mt-3">
          <div class="card ">
            <div class="card-body">
              <SimpleTable
                data={list}
                noDataComponentText="No se han capturado conteos"
                title="Conteos"
                sameSize={false}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleFinishCycleCountSkus = async (data) => {
    try {
      setLoading(true)
      await finishCycleCountSkus({
        variables: {
          scheduleId: data.id,
        },
      })
      setLoading(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Se ha finalizado correctamente!',
        },
        history.push(`/inventory/reports/edit/${data.id} `)
      )
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleFinish = async (data) => {
    MySwal.fire({
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      reverseButtons: true,
      title: 'Finalizar conteo de almacén',
      html: (
        <div className="message-container">
          ¿Estás seguro de querer finalizar esta operación de inventario?
        </div>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
      focusDeny: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleFinishCycleCountSkus(data)
      }
    })
  }

  //click en btn asignar contador
  const asignAccountant = (e, row) => {
    if (row.userId_omitField)
      setPagePaginationAccountants({
        ...pagePaginationAccountants,
        excludeIds: [row.userId_omitField],
      })

    e.preventDefault()
    refModal.current.click()
    refModal.current.setAttribute('rack', JSON.stringify([row]))
  }

  //click dentro de modal - seleccionar contador
  const handleSelectAcc = async (e, row) => {
    e.preventDefault()
    let racks = JSON.parse(refModal.current.getAttribute('rack'))
    // const idsRacks = racks.map((rack) => {
    //   return parseInt(rack.rack_id_omitField)
    // })

    try {
      setLoading(true)
      await assignAccountant({
        variables: {
          input: {
            userId: row.id,
            labelId: racks[0].id,
            scheduleId: parseInt(id),
          },
        },
      })
      await liveCounting.refetch()

      refModal.current.click()
      setLoading(false)

      return toastSweetAlert({
        mode: 'ok',
        message: 'Contador asignado correctamente!',
      })
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const refreshAsignationAcountantList = () => {
    const input = document.querySelector(`#accountantSearch`)
    input.value = ''

    setPagePaginationAccountants((prevState) => ({
      ...prevState,
      searchQuery: '',
    }))
  }

  const handleCheckCount = (obj) => {
    if (obj.selectedRows.length > 0) setActiveAddGroupBtn(true)
    else setActiveAddGroupBtn(false)
    setTo3rdCounts(obj.selectedRows)
  }

  const handleSelectGroup = () => {
    // console.log(to3rdCount)
    refModal.current.click()
    refModal.current.setAttribute('rack', JSON.stringify(to3rdCount))
  }

  const handleFilter = async (obj) => {
    setPagePaginationLiveCountingDetails({
      ...pagePaginationLiveCountingDetails,
      filter: parseInt(obj.target.value),
    })
  }

  const disabledCheckBox = (row) => {
    return !row.status
  }

  /* MODES */
  const liveView = () => {
    return (
      <>
        <Box
          title="Conteo cíclico activo / SKU"
          content={
            <>
              <div className="row">
                <div className="card col-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h6 className="lead">ALMACEN</h6>
                        <p className="bg-gray-light pt-1 pb-0">
                          {dataAssignment.almacen}
                        </p>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <h6 className="lead">FECHA DE INICIO</h6>
                        <p className="bg-gray-light pt-1 pb-0 text-uppercase">
                          {dataAssignment.fecha}
                        </p>
                      </div>
                      <div className="col-12 text-right">
                        <button
                          className="btn btn-accept"
                          onClick={(e) => {
                            handleFinish(dataAssignment)
                          }}
                        >
                          Finalizar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-lg-6 col-md-12">
                  <LayoutTable
                    title={null}
                    withCard={false}
                    hideId
                    hideAddNew
                    exportImportButton={false}
                    hideActions
                    data={asignmentInfoTable.items}
                    totalCount={asignmentInfoTable.total}
                    pagePagination={pagePaginationAssigmentsInfoProgress}
                    setPagePagination={setPagePaginationAssigmentsInfoProgress}
                    paginationPerPage={30}
                    hideBtnEdit={false}
                    hideBtnErase
                    hideDetails
                    alertColor="#fff"
                    alertSecondaryColor="#fff"
                    alertTextColor="#559615"
                    textFontWeight="bold"
                    collapsed={true}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <CountGraphic
                    title="Avance"
                    data={[
                      {
                        title: 'Pendientes',
                        total: progressIndicatorsLive?.firstCount?.totalLabels,
                        num: progressIndicatorsLive?.firstCount?.labelsPending,
                      },
                      {
                        title: 'Finalizadas',
                        total: progressIndicatorsLive?.firstCount.totalLabels,
                        num: progressIndicatorsLive?.firstCount?.labelsComplete,
                      },
                    ]}
                  />
                </div>
              </div>
            </>
          }
        />

        <Box
          title="Conteo"
          withHeader={false}
          content={
            <>
              <LayoutTable
                title={
                  <>
                    <div className="row">
                      <div className="col-12 border border-1 border-dark border-top-0 border-left-0 border-right-0 pb-1">
                        <h3 className="card-title">Conteo</h3>
                      </div>
                    </div>
                  </>
                }
                expandableRows
                expandableRowsComponent={ExpandedComponentLive}
                withCard={false}
                hideAddNew
                exportImportButton={false}
                data={LiveCountingData.items}
                totalCount={LiveCountingData.total}
                loadingData={LiveCountingData?.loading}
                pagePagination={pagePaginationLiveCountingDetails}
                setPagePagination={setPagePaginationLiveCountingDetails}
                paginationPerPage={30}
                hideBtnEdit={true}
                hideBtnErase
                hideDetails
                hideColumns={['status']}
                filter={true}
                filterDefaultOptionText="Seleccione una opción"
                defaultValueSelect
                selectableRows={false} //CHANGE TO TRUE TO ACTIVATE SELECTABLE MODE
                selectableRowsClick={handleCheckCount}
                selectableRowsDisabled={true}
                clearSelectedRows={toggledClearRows}
                rowDisabledCriteria={disabledCheckBox}
                alertColor="#fff"
                alertSecondaryColor="#fff"
                alertTextColor="#559615"
                alertSecondaryTextColor="#AF2525"
                alertThirdTextColor="#bab8b8"
                textFontWeight="bold"
                componentAction={(row) => {
                  return (
                    <>
                      {/* {row.status === 1 && (
                        <span
                          className="btn btn-accept"
                          onClick={(e) => reOpenCount(e, row)}
                        >
                          Reabrir conteo
                        </span>
                      )} */}
                      {!row.userId_omitField && (
                        <span
                          className="btn btn-accept"
                          onClick={(e) => asignAccountant(e, row)}
                        >
                          Asignar contador
                        </span>
                      )}
                    </>
                  )
                }}
                // extraCompFilter={
                //   <>
                //     {activeAddGroupBtn && (
                //       <button
                //         className="btn btn-accept btn-thin"
                //         // onClick={() => handleSelectGroup()}
                //       >
                //         Asignar grupo 3er conteo
                //       </button>
                //     )}
                //   </>
                // }
                filterOptions={[
                  <option value="0" key="0">
                    Todos
                  </option>,
                  <option value="1" key="1">
                    Pendientes
                  </option>,
                  <option value="3" key="3">
                    Completados
                  </option>,
                ]}
                filterOnChange={handleFilter}
              />
            </>
          }
        />
      </>
    )
  }

  return (
    <div className={` ${loadingBtn ? 'processing-file' : ''}`}>
      {liveView()}
      <>
        <span
          ref={refModal}
          className="btn btn-accept btn-sm invisible"
          data-toggle="modal"
          data-target="#modal-third-counting"
          data-backdrop="static"
          data-keyboard="false"
          data-dismiss="modal"
        ></span>
        <ModalContent
          idModal="modal-third-counting"
          title="Asignar contador"
          onClose={refreshAsignationAcountantList}
          content={
            <LayoutTable
              title={null}
              withCard={false}
              searchId="accountantSearch"
              data={dataAcc.items}
              totalCount={dataAcc.total}
              // loadingData={accountants?.loading}
              pagePagination={pagePaginationAccountants}
              setPagePagination={setPagePaginationAccountants}
              paginationPerPage={30}
              hideBtnEdit
              hideDetails
              hideColumns={['user_id']}
              componentAction={(row) => {
                return (
                  <div className="w-100 pr-4 text-center">
                    <span
                      className="btn btn-accept"
                      onClick={(e) => {
                        handleSelectAcc(e, row)
                      }}
                    >
                      Seleccionar
                    </span>
                  </div>
                )
              }}
            />
          }
        />
      </>
    </div>
  )
}

export default LiveCycleCounts
