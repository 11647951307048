import React from 'react'
import { useHistory } from 'react-router-dom'
function OrderButtons({
  oneButton,
  firstButtonGray,
  cancelBtn,
  onCancel,
  processBtn,
  onProcess,
  goBack,
  secondAction = true,
  thirdAction = false,
  thirdBtn,
}) {
  const history = useHistory()
  return (
    <div
      className={`${
        oneButton
          ? 'col-12 action_orders_one_button'
          : 'col-12 actions_orders_buttons'
      } `}
    >
      {oneButton ? (
        <>
          <button
            className={
              firstButtonGray
                ? 'btn StandarModalCancelButtonLogOut'
                : 'btn StandarModalConfirmButtonLogOut'
            }
            onClick={onProcess}
          >
            {processBtn}
          </button>
        </>
      ) : (
        <>
          {goBack && (
            <button
              className={'btn StandarModalCancelButtonLogOut'}
              onClick={() => history.goBack()}
            >
              Regresar
            </button>
          )}
          {secondAction && (
            <button
              className={
                firstButtonGray
                  ? 'btn StandarModalCancelButtonLogOut'
                  : 'btn StandarModalRejectButton'
              }
              onClick={onCancel}
            >
              {cancelBtn}
            </button>
          )}
          {thirdAction && (
            <button
              className={
                firstButtonGray
                  ? 'btn StandarModalCancelButtonLogOut'
                  : 'btn StandarModalRejectButton'
              }
              onClick={thirdAction}
            >
              {thirdBtn}
            </button>
          )}
          <button
            className="btn StandarModalConfirmButtonLogOut"
            onClick={onProcess}
          >
            {processBtn}
          </button>
        </>
      )}
    </div>
  )
}

export default OrderButtons
