import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import ContentHeader from "../../../Layout/ContentHeader"
import { useQuery } from "@apollo/client"
import moment from "moment"

/**Customer Components */
import Counters from "../../../Global/Reports/SalesDetails/Counters"
import History from "../../../Global/Reports/SalesDetails/History"
import SalesComparative from "../../../Global/Reports/SalesDetails/SalesComparative"
import { toastSweetAlert } from "../../../Helpers/ToastSweetAlert"

//Queries and Mutations
import { GET_DISPLACEMENTS_AND_INVENTORY_COUNTERS_REPORT, GET_INVENTORY_COUNTERS_REPORT } from "../../../../graphql/Catalog/Reports/reports"

const InventoryReportNew =()=>{
    const { id: _id } = useParams()
    const [filter,setFilter] = useState(1)

    //---------- queries ---------
    const {
        data,
        loading,
        error,
    } = useQuery(GET_DISPLACEMENTS_AND_INVENTORY_COUNTERS_REPORT,{
        variables: {
            idSapItem: parseInt(_id ? _id : 0),
            type: 2,//Inventory,
            filter,
        }
    })

    const [inventory,setInventory] = useState([])
    const [inventoryHistory,setInventoryHistory] = useState([])
    const [product,setProduct] = useState("")

    //---------- useEffects ----------
    useEffect(()=>{
        if(!loading){
            if(error) return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })

            //1) Get general values
            const {item_name,item_code,counters} = data.getDisplacementsAndInventoryCountersReport

            //2) set product name
            setProduct(`SKU ${item_code} - ${item_name}`)

            //3) Get Counters
            let listCounters = []
            counters && counters.map((item)=>{
                const {warehouse_name,count} = item
                listCounters.push({
                    title: warehouse_name,
                    counter: count,
                })
            })
            setInventory(listCounters)

            //4) Get history
            let listHistory = []
            counters && counters.map((item)=>{
                const {id,date,warehouse_name,count} = item
                listHistory.push({
                    id: id,
                    date: date ? moment(date).format('DD/MM/YYYY'): '---',
                    warehouse: warehouse_name,
                    quantity: count,
                })
            })
            setInventoryHistory(listHistory)

        }
    },[loading,data,error])

    const handleSelectOptions =(e)=> parseInt(e.target.value) && setFilter(parseInt(e.target.value))

    return (
        <>
        <ContentHeader
            title={product}
            // dateFilter
            // dateFilterHandleChangeDates={handleChangeDates}
        />

        <div className="mb-2 mt-2" style={{display: 'flex',justifyContent: 'end'}}>
            <select value={filter} className='btn btn-accept btn-sm' name="selectOptions" id="selectOptions" onChange={handleSelectOptions}>
                <option value={1}>Último corte</option>
                <option value={2}>Quincena pasada</option>
                <option value={5}>Tiempo real</option>
            </select>
        </div>
        {!loading && (
            <>

                <Counters counters={inventory} title="Inventarios" />
                <History history={inventoryHistory} title="Historial de Inventario" />
                {/* <SalesComparative /> */}
            </>
        )} 
      </>
    )
}

export default InventoryReportNew