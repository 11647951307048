import { useRef, useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import InputController from '../../../Global/InputController'
import Box from '../../../Global/Box'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_NEW_INVENTORY_ACTIVITY_SCHEDULE,
  UPDATE_INVENTORY_ACTIVITY_SCHEDULE,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesSchedule'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_ALL_WAREHOUSES } from '../../../../graphql/InventoryActivities/InventoryActivitiesLabels'
import moment from 'moment'

const AddDateForm = ({
  dataDateToEdition,
  setDataDateToEdition,
  refetch,
  setLoading,
}) => {
  const refModal = useRef()
  const [warehouses, setWarehouses] = useState([])
  const [idWarehouseSelected, setIdWarehouseSelected] = useState(null)
  const [idTypeScheduleSelected, setIdTypeScheduleSelected] = useState(null)
  const [ubicationsOrSkus, setUbicationsOrSkus] = useState(null)

  const { data: warehouseData } = useQuery(GET_ALL_WAREHOUSES)
  const [addNewDate] = useMutation(CREATE_NEW_INVENTORY_ACTIVITY_SCHEDULE)
  const [updateDate] = useMutation(UPDATE_INVENTORY_ACTIVITY_SCHEDULE)

  useEffect(() => {
    if (warehouseData?.getAllAddressWarehouses) {
      const warehouse = warehouseData.getAllAddressWarehouses.rows.map((w) => ({
        value: w.id,
        label: w.warehouse_name,
      }))
      setWarehouses(warehouse)
    }
  }, [warehouseData])

  useEffect(() => {
    if (dataDateToEdition) {
      return (
        setValue('warehouse_id', dataDateToEdition?.warehouse_id),
        setIdWarehouseSelected(dataDateToEdition?.warehouse_id),
        setIdTypeScheduleSelected(dataDateToEdition?.type_schedule),
        handleSelectBox(true, dataDateToEdition.ubications_or_skus),
        setValue('type_schedule', dataDateToEdition?.type_schedule),
        setValue(
          'date',
          moment(dataDateToEdition?.start_date).local().format('YYYY-MM-DD')
        )
      )
    }
    return reset(), setIdWarehouseSelected(''), setIdTypeScheduleSelected('')
  }, [dataDateToEdition])

  const handleAdd = async (data) => {
    if (data.type_schedule === 1) {
      if (!ubicationsOrSkus)
        return toastSweetAlert({
          mode: 'error',
          message: 'Selecciona que se va a contar en el conteo cíclico',
        })
    }

    setLoading(true)
    const date = new Date(data.date)
    const start_date = moment(date).local().format('YYYY-MM-DD')
    try {
      await addNewDate({
        variables: {
          input: {
            warehouse_id: data.warehouse_id,
            start_date: start_date,
            type_schedule: data.type_schedule,
            ubications_or_skus: ubicationsOrSkus,
          },
        },
      })
      await refetch()
      refModal.current.click()
      reset()
      setIdWarehouseSelected('')
      setLoading(false)
      return toastSweetAlert({
        mode: 'ok',
        message: 'Fecha asignada correctamente',
      })
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleUpdate = async (data) => {
    const date = new Date(data.date)
    const start_date = moment(date).local().format('YYYY-MM-DD')
    if (data.type_schedule === 1) {
      if (!ubicationsOrSkus)
        return toastSweetAlert({
          mode: 'error',
          message: 'Selecciona que se va a contar en el conteo cíclico',
        })
    }
    setLoading(true)
    try {
      await updateDate({
        variables: {
          id: parseInt(dataDateToEdition?.id),
          input: {
            warehouse_id: data.warehouse_id,
            start_date: start_date,
            type_schedule: data.type_schedule,
            ubications_or_skus: ubicationsOrSkus,
          },
        },
      })
      await refetch()
      refModal.current.click()
      setLoading(false)
      return toastSweetAlert({
        mode: 'ok',
        message: 'Fecha actualizada correctamente',
      })
    } catch (error) {
      setLoading(false)
      toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleSelectBox = (event, type) => {
    try {
      setUbicationsOrSkus(type)
      if (type === 1) {
        setValue('ubications', event)
        return setValue('skus', false)
      }

      if (type === 2) {
        setValue('skus', event)
        return setValue('ubications', false)
      }
      setValue('ubications', false)
      setValue('skus', false)
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const validationSchema = Yup.object().shape({
    warehouse_id: Yup.number().required('Este campo es obligatorio').integer(),
    date: Yup.date()
      .required('Este campo es obligatorio')
      .min(today, 'No puedes agendar una revisión en una fecha pasada'),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) })

  return (
    <>
      <FormProvider {...methods}>
        <form
          className="p-3"
          onSubmit={handleSubmit(dataDateToEdition ? handleUpdate : handleAdd)}
        >
          <Box
            btnSecondFunction={() => {
              setDataDateToEdition('')
            }}
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={true}
            btnSubmitText="Guardar"
            errors={errors}
            refModal={refModal}
            btnSubmit={true}
            withCard={false}
            content={
              <div className="row">
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Tipo de conteo"
                    type="text"
                    name="type_schedule"
                    inputType="choosen"
                    valueManual={idTypeScheduleSelected}
                    changeAction={(e) => {
                      handleSelectBox(null, null)
                      setIdTypeScheduleSelected(e.value)
                    }}
                    options={[
                      { value: 1, label: 'Conteo cíclico' },
                      { value: 2, label: 'Toma física de inventario' },
                    ]}
                    control={control}
                  />
                </div>
                {idTypeScheduleSelected === 1 && (
                  <div className="row w-100 pt-2 pb-3">
                    <div className="col-6">
                      <InputController
                        label="Ubicaciones"
                        name="ubications"
                        inputType="checkbox"
                        debounceTime={0}
                        changeAction={(e) =>
                          handleSelectBox(e.target.checked, 1)
                        }
                        control={control}
                      />
                    </div>
                    <div className="col-6">
                      <InputController
                        label="SKUS"
                        name="skus"
                        inputType="checkbox"
                        debounceTime={0}
                        changeAction={(e) =>
                          handleSelectBox(e.target.checked, 2)
                        }
                        control={control}
                      />
                    </div>
                  </div>
                )}
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Almacén"
                    type="number"
                    name="warehouse_id"
                    inputType="choosen"
                    options={warehouses}
                    valueManual={idWarehouseSelected}
                    changeAction={(e) => {
                      setIdWarehouseSelected(e.value)
                    }}
                    control={control}
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Fecha de inicio"
                    type="date"
                    name="date"
                    control={control}
                  />
                </div>
              </div>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default AddDateForm
