import { gql } from '@apollo/client'

export const DECRYPT_TOKEN = gql`
  mutation decryptToken($token: String!) {
    decryptToken(token: $token) {
      id
      username
      name
      first_name
      last_name
      avatar
      role
      email
      id_store
      warehouse_id
      warehouse_code
    }
  }
`

export const AUTH_USER = gql`
  mutation authUser($input: userLoginInput!) {
    authUser(input: $input) {
      token
    }
  }
`

export const EMPTY = gql`
  mutation _emptyMutation {
    _empty
  }
`

export const APP_CONFIG = gql`
  query GetConfigSys {
    getConfigSys {
      project_name
      project_logo
      project_mini_logo
      project_favicon
    }
  }
`
