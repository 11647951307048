import React, { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import LayoutTable from '../../../Global/LayoutTable'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_ALL_INVENTORY_ACTIVITIES_RACKS_AVAILABLE,
  GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_TO_EXCLUDE,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesLabels'

const TableAssignmentsRacks = ({
  elementsToAssign,
  setElementsToAssign,
  selectedLabels,
  setSelectedLabels,
  pagePaginationLabel,
  setPagePaginationLabel,
  showJustResume,
}) => {
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [toggledClearRows, setToggleClearRows] = useState(true)
  const [toggleClearAssignationRows, setToggleClearAssignationRows] =
    useState(true)
  const [checkedLabels, setCheckedLabels] = useState(true)
  const [checkedLabelsToDelete, setCheckedLabelsToDelete] = useState(true)
  const [activeAddGroupBtn, setActiveAddGroupBtn] = useState(false)
  const [activeAddGroupDeleteBtn, setActiveAddGroupDeleteBtn] = useState(false)

  const [labelDataTable, setLabelDataTable] = useState({
    items: [],
    total: 0,
  })

  //useQuery & mutations
  const labels = useQuery(GET_ALL_INVENTORY_ACTIVITIES_RACKS_AVAILABLE, {
    variables: pagePaginationLabel,
    refetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  })

  const [labelsToSearch] = useMutation(
    GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_TO_EXCLUDE
  )

  //FOR LABELS
  useEffect(() => {
    try {
      if (labels?.loading) return

      if (labels.error)
        return toastSweetAlert({ mode: 'error', message: labels.error.message })
      const list =
        labels?.data?.getAllInventoryActivitiesRacksAvailable?.rows.map(
          (item) => {
            return {
              id: item?.id,
              Almacén: item?.SapWarehouse?.warehouse_name,
              Código: item?.code,
              Etiqueta: item?.name,
            }
          }
        )

      setLabelDataTable({
        items: list,
        total: labels?.data?.getAllInventoryActivitiesRacksAvailable?.count,
      })
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [labels.loading, labels.data, labels.error, pagePaginationLabel])

  const handleCheckLabel = (selections) => {
    setCheckedLabels(selections.selectedRows)
    if (selections.selectedRows.length > 1) setActiveAddGroupBtn(true)
    if (selections.selectedRows.length < 1) setActiveAddGroupBtn(false)
  }

  const handleAddLabel = async (row) => {
    try {
      setLoadingProcess(true)
      setSelectedLabels((labels) => [
        ...labels,
        {
          id: row.id,
          Almacén: row['Almacén'],
          Código: row['Código'],
          Etiqueta: row.Etiqueta,
        },
      ])
      const { excludeIds } = pagePaginationLabel
      excludeIds.push(row.id)
      setElementsToAssign((elements) => [...elements, `${row.id}`])
      await labels.refetch()
      setLoadingProcess(false)
      handleResetSelectedRows()
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleAddAllLabels = async (rows) => {
    try {
      if (pagePaginationLabel.searchQuery === '') {
        const result = await toastSweetAlert({
          mode: 'modalConfirmCancel',
          title: 'Se agregaran todos los racks',
          message: `¿Estas seguro de agregar todos los racks diponibles? Se agregara a la asignación un total de ${labelDataTable.total} racks.`,
        })
        if (result.isConfirmed) {
          setLoadingProcess(true)
          const getIdRacks = await labelsToSearch({
            variables: pagePaginationLabel,
            refetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
          })
          setLoadingProcess(false)
          return handleAddSelectGroup(
            getIdRacks.data.getAllInventoryActivitiesAssignmentsToExclude
          )
        }
      } else {
        setLoadingProcess(true)
        const getIdRacks = await labelsToSearch({
          variables: pagePaginationLabel,
          refetchPolicy: 'no-cache',
          fetchPolicy: 'no-cache',
        })

        handleAddSelectGroup(
          getIdRacks.data.getAllInventoryActivitiesAssignmentsToExclude
        )
        setLoadingProcess(false)
      }
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleAddSelectGroup = async (rowsFromAddAllLabels) => {
    try {
      setLoadingProcess(true)
      const { excludeIds } = pagePaginationLabel
      for (const label of rowsFromAddAllLabels
        ? rowsFromAddAllLabels
        : checkedLabels) {
        setSelectedLabels((labels) => [
          ...labels,
          {
            id: label.id,
            Almacén: rowsFromAddAllLabels
              ? label.SapWarehouse?.warehouse_name
              : label['Almacén'],
            Código: rowsFromAddAllLabels ? label.code : label['Código'],
            Etiqueta: rowsFromAddAllLabels ? label.name : label.Etiqueta,
          },
        ])
        excludeIds.push(label.id)
        setElementsToAssign((elements) => [...elements, `${label.id}`])
      }
      setPagePaginationLabel({ ...pagePaginationLabel, searchQuery: '' })
      setLoadingProcess(false)
      await labels.refetch()
      handleResetSelectedRows()
      document.getElementById('searchUbications').value = ''
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleRemoveLabel = async (row) => {
    try {
      setLoadingProcess(true)
      const { excludeIds } = pagePaginationLabel
      const newExcludeIds = excludeIds.filter((rackId) => rackId !== row.id)
      const newElementsToAdd = elementsToAssign.filter(
        (element) => element !== `${row.id}`
      )
      setSelectedLabels(selectedLabels.filter((rack) => rack.id !== row.id))
      setElementsToAssign(newElementsToAdd)
      setPagePaginationLabel({
        ...pagePaginationLabel,
        searchQuery: '',
        excludeIds: newExcludeIds,
      })
      document.getElementById('searchUbications').value = ''
      setLoadingProcess(false)
      setToggleClearAssignationRows(!toggleClearAssignationRows)
      setActiveAddGroupDeleteBtn(false)
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleResetSelectedRows = () => {
    setToggleClearRows(!toggledClearRows)
    setActiveAddGroupBtn(false)
  }

  const handleCheckLabelToDelete = (selections) => {
    setCheckedLabelsToDelete(selections)
    if (selections.selectedRows.length > 1) setActiveAddGroupDeleteBtn(true)
    if (selections.selectedRows.length < 1) setActiveAddGroupDeleteBtn(false)
  }

  const handleDeleteGroup = async () => {
    try {
      if (checkedLabelsToDelete.allSelected) {
        const result = await toastSweetAlert({
          mode: 'modalConfirmCancel',
          title: 'Se eliminaran todos los racks',
          message: `¿Estas seguro de eliminar los ${checkedLabelsToDelete.selectedCount} racks que has asignado?`,
        })
        if (result.isConfirmed) {
          setLoadingProcess(true)
          setElementsToAssign([])
          setSelectedLabels([])
          setPagePaginationLabel({
            ...pagePaginationLabel,
            searchQuery: '',
            excludeIds: [],
          })
          setToggleClearAssignationRows(!toggleClearAssignationRows)
          setActiveAddGroupDeleteBtn(false)
          return setLoadingProcess(false)
        }
      } else {
        setLoadingProcess(true)
        const { excludeIds } = pagePaginationLabel
        let newExcludeIds = []
        let newElementsToAdd = []

        for (const row of checkedLabelsToDelete.selectedRows) {
          newElementsToAdd.push(newElementsToAdd.push(row.id))
        }

        newExcludeIds = excludeIds.filter(
          (labelId) => !newElementsToAdd.includes(labelId)
        )

        setSelectedLabels((selectedLabel) => {
          return selectedLabel.filter(
            (prev) => !newElementsToAdd.includes(prev.id)
          )
        })

        setPagePaginationLabel({
          ...pagePaginationLabel,
          searchQuery: '',
          excludeIds: newExcludeIds,
        })

        document.getElementById('searchUbications').value = ''
        setToggleClearAssignationRows(!toggleClearAssignationRows)
        setActiveAddGroupDeleteBtn(false)
        setLoadingProcess(false)
      }
    } catch (error) {
      setLoadingProcess(false)
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  return (
    <div className={`${loadingProcess ? 'processing-file' : ''}`}>
      {!showJustResume && (
        <LayoutTable
          title="Selecciona ubicaciones"
          hideAddNew
          searchId="searchUbications"
          exportImportButton={false}
          loadingData={labelDataTable.loading}
          data={labelDataTable.items}
          totalCount={labelDataTable.total}
          pagePagination={pagePaginationLabel}
          setPagePagination={setPagePaginationLabel}
          paginationPerPage={30}
          hideBtnEdit={true}
          hideBtnErase
          hideDetails
          selectableRows={true}
          selectableRowsClick={handleCheckLabel}
          clearSelectedRows={toggledClearRows}
          enableCollapse={false}
          collapsed={false}
          customActionButtons={[
            {
              className: 'btn btn-accept',
              handleButton: handleAddLabel,
              buttonText: 'Seleccionar',
            },
          ]}
          extraCompFilter={
            <>
              <button
                className="btn btn-accept btn-thin"
                onClick={() => labelDataTable.total > 0 && handleAddAllLabels()}
                disabled={activeAddGroupBtn}
              >
                {pagePaginationLabel.searchQuery === ''
                  ? 'Agregar todo'
                  : labelDataTable.total === 0
                  ? 'Nada que agregar'
                  : `Agregar ${labelDataTable.total} ubicaciones`}
              </button>

              {activeAddGroupBtn && (
                <button
                  className="btn btn-accept btn-thin"
                  onClick={() => handleAddSelectGroup()}
                >
                  Asignar ubicaciones
                </button>
              )}
            </>
          }
        />
      )}
      <LayoutTable
        withCard={!showJustResume}
        title={
          !showJustResume ? (
            'Ubicaciones seleccionadas'
          ) : (
            <div className="row">
              <div className="col-12">
                <p className="pl-4 pt-3 mb-2">
                  <h6>Ubicaciones seleccionados</h6>
                </p>
                <hr className="mt-0 mb-2 pt-0 border-top border border-2" />
              </div>
            </div>
          )
        }
        hideAddNew
        hideFilterBar={showJustResume}
        hideActions={showJustResume}
        exportImportButton={false}
        data={selectedLabels}
        totalCount={selectedLabels.length}
        paginationPerPage={30}
        hideBtnEdit={true}
        paginationServer={false}
        hideBtnErase
        hideDetails
        selectableRows={!showJustResume ? true : false}
        selectableRowsClick={handleCheckLabelToDelete}
        clearSelectedRows={toggleClearAssignationRows}
        enableCollapse={false}
        collapsed={false}
        noDataComponent="Aún no se han seleccionado ubicaciones"
        paginationServerOptions={{
          persistSelectedOnSort: true,
          persistSelectedOnPageChange: false,
        }}
        customActionButtons={
          showJustResume
            ? false
            : [
                {
                  className: 'btn btn-danger btn-trash',
                  handleButton: handleRemoveLabel,
                  buttonText: <i className="fa fa-trash"></i>,
                },
              ]
        }
        extraCompFilter={
          <>
            {activeAddGroupDeleteBtn && (
              <button
                className="btn btn-accept btn-thin"
                onClick={() => handleDeleteGroup()}
              >
                Eliminar asignaciones
              </button>
            )}
          </>
        }
      />
    </div>
  )
}

export default TableAssignmentsRacks
