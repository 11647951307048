import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import OrderCol from '../../../Global/OrderCol/index'
import LayoutTable from '../../../Global/LayoutTable'
import Box from '../../../Global/Box'
import {
  CREATE_OMNICHANNEL_CREDENTIALS,
  GET_ALL_OMNICHANNEL_PRODUCTS,
  OMNICHANNEL_AUTOMATIC_SYNC,
  OMNICHANNEL_INITIAL_SYNC,
  OMNICHANNEL_MANUAL_SYNC,
} from '../../../../graphql/Omnichannel/OmnichannelProducts'
import { GET_ALL_OMNICHANNEL_USER_CREDENTIALS } from '../../../../graphql/Omnichannel/OmnichannelCredentials'
import {
  CREATE_OMNICHANNEL_WEBHOOK,
  DELETE_OMNICHANNEL_WEBHOOK,
  GET_ALL_OMNICHANNEL_WEBHOOKS,
  UPDATE_OMNICHANNEL_WEBHOOK,
} from '../../../../graphql/Omnichannel/OmnichannelWebhooks'
import Swal from 'sweetalert2'

const ShopifyChannel = ({ platformSelected }) => {
  const [loading, setLoading] = useState(false)
  const [credentialsOmnichannel, setCredentialsOmnichannel] = useState({})
  const [webhooksOmnichannel, setWebhooksOmnichannel] = useState({
    rows: [],
    count: 0,
  })
  const [productsOmnichannel, setProductsOmnichannel] = useState({
    rows: [],
    count: 0,
  })
  const [productsPagePagination, setProductsPagePagination] = useState({
    platformId: '',
    limit: 10,
    offset: 0,
    searchQuery: '',
  })

  const omnichannelCredentials = useQuery(GET_ALL_OMNICHANNEL_USER_CREDENTIALS)
  const omnichannelWebhooks = useQuery(GET_ALL_OMNICHANNEL_WEBHOOKS, {
    variables: { platformId: platformSelected ? platformSelected : 0 },
  })

  const omnichannelProducts = useQuery(GET_ALL_OMNICHANNEL_PRODUCTS, {
    variables: productsPagePagination,
  })

  const [omnichannelInitialSync] = useMutation(OMNICHANNEL_INITIAL_SYNC)
  const [omnichannelCreateCredentials] = useMutation(
    CREATE_OMNICHANNEL_CREDENTIALS
  )
  const [omnichannelCreateWebhook] = useMutation(CREATE_OMNICHANNEL_WEBHOOK)
  const [omnichannelUpdateWebhook] = useMutation(UPDATE_OMNICHANNEL_WEBHOOK)
  const [omnichannelDeleteWebhook] = useMutation(DELETE_OMNICHANNEL_WEBHOOK)
  const [omnichannelAutomaticSync] = useMutation(OMNICHANNEL_AUTOMATIC_SYNC)

  //   CREDENTIALS INFO
  useEffect(() => {
    try {
      if (omnichannelCredentials.loading) return
      if (omnichannelCredentials.error)
        return toastSweetAlert({
          mode: 'error',
          message: omnichannelCredentials.error.message,
        })

      let list = {}
      omnichannelCredentials?.data?.getOmnichannelCredentials?.map(
        (credentialsOmnichannel) => {
          list = {
            ...list,
            [credentialsOmnichannel?.EcommerceClientCredentialsPlatform?.name?.toUpperCase()]:
            {
              id: credentialsOmnichannel.EcommerceClientCredentialsPlatform
                .id,
              platform:
                credentialsOmnichannel?.EcommerceClientCredentialsPlatform
                  ?.name,
              token: credentialsOmnichannel?.token,
              shopUrl: credentialsOmnichannel?.shop_url,
              apiVersion: credentialsOmnichannel?.api_version,
              expirate: credentialsOmnichannel?.expire,
              publicKey: credentialsOmnichannel?.public_key,
              refreshToken: credentialsOmnichannel?.refresh_token,
              secretKey: credentialsOmnichannel?.secret_key,
              suiteUrl: credentialsOmnichannel?.productive_link,
              alreadyInitialSync: credentialsOmnichannel?.initial_sync,
            },
          }
          return null
        }
      )
      setCredentialsOmnichannel(list)
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    omnichannelCredentials.data,
    omnichannelCredentials.loading,
    omnichannelCredentials.error,
  ])

  //   WEBHOOKS INFO
  useEffect(() => {
    try {
      if (omnichannelWebhooks.loading) return
      if (omnichannelWebhooks.error)
        return toastSweetAlert({
          mode: 'error',
          message: omnichannelWebhooks.error.message,
        })

      const list = omnichannelWebhooks.data.getAllOmnichannelWebhooks.rows.map(
        (webhookOmnichannel) => {
          return {
            id: webhookOmnichannel.id,
            topic: webhookOmnichannel.topic_name,
            callback: webhookOmnichannel.callback,
          }
        }
      )
      setWebhooksOmnichannel({
        rows: list,
        count: omnichannelWebhooks.data.getAllOmnichannelWebhooks.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    omnichannelWebhooks.data,
    omnichannelWebhooks.loading,
    omnichannelWebhooks.error,
  ])

  //PRODUCTS FROM SHOPIFY
  useEffect(() => {
    try {
      if (omnichannelProducts.loading) return
      if (omnichannelProducts.error)
        return toastSweetAlert({
          mode: 'error',
          message: omnichannelProducts.error.message,
        })

      let objStocks = {}
      const list = omnichannelProducts.data.getAllOmnichannelProducts.rows.map(
        (product) => {
          product.EcommerceProductsStocksOmnichannel.map((stock) => {
            objStocks = {
              ...objStocks,
              [stock.EcommerceStocksLocationsOmnichannel
                .integration_location_name]: stock.available,
            }
            return null
          })

          return {
            '': (
              <img
                src={product.principal_url_image}
                width={70}
                height={70}
                alt={product.title}
              />
            ),
            sku: product.sku,
            titulo: product.title,
            handle: product.handle,
            precio: `$ ${parseFloat(product.price).toFixed(2)}`,
            promo: product.compare_at_price
              ? `$ ${parseFloat(product.compare_at_price).toFixed(2)}`
              : '$ 0.00',
            ...objStocks,
            'Inventario total': product.total_inventory,
            Estatus: product.status,
          }
        }
      )

      setProductsOmnichannel({
        rows: list,
        count: omnichannelProducts.data.getAllOmnichannelProducts.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [omnichannelProducts, productsPagePagination])

  const initialSyncronization = async (alreadySync) => {
    try {
      if (alreadySync === 2) {
        const result = await toastSweetAlert({
          mode: 'modalConfirmCancel',
          icon: 'info',
          title: '¿Quieres volver hacer la sincronización?',
          message:
            'Este proceso borrara todo y volvera a sincronizar todo lo que existe en shopify hacia suite. Creara de nuevo todas las cosas necesarias para poder operar',
        })

        if (result?.isConfirmed) return await helperRunInitialSync()
      }

      await helperRunInitialSync()
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const createCredentials = async () => {
    try {
      const validatorFormCreate = () => {
        const shopUrl = document.getElementById('shopUrlShopify').value
        const token = document.getElementById('tokenShopify').value
        const apiVersion = document.getElementById('apiVersionShopify').value
        // const suiteUrl = document.getElementById('suiteUrlShopify').value
        if (!shopUrl)
          Swal.showValidationMessage(`Especifica la URL de la tienda`)

        if (!token)
          Swal.showValidationMessage(`Especifica el API KEY de la tienda`)

        if (!apiVersion)
          Swal.showValidationMessage(
            `Especifica la versión que se usara en la API`
          )
        if (!apiVersion) {
          Swal.showValidationMessage(
            `Especifica la versión que se usara en la API`
          )
        }
        // if (!suiteUrl) {
        //   Swal.showValidationMessage(`Especifica la URL de la suite`)
        // }

        return { shopUrl, apiVersion, token }
      }
      const result = await toastSweetAlert({
        mode: 'modalConfirmContent',
        content: (
          <div className="message-container mt-3">
            <div className="row">
              <div className="col-12">
                <h4>Url tienda</h4>
                <input
                  id="shopUrlShopify"
                  className="form-control rounded-2"
                  defaultValue=""
                />
              </div>
              {/* <div className="col-12 mt-3">
                <h4>Url suite</h4>
                <input
                  id="suiteUrlShopify"
                  className="form-control rounded-2"
                  defaultValue=""
                />
              </div> */}
              <div className="col-12 mt-3">
                <h4>Secret token</h4>
                <input
                  id="tokenShopify"
                  className="form-control rounded-2"
                  defaultValue=""
                />
              </div>
              <div className="col-12 mt-3">
                <h4>Versión API</h4>
                <input
                  id="apiVersionShopify"
                  className="form-control rounded-2"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        ),
        validator: validatorFormCreate,
        title: 'Nueva credencial para shopify',
      })

      if (result.isConfirmed) {
        await omnichannelCreateCredentials({
          variables: {
            input: {
              api_version: result.value.apiVersion,
              shop_url: result.value.shopUrl,
              token: result.value.token,
              productive_link: ' ',
            },
            platformId: platformSelected,
          },
        })
        await omnichannelCredentials.refetch()
        return await toastSweetAlert({
          mode: 'ok',
          message: 'Se ha agregado las credencial para shopify',
        })
      }
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const createWebhook = async () => {
    try {
      const validatorFormCreate = () => {
        const callback = document.getElementById('callbackUrl').value
        const topic = document.getElementById('topicName').value
        if (!callback) {
          Swal.showValidationMessage(`Debes especificar la URL del callback`)
        }
        if (!topic) {
          Swal.showValidationMessage(
            `Debes especificar un topico para el webhook`
          )
        }
        return { callback, topic }
      }
      const result = await toastSweetAlert({
        mode: 'modalConfirmContent',
        content: (
          <div className="message-container mt-3">
            <div className="row">
              <div className="col-12">
                <h4>Topic</h4>
                <input
                  id="topicName"
                  className="form-control rounded-2"
                  defaultValue=""
                />
              </div>
              <div className="col-12 mt-3">
                <h4>Callback</h4>
                <input
                  id="callbackUrl"
                  className="form-control rounded-2"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        ),
        validator: validatorFormCreate,
        title: 'Nuevo webhook',
      })

      if (result.isConfirmed) {
        console.log(result)
        await omnichannelCreateWebhook({
          variables: {
            topic: result.value.topic,
            callback: result.value.callback,
            platformId: platformSelected,
          },
        })
        return await toastSweetAlert({
          mode: 'ok',
          message:
            'Se ha enviado la petición para crear un webhook, pronto podras ver el cambio',
        })
      }
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const updateWebhook = async (id) => {
    try {
      const validatorCallback = () => {
        const callback = document.getElementById('callbackUrl').value
        if (!callback) {
          Swal.showValidationMessage(`Debes especificar la URL del callback`)
        }
        return { callback }
      }
      const result = await toastSweetAlert({
        mode: 'modalConfirmContent',
        content: (
          <div className="message-container mt-3">
            <div className="col-8">
              <h4>Nuevo callback</h4>
              <input
                id="callbackUrl"
                className="form-control rounded-2"
                defaultValue=""
              />
            </div>
          </div>
        ),
        validator: validatorCallback,
        title: 'Actualizar webhook',
      })

      if (result.isConfirmed) {
        await omnichannelUpdateWebhook({
          variables: {
            updateOmnichannelWebhookId: parseInt(id),
            callback: result.value.callback,
            platformId: platformSelected,
          },
        })
        return await toastSweetAlert({
          mode: 'ok',
          message:
            'Se ha enviado la petición para actualizar, pronto podras ver el cambio',
        })
      }
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const deleteWebhook = async (id) => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        message: '¿Estas seguro de querer eliminar este webhook?',
        title: 'Eliminar webhook',
      })

      if (result.isConfirmed) {
        await omnichannelDeleteWebhook({
          variables: {
            deleteOmnichannelWebhookId: parseInt(id),
            platformId: platformSelected,
          },
        })
        return await toastSweetAlert({
          mode: 'ok',
          message:
            'Se ha enviado la petición para eliminar, pronto podras ver el cambio',
        })
      }
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const automaticSyncronization = async () => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'info',
        title: '¿Sincronizar shopify con el stock de suite?',
        message:
          'Este proceso sincronizara todo el stock de las diferentes sucursales activas de suite y las modificara en shopify',
      })

      if (result?.isConfirmed) {
        await omnichannelAutomaticSync({
          variables: {
            platformId: parseInt(platformSelected),
          },
        })
        await omnichannelCredentials.refetch()
        return toastSweetAlert({
          mode: 'ok',
          message:
            'Ha inciado el proceso de sincronización. Te avisaremos por correo cuando halla finalizado',
        })
      }
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const helperRunInitialSync = async () => {
    try {
      await omnichannelInitialSync({
        variables: {
          platformId: parseInt(platformSelected),
        },
      })
      await omnichannelCredentials.refetch()
      return toastSweetAlert({
        mode: 'ok',
        message:
          'Ha inciado el proceso de sincronización de la plataforma hacia suite, te avisaremos por correo cuando halla finalizado',
      })
    } catch (error) {
      toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  return (
    <>
      {credentialsOmnichannel['SHOPIFY'] ? (
        <div className="row">
          <div className="col-12 mb-3 mt-3">
            <Box
              title="Credencial activa"
              enableCollapse
              collapsed={true}
              customHeadersButtons={
                <>
                  <span
                    className="btn btn-accept btn-sm"
                    onClick={
                      credentialsOmnichannel['SHOPIFY']?.alreadyInitialSync !== 1
                        ? () =>
                          initialSyncronization(
                            credentialsOmnichannel['SHOPIFY']
                              ?.alreadyInitialSync
                          )
                        : null
                    }
                  >
                    {credentialsOmnichannel['SHOPIFY']?.alreadyInitialSync ===
                      1 ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        &nbsp; Sincronizando...
                      </>
                    ) : credentialsOmnichannel['SHOPIFY']?.alreadyInitialSync ===
                      2 ? (
                      'Volver a sincronizar'
                    ) : (
                      ' Sincronización inicial'
                    )}
                  </span>
                </>
              }
              content={
                <div className="row">
                  <OrderCol
                    mediumColSize={4}
                    title="Plataforma"
                    content={credentialsOmnichannel['SHOPIFY']?.platform}
                  />
                  <OrderCol
                    mediumColSize={4}
                    title="Shop URL"
                    content={credentialsOmnichannel['SHOPIFY']?.shopUrl}
                  />
                  <OrderCol
                    mediumColSize={4}
                    title="Versión API"
                    content={credentialsOmnichannel['SHOPIFY']?.apiVersion}
                  />
                  <OrderCol
                    mediumColSize={4}
                    title="Secret Token"
                    content={`**********************${credentialsOmnichannel["SHOPIFY"] && credentialsOmnichannel[
                      'SHOPIFY'
                    ]?.token?.substr(
                      credentialsOmnichannel['SHOPIFY']?.token.length - 5
                    )}`}
                  />
                  <OrderCol
                    mediumColSize={4}
                    title="Suite URL"
                    content={credentialsOmnichannel['SHOPIFY']?.suiteUrl}
                  />
                </div>
              }
            />
          </div>
          <div className="col-12">
            <LayoutTable
              title="Webhooks activos"
              loadingData={omnichannelWebhooks.loading}
              data={webhooksOmnichannel.rows}
              totalCount={webhooksOmnichannel.count}
              paginationServer={false}
              noDataComponent="No se encontrarón webhooks"
              searchByProperty="topic"
              hideDetails
              headersButtons={false}
              headerButtonsNoPermissions={
                <>
                  <span
                    className="btn btn-accept btn-sm"
                    onClick={createWebhook}
                  >
                    Nuevo webhook
                  </span>
                </>
              }
              extraHeaderButtonFunction={createWebhook}
              extraHeaderButtonFunctionText="Nuevo webhook"
              editText="Actualizar callback"
              actionBtnEdit={updateWebhook}
              actionBtnErase={deleteWebhook}
            />
          </div>
          <div className="col-12">
            <LayoutTable
              title="Productos en shopify"
              loadingData={omnichannelProducts.loading}
              data={productsOmnichannel.rows}
              totalCount={productsOmnichannel.count}
              pagePagination={productsPagePagination}
              setPagePagination={setProductsPagePagination}
              hideAddNew
              headersButtons={
                credentialsOmnichannel['SHOPIFY'].alreadyInitialSync === 2
                  ? true
                  : false
              }
              importBtnText={
                <>
                  <i className="fas fa-file-import"></i> &nbsp;Sincronizar con
                  archivo{' '}
                </>
              }
              refetchFunctionQueries={async () =>
                await omnichannelCredentials.refetch()
              }
              import
              messageSuccesImport="Su archivo se esta procesando. Esta operación tardara un momento. Te avisaremos por correo cuando finalice o vuelve en un momento para ver los cambios"
              templateDataImport={[
                {
                  sku: '',
                  title: '',
                  price: '',
                  promo_price: '',
                  'CORPORATIVO GUADALAJARA': '',
                  Andares: '',
                  Country: '',
                  'López Mateos': '',
                  'Niños Héroes': '',
                  Terranova: '',
                  'Tienda GDL': '',
                  'Valle Real': '',
                },
              ]}
              importQuery={OMNICHANNEL_MANUAL_SYNC}
              importCustomVariables={[
                {
                  platformId: parseInt(platformSelected),
                },
              ]}
              headerButtonsNoPermissions={
                credentialsOmnichannel['SHOPIFY'].alreadyInitialSync !== 2 ? (
                  false
                ) : (
                  <>
                    <span
                      className="btn btn-accept btn-sm"
                      onClick={() => automaticSyncronization()}
                    >
                      Sincronización automatica
                    </span>
                  </>
                )
              }
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={`row one-centered ${loading ? 'processing-file' : ''}`}
          >
            <div className="col-12 text-center">
              <p>Para comenzar agrege sus credendiales de shopify</p>
              <span
                className="btn btn-accept btn-lg"
                onClick={createCredentials}
              >
                Agregar credenciales
              </span>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ShopifyChannel
