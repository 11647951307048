import { gql } from '@apollo/client'

export const GET_SAP_WAREHOUSES = gql`
  query GetSapWarehouses($limit: Int, $offset: Int) {
    getSapWarehouses(limit: $limit, offset: $offset) {
      id
      warehouse_code
      warehouse_name
    }
  }
`

export const GET_SAP_WAREHOUSES_SCHEDULE = gql`
  query GetSapWarehousesSchedule($limit: Int, $offset: Int) {
    getSapWarehousesSchedule(limit: $limit, offset: $offset) {
      id
      warehouse_code
      warehouse_name
    }
  }
`

export const GET_SAP_WAREHOUSES_MUTATION = gql`
  mutation GetSapWarehouses {
    getSapWarehouses {
      id
      warehouse_code
      warehouse_name
    }
  }
`

export const GET_SAP_WAREHOUSES_TRANSFERS = gql`
  mutation GetSapWarehousesTransfers {
    getSapWarehousesTransfers {
      id
      store_id
      name
      from_whs_code
      to_whs_code
      whs_transfer
      branch_code
    }
  }
`
export const GET_ONE_SAP_WAREHAUSE = gql`
query GetOneSapWarehouses($getOneSapWarehousesId: Int!) {
  getOneSapWarehouses(id: $getOneSapWarehousesId) {
    id
    warehouse_code
    warehouse_name
    street
    block
    zip_code
    city
    county
    country
    state
    location
    street_num
  }
}`

export const CLOSE_SAP_ORDER = gql`
mutation CloseOrder($sapPurchaesOrdersId: Int!) {
  closeOrder(sapPurchaesOrdersId: $sapPurchaesOrdersId)
}
`