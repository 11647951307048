import { gql } from '@apollo/client'

export const GET_ALL_OMNICHANNEL_WEBHOOKS = gql`
  query GetAllOmnichannelWebhooks($platformId: Int!) {
    getAllOmnichannelWebhooks(platformId: $platformId) {
      count
      rows {
        id
        client_id
        platform_id
        ecommerce_webhook_id
        callback
        topic_name
        EcommerceWebhooksPlatform {
          id
          name
        }
      }
    }
  }
`
export const CREATE_OMNICHANNEL_WEBHOOK = gql`
  mutation CreateOmnichannelWebhook(
    $topic: String!
    $callback: String!
    $platformId: Int!
  ) {
    createOmnichannelWebhook(
      topic: $topic
      callback: $callback
      platformId: $platformId
    )
  }
`

export const UPDATE_OMNICHANNEL_WEBHOOK = gql`
  mutation UpdateOmnichannelWebhook(
    $updateOmnichannelWebhookId: Int!
    $callback: String!
    $platformId: Int!
  ) {
    updateOmnichannelWebhook(
      id: $updateOmnichannelWebhookId
      callback: $callback
      platformId: $platformId
    )
  }
`

export const DELETE_OMNICHANNEL_WEBHOOK = gql`
  mutation DeleteOmnichannelWebhook(
    $deleteOmnichannelWebhookId: Int!
    $platformId: Int!
  ) {
    deleteOmnichannelWebhook(
      id: $deleteOmnichannelWebhookId
      platformId: $platformId
    )
  }
`
