import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import InputController from '../../Global/InputController'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import Box from '../../Global/Box'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ProcessReturn } from './ProcessReturn'
import { marketing_documents_and_receipts } from '../../Helpers/StatusesLanguages'
import ToogleDetailReturnsReceipt from './ToogleDetailReturnsReceipt'
// apollo
import {
  FIND_BUSINESS_PARTNERS,
  GET_RETURN_DOCUMENT,
  GET_SBO_RETURN_DOCUMENT,
  GET_SBO_RETURN_DOCUMENT_LINES,
  CREATE_RETURN_REQUEST_DOCUMENT,
  ADD_SBO_RETURN_REQUEST,
  GET_OPTIONS_CARDS_RETURN,
  DELETE_DETAIL_RETURN_RECEIPT,
  UPDATE_RETURN_REQUEST,
} from '../../../graphql/Documents/Inventories/Returns'

export const ReturnRequestNew = () => {
  const { id: _id, show } = useParams()
  const history = useHistory()
  const schema = yup
    .object()
    .shape({
      document_type: yup.string().required('Este campo es obligatorio'),
      inputCardCode: yup.string().required('Este campo es obligatorio'),
      inputCardName: yup.string().required('Este campo es obligatorio'),
      inputDocNum: yup.string().required('Este campo es obligatorio'),
    })
    .required()
  const {
    methods,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(schema) })
  const documentStatus = marketing_documents_and_receipts
  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [canFindDocument, setCanFindDocument] = useState(false)
  const [canCollectCard, setCollectCard] = useState(false)
  const [canProcess, setCanProcess] = useState(false)
  const [listOfLines, setListOfLines] = useState([])
  const [listOfSboLines, setListOfSboLines] = useState([])
  const [document, setDocument] = useState([])
  const [businessPartnerId, setBusinessPartnerId] = useState([])
  const [content, setContent] = useState([])
  const [listOfCards, setListOfCards] = useState([
    {
      label: '0',
      value: '- -',
    },
  ])
  const [isBeingProcessed, setProcess] = useState(false)
  const [btnSubmtText, setBtnSubmitText] = useState('Crear')

  const [findBusinessPartners] = useLazyQuery(FIND_BUSINESS_PARTNERS)
  const [getSboDocumentLines] = useMutation(GET_SBO_RETURN_DOCUMENT_LINES)
  const [getSboDocument] = useMutation(GET_SBO_RETURN_DOCUMENT)
  const [createReturn] = useMutation(CREATE_RETURN_REQUEST_DOCUMENT)
  const [addSboReturnRequest] = useMutation(ADD_SBO_RETURN_REQUEST)
  const [getOptionsCardsReturn] = useLazyQuery(GET_OPTIONS_CARDS_RETURN)
  const [deleteDetailReturnsReceipt] = useMutation(DELETE_DETAIL_RETURN_RECEIPT)
  const [updateReturRequest] = useMutation(UPDATE_RETURN_REQUEST)

  const {
    data: dataReturn,
    loading: loadingReturn,
    error: errorReturn,
    refetch: refetchReturn,
  } = useQuery(GET_RETURN_DOCUMENT, {
    variables: {
      getOneReturnDocumentId: parseInt(_id),
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(async () => {
    if (_id) {
      if (!loadingReturn && !errorReturn) {
        const data = dataReturn.getOneReturnDocument

        setValue('document_type', data.object_type)
        setValue('inputCardCode', data.card_code)
        setValue('inputCardName', data.card_name)
        setValue('inputDocNum', data.document_number)
        setValue('inputMemo', data.comments)
        setValue('inputStatus', documentStatus[data.document_status])
        setDocument(data)
        setIsDisabled(true)
        setCanFindDocument(true)

        if (data.document_status == 'O' && show) {
          setCanProcess(true)
        }
        let list = []
        if (show) {
          setBtnSubmitText('Finalizar')
          data.Rows.map((line) => {
            if (line.line_status != 'L') {
              let btnDelete = (
                <button
                  id={line.id + '-deleteButton'}
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => onDeleteScan(line['id'])}
                >
                  <i className="fas fas fa-trash"></i>
                </button>
              )
              return list.push({
                ['id']: line.id,
                ['#']: line.line_number,
                ['SKU']: line.item_code,
                ['Descripción']: line.item_name,
                ['Cantidad']: line.quantity,
                ['Cantidad Pendiente']: line.open_quantity,
                ['Status']: line.line_status,
                ['']: data.document_status == 'O' ? btnDelete : null,
                ['Orden Compra']: line.base_number,
                ['DetailRow']: data.Receipts.filter(
                  (element) =>
                    element.return_document_id == data.id &&
                    element.return_row_id == line.id
                ),
              })
            }
          })
        } else {
          setBtnSubmitText('Actualizar')
          setCanProcess(false)

          // Get document Content
          const documentLines = await getSboDocumentLines({
            variables: {
              documentNumber: parseInt(data.document_number),
              documentType: data.object_type,
            },
            fetchPolicy: 'no-cache',
          })

          setListOfSboLines(documentLines.data.getReturnLinesFromSBO)
          documentLines.data.getReturnLinesFromSBO.map((line) => {
            const onSuiteRows = data.Rows.some(
              (element) => element.line_number == line.line_number
            )
            let sboRow = []
            if (onSuiteRows) {
              sboRow = data.Rows.find(
                (element) => element.line_number == line.line_number
              )
              setContent((old) => [...old, line])
            }
            let btnCheck = (
              <input
                id={`${line.document_entry}-${line.line_number}-checkBoxLay`}
                type="checkbox"
                onClick={(e) =>
                  checkSelectedItem(e, onSuiteRows ? sboRow : line)
                }
                data-item={JSON.stringify(line)}
                defaultChecked={onSuiteRows}
                disabled={data.document_status == 'C'}
              />
            )

            return list.push({
              ['id']: onSuiteRows ? sboRow.id : null,
              ['#']: onSuiteRows ? sboRow.line_number : line.line_number,
              ['SKU']: onSuiteRows ? sboRow.item_code : line.item_code,
              ['Descripcion']: onSuiteRows ? sboRow.item_name : line.item_name,
              ['Cantidad']: onSuiteRows ? sboRow.quantity : line.quantity,
              ['Cantidad Pendiente']: onSuiteRows
                ? sboRow.open_quantity
                : line.open_quantity,
              ['Status']: onSuiteRows ? sboRow.line_status : line.line_status,
              ['Seleccionar']: btnCheck,
              ['DetailRow']: data.Receipts.filter(
                (element) =>
                  element.return_document_id == data.id &&
                  element.return_row_id == line.id
              ),
            })
          })
        }

        setListOfLines(list)
      }
    }
  }, [_id, dataReturn, loadingReturn, errorReturn])

  //Show statics documents type for now
  const [documentTypes, setDocumentTypes] = useState([
    // {
    //     label: "Devolución de cliente",
    //     value: '17',
    //     disabled: true
    // },
    {
      label: 'Devolución de mercancias',
      value: '20',
    },
    {
      label: 'Devolución por traspaso',
      value: '67',
    },
  ])
  const onFindBusinessPartner = async (value) => {
    try {
      const result = await findBusinessPartners({
        variables: {
          searchQuery: value,
        },
        fetchPolicy: 'no-cache',
      })

      if (result.data.findSapBusinessPartner.count > 0) {
        const businessPartner = result.data.findSapBusinessPartner.rows[0]
        setValue('inputCardCode', businessPartner.card_code)
        setValue('inputCardName', businessPartner.card_name)
        setBusinessPartnerId(businessPartner.id)
      }
      setCollectCard(false)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const onFindDocument = async (value) => {
    try {
      setLoading(true)
      // Get document
      const result = await getSboDocument({
        variables: {
          documentNumber: value,
          businessPartnerId: businessPartnerId,
        },
      })
      if (result.data) {
        setDocument(result.data.getReturnFromSBO)
        setValue('inputMemo', result.data.getReturnFromSBO.comments)
      } else {
        return toastSweetAlert({
          mode: 'error',
          message: 'El documento indicado existe',
        })
      }

      // Get document Content
      const documentLines = await getSboDocumentLines({
        variables: {
          documentNumber: parseInt(
            result.data.getReturnFromSBO.document_number
          ),
          documentType: result.data.getReturnFromSBO.object_type,
        },
        fetchPolicy: 'no-cache',
      })

      if (documentLines.data.getReturnLinesFromSBO) {
        let list = []
        documentLines.data.getReturnLinesFromSBO.map((line) => {
          let btnCheck = (
            <input
              id={line.id + '-checkBoxLay'}
              type="checkbox"
              onClick={(e) => checkSelectedItem(e, line)}
              data-item={JSON.stringify(line)}
            />
          )

          return list.push({
            ['#']: line.line_number,
            ['SKU']: line.item_code,
            ['Descripcion']: line.item_name,
            ['Cantidad']: line.quantity,
            ['Almacen Origen']: line.fromWhsCod,
            ['Orden Compra']: line.base_entry,
            ['Seleccionar']: btnCheck,
            ['DetailRow']: [],
          })
        })
        setListOfLines(list)
      } else {
        return toastSweetAlert({
          mode: 'error',
          message: 'El documento indicado no contiene partidas',
        })
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    } finally {
      setLoading(false)
    }
  }
  const onDocumentType = async (e) => {
    try {
      const result = await getOptionsCardsReturn({
        variables: {
          documentType: e,
        },
      })

      const options = []
      result.data.getOptionsCardsReturn.map((card) => {
        return options.push({
          ['value']: card.card_code,
          ['label']: `${card.card_code} - ${card.card_name}`,
        })
      })

      setListOfCards(options)
      // if (e == '67') {
      setCollectCard(true)
      // } else {
      //     setCollectCard(false)

      // }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const onCardCode = async (e) => {
    try {
      setValue('inputCardCode', e)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const checkSelectedItem = (e, itemSel) => {
    const check = e.target.checked ? true : false
    const onSuite = itemSel.id ? true : false
    if (itemSel.open_quantity != itemSel.quantity && onSuite) {
      e.target.checked = true
      return toastSweetAlert({
        mode: 'error',
        message: `La partida ${itemSel.line_number} - ${itemSel.item_code} ${itemSel.item_name} ya inicio el proceso de devolución no se puede desmarcar`,
      })
    }

    if (check) {
      setContent((old) => [...old, itemSel])
    } else {
      setContent((old) =>
        old.filter((item) => item.line_number !== itemSel.line_number)
      )
    }
  }
  const ExpandableRows = ({ data }) => {
    return (
      <div>
        <div>
          <ToogleDetailReturnsReceipt ReturnDocumentRow={data} />
        </div>
      </div>
    )
  }
  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const cardCode = getValues('inputCardCode')
      const cardName = getValues('inputCardName')

      if (!_id) {
        // Create
        const input = {
          document_entry: document.document_entry,
          document_number: document.document_number,
          document_type: document.document_type,
          canceled: document.canceled,
          document_status: document.document_status,
          object_type: document.object_type,
          document_date: document.document_date,
          document_due_date: document.document_due_date,
          comments: document.comments,
          series: document.series,
          card_code: cardCode,
          card_name: cardName,
        }
        const details = []
        content.map((line) => {
          details.push({
            line_number: line.line_number,
            base_entry: line.base_entry,
            base_number: line.base_number,
            base_type: line.base_type,
            line_status: line.line_status,
            item_id: line.item_id,
            item_code: line.item_code,
            item_name: line.item_name,
            quantity: line.quantity,
            open_quantity: line.open_quantity,
            warehouses_code: line.warehouses_code,
            tree_type: line.tree_type,
            codebars: line.codebars,
            tax_code: line.tax_code,
          })
        })
        input.Content = details

        const resultID = await createReturn({
          variables: { input },
        })

        if (resultID.data.createReturRequest > 0) {
          setLoading(false)
          const response = await toastSweetAlert({
            mode: 'modalConfirmCancel',
            title: `Solicitud de devolución ${resultID.data.createReturRequest} registrada correctamente`,
            message: `¿Desea comenzar el escaneo de la devolución actual?`,
          })

          if (response.isConfirmed) {
            setLoading(false)
            history.push(
              `/Returns/ReturnRequest/details/${resultID.data.createReturRequest}/show`
            )
          } else {
            setLoading(false)
            history.push(`/Returns/ReturnRequest`)
          }
        }
      } else {
        if (show) {
          // Process
          try {
            if (_id) {
              let canBeFinished = true
              setLoading(true)
              if (
                listOfLines.some((element) => element['Cantidad Pendiente'] > 0)
              ) {
                setLoading(false)
                const response = await toastSweetAlert({
                  mode: 'modalConfirmCancel',
                  title: 'Partidas abiertas',
                  message: `¿Hay artículos que no han sido escaneados, quieres continuar con la finalización de la devolución?`,
                })
                canBeFinished = response.isConfirmed
              }

              if (canBeFinished) {
                await addSboReturnRequest({
                  variables: { addSboReturnRequestId: parseInt(_id) },
                })
                setLoading(false)
                toastSweetAlert({
                  mode: 'ok',
                  message: 'Devolucion finalizada correctamente',
                })
                history.push(`/Returns/ReturnRequest`)
              }
            }
          } catch (error) {
            setLoading(false)
            return toastSweetAlert({
              mode: 'error',
              message: error.message,
            })
          } finally {
            setLoading(false)
          }
        } else {
          // Update
          try {
            if (_id) {
              setLoading(true)

              const input = {
                id: parseInt(_id),
                canceled: 'N',
                document_status: 'O',
                comments: getValues('inputMemo'),
              }

              const details = []
              listOfSboLines.map((line) => {
                const selected = content.some(
                  (element) => element.line_number == line.line_number
                )
                const onSuite = document.Rows.some(
                  (element) => element.line_number == line.line_number
                )

                details.push({
                  id: onSuite
                    ? document.Rows.find(
                        (element) => element.line_number == line.line_number
                      ).id
                    : 0,
                  line_number: line.line_number,
                  base_entry: line.base_entry,
                  base_number: line.base_number,
                  base_type: line.base_type,
                  line_status: line.line_status,
                  item_id: line.item_id,
                  item_code: line.item_code,
                  item_name: line.item_name,
                  quantity: line.quantity,
                  open_quantity: line.open_quantity,
                  warehouses_code: line.warehouses_code,
                  tree_type: line.tree_type,
                  codebars: line.codebars,
                  tax_code: line.tax_code,
                  selected: selected,
                })
              })
              input.Content = details

              await updateReturRequest({
                variables: { updateReturRequestId: parseInt(_id), input },
              })
              setLoading(false)
              toastSweetAlert({
                mode: 'ok',
                message: 'Devolución registrada correctamente',
              })
              history.push(`/Returns/ReturnRequest`)
            }
          } catch (error) {
            setLoading(false)
          } finally {
            setLoading(false)
          }
        }
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const onDeleteScan = async (data) => {
    const result = await toastSweetAlert({
      mode: 'modalConfirmCancel',
      title: `Borrar escaneado de la partida  ${data}`,
      message: `¿El borrado de lo escaneado es permanente, esta seguro de continuar?`,
    })
    if (result.isConfirmed) {
      setLoading(true)
      const resultID = await deleteDetailReturnsReceipt({
        variables: { deleteDetailReturnsReceiptId: data },
        refetchQueries: refetchReturn,
      })
      setLoading(false)
    }
    return setLoading(false)
  }
  const onToFinalize = async (data) => {
    if (document.document_status == 'O') {
      setProcess(true)
    } else {
      return toastSweetAlert({
        mode: 'ok',
        message: 'El documento ya esta cerrado',
      })
    }
  }
  return (
    <>
      {/* <ContentHeader
        title="Devolución de mercancías"
        breadcrumb="Inventario"
        windowTitle="Devoluciones"
      /> */}
      <div className={`${loading ? 'processing-file' : ''}`}>
        {isBeingProcessed && (
          <ProcessReturn
            _returnDocument={document}
            _returnContent={document.Rows}
            _setProcess={setProcess}
            _refetchReturn={refetchReturn}
          />
        )}
        {isBeingProcessed == false && (
          <Box
            title={`Solicitud de devolucion`}
            btnRedPath="/Returns/ReturnRequest"
            btnRedTxt="Regresar"
            btnSubmit={canProcess || !show ? handleSubmit(onSubmit) : false}
            btnSubmitText={btnSubmtText}
            btnState={loading}
            btnFunction={canProcess ? () => onToFinalize() : null}
            btnFunctionTitle={'Comenzar escaneo'}
            errors={errors}
            btn
            content={
              <>
                <FormProvider {...methods}>
                  <form className="p-0" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-12 col-sm-12 col-md-12">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-12 col-md-3">
                            <InputController
                              label="Tipo de devolucion"
                              name="document_type"
                              type="number"
                              inputType="choosen"
                              control={control}
                              options={documentTypes}
                              disabled={isDisabled}
                              changeAction={(e) => onDocumentType(e.value)}
                            />
                          </div>
                          <div className="col-sm-12 col-md-3 offset-md-3">
                            <InputController
                              label="Estatus"
                              type="text"
                              inputType="text"
                              name="inputStatus"
                              id="inputStatus"
                              placeholder="Estatus del documento"
                              control={control}
                              disabled={true}
                            />
                          </div>
                        </div>

                        {canCollectCard && (
                          <div className="col-sm-12 col-md-12">
                            <InputController
                              label="Codigo de socio de negocio"
                              name="choosenCardCode"
                              type="text"
                              inputType="choosen"
                              placeholder="Seleccionar codigo socio de negocio"
                              control={control}
                              options={listOfCards}
                              disabled={isDisabled}
                              changeAction={(e) =>
                                onFindBusinessPartner(e.value)
                              }
                            />
                          </div>
                        )}
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <InputController
                              label="Codigo de socio de negocio"
                              type="text"
                              inputType="text"
                              name="inputCardCode"
                              id="inputCardCode"
                              placeholder="Buscar por codigo socio de negocio"
                              control={control}
                              disabled={true}
                              changeAction={(e) => onCardCode(e.value)}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  onFindBusinessPartner(event.target.value)
                                }
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <InputController
                              label="Nombre de socio de negocio"
                              type="text"
                              inputType="text"
                              name="inputCardName"
                              id="inputCardName"
                              placeholder="Buscar por Nombre socio de negocio"
                              disabled={true}
                              control={control}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <InputController
                              label="Número de documento"
                              type="text"
                              name="inputDocNum"
                              id="inputDocNum"
                              placeholder="Buscar por Número de documento en SAP B1"
                              control={control}
                              disabled={canFindDocument}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  onFindDocument(event.target.value)
                                }
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <InputController
                              label="Comentarios"
                              type="textarea"
                              inputType="textarea"
                              name="inputMemo"
                              id="inputMemo"
                              placeholder="Comentarios"
                              disabled={true}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="row">
                      <div className="mb-12 col-sm-12 col-md-12">
                        <LayoutTable
                          title="Articulos"
                          data={listOfLines}
                          hideId={true}
                          hideFilterBar={true}
                          hideActions={true}
                          hideAddNew={true}
                          hideDetails={true}
                          loadingData={loading}
                          expandableRows
                          expandableRowsComponent={ExpandableRows}
                        />
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </>
            }
          ></Box>
        )}
      </div>
    </>
  )
}

export default ReturnRequestNew
