import React, { useEffect, useState } from 'react'

const BarsChart = ({ data }) => {
  const styles = {
    bgBar: {
      background: '#EFEFEF',
      height: 10,
    },
  }

  const [rows, setRows] = useState([])

  /* SUBCOMPONENTS */
  const Row = ({ title, total, num }) => {
    let percentage = 0
    if (total > 0) {
      percentage = parseInt((num * 100) / total)
    }

    return (
      <div className="w-100 mb-2">
        <div className="row d-flex align-items-center">
          <div className="col-11">
            <div className="w-100" style={styles.bgBar}>
              <div
                style={{
                  height: 10,
                  background: '#2CCB6C',
                  width: percentage + '%',
                }}
              ></div>
            </div>
          </div>
          <div className="col-1 pb-1">
            <span className="badge bg-danger rounded-0 p-1 percentage">
              <span style={{ color: '#fff' }}>{percentage}%</span>
            </span>
          </div>
        </div>
        <div className="row" style={{ lineHeight: '0.8em' }}>
          <div className="col-12">
            <p className="mb-0 pb-0">
              <small>
                <strong>{title}</strong>
              </small>
            </p>
            <p>
              <small>
                {num} ({percentage}%)
              </small>
            </p>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (data && data.length > 0) {
      //console.log('data => ', data)
      let arr = []

      data.forEach((item) => {
        arr.push(<Row title={item.title} total={item.total} num={item.num} />)
      })

      setRows(arr)
    }
  }, [data])

  return <>{rows}</>
}
export default BarsChart
