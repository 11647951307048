import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import Box from '../../../Global/Box'
import LayoutTable from '../../../Global/LayoutTable'
import {
  GET_INVENTORY_ACTIVITY_INFO_BY_ID,
  // GET_TFI_PROGRESSBAR,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesSchedule'

import {
  GET_INVENTORY_ACTIVITY_REPORT_EXCEL,
  GET_INVENTORY_ACTIVITY_FINAL_REPORT,
  SYNC_TFI_SAP,
  UPDATE_SUITE_ITEM_POSITIONS,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesFinalReport'
import _ from 'lodash'
import HelperGenerateExcel from '../../../Helpers/HelperGenerateExcel'
import InventorySyncSapErrorsModal from '../InventorySyncSapErrorsModal'

const InventoryActivityReportCycleCountSku = ({ dataAssignment }) => {
  const { id } = useParams() //scheduleId
  const [loadingBtn, setLoading] = useState(false)
  const [statusSync, setStatusSync] = useState(null)
  const [open, setOpen] = useState(false)
  const [progressbarPercentage, setProgressBarPercentage] = useState(0)

  const [progressbarTitle, setProgressBarTitle] = useState(
    'Productos Recibidos'
  )
  const refModal = useRef()

  const [dataAssign, setDataAssign] = useState({
    warehouse: null,
    date: null,
  })

  const [dataReport, setDataReport] = useState({ items: [], total: 0 })
  const [downloadDataReport, setDownloadDataReport] = useState([])

  const [pagePagination, setPagePagination] = useState({
    limit: 30,
    offset: 0,
    searchQuery: null,
  })

  /* USEQUERY & USEMUTATION */
  const assignment = useQuery(GET_INVENTORY_ACTIVITY_INFO_BY_ID, {
    variables: {
      scheduleId: parseInt(id) > 0 ? parseInt(id) : 0,
    },
  })
  // const progressBar = useQuery(GET_TFI_PROGRESSBAR, {
  //   variables: {
  //     idCalendar: +id,
  //   },
  // })

  const report = useQuery(GET_INVENTORY_ACTIVITY_FINAL_REPORT, {
    variables: {
      scheduleId: parseInt(id) > 0 ? parseInt(id) : 0,
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })

  const [updateSuiteItems] = useMutation(UPDATE_SUITE_ITEM_POSITIONS)
  const [downloadReport] = useMutation(GET_INVENTORY_ACTIVITY_REPORT_EXCEL)
  const [syncSap] = useMutation(SYNC_TFI_SAP)
  const [disabledButton, setDisabledButton] = useState(false)
  const history = useHistory()

  /* USEEFFECT */
  useEffect(() => {
    if (!assignment?.loading) {
      if (assignment.error) {
        return toastSweetAlert({
          mode: 'error',
          message: assignment.error.message,
        })
      }

      let data = assignment.data.GetInventoryActivityInfoById
      setStatusSync(data?.status_sync)

      setDataAssign({
        warehouse: data?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
        date: moment(data?.start_date).format('lll'),
        ...data,
      })
    }
  }, [assignment?.loading, assignment?.data, assignment?.error, id])

  useEffect(() => {
    if (!report?.loading) {
      if (report?.error) {
        return toastSweetAlert({
          mode: 'error',
          message: report?.error.message,
        })
      }

      const list = report?.data?.getInventoryActivityFinalReport?.rows.map(
        (item) => {
          return {
            id: item?.id,
            sku: item?.InventoryActivityFinalReportSapItems?.item_code,
            Nombre: item?.InventoryActivityFinalReportSapItems?.item_name,
            'Existencia en sap': item?.on_hand,
            'Cantidad definitiva': item?.counted,
            Diferencia: item?.deviation,
            'Diferencia en $':
              item?.deviation_money !== -10
                ? `$ ${(item?.deviation_money).toFixed(2)}`
                : 'Precio de producto no encontrado',
          }
        }
      )

      setDataReport({
        items: list,
        total: report?.data?.getInventoryActivityFinalReport?.count,
        quantityDeviation:
          report?.data?.getInventoryActivityFinalReport?.totalQuantityDeviation,
        totalDeviation:
          report?.data?.getInventoryActivityFinalReport?.totalDeviation,
      })
    }
  }, [report.loading, report.data, report.error, pagePagination, id])

  /*TFI PROGFRESS BAR*/

  // useEffect(() => {
  //   if (!progressBar?.loading) {
  //     if (progressBar.error) {
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: progressBar.error.message,
  //       })
  //     }
  //     try {
  //       setProgressBarPercentage(
  //         progressBar?.data?.getTfiProgressbar?.percentage
  //       )
  //       setProgressBarTitle(
  //         `Productos recibidos:  ${progressBar?.data?.getTfiProgressbar?.completed} / ${progressBar?.data?.getTfiProgressbar?.total}  `
  //       )
  //       setStatusSync(progressBar?.data?.getTfiProgressbar?.status_sync)
  //     } catch (error) {
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: error.message,
  //       })
  //     }
  //   }
  // }, [progressBar.loading, progressBar.data, progressBar.error])

  // useEffect(() => {
  //   try {
  //     const interval = setInterval(async () => {
  //       // await progressBar.refetch()
  //       if (id) {
  //         await progressBar.refetch({
  //           idCalendar: +id,
  //         })
  //       }
  //     }, 5000)

  //     return () => {
  //       clearInterval(interval)
  //     }
  //   } catch (error) {
  //     return toastSweetAlert({
  //       mode: 'error',
  //       message: error.message,
  //     })
  //   }
  // }, [statusSync, id])

  /* FUNCTIONS */
  const handleUpdateSuite = async () => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        title: 'Se va a actualizar el inventario de la suite',
        message: (
          <>
            <div>
              <h5 className="mb-3 mx-auto">
                Antes de continuar toma en cuenta lo siguiente:
              </h5>
              <div class="list-group">
                <div class="list-group-item list-group-item-action align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      <b>Verifica la información</b>
                    </h5>
                  </div>
                  <p class="mb-1">
                    Revisa el detalle del reporte generado en la suite.
                  </p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      <b>Asegurate de estar en el reporte correcto</b>
                    </h5>
                  </div>
                  <p class="mb-1">
                    Confirma que estas realmente en el reporte de la TFI del
                    almacen que deseas sincronizar.
                  </p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      <b>Esta acción solo se puede realizar una vez</b>
                    </h5>
                  </div>
                  <p class="mb-1">
                    Una vez que decidas sincronizar el inventario, no se podra
                    revertir.
                  </p>
                </div>
              </div>
              <h5 className="mt-3">
                Tomando en cuenta lo siguiente, ¿Deseas continuar con la
                sincronización?
              </h5>
            </div>
          </>
        ),
      })
      if (result.isConfirmed) {
        setLoading(true)
        toastSweetAlert({
          mode: 'loading',
          message: 'Actualizando inventario. Espere por favor.',
        })
        await updateSuiteItems({
          variables: {
            scheduleId: parseInt(id),
          },
        })
        await assignment.refetch()
        setLoading(false)

        return toastSweetAlert({
          mode: 'ok',
          message:
            'Se han actualizado correctamente las posiciones en la suite',
        })
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleSyncSap = async () => {
    try {
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        title: '¿Deseas mandar la solicitud de de sincronización a SAP?',
        message: (
          <>
            <div>
              <h5 className="mb-3 mx-auto">
                Antes de continuar toma en cuenta lo siguiente:
              </h5>
              <div class="list-group">
                <div class="list-group-item list-group-item-action align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      <b>Verifica la información</b>
                    </h5>
                  </div>
                  <p class="mb-1">
                    Revisa el detalle del reporte generado en la suite.
                  </p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      <b>Asegurate de estar en el reporte correcto</b>
                    </h5>
                  </div>
                  <p class="mb-1">
                    Confirma que estas realmente en el reporte de la TFI del
                    almacen que deseas sincronizar.
                  </p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                      <b>Esta acción solo se puede realizar una vez</b>
                    </h5>
                  </div>
                  <p class="mb-1">
                    Una vez que decidas sincronizar el inventario, no se podra
                    revertir.
                  </p>
                </div>
              </div>
              <h5 className="mt-3">
                Tomando en cuenta lo siguiente, ¿Deseas continuar con la
                sincronización?
              </h5>
            </div>
          </>
        ),
      })
      if (result.isConfirmed) {
        setLoading(true)

        await syncSap({
          variables: {
            reportId: parseInt(id),
          },
        })
        setStatusSync(2)
        await assignment.refetch()
        setLoading(false)

        return toastSweetAlert({
          mode: 'ok',
          message:
            'Se ha enviado la solicitud a SAP para sincronización de inventario',
        })
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleSyncErrors = async () => {
    setOpen(true)
    refModal.current.click()
  }

  const getStatus = (_status) => {
    let status = null

    switch (_status) {
      case 1:
        status = 'CORRECTO'
        break

      case 0:
        status = 'CON DESVIACIÓN'
        break

      case 2:
        status = 'PENDIENTE'
        break

      case 3:
        status = 'DESV. CORREGIDA'
        break
      default:
        status = ''
        break
    }
    return status
  }

  const handleCreateExcel = async () => {
    try {
      setLoading(true)
      toastSweetAlert({
        mode: 'loading',
        message: 'Generando reportes. Espere por favor.',
      })
      const { data } = await downloadReport({
        variables: {
          scheduleId: parseInt(id),
        },
      })

      const listSimple = data.getInventoryActivityReportExcel.simple.map(
        (item) => {
          return {
            sku: item?.InventoryActivityFinalReportSapItems?.item_code,
            Nombre: item?.InventoryActivityFinalReportSapItems?.item_name,
            'Existencia en sap': item?.on_hand,
            'Cantidad definitiva': item?.counted,
            Diferencia: item?.deviation,
            'Diferencia en $': `$ ${(item?.deviation_money).toFixed(2)}`,
          }
        }
      )
      const listDetailed = []
      data.getInventoryActivityReportExcel.detailed.map((item) => {
        const data = item[0]
        console.log(data)
        for (const item of data.InventoryActivitiesLabelsCounts) {
          listDetailed.push({
            sku: data.element_assigned,
            Nombre: data.InventoryActivitiesLabelSKU.item_name,
            Ubicación: item.element_found,
            Contador: data.InventoryActivitieLabelAccountant.full_name,
            Contado: item.quantity,
            Expiración: item.expiration ? item.expiration : 'N/A',
            Añada: item.vintage ? item.vintage : 'N/A',
          })
        }
      })

      setDownloadDataReport([
        {
          bookName: 'Reporte simple',
          data: listSimple,
          columnsWidth: [15, 50, 25, 25, 25, 25, 25],
        },
        {
          bookName: 'Reporte detallado',
          data: listDetailed,
          columnsWidth: [15, 50, 15, 15, 15, 25, 25, 15, 25, 25],
        },
      ])
      document.getElementById('ExportExcel').click()

      setLoading(false)
      return toastSweetAlert({
        mode: 'ok',
        message: 'Se ha generado el reporte correctamente',
      })
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  /* MODES */

  return (
    <>
      <HelperGenerateExcel
        id="ExportExcel"
        excelData={downloadDataReport}
        fileName={'Reporte conteo TFI'}
      />
      <div className={`${loadingBtn ? 'processing-file' : ''}`}>
        <Box
          // title="TFI seleccionado"
          headerLeft={
            <>
              <div className="text-right">
                <span
                  className="btn btn-accept mr-2"
                  onClick={handleCreateExcel}
                >
                  <i className="fa fa-download"></i> Descargar reporte
                </span>

                {statusSync === 0 && (
                  <span className="btn btn-accept" onClick={handleUpdateSuite}>
                    <i className="fa fa-sync"></i> Sincronizar con suite
                  </span>
                )}
                {/* {statusSync === 0 && (
                  <button
                    className="btn btn-accept ml-2"
                    onClick={handleReopenTfiButton}
                    disabled={disabledButton}
                  >
                    <i className="fa fa-sync"></i>{' '}
                    {disabledButton ? 'Procesando...' : 'Reabrir TFI'}
                  </button>
                )} */}

                {/* {statusSync === 1 && (
                  <span className="btn btn-accept" onClick={handleSyncSap}>
                    <i className="fa fa-sync"></i> Sincronizar con SAP
                  </span>
                )} */}
                {statusSync === 3 && (
                  <>
                    <span
                      className="btn btn-accept mr-2"
                      onClick={handleSyncSap}
                    >
                      <i className="fa fa-sync"></i> Sincronizar con SAP
                    </span>
                    <span className="btn btn-accept" onClick={handleSyncErrors}>
                      <i className="fa fa-sync"></i> Mostrar Errores
                    </span>
                  </>
                )}
              </div>
            </>
          }
          content={
            <>
              {statusSync === 2 && (
                <>
                  <h3>{progressbarTitle}</h3>
                  <div className="progress mt-2 mb-5 row">
                    <div
                      className="progress-bar  bg-primary progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progressbarPercentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </>
              )}
              {statusSync === 3 && (
                <>
                  <h3>
                    {progressbarTitle} -{' '}
                    <b>
                      La sincronización se ha detenido debido a que se detecto
                      un error
                    </b>
                  </h3>
                  <div className="progress mt-2 mb-5 row">
                    <div
                      className="progress-bar  bg-danger progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progressbarPercentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </>
              )}
              {statusSync === 4 && (
                <>
                  <h3>
                    {progressbarTitle} - La sincronización ha finalizado
                    correctamente
                  </h3>
                  <div className="progress mt-2 mb-5 row">
                    <div
                      className="progress-bar  bg-success progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progressbarPercentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-12 col-md-3">
                  <h6 className="lead">ALMACEN</h6>
                </div>
                <div className="col-12 col-md-3">
                  <h6 className="lead">FECHA DE INICIO</h6>
                </div>
                <div className="col-12 col-md-3">
                  <h6 className="lead">CANTIDAD TOTAL DE DESVIACIÓN</h6>
                </div>
                <div className="col-12 col-md-3">
                  <h6 className="lead">TOTAL DE DESVIACIÓN</h6>
                </div>
                {/* <div className="col-12 col-md-2">
                  <h6>&nbsp;</h6>
                </div> */}
              </div>
              <div className="row bg-gray-light pt-1 pb-0">
                <div className="col-12 col-md-3 pt-1 pl-2">
                  <p>{dataAssign.warehouse}</p>
                </div>
                <div className="col-12 col-md-3 pt-1">
                  <p>{dataAssign.date}</p>
                </div>
                <div className="col-12 col-md-3 pt-1">
                  <p>{dataReport.quantityDeviation} pzas.</p>
                </div>
                <div className="col-12 col-md-3 pt-1">
                  <p>$ {dataReport.totalDeviation}</p>
                </div>
              </div>

              <LayoutTable
                title={
                  <>
                    <div className="row pt-5">
                      <div className="col-12 border border-1 border-dark border-top-0 border-left-0 border-right-0 pb-1">
                        <h3 className="card-title">Conteo</h3>
                      </div>
                    </div>
                  </>
                }
                hideActions
                hideId
                withCard={false}
                hideAddNew
                paginationPerPage={30}
                // loadingData={report.loading}
                exportImportButton={false}
                data={dataReport.items}
                totalCount={dataReport.total}
                pagePagination={pagePagination}
                setPagePagination={setPagePagination}
                hideBtnEdit={true}
                hideBtnErase
                hideDetails
              />
            </>
          }
        />
      </div>
      {statusSync === 3 && (
        <InventorySyncSapErrorsModal
          refModal={refModal}
          idCalendar={id}
          isOpen={open}
          setOpen={setOpen}
        />
      )}
    </>
  )
}

export default InventoryActivityReportCycleCountSku
