import { useEffect, useState, useRef } from 'react'
import { FullCalendarController } from '../../Global/FullCalendar/FullCalendar'
import ModalContent from '../../Global/ModalContent'
import { useMutation, useQuery } from '@apollo/client'
import {
  CANCEL_INVENTORY_ACTIVITY_SCHEDULE,
  GET_ALL_INVENTORY_ACTIVITIES_DATES,
  GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE,
} from '../../../graphql/InventoryActivities/InventoryActivitiesSchedule'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import AddDateForm from './Components/AddDateForm'
import moment from 'moment'
import LayoutTable from '../../Global/LayoutTable'
import Box from '../../Global/Box'
import Swal from 'sweetalert2'
import InputController from '../../Global/InputController'
import { useForm } from 'react-hook-form'

export const InventoryActivitiesSchedule = () => {
  const refModal = useRef()
  const [fullSchedule, setFullSchedule] = useState([{}])
  const [datesHistoricData, setDatesHistoricData] = useState({
    items: [],
    count: 0,
  })
  const [loading, setLoading] = useState(false)
  const [dataDateToEdition, setDataDateToEdition] = useState(null)
  const [
    pageInventoryActivitiesPagination,
    setPageInventoryActivitiesPagination,
  ] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    status: 1,
  })

  const {
    data: InventoryActivitiesDatesData,
    loading: InventoryActivitiesDatesLoading,
    error: InventoryActivitiesDatesError,
    refetch: InventoryActivitiesDatesRefetch,
  } = useQuery(GET_ALL_INVENTORY_ACTIVITIES_DATES)

  const {
    data: InventoryActivitiesHistoricData,
    loading: InventoryActivitiesHistoricLoading,
    error: InventoryActivitiesHistoricError,
    refetch: InventoryActivitiesHistoricRefetch,
  } = useQuery(GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE, {
    variables: pageInventoryActivitiesPagination,
  })

  const [cancelDate] = useMutation(CANCEL_INVENTORY_ACTIVITY_SCHEDULE)

  useEffect(() => {
    try {
      if (InventoryActivitiesHistoricLoading) return
      if (InventoryActivitiesHistoricError)
        return toastSweetAlert({
          mode: 'error',
          message: InventoryActivitiesHistoricError.message,
        })

      const list =
        InventoryActivitiesHistoricData?.getAllInventoryActivitiesHistoricSchedule?.rows.map(
          (date) => {
            return {
              id: date.id,
              Almacén:
                date?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
              'Fecha Inicio': moment(date.start_date).format('YYYY-MM-DD'),
              start_omitField: date.start_date,
              warehouse_id_omitField: date.warehouse_id,
              Estatus: getStatus(date.status),
              status_omitField: date.status,
              [date.status === 4 ? 'Razón' : 'reason_omitField']: date.reason,
              Tipo:
                date.type_schedule === 1
                  ? `Conteo cíclico / ${
                      date.ubications_or_skus === 1 ? 'Ubicaciones' : 'SKUS'
                    }`
                  : 'TFI',
              ubications_or_skus_omitField: date.ubications_or_skus,
              type_schedule_omitField: date.type_schedule,
            }
          }
        )
      setDatesHistoricData({
        items: list,
        count:
          InventoryActivitiesHistoricData
            ?.getAllInventoryActivitiesHistoricSchedule?.count,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    InventoryActivitiesHistoricData,
    InventoryActivitiesHistoricLoading,
    InventoryActivitiesHistoricError,
  ])

  useEffect(() => {
    try {
      if (InventoryActivitiesDatesLoading) return

      if (InventoryActivitiesDatesError)
        return toastSweetAlert({
          mode: 'error',
          message: InventoryActivitiesDatesError.message,
        })

      setValue('filterHistoricSchedule', 1)
      const list =
        InventoryActivitiesDatesData.getAllInventoryActivitiesDatesSchedule.rows.map(
          (item) => {
            return {
              id: item.id,
              title: item.InventoryActivitiesWarehouseSchedule?.warehouse_name,
              start: item.start_date,
              end: moment(item.start_date).format('YYYY-MM-DD'),
              active: item.status === 2 ? true : false,
              start_date: item.start_date,
              ending_date: item.ending_date,
              warehouse_id: item.warehouse_id,
              type_schedule: item.type_schedule,
              ubications_or_skus: item.ubications_or_skus,
            }
          }
        )
      setFullSchedule(list)
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    InventoryActivitiesDatesData,
    InventoryActivitiesDatesLoading,
    InventoryActivitiesDatesError,
  ])

  /* FUNCTIONS */
  const getStatus = (status) => {
    let className = 'badge status-assignment bg-success'
    let text = 'EN REVISION'

    switch (status) {
      case 1:
        className = 'badge status-assignment bg-pendiente'
        text = 'PENDIENTE'
        break

      case 3:
        className = 'badge status-assignment bg-terminada'
        text = 'TERMINADA'
        break

      case 4:
        className = 'badge status-assignment bg-cancelada'
        text = 'CANCELADA'
        break

      default:
        break
    }
    return <span className={className}>{text}</span>
  }

  const handleCancel = async (row) => {
    const validatorReason = () => {
      const reason = document.getElementById('cancel_reason').value
      if (!reason) {
        Swal.showValidationMessage(`Debes especificar una razón de cancelación`)
      }
      return { reason }
    }

    const result = await toastSweetAlert({
      mode: 'modalConfirmContent',
      content: (
        <div className="message-container mt-3">
          <div className="col-8">
            <h4>Especifica una razón</h4>
            <input
              id="cancel_reason"
              className="form-control rounded-2"
              defaultValue=""
            />
          </div>
        </div>
      ),
      validator: validatorReason,
      title: 'Vas a cancelar esta cita',
    })

    if (result.isConfirmed) {
      try {
        await cancelDate({
          variables: {
            id: parseInt(row.id),
            reason: result.value.reason,
          },
        })
        await handleRefetch()
        setLoading(false)
        return await toastSweetAlert({
          mode: 'ok',
          message: 'Se ha cancelado este conteo correctamente',
        })
      } catch (error) {
        setLoading(false)
        return toastSweetAlert({ mode: 'error', message: error.message })
      }
    }
  }

  const handleEditEvent = (row) => {
    setDataDateToEdition({
      id: row.id,
      start_date: row.start_omitField,
      warehouse_id: row.warehouse_id_omitField,
      type_schedule: row.type_schedule_omitField,
      ubications_or_skus: row.ubications_or_skus_omitField,
    })
    refModal.current.click()
  }

  const handleRefetch = async () => {
    await InventoryActivitiesDatesRefetch()
    await InventoryActivitiesHistoricRefetch()
  }

  const handleNewClickEvent = () => {
    setDataDateToEdition(null)
    refModal.current.click()
  }

  const handleEditEventCalendar = (e) => {
    setDataDateToEdition({
      id: e.event._def.publicId,
      start_date: e.event._def.extendedProps.start_date,
      warehouse_id: e.event._def.extendedProps.warehouse_id,
      type_schedule: e.event._def.extendedProps.type_schedule,
      ubications_or_skus: e.event._def.extendedProps.ubications_or_skus,
    })
    refModal.current.click()
  }

  const getActionBtn = (row) => {
    if (row.status_omitField === 1)
      return (
        <>
          <span
            onClick={() => handleEditEvent(row)}
            className="btn btn-accept btn-block"
          >
            Reagendar
          </span>
          <span
            onClick={() => handleCancel(row)}
            className="btn btn-danger btn-sm"
          >
            <i className="fa fa-trash"></i>
          </span>
        </>
      )

    return
  }

  const { control, setValue } = useForm({})

  return (
    <>
      {/* <ContentHeader
        title="Calendario"
        breadcrumb="Calendario"
        windowTitle={`Calendario`}
      /> */}

      <div className={`${loading ? 'processing-file' : ''}`}>
        <Box
          title="Calendario"
          headerLeft={
            <div className="w-100 text-right">
              <button
                type="button"
                className="btn btn-accept btn-sm"
                onClick={handleNewClickEvent}
              >
                <i className="fa fa-plus"></i> Nueva revisión
              </button>
            </div>
          }
          content={
            <FullCalendarController
              eventsList={fullSchedule}
              handleEvent={handleEditEventCalendar}
            />
          }
          enableCollapse={false}
        />

        <LayoutTable
          title="Agenda"
          hideAddNew
          hideId
          extraHeaderButtonFunctionText={
            <>
              <i className="fa fa-plus"></i> Nueva revisión
            </>
          }
          extraHeaderButtonFunction={handleNewClickEvent}
          exportImportButton={false}
          data={datesHistoricData.items}
          totalCount={datesHistoricData.count}
          pagePagination={pageInventoryActivitiesPagination}
          setPagePagination={setPageInventoryActivitiesPagination}
          gql={CANCEL_INVENTORY_ACTIVITY_SCHEDULE}
          hideBtnEdit={true}
          hideBtnErase
          hideDetails
          refetchFunctionQueries={handleRefetch}
          customFilterButtonInput={
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
              <InputController
                type="text"
                name="filterHistoricSchedule"
                inputType="choosen"
                options={[
                  {
                    label: 'Pendientes',
                    value: 1,
                  },
                  {
                    label: 'Iniciadas',
                    value: 2,
                  },
                  {
                    label: 'Completadas',
                    value: 3,
                  },
                  {
                    label: 'Canceladas',
                    value: 4,
                  },
                ]}
                control={control}
                changeAction={(e) =>
                  setPageInventoryActivitiesPagination({
                    ...pageInventoryActivitiesPagination,
                    status: parseInt(e.value),
                  })
                }
              />
            </div>
          }
          componentAction={(row) => {
            return <>{getActionBtn(row)}</>
          }}
        />

        <span
          ref={refModal}
          className="btn btn-accept btn-sm invisible"
          data-toggle="modal"
          data-target="#createDateTfi"
          data-backdrop="static"
          data-keyboard="false"
          data-dismiss="modal"
        />
        <ModalContent
          idModal="createDateTfi"
          title={`${dataDateToEdition ? 'Reagendar' : 'Agendar revisión'} `}
          content={
            <AddDateForm
              setLoading={setLoading}
              dataDateToEdition={dataDateToEdition}
              setDataDateToEdition={setDataDateToEdition}
              refetch={handleRefetch}
            />
          }
        />
      </div>
    </>
  )
}

export default InventoryActivitiesSchedule
