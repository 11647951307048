import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '../../../Global/Box'
import {
  GET_ONE_INVENTORY_ACTIVITIES_ACCOUNTANT,
  CREATE_INVENTORY_ACTIVITIES_ACCOUNTANT,
  UPDATE_INVENTORY_ACTIVITIES_ACCOUNTANT,
} from '../../../../graphql/InventoryActivities/InventoryActivitiesAccountants'
import { GET_SAP_WAREHOUSES } from '../../../../graphql/Catalog/SAP/sapWarehouses'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import InputController from '../../../Global/InputController'

const AccountantForm = ({
  editMode,
  refModal,
  id,
  setId,
  refetch,
  cleanForm,
  setCleanForm,
}) => {
  const [loading, setLoading] = useState(false)
  const [listOfWarehausesOptions, setListOfWarehausesOptions] = useState([])
  const [warehouseAccountantAssignment, setWarehouseAccountantAssignment] =
    useState('')

  const [
    getAccountant,
    { loading: loadingAccountantData, error: errorAccountantData },
  ] = useLazyQuery(GET_ONE_INVENTORY_ACTIVITIES_ACCOUNTANT, {
    fetchPolicy: 'no-cache',
  })

  const [registerUser] = useMutation(CREATE_INVENTORY_ACTIVITIES_ACCOUNTANT)
  const [updateAccountantUserProfile] = useMutation(
    UPDATE_INVENTORY_ACTIVITIES_ACCOUNTANT
  )

  const {
    data: listOfWarehauses,
    loading: loadingListOfWarehauses,
    error: errorListOfWarehauses,
  } = useQuery(GET_SAP_WAREHOUSES, {
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (cleanForm) {
      handleCancel()
      setCleanForm(false)
    }
  }, [cleanForm])

  useEffect(() => {
    try {
      if (loadingAccountantData) return
      if (errorAccountantData)
        return toastSweetAlert({
          mode: 'error',
          message: errorAccountantData.message,
        })

      if (id) {
        getOneAccountantById(id)
      }
    } catch (error) {
      console.log(error)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [id])

  useEffect(() => {
    try {
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
    if (loadingListOfWarehauses) return
    if (errorListOfWarehauses)
      return toastSweetAlert({
        mode: 'error',
        message: errorListOfWarehauses.message,
      })

    const list = listOfWarehauses?.getSapWarehouses.map((item) => {
      return {
        label: item.warehouse_name,
        value: item.id,
      }
    })

    setListOfWarehausesOptions(list)
  }, [loadingListOfWarehauses, listOfWarehauses, errorListOfWarehauses])

  const validationSchema = !id
    ? Yup.object().shape({
        username: Yup.string().required('El usuario es obligatorio'),
        name: Yup.string().required('El nombre es obligatorio'),
        first_name: Yup.string().required('El apellido paterno es obligatorio'),
        password: Yup.string()
          .required('La contraseña es obligatoria')
          .min(6, 'La contraseña debe contener al menos 6 caracteres'),
        passwordConfirm: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Las contraseñas deben coincidir'
        ),
        warehouse: Yup.string().required('El almacén es obligatorio'),
      })
    : Yup.object().shape({
        name: Yup.string().required('El nombre es obligatorio'),
        first_name: Yup.string().required('El apellido paterno es obligatorio'),
        username: Yup.string().required('El usuario es obligatorio'),
        warehouse: Yup.string().required('El almacén es obligatorio'),
        password: Yup.string()
          .notRequired()
          .when({
            is: (val) => {
              return val !== ''
            },
            then: Yup.string().min(
              6,
              'la contraseña debe ser minimo 6 caracteres'
            ),
            otherwise: Yup.string().notRequired(),
          }),

        passwordConfirm: Yup.string()
          .notRequired()
          .when(['password'], {
            is: (val) => {
              return val
            },
            then: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'Las contraseñas deben coincidir'
            ),
            otherwise: Yup.string().notRequired(),
          }),
      })

  const { methods, handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const getOneAccountantById = async (id) => {
    try {
      const { data } = await getAccountant({
        variables: {
          id: +id,
        },
      })
      const accountant =
        data?.getOneInventoryActivitiesAccountant?.UserToAccountant
      setValue('name', accountant?.name)
      setValue('first_name', accountant?.first_name)
      setValue('last_name', accountant?.last_name)
      setValue('username', accountant?.user_name)
      setValue('warehouse', accountant?.warehouse_id)
      setWarehouseAccountantAssignment(accountant?.warehouse_id)
    } catch (error) {
      console.log(error)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const updateAccountantProfile = async (data) => {
    const isPassword = data.password ? data.password : null
    await updateAccountantUserProfile({
      variables: {
        id,
        input: {
          name: data.name,
          first_name: data.first_name,
          last_name: data.last_name,
          user_name: data.username,
          password: isPassword,
          warehouse_id: parseInt(data.warehouse),
        },
      },
    })
    handleCancel()
  }

  const onSubmit = async (data) => {
    //console.log('Data => ', data)
    setLoading(true)

    try {
      if (editMode) {
        await updateAccountantProfile(data)
        toastSweetAlert({
          mode: 'ok',
          message: 'Usuario editado correctamente',
        })
      } else {
        await registerUser({
          variables: {
            input: {
              name: data.name,
              first_name: data.first_name,
              last_name: data.last_name,
              user_name: data.username,
              password: data.password,
              warehouse_id: parseInt(data.warehouse),
            },
          },
        })
        handleCancel()
        toastSweetAlert({
          mode: 'ok',
          message: 'Usuario registrado correctamente',
        })
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    } finally {
      await refetch()
      setLoading(false)
    }

    refModal.current.click()
  }

  const handleCancel = () => {
    reset()
    setId(null)
    setValue('name', '')
    setValue('first_name', '')
    setValue('last_name', '')
    setValue('username', '')
    setValue('warehouse', '')
    setWarehouseAccountantAssignment('')
  }

  const handleWarehouseAccountantAssignament = (e) => {
    setValue('warehouse', e.value)
    setWarehouseAccountantAssignment(e.label)
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`p-0 ${loading ? 'processing-file' : ''} `}
      >
        <Box
          btnSubmitText={id ? 'Editar' : 'Crear'}
          btnSecondFunction={() => {
            handleCancel()
          }}
          btnSecondFunctionTitle="Cancelar"
          btnSecondFunctionDismissModal={true}
          btnSubmit={handleSubmit(onSubmit)}
          btnState={loading}
          withCard={false}
          content={
            <div className="row ">
              <div className="mb-4 col-sm-12 ">
                <InputController
                  label="Nombre"
                  type="text"
                  inputType="text"
                  name="name"
                  placeholder="Nombre de contador"
                  control={control}
                />
              </div>
              <div className="mb-4 col-sm-12">
                <InputController
                  label="Apellido Paterno"
                  type="text"
                  inputType="text"
                  name="first_name"
                  placeholder="Apellido paterno"
                  control={control}
                />
              </div>
              <div className="mb-4 col-sm-12">
                <InputController
                  label="Apellido Materno"
                  type="text"
                  inputType="text"
                  name="last_name"
                  placeholder="Apellido materno"
                  control={control}
                />
              </div>
              <div className="mb-3 col-sm-12">
                <InputController
                  label="Usuario"
                  type="text"
                  name="username"
                  placeholder="Ingrese un nombre de usuario"
                  control={control}
                />
              </div>
              <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                <InputController
                  label="Almacén"
                  type="text"
                  name="warehouse"
                  inputType="choosen"
                  options={listOfWarehausesOptions}
                  control={control}
                  valueManual={warehouseAccountantAssignment}
                  changeAction={handleWarehouseAccountantAssignament}
                />
              </div>
              <div className="mb-3 col-sm-12">
                <InputController
                  label="Contraseña"
                  type="password"
                  name="password"
                  placeholder="Ingrese una contraseña"
                  control={control}
                />
              </div>
              <div className="mb-3 col-sm-12">
                <InputController
                  label="Confirmar contraseña"
                  type="password"
                  name="passwordConfirm"
                  placeholder="Confirme la contraseña"
                  control={control}
                />
              </div>
            </div>
          }
        ></Box>
      </form>
    </FormProvider>
  )
}

export default AccountantForm
