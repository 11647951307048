import React, { useState, useEffect } from 'react'
import OrderCol from '../../Global/OrderCol'
import BoxCollapsible from '../../Global/BoxCollapsible'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { GET_PRODUCT_SAP_BY_ID, UPDATE_ORDER_SHIPPING_SAP } from '../../../graphql/Catalog/Telemarketing/Orders/orders'
import { GET_ALL_ROUTE_TIME_BLOCKS } from '../../../graphql/Catalog/Routes/routeTimeBlocks'

export const DetailsPaymentDelivery = ({
    key,
    withBox = true,
    comesFromSummary,
    paymentMethodSelected,
    deliveryDateSelectedDefault,
    deliveryTimeSelectedDefault,
    submitDataOrder,
    handleCancelPaymentDelivery,
    detailsForm = false,
    businessPartnerAddresses,
    businessPartnerAddressesSelected,
    onChangeDirection,
    dataShipping,
    _id,
}) => {
    const history = useHistory()

    //API for update data delivery of order
    const [updateOrderShippingSap] = useMutation(UPDATE_ORDER_SHIPPING_SAP, {
            refetchQueries: [
            {
                query: GET_PRODUCT_SAP_BY_ID,
                variables: {
                "getOrderPartnerByIdId": parseInt(_id) 
                },
            },
        ],
    })

    const [loadingBtn, setLoadingBtn] = useState(false)

    //API get delivery times
    const { loading: loadingDeliveryTimes, error: errorDeliveryTimes, data: dataDeliveryTimes } = useQuery(GET_ALL_ROUTE_TIME_BLOCKS)

    //save delivery times
    const [deliveryTimes, setDeliveryTimes] = useState([])

    //get date selected for delivery
    const [deliveryDateSelected, setDeliveryDateSelected] = useState(null)

    //get delivery time selected for delivery
    const [deliveryTimeSelected, setDeliveryTimeSelected] = useState('')

    //Show statics paymenth method for now
    const [paymentMethods, setPaymentMethods] = useState([
    {
        label: "Tarjeta de credito", 
        value: 1
    },
    {
        label: "Transferencia",
        value: 2
    },
    {
        label: "Efectivo",
        value: 3
    }
    ])

    //show statics delivery times for now
    const [deliveryTimesStatic, setDeliveryTimesStatic] = useState([
    {
        label: "9:00 am - 10:00 am", 
        value: 1
    },
    {
        label: "10:00 am - 11:00 am",
        value: 2
    },
    {
        label: "11:00 am - 12:00 pm",
        value: 3
    },
    {
        label: "12:00 pm - 1:00 pm",
        value: 4
    },
    {
        label: "1:00 pm - 2:00 pm",
        value: 5
    },
    {
        label: "2:00 pm - 3:00 pm",
        value: 6
    },
    {
        label: "3:00 pm - 4:00 pm",
        value: 7
    },
    {
        label: "4:00 pm - 5:00 pm",
        value: 8
    },
    {
        label: "5:00 pm - 6:00 pm",
        value: 9
    },
    {
        label: "6:00 pm - 7:00 pm",
        value: 10
    }
    ])

    const validationSchema = Yup.object().shape(!detailsForm ? 
        {
            payment_method: Yup.string().required('El método de pago es requerido'),
            delivery_date: Yup.string().required('La fecha de entrega es requerida'),
            delivery_time: Yup.string().required('La ventana de entrega es requerida').test('required', 'La ventana de entrega es requerida', 
            () => deliveryTimeSelected != "" ? true : false),
        }
        :
        {
            payment_method: Yup.string().required('El método de pago es requerido'),
            direction: Yup.string().required('La dirección es requerida'),
            delivery_date: Yup.string().test('required', 'La fecha de entrega es requerida', 
            () => businessPartnerAddressesSelected?.route?.length > 0 && !deliveryDateSelected ? false : true),
            delivery_time: Yup.string().test('required', 'La ventana de entrega es requerida', 
            () => businessPartnerAddressesSelected?.route?.length > 0 && !deliveryTimeSelected ? false : true),
        }
        
    )

    const {
        methods,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
    })

    useEffect(() => {
        if (!loadingDeliveryTimes) {
            let list = []
            dataDeliveryTimes.getAllTimeBlocks.map((item) => {
                return list.push({
                    value: item.id,
                    label: item.time,
                })
            })
            setDeliveryTimes(list)
        }
  }, [dataDeliveryTimes])

    useEffect(() => {
        //when comes from summary order, load the data of payment and delivery
        if(comesFromSummary){
            setValue('payment_method', paymentMethodSelected)
            setValue('delivery_date', deliveryDateSelectedDefault)
            setValue('delivery_time', deliveryTimeSelectedDefault)
            setDeliveryDateSelected(deliveryDateSelectedDefault)
            setDeliveryTimeSelected(deliveryTimeSelectedDefault)
        }else{
            setValue('payment_method', dataShipping?.payment_method)
            setValue('direction', dataShipping?.direction)
        }
    }, [])

    const onSubmitOrder = async(data) => {
        submitDataOrder({
            paymentMethodSelected: data.payment_method,
            deliveryDateSelected: deliveryDateSelected,
            deliveryTimeSelected: deliveryTimeSelected
        })
    }

    const onSubmitDetailsOrder = async(data) => {
        try {
            setLoadingBtn(true)
            let dataAddress = structuredClone(businessPartnerAddressesSelected)
            delete dataAddress.route
            await updateOrderShippingSap({
                variables: {
                    shippingId: parseInt(dataShipping.shipping_id),
                    input: dataAddress,
                },
            })
            return (
                toastSweetAlert({
                    mode: 'ok',
                    message: 'Orden de venta actualizada correctamente',
                }),
                setLoadingBtn(false),
                history.push(`/telemarketingOrderHistory`)
            )
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }

    const handleDeliveryDate = (e) => {
        setDeliveryTimeSelected("")
        setDeliveryDateSelected(e?.target.value)
    }

    return( 
        withBox ? 
            <BoxCollapsible
                key={key}
                title={"Pago y entrega"}
                icon=""
                nestedContent={true}
                customizedDefault={true}
                collapseUnfolded={true}
                content={
                    <FormProvider {...methods}>
                        <form className="p-3" onSubmit={handleSubmit(!detailsForm ? onSubmitOrder : onSubmitDetailsOrder)}>
                            <Box
                                btnSecondFunction={handleCancelPaymentDelivery}
                                btnSecondFunctionTitle="Cancelar"
                                errors={errors}
                                withCard={false}
                                btnSubmit={true}
                                btnState={loadingBtn}
                                content={
                                    !detailsForm ? 
                                    <>
                                        <div className='row'>
                                            <div className='col col-md-6 mb-3'>
                                                <InputController
                                                label="Método de pago"
                                                name="payment_method"
                                                type="number"
                                                inputType="choosen"
                                                control={control}
                                                options={paymentMethods}
                                                />
                                            </div>
                                            <div className='col col-md-6 mb-3'>
                                                <InputController
                                                    id="delivery_date"
                                                    label="Fecha de entrega"
                                                    name="delivery_date"
                                                    type="date"
                                                    changeAction={( e ) => { handleDeliveryDate(e) }}
                                                    control={control}
                                                />
                                            </div>
                                        </div>
                                        {
                                        (deliveryDateSelected || comesFromSummary) && 
                                            <div className='row'>
                                                <div className='col col-md-6 mb-3'>
                                                    <InputController
                                                    id="delivery_time"
                                                    label="Selecciona la ventana de entrega"
                                                    name="delivery_time"
                                                    type="number"
                                                    inputType="choosen"
                                                    valueManual={deliveryTimeSelected}
                                                    changeAction={(e) => {
                                                        setDeliveryTimeSelected(e.value)
                                                    }}
                                                    control={control}
                                                    options={deliveryTimesStatic}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        <div className='row'>
                                            <div className='col col-md-6 mb-3'>
                                                <InputController
                                                    label="Método de pago"
                                                    name="payment_method"
                                                    type="text"
                                                    control={control}
                                                    disabled
                                                />
                                            </div>
                                            <div className='col col-md-6 mb-3'>
                                                <InputController
                                                    label="Dirección"
                                                    name="direction"
                                                    type="text"
                                                    inputType="choosen"
                                                    control={control}
                                                    changeAction={( e ) => { onChangeDirection(e) }}
                                                    options={businessPartnerAddresses}
                                                />
                                            </div>
                                            {
                                                businessPartnerAddressesSelected.id && 
                                                <div className='col col-md-12 mb-3'>
                                                <label>Datos de dirección</label>
                                                <p className='border border-rounded p-3'>
                                                    {businessPartnerAddressesSelected.street + ", " + businessPartnerAddressesSelected.block + ", " + 
                                                    businessPartnerAddressesSelected.zip_code + ", " + businessPartnerAddressesSelected.city + ", " +
                                                    businessPartnerAddressesSelected.state + ", " + businessPartnerAddressesSelected.country}
                                                </p>
                                                </div>
                                            }
                                            {
                                                businessPartnerAddressesSelected.route?.length > 0 &&
                                                <>
                                                    <div className='col col-md-6 mb-3'>
                                                    <InputController
                                                        label="Fecha de entrega"
                                                        name="delivery_date"
                                                        type="date"
                                                        changeAction={( e ) => { handleDeliveryDate(e) }}
                                                        control={control}
                                                    />
                                                    </div>
                                                    {
                                                    deliveryDateSelected && 
                                                        <div className='col col-md-6 mb-3'>
                                                            <InputController
                                                            label="Selecciona la ventana de entrega"
                                                            name="delivery_time"
                                                            type="number"
                                                            inputType="choosen"
                                                            valueManual={deliveryTimeSelected}
                                                            disabled={errorDeliveryTimes ? true : false}
                                                            changeAction={(e) => {
                                                                setDeliveryTimeSelected(e.value)
                                                            }}
                                                            control={control}
                                                            options={deliveryTimes}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                            />
                        </form>
                    </FormProvider>
                }
            />
        : 
            <div className="row">
                <OrderCol title="Método de pago" content={paymentMethods.map((item, index)=>{
                    if(item.value == paymentMethodSelected) return item.label
                })} mediumColSize="4" />
                <OrderCol title="Fecha de entrega" content={deliveryDateSelectedDefault} mediumColSize="4" />
                <OrderCol title="Ventana de entrega" content={deliveryTimesStatic.map((item, index)=>{
                    if(item.value == deliveryTimeSelectedDefault) return item.label
                })} mediumColSize="4" />
            </div>
    )
}
export default DetailsPaymentDelivery