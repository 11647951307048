import { gql } from '@apollo/client'

export const CREATE_POSITION_ORDER = gql`
  mutation CreatePositionOrder(
    $orderInput: PositionOrderInput!
    $productsInput: [PositionOrderProductInput!]!
  ) {
    createPositionOrder(orderInput: $orderInput, productsInput: $productsInput)
  }
`
