import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '../../../Global/Box'
import ContentHeader from '../../../Layout/ContentHeader'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_ROUTE,
  GET_ALL_ROUTES,
  GET_ROUTE,
  UPDATE_ROUTE,
} from '../../../../graphql/Catalog/Routes/routes'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import InputController from '../../../Global/InputController'
import { GET_SAP_WAREHOUSES } from '../../../../graphql/Catalog/SAP/sapWarehouses'

export const RoutesNew = () => {
  const { id: _id } = useParams()
  const {
    data: sapWarehousesData,
    loading: sapWarehousesLoading,
    error: sapWarehousesError,
  } = useQuery(GET_SAP_WAREHOUSES)
  const [storeOptions, setStoreOptions] = useState([])

  const [createRoute] = useMutation(CREATE_ROUTE, {
    refetchQueries: [
      {
        query: GET_ALL_ROUTES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateRoute] = useMutation(UPDATE_ROUTE, {
    refetchQueries: [
      {
        query: GET_ALL_ROUTES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      { query: GET_ROUTE, variables: { getOneRouteId: parseInt(_id) } },
    ],
  })

  const {
    data: dataRouteId,
    loading: loadingRouteId,
    error: errorRouteId,
  } = useQuery(GET_ROUTE, {
    variables: {
      getOneRouteId: parseInt(_id),
    },
  })

  const [loadingBtn, setLoadingBtn] = useState(false)
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Este campo es obligatorio'),
    description: Yup.string().required('Este campo es obligatorio'),
    store: Yup.string().required('Este campo es obligatorio'),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!loadingRouteId) {
      if (_id) {
        if (errorRouteId) {
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'error',
            message: errorRouteId.message,
          })
        }

        reset(dataRouteId?.getOneRoute)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataRouteId, errorRouteId])

  useEffect(() => {
    if (!sapWarehousesLoading) {
      setStoreOptions(
        sapWarehousesData?.getSapWarehouses.map((store) => {
          return { value: store.warehouse_code, label: store.warehouse_name }
        })
      )
    }
  }, [sapWarehousesData])

  const onSubmit = async (Data) => {
    setLoadingBtn(true)
    try {
      if (_id) {
        await updateRoute({
          variables: {
            routeId: parseInt(_id),
            input: {
              name: Data.name,
              description: Data.description,
              store: Data.store,
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Ruta actualizado correctamente',
          }),
          history.goBack()
        )
      } else {
        await createRoute({
          variables: {
            input: {
              name: Data.name,
              description: Data.description,
              store: Data.store,
            },
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Ruta registrado correctamente',
          },
          history.goBack()
        )
      }
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  return (
    <>
      {/* <ContentHeader
        title="Ruta"
        breadcrumb="Ruta"
        windowTitle={`${_id ? 'Editar' : 'Agregar'} ruta `}
      /> */}
      <FormProvider {...methods}>
        <form
          className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title={`${_id ? 'Editar' : 'Agregar'} ruta`}
            btnSecondFunction={() => history.goBack()}
            btnSecondFunctionTitle="Cancelar"
            errors={errors}
            btnSubmit={true}
            btnLoading={loadingBtn}
            content={
              <>
                <div className="row">
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="Nombre"
                      name="name"
                      type="text"
                      placeholder="Nombre de la ruta"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="Descripción"
                      name="description"
                      inputType="textarea"
                      placeholder="Breve descripción de la ruta"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="Almacen/Tienda destino"
                      name="store"
                      inputType="choosen"
                      control={control}
                      options={storeOptions}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}
export default RoutesNew
