import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import ContentHeader from "../Layout/ContentHeader";
import * as Yup from 'yup'
import Box from "../Global/Box";
import InputController from "../Global/InputController";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { toastSweetAlert } from "../Helpers/ToastSweetAlert";
import { GET_INVENTORY, GET_INVENTORY_BY_ID, UPDATE_INVENTORY_RACK_REORDER_BY_ID } from "../../graphql/Catalog/PositionOrders/positionOrders";

const InventoryGeneralNew =()=>{
    const { id: _id } = useParams()
    const history = useHistory()
    
    // const [loading, setLoading] = useState(false)
    // const [item,setItem] = useState(null)

    // const validationSchema = Yup.object().shape({
    //     reorder: Yup.number().typeError('Debe ser un numero').integer().required(),
    // })
    
    // const {
    //     methods,
    //     handleSubmit,
    //     control,
    //     formState: { errors },
    //     setValue,
    // } = useForm({ resolver: yupResolver(validationSchema) })

    // //Query to find Inventory and rack
    // const {
    //     data: dataItem,
    //     loading: loadingItem,
    //     error: errorItem,
    // } = useQuery(GET_INVENTORY_BY_ID, {
    //     variables: {
    //         id: parseInt(_id),
    //     },
    // })

    // //Mutation to update rack
    // const [updateInventoryRack] = useMutation(UPDATE_INVENTORY_RACK_REORDER_BY_ID,{
    //     refetchQueries: [
    //         {
    //           query: GET_INVENTORY,
    //           variables: {
    //             limit: 10,
    //             offset: 0,
    //             searchQuery: null,
    //           },
    //         },
    //         {
    //             query: GET_INVENTORY_BY_ID,
    //             variables: {
    //                 id: parseInt(_id),
    //             },
    //         }
    //     ],
    // });

    // useEffect(()=>{
    //     try {
    //         if(_id){
    //             setLoading(true)
    //             if(!loadingItem){
    
    //                 if (errorItem)
    //                     return toastSweetAlert({
    //                         mode: 'error',
    //                         message: errorItem.message,
    //                     })
                    
    //                 /**Save data using item hook */
    //                 if (dataItem){
    //                     setItem(dataItem.getInventoryById);
    //                     setValue('reorder', dataItem.getInventoryById?.RackPosition?.reorder ? dataItem.getInventoryById.RackPosition.reorder : 0)
    //                 }

    //                 setLoading(false)
    //             }
    //         }
    //     } catch (error) {
    //         setLoading(false)
    //         return toastSweetAlert({
    //           mode: 'error',
    //           message: error.message,
    //         })
    //     }
    // },[dataItem,loadingItem,errorItem])

    // const onSubmit = async (data) => {
    //     /**Update new reorder */
    //     try {
    //         await updateInventoryRack({
    //             variables: {
    //               rack_id: parseInt(item.RackPosition.id),
    //               newReorder: parseInt(data.reorder),
    //             },
    //         })
    //         return toastSweetAlert({
    //             mode: 'ok',
    //             message: 'Reorden actualizado',
    //         }),history.goBack();
    //     } catch (error) {
    //         return toastSweetAlert({
    //             mode: 'error',
    //             message: error.message,
    //         })
    //     }
    // }

    return(
        <>
            <ContentHeader
                title="Modificacion de reorden"
                breadcrumb="Modificacion de reorden"
                windowTitle={`${_id ? 'Editar' : 'Agregar'} producto `}
            />
            {/* <FormProvider {...methods}>
                <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    title={`Rack: ${item?.RackPosition?.name ? item.RackPosition.name: '--'} - Producto: ${item?.SapItem?.item_name ? item.SapItem.item_name: '--'}`}
                    btnRedPath="/stowing/inventory"
                    btnRedTxt="Cancelar"
                    btnSubmit={true}
                    btnState={loading}
                    errors={errors}
                    content={
                        <>
                            <div className="row">
                                <div className="mb-3 col-md-12 col-lg-4">
                                    <label className="form-label">
                                    {`Cantidad de reorden actual: ${item?.RackPosition?.reorder ? item.RackPosition.reorder : '--'}`}
                                    </label>
                                </div>
                                <div className="mb-3 col-md-12 col-lg-4">
                                    <label className="form-label">
                                        {`Capacidad maxima del rack: ${item?.RackPosition?.capacity ? item.RackPosition.capacity : '--'}`}
                                    </label>
                                </div>
                                <div className="mb-3 col-md-12 col-lg-4">
                                    <label className="form-label">
                                        {`Zona de rack: ${item?.RackPosition?.RackZone?.name ? item.RackPosition.RackZone.name : '--'}`}
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <InputController
                                        label="Cantidad de reorden"
                                        type="number"
                                        name="reorder"
                                        placeholder="Ingrese cantidad"
                                        control={control}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
                </form>
            </FormProvider> */}
        </>
    );
}

export default InventoryGeneralNew;