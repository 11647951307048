import { gql } from '@apollo/client'

export const GET_SAP_NUMBER_GROUPS = gql`
  query GetSapNumberGroups($limit: Int, $offset: Int) {
    getSapNumberGroups(limit: $limit, offset: $offset) {
      id
      extra_days
      extra_month
      group_number
      installments_number
      open_reception
      pay_due_month
      payment_group
      payments_number
    }
  }
`
