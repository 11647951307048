import { gql } from '@apollo/client'

export const GET_SCHEDULE = gql`
  query GetSchedule($todaySelection: Boolean) {
    getSchedule(todaySelection: $todaySelection) {
      id
      document_date
      document_time_start
      document_time_end
      comments
      warehouse_id
      warehouse {
        warehouse_code
        warehouse_name
      }
      document_status_id
      ScheduleDocumentStatus {
        id
        name
      }
      ScheduleScheduleOrdersLines {
        ScheduleOrdersLinesSapBusinessPartner {
          card_code
          card_name
        }
        ScheduleOrdersLinesSapPurchasesOrders {
          id
          document_entry
        }
      }
    }
  }
`

export const GET_SCHEDULE_ONLY_PAGINATION = gql`
  query GetScheduleOnlyPagination(
    $type: [Int]
    $limit: Int
    $offset: Int
    $searchQuery: String
  ) {
    getScheduleOnlyPagination(
      type: $type
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      rows {
        id
        document_date
        document_time_start
        document_time_end
        comments
        warehouse_id
        warehouse {
          warehouse_code
          warehouse_name
        }
        document_status_id
        ScheduleDocumentStatus {
          id
          name
        }
        ScheduleScheduleOrdersLines {
          ScheduleOrdersLinesSapBusinessPartner {
            card_code
            card_name
          }
          ScheduleOrdersLinesSapPurchasesOrders {
            id
            document_entry
          }
        }
        ScheduleScheduleDocks {
          id
          dock {
            name
            id
          }
        }
      }
      count
    }
  }
`

export const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($inputSchedule: schedulesInput!) {
    createSchedule(inputSchedule: $inputSchedule)
  }
`

export const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule($scheduleId: Int!, $inputSchedule: schedulesInput!) {
    updateSchedule(scheduleId: $scheduleId, inputSchedule: $inputSchedule)
  }
`

export const CANCELL_SCHEDULE = gql`
  mutation CancellSchedule(
    $scheduleId: Int!
    $reason: String
    $isRecieving: Boolean
  ) {
    cancellSchedule(
      scheduleId: $scheduleId
      reason: $reason
      isRecieving: $isRecieving
    )
  }
`
export const CLOSE_SCHEDULE = gql`
  mutation CloseSchedule($scheduleId: Int!) {
    closeSchedule(scheduleId: $scheduleId)
  }
`
// export const GET_SAP_PURCHASES_ORDERS_QUOTES = gql`
//   query GetSapPurchasesOrdersQuotes($limit: Int, $offset: Int) {
//     getSapPurchasesOrdersQuotes(limit: $limit, offset: $offset) {
//       rows {
//         id
//         business_partner_id
//         document_date
//         document_due_date
//         comments
//         document_status
//         business_partner {
//           card_code
//           card_name
//           phone1
//           phone2
//           email
//           group_code
//           partner_group {
//             group_name
//             group_type
//           }
//         }
//       }
//       count
//     }
//   }
// `
// export const GET_SAP_PURCHASES_ORDERS_QUOTES_BY_ID = gql`
//   query GetSapPurchasesOrdersQuotesById($idPurcharseOrder: Int) {
//     getSapPurchasesOrdersQuotesById(idPurcharseOrder: $idPurcharseOrder) {
//       id
//       business_partner_id
//       document_date
//       document_due_date
//       comments
//       document_status
//       business_partner {
//         card_code
//         card_name
//         phone1
//         phone2
//         email
//         group_code
//         partner_group {
//           group_name
//           group_type
//         }
//       }
//       purchases_orders_lines {
//         id
//         item_code
//         quantity
//         price
//         tax_code
//         total
//         item {
//           item_name
//         }
//       }
//     }
//   }
// `
