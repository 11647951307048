import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import moment from 'moment'
// import {
//   GET_ALL_TFI_HISTORIC_SCHEDULE,
//   REOPEN_TFI,
// } from '../../../graphql/InventoryActivities/Schedule'
import { useHistory } from 'react-router-dom'
import Loader from '../../Global/Spinner'
import { GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE } from '../../../graphql/InventoryActivities/InventoryActivitiesSchedule'
import { historicStatus } from '../Global/LiveStatus'

/*
    ===Status===
    1: Pendiente
    2: In review
    3: finalizado
    4: Cancelado
*/

const InventoryActivitiesReports = () => {
  const [dataTable, setDataTable] = useState({
    items: [],
    total: 0,
  })

  const [pageDatesHistoricPagination, setPageDatesHistoricPagination] =
    useState({
      searchQuery: null,
      limit: 30,
      offset: 0,
      typeSchedule: null,
    })

  const inventorySchedule = useQuery(
    GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE,
    {
      variables: pageDatesHistoricPagination,
      fetchPolicy: 'no-cache',
    }
  )
  // const [reOpenTfi] = useMutation(REOPEN_TFI)
  const [disabledButton, setDisabedButton] = useState(false)
  const history = useHistory()

  /* USEEFFECTS */

  useEffect(() => {
    try {
      if (inventorySchedule.loading) return
      if (inventorySchedule.error) {
        return toastSweetAlert({
          mode: 'error',
          message: inventorySchedule.error.message,
        })
      }
      const list =
        inventorySchedule?.data?.getAllInventoryActivitiesHistoricSchedule?.rows.map(
          (item) => {
            return {
              id: item?.id,
              Almacén:
                item?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
              'Fecha Inicio': moment(item?.start_date).format('LLL'),
              Estatus: historicStatus(item.status),
              // Estatus: getStatus(item?.status),
              status: item?.status,
              Tipo:
                item.type_schedule === 1
                  ? `Conteo cíclico / ${
                      item.ubications_or_skus === 1 ? 'Ubicaciones' : 'SKUS'
                    }`
                  : 'TFI',
              type_schedule_omitField: item.type_schedule,
              conditional_hide_button: item?.type_schedule === 1,
            }
          }
        )
      setDataTable({
        items: list,
        total: list.length,
      })
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [
    inventorySchedule?.loading,
    inventorySchedule?.data,
    inventorySchedule?.error,
  ])

  // const handleButton = async (row) => {
  //   console.log(row)
  //   setDisabedButton(true)
  //   try {
  //     const { data } = await reOpenTfi({
  //       variables: {
  //         reOpenTfiId: +row.id,
  //       },
  //     })
  //     if (data) {
  //       // setDisabedButton(false)
  //       toastSweetAlert({
  //         mode: 'ok',
  //         message: 'La TFI se reabrio con exito',
  //       })
  //       history.push('/live')
  //     }
  //   } catch (error) {
  //     setDisabedButton(false)
  //     return toastSweetAlert({
  //       mode: 'error',
  //       message: error.message,
  //     })
  //   }
  // }
  const handleButton = async (id) => {
    // console.log(id)
    // setDisabedButton(id)
    setDisabedButton(true)
    try {
      // const { data } = await reOpenTfi({
      //   variables: {
      //     reOpenTfiId: +id,
      //   },
      // })
      // if (data) {
      //   setDisabedButton(false)
      //   toastSweetAlert({
      //     mode: 'ok',
      //     message: 'La TFI se reabrio con exito',
      //   })
      //   history.push('/live')
      // }
      setDisabedButton(false)
    } catch (error) {
      setDisabedButton(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      {disabledButton ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <LayoutTable
          title="Reportes TFI / Conteos ciclicos"
          hideAddNew
          exportImportButton={false}
          loadingData={inventorySchedule.loading}
          data={dataTable.items}
          totalCount={dataTable.total}
          pagePagination={pageDatesHistoricPagination}
          setPagePagination={setPageDatesHistoricPagination}
          paginationPerPage={30}
          hideDetails={true}
          hideBtnErase
          editText="Ver detalles"
          hideColumns={['status']}
          handleConditionalButton={handleButton}
          handleConditionalButtonTitle="Reabrir TFI"
        />
      )}
    </>
  )
}

export default InventoryActivitiesReports
