import Box from '../../../Global/Box'
import LayoutTable from '../../../Global/LayoutTable'
import ModalContent from '../../../Global/ModalContent'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import InputController from '../../../Global/InputController'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_SHIPMENT_ROUTE,
  GET_ALL_SHIPMENTS_ROUTES,
  GET_ONE_SHIPMENT_ROUTE_FOR_EDITION,
  GET_SHIPMENT_ROUTE_CHOOSEN_OPTS,
  UPDATE_SHIPMENT_ROUTE,
  UPDATE_VEHICLE_VERIFICATION,
} from '../../../../graphql/Catalog/Routes/routes'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useState } from 'react'
import { GET_VEHICLES } from '../../../../graphql/Catalog/Vehicles/vehicles'
const RoteShipmentCrationModal = ({ refModal, editId = null, setEditId, refetch }) => {
  const [users, setUsers] = useState([])
  const [routes, setRoutes] = useState([])
  const [vehicles, setVehicles] = useState([])

  const { data, error, loading } = useQuery(GET_SHIPMENT_ROUTE_CHOOSEN_OPTS)
  const [createShipmentRoute] = useMutation(CREATE_SHIPMENT_ROUTE)
  const [updateShipmentRoute] = useMutation(UPDATE_SHIPMENT_ROUTE)
  const [getShipmentRoute] = useMutation(GET_ONE_SHIPMENT_ROUTE_FOR_EDITION)
  const [updateVehicleVerification] = useMutation(UPDATE_VEHICLE_VERIFICATION)
  const [vehicleVerification, setVehicleVerification] = useState(true)
  const { loading: vehiclesLoading, error: vehiclesError, data: vehiclesData } = useQuery(GET_VEHICLES, {
    fetchPolicy: "no-cache"
  })
  console.log(editId, "EDICION")
  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      const userList = data?.getShipmentRoutesChoosenOptions?.users.map(
        (user) => {
          const name = user?.name ? user?.name : ''
          const firstName = user?.first_name ? user?.first_name : ''
          const lastName = user?.last_name ? user?.last_name : ''

          return {
            value: user?.id,
            label: `${name} ${firstName} ${lastName}`,
          }
        }
      )
      const routesList = data?.getShipmentRoutesChoosenOptions?.routes.map(
        (route) => {
          return {
            value: route?.id,
            label: route?.name,
          }
        }
      )

      setUsers(userList)
      setRoutes(routesList)
    }
  }, [data, error, loading])

  useEffect(() => {
    if (!vehiclesLoading) {
      if (vehiclesError) {
        return toastSweetAlert({
          mode: "error",
          message: vehiclesError.message
        })
      }
      console.log(vehiclesData, "VEHICULOS")
      const list = vehiclesData?.getAllVehicles?.rows.map(item => ({
        label: `${item?.model} - ${item?.plates_number}`,
        value: item?.id
      }))

      setVehicles(list)

    }


  }, [
    vehiclesLoading,
    vehiclesError,
    vehiclesData
  ]);

  useEffect(() => {
    console.log(editId, "ID DE EDICION")
    if (editId) {
      const editInformation = async () => {
        const { data: shipmentRouteData } = await getShipmentRoute({
          variables: {
            getShipmentRouteForEditionId: +editId,
          },
        })

        const { data } = await updateVehicleVerification({
          variables: {
            routeId: +editId
          }
        })
        if (data) {
          setVehicleVerification(data?.updateVehicleVerification)

        }


        const shipmentRoute = shipmentRouteData?.getShipmentRouteForEdition
        console.log(shipmentRoute, "RUTA DETALLES")
        setValue('route_id', shipmentRoute?.route_id)
        setValue('route_name', shipmentRoute?.name)
        setValue('start_date', shipmentRoute?.start_date)
        setValue('start_time', shipmentRoute?.start_time)
        setValue('operator_id', shipmentRoute?.operator_id)
        setValue('vehicle_id', shipmentRoute?.vehicle_id)
      }

      editInformation()
    }
  }, [editId])

  const validationSchema = editId
    ? Yup.object().shape({
      route_name: Yup.string().required(
        'El nombre de la ruta es obligatorio'
      ),
      start_date: Yup.string().required('La fecha inicial es obligatoria'),
      start_time: Yup.string().required('La hora inicial es obligatoria'),
      operator_id: Yup.number().required('El operador es obligatorio'),
    })
    : Yup.object().shape({
      route_name: Yup.string().required(
        'El nombre de la ruta es obligatorio'
      ),
      start_date: Yup.string().required('La fecha inicial es obligatoria'),
      start_time: Yup.string().required('La hora inicial es obligatoria'),
      route_id: Yup.number().required('La ruta principal es obligatoria'),
      operator_id: Yup.number().required('El operador es obligatorio'),
    })

  const { methods, handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (data) => {
    try {
      if (editId) {
        const { data: updateShipmentData } = await updateShipmentRoute({
          variables: {
            updateShipmentRouteId: +editId,
            input: {
              operator_id: +data.operator_id,
              route_name: data.route_name,
              start_date: data.start_date,
              start_time: data.start_time,
              vehicle_id: +data.vehicle_id
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_SHIPMENTS_ROUTES,
              variables: {
                searchQuery: null,
                limit: 10,
                offset: 0,
              },
            },
          ],
        })
        await refetch({
          getOneShimpentRouteId: +editId
        })
        setEditId(null)
        reset()
        refModal.current.click()
        if (updateShipmentData) {
          toastSweetAlert({
            mode: 'ok',
            message: 'La ruta se actualizo con exito',
          })
        }
      } else {
        const { data: createShipmentData } = await createShipmentRoute({
          variables: {
            input: {
              operator_id: +data.operator_id,
              route_id: data.route_id,
              start_date: data.start_date,
              route_name: data.route_name,
              start_time: data.start_time,
              vehicle_id: +data.vehicle_id
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_SHIPMENTS_ROUTES,
              variables: {
                searchQuery: null,
                limit: 10,
                offset: 0,
              },
            },
          ],
        })
        reset()
        refModal.current.click()
        if (createShipmentData) {
          toastSweetAlert({
            mode: 'ok',
            message: 'La ruta se creo con exito',
          })
        }
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const handleCancelButton = () => {
    setEditId(null)
    reset()
    setVehicleVerification(true)
    refModal.current.click()
  }
  const handleCloseModal = () => {
    setVehicleVerification(true)
    setEditId(null)
    reset()
  }

  return (
    <>
      <>
        <span
          ref={refModal}
          className="btn btn-accept btn-sm invisible"
          data-toggle="modal"
          data-target="#shipmentRoute"
          data-backdrop="static"
          data-keyboard="false"
          data-dismiss="modal"
        />
        <ModalContent
          onClose={handleCloseModal}
          idModal="shipmentRoute"
          title={editId ? 'Editar ruta' : 'Asignar ruta'}
          size="large"
          content={
            <>
              <form onSubmit={handleSubmit(onSubmit)} className="p-2">
                <Box
                  btnSubmitText={editId ? 'Editar ruta' : 'Crear ruta'}
                  withCard={false}
                  btnSubmit
                  btnSecondFunction={handleCancelButton}
                  btnSecondFunctionTitle="Cancelar"
                  content={
                    <>
                      <>
                        <div className="row m-2">
                          <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                            <InputController
                              label="Ruta principal"
                              name="route_id"
                              placeholder="Ruta principal"
                              control={control}
                              inputType="choosen"
                              options={routes}
                              disabled={editId}
                            />
                          </div>

                          <div className=" col-sm-12 col-md-6 col-lg-6 ">
                            <InputController
                              label="Ruta secundaria"
                              type="text"
                              inputType="text"
                              name="route_name"
                              placeholder="Nombre de ruta"
                              control={control}
                            />
                          </div>

                        </div>
                        <div className="row mt-4 align-items-center justify-content-center">

                          <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                            <InputController
                              label="Operador"
                              name="operator_id"
                              control={control}
                              inputType="choosen"
                              options={users}
                            />
                          </div>

                          {vehicleVerification && (
                            <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                              <InputController
                                label="Unidad"
                                type="text"
                                name="vehicle_id"
                                inputType="choosen"
                                // changeAction={(e) => setValue('colonia_name', e.label)}
                                options={vehicles}
                                control={control}
                              />
                            </div>
                          )}


                        </div>

                        <div className="row mt-4 align-items-center justify-content-center">
                          <div className=" col-sm-12 col-md-6 col-lg-6">
                            <InputController
                              label="Fecha de inicio"
                              type="date"
                              // inputType="text"
                              name="start_date"
                              placeholder="Fecha inicio"
                              control={control}
                            />
                          </div>
                          <div className=" col-sm-12 col-md-6 col-lg-6">
                            <InputController
                              label="Hora Inicial"
                              type="time"
                              // inputType="text"
                              name="start_time"
                              placeholder="Hora Inicial"
                              control={control}
                            />
                          </div>

                        </div>

                      </>
                    </>
                  }
                ></Box>
              </form>
            </>
          }
        />
      </>
    </>
  )
}

export default RoteShipmentCrationModal
