import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { GET_POSITION_ORDERS } from '../../../graphql/Catalog/PositionOrders/positionOrders'
import DatesOptions from '../../Global/DatesOptions'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'

export const PositionOrders = () => {
  const [pagePagination, setPagePagination] = useState({
    type: 2,
    limit: 10,
    offset: 0,
    date: null,
    searchQuery: null,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_POSITION_ORDERS, {
    variables: {
      type: pagePagination.type,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      date: pagePagination.date,
      searchQuery: pagePagination.searchQuery,
    },
    fetchPolicy: 'no-cache',
  })

  const [dataToTable, setDataToTable] = useState([])

  useEffect(() => {
    console.log({ data })
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getAllPositionOrders.rows.map((position) => {
        const originRack =
          position?.type_id === 2 ? 'Staging' : position?.RackPosition?.name
        const status =
          position?.is_open === true && position?.user_id !== null
            ? 'Completado'
            : position?.is_open === false && position?.user_id !== null
            ? 'En proceso'
            : position?.user_id === null && 'Pendiente'

        return list.push({
          id: position.id,
          'Fecha creación': position?.creation_date,
          // 'Fecha creación': moment(position?.creation_date).format('DD/MMMM/YYYY'),
          'Rack origen': originRack,
          'Total productos': position.total_products,
          Estatus: status,
          Tipo: position?.type_id === 1 ? 'Rellenado' : 'Posicionado',
        })
      })
      setDataToTable(list)
      setTotal(data.getAllPositionOrders.count)
    }
    // eslint-disable-next-liconsole.log(e)ne react-hooks/exhaustive-deps
  }, [loading, error, data, total, pagePagination])

  const filterOnChange = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      type: parseInt(filterSelected),
    })
  }

  const filterOnChangeSecond = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  return (
    <>
      {/* <ContentHeader
        title="Posición de órdenes"
        breadcrumb="Posiciones"
        windowTitle="Posiciones"
      /> */}
      <LayoutTable
        data={dataToTable}
        title="Posición de órdenes"
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        // hideFilterBar
        FilterBarPlaceholder="Buscar por ID"
        /** First selector */
        // filter
        // filterOnChange={filterOnChange}
        // filterOptions={[
        //   <option value="1" key="1">
        //     Rellenado
        //   </option>,
        //   <option value="2" key="2">
        //     Posicionado
        //   </option>,
        // ]}
        // filterLabelText="Por tipo"
        filter
        filterOnChange={filterOnChangeSecond}
        filterOptions={[<DatesOptions daysBefore={true} />]}
        filterLabelText="Por fecha"
      />
    </>
  )
}

export default PositionOrders
