import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '../../Global/Box'
import ContentHeader from '../../Layout/ContentHeader'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_ALL_WAREHOUSES,
  GET_ONE_WAREHOUSE,
  UPDATE_WAREHOUSE,
} from '../../../graphql/Catalog/Addresses/addressesWarehouses'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import InputController from '../../Global/InputController'
import { SEPOMEX_MUTATION } from '../../../graphql/Global/sepomex'
import { setZipNullValues, setZipValues } from '../../Helpers/setInputValues'

export const AddressWarehousesNew = () => {
  const { id: _id } = useParams()
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [colonies, setColonies] = useState([])
  const [nameWarehouse, setNameWarehouse] = useState('')
  const history = useHistory()
  const initialStateZip = {
    colonia: [],
    city: '',
    municipality: '',
    state: '',
    idCountry: '',
    idState: '',
    idCity: '',
    idMuicipality: '',
  }
  const [zip, setZip] = useState(initialStateZip)

  const [sepomexAdress] = useMutation(SEPOMEX_MUTATION)

  const [updateWarehouse] = useMutation(UPDATE_WAREHOUSE, {
    refetchQueries: [
      {
        query: GET_ALL_WAREHOUSES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_ONE_WAREHOUSE,
        variables: { getAddressWarehousesId: parseInt(_id) },
      },
    ],
  })

  const {
    data: dataWarehouse,
    loading: loadingWarehouse,
    error: errorWarehouse,
  } = useQuery(GET_ONE_WAREHOUSE, {
    variables: {
      getAddressWarehousesId: parseInt(_id),
    },
  })

  const validationSchema = Yup.object().shape({
    direction: Yup.string().required('Este campo es obligatorio'),
    outdoorNumber: Yup.string().required('Este campo es obligatorio'),
    postalCode: Yup.string()
      .min(4, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
      .max(6, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
      .required('Este campo es obligatorio'),
    colonia: Yup.string().required('Este campo es obligatorio'),
    city: Yup.string().required('Este campo es obligatorio'),
    municipality: Yup.string().required('Este campo es obligatorio'),
    state: Yup.string().required('Este campo es obligatorio'),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!loadingWarehouse) {
      if (_id) {
        if (errorWarehouse) {
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'error',
            message: errorWarehouse.message,
          })
        }
        setNameWarehouse(
          `${dataWarehouse?.getAddressWarehouses?.warehouse_name} ${dataWarehouse?.getAddressWarehouses?.warehouse_code}`
        )
        const address = dataWarehouse?.getAddressWarehouses?.addressWarehouse
        if (address) {
          const dataFormated = {
            direction: `${address?.street}`,
            outdoorNumber: `${address?.external_number}`,
            interiorNumber: `${address?.internal_number}`,
            city: `${address?.city?.name}`,
            municipality: `${address?.municipality?.name}`,
            state: `${address?.state?.name}`,
            colonia: `${address?.colony?.id}`,
            postalCode: `${address?.zip_code}`,
          }
          reset(dataFormated)
          getAddress(address?.zip_code)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataWarehouse, errorWarehouse])

  useEffect(() => {
    if (zip.colonia?.length > 0) {
      if (dataWarehouse !== undefined && dataWarehouse !== null) {
        setValue(
          'colonia',
          dataWarehouse.getAddressWarehouse.addressWarehouse.colony.id
        )
      } else {
        setValue('colonia', zip.colonia[0].id_colony)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip, dataWarehouse])

  const getAddress = async (zipCode) => {
    try {
      const { data } = await sepomexAdress({
        variables: {
          zipCode: parseInt(zipCode),
        },
      })
      setZipValues(setValue, setColonies, data.getFullAddressByZipcode, setZip)
    } catch (error) {
      setZipNullValues(setValue)
      toastSweetAlert({
        mode: 'error',
        message: 'Por favor ingrese un código postal válido',
      })
      setZip(initialStateZip)
    }
  }

  const onSubmit = async (Data) => {
    try {
      setLoadingBtn(true)
      if (_id) {
        await updateWarehouse({
          variables: {
            warehouseId: parseInt(_id),
            input: {
              street: Data.direction,
              external_number: Data.outdoorNumber,
              internal_number: Data.interiorNumber,
              id_country: parseInt(zip.idCountry),
              id_state: parseInt(zip.idState),
              id_city: parseInt(zip.idCity),
              id_municipality: parseInt(zip.idMunicipality),
              id_colony: parseInt(Data.colonia),
              zip_code: parseInt(Data.postalCode),
              state: Data.state,
              city: Data.city,
              municipality: Data.municipality,
              colony: Data.colonia_name,
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Almacén actualizado correctamente',
          }),
          history.push(`/addresses/warehouses`)
        )
      }
      setLoadingBtn(false)
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  return (
    <>
      {/* <ContentHeader
        title="Almacenes"
        breadcrumb="Almacenes"
        windowTitle="Editar dirección"
      /> */}
      <FormProvider {...methods}>
        <form
          className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title={`${_id ? 'Editar' : 'Agregar'} dirección de almacén`}
            btnRedPath="/addresses/warehouses"
            btnRedTxt="Cancelar"
            errors={errors}
            btnSubmit={true}
            btnLoading={loadingBtn}
            content={
              <>
                <div className="row">
                  <div className="mb-3 mt-3 col-12">
                    <h2>{nameWarehouse}</h2>
                  </div>
                  <div className="mb-3 col-12">
                    <hr />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Calle"
                      type="text"
                      name="direction"
                      placeholder="Ingrese una calle"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Numero exterior"
                      type="text"
                      name="outdoorNumber"
                      placeholder="# ext."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Numero interior"
                      type="text"
                      name="interiorNumber"
                      placeholder="# int."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="C. P."
                      type="text"
                      name="postalCode"
                      placeholder="Ingrese un C. P."
                      control={control}
                      blurAction={(e) => {
                        getAddress(e.target.value)
                      }}
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Colonia"
                      type="text"
                      name="colonia"
                      inputType="choosen"
                      changeAction={(e) => setValue('colonia_name', e.label)}
                      options={colonies}
                      control={control}
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Ciudad"
                      readOnly
                      type="text"
                      name="city"
                      placeholder="Ciudad"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Municipio"
                      readOnly
                      type="text"
                      name="municipality"
                      placeholder="Municipio"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                    <InputController
                      label="Estado"
                      readOnly
                      type="text"
                      name="state"
                      placeholder="Estado"
                      control={control}
                    />
                  </div>
                  {/* hidden input */}
                  <InputController
                    label=""
                    type="text"
                    hidden
                    name="colonia_name"
                    control={control}
                  />
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}
export default AddressWarehousesNew
