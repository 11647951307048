import { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { DELETE_TRANSFER_LINE, GET_TRANSFER_LINES_BY_TRANSFER_REQUEST } from "../../../../graphql/Catalog/Transfers/Transfers"
import { toastSweetAlert } from "../../../Helpers/ToastSweetAlert"
import trashIcon from '../../../../Assets/icons/trash.svg'
import editIcon from '../../../../Assets/icons/edit.svg'
import { useTransferContext } from "./TransferContext"
import LayoutTable from "../../../Global/LayoutTable"
const ProductsToTransfer = ({ control, _id, mutationGetTransferLines, loadingTransferLines }) => {
    const [dataToTable, setDataToTable] = useState([]);

    //ESTADOS COMPARTIDOS
    const {
        warehouseOriginSelected,
        dataRequest,
        refModal,
        dataLines,
        pagePagination,
        total,
        setDataItem,
        setPagePagination
    } = useTransferContext()
    /////

    const [deleteTransferLine] = useMutation(DELETE_TRANSFER_LINE, {
        refetchQueries: [
            GET_TRANSFER_LINES_BY_TRANSFER_REQUEST,
            {
                variables: {
                    getTransferLinesByTransferRequestId: parseInt(_id),
                    warehouseCode: warehouseOriginSelected
                        ? warehouseOriginSelected
                        : dataRequest?.from_whs_code,
                },
            },
        ],
    })

    useEffect(() => {
        if (dataLines.length > 0) {
            const list = []
            dataLines.map(item => {
                list.push({
                    id: item?.itemCode + 1,
                    SKU: item?.itemCode,
                    "Descripción de artículo": item?.itemName,
                    cajas: item?.quantityBoxes,
                    botellas: item?.quantityBottles,
                    "Piezas totales": item?.quantity,
                    cajas_omitField: item?.cajasHide,
                    unidad_omitField: item?.unidadHide,
                    nombre_omitField: item?.itemName,
                    stock_omitField: item?.stock

                })

            })
            setDataToTable(list)
        } else {
            setDataToTable([])
        }


    }, [dataLines]);


    const refetchMutationLines = async () => {
        try {
            await mutationGetTransferLines({
                variables: {
                    // getTransferLinesByTransferRequestId: parseInt(_id),
                    mutationGetTransferLinesByTransferRequestId: parseInt(_id),
                    warehouseCode: warehouseOriginSelected
                        ? warehouseOriginSelected
                        : dataRequest?.from_whs_code,
                    searchQuery: null,
                    limit: 10,
                    offset: 0
                },
            })
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }


    const handleDelete = async (row) => {
        // console.log(row, "eliminar")
        const responseRePrint = await toastSweetAlert({
            mode: 'modalConfirmCancel',
            icon: 'warning',
            message: 'Esta acción no se podra revertir',
            title: "¿Eliminar registro?"
        })
        if (responseRePrint.isConfirmed) {
            const itemCode = row.SKU
            try {
                await deleteTransferLine({
                    variables: {
                        deleteTransferLineRequestId: parseInt(_id),
                        itemCode,
                    },
                })
                await refetchMutationLines()
                toastSweetAlert({
                    mode: "ok",
                    message: "Registro eliminado"
                })

            } catch (error) {
                //   setLoadingBtn(false)
                return toastSweetAlert({
                    mode: 'error',
                    message: error.message,
                })
            }
        }



    }
    const editTransferline = (row) => {
        setDataItem({
            CajasHide: row.cajasHide,
            Nombre: row.nombre_omitField,
            SKU: row.SKU,
            Stock: row.stock_omitField,
            UnidadHide: row.unidad_omitField,
            id: row.id,
            quantityBoxes:
                row.cajas,
            quantityBottles:
                row.botellas,
            quantity: row["Piezas totales"],
        })
        refModal.current.click()
    }

    return (

        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title header-title">
                        Productos a traspasar
                    </h3>
                </div>
                <div className="">
                    {total > 0 && (
                        <h5 className="text-center my-4">Total de productos a traspasar: {total} </h5>
                    )}
                    <LayoutTable
                        // data={dataLines}
                        hideId
                        data={dataToTable}
                        hideDetails
                        hideAddNew
                        loadingData={loadingTransferLines}
                        hideBtnEdit
                        hideBtnErase
                        exportImportButton={false}
                        withCard={false}
                        pagePagination={pagePagination}
                        setPagePagination={setPagePagination}
                        totalCount={total}
                        customActionButtons={[
                            {
                                className: 'btn btn-sm btn-accept-white',
                                handleButton: editTransferline,
                                buttonText: <img src={editIcon} alt="edit" className="icon3" />,
                            },
                            {
                                className: 'btn btn-sm btn-accept-white',
                                handleButton: handleDelete,
                                buttonText: <img src={trashIcon} alt="delete" className="icon3" />,
                            },
                        ]}

                    />
                </div>
            </div>

        </>

    )

}

export default ProductsToTransfer;