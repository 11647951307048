import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  //business partner
  PROVIDERS_BUSINESS_PARTNER_BY_USER_ID,
  PROVIDERS_BUSINESS_PARTNER,
  PROVIDERS_BUSINESS_PARTNER_ASSIGNATION,
} from '../../../graphql/Catalog/SAP/ProvidersBusinessPartner'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import BusinessPartnerLevels from './businessPartnerLevels'
import StoreWarehouseAssignation from './storeWarehousesAssignation'

const BusinessPartnerEdit = ({ _id, show }) => {
  const [dataToTable, setDataToTable] = useState([])
  const [businessPartnerIds, setBusinessPartnerIds] = useState([])
  const [businessPartnerByUserID, setBusinessPartnerByUserID] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const history = useHistory()

  //GET ALL BUSINESS PARTNER
  const {
    data: businessPartnerData,
    error,
    loading,
  } = useQuery(PROVIDERS_BUSINESS_PARTNER, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })
  const [businessPartnerAssignation] = useMutation(
    PROVIDERS_BUSINESS_PARTNER_ASSIGNATION,
    {
      refetchQueries: [
        {
          query: PROVIDERS_BUSINESS_PARTNER_BY_USER_ID,
          variables: {
            id: +_id,
          },
          fetchPolicy: 'no-cache',
        },
        { query: PROVIDERS_BUSINESS_PARTNER, fetchPolicy: 'no-cache' },
      ],
    }
  )

  //GET BUSINESS PARTNER BY USER ID
  const {
    data: businessPartnerByIdData,
    error: businessPartnerByIdError,
    loading: businessPartnerByIdLoading,
  } = useQuery(PROVIDERS_BUSINESS_PARTNER_BY_USER_ID, {
    variables: {
      id: +_id,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    try {
      if (_id && !businessPartnerByIdLoading && !loading) {
        if (businessPartnerByIdError)
          return toastSweetAlert({
            mode: 'error',
            message: businessPartnerByIdError.message,
          })
        const list =
          businessPartnerByIdData.getAllBusinessPartnerByUserId.rows.map(
            (item) => {
              return {
                id: item.business_partner_id,
                nombre:
                  item.SapBusinessPartnerToCatalogUserBusinessPartner.card_name,
              }
            }
          )
        setBusinessPartnerByUserID(list)
        const listBusinessPartner =
          businessPartnerData.getAllBusinessPartner.rows.map((item) => {
            handleChecked(item.id)
            return {
              id: item.id,
              nombre: item.card_name,
            }
          })

        setDataToTable(listBusinessPartner)
        setTotal(businessPartnerData.getAllBusinessPartner.count)
      }
    } catch (error) {
      return toastSweetAlert({
        message: error.message,
        mode: 'error',
      })
    }
  }, [
    businessPartnerData,
    error,
    loading,
    businessPartnerByIdData,
    businessPartnerByIdError,
    businessPartnerByIdLoading,
  ])

  //create / deleted business partner assignation
  const handleCheckBox = async (id) => {
    setBusinessPartnerIds((prevState) => {
      const findItem = prevState.find((item) => item == id)

      if (findItem) {
        const list = prevState.filter((item) => item !== id)
        setBusinessPartnerIds(list)
      } else {
        return [...prevState, id]
      }
    })
  }

  //handle checked property
  const handleChecked = (id) => {
    const businessPartnerAssigned = businessPartnerByUserID.find(
      (item) => item.id == id
    )
    const busibessPartnerChecked = businessPartnerIds.find(
      (businessId) => businessId == id
    )

    return businessPartnerAssigned || busibessPartnerChecked ? true : false
  }

  const handleSubmit = async () => {
    try {
      const { data } = await businessPartnerAssignation({
        variables: {
          userId: +_id,
          businessPartnerId: businessPartnerIds,
        },
        refetchQueries: [
          {
            query: PROVIDERS_BUSINESS_PARTNER_BY_USER_ID,
            variables: {
              id: +_id,
            },
            fetchPolicy: 'no-cache',
          },
          { query: PROVIDERS_BUSINESS_PARTNER, fetchPolicy: 'no-cache' },
        ],
      })
      history.push('/businesspartner')
    } catch (error) {
      return toastSweetAlert({
        message: error.message,
        mode: 'error',
      })
    }
  }

  return (
    <>
      <Box
        title="Listado de socios de negocios"
        // btnRedPath="/businesspartner"
        // btnRedTxt="Cancelar"
        // btnSubmit={handleSubmit}
        // btnSubmitText="Actualizar socios"
        content={
          <>
            <LayoutTable
              data={dataToTable}
              hideDetails
              checkbox
              handleCheckbox={handleCheckBox}
              handleCheckedInputProperty={handleChecked}
              setPagePagination={setPagePagination}
              pagePagination={pagePagination}
              totalCount={total}
              withCard={false}
              loadingData={loading}
            />

            <StoreWarehouseAssignation submitBusinessPartner={handleSubmit} />
          </>
        }
      />

      <BusinessPartnerLevels />
    </>
  )
}

export default BusinessPartnerEdit
