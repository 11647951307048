import React, {useEffect, useState, useContext} from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import DataTable from 'react-data-table-component'
import { useQuery } from '@apollo/client'
import { GET_LAST_PRODUCTS_UPDATED } from '../../../graphql/Catalog/Telemarketing/Orders/orders'
import { GET_CUSTOMERS } from '../../../graphql/Catalog/Telemarketing/Customers/customers'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { AuthContext } from '../../../Auth/AuthContext'
import Chart from '../../Global/Charts/Chart'
import SimpleBox from '../../Global/SimpleBox'
import GrowthIndicator from '../../Global/GrowthIndicator/GrowthIndicator'

export const TelemarketingDashboard = () => {
    const { user } = useContext(AuthContext)

    //API get last 5 products updated
    const { loading: loadingLastProd, error: errorLastProd, data: dataLastProd } = useQuery(GET_LAST_PRODUCTS_UPDATED, {
        variables:{
            warehouseCode: user.warehouse_code ? user.warehouse_code : "270",
        } 
    })

    const columnsLastProductsUpdated = [
    {
        name: "SKU",
        selector: row => row.id,
        width: "100px"
    },
    {
        name: "PRODUCTO",
        selector: row => row.name,
        width: "400px"
    },
    {
        name: "CANTIDAD",
        selector: row => row.quantity,
        width: "120px"
    },
    ]

    const [dataLastProductsUpdated, setDataLastProductsUpdated] = useState([])

    //API get last 5 customers created
    const { loading: loadingLastCus, error: errorLastCus, data: dataLastCus } = useQuery(GET_CUSTOMERS, {
    variables:{
        limit: 5,
        offset: 0
    }
    })

    const columnsLastCustomers = [
    {
        name: "ID",
        selector: row => row.id,
    },
    {
        name: "NOMBRE",
        selector: row => row.name,
    },
    ]

    const [dataLastCustomers, setDataLastCustomers] = useState([])

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
    const [currentDay, setCurrentDay] = useState(new Date().getDate());

    const optionsChartNewCustomers = {
        chart:{
            type: "line"
        },
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            },
        },
        xAxis: {
            type: 'datetime'
        },
        series: [
            {   
                name: "Esta semana",
                data: [90, 100, 110, 120, 125, 128, 139, 120],
                color: "#FFD186"

            },
            {
                name: "Semana pasada",
                data: [60, 70, 80, 85, 90, 91, 99, 70],
                color: "#C3353F"
            }
        ],
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: Date.UTC(currentYear, currentMonth - 1, currentDay),
                pointInterval: 24 * 3600 * 1000 // one day        
            }
        },
    }

    const optionsChartOrders = {
        chart:{
            type: "column"
        },
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis:{
            categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio"]
        },
        series: [
            {   name: "Este año",
                data: [60, 70, 40, 90, 50, 80, 60],
                color: "#FFD186"
            },
            {
                name: "Año pasado",
                data: [50, 40, 10, 40, 30, 75, 40],
                color: "#C3353F"
            }
        ]
    }

    useEffect(() => {
        if (!loadingLastProd) {
            let list = []
            dataLastProd.getLastProductsUpdated.map((item) => {
                return list.push({
                    id: item.item_code,
                    name: item.SapItems[0].item_name,
                    quantity: item.on_hand,
                })
            })
            setDataLastProductsUpdated(list)
        }
    }, [dataLastProd])

    useEffect(() => {
        if (!loadingLastCus) {
            let list = []
            dataLastCus.getAllCustomers.rows.map((item) => {
                return list.push({
                    id: item.id,
                    name: item.first_name + " " + item.last_name,
                })
            })
            setDataLastCustomers(list)
        }
    }, [dataLastCus])

    useEffect(() => {
        if(currentDay - 7 > 0){
            setCurrentDay(currentDay - 7)
        }else if(currentMonth - 1 > 0){
            setCurrentMonth(currentMonth - 1)
            let daysofMonth = new Date(2022, currentMonth - 1, 0).getDate();
            setCurrentDay(daysofMonth - 7)
            // console.log(currentYear + " " + (currentMonth - 1) + " " + (daysofMonth - 7));
        }
        else{
            setCurrentYear(currentYear - 1)
            let daysofMonth = new Date(2022, 12, 0).getDate();
            setCurrentMonth(12)
            setCurrentDay(daysofMonth - 7)
            // console.log(currentYear - 1 + " " + 12 + " " + (daysofMonth - 7));
        }  // console.log(currentYear + " " +currentMonth + " " + (currentDay- 7));
    }, [])

    return (
    <>
        {/* <ContentHeader
            title="Dashboard"
            breadcrumb="Dashboard Telemarketing"
            windowTitle="Dashboard"
        /> */}

        {/* charts new customers per week and table with the 5 most recent customers */}
        <div className='row'>
            <SimpleBox 
                headerButton={true}
                headerButtonTitle={<><i className="fa fa-clipboard"></i> Ver reporte</>}
                cardTitle='Nuevos clientes'
                content={
                    <>
                        <GrowthIndicator 
                            actualData={optionsChartNewCustomers.series[0].data}
                            oldData={optionsChartNewCustomers.series[1].data}
                        />
                        <Chart options={optionsChartNewCustomers} />
                    </>
                }
            />
            <SimpleBox 
                cardTitle='Clientes recientes'
                loading={loadingLastCus}
                error={errorLastCus}
                content={
                    <>
                        <DataTable 
                            columns={columnsLastCustomers}
                            data={dataLastCustomers}
                        />
                    </>
                }
            />
        </div>

        {/* chart orders per month and table with the latest updated products */}
        <div className='row'>
            <SimpleBox 
                headerButton={true}
                headerButtonTitle={<><i className="fa fa-clipboard"></i> Ver reporte</>}
                cardTitle='Pedidos'
                content={
                    <>
                        <GrowthIndicator 
                            actualData={optionsChartOrders.series[0].data}
                            oldData={optionsChartOrders.series[1].data}
                        />
                        <Chart options={optionsChartOrders} />
                    </>
                }
            />

            <SimpleBox 
                cardTitle='Últimas piezas'
                loading={loadingLastProd}
                error={errorLastProd}
                content={
                    <>
                        <DataTable
                            columns={columnsLastProductsUpdated}
                            data={dataLastProductsUpdated}
                        />
                    </>
                }
            />
        </div>
    </>
    )
}

export default TelemarketingDashboard
