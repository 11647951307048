import { useMutation, useQuery } from "@apollo/client";
import ModalContent from "../../../Global/ModalContent";
import { GET_VEHICLES } from "../../../../graphql/Catalog/Vehicles/vehicles";
import { useEffect, useState } from "react";
import { toastSweetAlert } from "../../../Helpers/ToastSweetAlert";
import { UPDATE_VEHICLE_ASSIGNATION } from "../../../../graphql/Catalog/Routes/routes";
import LayoutTable from "../../../Global/LayoutTable";

const UpdateVehicleAssignation = ({ refModal, routeId }) => {
    const [vehicles, setVehicles] = useState([])
    const [vehicle, setVehicle] = useState(0)
    const [updateVehicleAssignation] = useMutation(UPDATE_VEHICLE_ASSIGNATION)
    const [total, setTotal] = useState(0)
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })
    const { loading: vehiclesLoading, error: vehiclesError, data: vehiclesData } = useQuery(GET_VEHICLES, {
        variables: {
            limit: pagePagination.limit,
            offset: pagePagination.offset,
            searchQuery: pagePagination.searchQuery
        },
        fetchPolicy: "no-cache"
    })
    useEffect(() => {
        if (!vehiclesLoading) {
            if (vehiclesError) {
                return toastSweetAlert({
                    mode: "error",
                    message: vehiclesError.message
                })
            }
            // console.log(vehiclesData, "VEHICULOS")
            const list = vehiclesData?.getAllVehicles?.rows.map(item => ({
                id: item?.id,
                unidad: `${item?.model} - ${item?.plates_number}`,
            }))

            setTotal(vehiclesData?.getAllVehicles?.count)
            setVehicles(list)

        }


    }, [
        vehiclesLoading,
        vehiclesError,
        vehiclesData
    ]);





    const handleButton = async () => {
        if (vehicle == 0) return
        try {
            const { data } = await updateVehicleAssignation({
                variables: {
                    routeId: +routeId,
                    vehicleId: vehicle
                }
            })
            if (data) {
                refModal.current.click()
                return toastSweetAlert({
                    mode: "ok",
                    message: "Asignacion de vehículo actualizada con exito"
                })
            }

        } catch (error) {
            return toastSweetAlert({
                mode: "error",
                message: error.message
            })
        }


    }


    return (
        <>
            <span
                ref={refModal}
                className="btn btn-accept btn-sm invisible"
                data-toggle="modal"
                data-target="#vehicleAssignation"
                data-backdrop="static"
                data-keyboard="false"
                data-dismiss="modal"
            />
            <ModalContent
                idModal="vehicleAssignation"
                title="Asignar Vehículo"
                size="large"
                content={
                    <>
                        <LayoutTable
                            hideAddNew
                            exportImportButton={false}
                            data={vehicles}
                            loadingData={vehiclesLoading}
                            totalCount={total}
                            pagePagination={pagePagination}
                            setPagePagination={setPagePagination}
                            hideBtnErase
                            hideDetails
                            hideId
                            hideBtnEdit
                            radio
                            handleRadio={(id) => { setVehicle(+id) }}
                        // handleCheckedRadioProperty={}
                        />

                        <div className="d-flex justify-content-end ">
                            <button
                                className="btn StandarModalConfirmButtonLogOut"
                                onClick={handleButton}
                            >
                                Asignar vehículo
                            </button>
                        </div>
                    </>
                }
            />
        </>

    )

}

export default UpdateVehicleAssignation;