import '../../Styles/Tooltip.scss'

const Tooltip = ({
    text,
    content,
}) => {
    return (
        <div className="tooltip2">
            { content }
            <span className="tooltiptext">{ text }</span>
        </div>
    )
}

export default Tooltip