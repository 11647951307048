import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import {
  GET_BOXES_TOTAL,
  GET_NEXT_SCHEDULES,
  GET_PALLETS_TOTAL,
  GET_PENDING_SCHEDULE,
  GET_RAMPS_DOWNLOADS,
  GET_SCHEDULES_TOTAL,
  GET_TOP_PROVIDERS,
} from '../../../graphql/Catalog/WarehouseDashboard/receiptDashboard'
import { CREATE_WAREHOUSES_REPORTS } from '../../../graphql/Catalog/WarehouseDashboard/warehouseDashboard'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { validateDatesForCreateReports } from '../../WarehouseDashboards/warehouseOperationValidations'
import ModalWarehouseReports from '../Global/ModalWarehouseReports'
import ReceiptDashboardSection from './components/ReceiptDashboardSection'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import moment from 'moment'
import { GET_POWER_BI_TOKEN } from '../../../graphql/Documents/PowerBi/powerBi'
import HelperGenerateExcel from '../../../helpers/HelperGenerateExcel'
import { DOWNLOAD_RECEIVE_REPORT } from '../../../graphql/Documents/CommodityReceipt/commodityReceipt'

const ReceiptDashboard = () => {
  /**-------- Hooks */
  //To save reports data
  const [form, setForm] = useState({
    start: '',
    end: '',
    reportsType: 0,
  })
  /**Hook to show inputs dates */
  const refModal = useRef()

  const [loading, setLoading] = useState(true)
  const [accessToken, setAccessToken] = useState('')
  const [GetReceiveReport] = useLazyQuery(DOWNLOAD_RECEIVE_REPORT)
  const [downloadReport, setDownloadReport] = useState([])
  //To save all forms for sections
  // const [firstSection,setFirstSection] = useState([])
  // const [secondSection,setSecondSection] = useState([])

  /**------------ Queries */
  // const {
  //   data: dataPendingSchedule,
  //   loading: loadingPendingSchedule,
  //   error: errorPendingSchedule,
  // } = useQuery(GET_PENDING_SCHEDULE)

  // const {
  //   data: dataNextSchedule,
  //   loading: loadingNextSchedule,
  //   error: errorNextSchedule,
  // } = useQuery(GET_NEXT_SCHEDULES)

  // const {
  //   data: dataTopProviders,
  //   loading: loadingTopProviders,
  //   error: errorTopProviders,
  // } = useQuery(GET_TOP_PROVIDERS)

  // const {
  //   data: dataRampsDownloads,
  //   loading: loadingRampsDownloads,
  //   error: errorRampsDownloads,
  // } = useQuery(GET_RAMPS_DOWNLOADS)

  // const {
  //   data: dataBoxesTotal,
  //   loading: loadingBoxesTotal,
  //   error: errorBoxesTotal,
  // } = useQuery(GET_BOXES_TOTAL)

  // const {
  //   data: dataPalletsTotal,
  //   loading: loadingPalletsTotal,
  //   error: errorPalletsTotal,
  // } = useQuery(GET_PALLETS_TOTAL)

  // const {
  //   data: dataSchedulesTotal,
  //   loading: loadingSchedulesTotal,
  //   error: errorSchedulesTotal,
  // } = useQuery(GET_SCHEDULES_TOTAL)

  const {
    data: dataPowerBiToken,
    loading: loadingPowerBiToken,
    error: errorPowerBiToken,
  } = useQuery(GET_POWER_BI_TOKEN)

  /**------------ Mutations */
  const [CreateWarehousesReports] = useMutation(CREATE_WAREHOUSES_REPORTS)

  /**------------ UseEffects */
  useEffect(() => {
    if (
      // errorPendingSchedule ||
      // errorNextSchedule ||
      // errorTopProviders ||
      // // errorRampsDownloads ||
      // errorBoxesTotal ||
      // errorPalletsTotal ||
      // errorSchedulesTotal
      errorPowerBiToken
    )
      return toastSweetAlert({
        mode: 'error',
        message: 'something has happend',
      })

    if (
      // !loadingPendingSchedule &&
      // !loadingNextSchedule &&
      // !loadingTopProviders &&
      // // !loadingRampsDownloads &&
      // !loadingBoxesTotal &&
      // !loadingPalletsTotal &&
      // !loadingSchedulesTotal
      !loadingPowerBiToken
    ) {
      // //Get graphics
      // let resBoxes = []
      // dataBoxesTotal?.getBoxesTotal?.boxesDataSevenDaysAgo?.map((itemBox)=> {
      //   resBoxes.push({
      //     name: itemBox.date,
      //     y: itemBox.quantity,
      //     drilldown: itemBox.date
      //   })
      // })

      // let resPallets = []
      // dataPalletsTotal?.getPalletsTotal?.palletsDataSevenDaysAgo?.map((itemPallet)=> {
      //   resPallets.push({
      //     name: itemPallet.date,
      //     y: itemPallet.quantity,
      //     drilldown: itemPallet.date
      //   })
      // })

      // let resSchedule = []
      // dataSchedulesTotal?.getSchedulesTotal?.schedulesDataSevenDaysAgo?.map((itemSchedule)=> {
      //   resSchedule.push({
      //     name: itemSchedule.date,
      //     y: itemSchedule.quantity,
      //     drilldown: itemSchedule.date
      //   })
      // })

      // /**Fill first section */
      // setFirstSection({
      //   pendingSchedules: dataPendingSchedule.getPendingSchedule.quantity, //Citas pendientes
      //   nextSchedules: dataNextSchedule.getNextSchedules.quantity,//Citas proximas
      //   topTenProviders: dataTopProviders.getTopProviders,
      //   // rampsDownload: dataRampsDownloads.getRampsDownload,//Descargas por rampa

      // })

      // setSecondSection({
      //   totalBoxes: dataBoxesTotal.getBoxesTotal.quantity, //Cajas totales
      //   totalPallets: dataPalletsTotal.getPalletsTotal.quantity, //Pallets totales
      //   totalSchedule: dataSchedulesTotal.getSchedulesTotal.quantity, //Citas totales
      //   boxesData: resBoxes, //Data graphics for boxes
      //   palletsData: resPallets, //Data graphics for pallets
      //   scheduleData: resSchedule,//Data graphics for schedules
      // })

      //Get Access token
      const token = dataPowerBiToken?.getPowerBiToken?.token ? dataPowerBiToken.getPowerBiToken.token : null
      setAccessToken(token)

      setLoading(false)
    }
  }, [
    // //PendingSchedule
    // dataPendingSchedule,
    // loadingPendingSchedule,
    // errorPendingSchedule,

    // //NextSchedule
    // dataNextSchedule,
    // loadingNextSchedule,
    // errorNextSchedule,

    // //TopProviders
    // dataTopProviders,
    // loadingTopProviders,
    // errorTopProviders,

    // //RampsDownloads
    // // dataRampsDownloads,
    // // loadingRampsDownloads,
    // // errorRampsDownloads,

    // //BoxesTotal
    // dataBoxesTotal,
    // loadingBoxesTotal,
    // errorBoxesTotal,

    // //PalletsTotal
    // dataPalletsTotal,
    // loadingPalletsTotal,
    // errorPalletsTotal,

    // //SchedulesTotal
    // dataSchedulesTotal,
    // loadingSchedulesTotal,
    // errorSchedulesTotal,
    dataPowerBiToken,
    loadingPowerBiToken,
    loadingPowerBiToken,
  ])

  /**Functions  */
  const formmatNumber = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const reportFilterHandleButton = async () => {
    try {
      const { start, end, reportsType } = form

      if (reportsType == 5) {
        //Custom range
        //Validate data
        const getValidation = validateDatesForCreateReports({
          rangeStart: start,
          rangeEnd: end,
        })
        if (getValidation.error)
          return toastSweetAlert({
            mode: 'error',
            message: getValidation.msg,
          })
      }

      //Reset hook
      setForm({
        start: '',
        end: '',
        reportsType: 0,
      })
      refModal.current.click()

      //All correct - proceed
      toastSweetAlert({
        mode: 'loading',
        message: 'Iniciando proceso, puede tardar algunos minutos',
      })

      //Create mutation to send information
      const res = await CreateWarehousesReports({
        variables: {
          start,
          end,
          reportsType,
          mode: 1, //Receipt
        },
      })
      const { status, message } = res.data.createWarehousesReports

      if (!status)
        return toastSweetAlert({
          mode: 'error',
          message: message,
        })

      return toastSweetAlert({
        mode: 'ok',
        message: message,
      })
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleDownloadReport = async () => {
    try {
      const { data } = await GetReceiveReport()

      const list = data?.getReceiveReport.map((item) => {
        return {
          No: item?.id,
          'Fecha de cita': item?.schedules?.document_date,
          'Nombre del proveedor':
            item?.sap_purchases_orders?.business_partner?.card_name,
          'Hora de cita': item?.schedules?.document_time_start,
          'Orden de compra': item?.sap_purchases_orders?.document_entry,
          sku: item?.sap_purchases_orders_lines?.item_code,
          nombre: item?.sap_purchases_orders_lines?.LineProduct?.item_name,
          Cantidad: item?.receipt_quantity,
          Unidades: item?.items,
          Cajas: item?.boxes,
          Camas: item?.beds,
          'Residuo cajas': item?.residue_boxes,
          'Residuo botellas': item?.residue_bottles,
        }
      })

      setDownloadReport(list)
      document.getElementById('ExportExcel').click()
      // setDisabledHeaderButton(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Reporte generado correctamente',
        }
        // setLoadingBtn(false)
      )
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <div className="content-header">
        <div className="container-fluid">
          <div className="py-2 d-flex justify-content-between">
            <h1 className="font-weight-bold">Recibo</h1>
            <button
              type="button"
              className="btn StandarModalConfirmButtonLogOut"
              onClick={() => {
                refModal.current.click()
              }}
              // onClick={handleDownloadReport}
            >
              Generar reporte
            </button>
          </div>
        </div>
      </div>

      <HelperGenerateExcel
        id="ExportExcel"
        excelData={[
          {
            bookName: 'Reporte de Recibo',
            data: downloadReport,
            columnsWidth: [25, 25, 25, 25, 25, 25, 25, 25, 25],
          },
        ]}
        fileName="Reporte de Recibo"
      />

      {/**Generate report button for Warehouse */}
      <ModalWarehouseReports
        refModal={refModal}
        handleSubmit={reportFilterHandleButton}
        setForm={setForm}
        form={form}
      />

      {!loading && (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {/* <ReceiptDashboardSection 
                  formmatNumber={formmatNumber}
                  firstSection={firstSection}
                  secondSection={secondSection}
                /> */}

            <PowerBIEmbed
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                id: 'ed5a7e74-ddd2-4b06-8cad-fdedb289490a',
                embedUrl:
                  'https://app.powerbi.com/reportEmbed?reportId=8c030dcb-b8ae-4dfd-9796-1caf5592a145&groupId=57164b8e-8e11-4618-a744-30ca22b26d35&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUNBTkFEQS1DRU5UUkFMLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
                accessToken,
                tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    function () {
                      console.log('Report loaded')
                    },
                  ],
                  [
                    'rendered',
                    function () {
                      console.log('Report rendered')
                    },
                  ],
                  [
                    'error',
                    function (event) {
                      console.log(event.detail)
                    },
                  ],
                ])
              }
              cssClassName={'embed-container'}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ReceiptDashboard
