import { useParams } from 'react-router-dom'
import ContentHeader from '../Layout/ContentHeader'
import BusinessPartnerDetails from './components/businessPartnerDetails'
import BusinessPartnerEdit from './components/businessPartnerEdit'

const BusinessPartnerNew = () => {
  const { id: _id, show } = useParams()

  return (
    <>
      {/* <ContentHeader
        title={`${
          show
            ? 'Detalles del socio de negocio'
            : _id
            ? 'Socios de negocio'
            : 'Asignar socio de negocio'
        } `}
        breadcrumb="socios de negocio"
      /> */}

      {_id && !show && <BusinessPartnerEdit _id={_id} show={show} />}
      {show && _id ? <BusinessPartnerDetails show={show} _id={_id} /> : ''}
    </>
  )
}

export default BusinessPartnerNew
