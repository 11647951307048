import React, { useState, useContext, useEffect } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import { useQuery } from '@apollo/client'
import { GET_PRODUCTS_SAP_STOCK } from '../../../graphql/Catalog/Telemarketing/Orders/orders'
import { AuthContext } from '../../../Auth/AuthContext'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'


export const Existencias = () => {
  const { user } = useContext(AuthContext)

  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0);

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  //API get stock products
  const { loading, error, data } = useQuery(GET_PRODUCTS_SAP_STOCK, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      warehouseCode: user.warehouse_code,
    },
  })

  //save products of query
  useEffect(() => {
    if (!loading) {
      if (error) toastSweetAlert({
        mode:'error',
        message:error.message,
      })
      
      let list = []
      data.getProductSapStock.rows.map((item) => {
        let dataList = {}
        dataList.img_url = "http://http2.mlstatic.com/D_981113-MLM42366646822_062020-O.jpg"
        dataList.sku = item.item_code

        item.SapItems.map((sapItem)=>{
          dataList.product_add = sapItem.item_name
        })
        
        dataList.existencia = item.on_hand
        return list.push(dataList)
      })
      setDataToTable(list)
      setTotal(data.getProductSapStock.count)
    }
  }, [error, data])

  return (
    <>
      {/* <ContentHeader
        title="Existencias"
        breadcrumb="Existencias"
        windowTitle="Existencias"
      /> */}

      <LayoutTable
        data={dataToTable}
        title="Existencias"
        headersButtons={false}
        hideActions={true}
        sizeProductName="400px"
        requery={GET_PRODUCTS_SAP_STOCK}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        FilterBarPlaceholder= "Buscar"
      />
    </>
  )
}

export default Existencias
