import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, set, useForm } from 'react-hook-form'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import ContentHeader from '../Layout/ContentHeader'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import Box from '../Global/Box'
// apollo
import {
  GET_ITEMS_IN_STORE,
  GET_ITEMS_SELECTED_FOR_PRINT_LABELS,
} from '../../graphql/Catalog/SAP/sapItems'
import { GET_ALL_ITEM_GROUPS } from '../../graphql/Catalog/SAP/sapItems'
import LayoutTable from '../Global/LayoutTable'
import moment from 'moment'
import PrintLabels from './PrintLabels/PrintLabels'
import ReactToPrint from 'react-to-print'
import { useBarcode } from 'next-barcode'
import ModalContent from '../Global/ModalContent'
import ModalQuantityLabels from './ModalQuantityLabels/ModalQuantityLabels'
import OrderCol from '../Global/OrderCol'
import {
  GET_ALL_ITEMS_CODE_SELECTEDS_UNIQUE_PRICE_FOR_PRINT,
  GET_ALL_ITEMS_CODE_UNIQUE_PRICE_FOR_PRINT,
  GET_ALL_SO1_PROMO_PRICE_UNIQUE,
  GET_PROMO_UNIQUE_BY_ITEM_CODE_BARCODE,
} from '../../graphql/Catalog/SO1/so1prompriceunique'
import Swal from 'sweetalert2'
import { toastSweetAlertNotifications } from '../Helpers/ToastSweetAlertNotifications'
import {
  DISCOUNT_STYLES_TAGS_20,
  DISCOUNT_STYLES_TAGS_24,
} from '../Helpers/StylesToTagsForStores'
import InputController from '../Global/InputController'

export const UniquePriceTags = () => {
  const componentRef = useRef()
  const refModalQuantity = useRef()
  const onlyComponent = useRef()

  const [total, setTotal] = useState(0)
  const [printCount, setPrintCount] = useState(0)
  const [listOfItems, setListOfItems] = useState([])
  const [itemGroups, setItemGroups] = useState([])
  const [items, setItems] = useState([])
  const [listOfStoreItems, setListOfStoreItems] = useState([])
  const [skusSelected, setSkusSelected] = useState([])
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [barcodeValue, setBarcodeValue] = useState('1')
  const [isPrinting, setIsPrinting] = useState(false)
  const [printLabels, setPrintLabels] = useState(false)
  const [printingLabels, setPrintingLabels] = useState(false)
  const [labelsInformation, setLabelsInformation] = useState([])
  const [closeModal, setCloseModal] = useState(false)
  const [countSelected, setCountSelected] = useState(0)
  const [listPrice, setListPrice] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [typeLabel, setTypeLabel] = useState('') // vertical, horizontal, fridge
  const [printLabelsWithDifferentPrice, setPrintLabelsWithDifferentPrice] =
    useState(false)

  const [skuToSearch, setSkuToSearch] = useState('')
  const [skuDelete, setSkuDelete] = useState(false)
  const [clickComponent, setClickComponent] = useState(true)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { inputRef } = useBarcode({
    value: barcodeValue,
  })

  const formatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 2,
    }).format(number)

    return numberFormat
  }

  const { data: itemGroupsData } = useQuery(GET_ALL_ITEM_GROUPS)
  const [getItemInStore] = useLazyQuery(GET_ITEMS_IN_STORE)
  const [getItemsSelectedForPrintLabel] = useMutation(
    GET_ITEMS_SELECTED_FOR_PRINT_LABELS
  )

  // const { data, loading, error } = useQuery(GET_ALL_SO1_PROMO_PRICE_UNIQUE, {
  //   variables: {
  //     searchQuery: pagePagination.searchQuery,
  //     limit: pagePagination.limit,
  //     offset: pagePagination.offset,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const [getItemsUniquePrice] = useMutation(
    GET_ALL_ITEMS_CODE_SELECTEDS_UNIQUE_PRICE_FOR_PRINT
  )

  const [printAllLabels] = useMutation(
    GET_ALL_ITEMS_CODE_UNIQUE_PRICE_FOR_PRINT
  )

  const [getPromoUniqueByItemCodeBarcode] = useMutation(
    GET_PROMO_UNIQUE_BY_ITEM_CODE_BARCODE
  )

  const {
    methods,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm()

  // const handleClick = (event) => {
  //   if (onlyComponent.current && onlyComponent.current.contains(event.target)) {
  //     const searchQuery = document.getElementById('idSearchQuery')
  //     if (searchQuery) {
  //       searchQuery.focus()
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (clickComponent) {
  //     document.addEventListener('click', handleClick)
  //     return () => {
  //       document.removeEventListener('click', null)
  //     }
  //   }
  // }, [clickComponent])

  useEffect(() => {
    if (itemGroupsData?.getAllSapItemGroups) {
      const list = itemGroupsData.getAllSapItemGroups.map((item) => ({
        value: item.items_group_code,
        label: item.items_group_name,
      }))
      setItemGroups(list)
    }
  }, [itemGroupsData])

  useEffect(() => {
    if (closeModal) {
      const list = labelsInformation.map((itemLabel) => {
        return {
          id: itemLabel.id,
          SKU: itemLabel.SKU,
          Descripcion: itemLabel.Descripcion,
          Codigo: itemLabel.Codigo,
          date_until: itemLabel.date_until,
          Precio: itemLabel.Precio,
          discount_price: itemLabel.discount_price,
          inputRef: itemLabel.inputRef,
          quantity: 1,
        }
      })

      document.getElementById('idSearchQuery').focus()
      setTimeout(() => {
        setLabelsInformation(list)
        setCloseModal(false)
        document.getElementById('idSearchQuery').focus()
      }, 300)
    }
  }, [closeModal])

  useEffect(() => {
    try {
      const deleteItem = async () => {
        const result = await toastSweetAlert({
          mode: 'modalConfirmCancel',
          icon: 'warning',
          title: '¿Estas seguro de eliminar este producto de la lista?',
          message: 'No podras recuperar la informacion despues de eliminarla',
        })

        if (result.isConfirmed) {
          const list = labelsInformation.filter(
            (item) => item.SKU !== skuDelete
          )
          const listTable = listOfItems.filter((item) => item.SKU !== skuDelete)

          const listLabels = list.map((itemLabel, index) => {
            return {
              id: index + 1,
              SKU: itemLabel.SKU,
              Descripcion: itemLabel.Descripcion,
              Codigo: itemLabel.Codigo,
              date_until: itemLabel.date_until,
              Precio: itemLabel.Precio,
              discount_price: itemLabel.discount_price,
              inputRef: itemLabel.inputRef,
              quantity: 1,
            }
          })

          setTimeout(() => {
            setLabelsInformation(listLabels)
            setListOfItems(listTable)
            setSkuDelete(false)
            document.getElementById('idSearchQuery').focus()
            return toastSweetAlertNotifications({
              mode: 'ok',
              message: 'Articulo eliminado de la lista con exito',
              timer: 2000,
            })
          }, 500)
        }

        document.getElementById('idSearchQuery').focus()
        setSkuDelete(false)
      }

      if (skuDelete) {
        deleteItem()
        setSkuDelete(false)
        document.getElementById('idSearchQuery').focus()
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [skuDelete])

  useEffect(() => {
    try {
      const getItem = async () => {
        const existsItemBySKU = listOfItems.find(
          (item) => item.SKU === skuToSearch
        )

        const existsItemByBarcode = listOfItems.find(
          (item) => item.Codigo === skuToSearch
        )

        if (existsItemBySKU || existsItemByBarcode) {
          return setTimeout(() => {
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: 'El articulo ya se encuentra en la lista',
                timer: 2000,
              },
              setSkuToSearch(''),
              setValue('searchQuery', ''),
              setLoadingBtn(false),
              document.getElementById('idSearchQuery').focus()
            )
          })
        }

        const result = await getPromoUniqueByItemCodeBarcode({
          variables: {
            value: skuToSearch,
          },
          fetchPolicy: 'no-cache',
        })

        let barcode = '1'
        const item = result.data.getItemPromoUniqueByItemCodeBarcode.promo
        // console.log('item', item)

        const existsItemCode = listOfItems.find((itemSearch) => {
          return itemSearch.SKU === item.PromoItemToSapItems.item_code
        })

        // console.log('existsItemCode', existsItemCode)

        if (existsItemCode) {
          return setTimeout(() => {
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: 'El articulo ya se encuentra en la lista',
                timer: 2000,
              },
              setSkuToSearch(''),
              setValue('searchQuery', ''),
              setLoadingBtn(false),
              document.getElementById('idSearchQuery').focus()
            )
          })
        }

        if (item === null || item === undefined) {
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message: 'El articulo no existe',
              timer: 2000,
            },
            setSkuToSearch(''),
            setValue('searchQuery', ''),
            setLoadingBtn(false),
            document.getElementById('idSearchQuery').focus()
          )
        }

        const listPrice =
          result.data.getItemPromoUniqueByItemCodeBarcode.priceListItems

        const findListPrice = listPrice.filter(
          (price) => price.item_code === item.PromoItemToSapItems.item_code
        )

        findListPrice.sort((a, b) => {
          return a.price - b.price
        })

        const priceList =
          findListPrice.length === 1
            ? findListPrice[0].price_list
            : findListPrice.reduce((list1, list2) => {
                return list2.price >= list1.price ||
                  (list2.price <= list1.price && list2.price !== 0)
                  ? list2.price_list
                  : list1.price_list
              })

        const price = findListPrice.find(
          (element) => element.price_list == priceList
        )

        const listTable = listOfItems.map((itemTable) => {
          return {
            id: itemTable.id,
            SKU: itemTable.SKU,
            Descripcion: itemTable.Descripcion,
            Codigo: itemTable.Codigo,
            Precio: itemTable.Precio,
            'Precio único': itemTable['Precio único'],
            'En Stock': itemTable['En Stock'],
          }
        })

        const list = labelsInformation.map((itemLabel) => {
          return {
            id: itemLabel.id,
            SKU: itemLabel.SKU,
            Descripcion: itemLabel.Descripcion,
            Codigo: itemLabel.Codigo,
            date_until: itemLabel.date_until,
            Precio: itemLabel.Precio,
            discount_price: itemLabel.discount_price,
            inputRef: itemLabel.inputRef,
            quantity: 1,
          }
        })

        let product = item.PromoItemToSapItems

        if (product.SapItemsToSapBarCodeData && barcode === '1') {
          setBarcodeValue(product.SapItemsToSapBarCodeData.barcode)
          barcode = product.SapItemsToSapBarCodeData.barcode
        }

        if (product.item_code_bar !== '' && barcode === '1') {
          setBarcodeValue(product.item_code_bar)
          barcode = product.item_code_bar
        }

        if (barcode === '1') {
          setBarcodeValue('1')
        }

        const barcodeIMG = await handleGetImageFromBarcode(barcode)

        const priceWithTax = price.price

        // const priceWithTax = calculatePriceWithTax(
        //   findListPrice.price,
        //   item.PromoItemToSapItems
        // )

        listTable.push({
          id: item.PromoItemToSapItems.item_code,
          SKU: item.PromoItemToSapItems.item_code,
          Descripcion: item.PromoItemToSapItems.item_name,
          Codigo: barcode,
          Precio: formatterCurrency(priceWithTax),
          'Precio único': formatterCurrency(
            item.PromoItemToSouPromo.PromoToPromoUniquePrices.u_so1_amount
          ),
          // Stock: item.PromoItemToSapItems.SapItemsToStockOne.on_hand,
        })

        list.push({
          id: list.length + 1,
          SKU: item.PromoItemToSapItems.item_code,
          Descripcion: item.PromoItemToSapItems.item_name,
          Codigo: barcode,
          date_until: item.PromoItemToSouPromo.u_so1_dateuntil,
          Precio: priceWithTax,
          discount_price:
            item.PromoItemToSouPromo.PromoToPromoUniquePrices.u_so1_amount,
          inputRef: barcodeIMG,
          quantity: 1,
        })

        setListOfItems(listTable)
        setLabelsInformation(list)

        return toastSweetAlertNotifications(
          {
            mode: 'ok',
            message: 'Articulo agregado con exito',
            timer: 2000,
          },
          setSkuToSearch(''),
          setValue('searchQuery', ''),
          setLoadingBtn(false),
          document.getElementById('idSearchQuery').focus()
        )
      }

      if (skuToSearch !== '') {
        toastSweetAlert({
          mode: 'loading',
          message: 'Buscando articulo...',
        })
        getItem()
      }
    } catch (error) {
      console.log(error)
      return toastSweetAlert(
        {
          mode: 'error',
          message: error.message,
        },
        setSkuToSearch(''),
        setValue('searchQuery', ''),
        setLoadingBtn(false),
        document.getElementById('idSearchQuery').focus()
      )
    }
  }, [skuToSearch])

  // useEffect(() => {
  //   let list = []
  //   if (!loading) {
  //     if (error) {
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: error.message,
  //       })
  //     }
  //     // console.log('data', data.getAllUniquePrices)
  //     if (data) {
  //       data.getAllUniquePrices.rows.map((item) => {
  //         const findListPrice = data.getAllUniquePrices.priceListItems.find(
  //           (price) => price.item_code === item.PromoItemToSapItems.item_code
  //         )

  //         // console.log('findListPrice', findListPrice)

  //         return list.push({
  //           id: item.id,
  //           Promocion: item.PromoItemToSouPromo.Name,
  //           SKU: item.PromoItemToSapItems.item_code,
  //           Descripcion: item.PromoItemToSapItems.item_name,
  //           Codigo: item.PromoItemToSapItems.item_code_bar,
  //           // date_until: item.PromoItemToSouPromo.u_so1_dateuntil,
  //           Precio: formatterCurrency(
  //             calculatePriceWithTax(
  //               findListPrice.price,
  //               item.PromoItemToSapItems
  //             )
  //           ),
  //           'Precio único': item.PromoItemToSouPromo.PromoToPromoUniquePrices
  //             ? formatterCurrency(
  //                 item.PromoItemToSouPromo.PromoToPromoUniquePrices.u_so1_amount
  //               )
  //             : formatterCurrency(0),
  //           'En Stock': item.PromoItemToSapItems.SapItemsToStockOne.on_hand,
  //         })
  //       })
  //     }
  //     setListOfItems(list)
  //     setTotal(data.getAllUniquePrices.count)
  //   }
  // }, [data, loading, error])

  useEffect(() => {
    try {
      const getItems = async () => {
        //  toastSweetAlert({
        //    mode: 'loading',
        //    message: 'Imprimiendo etiquetas...',
        //  })
        // console.log('skusSelected', skusSelected)
        const result = await getItemsUniquePrice({
          variables: {
            idsUniquePricesSelecteds: skusSelected,
          },
          fetchPolicy: 'no-cache',
        })
        let list = []
        let listTable = []

        // console.log('result', result.data)

        const barcodeIs1 = barcodeValue === '1'

        if (
          result.data.getAllSkusSelectedsForPrintLablesUniquePrices.length === 0
        ) {
          return toastSweetAlertNotifications(
            {
              mode: 'info',
              message: 'No se encontraron articulos',
              timer: 1000,
            },
            setPrintLabels(false),
            setLabelsInformation([]),
            setListOfItems([]),
            setSkusSelected([]),
            setLoadingBtn(false)
          )
        }

        result.data.getAllSkusSelectedsForPrintLablesUniquePrices.map(
          async (item) => {
            let barcode = '1'
            const product = item.PromoItemToSapItems
            if (product.SapItemsToSapBarCodeData && barcode === '1') {
              setBarcodeValue(product.SapItemsToSapBarCodeData.barcode)
              barcode = product.SapItemsToSapBarCodeData.barcode
            }

            if (product.item_code_bar !== '' && barcode === '1') {
              setBarcodeValue(product.item_code_bar)
              barcode = product.item_code_bar
            }

            if (barcode === '1') {
              setBarcodeValue('1')
            }

            const barcodeIMG = await handleGetImageFromBarcode(barcode)

            let priceWithTax = 0

            item.PromoItemToSapItems.ItemPrices.sort((a, b) => {
              return a.price_list - b.price_list
            })

            console.log(
              'item.PromoItemToSapItems.ItemPrices',
              item.PromoItemToSapItems.ItemPrices
            )

            const priceList =
              item.PromoItemToSapItems.ItemPrices.length === 1
                ? item.PromoItemToSapItems.ItemPrices[0].price_list
                : item.PromoItemToSapItems.ItemPrices.reduce((list1, list2) => {
                    return list2.price >= list1.price ||
                      (list2.price <= list1.price && list2.price !== 0)
                      ? list2.price_list
                      : list1.price_list
                  })

            const price = item.PromoItemToSapItems.ItemPrices.find(
              (element) => element.price_list == priceList
            )

            priceWithTax = price.price

            // if (item.PromoItemToSapItems.ItemPrices) {
            //   for (let price of item.PromoItemToSapItems.ItemPrices) {
            //     priceWithTax = price.price

            //     // priceWithTax = calculatePriceWithTax(
            //     //   price.price,
            //     //   item.PromoItemToSapItems
            //     // )
            //   }
            // }

            listTable.push({
              id: item.PromoItemToSapItems.item_code,
              SKU: item.PromoItemToSapItems.item_code,
              Descripcion: item.PromoItemToSapItems.item_name,
              Codigo: barcode,
              Precio: formatterCurrency(priceWithTax),
              'Precio único': formatterCurrency(
                item.PromoItemToSouPromo.PromoToPromoUniquePrices.u_so1_amount
              ),
              // Stock: item.PromoItemToSapItems.SapItemsToStockOne.on_hand,
            })

            return list.push({
              id: list.length + 1,
              SKU: item.PromoItemToSapItems.item_code,
              Descripcion: item.PromoItemToSapItems.item_name,
              Codigo: barcode,
              date_until: item.PromoItemToSouPromo.u_so1_dateuntil,
              Precio: priceWithTax,
              discount_price:
                item.PromoItemToSouPromo.PromoToPromoUniquePrices.u_so1_amount,
              inputRef: barcodeIMG,
              quantity: 1,
            })
          }
        )
        setBarcodeValue('1')
        setPrintLabels(false)
        document.getElementById('idSearchQuery').focus()
        setTimeout(() => {
          setLabelsInformation(list)
          setListOfItems(listTable)
          setLoadingBtn(false)
          // document.getElementById('spanModalQuantity').click()
        }, 200)
        return toastSweetAlertNotifications({
          mode: 'ok',
          message: 'Articulos encontrados',
          timer: 1000,
        })
      }

      if (printLabels && !printingLabels) {
        setLabelsInformation([])
        setListOfItems([])
        getItems()
        setPrintCount(0)
      }
    } catch (e) {
      console.log(e)
      return toastSweetAlert(
        {
          mode: 'error',
          message: e.message,
        },
        setLoadingBtn(false),
        setPrintLabels(false),
        setLabelsInformation([]),
        setListOfItems([]),
        setSkusSelected([])
      )
    }
  }, [printLabels])

  useEffect(() => {
    if (isPrinting && printingLabels) {
      return toastSweetAlert({
        mode: 'loading',
        message: 'Imprimiendo etiquetas...',
      })
    }

    if (!isPrinting && printingLabels) {
      const list = labelsInformation.map((itemLabel, index) => {
        return {
          id: index + 1,
          SKU: itemLabel.SKU,
          Descripcion: itemLabel.Descripcion,
          Codigo: itemLabel.Codigo,
          date_until: itemLabel.date_until,
          Precio: itemLabel.Precio,
          discount_price: itemLabel.discount_price,
          inputRef: itemLabel.inputRef,
          quantity: 1,
        }
      })

      document.getElementById('idSearchQuery').focus()
      setTimeout(() => {
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Etiquetas impresas con exito',
          },
          setPrintingLabels(false),
          setClearSelectedRows(false),
          setLabelsInformation(list),
          setSkusSelected([]),
          document.getElementById('idSearchQuery').focus()
        )
      }, 1000)
    }

    if (printingLabels) {
      document.getElementById('printPageLabels').click()
      setIsPrinting(true)
      setClearSelectedRows(true)
      setCountSelected(0)
      setPrintCount(printCount + 1)
    }
  }, [printingLabels, isPrinting])

  const calculatePriceWithTax = (price, tax) => {
    if (!tax.SapItemsToSalesTaxCodes) return price

    return price * (1 + tax.SapItemsToSalesTaxCodes.rate / 100)
  }

  // const getPriceWithDiscount2 = (product, promotion, priceOriginal) => {
  //   let priceDiscount = 0
  //   let percentage = 0
  //   const productOriginal = product

  //   // console.log('=========ITEM=========')

  //   const price =
  //     promotion.u_so1_amount === 0 ? priceOriginal : promotion.u_so1_amount

  //   priceDiscount =
  //     price *
  //     (promotion.PromoItemToSouPromo.PromoToPromoDiscountFor.u_so1_discount1 /
  //       100)

  //   priceDiscount = price - priceDiscount

  //   if (
  //     promotion.PromoItemToSouPromo.PromoToPromoDiscountFor.u_so1_discount2 ===
  //     0
  //   ) {
  //     priceDiscount = calculatePriceWithTax(
  //       priceDiscount,
  //       product.PromoItemToSapItems
  //     )
  //     // console.log('=========FIN ITEM1=========')
  //     return priceDiscount
  //   }

  //   let secondDiscount =
  //     priceDiscount *
  //     (promotion.PromoItemToSouPromo.PromoToPromoDiscountFor.u_so1_discount2 /
  //       100)

  //   priceDiscount = priceDiscount - secondDiscount
  //   priceDiscount = calculatePriceWithTax(
  //     priceDiscount,
  //     product.PromoItemToSapItems
  //   )
  //   // console.log('priceDiscount Final', priceDiscount)
  //   // console.log('=========FIN ITEM2=========')
  //   return priceDiscount
  // }

  const exportPDF = async () => {
    try {
      // if (printCount > 0) {
      // const response = await toastSweetAlert({
      //   mode: 'modalConfirmCancel',
      //   message:
      //     '¿Desea exportar una nueva lista de etiquetas?. Ya no podra imprimir las etiquetas anteriores',
      // })

      // if (response.isConfirmed) {
      if (labelsInformation.length === 0) {
        return toastSweetAlert({
          mode: 'error',
          message: 'Debe seleccionar al menos un articulo',
        })
      }
      const validatorOptionSelected = () => {
        const tags20 = document.getElementById('20tags').checked
        const tags24 = document.getElementById('24tags').checked

        if (!tags20 && !tags24) {
          Swal.showValidationMessage(`Debe seleccionar un tipo de etiqueta`)
        }
        return true
      }

      const response = await toastSweetAlert({
        mode: 'modalConfirmContent',
        icon: 'info',
        validator: validatorOptionSelected,
        content: (
          <>
            <div className="col-12">
              <label>Seleccione el tipo de etiqueta:</label>
              <div className="col-lg-12 ml-1">
                <input id="20tags" type="radio" name="typeLabel" />
                <label htmlFor="20tags"> 20 etiquetas</label>
              </div>
              <div className="col-lg-12 ml-1">
                <input id="24tags" type="radio" name="typeLabel" />
                <label htmlFor="24tags"> 24 etiquetas</label>
              </div>
            </div>
          </>
        ),
      })

      const tags20 = document.getElementById('20tags').checked
      const tags24 = document.getElementById('24tags').checked
      // const fridgeLabel = document.getElementById('fridge').checked

      if (response.isConfirmed) {
        if (tags20) {
          setTypeLabel('20tags')
        }

        if (tags24) {
          setTypeLabel('24tags')
        }

        // if (fridgeLabel) {
        //   setTypeLabel('fridge')
        // }
        document.getElementById('idSearchQuery').focus()
        return document.getElementById('spanModalQuantity').click()
      }

      document.getElementById('idSearchQuery').focus()
      // }
      // return
      // }

      // if (skusSelected.length === 0) {
      //   return toastSweetAlert({
      //     mode: 'error',
      //     message: 'Debe seleccionar al menos un articulo',
      //   })
      // }
      // return setPrintLabels(true)
    } catch (e) {
      console.log(e)
    }
  }

  const handleRePrintLabels = async () => {
    // console.log('ESTOY EN REPRINT LABELS')
    document.getElementById('printPageLabels').click()
    setPrintingLabels(true)
    setIsPrinting(true)
    setClearSelectedRows(true)
    setCountSelected(0)
    setPrintCount(printCount + 1)
  }

  // const getPriceDiscountForPrint2 = (item, promotion, priceOriginal) => {
  //   let priceDiscount = 0
  //   const price =
  //     promotion.u_so1_amount === 0 ? priceOriginal : promotion.u_so1_amount

  //   priceDiscount =
  //     price *
  //     (promotion.PromoItemToSouPromo.PromoToPromoDiscountFor.u_so1_discount1 /
  //       100)

  //   priceDiscount = price - priceDiscount

  //   if (
  //     promotion.PromoItemToSouPromo.PromoToPromoDiscountFor.u_so1_discount2 ===
  //     0
  //   ) {
  //     priceDiscount = calculatePriceWithTax(priceDiscount, item)

  //     return priceDiscount
  //   }

  //   let secondDiscount =
  //     priceDiscount *
  //     (promotion.PromoItemToSouPromo.PromoToPromoDiscountFor.u_so1_discount2 /
  //       100)

  //   priceDiscount = priceDiscount - secondDiscount

  //   // priceDiscount = salesPrice(item, listPrice)
  //   priceDiscount = calculatePriceWithTax(priceDiscount, item)

  //   return priceDiscount
  // }

  const handleGetImageFromBarcode = (barcode) => {
    setBarcodeValue(barcode)
    return inputRef.current.src
  }

  const handleFilterChange = (groups) => {
    const groupsCode = groups.map((group) => {
      return parseInt(group.value)
    })
    setPagePagination({
      ...pagePagination,
      groupItem: groupsCode,
    })
  }
  const checkSelectedItem = (e, itemSel) => {
    const check = e.target.checked ? true : false
    if (check) {
      setItems((old) => [...old, itemSel])
    } else {
      setItems((old) =>
        old.filter((item) => item.item_code !== itemSel.item_code)
      )
    }
  }

  const handleSelectedRows = (value) => {
    let arraySkus = []
    for (let row of value.selectedRows) {
      // console.log('row', row)
      arraySkus.push(row.id)
    }
    setSkusSelected(arraySkus)
    setCountSelected(value.selectedCount)
  }

  const handleCheckAll = async () => {
    try {
      setLoadingBtn(true)
      // const validatorOptionSelected = () => {
      //   const tags20 = document.getElementById('20tags').checked
      //   const tags24 = document.getElementById('24tags').checked

      //   if (!tags20 && !tags24) {
      //     Swal.showValidationMessage(`Debe seleccionar un tipo de etiqueta`)
      //   }
      //   return true
      // }

      // const response = await toastSweetAlert({
      //   mode: 'modalConfirmContent',
      //   icon: 'info',
      //   validator: validatorOptionSelected,
      //   content: (
      //     <>
      //       <div className="col-12">
      //         <label>Seleccione el tipo de etiqueta:</label>
      //         <div className="col-lg-12 ml-1">
      //           <input id="20tags" type="radio" name="typeLabel" />
      //           <label htmlFor="20tags"> 20 etiquetas</label>
      //         </div>
      //         <div className="col-lg-12 ml-1">
      //           <input id="24tags" type="radio" name="typeLabel" />
      //           <label htmlFor="24tags"> 24 etiquetas</label>
      //         </div>
      //       </div>
      //     </>
      //   ),
      // })

      // const tags20 = document.getElementById('20tags').checked
      // const tags24 = document.getElementById('24tags').checked
      // // const fridgeLabel = document.getElementById('fridge').checked

      // if (response.isConfirmed) {
      //   if (tags20) {
      //     setTypeLabel('20tags')
      //   }

      //   if (tags24) {
      //     setTypeLabel('24tags')
      //   }

      const result = await printAllLabels()
      // console.log('result', result)

      const dataItemsWithDiscount =
        result.data.getAllSkusUniquePricesForPrintLabels

      // console.log('dataItemsWithDiscount', dataItemsWithDiscount)

      if (dataItemsWithDiscount) {
        setSkusSelected(dataItemsWithDiscount)
        // setCountSelected(dataItemsWithDiscount.getItemsSapWithPromotion.count)
        setPrintLabels(true)
        toastSweetAlert({
          mode: 'loading',
          message: 'Buscando articulos...',
        })
      } else {
        setSkusSelected([])
        setCountSelected(0)
        setLoadingBtn(false)
        return toastSweetAlertNotifications({
          mode: 'info',
          message: 'No se encontraron articulos',
        })
      }
      // }
    } catch (e) {
      console.log(e)
      return toastSweetAlert(
        {
          mode: 'error',
          message: e.message,
        },
        setLoadingBtn(false)
      )
    }
  }

  const handleDeleteItem = async (id) => {
    try {
      setSkuDelete(id)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleClearLists = () => {
    setListOfItems([])
    setLabelsInformation([])
    setSkusSelected([])
  }

  // console.log('labelsInformation', labelsInformation)

  return (
    <div
      className={`${loadingBtn ? 'processing-file' : ''}`}
      ref={onlyComponent}
    >
      <ContentHeader
        title="Etiquetas de precio único"
        breadcrumb="Lista de precios"
        windowTitle="Etiquetas"
      />
      <Box
        // title={`Escaner de etiquetas`}
        btnFunction={() => exportPDF()}
        btnFunctionTitle={'Exportar'}
        content={
          <>
            <div className="container-fluid">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <InputController
                  id="idSearchQuery"
                  name="searchQuery"
                  control={control}
                  label="Buscar producto"
                  placeHolder="Buscar por SKU o Código de barras"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      if (event.target.value === '') {
                        return toastSweetAlert(
                          {
                            mode: 'error',
                            message: 'Debe ingresar un valor para buscar',
                          },
                          setValue('searchQuery', ''),
                          setSkuToSearch('')
                        )
                      }
                      setSkuToSearch(event.target.value.trim())
                    }
                  }}
                />
              </div>
              <div>
                <LayoutTable
                  withCard={false}
                  hideId
                  hideBtnEdit
                  hideDetails
                  actionBtnErase={handleDeleteItem}
                  // title="Lista de articulos"
                  data={listOfItems}
                  // totalCount={total}
                  headersButtons={false}
                  // pagePagination={pagePagination}
                  // setPagePagination={setPagePagination}
                  // loadingData={loading}
                  paginationServer={false}
                  searchByProperty="SKU"
                  // customFilterButtonInput={
                  //   <>
                  //     <hr />
                  //     <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  //       {/* <InputController
                  //         name="id_warehouse"
                  //         isMulti
                  //         control={control}
                  //         inputType="choosen"
                  //         options={itemGroups}
                  //         placeHolderChoosen="Seleccione uno o varios grupos"
                  //         changeAction={(e) => handleFilterChange(e)}
                  //       /> */}
                  //       <div>
                  //         <OrderCol
                  //           mediumColSize="12"
                  //           content={
                  //             <>
                  //               <label>Seleccionados: {countSelected}</label>
                  //             </>
                  //           }
                  //         />
                  //       </div>
                  //     </div>
                  //     <hr />
                  //     {/* <div className="col-12">
                  //       <button
                  //         id="checkAll"
                  //         className="btn btn-accept btn-sm"
                  //         onClick={(e) => handleCheckAll(e)}
                  //         disabled={loadingPrintAllLabels}
                  //       >
                  //         Imprimir todas las etiquetas
                  //       </button>
                  //     </div> */}
                  //   </>
                  // }
                  // selectableRows
                  // selectableRowsClick={(e) => handleSelectedRows(e)}
                  // clearSelectedRows={clearSelectedRows}
                  // hideId
                  headerButtonsNoPermissions={
                    <>
                      <button
                        onClick={() => handleCheckAll()}
                        type="button"
                        className="btn btn-accept btn-sm"
                        // disabled={loadingPrintAllLabels}
                      >
                        Imprimir todas las etiquetas
                      </button>
                      <div>
                        <button
                          onClick={handleClearLists}
                          type="button"
                          className="btn btn-danger btn-sm"
                        >
                          Limpiar lista
                        </button>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          </>
        }
      />
      {barcodeValue && (
        <img className="invisible" id="barcode" ref={inputRef} />
      )}
      <div className="hide">
        <PrintLabels
          componentRef={componentRef}
          labelsInformation={labelsInformation}
          printingLabels={printingLabels}
          setPrintingLabels={setPrintingLabels}
          setIsPrinting={setIsPrinting}
          tagDiscount={true}
          pageBreakQuantity={typeLabel === '20tags' ? 20 : 24}
        />
        <ReactToPrint
          trigger={() => <button id="printPageLabels"></button>}
          content={() => componentRef.current}
          documentTitle={`Etiquetas-descuentos-${
            typeLabel === '20tags' ? '20-etiquetas' : '24-etiquetas'
          }${moment().format('DD-MM-YYYY HH:mm:ss')}`}
          copyStyles={false}
          pageStyle={
            typeLabel === '20tags'
              ? DISCOUNT_STYLES_TAGS_20
              : typeLabel === '24tags'
              ? DISCOUNT_STYLES_TAGS_24
              : null
          }
          // bodyClass="page-print-labels"
        />
      </div>
      <span
        id="spanModalQuantity"
        ref={refModalQuantity}
        className="invsible"
        data-toggle="modal"
        data-target="#modalQuantity"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="modalQuantity"
        title="Cantidad de etiquetas"
        onClose={() => setCloseModal(true)}
        size="large"
        content={
          <ModalQuantityLabels
            refModalQuantity={refModalQuantity}
            labelsInformation={labelsInformation}
            setLabelsInformation={setLabelsInformation}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
            setPrintLabels={setPrintLabels}
            setPrintingLabels={setPrintingLabels}
            setPrintLabelsWithDifferentPrice={setPrintLabelsWithDifferentPrice}
          />
        }
      />
    </div>
  )
}

export default UniquePriceTags
