import { jsPDF } from 'jspdf'
import QRCode from 'qrcode'
import { useBarcode } from 'next-barcode'
import _logo from '../../Assets/Images/vinos-america-logo.png'
import moment from 'moment'
import { string } from 'prop-types'

export const masterLabel = async (data, fileName, inputRef) => {
  try {
    const doc = new jsPDF('l', 'mm', [105, 50])
    const newLogo = _logo.split(',')
    const _qr = await generateQR(data.positionId)
    // const barcode = await GenerateBarCode(data.barcode)
    const newQr = _qr.split(',')
    // console.log(data)

    //Master Label
    doc.setFontSize(10).text('ETIQUETA MAESTRA', 33, 5)
    doc.rect(7, 7, 90, 7, 'F')

    doc.setTextColor(255, 255, 255)
    doc.setFont(undefined, 'bold')
    const stringSize = data?.product?.name
    // console.log(stringSize?.length)
    if (stringSize?.length >= 50) {
      doc.setFontSize(8)
      doc.text(`${data?.product?.name}`, 8, 13)
    } else if (stringSize?.length >= 40) {
      doc.setFontSize(10)
      doc.text(`${data?.product?.name}`, 15, 13)
    } else if (stringSize?.length >= 30) {
      doc.setFontSize(10)
      doc.text(`${data?.product?.name}`, 18, 13)
    } else if (stringSize?.length >= 20) {
      doc.setFontSize(10)
      doc.text(`${data?.product?.name}`, 26, 13)
    } else {
      doc.setFontSize(10)
      doc.text(`${data?.product?.name}`, 37, 13)
    }

    //Renctangle SKU
    // doc.rect(87, 20, 15, 9)
    // doc.setTextColor(0, 0, 0)
    // doc.text('# de Art', 87, 20)
    // doc.setFontSize(10)

    // doc.text(`${data?.product?.sku}`, 90, 26)

    //Rectangle Headers 1
    // doc.rect(5, 29, 35, 3)
    // doc.setTextColor(0, 0, 0)
    // doc.setFontSize(7)
    // doc.text('Verifico', 17, 31)

    doc.rect(40, 29, 35, 3)
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(7)
    doc.text('Orden de posicionado', 45, 31)
    doc.setFontSize(12)
    doc.setFont(undefined, 'bold')
    doc.text(`${data?.positionId}`, 53, 37)

    doc.rect(75, 29, 27, 3)
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(7)
    doc.text('Item Code', 82, 31)
    doc.setFont(undefined, 'bold')
    doc.setFontSize(12)
    doc.text(`${data?.product?.sku}`, 83, 37)

    //Rectangle information 1
    // doc.rect(5, 32, 35, 7)
    doc.setFontSize(7)
    doc.rect(40, 32, 35, 7)
    doc.setFontSize(7)
    doc.rect(75, 32, 27, 7)
    doc.setFontSize(7)

    //Rectangle Headers 2
    doc.setFillColor(0, 0, 0)
    doc.setTextColor(255, 255, 255)
    doc.setDrawColor(255, 255, 255)
    doc.setFontSize(8)

    doc.rect(5, 38, 35, 4, 'FD')
    doc.text('Fecha', 17, 41)

    doc.setFillColor(0, 0, 0)
    doc.setTextColor(255, 255, 255)
    doc.setDrawColor(255, 255, 255)
    doc.setFontSize(8)

    doc.rect(40, 38, 35, 4, 'FD')
    doc.text('Añada/Caducidad', 45, 41)

    doc.setFillColor(0, 0, 0)
    doc.setTextColor(255, 255, 255)
    doc.setDrawColor(255, 255, 255)
    doc.setFontSize(7)

    doc.rect(75, 38, 15, 4, 'FD')
    doc.text('Cjs x cama', 76, 41)

    doc.setFillColor(0, 0, 0)
    doc.setTextColor(255, 255, 255)
    doc.setDrawColor(255, 255, 255)
    doc.setFontSize(7)

    doc.rect(90, 38, 12, 4, 'FD')
    doc.text('Pza x Cja', 91, 41)

    //Rectangle Information 2
    doc.setDrawColor(0, 0, 0)
    doc.setTextColor(0, 0, 0)
    doc.rect(5, 42, 35, 4)
    doc.setFontSize(10)
    doc.setFont(undefined, 'bold')
    doc.text(`${data?.order?.date}`, 15, 45)

    doc.rect(40, 42, 35, 4)
    doc.text(`${data?.anada ? data?.anada : 'N/A'}`, 55, 45)

    doc.rect(75, 42, 15, 4)
    doc.text(`${data?.pallet?.boxes}`, 81, 45)

    doc.rect(90, 42, 12, 4)
    doc.text(`${data?.pallet?.items}`, 95, 45)

    //QR
    // doc.addImage(newQr[1], 'JPEG', 47, 15, 10, 10)
    doc.addImage(inputRef?.current?.src, 'JPEG', 35, 15, 65, 12)
    doc.addImage(newQr[1], 'JPEG', 7, 14, 22, 22)
    // console.log(barcode)

    // Download
    doc.save(fileName)
    // Print
    // doc.autoPrint();
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const masterLabelsGenerator = async (data, fileName, inputRef) => {
  try {
    const doc = new jsPDF()
    // const newLogo = _logo.split(',')
    // const barcode = await GenerateBarCode(data.barcode)
    // console.log(data)
    // for (let i = 0; i < data.length; i++) {
    //   const item = data[i]
    let collector = 0
    for (let item of data) {
      const _qr = await generateQR(item.positionId)
      const newQr = _qr.split(',')
      collector += 1
      let posMasterLabelTitle = 55 * collector
      let posBlackRecForName = posMasterLabelTitle + 2
      let posNameProduct = posMasterLabelTitle + 6
      //Master Label
      doc.setFontSize(10).text('ETIQUETA MAESTRA', 33, posMasterLabelTitle)

      doc.rect(7, posBlackRecForName, 90, 7, 'F') // +2 titulo

      doc.setTextColor(255, 255, 255)
      doc.setFont(undefined, 'bold')

      const stringSize = item?.product?.name
      // console.log(stringSize.length)

      if (stringSize?.length >= 50) {
        doc.setFontSize(8)
        doc.text(`${item?.product?.name}`, 8, posMasterLabelTitle + 7)
      } else if (stringSize?.length >= 40) {
        doc.setFontSize(9)
        doc.text(`${item?.product?.name}`, 15, posMasterLabelTitle + 7)
      } else if (stringSize?.length >= 30) {
        doc.setFontSize(10)
        doc.text(`${item?.product?.name}`, 18, posMasterLabelTitle + 7)
      } else if (stringSize?.length >= 20) {
        doc.setFontSize(10)
        doc.text(`${item?.product?.name}`, 26, posMasterLabelTitle + 7)
      } else {
        doc.setFontSize(10)
        doc.text(`${item?.product?.name}`, 37, posMasterLabelTitle + 7)
      }

      //Renctangle SKU
      // doc.rect(87, posMasterLabelTitle + 15, 15, 9) //+15 titulo
      // doc.setTextColor(0, 0, 0)
      // doc.text('# de Art', 87, posMasterLabelTitle + 15) // +15 titulo
      // doc.setFontSize(10)
      // doc.text(`${item?.product?.sku}`, 90, posMasterLabelTitle + 21) //  + 21

      //Rectangle Headers 1
      // doc.rect(5, posMasterLabelTitle + 24, 35, 3) // +24 TITULO
      // doc.setTextColor(0, 0, 0)
      // doc.setFontSize(7)
      // doc.setFont(undefined, 'bold')
      // doc.text('Verifico', 17, posMasterLabelTitle + 26) // +26 TITULO

      doc.rect(40, posMasterLabelTitle + 24, 35, 3)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(7)
      doc.text('Orden de posicionado', 45, posMasterLabelTitle + 26)
      doc.setFontSize(12)
      doc.setFont(undefined, 'bold')
      doc.text(`${item?.positionId}`, 55, posMasterLabelTitle + 31)

      doc.rect(75, posMasterLabelTitle + 24, 27, 3)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(7)
      doc.text('Item code', 82, posMasterLabelTitle + 26)
      doc.setFontSize(12)
      doc.text(`${item?.product?.sku}`, 82, posMasterLabelTitle + 31)

      //Rectangle information 1
      // doc.rect(5, posMasterLabelTitle + 27, 35, 7) // +27 titulo
      doc.setFontSize(7)
      doc.rect(40, posMasterLabelTitle + 27, 35, 7) // }27 titulo
      doc.setFontSize(7)
      doc.rect(75, posMasterLabelTitle + 27, 27, 7) // +27 titulo
      doc.setFontSize(7)

      //Rectangle Headers 2
      doc.setFillColor(0, 0, 0)
      doc.setTextColor(255, 255, 255)
      doc.setDrawColor(255, 255, 255)
      doc.setFontSize(8)

      doc.rect(5, posMasterLabelTitle + 33, 35, 4, 'FD') // +33 a titulo
      doc.text('Fecha', 17, posMasterLabelTitle + 36) // + 36 a titulo

      doc.setFillColor(0, 0, 0)
      doc.setTextColor(255, 255, 255)
      doc.setDrawColor(255, 255, 255)
      doc.setFontSize(8)

      doc.rect(40, posMasterLabelTitle + 33, 35, 4, 'FD')
      doc.text('Añada/Caducidad', 45, posMasterLabelTitle + 36)

      doc.setFillColor(0, 0, 0)
      doc.setTextColor(255, 255, 255)
      doc.setDrawColor(255, 255, 255)
      doc.setFontSize(7)

      doc.rect(75, posMasterLabelTitle + 33, 15, 4, 'FD')
      doc.text('Cjs x cama', 76, posMasterLabelTitle + 36)

      doc.setFillColor(0, 0, 0)
      doc.setTextColor(255, 255, 255)
      doc.setDrawColor(255, 255, 255)
      doc.setFontSize(7)

      doc.rect(90, posMasterLabelTitle + 33, 12, 4, 'FD')
      doc.text('Pza x Cja', 91, posMasterLabelTitle + 36)

      //Rectangle Information 2
      doc.setDrawColor(0, 0, 0)
      doc.setTextColor(0, 0, 0)
      doc.rect(5, posMasterLabelTitle + 37, 35, 4) // 37 a titulo
      doc.setFontSize(10)
      doc.setFont(undefined, 'bold')
      doc.text(`${item?.order?.date}`, 15, posMasterLabelTitle + 40) // 40 A TITULO

      doc.rect(40, posMasterLabelTitle + 37, 35, 4)
      doc.setFontSize(10)
      doc.setFont(undefined, 'bold')
      doc.text(
        `${item?.anada ? item?.anada : 'N/A'}`,
        55,
        posMasterLabelTitle + 40
      )

      doc.rect(75, posMasterLabelTitle + 37, 15, 4)
      doc.setFontSize(10)
      doc.setFont(undefined, 'bold')
      doc.text(`${item?.pallet?.boxes}`, 81, posMasterLabelTitle + 40)

      doc.rect(90, posMasterLabelTitle + 37, 12, 4)
      doc.setFontSize(10)
      doc.setFont(undefined, 'bold')
      doc.text(`${item?.pallet?.items}`, 95, posMasterLabelTitle + 40)

      doc.addImage(
        inputRef?.current?.src,
        'JPEG',
        35,
        posMasterLabelTitle + 10,
        65,
        12
      )
      doc.addImage(newQr[1], 'JPEG', 7, posMasterLabelTitle + 9, 22, 22)

      // console.log(barcode)

      if (collector === 4) {
        collector = 0
        doc.addPage()
      }
    }
    // Download
    doc.save(fileName)

    return true
  } catch (error) {
    return false
  }
}

export const labelGenerator = async (data, fileName) => {
  try {
    const doc = new jsPDF()
    const newLogo = _logo.split(',')
    const _qr = await generateQR(data.positionId)
    const newQr = _qr.split(',')

    // Logo
    doc.addImage(newLogo[1], 'JPEG', 70, 15, 65, 10)
    // Warehouse
    doc.setFontSize(16).text(`${data.warehouse}`, 20, 35)

    // Order imformation
    doc
      .setFontSize(15)
      .text('Fecha:', 20, 50)
      .text(`${data.order.date}`, 60, 50)
    doc
      .setFontSize(15)
      .text('Tipo de orden:', 20, 60)
      .text(`${data.order.type}`, 60, 60)
    doc
      .setFontSize(15)
      .text('Proveedor:', 20, 70)
      .text(`${data.provider.name}`, 60, 70)
    doc
      .setFontSize(15)
      .text('Num orden:', 20, 80)
      .text(`${data.order.id}`, 60, 80)

    // Product information
    doc.setFontSize(16).text('Producto', 20, 90)
    doc
      .setFontSize(15)
      .rect(20, 100, 50, 27)
      .text('Código', 25, 110)
      .text(`${data.product.sku}`, 25, 120)
    doc
      .rect(75, 100, 110, 27)
      .text('Descripción', 80, 110)
      .text(`${data.product.name}`, 80, 120)
    doc
      .rect(20, 135, 50, 27)
      .text('Piezas x caja', 25, 145)
      .text(`${data.pallet.items}`, 25, 155)
    doc
      .rect(75, 135, 53, 27)
      .text('Cajas', 80, 145)
      .text(`${data.pallet.boxes}`, 80, 155)
    doc
      .rect(133, 135, 53, 27)
      .text('Camas', 137, 145)
      .text(`${data.pallet.beds}`, 137, 155)
    doc
      .rect(20, 170, 50, 27)
      .text('Piezas totales', 25, 180)
      .text(`${data.pallet.quantity}`, 25, 190)
    doc
      .rect(75, 170, 53, 27)
      .text('Escaneo', 80, 180)
      .text(`${data.pallet.id}`, 80, 190)
    doc
      .rect(133, 170, 53, 27)
      .text('Pallet', 137, 180)
      .text(`${data.pallet.palletId}`, 137, 190)

    // QR
    doc
      .addImage(newQr[1], 'JPEG', 25, 200, 35, 35)
      .text('Orden de posicionado', 75, 210)
      .text(`# ${data.positionId}`, 75, 220)
      .rect(20, 200, 165, 35)

    // Signatures
    doc.setLineWidth(0.5).line(20, 260, 70, 260).text('Recibe', 35, 265)
    doc.setLineWidth(0.5).line(80, 260, 130, 260).text('Verifica', 95, 265)
    doc.setLineWidth(0.5).line(140, 260, 190, 260).text('Ingresa', 155, 265)

    // Download
    doc.save(fileName)
    // Print
    // doc.autoPrint();
    return true
  } catch (error) {
    return false
  }
}

export const labelsGenerator = async (data, fileName) => {
  try {
    const doc = new jsPDF()
    const newLogo = _logo.split(',')

    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      const _qr = await generateQR(data.positionId)
      const newQr = _qr.split(',')
      // Logo
      doc.addImage(newLogo[1], 'JPEG', 70, 15, 65, 10)
      // Warehouse
      doc.setFontSize(16).text(`${item.warehouse}`, 20, 35)

      // Order imformation
      doc
        .setFontSize(15)
        .text('Fecha:', 20, 50)
        .text(`${item.order.date}`, 60, 50)
      doc
        .setFontSize(15)
        .text('Tipo de orden:', 20, 60)
        .text(`${item.order.type}`, 60, 60)
      doc
        .setFontSize(15)
        .text('Proveedor:', 20, 70)
        .text(`${item.provider.name}`, 60, 70)
      doc
        .setFontSize(15)
        .text('Num orden:', 20, 80)
        .text(`${item.order.id}`, 60, 80)

      // Product information
      doc.setFontSize(16).text('Producto', 20, 90)
      doc
        .setFontSize(15)
        .rect(20, 100, 50, 27)
        .text('Código', 25, 110)
        .text(`${item.product.sku}`, 25, 120)
      doc
        .rect(75, 100, 110, 27)
        .text('Descripción', 80, 110)
        .text(`${item.product.name}`, 80, 120)
      doc
        .rect(20, 135, 50, 27)
        .text('Piezas x caja', 25, 145)
        .text(`${item.pallet.items}`, 25, 155)
      doc
        .rect(75, 135, 53, 27)
        .text('Cajas', 80, 145)
        .text(`${item.pallet.boxes}`, 80, 155)
      doc
        .rect(133, 135, 53, 27)
        .text('Camas', 137, 145)
        .text(`${item.pallet.beds}`, 137, 155)
      doc
        .rect(20, 170, 50, 27)
        .text('Piezas totales', 25, 180)
        .text(`${item.pallet.quantity}`, 25, 190)
      doc
        .rect(75, 170, 53, 27)
        .text('Escaneo', 80, 180)
        .text(`${item.pallet.id}`, 80, 190)
      doc
        .rect(133, 170, 53, 27)
        .text('Pallet', 137, 180)
        .text(`${item.pallet.palletId}`, 137, 190)

      // QR
      doc
        .addImage(newQr[1], 'JPEG', 25, 200, 35, 35)
        .text('Orden de posicionado', 75, 210)
        .text(`# ${item.positionId}`, 75, 220)
        .rect(20, 200, 165, 35)

      // Signatures
      doc.setLineWidth(0.5).line(20, 260, 70, 260).text('Recibe', 35, 265)
      doc.setLineWidth(0.5).line(80, 260, 130, 260).text('Verifica', 95, 265)
      doc.setLineWidth(0.5).line(140, 260, 190, 260).text('Ingresa', 155, 265)

      if (i + 1 !== data.length) {
        doc.addPage()
      }
    }
    // Download
    doc.save(fileName)

    return true
  } catch (error) {
    return false
  }
}

export const generateTicketCounterCharges = async (
  dataNote,
  flag,
  secondTicket,
  amountTotal
) => {
  try {
    let doc = new jsPDF({
      unit: 'mm',
      format: [130, 310],
    })
    console.log(amountTotal)
    const formmatterCurrency = (number) => {
      let numberFormat = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        signDisplay: 'negative',
        maximumFractionDigits: 4,
      }).format(number)

      return numberFormat
    }
    // doc.setFont('helvetica', 'bold')
    // doc.setFontSize(20)
    // doc.text('VINOS AMERICA S.A DE C.V.', 30, 20)
    // doc.text('VINOS Y LICORES', 45, 30
    doc.addImage(_logo, 'JPEG', 40, 10, 50, 15)
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.text(
      moment(dataNote?.date_note, 'DD/MM/YYYY h:mm:ss a').format('LLLL'),
      10,
      40
    )

    doc.text('Contra - Recibo.', 10, 50)
    doc.setFontSize(13)
    doc.text('Folio:', 10, 60)
    doc.setFontSize(14)
    doc.text(`${dataNote?.consecutive_number}`, 12, 70)
    doc.setFontSize(13)
    doc.text(`Recibimos de:`, 10, 80)
    doc.setFontSize(14)
    doc.text(
      `${
        flag === 2
          ? dataNote?.partnerNotes?.provider_name
          : dataNote?.partnerNotes
      }`,
      12,
      90
    )
    // doc.text(dataNote.partnerNotes.provider_name, 7, 95)
    doc.setFontSize(12)
    doc.text('Los siguientes documentos documentos a revisión:', 15, 100)

    let invoices = ''
    let dates = ''
    let amounts = ''
    let collector = 0
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(12)
    console.log('dataNote.bills', dataNote.bills)
    for (const bill of dataNote?.bills) {
      collector += 1
      invoices += bill?.invoice_bill + '\n'
      dates += moment(bill?.date_bill, 'DD/MM/YYYY').format('DD/MM/YYYY') + '\n'
      amounts += formmatterCurrency(bill?.amount_bill) + '\n'

      doc.text('Factura', 15, 110).text(invoices, 15, 115)
      doc.text('Fecha', 50, 110).text(dates, 45, 115)
      doc.text('Importe', 90, 110).text(amounts, 85, 115)
      if (collector === dataNote?.bills?.length) {
        let currentBill = 115
        let finalPosTotal = 5 * collector + currentBill
        let finalPosRevision = finalPosTotal + 20
        doc.setFontSize(15)
        doc.text('Total:', 60, finalPosTotal + 5)
        doc.text(
          formmatterCurrency(dataNote?.amount_note),
          85,
          finalPosTotal + 5
        )

        // doc.text('Revisiones día Martes de 10 AM a 2 PM', 2, finalPosRevision)
        // doc.text('Pagos día Lunes de 4 PM a 6 PM', 2, finalPosRevision + 5)
        //doc.rect(psitionX, positionY, width, height)
        doc.setFontSize(12)
        doc.text('Deducciones:', 2, 240)
        doc.rect(0, 241, 160, 15)
        doc.text('Comentarios:', 2, 265)
        doc.rect(0, 268, 160, 15)
        doc.setFont('helvetica', 'bold')
        doc.text('Total a pagar:', 50, 290)
        doc.rect(0, 291, 160, 15)
      }

      if (secondTicket) {
        let doc2 = new jsPDF('l', 'mm', [85, 80])

        doc2.addImage(_logo, 'JPEG', 17, 10, 50, 15)
        doc2.setFont('helvetica', 'bold')
        doc2.setFontSize(15)

        doc2.text(`Contra-recibo ${dataNote?.consecutive_number}`, 7, 30)
        doc2.text(`A pagar:`, 30, 40)
        doc2.text(
          `${formmatterCurrency(
            amountTotal ? amountTotal : dataNote?.amount_total
          )}`,
          30,
          50
        )

        doc2.save(`ticket2_folio_${dataNote?.consecutive_number}`)
      }
    }

    // doc.rect(100, 170, 53, 27).text('Monto', 60, 190).text(amount, 60, 190)

    doc.save(`ticket_folio_${dataNote?.consecutive_number}`)
  } catch (e) {
    console.log(e)
    return false
  }
}

const generateQR = async (data) => {
  try {
    const qr = await QRCode.toDataURL(`${data}`)
    return qr
  } catch (err) {
    console.error(err)
  }
}

export const generatePriceTags = async (data, fileName) => {
  try {
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'pt',
    })

    let index = 0
    let x = 11
    let y = 520
    for (let i = 0; i < data.length; i++) {
      const item = data[i]

      // doc.addFont("consola.ttf", "consola", "normal")

      doc.setFontSize(6)

      doc.text(item.Descripcion, x, y)
      x = x + 113

      if (x > 660 && index == 0) {
        x = 11
        y = 405
        index++
      } else if (x > 660 && index == 1) {
        x = 11
        y = 265
        index++
      } else if (x > 660 && index == 2) {
        x = 11
        y = 125
        index++
      }
      index++
    }
    doc.save(fileName)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}
