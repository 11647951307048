import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'

export const Box = ({
  title = null,
  whiteHeader = false,
  subTitle = null,
  btnGreenTxt = false,
  btnGreenPath = '/',
  btnRedTxt = false,
  btnRedPath = '/',
  content = '',
  btnSubmit = false,
  btnSubmitNoEnter = false,
  btnState = false,
  btnOnClick = false,
  errors = false,
  btnFunction = false,
  btnFunctionRed = false,
  btnFunctionTitle = 'Regresar',
  btnSecondFunction = false,
  btnSecondFunctionRed = false,
  btnSecondFunctionTitle = 'Regresar',
  btnSecondFunctionDismissModal = false,
  btnIcon = 'fa fa-plus',
  withIcons = false,
  withCard = true,
  withHeader = true,
  btnSubmitText,
  btnSubmitNoEnterTxtSave = 'Guardar',
  btnSubmitNoEnterTxtUpdate = 'Actualizar',
  refModal,
  headerLeft = null,
  enableCollapse = false,
  collapsed = false,
  style = null,
  selectFilter = false,
  selectFilterOptionData = [],
  handleSelectFilter = () => {},
  selectFilterLabelName = 'Categoria:',
  headerBtn = false,
  headerBtnDisabled = false,
  headerBtnText = '',
  headerBtnHandler = () => {},
  buttonsOnBottom = true,
  customHeadersButtons = false,
}) => {
  const { id: _id } = useParams()

  const [isCollapsed, setIsCollapsed] = useState(collapsed)

  useEffect(() => {
    if (Object.values(errors).length > 0) {
      toastSweetAlert({
        mode: 'error',
        message: 'Debes completar los campos restantes en el formulario',
      })
    }
  }, [errors])

  let collapseBtn = (
    <>
      <div className="text-right">
        <button className="btn" onClick={() => setIsCollapsed(!isCollapsed)}>
          <i className={isCollapsed ? 'fa fa-plus' : 'fa fa-minus'}></i>
        </button>
      </div>
    </>
  )

  const actionButtons = (
    <div
      className={`pt-4 pb-4 control-btn-box ${
        buttonsOnBottom ? 'Abajo' : 'Arriba'
      }`}
    >
      {btnGreenTxt && (
        <Link to={btnGreenPath} className="btn StandarModalConfirmButtonLogOut">
          {withIcons && <i className="fa fa-plus"></i>} {btnGreenTxt}
        </Link>
      )}
      {btnSubmit && (
        <button
          type="submit"
          onClick={typeof btnSubmit === 'function' ? btnSubmit : () => {}}
          className="btn StandarModalConfirmButtonLogOut"
          disabled={btnState ? true : false}
        >
          {withIcons && (
            <i className={`${_id ? 'fa fa-edit' : 'fa fa-save'}`}></i>
          )}
          {btnState && !btnSubmitText
            ? ' Procesando...'
            : _id && !btnSubmitText
            ? ' Actualizar'
            : !btnSubmitText && ' Guardar'}
          {btnState && btnSubmitText ? ' Procesando...' : btnSubmitText}
        </button>
      )}
      {btnSubmitNoEnter && (
        <span
          type="submit"
          onClick={
            typeof btnSubmitNoEnter === 'function' ? btnSubmitNoEnter : () => {}
          }
          className="btn StandarModalConfirmButtonLogOut"
          disabled={btnState ? true : false}
        >
          {withIcons && (
            <i className={`${_id ? 'fa fa-edit' : 'fa fa-save'}`}></i>
          )}
          {btnState
            ? ' Procesando...'
            : _id
            ? ` ${btnSubmitNoEnterTxtUpdate}`
            : ` ${btnSubmitNoEnterTxtSave}`}
        </span>
      )}
      {btnRedTxt && (
        <Link to={btnRedPath} className="btn StandarModalCancelButtonLogOut">
          {withIcons && <i className="fa fa-times"></i>} {btnRedTxt}
        </Link>
      )}
      {btnOnClick && (
        <button
          type="button"
          onClick={typeof btnOnClick === 'function' ? btnOnClick : () => {}}
          className="btn StandarModalConfirmButtonLogOut"
          disabled={btnState ? true : false}
        >
          {withIcons && (
            <i className={`${_id ? 'fa fa-edit' : 'fa fa-save'}`}></i>
          )}
          {btnState ? ' Procesando...' : _id ? ' Actualizar' : ' Guardar'}
        </button>
      )}
      {btnFunction && (
        <span
          onClick={typeof btnFunction === 'function' ? btnFunction : () => {}}
          className={
            btnFunctionRed
              ? 'btn btn-danger StandarModalCancelButtonLogOut'
              : 'btn StandarModalConfirmButtonLogOut'
          }
        >
          {btnFunctionTitle}
        </span>
      )}
      {btnSecondFunction && (
        <span
          ref={refModal}
          onClick={
            typeof btnSecondFunction === 'function'
              ? btnSecondFunction
              : () => {}
          }
          className={
            btnSecondFunctionRed
              ? 'btn btn-danger StandarModalCancelButtonLogOut'
              : 'btn StandarModalCancelButtonLogOut'
          }
          data-dismiss={`${btnSecondFunctionDismissModal && 'modal'}`}
        >
          {btnSecondFunctionTitle}
        </span>
      )}
    </div>
  )

  return (
    <>
      <div className={withCard ? 'card' : 'row'}>
        {withCard && withHeader && (
          <div className={`card-header ${whiteHeader && 'whiteHeader'}`}>
            {title && <h3 className="card-title header-title">{title}</h3>}
            {subTitle && (
              <h6 className="card-subTitle" style={{ marginTop: '4px' }}>
                &nbsp;{subTitle}
              </h6>
            )}
            {selectFilter && (
              <>
                <div className="d-xl-flex  justify-content-end align-items-center d-block">
                  <div className="d-xl-flex align-items-center d-block">
                    <label
                      className="title-filter mt-5 ml-2 text-white m-xl-0 d-block "
                      htmlFor="dateStart"
                    >
                      {selectFilterLabelName}
                    </label>

                    <select
                      className="form-control ml-2"
                      name="category"
                      id="category"
                      onChange={handleSelectFilter}
                    >
                      {selectFilterOptionData.map((item, i) => (
                        <option value={item.value} key={`${item.value}-${i} `}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* {(headerLeft || enableCollapse) && (
                  <div className="col-6">
                    {headerLeft}
                    {enableCollapse && collapseBtn}
                  </div>
                )} */}
              </>
            )}
            {(headerLeft || enableCollapse) && (
              <div className="col-12">
                {headerLeft}
                {enableCollapse && collapseBtn}
              </div>
            )}

            {headerBtn && (
              <div className="d-xl-flex  justify-content-end align-items-center d-block">
                <div className="d-xl-flex align-items-center d-block">
                  <button
                    className="StandarModalConfirmButtonLogOut"
                    onClick={headerBtnHandler}
                    disabled={headerBtnDisabled}
                  >
                    {headerBtnDisabled ? 'loading...' : headerBtnText}
                  </button>
                </div>
              </div>
            )}
            {customHeadersButtons && (
              <div className="d-xl-flex  justify-content-end align-items-center d-block">
                <div className="d-xl-flex align-items-center d-block">
                  {customHeadersButtons}
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={
            (withCard ? 'card-body' : 'col col-md-12') +
            (isCollapsed ? ' d-none' : '')
          }
        >
          {!buttonsOnBottom && actionButtons}
          {content}
          {buttonsOnBottom && actionButtons}
        </div>
      </div>
    </>
  )
}
export default Box
