import { gql } from '@apollo/client'

export const GET_PRODUCTS_SAP_STOCK = gql`
  query GetProductSapStock(
    $warehouseCode: String!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getProductSapStock(
      warehouseCode: $warehouseCode
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        item_code
        on_hand
        SapItems {
          item_name
        }
      }
      count
    }
  }
`

export const GET_LAST_PRODUCTS_UPDATED = gql`
  query GetLastProductsUpdated($warehouseCode: String!, $limit: Int) {
    getLastProductsUpdated(warehouseCode: $warehouseCode, limit: $limit) {
      item_code
      on_hand
      SapItems {
        item_name
      }
    }
  }
`

export const GET_PROUCT_SAP = gql`
  mutation GetProductSapTelemarketing(
    $warehouseCode: String!
    $searchQuery: String
  ) {
    getProductSapTelemarketing(
      warehouseCode: $warehouseCode
      searchQuery: $searchQuery
    ) {
      item_code
      on_hand
      SapItems {
        item_name
      }
    }
  }
`

export const GET_ALL_PRODUCTS_SAP = gql`
  query GetAllOrdersSap($searchQuery: String, $limit: Int, $offset: Int) {
    getAllOrdersSap(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        platform_id
        platform {
          id
          name
        }
        payment_id
        paymentTelemarketing {
          id
          order_id
          platform
          payment_id
        }
        order_id
        product_quantity
        total_price
        shipping_price
        order_doc_num
        num_at_card
        createdAt
        business_partner_id
        BusinessPartner {
          card_name
        }
      }
      count
    }
  }
`

export const GET_PRODUCT_SAP_BY_ID = gql`
  query GetOrderPartnerById($getOrderPartnerByIdId: Int!) {
    getOrderPartnerById(id: $getOrderPartnerByIdId) {
      id
      platform_id
      platform {
        id
        name
      }
      payment_id
      paymentTelemarketing {
        id
        order_id
        platform
        payment_id
      }
      order_id
      product_quantity
      total_price
      shipping_id
      shipping_price
      order_doc_num
      num_at_card
      createdAt
      productsTelemarketing {
        id
        product_id
        sku
        name
        order_id
        quantity
        price
        total
        variation_id
        picked
        partial_picked
        packed
        unavailable
        img_url
      }
      business_partner_id
      BusinessPartner {
        card_name
        phone1
        phone2
        email
        SapBusinessPartnerAddress {
          id
          zip_code
          address
          street
          block
          city
          country
          state
          BusinessPartnerAddressRoute {
            id
            name
            description
            warehouse_id
          }
        }
        cardCode
        cardName
        cardType
        address
        zipCode
        mailAdress
      }
      shipping_id
      shipping {
        ShippingBusinessPartnerAddress {
          id
          zip_code
          address
          street
          block
          city
          country
          state
          BusinessPartnerAddressRoute {
            id
            name
            description
            warehouse_id
          }
        }
      }
    }
  }
`
export const UPDATE_ORDER_SHIPPING_SAP = gql`
  mutation UpdateOrderShippingSap(
    $shippingId: Int!
    $input: orderShippingData!
  ) {
    updateOrderShippingSap(shipping_id: $shippingId, input: $input)
  }
`
