import React, { useEffect, useState } from 'react'
import moment from 'moment'

import LayoutTable from '../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'
import { useQuery } from '@apollo/client'
import { order_statuses_esp } from '../../../../Helpers/StatusesLanguages'

/* DATA */
import {
  EXPORT_LOCAL_ORDERS,
  GET_LOCAL_ORDERS,
} from '../../../../../graphql/Catalog/Orders/localOrders'
import DatesOptions from '../../../../Global/DatesOptions'

export const LocalShippingOrders = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    platform: null,
    date: null,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_LOCAL_ORDERS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      platform: pagePagination.platform,
      date: pagePagination.date,
    },
    fetchPolicy: 'no-cache',
  })

  const [dataToTable, setDataToTable] = useState([])

  const dictionary = order_statuses_esp

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const list = data.getLocalOrders.rows.map((order) => {
        return {
          id: order.id ? order.id : '--',
          'No. de Venta': order.order_id ? order.order_id : '--',
          'Fecha y hora':
            moment(order?.order_date).format('L') +
              ', ' +
              moment(order?.order_date).format('LT') || '--',
          Plataforma: order.platform?.name || '--',
          Estatus: order.status?.id ? dictionary[order.status.id] : '--',
          'Mdo. de pago': order.payment?.platform || '--',
          Cliente: `${order.shipping?.first_name} ${order.shipping?.last_name}`,
          // 'Tipo documento': order?.object_type_name
          //   ? order?.object_type_name
          //   : 'Orden de plataforma',
          Tienda: order?.store?.name || order?.warehouse?.name || '--',
        }
      })
      setTotal(data.getLocalOrders.count)
      setDataToTable(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data])

  const filterOnChange = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  return (
    <>
      {/* <ContentHeader
        title="Pedidos locales"
        breadcrumb="Locales"
        windowTitle="Locales"
      /> */}
      <LayoutTable
        hideId
        data={dataToTable}
        title="Pedidos locales"
        requery={GET_LOCAL_ORDERS}
        exportQuery={EXPORT_LOCAL_ORDERS}
        editText={'Detalles'}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        platformSelector={true}
        loadingData={loading}
        FilterBarPlaceholder="Buscar por Cliente, No. de Venta o Tienda"
        /** First filter */
        filter
        filterOnChange={filterOnChange}
        filterOptions={[<DatesOptions daysBefore={true} />]}
        filterLabelText="Por fecha"
      />
    </>
  )
}

export default LocalShippingOrders
