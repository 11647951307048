import Box from '../../../Global/Box'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import InputController from '../../../Global/InputController'
import ContentHeader from '../../../Layout/ContentHeader'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import LayoutTable from '../../../Global/LayoutTable'
import { useState, useEffect, useContext } from 'react'
import {
  CREATE_NOTE,
  GET_COUNT_NOTES_FOR_CONSECUTIVE,
  GET_INFO_PARTNER_FOR_CREATE_NOTE,
  VALIDATE_BILL,
  VALIDATE_INVOICE,
} from '../../../../graphql/Catalog/Notes/notes'
import moment from 'moment'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_INFO_STORE_COUNTER_CHARGES } from '../../../../graphql/Catalog/Notes/StoreHelperCounterCharges/storehelpercountercharges'
import OrderCol from '../../../Global/OrderCol'
import { GET_STORES } from '../../../../graphql/Catalog/Stores/stores'
import { AuthContext } from '../../../../Auth/AuthContext'
import Validate from './ValidateBill'
import BusinessRetentionNew from './BusinessRetention/BusinessRetentionNew'
import { generateTicketCounterCharges } from '../../../Helpers/pdfGenerator'
import { GET_ALL_CONCEPT_COUNTER_CHARGES } from '../../../../graphql/Catalog/Notes/ConceptCounterCharges/ConceptCounterCharges'
import DisplayInfoNoteForPrint from './PrintTicketsNotes/DisplayInfoNoteForPrint'
import { GET_ROLES } from '../../../../graphql/Catalog/Roles/roles'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'

export const NoteNew = () => {
  //expresiones regulares
  const regexID = new RegExp('id(¡|¿|=)[^/ | &]+', 'i')
  const regexEmisor = new RegExp('re(¡|¿|=)([^/]+)(/|&|$)', 'i')
  // const regexEmisor = new RegExp('re(¡|¿|=)[^/ | &]+', 'i')
  const regexReceptor = new RegExp('rr(¡|¿|=)[^/ | &]+', 'i')
  const regexTotal = new RegExp('tt(¡|¿|=)[^/ | &]+', 'i')

  const history = useHistory()
  const { user } = useContext(AuthContext)
  const [sum, setSum] = useState(0.0)
  const [sumTotalRetention, setSumTotalRetention] = useState(0.0)
  const [filter, setFilter] = useState(null)
  const [idBill, setIdBill] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [infoStore, setInfoStore] = useState(null)
  const [secondTicket, setSecondTicket] = useState(null)
  const [valueCountConsecutive, setValueCountConsecutive] = useState(null)
  const [consecutiveNumber, setConsecutiveNumber] = useState(null)
  const [idRole, setIdRole] = useState(null)
  const [idRoleReception, setIdRoleReception] = useState(null)
  const [valuePartner, setValuePartner] = useState(null)
  const [valueInvoice, setValueInvoice] = useState(null)
  const [billCaptured, setBillCaptured] = useState(null)
  const [checkboxContability, setCheckboxContability] = useState(false)
  const [checkboxFinances, setCheckboxFinances] = useState(false)
  const [flag, setFlag] = useState(1)
  const [idStore, setIdStore] = useState(null)
  // const [idConcept, setIdConcept] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [dataBack, setdataBack] = useState([])
  const [options, setOptions] = useState([])
  const [rolesInfo, setRolesInfo] = useState([])
  const [createNote] = useMutation(CREATE_NOTE)

  const [pagePagination, setPagePagination] = useState({})

  const [getPartner] = useLazyQuery(GET_INFO_PARTNER_FOR_CREATE_NOTE)

  const [getBill] = useLazyQuery(VALIDATE_BILL)

  const [getInvoice] = useLazyQuery(VALIDATE_INVOICE)

  const getRolesInfo = useQuery(GET_ROLES, {
    variables: {
      rolesName: ['Recepción Control de Pagos', 'Finanzas Control de Pagos'],
    },
    fetchPolicy: 'no-cache',
  })

  // const {
  //   loading: loadingGetInfoStore,
  //   error: errorGetInfoStore,
  //   data: dataGetInfoStore,
  //   refetch: refetchGetInfoStore,
  // } = useQuery(GET_INFO_STORE_COUNTER_CHARGES, {
  //   variables: {
  //     id: null,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const [getInfoStore] = useLazyQuery(GET_INFO_STORE_COUNTER_CHARGES)

  const {
    loading: loadingCountConsecutive,
    error: errorCountConsecutive,
    data: dataCountConsecutive,
  } = useQuery(GET_COUNT_NOTES_FOR_CONSECUTIVE, {
    variables: {
      storeId: parseInt(idStore),
      isManualNote: false,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingStores,
    error: errorStores,
    data: dataStores,
  } = useQuery(GET_STORES, {
    fetchPolicy: 'no-cache',
  })

  const validationForm = yup.object().shape({
    invoice_input: yup.string(),
  })

  const validationSchema = yup.object().shape({
    scan_bill: yup.string().required('Este campo es obligatorio'),
    invoice_input: yup
      .string()
      .min(4, 'Folio debe contener 4 digitos')
      .required('Este campo es obligatorio'),
    date_bill: yup.date().typeError('Inserte una fecha'),
    id_store: yup
      .string()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),
    retail: yup.string().required('Este campo es obligatorio'),
    // checkboxGroup: yup.object().shape(
    //   {
    //     contability: yup.boolean().when('finances', {
    //       is: (finances) => !finances,
    //       then: yup
    //         .boolean()
    //         .oneOf([true], 'Debe seleccionar al menos una opción')
    //         .typeError('Debe seleccionar al menos una opción'),
    //     }),
    //     finances: yup.boolean().when('contability', {
    //       is: (contability) => !contability,
    //       then: yup
    //         .boolean()
    //         .oneOf([true], 'Debe seleccionar al menos una opción')
    //         .typeError('Debe seleccionar al menos una opción'),
    //     }),
    //   },
    //   [
    //     ['contability', 'finances'],
    //     ['finances', 'contability'],
    //   ]
    // ),
    // .test((obj) => {
    //   if (obj.contability || obj.finances) {
    //     return true
    //   } else if (obj.contability === false && obj.finances === false) {
    //     return true
    //   }
    //   return new yup.ValidationError('Debes marcar almenos una opción')
    // }),
  })

  const {
    register,
    methods,
    control,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  // const { handleSubmit } = useForm({
  //   resolver: yupResolver(validationForm),
  // })

  useEffect(() => {
    try {
      if (getRolesInfo.loading) return
      if (getRolesInfo.error)
        return toastSweetAlert({ mode: 'error', message: getRolesInfo.error })

      const dataGetRoles = getRolesInfo?.data?.getAllRoles?.rows

      const findRoleFinances = dataGetRoles?.find(
        (item) => item?.role_name === 'Finanzas Control de Pagos'
      )
      const findRoleReception = dataGetRoles?.find(
        (item) => item?.role_name === 'Recepción Control de Pagos'
      )

      if (findRoleReception?.id === user.role) {
        console.log('Recepción')
        setIdRoleReception(findRoleReception?.id)
      } else {
        console.log('Finanzas')
        setIdRoleReception(findRoleFinances?.id)
      }

      setRolesInfo(getRolesInfo?.data?.getAllRoles?.rows)
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [getRolesInfo.data, getRolesInfo.loading, getRolesInfo.error])

  useEffect(() => {
    if (idBill) {
      verifyBill()
      setBillCaptured(idBill)
      setIdBill(null)
    }
  }, [idBill])

  // useEffect(() => {
  //   if (idStore !== null && idStore !== undefined) {
  //     if (!loadingGetInfoStore) {
  //       if (errorGetInfoStore)
  //         return toastSweetAlert(
  //           {
  //             mode: 'error',
  //             message: errorGetInfoStore.message,
  //           },
  //           history.push('/catalog/notes/shownotes')
  //         )
  //       setInfoStore(dataGetInfoStore.getInfoStoreCounterCharges)
  //       console.log(dataGetInfoStore.getInfoStoreCounterCharges)
  //     }
  //   }
  // }, [
  //   loadingGetInfoStore,
  //   errorGetInfoStore,
  //   dataGetInfoStore,
  //   refetchGetInfoStore,
  //   user,
  // ])

  useEffect(() => {
    const getInfo = async () => {
      try {
        const { data } = await getInfoStore({
          variables: {
            id: idStore ? idStore : null,
          },
          fetchPolicy: 'no-cache',
        })
        if (data.getInfoStoreCounterCharges === null) {
          return toastSweetAlert(
            {
              mode: 'error',
              message:
                'La tienda no tiene información para crear contrarecibos',
            },
            history.push('/shownotes')
          )
        }

        // console.log('Data', data.getInfoStoreCounterCharges)
        setInfoStore(data.getInfoStoreCounterCharges)
      } catch (error) {
        return toastSweetAlert(
          {
            mode: 'error',
            message: error.message,
          },
          history.push('/shownotes')
        )
      }
    }

    if (user.store || idStore) {
      getInfo()
    }
  }, [user.store, idStore])

  useEffect(() => {
    if (dataCountConsecutive !== null && dataCountConsecutive !== undefined) {
      if (!loadingCountConsecutive) {
        if (errorCountConsecutive)
          return toastSweetAlert({
            mode: 'error',
            message: errorCountConsecutive.message,
          })
      }
      console.log('dataCountConsecutive', dataCountConsecutive)
      setValueCountConsecutive(
        dataCountConsecutive.getCountForConsecutiveNumber + 1
      )
    }
  }, [dataCountConsecutive])

  useEffect(() => {
    const getRoleForReception = async () => {
      try {
        if (!loadingStores) {
          if (errorStores)
            return toastSweetAlert({
              mode: 'error',
              message: errorStores.message,
            })
          // if (user.role === 1 || user.role === 5) {
          const shops = dataStores?.getAllStores?.rows?.map((item) => {
            return {
              value: item.id,
              label: item.alias,
            }
          })
          await handleRoleForReception(shops)
          setOptions(shops)
          setValue('id_store', user.store)
          setIdStore(user.store)
          setValue('date_bill', moment().format('YYYY-MM-DD'))
        }
      } catch (error) {
        return toastSweetAlert(
          {
            mode: 'error',
            message: error.message,
          },
          history.push('/shownotes')
        )
      }
    }

    getRoleForReception()
  }, [loadingStores, errorStores, dataStores])

  useEffect(() => {
    if (parseInt(idRoleReception) === parseInt(user.role)) {
      setValue('retail', '  ')
    }
  }, [idRoleReception])

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 2,
    }).format(number)

    return numberFormat
  }

  const handleChange = (id_bill) => {
    if (id_bill === '') return
    setIdBill(id_bill)
  }

  const sumTotalNote = (amountNote, noteCredit) => {
    let total = 0.0

    if (noteCredit) {
      total = sum - parseFloat(amountNote)

      return total
    }
    total = sum + parseFloat(amountNote)

    return total
  }

  const retention = (valueRetention, noteCredit) => {
    let total = 0.0

    // if (noteCredit) {
    //   total = sumTotalRetention - parseFloat(valueRetention)

    //   return total
    // }
    total = sumTotalRetention + parseFloat(valueRetention)

    return total
  }

  const calculateRetention = (amountBill, valueRetention) => {
    let total = 0.0

    if (valueRetention) {
      total = 0
    }

    // let percentage = valueRetention / 100
    let retention = (parseFloat(amountBill) / 1.16) * 0.9 * valueRetention

    // total = retention * amountBill
    total = retention
    return total.toFixed(2)
  }

  const handleValueInvoice = async (data) => {
    try {
      setLoadingBtn(true)
      // console.log('data', data)
      // console.log('HANDLEVALUEINVOICE', dataTable)
      // console.log('HANDLEVALUEINVOICE', dataBack)
      setValueInvoice(data.invoice_input)
      validarChk()
      if (!filter) return
      const date = getValues('date_bill')

      const { data: dataInvoice, error: errorInvoice } = await getInvoice({
        variables: {
          invoiceCaptured: data.invoice_input.toString(),
          idPartner: parseInt(filter?.id_partner),
        },
        fetchPolicy: 'no-cache',
      })

      if (errorInvoice) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: errorInvoice.message,
        })
      }

      const list = dataBack.map((item) => {
        return {
          id: item.id,
          id_bill: item.id_bill,
          receptor: item.receptor,
          emisor: item.emisor,
          id_partner: item.id_partner,
          amount_bill: item.amount_bill,
          date_bill: item.date_bill,
          invoice_bill: item.invoice_bill,
          retention_bill: item.retention_bill,
          retail: item.retail,
          note_credit: item.note_credit,
        }
      })

      const listTable = dataTable.map((item) => {
        return {
          id: item.id,
          folio: item.folio,
          emisor: item.emisor,
          razón: item.razón,
          total: item.total,
          retención: item.retención,
          retail: item.retail,
          Fecha: item.Fecha,
        }
      })

      //Validadores para el escaneo
      const RFCVA = 'VAM870320RI3'
      if (RFCVA !== filter.receptor) {
        setLoadingBtn(false)

        return toastSweetAlert(
          {
            mode: 'error',
            message: 'El RFC de VA no coincide con el escaneado',
          },
          setValue('scan_bill', ''),
          setFilter(null),
          setValueInvoice(null),
          setValue('date_bill', ''),
          setValue('invoice_input', ''),
          setSupplier(null)
        )
      }

      if (list.find((item) => item.emisor !== filter.emisor)) {
        setLoadingBtn(false)

        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'El RFC del emisor debe coincidir: ' +
              ' usted escaneo el RFC: ' +
              filter.emisor +
              ' solo se aceptan facturas del RFC: ' +
              dataBack[0].emisor,
          },
          setValue('scan_bill', ''),
          setFilter(null),
          setValueInvoice(null),
          setValue('date_bill', ''),
          setValue('invoice_input', ''),
          setSupplier(null)
        )
      }

      if (list.find((item) => item.id_bill === filter.id_bill)) {
        setLoadingBtn(false)

        return toastSweetAlert(
          {
            mode: 'error',
            message: 'Está factura ya se encuentra registrada',
          },
          setValue('scan_bill', ''),
          setFilter(null),
          setValueInvoice(null),
          setValue('date_bill', ''),
          setValue('invoice_input', ''),
          setSupplier(null)
        )
      }

      if (list.find((item) => item.invoice_bill === data?.invoice_input)) {
        setLoadingBtn(false)

        return toastSweetAlert({
          mode: 'error',
          message: 'El No. de folio ya se ha añadido',
        })
      }

      const sumNote = sumTotalNote(filter.total, getValues('note_credit'))
      const sumRetention = calculateRetention(
        filter.total,
        filter.percentage_retention
      )
      const sumValueRetention = retention(
        sumRetention,
        getValues('note_credit')
      )

      list.push({
        id: data.invoice_input,
        id_bill: filter.id_bill,
        receptor: filter.receptor,
        emisor: filter.emisor,
        id_partner: filter.id_partner,
        amount_bill: getValues('note_credit') ? -filter.total : filter.total,
        retention_bill: sumRetention,
        date_bill: moment(date, 'YYYY-MM-DD hh:mm:ss a').format('YYYY-MM-DD'),
        retail: data.retail,
        invoice_bill: data.invoice_input,
        note_credit: getValues('note_credit'),
      })

      listTable.push({
        id: data.invoice_input,
        folio: data.invoice_input,
        emisor: filter.emisor,
        retail: data.retail,
        razón: filter.razon,
        total: getValues('note_credit')
          ? ' -' + formmatterCurrency(filter.total)
          : formmatterCurrency(filter.total),
        retención: formmatterCurrency(sumRetention),
        Fecha: moment(date).format('DD/MM/YYYY'),
      })

      // console.log(
      //   'data?.checkboxGroup?.finances',
      //   data?.checkboxGroup?.finances
      // )
      // console.log(
      //   'data?.checkboxGroup?.contability',
      //   data?.checkboxGroup?.contability
      // )

      setSumTotalRetention(sumValueRetention)
      setSum(sumNote)
      setdataBack(list)
      setDataTable(listTable)
      setSupplier(filter.emisor)
      setSecondTicket(filter.second_ticket)
      setCheckboxFinances(data?.checkboxGroup?.finances)
      setCheckboxContability(data?.checkboxGroup?.contability)
      setValue('scan_bill', '')
      setValue('invoice_input', '')
      setValue('date_bill', moment().format('YYYY-MM-DD'))
      idRoleReception === user.role
        ? setValue('retail', '  ')
        : setValue('retail', '')
      setValue('note_credit', false)
      setValue('contability', false)
      setFilter(null)
      setIdBill(null)
      setSupplier(null)
      setValueInvoice(null)
      setValuePartner(filter.id_partner)
      setLoadingBtn(false)
      // }
      // console.log('total', sum)
      // console.log(getValues('note_credit'))
      // console.log(getValues('contability'))
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
      return toastSweetAlert(
        {
          mode: 'error',
          message: e.message,
        },
        setValue('scan_bill', ''),
        setIdBill(null),
        setSupplier(null),
        setFilter(null)
      )
    }
  }

  const validarChk = () => {
    if (getValues('note_credit')) {
      setValue('note_credit', true)
    } else {
      setValue('note_credit', false)
    }

    if (getValues('contability')) {
      setValue('contability', true)
    } else {
      setValue('contability', false)
    }
  }

  const verifyBill = async () => {
    if (idBill === null || idBill === '') return
    try {
      setLoadingBtn(true)
      // console.log(idBill.toUpperCase())
      const upperIdBill = idBill.toUpperCase()
      let stringFormat = ''
      let replaceBefore = upperIdBill.replace(/HTTPS:\/\//, '')
      // console.log('upperIdBill', replaceBefore)
      if (replaceBefore.includes('/') || replaceBefore.includes('&')) {
        console.log('Contiene / o &')
        stringFormat = replaceBefore
          .replace(/HTTPSÑ/, '')
          // .replace(/RE(¡|¿|=|&|)/, '/RE¿')
          // .replace(/RR(¡|¿|=|&|)/, '/RR¿')
          // .replace(/ID(¡|¿|=|&|)/, '/ID¿')
          // .replace(/TT(¡|¿|=|&|)/, '/TT¿')
          .replace(/[\/&_]RE(¡|¿|=|&|)/, '/RE¿')
          .replace(/[\/&_]RR(¡|¿|=|&|)/, '/RR¿')
          .replace(/[\/&_]ID(¡|¿|=|&|)/, '/ID¿')
          .replace(/[\/&_]TT(¡|¿|=|&|)/, '/TT¿')
      } else {
        console.log('No contiene / o &')
        stringFormat = replaceBefore
          .replace(/HTTPSÑ/, '')
          .replace(/RE(¡|¿|=|&|)/, '/RE¿')
          .replace(/RR(¡|¿|=|&|)/, '/RR¿')
          .replace(/ID(¡|¿|=|&|)/, '/ID¿')
          .replace(/TT(¡|¿|=|&|)/, '/TT¿')
      }

      console.log('Cadena formatead', stringFormat)
      const uuidExec = regexID.exec(stringFormat)
      // console.log('uuidExec', uuidExec)
      let uuid = uuidExec[0].replace(/ID(¡|¿|=|)/, '').replace(/('|-)/g, '')
      // console.log('uuid', uuid)

      let includesCharacters = uuid.includes('_')
      if (includesCharacters) {
        const splitUUID = uuid.split('_')
        console.log('splitUUID', splitUUID)
        uuid = splitUUID[0]
      }

      console.log('uuid', uuid)
      const receptorExec = regexReceptor.exec(stringFormat)
      // console.log('receptorExec', receptorExec)
      const receptor = receptorExec[0].replace(/RR(¡|¿|=|)/, '')
      console.log('receptor', receptor)

      let emisor = ''
      const emitterExec = regexEmisor.exec(stringFormat)
      if (emitterExec && emitterExec[2]) {
        emisor = emitterExec[2]
        // const emitter = emitterExec[0].replace(/RE(¡|¿|=|&|)/, '')
      }
      console.log('emitterExec', emitterExec)
      console.log('emisor', emisor)

      // const emisorExec = regexEmisor.exec(stringFormat)
      // // console.log('emisorExec', emisorExec)
      // const emisor = emisorExec[0].replace(/RE(¡|¿|=|)/, '')
      // console.log('emisor', emisor)

      const totalExec = regexTotal.exec(stringFormat)
      // console.log('totalExec', totalExec)
      const total = totalExec[0].replace(/TT(¡|¿|=|)/, '').replace(/,/g, '')
      // console.log('total', total)

      // setSupplier(emisor)

      const { data, error: errorPartner } = await getPartner({
        variables: {
          rfcSearch: emisor,
        },
        fetchPolicy: 'no-cache',
      })

      const { data: dataBill, error: errorBill } = await getBill({
        variables: {
          idCaptured: uuid,
        },
        fetchPolicy: 'no-cache',
      })
      // console.log('errorBill', errorBill)
      // console.log('dataBill', dataBill)
      // console.log(errorPartner)
      // console.log(user.store)

      const RFCVA = 'VAM870320RI3'
      if (RFCVA !== receptor) {
        setLoadingBtn(false)

        return toastSweetAlert(
          {
            mode: 'error',
            message: `El RFC del receptor no es válido: "${receptor}", el RFC del receptor debe ser "${RFCVA}"`,
          },
          setValue('scan_bill', ''),
          setFilter(null),
          setValueInvoice(null),
          setValue('date_bill', ''),
          setValue('invoice_input', ''),
          setSupplier(null)
        )
      }

      const findRoleReception = rolesInfo?.find(
        (item) => item?.role_name === 'Recepción Control de Pagos'
      )

      if (user.store && findRoleReception.id !== user.role) {
        if (!data?.getInfoPartnerForCreateNote) {
          setLoadingBtn(false)
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message:
                'Contacte al administrador para dar de alta a este proveedor',
              timer: 7000,
            },
            setValue('scan_bill', ''),
            setIdBill(null),
            setSupplier(null),
            setFilter(null),
            setBillCaptured(null)
          )
        }
      }

      if (!data?.getInfoPartnerForCreateNote) {
        setLoadingBtn(false)
        setSupplier(emisor)
        findRoleReception?.id === user.role
          ? setIdRoleReception(true)
          : setIdRoleReception(false)
        return setFlag(2)
      }
      // console.log(stringFormat)

      if (dataBill.validateBill || errorBill) {
        setLoadingBtn(false)
        // console.log('Dentro del Error', stringFormat)
        // console.log('flag', flag)
        return setFlag(3)
        // return toastSweetAlert(
        //   {
        //     mode: 'error',
        //     message: errorBill.message,
        //   },
        //   setValue('scan_bill', ''),
        //   setIdBill(null),
        //   setSupplier(null),
        //   setFilter(null)
        // )
        // history.push(`/catalog/notes/validatebill`)
      }

      // console.log(sumTotalRetention)

      const list = {
        id_bill: uuid,
        receptor: receptor,
        emisor: emisor,
        total: parseFloat(total),
        percentage_retention:
          data?.getInfoPartnerForCreateNote?.percentage_retention,
        razon: data?.getInfoPartnerForCreateNote?.provider_name,
        id_partner: data?.getInfoPartnerForCreateNote?.id,
        second_ticket: data?.getInfoPartnerForCreateNote?.second_ticket,
      }
      setFilter(list)
      // console.log(flag)
      setLoadingBtn(false)
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'Error al leer el código escaneado',
        },
        setValue('scan_bill', ''),
        setIdBill(null),
        setSupplier(null),
        setFilter(null)
      )
    }
  }

  const handleRoleForReception = async (shops) => {
    const findRoleFinances = rolesInfo?.find(
      (item) => item?.role_name === 'Finanzas Control de Pagos'
    )
    const findRoleReception = rolesInfo?.find(
      (item) => item?.role_name === 'Recepción Control de Pagos'
    )

    if (findRoleReception?.id === user.role) {
      setIdRoleReception(findRoleReception?.id)
    } else {
      setIdRoleReception(findRoleFinances?.id)
    }
  }

  const handleDeleteRowBill = async (id) => {
    try {
      setLoadingBtn(true)
      // console.log('dataBack', dataBack)
      // console.log('dataFRONT', dataTable)
      // console.log('totalActual', sum)
      // console.log('totalRetencionActual', sumTotalRetention)
      // console.log('id', id)
      const findBillTable = dataTable.find((item) => item.id === id)
      const findBillBack = dataBack.find((item) => item.id === id)
      // console.log(findBillBack)
      // console.log(findBillTable)
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        title: '¿Estas seguro de eliminar esta factura?',
        message: 'No podrás revertir esta acción',
      })
      if (result.isConfirmed) {
        // const findBillBack = dataBack.find((item) => item.id === id)
        const newData = dataTable.filter((item) => item.id !== id)
        const newDataBack = dataBack.filter((item) => item.id !== id)
        // console.log(parseFloat(sum) - parseFloat(findBillBack.amount_bill))
        const substration =
          parseFloat(sum) - parseFloat(findBillBack.amount_bill)
        const substrationRetention =
          parseFloat(sumTotalRetention) -
          parseFloat(findBillBack.retention_bill)
        // console.log('resta', substration)
        // console.log('restaRetencion', substrationRetention)
        setSumTotalRetention(substrationRetention)
        setSum(substration)
        setdataBack(newDataBack)
        setDataTable(newData)
        setLoadingBtn(false)
        toastSweetAlertNotifications({
          mode: 'ok',
          message: 'Factura eliminada correctamente',
        })
      }
      setLoadingBtn(false)
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  const onSubmit = async (Data) => {
    try {
      setLoadingBtn(true)
      if (dataTable.length === 0) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'Aun no hay información registrada',
        })
      }
      let provider_name = dataTable.map((item) => item.razón)[0]
      const dataPrint = {
        consecutive_number:
          infoStore.consecutive_number + valueCountConsecutive.toString(),
        date_note: moment().format('DD/MM/YYYY h:mm:ss a'),
        amount_note: sum?.toFixed(2).toString(),
        retention_note: sumTotalRetention?.toFixed(2).toString(),
        amount_total: Number(
          parseFloat(sum) - parseFloat(sumTotalRetention)
        ).toFixed(2),
        partnerNotes: provider_name,
        bills: dataBack,
      }

      // generateTicketCounterCharges(dataPrint, 1, secondTicket)

      const item = dataBack.map((item) => {
        return {
          id_bill: item.id_bill,
          amount_bill: item.amount_bill.toString(),
          retention_bill: item.retention_bill.toString(),
          date_bill: item.date_bill,
          invoice_bill: item.invoice_bill.toString(),
          note_credit: item.note_credit,
          retail: item.retail,
        }
      })

      await createNote({
        variables: {
          noteInput: {
            date_note: moment().format('YYYY-MM-DD HH:mm:ss'),
            amount_note: sum?.toFixed(2).toString(),
            retention_note: sumTotalRetention?.toFixed(2).toString(),
            pay_note: '0',
            id_partner: valuePartner,
            id_store_register: idStore,
            // id_concept: idConcept,
            consecutive_number: (
              parseFloat(infoStore?.consecutive_number) +
              parseFloat(valueCountConsecutive)
            ).toString(),
            note_for_contability: false,
            note_for_finances: false,
            is_manual_note: false,
          },
          billInput: item,
        },
      })
      setConsecutiveNumber(
        (
          parseFloat(infoStore?.consecutive_number) +
          parseFloat(valueCountConsecutive)
        ).toString()
      )
      setLoadingBtn(false)
      return setFlag(4)
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      {flag === 1 && (
        <>
          <ContentHeader
            title="Generación de contra-recibo"
            windowTitle="Generación de contra-recibo"
            // breadcrumb="Contrarecibo"
          />
          <FormProvider {...methods}>
            <form
              className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
              onSubmit={handleSubmit(handleValueInvoice)}
            >
              <Box
                // title="Generación de contra-recibo"
                errors={errors}
                btnLoading={loadingBtn}
                // btnSubmit={true}
                // btnSubmitText="Añadir Factura"
                btnSubmitNoEnter={handleSubmit(handleValueInvoice)}
                btnSubmitNoEnterTxtSave="Añadir Factura"
                btnOnClick={(e) => onSubmit(e)}
                content={
                  <>
                    <div className="row">
                      <div className="mb-3 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <InputController
                          valueManual={filter?.emisor}
                          label="Escaner Factura"
                          name="scan_bill"
                          control={control}
                          changeAction={(e) => handleChange(e.target.value)}
                          debounceTime={800}
                        />
                      </div>
                      <div className="mb-3 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <InputController
                          label="Razón Social"
                          name="name_provider"
                          control={control}
                          disabled={true}
                          valueManual={filter?.razon ? filter?.razon : ''}
                        />
                      </div>
                      <div className="mb-3 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <InputController
                          label="Fecha Factura"
                          type="date"
                          name="date_bill"
                          control={control}
                          // changeAction={(e) => console.log(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        {/* <Controller
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange, ...field } }) => ( */}
                        <InputController
                          // {...field}
                          label="Folio"
                          name="invoice_input"
                          control={control}
                          blurAction={(e) => setValueInvoice(e.target.value)}
                          // changeAction={({ target: { value } }) => {
                          //   setValueInvoice(value)
                          //   console.log(value)
                          // }}
                          // onKeyDown={(event) => {
                          //   if (event.key === 'ArrowDown') {
                          //     onChange(valueInvoice)
                          //     handleValueInvoice(valueInvoice)
                          //   }
                          // }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              document.getElementById('spanSaveEnter').click()
                            }
                          }}
                        />
                        {/* )}
                      /> */}
                      </div>
                      <div className="mb-3 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <InputController
                          label="CFDI"
                          name="cfdi"
                          control={control}
                          disabled={true}
                          valueManual={filter?.id_bill ? filter?.id_bill : ''}
                        />
                      </div>
                      <div className="mb-3 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <InputController
                          label="Monto Factura"
                          name="amount_bill"
                          control={control}
                          disabled={true}
                          valueManual={
                            filter?.total
                              ? formmatterCurrency(parseFloat(filter?.total))
                              : ''
                          }
                        />
                      </div>
                      {parseInt(idRoleReception) !== parseInt(user.role) && (
                        <div className="mb-3 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                          <InputController
                            label="Retail o Concepto"
                            name="retail"
                            control={control}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                document.getElementById('spanSaveEnter').click()
                              }
                            }}
                          />
                        </div>
                      )}
                      {user.store === null && (
                        <div
                          style={{ zIndex: '2' }}
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-4"
                        >
                          <InputController
                            label="Tiendas"
                            name="id_store"
                            control={control}
                            inputType="choosen"
                            options={options}
                            changeAction={(e) => setIdStore(e.value)}
                            // disabled={user.role === 6 ? false : true}
                          />
                        </div>
                      )}

                      {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <InputController
                          label="Concepto"
                          name="id_concept"
                          control={control}
                          inputType="choosen"
                          options={optionsConcepts}
                          changeAction={(e) => setIdConcept(e.value)}
                          // disabled={user.role === 6 ? false : true}
                        />
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex flex-column align-items-center justify-content-start">
                        <InputController
                          label="Nota de crédito"
                          name="note_credit"
                          inputType="checkbox"
                          control={control}
                        />
                      </div>
                      {/* <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column align-items-center justify-content-start">
                        <InputController
                          label="Contabilidad"
                          name="contability"
                          inputType="checkbox"
                          control={control}
                          // {...register('checkboxGroup.contability')}
                        />
                      </div>
                      <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column align-items-center justify-content-start">
                        <InputController
                          label="Finanzas"
                          name="finances"
                          inputType="checkbox"
                          control={control}
                          // {...register('checkboxGroup.finances')}
                        />
                      </div> */}
                    </div>

                    <LayoutTable
                      title=""
                      withCard={false}
                      hideFilterBar={true}
                      headersButtons={false}
                      hideId
                      titleActions="Borrar"
                      // pagePagination={pagePagination}
                      // setPagePagination={setPagePagination}
                      actionBtnErase={(e) => handleDeleteRowBill(e)}
                      hideBtnEdit
                      hideDetails
                      data={dataTable}
                      paginationServer={false}
                    />
                    <div className="col-12 d-flex flex-column justify-content-around">
                      <div className="row">
                        <div className="col-lg-6">
                          <OrderCol
                            title="Operación:"
                            content={
                              infoStore
                                ? `${
                                    parseFloat(infoStore?.consecutive_number) +
                                    parseFloat(valueCountConsecutive)
                                  }`
                                : '---'
                            }
                            mediumColSize="4"
                          />
                        </div>

                        <div className="col-lg-6 ">
                          <OrderCol
                            title="Total:"
                            content={formmatterCurrency(sum)}
                            mediumColSize="4"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
              <span
                id="spanSaveEnter"
                className="invisible"
                type="submit"
                onClick={handleSubmit(handleValueInvoice)}
              >
                Guardar
              </span>
            </form>
          </FormProvider>
        </>
      )}
      {flag === 2 && (
        <BusinessRetentionNew valueRFC={supplier} reception={idRoleReception} />
      )}
      {flag === 3 && <Validate billCaptured={billCaptured} />}
      {flag === 4 && (
        <DisplayInfoNoteForPrint consecutiveNumber={consecutiveNumber} />
      )}
    </>
  )
}

export default NoteNew
