export const LiveStatus = (_status) => {
  const status = Math.abs(_status)
  // 0.- Pendiente
  // 1.- Correcto
  // 2.- Cantidad con Desviación
  // 3.- Añada con Desviación
  // 4.- Expiración con Desviación
  // 5.- Desviación corregida
  // 6.- Tercer conteo en progreso
  // 7.- Desviación general
  // 8.- Producto nuevo encontrado en ubicación
  // 9.- Producto no encontrado en el conteo opuesto

  const statusBoxDefaultStyle = {
    0: 'statusBox-open',
    1: 'statusBox-processing',
    2: 'statusBox-closed',
    3: 'statusBox-closed',
    4: 'statusBox-closed',
    5: 'statusBox-processing',
    6: 'statusBox-partially-approved',
    7: 'statusBox-closed',
    8: 'statusBox-closed',
    9: 'statusBox-closed',
  }

  const labelStatus = {
    0: 'Pendiente',
    1: 'Correcto',
    2: 'Desv. cantidad',
    3: 'Desv. añada',
    4: 'Desv. expiración',
    5: 'Desv. corregida',
    6: '3er conteo pend.',
    7: 'Desviación',
    8: 'Linea nueva',
    9: 'Sin coincidencia',
  }

  return (
    <div className={`statusBox ${statusBoxDefaultStyle[parseInt(status)]}`}>
      <span>&nbsp;{labelStatus[parseInt(status)]}&nbsp;</span>
    </div>
  )
}

export const historicStatus = (_status) => {
  // 1: Pendiente
  // 2: In review
  // 3: Error
  // 4: finalizado
  const statusBoxDefaultStyle = {
    1: 'statusBox-open',
    2: 'statusBox-processing',
    3: 'statusBox-partially-approved',
    4: 'statusBox-closed',
  }

  const labelStatus = {
    1: 'Pendiente',
    2: 'En revisión',
    3: 'Finalizada',
    4: 'Error',
  }

  return (
    <div className={`statusBox ${statusBoxDefaultStyle[parseInt(_status)]}`}>
      <span>&nbsp;{labelStatus[parseInt(_status)]}&nbsp;</span>
    </div>
  )
}
