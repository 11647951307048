import React, { useEffect, useState } from 'react'
import _logo from '../../../../../Assets/Images/vinos-america-logo.png'
const PrintSecondTicket = React.forwardRef(
  ({ componentRef, TotalRetention, consecutiveNumber }) => {
    const formmatterCurrency = (number) => {
      let numberFormat = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        signDisplay: 'negative',
        maximumFractionDigits: 4,
      }).format(number)

      return numberFormat
    }

    const centerLogo = {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
      height: '100px',
    }

    const textCounterCharges = {
      textAlign: 'center',
      marginTop: '10px',
      fontSize: '50px',
    }
    const textToPay = {
      textAlign: 'center',
      // marginTop: '0px',
      fontSize: '36px',
    }
    const textTotalRetention = {
      textAlign: 'center',
      // marginTop: '10px',
      fontSize: '45px',
    }

    return (
      <>
        <div ref={componentRef} className="page-second-ticket">
          <div>
            <img style={centerLogo} src={_logo} alt="logo" />
          </div>

          <div style={textCounterCharges}>
            Contra-recibo {`${consecutiveNumber}`}
          </div>

          <div style={textToPay}>A pagar</div>

          <div style={textTotalRetention}>
            {formmatterCurrency(TotalRetention)}
          </div>
        </div>
      </>
    )
  }
)

export default PrintSecondTicket
