import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  CREATE_BANK_FINANCES_COUNTER_CHARGE,
  GET_ALL_BANKS_FINANCES_COUNTER_CHARGES,
  GET_ONE_BANK_FINANCES_COUNTER_CHARGE,
  UPDATE_BANK_FINANCES_COUNTER_CHARGE,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/banksfinancescountercharges'
import * as Yup from 'yup'
import InputController from '../../../../Global/InputController'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import Box from '../../../../Global/Box'
import ContentHeader from '../../../../Layout/ContentHeader'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'

const BanksFinancesCounterChargesNew = () => {
  const { id: _id } = useParams()
  const history = useHistory()

  const [loadingBtn, setLoadingBtn] = useState(false)

  const { data: dataOneBank } = useQuery(GET_ONE_BANK_FINANCES_COUNTER_CHARGE, {
    variables: {
      getOneBankFinancesCounterChargesId: parseInt(_id),
    },
    fetchPolicy: 'no-cache',
  })

  const [createBankCounterCharges] = useMutation(
    CREATE_BANK_FINANCES_COUNTER_CHARGE,
    {
      refetchQueries: [
        {
          query: GET_ALL_BANKS_FINANCES_COUNTER_CHARGES,
          variables: {
            searchQuery: null,
            limit: 10,
            offset: 0,
          },
          fetchPolicy: 'no-cache',
        },
      ],
    }
  )

  const [updateBankCounterCharges] = useMutation(
    UPDATE_BANK_FINANCES_COUNTER_CHARGE
  )

  const validationSchema = Yup.object().shape({
    bank_name: Yup.string().required('Este campo es requerido'),
    bank_nomenclature: Yup.string().required('Este campo es requerido'),
    bank_invoice_number: Yup.string().required('Este campo es requerido'),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (_id) {
      if (dataOneBank !== null && dataOneBank !== undefined) {
        const data = dataOneBank?.getOneBankFinancesCounterCharges

        const dataFormated = {
          bank_name: data?.bank_name,
          bank_nomenclature: data?.bank_nomenclature,
          bank_invoice_number: data?.bank_invoice_number,
        }
        reset(dataFormated)
      }
    }
  }, [dataOneBank])

  const onSubmit = async (data) => {
    try {
      console.log(data)
      setLoadingBtn(true)
      if (_id) {
        await updateBankCounterCharges({
          variables: {
            updateBankFinancesCounterChargesId: parseInt(_id),
            bankFinancesCounterChargesInput: {
              bank_name: data.bank_name.trim(),
              bank_nomenclature: data.bank_nomenclature.trim(),
              bank_invoice_number: data.bank_invoice_number.trim(),
            },
          },
        })
        setLoadingBtn(false)
        return toastSweetAlertNotifications(
          {
            mode: 'ok',
            message: 'Se ha actualizado correctamente el banco y los datos',
          },
          history.push('/catalogsforcountercharges/banksfinancescountercharges')
        )
      }

      await createBankCounterCharges({
        variables: {
          bankFinancesCounterChargesInput: {
            bank_name: data.bank_name.trim(),
            bank_nomenclature: data.bank_nomenclature.trim(),
            bank_invoice_number: data.bank_invoice_number.trim(),
          },
        },
      })
      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: 'Se ha creado el y asignado correctamente los datos',
        },
        history.push('/catalogsforcountercharges/banksfinancescountercharges')
      )
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ContentHeader
            windowTitle={_id ? 'Editar Banco' : 'Nuevo Banco'}
            title={_id ? 'Editar Banco' : 'Nuevo Banco'}
            breadcrumb={_id ? 'Editar Banco' : 'Nuevo Banco'}
          />

          <Box
            title={_id ? 'Editar Banco' : 'Nuevo Banco'}
            btnRedPath="/catalogsforcountercharges/banksfinancescountercharges"
            btnRedTxt="Cancelar"
            btnSubmit={true}
            btnSubmitText={_id ? 'Actualizar' : 'Guardar'}
            errors={errors}
            content={
              <>
                <div className="col">
                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <InputController
                        label="Nombre del banco"
                        name="bank_name"
                        control={control}
                        placeholder="Nombre del banco"
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <InputController
                        label="Nomenclatura del banco"
                        name="bank_nomenclature"
                        control={control}
                        placeholder="Nomenclatura del banco"
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <InputController
                        label="Folio del banco"
                        name="bank_invoice_number"
                        control={control}
                        placeholder="Folio del banco"
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default BanksFinancesCounterChargesNew
