import React, { useEffect, useState } from 'react'
import MenuPaginationItem from './MenuPaginationItem'

export const MenuPagination = ({
  data,
  total,
  title,
  handleClick,
  pagePagination,
  setPagePagination,
  handleButton = false,
  handleButtonText = 'Function button',
  noDataText = 'No existen registros',
  dropdownMenu = false,
}) => {
  const pageSize = 10

  const [actualPage, setActualPage] = useState(0)
  const [totalPages, setTotalPages] = useState(Math.ceil(total / pageSize))
  const [statusNext, setStatusNext] = useState(total < 10 ? true : false)
  const [statusPrevious, setStatusPrevious] = useState(true)

  useEffect(() => {
    setStatusNext(total < 10 ? true : false)
    setTotalPages(Math.ceil(total / pageSize))
  }, [total])

  //LOGIC DATA PAGINATION
  const handlePageChange = (pageNumber, action) => {
    const page = action === 'next' ? pageNumber + 1 : pageNumber - 1
    setPagePagination({
      ...pagePagination,
      offset: pagePagination.limit * page,
    })
    setActualPage(page)

    if (page < 1) setStatusPrevious(true)
    else setStatusPrevious(false)

    if (page === totalPages - 1) setStatusNext(true)
    else setStatusNext(false)
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title col-8">{title}</h3>
        <div className="control-btn-box">
          {handleButton && (
            <span
              className="btn btn-accept btn-sm"
              onClick={() => handleButton()}
            >
              {handleButtonText}
            </span>
          )}
        </div>
      </div>
      <div className={`card-body ${data.length > 0 ? '' : 'content-menu'}`}>
        {data.length > 0 && !dropdownMenu ? (
          <div
            className="nav flex-column nav-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            {data.map((item) => (
              <a
                key={`${item.name}-${item.id}`}
                className="nav-link mb-2 item-border"
                id={`${item.name}-${item.id}`}
                data-toggle="pill"
                href={`"#v-pills-${item.name}"`}
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                onClick={() => handleClick(item.id)}
              >
                {item.name}
              </a>
            ))}
          </div>
        ) : dropdownMenu ? (
          <>
            <MenuPaginationItem handleClick={handleClick} data={data} />
          </>
        ) : (
          <div className="text-center">
            <h3>{noDataText}</h3>
          </div>
        )}
      </div>
      <div className="card-footer pagination-buttons-menu">
        <button
          id="pagination-previous-page"
          type="button"
          aria-label="Previous Page"
          aria-disabled="true"
          disabled={statusPrevious}
          className="button_arrow"
          onClick={() => handlePageChange(actualPage, 'previous')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
        <button
          id="pagination-next-page"
          type="button"
          aria-label="Next Page"
          aria-disabled="true"
          disabled={statusNext}
          className="button_arrow"
          onClick={() => handlePageChange(actualPage, 'next')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </div>
      <div className="pagination-counter">
        <span>
          {pageSize * actualPage + 1}-
          {totalPages === 0 && total < 10
            ? data.length /* CALCULATE TOTAL IF THERE IS ONLY 1 PAGE */
            : actualPage < totalPages - 1
            ? totalPages < 2
              ? data.length
              : pageSize *
                (actualPage +
                  1) /* CALCULATE TOTAL IF THERE IS MORE THAN 1 PAGE */
            : data.length + pageSize * actualPage}{' '}
          {/* CALCULATE LAST PAGE */}
          {''} de {total}
        </span>
      </div>
    </div>
  )
}

export default MenuPagination
