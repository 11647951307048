import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ContentHeader from '../../../../Layout/ContentHeader'
import moment from 'moment'
import 'moment/locale/es'
import {
  CHANGE_TO_PROCESS,
  GET_ORDER_BY_ID_STATUS,
  CHANGE_TO_BILLING,
  BILLING_PROCESS,
  CHANGE_TO_PICKING,
} from '../../../../../graphql/Catalog/Orders/pendingOrders'
import { GET_COUNTERS_ORDERS } from '../../../../../graphql/Catalog/Orders/countOrders'
import { useMutation, useQuery } from '@apollo/client'
import OrderGeneral from '../../../../OrderGeneral'
import OrderDetailStore from '../../../../OrderDetailStore'
import OrderShippingDetails from '../../../../OrderShippingDetails'
import OrderButtons from '../../../../OrderButtons'
import OrderProducts from '../../../../OrderProducts'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import Timeline from '../../../../Global/Timeline/Timeline'
import InternalNotes from '../../../../Global/InternalNotes/InternalNotes'
import Swal from 'sweetalert2'
import { RejectOrder } from '../../../../Helpers/RejectOrder'
import { AuthContext } from '../../../../../Auth/AuthContext'
import { GET_ALL_ISSUES } from '../../../../../graphql/Catalog/Issues/issues'
import { CREATE_REASON } from '../../../../../graphql/Catalog/Reasons/reasons'

export const PendingOrdersNew = () => {
  const { id: _id, show } = useParams()
  const history = useHistory()
  const [storeId, setStoreId] = useState('')
  const [uploadReceipt, setUploadReceipt] = useState(false)
  const [dataOrder, setDataOrder] = useState([])
  const [shippingCompanyId, setShippingCompanyId] = useState('')
  const [idOrder, setIdOrder] = useState('')
  const [warehouseId, setWarehouseId] = useState('')
  const [storeFromDB, setStoreFromDB] = useState(false)
  const [issuses, setIssuses] = useState({})
  const [processing, setProcessing] = useState(false)

  /* ORDER DATA */
  const { data, loading, error } = useQuery(GET_ORDER_BY_ID_STATUS, {
    variables: {
      id: parseInt(_id),
      statusId: 1,
    },
  })

  const [changeToProcess] = useMutation(CHANGE_TO_PROCESS, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })
  const [changeToBilling] = useMutation(CHANGE_TO_BILLING, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })
  const [changeToPicking] = useMutation(CHANGE_TO_PICKING, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })
  const [createReason] = useMutation(CREATE_REASON, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })

  const [billingProcess] = useMutation(BILLING_PROCESS, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })

  const { user } = useContext(AuthContext)

  /* GET ALL ISSUSES DATA */
  const {
    data: dataIssuses,
    loading: loadingIssuses,
    error: errorIssuses,
  } = useQuery(GET_ALL_ISSUES)

  useEffect(() => {
    if (!loading) {
      if (error)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/dashboard')
        )

      const orderData = data.getOrderByIdAndStatus
      if (orderData.store?.name || orderData.warehouse?.name)
        setStoreFromDB(true)
      setStoreId(orderData.store_id)
      setWarehouseId(orderData.warehouse_id)
      setIdOrder(orderData.order_id)
      if (orderData?.shippingCompany?.name) {
        setShippingCompanyId(orderData?.shipping_company_id)
      }
      if (orderData?.shipping?.rececipt?.url) {
        setUploadReceipt(orderData.shipping.receipt.url)
      }
      console.log(orderData)
      setDataOrder(orderData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  /* SET DATA TO NEW OBJ */
  useEffect(() => {
    if (!loadingIssuses && !errorIssuses) {
      let list = {}
      dataIssuses.getAllIssusses.map((item) => {
        list = Object.assign(list, { [item.id]: item.name })
        return true
      })
      setIssuses(list)
    }
  }, [dataIssuses, loadingIssuses, errorIssuses])

  const onSubmitLocal = async () => {
    if (!storeId)
      return toastSweetAlert({
        mode: 'error',
        message: 'Por favor seleccione una tienda',
      })

    try {
      setProcessing(true)
      const { data } = await changeToProcess({
        variables: {
          orderId: `${_id}`,
          storeId: parseInt(storeId),
        },
      })
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Pedido procesado',
        },
        history.goBack()
      )
    } catch (error) {
      setProcessing(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const onSubmitNational = async () => {
    if (!warehouseId)
      return toastSweetAlert({
        mode: 'error',
        message: 'Por favor seleccione un almacén',
      })
    if (!shippingCompanyId)
      return toastSweetAlert({
        mode: 'error',
        message: 'Por favor seleccione una empresa de logística',
      })

    if (!dataOrder?.shipping?.receipt) {
      if (!uploadReceipt)
        return toastSweetAlert({
          mode: 'error',
          message: 'Cargue la ficha de envío',
        })
    }

    try {
      setProcessing(true)
      const { data } = await changeToBilling({
        variables: {
          uploadReceipt: dataOrder?.shipping?.receipt?.url
            ? null
            : uploadReceipt,
          orderId: `${_id}`,
          shippingCompanyId: parseInt(shippingCompanyId),
        },
      })
      if (data.changeToBilling) {
        const { data: processBilling } = await billingProcess({
          variables: {
            orderId: `${_id}`,
          },
        })
        if (processBilling.billingProcess) {
          return toastSweetAlert(
            {
              mode: 'ok',
              message: 'Pedido procesado',
            },
            history.goBack()
          )
        } else {
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
          setProcessing(false)
        }
      }
    } catch (error) {
      setProcessing(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleStoreChange = (e) => {
    setStoreId(parseInt(e.value))
  }
  const handleShippingCompanyChange = (e) => {
    setShippingCompanyId(parseInt(e.value))
  }

  moment.locale('es') //Change dates languages to spanish

  return (
    <>
      <>
        <ContentHeader
          title="Pedido pendiente"
          breadcrumb="Pendiente"
          windowTitle={`${_id ? 'Editar' : 'Agregar'} pedido `}
        />
        <div className={`row ${processing ? 'processing-file' : ''}`}>
          <div className="col-8">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Detalles</h3>
                  </div>
                  <div className="card-body col-12">
                    <OrderGeneral
                      orderDate={moment(dataOrder?.order_date).format(
                        'DD/MM/YYYY HH:mm'
                      )}
                      orderId={dataOrder?.order_id}
                      paymentId={dataOrder?.payment?.payment_id}
                      platform={dataOrder?.platform?.name}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Detalles de pago</h3>
                  </div>
                  <div className="card-body">
                    <OrderDetailStore
                      orderType={dataOrder?.type?.name}
                      orderStore={
                        dataOrder?.store?.name || dataOrder?.warehouse?.name
                      }
                      orderPaymentPlatform={dataOrder?.payment?.platform}
                      orderMethod={dataOrder?.method?.name}
                      withStore={storeFromDB}
                      totalPrice={dataOrder?.total_price}
                      shippingPrice={dataOrder?.shipping_price}
                      id_store={storeId}
                      status={dataOrder?.status?.id}
                      uberAssignedId={dataOrder?.uber_id}
                      id_shippingCompany={shippingCompanyId}
                      status_id={dataOrder?.status_id}
                      handleStoreChange={handleStoreChange}
                      shippingCompanyName={
                        dataOrder?.shippingCompany?.name
                          ? dataOrder?.shippingCompany?.name
                          : null
                      }
                      handleShippingCompanyChange={handleShippingCompanyChange}
                      setUploadReceipt={setUploadReceipt}
                      shippingReceipt={
                        dataOrder?.shipping?.receipt?.url
                          ? dataOrder?.shipping?.receipt?.url
                          : false
                      }
                      show={show}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Desglose de productos</h3>
                  </div>
                  <div className="card-body">
                    <OrderProducts
                      productsArray={dataOrder?.products}
                      totalQuantity={dataOrder?.product_quantity}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Detalles de envío</h3>
                  </div>
                  <div className="card-body">
                    <OrderShippingDetails
                      city={dataOrder?.shipping?.city}
                      address={dataOrder?.shipping?.address_1}
                      postcode={dataOrder?.shipping?.postcode}
                      email={dataOrder?.shipping?.email}
                      phone={dataOrder?.shipping?.phone}
                      firstName={dataOrder?.shipping?.first_name}
                      lastName={dataOrder?.shipping?.last_name}
                      state={dataOrder?.shipping?.state}
                    />
                  </div>
                </div>
              </div>
              {!show ? (
                <OrderButtons
                  goBack
                  cancelBtn={'Rechazar'}
                  onCancel={async () =>
                    RejectOrder(
                      issuses,
                      user.idUser,
                      createReason,
                      idOrder,
                      history
                    )
                  }
                  processBtn={'Procesar'}
                  onProcess={
                    dataOrder?.type_id === 1 ? onSubmitNational : onSubmitLocal
                  }
                />
              ) : (
                <OrderButtons
                  oneButton={true}
                  onProcess={() => history.goBack()}
                  firstButtonGray={true}
                  processBtn="Volver"
                />
              )}
            </div>
          </div>

          {/* TIMELINE & INTERNAL NOTES */}
          <div className="col-4">
            <Timeline idOrder={idOrder} />
            <InternalNotes idOrder={idOrder} />
          </div>
        </div>
      </>
    </>
  )
}

export default PendingOrdersNew
