import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export const Chart = ({
    options = {}
}) => {

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default Chart