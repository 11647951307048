import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useState } from 'react'
import { useEffect } from 'react'
import Box from '../../../../Global/Box'
import InputController from '../../../../Global/InputController'
import OrderCol from '../../../../Global/OrderCol'
import * as yup from 'yup'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import {
  GET_ONE_NOTE,
  GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER,
  GET_ONE_NOTE_BY_UUID_FOR_BILL,
  UPDATE_NOTES_AMOUNT,
} from '../../../../../graphql/Catalog/Notes/notes'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { GET_STORES } from '../../../../../graphql/Catalog/Stores/stores'
import { AuthContext } from '../../../../../Auth/AuthContext'
import { GET_INFO_STORE_COUNTER_CHARGES } from '../../../../../graphql/Catalog/Notes/StoreHelperCounterCharges/storehelpercountercharges'
import {
  GET_COUNT_PAYMENTS_FOR_REFERENCE,
  GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE_BY_INVOICE_NUMBER_PAYMENTS,
} from '../../../../../graphql/Catalog/Notes/payments'
import PrintCheckCoverages from './PrintCheckCoverages/PrintCheckCoverages'
import ReactToPrint from 'react-to-print'
import { useRef } from 'react'
import LayoutTable from '../../../../Global/LayoutTable'
import { GET_ROLES } from '../../../../../graphql/Catalog/Roles/roles'
import DisplayInfoForPrintCoverage from './DisplayInfoForPrintCoverage'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'
import ContentHeader from '../../../../Layout/ContentHeader'

const ExecutedPayment = ({ infoBills, handleNextStep, idNote }) => {
  const { user } = useContext(AuthContext)
  // const componentRef = useRef()
  // console.log(user)
  const regexID = new RegExp('id(¡|¿|=)[^/ | &]+', 'i')
  const regexEmisor = new RegExp('re(¡|¿|=)[^/ | &]+', 'i')
  const regexReceptor = new RegExp('rr(¡|¿|=)[^/ | &]+', 'i')
  const regexTotal = new RegExp('tt(¡|¿|=)[^/ | &]+', 'i')

  const billArray = []
  const infoIdNote = idNote
  const history = useHistory()
  const [totalSum, setTotalSum] = useState(0.0)
  const [TotalRetention, setTotalRetention] = useState(0.0)
  const [valueSearchNote, setValueSearchNote] = useState(null)
  const [flag, setFlag] = useState(1)
  const [valueNominative, setValueNominative] = useState(false)
  const [valueCarrier, setValueCarrier] = useState(false)
  const [idStoreRegister, setIdStoreRegister] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [options, setOptions] = useState([])
  const [idStore, setIdStore] = useState(null)
  const [valueStore, setValueStore] = useState(null)
  const [nameStore, setNameStore] = useState(null)
  const [infoStore, setInfoStore] = useState(null)
  const [idRole, setIdRole] = useState(null)
  const [dateInfo, setDateInfo] = useState('')
  const [invoiceNumberPayment, setInvoiceNumberPayment] = useState(null)
  const [valueCountReference, setValueCountReference] = useState(null)
  const [valueProviderNameNm, setValueProviderNameNm] = useState(null)
  const [checkNumber, setCheckNumber] = useState('')
  const [dataTable, setDataTable] = useState([])
  const [dataBack, setDataBack] = useState([])
  const [infoNote, setInfoNote] = useState(null)
  const {
    loading: loadingStores,
    error: errorStores,
    data: dataStores,
  } = useQuery(GET_STORES)
  // const {
  //   loading: loadingInfoNote,
  //   error: errorInfoNote,
  //   data: dataInfoNote,
  // } = useQuery(GET_ONE_NOTE, {
  //   variables: {
  //     getOneNoteId: infoIdNote,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const [getOneNoteByConsecutive] = useLazyQuery(
    GET_ONE_NOTE_BY_CONSECUTIVE_NUMBER
  )

  const [getRoles] = useLazyQuery(GET_ROLES)

  const [getOneNoteByUUIDForBill] = useLazyQuery(GET_ONE_NOTE_BY_UUID_FOR_BILL)

  // const {
  //   loading: loadingGetInfoStore,
  //   error: errorGetInfoStore,
  //   data: dataGetInfoStore,
  // } = useQuery(GET_INFO_STORE_COUNTER_CHARGES, {
  //   variables: {
  //     id: parseInt(idStore),
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const [getInfoStore] = useLazyQuery(GET_INFO_STORE_COUNTER_CHARGES)

  const {
    loading: loadingCountReference,
    error: errorCountReference,
    data: dataCountReference,
  } = useQuery(GET_COUNT_PAYMENTS_FOR_REFERENCE, {
    variables: {
      idStore: parseInt(idStore),
    },
    fetchPolicy: 'no-cache',
  })

  const [updateNotesAmount] = useMutation(UPDATE_NOTES_AMOUNT)

  const validationSchema = yup.object().shape({
    id_store_payment: yup
      .string()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),
    check_number: yup
      .string()
      .min(4, 'Mínimo 4 dígitos')
      .max(4, 'Máximo 4 dígitos')
      .matches(/^[0-9]+$/, 'Este campo solo acepta números')
      .required('Este campo es obligatorio'),
    check_date: yup.date().typeError('Este campo es obligatorio'),
    // nominative: yup.boolean().typeError('Este campo es obligatorio'),
    // carrier: yup.boolean().typeError('Este campo es obligatorio'),
    // bills: yup.array().of(
    //   yup.object().shape({
    //     amount_bill: yup.number().typeError('Este campo es obligatorio'),
    //   })
    // ),
  })
  // .test((obj) => {
  //   if (obj.nominative && obj.carrier) {
  //     return true
  //   }
  //   return new yup.ValidationError('Debes marcar almenos una opción')
  // })

  const {
    methods,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'bills',
  })

  useEffect(() => {
    if ((dataCountReference !== null) & (dataCountReference !== undefined)) {
      if (!loadingCountReference) {
        if (errorCountReference)
          return toastSweetAlert({
            mode: 'error',
            message: errorCountReference.message,
          })

        // console.log(dataCountReference.getCountPaymentsForReference.rows)

        const dataCount = dataCountReference.getCountPaymentsForReference.rows

        let count = 0
        let consecutiveNumber = null
        for (let data of dataCount) {
          if (consecutiveNumber !== data?.invoice_number_payments) {
            count++
          }
          consecutiveNumber = data?.invoice_number_payments
        }

        // console.log('count', count)

        setValueCountReference(count + 1)
      }
    }
  }, [loadingCountReference, errorCountReference, dataCountReference])

  // useEffect(() => {
  //   if (idStore) {
  //     if (!loadingGetInfoStore) {
  //       console.log('errorGetInfoStore', errorGetInfoStore)
  //       if (errorGetInfoStore)
  //         return toastSweetAlert(
  //           {
  //             mode: 'error',
  //             message: errorGetInfoStore.message,
  //           },
  //           history.push('/catalog/notes/shownotes')
  //         )
  //       setInfoStore(dataGetInfoStore.getInfoStoreCounterCharges)
  //     }
  //   }
  // }, [loadingGetInfoStore, errorGetInfoStore, dataGetInfoStore])

  useEffect(async () => {
    // if (user.store || idStore) {
    const { data } = await getInfoStore({
      variables: {
        id: idStore ? idStore : null,
      },
    })

    if (data === null) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'La tienda no tiene información para crear contrarecibos',
        },
        history.push('/shownotes')
      )
    }

    // console.log('Data', data.getInfoStoreCounterCharges)
    setInfoStore(data.getInfoStoreCounterCharges)
    // }
  }, [user.store, idStore])

  useEffect(async () => {
    if (!loadingStores) {
      if (errorStores)
        return toastSweetAlert({
          mode: 'error',
          message: errorStores.message,
        })
      // if (user.role === 1 || user.role === 5) {
      const shops = dataStores?.getAllStores?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.alias,
        }
      })
      // console.log('shops', shops)
      // await handleRoleForStore(shops)
      setOptions(shops)
      setValue('id_store_payment', user.store)
      setIdStore(user.store)
      setValue('check_date', moment().format('YYYY-MM-DD'))
      handleChangeDate(moment().format('YYYY-MM-DD'))
    }
  }, [loadingStores, errorStores, dataStores])

  // const handleRoleForStore = async (shops) => {
  //   const { data: roles } = await getRoles({
  //     variables: {},
  //     fetchPolicy: 'no-cache',
  //   })

  //   console.log('roles', roles)

  //   const dataRoles = roles?.getAllRoles?.rows

  //   const findRoleShop = await dataRoles?.find(
  //     (item) => item?.role_name === 'Tienda'
  //   )

  //   const findRoleAdmin = await dataRoles?.find(
  //     (item) => item?.role_name === 'Administrador'
  //   )

  //   console.log('findRoleAdmin', findRoleAdmin)
  //   console.log('user.role', user.role)

  //   if (parseInt(findRoleAdmin?.id) !== parseInt(user.role)) {
  //     if (findRoleShop?.id === user.role) {
  //       setValue('id_store', user?.store?.toString())
  //       setIdStore(user?.store)
  //     }

  //     if (parseInt(findRoleShop?.id) !== parseInt(user.role)) {
  //       const findShopMatriz = await shops?.find(
  //         (item) => item.label === 'Matriz'
  //       )

  //       console.log('findShopMatriz', findShopMatriz)

  //       setValue('id_store', findShopMatriz?.value?.toString())
  //       setIdStore(findShopMatriz?.value)

  //       console.log('findRoleShop', findRoleShop)
  //     }
  //   }
  //   setIdRole(findRoleAdmin?.id)
  // }

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const amountTotal = () => {
    let total = 0.0
    for (const amount of billArray) {
      // console.log('Monto', amount.amount_bill)
      total += parseFloat(amount.amount_bill)
      // }
      // console.log(total.toFixed(2))
    }
    setTotalSum(total.toFixed(2))
  }

  const handleChangeDate = (date) => {
    // console.log(date)
    const dateFormated = moment(date, 'YYYY-MM-DD').format('MMYY')
    setDateInfo(dateFormated)
  }

  useEffect(() => {
    if (valueSearchNote) {
      // if (handleCheckbox(getValues('nominative'), getValues('carrier'))) return
      // console.log('valueSearchNote', valueSearchNote.length)
      let valueCheckNumber = getValues('check_number')
      // console.log('valueCheckNumber', valueCheckNumber.length)
      const valueSearchNoteUpper = valueSearchNote.toUpperCase()

      if (valueSearchNoteUpper.includes('ID' || 'ID¡' || 'ID¿' || 'ID=')) {
        // console.log('valueSearchNoteUpper', valueSearchNoteUpper)
        return (
          handleSearchNoteForUUID(valueSearchNoteUpper),
          setValueSearchNote(null)
        )
      }

      handleSearchNote()
      setValueSearchNote(null)
    }
  }, [valueSearchNote])

  useEffect(() => {
    if (infoNote) {
      handleAddNote(infoNote)
      // setInfoNote(null)
    }
  }, [infoNote])

  const handleSearchNoteForUUID = async (uuid) => {
    try {
      setLoadingBtn(true)
      let stringFormat
      let replaceBefore = uuid.replace(/HTTPS:\/\//, '')
      if (replaceBefore.includes('/') || replaceBefore.includes('&')) {
        stringFormat = replaceBefore.replace(/HTTPSÑ/, '')
      } else {
        stringFormat = replaceBefore
          .replace(/HTTPSÑ/, '')
          .replace(/RE(¡|¿|=|&|)/, '/RE¿')
          .replace(/RR(¡|¿|=|&|)/, '/RR¿')
          .replace(/ID(¡|¿|=|&|)/, '/ID¿')
          .replace(/TT(¡|¿|=|&|)/, '/TT¿')
      }

      // console.log('stringFormat', stringFormat)

      const uuidExec = regexID.exec(stringFormat)
      let uuidForm = uuidExec[0].replace(/ID(¡|¿|=|)/, '').replace(/('|-)/g, '')
      let includesCharacters = uuidForm.includes('_')
      if (includesCharacters) {
        const splitUUID = uuidForm.split('_')
        console.log('splitUUID', splitUUID)
        uuidForm = splitUUID[0]
      }

      console.log('uuidForm', uuidForm)
      const { data: dataOneNote, error: errorOneNote } =
        await getOneNoteByUUIDForBill({
          variables: {
            uuid: uuidForm,
            notesForContability: true,
          },
          fetchPolicy: 'no-cache',
        })

      if (errorOneNote) {
        return toastSweetAlert(
          {
            mode: 'error',
            message: errorOneNote.message,
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      }

      if (dataOneNote?.getOneNoteByUUIDForBill?.full_payed)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'Este contrarecibo no se puede agregar a la lista porque ya se encuentra pagado',
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      if (idStoreRegister !== null) {
        if (
          dataOneNote?.getOneNoteByUUIDForBill?.id_store_register !==
          idStoreRegister
        ) {
          return toastSweetAlert(
            {
              mode: 'error',
              message:
                'Este contrarecibo no se puede agregar a la lista porque no pertenece a la misma tienda',
            },
            setLoadingBtn(false),
            setValueSearchNote(null),
            setInfoNote(null),
            setValue('invoice_countercharges', '')
          )
        }
      }

      // console.log(dataOneNote?.getOneNoteByUUIDForBill)

      setInfoNote(dataOneNote?.getOneNoteByUUIDForBill)
      setIdStoreRegister(
        dataOneNote?.getOneNoteByUUIDForBill?.id_store_register
      )
      setIdStore(dataOneNote?.getOneNoteByUUIDForBill?.id_store_register)
      setValue(
        'id_store_payment',
        dataOneNote?.getOneNoteByUUIDForBill?.id_store_register?.toString()
      )
      setLoadingBtn(false)
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Error al leer el código escaneado',
        },
        setValueSearchNote(null),
        setInfoNote(null),
        setValue('invoice_countercharges', '')
      )
    }
  }

  const handleSearchNote = async () => {
    try {
      if (valueSearchNote === null || valueSearchNote === '') return
      setLoadingBtn(true)
      const { data: dataOneNote, error: errorOneNote } =
        await getOneNoteByConsecutive({
          variables: {
            consecutiveNumber: valueSearchNote,
            notesForContability: true,
          },
          fetchPolicy: 'no-cache',
        })

      if (errorOneNote) {
        return toastSweetAlert(
          {
            mode: 'error',
            message: errorOneNote.message,
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      }

      if (dataOneNote?.getOneNoteByConsecutiveNumber?.full_payed)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'Este contrarecibo no se puede agregar a la lista porque ya se encuentra pagado',
          },
          setLoadingBtn(false),
          setValueSearchNote(null),
          setInfoNote(null),
          setValue('invoice_countercharges', '')
        )
      if (idStoreRegister !== null) {
        if (
          dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register !==
          idStoreRegister
        ) {
          return toastSweetAlert(
            {
              mode: 'error',
              message:
                'Este contrarecibo no se puede agregar a la lista porque no pertenece a la misma tienda',
            },
            setLoadingBtn(false),
            setValueSearchNote(null),
            setInfoNote(null),
            setValue('invoice_countercharges', '')
          )
        }
      }

      // console.log(dataOneNote?.getOneNoteByConsecutiveNumber)

      setInfoNote(dataOneNote?.getOneNoteByConsecutiveNumber)
      setIdStoreRegister(
        dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register
      )
      setIdStore(dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register)
      setValue(
        'id_store_payment',
        dataOneNote?.getOneNoteByConsecutiveNumber?.id_store_register?.toString()
      )
      setLoadingBtn(false)
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Error al buscar el contrarecibo',
        },
        setValueSearchNote(null),
        setInfoNote(null),
        setValue('invoice_countercharges', '')
      )
    }
  }

  const handleAddNote = (data) => {
    console.log(data)
    if (!data) return
    const amountToPay = parseFloat(data.amount_note) - parseFloat(data.pay_note)
    // console.log('dataTable INICIO', dataTable)
    // console.log('dataBack INICIO', dataBack)
    const listTable = dataTable.map((item) => {
      return {
        id: item.id,
        consecutivo: item.consecutivo,
        importe: item.importe,
        retención: item.retención,
        proveedor: item.proveedor,
      }
    })

    const listBack = dataBack.map((item) => {
      return {
        id: item.id,
        consecutive: item.consecutive,
        amount: item.amount,
        retention: item.retention,
        rfc: item.rfc,
      }
    })

    const findNote = listBack.find((item) => item.id === data?.id)
    // console.log('findNote', findNote)
    if (findNote) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'Este contrarecibo ya se encuentra agregado en la tabla',
        },
        setValueSearchNote(null),
        setInfoNote(null),
        setValue('invoice_countercharges', '')
      )
    }

    let sumAmount = sumTotalAmount(data.amount_note, data.retention_note)

    console.log('sumAmount', sumAmount)

    listBack.push({
      id: data?.id,
      consecutive: data?.consecutive_number,
      amount: data?.amount_note,
      retention: data?.retention_note,
      rfc: data?.partnerNotes?.provider_rfc
        ? data?.partnerNotes?.provider_rfc
        : 'XAXX010101000',
    })

    listTable.push({
      id: data?.id,
      consecutivo: data?.consecutive_number,
      importe: formmatterCurrency(data?.amount_note),
      retención: formmatterCurrency(data?.retention_note),
      proveedor: data?.partnerNotes?.provider_name
        ? data?.partnerNotes?.provider_name
        : data?.bills[0]?.provider_name_nm,
    })

    setTotalSum(sumAmount.sum)
    // setTotalRetention(sumAmount.sumRetention)
    setDataBack(listBack)
    setDataTable(listTable)
    setValue('invoice_countercharges', null)
    handleNominative(listBack)
    setValueProviderNameNm(data?.bills[0]?.provider_name_nm)
    // console.log('dataBack FINAL', dataBack)
    // console.log('dataTable FINAL', dataTable)
  }

  const sumTotalAmount = (amount, retention) => {
    let sum = 0.0
    let sumRetention = 0.0
    sum = totalSum + parseFloat(amount)
    sumRetention = TotalRetention + parseFloat(retention)

    sum = sum - sumRetention
    return {
      sum,
      sumRetention,
    }
  }

  const handleDeleteNote = async (id) => {
    try {
      setLoadingBtn(true)
      // console.log('flag Inicio', flag)
      const findNoteTable = dataTable.find((item) => item.id === id)
      const findNoteBack = dataBack.find((item) => item.id === id)
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        icon: 'warning',
        title: '¿Estas seguro de eliminar este contrarecibo?',
        message: `No podrás revertir esta acción`,
      })

      if (result.isConfirmed) {
        const newData = dataTable.filter((item) => item.id !== id)
        const newDataBack = dataBack.filter((item) => item.id !== id)
        console.log(
          'amount - retention',
          parseFloat(findNoteBack.amount - findNoteBack.retention)
        )
        const substractionAmount =
          parseFloat(totalSum) -
          parseFloat(findNoteBack.amount - findNoteBack.retention)
        // console.log(newDataBack)
        handleNominative(newDataBack)
        setTotalSum(substractionAmount)
        setDataBack(newDataBack)
        setDataTable(newData)
        setLoadingBtn(false)
        if (dataBack.length === 1) {
          setIdStore(null)
          setValue('id_store_payment', '')
          setIdStoreRegister(null)
        }
        toastSweetAlert({
          mode: 'ok',
          message: 'Contrarecibo eliminado correctamente',
        })
      }
      setLoadingBtn(false)
    } catch (e) {
      setLoadingBtn(false)
      console.log(e)
    }
  }

  const handleNominative = (data, ignore) => {
    // let valueNominative = e.target.checked
    // console.log(data)
    // console.log(ignore)
    let partnerCount = 0

    // console.log('partnerCount', partnerCount)
    // if(data.length === 0) return setFlag(3)
    // if (e) {
    let partnerBefore = data.map((item) => item.rfc)[0]
    for (let dataNote of data) {
      // console.log('partnerBefore', partnerBefore)
      let partnerAcual = dataNote.rfc
      // console.log('partnerAcual', partnerAcual)
      if (partnerBefore !== partnerAcual) {
        // if (
        //   partnerAcual === 'XAXX010101000' ||
        //   partnerBefore === 'XAXX010101000'
        // ) {
        partnerCount++
        // }
      }
      partnerBefore = partnerAcual
      // console.log('partnerCount', partnerCount)
    }
    // }

    if (partnerCount === 0) {
      setValue('carrier', false)
      setValueCarrier(false)
      setValue('nominative', true)
      setValueNominative(true)
    }
    // if (!ignore) {
    if (partnerCount > 0) {
      // setFlag(2)
      setValue('nominative', false)
      setValueNominative(false)
      setValue('carrier', true)
      setValueCarrier(true)
    }
  }

  const assignIdStoreAndName = (e) => {
    // console.log('Store', e)
    let id = e?.value
    let name = e?.label
    setIdStore(id)
    setNameStore(name)
  }

  useEffect(() => {
    if (valueStore) {
      assignIdStoreAndName(valueStore)
    }
  }, [valueStore])

  const handleLimitCharacters = (value, limitCharacters) => {
    if (value.length > limitCharacters) {
      value = value.slice(0, limitCharacters)
    }
    setValue('check_number', value)
    setCheckNumber(value)
  }

  const assignIdStoreAndNameStore = (id) => {
    // console.log('Store', id)
    const findStore = dataStores?.getAllStores?.rows?.find(
      (item) => item.id === id
    )
    // console.log('findStore', findStore)
    setNameStore(findStore?.name)
    setIdStore(findStore?.id)
  }

  useEffect(() => {
    if (user.store) {
      assignIdStoreAndNameStore(user.store)
    }
  }, [infoBills, dataStores])

  const onSubmit = async (Data) => {
    try {
      setLoadingBtn(true)
      // console.log('valueNominative', valueNominative)
      if (dataBack.length === 0)
        return toastSweetAlert(
          {
            mode: 'error',
            message: 'Debes agregar al menos un contrarecibo',
          },
          setLoadingBtn(false)
        )

      if (totalSum <= 0)
        return toastSweetAlert(
          {
            mode: 'error',
            message:
              'El total de los contrarecibos no puede ser menor o igual a 0. Agregue un contrarecibo con un monto mayor al total de los contrarecibos',
          },
          setLoadingBtn(false)
        )

      const noteData = dataBack.map((item) => {
        return {
          id: item.id,
          pay_note: parseFloat(item.amount),
        }
      })

      // console.log(
      //   `${infoStore?.invoice_payments}${valueCountReference.toString()}`
      // )

      // infoCoverage.push({
      //   checkNumber: Data.check_number,
      //   invoicePay: `${
      //     infoStore?.invoice_payments
      //   }${valueCountReference.toString()}`,
      //   dateCheck: moment(Data.check_date).format('DD/MM/YYYY'),
      //   total: totalSum,
      //   provider: valueNominative
      //     ? infoNote?.partnerNotes?.provider_name
      //       ? infoNote?.partnerNotes?.provider_name
      //       : valueProviderNameNm
      //     : 'Al portador',
      //   store: nameStore,
      // })

      const { data: dataUpdateNotes } = await updateNotesAmount({
        variables: {
          noteInput: noteData,
          totalPayed: parseFloat(totalSum),
          dateCheck: moment(Data.check_date).format('YYYY-MM-DD hh:mm:ss a'),
          numberCheck: Data.check_number,
          idStorePayment: parseInt(Data.id_store_payment),
          invoiceNumberPayments: `${
            parseFloat(infoStore?.invoice_payments) +
            parseFloat(valueCountReference)
          }`,
          referencePay:
            // infoStore?.invoice_payments +
            // valueCountReference.toString() +
            // '.' +
            infoStore?.nomenclature_initials +
            '.' +
            dateInfo +
            '.' +
            'CH' +
            '.' +
            checkNumber,
          payNominative: valueNominative,
        },
      })

      // console.log('dataUpdateNotes', dataUpdateNotes)

      setLoadingBtn(false)
      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: 'El pago se ha realizado con éxito',
        },

        setInvoiceNumberPayment(
          `${
            parseFloat(infoStore?.invoice_payments) +
            parseFloat(valueCountReference)
          }`
        ),
        setFlag(2)
        // document.getElementById('handleNextStep').click()
        // history.push('/catalog/notes/generatenewpayment')
      )
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        title="Generar pago con cheque"
        windowTitle="Pagos con cheque"
      />

      {flag === 1 && (
        <FormProvider {...methods}>
          <form
            className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              // title="Efectuar Pago"
              btnLoading={loadingBtn}
              errors={errors}
              // btnSubmit={true}
              // btnSubmitText="Generar Pago"
              // btnSecondFunction={handlePreviousStep}
              btnSubmitNoEnter={handleSubmit(onSubmit)}
              btnSubmitNoEnterTxtSave="Generar Pago"
              content={
                <>
                  <div className="row">
                    <div
                      className={`${
                        parseInt(idRole) === parseInt(user.role)
                          ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                          : 'col-12 col-sm-4 col-md-4 col-lg-4'
                      }`}
                    >
                      <InputController
                        label="Folio contrarecibo o factura"
                        name="invoice_countercharges"
                        control={control}
                        // blurAction={(e) => setValueSearchNote(e.target.value)}
                        // changeAction = {({ target: {value} }) =>{
                        //   setValueSearchNote(value)
                        //   console.log('value', value)
                        // }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            console.log('event', event)
                            setValueSearchNote(event.target.value)
                          }
                        }}
                      />
                    </div>
                    <div
                      className={`${
                        parseInt(idRole) === parseInt(user.role)
                          ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                          : 'col-12 col-sm-4 col-md-4 col-lg-4'
                      }`}
                    >
                      <InputController
                        label="Número de Cheque"
                        name="check_number"
                        control={control}
                        changeAction={(e) => setCheckNumber(e.target.value)}
                        limitCharacters={4}
                        debounceTime={0}
                        // onKeyDown={(event) => {
                        //   handleLimitCharacters(event.target.value, 4)
                        // }}
                      />
                    </div>
                    {user.store === null && (
                      <div
                        className={`${
                          user.store === null
                            ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                            : 'col-12 col-sm-4 col-md-4 col-lg-4'
                        }`}
                      >
                        <InputController
                          id="inputStorePayment"
                          label="Tiendas"
                          name="id_store_payment"
                          type="number"
                          inputType="choosen"
                          disabled={idStoreRegister ? true : false}
                          control={control}
                          options={options}
                          changeAction={(e) => setValueStore(e)}
                        />
                      </div>
                    )}
                    <div
                      className={`${
                        parseInt(idRole) === parseInt(user.role)
                          ? 'col-12 col-sm-3 col-md-3 col-lg-3'
                          : 'col-12 col-sm-4 col-md-4 col-lg-4'
                      }`}
                    >
                      <InputController
                        label="Fecha"
                        name="check_date"
                        type="date"
                        control={control}
                        changeAction={(e) => handleChangeDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex flex-column align-items-center justify-content-start">
                      <InputController
                        label="Nominativa"
                        name="nominative"
                        inputType="checkbox"
                        control={control}
                        debounceTime={0}
                        disabled={true}
                        checked={valueNominative}
                        changeAction={(e) =>
                          setValueNominative(e.target.checked)
                        }
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex flex-column align-items-center justify-content-start">
                      <InputController
                        label="Portador"
                        name="carrier"
                        inputType="checkbox"
                        control={control}
                        disabled={true}
                        debounceTime={0}
                        checked={valueCarrier}
                        changeAction={(e) => setValueCarrier(e.target.checked)}
                      />
                    </div>
                  </div>

                  <LayoutTable
                    data={dataTable}
                    hideId
                    withCard={false}
                    headersButtons={false}
                    hideFilterBar={true}
                    titleActions="Borrar"
                    hideBtnEdit
                    hideDetails
                    paginationServer={false}
                    actionBtnErase={(e) => handleDeleteNote(e)}
                  />
                  <br />

                  <div className="col-12 d-flex flex-column justify-content around">
                    <div className="row">
                      <div className="col-lg-8">
                        <OrderCol
                          title="Referencia:"
                          content={
                            infoStore
                              ? `${
                                  parseFloat(infoStore?.invoice_payments) +
                                  parseFloat(valueCountReference)
                                }.${
                                  infoStore?.nomenclature_initials
                                }.${dateInfo}.CH.${checkNumber}`
                              : '---'
                          }
                          mediumColSize="8"
                        />
                      </div>

                      <div className="col-lg-4">
                        <OrderCol
                          title="Total:"
                          content={formmatterCurrency(totalSum)}
                          mediumColSize="4"
                        />
                      </div>
                    </div>

                    {/* <div className="d-flex flex-row-reverse">
                    <OrderCol
                      title="Folio Pago:"
                      content={
                        infoStore
                          ? `${infoStore?.invoice_payments}.${valueCountReference}`
                          : '---'
                      }
                      mediumColSize="4"
                    />
                  </div> */}
                  </div>
                </>
              }
            />
            {/* <button
              id="handleNextStep"
              onClick={handleNextStep}
              className="invisible"
              // setInvoiceNumberPayment={`${
              //   infoStore?.invoice_payments
              // }${valueCountReference?.toString()}`}
            ></button> */}
          </form>
        </FormProvider>
      )}
      {flag === 2 && (
        <DisplayInfoForPrintCoverage
          invoiceNumberPayments={invoiceNumberPayment}
          setFlag={setFlag}
          // handlePreviousStep={handlePreviousStep}
        />
      )}
    </>
  )
}

export default ExecutedPayment
