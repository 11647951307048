import { useMutation, useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { AuthContext } from '../../../Auth/AuthContext'
import {
  GET_INVENTORY_ACTIVITY_COUNT_ASSIGNMENT_BY_USER,
  INVENTORY_ACTIVITY_VALIDATE_START_ASSIGNMENT,
} from '../../../graphql/InventoryActivities/InventoryActivitiesCount'

export const InventoryActivitiesCounts = () => {
  const { user } = useContext(AuthContext)
  const [countAssignedInfo, setCountAssignedInfo] = useState({
    scheduleInfo: '',
    nextElement: '',
    pendingElements: 0,
  })

  const history = useHistory()
  const { control, getValues, setValue } = useForm({})

  const {
    data: countAssignedData,
    loading: countAssignedLoading,
    error: countAssignedError,
  } = useQuery(GET_INVENTORY_ACTIVITY_COUNT_ASSIGNMENT_BY_USER, {
    fetchPolicy: 'no-cache',
  })

  const [startScan] = useMutation(INVENTORY_ACTIVITY_VALIDATE_START_ASSIGNMENT)

  useEffect(() => {
    try {
      if (countAssignedInfo.scheduleInfo === 0) return
      if (countAssignedLoading) return
      if (countAssignedError)
        return toastSweetAlert({
          mode: 'error',
          message: countAssignedError.message,
        })

      setCountAssignedInfo({
        scheduleInfo:
          countAssignedData.getInventoryActivityCountByUser.scheduleInfo,
        nextElement:
          countAssignedData.getInventoryActivityCountByUser.nextElement,
        pendingElements:
          countAssignedData.getInventoryActivityCountByUser.pendingElements,
      })
      setValue(
        'schedule_id',
        countAssignedData?.getInventoryActivityCountByUser.scheduleInfo.id
      )
      setValue(
        'label_id',
        countAssignedData?.getInventoryActivityCountByUser.nextElement.labelId
      )
      setValue(
        'count_number',
        countAssignedData?.getInventoryActivityCountByUser.nextElement
          .countNumber
      )
      setValue(
        'schedule_info',
        countAssignedData?.getInventoryActivityCountByUser.scheduleInfo
      )
      setValue(
        'next_element',
        countAssignedData?.getInventoryActivityCountByUser.nextElement
      )
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [countAssignedData, countAssignedLoading, countAssignedError])

  const handleFindRackStartScan = async (scan) => {
    try {
      if (scan.target.value.trim() === '') {
        setValue('scan_input', '')
        return toastSweetAlert({
          mode: 'errorToast',
          message: 'Código de barras incorrecto',
        })
      }
      if (
        scan.target.value.trim() !==
        countAssignedInfo?.nextElement?.elementAssigned
      ) {
        setValue('scan_input', '')
        return toastSweetAlert({
          mode: 'errorToast',
          message:
            'La etiqueta de la ubicación escaneada no coincide con la sugerida',
        })
      }

      await startScan({
        variables: {
          scheduleId: getValues('schedule_id'),
          scanElement: scan.target.value,
        },
      })

      return history.push(`/counts/edit/${getValues('schedule_id')}`, {
        scheduleInfo: getValues('schedule_info'),
        labelId: getValues('label_id'),
        countNumber: getValues('count_number'),
        nextElement: getValues('next_element'),
      })
    } catch (error) {
      setValue('scan_input', '')
      return toastSweetAlert({ mode: 'errorToast', message: error.message })
    }
  }

  return (
    <>
      <div className="row pt-4">
        <div className="col-sm-12">
          <h1 className="header-title">Bienvenido {user.name}</h1>
        </div>
        <div className="col-sm-12">
          <h5>
            {
              countAssignedInfo?.scheduleInfo
                ?.InventoryActivitiesWarehouseSchedule?.warehouse_name
            }
          </h5>
          <p>
            {countAssignedInfo?.scheduleInfo?.type_schedule === 2
              ? 'TFI'
              : `Conteo cíclico - ${
                  countAssignedInfo?.scheduleInfo?.ubications_or_skus === 2
                    ? 'SKUS'
                    : 'Ubicaciones'
                }`}
          </p>
        </div>
      </div>
      <Box
        title={null}
        content={
          <>
            {countAssignedInfo.pendingElements === 0 ? (
              <>
                <h3 className="mb-2">
                  Siguiente ubicación: <b>Finalizado</b>
                </h3>
                <p>Asignaciones pendientes: 0</p>
              </>
            ) : (
              <>
                {countAssignedInfo?.scheduleInfo?.ubications_or_skus === 2 && (
                  <b>
                    {countAssignedInfo?.nextElement?.skuOrRackId} -{' '}
                    {countAssignedInfo?.nextElement?.itemName}
                  </b>
                )}
                <h3 className="mb-2">
                  Siguiente ubicación:{' '}
                  <b>{countAssignedInfo?.nextElement?.elementAssigned}</b>
                </h3>
                <p>
                  Asignaciones pendientes: {countAssignedInfo?.pendingElements}
                </p>
                <InputController
                  label="Escanear rack"
                  control={control}
                  name="scan_input"
                  placeholder="Escanea la etiqueta del rack"
                  changeAction={handleFindRackStartScan}
                />
                <InputController
                  control={control}
                  name="schedule_id"
                  addClass="invisible"
                />
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default InventoryActivitiesCounts
