import React, { useEffect, useState } from 'react'

export const LayoutCardTable = ({
  data = [],
  paginationServer = false,
  paginationTotalRows = 0,
  paginationComponentOptions,
  pointerOnHover = false,
  conditionalRowStyles,
  onChangeRowsPerPage = () => {},
  onChangePage = () => {},
  noDataComponent = 'No hay datos',
  progressPending = false,
  progressComponent = <></>,
  subHeaderComponent = <></>,
  actionsButtons = false,
  hideId = false,
}) => {
  const pageSize = 10
  const [actualPage, setActualPage] = useState(0)
  const [totalPages, setTotalPages] = useState(
    Math.ceil(paginationTotalRows / pageSize)
  )
  const [statusNext, setStatusNext] = useState(
    paginationTotalRows < 10 ? true : false
  )
  const [statusPrevious, setStatusPrevious] = useState(true)

  useEffect(() => {
    setStatusNext(paginationTotalRows < 10 ? true : false)
    setTotalPages(Math.ceil(paginationTotalRows / pageSize))
  }, [paginationTotalRows])

  //LOGIC DATA PAGINATION
  const handlePageChange = (pageNumber, action) => {
    let page = action === 'next' ? pageNumber + 1 : pageNumber - 1
    onChangePage(page + 1)
    setActualPage(page)

    if (page < 1) setStatusPrevious(true)
    else setStatusPrevious(false)

    if (page === totalPages - 1) setStatusNext(true)
    else setStatusNext(false)
  }

  const handlePerRowsChange = async (newPerPage) => {
    if (!paginationServer) return
    onChangeRowsPerPage(parseInt(newPerPage.target.value))
  }

  return (
    <div className="container-card-layout-table">
      {subHeaderComponent && (
        <div className="subheader-component">{subHeaderComponent}</div>
      )}
      <div className={`card-body ${data.length > 0 ? '' : 'content-menu'}`}>
        {progressPending ? (
          <div className="text-center">{progressComponent}</div>
        ) : data.length > 0 ? (
          data.map((row, idx) => (
            <div key={`${idx}-card-layout-table`} className="card">
              <div className="card-body">
                <div className="col-layout-card-table">
                  <div>
                    {Object.entries(row).map((itemsRow) => (
                      <div
                        className={`${
                          itemsRow[0].includes('omitField') ||
                          (itemsRow[0] === 'id' && hideId && 'display-none')
                        }`}
                      >
                        <b className="cardTitle">{itemsRow[0]}</b>
                        <br />
                        <span className="cardContent">{itemsRow[1]}</span>
                      </div>
                    ))}
                    {actionsButtons && (
                      <div>
                        <b className="cardTitle">Acciones</b>
                        <span className="cardContent">
                          {actionsButtons.actions(row)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <h3>{noDataComponent}</h3>
          </div>
        )}
      </div>
      <div className="card-footer pagination-buttons-menu">
        <span className="rows-perpage-card-layout pagination-counter">
          {paginationComponentOptions.rowsPerPageText}
        </span>
        <div className=" row-perpage-select-card-layout">
          <select
            aria-label="Filas por página"
            className="sc-cxabCf MEdZT"
            onChange={(e) => handlePerRowsChange(e)}
          >
            <option value="10" defaultValue="10">
              10
            </option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M7 10l5 5 5-5z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <button
          id="pagination-previous-page"
          type="button"
          aria-label="Previous Page"
          aria-disabled="true"
          disabled={statusPrevious}
          className="button_arrow"
          onClick={() => handlePageChange(actualPage, 'previous')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
        <button
          id="pagination-next-page"
          type="button"
          aria-label="Next Page"
          aria-disabled="true"
          disabled={statusNext}
          className="button_arrow"
          onClick={() => handlePageChange(actualPage, 'next')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </div>

      <div className="pagination-counter">
        <span>
          {pageSize * actualPage + 1}-
          {totalPages === 0 && paginationTotalRows < 10
            ? data.length /* CALCULATE TOTAL IF THERE IS ONLY 1 PAGE */
            : actualPage < totalPages - 1
            ? totalPages < 2
              ? data.length
              : pageSize *
                (actualPage +
                  1) /* CALCULATE TOTAL IF THERE IS MORE THAN 1 PAGE */
            : data.length + pageSize * actualPage}{' '}
          {/* CALCULATE LAST PAGE */}
          {''} {paginationComponentOptions.rangeSeparatorText}{' '}
          {paginationTotalRows}
        </span>
      </div>
    </div>
  )
}

export default LayoutCardTable
