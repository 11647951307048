import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, set, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import {
  CFDI_DATA,
  OPTIONS_MONTH,
  PAYMENT_FORMS,
  PAYMENT_METHODS,
} from '../DataBilling/DataBilling'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_CLIENTS_TO_BILLING_MUTATIION } from '../../../graphql/Catalog/Billing/documenttobillingclients'
import { toastSweetAlertNotifications } from '../../Helpers/ToastSweetAlertNotifications'
import { CREATE_DOCUMENT_TO_BILLING } from '../../../graphql/Catalog/Billing/documenttobilling'
const FormToUploadDocumentBilling = ({
  refModal,
  refetch,
  closeModal,
  setCloseModal,
  openModal,
  setOpenModal,
}) => {
  const [optionsMonth, setOptionsMonth] = useState([])
  const [optionsYear, setOptionsYear] = useState([])
  const [clientSelected, setClientSelected] = useState(null)
  const [rfcClientSelected, setRfcClientSelected] = useState(null)
  const [clientOptions, setClientOptions] = useState([])
  const [optionsCFDI, setOptionsCFDI] = useState([])
  const [optionsPaymentMethod, setOptionsPaymentMethod] = useState([])
  const [optionsPaymentForm, setOptionsPaymentForm] = useState([])
  const [defaultClient, setDefaultClient] = useState(false)
  const [inputValue, setInputValue] = useState('')
  // const [document, setDocument] = useState({})

  const validationSchema = yup.object().shape({
    client_uid: yup.string().typeError('Cliente es requerido').required(),
    cfdi_use: yup.string().typeError('Uso de CFDI es requerido').required(),
    payment_method: yup
      .string()
      .typeError('Método de pago es requerido')
      .required(),
    payment_form: yup
      .string()
      .typeError('Forma de pago es requerido')
      .required(),
    month: yup.string().when([], {
      is: () => rfcClientSelected === 'XAXX010101000',
      then: yup.string().typeError('Mes es requerido').required(),
      otherwise: yup.string().nullable(),
    }),
    year: yup.string().when([], {
      is: () => rfcClientSelected === 'XAXX010101000',
      then: yup.string().typeError('Año es requerido').required(),
      otherwise: yup.string().nullable(),
    }),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const [getClients] = useMutation(GET_ALL_CLIENTS_TO_BILLING_MUTATIION)
  const [createBilling] = useMutation(CREATE_DOCUMENT_TO_BILLING)

  useEffect(() => {
    if (CFDI_DATA) {
      let optionsCFDI = CFDI_DATA.map((item) => {
        return {
          value: item.value,
          label: `${item.value} - ${item.label}`,
        }
      })

      setOptionsCFDI(optionsCFDI)
      setValue('cfdi_use', 'S01')
    }

    if (PAYMENT_METHODS) {
      let optionsPaymentMethod = PAYMENT_METHODS.map((item) => {
        return {
          value: item.value,
          label: `${item.value} - ${item.label}`,
        }
      })

      // console.log(optionsPaymentMethod, 'optionsPaymentMethod')

      setOptionsPaymentMethod(optionsPaymentMethod)
      setValue('payment_method', 'PUE')
    }

    if (PAYMENT_FORMS) {
      let optionsPaymentForm = PAYMENT_FORMS.map((item) => {
        return {
          value: item.value,
          label: `${item.value} - ${item.label}`,
        }
      })

      setOptionsPaymentForm(optionsPaymentForm)
      setValue('payment_form', '31')
    }
  }, [CFDI_DATA, PAYMENT_METHODS, PAYMENT_FORMS])

  useEffect(() => {
    try {
      const loadClients = async (value) => {
        // console.log(value, 'value')
        const clients = await getClients({
          variables: {
            searchQuery: value,
            limit: 500,
            offset: 0,
          },
          fetchPolicy: 'no-cache',
        })

        let options = []

        if (clients.data.getAllClientsToBilling.count > 0) {
          for (const row of clients.data.getAllClientsToBilling.rows) {
            options.push({
              value: row.invoice_uid,
              label: `${row.name} - ${row.rfc}`,
              rfc: row.rfc,
            })

            if (row.rfc === 'XAXX010101000' && value === 'XAXX010101000') {
              setClientSelected(row.invoice_uid)
              setRfcClientSelected(row.rfc)
            }
          }

          setClientOptions(options)
        }
      }

      if (inputValue !== '') {
        loadClients(inputValue)
      }

      if (inputValue === '') {
        return
      }

      if (inputValue === 'restart') {
        loadClients('XAXX010101000')
        setInputValue('')
      }
    } catch (error) {
      handleCloseModal()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [inputValue])

  useEffect(() => {
    try {
      if (openModal) {
        // console.log(openModal, 'openModal')
        let clientDefaault = ''
        // console.log('openModal', openModal)
        const loadClients = async () => {
          let client = ''
          // console.log('loadClients')
          const clients = await getClients({
            variables: {
              searchQuery: 'XAXX010101000',
              limit: 500,
              offset: 0,
            },
            fetchPolicy: 'no-cache',
          })

          // console.log(clients, 'clients USEEFFECT LOAD CLIENTS')

          let options = []

          if (clients.data.getAllClientsToBilling.count > 0) {
            for (const row of clients.data.getAllClientsToBilling.rows) {
              options.push({
                value: row.invoice_uid,
                label: `${row.name} - ${row.rfc}`,
                rfc: row.rfc,
              })

              if (row.rfc === 'XAXX010101000') {
                client = row.invoice_uid
                setClientSelected(row.invoice_uid)
                setRfcClientSelected(row.rfc)
                setValue('client_uid', row.invoice_uid)
              }
            }

            // console.log(options, 'options USEEFFECT LOAD CLIENTS')
            setClientOptions(options)
            // callback(options)
            // return client
          }
        }

        if (!clientSelected) loadClients()

        let optionsYear = []
        let currentMonth = moment().format('MM')
        let currentYear = moment().format('YYYY')
        let beforeYear = moment().subtract(1, 'year').format('YYYY')

        optionsYear.push(
          {
            value: currentYear,
            label: currentYear,
          },
          {
            value: beforeYear,
            label: beforeYear,
          }
        )

        // console.log(clientDefaault, 'clientDefaault')

        setOptionsYear(optionsYear)
        setValue('month', currentMonth)
        setValue('year', currentYear)
        // setValue('payment_form', '31')
        // setValue('cfdi_use', 'S01')
        // setValue('payment_method', 'PUE')
        setValue('client_uid', clientSelected)
        document.getElementById('file').value = null
      }
    } catch (error) {
      handleCloseModal()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [openModal])

  useEffect(() => {
    if (rfcClientSelected !== 'XAXX010101000') {
      setValue('month', null)
      setValue('year', null)
    }

    if (rfcClientSelected === 'XAXX010101000') {
      const currentMonth = moment().format('MM')
      const currentYear = moment().format('YYYY')
      setValue('month', currentMonth)
      setValue('year', currentYear)
    }
  }, [rfcClientSelected])

  // const handleSelectClient = async (val, callback) => {
  //   try {
  //     if (val.length > 0) {
  //       const clients = await getClients({
  //         variables: {
  //           searchQuery: val,
  //           limit: 100,
  //           offset: 0,
  //         },
  //         fetchPolicy: 'no-cache',
  //       })

  //       // console.log(clients, 'clients handleSelectClient')

  //       let options = []

  //       if (clients.data.getAllClientsToBilling.count > 0) {
  //         for (const row of clients.data.getAllClientsToBilling.rows) {
  //           options.push({
  //             value: row.invoice_uid,
  //             label: `${row.name} - ${row.rfc}`,
  //           })
  //         }

  //         // console.log(options, 'options handleSelectClient')
  //         callback(options)
  //       }
  //     }
  //   } catch (error) {
  //     return toastSweetAlert({
  //       mode: 'error',
  //       message: error.message,
  //     })
  //   }
  // }

  const handleCloseModal = (e) => {
    document.getElementById('file').value = null
    setRfcClientSelected(null)
    setClientSelected(null)
    setInputValue('restart')
    setCloseModal(false)
    setOpenModal(false)
    refModal.current.click()
  }

  const handleClientSelected = (e) => {
    // console.log(e, 'e')
    setRfcClientSelected(e.rfc)
    setClientSelected(e.value)
    setValue('client_uid', e.value)
  }

  const onSubmit = async (data) => {
    try {
      // console.log(data, 'data')
      const file = document.getElementById('file')

      toastSweetAlert({
        mode: 'loading',
        message: 'Procesando archivo ...',
      })

      // console.log(file.files[0], 'file.files[0]')

      if (!file.files[0]) {
        // console.log('No hay archivo')
        setTimeout(() => {
          return toastSweetAlertNotifications({
            mode: 'error',
            message: 'Debes seleccionar un archivo',
            timer: 6000,
            timerProgressBar: true,
          })
        }, 100)
        return
      }

      const { data: dataBilling } = await createBilling({
        variables: {
          file: file.files[0],
          input: {
            client_uid: data.client_uid,
            cfdi_use: data.cfdi_use,
            payment_method: data.payment_method,
            payment_form: data.payment_form,
            month: data.month,
            year: data.year,
          },
        },
      })

      if (dataBilling.createDocumentToBilling) {
        await refetch()
        setTimeout(() => {
          return toastSweetAlertNotifications(
            {
              mode: 'ok',
              message: 'Documento facturado correctamente',
              timer: 6000,
              timerProgressBar: true,
            },
            handleCloseModal()
          )
        })
      }
    } catch (error) {
      await refetch()
      return toastSweetAlert(
        {
          mode: 'error',
          message: error.message,
        },
        handleCloseModal()
      )
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            title="Datos del cliente"
            btnSubmit
            btnSubmitText="Facturar"
            withCard={false}
            btnSecondFunction={(e) => handleCloseModal(e)}
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={(e) => handleCloseModal(e)}
            content={
              <>
                <div className="row p-2">
                  <div
                    className="col-lg-12"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: '2px solid #ccc',
                      borderRadius: '5px',
                    }}
                  >
                    <input
                      id="file"
                      type="file"
                      style={{
                        margin: '10px',
                      }}
                    />
                    <label htmlFor="file">
                      <i className="fas fa-upload ml-1"> </i>{' '}
                      {` Seleccionar archivo`}
                    </label>
                  </div>
                  <div className="col-6">
                    {/* <label>Cliente</label>
                    <AsyncSelect
                      id="client_uid"
                      key="client_uid"
                      placeholder="Cliente"
                      className="form-control p-0"
                      loadOptions={handleSelectClient}
                      // onInputChange={(val, callback) => {
                      //   handleSelectClient(val, callback)
                      // }}
                      onChange={(e) => {
                        setClientSelected(e.value)
                      }}
                      menuPosition="fixed"
                      noOptionsMessage={() => 'No se encontraron clientes'}
                      defaultOptions={clientOptions}
                    /> */}
                    <InputController
                      control={control}
                      name="client_uid"
                      label="Cliente"
                      placeHolderChoosen="Cliente"
                      inputType="choosen"
                      options={clientOptions}
                      menuPosition="fixed"
                      onInputChange={(val) => setInputValue(val)}
                      changeAction={(e) => handleClientSelected(e)}
                    />
                  </div>
                  <div className="col-6">
                    <InputController
                      control={control}
                      name="cfdi_use"
                      label="Uso de CFDI"
                      inputType="choosen"
                      options={optionsCFDI}
                      placeHolderChoosen="Uso de CFDI"
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="col-6">
                    <InputController
                      control={control}
                      name="payment_method"
                      label="Método de pago"
                      inputType="choosen"
                      options={optionsPaymentMethod}
                      placeHolderChoosen="Método de pago"
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="col-6">
                    <InputController
                      control={control}
                      name="payment_form"
                      label="Forma de pago"
                      placeHolderChoosen="Forma de pago"
                      inputType="choosen"
                      options={optionsPaymentForm}
                      menuPosition="fixed"
                    />
                  </div>
                  {rfcClientSelected === 'XAXX010101000' && (
                    <>
                      <div className="col-6">
                        <InputController
                          control={control}
                          name="month"
                          label="Mes"
                          inputType="choosen"
                          options={OPTIONS_MONTH}
                          placeHolderChoosen="Mes"
                          menuPosition="fixed"
                        />
                      </div>
                      <div className="col-6">
                        <InputController
                          control={control}
                          name="year"
                          label="Año"
                          inputType="choosen"
                          options={optionsYear}
                          // placeholder="Año"
                          placeHolderChoosen="Año"
                          menuPosition="fixed"
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default FormToUploadDocumentBilling
