import { useQuery } from '@apollo/client'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useState } from 'react'
import { GET_COMPARATIVE_GRAPHICS } from '../../../../graphql/Catalog/ProvidersDashboard/providersDashboard'
import Loader from '../../../Global/Spinner'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import InventoryDisplacementGraphic from './inventoryDisplacementGraphic'
const InventoryDisplacemntsGraphics = ({ type, title }) => {
  //DISPLACEMENTS
  const [categories, setCategories] = useState([])
  const [series, setSeries] = useState([{}])
  const [totalQuantity, setTotalQuantity] = useState([])

  //INVENTORY
  const [seriesInventory, setSeriesInventory] = useState([{}])
  const [categoriesInventory, setCategoriesInventory] = useState([])
  const [totalQuantityInventory, setTotalQuantityInventory] = useState([])

  const { data, error, loading } = useQuery(GET_COMPARATIVE_GRAPHICS, {
    variables: {
      type,
    },
  })
  const options = {
    colors: ['#C3353F', '#43F20F', '#0C90DC', '#7A0ECA'],
    chart: {
      type: 'line',
      height: '50%',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories,
    },

    series: [
      {
        name: title,
        data: totalQuantity,
      },
      ...series,
    ],

    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            chart: {
              height: '330px',
            },
            subtitle: {
              text: null,
            },
            navigator: {
              enabled: false,
            },
          },
        },
      ],
    },
  }
  const optionsInventory = {
    colors: ['#C3353F', '#43F20F', '#0C90DC', '#7A0ECA'],
    chart: {
      type: 'line',
      height: '50%',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: categoriesInventory,
    },

    series: [
      {
        name: title,
        data: totalQuantityInventory,
      },
      ...seriesInventory,
    ],

    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            chart: {
              height: '330px',
            },
            subtitle: {
              text: null,
            },
            navigator: {
              enabled: false,
            },
          },
        },
      ],
    },
  }

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      // console.log(data)
      let categoriesArray =
        data.getComparativeGraphicsInventoryDisplacements.displacements.map(
          (item) => item.name
        )
      const totalQuantityArray =
        data.getComparativeGraphicsInventoryDisplacements.displacements.map(
          (item) => item.quantity
        )
      let categoriesInventoryArray =
        data.getComparativeGraphicsInventoryDisplacements.inventory.map(
          (item) => item.name
        )
      const totalQuantityInventoryArray =
        data.getComparativeGraphicsInventoryDisplacements.inventory.map(
          (item) => item.quantity
        )

      //SHOWING LAST MONTH, BIWEEKLY, Año a la fecha, Últimos 12 meses FROM DISPLACEMENTS
      if (
        data.getComparativeGraphicsInventoryDisplacements.all.displacements
          .lastMonth
      ) {
        const lastMonth =
          data.getComparativeGraphicsInventoryDisplacements.all.displacements.lastMonth.map(
            (item) => item.quantity
          )
        const biweekly =
          data.getComparativeGraphicsInventoryDisplacements.all.displacements.lastBiweekly.map(
            (item) => item.quantity
          )
        const yearToDate =
          data.getComparativeGraphicsInventoryDisplacements.all.displacements.yearToDate.map(
            (item) => item.quantity
          )
        const rollingYear =
          data.getComparativeGraphicsInventoryDisplacements.all.displacements.rollingYear.map(
            (item) => item.quantity
          )

        let seriesDisplacements = [
          {
            name: 'Al último corte',
            data: lastMonth,
          },
          {
            name: 'Quincena pasada',
            data: biweekly,
          },
          {
            name: 'Año a la fecha',
            data: yearToDate,
          },
          {
            name: 'Últimos 12 meses',
            data: rollingYear,
          },
        ]

        setSeries(seriesDisplacements)
        setCategories(categoriesArray)
      }

      //SHOWING LAST MONTH, BIWEEKLY  FROM INVENTORY
      if (
        data.getComparativeGraphicsInventoryDisplacements.all.inventory
          .lastMonth
      ) {
        const lastMonth =
          data.getComparativeGraphicsInventoryDisplacements.all.inventory.lastMonth.map(
            (item) => item.quantity
          )
        const biweekly =
          data.getComparativeGraphicsInventoryDisplacements.all.inventory.lastBiweekly.map(
            (item) => item.quantity
          )

        let seriesInventory = [
          {
            name: 'Al último corte',
            data: lastMonth,
          },
          {
            name: 'Quincena pasada',
            data: biweekly,
          },
        ]

        setSeriesInventory(seriesInventory)
        setCategoriesInventory(categoriesInventoryArray)
      }

      if (+type != 6) {
        setSeries([])
        setCategories([])
        setSeriesInventory([])
        setCategoriesInventory([])
      } else {
      }
      //DISPLACEMENTS
      setTotalQuantity(totalQuantityArray)
      setCategories(categoriesArray)

      //INVENTORY
      setCategoriesInventory(categoriesInventoryArray)
      setTotalQuantityInventory(totalQuantityInventoryArray)
    }
  }, [data, error, loading, type])

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-12">
          <h3 className="ml-5">
            {type == 3 || type == 4
              ? 'Filtro no disponible para inventarios'
              : 'Inventarios'}
          </h3>
          {loading ? (
            <div className="mx-auto d-flex justify-content-center align-items-center mt-3">
              <Loader />
            </div>
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsInventory}
            />
          )}
        </div>
        <div className="col-lg-6 col-12">
          <h3 className="ml-5">Desplazamientos</h3>
          {loading ? (
            <div className="mx-auto d-flex justify-content-center align-items-center mt-3">
              <Loader />
            </div>
          ) : (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      </div>
      <div className="row  justify-content-center mt-3">
        <div className="col-2 d-flex align-items-center justify-content-center">
          <div
            className="boxLeyendColor"
            style={{ backgroundColor: '#C3353F' }}
          ></div>
          <p className="m-0">{type == 6 ? 'Año a la fecha' : title} </p>
        </div>
        {type == 6 && (
          <>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <div
                className="boxLeyendColor"
                style={{ backgroundColor: '#7A0ECA' }}
              ></div>
              <p className="m-0">Quincena pasada</p>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <div
                className="boxLeyendColor"
                style={{ backgroundColor: '#0C90DC' }}
              ></div>
              <p className="m-0">Al último corte</p>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <div
                className="boxLeyendColor"
                style={{ backgroundColor: '#43F20F' }}
              ></div>
              <p className="m-0">Últimos 12 meses</p>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default InventoryDisplacemntsGraphics
