import React, { useEffect, useState, useRef, useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import LayoutTable from '../../Global/LayoutTable'
import InputController from '../../Global/InputController'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ModalContent from '../../Global/ModalContent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

// apollo
import {
  GET_ALL_INVENTORY_ACTIVITIES_RACKS_ACTIVES,
  WAREHOUSE_IS_STORE,
  EXPORT_INVENTORY_ACTIVITIES_LABEL,
  IMPORT_INVENTORY_ACTIVITIES_LABEL,
} from '../../../graphql/InventoryActivities/InventoryActivitiesLabels'
import { GET_SAP_WAREHOUSES } from '../../../graphql/Catalog/SAP/sapWarehouses'
import {
  GET_RACK_DETAILS,
  CREATE_RACK,
  CREATE_RACK_BY_SERIE,
  UPDATE_RACK,
  DELETE_RACK,
} from '../../../graphql/Catalog/Stowing/racks'
import { AuthContext } from '../../../Auth/AuthContext'

const InventoryActivitiesLabels = () => {
  const { user } = useContext(AuthContext)
  const [pagePagination, setPagePagination] = useState({
    limit: 30,
    offset: 0,
    searchQuery: '',
    warehouseId: user.warehouse_id,
  })
  const [total, setTotal] = useState(0)
  const [dataToTable, setDataToTable] = useState([])
  const [warehouseSelect, setWarehouseSelect] = useState([])
  const [listOfWarehousesOptions, setListOfWarehousesOptions] = useState([])
  const refModalInd = useRef()
  const refModalSer = useRef()
  const MySwal = withReactContent(Swal)
  const [showExportImport, setShowExportImport] = useState(false)

  /* QUERY - MUTATIONS */
  const {
    loading: InventoryActivitiesRacksLoading,
    error: InventoryActivitiesRacksError,
    data: InventoryActivitiesRacksData,
    refetch: InventoryActivitiesRacksRefetch,
  } = useQuery(GET_ALL_INVENTORY_ACTIVITIES_RACKS_ACTIVES, {
    variables: pagePagination,
  })

  const [getOneRack] = useMutation(GET_RACK_DETAILS, {
    variables: {
      getRackInfoId: 0,
    },
  })

  const {
    data: listOfWarehouses,
    loading: loadingListOfWarehouses,
    error: errorListOfWarehouses,
  } = useQuery(GET_SAP_WAREHOUSES)

  const [warehouseIsStore] = useMutation(WAREHOUSE_IS_STORE)

  const [updateRack] = useMutation(UPDATE_RACK)

  const [exportRacks] = useMutation(EXPORT_INVENTORY_ACTIVITIES_LABEL)

  const [createRack] = useMutation(CREATE_RACK)

  const [deleteRack] = useMutation(DELETE_RACK)

  const [createRackBySerie] = useMutation(CREATE_RACK_BY_SERIE)

  const { control, setValue } = useForm({})

  /* USEEFFECTS */
  useEffect(() => {
    try {
      if (InventoryActivitiesRacksLoading) return
      if (InventoryActivitiesRacksError)
        return toastSweetAlert({
          mode: 'error',
          message: InventoryActivitiesRacksError.message,
        })
      const list =
        InventoryActivitiesRacksData?.GetAllInventoryActivitiesRacksActives?.rows.map(
          (item) => {
            return {
              id: item?.id,
              Código: item?.code,
              Etiqueta: item?.name,
            }
          }
        )
      setDataToTable(list)
      setTotal(
        InventoryActivitiesRacksData?.GetAllInventoryActivitiesRacksActives
          ?.count
      )
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [
    InventoryActivitiesRacksLoading,
    InventoryActivitiesRacksError,
    InventoryActivitiesRacksData,
  ])

  useEffect(() => {
    try {
      if (loadingListOfWarehouses) return
      if (errorListOfWarehouses)
        return toastSweetAlert({
          mode: 'error',
          message: errorListOfWarehouses.message,
        })

      const list = listOfWarehouses?.getSapWarehouses.map((item) => {
        return {
          label: item.warehouse_name,
          value: item.id,
        }
      })
      setListOfWarehousesOptions(list)
      setValue('warehouse', user.warehouse_id)
      setWarehouseSelect(list)

      const initSel = async () => {
        const isStore = await warehouseIsStore({
          variables: {
            warehouseId: user.warehouse_id,
          },
        })

        setShowExportImport(isStore?.data?.warehouseIsStore ? false : true)
      }

      initSel()
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [loadingListOfWarehouses, listOfWarehouses, errorListOfWarehouses])

  /* VALIDATIONS */
  const patt = /[A-Z]-[0-9]+-[0-9]+\w.-[0-9]+/i

  const validationIndividual = Yup.object().shape({
    /* warehouseId: Yup.number()
      .required('Este campo es obligatorio')
      .positive()
      .integer()
      .min(1), */
    code: Yup.string()
      .matches(patt, {
        excludeEmptyString: true,
        message: 'Formato inválido ej: A-3-08A-1',
      })
      .required('Este campo es obligatorio'),
    label: Yup.string()
      .matches(patt, {
        excludeEmptyString: true,
        message: 'Formato inválido ej: A-3-08A-1',
      })
      .required('Este campo es obligatorio'),
  })

  const resInd = useForm({ resolver: yupResolver(validationIndividual) })

  const validationSerie = Yup.object().shape({
    /* warehouseId: Yup.number()
      .required('Este campo es obligatorio')
      .positive()
      .integer()
      .min(1), */

    codeInit: Yup.string()
      .matches(patt, {
        excludeEmptyString: true,
        message: 'Formato inválido ej: A-3-08A-1',
      })
      .required('Este campo es obligatorio'),

    codeEnd: Yup.string()
      .matches(patt, {
        excludeEmptyString: true,
        message: 'Formato inválido ej: A-3-08A-1',
      })
      .required('Este campo es obligatorio'),

    labelInit: Yup.string()
      .matches(patt, {
        excludeEmptyString: true,
        message: 'Formato inválido ej: A-3-08A-1',
      })
      .required('Este campo es obligatorio'),

    labelEnd: Yup.string()
      .matches(patt, {
        excludeEmptyString: true,
        message: 'Formato inválido ej: A-3-08A-1',
      })
      .required('Este campo es obligatorio'),
  })

  const resSer = useForm({ resolver: yupResolver(validationSerie) })

  /* FUNCTIONS */
  const handleSerie = () => {
    refModalSer.current.click()
  }

  const handleIndividual = () => {
    //console.log('handle individual')
    resInd?.setValue('warehouseId', '')
    resInd?.setValue('code', '')
    resInd?.setValue('label', '')
    document.querySelector(
      '#form-rack-individual input[name="isUpdateMode"]'
    ).value = 0
    refModalInd.current.click()
  }

  const handleEdit = async (e, row) => {
    try {
      refModalInd.current.click()

      const res = await getOneRack({
        variables: {
          getRackInfoId: row?.id,
        },
      })

      if (res?.data?.getRackInfo) {
        document.querySelector(
          '#form-rack-individual input[name="isUpdateMode"]'
        ).value = row.id
        resInd?.setValue('warehouseId', res?.data?.getRackInfo?.warehouse_id)
        resInd?.setValue('code', res?.data?.getRackInfo?.code)
        resInd?.setValue('label', res?.data?.getRackInfo?.name)
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleDel = (e, row) => {
    //console.log('Del => ', row)

    MySwal.fire({
      showCloseButton: true,
      icon: 'warning',
      title: '¿Eliminar registro?',
      html: (
        <>
          <div className="message-container">
            Esta acción no se podra revertir
          </div>
        </>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteRack({
            variables: {
              deleteRackId: row.id,
            },
          })
          await InventoryActivitiesRacksRefetch()

          return toastSweetAlert({
            mode: 'ok',
            message: 'Marbete eliminado',
          })
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
    })
  }

  const saveIndividual = async (data) => {
    try {
      const mode = parseInt(
        document.querySelector(
          '#form-rack-individual input[name="isUpdateMode"]'
        ).value
      )
      let msg = 'Marbete creado correctamente!'

      if (mode === 0) {
        await createRack({
          variables: {
            rack: {
              name: data?.label,
              code: data?.code,
              circuit: null,
              capacity: 10000,
              reorder: 60,
              warehouse_id: pagePagination.warehouseId,
              zone_id: 1,
            },
          },
        })
      } else {
        await updateRack({
          variables: {
            updateRackId: mode,
            rack: {
              name: data?.label,
              code: data?.code,
              warehouse_id: data?.warehouseId,
              circuit: null,
              capacity: 10000,
              reorder: 60,
              zone_id: 1,
            },
          },
        })

        msg = 'Marbete actualizado correctamente!'
      }

      resInd?.setValue('warehouseId', '')
      resInd?.setValue('code', '')
      resInd?.setValue('label', '')

      refModalInd.current.click()
      await InventoryActivitiesRacksRefetch()
      return toastSweetAlert({
        mode: 'ok',
        message: msg,
      })
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const saveSerie = async (data) => {
    try {
      if (validateSerie(data)) {
        await createRackBySerie({
          variables: {
            warehouseId: pagePagination.warehouseId,
            codeInit: data?.codeInit,
            codeEnd: data?.codeEnd,
            labelInit: data?.labelInit,
            labelEnd: data?.labelEnd,
          },
        })

        refModalSer.current.click()
        await InventoryActivitiesRacksRefetch()

        return toastSweetAlert({
          mode: 'ok',
          message: 'Ubicaciones en serie creados correctamente',
        })
      } else {
        return toastSweetAlert({
          mode: 'error',
          message: `Los códigos de inicio: ${data?.codeInit} y término: ${data?.codeEnd} son inválidos`,
        })
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const validateSerie = (data) => {
    try {
      const codeInit = data?.codeInit
      const arrInit = codeInit.split('-')

      const codeEnd = data?.codeEnd
      const arrEnd = codeEnd.split('-')

      const init = parseInt(arrInit[2].replace(/[A-Z]/i, ''))
      const end = parseInt(arrEnd[2].replace(/[A-Z]/i, ''))

      return init < end
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  /* validateSerie({
    codeInit: 'A-1-01A-1',
    codeEnd: 'A-1-20A-1',
  }) */

  return (
    <>
      <LayoutTable
        title="Control de Ubicaciones"
        loadingData={InventoryActivitiesRacksLoading}
        totalCount={total}
        data={dataToTable}
        hideAddNew
        hideDetails
        hideBtnEdit
        hideBtnErase
        exportImportButton={showExportImport}
        paginationPerPage={30}
        importExportBtnText={'Agregar marbete'}
        importBtnText={'Carga CSV'}
        importOptions={{
          confirmation: true,
          confirmationText: '¿Agregar o sustituir todo?',
          confirmationData: [
            {
              value: 1,
              text: 'Agregar información',
            },
            {
              value: 2,
              text: 'Sustituir todo',
            },
          ],
          variable: {
            name: 'importMode',
            type: 'int',
          },
        }}
        importQuery={IMPORT_INVENTORY_ACTIVITIES_LABEL}
        refetchFunctionQueries={async () => {
          await InventoryActivitiesRacksRefetch()
        }}
        importCustomVariables={[
          {
            warehouseId: pagePagination.warehouseId,
          },
        ]}
        exportQuery={EXPORT_INVENTORY_ACTIVITIES_LABEL}
        exportBtnText={'Exportar ubicaciones'}
        exportQueryCustom={exportRacks}
        templateImportBtnText={'Plantilla importación'}
        templateDataImport={[
          {
            code: 'Obligatorio',
            name: 'Obligatorio',
            warehouse_id: 'Opcional',
            zone_id: 'Opcional',
            circuit: 'Opcional',
            reorder: 'Opcional',
            capacity: 'Opcional',
          },
        ]}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        maximumFilesImport={1}
        importMessageValidation=".csv"
        customFilterButtonInput={
          showExportImport && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
              <InputController
                type="text"
                name="warehouse"
                inputType="choosen"
                options={listOfWarehousesOptions}
                control={control}
                changeAction={async (e) => {
                  setPagePagination({
                    ...pagePagination,
                    warehouseId: parseInt(e.value),
                  })
                  // const isStore = await warehouseIsStore({
                  //   variables: {
                  //     warehouseId: parseInt(e.value),
                  //   },
                  // })
                  // setShowExportImport(isStore?.data?.warehouseIsStore)
                }}
              />
            </div>
          )
        }
        extraButtonsImportExport={
          <>
            <span
              className="dropdown-item item-click"
              onClick={() => {
                handleIndividual()
              }}
            >
              Inividual
            </span>
            <span
              className="dropdown-item item-click"
              onClick={() => {
                handleSerie()
              }}
            >
              En serie
            </span>
            <div className="dropdown-divider"></div>
          </>
        }
        componentAction={(row) => {
          return (
            <>
              <span
                className="btn btn-accept"
                onClick={(e) => {
                  handleEdit(e, row)
                }}
              >
                Editar
              </span>
              <span
                className="btn btn-danger"
                onClick={(e) => {
                  handleDel(e, row)
                }}
              >
                <i className="fa fa-trash"></i>
              </span>
            </>
          )
        }}
      />

      <>
        <span
          ref={refModalInd}
          className="btn btn-accept btn-sm invisible"
          data-toggle="modal"
          data-target="#modal-individual"
          data-backdrop="static"
          data-keyboard="false"
          data-dismiss="modal"
        ></span>
        <ModalContent
          idModal="modal-individual"
          title="Agregar marbete individual"
          content={
            <form
              onSubmit={resInd.handleSubmit(saveIndividual)}
              id="form-rack-individual"
            >
              <input type="hidden" name="isUpdateMode" value="0" />

              {/* <div className="row mb-3">
                <div className="col-12">
                  <InputController
                    label="ALMACÉN *"
                    inputType="choosen"
                    name="warehouseId"
                    options={warehouseSelect}
                    control={resInd.control}
                  />
                </div>
              </div> */}

              <input
                type="hidden"
                name="warehouseId"
                value={pagePagination.warehouseId}
              />

              <div className="row mb-3">
                <div className="col-12">
                  <InputController
                    label="CODIGO *"
                    name="code"
                    placeholder="A-1-02A-1"
                    control={resInd.control}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <InputController
                    label="ETIQUETA *"
                    name="label"
                    placeholder="A-1-02A-1"
                    control={resInd.control}
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-12 text-right">
                  <span
                    className="btn btn-border-1 mr-1"
                    onClick={() => {
                      refModalInd.current.click()
                    }}
                  >
                    Cancelar
                  </span>
                  <button type="submit" className="btn btn-accept">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          }
        />
      </>

      <>
        <span
          ref={refModalSer}
          className="btn btn-accept btn-sm invisible"
          data-toggle="modal"
          data-target="#modal-serie"
          data-backdrop="static"
          data-keyboard="false"
          data-dismiss="modal"
        ></span>

        <ModalContent
          idModal="modal-serie"
          title="Agregar marbete en serie"
          content={
            <form onSubmit={resSer.handleSubmit(saveSerie)}>
              {/* <div className="row mb-3">
                <div className="col-12">
                  <InputController
                    label="ALMACÉN"
                    inputType="choosen"
                    name="warehouseId"
                    options={warehouseSelect}
                    control={resSer.control}
                  />
                </div>
              </div> */}
              <input
                type="hidden"
                name="warehouseId"
                value={pagePagination.warehouseId}
              />

              <div className="row mb-3">
                <div className="col-6">
                  <InputController
                    label="CODIGO DE INICIO"
                    name="codeInit"
                    control={resSer.control}
                    placeholder="A-1-02A-1"
                  />
                </div>

                <div className="col-6">
                  <InputController
                    label="CODIGO DE TERMINO"
                    name="codeEnd"
                    control={resSer.control}
                    placeholder="A-1-02A-1"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <InputController
                    label="ETIQUETA DE INICIO"
                    name="labelInit"
                    control={resSer.control}
                    placeholder="A-1-02A-1"
                  />
                </div>

                <div className="col-6">
                  <InputController
                    label="ETIQUETA DE TERMINO"
                    name="labelEnd"
                    control={resSer.control}
                    placeholder="A-1-02A-1"
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-12 text-right">
                  <span
                    className="btn btn-border-1 mr-1"
                    onClick={() => {
                      refModalSer.current.click()
                    }}
                  >
                    Cancelar
                  </span>
                  <button type="submit" className="btn btn-accept">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          }
        />
      </>
    </>
  )
}

export default InventoryActivitiesLabels
