import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ContentHeader from '../../../../Layout/ContentHeader'
import { GET_ORDER_BY_ID_STATUS } from '../../../../../graphql/Catalog/Orders/pendingOrders'
import { useQuery } from '@apollo/client'
import OrderGeneral from '../../../../OrderGeneral'
import OrderDetailStore from '../../../../OrderDetailStore'
import OrderShippingDetails from '../../../../OrderShippingDetails'
import OrderProducts from '../../../../OrderProducts'
import Timeline from '../../../../Global/Timeline/Timeline'
import InternalNotes from '../../../../Global/InternalNotes/InternalNotes'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import moment from 'moment'
import 'moment/locale/es'
import OrderButtons from '../../../../OrderButtons'

export const ShippedOrdersNew = () => {
  const { id: _id, show } = useParams()
  const [storeId, setStoreId] = useState('')
  const [storeFromDB, setStoreFromDB] = useState(false)
  const [idOrder, setIdOrder] = useState('')
  const [dataOrder, setDataOrder] = useState([])
  const history = useHistory()

  const { data, loading, error } = useQuery(GET_ORDER_BY_ID_STATUS, {
    variables: {
      id: parseInt(_id),
      statusId: [11],
      typeId: null,
    },
  })

  useEffect(() => {
    if (!loading) {
      if (error)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/dashboard')
        )

      const orderData = data.getOrderByIdAndStatus
      if (orderData.store?.name || orderData.warehouse?.name)
        setStoreFromDB(true)
      setStoreId(orderData.store_id)
      setIdOrder(orderData.order_id)
      setDataOrder(orderData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  const handleStoreChange = (e) => {
    setStoreId(e.value)
  }

  moment.locale('es') //Change dates languages to spanish

  return (
    <>
      <ContentHeader
        title="Pedido entregado"
        breadcrumb="Entregado"
        windowTitle={`${_id ? 'Editar' : 'Agregar'} entrega  `}
      />
      <div className="row">
        <div className="col-8">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles</h3>
                </div>
                <div className="card-body col-12">
                  <OrderGeneral
                    orderDate={moment(dataOrder?.order_date).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                    orderId={dataOrder?.order_id}
                    paymentId={dataOrder?.payment?.payment_id}
                    platform={dataOrder?.platform?.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de pago</h3>
                </div>
                <div className="card-body">
                  <OrderDetailStore
                    orderType={dataOrder?.type?.name}
                    orderStore={
                      dataOrder?.store?.name || dataOrder?.warehouse?.name
                    }
                    orderPaymentPlatform={dataOrder?.payment?.platform}
                    orderMethod={dataOrder?.method?.name}
                    withStore={storeFromDB}
                    totalPrice={dataOrder?.total_price}
                    shippingPrice={dataOrder?.shipping_price}
                    handleStoreChange={handleStoreChange}
                    id_store={storeId}
                    status={dataOrder?.status?.id}
                    shippingReceipt={dataOrder?.shipping?.receipt?.url}
                    shippingCompanyName={
                      dataOrder?.shippingCompany?.name
                        ? dataOrder?.shippingCompany?.name
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Desglose de productos</h3>
                </div>
                <div className="card-body">
                  <OrderProducts
                    productsArray={dataOrder?.products}
                    totalQuantity={dataOrder?.product_quantity}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de envío</h3>
                </div>
                <div className="card-body">
                  <OrderShippingDetails
                    city={dataOrder?.shipping?.city}
                    address={dataOrder?.shipping?.address_1}
                    postcode={dataOrder?.shipping?.postcode}
                    email={dataOrder?.shipping?.email}
                    phone={dataOrder?.shipping?.phone}
                    firstName={dataOrder?.shipping?.first_name}
                    lastName={dataOrder?.shipping?.last_name}
                    state={dataOrder?.shipping?.state}
                  />
                </div>
              </div>
            </div>

            {show && (
              <OrderButtons
                oneButton={true}
                onProcess={() => history.goBack()}
                processBtn="Volver"
                firstButtonGray={true}
              />
            )}
          </div>
        </div>
        {/* TIMELINE & INTERNAL NOTES */}
        <div className="col-4">
          <Timeline idOrder={idOrder} />
          <InternalNotes idOrder={idOrder} />
        </div>
      </div>
    </>
  )
}

export default ShippedOrdersNew
