import { useHistory, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'
import {
  CREATE_ITEM,
  GET_ALL_ITEMS,
  GET_ITEM_BY_ID,
  UPDATE_ITEM,
} from '../../../../graphql/Catalog/Stowing/items'
import { GET_SAP_ITEMS } from '../../../../graphql/Catalog/SAP/sapItems'
import { GET_ALL_UNUSED_RACKS } from '../../../../graphql/Catalog/Stowing/racks'
import Loader from '../../../Global/Spinner'

export const ProductsNew = () => {
  const { id: _id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [itemOptions, setItemOptions] = useState([])
  const [rackOptions, setRackOptions] = useState([])

  const {
    data: dataItem,
    loading: loadingItem,
    error: errorItem,
  } = useQuery(GET_ITEM_BY_ID, {
    variables: {
      getOneItemByIdId: parseInt(_id),
    },
  })

  const {
    data: sapItemsData,
    loading: sapItemsLoading,
    error: sapItemsError,
  } = useQuery(GET_SAP_ITEMS, {
    variables: {
      limit: null,
      offset: null,
    },
  })

  const {
    data: rackData,
    loading: rackLoading,
    error: rackError,
  } = useQuery(GET_ALL_UNUSED_RACKS)

  const [createItem] = useMutation(CREATE_ITEM, {
    refetchQueries: [
      {
        query: GET_ALL_ITEMS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateItem] = useMutation(UPDATE_ITEM, {
    refetchQueries: [
      {
        query: GET_ALL_ITEMS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_ITEM_BY_ID,
        variables: { getOneItemByIdId: parseInt(_id) },
      },
    ],
  })

  const validationSchema = Yup.object().shape({
    rack_id: Yup.string().required('Este campo es obligatorio'),
    item_code: Yup.string().required('Este campo es obligatorio'),
    on_hand: Yup.number().typeError('Debe ser un numero').integer().required(),
    static: Yup.boolean(),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) })

  useEffect(() => {
    if (_id) {
      setLoading(true)
      if (!loadingItem) {
        if (errorItem)
          return toastSweetAlert({
            mode: 'error',
            message: errorItem.message,
          })
        if (dataItem) {
          setValue('rack_id', dataItem.getOneItemById.rack_id)
          setValue('item_code', dataItem.getOneItemById.item_code)
          setValue('on_hand', dataItem.getOneItemById.on_hand)
          setValue('static', dataItem.getOneItemById.static)
        }
        return setLoading(false)
      }
    } else {
      setValue('static', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataItem, loadingItem])

  useEffect(() => {
    setLoading(true)
    if (!sapItemsLoading && !sapItemsError) {
      const list = sapItemsData.getSapItems.map((item) => {
        return {
          value: item.item_code,
          label: item.item_name,
        }
      })
      setItemOptions(list)
      setLoading(false)
    }
  }, [sapItemsData, sapItemsLoading])

  useEffect(() => {
    if (!rackLoading && !rackError) {
      const list = rackData.getAllUnusedRacks.map((rack) => {
        setLoading(true)
        return {
          value: rack.id,
          label: rack.name,
        }
      })
      setRackOptions(list)
      setLoading(false)
    }
  }, [rackData, rackLoading])

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      if (_id) {
        const { data: updateItemData } = await updateItem({
          variables: {
            updateItemId: parseInt(_id),
            item: {
              item_code: data.item_code,
              rack_id: parseInt(data.rack_id),
              on_hand: parseInt(data.on_hand),
              static: data.static,
            },
          },
        })
        if (updateItemData.updateItem) {
          return (
            toastSweetAlert({
              mode: 'ok',
              message: 'Producto actualizado correctamente',
            }),
            history.push(`/stowing/reorder`)
          )
        }
      } else {
        const { data: createItemData } = await createItem({
          variables: {
            item: {
              item_code: data.item_code,
              rack_id: parseInt(data.rack_id),
              on_hand: parseInt(data.on_hand),
              static: data.static,
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_ITEMS,
              variables: {
                limit: 10,
                offset: 0,
                searchQuery: null,
              },
            },
            {
              query: GET_ALL_UNUSED_RACKS,
            },
          ],
        })
        if (createItemData.createItem) {
          return (
            toastSweetAlert({
              mode: 'ok',
              message: 'Producto creado correctamente',
            }),
            history.push(`/stowing/reorder`)
          )
        }
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        title="Control de productos"
        breadcrumb="Reorden"
        windowTitle={`${_id ? 'Editar' : 'Agregar'} producto `}
      />
      <FormProvider {...methods}>
        <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`${_id ? 'Editar' : 'Agregar'} producto`}
            btnRedPath="/stowing/reorder"
            btnRedTxt="Cancelar"
            btnSubmit={true}
            btnState={loading}
            errors={errors}
            content={
              loading ? (
                <div className="row justify-content-center align-items-center h-100">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="mb-3 col-md-12 col-lg-3">
                      {!_id ? (
                        <InputController
                          label="Rack"
                          type="number"
                          inputType="choosen"
                          name="rack_id"
                          control={control}
                          options={rackOptions}
                        />
                      ) : (
                        <div>
                          <label>
                            <b>Rack</b>
                          </label>
                          <p>
                            {dataItem &&
                              dataItem.getOneItemById.RackPosition.name}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-12 col-lg-3">
                      <InputController
                        label="Producto"
                        type="text"
                        inputType="choosen"
                        name="item_code"
                        control={control}
                        options={itemOptions}
                      />
                    </div>
                    <div className="mb-3 col-md-12 col-lg-3">
                      <InputController
                        label="Cantidad"
                        type="number"
                        name="on_hand"
                        placeholder="Ingrese cantidad"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-md-12 col-lg-3">
                      <InputController
                        label="Mantener posición"
                        inputType="checkbox"
                        name="static"
                        de
                        control={control}
                      />
                    </div>
                  </div>
                </>
              )
            }
          />
        </form>
      </FormProvider>
    </>
  )
}
export default ProductsNew
