import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import ContentHeader from '../../../Layout/ContentHeader'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import Swal from 'sweetalert2'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_SCHEDULE,
  GET_SCHEDULE_ONLY_PAGINATION,
} from '../../../../graphql/Documents/Schedule/schedule'
import { document_statuses_esp } from '../../../Helpers/StatusesLanguages'
import { FullCalendarController } from '../../../Global/FullCalendar/FullCalendar'
import { CANCELL_SCHEDULE } from '../../../../graphql/Documents/Schedule/schedule'
import withReactContent from 'sweetalert2-react-content'
import LayoutTable from '../../../Global/LayoutTable'
import ProvidersScheduleDetails from './ProvidersScheduleDetails'
import { AuthContext } from '../../../../Auth/AuthContext'
import Loader from '../../../Global/Spinner'

const ProvidersSchedule = () => {
  const [pagePagination, setPagePagination] = useState({
    limit: 10,
    offset: 0,
    searchQuery: null,
  })
  const history = useHistory()
  const { user } = useContext(AuthContext)

  //GET SCHEDULE
  const { data, loading, error } = useQuery(GET_SCHEDULE)
  const [total, setTotal] = useState(0)

  //GET SCHEDULES ONLY CANCELED AND CLOSED
  const {
    data: dataSchedulesTable,
    loading: loadingSchedulesTable,
    error: errorSchedulesTable,
  } = useQuery(GET_SCHEDULE_ONLY_PAGINATION, {
    variables: {
      type: [2, 3],
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      searchQuery: pagePagination.searchQuery,
    },
  })

  const [cancellSchedule] = useMutation(CANCELL_SCHEDULE)
  const [step, setStep] = useState(1)
  const [clickOrders, setClickOrders] = useState(null)

  const MySwal = withReactContent(Swal)

  const [permissions, setPermissions] = useState({
    import: false,
    create: false,
    delete: false,
    edit: false,
  })
  const [schedulesTable, setSchedulesTable] = useState([])
  const [schedulesTableModal, setSchedulesTableModal] = useState([])
  const [fullCalendar, setFullCalendar] = useState({
    id: null,
    title: null,
    date: null,
  })

  useEffect(() => {
    if (!loadingSchedulesTable) {
      if (errorSchedulesTable) {
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/schedule/providers')
        )
      }

      const { rows, count } = dataSchedulesTable.getScheduleOnlyPagination
      let list = []
      let listModal = []
      for (const item of rows) {
        const { ScheduleScheduleOrdersLines, ScheduleScheduleDocks } = item
        let docEntriesTxt = ''
        const docEntries = ScheduleScheduleOrdersLines.map((order, index) => {
          docEntriesTxt += `${order.ScheduleOrdersLinesSapPurchasesOrders.document_entry}`
          if (ScheduleScheduleOrdersLines.length - 1 > index)
            docEntriesTxt += ', '
          return order.ScheduleOrdersLinesSapPurchasesOrders.document_entry
        })

        //ITERATE DOCUMENT SCHEDULE
        list.push({
          id: item.id,
          'Hora y fecha':
            moment(item.document_date + 'T' + item.document_time_start).format(
              'L hh:mm a'
            ) +
            ' - ' +
            moment(item.document_date + 'T' + item.document_time_end).format(
              'hh:mm a'
            ),
          Almacén: item.warehouse.warehouse_name
            ? item.warehouse.warehouse_name
            : '',
          proveedor:
            'Proveedor: ' +
            ScheduleScheduleOrdersLines[0]
              ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
              ? ScheduleScheduleOrdersLines[0]
                  ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
              : item.comments
              ? item.comments
              : 'CITA',
          estado: item.ScheduleDocumentStatus?.id
            ? document_statuses_esp[item.ScheduleDocumentStatus.id]
            : '',
          documents_omitField: docEntries,
          document_txt_omitField: docEntriesTxt,
          Documentos:
            docEntries.length > 2
              ? `${docEntries[0]}, ${docEntries[1]}....  ${
                  docEntries.length - 2
                } más`
              : `${docEntriesTxt}`,
        })

        listModal.push({
          id: item.id,
          title:
            'Proveedor: ' +
            ScheduleScheduleOrdersLines[0]
              ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
              ? ScheduleScheduleOrdersLines[0]
                  ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
              : item.comments
              ? item.comments
              : 'CITA',
          date: item.document_date,
          start: item.document_date + 'T' + item.document_time_start,
          end: item.document_date + 'T' + item.document_time_end,
          provider: {
            card_code:
              ScheduleScheduleOrdersLines[0]
                ?.ScheduleOrdersLinesSapBusinessPartner?.card_name,
            card_name:
              ScheduleScheduleOrdersLines[0]
                ?.ScheduleOrdersLinesSapBusinessPartner?.card_name &&
              ScheduleScheduleOrdersLines[0]?.ScheduleOrdersLinesSapBusinessPartner?.card_name.toLowerCase(),
          },
          status_id: item.document_status_id ? item.document_status_id : 1,
          warehouse_name:
            item.warehouse.warehouse_name &&
            item.warehouse.warehouse_name.toLowerCase(),
          comments: item.comments,
          docks: ScheduleScheduleDocks ? ScheduleScheduleDocks : [],
          reason: item.cancel_reason,
          documents_omitField: docEntries,
          document_txt_omitField: docEntriesTxt,
          Documentos:
            docEntries.length > 2
              ? `${docEntries[0]}, ${docEntries[1]}....  ${
                  docEntries.length - 2
                } más`
              : `${docEntriesTxt}`,
        })
      }
      setSchedulesTable(list) //For table
      setSchedulesTableModal(listModal) //For Modal
      setTotal(count)
    }
  }, [dataSchedulesTable, loadingSchedulesTable, errorSchedulesTable])

  useEffect(() => {
    if (!loading && !error) {
      /* ITERATE DATA FOR FULLCALENDAR */
      async function FillFullCalendar() {
        try {
          let list = []
          //ITERATE SCHEDULES
          for (const item of data.getSchedule) {
            const { ScheduleScheduleOrdersLines, ScheduleScheduleDocks } = item
            let docEntriesTxt = ''
            const docEntries = ScheduleScheduleOrdersLines.map(
              (order, index) => {
                docEntriesTxt += `${order.ScheduleOrdersLinesSapPurchasesOrders.document_entry}`
                if (ScheduleScheduleOrdersLines.length - 1 > index)
                  docEntriesTxt += ', '
                return order.ScheduleOrdersLinesSapPurchasesOrders
                  .document_entry
              }
            )
            //ITERATE DOCUMENT SCHEDULE
            list.push({
              id: item.id,
              title:
                'Proveedor: ' +
                ScheduleScheduleOrdersLines[0]
                  ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
                  ? ScheduleScheduleOrdersLines[0]
                      ?.ScheduleOrdersLinesSapBusinessPartner?.card_name
                  : item.comments
                  ? item.comments
                  : 'CITA',
              date: item.document_date,
              start: item.document_date + 'T' + item.document_time_start,
              end: item.document_date + 'T' + item.document_time_end,
              provider: {
                card_code:
                  ScheduleScheduleOrdersLines[0]
                    ?.ScheduleOrdersLinesSapBusinessPartner?.card_code,
                card_name:
                  ScheduleScheduleOrdersLines[0]
                    ?.ScheduleOrdersLinesSapBusinessPartner?.card_name &&
                  ScheduleScheduleOrdersLines?.ScheduleOrdersLinesSapBusinessPartner?.card_name.toLowerCase(),
              },
              status_id: item.document_status_id ? item.document_status_id : 1,
              warehouse_name:
                item.warehouse.warehouse_name &&
                item.warehouse.warehouse_name.toLowerCase(),
              comments: item.comments,
              docks: ScheduleScheduleDocks ? ScheduleScheduleDocks : [],
              documents: docEntries,
              document_txt_omitField: docEntriesTxt,
              documents_txt:
                docEntries.length > 2
                  ? `${docEntries[0]}, ${docEntries[1]}....  ${
                      docEntries.length - 2
                    } más`
                  : `${docEntriesTxt}`,
            })
          }
          setFullCalendar(list)
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
      FillFullCalendar()
    }
  }, [data, loading, error])

  //SET PERMISSION IMPORT
  useEffect(() => {
    const permissions = user.userPermissions.find(
      (permission) => permission.module_info.name === 'ProvidersSchedule'
    )

    setPermissions({
      ...permissions,
      create: permissions?.access_retrieve,
      import: permissions?.access_import,
      delete: permissions?.access_delete,
      edit: permissions?.access_edit,
    })
  }, [user])

  const handleClick = (arg, from) => {
    let idVar = null
    if (arg.event) idVar = arg.event.id
    else idVar = arg

    //Find schedule by Id
    let findData
    if (arg.event)
      findData = fullCalendar.find((item) => item.id === parseInt(idVar))
    else
      findData = schedulesTableModal.find((item) => item.id === parseInt(idVar))
    let docksEsp = {
      1: 'Puerta 1',
      2: 'Puerta 2',
      3: 'Puerta 3',
      4: 'Puerta 4',
    }
    return MySwal.fire({
      showCloseButton: true,
      showDenyButton: from === 'from_closed' ? false : permissions.edit,
      showCancelButton: from === 'from_closed' ? false : permissions.delete,
      title:
        from === 'from_closed'
          ? findData.reason
            ? 'Detalles cita cancelada'
            : 'Detalles cita cerrada'
          : 'Detalles de cita',
      html: (
        <div className="">
          <div id="quotesModalBox">
            {findData.date && findData.start && findData.end && (
              <h4>
                <i className="fas fa-clock"></i>{' '}
                {moment(findData.start).format('LL hh:mm a')} -{' '}
                {moment(findData.end).format('hh:mm a')}
              </h4>
            )}
            {findData.reason && (
              <>
                <h4>
                  <i className="fas fa-times"></i> Cancelada
                </h4>
                <h5>
                  <span
                    className="quoteAttribute"
                    style={{ fontSize: 'bold', color: '#bd2c35' }}
                  >
                    Motivo:{' '}
                  </span>
                  <span
                    style={{
                      display: 'block',
                      paddingTop: '5px',
                      fontSize: 'bold',
                      color: '#bd2c35',
                    }}
                  >
                    {findData.reason}
                  </span>
                </h5>
              </>
            )}
            {findData.provider.card_code && (
              <h5>
                <span className="quoteAttribute">Código de proveedor: </span>
                <span className="quoteAttributeRes" style={{ float: 'right' }}>
                  {findData.provider.card_code}
                </span>
              </h5>
            )}
            {findData.provider.card_name && (
              <h5 style={{ textTransform: 'capitalize' }}>
                <span className="quoteAttribute">Proveedor: </span>
                <span className="quoteAttributeRes">
                  {findData.provider.card_name}
                </span>
              </h5>
            )}
            {findData.status_id && (
              <h5>
                <span className="quoteAttribute">Estado de cita: </span>{' '}
                <span className="quoteAttributeRes">
                  {document_statuses_esp[findData.status_id]}
                </span>
              </h5>
            )}
            {findData.warehouse_name && (
              <h5>
                <span className="quoteAttribute">Almacén: </span>
                <span className="quoteAttributeRes">
                  {findData.warehouse_name}
                </span>
              </h5>
            )}
            {findData.docks.length > 0 && (
              <h5>
                <span className="quoteAttribute">Puertas: </span>
                <span className="docksBox">
                  {findData.docks.map((item) => (
                    <span className="docksItem">{docksEsp[item.dock.id]}</span>
                  ))}
                </span>
              </h5>
            )}
            {findData.comments && (
              <h5>
                <span className="quoteAttribute">Observaciones: </span>
                <span style={{ display: 'block', paddingTop: '5px' }}>
                  {findData.comments}
                </span>
              </h5>
            )}
            {findData.document_txt_omitField && (
              <h5>
                <span className="quoteAttribute">Documentos: </span>
                <span style={{ display: 'block', paddingTop: '5px' }}>
                  {findData.document_txt_omitField}
                </span>
              </h5>
            )}
          </div>
        </div>
      ),
      confirmButtonText: 'Ver más detalles',
      denyButtonText: 'Editar cita',
      cancelButtonText: 'Cancelar cita',
      focusConfirm: false,
      buttonsStyling: false,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalConfirmButtonLogOut',
        cancelButton: 'StandarModalConfirmButtonLogOut',
      },
    }).then((result) => {
      //DETAILS CLICKED
      if (result.isConfirmed)
        return handleClickOrders(parseInt(idVar), false, findData)
      if (result.isDenied) return handleClickOrders(parseInt(idVar), true)
      if (result.dismiss === 'cancel') return modalCancelReason(idVar)
    })
  }

  const modalCancelReason = (id) => {
    MySwal.fire({
      showCloseButton: true,
      showDenyButton: true,
      allowOutsideClick: false,
      buttonsStyling: false,
      reverseButtons: true,
      title: 'Motivo de cancelación',
      html: (
        <div className="message-container-input">
          Describe el motivo de cancelación
        </div>
      ),
      input: 'text',
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterRejectOrder',
      },
      confirmButtonText: 'Cancelar cita',
      denyButtonText: 'Volver',
      inputValidator: (value) => {
        if (!value) return 'Debe escribir el motivo de la cancelación'
      },
      // denyButtonText: '',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const reason = result.value
        /* INSERT AND UPDATE DATA */
        try {
          await cancellSchedule({
            variables: {
              scheduleId: parseInt(id),
              reason: reason,
              isRecieving: null,
            },
            refetchQueries: [
              {
                query: GET_SCHEDULE_ONLY_PAGINATION,
                variables: {
                  type: [2, 3],
                  limit: pagePagination.limit,
                  offset: pagePagination.offset,
                },
              },
              { query: GET_SCHEDULE },
            ],
          })
          return toastSweetAlert({
            mode: 'ok',
            message: 'Cita cancelada',
          })
          // ,history.goBack()
        } catch (e) {
          return toastSweetAlert({
            mode: 'error',
            message: e.message,
          })
        }
      }
    })
  }

  const handleClickOrders = async (idVar, edit, findData) => {
    if (idVar) {
      setClickOrders({ idVar, findData })
      setStep(2)
      if (edit) history.push(`/schedule/providers/edit/${idVar}`)
    }
  }

  return (
    <>
      {step === 1 && (
        <ContentHeader
          title="Citas proveedores de Tlajomulco"
          breadcrumb="Citas Proveedores de Tlajomulco"
          windowTitle="Citas"
        />
      )}
      {!loading ? (
        <>
          {step === 1 ? (
            <>
              <div className="card layout-configs">
                <div className="card-header">
                  <h3 className="card-title header-title">Agenda</h3>
                  <div className="control-btn-box">
                    <div
                      className="btn-group actions-btn"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      {permissions.import && (
                        <Link
                          to={`/schedule/providers/import/form`}
                          className="btn btn-accept btn-sm"
                        >
                          <i className="fa fa-file-import"></i> Importar &nbsp;
                        </Link>
                      )}
                      {permissions.create && (
                        <Link
                          to={`/schedule/providers/new`}
                          className="btn btn-accept btn-sm"
                        >
                          <i className="fa fa-plus"></i> Agregar nueva cita
                          &nbsp;
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body FullCalendar">
                  <FullCalendarController
                    eventsList={fullCalendar}
                    handleEvent={handleClick}
                  />
                </div>
              </div>
              {(user.role === 9 || user.role === 1) && (
                <LayoutTable
                  data={schedulesTable}
                  //   title="Historial de citas"
                  //   handleButton={handleClick}
                  //   extraArgHandleButton={'from_closed'}
                  //   handleButtonTitle="Detalles"
                  //   totalCount={total}
                  //   loadingData={loadingSchedulesTable}
                  //   pagePagination={pagePagination}
                  //   setPagePagination={setPagePagination}
                  //   // hideFilterBar
                  //   FilterBarPlaceholder="Buscar por Almacén"
                  //   titleActions=""
                  headersButtons={false}
                />
              )}
            </>
          ) : (
            <>
              {clickOrders && (
                <ProvidersScheduleDetails
                  scheduleData={clickOrders}
                  permissions={permissions}
                />
              )}
            </>
          )}
        </>
      ) : (
        <div className="text-center">
          <Loader />
        </div>
      )}
    </>
  )
}

export default ProvidersSchedule
