
const PercentageBar =({
    percentage = 0,
    description = "",
    displayInBlockBarBox = false,
    smallBar = false,
})=>{

    return (
        <div className="percentageBarMain row">
            <div className={`col-12 barBox ${displayInBlockBarBox && 'displayInBlockBarBox'}`}>
                {/* <div className={`${smallBar ? 'smallBar' : 'bar'}`}>
                    <div className="completed" style={{width:`${ Math.floor(percentage * 100) / 100 }%`}}>&nbsp;</div>
                    <div className="noCompleted" style={{width:`${100 - percentage }%`}}>&nbsp;</div>
                </div>
                <div className={`${smallBar ? 'smallCounter': 'counter'}`}>{`${Math.floor(percentage * 100) / 100}%`}</div> */}
                <div className={`${smallBar ? 'smallBar' : 'bar'}`}>
                    <div className="completed" style={{width:`${ (Math.trunc(percentage*100)/100).toFixed(2) }%`}}>&nbsp;</div>
                    <div className="noCompleted" style={{width:`${100 - percentage }%`}}>&nbsp;</div>
                </div>
                <div className={`${smallBar ? 'smallCounter': 'counter'}`}>{`${(Math.trunc(percentage*100)/100).toFixed(2)}%`}</div>
            </div>
            {description && <div className="col-12 mt-3">{description}</div>}
        </div>
    )
}

export default PercentageBar