import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_STORE_INVENTORY } from '../../graphql/Catalog/PositionOrders/inventoryReorder'
import LayoutTable from '../Global/LayoutTable'
import Loader from '../Global/Spinner'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import ContentHeader from '../Layout/ContentHeader'
// import Loader from '../Layout/ContentHeader'

const Inventory = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [total, setTotal] = useState(0)
  const [dataToTable, setDataToTable] = useState([])
  const { loading, data } = useQuery(GET_STORE_INVENTORY, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    try {
      if (!loading) {
        const list = data?.getStoreInventory?.rows.map((inventory) => {
          return {
            id: inventory?.id,
            SKU: inventory?.item_code ? inventory.item_code : '--',
            Nombre: inventory?.SapItem?.item_name
              ? inventory.SapItem.item_name
              : '--',

            Cantidad: inventory?.on_hand ? inventory.on_hand : '--',
          }
        })
        setDataToTable(list)
        setTotal(data?.getStoreInventory?.count)
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [data, total, loading, pagePagination])

  return (
    <>
      {/* <ContentHeader
        title="Inventario de Tienda"
        breadcrumb="Inventario de Tienda"
        windowTitle="Inventario de Tienda"
      /> */}

      <LayoutTable
        data={dataToTable}
        title="Inventario de Tienda"
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        hideId
        hideActions
        exportImportButton={false}
        hideAddNew
        hideDetails
        FilterBarPlaceholder="Buscar por SKU"
      />
    </>
  )
}

export default Inventory
