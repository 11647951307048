import BarsChart from './BarsChart'

export const CountGraphic = ({ title, data }) => {
  return (
    <>
      <div className="card" style={{ height: '96%' }}>
        <div className="card-title pt-2">
          <div className="col-12 border border-top-0 border-left-0 border-right-0 border-2 border-dark">
            <h6>{title}</h6>
          </div>
        </div>
        <div className="card-body">
          <BarsChart data={data} />
        </div>
      </div>
    </>
  )
}

export default CountGraphic
