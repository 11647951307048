import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import LayoutTable from '../../Global/LayoutTable'
import ModalContent from '../../Global/ModalContent'
import AccountantForm from './Components/AccountantForm'

// apollo
import {
  GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS,
  DELETE_INVENTORY_ACTIVITIES_ACCOUNTANT,
  EXPORT_INVENTORY_ACTIVITIES_ACCOUNTANTS,
  // IMPORT_INVENTORY_ACTIVITIES_ACCOUNTANT,
} from '../../../graphql/InventoryActivities/InventoryActivitiesAccountants'
import { GET_SAP_WAREHOUSES } from '../../../graphql/Catalog/SAP/sapWarehouses'
import InputController from '../../Global/InputController'
import { useForm } from 'react-hook-form'

export const InventoryActivitiesAccountant = () => {
  const refModal = useRef()
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 30,
    offset: 0,
    warehouseCode: '270',
  })

  const [totalCounters, setTotalCounters] = useState(0)
  const [listOfCounters, setListOfCounters] = useState([])
  const [listOfWarehousesOptions, setListOfWarehousesOptions] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [id, setId] = useState(null)
  const [cleanAccountantForm, setCleanAccountantForm] = useState(false)

  const {
    data: accountantsData,
    loading: accountantsLoading,
    error: accountantsError,
    refetch: accountantsRefetch,
  } = useQuery(GET_ALL_INVENTORY_ACTIVITIES_ACCOUNTANTS, {
    variables: pagePagination,
    fetchPolicy: 'no-cache',
  })

  const {
    data: listOfWarehouses,
    loading: loadingListOfWarehouses,
    error: errorListOfWarehouses,
  } = useQuery(GET_SAP_WAREHOUSES)

  const { control, setValue } = useForm({})

  useEffect(() => {
    try {
      if (accountantsLoading) return

      if (accountantsError)
        return toastSweetAlert({
          mode: 'error',
          message: accountantsError.message,
        })

      try {
        const list =
          accountantsData?.getAllInventoryActivitiesAccountant?.rows.map(
            (item) => {
              return {
                id: item.user_id,
                ['Nombre']: item.full_name,
                ['Almacén']: item?.WarehouseToAccountant?.warehouse_name,
              }
            }
          )

        setListOfCounters(list)
        setTotalCounters(
          accountantsData.getAllInventoryActivitiesAccountant.count
        )
      } catch (error) {
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      }
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [accountantsLoading, accountantsError, accountantsData])

  useEffect(() => {
    try {
      if (loadingListOfWarehouses) return
      if (errorListOfWarehouses)
        return toastSweetAlert({
          mode: 'error',
          message: errorListOfWarehouses.message,
        })

      const list = listOfWarehouses?.getSapWarehouses.map((item) => {
        return {
          label: item.warehouse_name,
          value: item.warehouse_code,
        }
      })
      setListOfWarehousesOptions(list)
      setValue('warehouse', '270')
      setListOfWarehousesOptions(list)
    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }, [loadingListOfWarehouses, listOfWarehouses, errorListOfWarehouses])

  const handleEdit = (e) => {
    setEditMode(true)
    setId(e.id)
    refModal.current.click()
  }

  return (
    <>
      <LayoutTable
        data={listOfCounters}
        title="Contadores"
        totalCount={totalCounters}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        paginationPerPage={30}
        loadingData={accountantsLoading}
        actionBtnErase={false}
        gql={DELETE_INVENTORY_ACTIVITIES_ACCOUNTANT}
        exportQuery={EXPORT_INVENTORY_ACTIVITIES_ACCOUNTANTS}
        importMessageValidation="archivo obligatorio"
        // importQuery={IMPORT_INVENTORY_ACTIVITIES_ACCOUNTANT}
        hideBtnEdit={true}
        hideDetails={true}
        refetchFunctionQueries={async () => await accountantsRefetch()}
        customActionButtons={[
          {
            className: 'btn btn-accept btn-block',
            handleButton: handleEdit,
            buttonText: 'Editar',
          },
        ]}
        hideAddNew={true}
        extraHeaderButtonFunction={(e) => {
          setEditMode(false)
          setId(null)
          refModal.current.click()
        }}
        extraHeaderButtonFunctionText={
          <>
            <i className="fa fa-plus"></i> Agregar contador
          </>
        }
        // filter={true}
        // filterOnChange={filterOnChange}
        // filterOptions={listOfWarehousesOptions}
        customFilterButtonInput={
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <InputController
              type="text"
              name="warehouse"
              inputType="choosen"
              options={listOfWarehousesOptions}
              control={control}
              changeAction={(e) =>
                setPagePagination({
                  ...pagePagination,
                  warehouseCode: e.value,
                })
              }
            />
          </div>
        }
      />

      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#modal-fti-accountant"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="modal-fti-accountant"
        title={editMode ? 'Editar contador' : 'Nuevo contador'}
        onClose={() => {
          setCleanAccountantForm(true)
        }}
        content={
          <>
            <AccountantForm
              editMode={editMode}
              refModal={refModal}
              id={id}
              setId={setId}
              refetch={accountantsRefetch}
              cleanForm={cleanAccountantForm}
              setCleanForm={setCleanAccountantForm}
            />
          </>
        }
      />
    </>
  )
}

export default InventoryActivitiesAccountant
