import { gql } from '@apollo/client'

export const GET_ALL_CLIENTS_TO_BILLING_QUERY = gql`
  query GetAllDocumentsToBillingClients(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllDocumentsToBillingClients(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        invoice_uid
        name
        rfc
      }
    }
  }
`

export const GET_ONE_CLIENT_TO_BILLING_QUERY = gql`
  query GetDocumentToBillingClientById($getDocumentToBillingClientByIdId: Int) {
    getDocumentToBillingClientById(id: $getDocumentToBillingClientByIdId) {
      id
      invoice_uid
      name
      rfc
    }
  }
`

export const SYNC_CLIENT_TO_BILLING_MUTATION = gql`
  mutation Mutation {
    syncDocumentToBillingClients
  }
`

export const GET_ALL_CLIENTS_TO_BILLING_MUTATIION = gql`
  mutation Mutation($searchQuery: String, $limit: Int, $offset: Int) {
    getAllClientsToBilling(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        invoice_uid
        name
        rfc
      }
    }
  }
`

export const DELETE_CLIENT_TO_BILLING_MUTATION = gql`
  mutation DeleteDocumentToBillingClient($id: Int) {
    deleteDocumentToBillingClient(id: $id)
  }
`
