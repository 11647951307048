import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GET_INVENTORY, GET_INVENTORY_REORDER_BY_SKU, UPDATE_OR_CREATE_INVENTORY_REORDER } from '../../graphql/Catalog/PositionOrders/inventoryReorder'
import LayoutTable from '../Global/LayoutTable'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import ContentHeader from '../Layout/ContentHeader'

export const InventoryGeneral = () => {
  const defaultError = 'Algo salio mal, vuelve a intentar en unos minutos'
  const inventoryReorderNotFound = 'No se encontro el reorden del inventario'
  const reorderNotIntegerError = 'El reorden debe ser un numero y positivo'
  const reorderNull = 'Porfavor llene el campo'

  const MySwal = withReactContent(Swal)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_INVENTORY, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })

  //Mutation to create or update Inventory Reorder table
  const [updateOrCreateInventoryReorder] = useMutation(UPDATE_OR_CREATE_INVENTORY_REORDER,{
    refetchQueries: [
      {
        query: GET_INVENTORY,
        variables: {
          searchQuery: pagePagination.searchQuery,
          limit: pagePagination.limit,
          offset: pagePagination.offset,
        },
      },
    ],
  });

  //Mutation to get reorder of catalog_inventory_reorder
  const [GetInventoryReorderSelected] = useMutation(GET_INVENTORY_REORDER_BY_SKU)

  const [dataToTable, setDataToTable] = useState([])

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getInventory.rows.map((inventory) => {
        return list.push({
          id: inventory?.id,
          SKU: inventory?.item_code ? inventory.item_code : '--',
          Nombre: inventory?.SapItem?.item_name ? inventory.SapItem.item_name : '--',
          Reorden: inventory?.RackPosition?.reorder ? inventory.RackPosition.reorder : '--',
          Cantidad: inventory?.on_hand ? inventory.on_hand : '--',
          alert: inventory?.on_hand <= inventory?.RackPosition?.reorder ? true : false,
        })
      })
      setDataToTable(list)
      setTotal(data.getInventory.count)
    }
    // eslint-disable-next-liconsole.log(e)ne react-hooks/exhaustive-deps
  }, [loading, error, data, total, pagePagination])

  const handleSubmitReorder = async(idVar)=>{
    try {
      const data = dataToTable.find(item => item.id === parseInt(idVar))

      if(data){
        //Find Inventory Reorder to show reorder in MySwal
        const myInventoryReorder = await GetInventoryReorderSelected({
          variables:{
            sku: parseInt(data.SKU)
          }
        })

        await MySwal.fire({
          title: data.Nombre ? data.Nombre : '',
          input: 'number',
          inputValue: myInventoryReorder?.data?.getInventoryReorderBySku ? myInventoryReorder.data.getInventoryReorderBySku.reorder : null,
          showCancelButton: true,
          showCloseButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: 'Guardar',
          cancelButtonText: 'Cancelar',
          focusConfirm: false,
          buttonsStyling: false,
          html: (
            <div className="message-container">
              Ingrese la nueva cantidad de reorden
            </div>
          ),
          customClass: {
            title: 'StandarModalTitle',
            htmlContainer: 'StandarModalHtmlContainer',
            confirmButton: 'StandarModalConfirmButtonLogOut',
            cancelButton: 'StandarModalConfirmButtonLogOut',
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            //Validate
            let newReorder = result.value;
            if(!newReorder)
              return toastSweetAlert({
                mode: 'error',
                message: reorderNull,
              }) 

            if(!isNaN(newReorder) && newReorder >= 0){
              //Create or update reorden to inventory_reorder
              await updateOrCreateInventoryReorder({
                variables: {
                  sku: parseInt(data.SKU),
                  reorder: parseInt(newReorder),
                },
              })

              return toastSweetAlert({
                mode: 'ok',
                message: 'Reorden actualizado',
              });
            }

            return toastSweetAlert({
              mode: 'error',
              message: reorderNotIntegerError,
            }) 
          }
        })

      }else{
        return toastSweetAlert({
          mode: 'error',
          message: defaultError,
        }) 
      }

    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: defaultError,
      })
    }

  }

  return (
    <>
      {/* <ContentHeader
        title="Inventario general"
        breadcrumb="Inventario"
        windowTitle="Inventario"
      /> */}
      <LayoutTable
        data={dataToTable}
        title="Inventario general"
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        hideId
        alertAction
        alertActionText="Orden"
        handleButton={handleSubmitReorder}
        handleButtonTitle="Reorden"
        handleButtonRowCondition={true}

        // hideFilterBar
        // hideActions
        hideDetails
        FilterBarPlaceholder="Buscar por SKU o Nombre de producto"
      />
    </>
  )
}

export default InventoryGeneral
