import React, { useState, useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '../../Global/Box'
import ContentHeader from '../../Layout/ContentHeader'
import { useMutation } from '@apollo/client'
import {
  CREATE_CUSTOMER, GET_CUSTOMERS
} from '../../../graphql/Catalog/Telemarketing/Customers/customers'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import InputController from '../../Global/InputController'
import { SEPOMEX_MUTATION } from '../../../graphql/Global/sepomex'
import { setZipNullValues, setZipValues } from '../../Helpers/setInputValues'
import { AuthContext } from '../../../Auth/AuthContext'

export const TelemarketingOrdersNew = () => {
  const { id: _id } = useParams()
  const { user } = useContext(AuthContext)
  
  const [loadingBtn, setLoadingBtn] = useState(false)

  const [colonies, setColonies] = useState([])

  const history = useHistory()

  const initialStateZip = {
    colonia: [],
    city: '',
    municipality: '',
    state: '',
    colony: '',
    idCountry: '',
    idState: '',
    idCity: '',
    idMuicipality: '',
  }

  const [zip, setZip] = useState(initialStateZip)

  const [sepomexAdress] = useMutation(SEPOMEX_MUTATION)

  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMERS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(100, 'El nombre debe ser menor a 100 caracteres').required('Este campo es obligatorio'),
    lastName: Yup.string().max(100, 'Los apellidos deben ser menores a 100 caracteres').required('Este campo es obligatorio'),
    phone: Yup.string().required('Este campo es obligatorio'),
    email: Yup.string().max(100, 'El email debe ser menor a 100 caracteres').email('Formato invalido').required("Este campo es obligatorio"),
    direction: Yup.string().max(100, 'La dirección debe ser menor a 100 caracteres').required('Este campo es obligatorio'),
    postalCode: Yup.string()
      .min(4, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
      .max(6, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
      .required('Este campo es obligatorio'),
    directionReference: Yup.string().max(50, 'La referencia debe ser menor a 50 caracteres').required('Este campo es obligatorio'),
    betweenStreets: Yup.string().max(50, 'La referencia debe ser menor a 50 caracteres').required('Este campo es obligatorio'),
    colonia: Yup.string().required('Este campo es obligatorio'),
    state: Yup.string().required('Este campo es obligatorio'),
    rfc: Yup.string().max(13, 'El RFC debe ser menor a 13 caracteres').required('Este campo es obligatorio'),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const getAddress = async (zipCode) => {
    try {
      const { data } = await sepomexAdress({
        variables: {
          zipCode: parseInt(zipCode),
        },
      })
      setZipValues(setValue, setColonies, data.getFullAddressByZipcode, setZip)
    } catch (error) {
      setZipNullValues(setValue)
      toastSweetAlert({
        mode: 'error',
        message: 'Por favor ingrese un código postal válido',
      })
      setZip(initialStateZip)
    }
  }

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)
        await createCustomer({
          variables: {
            storeId: parseInt(_id),
            input: {
              first_name: data.firstName,
              last_name: data.lastName,
              phone: data.phone,
              email: data.email,
              rfc: data.rfc,
              id_user_register: user.idUser,
              direction: data.direction,
              direction_reference: data.directionReference,
              between_streets: data.betweenStreets,
              id_state: parseInt(zip.idState),
              id_colony: parseInt(data.colonia),
              zip_code: parseInt(data.postalCode),
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Cliente registrado correctamente',
          }),
          history.push(`/telemarketingOrders`)
        )      
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      {/* <ContentHeader
        title="Pedidos"
        breadcrumb="Pedidos / Nuevo cliente"
        windowTitle="Nuevo cliente"
      /> */}
      <FormProvider {...methods}>
        <form
          className={`p-3 ${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title={`${_id ? 'Editar' : 'Nuevo'} cliente`}
            btnRedPath="/telemarketingOrders"
            btnRedTxt="Cancelar"
            errors={errors}
            btnSubmit={true}
            btnLoading={loadingBtn}
            content={
              <>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Nombre"
                      type="text"
                      name="firstName"
                      placeholder="Ingrese su nombre..."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Apellidos"
                      type="text"
                      name="lastName"
                      placeholder="Ingrese sus apellidos..."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Teléfono"
                      type="text"
                      name="phone"
                      placeholder="Ingrese su número telefónico..."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Correo electrónico"
                      type="email"
                      name="email"
                      placeholder="Ingrese su correo electrónico..."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Dirección"
                      type="text"
                      name="direction"
                      placeholder="Ingrese su dirección..."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="C. P."
                      type="text"
                      name="postalCode"
                      placeholder="Ingrese un C. P."
                      control={control}
                      blurAction={(e) => {
                        getAddress(e.target.value)
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Referencia"
                      type="text"
                      name="directionReference"
                      placeholder="Ingrese una referencia..."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Entre"
                      type="text"
                      name="betweenStreets"
                      placeholder="Ingrese entre que calles está su dirección..."
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Colonia"
                      type="text"
                      name="colonia"
                      inputType="choosen"
                      changeAction={(e) => setValue('colonia_name', e.label)}
                      options={colonies}
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="Estado"
                      readOnly
                      type="text"
                      name="state"
                      placeholder="Estado"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <InputController
                      label="RFC"
                      type="text"
                      name="rfc"
                      placeholder="Ingrese su RFC..."
                      control={control}
                    />
                  </div>
                  {/* hidden input */}
                  <InputController
                    label=""
                    type="text"
                    hidden
                    name="colonia_name"
                    control={control}
                  />
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}
export default TelemarketingOrdersNew
