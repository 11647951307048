import ContentHeader from '../../../../Layout/ContentHeader'
import Box from '../../../../Global/Box'
import { useHistory, useParams } from 'react-router'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
  GET_ALL_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
  GET_ONE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
  UPDATE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/SAP/sapBusinessPartnerCounterCharges'
import { FormProvider, useForm } from 'react-hook-form'
import InputController from '../../../../Global/InputController'
import {
  GET_SAP_LIST_PRICES,
  GET_SAP_LIST_PRICES_CHOOSEN,
} from '../../../../../graphql/Catalog/SAP/sapListPrices'
import { GET_SAP_NUMBER_GROUPS } from '../../../../../graphql/Catalog/SAP/sapNumberGroups'
import { GET_SAP_PARTNER_GROUP } from '../../../../../graphql/Catalog/SAP/sapPartnerGroup'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'

export const BusinessPartnerCounterChargesNew = () => {
  const { id: _id } = useParams()
  const history = useHistory()
  const [loadingBtn, setLoadingBtn] = useState(null)
  const [optionsListPrices, setOptionsListPrices] = useState([])
  const [optionsSapNumberGroups, setOptionsSapNumberGroups] = useState([])
  const [optionsSapPartnerGroup, setOptionsSapPartnerGroup] = useState([])

  const {
    loading: loadingGetOnePartner,
    error: errorGetOnePartner,
    data: dataGetOnePartner,
  } = useQuery(GET_ONE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES, {
    variables: {
      getOneSapBusinessPartnerCounterChargesId: parseInt(_id),
    },
    fetchPolicy: 'no-cache',
  })

  const { data: dataSapListPrices } = useQuery(GET_SAP_LIST_PRICES)

  const { data: dataSapNumberGroups } = useQuery(GET_SAP_NUMBER_GROUPS)

  const { data: dataSapPartnerGroup } = useQuery(GET_SAP_PARTNER_GROUP)

  const [createSapBusinessPartnerCounterCharges] = useMutation(
    CREATE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
    {
      refetchQueries: [
        {
          query: GET_ALL_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
          variables: {
            limit: 10,
            offset: 0,
            searchQuery: null,
          },
        },
        {
          query: GET_ONE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
          variables: {
            getOneSapBusinessPartnerCounterChargesId: null,
          },
        },
      ],
    }
  )

  const [updateSapBusinessPartnerCounterCharges] = useMutation(
    UPDATE_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
    {
      refetchQueries: [
        {
          query: GET_ALL_SAP_BUSINESS_PARTNER_COUNTER_CHARGES,
          variables: {
            limit: 10,
            offset: 0,
            searchQuery: null,
          },
        },
      ],
    }
  )

  const validationSchema = Yup.object().shape({
    card_code: Yup.string('Este campo es obligatorio'),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (dataSapListPrices !== null && dataSapListPrices !== undefined) {
      const listPrices = dataSapListPrices?.getSapListPrices?.map((item) => {
        return {
          value: item?.id,
          label: item?.list_name,
        }
      })
      setOptionsListPrices(listPrices)
    }
  }, [dataSapListPrices])

  useEffect(() => {
    if (dataSapNumberGroups !== null && dataSapNumberGroups !== undefined) {
      const listNumberGroups = dataSapNumberGroups?.getSapNumberGroups?.map(
        (item) => {
          return {
            value: item?.id,
            label: item?.payment_group,
          }
        }
      )
      setOptionsSapNumberGroups(listNumberGroups)
    }
  }, [dataSapNumberGroups])

  useEffect(() => {
    if (dataSapPartnerGroup !== null && dataSapPartnerGroup !== undefined) {
      const listPartnerGroup = dataSapPartnerGroup?.getSapPartnerGroup?.map(
        (item) => {
          return {
            value: item?.group_code,
            label: item?.group_name,
          }
        }
      )
      setOptionsSapPartnerGroup(listPartnerGroup)
      console.log(listPartnerGroup)
    }
  }, [dataSapPartnerGroup])

  useEffect(() => {
    if (_id) {
      if (dataGetOnePartner !== null && dataGetOnePartner !== undefined) {
        const data = dataGetOnePartner.getOneSapBusinessPartnerCounterCharges

        const dataFormated = {
          lic_trad_number: data.lic_trad_number,
          card_name: data.card_name,
          card_code: data.card_code,
          phone1: data.phone1,
          phone2: data.phone2,
          email: data.email,
          group_code: data.group_code,
          group_number: data.group_number,
          list_number: data.list_number,
          credit_line: data.credit_line,
          debit_line: data.debit_line,
          discount: data.discount,
        }
        reset(dataFormated)
        console.log(dataFormated)
      }
    }
  }, [dataGetOnePartner, reset, _id])

  const onSubmit = async (data) => {
    try {
      if (_id) {
        await updateSapBusinessPartnerCounterCharges({
          variables: {
            updateSapBusinessPartnerCounterChargesId: parseInt(_id),
            sapBusinessPartnerInput: {
              card_code: data.card_code,
              card_name: data.card_name,
              group_code: data.group_code,
              list_number: data.list_number,
              group_number: data.group_number,
              credit_line: parseInt(data.credit_line),
              debit_line: parseInt(data.debit_line),
              discount: parseInt(data.discount),
              phone1: data.phone1,
              phone2: data.phone2,
              email: data.email,
              lic_trad_number: data.lic_trad_number,
            },
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'El proveedor se ha actualizado correctamente',
          },
          history.push(`/catalog/notes/businesspartnercountercharges`)
        )
      }

      await createSapBusinessPartnerCounterCharges({
        variables: {
          sapBusinessPartnerInput: {
            card_code: data.card_code,
            card_name: data.card_name,
            group_code: data.group_code,
            list_number: data.list_number,
            group_number: data.group_number,
            credit_line: parseInt(data.credit_line),
            debit_line: parseInt(data.debit_line),
            discount: parseInt(data.discount),
            phone1: data.phone1,
            phone2: data.phone2,
            email: data.email,
            lic_trad_number: data.lic_trad_number,
          },
        },
      })
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'El proveedor se ha creado correctamente',
        },
        history.push(`/catalog/notes/businesspartnercountercharges`)
      )
    } catch (e) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        windowTitle="Proveedores"
        title={`${_id ? 'Editar' : 'Agregar'} Proveedor `}
        breadcrumb={`${_id ? 'Editar' : 'Agreggar'} Proveedor `}
      />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`${_id ? 'Editar' : 'Agregar'} Proveedor `}
            btnRedTxt="Cancelar"
            btnRedPath="/catalog/notes/businesspartnercountercharges"
            btnSubmit={true}
            btnState={loadingBtn}
            error={errors}
            content={
              <>
                <div className="col">
                  <div className="row">
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="lic_trad_number"
                        label="RFC"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="card_name"
                        label="Razón Social"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="card_code"
                        label="Código Proveedor"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="phone1"
                        label="Teléfono 1"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="phone2"
                        label="Teléfono 2"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="email"
                        label="Correo"
                        control={control}
                      />
                    </div>
                    {/* <div className="mb-3 col-lg-4">
                      <InputController
                        label="Tipo tarjeta"
                        name="card_typeProvider"
                        control={control}
                      />
                    </div> */}
                    <div className="mb-3 col-lg-4">
                      <InputController
                        label="Código de grupo"
                        name="group_code"
                        type="number"
                        inputType="choosen"
                        options={optionsSapPartnerGroup}
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="group_number"
                        label="Grupo"
                        type="number"
                        inputType="choosen"
                        options={optionsSapNumberGroups}
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="list_number"
                        label="Lista"
                        type="number"
                        inputType="choosen"
                        options={optionsListPrices}
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="credit_line"
                        label="Línea de crédito"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="debit_line"
                        label="Línea de debito"
                        control={control}
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <InputController
                        name="discount"
                        label="Descuento"
                        control={control}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default BusinessPartnerCounterChargesNew
