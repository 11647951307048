import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { GET_ALL_DOCUMENT_TYPES } from '../../../../graphql/Catalog/DocumentTypes/documentTypes'
import { GET_ALL_RACKS_NO_PAGINATION } from '../../../../graphql/Catalog/Stowing/racks'
import {
  GET_ALL_KARDEX,
  GET_ALL_KARDEX_PAGINATION,
} from '../../../../graphql/Documents/Kardex/kardex'
import LayoutTable from '../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../Layout/ContentHeader'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

const defaultError = 'Algo salio mal, vuelve a intentar en unos minutos'

const Kardex = () => {
  /**------------- Hooks */
  const dictionary = {
    1: 'Recepción',
    2: 'Surtido',
    3: 'Verificacion',
    4: 'Embarques',
    9: 'Reubicación',
    10: 'Posicionado',
    11: 'Relleno',
    12: 'Devolución',
    13: 'Devolución proveedor',
    14: 'Devolución por traspaso',
    15: 'TFI',
    16: 'Conteo cíclico',
  }

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    documentType: 0, //0=All,1=Receipt,2=Picking...etc
    start: '',
    end: '',
    rackId: null,
  })

  const [kardex, setKardex] = useState([])
  const [documentTypes, setDocumentTypes] = useState([])
  const [total, setTotal] = useState([])
  const [generalLoading, setGeneralLoading] = useState(true)
  const [dates, setDates] = useState({
    start: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
    end: moment().format('YYYY-MM-DD'),
  })

  const [rackOptions, setRackOptions] = useState([])

  const validationSchema = Yup.object().shape({
    rack_id: Yup.string(),
  })

  /**------------- Queries */
  const { data, loading, error } = useQuery(GET_ALL_KARDEX_PAGINATION, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      documentType: pagePagination.documentType, //0=All,1=Receipt,2=Picking...etc
      start: pagePagination.start,
      end: pagePagination.end,
      rackId: pagePagination.rackId,
    },
  })

  const {
    data: dataRack,
    loading: loadingRack,
    error: errorRack,
  } = useQuery(GET_ALL_RACKS_NO_PAGINATION)

  //Get all document types
  const {
    data: dataDocumentType,
    loading: loadingDocumentType,
    error: errorDocumentType,
  } = useQuery(GET_ALL_DOCUMENT_TYPES, {
    variables: {
      excludeIds: [5], //Ids that you dont need
    },
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) })

  /**------------- useEffects */
  useEffect(() => {
    if (!loading && !loadingDocumentType && !loadingRack) {
      if (error && errorDocumentType && errorRack)
        return toastSweetAlert({
          mode: 'error',
          message: defaultError,
        })

      //Prepare data
      let kardexData = []
      let movementName = ''
      data?.getAllKardexPagination?.rows?.map((item) => {
        movementName = item.movementTypeId
          ? item.movementTypeId == 1
            ? 'Entrada'
            : item.movementTypeId == 2
              ? 'Salida'
              : 'Movimiento sin nombre'
          : ''

        kardexData.push({
          id: item.id ? item.id : 0,
          sku: item.sku ? item.sku : '',
          cantidad: item.quantity ? item.quantity : 0,
          documento: item.documentName ? item.documentName : '',
          'id ': item.documentId ? item.documentId : '',
          tipo: movementName,
          rack: item.rackName ? item.rackName : '',
          fecha: item.date ? item.date : 'item.date',
          usuario: item.user ? item.user : '',
          conditional_alert: item.movementTypeId ? item.movementTypeId == 1 : 0,
        })
      })

      let listRacks = []
      // listRacks.push({value: 100000000, label: 'ss'})
      listRacks = dataRack.getAllRacksNoPagination.map((rack) => {
        return {
          value: rack.id,
          label: rack.name,
        }
      })
      setRackOptions(listRacks)

      //Prepare document types data
      let documentTypesData = []
      dataDocumentType?.getAllDocumentTypes.map((item) => {
        documentTypesData.push({
          value: item.id,
          label: dictionary[item.id] ? dictionary[item.id] : '',
        })
      })

      //Set document types
      setDocumentTypes(documentTypesData)
      //Set data
      setKardex(kardexData)
      //Set total
      setTotal(data.getAllKardexPagination.count)
      //Set global loading false
      setGeneralLoading(false)
    }
  }, [
    data,
    loading,
    error,
    dataDocumentType,
    loadingDocumentType,
    errorDocumentType,
    dataRack,
    loadingRack,
    errorRack,
  ])



  useEffect(() => {
    if (
      dates.start &&
      dates.end &&
      moment(dates.start).format() &&
      moment(dates.end).format()
    ) {
      if (moment(dates.start).format() > moment(dates.end).format())
        return toastSweetAlert({
          mode: 'error',
          message: 'La fecha inicial debe ser menor o igual a la fecha final',
        })
      else
        setPagePagination({
          ...pagePagination,
          start: dates.start,
          end: dates.end,
        })
    }
  }, [dates, setDates])

  /**------------------ Functions */
  const filterOnChange = (e) => {
    const filterSelected = e?.value
    setPagePagination({
      ...pagePagination,
      documentType: parseInt(filterSelected),
    })
  }

  const handleChangeDates = (e, type) => {
    setDates({
      ...dates,
      [type]: e.target.value,
    })
  }

  const handleFilterInput = (e) => {
    setPagePagination({
      ...pagePagination,
      rackId: parseInt(e?.value),
    })
    // setValue('rack_id', e.value)
  }

  return (
    <>
      <ContentHeader
        title="Kardex"
        breadcrumb="kardex"
        dateFilter
        dateFilterHandleChangeDates={handleChangeDates}
        dateFilterDataValues={dates}
      />

      <LayoutTable
        data={kardex}
        hideActions={true}
        hideAddNew={true}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        FilterBarPlaceholder="Buscar por SKU"
        // loadingData={generalLoading}
        loadingData={loading}
        hideDetails={true}
        title="Listado de kardex"
        alertColor="#fff"
        alertSecondaryColor="#fff"
        alertTextColor="#559615"
        alertSecondaryTextColor="#AF2525"
        hideId={true}
        // filter
        // filterOptions={
        //   documentTypes &&
        //   documentTypes.map((item) => {
        //     return <option value={item.id}>{item.name}</option>
        //   })
        // }
        // filterOnChange={filterOnChange}
        // filterInput
        // filterInputControl={control}
        // filterInputOptions={rackOptions}
        // filterInputName="rack_id"
        // handleFilterInput={handleFilterInput}
        textFontWeight="bold"
        exportQuery={GET_ALL_KARDEX}
        filterChoosen={[
          {
            id: 'documentType_id',
            name: 'documentType',
            options: documentTypes,
            placeholder: 'Seleccione tipo de documento',
            onChange: (e) => filterOnChange(e),
            isClearable: true,
          },
          {
            id: 'rack_id',
            name: 'racks',
            options: rackOptions,
            placeholder: 'Seleccione un rack',
            onChange: (e) => handleFilterInput(e),
            isClearable: true,
          },
        ]}
      />
    </>
  )
}

export default Kardex
