import React from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import { useParams } from 'react-router-dom'
import 'moment/locale/es'
import DetailsTransfer from './Details/DetailsTransfer'
import DetailsOrder from './Details/DetailsOrder'
// http://localhost:3000/logistic/routes-assignation/specificDetails/31/transfer
// http://localhost:3000/logistic/routes-assignation/specificDetails/162/order

export const ControlDeskRoutesDetails = () => {
  const { id: _id, type } = useParams()

  return (
    <>
      <ContentHeader
        title={`${
          type == 'transfer' ? 'Detalles traspaso' : 'Detalles pedido'
        }`}
        breadcrumb={`${
          type == 'transfer' ? 'Detalles traspaso' : 'Detalles pedido'
        }`}
        windowTitle={`${
          type == 'transfer' ? 'Detalles traspaso' : 'Detalles pedido'
        }`}
      />

      {type == 'transfer' ? (
        <DetailsTransfer _id={_id} />
      ) : (
        type == 'order' && <DetailsOrder _id={_id} />
      )}
    </>
  )
}
export default ControlDeskRoutesDetails
