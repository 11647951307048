import React, { useState, useEffect } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useQuery } from '@apollo/client'
import { GET_PRODUCT_SAP_BY_ID } from '../../../../graphql/Catalog/Telemarketing/Orders/orders'
import DetailsCustomer from '../../Shared/DetailsCustomer'
import DetailsProducts from '../../Shared/DetailsProducts'
import DetailsPaymentDelivery from '../../Shared/DetailsPaymentDelivery'
import Loader from '../../../Global/Spinner'

export const OrderDetails = ({
    _id,
}) => {

  //API get data of product SAP
  const { loading: loadingOrder, error: errorOrder, data: dataOrder } = useQuery(GET_PRODUCT_SAP_BY_ID, {
    variables: {
      "getOrderPartnerByIdId": parseInt(_id) 
    },
  })

  //save data of order
  const [businessPartner, setBusinessPartner] = useState([])
  const [businessPartnerAddresses, setBusinessPartnerAddresses] = useState([])
  const [productsOrder, setProductsOrder] = useState([])
  const [orderTotals, setOrderTotals] = useState({
    total_products: 0,
    total_shipping: 0,
    total: 0
  })
  
  //save address selected
  const [businessPartnerAddressesSelected, setBusinessPartnerAddressesSelected] = useState({})

  const [dataShipping, setDataShipping] = useState(null)

  useEffect(() => {
    if (!loadingOrder) {
      if (errorOrder) toastSweetAlert({
        mode: 'error',
        message: errorOrder.message,
      })

      let list = []
      let productsTotal = 0
      dataOrder.getOrderPartnerById.productsTelemarketing.map((item) => {
        productsTotal += item.total
        return list.push({
          img_url: item.img_url,
          sku: item.sku,
          product_add: item.name,
          precio: item.price,
          cantidad: item.quantity,
        })
      })

      setProductsOrder(list)
      setOrderTotals({
        total_products: productsTotal,
        total_shipping: dataOrder.getOrderPartnerById.shipping_price,
        total: productsTotal + dataOrder.getOrderPartnerById.shipping_price
      })

      list = []
      dataOrder.getOrderPartnerById.BusinessPartner[0].SapBusinessPartnerAddress.map((item) => {
        return list.push({
          label: item.id + ' | ' + item.address,
          value: item.id,
          street: item.street,
          block: item.block,
          zip_code: item.zip_code,
          city: item.city,
          country: item.country,
          state: item.state,
          route: item.BusinessPartnerAddressRoute
        })
      })

      setBusinessPartner(dataOrder.getOrderPartnerById.BusinessPartner[0])
      setBusinessPartnerAddresses(list)
      setDataShipping({
        payment_method: dataOrder.getOrderPartnerById.paymentTelemarketing?.platform ?? "indefinido",
        direction: dataOrder.getOrderPartnerById.shipping?.ShippingBusinessPartnerAddress?.id,
        shipping_id: dataOrder.getOrderPartnerById.shipping_id
      })
      onChangeDirection(dataOrder.getOrderPartnerById.shipping?.ShippingBusinessPartnerAddress)
    }
  }, [dataOrder])

  const onChangeDirection = (data) => {
    setBusinessPartnerAddressesSelected(
      {
        id: data?.value ?? data?.id, 
        street: data?.street,
        block: data?.block,
        zip_code: data?.zip_code,
        city: data?.city,
        country: data?.country,
        state: data?.state,
        route: data?.route ?? data?.BusinessPartnerAddressRoute
      }
    )
  }

  return(
    <div id="accordion">
      <DetailsCustomer 
        key={1}
        headers={["Nombre", "Teléfono 1", "Teléfono 2", "Correo electrónico"]} 
        customData={[businessPartner.card_name, businessPartner.phone1, businessPartner.phone2, businessPartner.email]}
      />

      <DetailsProducts 
        key={2}
        title={"Pedido"}
        customDataCart={productsOrder}
        customTotalCart={orderTotals}
      />
      
      {dataShipping ?
        <DetailsPaymentDelivery
          key={3}
          detailsForm={true}
          businessPartnerAddresses={businessPartnerAddresses}
          businessPartnerAddressesSelected={businessPartnerAddressesSelected}
          onChangeDirection={onChangeDirection}
          dataShipping={dataShipping}
          _id={_id}
        />
        :
        <div className='text-center'>
          <Loader/>
        </div>
      }
    </div>
  )

}
export default OrderDetails