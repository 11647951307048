import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { EXPORT_ROLES } from '../../../../graphql/Catalog/Roles/roles'
import ContentHeader from '../../../Layout/ContentHeader'
import LayoutTable from '../../../Global/LayoutTable'
import {
  DELETE_POSITION,
  GET_ALL_ITEMS,
  GET_ALL_ITEMS_EXPORT,
} from '../../../../graphql/Catalog/Stowing/items'
import { GET_ALL_RACKS_NO_PAGINATION } from '../../../../graphql/Catalog/Stowing/racks'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

export const RackPosition = () => {
  const dictionaryWarehouse = [
    { value: 1, label: 'Picking' },
    { value: 9, label: 'Almacén Gral.' },
    { value: 10, label: 'Nesspreso' },
  ]

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    zoneId: null,
  })
  const [total, setTotal] = useState(0)
  const [loader, setLoader] = useState(false)
  const [racksOptions, setRacksOptions] = useState([])
  const { loading, error, data } = useQuery(GET_ALL_ITEMS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      zoneId: pagePagination.zoneId,
      rackId: pagePagination.rackId,
    },
  })
  const {
    data: dataRacksNoPagination,
    loading: loadingRacksNoPagination,
    error: errorRacksNoPagination,
  } = useQuery(GET_ALL_RACKS_NO_PAGINATION)

  const [dataToTable, setDataToTable] = useState([])

  const validationSchema = Yup.object().shape({
    rack_id: Yup.string(),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) })

  useEffect(() => {
    const setData = async () => {
      setLoader(true)
      if (!loading) {
        if (error) {
          setLoader(false)
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
        const list = data.getAllItems.rows.map((item) => {
          return {
            id: item.id,
            Rack: item.RackPosition?.name || '--',
            SKU: item.item_code,
            Lote: item.lote || '--',
            'Fecha de Caducidad': item.expiration_date || '--',
            Añada: item.vintage || '--',
            Nombre: item.SapItem?.item_name || '--',
            Cantidad: item.on_hand,
            Comprometido: item.on_commit,
            alert:
              item.RackPosition?.reorder === -1
                ? false
                : item.on_hand <= item.RackPosition?.reorder,
          }
        })
        setDataToTable(list)
        setTotal(data.getAllItems?.count)
        setLoader(false)
      }
      setLoader(false)
    }
    setData()
  }, [loading, error, data])

  useEffect(() => {
    const dataRacks = async () => {
      if (!loadingRacksNoPagination) {
        if (errorRacksNoPagination) {
          return toastSweetAlert({
            mode: 'error',
            message: errorRacksNoPagination.message,
          })
        }
        const list = dataRacksNoPagination.getAllRacksNoPagination.map(
          (item) => {
            return {
              value: item.id,
              label: item.name,
            }
          }
        )
        setRacksOptions(list)
      }
    }
    dataRacks()
  }, [loadingRacksNoPagination, errorRacksNoPagination, dataRacksNoPagination])

  const filterOnChange = (e) => {
    console.log(e)
    const filterSelected = e?.value
    setPagePagination({
      ...pagePagination,
      zoneId: parseInt(filterSelected),
    })
  }

  const handleFilterInput = (e) => {
    console.log(e)
    setPagePagination({
      ...pagePagination,
      rackId: parseInt(e?.value),
    })
  }

  return (
    <>
      {/* <ContentHeader
        title="Productos"
        breadcrumb="Productos"
        windowTitle="Productos"
      /> */}
      <LayoutTable
        data={dataToTable}
        title="Productos"
        gql={DELETE_POSITION}
        requery={GET_ALL_ITEMS}
        exportQuery={GET_ALL_ITEMS_EXPORT}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading || loader}
        hideDetails
        hideId
        alertAction
        alertActionText="Rellenar"
        addBtnTxt="Asignar nueva posición"
        extraHeaderButton
        extraHeaderButtonTo="/stowing/reorder/new/palletCreation"
        extraHeaderText={'Crear nuevo pallet'}
        // filter
        // filterOnChange={filterOnChange}
        // filterOptions={[
        //   <option value="1" key="1">
        //     Picking
        //   </option>,
        //   <option value="9" key="9">
        //     Almacén Gral.
        //   </option>,
        //   <option value="10" key="10">
        //     Nesspreso
        //   </option>,
        // ]}
        FilterBarPlaceholder="Buscar por SKU o Nombre de producto"
        // // filterLabelText="Por almacén"
        // filterInput
        // filterInputControl={control}
        // filterInputName="rack_id"
        // filterInputOptions={racksOptions}
        // handleFilterInput={handleFilterInput}
        filterChoosen={[
          {
            label: 'Por almacén',
            id: 'warehouse_id',
            name: 'warehouse',
            options: dictionaryWarehouse,
            placeholder: 'Seleccione un almacén',
            onChange: (e) => filterOnChange(e),
            isClearable: true,
          },
          {
            label: 'Por rack',
            id: 'rack_id',
            name: 'racks',
            options: racksOptions,
            placeholder: 'Seleccione un rack',
            onChange: (e) => handleFilterInput(e),
            isClearable: true,
          },
        ]}
      />
    </>
  )
}

export default RackPosition
