import React, { useState, useEffect } from 'react'
import BoxCollapsible from '../../Global/BoxCollapsible'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useMutation } from '@apollo/client'
import { GET_PROUCT_SAP } from '../../../graphql/Catalog/Telemarketing/Orders/orders'
import LayoutTable from '../../Global/LayoutTable'
import InputController from '../../Global/InputController'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import DetailsPrices from './DetailsPrices'
import ActionButtonsNewOrder from './ActionButtonsNewOrder'

export const DetailsProducts = ({
    key,
    title,
    showProductsCollapse,
    showPaymentDeliveryCollapse,
    handleShowProductsCollapse,
    handleShowPaymentDeliveryCollapse,
    handleCart,
    customDataCart,
    customTotalCart,
    withBox = true
}) => {
    const [getProducts] = useMutation(GET_PROUCT_SAP)

    //save data order
    const [dataCart, setDataCart] = useState([])

    //save total order
    const [totalCart, setTotalCart] = useState(0);

    const [loadingTable, setLoadingTable] = useState(false)

    const [dataToTableCatalogProducts, setDataToTableCatalogProducts] = useState([])

    const validationSchemaSearch = Yup.object().shape({
        search_product: Yup.string().min(4, 'La busqueda debe ser mayor a 3 caracteres')
    })

    const {
    methods: methodsSearch,
    handleSubmit: handleSubmitSearch,
    formState: { errorsSearch },
    control: controlSearch,
    reset: resetSearch,
    } = useForm({
    resolver: yupResolver(validationSchemaSearch),
    })

    useEffect(() => {
        //Save data of order in localhost at the momment
        setTotalCart(localStorage.getItem("totalOrder"));
        setDataCart(localStorage.getItem("dataOrder") ? JSON.parse(localStorage.getItem("dataOrder")) : [])
    }, [])

    const searchProduct = async(Data) => {
        try {
        setLoadingTable(true)
            const { data } = await getProducts({
            variables: {
                warehouseCode: "270",
                searchQuery: Data.search_product,
            },
            })
            let list = []
            data.getProductSapTelemarketing.map((item) => {
                    let dataList = {}
                    dataList.img_url = "http://http2.mlstatic.com/D_981113-MLM42366646822_062020-O.jpg"
                    dataList.sku = item.item_code

                    item.SapItems.map((sapItem)=>{
                        dataList.product_add = sapItem.item_name
                    })
                    
                    dataList.quantity_select = item.on_hand
                    dataList.precio = 298
                    return list.push(dataList)
            })
            setDataToTableCatalogProducts(list)
            setLoadingTable(false)
        } catch (error) {
            return toastSweetAlert({
                mode: 'error',
                message: error.message,
            })
        }
    }

    const handleDetails = (row) => {
        // let index = dataToTable.findIndex(a => a.sku == row.sku)

        let itemsOrder = localStorage.getItem("dataOrder") ? JSON.parse(localStorage.getItem("dataOrder")) : [];
        let existingProduct = false;
        let totalOrder = 0;
        
        itemsOrder.map((item, index) => {
        if(itemsOrder[index].sku == row.sku){
                itemsOrder[index].cantidad += row.quantity_selectioned ? row.quantity_selectioned : 1;
                delete itemsOrder[index].quantity_select;
                delete itemsOrder[index].quantity_selectioned;

                existingProduct = true;
                //validate product stock
                if(itemsOrder[index].cantidad > row.quantity_select){
                itemsOrder[index].cantidad -= row.quantity_selectioned ? row.quantity_selectioned : 1;
                toastSweetAlert({
                    mode: 'error',
                    message: 'No hay suficiente stock',
                })
                // document.getElementById("select-quantity-" + row["sku"]).setAttribute("disabled", "disabled");
                }
            }
        })

        if(!existingProduct){ 
        if(row.quantity_selectioned > row.quantity_select){
            toastSweetAlert({
                mode: 'error',
                message: 'No hay suficiente stock',
            })
            // document.getElementById("select-quantity-" + row["sku"]).setAttribute("disabled", "disabled");
            }else{
            let newRow = structuredClone(row)
            newRow.cantidad = newRow.quantity_selectioned ? newRow.quantity_selectioned : 1;
            delete newRow.quantity_select;
            delete newRow.quantity_selectioned;
            itemsOrder.push(newRow)
            }
        }

        itemsOrder.map((item, index) => {
            totalOrder += itemsOrder[index].cantidad * itemsOrder[index].precio
        })

        setDataCart(itemsOrder)
        setTotalCart(totalOrder)
        localStorage.setItem("dataOrder", JSON.stringify(itemsOrder))
        localStorage.setItem("totalOrder", totalOrder)
    }

    const handleDeleteCarItem = (row) => {
        let itemsOrder = localStorage.getItem("dataOrder") ? JSON.parse(localStorage.getItem("dataOrder")) : [];
        let index = itemsOrder.findIndex(a => a.sku == row.sku)

        itemsOrder.splice(index, 1)

        let totalOrder = 0;

        itemsOrder.map((item, index) => {
            totalOrder += itemsOrder[index].cantidad * itemsOrder[index].precio
        })
        setTotalCart(totalOrder)

        setDataCart(itemsOrder)
        localStorage.setItem("dataOrder", JSON.stringify(itemsOrder))
        localStorage.setItem("totalOrder", totalOrder)

    }

    const handleCleanOrder = () => {
        setDataCart([])
        localStorage.removeItem("dataOrder")
        setTotalCart(0)
        localStorage.removeItem("totalOrder")
    }

    const handleContinue = () => {
        //hide products collapse
        handleShowProductsCollapse(false)
        //show payment and delivery collapse
        handleShowPaymentDeliveryCollapse(true)
        //return data and total cart
        handleCart({
            dataCart: dataCart,
            totalCart: totalCart
        })
    }

    return(
        withBox ?
            <BoxCollapsible
                key={key}
                title={title}
                icon=""
                nestedContent={true}
                customizedDefault={true}
                collapseUnfolded={showProductsCollapse}
                content={
                    <>
                        {
                        !showPaymentDeliveryCollapse && !customDataCart ?
                            <>
                            <FormProvider {...methodsSearch}>
                            <form className={`mb-n3 p-3 ${loadingTable ? 'processing-file' : ''}`} onSubmit={handleSubmitSearch(searchProduct)}>
                                <div className='row'>
                                    <div className='col col-md-4'>
                                    <InputController
                                        id="search_product"
                                        label="Buscador de productos"
                                        name="search_product"
                                        control={controlSearch}
                                    />
                                    </div>
                                </div>
                            </form>
                            </FormProvider>
                            <LayoutTable
                                data={dataToTableCatalogProducts}
                                hideActions={true}
                                headersButtons={false}
                                actionsAddProducts={true}
                                handleButtonRowCondition={true}
                                handleButton={(e) => handleDetails(e)}
                                handleButtonTitle="Agregar"
                                withCard={false}
                                listFormat={true}
                                loadingData={loadingTable}
                                hideFilterBar={true}
                            />
                            <h3 className='mt-5 mb-n4'>Pedido</h3>
                            <LayoutTable
                                data={dataCart}
                                hideFilterBar={true}
                                headersButtons={false}
                                hideActions={true}
                                actionsAddProducts={true}
                                handleButtonRowCondition={true}
                                handleButtonClassName="btn-danger"
                                handleButton={(e) => handleDeleteCarItem(e)}
                                handleButtonTitle= {(<i className="fas fa-trash"></i>)}
                                withCard={false}
                                listFormat={true}
                                FilterBarPlaceholder= "Buscar"
                            />
                            </>
                            :
                            <LayoutTable
                                data={!customDataCart ? dataCart : customDataCart}
                                hideFilterBar={true}
                                headersButtons={false}
                                hideActions={true}
                                withCard={false}
                                listFormat={true}
                                FilterBarPlaceholder= "Buscar"
                            />
                        }

                        {/* DETAILS OF PRICES */}
                        <DetailsPrices 
                            orderTotals={
                                !customTotalCart ? 
                                {
                                    total_products: totalCart,
                                    total_shipping: 0,
                                    total: totalCart
                                }
                                :
                                customTotalCart
                            } 
                        />

                        {/* To validate that the products order cannot be modified once it is in payment and delivery */}
                        {
                        !showPaymentDeliveryCollapse && !customDataCart &&
                            <ActionButtonsNewOrder 
                                handleCleanOrder={handleCleanOrder} 
                                handleContinue={handleContinue} 
                                dataCart={dataCart} 
                            />
                        }
                    </>
                }
            />
        :
            <>
                <LayoutTable
                    data={dataCart}
                    hideFilterBar={true}
                    headersButtons={false}
                    hideActions={true}
                    withCard={false}
                    listFormat={true}
                    FilterBarPlaceholder= "Buscar"
                />
                {/* DETAILS OF PRICES */}
                <DetailsPrices 
                    orderTotals={
                        !customTotalCart ? 
                        {
                            total_products: totalCart,
                            total_shipping: 0,
                            total: totalCart
                        }
                        :
                        customTotalCart
                    } 
                />
            </>
    )
}
export default DetailsProducts