import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { GET_TRANSFER_REQUEST } from '../../../graphql/Catalog/Transfers/Transfers'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import {
  document_statuses_esp,
  transfer_filter_by_status,
  // status_transfers,
} from '../../Helpers/StatusesLanguages'
import { GET_DOCUMENT_STATUS } from '../../../graphql/Documents/Status/status'
import DatesOptions from '../../Global/DatesOptions'
import StatusBox from '../../Global/StatusBox/StatusBox'

export const TransfersValidation = () => {
  /**Get all status enable */
  // const {
  //   data: dataStatus,
  //   loading: loadingStatus,
  //   error: errorStatus,
  // } = useQuery(GET_DOCUMENT_STATUS)
  // const [allStatus, setAllStatus] = useState(null)
  const [statusOptions, setStatusOptions] = useState([])

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_TRANSFER_REQUEST, {
    variables: {
      onlyWhs: null,
      stageStatus: 3,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      searchQuery: pagePagination.searchQuery,
      type: pagePagination.type,
      date: pagePagination.date,
      validation: true,
    },
    fetchPolicy: 'no-cache',
  })

  const [dataToTable, setDataToTable] = useState([])
  // const transfersStatus = status_transfers
  const statusDictionary = document_statuses_esp

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []

      data.getAllTransferRequest.rows.map((transfer, index) => {
        let newTransfer = transfer
        let percentage = 0
        let totalBoxLines = 0
        let counter = 0
        let virtualCounter = 0
        let flagFoundBottles = false
        const newPackingListBoxes = []

        if (newTransfer.PackingList !== null) {
          transfer.PackingList.map((packingListBoxes) => {
            // console.log(
            //   packingListBoxes.PackingListBoxes,
            //   'PACKING LIST BOXES PROPERTY'
            // )
            const activePackingList = packingListBoxes.PackingListBoxes.filter(
              (item) => item.is_active == true && item.validated == true
            )
            // newPackingListBoxes.push(...packingListBoxes.PackingListBoxes)
            newPackingListBoxes.push(...activePackingList)

            return null
          })

          newTransfer.PackingList.PackingListBoxes = newPackingListBoxes

          // transfer.PackingList.PackingListBoxes.map((box) => {
          newPackingListBoxes.map((box) => {
            // console.log(box.is_active, 'BOXES ACTIVAS')
            if (!box.is_virtual) {
              counter += box.validated ? 1 : 0
            }

            if (box.is_virtual) {
              let internalCounter = 0
              flagFoundBottles = true
              box.PackingListBoxesLines.map((product) => {
                internalCounter += product.validated ? 1 : 0
                return null
              })
              totalBoxLines = box.PackingListBoxesLines.length
              counter += internalCounter
              virtualCounter = internalCounter
            }
            return null
          })

          const dividend = flagFoundBottles
            ? transfer.PackingList.PackingListBoxes.length + totalBoxLines
            : transfer.PackingList.PackingListBoxes.length

          percentage =
            (counter / (dividend - (flagFoundBottles ? virtualCounter : 0))) *
            100

          // console.log('+++++++++++++++ ', transfer.id)
          // console.log(counter, 'conteo')
          // console.log(dividend, 'dividendo')
          // console.log(
          //   dividend - (flagFoundBottles ? virtualCounter : 0),
          //   'dividendo resta'
          // )
          // console.log(percentage, 'porcentaje')
          // console.table(transfer, 'item')
          // console.log('+++++++++++++++')
        }

        const user = transfer?.PackingList[0]?.UserValidate
        // let btnCheck = (
        //   <a href="/" target="_blank" download>
        //     <i className="fa fa-file-pdf"></i> Entrada de mercancia
        //     </a >
        // )
        // if(item.sap_purchases_orders.goods_receipt_pdf_id > 0){
        //    btnCheck = (
        //     <a href={item.sap_purchases_orders.purchase_order_pdf.url} target="_blank" download>
        //       <i className="fa fa-file-pdf"></i> Entrada de mercancia
        //       </a >
        //   )
        // }
        return list.push({
          id: transfer.id,
          'Doc Num': transfer.doc_num ? transfer.doc_num : 'Sin número',
          'Doc Entry': transfer?.doc_entry ? transfer?.doc_entry : 'Sin número',
          Recibido: moment(transfer.doc_date).format('DD/MM/YYYY'),
          // Vencimiento: moment(transfer.doc_due_date).format('DD/MMMM/YYYY'),
          Estatus: <StatusBox mode={transfer.status} />,
          //Ubicación: transfersStatus[transfer.stage_status],
          'Origen-Destino': `${transfer?.warehouse_origin_name?.warehouse_name} - ${transfer?.warehouse_destiny_name?.warehouse_name}`,
          // Destino: transfer?.warehouse_destiny_name?.warehouse_name,
          'Validado por': user
            ? `${user?.name} ${user?.first_name} ${user?.last_name}`
            : 'Desconocido',
          Progreso:
            newPackingListBoxes.length > 0 || flagFoundBottles ? percentage : 0,
        })
      })
      setDataToTable(list)
      setTotal(data.getAllTransferRequest.count)
    }
    // eslint-disable-next-liconsole.log(e)ne react-hooks/exhaustive-deps
  }, [loading, error, data, total, pagePagination])

  // useEffect(() => {
  //   if (!loadingStatus) {
  //     if (errorStatus)
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: error.message,
  //       })

  //     /**Set data to hook */
  //     let list = []
  //     dataStatus.getDocumentStatus.map((item) => {
  //       list.push({
  //         id: item.id,
  //         name: document_statuses_esp[item.id]
  //           ? document_statuses_esp[item.id]
  //           : '',
  //       })
  //     })
  //     setAllStatus(list)
  //   }
  // }, [dataStatus, loadingStatus, errorStatus])

  useEffect(() => {
    const options = []
    for (const key in transfer_filter_by_status) {
      options.push(
        <option value={key} key={key}>
          {transfer_filter_by_status[key]}{' '}
        </option>
      )
      setStatusOptions(options)
    }
  }, [transfer_filter_by_status])

  const filterOnChange = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      type: parseInt(filterSelected),
    })
  }

  const filterOnChangeSecond = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  return (
    <>
      {/* <ContentHeader
        title="Órdenes de traspasos"
        breadcrumb="Traspasos validación"
        windowTitle="Traspasos"
      /> */}

      <LayoutTable
        data={dataToTable}
        title="Órdenes de traspasos"
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        // hideFilterBar

        FilterBarPlaceholder="Buscar por Origen o Destino"
        /** First filter */
        /* filter
        filterOnChange={filterOnChange}
        filterOptions={[
          allStatus
            ? allStatus.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                )
              })
            : null,
        ]}
        filterLabelText="Por estatus" */
        /** Second filter */
        /* filterSecond
        filterOnChangeSecond={filterOnChangeSecond}
        filterOptionsSecond={[<DatesOptions daysBefore={true} />]}
        filterLabelTextSecond="Por fecha" */
        filter
        filterOnChange={filterOnChangeSecond}
        filterOptions={[<DatesOptions daysBefore={true} />]}
        filterLabelText="Por fecha"
        filterLabelTextSecond="Por status"
        filterOptionsSecond={statusOptions}
        filterSecond={true}
        filterOnChangeSecond={filterOnChange}
        minCharSearching={true}
        minCharacterSearch={4}
      />
    </>
  )
}

export default TransfersValidation
