import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GET_ALL_STORES } from '../../../graphql/Catalog/Addresses/addressesStores'
import LayoutTable from '../../Global/LayoutTable'
import ContentHeader from '../../Layout/ContentHeader'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

export const AddressStores = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_ALL_STORES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })
  const [dataToTable, setDataToTable] = useState([])

  useEffect(() => {
    if (!loading) {
      if (error)
        toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getAllAddressStores.rows.map((item) => {
        return list.push({
          id: item.id,
          Nombre: item.name,
          Dirección: item.addressStore
            ? `${item.addressStore.street} ${item.addressStore.external_number}`
            : '-',
          Ciudad: item.addressStore ? item.addressStore.city.name : '-',
          Estado: item.addressStore ? item.addressStore.state.name : '-',
          Municipio: item.addressStore
            ? item.addressStore.municipality.name
            : '-',
        })
      })
      setDataToTable(list)
      setTotal(data.getAllAddressStores.count)
    }
  }, [loading, error, data])

  return (
    <>
      {/* <ContentHeader
        title="Tiendas"
        breadcrumb="Tiendas"
        windowTitle="Tiendas"
      /> */}
      <LayoutTable
        hideId
        data={dataToTable}
        title="Tiendas"
        requery={GET_ALL_STORES}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        titleActions=""
        FilterBarPlaceholder="Buscar por Nombre"
      />
    </>
  )
}

export default AddressStores
