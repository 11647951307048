import React, { useState } from 'react'
import { useEffect } from 'react'
import DownloadReports from '../../../Global/DownloadReports/DownloadReports'
import LayoutTable from '../../../Global/LayoutTable'
import ContentHeader from '../../../Layout/ContentHeader'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//DATA FAKE
import { useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { GET_DISPLACEMENTS_AND_INVENTORY_REPORT } from '../../../../graphql/Catalog/Reports/reports'
import GenerateReport from '../../../ProvidersDashboard/Reports/components/GenerateReport'

const MySwal = withReactContent(Swal)

const InventoryReport = () => {
  const [pagePagination, setPagePagination] = useState({
    limit: 10,
    offset: 0,
    searchQuery: null,
    filter: 1, //Always monthly
  })

  //------- Hooks ---------
  const {
    loading: loading,
    data: data,
    error: error,
  } = useQuery(GET_DISPLACEMENTS_AND_INVENTORY_REPORT, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      filter: pagePagination.filter, //Monthly,
      type: 2, //Inventory
    },
  })

  const [total, setTotal] = useState(0)

  //Hook to save data to show using datatable
  const [inventory, setInventory] = useState([])

  //Hook to save reports date using ContentHeader component
  const [reportDates, setReportDates] = useState({
    start: '',
    end: '',
  })

  //Hook to save options filters for reports
  const [selectOptions, setSelectOptions] = useState([])
  const [hideDetails, setHideDetails] = useState(false)

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      // //QUERY HERE
      const list = data?.getDisplacementsAndInventoryReports?.rows.map(
        (item) => {
          const { id, item_code, item_name, obj } = item

          const newObj = JSON.parse(obj)

          return {
            id,
            'COD SAP': item_code,
            DESCRIPCIÓN: item_name,
            ...newObj,
          }
        }
      )

      setInventory(list ? list : [])
      setTotal(
        data?.getDisplacementsAndInventoryReports?.count
          ? data?.getDisplacementsAndInventoryReports?.count
          : 0
      )
      setSelectOptions(
        JSON.parse(data.getDisplacementsAndInventoryReports.filterOptions)
      )
      setHideDetails(
        !data.getDisplacementsAndInventoryReports.haveDetailsPermission
      )
    }
  }, [loading, data, error])

  //Handle to save reports dates
  const handleChangeDates = (e, type) => {
    setReportDates({
      ...reportDates,
      [type]: e.target.value,
    })
  }

  //Handle to download report
  const handleReport = () => {
    //Download report
    return MySwal.fire({
      showCloseButton: true,
      showConfirmButton: true,
      showDenyButton: true,
      title: 'Descargar reporte',
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterRejectOrder',
      },
      confirmButtonText: 'Descargar',
      denyButtonText: 'Cancelar',
      focusConfirm: false,
      buttonsStyling: false,
      html: (
        <>
          <DownloadReports />
        </>
      ),
    }).then((result) => {
      //DETAILS CLICKED
      if (result.isConfirmed) return console.log('Confirm')
      if (result.isDenied) return console.log('Denied')
    })
  }

  const handleDates = (e) => {
    if (parseInt(e.target.value)) {
      setPagePagination({
        ...pagePagination,
        filter: parseInt(e.target.value),
      })
    }
  }

  return (
    <>
      {/* <ContentHeader
                title="Inventarios"
                reportFilter
                reportFilterHandleButton={handleReport}
                handleChangeDates={handleChangeDates}
            /> */}
      <GenerateReport title="" dataType={2} />
      <LayoutTable
        data={inventory}
        title="Inventarios"
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        // hideFilterBar
        FilterBarPlaceholder="Buscar por Codigo SAP o Descripción."
        hideId
        hideBtnErase
        hideBtnEdit
        hideAddNew
        exportImportButton={false}
        selectOptions={selectOptions}
        handleSelectOptions={handleDates}
        hideActions={hideDetails}
      />
    </>
  )
}
export default InventoryReport
