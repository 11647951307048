import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'
import ContentHeader from '../../../Layout/ContentHeader'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import OrderCol from '../../../Global/OrderCol'
import { useState } from 'react'
import { useEffect } from 'react'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'
import { useLazyQuery } from '@apollo/client'
import { VALIDATE_BILL_BY_UUID } from '../../../../graphql/Catalog/Notes/bills'
import moment from 'moment'

export const Validate = ({ billCaptured }) => {
  //expresiones regualres
  const regexUUID = new RegExp('id(¡|¿|=)[^/ | &]+', 'i')
  const regexEmitter = new RegExp('re(¡|¿|=)([^/]+)(/|&|$)', 'i')
  // const regexEmitter = new RegExp('re(¡|¿|=)([^/]+/[^/|&]+)', 'i')
  // const regexEmitter = new RegExp('re(¡|¿|=)([^/|&]+)', 'i')
  // const regexEmitter = new RegExp('re(¡|¿|=)([^/|&]+)(/|&|$)', 'i')
  // const regexEmitter = new RegExp('re(¡|¿|=)[^/ | &]+', 'i')
  const regexReceiver = new RegExp('rr(¡|¿|=)[^/ | &]+', 'i')
  const regexTotal = new RegExp('tt(¡|¿|=)[^/ | &]+', 'i')
  // console.log(billCaptured)
  const [idBill, setIdBill] = useState(null)
  // const [valueUUID, setValueUUID] = useState(null)
  const [valueBillCaptured, setValueBillCaptured] = useState(null)
  const [ValueReceiver, setValueReceiver] = useState(null)
  const [infoBillUUID, setInfoBillUUID] = useState(null)

  const validationSchema = yup.object().shape({
    factura: yup.string(),
  })

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const [getBillValidate] = useLazyQuery(VALIDATE_BILL_BY_UUID)

  useEffect(() => {
    if (infoBillUUID) {
      validateBill()
    }
  }, [infoBillUUID])

  useEffect(() => {
    if (billCaptured) {
      setValueBillCaptured(billCaptured)
    }
  }, [billCaptured])

  useEffect(() => {
    if (valueBillCaptured) {
      handleREGEX(valueBillCaptured)
    }
  }, [valueBillCaptured])

  useEffect(() => {
    if (idBill) {
      verifyBill()
      setIdBill(null)
    }
  }, [idBill, infoBillUUID])

  // FUNCTIONS
  const handleREGEX = (id_bill) => {
    if (id_bill === '') return
    setIdBill(id_bill)
  }

  const verifyBill = async () => {
    try {
      // setValueUUID(null)

      const { uuid, emitter, receiver, total } = extractValuesFromBillCaptured()

      const invoice = await getBillValidate({
        variables: {
          uuid: uuid,
        },
        fetchPolicy: 'no-cache',
      })

      // console.log('invoice', invoice)

      if (!invoice?.data.validateBillByUUID) {
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message: `La factura del proveedor ${emitter}, con la cantidad ${formmatterCurrency(
              parseFloat(total)
            )}, con UUID ${uuid}\n. No se encuentra registrada en el sistema`,
            timer: 5000,
          },
          setIdBill(null),
          setValue('scan_bill', ''),
          setValueBillCaptured(null),
          setInfoBillUUID(null)
        )
      }

      setInfoBillUUID(invoice?.data)
      setValueReceiver(receiver)
    } catch (e) {
      console.log('error', e)
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'Error al leer',
        },
        // setValueUUID(null),
        setValue('scan_bill', '')
      )
    }
  }

  const validateBill = () => {
    if (!infoBillUUID) return

    if (infoBillUUID?.validateBillByUUID?.full_payed)
      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: 'Esta factura se encuentra registrada y pagada',
        },
        setIdBill(null),
        setValue('scan_bill', '')
      )

    if (infoBillUUID?.validateBillByUUID?.full_payed === false)
      return toastSweetAlertNotifications(
        {
          mode: 'info',
          message:
            'La factura se encuentra registrada, pero con pagos pendientes',
        },
        setIdBill(null),
        setValue('scan_bill', '')
      )
  }

  const extractValuesFromBillCaptured = () => {
    const upperIDBill = idBill.toUpperCase()
    // upperIDBill.replace(/HTTPS:\/\//, '')
    let replaceBefore = upperIDBill.replace(/HTTPS:\/\//, '')
    let stringFormat
    if (replaceBefore.includes('/') || replaceBefore.includes('&')) {
      stringFormat = replaceBefore
        .replace(/HTTPSÑ/, '')
        // .replace(/RE(¡|¿|=|&|)/, '/RE¿')
        // .replace(/RR(¡|¿|=|&|)/, '/RR¿')
        // .replace(/ID(¡|¿|=|&|)/, '/ID¿')
        // .replace(/TT(¡|¿|=|&|)/, '/TT¿')
        .replace(/[\/&_]RE(¡|¿|=|&|)/, '/RE¿')
        .replace(/[\/&_]RR(¡|¿|=|&|)/, '/RR¿')
        .replace(/[\/&_]ID(¡|¿|=|&|)/, '/ID¿')
        .replace(/[\/&_]TT(¡|¿|=|&|)/, '/TT¿')
    } else {
      console.log('No contiene / o &')
      stringFormat = replaceBefore
        .replace(/HTTPSÑ/, '')
        .replace(/RE(¡|¿|=|&|)/, '/RE¿')
        .replace(/RR(¡|¿|=|&|)/, '/RR¿')
        .replace(/ID(¡|¿|=|&|)/, '/ID¿')
        .replace(/TT(¡|¿|=|&|)/, '/TT¿')
    }

    const UUIDExec = regexUUID.exec(stringFormat)
    let uuid = UUIDExec[0].replace(/ID(¡|¿|=|&|)/, '').replace(/('|-)/g, '')
    let includesCharacters = uuid.includes('_')
    if (includesCharacters) {
      const splitUUID = uuid.split('_')
      console.log('splitUUID', splitUUID)
      uuid = splitUUID[0]
    }
    const receiverExec = regexReceiver.exec(stringFormat)
    const receiver = receiverExec[0].replace(/RR(¡|¿|=|&|)/, '')

    let emitter = ''
    const emitterExec = regexEmitter.exec(stringFormat)
    if (emitterExec && emitterExec[2]) {
      emitter = emitterExec[2]
      // const emitter = emitterExec[0].replace(/RE(¡|¿|=|&|)/, '')
    }

    const totalExec = regexTotal.exec(stringFormat)
    const total = totalExec[0].replace(/TT(¡|¿|=|&|)/, '').replace(/,/g, '')

    return {
      uuid,
      emitter,
      receiver,
      total,
    }
  }

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  return (
    <>
      <ContentHeader
        title="Validar Factura"
        windowTitle="Validar Factura"
        breadcrumb="Validar Factura"
      />
      <Box
        // title="Carga de facturas"
        content={
          <>
            <div className="row">
              <div className=" mb-3 col-lg-3">
                <InputController
                  label="Escanear Factura"
                  name="scan_bill"
                  // valueManual={
                  //   infoBillUUID?.validateBillByUUID?.partnerBills
                  //     ?.lic_trad_number
                  // }
                  // valueManual={billCaptured ? billCaptured : ''}
                  changeAction={(e) => handleREGEX(e.target.value)}
                  control={control}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <OrderCol
                  title="RFC Vinos América"
                  content={ValueReceiver === null ? '---' : ValueReceiver}
                  mediumColSize="4"
                />
                <OrderCol
                  title="RFC Proveedor"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : infoBillUUID?.validateBillByUUID?.notes?.partnerNotes
                          ?.provider_rfc
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="Razón Social"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : infoBillUUID?.validateBillByUUID?.notes?.partnerNotes
                          ?.provider_name
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="Monto"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : formmatterCurrency(
                          infoBillUUID?.validateBillByUUID?.amount_bill
                        )
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="ID Factura"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : infoBillUUID?.validateBillByUUID?.id_bill
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="Folio Factura"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : infoBillUUID?.validateBillByUUID?.invoice_bill
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="Fecha Factura"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : moment(
                          infoBillUUID?.validateBillByUUID?.date_bill,
                          'YYYY-MM-DD'
                        ).format('DD/MM/YYYY')
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="Consecutivo"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : infoBillUUID?.validateBillByUUID?.notes
                          ?.consecutive_number
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="Tienda"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : infoBillUUID?.validateBillByUUID?.notes?.storesRegister
                          ?.alias
                  }
                  mediumColSize="4"
                />
                <OrderCol
                  title="Pago"
                  content={
                    infoBillUUID === null
                      ? '---'
                      : formmatterCurrency(
                          infoBillUUID?.validateBillByUUID?.pay_bill
                        )
                  }
                  mediumColSize="4"
                />
                {infoBillUUID?.validateBillByUUID?.notes?.payNoteChecks[0]
                  ?.reference_pay && (
                  <OrderCol
                    title="Referencia"
                    content={
                      infoBillUUID === null
                        ? '---'
                        : infoBillUUID?.validateBillByUUID?.notes
                            ?.payNoteChecks[0]?.reference_pay
                    }
                    mediumColSize="4"
                  />
                )}
                {infoBillUUID?.validateBillByUUID?.notes?.payNoteChecks[0]
                  ?.invoice_number_payments
                  ? infoBillUUID?.validateBillByUUID?.notes?.payNoteChecks[0]
                      ?.invoice_number_payments && (
                      <OrderCol
                        title="Folio de pago"
                        content={
                          infoBillUUID === null
                            ? '---'
                            : infoBillUUID?.validateBillByUUID?.notes
                                ?.payNoteChecks[0]?.invoice_number_payments
                        }
                        mediumColSize="4"
                      />
                    )
                  : infoBillUUID?.validateBillByUUID
                      ?.billsToBillsPaymentsFinances[0]
                      ?.billsPaymentsToPaymentsFinances[0]
                      ?.consecutive_number_of_payments && (
                      <OrderCol
                        title="Folio de pago"
                        content={
                          infoBillUUID === null
                            ? '---'
                            : infoBillUUID?.validateBillByUUID
                                ?.billsToBillsPaymentsFinances[0]
                                ?.billsPaymentsToPaymentsFinances[0]
                                ?.consecutive_number_of_payments
                        }
                      />
                    )}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default Validate
