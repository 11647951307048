import CardButton from "../../../Global/CardButton/CardButton"
import ContentHeader from "../../../Layout/ContentHeader"


const path = "/catalog/notes/"

export const Notes = () => {
  return (
    <>
      <ContentHeader
        title="Inicio"
        breadcrumb="Notas"
        windowTitle="Notas"
      />  
      
    <div className="row">
      <div className="col-md-6 col-xl-4">
        <CardButton 
          title="Nueva Nota"
          icon="fas fa-clipboard"
          link={`${path}newnote`}
      />
      </div> 
      <div className=" col-md-6 col-xl-4">
        <CardButton 
          title="Consultar Notas"
          icon="far fa-sticky-note"
          link={`${path}notes`}
         />
      </div>
      <div className="col-md-6 col-xl-4">
      <CardButton 
        title="Nota Manual"
        icon="fas fa-pen-square"
        link={`${path}manualnote`}
      />
      </div>
      <div className="col-md-6 col-xl-4 ">
      <CardButton 
        title="Validar Factura"
        icon="fas fa-clipboard-check"
        link={`${path}validate`}
      />
      </div>
      <div className=" col-md-6 col-xl-4">
      <CardButton 
        title="Generar Pagos"
        icon="fas fa-money-check-alt"
        link={`${path}genpayments`}
      />
      </div>
    </div>
    </>
  )
}


export default Notes