import { gql } from '@apollo/client'

export const GET_ALL_INVENTORY_ACTIVITIES_RACKS_ACTIVES = gql`
  query GetAllInventoryActivitiesRacksActives(
    $limit: Int
    $offset: Int
    $searchQuery: String
    $warehouseId: Int
  ) {
    GetAllInventoryActivitiesRacksActives(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      warehouseId: $warehouseId
    ) {
      rows {
        id
        code
        name
      }
      count
    }
  }
`

export const WAREHOUSE_IS_STORE = gql`
  mutation WarehouseIsStore($warehouseId: Int!) {
    warehouseIsStore(warehouse_id: $warehouseId)
  }
`

export const EXPORT_INVENTORY_ACTIVITIES_LABEL = gql`
  mutation getAllInventoryActivitiesLabelExport(
    $warehouseId: Int
    $scheduleId: Int
  ) {
    getAllInventoryActivitiesLabelExport(
      warehouseId: $warehouseId
      scheduleId: $scheduleId
    ) {
      id
      code
      name
      capacity
      warehouse_id
      reorder
      zone_id
      in_use
      circuit
    }
  }
`

export const IMPORT_INVENTORY_ACTIVITIES_LABEL = gql`
  mutation InventoryActivitiesLabelImportFileLabel(
    $files: [Upload!]!
    $warehouseId: Int
    $scheduleId: Int
    $importMode: Int
  ) {
    InventoryActivitiesLabelImportFileLabel(
      files: $files
      warehouseId: $warehouseId
      scheduleId: $scheduleId
      importMode: $importMode
    )
  }
`

export const GET_ALL_WAREHOUSES = gql`
  query getAllAddressWarehouses {
    getAllAddressWarehouses {
      rows {
        id
        warehouse_name
      }
      count
    }
  }
`

export const GET_ALL_INVENTORY_ACTIVITIES_RACKS_AVAILABLE = gql`
  query getAllInventoryActivitiesRacksAvailable(
    $limit: Int
    $offset: Int
    $searchQuery: String
    $scheduleId: Int
    $warehouseId: Int
    $count: Int
    $excludeIds: [Int]
  ) {
    getAllInventoryActivitiesRacksAvailable(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      scheduleId: $scheduleId
      warehouseId: $warehouseId
      count: $count
      excludeIds: $excludeIds
    ) {
      rows {
        code
        name
        id
        SapWarehouse {
          warehouse_name
          warehouse_code
        }
      }
      count
    }
  }
`

export const GET_ALL_INVENTORY_ACTIVITIES_ASSIGNMENTS_TO_EXCLUDE = gql`
  mutation getAllInventoryActivitiesAssignmentsToExclude(
    $searchQuery: String
    $warehouseId: Int
    $excludeIds: [Int]
    $count: Int
    $scheduleId: Int!
  ) {
    getAllInventoryActivitiesAssignmentsToExclude(
      searchQuery: $searchQuery
      warehouseId: $warehouseId
      excludeIds: $excludeIds
      count: $count
      scheduleId: $scheduleId
    ) {
      code
      name
      id
      SapWarehouse {
        warehouse_name
      }
    }
  }
`

export const GET_ALL_INVENTORY_ACTIVITIES_SAP_ITEMS = gql`
  query GetAllInventoryActivitiesSapItems(
    $limit: Int
    $offset: Int
    $scheduleId: Int
    $searchQuery: String
    $count: Int
    $excludeSkus: [String]
  ) {
    getAllInventoryActivitiesSapItems(
      limit: $limit
      offset: $offset
      scheduleId: $scheduleId
      searchQuery: $searchQuery
      count: $count
      excludeSkus: $excludeSkus
    ) {
      count
      rows {
        id
        item_code
        SapStocksToSapItems {
          item_name
          item_code
        }
      }
    }
  }
`

// export const GET_ALL_LABELS = gql`
//   query GetAllTfiLabel($searchQuery: String, $limit: Int, $offset: Int) {
//     getAllTfiLabel(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
//       rows {
//         id
//         id_sap_warehause
//         id_rack
//         name
//         code
//         first_count
//         second_count
//         assigned_first_count
//         assigned_second_count
//         closed
//         is_active
//         Warehouse {
//           warehouse_code
//           warehouse_name
//         }
//       }
//       count
//     }
//   }
// `

// export const GET_LABELS_BY_COUNT = gql`
//   query getAllTfiLabelByCount($count: Int!) {
//     getAllTfiLabelByCount(count: $count) {
//       rows {
//         id
//         name
//         first_count
//         second_count
//         assigned_first_count
//       }
//     }
//   }
// `

// export const GET_ONE_LABEL = gql`
//   query getOneTfiLabel($id: Int!) {
//     getOneTfiLabel(id: $id) {
//       id
//       id_sap_warehause
//       id_rack
//       name
//       code
//       first_count
//       second_count
//       assigned_first_count
//       assigned_second_count
//       closed
//       is_active
//     }
//   }
// `

// export const CREATE_NEW_LABEL = gql`
//   mutation createTfiLabel($input: TfiLabelInput!) {
//     createTfiLabel(input: $input)
//   }
// `

// export const UPDATE_LABEL = gql`
//   mutation updateTfiLabel($id: Int!, $input: TfiLabelInput!) {
//     updateTfiLabel(id: $id, input: $input)
//   }
// `

// export const DELETE_LABEL = gql`
//   mutation deleteTfiLabel($id: Int!) {
//     deleteTfiLabel(id: $id)
//   }
// `

// export const GET_RACK_FOR_TFI = gql`
//   query GetRackForTFI($searchValue: String!) {
//     getRackForTFI(searchValue: $searchValue) {
//       id
//       name
//       code
//       warehouse_id
//     }
//   }
// `
