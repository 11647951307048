import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useContext, useRef, useState } from 'react'
import { useEffect } from 'react'
import Box from '../../../../Global/Box'
import { useHistory, useParams } from 'react-router-dom'
import {
  GET_ONE_NOTE,
  MOVE_NOTE_CONTABILITY_TO_FINANCES,
} from '../../../../../graphql/Catalog/Notes/notes'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'
import LayoutTable from '../../../../Global/LayoutTable'
import ContentHeader from '../../../../Layout/ContentHeader'
import PaymentsHistory from '../DELETE/PaymentsHistory/PaymentsHistory'
// import PaymentsHistoryBills from '../PaymentsHistory/PaymentsHistoryNew'
import { GET_BILLS } from '../../../../../graphql/Catalog/Notes/bills'
import PaymentsHistoryNew from '../DELETE/PaymentsHistory/PaymentsHistoryNew'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import PrintFirstTicket from '../PrintTicketsNotes/PrintFirstTicket'
import ReactToPrint from 'react-to-print'
import {
  DELETE_PAYMENTS_CHECK,
  GET_ALL_CHECKS_NOTE_BY_ID,
  GET_ALL_PAYMENTS_NOTE_BY_INVOICE_NUMBER_PAYMENTS,
} from '../../../../../graphql/Catalog/Notes/payments'
import { AuthContext } from '../../../../../Auth/AuthContext'

export const ShowDetailsNotes = ({}) => {
  const { user } = useContext(AuthContext)
  const { id: _id } = useParams()
  const history = useHistory()
  const componentRef = useRef()
  const [dataBillTable, setDataBillTable] = useState([])
  const [step, setStep] = useState(1)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [idNote, setIdNote] = useState(false)
  const [idCheck, setIdCheck] = useState(false)
  const [infoNote, setInfoNote] = useState([])
  const [infoBill, setInfoBill] = useState([])
  const [total, setTotal] = useState()
  const [activateButtonsHidden, setActivateButtonsHidden] = useState(false)
  const [valueSecondTicket, setValueSecondTicket] = useState(false)
  const [numId, setNumId] = useState(null)
  const [styles, setStyles] = useState(null)
  const [activateToggle, setActivateToggle] = useState(false)
  const [consecutives, setConsecutives] = useState(null)
  const [paymentsChecks, setPaymentsChecks] = useState([])
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    idNote: parseInt(_id),
  })

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const {
    loading: loadingAllBillsByIdNote,
    error: errorAllBillsByIdNote,
    data: dataAllBillsByIdNote,
  } = useQuery(GET_BILLS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      idNote: pagePagination.idNote,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingGetOneNote,
    error: errorGetOneNote,
    data: dataGetOneNote,
    refetch: refetchGetOneNote,
  } = useQuery(GET_ONE_NOTE, {
    variables: {
      getOneNoteId: parseInt(_id),
    },
    fetchPolicy: 'no-cache',
  })

  const [getAllPaymentsNoteByInvoiceNumberPayments] = useLazyQuery(
    GET_ALL_PAYMENTS_NOTE_BY_INVOICE_NUMBER_PAYMENTS
  )

  const [deletePayment] = useMutation(DELETE_PAYMENTS_CHECK)

  const validationForm = yup.object().shape({
    invoice_input: yup.string(),
  })

  const { methods, control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationForm),
  })

  const stepNext = () => {
    setStep(step + 1)
  }

  const stepPrevious = () => {
    setStep(step - 1)
  }

  const handleStepHistory = () => {
    setIdNote(parseInt(_id))
    stepNext()
  }

  const handleToBillsByIdCheck = (id) => {
    setIdCheck(id)
    stepNext()
  }

  const handleBillsHistoryPrevious = () => {
    stepPrevious()
  }

  useEffect(() => {
    if (!loadingGetOneNote) {
      if (errorGetOneNote)
        return toastSweetAlert({
          mode: 'error',
          message: errorGetOneNote.message,
        })

      let list = []
      const dataBills = dataGetOneNote?.getOneNote?.bills
      const partner = dataBills.map((item) => item.id_partner)[0]

      partner !== 0
        ? dataBills.map((item) => {
            list.push({
              id: item.id,
              Factura: item.invoice_bill,
              fecha: moment(item.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              monto: formmatterCurrency(item.amount_bill),
              'monto pagado': formmatterCurrency(item.pay_bill),
              estatus: item.full_payed ? 'Pagada' : 'No pagada',
            })
          })
        : dataBills.map((item) => {
            list.push({
              id: item.id,
              concepto: item?.conceptBills?.name,
              beneficiario: item.provider_name_nm,
              Factura: item.invoice_bill,
              fecha: moment(item.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              monto: formmatterCurrency(item.amount_bill),
              descripcion: item.description,
              'monto pagado': formmatterCurrency(item.pay_bill),
              estatus: item.full_payed ? 'Pagada' : 'No pagada',
            })
          })

      setInfoNote(dataGetOneNote?.getOneNote)
      handleGetPaymentsChecks(dataGetOneNote?.getOneNote)
      setDataBillTable(list)
      setInfoBill(list)
      setTotal(list.length)
      // console.log(dataGetOneNote?.getOneNote)
    }
  }, [loadingGetOneNote, errorGetOneNote, dataGetOneNote, refetchGetOneNote])

  // useEffect(() => {
  //   if (!loadingAllBillsByIdNote) {
  //     if (errorAllBillsByIdNote)
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: errorAllBillsByIdNote.message,
  //       })
  //     let list = []
  //     const partner = dataAllBillsByIdNote?.getBills.rows.map(
  //       (item) => item.id_partner
  //     )[0]
  //     // console.log(partner)
  //     partner !== 0
  //       ? dataAllBillsByIdNote.getBills.rows.map((item) => {
  //           list.push({
  //             id: item.id,
  //             Factura: item.invoice_bill,
  //             fecha: moment(item.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY'),
  //             monto: formmatterCurrency(item.amount_bill),
  //             'monto pagado': formmatterCurrency(item.pay_bill),
  //             estatus: item.full_payed ? 'Pagada' : 'No pagada',
  //           })
  //         })
  //       : dataAllBillsByIdNote.getBills.rows.map((item) => {
  //           list.push({
  //             id: item.id,
  //             concepto: item?.conceptBills?.name,
  //             beneficiario: item.provider_name_nm,
  //             Factura: item.invoice_bill,
  //             fecha: moment(item.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY'),
  //             monto: formmatterCurrency(item.amount_bill),
  //             descripcion: item.description,
  //             'monto pagado': formmatterCurrency(item.pay_bill),
  //             estatus: item.full_payed ? 'Pagada' : 'No pagada',
  //           })
  //         })
  //     setDataBillTable(list)
  //     setInfoBill(list)
  //     setTotal(dataAllBillsByIdNote.getBills.count)
  //   }
  // }, [loadingAllBillsByIdNote, errorAllBillsByIdNote, dataAllBillsByIdNote])

  const handleGetPaymentsChecks = (data) => {
    let list = []
    // console.log('data', data)
    if (data?.payNoteChecks.length > 0) {
      for (let payment of data?.payNoteChecks) {
        let isActive = payment?.is_active
        if (isActive) {
          list.push(payment)
        }
        // console.log('payment', payment)
      }
      setPaymentsChecks(list)
    }
  }

  // console.log('paymentsChecks', paymentsChecks)

  const handleToogleNotes = async (invoiceNumberPayment, checkNumber) => {
    try {
      setActivateToggle(!activateToggle)
      const { data } = await getAllPaymentsNoteByInvoiceNumberPayments({
        variables: {
          invoiceNumber: invoiceNumberPayment,
          checkNumber,
        },
        fetchPolicy: 'no-cache',
      })
      // console.log(data?.getAllPaymentsNoteByInvoiceNumberPayments)
      setConsecutives(data?.getAllPaymentsNoteByInvoiceNumberPayments)
    } catch (e) {
      console.log(e)
    }
  }

  const deletePaymentCheck = async (id) => {
    try {
      // console.log('id', id)
      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message: '¿Estas seguro de eliminar este pago?',
        title: 'Eliminar pago',
        icon: 'warning',
      })

      if (result.isConfirmed) {
        await deletePayment({
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        toastSweetAlertNotifications({
          mode: 'ok',
          message: 'Pago eliminado correctamente',
        })
      }
      await refetchGetOneNote()
    } catch (e) {
      console.log(e)
    }
  }

  const buttonGeneric = (numButtons) => {
    let buttons = []
    for (let i = 0; i < numButtons; i++) {
      buttons.push(
        <button
          key={i}
          className="btn btn-primary"
          onClick={() => {
            handlePrintTickets(i + 1)
          }}
        >
          {i + 1} Ticket
        </button>
      )
    }
    return buttons
  }

  const handlePrintTickets = (num) => {
    // console.log('dataInfo', infoNote)
    setNumId(num)
    handleStylesForPrint(num)
  }

  const handleStylesForPrint = (num) => {
    let styleSelect = ''
    switch (num) {
      case 1:
        styleSelect = `@page { size: 120mm auto; margin: 0mm; }`
        break
      case 2:
        styleSelect = `'@page { size: 210mm 297mm; margin: 0mm; } @media print { html, body { width: 210mm; height: 297mm; } }`
        break
      case 3:
        styleSelect = `@page { size: 80mm 80mm; @media print { html, body {width: 80mm; height: 80mm}}; }`
        break
      case 4:
        styleSelect = `@page { size: 120mm auto; margin: 0mm; @media print { html, body {width: 120mm; height: auto}}; }`
        break
      case 5:
        styleSelect = `@page { size: A4;}`
        break
      case 6:
        styleSelect = `@page { size: A4; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 50mm; }`
        break
      case 7:
        styleSelect = `@page { size: A4; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 80mm; }`
        break
      case 8:
        styleSelect = `@page { size: 80mm auto; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 40mm; }`
        break
      case 9:
        styleSelect = `@page { size: 80mm auto; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 80mm; }`
        break
      case 10:
        styleSelect = `@page { size: 80mm 80mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right:80mm; @media print { html, body {width: 80mm; height: 80mm}}; }`
        break
      case 11:
        styleSelect = `@page { size: 80mm 80mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 20mm; margin-right: 80mm; @media print { html, body {width: 80mm; height: 80mm}}; }`
        break
      case 12:
        styleSelect = `@page { size: 80mm 80mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 40mm; margin-right: 80mm; @media print { html, body {width: 80mm; height: 80mm}}; }`
        break
      case 13:
        styleSelect = `@page { size: 120mm 120mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 60mm; margin-right: 80mm; @media print { html, body {width: 120mm; height: 120mm}}; }`
        break
      case 14:
        styleSelect = `@page { size: 120mm auto; margin-top: 0mm; margin-bottom: 0mm; margin-left: 30mm; margin-right: 80mm; @media print { html, body {width: 120mm; height: auto}}; }`
        break
      case 15:
        styleSelect = `@page { size: 50mm 50mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 20mm; @media print { html, body {width: 50mm; height: 50mm}}; }`
        break
      case 16:
        styleSelect = `@page { size: 50mm 50mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 30mm; }`
        break
      case 17:
        styleSelect = `@page { size: 120mm 120mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 40mm; }`
        break
      case 18:
        styleSelect = `@page { size: A4; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 210mm; }`
        break
      case 19:
        styleSelect = `@page { size: 210mm 297mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 40mm; }`
        break
      case 20:
        styleSelect = `@page { size: 210mm 297mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 20mm; margin-right: 40mm; }`
        break
      case 21:
        styleSelect = `@page { size: 120mm 120mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 20mm; margin-right: 40mm; }`
        break
      case 22:
        styleSelect = `@page { size: 50mm 50mm; margin-top: 0mm; margin-bottom: 0mm; margin-left: 20mm; margin-right: 40mm; }`
        break
      case 23:
        styleSelect = `@page { size: 80mm 80mm; @media print { html, body {width: 50mm; height: 50mm; font-size: 50px;}}; }`
        break
    }
    setStyles(styleSelect)
  }

  useEffect(() => {
    if (numId) {
      document.getElementById(`PrintTicket${numId}`)?.click()
    }
  }, [numId])

  return (
    <>
      {step === 1 && (
        <>
          <ContentHeader
            windowTitle={`Detalles ${
              infoNote?.id_partner === 0
                ? 'de la nota manual'
                : 'del contrarecibo'
            }`}
            title={`Detalles ${
              infoNote?.id_partner === 0
                ? 'de la nota manual'
                : 'del contrarecibo'
            }`}
          />
          {/* <FormProvider {...methods}>
            <form
              className={`${loadingBtn ? 'processing-file' : ''}`}
              onSubmit={handleSubmit(onSubmit)}
            > */}
          <Box
            // title="Detalles Nota"
            btnRedPath="/shownotes"
            btnRedTxt="Atras"
            // btnFunctionTitle="Historial"
            // btnFunction={handleStepHistory}
            // btnSubmit
            // btnSubmitText="Mover a Finanzas"
            content={
              <>
                <div className="mt-3 col-12">
                  {infoNote && (
                    <div className="boxQuotesProvider">
                      <div className="row">
                        <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                          <h4>
                            Proveedor: &nbsp;
                            <span className="resultData">
                              {infoNote?.partnerNotes?.provider_name
                                ? infoNote?.partnerNotes?.provider_name
                                : infoBill[0]?.beneficiario}
                            </span>
                          </h4>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                          <div className="boxItem">
                            <h4>
                              RFC: &nbsp;
                              <span className="resultData">
                                {infoNote?.partnerNotes?.provider_rfc
                                  ? infoNote?.partnerNotes?.provider_rfc
                                  : 'XAXX010101000'}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                          <h4>
                            No. Consecutivo: &nbsp;
                            <span className="resultData">
                              {infoNote?.consecutive_number}
                            </span>
                          </h4>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                          <h4>
                            Fecha de Nota: &nbsp;
                            <span className="resultData">
                              {moment(
                                infoNote?.date_note,
                                'YYYY-MM-DD hh:mm:ss a'
                              ).format('DD/MM/YYYY hh:mm:ss a')}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                          <h4>
                            Estatus Nota: &nbsp;
                            <span className="resultData">
                              {infoNote?.full_payed
                                ? 'Pagada'
                                : 'Pagos Pendientes'}
                            </span>
                          </h4>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6 col-lg-6 ">
                          <h4>
                            Tienda: &nbsp;
                            <span className="resultData">
                              {infoNote?.id_store_register
                                ? infoNote?.storesRegister?.alias
                                : 'No registrada'}
                            </span>
                          </h4>
                        </div>
                      </div>
                      {user.store === null && (
                        <div className="row">
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <h4>
                              Usuario que registro: &nbsp;
                              <span className="resultData">
                                {infoNote?.userRegisterNotes?.name
                                  ? infoNote?.userRegisterNotes?.name
                                  : 'Información no registrada'}
                              </span>
                            </h4>
                          </div>
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <h4>
                              Usuario que modifico: &nbsp;
                              <span className="resultData">
                                {infoNote?.userUpdateNotes?.name
                                  ? infoNote?.userUpdateNotes?.name
                                  : 'Información no registrada'}
                              </span>
                            </h4>
                          </div>
                        </div>
                      )}
                      {/* {activateButtonsHidden && buttonGeneric(23)} */}
                      {paymentsChecks.map((item, index) => (
                        <div
                          key={item?.id}
                          className="col-md-12 col-lg-12 display-info-payments-finances"
                        >
                          <div>
                            {user.store === null && (
                              <>
                                <div
                                  style={{ marginTop: '10px' }}
                                  className="btn-add-header"
                                >
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      float: 'right',
                                    }}
                                    className="btn btn-sm btn-outline-danger fas fa-trash-alt"
                                    onClick={() => deletePaymentCheck(item?.id)}
                                  ></span>
                                </div>
                              </>
                            )}
                            <br />
                            <table id="" className="">
                              <thead>
                                <tr style={{ textAlign: 'center' }}>
                                  <th style={{ width: '20%' }}>Referencia</th>
                                  <th style={{ width: '15%' }}>N°. Cheque</th>
                                  <th style={{ width: '20%' }}>Monto Pagado</th>
                                  <th style={{ width: '20%' }}>
                                    Fecha de Cheque
                                  </th>
                                  <th style={{ width: '25%' }}>
                                    Fecha de pago
                                  </th>
                                  {/* <th>Borrar</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                  <td>{item?.invoice_number_payments}</td>
                                  <td>{item?.check_number}</td>
                                  <td>
                                    {formmatterCurrency(
                                      item?.amount_check_payment
                                    )}
                                  </td>
                                  <td>
                                    {moment(
                                      item?.date_check,
                                      'YYYY-MM-DD hh:mm:ss a'
                                    ).format('DD/MM/YYYY')}
                                  </td>
                                  <td>
                                    {moment(
                                      item?.date_payment,
                                      'YYYY-MM-DD hh:mm:ss a'
                                    ).format('DD/MM/YYYY hh:mm:ss a')}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div>
                            <span
                              style={{
                                color: 'black',
                                fontSize: '20px',
                                marginLeft: '30px',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                              }}
                              className={`${
                                activateToggle
                                  ? 'fas fa-chevron-circle-down'
                                  : 'fas fa-chevron-circle-right'
                              } btn-chevron`}
                              aria-expanded="false"
                              data-toggle="collapse"
                              data-target={`#collapse${item?.id}`}
                              aria-controls={`collapse${item?.id}`}
                              onClick={(e) =>
                                handleToogleNotes(
                                  item?.invoice_number_payments,
                                  item?.check_number
                                )
                              }
                            >
                              {/* <i
                                className={`${
                                  activateToggle
                                    ? 'fas fa-chevron-circle-down'
                                    : 'fas fa-chevron-circle-right'
                                }`}
                              ></i> */}
                            </span>

                            <div
                              className="collapse"
                              id={`collapse${item?.id}`}
                              key={index}
                              style={{ marginLeft: '40px' }}
                            >
                              <h6>Consecutivos:</h6>
                              {consecutives?.map((consecutive, index) => (
                                <p style={{ marginLeft: '20px' }}>
                                  {
                                    consecutive?.checksToNotes
                                      ?.consecutive_number
                                  }
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <LayoutTable
                  data={dataBillTable}
                  setPagePagination={setPagePagination}
                  pagePagination={pagePagination}
                  totalCount={total}
                  hideAddNew={true}
                  hideId={true}
                  hideActions={true}
                  exportImportButton={false}
                  hideFilterBar={true}
                  withCard={false}
                  hideBtnEdit={true}
                  // headerButtonsNoPermissions={
                  //   <span
                  //     style={{
                  //       color: 'white',
                  //       border: 'none',
                  //       ':hover': {
                  //         color: 'black',
                  //         border: '1px solid black',
                  //       },
                  //     }}
                  //     className="btn-actions-bulk-counter-charges"
                  //     onClick={() =>
                  //       setActivateButtonsHidden(!activateButtonsHidden)
                  //     }
                  //   >
                  //     oculto
                  //   </span>
                  // }
                />
              </>
            }
          />
          {/* </form>
          </FormProvider> */}
        </>
      )}
      {step === 2 && (
        <>
          <PaymentsHistory
            idNote={idNote}
            handleToBillsByIdCheck={handleToBillsByIdCheck}
            handleBillsHistoryPrevious={handleBillsHistoryPrevious}
          />
        </>
      )}
      {step === 3 && (
        <>
          <PaymentsHistoryNew
            idCheck={idCheck}
            handleHistoryPrevious={handleBillsHistoryPrevious}
          />
        </>
      )}
      {/* <div className="hide">
        <PrintFirstTicket
          componentRef={componentRef}
          dataNote={infoNote}
          secondTicket={valueSecondTicket}
        />
        <ReactToPrint
          trigger={() => (
            <button id={`PrintTicket${numId}`}>
              Print this out!{console.log('numId', numId, 'style', styles)}
            </button>
          )}
          content={() => componentRef.current}
          pageStyle={styles}
        />
      </div> */}
    </>
  )
}

export default ShowDetailsNotes
