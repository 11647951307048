import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ContentHeader from '../../../../Layout/ContentHeader'
import { GET_ORDER_BY_ID_STATUS } from '../../../../../graphql/Catalog/Orders/pendingOrders'
import { GET_COUNTERS_ORDERS } from '../../../../../graphql/Catalog/Orders/countOrders'
import { useMutation, useQuery } from '@apollo/client'
import OrderGeneral from '../../../../OrderGeneral'
import OrderDetailStore from '../../../../OrderDetailStore'
import OrderShippingDetails from '../../../../OrderShippingDetails'
import OrderButtons from '../../../../OrderButtons'
import OrderProducts from '../../../../OrderProducts'
import { CHANGE_TO_SHIPPED } from '../../../../../graphql/Catalog/Orders/shippedOrders'
import { CHANGE_TO_RETURNED } from '../../../../../graphql/Catalog/Orders/rejectedOrders'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import Timeline from '../../../../Global/Timeline/Timeline'
import InternalNotes from '../../../../Global/InternalNotes/InternalNotes'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/es'
import { RejectOrder } from '../../../../Helpers/RejectOrder'
import { AuthContext } from '../../../../../Auth/AuthContext'
import { GET_ALL_ISSUES } from '../../../../../graphql/Catalog/Issues/issues'
import { CREATE_REASON } from '../../../../../graphql/Catalog/Reasons/reasons'

export const InRouteOrdersNew = () => {
  const { id: _id, show } = useParams()
  const history = useHistory()
  const [idOrder, setIdOrder] = useState('')
  const [storeFromDB, setStoreFromDB] = useState(false)
  const [dataOrder, setDataOrder] = useState([])
  const [processing, setProcessing] = useState(false)
  const [issuses, setIssuses] = useState({})
  const { user } = useContext(AuthContext)

  /* GET ALL ISSUSES DATA */
  const {
    data: dataIssuses,
    loading: loadingIssuses,
    error: errorIssuses,
  } = useQuery(GET_ALL_ISSUES)

  useEffect(() => {
    if (!loadingIssuses && !errorIssuses) {
      let list = {}
      dataIssuses.getAllIssusses.map((item) => {
        list = Object.assign(list, { [item.id]: item.name })
        return true
      })
      setIssuses(list)
    }
  }, [dataIssuses, loadingIssuses, errorIssuses])

  const [createReason] = useMutation(CREATE_REASON, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })

  const { data, loading, error } = useQuery(GET_ORDER_BY_ID_STATUS, {
    variables: {
      id: parseInt(_id),
      statusId: [7],
      typeId: null,
    },
  })

  useEffect(() => {
    if (!loading) {
      if (error)
        return (
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          }),
          history.push('/dashboard')
        )

      const orderData = data.getOrderByIdAndStatus
      if (orderData.store?.name || orderData.warehouse?.name)
        setStoreFromDB(true)
      setIdOrder(orderData.order_id)
      setDataOrder(orderData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  const [changeToShipped] = useMutation(CHANGE_TO_SHIPPED, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })
  const [changeToReturned] = useMutation(CHANGE_TO_RETURNED, {
    refetchQueries: [{ query: GET_COUNTERS_ORDERS }],
  })

  const onSubmitCompleted = async () => {
    try {
      setProcessing(true)
      const { data } = await changeToShipped({
        variables: {
          orderId: _id,
        },
      })
      if (data.changeToShipped) {
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Pedido procesado',
          },
          history.goBack()
        )
      }
    } catch (error) {
      setProcessing(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const onSubmitReturned = async () => {
    try {
      setProcessing(true)
      const { data } = await changeToReturned({
        variables: {
          orderId: _id,
        },
      })
      if (data.changeToReturned) {
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Pedido procesado',
          },
          history.goBack()
        )
      }
    } catch (error) {
      setProcessing(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleSubmitModal = async (nameSubmit) => {
    return Swal.fire({
      title: '¿Ya subiste evidencias?',
      text: 'Debes subir evidencias de esta entrega. Utiliza las notas internas para hacerlo',
      icon: 'warning',
      allowOutsideClick: false,
      buttonsStyling: false,
      confirmButtonText: 'Si, Entregar',
      showDenyButton: true,
      denyButtonText: 'No, Regresar',
      reverseButtons: true,
      customClass: {
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        nameSubmit()
      }
    })
  }

  moment.locale('es') //Change dates languages to spanish

  return (
    <>
      <ContentHeader
        title="Pedido en ruta"
        breadcrumb="Ruta"
        windowTitle={`${_id ? 'Editar' : 'Agregar'} pedido `}
      />
      <div className={`row ${processing ? 'processing-file' : ''}`}>
        <div className="col-8">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles</h3>
                </div>
                <div className="card-body col-12">
                  <OrderGeneral
                    orderDate={moment(dataOrder?.order_date).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                    orderId={dataOrder?.order_id}
                    paymentId={dataOrder?.payment?.payment_id}
                    platform={dataOrder?.platform?.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de pago</h3>
                </div>
                <div className="card-body">
                  <OrderDetailStore
                    orderType={dataOrder?.type?.name}
                    orderStore={
                      dataOrder?.store?.name || dataOrder?.warehouse?.name
                    }
                    orderPaymentPlatform={dataOrder?.payment?.platform}
                    orderMethod={dataOrder?.method?.name}
                    withStore={storeFromDB}
                    totalPrice={dataOrder?.total_price}
                    shippingPrice={dataOrder?.shipping_price}
                    status={dataOrder?.status?.id}
                    shippingReceipt={dataOrder?.shipping?.receipt?.url}
                    shippingCompanyName={
                      dataOrder?.shippingCompany?.name
                        ? dataOrder?.shippingCompany?.name
                        : null
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Desglose de productos</h3>
                </div>
                <div className="card-body">
                  <OrderProducts
                    productsArray={dataOrder?.products}
                    totalQuantity={dataOrder?.product_quantity}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de envío</h3>
                </div>
                <div className="card-body">
                  <OrderShippingDetails
                    city={dataOrder?.shipping?.city}
                    address={dataOrder?.shipping?.address_1}
                    postcode={dataOrder?.shipping?.postcode}
                    email={dataOrder?.shipping?.email}
                    phone={dataOrder?.shipping?.phone}
                    firstName={dataOrder?.shipping?.first_name}
                    lastName={dataOrder?.shipping?.last_name}
                    state={dataOrder?.shipping?.state}
                  />
                </div>
              </div>
            </div>
            {/* {!show && dataOrder?.method?.name !== 'UDirect' && (
              <OrderButtons
                goBack
                cancelBtn={'Orden devuelta'}
                onCancel={onSubmitReturned}
                processBtn={'Orden entregada'}
                onProcess={onSubmitCompleted}
              />
            )} */}
            {!show && dataOrder?.method?.name !== 'UDirect' ? (
              <OrderButtons
                goBack
                cancelBtn={'Cancelar orden'}
                onCancel={async () =>
                  RejectOrder(
                    issuses,
                    user.idUser,
                    createReason,
                    idOrder,
                    history
                  )
                }
                thirdBtn={'Orden devuelta'}
                thirdAction={() => handleSubmitModal(onSubmitReturned)}
                processBtn={'Orden entregada'}
                onProcess={() => handleSubmitModal(onSubmitCompleted)}
              />
            ) : (
              <OrderButtons
                oneButton={true}
                onProcess={() => history.goBack()}
                processBtn="Volver"
                firstButtonGray={true}
              />
            )}
            {show && (
              <OrderButtons
                oneButton={true}
                onProcess={() => history.goBack()}
                firstButtonGray={true}
                processBtn="Volver"
              />
            )}
          </div>
        </div>
        {/* TIMELINE & INTERNAL NOTES */}
        <div className="col-4">
          <Timeline idOrder={idOrder} />
          <InternalNotes idOrder={idOrder} />
        </div>
      </div>
    </>
  )
}

export default InRouteOrdersNew
