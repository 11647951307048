import { useEffect, useState } from 'react'
import LayoutTable from '../Global/LayoutTable'
import { useMutation, useQuery } from '@apollo/client'
import {
  DOWNLOAD_REPORT_COUNTERCHARGES,
  GET_HISTORY_REPORTS_COUNTERCHARGES,
} from '../../graphql/Catalog/Reports/counterchargesreport'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'

const CounterchargesReports = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [dataToGetLinks, setDataToGetLinks] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { data, error, loading, refetch } = useQuery(
    GET_HISTORY_REPORTS_COUNTERCHARGES,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [downloadReport] = useMutation(DOWNLOAD_REPORT_COUNTERCHARGES)

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const list = data?.getAllCounterchargesReportsFilesByUserId?.rows.map(
        (item) => {
          return {
            fileId_omitField: item?.file_id,
            id: item?.id,
            'tipo de reporte':
              item?.CounterchargesReportFilesToCounterchargesFileType?.name,
            De: item?.custom_range_start,
            Hasta: item?.custom_range_end,
            progreso: (
              <>
                <div className="progress" style={{ width: '100px' }}>
                  <div
                    className={`progress-bar progress-bar-striped ${
                      item?.is_error
                        ? 'bg-danger'
                        : item?.completed
                        ? 'bg-success'
                        : 'bg-warning progress-bar-animated'
                    }`}
                    role="progressbar"
                    style={{ width: item?.completed ? '100%' : '50%' }}
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </>
            ),
            completed_omitField: item?.completed,
          }
        }
      )
      setDataToTable(list)
      // setDataToGetLinks(data?.getAllCounterchargesReportsFilesByUserId?.rows)
      setTotal(data?.getAllCounterchargesReportsFilesByUserId?.count)
    }
  }, [data, error, loading])

  // function stringToBytes(text) {
  //   const length = text.length
  //   const result = new Uint8Array(length)
  //   for (let i = 0; i < length; i++) {
  //     const code = text.charCodeAt(i)
  //     const byte = code > 255 ? 32 : code
  //     result[i] = byte
  //   }
  //   return result
  // }

  const handleButton = async (e, item) => {
    try {
      console.log('item', item)
      const { data } = await downloadReport({
        variables: {
          fileId: parseInt(item.fileId_omitField),
        },
        fetchPolicy: 'no-cache',
      })
      console.log('data', data)

      const a = document.createElement('a')
      a.setAttribute('href', data?.downloadReportCountercharges?.url)
      a.setAttribute('target', '_blank')
      a.setAttribute('download', 'reporte.xlsx')
      a.click()
    } catch (error) {
      console.log(error)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  return (
    <>
      <LayoutTable
        data={dataToTable}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        hideAddNew
        refetchFunctionQueries={refetch}
        // handleButtonTitle="Descargar reporte"
        // handleButton={handleButton}
        // hideActions
        hideBtnEdit
        hideBtnErase
        hideDetails
        titleActions="Descargar reporte"
        componentAction={(row) => {
          return (
            <>
              {row?.completed_omitField && (
                <span
                  className="btn btn-accept"
                  onClick={(e) => handleButton(e, row)}
                >
                  Descargar reporte
                </span>
              )}
            </>
          )
        }}
      />
    </>
  )
}

export default CounterchargesReports
