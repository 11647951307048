import { PrivateRoute } from '../Routers/PrivateRoute'
import React from 'react'
import Users from '../Components/Admin/Catalogs/Users/List'
import UsersNew from '../Components/Admin/Catalogs/Users/Register'
import UsersPermissions from '../Components/Admin/Catalogs/Users/Permissions'
import Roles from '../Components/Admin/Catalogs/Roles/Roles'
import RolesNew from '../Components/Admin/Catalogs/Roles/RolesNew'
import Modules from '../Components/Admin/Catalogs/Modules/Modules'
import ModulesNew from '../Components/Admin/Catalogs/Modules/ModulesNew'
import UsersApp from '../Components/Admin/Catalogs/UsersApp/UsersApp'
import UsersAppNew from '../Components/Admin/Catalogs/UsersApp/UsersAppNew'
import ProvidersSchedule from '../Components/Admin/Catalogs/ProvidersSchedule/ProvidersSchedule'
import ProvidersScheduleNew from '../Components/Admin/Catalogs/ProvidersSchedule/ProvidersScheduleNew'
import { UserProfileMain } from '../Components/Admin/Catalogs/Users/UserProfile/UserProfileMain'
import CommodityReceipt from '../Components/Documents/CommodityReceipt/CommodityReceipt'
import CommodityReceiptNew from '../Components/Documents/CommodityReceipt/CommodityReceiptNew'
import TransfersOrdersSingular from '../Components/Store/TransfersOrders/TransfersOrdersSingular'
import TransfersOrdersSingularNew from '../Components/Store/Shared/TransfersNew'
import AuthML from '../Components/Admin/Integrations/AuthML'
//Notes COUNTERCHARGES
import Notes from '../Components/Admin/Catalogs/Notes/Notes'
import NoteNew from '../Components/Admin/Catalogs/Notes/NoteNew'
import ShowNotes from '../Components/Admin/Catalogs/Notes/ShowNotes'
import ShowNotesNew from '../Components/Admin/Catalogs/Notes/NoteDetails/ShowNotesNew'
import GenNewPayment from '../Components/Admin/Catalogs/Notes/GenerateNewPayment'

//FINANCES COUNTERCHARGES
import FinancesCounterCharges from '../Components/Admin/Catalogs/FinancesCounterCharges/FinancesCounterCharges'

//CATALOGS COUNTERCHARGES
import CatalogsForCounterCharges from '../Components/Admin/Catalogs/Notes/CatalogsForCounterCharges'

import Displacements from '../Components/Admin/Catalogs/Displacements/Displacements'
import DisplacementsNew from '../Components/Admin/Catalogs/Displacements/DisplacementsNew'

import InventoryReport from '../Components/Admin/Catalogs/Inventory/InventoryReport'
import InventoryReportNew from '../Components/Admin/Catalogs/Inventory/InventoryReportNew'

// INVENTORY ACTIVITIES
import InventoryActivitiesSchedule from '../Components/InventoryActivities/Schedule/InventoryActivitiesSchedule'
import InventoryActivitiesAccountants from '../Components/InventoryActivities/Accountant/InventoryActivitiesAccountant'
import InventoryActivitiesLabels from '../Components/InventoryActivities/Labels/InventoryActivitiesLabels'
import InventoryActivitiesAssignments from '../Components/InventoryActivities/Assignments/InventoryActivitiesAssignments'
import InventoryActivitiesAssignmentsNew from '../Components/InventoryActivities/Assignments/InventoryActivitiesAssignmentsNew'
import InventoryActivitiesLive from '../Components/InventoryActivities/Live/InventoryActivitiesLive'
import InventoryActivitiesLiveNew from '../Components/InventoryActivities/Live/InventoryActivitiesLiveNew'
import InventoryActivitiesCounts from '../Components/InventoryActivities/CountsComponent/InventoryActivitiesCounts'
import InventoryActivitiesCountsNew from '../Components/InventoryActivities/CountsComponent/InventoryActivitiesCountsNew'
import InventoryActivitiesHistory from '../Components/InventoryActivities/History/InventoryActivitiesHistory'
import InventoryActivitiesHistoryNew from '../Components/InventoryActivities/History/InventoryActivitiesHistoryNew'
import InventoryActivitiesReports from '../Components/InventoryActivities/Reports/InventoryActivitiesReports'
import InventoryActivitiesReportsNew from '../Components/InventoryActivities/Reports/InventoryActivitiesReportsNew'

//ROUTES
import Vehicles from '../Components/LogisticAgent/Catalogs/Vehicles/Vehicles'
import VehiclesNew from '../Components/LogisticAgent/Catalogs/Vehicles/VehiclesNew'
import Routes from '../Components/LogisticAgent/Catalogs/Routes/Routes'
import RoutesNew from '../Components/LogisticAgent/Catalogs/Routes/RoutesNew'
import Operators from '../Components/LogisticAgent/Catalogs/Operators/Operators'
import OperatorsNew from '../Components/LogisticAgent/Catalogs/Operators/OperatorsNew'
import ControlDeskRoutes from '../Components/LogisticAgent/ControlDeskRoutes/ControlDeskRoutes'
import ControlDeskRoutesDetails from '../Components/LogisticAgent/ControlDeskRoutes/ControlDeskRoutesDetails'

import TelemarketingDashboard from '../Components/Telemarketing/Dashboard/TelemarketingDashboard'
import TelemarketingStock from '../Components/Telemarketing/Stock/TelemarketingStock'
import TelemarketingOrders from '../Components/Telemarketing/Orders/TelemarketingOrders'
import TelemarketingOrdersNew from '../Components/Telemarketing/Orders/TelemarketingOrdersNew'
import TelemarketingOrderHistory from '../Components/Telemarketing/OrdersHistory/TelemarketingOrderHistory'
import TelemarketingOrderHistoryNew from '../Components/Telemarketing/OrdersHistory/TelemarketingOrderHistoryNew'
//ProvidersDashboard
import ProvidersDashboard from '../Components/ProvidersDashboard/ProvidersDashboard'
import ReportHistorial from '../Components/ProvidersDashboard/Reports/ReportHistorial'
// Returns
import ReturnRequest from '../Components/Inventories/Returns/ReturnRequest'
//WarehouseOperationDashboard
import WarehouseOperationDashboard from '../Components/WarehouseDashboards/WarehouseOperationDashboard'
import BusinessPartner from '../Components/BusinessPartner/BusinessPartner'
import BusinessPartnerNew from '../Components/BusinessPartner/BusinessPartnerNew'
//Reports
import ScheduleRegister from '../Components/Schedule/vehicles/ScheduleRegister'
import ScheduleRegisterNew from '../Components/Schedule/vehicles/ScheduleRegisterNew'

//ReceiptReports
import ReceiptReports from '../Components/ReceiptReports/ReceiptReports'
import ReceiptReportsNew from '../Components/ReceiptReports/ReceiptReportsNew'

//ProductsReception
import ProductsReception from '../Components/Documents/ProductsReception/ProductsReception'
import ProductsReceptionNew from '../Components/Documents/ProductsReception/ProductsReceptionNew'
import Omnichannel from '../Components/Omnichannel/Omnichannel'

//Reports countercharges
import CounterchargesReports from '../Components/CounterchargesReports/CounterchargesReports'

//Billing
import DocumentToBilling from '../Components/Billing/DocumentToBilling'
import DocumentToBillingNew from '../Components/Billing/DocumentToBillingNew'
import DocumentToBillingClients from '../Components/Billing/Clients/DocumentToBillingClients'
import DocumentToBillingClientsNew from '../Components/Billing/Clients/DocumentToBillingClientsNew'

const moduleRoutesGenerator = (read, create, edit, imports, module, isAuth) => {
  const components = {
    Users,
    UsersNew,
    UsersPermissions,
    Roles,
    RolesNew,
    Modules,
    ModulesNew,
    UserProfileMain,
    UsersApp,
    UsersAppNew,
    ProvidersSchedule,
    ProvidersScheduleNew,
    CommodityReceipt,
    CommodityReceiptNew,
    TransfersOrdersSingular,
    TransfersOrdersSingularNew,
    AuthML,

    //NOTES   COUNTERCHARGES
    Notes,
    NoteNew,
    ShowNotes,
    ShowNotesNew,
    GenNewPayment,
    //FINANCES COUNTERCHARGES
    FinancesCounterCharges,

    //CATALOGS COUNTERCHARGES
    CatalogsForCounterCharges,

    Displacements,
    DisplacementsNew,
    InventoryReport,
    InventoryReportNew,

    // INVENTORY ACTIVITIES
    InventoryActivitiesSchedule,
    InventoryActivitiesAccountants,
    InventoryActivitiesLabels,
    InventoryActivitiesAssignments,
    InventoryActivitiesAssignmentsNew,
    InventoryActivitiesLive,
    InventoryActivitiesLiveNew,
    InventoryActivitiesCounts,
    InventoryActivitiesCountsNew,
    InventoryActivitiesHistory,
    InventoryActivitiesHistoryNew,
    InventoryActivitiesReports,
    InventoryActivitiesReportsNew,

    //ROUTES
    Vehicles,
    VehiclesNew,
    Routes,
    RoutesNew,
    Operators,
    OperatorsNew,
    ControlDeskRoutes,
    ControlDeskRoutesDetails,

    TelemarketingDashboard,
    TelemarketingStock,
    TelemarketingOrders,
    TelemarketingOrdersNew,
    TelemarketingOrderHistory,
    TelemarketingOrderHistoryNew,
    ProvidersDashboard,

    // Returns
    ReturnRequest,

    //ReceiptReports
    ReceiptReports,
    ReceiptReportsNew,
    WarehouseOperationDashboard,

    //providers reports
    ReportHistorial,

    //business partner
    BusinessPartner,
    BusinessPartnerNew,

    ScheduleRegister,
    ScheduleRegisterNew,

    //ProductReception
    ProductsReception,
    ProductsReceptionNew,
    //Omnichannel Main
    Omnichannel,

    //Reports countercharges
    CounterchargesReports,

    //Billing
    DocumentToBilling,
    DocumentToBillingNew,
    DocumentToBillingClients,
    DocumentToBillingClientsNew,
  }

  const routesArray = []
  if (read) {
    routesArray.push(
      <PrivateRoute
        exact
        key={module.id}
        path={module.module_info.relative_link}
        component={components[module.module_info.name]}
        isAuth={isAuth}
      />
    )
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module.module_info.relative_link}/details/:id/:show`}
        component={components[`${module.module_info.name}New`]}
        isAuth={isAuth}
      />
    )
    if (module.module_info.name === 'ControlDeskRoutes') {
      routesArray.push(
        <PrivateRoute
          exact
          path={`${module.module_info.relative_link}/specificDetails/:id/:type`}
          component={components[`${module.module_info.name}Details`]}
          isAuth={isAuth}
        />
      )
    }
  }
  if (create) {
    routesArray.push(
      <PrivateRoute
        exact
        key={module.id}
        path={`${module.module_info.relative_link}/new`}
        component={components[`${module.module_info.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (edit) {
    routesArray.push(
      <PrivateRoute
        exact
        key={module.id}
        path={`${module.module_info.relative_link}/edit/:id`}
        component={components[`${module.module_info.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (imports) {
    routesArray.push(
      <PrivateRoute
        exact
        key={module.id}
        path={`${module.module_info.relative_link}/import/:form`}
        component={components[`${module.module_info.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  return routesArray
}
export default moduleRoutesGenerator
