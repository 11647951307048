import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import InputController from '../../Global/InputController'
import { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import {
  DELETE_ALL_TRANSFER_LINE,
  DELETE_TRANSFER_LINE,
  GET_PRODUCTS_SAP,
  GET_TRANSFER_LINES_BY_TRANSFER_REQUEST,
  GET_TRANSFER_REQUEST,
  GET_TRANSFER_REQUEST_BY_EDITION_ID,
  GET_TRANSFER_REQUEST_BY_ID,
  MUTATION_GET_TRANSFER_LINES_BY_TRANSFER_REQUEST,
  PROCESS_TRANSFERS_PICKING_SCHEDULES,
  UPDATE_TRANSFER_REQUEST,
} from '../../../graphql/Catalog/Transfers/Transfers'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from '../../../Auth/AuthContext'
import { document_statuses_esp } from '../../Helpers/StatusesLanguages'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {
  GET_SAP_WAREHOUSES_MUTATION,
  GET_SAP_WAREHOUSES_TRANSFERS,
} from '../../../graphql/Catalog/SAP/sapWarehouses'
import TransferQuantitiesForm from './TransferQuantitiesForm'
import ModalContent from '../../Global/ModalContent'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'
import trashIcon from '../../../Assets/icons/trash.svg'
import ProductsToTransfer from './AddTransferRequestComponents/ProductsToTransfer'
import ProductsFound from './AddTransferRequestComponents/ProductsFound'
import TransferRequestForm from './AddTransferRequestComponents/TransferRequestForm'
import { useTransferContext } from './AddTransferRequestComponents/TransferContext'

const AddTransferRequest = ({ _id, show }) => {
  const { user } = useContext(AuthContext)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [textBtnBack, setTextBtnBack] = useState('Regresar')
  const history = useHistory()
  const location = useLocation()
  const statusDictionary = document_statuses_esp

  //ESTADOS COMPARTIDOS
  const {
    warehouseOriginSelected,
    dataRequest,
    refModal,
    dataLines,
    dataItem,
    warehousesCodes,
    pagePagination,
    setDataLines,
    setWarehouseOriginSelected,
    setDataRequest,
    setTotal,
    setResetTransferQuantityForm,
  } = useTransferContext()
  /////

  const [isPicking, setIsPicking] = useState(false)
  const [locationBack, setLocationBack] = useState('')

  const [
    mutationGetTransferLines,
    {
      data: dataTransferLines,
      loading: loadingTransferLines,
      error: errorTransferLines,
    },
  ] = useMutation(MUTATION_GET_TRANSFER_LINES_BY_TRANSFER_REQUEST)

  const [updateTransferRequest] = useMutation(UPDATE_TRANSFER_REQUEST)
  const [processPickingScheduleTransfer] = useMutation(
    PROCESS_TRANSFERS_PICKING_SCHEDULES
  )

  const {
    data: dataOneTransferRequestId,
    loading: loadingOneTransferRequestId,
    error: errorOneTransferRequestId,
    refetch: refetchOneTransferRequestId,
  } = useQuery(GET_TRANSFER_REQUEST_BY_ID, {
    variables: {
      id: parseInt(_id),
      validation: false,
      details: true,
    },
  })

  const {
    data: dataTransferRequestId,
    loading: loadingTransferRequestId,
    error: errorTransferRequestId,
  } = useQuery(GET_TRANSFER_REQUEST_BY_EDITION_ID, {
    variables: {
      getOneTransferRequestByEditionIdId: parseInt(_id),
      details: true,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    const windowLocation = `/${location.pathname.split('/')[1]}/${
      location.pathname.split('/')[2]
    }`
    // console.log(windowLocation)
    if (
      location.pathname === '/transfers/new' ||
      location.pathname.includes('/transfers/edit')
    ) {
      setLocationBack('/transfers')
    } else if (location.pathname.includes('/transfers/picking')) {
      setLocationBack('/transfers/picking')
    } else {
      setLocationBack('/transfers/general')
    }
  }, [])

  useEffect(() => {
    if (_id) {
      if (
        !loadingTransferRequestId &&
        !location.pathname.includes('/transfers/picking')
      ) {
        const data = dataTransferRequestId?.getOneTransferRequestByEditionId

        let list = {
          // date: moment(data?.doc_date).format('DD/MMMM/YYYY'),
          date: moment(data?.doc_date).format('YYYY-MM-DD'),
          date_due: moment(data?.doc_due_date).format('DD/MMMM/YYYY'),
          doc_entry: data?.TransferlineToTransferRequest[0]?.doc_entry,
          comments: data?.comments ? data?.comments : '---',
          origin: data?.warehouse_origin_name?.warehouse_name,
          destiny: data?.warehouse_destiny_name?.warehouse_name,
          status: statusDictionary[data?.status],
          from_whs_code: data?.from_whs_code,
          to_whs_code: data?.to_whs_code,
          branch_code: warehousesCodes.branch_code,
        }

        const status = data?.status

        const products = data?.TransferlineToTransferRequest?.map((product) => {
          return {
            id: product?.id,
            sku: product?.item_code,
            nombre: product?.name_product?.item_name,
            Solicitado: product?.quantity,
            Aprobado:
              status === 4 || status === 5
                ? product?.quantity - product?.open_quantity
                : 'Pendiente',
            Faltante:
              status === 4 || status === 5
                ? product?.open_quantity
                : 'Pendiente',
          }
        })
        list.products = products

        setDataRequest(list)
      }
      if (
        !loadingOneTransferRequestId &&
        location.pathname.includes('/transfers/picking')
      ) {
        const dataOneRequestId = dataOneTransferRequestId?.getOneTransferRequest
        let list = {}
        if (
          dataOneRequestId?.stage_status !== 1 ||
          dataOneRequestId?.stage_status !== 6
        ) {
          setIsPicking(true)
          list = {
            date: moment(dataOneRequestId?.doc_date).format('DD/MMMM/YYYY'),
            date_due: moment(dataOneRequestId?.doc_due_date).format(
              'DD/MMMM/YYYY'
            ),
            doc_entry: dataOneRequestId?.products[0]?.doc_entry,
            comments: dataOneRequestId?.comments
              ? dataOneRequestId?.comments
              : '---',
            origin: dataOneRequestId?.warehouse_origin_name?.warehouse_name,
            destiny: dataOneRequestId?.warehouse_destiny_name?.warehouse_name,
            status: statusDictionary[dataOneRequestId?.status],
            branch_code: warehousesCodes.branch_code,
          }

          const products = dataOneRequestId?.OrderToTransfer?.map((item) => {
            return {
              id: item?.id,
              nombre: `${
                item?.UserToOrder
                  ? `${item?.UserToOrder?.name} ${item?.UserToOrder?.first_name} ${item?.UserToOrder?.last_name}`
                  : 'Sin asignar'
              } `,
              // estado: item?.open ? 'Abierto' : 'Cerrado',
              activo: item?.is_active ? 'Activo' : 'Inactivo',
              surtido: item?.picked ? 'Surtida' : 'Sin Surtir',
              // tipo: item?.type,
              conditional_hide_button: item?.picked ? true : false,
            }
          })
          list.products = products
        }

        setDataRequest(list)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataTransferRequestId, dataOneTransferRequestId])

  useEffect(() => {
    try {
      const getTransferLines = async () => {
        await mutationGetTransferLines({
          variables: {
            // getTransferLinesByTransferRequestId: parseInt(_id),
            mutationGetTransferLinesByTransferRequestId: parseInt(_id),
            warehouseCode: warehouseOriginSelected
              ? warehouseOriginSelected
              : dataRequest?.from_whs_code,
            searchQuery: pagePagination.searchQuery,
            limit: pagePagination.limit,
            offset: pagePagination.offset,
          },
        })
      }
      if (_id && warehouseOriginSelected && dataRequest?.from_whs_code) {
        getTransferLines()
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [_id, warehouseOriginSelected, dataRequest?.from_whs_code, pagePagination])

  useEffect(() => {
    if (_id) {
      if (
        !loadingTransferLines &&
        !location.pathname.includes('/transfers/picking')
      ) {
        if (errorTransferLines) {
          setLoadingBtn(false)
          return toastSweetAlert(
            {
              mode: 'error',
              message: errorTransferLines.message,
            },
            // history.push('/transfers')
            history.push(locationBack)
          )
        }

        const data =
          dataTransferLines?.mutationGetTransferLinesByTransferRequest.rows ||
          []
        // dataTransferLines?.mutationGetTransferLinesByTransferRequest || []

        let list = []
        data.map((item) => {
          list.push({
            itemCode: item.itemCode,
            itemName: item.itemName,
            quantity: item.quantity,
            cajasHide: item.cajasHide,
            unidadHide: item.unidadHide,
            stock: item.stock,
            quantityBoxes: item.quantityBoxes,
            quantityBottles: item.quantityBottles,
          })
        })
        setTotal(
          dataTransferLines?.mutationGetTransferLinesByTransferRequest.count
        )
        setDataLines(list)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTransferLines, warehouseOriginSelected, dataRequest])

  //Effect change text of back button
  useEffect(() => {
    if (dataLines.length > 0) {
      setTextBtnBack('Guardar y continuar luego')
    }
  }, [dataLines])

  useEffect(() => {
    if (_id && !show) {
      setValue('source_warehouse_sap', dataRequest?.from_whs_code)
      setValue('flagSourceWarehouseSelected', dataRequest?.from_whs_code)
      setWarehouseOriginSelected(dataRequest?.from_whs_code)
      setValue('destination_warehouse_sap', dataRequest?.to_whs_code)
      setValue('comments_sap', dataRequest?.comments)
    }
  }, [dataRequest])

  const validationSchemaTransferRequest = Yup.object().shape({
    source_warehouse_sap: Yup.string().required('Este campo es obligatorio'),
    comments_sap: Yup.string(),
  })

  const validationSchemaTransferRequestNoStore = Yup.object().shape({
    source_warehouse_sap: Yup.string().required('Este campo es obligatorio'),
    destination_warehouse_sap: Yup.string()
      .required('Este campo es obligatorio')
      .when('source_warehouse_sap', (destination_warehouse_sap, schema) => {
        return schema.test({
          test: (source_warehouse_sap) =>
            source_warehouse_sap !== destination_warehouse_sap,
          message: 'El almacén origen y destino no pueden ser el mismo',
        })
      }),
    comments_sap: Yup.string(),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(
      user.store
        ? validationSchemaTransferRequest
        : validationSchemaTransferRequestNoStore
    ),
  })

  const onSubmit = async (Data) => {
    setLoadingBtn(true)
    try {
      let flagQuantities = false

      dataLines.map((product) => {
        //1) ----- Transform box to bottles quantity
        let newQuantity =
          product.quantityBoxes * product.unidadHide + product.quantityBottles
        //2) ----- Return the new object with new quantity
        if (newQuantity === 0) flagQuantities = true
      })

      if (dataLines.length < 1) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'No se han agregado productos a la transferencia',
        })
      }

      if (flagQuantities) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message:
            'Hay productos sin cantidades definidas, por favor edita sus cantidades o eliminalos',
          // message:
          //   'Hay productos donde se solicita una cantidad 0 piezas. Si no deseas incluir dichos productos, eliminalos de la solicitud',
        })
      }
      await updateTransferRequest({
        variables: {
          updateTransferRequestId: parseInt(_id),
          dataRequest: {
            doc_date: moment().format('DD/MMMM/YYYY'),
            from_whs_code: getValues('source_warehouse_sap'),
            to_whs_code: getValues('destination_warehouse_sap') ?? null,
            comments: getValues('comments_sap'),
          },
          products: dataLines,
        },
      })
      //Second step ->
      await processPickingScheduleTransfer({
        variables: {
          transferId: parseInt(_id),
          fromTransferNew: true,
        },
        refetchQueries: [
          {
            query: GET_TRANSFER_REQUEST,
            variables: {
              limit: 10,
              offset: 0,
              searchQuery: null,
              // status: 1,
              statuses: [1, 8],
              onlyFromWhs: ['270'],
              onlyToWhs: null,
            },
          },
        ],
      })
      return toastSweetAlert(
        {
          mode: 'ok',
          message:
            'Procesando solicitud de transferencia. Tu solicitud se vera reflejada en el listado en unos minutos.',
        },
        history.push(locationBack)
      )
    } catch (error) {
      setLoadingBtn(false)
      reloadListTransfer()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const reloadListTransfer = async () => {
    try {
      const { data } = await mutationGetTransferLines({
        variables: {
          mutationGetTransferLinesByTransferRequestId: parseInt(_id),
          warehouseCode: warehouseOriginSelected
            ? warehouseOriginSelected
            : dataRequest?.from_whs_code,
          searchQuery: null,
          limit: 10,
          offset: 0,
        },
      })
      let dataTransferLines =
        data?.mutationGetTransferLinesByTransferRequest.rows
      // let dataTransferLines = data?.mutationGetTransferLinesByTransferRequest

      let list = []
      dataTransferLines.map((item) => {
        list.push({
          itemCode: item.itemCode,
          itemName: item.itemName,
          quantity: item.quantity,
          cajasHide: item.cajasHide,
          unidadHide: item.unidadHide,
          stock: item.stock,
          quantityBoxes: item.quantityBoxes,
          quantityBottles: item.quantityBottles,
        })
      })
      setTotal(
        dataTransferLines?.mutationGetTransferLinesByTransferRequest.count
      )
      setDataLines(list)
    } catch (error) {
      console.warn(error)
    }
  }

  const handleQuantity = (id, max, value, type, perBox, maxStock) => {
    const val = document.getElementById(type + id).value

    if (type == 'quantityBox') {
      const maxBottles = maxStock - value * perBox
      document.getElementById('quantity' + id).disabled = false
      if (maxBottles == 0) {
        document.getElementById('quantity' + id).placeholder = 0
        document.getElementById('quantity' + id).disabled = true
      } else {
        const bottlesQuantityPlaceHolder =
          maxBottles < perBox - 1
            ? `Disponible ${maxBottles} ${maxBottles < 2 ? 'pza' : 'pzas'} `
            : `Disponible ${perBox - 1} ${perBox - 1 < 2 ? 'pza' : 'pzas'}`
        document.getElementById('quantity' + id).placeholder =
          bottlesQuantityPlaceHolder
      }
    }

    if (!val) {
      showTotalDynamic(id, perBox)
      return
    }
    const valueConverted = Number(value)

    if (isNaN(valueConverted) || valueConverted < 0) {
      document.getElementById(type + id).value = ''
      showTotalDynamic(id, perBox)
      return toastSweetAlert({
        mode: 'error',
        message: 'Cantidad no válida',
      })
    }

    if (type === 'quantity') {
      if (val >= perBox) {
        document.getElementById(type + id).value = ''
        showTotalDynamic(id, perBox)
        return toastSweetAlert({
          mode: 'error',
          message: 'Sobrepasas el límite de cantidad por unidad',
        })
      }
    }

    if (val > max) {
      document.getElementById(type + id).value = ''
      showTotalDynamic(id, perBox)
      return toastSweetAlert({
        mode: 'error',
        message: 'Sobrepasas el límite de stock disponible',
      })
    }
    if (val.includes('.')) {
      document.getElementById(type + id).value = ''
      showTotalDynamic(id, perBox)
      return toastSweetAlert({
        mode: 'error',
        message: 'Solo cantidades enteras',
      })
    }

    let valBox = document.getElementById('quantityBox' + id).value
    let valBottle = document.getElementById('quantity' + id).value

    let resQuantityBox = parseInt(valBox ? parseInt(valBox) : 0) * perBox
    let resQuantityIndividual = parseInt(valBottle ? valBottle : 0)

    if (resQuantityBox + resQuantityIndividual > maxStock) {
      document.getElementById(type + id).value = ''
      // showTotalDynamic(id, perBox)
      return toastSweetAlert({
        mode: 'error',
        message: 'Sobrepasas el límite de stock disponible',
      })
    }

    showTotalDynamic(id, perBox)
  }

  const showTotalDynamic = (id, perBox) => {
    let valTotalBox = document.getElementById('quantityBox' + id).value
      ? document.getElementById('quantityBox' + id).value
      : 0
    let valTotalBottle = document.getElementById('quantity' + id).value
      ? document.getElementById('quantity' + id).value
      : 0

    let valTotalBoxConvert =
      parseInt(valTotalBox ? parseInt(valTotalBox) : 0) * perBox
    let valTotalBottleConvert = parseInt(valTotalBottle ? valTotalBottle : 0)
    document.getElementById('totalShow' + id).value =
      valTotalBoxConvert + valTotalBottleConvert
  }

  const refetchMutationLines = async () => {
    try {
      const warehouseOrigin = getValues('source_warehouse_sap') || ''

      await mutationGetTransferLines({
        variables: {
          // getTransferLinesByTransferRequestId: parseInt(_id),
          mutationGetTransferLinesByTransferRequestId: parseInt(_id),
          warehouseCode: warehouseOriginSelected
            ? warehouseOriginSelected
            : dataRequest?.from_whs_code
            ? dataRequest?.from_whs_code
            : warehouseOrigin,
          searchQuery: null,
          limit: 10,
          offset: 0,
        },
      })
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          autoComplete="off"
          className={`p-3 internalNotes-body ${
            loadingBtn ? 'processing-file' : ''
          }`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            title={`${_id ? 'Edita' : 'Agenda'} la solicitud de traspaso`}
            errors={errors}
            btnSubmitNoEnter={handleSubmit(onSubmit)}
            btnLoading={loadingBtn}
            btnSubmitNoEnterTxtSave={'Enviar Solicitud'}
            btnSubmitNoEnterTxtUpdate={'Finalizar Solicitud'}
            btnSecondFunction={handleCancel}
            btnSecondFunctionTitle={textBtnBack}
            content={
              <>
                <div className="row">
                  <TransferRequestForm
                    _id={_id}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                  />

                  <ProductsFound control={control} getValues={getValues} />
                </div>

                <div className="col-12">
                  <ProductsToTransfer
                    _id={_id}
                    control={control}
                    mutationGetTransferLines={mutationGetTransferLines}
                    loadingTransferLines={loadingTransferLines}
                  />
                </div>
              </>
            }
          />
        </form>
      </FormProvider>

      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#addProductToTransfer"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      ></span>
      <ModalContent
        idModal="addProductToTransfer"
        title={`${
          dataItem?.quantity || dataItem?.quantity == 0 ? 'Editar' : 'Agregar'
        } producto`}
        onClose={() => {
          setResetTransferQuantityForm(true)
        }}
        content={
          <TransferQuantitiesForm
            dataItemQuantity={dataItem}
            handleQuantity={handleQuantity}
            _id={_id}
            sourceWarehouseSap={
              warehouseOriginSelected
                ? warehouseOriginSelected
                : getValues('source_warehouse_sap')
            }
            refModal={refModal}
            refetch={refetchMutationLines}
            dataRequest={{
              doc_date: '',
              from_whs_code: getValues('source_warehouse_sap'),
              to_whs_code: getValues('destination_warehouse_sap') ?? null,
              comments: getValues('comments_sap'),
            }}
          />
        }
      />
    </>
  )
}

export default AddTransferRequest
