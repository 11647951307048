import DataTable from 'react-data-table-component'

const History =({
    history = [],
    title = ""
})=>{

    const columns = [
        {
            name: "Fecha",
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: "Almacen",
            selector: (row) => row.warehouse,
            sortable: true,
        },
        {
            name: "Cantidad",
            selector: (row) => row.quantity,
            sortable: true,
            right: true
        },
    ]

    console.log("history: ",history)

    return(
        <div className="row">

            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">{title}</h3>
                    </div>
                    <div className="card-body col-12">
                        
                        <DataTable
                            columns={columns}
                            data={history}
                            fixedHeader
                            highlightOnHover

                            pagination
                            paginationServer={false}
                            paginationTotalRows={history.length}
                            noDataComponent="No se encontrarón datos"
                        />

                    </div>
                </div>
            </div>

        </div>
    )
}

export default History