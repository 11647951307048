import { useMutation, useQuery } from '@apollo/client'
import { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  GET_ALL_LEVELS_FOR_PROVIDERS_BUSINESS_PARTNER_ASSIGNATION,
  PROVIDERS_BUSINESS_PARTNER_LEVEL,
  UPDATE_CREATE_BUSINESS_PARTNER_LEVEL_ASSIGNATION,
} from '../../../graphql/Catalog/SAP/ProvidersBusinessPartner'
import ModalContent from '../../Global/ModalContent'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

const BusinessPartnerLevels = () => {
  const [packageSelected, setPackageSelected] = useState(0)
  const [updatePackageSelection, setUpdatePackageSelection] = useState(0)
  const [handleModalContent, setHandleModalContent] = useState(null)
  const [levels, setLevels] = useState([])
  const { id: _id } = useParams()
  const refModal = useRef()

  //get business partner level by user_id
  const {
    data: businessPartnerLevelData,
    error: businessPartnerLevelError,
    loading: businessPartnerLevelLoading,
  } = useQuery(PROVIDERS_BUSINESS_PARTNER_LEVEL, {
    variables: {
      getBusinessPartnerLevelByUserIdId: +_id,
    },
  })

  //get all business partner packages levels
  const {
    data: businessPartnerLevelsData,
    error: businessPartnerLevelsError,
    loading: businessPartnerLevelsLoading,
  } = useQuery(GET_ALL_LEVELS_FOR_PROVIDERS_BUSINESS_PARTNER_ASSIGNATION)

  const [updateCreateBusinessPartnerLevelAssignation] = useMutation(
    UPDATE_CREATE_BUSINESS_PARTNER_LEVEL_ASSIGNATION
  )

  useEffect(() => {
    if (!businessPartnerLevelLoading && !businessPartnerLevelsLoading) {
      if (businessPartnerLevelError) {
        return toastSweetAlert({
          mode: 'error',
          message: businessPartnerLevelError.message,
        })
      }
      if (businessPartnerLevelsError) {
        return toastSweetAlert({
          mode: 'error',
          message: businessPartnerLevelsError.message,
        })
      }

      setHandleModalContent(
        businessPartnerLevelData?.getBusinessPartnerLevelByUserId
      )
      setPackageSelected(
        businessPartnerLevelData?.getBusinessPartnerLevelByUserId
          ?.level_types_id
      )
      setLevels(businessPartnerLevelsData.getAllBusinessPartnerLevels)
    }
  }, [
    businessPartnerLevelData,
    businessPartnerLevelError,
    businessPartnerLevelLoading,
    _id,

    businessPartnerLevelsData,
    businessPartnerLevelsError,
    businessPartnerLevelsLoading,
  ])

  const handlerSelectedPackage = async (id) => {
    setUpdatePackageSelection(id)
    refModal.current.click()
  }
  const handleCancelModalButton = () => {
    refModal.current.click()
  }

  const handleButton = async () => {
    try {
      const { data } = await updateCreateBusinessPartnerLevelAssignation({
        variables: {
          userId: +_id,
          levelTypesId: +updatePackageSelection,
        },
        refetchQueries: [
          {
            query: PROVIDERS_BUSINESS_PARTNER_LEVEL,
            variables: {
              getBusinessPartnerLevelByUserIdId: +_id,
            },
          },
        ],
      })
      if (data.createUpdateBusinessPartnerLevelAssination) {
        refModal.current.click()
        setPackageSelected(+updatePackageSelection)
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <div className="content-header ">
        <div className="container-fluid ">
          <h1 className="font-weight-bold text-center text-xl-left">
            Paquete Actual
          </h1>

          <div className="row justify-content-xl-between mt-3  justify-content-center   ">
            {levels.map((item) => (
              <div
                key={item.id}
                className={`mx-md-3 mx-xl-0 my-xl-3 ${
                  packageSelected == item.id ? 'business-partner-selected' : ''
                }`}
              >
                <div
                  className="col-sm-12 my-3 business-partner-level-box col-xl-2 my-xl-0 "
                  onClick={() => handlerSelectedPackage(item.id)}
                >
                  <div className=" business-partner-level-box">
                    <p className="business-partner-title-box">{item.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CONFIRM MODAL */}
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#levelAssignation"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="levelAssignation"
        title={`${
          handleModalContent ? 'Actualizar Suscripción' : 'Crear Suscripción'
        }`}
        size="large"
        content={
          <>
            <p className="mt-3">
              {' '}
              {handleModalContent
                ? ' ¿Deseas actualizar tu suscripción?'
                : '¿Deseas crear una suscripción ?'}
            </p>

            <div className="d-flex justify-content-end mt-5">
              <button
                className="btn StandarModalCancelButtonLogOut mr-4"
                onClick={handleCancelModalButton}
              >
                Cancelar
              </button>
              <button
                className="btn StandarModalConfirmButtonLogOut"
                onClick={handleButton}
              >
                {handleModalContent ? 'Actualizar' : 'Crear'}
              </button>
            </div>
          </>
        }
      />
    </>
  )
}

export default BusinessPartnerLevels
