import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import Box from '../../../../Global/Box'
import InputController from '../../../../Global/InputController'
import LayoutTable from '../../../../Global/LayoutTable'
import { GET_ONE_NOTE } from '../../../../../graphql/Catalog/Notes/notes'
import OrderCol from '../../../../Global/OrderCol'
import {
  GET_ALL_BANKS_FINANCES_COUNTER_CHARGES,
  GET_ONE_BANK_FINANCES_COUNTER_CHARGE,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/banksfinancescountercharges'
import {
  GENERATE_PAYMENTES_FINANCES_AND_UPDATE_NOTES,
  GET_COUNT_PAYMENTS_FINANCES_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/FinancesCounterCharges/generatepaymentsfinancescountercharges'
import moment from 'moment'
import { toOrdinal } from '../../../../Helpers/ConvertNumbersToOrdinals'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'
import { useHistory } from 'react-router-dom'
const GeneratePaymentsFinances = ({
  dataToGeneratePayments,
  dataHistoryPayments,
  refModal,
  refetch,
  paymentsContability,
  closeModal,
  setCloseModal,
}) => {
  const history = useHistory()
  const [dataToTable, setDataToTable] = useState([])
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [options, setOptions] = useState([])
  const [infoBank, setInfoBank] = useState(null)
  const [valueAmount, setValueAmount] = useState(null)
  const [valueIdBank, setValueIdBank] = useState(null)
  const [dateInfo, setDateInfo] = useState(null)
  const [valueConsecutive, setValueConsecutive] = useState(null)
  const [invoicePayment, setInvoicePayment] = useState(null)
  const [sumTotal, setSumTotal] = useState(0)
  const [sumTotalPayments, setSumTotalPayments] = useState(0)
  const [chkBoxPartialPayment, setChkBoxPartialPayment] = useState(false)
  const [chkBoxCloseWithAdjustment, setChkBoxCloseWithAdjustment] =
    useState(false)
  const [getOneNote] = useLazyQuery(GET_ONE_NOTE)
  const {
    loading: loadingOneBank,
    data: dataOneBank,
    error: errorOneBank,
  } = useQuery(GET_ONE_BANK_FINANCES_COUNTER_CHARGE, {
    variables: {
      getOneBankFinancesCounterChargesId: parseInt(valueIdBank),
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingCountForConsecutive,
    data: dataCountForConsecutive,
    error: errorCountForConsecutive,
  } = useQuery(GET_COUNT_PAYMENTS_FINANCES_COUNTER_CHARGES, {
    variables: {
      idBank: parseInt(valueIdBank),
      nomenclatureDate: dateInfo,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingBank,
    data: dataBank,
    error: errorBank,
  } = useQuery(GET_ALL_BANKS_FINANCES_COUNTER_CHARGES, {
    fetchPolicy: 'no-cache',
  })

  const [generatePaymentsFinancesAndUpdateNotes] = useMutation(
    GENERATE_PAYMENTES_FINANCES_AND_UPDATE_NOTES
  )

  const validationSchema = yup.object().shape({
    bank: yup.string().required('Este campo es obligatorio'),
    amount_payment: yup.string().required('Este campo es obligatorio'),
    date_payment: yup.date().typeError('Este campo es obligatorio'),
    // comments: yup.string().required('Este campo es obligatorio'),
    // checkBoxGroup: yup.object().shape(
    //   {
    //     close_with_adjustment: yup.boolean().when('partial_payment', {
    //       is: (partial_payment) => partial_payment === true,
    //       then: yup.boolean().oneOf([true], 'Este campo es obligatorio'),
    //     }),
    //     partial_payment: yup.boolean().when('close_with_adjustment', {
    //       is: (close_with_adjustment) => close_with_adjustment === true,
    //       then: yup.boolean().oneOf([true], 'Este campo es obligatorio'),
    //     }),
    //   },
    //   [
    //     ['close_with_adjustment', 'partial_payment'],
    //     ['partial_payment', 'close_with_adjustment'],
    //   ]
    // ),
  })

  const {
    register,
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'consecutives',
  })

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      signDisplay: 'negative',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const formattedNumber = (number) => {
    let cleanNumber = number
    let numberFormat = new Intl.NumberFormat('es-MX', {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cleanNumber)
    return numberFormat === '0' ? '' : numberFormat
  }

  useEffect(() => {
    if (!loadingBank) {
      if (errorBank)
        return toastSweetAlert({
          mode: 'error',
          message: errorBank?.message,
        })

      const banks = dataBank?.getAllBanksFinancesCounterCharges?.rows?.map(
        (item) => {
          return {
            value: item?.id,
            label: item?.bank_name + ' - ' + item?.bank_nomenclature,
          }
        }
      )
      setOptions(banks)
    }
  }, [loadingBank, dataBank, errorBank])

  useEffect(() => {
    if (dataToGeneratePayments) {
      let list = []

      dataToGeneratePayments?.map((item) => {
        return list.push({
          id: item?.id,
          consecutivo: item?.consecutive_number,
          Monto: formmatterCurrency(item?.amount_note),
        })
      })
      setDataToTable(list)
      sumTotalOnArray(dataToGeneratePayments, dataHistoryPayments)
      setValue('date_payment', moment().format('YYYY-MM-DD'))
      handleChangeDate(moment().format('YYYY-MM-DD'))
      setValue('bank', '')
    }
  }, [dataToGeneratePayments, dataHistoryPayments])

  // useEffect(() => {
  //   if (banks) {
  //     const banksOptions = banks?.map((item) => {
  //       return {
  //         value: item?.id,
  //         label: item?.name,
  //       }
  //     })
  //     setOptions(banksOptions)
  //   }
  // }, [dataToGeneratePayments])

  useEffect(() => {
    if (valueAmount) {
      setLoadingBtn(true)
      handleAmountPayment(valueAmount)
    }
  }, [valueAmount])

  useEffect(() => {
    if (dataOneBank !== null && dataOneBank !== undefined) {
      if (!loadingOneBank) {
        // setLoadingBtn(true)
        if (errorOneBank)
          return toastSweetAlert(
            {
              mode: 'error',
              message: errorOneBank?.message,
            }
            // setLoadingBtn(false)
          )

        setInfoBank(dataOneBank?.getOneBankFinancesCounterCharges)
        // setLoadingBtn(false)
      }
    }
  }, [dataOneBank, loadingOneBank, errorOneBank])

  useEffect(() => {
    if (!loadingCountForConsecutive) {
      // setLoadingBtn(true)
      if (errorCountForConsecutive)
        return toastSweetAlert(
          {
            mode: 'error',
            message: errorCountForConsecutive?.message,
          }
          // setLoadingBtn(false)
        )

      const dataCount =
        dataCountForConsecutive?.getCountForConsecutiveNumberForPaymentsFinances
          ?.rows

      let count = 0
      let consecutiveNumber = null
      for (let data of dataCount) {
        if (consecutiveNumber !== data?.consecutive_number_of_payments) {
          count++
        }
        consecutiveNumber = data?.consecutive_number_of_payments
      }

      // setValueConsecutive(
      //   dataCountForConsecutive?.getCountForConsecutiveNumberForPaymentsFinances
      //     ?.count + 1
      // )
      setValueConsecutive(count + 1)
      // setLoadingBtn(false)
    }
    // setLoadingBtn(false)
  }, [
    loadingCountForConsecutive,
    dataCountForConsecutive,
    errorCountForConsecutive,
  ])

  useEffect(async () => {
    if (infoBank) {
      setLoadingBtn(true)
      handleDelay()
    }
  }, [infoBank])

  const handleChangeDate = (date) => {
    const dateFormated = moment(date, 'YYYY-MM-DD').format('MMYY')
    setDateInfo(dateFormated)
    setLoadingBtn(true)
    handleDelay()
  }

  // useEffect(() => {
  //   if (chkBoxCloseWithAdjustment || !chkBoxCloseWithAdjustment) {
  //     handleChkBoxCloseWithAdjustment(chkBoxCloseWithAdjustment)
  //   }
  // }, [chkBoxCloseWithAdjustment])

  const handleSelectBank = (value) => {
    setLoadingBtn(true)
    const id = value?.value
    handleDelay()
    // const { data: dataBank } = getOneBank({
    //   variables: {
    //     getOneBankFinancesCounterChargesId: parseInt(id),
    //   },
    //   fetchPolicy: 'no-cache',
    // })

    // console.log('dataBank', dataBank)
    // setInfoBank(dataBank?.getOneBankFinancesCounterCharges)
    // const id = value?.value
    // const bank = banks?.find((item) => item?.id === id)
    // setInfoBank(bank)
  }

  // const handleChkBoxCloseWithAdjustment = (value) => {
  //   console.log('e.target.checked Close', value)
  //   if (value) {
  //     setChkBoxCloseWithAdjustment(value)
  //     setChkBoxPartialPayment(false)
  //     let sum = 0
  //     for (let retention of dataToGeneratePayments) {
  //       console.log('retention', retention?.retention_note)
  //       console.log('sumTotal', sumTotal)
  //       sum += parseFloat(retention?.retention_note)
  //       console.log('sum antes de restar', sum)
  //     }
  //     sum = sumTotal - sum
  //     console.log('sum despues de restar', sum)
  //     setValue('amount_payment', sum.toFixed(2))
  //   }

  //   if (!value) {
  //     setChkBoxCloseWithAdjustment(false)
  //     setValue('amount_payment', '')
  //   }
  // }

  // const handleChkBoxPartialPayment = (value) => {
  //   console.log('e.target.checked Partial', value)
  //   setChkBoxPartialPayment(value)
  //   setChkBoxCloseWithAdjustment(false)
  //   if (value) {
  //     handleChkBoxCloseWithAdjustment(false)
  //   }
  // }

  // console.log('infoBank', infoBank)

  const handleDelay = () => {
    setTimeout(() => {
      // setInvoicePayment(
      //   `${infoBank?.bank_nomenclature}${valueConsecutive}`
      // )
      setLoadingBtn(false)
    }, 600)
  }

  useEffect(() => {
    if (closeModal) {
      handleCloseModal()
    }
  }, [closeModal])

  const handleCloseModal = (e) => {
    dataToGeneratePayments = []
    reset({
      amount_payment: '',
      bank: '',
      date_payment: '',
    })
    setChkBoxCloseWithAdjustment(false)
    setChkBoxPartialPayment(false)
    setInfoBank(null)
    setValueIdBank(null)
    setValueAmount(null)
    setValue('bank', '')
  }

  const sumTotalOnArray = (array, payments) => {
    let sum = 0
    let sumPayments = 0
    let count = 0
    for (let amount of array) {
      if (!paymentsContability) {
        sum += parseFloat(amount?.amount_programmed_to_pay_note)
        // sum = sum - parseFloat(amount?.pay_note)
      }

      if (paymentsContability) {
        sum += parseFloat(amount?.amount_note)
        // sum = sum - parseFloat(amount?.pay_note)
      }
      count++
    }

    if (payments.length > 0) {
      for (let amount of payments) {
        sumPayments += parseFloat(amount?.payment_amount)
      }
    }
    setSumTotal(sum.toFixed(2))
    setSumTotalPayments(sumPayments.toFixed(2))
    if (paymentsContability) {
      setValue('amount_payment', sum.toFixed(2))
      setChkBoxCloseWithAdjustment(true)
    }

    if (!paymentsContability && count > 1) {
      setValue('amount_payment', formattedNumber(sum.toFixed(2)))
      setChkBoxCloseWithAdjustment(true)
    }

    return sum.toFixed(2)
  }

  const handleAmountPayment = async (value) => {
    if (value === '') return

    value = value.includes(',') ? value?.replace(/[,]/g, '') : value

    const valueConverted = Number(value)
    // const sumTotalNote = sumTotalOnArray(dataToGeneratePayments)

    if (isNaN(valueConverted)) {
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'Cantidad ingresada es inválida',
        },
        setValue('amount_payment', ''),
        setValueAmount(null),
        setLoadingBtn(false)
      )
    }

    if (valueConverted <= 0) {
      return toastSweetAlertNotifications(
        {
          mode: 'error',
          message: 'El monto no puede ser 0 o negativo',
        },
        setValue('amount_payment', ''),
        setValueAmount(null),
        setLoadingBtn(false)
      )
    }

    // if (parseFloat(valueConverted) > parseFloat(sumTotal)) {
    //   return toastSweetAlertNotifications(
    //     {
    //       mode: 'error',
    //       message: 'El monto no puede ser mayor al total',
    //     },
    //     setValue('amount_payment', ''),
    //     setValueAmount(null)
    //   )
    // }
    const getFormatedNumber = formattedNumber(valueConverted.toFixed(2))
    setValue('amount_payment', getFormatedNumber)
    handleDelay()
  }

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)
      const noteData = dataToGeneratePayments?.map((item) => {
        return {
          id: item?.id,
          pay_note: parseFloat(item?.amount_note),
        }
      })

      const cleanPaymentAmount = data?.amount_payment?.replace(/[,]/g, '')

      await generatePaymentsFinancesAndUpdateNotes({
        variables: {
          noteInput: noteData,
          inputPayments: {
            id_bank: parseInt(data?.bank),
            consecutive_number_of_payments: `${
              infoBank?.bank_nomenclature
            } ${dateInfo} ${
              parseFloat(infoBank?.bank_invoice_number) +
              parseFloat(valueConsecutive)
            }`,
            payment_amount: cleanPaymentAmount,
            payment_date: moment(data?.date_payment).format('YYYY-MM-DD'),
            nomenclature_date: dateInfo,
            nomenclature_bank: infoBank?.bank_nomenclature,
            consecutive_number: `${
              parseFloat(infoBank?.bank_invoice_number) +
              parseFloat(valueConsecutive)
            }`,
            comments: data?.comments,
          },
          partialPayment: chkBoxCloseWithAdjustment ? false : true,
          closeWithAdjustment: chkBoxCloseWithAdjustment,
          paymentContability: paymentsContability,
        },
      })

      dataToGeneratePayments = []
      setValue('bank', '')
      reset({
        amount_payment: '',
        bank: '',
        date_payment: '',
      })
      setChkBoxCloseWithAdjustment(false)
      setChkBoxPartialPayment(false)
      setInfoBank(null)
      setValueIdBank(null)
      setValueAmount(null)

      if (refetch) await refetch()

      return (
        refModal.current?.click(),
        setLoadingBtn(false),
        toastSweetAlert(
          {
            mode: 'ok',
            message: 'Pago generado correctamente',
          },
          history.push('/financescountercharges/financescountercharges')
        )
      )
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <div className={`${loadingBtn ? 'processing-file' : ''}`}>
      <FormProvider {...methods}>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            withCard={false}
            errors={errors}
            btnSubmit={true}
            btnSubmitText="Pagar"
            btnState={loadingBtn}
            btnSecondFunction={(e) => handleCloseModal(e)}
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={true}
            content={
              <>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Consecutivo</th>
                        <th>Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataToGeneratePayments?.map((item, index) => (
                        <tr>
                          <td style={{ width: '500px' }}>
                            {item?.consecutive_number}
                          </td>
                          <td>
                            {paymentsContability
                              ? formmatterCurrency(item?.amount_note)
                              : formmatterCurrency(
                                  item?.amount_programmed_to_pay_note
                                )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <hr />
                <div
                  className="col-12 d-flex flex-column justify-content-end align-items-end"
                  style={{ marginBottom: '20px' }}
                >
                  {/* <div className="col-lg-3 " style={{ maringLeft: '100px' }}>
                    <OrderCol
                      title="Total:"
                      content={formmatterCurrency(sumTotal)}
                      mediumColSize="4"
                    />
                  </div> */}
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '190px' }}>
                          Total de contrarecibos:
                        </th>
                        <th style={{ width: '50px' }}>
                          {formmatterCurrency(sumTotal)}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <hr />
                <div className="col-12">
                  <div className="row">
                    {/* <div className="col-lg-12"> */}
                    {dataHistoryPayments?.map((item, index) => (
                      <div key={item?.id} className="col-md-12 col-lg-12">
                        {/* <h4 className="title-display-info-payments-finances">
                          {toOrdinal(index + 1).toUpperCase() + ' PAGO'}
                        </h4> */}
                        <div>
                          <table>
                            <thead>
                              <tr style={{ marginLeft: '100px' }}>
                                {/* <th style={{ width: '25%' }}>Folio</th>
                                <th style={{ width: '25%' }}>Banco</th> */}
                                <th style={{ width: '75%' }}>N°. de Pago</th>
                                <th style={{ width: '50%' }}>Monto Pagado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* <td>{item?.consecutive_number_of_payments}</td>
                                <td>
                                  {item?.paymentsFinancesToBanks?.bank_name}
                                </td> */}
                                <td>
                                  {toOrdinal(index + 1).toUpperCase() + ' PAGO'}
                                </td>
                                <td>
                                  {formmatterCurrency(item?.payment_amount)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr />

                {sumTotalPayments !== 0 && (
                  <>
                    <div
                      className="col-12 d-flex flex-column justify-content-end align-items-end"
                      style={{ marginBottom: '20px' }}
                    >
                      {/* <div className="col-lg-3 " style={{ maringLeft: '100px' }}>
                    <OrderCol
                      title="Total:"
                      content={formmatterCurrency(sumTotal)}
                      mediumColSize="4"
                    />
                  </div> */}
                      <table>
                        <thead>
                          <tr>
                            <th style={{ width: '150px' }}>Total de pagos:</th>
                            <th style={{ width: '50px' }}>
                              {formmatterCurrency(sumTotalPayments)}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <hr />

                    <br />
                    <br />
                  </>
                )}
                <div className="col-12 d-flex flex-column justify-content-around">
                  {/* <div className="row"> */}
                  <div className="col-md-12 col-lg-12">
                    <OrderCol
                      title="Folio:"
                      content={
                        infoBank
                          ? `${`${infoBank?.bank_nomenclature} ${dateInfo} ${
                              parseFloat(infoBank?.bank_invoice_number) +
                              parseFloat(valueConsecutive)
                            }`}`
                          : '---'
                      }
                      mediumColSize="12"
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <InputController
                      name="bank"
                      label="Bancos"
                      control={control}
                      inputType="choosen"
                      options={options}
                      changeAction={(e) => setValueIdBank(e?.value)}
                      debounceTime={400}
                      placeHolderChoosen="Elija un banco"
                    />
                  </div>
                  <div className="col-lg-12">
                    <InputController
                      name="date_payment"
                      label="Fecha"
                      type="date"
                      control={control}
                      debounceTime={400}
                      changeAction={(e) => handleChangeDate(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputController
                      name="amount_payment"
                      label="Monto"
                      control={control}
                      changeAction={(e) => setValueAmount(e.target.value)}
                      debounceTime={900}
                      disabled={paymentsContability ? true : false}
                    />
                  </div>
                  <div>
                    <InputController
                      name="comments"
                      label="Comentarios"
                      control={control}
                      inputType="textarea"
                    />
                  </div>
                  {!paymentsContability && (
                    <div
                      className="col-12 d-flex"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="col-lg-6 form-check">
                        <input
                          id="close_with_adjustment"
                          label="Cerrar con ajuste"
                          type="checkbox"
                          name="close_with_adjustment"
                          checked={chkBoxCloseWithAdjustment}
                          onChange={(e) =>
                            setChkBoxCloseWithAdjustment(e.target.checked)
                          }
                          // {...register('checkboxGroup.close_with_adjustment')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="close_with_adjustment"
                        >
                          &nbsp; Cerrar con ajuste
                        </label>
                      </div>
                      {/* <div className="col-lg-6 form-check">
                      <input
                        id="partial_payment"
                        label="Pago parcial"
                        type="radio"
                        name="close_with_adjustment"
                        checked={chkBoxPartialPayment}
                        onChange={(e) =>
                          handleChkBoxPartialPayment(e.target.checked)
                        }
                        // {...register('checkboxGroup.partial_payment')}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="partial_payment"
                      >
                        &nbsp; Pago parcial
                      </label>
                    </div> */}
                    </div>
                  )}
                </div>
                <hr />
              </>
            }
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default GeneratePaymentsFinances
