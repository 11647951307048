//Customer components
import Box from '../../../Global/Box'
import OrderCol from '../../../Global/OrderCol'
import PercentageBar from '../../../Global/PercentageBar/PercentageBar'

const OperationDashboardsFirstSection = ({
  data = null,
  formmatNumber = () => {},
}) => {

  return (
    <div className="row mb-3">
      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 alignCards mb-3">
        <Box
          title="Almacen"
          whiteHeader={false}
          content={
            <>
              <div className="row">
                <div className="col-12 mb-3">
                  <OrderCol
                    mediumColSize={12}
                    content={
                      <>
                        <p style={{ fontWeight: 'bold' }}>
                          {`Capacidad: ${
                            data?.usedStorageCapacity
                              ? formmatNumber(data.usedStorageCapacity)
                              : 0
                          }`}
                        </p>

                        <PercentageBar
                          percentage={
                            data?.percentageWarehouse
                              ? data.percentageWarehouse
                              : 0
                          }
                        />
                        <br />
                        <p style={{ fontWeight: 'bold' }}>
                          Capacidad de almacenamiento utilizada
                        </p>
                      </>
                    }
                    footer={
                      <p>
                        +
                        {data?.warehouseUnitsSevenDaysAgo
                          ? formmatNumber(data.warehouseUnitsSevenDaysAgo)
                          : 0}{' '}
                        en los últimos 7 días
                        <br />
                        Capacidad total:{' '}
                        {data?.warehouseTotalUnits
                          ? formmatNumber(data.warehouseTotalUnits)
                          : 0}{' '}
                        unidades
                      </p>
                    }
                    switchSize
                    noPaddingParagraph
                  />
                  <br />

                  <br />
                </div>

                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                      <OrderCol
                        mediumColSize={12}
                        title={
                          data?.unitsReceived
                            ? formmatNumber(data.unitsReceived)
                            : 0
                        }
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Unidades recibidas hoy
                          </p>
                        }
                        footer={
                          <p>
                            +
                            {data?.unitsReceivedSevenDaysAgo
                              ? formmatNumber(data.unitsReceivedSevenDaysAgo)
                              : 0}{' '}
                            en los últimos 7 días
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                      <OrderCol
                        mediumColSize={12}
                        title={
                          data?.assortedUnits
                            ? formmatNumber(data.assortedUnits)
                            : 0
                        }
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Unidades surtidas hoy
                          </p>
                        }
                        footer={
                          <p>
                            +
                            {data?.assortedUnitsSevenDaysAgo
                              ? formmatNumber(data.assortedUnitsSevenDaysAgo)
                              : 0}{' '}
                            en los últimos 7 días
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                      <OrderCol
                        mediumColSize={12}
                        title={
                          data?.returnedUnits
                            ? formmatNumber(data.returnedUnits)
                            : 0
                        }
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Unidades devueltas hoy
                          </p>
                        }
                        footer={
                          <p>
                            +
                            {data?.returnedUnitsSevenDaysAgo
                              ? formmatNumber(data.returnedUnitsSevenDaysAgo)
                              : 0}{' '}
                            en los últimos 7 días
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div> */}

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                      <OrderCol
                        mediumColSize={12}
                        title={
                          data?.unitsDecrease
                            ? formmatNumber(data.unitsDecrease)
                            : 0
                        }
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Unidades en mermas
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div className="row alignCards">
          <div className="col-12 alignCardsFix">
            <Box
              title="Estatus de las ordenes"
              whiteHeader={false}
              content={
                <>
                  <div className="row mb-3">
                    <div className="col-6">
                      <OrderCol
                        mediumColSize={12}
                        title={`${
                          data?.ordersToFill
                            ? formmatNumber(data.ordersToFill)
                            : 0
                        }`}
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Ordenes por surtir
                          </p>
                        }
                        footer={
                          <p>
                            +
                            {data?.ordersToFillSevenDaysAgo
                              ? formmatNumber(data.ordersToFillSevenDaysAgo)
                              : 0}{' '}
                            surtidas en los últimos 7 días
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div>

                    <div className="col-6">
                      <OrderCol
                        mediumColSize={12}
                        title={`${
                          data?.ordersStowing
                            ? formmatNumber(data.ordersStowing)
                            : 0
                        }`}
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Ordenes en stowing
                          </p>
                        }
                        footer={
                          <p>
                            {data?.ordersStowingTimePerUnit
                              ? data.ordersStowingTimePerUnit
                              : 0}{' '}
                            tiempo promedio por unidad
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <OrderCol
                        mediumColSize={12}
                        title={`${
                          data?.ordersPicking
                            ? formmatNumber(data.ordersPicking)
                            : 0
                        }`}
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Ordenes en picking
                          </p>
                        }
                        footer={
                          <p>
                            {data?.ordersPickingTimePerUnit
                              ? data.ordersPickingTimePerUnit
                              : 0}{' '}
                            tiempo promedio por unidad
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div>

                    <div className="col-6">
                      <OrderCol
                        mediumColSize={12}
                        title={`${
                          data?.ordersVerification
                            ? formmatNumber(data.ordersVerification)
                            : 0
                        }`}
                        content={
                          <p style={{ fontWeight: 'bold' }}>
                            Ordenes en verificacion
                          </p>
                        }
                        footer={
                          <p>
                            {data?.ordersVerificationTimePerUnit
                              ? data.ordersVerificationTimePerUnit
                              : 0}{' '}
                            tiempo promedio por unidad
                          </p>
                        }
                        switchSize
                        noPaddingParagraph
                      />
                    </div>
                  </div>
                </>
              }
            />
          </div>

          {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <Box
              title="Porcentaje de utilización de puertas"
              whiteHeader
              content={
                <>
                  <div className="col-12">
                    <PercentageBar
                      percentage={
                        data?.doorsPercentage ? data.doorsPercentage : 0
                      }
                      displayInBlockBarBox
                    />
                  </div>
                  <OrderCol
                    mediumColSize={12}
                    content={
                      <p>
                        {data?.doorsPercentageOneMonthAgo
                          ? formmatNumber(data.doorsPercentageOneMonthAgo)
                          : 0}
                        % en el último mes
                      </p>
                    }
                    switchSize
                    noPaddingParagraph
                  />
                </>
              }
            />
          </div> */}
        </div>

        <div className="row mt-3">

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 ">
            <Box
              title="Órdenes de compra por recibir"
              whiteHeader={false}
              content={
                <OrderCol
                  mediumColSize={12}
                  title={
                    data?.ordersToReceive
                      ? formmatNumber(data.ordersToReceive)
                      : 0
                  }
                  content={
                    <p>
                      +
                      {data?.ordersToReceiveSevenDaysAgo
                        ? formmatNumber(data.ordersToReceiveSevenDaysAgo)
                        : 0}{' '}
                      en los últimos 7 días
                    </p>
                  }
                  switchSize
                  noPaddingParagraph
                />
              }
            />
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <Box
              title="Resumen de ubicaciones"
              whiteHeader={false}
              content={
                <div className="row ubicationsBoxWarehouseDashboard">
                  <OrderCol
                    mediumColSize={12}
                    title={`${
                      data?.emptyLocations
                        ? formmatNumber(data.emptyLocations)
                        : 0
                    }`}
                    content={`Ubicaciones vacías`}
                    switchSize
                    customColSize={6}
                  />
                  <OrderCol
                    mediumColSize={12}
                    title={`${
                      data?.locatedLocations
                        ? formmatNumber(data.locatedLocations)
                        : 0
                    }`}
                    content={`Ubicaciones ocupadas`}
                    switchSize
                    customColSize={6}
                  />
                </div>
              }
            />
          </div>

        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <Box
                  title="TFI"
                  whiteHeader={false}
                  content={
                    <>
                      <div className="col-12">
                        <p style={{ fontWeight: 'bold', marginBottom: '0px' }}>
                          Ultimo TFI
                        </p>
                      </div>
                      <OrderCol
                        mediumColSize={12}
                        title={`${data?.lastDateTFI ? data.lastDateTFI : ''}`}
                        onlyTitle
                        switchSize
                        // noPaddingParagraph
                      />

                      <div className="col-12 mt-3">
                        <p style={{ fontWeight: 'bold', marginBottom: '0px' }}>
                          Confiabilidad del inventario
                        </p>
                      </div>
                      <div className="col-12">
                        <p>De acuerdo a ultimo TFI</p>
                      </div>

                      <div className="col-12">
                        <PercentageBar
                          percentage={
                            data?.lastPercentageTFI ? data.lastPercentageTFI : 0
                          }
                          smallBar
                        />
                      </div>
                    </>
                  }
                />
              </div>

              {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-12">
                    <Box
                      title="Antigüedad del inventario"
                      whiteHeader
                      content={
                        <div className="col-12 table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th style={{ width: '40%' }}>
                                  No. de unidades
                                </th>
                                <th
                                  className="text-right"
                                  style={{ width: '60%' }}
                                >
                                  Antigüedad
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.antiquityInventory &&
                                data.antiquityInventory.map(
                                  (itemAntiquityInventory, key) => {
                                    return (
                                      <tr key={`itemAntiquityInventory-${key}`}>
                                        <td>
                                          {itemAntiquityInventory?.units
                                            ? formmatNumber(
                                                itemAntiquityInventory.units
                                              )
                                            : 0}
                                        </td>
                                        <td className="text-right">
                                          {itemAntiquityInventory?.range
                                            ? itemAntiquityInventory.range
                                            : ''}
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      }
                    />
                  </div>

                  <div className="col-12">
                    <Box
                      title="Frescura del inventario"
                      whiteHeader
                      content={
                        <div className="col-12 table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th style={{ width: '40%' }}>
                                  No. de unidades
                                </th>
                                <th
                                  className="text-right"
                                  style={{ width: '60%' }}
                                >
                                  Caduca en
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.refreshInventory &&
                                data.refreshInventory.map(
                                  (itemRefreshInventory, key) => {
                                    return (
                                      <tr key={`itemRefreshInventory-${key}`}>
                                        <td>
                                          {itemRefreshInventory?.units
                                            ? formmatNumber(
                                                itemRefreshInventory.units
                                              )
                                            : 0}
                                        </td>
                                        <td className="text-right">
                                          {itemRefreshInventory.range}
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="row alignCards">
              

              {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <Box
                  title="Puntos de re-surtido"
                  whiteHeader
                  content={
                    <>
                      <OrderCol
                        mediumColSize={12}
                        title={`${
                          data?.locationsUnderFromLevel
                            ? formmatNumber(data.locationsUnderFromLevel)
                            : 0
                        }`}
                        content={`Ubicaciones debajo del nivel`}
                        switchSize
                      />
                    </>
                  }
                />
              </div> */}

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
                <Box
                  title="Top 10 SKUS de mayor rotación"
                  whiteHeader={false}
                  content={
                    <div className="col-12 table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: '20%' }}>
                              #
                            </th>
                            <th scope="col" style={{ width: '40%' }}>
                              SKU
                            </th>
                            <th scope="col" style={{ width: '40%' }}>
                              Producto
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.topHigherRotations &&
                            data.topHigherRotations.map(
                              (itemTopHigherRotation, key) => {
                                return (
                                  <tr key={`itemTopHigherRotation-${key}`}>
                                    <th scope="row">
                                      {key + 1 !== 10
                                        ? '0' + (key + 1).toString()
                                        : 10}
                                    </th>
                                    <td>
                                      {itemTopHigherRotation?.sku
                                        ? itemTopHigherRotation.sku
                                        : ''}
                                    </td>
                                    <td>
                                      {itemTopHigherRotation.product
                                        ? itemTopHigherRotation.product
                                        : ''}
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  }
                />
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
                <Box
                  title="Top 10 SKUS de menor rotación"
                  whiteHeader={false}
                  content={
                    <div className="col-12 table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: '20%' }}>
                              #
                            </th>
                            <th scope="col" style={{ width: '40%' }}>
                              SKU
                            </th>
                            <th scope="col" style={{ width: '40%' }}>
                              Producto
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.topLessRotations &&
                            data.topLessRotations.map(
                              (itemTopLessRotation, key) => {
                                return (
                                  <tr key={`itemTopLessRotation-${key}`}>
                                    <th scope="row">
                                      {key + 1 !== 10
                                        ? '0' + (key + 1).toString()
                                        : 10}
                                    </th>
                                    <td>
                                      {itemTopLessRotation?.sku
                                        ? itemTopLessRotation.sku
                                        : ''}
                                    </td>
                                    <td>
                                      {itemTopLessRotation?.product
                                        ? itemTopLessRotation.product
                                        : ''}
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperationDashboardsFirstSection
