import { gql } from '@apollo/client'

export const GET_SAP_LIST_PRICES = gql`
  query GetSapListPrices($limit: Int, $offset: Int) {
    getSapListPrices(limit: $limit, offset: $offset) {
      id
      list_number
      list_name
      base_number
      round_system
    }
  }
`
