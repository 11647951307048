import { gql } from '@apollo/client'

export const GET_TOKEN_INTEGRATION = gql`
  query GetTokenIntegration($name: String!) {
    getTokenIntegration(name: $name) {
      token
      name
      expire
      refresh_token
      createdAt
    }
  }
`

export const GET_LOGS = gql`
  query GetLogIncomingTopics($searchQuery: String, $limit: Int, $offset: Int, $dateSend: Int, $dateReceived: Int) {
    getLogIncomingTopics(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      dateSend: $dateSend
      dateReceived: $dateReceived
    ) {
      rows {
        id
        topic
        jsonTopic
        sent
        attempts
        received
        result
      }
      count
    }
  }
`

export const CREATE_TOKEN_INTEGRATION = gql`
  mutation CreateTokenIntegration($code: String!) {
    createTokenIntegration(code: $code)
  }
`

export const UPDATE_TOKEN_INTEGRATION = gql`
  mutation UpdateTokenIntegration($name: String!, $code: String!) {
    updateTokenIntegration(name: $name, code: $code)
  }
`
