import { gql } from "@apollo/client"

export const GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID = gql`
mutation GetScheduleDocksByScheduleId($scheduleId: Int!) {
    getScheduleDocksByScheduleId(scheduleId: $scheduleId){
      dock {
        id
        name
      }
    }
}
` 

export const GET_SCHEDULE_DOCKS_BY_SCHEDULE_ID_QUERY = gql`
query GetScheduleDocksByScheduleIdQuery($scheduleId: Int!) {
    getScheduleDocksByScheduleIdQuery(scheduleId: $scheduleId){
      dock {
        id
        name
      }
    }
}
` 