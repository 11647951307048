import { gql } from '@apollo/client'

export const GET_INVENTORY_ACTIVITY_FINAL_REPORT = gql`
  query getInventoryActivityFinalReport(
    $scheduleId: Int!
    $offset: Int
    $limit: Int
    $searchQuery: String
  ) {
    getInventoryActivityFinalReport(
      scheduleId: $scheduleId
      offset: $offset
      limit: $limit
      searchQuery: $searchQuery
    ) {
      count
      rows {
        item_code
        InventoryActivityFinalReportSapItems {
          item_code
          item_name
          ItemPrices {
            price
            item_code
          }
        }
        # ScheduleReport {
        #   status_sync
        # }
        closed
        counted
        deviation
        deviation_money
        id
        on_hand
        vintage
        expiration_date
      }
      totalDeviation
      totalQuantityDeviation
    }
  }
`

export const UPDATE_SUITE_ITEM_POSITIONS = gql`
  mutation GenerateItemPositionsSuite($scheduleId: Int!) {
    generateItemPositionsSuite(scheduleId: $scheduleId)
  }
`

export const GET_INVENTORY_ACTIVITY_REPORT_EXCEL = gql`
  mutation GetInventoryActivityReportExcel($scheduleId: Int!) {
    getInventoryActivityReportExcel(scheduleId: $scheduleId) {
      detailed {
        id
        user_id
        inventory_schedule_id
        element_assigned
        count_number
        completed
        status_deviated
        InventoryActivitiesLabelRacks {
          name
          ItemPositionToRackPosition {
            on_hand
          }
        }
        InventoryActivitiesLabelSKU {
          id
          item_name
        }
        InventoryActivitieLabelAccountant {
          full_name
          warehouse_code
          warehouse_name
        }
        InventoryActivitiesLabelsCounts {
          element_found
          vintage
          expiration
          quantity
          count_number
          InventoryActivitiesCountsSapItems {
            item_code
            item_name
          }
          InventoryActivitiesCountsItemPosition {
            on_hand
          }
          InventoryActivitiesCountsRacks {
            name
            ItemPositionToRackPosition {
              on_hand
            }
          }
          inventory_activity_label_id
          item_position_suite
        }
      }
      simple {
        id
        counted
        on_hand
        deviation
        deviation_money
        closed
        item_code
        InventoryActivityFinalReportSapItems {
          item_code
          item_name
        }
      }
    }
  }
`
export const SYNC_TFI_SAP = gql`
  mutation SyncTFISap($reportId: Int!) {
    syncTFISap(reportId: $reportId)
  }
`
