import React, { useEffect, useState } from 'react'
import _logo from '../../../../../Assets/Images/vinos-america-logo.png'
import moment from 'moment'
import PrintSecondTicket from './PrintSecondTicket'

export const PrintFirstTicket = React.forwardRef(
  ({ componentRef, dataNote, secondTicket }) => {
    // const secondTicket = secondTicket
    // console.log(componentRef)
    // console.log(secondTicket)
    const [totalRetention, setTotalRetention] = useState(null)
    const [cosecutiveNumber, setConsecutiveNumber] = useState(null)

    const formmatterCurrency = (number) => {
      let numberFormat = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 4,
      }).format(number)

      return numberFormat
    }

    const handlePrintTicket = (dataNote) => {
      console.log(dataNote)
      let total = 0
      total =
        parseFloat(dataNote.amount_note) - parseFloat(dataNote.retention_note)

      setConsecutiveNumber(dataNote.consecutive_number)
      setTotalRetention(formmatterCurrency(total))
    }

    useEffect(() => {
      if (dataNote) {
        handlePrintTicket(dataNote)
      }
    }, [dataNote])

    const logoPosition = {
      // border: '3px solid black',
    }

    const size = {
      width: '302px',
      height: '1000px',
      margin: '0px',
      padding: '0px',
    }

    const centerImage = {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
      height: '100px',
      width: '330px',
      marginTop: '0px',
    }

    const datePosition = {
      textAlign: 'center',
      marginTop: '5px',
      fontSize: '1em',
    }

    const textCounterCharge = {
      // marginLeft: '80px',
      marginTop: '20px',
      fontSize: '1.3em',
    }

    const textInvoiceCounterCharge = {
      // marginLeft: '80px',
      fontSize: '1.3em',
      fontWeight: 'bolder',
      marginTop: '5px',
    }

    const textPartnerCounterCharge = {
      // marginLeft: '80px',
      marginTop: '5px',
    }

    const textDocumentsCounterCharge = {
      marginTop: '20px',
      textAlign: 'center',
      fontSize: '1.3em',
    }

    const tableStyle = {
      // border: '1px solid black',
      // width: '100%',
      marginLeft: '0px',
      fontSize: '28px',
      marginTop: '20px',
    }

    const wrapTotal = {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      // border: '1px solid black',
      // marginLeft: '100px',
      marginTop: '5px',
    }

    const textTotal = {
      marginRight: '0px',
      textAlign: 'center',
      fontSize: '1.5em',
    }

    const textAmount = {
      textAlign: 'center',
      fontSize: '1.5em',
      marginLeft: '5px',
    }

    const rectangleWrite = {
      border: '3px solid black',
      width: '200%',
      height: '100px',
      marginBottom: '0px',
    }

    const rectangleToPay = {
      border: '3px solid black',
      width: '200%',
      height: '150px',
    }

    const textDeductions = {
      fontSize: '1.3em',
      marginLeft: '5px',
    }

    const textComments = {
      fontSize: '1.3em',
      marginLeft: '5px',
    }

    const textToPay = {
      fontSize: '1.5em',
      textAlign: 'center',
    }

    const wrapInformationBills = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: '0px',
      // border: '1px solid black',
      // alignItems: 'center',
    }

    // const printSecondTicket = {
    //   display: 'block',
    //   pageBreakAfter: 'always',
    //   // marginTop: '1000px',
    // }

    return (
      // <>
      <div ref={componentRef}>
        <div style={logoPosition}>
          <img style={centerImage} src={_logo} alt="logo" />
        </div>
        <div style={datePosition}>
          <p
            style={{
              fontSize: '1.3em',
            }}
          >
            {moment(dataNote?.date_note, 'YYYY-MM-DD h:mm:ss a').format('LLLL')}
          </p>
        </div>
        <div style={textCounterCharge}>
          <p
            style={{
              fontSize: '1.3em',
              fontWeight: 'bold',
            }}
          >
            Contra-Recibo
          </p>
        </div>
        <div style={textInvoiceCounterCharge}>
          <strong>
            <h2>
              <p
                style={{ fontSize: '1.2em' }}
              >{`Folio: ${dataNote?.consecutive_number}`}</p>
            </h2>
          </strong>
        </div>
        <div style={textPartnerCounterCharge}>
          <strong>
            <h2>
              <p style={{ fontSize: '1.2em' }}>{`Recibimos de: ${
                dataNote?.partnerNotes?.provider_name
                  ? dataNote?.partnerNotes?.provider_name
                  : dataNote?.bills[0]?.provider_name_nm
              }`}</p>
            </h2>
          </strong>
        </div>
        <div style={textDocumentsCounterCharge}>
          Los siguientes documentos a revisión:
        </div>
        {/* <div style={tableStyle}> */}
        {/* <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '350px',
                    textAlign: 'center',
                    fontSize: '1.7em',
                  }}
                >
                  Factura
                </th>
                <th
                  style={{
                    width: '450px',
                    textAlign: 'center',
                    fontSize: '1.7em',
                  }}
                >
                  Fecha
                </th>
                <th
                  style={{
                    width: '350px',
                    textAlign: 'center',
                    fontSize: '1.7em',
                  }}
                >
                  Monto
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table> */}
        {/* <div className="col"> */}
        <div style={wrapInformationBills}>
          <p
            style={{
              // marginLeft: '10px',
              fontSize: '1.8em',
              fontWeight: 'bolder',
              textAlign: 'center',
            }}
          >
            Factura
            {dataNote?.bills?.map((item) => (
              <p style={{ fontWeight: 'normal', fontSize: '0.7em' }}>
                {item?.invoice_bill}
              </p>
            ))}
          </p>
          <p
            style={{
              // marginLeft: '10px',
              fontSize: '1.8em',
              fontWeight: 'bolder',
              textAlign: 'center',
            }}
          >
            Fecha
            {dataNote?.bills?.map((item) => (
              <p style={{ fontWeight: 'normal', fontSize: '0.7em' }}>
                {moment(item?.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </p>
            ))}
          </p>
          <p
            style={{
              fontSize: '1.8em',
              // marginLeft: '10px',
              fontWeight: 'bolder',
              textAlign: 'center',
              marginBottom: '0px',
            }}
          >
            Monto
            {dataNote?.bills?.map((item) => (
              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '0.7em',
                  marginBottom: '0px',
                }}
              >
                {formmatterCurrency(item?.amount_bill)}
              </p>
            ))}
          </p>
        </div>
        {/* {dataNote?.bills?.map((item) => (
              <div className="row" key={item?.id}>
                <p
                  style={{
                    marginLeft: '80px',
                    // marginRight: '30px',
                    // textAlign: 'center',
                    textTransform: 'capitalize',
                    fontSize: '1.7em',
                  }}
                >
                  {item?.invoice_bill}
                </p>
                <p
                  style={{
                    // textAlign: 'center',
                    marginLeft: '180px',
                    // textAlign: 'center',
                    textTransform: 'capitalize',
                    fontSize: '1.7em',
                    // marginRight: '20px',
                  }}
                >
                  {moment(item?.date_bill, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </p>
                <p
                  style={{
                    fontSize: '1.7em',
                    marginLeft: '90px',
                    // textAlign: 'center',
                    textTransform: 'capitalize',
                    // alignContent: 'center',
                    // alignItems: 'center',
                  }}
                >
                  {formmatterCurrency(item?.amount_bill)}
                </p>
              </div>
            ))} */}
        {/* </div> */}
        {/* </div> */}
        <div style={wrapTotal}>
          <div style={textTotal}>
            <strong>Total: </strong>
          </div>
          <div style={textAmount}>
            <strong> {formmatterCurrency(dataNote?.amount_note)}</strong>
          </div>
        </div>
        {/* <div className="page-breaker"></div> */}
        <div>
          <div style={textDeductions}>
            <strong>Deducciones:</strong>
          </div>
          <div style={rectangleWrite}></div>
        </div>
        <div>
          <div style={textComments}>
            <strong>Comentarios:</strong>
          </div>
          <div style={rectangleWrite}></div>
        </div>
        <div>
          <div style={textToPay}>
            <strong>A pagar:</strong>
            <div style={rectangleToPay}></div>
          </div>
        </div>
        {/* {secondTicket && <div className="page-breaker"></div>} */}
        {/* <>
            {secondTicket && (
              <div>
                <PrintSecondTicket
                  TotalRetention={totalRetention}
                  consecutiveNumber={cosecutiveNumber}
                />
              </div>
            )}
          </> */}
      </div>
      // {/* </> */}
    )
  }
)

export default PrintFirstTicket
