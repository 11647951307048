import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import InputController from '../../Global/InputController'
import Box from '../../Global/Box'

// apollo
import { FIND_ITEM_BARCODES } from '../../../graphql/Catalog/SAP/sapItemBarcodes'
import { GET_ITEM_MASTER_DATA } from '../../../graphql/Catalog/SAP/sapItems'
import {
  CREATE_DETAIL_RETURN_RECEIPT,
  GET_ALL_RETURN_REASON,
} from '../../../graphql/Documents/Inventories/Returns'

export const ProcessReturn = ({
  _returnDocument,
  _returnContent,
  _setProcess,
  _refetchReturn,
}) => {
  const [showScaner, setShowScaner] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [item, setItem] = useState([])
  const [currentRow, setCurrentRow] = useState([])
  const [hasHarvest, setHasHarvest] = useState(false)
  const [hasExpiration, setHasExpiration] = useState(false)
  const [loading, setLoading] = useState(false)

  const [createDetailReturnsReceipt] = useMutation(CREATE_DETAIL_RETURN_RECEIPT)
  const [getFoundItemByBarCode] = useMutation(FIND_ITEM_BARCODES)
  const [getItemMasterDate] = useLazyQuery(GET_ITEM_MASTER_DATA)
  // List of return reasons
  const [returnReasonList, setReturnReasonList] = useState([])
  const {
    reset,
    methods,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const {
    data: dataReasonList,
    loading: loadingReturnReason,
    error: errorReturnReason,
  } = useQuery(GET_ALL_RETURN_REASON, {
    variables: {
      objectType: _returnDocument.object_type,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!loadingReturnReason && !errorReturnReason) {
      const data = dataReasonList.getAllReasonsForReturn
      let list = []
      data.map((line) => {
        return list.push({
          label: line.name,
          value: line.id,
        })
      })
      setReturnReasonList(list)
    }
  }, [dataReasonList, loadingReturnReason, errorReturnReason])

  const returnToDetails = async () => {
    _setProcess(false)
  }
  const clearData = async () => {
    setShowScaner(true)
    setShowDetails(false)
    setValue('scanerText', '')
    setValue('return_reason', 0)
    setValue('purchase_pack_un', '')
    setValue('boxes', '')
    setValue('beds', '')
    setValue('residueBox', '')
    setValue('residueBottles', '')
    setValue('pallets', '')
    setValue('expDate', '')
    setValue('añada', '')
    await _refetchReturn()
  }
  const calculateQuantity = () => {
    const beds = getValues('beds')
    const boxes = getValues('boxes')
    const purchase_pack_un = getValues('purchase_pack_un')
    if (beds && boxes && purchase_pack_un) {
      const total = purchase_pack_un * boxes * beds
      return setValue('quantity', total)
    }
    setValue('quantity', 0)
  }
  const calculateResidue = () => {
    const beds = getValues('beds')
    const purchase_pack_un = getValues('purchase_pack_un')
    const boxes = getValues('boxes')
    const residueBox = getValues('residueBox')
    const residueBottles = getValues('residueBottles')

    const quantity = purchase_pack_un * boxes * beds
    const residuePack = purchase_pack_un * (residueBox ? residueBox : 0)
    const residueUn = parseInt(residueBottles) ? parseInt(residueBottles) : 0
    // if (beds && boxes && purchase_pack_un && residueBox && residueBottles) {
    const total = quantity + residuePack + residueUn
    return setValue('quantity', total)
    // } else {
    //     calculateQuantity()
    // }
  }
  const onScanData = async () => {
    try {
      setLoading(true)
      const scanner = getValues('scanerText')

      const dataItems = await getFoundItemByBarCode({
        variables: {
          query: scanner,
        },
        fetchPolicy: 'no-cache',
      })

      if (dataItems.data.findSapItemBarcodes.length <= 0) {
        setLoading(false)
        setShowScaner(true)
        setShowDetails(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'No se encontraron coincidencias',
        })
      }

      if (dataItems.data.findSapItemBarcodes.length > 0) {
        const barcodeData = dataItems.data.findSapItemBarcodes[0]

        const resultItem = await getItemMasterDate({
          variables: { itemCode: barcodeData.item_code },
        })
        const item = resultItem.data.getOneSapItem
        if (
          !_returnContent.some((element) => element.item_code == item.item_code)
        ) {
          setLoading(false)
          return toastSweetAlert({
            mode: 'error',
            message: `El SKU ${item.item_name} no se encuentra en articulos por devolver`,
          })
        }
        if (
          !_returnContent.some(
            (element) =>
              element.item_code == item.item_code && element.open_quantity > 0
          )
        ) {
          setLoading(false)
          return toastSweetAlert({
            mode: 'error',
            message: `El SKU ${item.item_name} no tiene partidas abiertas`,
          })
        }
        setLoading(false)
        setShowScaner(false)
        setShowDetails(true)
        setCurrentRow(
          _returnContent.find(
            (element) =>
              element.item_code == item.item_code && element.open_quantity > 0
          )
        )

        setItem(item)
        if (currentRow.quantity >= item.purchase_pack_un) {
          setValue('purchase_pack_un', item.purchase_pack_un)
        }

        setHasHarvest(item.hasHarvest)
        setHasExpiration(item.expires)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setShowScaner(true)
      setShowDetails(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  const onAddRowToReturn = async (event) => {
    try {
      setLoading(true)
      event.preventDefault()
      const reasons = getValues('return_reason')

      if (reasons === '') {
        setLoading(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'Seleccione un motivo de Devolución',
        })
      }

      if (hasExpiration) {
        var expiration = getValues('expDate')
        if (expiration === '') {
          setLoading(false)
          return toastSweetAlert({
            mode: 'error',
            message: `La fecha de caducidad es obligatoria para ${currentRow.item_name}`,
          })
        }
      }

      if (hasHarvest) {
        var harvest = getValues('añada')
        if (harvest === '') {
          setLoading(false)
          return toastSweetAlert({
            mode: 'error',
            message: `La añada es obligatoria para ${currentRow.item_name}`,
          })
        }
      }

      const quantity = getValues('quantity')
      if (quantity == 0) {
        setLoading(false)
        return toastSweetAlert({
          mode: 'error',
          message: `La cantidad no puede ser cero`,
        })
      }
      let input = {
        return_document_id: _returnDocument.id,
        return_row_id: currentRow.id,
        reason_return_id: reasons,
        base_entry: _returnDocument.document_entry,
        base_number: _returnDocument.document_number,
        item_id: item.id,
        quantity: quantity,
        warehouse_code: currentRow.warehouse_code,
        from_warehouse_code: null,
        to_warehouse_code: null,
      }

      const result = await createDetailReturnsReceipt({
        variables: { input },
      })
      await clearData()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  return (
    <div
      className={`mb-12 col-sm-12 col-md-12 ${loading ? 'processing-file' : ''
        }`}
    >
      {showScaner && (
        <Box
          title={`Procesar devolución`}
          content={
            <div className="row">
              <div className="col-12 inptu-manual-content">
                <InputController
                  label=""
                  name="scanerText"
                  type="text"
                  inputType="text"
                  control={control}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      onScanData()
                    }
                  }}
                />
              </div>
              <div className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
                <b className="cardTitle"></b>
                <div className="cardContent">
                  <div className="col-12 actions-buttons-scan pt-5">
                    <span
                      className="btn StandarModalCancelButtonLogOut"
                      onClick={() => returnToDetails()}
                    >
                      Regresar{' '}
                    </span>
                    <button
                      type="submit"
                      className="btn StandarModalConfirmButtonLogOut"
                      onClick={() => onScanData()}
                    >
                      Siguiente{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}
      {showDetails && (
        <div
          className={`mb-12 col-sm-12 col-md-12 ${loading ? 'processing-file' : ''
            }`}
        >
          <Box
            title={'Cantidad recibida'}
            content={
              <FormProvider {...methods}>
                <form>
                  <div className="row">
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-3 col-xl-3`}>
                      <b className="cardTitle">Sku</b>
                      <div className="cardContent">{item.item_code}</div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-6 col-xl-6`}>
                      <b className="cardTitle">Descripcion</b>
                      <div className="cardContent">{item.item_name}</div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-3 col-xl-3`}>
                      <b className="cardTitle">Codigo de barras</b>
                      <div className="cardContent">{item.item_code_bar}</div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-3 col-xl-3`}>
                      <b className="cardTitle">Cantidad abierta</b>
                      <div className="cardContent">
                        {currentRow.open_quantity}
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Motivo de devolución"
                          name="return_reason"
                          type="number"
                          inputType="choosen"
                          placeHolderChoosen='-- Seleccione una opcion --'
                          control={control}
                          options={returnReasonList}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Piezas por caja"
                          type="number"
                          name="purchase_pack_un"
                          blurAction={calculateQuantity}
                          placeholder="Ingrese cantidad"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Cajas por cama"
                          type="number"
                          name="boxes"
                          blurAction={calculateQuantity}
                          placeholder="Ingrese cantidad"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Camas"
                          type="number"
                          name="beds"
                          blurAction={calculateQuantity}
                          placeholder="Ingrese cantidad"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Residuo cajas"
                          type="number"
                          name="residueBox"
                          blurAction={calculateResidue}
                          placeholder="Ingrese cantidad"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Residuo botellas"
                          type="number"
                          name="residueBottles"
                          blurAction={calculateResidue}
                          placeholder="Ingrese cantidad"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          min="1"
                          label="Pallets"
                          type="number"
                          name="pallets"
                          placeholder="Ingrese cantidad"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Cantidad"
                          type="number"
                          name="quantity"
                          // value={numeral(data.quantity).format('0,0')}
                          placeholder="Ingrese cantidad"
                          control={control}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Fecha de caducidad"
                          type="date"
                          name="expDate"
                          placeholder="Ingrese cantidad recibida"
                          control={control}
                          disabled={!hasExpiration}
                        />
                      </div>
                    </div>
                    <div className={`col-12 col-sm-8 col-md-8 col-lg-4 col-xl-4`}>
                      <div className="cardContent">
                        <InputController
                          label="Añada"
                          type="text"
                          name="añada"
                          inputType="text"
                          control={control}
                          disabled={!hasHarvest}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`}
                    >
                      <b className="cardTitle"></b>
                      <p className="cardContent">
                        <div className="col-12 actions-buttons-scan pt-5">
                          <span
                            className="btn StandarModalCancelButtonLogOut"
                            onClick={clearData}
                          >
                            Regresar{' '}
                          </span>
                          <button
                            type="submit"
                            className="btn StandarModalConfirmButtonLogOut"
                            onClick={(event) => onAddRowToReturn(event)}
                          >
                            Siguiente{' '}
                          </button>
                        </div>
                      </p>
                    </div>
                  </div>
                </form>
              </FormProvider>
            }
          />
        </div>
      )}
    </div>
  )
}
