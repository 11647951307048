import { gql } from '@apollo/client'

export const PROVIDERS_BUSINESS_PARTNER = gql`
  query GetAllBusinessPartner($searchQuery: String, $limit: Int, $offset: Int) {
    getAllBusinessPartner(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        card_code
        card_name
        card_type
        group_code
        list_number
        group_number
        credit_line
        debit_line
        discount
        phone1
        phone2
        email
        lic_trad_number
        is_active
      }
      count
    }
  }
`

export const PROVIDERS_BUSINESS_PARTNER_BY_USER_ID = gql`
  query GetAllBusinessPartnerByUserId(
    $id: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllBusinessPartnerByUserId(
      id: $id
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        business_partner_id
        user_id
        is_active
        SapBusinessPartnerToCatalogUserBusinessPartner {
          id
          card_code
          card_name
          card_type
          group_code
          list_number
          group_number
          credit_line
          debit_line
          discount
          phone1
          phone2
          email
          lic_trad_number
          is_active
        }
      }
    }
  }
`

export const PROVIDERS_BUSINESS_PARTNER_ASSIGNATION = gql`
  mutation CreateUpdateBusinessPartnerAssination(
    $userId: Int!
    $businessPartnerId: [Int!]!
  ) {
    createUpdateBusinessPartnerAssination(
      user_id: $userId
      business_partner_id: $businessPartnerId
    )
  }
`

export const PROVIDERS_BUSINESS_PARTNER_LEVEL = gql`
  query GetBusinessPartnerLevelByUserId(
    $getBusinessPartnerLevelByUserIdId: Int!
  ) {
    getBusinessPartnerLevelByUserId(id: $getBusinessPartnerLevelByUserIdId) {
      id
      user_id
      level_types_id
      level_start
      level_end
      is_active
      CatalogLevelsTypesToCatalogUserLevels {
        id
        name
        is_active
      }
    }
  }
`
export const GET_ALL_LEVELS_FOR_PROVIDERS_BUSINESS_PARTNER_ASSIGNATION = gql`
  query GetAllBusinessPartnerLevels {
    getAllBusinessPartnerLevels {
      id
      name
      is_active
    }
  }
`
export const UPDATE_CREATE_BUSINESS_PARTNER_LEVEL_ASSIGNATION = gql`
  mutation CreateUpdateBusinessPartnerLevelAssination(
    $userId: Int!
    $levelTypesId: Int!
  ) {
    createUpdateBusinessPartnerLevelAssination(
      user_id: $userId
      level_types_id: $levelTypesId
    )
  }
`

export const GET_ALL_WARHEOUSES_TO_BE_ASSSIGNED = gql`
  query GetAllStoreWarehouses($searchQuery: String, $limit: Int, $offset: Int) {
    getAllStoreWarehouses(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        sap_warehouses_id
        is_active
        SapWarehousesToCatalogWarehouseReport {
          id
          warehouse_code
          warehouse_name
          street
          block
          zip_code
          city
          county
          country
          state
          location
          street_num
        }
      }
      count
    }
  }
`
export const UPDATE_CREATE_WAREHOUSE_ASSIGNATION = gql`
  mutation CreateUpdateWarehouseAssination(
    $userId: Int!
    $sapWarehousesId: [Int!]!
  ) {
    createUpdateWarehouseAssination(
      user_id: $userId
      sap_warehouses_id: $sapWarehousesId
    )
  }
`
export const GET_ALL_WAREHOUSE_ASSIGNATION_BY_USER_ID = gql`
  query GetAllWarehouseAssignationByUserId(
    $getAllWarehouseAssignationByUserIdId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllWarehouseAssignationByUserId(
      id: $getAllWarehouseAssignationByUserIdId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        sap_warehouses_id
        is_active
        SapWarehousesToCatalogUserWarehouseReports {
          id
          warehouse_code
          warehouse_name
          street
          block
          zip_code
          city
          county
          country
          state
          location
          street_num
        }
      }
      count
    }
  }
`
