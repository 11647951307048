import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import InputController from '../../Global/InputController'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  CREATE_REGISTER_FOR_VEHICLE,
  GET_ALL_REGISTER_VEHICLES,
  GET_ONE_REGISTER_VEHICLE,
  GET_VEHICLE_OPTIONS,
  UPDATE_REGISTER_VEHICLE,
} from '../../../graphql/Documents/Schedule/ScheduleVehicle'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'

const ScheduleRegisterNew = () => {
  const [businessPartnerOptions, setBusinesspartnerOptions] = useState([])
  const [vehiclesTypesOptions, setVehiclesTypesOptions] = useState([])
  const [parcelOptions, setParcelOptions] = useState([])
  const history = useHistory()
  const { id: _id } = useParams()

  const validationSchema = Yup.object().shape({
    business_partner_id: Yup.string().required('campo requerido'),
    vehicle_type_id: Yup.string().required('campo requerido'),
    shipping_companies_id: Yup.string().required('campo requerido'),
    plates: Yup.string().required('campo requerido'),
  })

  const { methods, handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const [createScheduleForVehicle] = useMutation(CREATE_REGISTER_FOR_VEHICLE)
  const [updateVehicleInformation] = useMutation(UPDATE_REGISTER_VEHICLE)
  const { data, error, loading } = useQuery(GET_VEHICLE_OPTIONS)
  const [getOneVehicle, { error: vehicleError, loading: vehicleLoading }] =
    useLazyQuery(GET_ONE_REGISTER_VEHICLE)

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const businessPartnerOptions =
        data?.getLoadingDockControlOptions?.business_partners.map(
          (itemBusinessPartner) => {
            return {
              value: itemBusinessPartner?.id,
              label: itemBusinessPartner?.card_name,
            }
          }
        )
      const vehicleTypeOptions =
        data?.getLoadingDockControlOptions?.vehicles_types.map(
          (itemVehiclesTypes) => {
            return {
              value: itemVehiclesTypes?.id,
              label: itemVehiclesTypes?.name,
            }
          }
        )
      const parcelServicesOptions =
        data?.getLoadingDockControlOptions?.parcel_services.map(
          (itemParcel) => {
            return {
              value: itemParcel?.id,
              label: itemParcel?.name,
            }
          }
        )

      setBusinesspartnerOptions(businessPartnerOptions)
      setVehiclesTypesOptions(vehicleTypeOptions)
      setParcelOptions(parcelServicesOptions)
    }
  }, [data, error, loading])

  useEffect(() => {
    if (vehicleError) {
      return toastSweetAlert({
        mode: 'error',
        message: vehicleError.message,
      })
    }

    if (!vehicleLoading) {
      if (_id) {
        getOneVehicleById(_id)
      }
    }
  }, [_id, vehicleError, vehicleLoading])

  const getOneVehicleById = async (id) => {
    try {
      const { data } = await getOneVehicle({
        variables: {
          getOneVehicleRegisterId: +id,
        },
      })
      const vehicleType =
        data?.getOneVehicleRegister?.LoadingDockControlToDocumentVehicleType?.id
      const businessPartner =
        data?.getOneVehicleRegister?.LoadingDockControlToSapBusinessPartner?.id
      const shippingCompanie =
        data?.getOneVehicleRegister?.LoadingDockControlToShippingCompanies?.id
      const plates = data?.getOneVehicleRegister?.plates_number

      setValue('vehicle_type_id', vehicleType)
      setValue('business_partner_id', businessPartner)
      setValue('shipping_companies_id', shippingCompanie)
      setValue('plates', plates)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const onSubmit = async (data) => {
    try {
      if (_id) {
        await updateVehicleInformation({
          variables: {
            updateVehicleRegisterId: +_id,
            input: {
              business_partner_id: +data.business_partner_id,
              vehicle_type_id: +data.vehicle_type_id,
              shipping_companies_id: +data.shipping_companies_id,
              plates: data.plates,
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_REGISTER_VEHICLES,
              variables: {
                searchQuery: null,
                limit: 10,
                offset: 0,
                isGuard: true,
              },
            },
            {
              query: GET_ONE_REGISTER_VEHICLE,
              variables: {
                getOneVehicleRegisterId: +_id,
              },
            },
          ],
        })
        history.push('/scheduleRegister')
      } else {
        await createScheduleForVehicle({
          variables: {
            input: {
              business_partner_id: +data.business_partner_id,
              vehicle_type_id: +data.vehicle_type_id,
              shipping_companies_id: +data.shipping_companies_id,
              plates: data.plates,
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_REGISTER_VEHICLES,
              variables: {
                searchQuery: null,
                limit: 10,
                offset: 0,
                isGuard: true,
              },
            },
          ],
        })
        history.push('/scheduleRegister')
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <div className="pt-5">
        <Box
          title={_id ? 'Editar vehículo' : 'Registrar vehículo'}
          btnRedPath="/scheduleRegister"
          btnRedTxt="Cancelar"
          btnSubmitText={_id ? 'Editar vehículo' : 'Registrar vehículo'}
          btnSubmit={handleSubmit(onSubmit)}
          content={
            <>
              <form className="row justify-content-center align-items-center p-3">
                <div className="mb-3  col-md-6 col-sm-12">
                  <InputController
                    label="Proveedor"
                    inputType="choosen"
                    options={businessPartnerOptions}
                    // valueManual={valueChoosen}
                    name="business_partner_id"
                    control={control}
                  />
                </div>
                <div className="mb-3  col-md-6 col-sm-12">
                  <InputController
                    label="Tipo de vehiculo"
                    inputType="choosen"
                    options={vehiclesTypesOptions}
                    // valueManual={valueChoosen}
                    name="vehicle_type_id"
                    control={control}
                  />
                </div>
                <div className="mb-3  col-md-6 col-sm-12">
                  <InputController
                    label="paqueteria"
                    inputType="choosen"
                    options={parcelOptions}
                    // valueManual={valueChoosen}
                    name="shipping_companies_id"
                    control={control}
                  />
                </div>
                <div className="mb-3  col-md-6 col-sm-12">
                  <InputController
                    label="Placas"
                    type="text"
                    name="plates"
                    placeholder="Ingrese las placas del vehiculo"
                    control={control}
                  />
                </div>
                {/* 
                <div className="col-12 d-flex justify-content-center mt-3">
                  <button type="submit" className="btn btn-accept">
                    Confirmar cita
                  </button>
                </div> */}
              </form>
            </>
          }
        />
      </div>
    </>
  )
}

export default ScheduleRegisterNew
