import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import {
  CREATE_CONCEPT_COUNTER_CHARGE,
  GET_ALL_CONCEPT_COUNTER_CHARGES,
  GET_ONE_CONCEPT_COUNTER_CHARGE,
  UPDATE_CONCEPT_COUNTER_CHARGE,
} from '../../../../../graphql/Catalog/Notes/ConceptCounterCharges/ConceptCounterCharges'
import { useMutation, useQuery } from '@apollo/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'
import Box from '../../../../Global/Box'
import InputController from '../../../../Global/InputController'
import { yupResolver } from '@hookform/resolvers/yup'
import { toastSweetAlertNotifications } from '../../../../Helpers/ToastSweetAlertNotifications'

export const ConceptCounterChargesNew = () => {
  const { id: _id } = useParams()
  const history = useHistory()

  const [loadingBtn, setLoadingBtn] = useState(false)

  const { data: dataOneConceptCounterCharges } = useQuery(
    GET_ONE_CONCEPT_COUNTER_CHARGE,
    {
      variables: {
        id: parseInt(_id),
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [createConceptCounterCharges] = useMutation(
    CREATE_CONCEPT_COUNTER_CHARGE,
    {
      refetchQueries: [
        {
          query: GET_ALL_CONCEPT_COUNTER_CHARGES,
          variables: {
            searchQuery: null,
            limit: 10,
            offset: 0,
          },
          fetchPolicy: 'no-cache',
        },
      ],
    }
  )

  const [updateConceptCounterCharges] = useMutation(
    UPDATE_CONCEPT_COUNTER_CHARGE,
    {
      refetchQueries: [
        {
          query: GET_ALL_CONCEPT_COUNTER_CHARGES,
          variables: {
            searchQuery: null,
            limit: 10,
            offset: 0,
          },
          fetchPolicy: 'no-cache',
        },
      ],
    }
  )

  const validationSchema = Yup.object({
    name: Yup.string().required('El campo es requerido'),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (_id) {
      if (
        dataOneConceptCounterCharges !== null &&
        dataOneConceptCounterCharges !== undefined
      ) {
        const data =
          dataOneConceptCounterCharges.getOneConceptCounterChargesById

        const dataFormated = {
          name: data.name,
        }

        reset(dataFormated)
      }
    }
  }, [dataOneConceptCounterCharges])

  const onSubmit = async (data) => {
    try {
      setLoadingBtn(true)
      if (_id) {
        await updateConceptCounterCharges({
          variables: {
            id: parseInt(_id),
            conceptInput: {
              name: data.name,
            },
          },
        })
        return toastSweetAlertNotifications(
          {
            mode: 'ok',
            message: 'Concepto actualizado correctamente',
          },
          history.push(`/catalogsforcountercharges/conceptcountercharges`)
        )
      }

      await createConceptCounterCharges({
        variables: {
          conceptInput: {
            name: data.name,
          },
        },
      })
      return toastSweetAlertNotifications(
        {
          mode: 'ok',
          message: 'Concepto creado correctamente',
        },
        history.push(`/catalogsforcountercharges/conceptcountercharges`)
      )
    } catch (e) {
      console.log(e)
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: e.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        windowTitle={
          _id ? 'Editar concepto de cargo' : 'Nuevo concepto de cargo'
        }
        breadcrumb={
          _id ? 'Editar concepto de cargo' : 'Nuevo concepto de cargo'
        }
        title={_id ? 'Editar concepto de cargo' : 'Nuevo concepto de cargo'}
      />

      <FormProvider {...methods}>
        <form
          className={`${loadingBtn ? 'processing-file' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            // title={_id ? 'Editar concepto de cargo' : 'Nuevo concepto de cargo'}
            btnRedPath="/catalogsforcountercharges/conceptcountercharges"
            btnRedTxt="Cancelar"
            btnSubmit={true}
            btnSubmitText={_id ? 'Editar' : 'Crear'}
            btnState={loadingBtn}
            errors={errors}
            content={
              <>
                <div className="col">
                  <div className="row">
                    <div className="mb3 col-md-4 col-lg-4">
                      <InputController
                        name="name"
                        label="Nombre concepto de cargo"
                        placeholder="Nombre concepto de cargo"
                        control={control}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default ConceptCounterChargesNew
