import React from 'react'
import { Camera, FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

export const CameraPhoto = ({ active = false, fullScreen = false, handleResult }) => {
    
    return (
        <>
            { active &&
                <div className="">
                    <Camera
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        isImageMirror={false}
                        isFullScreen={fullScreen}
                        // isMaxResolution={true}
                        idealResolution={{
                            width: 1024,
                            height: 720
                        }}
                        sizeFactor={1}
                        onTakePhoto={ (imgSrc) => {
                            handleResult(imgSrc);
                        }}
                    />
                </div>
            }
        </>
    )
}
