import { gql } from '@apollo/client'

export const GET_IN_ROUTE_ORDERS = gql`
  query GetInRouteOrders(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $platform: Int
    $date: Int
  ) {
    getInRouteOrders(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      platform: $platform
      date: $date
    ) {
      rows {
        id
        platform_id
        platform {
          name
          id
        }
        type {
          name
        }
        payment {
          platform
          payment_id
        }
        store {
          name
        }
        warehouse {
          name
        }
        shipping {
          first_name
          last_name
        }
        order_id
        order_date
        object_type
        object_type_name
      }
      count
    }
  }
`

export const CHANGE_TO_IN_ROUTE = gql`
  mutation ChangeToInRoute($orderId: String!) {
    changeToInRoute(order_id: $orderId) {
      id
      status {
        id
        name
      }
    }
  }
`

export const EXPORT_IN_ROUTE_ORDERS = gql`
  mutation GetAllInRouteExport {
    getAllInRouteExport {
      id
      order_date
      platform_name
      type_name
      id_payment
      platform
      shipping_first_name
      shipping_last_name
      order_id
      store_name
      warehouse_name
    }
  }
`
