import React, { useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import Loader from './Spinner'
import debounce from 'lodash.debounce'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { AuthContext } from './../../Auth/AuthContext'
import { useLocation } from 'react-router'
import { EMPTY } from './../../graphql/Auth/auth'
import { useMutation } from '@apollo/client'
import { CSVLink } from 'react-csv'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom'
import LayoutCardTable from './LayoutCardTable'
import DropDownButton from '../ProvidersDashboard/Reports/components/DropDownButton'
import InputController from './InputController'
import Select from 'react-select'
import editIcon from '../../../src/Assets/icons/edit.svg'
import trashIcon from '../../Assets/icons/trash.svg'
import lockIcon from '../../Assets/icons/lock.svg'
import downloadIcon from '../../Assets/icons/download.svg'
import detailsIcon from '../../Assets/icons/details.svg'

const defaultCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          ref={ref}
          onClick={onClick}
          {...rest}
        />
        <label className="form-check-label" id="check" />
      </div>
    </>
  )
})

export const LayoutTable = ({
  layoutCardMobile = false,
  data = [],
  keyField = 'id',
  title,
  gql = EMPTY,
  requery = false,
  actionsInFirstColumn = false,
  refetchFunctionQueries = async () => {},
  exportQuery = EMPTY,
  exportQueryCustom = false,
  importQuery = EMPTY,
  importCustomVariables = '',
  templateDataImport = [{ exampleHeader: 'exampleCol' }],
  maximumFilesImport = 1,
  specialImportRoute = false,
  importMessageValidation = 'Archivos necesarios',
  desactiveBOM = true,
  editText = <img src={editIcon} alt="edit" className="icon3" />,
  detailsText = <img src={detailsIcon} alt="edit" className="icon3" />,
  deleteText = <img src={trashIcon} alt="trash" className="icon3" />,
  hideDetails = false,
  hideActions = false,
  convertActionsToIcons = false,
  actionBtnEdit = false,
  actionBtnDetail = false,
  sizeProductName = '300px',
  hideBtnEdit = false,
  hideBtnErase = false,
  actionBtnErase = false,
  changeCustomActionErase = false,
  widthCustomActions = false,
  platformSelector = false,
  paginationServer = true,
  totalCount,
  pagePagination,
  setPagePagination,
  mutationAction = false,
  hideFilterBar = false,
  FilterBarPlaceholder = false,
  loadingData,
  handleButton,
  handleButtonGetCompleteRow = false,
  actionsAddProducts = false,
  handleButtonRowCondition = false,
  handleButtonClassName = '',
  handleButtonTitle = 'Action',
  handleButtonType = 'button',
  headersButtons = true,
  exportImportButton = true,
  importExportBtnText = <i className="fas fa-cloud-download-alt"></i>,
  extraButtonsImportExport = false,
  exportBtnText = (
    <>
      <i className="fas fa-file-export"></i> &nbsp;Exportar CSV{' '}
    </>
  ),
  importBtnText = (
    <>
      <i className="fas fa-file-import"></i> &nbsp;Importar CSV{' '}
    </>
  ),
  templateImportBtnText = (
    <>
      <i className="fas fa-file-excel"></i> &nbsp;Plantilla CSV{' '}
    </>
  ),
  alertAction = false,
  alertActionText = '',
  addBtnTxt,
  filterChoosen = false,
  filter = false,
  filterOptions = [],
  filterOptionsSecond = [],
  filterOnChange = () => {},
  filterOnChangeSecond = () => {},
  filterDefaultOptionText = false,
  filterDefaultOptionTextSecond = false,
  defaultValueSelectSecond = false,
  filterLabelText = false,
  filterLabelTextSecond = false,
  filterSecond = false,
  extraHeaderText,
  extraHeaderButtonTo = '',
  extraHeaderButton,
  hideId = false,
  hideColumns = [],
  titleActions = 'Acciones',
  withCard = true,
  extraArgHandleButton = null,
  handleButtonModal = () => {},
  handleButtonModalText = false,
  expandableRows = false,
  expandableRowsComponent = <div></div>,
  alertColor = '#ffd281',
  alertSecondaryColor = '#ffd281',
  alertTextColor = null,
  alertSecondaryTextColor = null,
  alertThirdTextColor = null,
  listFormat = false,
  hidePagination = false,
  defaultValueSelect = false,
  filterCheckBox = false,
  filterCheckBox1 = false,
  filterCheckBox2 = false,
  filterCheckBox3 = false,
  filterCheckBox4 = false,
  lblChkBox = '',
  lblChkBox2 = '',
  lblChkBox3 = '',
  lblChkBox4 = '',
  filterOnChangeBox = () => {},
  filterOnChangeBox2 = () => {},
  filterOnChangeBox3 = () => {},
  filterOnChangeBox4 = () => {},
  valueChkBox = null,
  valueChkBox2 = null,
  handleButtonSecond = false,
  handleButtonSecondTitle = '',
  noDataComponent = 'No se encontrarón datos',
  extraHeaderButtonFunction = false,
  extraHeaderButtonFunctionText = 'Función',
  filterLabelDate = 'Fecha salida',
  filterOnChangeDate = false,
  idFilterRangeDateTo = 'filterRangeDateTo',
  idFilterRangeDateFrom = 'filterRangeDateFrom',
  filterRangeDateFrom = false,
  filterOnChangeRangeDateFrom = () => {},
  filterRangeDateTo = false,
  filterOnChangeRangeDateTo = () => {},
  hideAddNew = false,
  selectableRows = false,
  selectableRowsComponent = defaultCheckbox,
  selectableRowsClick = () => {},
  rowDisabledCriteria = (row) => {},
  // selectableRowSelected = false,
  paginationServerOptions = {
    persistSelectedOnSort: true,
    persistSelectedOnPageChange: true,
  },
  searchId = 'searchBar',
  clearSelectedRows = false,
  customFilterButtonInput = <></>,
  customActionButtons = false,
  headerButtonsNoPermissions = false,
  wrapFlag = true,
  componentAction = null,
  append = null,
  enableCollapse = false,
  collapsed = false,
  extraCompFilter = null,
  classes = '',
  showDataTable = true,
  headerComponents = null,
  importOptions = {
    confirmation: false,
    confirmationText: null,
    confirmationTitle: null,
    /*
    confirmationData:[
      {
        value: Int
        text: 'text-label',
      },
    ]
     */
    confirmationData: [],
    variable: null, //object { name: String, type: String int | string}
  },
  dropDownButton = false,
  handleDropDownButton = (e, id) => {},
  checkbox = false,
  handleCheckbox = (id) => {},
  handleCheckedInputProperty = (id) => {},
  selectOptions = false,
  handleSelectOptions = () => {},
  searchByProperty = '',
  handleConditionalButton = false,
  handleConditionalButtonTitle = false,
  radio = false,
  handleRadio = (id) => {},
  handleCheckedRadioProperty = (id) => {},
  textFontWeight = '',
  filterInput = false,
  filterInputControl,
  filterInputOptions = [],
  filterInputName = '',
  handleFilterInput = () => {},
  paginationRowsPerPageOptions = [10, 15, 20, 25, 30],
  messageSuccesImport = 'La importación se hizo correctamente',
  minCharSearching = false,
  minCharacterSearch = 0,
}) => {
  const history = useHistory()
  const [columns, setColumns] = useState([])
  const [cell, setCell] = useState([])
  const [total, setTotal] = useState([])
  const [dataExported, setDataExported] = useState([])
  const [FileNameExported, setFileNameExported] = useState('')
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingImport, setLoadingImport] = useState(false)
  const [detectMobileSize, setDetectMobileSize] = useState(false)
  const [
    permissionsButtonsLayoutCardTable,
    setPermissionsButtonsLayoutCardTable,
  ] = useState(false)
  const location = useLocation()
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    erase: false,
    exportBtn: false,
    importBtn: false,
    permissionsBtn: false,
    cycleCountcycleOperationBtn: false,
    path: '',
  })
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const MySwal = withReactContent(Swal)
  let selectedValue = null

  // const rowSelectCriteria = useMemo(() => {
  //   return pagePagination.rowSelectCriteria
  // }, [pagePagination.rowSelectCriteria])

  const {
    create,
    edit,
    erase,
    exportBtn,
    importBtn,
    permissionsBtn,
    cycleCountcycleOperationBtn,
    path,
  } = permissions
  const { user } = useContext(AuthContext)

  const [deleteItem] = useMutation(gql)

  const [exportItems] = useMutation(exportQuery)
  const [importItems] = useMutation(importQuery)

  // DETECT SIZE MOBILE MODE AND SET HOOKS TO SHOW LAYOUT MOBILE
  const handleResize = () => {
    if (!layoutCardMobile) return
    if (window.innerWidth <= 800) return setDetectMobileSize(true)
    return setDetectMobileSize(false)
  }

  window.addEventListener('resize', handleResize)

  useEffect(() => {
    if (!layoutCardMobile) return
    if (window.innerWidth <= 800) return setDetectMobileSize(true)
  }, [])

  //SET PERMISSIONS
  useEffect(() => {
    const permissions = user?.userPermissions
    let listPermissions = []
    permissions.map((item) => {
      let moduleName = `${item?.module_info?.relative_link}`
      if (moduleName === location.pathname) {
        return (listPermissions = {
          create: item.access_retrieve,
          edit: item.access_edit,
          erase: item.access_delete,
          exportBtn: item.access_export,
          importBtn: item.access_import,
          path: item.module_info.relative_link,
        })
      }
      return null
    })
    permissions.map((item) => {
      let submoduleName = item.submodule_info?.relative_link
      if (submoduleName) {
        if (location.pathname.includes('new')) {
          const relativePath = location.pathname
            .split('new')[0]
            .replace(/.$/, '')
          const relativePathSplit = relativePath.split('/')
          const submodulePath = relativePathSplit[relativePathSplit.length - 1]
          if (submoduleName === `/${submodulePath}`) {
            return (listPermissions = {
              create: item.access_retrieve,
              edit: item.access_edit,
              erase: item.access_delete,
              exportBtn: item.access_export,
              importBtn: item.access_import,
              path: `${item.module_info.relative_link}/${submodulePath}`,
            })
          }
        }
        if (location.pathname.includes('edit')) {
          const relativePath = location.pathname
            .split('edit')[0]
            .replace(/.$/, '')
          const relativePathSplit = relativePath.split('/')
          const submodulePath = relativePathSplit[relativePathSplit.length - 1]
          if (submoduleName === `/${submodulePath}`) {
            return (listPermissions = {
              create: item.access_retrieve,
              edit: item.access_edit,
              erase: item.access_delete,
              exportBtn: item.access_export,
              importBtn: item.access_import,
              path: `${item.module_info.relative_link}/${submodulePath}`,
            })
          }
          if (relativePath === '/TFI/Calendar') {
            return (listPermissions = {
              create: true,
              edit: true,
              erase: true,
              exportBtn: true,
              importBtn: true,
              path: `/TFI/Calendar`,
            })
          }
        }
        const relativePathSplit = location.pathname.split('/')
        const submodulePath = relativePathSplit[relativePathSplit.length - 1]
        if (submoduleName === `/${submodulePath}`) {
          return (listPermissions = {
            create: item.access_retrieve,
            edit: item.access_edit,
            erase: item.access_delete,
            exportBtn: item.access_export,
            importBtn: item.access_import,
            path: `${item.module_info.relative_link}/${submodulePath}`,
          })
        }
      }
      return true
    })
    if (location.pathname === '/catalog/users') {
      const checkBtnPermissions = permissions.some((permission) => {
        if (permission?.module_info.name === 'UsersPermissions') {
          if (permission?.access_read) {
            return true
          } else {
            return false
          }
        }
        return null
      })
      listPermissions.permissionsBtn = checkBtnPermissions
    }

    if (location.pathname === '/inventories/cycleCountRequest') {
      const checkBtnCycleCountcycleOperation = permissions.some(
        (permission) => {
          if (permission?.submodule_info?.name === 'CycleCountcycleOperation') {
            return permission?.access_read ? true : false
          }
          return null
        }
      )
      listPermissions.cycleCountcycleOperationBtn =
        checkBtnCycleCountcycleOperation
    }

    return setPermissions(listPermissions)
  }, [location.pathname, user])

  //SETTING DATA
  useEffect(() => {
    let columns = []
    setCell(data)
    setTotal(totalCount)
    if (data.length === 0) return
    Object.keys(data[0]).map((item, index) => {
      if (item === 'omitButtonsLayout') {
        return null
      }
      if (item === 'id') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          sortable: true,
          width: '80px',
          omit: hideId,
          wrap: wrapFlag,
        })
      }
      if (item === 'DetailRow') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          sortable: true,
          width: '80px',
          omit: true,
          wrap: wrapFlag,
        })
      }
      if (item === 'CajasHide') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          sortable: true,
          width: '80px',
          omit: true,
          wrap: true,
        })
      }
      if (item === 'UnidadHide') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          sortable: true,
          width: '80px',
          omit: true,
          wrap: true,
        })
      }
      if (item === 'alert') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          omit: true,
          wrap: true,
        })
      }
      if (item === 'conditional_alert') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          omit: true,
          wrap: true,
        })
      }
      if (item === 'conditional_status') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          omit: true,
          wrap: true,
        })
      }
      if (item === 'orderIdRoutesForm') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          omit: true,
          wrap: true,
        })
      }

      if (item === 'órdenes de compra') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          sortable: true,
          wrap: wrapFlag,
        })
      }
      if (item === 'item_code') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          omit: true,
          wrap: true,
        })
      }
      if (item === 'Progreso') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => (
            <div
              className="progress"
              style={{ backgroundColor: '#b0c0d0', width: '100%' }}
            >
              <div
                className={`progress-bar progress-bar-striped progress-bar-animated
                  ${
                    row[item] < 25
                      ? 'bg-danger'
                      : row[item] >= 25 && row[item] < 50
                      ? 'bg-warning'
                      : row[item] >= 50 && row[item] < 75
                      ? 'bg-primary'
                      : row[item] >= 75 && 'bg-success'
                  }
                 
                `}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${row[item]}%` }}
              >
                &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
              </div>
            </div>
          ),
          sortable: false,
          width: '180px',
          wrap: wrapFlag,
        })
      }
      if (item === 'expandable') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          omit: true,
          wrap: true,
        })
      }
      if (item === 'orderShippingId') {
        return columns.push({
          name: <div>{item}</div>,
          selector: (row) => row[item],
          omit: true,
          wrap: true,
        })
      }
      //Para mostrar la imagen en el catalogo de productos para agregar en nuevo pedido
      if (item === 'img_url') {
        return columns.push({
          name: <div>{item}</div>,
          cell: (row) => (
            <>
              <div className="text-center w-100 my-1">
                <img
                  src={row[item]}
                  style={{ maxWidth: '100%', maxHeight: '100px' }}
                  alt="ordersImages"
                />
              </div>
            </>
          ),
        })
      }
      //Para poder seleccionar la cantidad del producto a agregar en nuevo pedido
      if (item === 'quantity_select') {
        return columns.push({
          name: <div>Cantidad</div>,
          width: '150px',
          cell: (row) => {
            // let limite = row[item] > 5 ? 5 : row[item];
            // let array = []
            // for(let i = 0; i < limite; i++){
            //   array.push(i)
            // }

            return (
              <>
                <div id="principal" className="text-center w-100 my-1">
                  {/* <select id={`select-quantity-${row["sku"]}`} name="select-quantity" className="form-control" onChange={(e)=>{row["quantity_selectioned"] = parseInt(e.target.value)}}>
                  {array.map((item, index)=><option key={index}>{item + 1}</option>)}
                </select> */}
                  <input
                    type="number"
                    className="form-control"
                    placeholder={row[item] + ' máximo'}
                    onChange={(e) => {
                      row['quantity_selectioned'] = parseInt(e.target.value)
                    }}
                  />
                </div>
              </>
            )
          },
          wrap: wrapFlag,
        })
      }
      if (item === 'show_button' || item === 'hide_button') {
        return columns.push({
          name: <div>Acciones</div>,
          // width: '200px',
          cell: (row) => {
            return (
              <>
                {handleButton && row['show_button'] && (
                  <span
                    className="btn btn-accept btn-md"
                    onClick={() => handleButton(row['id'])}
                  >
                    {handleButtonTitle}
                  </span>
                )}
              </>
            )
          },
          wrap: wrapFlag,
        })
      }
      if (item === 'conditional_hide_button') {
        return columns.push({
          name: '',
          width: '200px',
          omit: true,
          wrap: wrapFlag,
        })
      }
      if (item === 'conditional_hide_custom_action_buttons') {
        return columns.push({
          name: '',
          width: '200px',
          omit: true,
          wrap: wrapFlag,
        })
      }
      //To the name of product can show correctly in new order telemarketing
      if (item === 'product_add') {
        return columns.push({
          name: <div>nombre</div>,
          width: sizeProductName,
          selector: (row) => row[item],
          sortable: true,
          wrap: wrapFlag,
        })
      }
      if (item === 'TransferStatus') {
        return
      }

      if (hideColumns.length > 0) {
        for (let column of hideColumns) {
          if (item.toLowerCase() === column.toLowerCase()) {
            return columns.push({
              name: <div>{item}</div>,
              selector: (row) => row[item],
              sortable: true,
              omit: true,
              wrap: wrapFlag,
            })
          }
        }
      }
      return columns.push({
        name: <div>{item}</div>,
        selector: (row) => row[item],
        sortable: true,
        wrap: wrapFlag,
        omit: item.includes('omitField'),
        allowOverflow: wrapFlag,
      })
    })
    //AQUI
    if (!hideActions) {
      columns.push({
        name: <div>{titleActions}</div>,
        button: true,
        width: widthCustomActions ? widthCustomActions : '290px',
        wrap: true,
        cell: (row) => (
          <>
            {row['TransferStatus']
              ? row['TransferStatus'] === 8
                ? (hideBtnEdit = false)
                : (hideBtnEdit = true)
              : null}
            <div
              className={
                convertActionsToIcons
                  ? 'icon-actions-layout-table-minwidth'
                  : handleButton && handleButtonRowCondition
                  ? 'btn-group actions-buttons-layout-table'
                  : 'btn-group actions-buttons-layout-table'
              }
            >
              {componentAction && componentAction(row)}
              {handleButtonSecond && (
                <span
                  className="btn btn-accept btn-sm"
                  onClick={() => handleButtonSecond(row['id'])}
                >
                  {handleButtonSecondTitle}
                </span>
              )}
              {handleConditionalButton && !row['conditional_hide_button'] && (
                <span
                  className="btn btn-accept btn-sm"
                  onClick={() => handleConditionalButton(row['id'], row)}
                >
                  {handleConditionalButtonTitle}
                </span>
              )}

              {dropDownButton && (
                <DropDownButton
                  key={row['id']}
                  handleDropDownButton={(e) =>
                    handleDropDownButton(e, row['id'])
                  }
                />
              )}

              {handleButton ? (
                <>
                  {handleButtonType === 'checkbox' ? (
                    <>
                      <input
                        id={row['id'] + '-checkBoxLay'}
                        type="checkbox"
                        onClick={() => handleButton(row['id'])}
                      ></input>
                    </>
                  ) : (
                    <>
                      {/* Span */}
                      <span
                        className={`btn btn-accept btn-sm ${handleButtonClassName}`}
                        onClick={() =>
                          handleButton(
                            handleButtonGetCompleteRow ? row : row['id'],
                            extraArgHandleButton
                          )
                        }
                      >
                        {handleButtonTitle}
                      </span>
                    </>
                  )}
                </>
              ) : !hideDetails &&
                !handleButton &&
                !handleButtonRowCondition &&
                !actionBtnDetail ? (
                <Link
                  className={`${
                    convertActionsToIcons
                      ? 'icon-actions-layout-table'
                      : 'btn btn-sm btn-accept-white'
                  }`}
                  to={`${path}/details/${row['id']}/show`}
                >
                  {detailsText}
                </Link>
              ) : (
                actionBtnDetail && (
                  <span
                    className={`${
                      convertActionsToIcons
                        ? 'icon-actions-layout-table'
                        : 'btn btn-sm btn-accept-white'
                    }`}
                    onClick={() => actionBtnDetail(row['id'])}
                  >
                    {detailsText}
                  </span>
                )
              )}

              {checkbox && (
                <>
                  <input
                    className="mx-auto"
                    id={row['id'] + '-checkBoxLay'}
                    type="checkbox"
                    onClick={() => handleCheckbox(row['id'])}
                    defaultChecked={handleCheckedInputProperty(row['id'])}
                    onChange={() => {}}
                    // value=""
                  ></input>
                </>
              )}
              {radio && (
                <>
                  <input
                    className="mx-auto"
                    id={row['id'] + '-checkBoxLay'}
                    type="radio"
                    name="schedule_id"
                    onClick={() => handleRadio(row['id'])}
                    defaultChecked={handleCheckedRadioProperty(row['id'])}
                    onChange={() => {}}
                    // value=""
                  ></input>
                </>
              )}

              {!hideBtnEdit ? (
                edit &&
                !handleButton &&
                !handleButtonRowCondition &&
                !actionBtnEdit ? (
                  <Link
                    className={`${
                      convertActionsToIcons
                        ? 'icon-actions-layout-table'
                        : 'btn btn-sm btn-accept-white'
                    }`}
                    to={`${path}/edit/${row['id']}`}
                  >
                    {editText}
                  </Link>
                ) : (
                  actionBtnEdit &&
                  edit && (
                    <button
                      className={`${
                        convertActionsToIcons
                          ? 'icon-actions-layout-table'
                          : 'btn btn-sm btn-accept-white'
                      }`}
                      onClick={() => actionBtnEdit(row['id'])}
                    >
                      {editText}
                    </button>
                  )
                )
              ) : (
                <></>
              )}
              {customActionButtons &&
                !row['conditional_hide_custom_action_buttons'] &&
                customActionButtons.map((button) => (
                  <span
                    className={`${button.className}`}
                    onClick={(e) => button.handleButton(row, e)}
                  >
                    {button.buttonText}
                  </span>
                ))}
              {cycleCountcycleOperationBtn && handleButtonModalText && (
                <button
                  className="btn btn-accept btn-sm"
                  onClick={() => handleButtonModal(row['id'])}
                >
                  <i className="fas fa-play"></i>
                </button>
              )}

              {!hideBtnErase ? (
                !actionBtnErase &&
                !handleButton &&
                erase &&
                !handleButtonRowCondition &&
                !changeCustomActionErase ? (
                  <button
                    type="button"
                    className={`${
                      convertActionsToIcons
                        ? 'icon-actions-layout-table'
                        : 'btn btn-sm btn-accept-white'
                    }`}
                    onClick={() => handleDelete(row['id'])}
                  >
                    {deleteText}
                  </button>
                ) : (
                  actionBtnErase &&
                  erase && (
                    <button
                      type="button"
                      className={`${
                        convertActionsToIcons
                          ? 'icon-actions-layout-table'
                          : 'btn btn-sm btn-accept-white'
                      }`}
                      onClick={() => actionBtnErase(row['id'])}
                    >
                      {deleteText}
                    </button>
                  )
                )
              ) : (
                <></>
              )}
              {permissionsBtn && !handleButton && !handleButtonRowCondition && (
                <Link
                  className="btn btn-warning btn-sm btn-accept-white"
                  to={`${path}/permissions/edit/${row['id']}`}
                >
                  <img src={lockIcon} alt="lock" className="icon3" />
                </Link>
              )}
              {row.alert &&
                alertAction &&
                edit &&
                !handleButton &&
                !handleButtonRowCondition && (
                  <Link
                    className="btn btn-accept btn-sm"
                    to={`${path}/refill/${row['id']}`}
                  >
                    {alertActionText}
                  </Link>
                )}
              {row.alert &&
                alertAction &&
                edit &&
                handleButton &&
                handleButtonRowCondition && (
                  <Link
                    className="btn btn-accept btn-sm buttonWithoutSpace"
                    to={`${path}/refill/${row['id']}`}
                  >
                    {alertActionText}
                  </Link>
                )}
            </div>
          </>
        ),
      })

      setPermissionsButtonsLayoutCardTable({
        actions: function (item) {
          return (
            <>
              {item.omitButtonsLayout
                ? ((hideBtnEdit = false),
                  (handleButton = false),
                  (handleButtonSecond = false),
                  (customActionButtons = false),
                  (hideBtnErase = false),
                  (hideDetails = false))
                : null}
              <div
                className={
                  handleButton && handleButtonRowCondition
                    ? 'btn-group actions-buttons-layout-table btn-card-layout-table'
                    : 'btn-group actions-buttons-layout-table btn-card-layout-table'
                }
              >
                {componentAction && componentAction(item)}

                {handleButtonSecond && (
                  <span
                    className="btn btn-accept btn-sm"
                    onClick={() => handleButtonSecond(item['id'])}
                  >
                    {handleButtonSecondTitle}
                  </span>
                )}

                {handleButton ? (
                  <>
                    {handleButtonType === 'checkbox' ? (
                      <>
                        <input
                          id={item['id'] + '-checkBoxLay'}
                          type="checkbox"
                          onClick={() => handleButton(item['id'])}
                        ></input>
                      </>
                    ) : (
                      <>
                        {/* Span */}
                        <span
                          className={`btn btn-accept btn-sm ${handleButtonClassName}`}
                          onClick={() =>
                            handleButton(item['id'], extraArgHandleButton)
                          }
                        >
                          {handleButtonTitle}
                        </span>
                      </>
                    )}
                  </>
                ) : !hideDetails &&
                  !handleButton &&
                  !handleButtonRowCondition &&
                  !actionBtnDetail ? (
                  <Link
                    className="btn btn-accept btn-sm"
                    to={`${path}/details/${item['id']}/show`}
                  >
                    {detailsText}
                  </Link>
                ) : (
                  actionBtnDetail && (
                    <span
                      className="btn btn-sm btn-accept-white"
                      onClick={() => actionBtnDetail(item['id'])}
                    >
                      {detailsText}
                    </span>
                  )
                )}

                {!hideBtnEdit ? (
                  edit &&
                  !handleButton &&
                  !handleButtonRowCondition &&
                  !actionBtnEdit ? (
                    <Link
                      className="btn btn-sm btn-accept-white"
                      to={`${path}/edit/${item['id']}`}
                    >
                      {editText}
                    </Link>
                  ) : (
                    actionBtnEdit &&
                    edit && (
                      <button
                        className="btn btn-sm btn-accept-white"
                        onClick={() => actionBtnEdit(item['id'])}
                      >
                        {editText}
                      </button>
                    )
                  )
                ) : (
                  <></>
                )}
                {customActionButtons &&
                  customActionButtons.map((button) => (
                    <span
                      className={`${button.className}`}
                      onClick={() => button.handleButton(item)}
                    >
                      {button.buttonText}
                    </span>
                  ))}
                {cycleCountcycleOperationBtn && handleButtonModalText && (
                  <button
                    className="btn btn-accept btn-sm"
                    onClick={() => handleButtonModal(item['id'])}
                  >
                    <i className="fas fa-play"></i>
                  </button>
                )}

                {!hideBtnErase ? (
                  !actionBtnErase &&
                  !handleButton &&
                  erase &&
                  !handleButtonRowCondition &&
                  !changeCustomActionErase ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-accept-white"
                      onClick={() => handleDelete(item['id'])}
                    >
                      <img src={trashIcon} alt="trash" className="icon3" />
                    </button>
                  ) : (
                    actionBtnErase &&
                    erase && (
                      <button
                        type="button"
                        className="btn btn-sm btn-accept-white"
                        onClick={() => actionBtnErase(item['id'])}
                      >
                        <img src={trashIcon} alt="trash" className="icon3" />
                      </button>
                    )
                  )
                ) : (
                  <></>
                )}
                {permissionsBtn && !handleButton && !handleButtonRowCondition && (
                  <Link
                    className="btn btn-warning btn-sm btn-accept-white"
                    to={`${path}/permissions/edit/${item['id']}`}
                  >
                    <img src={lockIcon} alt="lock" className="icon3" />
                  </Link>
                )}
                {item.alert &&
                  alertAction &&
                  edit &&
                  !handleButton &&
                  !handleButtonRowCondition && (
                    <Link
                      className="btn btn-accept btn-sm"
                      to={`${path}/refill/${item['id']}`}
                    >
                      {alertActionText}
                    </Link>
                  )}
                {item.alert &&
                  alertAction &&
                  edit &&
                  handleButton &&
                  handleButtonRowCondition && (
                    <Link
                      className="btn btn-accept btn-sm buttonWithoutSpace"
                      to={`${path}/refill/${item['id']}`}
                    >
                      {alertActionText}
                    </Link>
                  )}
              </div>
            </>
          )
        },
      })
    } else if (actionsAddProducts) {
      columns.push({
        name: <div>{titleActions}</div>,
        button: true,
        width: '100px',
        wrap: true,
        className: 'cellEnd',
        cell: (row) => (
          <>
            <div
              className={
                handleButton && handleButtonRowCondition
                  ? 'btn-group actions-buttons-layout-table'
                  : 'btn-group actions-buttons-layout-table'
              }
            >
              {handleButton && (
                <>
                  <span
                    className={`btn btn-accept btn-md ${handleButtonClassName}`}
                    onClick={() => handleButton(row, extraArgHandleButton)}
                  >
                    {handleButtonTitle}
                  </span>
                </>
              )}
            </div>
          </>
        ),
      })
      setPermissionsButtonsLayoutCardTable({
        actions: function (item) {
          return (
            <>
              <div
                className={
                  handleButton && handleButtonRowCondition
                    ? 'btn-group actions-buttons-layout-table btn-card-layout-table'
                    : 'btn-group actions-buttons-layout-table btn-card-layout-table'
                }
              >
                {handleButton && (
                  <>
                    <span
                      className={`btn btn-accept btn-md ${handleButtonClassName}`}
                      onClick={() => handleButton(item, extraArgHandleButton)}
                    >
                      {handleButtonTitle}
                    </span>
                  </>
                )}
              </div>
            </>
          )
        },
      })
    }

    if (actionsInFirstColumn) {
      columns.unshift(columns.pop())
    }

    setColumns(columns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    edit,
    erase,
    permissionsBtn,
    cycleCountcycleOperationBtn,
    path,
    loadingExport,
    loadingImport,
    dataExported,
    loadingData,
    totalCount,
  ])

  //PAGINATION OPTIONS DATA TABLE
  const Options = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    // selectAllRowsItem: true,
    // selectAllRowsItemText: 'Todos',
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.alert,
      style: {
        backgroundColor: alertColor,
        color: alertTextColor,
        fontWeight: textFontWeight,
      },
    },
    {
      when: (row) => row.conditional_alert,
      style: {
        backgroundColor: alertColor,
        color: alertTextColor,
        fontWeight: textFontWeight,
      },
    },
    {
      when: (row) => row.conditional_alert == false,
      style: {
        backgroundColor: alertSecondaryColor,
        color: alertSecondaryTextColor,
        fontWeight: textFontWeight,
      },
    },
    {
      when: (row) => row.conditional_status === 0,
      style: {
        backgroundColor: alertSecondaryColor,
        color: alertSecondaryTextColor,
        fontWeight: textFontWeight,
      },
    },
    {
      when: (row) => row.conditional_status === 2,
      style: {
        backgroundColor: alertSecondaryColor,
        color: alertThirdTextColor,
        fontWeight: textFontWeight,
      },
    },
    {
      when: (row) =>
        row.conditional_status === 1 || row.conditional_status === 3,
      style: {
        backgroundColor: alertSecondaryColor,
        color: alertTextColor,
        fontWeight: textFontWeight,
      },
    },
  ]

  const handleDelete = async (id) => {
    MySwal.fire({
      showCloseButton: true,
      icon: 'warning',
      title: '¿Eliminar registro?',
      html: (
        <>
          <div className="message-container">
            Esta acción no se podra revertir
          </div>
        </>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (requery) {
            await deleteItem({
              variables: {
                id: parseInt(id),
                userId: parseInt(user.idUser),
              },
              refetchQueries: [
                {
                  query: requery,
                  variables: { ...pagePagination },
                },
              ],
            })
          } else {
            await deleteItem({
              variables: {
                id: parseInt(id),
                userId: parseInt(user.idUser),
              },
            })
            await refetchFunctionQueries()
          }
          return toastSweetAlert({
            mode: 'ok',
            message: 'Registro eliminado',
          })
        } catch (error) {
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
    })
  }

  //LOGIC FOR FILTER DATA
  const handleSearch = (value) => {
    const query = value.target.value.toLowerCase()
    if (!query) {
      setCell(data)
      setTotal(totalCount)
    }

    // console.log('query', query)

    if (query && !paginationServer) {
      // console.log(data, 'DATA TO TABLE INFO')
      // console.log('searchByProperty', searchByProperty)
      const dataFilter = data.filter((item) =>
        item[searchByProperty].includes(query)
      )
      setCell(dataFilter)
      setTotal(dataFilter.length)
      return
    }

    if (minCharSearching) {
      if (query.length >= minCharacterSearch) {
        setPagePagination({
          ...pagePagination,
          searchQuery: query,
        })
      } else if (query.length == 0) {
        setPagePagination({
          ...pagePagination,
          searchQuery: null,
        })
      }
    } else {
      if (!query && !paginationServer) {
        return
      }

      setPagePagination({
        ...pagePagination,
        searchQuery: query,
      })
    }
  }

  //LOGIC DATA PAGINATION
  const handlePageChange = (page) => {
    if (!paginationServer) return
    if (mutationAction) {
      localStorage.setItem('limit', 10)
      localStorage.setItem(
        'offset',
        parseInt(localStorage.getItem('limit')) * (page - 1)
      )

      // setPagePaginationMutation({
      //   limit: 10,
      //   offset: 0,
      //   searchQuery: pagePaginationMutation?.searchQuery,
      //   platform: pagePaginationMutation?.platform,
      // })
      mutationAction()
      return
    }
    setPagePagination({
      ...pagePagination,
      offset: pagePagination.limit * (page - 1),
    })
  }

  const handlePerRowsChange = async (newPerPage) => {
    if (!paginationServer) return
    if (mutationAction) {
      localStorage.setItem('limit', newPerPage)

      // setPagePaginationMutation({
      //   ...pagePaginationMutation,
      //   limit: newPerPage,
      // })
      mutationAction()
      return
    }
    setPagePagination({ ...pagePagination, limit: newPerPage })
  }

  const getDataToExport = async () => {
    try {
      let dataExported = null

      if (exportQueryCustom) {
        dataExported = await exportQueryCustom()
      } else {
        dataExported = await exportItems({
          variables: { ...pagePagination },
        })
      }
      const nameOperation = exportQuery.definitions[0].name.value

      const camelCaseNameOperation =
        nameOperation.charAt(0).toLowerCase() + nameOperation.slice(1)

      const fileName = nameOperation.slice(6)
      setFileNameExported(fileName)
      setDataExported(dataExported.data[`${camelCaseNameOperation}`])

      document.getElementById('exportfileCSV').click()

      setLoadingExport(false)
      return toastSweetAlert({
        mode: 'ok',
        message: 'CSV Generado',
      })
    } catch (error) {
      setLoadingExport(false)
      toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const getConfData = () => {
    return importOptions?.confirmationData?.map((item) => {
      return <option value={item.value}>{item.text}</option>
    })
  }

  const changeSelectImport = (e) => {
    selectedValue = e.target.value
    //console.log(e.target.value)
  }

  const importCSVData = async () => {
    try {
      if (importOptions?.confirmation) {
        MySwal.fire({
          html: (
            <div className="p-1">
              <p>{importOptions?.confirmationText}</p>
              <select
                id="select-import-opts2"
                className="form-control position-searchInput pl-1 pr-1"
                required
                onChange={changeSelectImport}
              >
                <option value="">Seleccionar</option>
                {getConfData()}
              </select>
            </div>
          ),
          //title: importOptions?.confirmationTitle,
          showCloseButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Seleccionar archivo',
          cancelButtonText: 'Cancelar',
          buttonsStyling: true,
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,

          customClass: {
            title: 'StandarModalTitle',
            htmlContainer: 'StandarModalContainer',
            confirmButton: 'StandarModalConfirmButtonLogOut',
            denyButton: 'StandarModalCancelButtonLogOut',
            cancelButton: 'StandarModalCancelButtonLogOut',
            footer: 'StandarModalFooterRejectOrder',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            //console.log({selectedValue})
            document.getElementById('importfileCSV').click()
          }
        })
      } else {
        document.getElementById('importfileCSV').click()
      }
    } catch (error) {
      toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleFileCSV = async (e) => {
    try {
      if (!e.target.files[0]) {
        return
      }
      for (const file of e.target.files) {
        if (file.type !== 'text/csv')
          return toastSweetAlert({
            mode: 'error',
            message: 'Solo se admiten archivos CSV',
          })
      }
      if (e.target.files.length < maximumFilesImport) {
        return toastSweetAlert({
          mode: 'error',
          message: `Esta importación requiere mínimo de ${maximumFilesImport} ${
            maximumFilesImport > 1 ? 'documentos.' : 'documento.'
          }
          Archivos necesarios: ${importMessageValidation}
          `,
        })
      }

      if (e.target.files.length > maximumFilesImport) {
        return toastSweetAlert({
          mode: 'error',
          message: `Esta importación admite un máximo de ${maximumFilesImport} ${
            maximumFilesImport > 1 ? 'documentos.' : 'documento.'
          }
          Archivos necesarios: ${importMessageValidation}
          `,
        })
      }

      // const validate = await fileValidator(e.target.files[0], 'all')

      // if (!validate) {
      //   return toastSweetAlert({
      //     mode: 'error',
      //     message: 'Solo se admite CSV',
      //   })
      // }
      setLoadingImport(true)
      toastSweetAlert({
        mode: 'loading',
        message: 'Cargando información, espere...',
      })

      let variables = {
        files: e.target.files,
      }

      if (importCustomVariables) {
        importCustomVariables.map((variable) => {
          variables[Object.keys(variable)] = Object.values(variable)[0]
        })
      }

      //console.log({ variable: importOptions?.confirmationDataVariable, selectedValue })

      if (importOptions?.variable !== null) {
        let val = null

        switch (importOptions?.variable?.type) {
          case 'int':
            val = parseInt(selectedValue)
            break

          case 'float':
            val = parseFloat(selectedValue)
            break

          default:
            //string
            val = selectedValue
            break
        }

        variables[importOptions?.variable?.name] = val
      }

      await importItems({
        variables,
      })

      setLoadingImport(false)

      if (refetchFunctionQueries) await refetchFunctionQueries()

      return toastSweetAlert({
        mode: 'ok',
        message: messageSuccesImport,
      })
    } catch (error) {
      setLoadingImport(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const disableDates = () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    return `${yyyy}-${mm}-${dd}`
  }

  const subHeaderComponent = (
    <div
      id="headerFilters"
      className="row"
      style={{
        justifyContent: hideFilterBar && filter ? 'end' : 'space-between',
        zIndex: 99,
      }}
    >
      {!hideFilterBar && (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          {filter && filterLabelText && (
            <label style={{ visibility: 'hidden' }}>hide</label>
          )}
          <input
            id={searchId}
            type="text"
            className="input-field form-control position-searchInput"
            placeholder={
              FilterBarPlaceholder ? FilterBarPlaceholder : 'Buscar...'
            }
            onChange={debounce(handleSearch, 300)}
          />
        </div>
      )}
      {filter && (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          {filterLabelText && <label htmlFor="filter">{filterLabelText}</label>}
          <select
            id="filter"
            name="filter"
            type="select"
            className="input-field form-control position-searchInput"
            placeholder="Selecciona una opción"
            onChange={filterOnChange}
            defaultValue={defaultValueSelect ? defaultValueSelect : ''}
          >
            <option
              value=""
              readOnly={defaultValueSelect}
              disabled={defaultValueSelect}
            >
              {filterDefaultOptionText ? filterDefaultOptionText : 'Todos'}
            </option>
            {filterOptions.map((option) => option)}
          </select>
        </div>
      )}
      {filterSecond && (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          {filterLabelTextSecond && (
            <label htmlFor="filterSecond">{filterLabelTextSecond}</label>
          )}
          <select
            id="filterSecond"
            name="filterSecond"
            type="select"
            className="input-field form-control position-searchInput"
            placeholder="Selecciona una opción"
            onChange={filterOnChangeSecond}
            defaultValue={
              defaultValueSelectSecond ? defaultValueSelectSecond : ''
            }
          >
            <option
              value=""
              readOnly={defaultValueSelectSecond}
              disabled={defaultValueSelectSecond}
            >
              {filterDefaultOptionTextSecond
                ? filterDefaultOptionTextSecond
                : 'Todos'}
            </option>
            {filterOptionsSecond.map((option) => option)}
          </select>
        </div>
      )}
      {filterInput && (
        <div
          className="col-4"
          style={{
            zIndex: 2,
            padding: '0px',
            margin: '0px',
            marginLeft: '-6px',
          }}
        >
          <InputController
            // label="Rack"
            type="number"
            inputType="choosen"
            customHandleChange={handleFilterInput}
            name={filterInputName}
            control={filterInputControl}
            options={filterInputOptions}
          />
        </div>
      )}
      {filterChoosen &&
        filterChoosen.map(
          (filter) =>
            !filter.condition && (
              // <div
              //   className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4"
              //   style={{
              //     zIndex: 2,
              //     marginTop: '0px',
              //   }}
              // >
              // {/* {console.log('filter.options', filter)} */}
              <>
                {/* <label htmlFor={filter.id}>{filter.label}</label> */}
                <Select
                  id={filter.id}
                  name={filter.name}
                  className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 ${filter.className}`}
                  placeholder={filter.placeholder}
                  noOptionsMessage={filter.noOptionsMessage}
                  options={filter.options}
                  onBlur={filter.onBlur}
                  onChange={filter.onChange}
                  filterOption={filter.filterOption}
                  isMulti={filter.isMulti}
                  isClearable={filter.isClearable}
                  isSearchable={filter.isSearchable}
                  isDisabled={filter.isDisabled}
                  isOptionDisabled={filter.isOptionDisabled}
                  styles={filter.styles}
                  readOnly={filter.readOnly}
                  // value = {}
                />
                {/* // </div> */}
              </>
            )
        )}
      <div className="col-12">
        <div className="row">
          {filterRangeDateFrom && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <label htmlFor={idFilterRangeDateFrom}>De:</label>
              <input
                id={idFilterRangeDateFrom}
                name="filterRangeDateFrom"
                type="date"
                className="input-field form-control position-searchInput"
                onChange={filterOnChangeRangeDateFrom}
                // defaultValue={moment().format('YYYY-MM-DD')}
                // min={disableDates()}
              />
            </div>
          )}
          {filterRangeDateTo && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <label htmlFor={idFilterRangeDateTo}>A:</label>
              <input
                id={idFilterRangeDateTo}
                name="filterRangeDateTo"
                type="date"
                className="input-field form-control position-searchInput"
                onChange={filterOnChangeRangeDateTo}
                // defaultValue={moment().format('YYYY-MM-DD')}
                // min={disableDates()}
              />
            </div>
          )}
        </div>
      </div>
      {filterCheckBox && (
        <div className="col-12 d-flex">
          {filterCheckBox1 && (
            <div className="col-lg-3 form-check ">
              <input
                id="Checkbox1"
                name="Checkbox1"
                type="checkbox"
                className="form-check-input"
                onChange={filterOnChangeBox}
                checked={valueChkBox}
              />
              <label className="form-check-label" htmlFor="Checkbox1">
                {lblChkBox}
              </label>
            </div>
          )}
          {filterCheckBox2 && (
            <div className="col-lg-3 form-check">
              <input
                id="Checkbox2"
                name="Checkbox2"
                type="checkbox"
                className="form-check-input"
                onChange={filterOnChangeBox2}
                checked={valueChkBox2}
              />
              <label className="form-check-label " htmlFor="Checkbox2">
                {lblChkBox2}
              </label>
            </div>
          )}
          {filterCheckBox3 && (
            <div className="col-lg-3 form-check">
              <input
                id="Checkbox3"
                name="Checkbox3"
                type="checkbox"
                className="form-check-input"
                onChange={filterOnChangeBox3}
                // checked={valueChkBox3}
              />
              <label className="form-check-label " htmlFor="Checkbox3">
                {lblChkBox3}
              </label>
            </div>
          )}
          {filterCheckBox4 && (
            <div className="col-lg-3 form-check">
              <input
                id="Checkbox4"
                name="Checkbox4"
                type="checkbox"
                className="form-check-input"
                onChange={filterOnChangeBox4}
                // checked={valueChkBox4}
              />
              <label className="form-check-label " htmlFor="Checkbox4">
                {lblChkBox4}
              </label>
            </div>
          )}
        </div>
      )}
      {filterOnChangeDate && (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          {filterLabelTextSecond && (
            <label htmlFor="filterDate">{filterLabelDate}</label>
          )}
          <input
            id="filterDate"
            name="filterDate"
            type="date"
            className="input-field form-control position-searchInput"
            onChange={filterOnChangeDate}
            defaultValue={new Date()}
            min={disableDates()}
          />
        </div>
      )}
      {customFilterButtonInput && customFilterButtonInput}
      {extraCompFilter}
    </div>
  )

  return (
    <div
      className={`${withCard && 'card'} layout-configs ${
        loadingImport ? 'processing-file' : ''
      } ${classes}`}
    >
      <div className={`${withCard && 'card-header'}`}>
        <h3 className="card-title header-title">{title}</h3>
        <div className="control-btn-box">
          <>
            <div
              className="btn-group actions-btn"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <div className="btn-group" role="group">
                {exportImportButton &&
                  headersButtons &&
                  (importBtn || exportBtn) && (
                    <>
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-sm btn-accept-white"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        disabled={loadingExport}
                      >
                        <img
                          src={downloadIcon}
                          alt="download"
                          className="icon3"
                        />
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <>
                          {extraButtonsImportExport && extraButtonsImportExport}
                          {exportBtn && (
                            <>
                              <CSVLink
                                id="exportfileCSV"
                                filename={FileNameExported}
                                data={dataExported}
                                className="hide"
                                uFEFF={desactiveBOM}
                              ></CSVLink>
                              <span
                                className="dropdown-item pointer"
                                onClick={getDataToExport}
                              >
                                {exportBtnText}
                              </span>
                            </>
                          )}
                          {importBtn && specialImportRoute ? (
                            <>
                              <span
                                className="dropdown-item pointer"
                                onClick={history.push(specialImportRoute)}
                              >
                                <i className="fas fa-file-import"></i>{' '}
                                {importBtnText}
                              </span>
                            </>
                          ) : (
                            importBtn && (
                              <>
                                <input
                                  type="file"
                                  id="importfileCSV"
                                  className="hide"
                                  onChange={(e) => handleFileCSV(e)}
                                  multiple={
                                    maximumFilesImport > 1 ? true : false
                                  }
                                  accept={'.csv'}
                                />
                                <span
                                  className="dropdown-item pointer"
                                  onClick={importCSVData}
                                >
                                  {importBtnText}
                                </span>
                                <CSVLink
                                  id="templateCSV"
                                  filename="Plantilla"
                                  data={templateDataImport}
                                  className="hide"
                                  uFEFF={desactiveBOM}
                                ></CSVLink>
                                <span
                                  className="dropdown-item pointer"
                                  onClick={() =>
                                    document
                                      .getElementById('templateCSV')
                                      .click()
                                  }
                                >
                                  <i className="fas fa-file-excel"></i>{' '}
                                  &nbsp;Plantilla CSV
                                </span>
                                {templateDataImport && (
                                  <>
                                    <CSVLink
                                      id="templateCSV"
                                      filename="Plantilla"
                                      data={templateDataImport}
                                      className="hide"
                                    ></CSVLink>
                                    <span
                                      className="dropdown-item pointer"
                                      onClick={() =>
                                        document
                                          .getElementById('templateCSV')
                                          .click()
                                      }
                                    >
                                      {templateImportBtnText}
                                    </span>
                                  </>
                                )}
                              </>
                            )
                          )}
                        </>
                      </div>
                    </>
                  )}
              </div>

              {enableCollapse && (
                <>
                  <div className="text-right">
                    <button
                      className="btn text-white"
                      onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                      <i
                        className={isCollapsed ? 'fa fa-plus' : 'fa fa-minus'}
                      ></i>
                    </button>
                  </div>
                </>
              )}
              {create && headersButtons && !hideAddNew && (
                <Link to={`${path}/new`} className="btn btn-accept btn-sm">
                  <i className="fa fa-plus"></i>{' '}
                  {addBtnTxt ? addBtnTxt : 'Agregar nuevo  '}
                </Link>
              )}

              {create && headersButtons && hideAddNew && selectOptions && (
                <select
                  name="selectOptions"
                  id="selectOptions"
                  onChange={handleSelectOptions}
                >
                  {/* <option value={null}>{selectOptionsText ? selectOptionsText : 'Seleccione una opcion'}</option> */}
                  {selectOptions &&
                    selectOptions.map((optionItem, index) => {
                      return (
                        <option value={optionItem.value} selected={index == 0}>
                          {optionItem.name}
                        </option>
                      )
                    })}
                </select>
              )}

              {create && headersButtons && extraHeaderButton && (
                <Link
                  to={extraHeaderButtonTo}
                  className="btn btn-accept btn-sm"
                >
                  {extraHeaderText}
                </Link>
              )}
              {create && headersButtons && extraHeaderButtonFunction && (
                <span
                  onClick={extraHeaderButtonFunction}
                  className="btn btn-accept btn-sm"
                >
                  {extraHeaderButtonFunctionText}
                </span>
              )}
              {headerButtonsNoPermissions && headerButtonsNoPermissions}
              {headerComponents && headerComponents}
            </div>
          </>
        </div>
      </div>
      <div
        className={
          'card-body' + (enableCollapse && isCollapsed ? ' d-none' : '')
        }
      >
        <div
          className={`${
            listFormat
              ? 'hidePagination'
              : hidePagination && cell.length < 10
              ? 'hidePagination'
              : ''
          }`}
        >
          {layoutCardMobile && detectMobileSize ? (
            <LayoutCardTable
              data={cell}
              paginationComponentOptions={Options}
              actionsButtons={permissionsButtonsLayoutCardTable}
              pointerOnHover
              subHeaderComponent={subHeaderComponent}
              conditionalRowStyles={conditionalRowStyles}
              paginationServer={paginationServer}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={total}
              noDataComponent={noDataComponent}
              progressPending={loadingData}
              progressComponent={<Loader />}
              hideId
            />
          ) : (
            showDataTable && (
              <DataTable
                selectableRows={selectableRows}
                selectableRowsComponent={selectableRowsComponent}
                onSelectedRowsChange={selectableRowsClick}
                // selectableRowSelected={
                //   selectableRowSelected ? rowSelectCriteria : false
                // }
                // paginationServerOptions={{
                //   persistSelectedOnSort: true,
                //   persistSelectedOnPageChange: true,
                // }}
                paginationServerOptions={paginationServerOptions}
                clearSelectedRows={clearSelectedRows}
                selectableRowsHighlight={selectableRows ? true : false}
                selectableRowDisabled={rowDisabledCriteria}
                selectable
                columns={columns}
                data={cell}
                expandOnRowClicked
                expandableRows={expandableRows}
                expandableRowsComponent={expandableRowsComponent}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                highlightOnHover
                pagination
                paginationComponentOptions={Options}
                pointerOnHover
                paginationPerPage={pagePagination?.limit}
                // responsive
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderWrap
                conditional
                responsive
                // actions={
                //   platformSelector && (
                //     <div className="platform-selector">
                //       <div className="row">
                //         <div className="col-s6 mr-3">
                //           <i className="fa fa-filter"></i>
                //         </div>
                //         <div className="col-s6">
                //           <select
                //             className="form-control"
                //             type="select"
                //             onChange={(e) =>
                //               setPagePagination({
                //                 ...pagePagination,
                //                 platform: parseInt(e.target.value),
                //               })
                //             }
                //           >
                //             <option value="" disabled>
                //               Selecciona una opción
                //             </option>
                //             <option value="">Todas</option>
                //             <option value="1">Woocomerce</option>
                //             <option value="2">MercadoLibre</option>
                //             <option value="3">Amazon</option>
                //           </select>
                //         </div>
                //       </div>
                //     </div>
                //   )
                // }
                keyField={keyField}
                subHeaderComponent={subHeaderComponent}
                conditionalRowStyles={conditionalRowStyles}
                paginationServer={paginationServer}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationTotalRows={totalCount}
                noDataComponent={noDataComponent}
                progressPending={loadingData}
                progressComponent={<Loader />}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              />
            )
          )}

          {append && append}
        </div>
      </div>
    </div>
  )
}
export default LayoutTable
