import { useEffect, useState } from 'react'
import LayoutTable from '../../../Global/LayoutTable'
import ModalContent from '../../../Global/ModalContent'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_PENDING_BOXES_TO_LOAD } from '../../../../graphql/Catalog/Routes/routes'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'

const BoxesPendingLoadedModal = ({ refModal, routeId }) => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [getPendingBoxes, { loading }] = useLazyQuery(GET_PENDING_BOXES_TO_LOAD)

  useEffect(() => {
    if (routeId) {
      const getBoxes = async () => {
        try {
          const { data } = await getPendingBoxes({
            variables: {
              getAllPendingBoxesToLoadId: +routeId,
              searchQuery: pagePagination.searchQuery,
              limit: pagePagination.limit,
              offset: pagePagination.offset,
            },
            fetchPolicy: 'no-cache',
          })

          const list = data?.getAllPendingBoxesToLoad?.rows.map((item) => {
            return {
              box_id: item?.id,
              sku: item?.item_code ? item?.item_code : 'Mixtas',
              cantidad: item?.PackingListBoxesLines[0]?.quantity,
              ['nombre del producto']: item?.item_code
                ? item?.PackingListBoxesLines[0]?.SapItem.item_name
                : 'Personalizada',
            }
          })
          if (data) {
            setDataToTable(list)
            setTotal(data?.getAllPendingBoxesToLoad?.count)
          }
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }

      getBoxes()
    }
  }, [routeId, pagePagination])

  return (
    <>
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#boxesPerndingLoaded"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="boxesPerndingLoaded"
        title="Cajas pendientes "
        size="large"
        content={
          <>
            <LayoutTable
              data={dataToTable}
              loadingData={loading}
              hideAddNew={true}
              FilterBarPlaceholder="Buscar por box_id o sku"
              withCard={false}
              hideDetails={true}
              hideBtnEdit={true}
              hideBtnErase={true}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              totalCount={total}
              exportImportButton={false}
              noDataComponent="No hay más cajas pendientes"
              hideActions={true}
            />
          </>
        }
      />
    </>
  )
}

export default BoxesPendingLoadedModal
