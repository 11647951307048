import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import moment from 'moment'
import OrderButtons from '../../../OrderButtons'
import { GET_ORDER_BY_ID_STATUS } from '../../../../graphql/Catalog/Orders/pendingOrders'
import OrderGeneral from '../../../OrderGeneral'
import OrderDetailStore from '../../../OrderDetailStore'
import OrderShippingDetails from '../../../OrderShippingDetails'
import OrderProducts from '../../../OrderProducts'
import Timeline from '../../../Global/Timeline/Timeline'
import InternalNotes from '../../../Global/InternalNotes/InternalNotes'
import { GET_ALL_ISSUES } from '../../../../graphql/Catalog/Issues/issues'
import 'moment/locale/es'

export const DetailsOrder = ({ _id }) => {
    const history = useHistory()
    const [storeId, setStoreId] = useState('')
    const [idOrder, setIdOrder] = useState('')
    const [storeFromDB, setStoreFromDB] = useState(false)
    const [dataOrder, setDataOrder] = useState([])
    const [processing, setProcessing] = useState(false)

    const { data, loading, error } = useQuery(GET_ORDER_BY_ID_STATUS, {
        variables: {
        id: parseInt(_id),
        statusId: [2],
        typeId: null,
        },
    })

    /* GET ALL ISSUSES DATA */
    const {
        data: dataIssuses,
        loading: loadingIssuses,
        error: errorIssuses,
    } = useQuery(GET_ALL_ISSUES)

    useEffect(() => {
        if (!loading) {
            if (error)
            return (
                toastSweetAlert({
                mode: 'error',
                message: error.message,
                }),
                history.push('/dashboard')
            )

            const orderData = data.getOrderByIdAndStatus
            if (orderData.store?.name || orderData.warehouse?.name)
                setStoreFromDB(true)
                setStoreId(orderData.store_id)
                setIdOrder(orderData.order_id)
                setDataOrder(orderData)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error])

    /* SET DATA TO NEW OBJ */
    useEffect(() => {
        if (!loadingIssuses && !errorIssuses) {
            let list = {}
            dataIssuses.getAllIssusses.map((item) => {
            list = Object.assign(list, { [item.id]: item.name })
                return true
            })
        }
    }, [dataIssuses, loadingIssuses, errorIssuses])

    const handleStoreChange = (e) => {
        setStoreId(e.value)
    }

    moment.locale('es') //Change dates languages to spanish

    return(
        <div className={`row ${processing ? 'processing-file' : ''}`}>
        <div className="col-8">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles</h3>
                </div>
                <div className="card-body col-12">
                  <OrderGeneral
                    orderDate={moment(dataOrder?.order_date).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                    orderId={dataOrder?.order_id}
                    paymentId={dataOrder?.payment?.payment_id}
                    platform={dataOrder?.platform?.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de pago</h3>
                </div>
                <div className="card-body">
                  <OrderDetailStore
                    orderType={dataOrder?.type?.name}
                    orderStore={
                      dataOrder?.store?.name || dataOrder?.warehouse?.name
                    }
                    orderPaymentPlatform={dataOrder?.payment?.platform}
                    orderMethod={dataOrder?.method?.name}
                    withStore={storeFromDB}
                    totalPrice={dataOrder?.total_price}
                    shippingPrice={dataOrder?.shipping_price}
                    handleStoreChange={handleStoreChange}
                    id_store={storeId}
                    status={data?.getOrderById?.status?.id}
                    shippingReceipt={dataOrder?.shipping?.receipt?.url}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Desglose de productos</h3>
                </div>
                <div className="card-body">
                  <OrderProducts
                    productsArray={dataOrder?.products}
                    totalQuantity={dataOrder?.product_quantity}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detalles de envío</h3>
                </div>
                <div className="card-body">
                  <OrderShippingDetails
                    city={dataOrder?.shipping?.city}
                    address={dataOrder?.shipping?.address_1}
                    postcode={dataOrder?.shipping?.postcode}
                    email={dataOrder?.shipping?.email}
                    phone={dataOrder?.shipping?.phone}
                    firstName={dataOrder?.shipping?.first_name}
                    lastName={dataOrder?.shipping?.last_name}
                    state={dataOrder?.shipping?.state}
                  />
                </div>
              </div>
            </div>
            <OrderButtons
            oneButton={true}
            onProcess={() => history.goBack()}
            firstButtonGray={true}
            processBtn="Regresar"
            />
          </div>
        </div>

        {/* TIMELINE & INTERNAL NOTES */}
        <div className="col-4">
          <Timeline idOrder={idOrder} />
          {/* <InternalNotes idOrder={idOrder} /> */}
        </div>
        </div>
    )

}

export default DetailsOrder