import { useMutation, useQuery } from "@apollo/client"
import { useEffect, useRef, useState } from "react"
import { GET_ORDERS_ASSORTED, GET_ORDERS_ASSORTED_COMPARATIVE } from "../../../graphql/Catalog/WarehouseDashboard/pickingDashboard"
import { CREATE_WAREHOUSES_REPORTS } from "../../../graphql/Catalog/WarehouseDashboard/warehouseDashboard"
import { toastSweetAlert } from "../../Helpers/ToastSweetAlert"
import { validateDatesForCreateReports } from "../../WarehouseDashboards/warehouseOperationValidations"
import ModalWarehouseReports from "../Global/ModalWarehouseReports"
import { PowerBIEmbed } from 'powerbi-client-react'
import {models} from 'powerbi-client'
import PickingDashboardSection from "./component/PickingDashboardSection"
import { GET_POWER_BI_TOKEN } from "../../../graphql/Documents/PowerBi/powerBi"

const PickingDashboard = () => {
    /**-------- Hooks */
    //To save reports data
    const [form,setForm] = useState({
      start: '',
      end: '',
      reportsType: 0,
    })
    /**Hook to show inputs dates */
    const refModal = useRef()

    const [loading, setLoading] = useState(true)

    const [accessToken,setAccessToken] = useState('')
    // const [firstSection,setFirstSection] = useState([])
    // const [secondSection,setSecondSection] = useState([])

    /**------------ Queries */
    // const {
    //   data: dataOrdersAssortedTotal,
    //   loading: loadingOrdersAssortedTotal,
    //   error: errorOrdersAssortedTotal,
    // } = useQuery(GET_ORDERS_ASSORTED)

    // const {
    //   data: dataOrdersAssortedComparative,
    //   loading: loadingOrdersAssortedComparative,
    //   error: errorOrdersAssortedComparative,
    // } = useQuery(GET_ORDERS_ASSORTED_COMPARATIVE)

    const {
      data: dataPowerBiToken,
      loading: loadingPowerBiToken,
      error: errorPowerBiToken,
    } = useQuery(GET_POWER_BI_TOKEN)

    /**------------ Mutations */
    const [CreateWarehousesReports] = useMutation(CREATE_WAREHOUSES_REPORTS)

    /**------------ useEffects */
    useEffect(() => {
      if (
        errorPowerBiToken 
      )
        return toastSweetAlert({
          mode: 'error',
          message: 'something has happend',
        })

      if (
        !loadingPowerBiToken
      ){

        // /**Fill first section */
        // setFirstSection({
        //   assortedOrdersQuantity: dataOrdersAssortedTotal.getOrdersAssorted.quantity, //Cantidad de ordenes surtidas
        // })

        // //Get graphics
        // let resOrders = []
        // dataOrdersAssortedComparative?.
        // getOrdersAssortedComparative?.
        // ordersAssortedDataSevenDaysAgo?.map((itemOrder) => {
        //   resOrders.push({
        //     name: itemOrder.date,
        //     y: itemOrder.quantity,
        //     drilldown: itemOrder.date
        //   })
        // })

        // /**Fill second section */
        // setSecondSection({
        //   assortedOrdersQuantities: { //Cantidad de ordenes surtidas
        //     totalOrders: dataOrdersAssortedComparative.getOrdersAssortedComparative.quantity,
        //     totalOrdersGraphics: resOrders,
        //   },
        // })

        //Get Access token
        const token = dataPowerBiToken?.getPowerBiToken?.token ? dataPowerBiToken.getPowerBiToken.token : null
        setAccessToken(token)

        setLoading(false)
      }


    },[
      // //Orders Assorted Total
      // dataOrdersAssortedTotal,
      // loadingOrdersAssortedTotal,
      // errorOrdersAssortedTotal,

      // //Orders Assorted Comparative
      // dataOrdersAssortedComparative,
      // loadingOrdersAssortedComparative,
      // errorOrdersAssortedComparative,

      dataPowerBiToken,
      loadingPowerBiToken,
      errorPowerBiToken,
    ])

    /**-------- functions */
    const formmatNumber = (number) => {
      let numberFormat = new Intl.NumberFormat('es-MX', {
        maximumFractionDigits: 4,
      }).format(number)
  
      return numberFormat
    }

    const handleChangeDates = (e,field) => {
        setForm({
          ...form,
          [field]: e.target.value
        })
    }

    const reportFilterHandleButton = async () => {
        try {
          const {start,end,reportsType} = form

          if(reportsType == 5) {//Custom range
            //Validate data
            const getValidation = validateDatesForCreateReports({
              rangeStart: start,
              rangeEnd: end,
            })
            if(getValidation.error) return toastSweetAlert({
              mode: 'error',
              message: getValidation.msg,
            })
          }

          //Reset hook
          setForm({
            start: '',
            end: '',
          })
          refModal.current.click()

          //All correct - proceed
          toastSweetAlert({
            mode: 'loading',
            message: 'Iniciando proceso, puede tardar algunos minutos',
          })
    
          //Create mutation to send information
          const res = await CreateWarehousesReports({
            variables: {
              start,
              end,
              reportsType,
              mode: 2,// Picking
            }
          })

          const {status,message} = res.data.createWarehousesReports
    
          if(!status)
            return toastSweetAlert({
              mode: 'error',
              message: message,
            })
    
          return toastSweetAlert({
            mode: 'ok',
            message: message,
          })
    
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
    }

    return (
      <>
        <div className="content-header">
          <div className="container-fluid">
            <div className="py-2 d-flex justify-content-between">
              <h1 className="font-weight-bold">Picking</h1>
                <button
                  type="button"
                  className="btn StandarModalConfirmButtonLogOut"
                  onClick={ () => { refModal.current.click() } }
                >
                  Generar reporte
                </button>
            </div>
          </div>
        </div>

        {/**Generate report button for Warehouse */}
        <ModalWarehouseReports 
          refModal={refModal}
          handleSubmit={reportFilterHandleButton}
          setForm={setForm}
          form={form}
        />

        {!loading && (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {/* <PickingDashboardSection 
              formmatNumber={formmatNumber}
              firstSection={firstSection}
              secondSection={secondSection}
            /> */}

            <PowerBIEmbed
              embedConfig = {
                {
                  type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                  id: '931372c6-f831-4eb0-a429-42569b0fe663',
                  embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=8c030dcb-b8ae-4dfd-9796-1caf5592a145&groupId=57164b8e-8e11-4618-a744-30ca22b26d35&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUNBTkFEQS1DRU5UUkFMLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
                  accessToken,
                  tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false
                      }
                    },
                  }
                }
              }
                
              eventHandlers = {
                new Map([
                  ['loaded', function () {
                    console.log('Report loaded');
                  }],
                  ['rendered', function () {
                    console.log('Report rendered');
                  }],
                  ['error', function (event) {
                    console.log(event.detail);
                  }]
                ])
              }
                
              cssClassName = {
                "embed-container"
              }
                
              getEmbeddedComponent = {
                (embeddedReport) => {
                  window.report = embeddedReport;
                }
              }
            />
          </div>
        </div>
        )}
      </>
    )
}

export default PickingDashboard