import ModalContent from '../../Global/ModalContent'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import InputController from '../../Global/InputController'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_REGISTER_FOR_VEHICLE,
  GET_ALL_REGISTER_VEHICLES,
  GET_VEHICLE_OPTIONS,
} from '../../../graphql/Documents/Schedule/ScheduleVehicle'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

const VehicleRegisterReceiptModal = ({ refModal }) => {
  const [businessPartnerOptions, setBusinesspartnerOptions] = useState([])
  const [vehiclesTypesOptions, setVehiclesTypesOptions] = useState([])
  const [parcelOptions, setParcelOptions] = useState([])

  const validationSchema = Yup.object().shape({
    business_partner_id: Yup.string().required('campo requerido'),
    vehicle_type_id: Yup.string().required('campo requerido'),
    shipping_companies_id: Yup.string().required('campo requerido'),
    plates: Yup.string().required('campo requerido'),
  })

  const { methods, handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const [createScheduleForVehicle] = useMutation(CREATE_REGISTER_FOR_VEHICLE)
  const { data, error, loading } = useQuery(GET_VEHICLE_OPTIONS)

  const handleCancelButton = () => {
    refModal.current.click()
  }

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const businessPartnerOptions =
        data.getLoadingDockControlOptions.business_partners.map(
          (itemBusinessPartner) => {
            return {
              value: itemBusinessPartner.id,
              label: itemBusinessPartner.card_name,
            }
          }
        )
      const vehicleTypeOptions =
        data.getLoadingDockControlOptions.vehicles_types.map(
          (itemVehiclesTypes) => {
            return {
              value: itemVehiclesTypes.id,
              label: itemVehiclesTypes.name,
            }
          }
        )
      const parcelServicesOptions =
        data.getLoadingDockControlOptions.parcel_services.map((itemParcel) => {
          return {
            value: itemParcel.id,
            label: itemParcel.name,
          }
        })

      setBusinesspartnerOptions(businessPartnerOptions)
      setVehiclesTypesOptions(vehicleTypeOptions)
      setParcelOptions(parcelServicesOptions)
    }
  }, [data, error, loading])

  const onSubmit = async (data) => {
    try {
      await createScheduleForVehicle({
        variables: {
          input: {
            business_partner_id: +data.business_partner_id,
            vehicle_type_id: +data.vehicle_type_id,
            shipping_companies_id: +data.shipping_companies_id,
            plates: data.plates,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_REGISTER_VEHICLES,
            variables: {
              searchQuery: null,
              limit: 10,
              offset: 0,
            },
          },
        ],
      })
      setValue('vehicle_type_id', '')
      setValue('business_partner_id', '')
      setValue('shipping_companies_id', '')
      reset()
      refModal.current.click()
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <span
        ref={refModal}
        className="btn btn-accept btn-sm invisible"
        data-toggle="modal"
        data-target="#agregarVehiculo"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="agregarVehiculo"
        title="Agregar vehiculo"
        size="large"
        // onClose={handleCancelButton}
        content={
          <>
            <form
              className="row justify-content-center align-items-center p-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-3  col-md-6 col-sm-12 vehicle-input-label">
                <InputController
                  label="PROVEEDOR"
                  inputType="choosen"
                  options={businessPartnerOptions}
                  // valueManual={''}
                  name="business_partner_id"
                  control={control}
                />
              </div>
              <div className="mb-3  col-md-6 col-sm-12 vehicle-input-label">
                <InputController
                  label="TIPO DE VEHÍCULO"
                  inputType="choosen"
                  options={vehiclesTypesOptions}
                  // valueManual={valueChoosen}
                  name="vehicle_type_id"
                  control={control}
                />
              </div>
              <div className="mb-3  col-md-6 col-sm-12 vehicle-input-label">
                <InputController
                  label="PAQUETERIA"
                  inputType="choosen"
                  options={parcelOptions}
                  // valueManual={valueChoosen}
                  name="shipping_companies_id"
                  control={control}
                />
              </div>
              <div className="mb-3  col-md-6 col-sm-12 vehicle-input-label">
                <InputController
                  label="PLACAS"
                  type="text"
                  name="plates"
                  placeholder="Ingrese las placas del vehiculo"
                  control={control}
                />
              </div>
              <div className="col-12 d-flex justify-content-end mt-3">
                <button
                  className=" ShortModalCancelButton  w-same"
                  type="button"
                  onClick={handleCancelButton}
                >
                  Cancelar
                </button>

                <button
                  type="submit"
                  className="ShortModalConfirmButton w-same"
                >
                  Agregar
                </button>
              </div>
            </form>
          </>
        }
      />
    </>
  )
}

export default VehicleRegisterReceiptModal
