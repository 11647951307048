import React, { useState, useEffect } from 'react'
import OrderCol from '../../Global/OrderCol'
import BoxCollapsible from '../../Global/BoxCollapsible'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useQuery } from '@apollo/client'
import { GET_CUSTOMER_BY_ID } from '../../../graphql/Catalog/Telemarketing/Customers/customers'

export const DetailsCustomer = ({
    key,
    _id,
    headers = [],
    withBox = true,
    customData
}) => {

    //API get data customer selected
    const { loading: loadingCustomer, error: errorCustomer, data: dataCustomer } = useQuery(GET_CUSTOMER_BY_ID, {
    variables: {
        "getCustomerByIdId": _id
    },
    })

    //save data customer
    const [dataClient, setDataClient] = useState([])

    //Save data of customer of query
    useEffect(() => {
    if (!loadingCustomer) {
        if (errorCustomer) toastSweetAlert({
        mode:'error',
        message:errorCustomer.message,
        })

        let list = {
            Nombre: dataCustomer?.GetCustomerById?.first_name,
            Apellidos: dataCustomer?.GetCustomerById?.last_name,
            "Teléfono": dataCustomer?.GetCustomerById?.phone,
            "Correo electrónico": dataCustomer?.GetCustomerById?.email,
            "Dirección": dataCustomer?.GetCustomerById?.CustomerAddress?.direction,
            Referencia: dataCustomer?.GetCustomerById?.CustomerAddress?.direction_reference,
            Entre: dataCustomer?.GetCustomerById?.CustomerAddress?.between_streets,
            "Código postal": dataCustomer?.GetCustomerById?.CustomerAddress?.zip_code,
            Colonia: dataCustomer?.GetCustomerById?.CustomerAddress?.CustomerColony.name,
            Estado: dataCustomer?.GetCustomerById?.CustomerAddress?.CustomerState.name,
            RFC: dataCustomer?.GetCustomerById?.rfc,
        }
        list = Object.entries(list).map(( [k, v] ) => (v));
        setDataClient(list)
    }
    }, [dataCustomer])

  return(
    withBox ? 
        <BoxCollapsible
            key={key}
            title={"Cliente"}
            icon=""
            nestedContent={true}
            customizedDefault={true}
            content={
                <div className="col-12">
                    <div className="row">
                        {customData ? headers.map((item, index) => {
                            return <OrderCol title={item} content={customData[index]} mediumColSize="3" />
                        }) 
                        : headers?.map((item, index) => {
                            return <OrderCol title={item} content={dataClient[index]} mediumColSize="3" />
                        })}
                    </div>
                </div>
            }
        />
    :
    <>
        <div className="col-12">
            <div className="row">
                {headers?.map((item, index) => {
                    return <OrderCol title={item} content={dataClient[index]} mediumColSize="3" />
                })}
            </div>
        </div>
    </>
  )
}
export default DetailsCustomer