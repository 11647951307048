import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_PACKING_LIST_BOXES_BY_PALLET_ID, GET_PACKING_LIST_BY_PALLET_ID } from "../../../../graphql/Catalog/Transfers/Transfers";
import { toastSweetAlert } from "../../../Helpers/ToastSweetAlert";
import LayoutTable from "../../../Global/LayoutTable";
import BoxesByPallet from "./BoxesByPallet";

const PalletByPackingList = ({ packingListId }) => {

    const [dataToTable, setDataToTable] = useState([]);
    const [total, setTotal] = useState(0);
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })

    const { data, error, loading } = useQuery(GET_PACKING_LIST_BY_PALLET_ID, {
        variables: {
            packingListId: +packingListId,
            limit: pagePagination.limit,
            offset: pagePagination.offset,
            searchQuery: pagePagination.searchQuery
        }
    })

    useEffect(() => {
        if (packingListId && !loading) {
            if (error)
                return toastSweetAlert({
                    mode: "error", message: error.message
                })

            const list = data?.getPackingListByPalletId?.rows.map(item => {
                return {
                    pallet: `pallet ${item?.id}`,
                    id: item?.id,
                }
            })


            setDataToTable(list)
            setTotal(data?.getPackingListByPalletId?.count)

        }



    }, [data, error, loading, packingListId]);



    const ExpandedComponent = ({ data }) => {
        return <BoxesByPallet palletId={data.id} />

    }
    return (

        <>
            <LayoutTable
                data={dataToTable}
                loadingData={loading}
                totalCount={total}
                pagePagination={pagePagination}
                setPagePagination={setPagePagination}
                // paginationServer={false}
                exportImportButton={false}
                hideId
                hideAddNew
                withCard={false}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                alertColor="#42d54d"
                hideActions
            // searchByProperty="sku"


            />
        </>

    )

}

export default PalletByPackingList;