import React, { useEffect, useState } from 'react'
import InputController from '../../Global/InputController'
import Box from '../../Global/Box'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@apollo/client'
import {
  CREATE_TRANSFER_LINE,
  UPDATE_TRANSFER_LINE,
  GET_TRANSFER_LINES_BY_TRANSFER_REQUEST,
  GET_TRANSFER_REQUEST_BY_EDITION_ID,
} from '../../../graphql/Catalog/Transfers/Transfers'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useTransferContext } from './AddTransferRequestComponents/TransferContext'

export const TransferQuantitiesForm = ({
  dataItemQuantity,
  handleQuantity,
  _id,
  sourceWarehouseSap,
  refModal,
  dataRequest,
  refetch,
}) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const { resetTransferQuantityForm, setResetTransferQuantityForm } = useTransferContext()

  const [createTransferLine] = useMutation(CREATE_TRANSFER_LINE, {
    refetchQueries: [
      GET_TRANSFER_LINES_BY_TRANSFER_REQUEST,
      {
        variables: {
          getTransferLinesByTransferRequestId: parseInt(_id),
          warehouseCode: sourceWarehouseSap,
        },
      },
    ],
  })

  const [updateTransferLine] = useMutation(UPDATE_TRANSFER_LINE, {
    refetchQueries: [
      GET_TRANSFER_LINES_BY_TRANSFER_REQUEST,
      {
        variables: {
          getTransferLinesByTransferRequestId: parseInt(_id),
          warehouseCode: sourceWarehouseSap,
        },
      },
    ],
  })

  const validationSchema = Yup.object().shape({
    quantityBoxStart: Yup.string().test(
      'box-validation',
      'Debes ingresar una caja o una botella',
      (value, context) => {
        return context.parent.quantityStart > 0 || value > 0
      }
    ),
    quantityStart: Yup.string().test(
      'box-validation',
      'Debes ingresar una caja o una botella',
      (value, context) => {
        return context.parent.quantityBoxStart > 0 || value > 0
      }
    ),
  })


  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) })


  const resetForm = () => {
    setResetTransferQuantityForm(false)
    document.getElementById('totalShow' + dataItemQuantity?.id).value = ""
    reset()
  }

  useEffect(() => {
    if (resetTransferQuantityForm) {
      resetForm()
    }

  }, [resetTransferQuantityForm]);


  const onSubmit = async (data) => {
    try {
      setBtnLoading(true)
      let quantity = parseInt(
        document.getElementById('totalShow' + dataItemQuantity?.id).value
      )
      dataItemQuantity.quantity || dataItemQuantity.quantity == 0
        ? await updateTransferLine({
          variables: {
            updateTransferLineRequestId: parseInt(_id),
            warehouseCode: sourceWarehouseSap,
            product: {
              item_code: dataItemQuantity?.SKU,
              quantity,
            },
            dataRequest,
          },
        })
        : await createTransferLine({
          variables: {
            createTransferLineRequestId: parseInt(_id),
            warehouseCode: sourceWarehouseSap,
            product: {
              item_code: dataItemQuantity?.SKU,
              quantity,
            },
            dataRequest,
          },
        })
      await refetch()
      return (
        refModal.current.click(),
        setBtnLoading(false),
        toastSweetAlert({
          mode: 'ok',
          message:
            dataItemQuantity.quantity || dataItemQuantity.quantity == 0
              ? 'Producto actualizado correctamente'
              : 'Producto añadido correctamente',
        })
        // handleAdd(dataItemQuantity?.id)
      )
    } catch (error) {
      //   setLoadingBtn(false)
      setBtnLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }



  useEffect(() => {
    // console.log(dataItemQuantity)
    document.getElementById('totalShow' + dataItemQuantity?.id).value =
      dataItemQuantity.quantity ?? ''
    reset({
      quantityBoxStart: dataItemQuantity.quantityBoxes ?? '',
      quantityStart: dataItemQuantity.quantityBottles ?? '',
    })
  }, [dataItemQuantity])

  return (
    <>
      <FormProvider {...methods}>
        <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
          <Box
            btnSecondFunction={resetForm}
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={true}
            btnSubmitText={
              dataItemQuantity.quantity || dataItemQuantity.quantity == 0
                ? 'Actualizar'
                : 'Guardar'
            }
            errors={errors}
            // refModal={refModal}
            btnSubmit={true}
            btnState={btnLoading}
            withCard={false}
            content={
              <div className="row">
                <div className="mb-3 col-md-12">
                  <InputController
                    label="SKU"
                    inputType="text"
                    valueManual={dataItemQuantity.SKU}
                    control={control}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    label="Nombre"
                    inputType="text"
                    valueManual={dataItemQuantity?.Nombre}
                    control={control}
                    disabled
                  />
                </div>
                {/* <div className="mb-3 col-md-12">
                  <input
                    placeholder="prueba"
                    onChange={(e) => {
                      console.log('input solo data', dataItemQuantity)
                    }}
                  />
                </div> */}
                <div className="mb-3 col-md-12">
                  <InputController
                    // id={`quantityBoxStart`}
                    id={`quantityBox${dataItemQuantity?.id}`}
                    name="quantityBoxStart"
                    label="Cajas"
                    inputType="text"
                    control={control}
                    disabled={dataItemQuantity?.CajasHide == 0}
                    dataToPersist={dataItemQuantity}
                    // defaultValue={dataItemQuantity.quantityBoxes}
                    placeholder={`Disponible ${dataItemQuantity?.CajasHide > 0
                      ? dataItemQuantity?.CajasHide
                      : 0
                      } ${dataItemQuantity?.CajasHide < 2 ? 'caja' : 'cajas'}`}
                    changeAction={(e, persistData) => {
                      const stock = persistData?.stock_omitField || persistData?.Stock
                      handleQuantity(
                        persistData?.id,
                        persistData?.CajasHide,
                        e.target.value,
                        'quantityBox',
                        persistData?.UnidadHide,
                        // persistData?.Stock
                        stock
                      )
                    }}
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <InputController
                    // id={`quantityStart`}
                    id={`quantity${dataItemQuantity?.id}`}
                    name="quantityStart"
                    label="Botellas"
                    inputType="text"
                    control={control}
                    dataToPersist={dataItemQuantity}
                    // defaultValue={dataItemQuantity.quantityBottles}
                    placeholder={`Disponible ${dataItemQuantity?.UnidadHide > 0
                      ? dataItemQuantity?.UnidadHide - 1
                        ? dataItemQuantity?.UnidadHide - 1
                        : 0
                      : 0
                      } ${
                      // (field.Stock - (field.CajasHide * field.UnidadHide)) < 2 ? 'pza' : 'pzas'
                      (dataItemQuantity?.UnidadHide - 1
                        ? dataItemQuantity?.UnidadHide - 1
                        : 0) < 2
                        ? 'pza'
                        : 'pzas'
                      }`}


                    changeAction={(e, persistData) => {
                      const stock = persistData?.stock_omitField || persistData?.Stock

                      handleQuantity(
                        persistData?.id,
                        persistData?.Stock,
                        e.target.value,
                        'quantity',
                        persistData?.UnidadHide,
                        // persistData?.Stock
                        stock
                      )
                    }
                    }
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <label>Total</label>
                  <input
                    id={'totalShow' + dataItemQuantity?.id}
                    className="form-control"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default TransferQuantitiesForm
