import { gql } from '@apollo/client'

export const GET_FLOW_FOR_TRANSFERS = gql`
query GetFlowForTransfers($limit: Int, $offset: Int, $searchQuery: String) {
  getFlowForTransfers(limit: $limit, offset: $offset, searchQuery: $searchQuery) {
    count
    rows {
      id
      store_id
      name
      from_whs_code
      to_whs_code
      whs_transfer
      branch_code
      origin_destination_code
      StoreSapWhsTransfers {
        id
        name
        alias
      }
      OriginWhsSapWhsTransfers {
        id
        warehouse_code
        warehouse_name
      }
      DestinationWhsSapWhsTransfers {
        id
        warehouse_code
        warehouse_name
      }
      TransferWhsSapWhsTransfers {
        id
        warehouse_code
        warehouse_name
      }
    }
  }
}
`
export const GET_ONE_FLOW_TRANSFER = gql`
query GetOneFlowForTransfers($getOneFlowForTransfersId: Int) {
    getOneFlowForTransfers(id: $getOneFlowForTransfersId) {
      id
      store_id
      name
      from_whs_code
      to_whs_code
      whs_transfer
      branch_code
      origin_destination_code
      StoreSapWhsTransfers {
        id
        name
        alias    
      }
      OriginWhsSapWhsTransfers {
        id
        warehouse_code
        warehouse_name
      }
      DestinationWhsSapWhsTransfers {
        id
        warehouse_code
        warehouse_name
      }
      TransferWhsSapWhsTransfers {
        id
        warehouse_code
        warehouse_name
      }
    }
  }
`

export const CREATE_TRANSFER_FLOW = gql`
mutation CreateFlowForTransfer($flowForTransfer: InputFlowForTransfer!) {
  createFlowForTransfer(flowForTransfer: $flowForTransfer)
}
`

export const UPDATE_TRANSFER_FLOW = gql`
mutation UpdateFlowForTransfer($flowForTransfer: InputPutFlowForTransfer!) {
  updateFlowForTransfer(flowForTransfer: $flowForTransfer)
}
`

export const DELETE_TRANSFER_FLOW = gql`
mutation DeleteFlowForTransfer($deleteFlowForTransferId: Int!) {
  deleteFlowForTransfer(id: $deleteFlowForTransferId)
}
`