import React from 'react'
import Bebida from '../../Assets/Images/bebida.jpg'

function OrderProductCard({ sku, name, price, quantity, total, id_product, image }) {
  var currencyFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  })
  return (
    <>
    <td className='align-middle'>
      <img
        src={image ? image : Bebida}
        style={{ maxWidth: '100%', maxHeight: '100px' }}
        alt="ordersImages"
      />  
    </td>
    <td className='align-middle'><p className="product-detail-text text-left">{sku}</p></td>
    <td className='align-middle'><p className="product-detail-text text-left">{name}</p></td>
    <td className='align-middle'><p className="product-detail-text text-left">{currencyFormat.format(price)}</p></td>
    <td className='align-middle'><p className="product-detail-text text-left">{quantity}</p></td>
    <td className='align-middle'><p className="product-detail-text text-right">{currencyFormat.format(total)}</p></td>
    </>
  )
}

export default OrderProductCard
