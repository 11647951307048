import React from 'react'

export const GrowthIndicator = ({
    actualData = [],
    oldData = []
}) => {

    let growing = ((actualData.reduce((a, b) => a + b, 0) - oldData.reduce((a, b) => a + b, 0)) / oldData.reduce((a, b) => a + b, 0) * 100).toFixed(2)
    growing = isNaN(growing) ? 0 : growing

    if(growing > 0){
        return(
            <div className='row justify-content-end text-success lead'>
                <i className='fa fa-arrow-up fa-sm'>
                    {" "+ growing + "%"}
                </i>
            </div>
        )

    }else if(growing < 0){
        return(
            <div className='row justify-content-end text-danger lead'>
                <i className='fa fa-arrow-down fa-sm'>
                    {" "+ growing + "%"}
                </i>
            </div>
        )
    }else{
        return(
            <div className='row justify-content-end text-secondary lead'>
                {" "+ growing + "%"}
            </div>
        )
    }
  }


export default GrowthIndicator