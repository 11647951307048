import { PrivateRoute } from '../Routers/PrivateRoute'
import React from 'react'

//ORDERS -AGENT
import PendingOrders from '../Components/Agent/Catalogs/Orders/Pending/PendingOrders'
import PendingOrdersNew from '../Components/Agent/Catalogs/Orders/Pending/PendingOrdersNew'
import InProcessOrders from '../Components/Shared/Catalogs/Orders/Process/InProcessOrders'
import InProcessOrdersNew from '../Components/Shared/Catalogs/Orders/Process/InProcessOrdersNew'
import LocalShippingOrders from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrders'
import LocalShippingOrdersNew from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrdersNew'
import NationalShippingOrders from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrders'
import NationalShippingOrdersNew from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrdersNew'
import ShippedOrders from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrders'
import ShippedOrdersNew from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrdersNew'
import ToStockOrders from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrders'
import ToStockOrdersNew from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrdersNew'
import BillingOrders from '../Components/Shared/Catalogs/Orders/Billing/BillingOrders'
import BillingOrdersNew from '../Components/Shared/Catalogs/Orders/Billing/BillingOrdersNew'
import RejectedOrders from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrders'
import RejectedOrdersNew from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrdersNew'
import CollectOrders from '../Components/Shared/Catalogs/Orders/Collect/CollectOrders'
import CollectOrdersNew from '../Components/Shared/Catalogs/Orders/Collect/CollectOrdersNew'
import InRouteOrders from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrders'
import InRouteOrdersNew from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrdersNew'
import RackPosition from '../Components/Warehouse/Stowing/Racks/Racks'
import RackPositionNew from '../Components/Warehouse/Stowing/Racks/RacksNew'
import ProductPosition from '../Components/Warehouse/Stowing/Products/Products'
import ProductPositionNew from '../Components/Warehouse/Stowing/Products/ProductsNew'
import Refill from '../Components/Warehouse/Stowing/Products/Refill'
import Kardex from '../Components/Warehouse/Stowing/kardex/Kardex'

//WAREHOUSE
import { PendingOrders as WarehousePendingOrders } from '../Components/Agent/Catalogs/Orders/Pending/PendingOrders'
import { PendingOrdersNew as WarehousePendingOrdersNew } from '../Components/Agent/Catalogs/Orders/Pending/PendingOrdersNew'
import { InProcessOrders as WarehouseInProcessOrders } from '../Components/Shared/Catalogs/Orders/Process/InProcessOrders'
import { InProcessOrdersNew as WarehouseInProcessOrdersNew } from '../Components/Shared/Catalogs/Orders/Process/InProcessOrdersNew'
import { LocalShippingOrders as WarehouseLocalShippingOrders } from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrders'
import { LocalShippingOrdersNew as WarehouseLocalShippingOrdersNew } from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrdersNew'
import { NationalShippingOrders as WarehouseNationalShippingOrders } from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrders'
import { NationalShippingOrdersNew as WarehouseNationalShippingOrdersNew } from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrdersNew'
import { ShippedOrders as WarehouseShippedOrders } from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrders'
import { ShippedOrdersNew as WarehouseShippedOrdersNew } from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrdersNew'
import { ToStockOrders as WarehouseToStockOrders } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrders'
import { ToStockOrdersNew as WarehouseToStockOrdersNew } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrdersNew'
import { ToStockOrders as WarehousePackToStockOrders } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrders'
import { ToStockOrdersNew as WarehousePackToStockOrdersNew } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrdersNew'
import { BillingOrders as WarehouseBillingOrders } from '../Components/Shared/Catalogs/Orders/Billing/BillingOrders'
import { BillingOrdersNew as WarehouseBillingOrdersNew } from '../Components/Shared/Catalogs/Orders/Billing/BillingOrdersNew'
import { RejectedOrders as WarehouseRejectedOrders } from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrders'
import { RejectedOrdersNew as WarehouseRejectedOrdersNew } from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrdersNew'
import { CollectOrders as WarehouseCollectOrders } from '../Components/Shared/Catalogs/Orders/Collect/CollectOrders'
import { CollectOrdersNew as WarehouseCollectOrdersNew } from '../Components/Shared/Catalogs/Orders/Collect/CollectOrdersNew'
import { InRouteOrders as WarehouseInRouteOrders } from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrders'
import { InRouteOrdersNew as WarehouseInRouteOrdersNew } from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrdersNew'

//ECOMMERCE
import { PendingOrders as EcommercePendingOrders } from '../Components/Agent/Catalogs/Orders/Pending/PendingOrders'
import { PendingOrdersNew as EcommercePendingOrdersNew } from '../Components/Agent/Catalogs/Orders/Pending/PendingOrdersNew'
import { InProcessOrders as EcommerceInProcessOrders } from '../Components/Shared/Catalogs/Orders/Process/InProcessOrders'
import { InProcessOrdersNew as EcommerceInProcessOrdersNew } from '../Components/Shared/Catalogs/Orders/Process/InProcessOrdersNew'
import { LocalShippingOrders as EcommerceLocalShippingOrders } from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrders'
import { LocalShippingOrdersNew as EcommerceLocalShippingOrdersNew } from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrdersNew'
import { NationalShippingOrders as EcommerceNationalShippingOrders } from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrders'
import { NationalShippingOrdersNew as EcommerceNationalShippingOrdersNew } from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrdersNew'
import { ShippedOrders as EcommerceShippedOrders } from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrders'
import { ShippedOrdersNew as EcommerceShippedOrdersNew } from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrdersNew'
import { ToStockOrders as EcommerceToStockOrders } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrders'
import { ToStockOrdersNew as EcommerceToStockOrdersNew } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrdersNew'
import { ToStockOrders as EcommercePackToStockOrders } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrders'
import { ToStockOrdersNew as EcommercePackToStockOrdersNew } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrdersNew'
import { BillingOrders as EcommerceBillingOrders } from '../Components/Shared/Catalogs/Orders/Billing/BillingOrders'
import { BillingOrdersNew as EcommerceBillingOrdersNew } from '../Components/Shared/Catalogs/Orders/Billing/BillingOrdersNew'
import { RejectedOrders as EcommerceRejectedOrders } from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrders'
import { RejectedOrdersNew as EcommerceRejectedOrdersNew } from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrdersNew'
import { CollectOrders as EcommerceCollectOrders } from '../Components/Shared/Catalogs/Orders/Collect/CollectOrders'
import { CollectOrdersNew as EcommerceCollectOrdersNew } from '../Components/Shared/Catalogs/Orders/Collect/CollectOrdersNew'
import { InRouteOrders as EcommerceInRouteOrders } from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrders'
import { InRouteOrdersNew as EcommerceInRouteOrdersNew } from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrdersNew'

//DESK CONTROL
import { PendingOrders as DeskControlPendingOrders } from '../Components/Agent/Catalogs/Orders/Pending/PendingOrders'
import { PendingOrdersNew as DeskControlPendingOrdersNew } from '../Components/Agent/Catalogs/Orders/Pending/PendingOrdersNew'
import { InProcessOrders as DeskControlInProcessOrders } from '../Components/Shared/Catalogs/Orders/Process/InProcessOrders'
import { InProcessOrdersNew as DeskControlInProcessOrdersNew } from '../Components/Shared/Catalogs/Orders/Process/InProcessOrdersNew'
import { LocalShippingOrders as DeskControlLocalShippingOrders } from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrders'
import { LocalShippingOrdersNew as DeskControlLocalShippingOrdersNew } from '../Components/Agent/Catalogs/Orders/Local/LocalShippingOrdersNew'
import { NationalShippingOrders as DeskControlNationalShippingOrders } from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrders'
import { NationalShippingOrdersNew as DeskControlNationalShippingOrdersNew } from '../Components/Agent/Catalogs/Orders/National/NationalShippingOrdersNew'
import { ShippedOrders as DeskControlShippedOrders } from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrders'
import { ShippedOrdersNew as DeskControlShippedOrdersNew } from '../Components/Shared/Catalogs/Orders/Shipped/ShippedOrdersNew'
import { ToStockOrders as DeskControlPackToStockOrders } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrders'
import { ToStockOrdersNew as DeskControlPackToStockOrdersNew } from '../Components/Shared/Catalogs/Orders/ToStock/ToStockOrdersNew'
import { BillingOrders as DeskControlBillingOrders } from '../Components/Shared/Catalogs/Orders/Billing/BillingOrders'
import { BillingOrdersNew as DeskControlBillingOrdersNew } from '../Components/Shared/Catalogs/Orders/Billing/BillingOrdersNew'
import { RejectedOrders as DeskControlRejectedOrders } from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrders'
import { RejectedOrdersNew as DeskControlRejectedOrdersNew } from '../Components/Shared/Catalogs/Orders/Rejected/RejectedOrdersNew'
import { CollectOrders as DeskControlCollectOrders } from '../Components/Shared/Catalogs/Orders/Collect/CollectOrders'
import { CollectOrdersNew as DeskControlCollectOrdersNew } from '../Components/Shared/Catalogs/Orders/Collect/CollectOrdersNew'
import { InRouteOrders as DeskControlInRouteOrders } from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrders'
import { InRouteOrdersNew as DeskControlInRouteOrdersNew } from '../Components/Shared/Catalogs/Orders/InRoute/InRouteOrdersNew'
import NewPallet from '../Components/Warehouse/Stowing/Products/NewPallet'

import StoreOrdersPending from '../Components/StoreOrders/Pending/Pending'
import StoreOrdersPendingNew from '../Components/StoreOrders/Pending/PendingNew'
import StoreOrdersPicking from '../Components/StoreOrders/Picking/Picking'
import StoreOrdersPickingNew from '../Components/StoreOrders/Picking/PickingNew'

import StoreOrdersVerification from '../Components/StoreOrders/Verification/StoreOrdersVerification'
import StoreOrdersVerificationNew from '../Components/StoreOrders/Verification/StoreOrdersVerificationNew'

import TransfersOrders from '../Components/Store/TransfersOrders/TransfersOrders'
import TransfersOrdersNew from '../Components/Store/Shared/TransfersNew'
import TransfersStores from '../Components/Store/TransfersStores/TransfersStores'
import TransfersStoresNew from '../Components/Store/Shared/TransfersNew'
import TransfersHistory from '../Components/Store/TransferHistory/TransfersHistory'
import TransfersHistoryNew from '../Components/Store/Shared/TransfersNew'
import TransfersPicking from '../Components/Store/Picking/TransfersPicking'
import TransfersPickingNew from '../Components/Store/Shared/TransfersNew'
import TransfersValidation from '../Components/Store/Validation/TransfersValidation'
import TransfersValidationNew from '../Components/Store/Validation/TransfersValidationNew'
import TransfersSchedule from '../Components/Store/Schedule/TransfersSchedule'
import TransfersScheduleNew from '../Components/Store/Shared/TransfersNew'
import PositionOrders from '../Components/PositionOrders/PositionOrders/PositionOrders'
import PositionOrdersNew from '../Components/PositionOrders/PositionOrders/PositionOrdersNew'
import InventoryGeneral from '../Components/InventoryGeneral/InventoryGeneral'
import InventoryGeneralNew from '../Components/InventoryGeneral/InventoryGeneralNew'
import AddressStores from '../Components/Addresses/Stores/AddressStores'
import AddressStoresNew from '../Components/Addresses/Stores/AddressStoresNew'
import AddressWarehouses from '../Components/Addresses/Warehouses/AddressWarehouses'
import AddressWarehousesNew from '../Components/Addresses/Warehouses/AddressWarehousesNew'

//HangFire
import TransactionReport from '../Components/HangFire/TransactionReport'
import TransactionReportNew from '../Components/HangFire/TransactionReportNew'

import TelemarketingToStock from '../Components/Telemarketing/OrderStatus/TelemarketingToStock'
import TelemarketingStocking from '../Components/Telemarketing/OrderStatus/TelemarketingStocking'
import TelemarketingStocked from '../Components/Telemarketing/OrderStatus/TelemarketingStocked'
import TelemarketingPacked from '../Components/Telemarketing/OrderStatus/TelemarketingPacked'
import TelemarketingEmbarked from '../Components/Telemarketing/OrderStatus/TelemarketingEmbarked'
import TelemarketingOnRoute from '../Components/Telemarketing/OrderStatus/TelemarketingOnRoute'
import TelemarketingDelivered from '../Components/Telemarketing/OrderStatus/TelemarketingDelivered'
// Returns
import ReturnRequest from '../Components/Inventories/Returns/ReturnRequest'
import ReturnRequestNew from '../Components/Inventories/Returns/ReturnRequestNew'
// Tags
import PriceTags from '../Components/PriceLists/PriceTags'
import PriceWithDiscountTags from '../Components/PriceLists/PriceWithDiscountTags'
import UniquePriceTags from '../Components/PriceLists/UniquePriceTags'
//Notes
import Notes from '../Components/Admin/Catalogs/Notes/Notes'
import NoteNew from '../Components/Admin/Catalogs/Notes/NoteNew'
import ManualNote from '../Components/Admin/Catalogs/Notes/ManualNote'
import ExecutedPayment from '../Components/Admin/Catalogs/Notes/GeneratePayments/ExecutedPayment'
import ValidateBill from '../Components/Admin/Catalogs/Notes/ValidateBill'
import BusinessRetention from '../Components/Admin/Catalogs/Notes/BusinessRetention/BusinessRetention'
import BusinessRetentionNew from '../Components/Admin/Catalogs/Notes/BusinessRetention/BusinessRetentionNew'
import BusinessPartnerCounterCharges from '../Components/Admin/Catalogs/Notes/BusinessPartner/BusinessPartnerCounterCharges'
import BusinessPartnerCounterChargesNew from '../Components/Admin/Catalogs/Notes/BusinessPartner/BusinessPartnerCounterChargesNew'
import StoreHelperCounterCharges from '../Components/Admin/Catalogs/Notes/StoreHelperCounterCharges/StoreHelperCounterCharges'
import StoreHelperCounterChargesNew from '../Components/Admin/Catalogs/Notes/StoreHelperCounterCharges/StoreHelperCounterChargesNew'
import ConceptCounterCharges from '../Components/Admin/Catalogs/Notes/ConceptCounterCharges/ConceptCounterCharges'
import ConceptCounterChargesNew from '../Components/Admin/Catalogs/Notes/ConceptCounterCharges/ConceptCounterChargesNew'
import FinancesCounterCharges from '../Components/Admin/Catalogs/FinancesCounterCharges/FinancesCounterCharges'
import FinancesCounterChargesNew from '../Components/Admin/Catalogs/FinancesCounterCharges/FinancesCounterChargesNew'
import BankingTransfersFinances from '../Components/Admin/Catalogs/FinancesCounterCharges/BankingTransfers/BankingTransfersFinances'
import BanksFinancesCounterCharges from '../Components/Admin/Catalogs/FinancesCounterCharges/BankFinancesCounterCharges/BanksFinancesCounterCharges'
import BanksFinancesCounterChargesNew from '../Components/Admin/Catalogs/FinancesCounterCharges/BankFinancesCounterCharges/BanksFinancesCounterChargesNew'
import BankPaymentsContability from '../Components/Admin/Catalogs/Notes/GeneratePayments/BankPaymentsContability'
// import PaymentsHistory from '../Components/Admin/Catalogs/Notes/PaymentsHistory/PaymentsHistory'
// import PaymentsHistoryNew from '../Components/Admin/Catalogs/Notes/PaymentsHistory/PaymentsHistoryNew'
// import ImportPayments from '../Components/Admin/Catalogs/Notes/ImportPayments/ImportPayments'

//store
import Inventory from '../Components/StoreInventory/Inventory'
//Receipt registers
import ReceiptVehiclesRegisters from '../Components/ReceiptRegisters/Vehicles/ReceiptVehiclesRegisters'
import ReceiptVehiclesRegistersNew from '../Components/ReceiptRegisters/Vehicles/ReceiptVehiclesRegistersNew'
import ReceiptParcelsRegisters from '../Components/ReceiptRegisters/Parcel/ReceiptParcelsRegisters'
import ReceiptParcelsRegistersNew from '../Components/ReceiptRegisters/Parcel/ReceiptParcelsRegistersNew'

import PickingDashboard from '../Components/Reports/Picking/PickingDashboard'
import ReceiptDashboard from '../Components/Reports/Receipt/ReceiptDashboard'
import VerificationDashboard from '../Components/Reports/Verification/VerificationDashboard'
import ShipmentDashboard from '../Components/Reports/Shipment/ShipmentDashboard'
import WarehouseReports from '../Components/WarehouseReports/WarehouseReports'

// Definitions
import ReturnsConfiguration from '../Components/Definitions/ReturnsConfiguration'
import TransferConfiguration from '../Components/Definitions/TransferConfiguration'
import TransferConfigurationNew from '../Components/Definitions/TransferConfigurationNew'
import ReturnsConfigurationNew from '../Components/Definitions/ReturnsConfigurationNew'

const submoduleRoutesGenerator = (
  read,
  create,
  edit,
  imports,
  module,
  isAuth
) => {
  const components = {
    PendingOrders,
    PendingOrdersNew,
    InProcessOrders,
    InProcessOrdersNew,
    LocalShippingOrders,
    LocalShippingOrdersNew,
    NationalShippingOrders,
    NationalShippingOrdersNew,
    ShippedOrders,
    ShippedOrdersNew,
    ToStockOrders,
    ToStockOrdersNew,
    BillingOrders,
    BillingOrdersNew,
    RejectedOrders,
    RejectedOrdersNew,
    CollectOrders,
    CollectOrdersNew,
    InRouteOrders,
    InRouteOrdersNew,

    WarehousePendingOrders,
    WarehousePendingOrdersNew,
    WarehouseInProcessOrders,
    WarehouseInProcessOrdersNew,
    WarehouseLocalShippingOrders,
    WarehouseLocalShippingOrdersNew,
    WarehouseNationalShippingOrders,
    WarehouseNationalShippingOrdersNew,
    WarehouseShippedOrders,
    WarehouseShippedOrdersNew,
    WarehouseToStockOrders,
    WarehousePackToStockOrders,
    WarehousePackToStockOrdersNew,
    WarehouseToStockOrdersNew,
    WarehouseBillingOrders,
    WarehouseBillingOrdersNew,
    WarehouseRejectedOrders,
    WarehouseRejectedOrdersNew,
    WarehouseCollectOrders,
    WarehouseCollectOrdersNew,
    WarehouseInRouteOrders,
    WarehouseInRouteOrdersNew,

    EcommercePendingOrders,
    EcommercePendingOrdersNew,
    EcommerceInProcessOrders,
    EcommerceInProcessOrdersNew,
    EcommerceLocalShippingOrders,
    EcommerceLocalShippingOrdersNew,
    EcommerceNationalShippingOrders,
    EcommerceNationalShippingOrdersNew,
    EcommerceShippedOrders,
    EcommerceShippedOrdersNew,
    EcommerceToStockOrders,
    EcommerceToStockOrdersNew,
    EcommercePackToStockOrders,
    EcommercePackToStockOrdersNew,
    EcommerceBillingOrders,
    EcommerceBillingOrdersNew,
    EcommerceRejectedOrders,
    EcommerceRejectedOrdersNew,
    EcommerceCollectOrders,
    EcommerceCollectOrdersNew,
    EcommerceInRouteOrders,
    EcommerceInRouteOrdersNew,

    DeskControlPendingOrders,
    DeskControlPendingOrdersNew,
    DeskControlInProcessOrders,
    DeskControlInProcessOrdersNew,
    DeskControlLocalShippingOrders,
    DeskControlLocalShippingOrdersNew,
    DeskControlNationalShippingOrders,
    DeskControlNationalShippingOrdersNew,
    DeskControlShippedOrders,
    DeskControlShippedOrdersNew,
    DeskControlPackToStockOrders,
    DeskControlPackToStockOrdersNew,
    DeskControlBillingOrders,
    DeskControlBillingOrdersNew,
    DeskControlRejectedOrders,
    DeskControlRejectedOrdersNew,
    DeskControlCollectOrders,
    DeskControlCollectOrdersNew,
    DeskControlInRouteOrders,
    DeskControlInRouteOrdersNew,

    RackPosition,
    RackPositionNew,
    ProductPosition,
    ProductPositionNew,
    Refill,
    NewPallet,

    TransfersOrders,
    TransfersOrdersNew,
    TransfersStores,
    TransfersStoresNew,
    TransfersHistory,
    TransfersHistoryNew,
    TransfersPicking,
    TransfersPickingNew,
    TransfersValidation,
    TransfersValidationNew,
    TransfersSchedule,
    TransfersScheduleNew,
    PositionOrders,
    PositionOrdersNew,
    InventoryGeneral,

    InventoryGeneralNew,
    AddressStores,
    AddressStoresNew,
    AddressWarehouses,
    AddressWarehousesNew,

    //HangFire
    TransactionReport,
    TransactionReportNew,

    TelemarketingToStock,
    TelemarketingStocking,
    TelemarketingStocked,
    TelemarketingPacked,
    TelemarketingEmbarked,
    TelemarketingOnRoute,
    TelemarketingDelivered,
    // Return
    ReturnRequest,
    ReturnRequestNew,
    // Tags
    PriceTags,
    PriceWithDiscountTags,
    UniquePriceTags,
    //Notes
    Notes,
    NoteNew,
    ManualNote,
    // GenerateNewPayment,
    ExecutedPayment,
    BusinessRetention,
    BusinessRetentionNew,
    BusinessPartnerCounterCharges,
    BusinessPartnerCounterChargesNew,
    StoreHelperCounterCharges,
    StoreHelperCounterChargesNew,
    ValidateBill,
    ConceptCounterCharges,
    ConceptCounterChargesNew,
    BankPaymentsContability,
    // ImportPayments,
    // PaymentsHistory,
    // PaymentsHistoryNew,

    FinancesCounterCharges,
    FinancesCounterChargesNew,
    BankingTransfersFinances,
    BanksFinancesCounterCharges,
    BanksFinancesCounterChargesNew,

    //Store
    Inventory,

    //Receipt Register
    ReceiptVehiclesRegisters,
    ReceiptVehiclesRegistersNew,
    ReceiptParcelsRegisters,
    ReceiptParcelsRegistersNew,

    Kardex,
    //Warehouses reports
    ReceiptDashboard,
    VerificationDashboard,
    ShipmentDashboard,
    PickingDashboard,
    VerificationDashboard,
    ShipmentDashboard,
    WarehouseReports,

    // Definitions
    ReturnsConfiguration,
    TransferConfiguration,
    TransferConfigurationNew,
    ReturnsConfigurationNew,
    //Store Order
    StoreOrdersPending,
    StoreOrdersPendingNew,
    StoreOrdersVerification,
    StoreOrdersVerificationNew,
    StoreOrdersPicking,
    StoreOrdersPickingNew,
  }
  let routesArray = []
  if (create) {
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module.module_info.relative_link}${module.submodule_info.relative_link}/new`}
        component={components[`${module.submodule_info.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (read) {
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module.module_info.relative_link}${module.submodule_info.relative_link}`}
        component={components[module.submodule_info.name]}
        isAuth={isAuth}
      />
    )
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module.module_info.relative_link}${module.submodule_info.relative_link}/details/:id/:show`}
        component={components[`${module.submodule_info.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (edit) {
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module.module_info.relative_link}${module.submodule_info.relative_link}/edit/:id`}
        component={components[`${module.submodule_info.name}New`]}
        isAuth={isAuth}
      />
    )
    if (module.submodule_info.name === 'ProductPosition') {
      routesArray.push(
        <PrivateRoute
          exact
          path={`${module.module_info.relative_link}${module.submodule_info.relative_link}/refill/:id`}
          component={components[`Refill`]}
          isAuth={isAuth}
        />,
        <PrivateRoute
          exact
          path={`${module.module_info.relative_link}${module.submodule_info.relative_link}/new/palletCreation`}
          component={components[`NewPallet`]}
          isAuth={isAuth}
        />
      )
    }
    if (module.submodule_info.name === 'InventoryGeneral') {
      routesArray.push(
        <PrivateRoute
          exact
          path={`${module.module_info.relative_link}${module.submodule_info.relative_link}/refill/:id`}
          component={components[`InventoryGeneralNew`]}
          isAuth={isAuth}
        />
      )
    }
    if (imports) {
      routesArray.push(
        <PrivateRoute
          exact
          key={module.id}
          path={`${module.module_info.relative_link}/import/:form`}
          component={components[`${module.module_info.name}New`]}
          isAuth={isAuth}
        />
      )
    }
  }
  return routesArray
}
export default submoduleRoutesGenerator
