import * as Yup from 'yup'
import moment from 'moment'

let dateNow = new Date();
const dateLimit = new Date();
export const validationSchemaQuote = Yup.object().shape({
    dock_ids: Yup.array().min(1).required('Selecciona por lo menos una puerta').nullable(),
    document_date: Yup.date().required('Este campo es obligatorio')
        .typeError('Este campo es obligatorio')
        .min(moment(dateNow.setDate(dateNow.getDate() - 1)).format(),'Debe hacer una cita apartir de la fecha actual')
        .max(moment(dateLimit.setDate(dateLimit.getDate() + 60)).format(),'La cita no debe ser mayor a 60 días'),
    document_time_start: Yup.string().required('Este campo es obligatorio'),
    document_time_end: Yup.string()
        .required('Este campo es obligatorio')
        .test("is-greater", "Tiempo final debe ser mayor que el inicio", function(value) {
            const { document_time_start } = this.parent;
            return moment(value, "HH:mm").isSameOrAfter(moment(document_time_start, "HH:mm"))
        }),
    comments: Yup.string()    
        .max(200, 'El comentario debe ser mayor a 4 y menor a 200 dígitos'),
    warehouse_id: Yup.number()
        .typeError('Este campo es obligatorio')
        .required('Este campo es obligatorio'),
});