import { gql } from '@apollo/client'

export const CREATE_COMMODITY_RECEIPT_EVIDENCE = gql`
  mutation CreateCommodityReceiptEvidence(
    $commodityReceiptEvidenceInput: commodityReceiptEvidenceInput!
  ) {
    createCommodityReceiptEvidence(
      commodityReceiptEvidenceInput: $commodityReceiptEvidenceInput
    )
  }
`
export const CREATE_COMMODITY_RECEIPT = gql`
  mutation CreateCommodityReceipt(
    $commodityReceiptInput: commodityReceiptInput!
  ) {
    createCommodityReceipt(commodityReceiptInput: $commodityReceiptInput)
  }
`

export const DELETE_COMMODITY_RECEIPT_LINE = gql`
  mutation DeleteCommodityReceiptLine(
    $commodityReceiptId: Int!
    $purchaseOrderLineId: Int!
  ) {
    deleteCommodityReceiptLine(
      commodityReceiptId: $commodityReceiptId
      purchaseOrderLineId: $purchaseOrderLineId
    )
  }
`

export const CLOSE_COMMODITY_RECEIPT = gql`
  mutation CloseCommodityReceipt($scheduleId: Int!) {
    closeCommodityReceipt(scheduleId: $scheduleId)
  }
`

export const CREATE_COMMODITY_RECEIPT_ISSUES = gql`
  mutation CreateCommodityReceiptIssues(
    $commodityReceiptIssuesInput: commodityReceiptIssuesInput!
  ) {
    createCommodityReceiptIssues(
      commodityReceiptIssuesInput: $commodityReceiptIssuesInput
    )
  }
`

export const GET_ISSUES_COMMODITY_RECEIPT = gql`
  query GetAllIssuesCommodityReceipt {
    getAllIssuesCommodityReceipt {
      id
      name
    }
  }
`

export const GET_ALL_COMMODITY_RECEIPT = gql`
  query GetAllCommodityReceipt(
    $searchQuery: String
    $limit: Int!
    $offset: Int!
    $status: Int!
  ) {
    getAllCommodityReceipt(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      status: $status
    ) {
      rows {
        schedule_id
        schedules {
          id
          document_date
          document_time_start
          document_time_end
          comments
          warehouse_id
          warehouse {
            id
            warehouse_name
            warehouse_code
          }
          document_status_id
          ScheduleDocumentStatus {
            id
            name
          }
        }
        sap_purchases_orders {
          business_partner {
            id
            card_code
            card_name
          }
        }
      }
      count
    }
  }
`

export const GET_EVIDENCES_COMMODITY_RECEIPT_BY_SCHEDULE_ID = gql`
  query GetEvidencesCommodityReceiptByScheduleId($scheduleId: Int) {
    getEvidencesCommodityReceiptByScheduleId(scheduleId: $scheduleId) {
      id
      beds
      items
      boxes
      receipt_quantity
      position_order_id
      sap_purchases_orders_lines_id
      evidences {
        fileInternal {
          url
          id
        }
      }
    }
  }
`
export const GET_COMMODITY_RECEIPT_EVIDENCE = gql`
  mutation GetEvidenceByCommodityId($commodityReceiptId: Int!) {
    getEvidenceByCommodityId(commodityReceiptId: $commodityReceiptId) {
      fileInternal {
        url
        id
      }
    }
  }
`

export const GET_COMMODITY_RECEIPT_BY_LINE_ID = gql`
  mutation GetCommodityReceiptByPurchesLineId($purchesLineId: Int!) {
    getCommodityReceiptByPurchesLineId(purchesLineId: $purchesLineId) {
      receipt_quantity
    }
  }
`

export const GET_REPORT_COMMODITY_RECEIPT_BY = gql`
query GetReportCommodityReceiptBy($status: [String], $init: String, $end: String, $scheduleId: Int, $warehouseCode: String, $limit: Int, $offset: Int, $searchQuery: String, $idProvider: Int) {
  getReportCommodityReceiptBy(status: $status, init: $init, end: $end, scheduleId: $scheduleId, warehouseCode: $warehouseCode, limit: $limit, offset: $offset, searchQuery: $searchQuery, idProvider: $idProvider) {
    count
    rows {
      schedule_id
      receipt_quantity
      items
      expectedQuantity {
        quantity
      }
      expiration_date
      sap_purchases_orders {
        id
        document_entry
        document_number
        document_status
        OrdersDeleveryNotesRows {
          quantity
        }
        business_partner {
          card_name
        }
        document_date
        closing_order_error
      }
      sap_purchases_orders_lines {
        quantity
        OrdersSapWarehouse {
          warehouse_name
        }
      }
      CommodityReceiptUser {
        name
      }
    }
  }
}
`

export const DOWNLOAD_RECEIVE_REPORT = gql`
  query GetReceiveReport {
    getReceiveReport {
      id
      beds
      boxes
      items
      receipt_quantity
      residue_bottles
      residue_boxes
      sap_purchases_orders {
        id
        document_entry
        document_number
        document_date
        business_partner {
          card_name
          id
        }
      }
      sap_purchases_orders_lines {
        item_code
        LineProduct {
          item_name
        }
      }
      schedules {
        comments
        document_date
        id
        document_time_start
        document_time_end
      }
      CommodityReceiptUser {
        first_name
        id
        last_name
        name
      }
    }
  }
`

export const GET_DETAIL_COMMODITY_RECEIPT = gql`
 query GetReportCommodityReceiptDetails($scheduleId: Int!, $limit: Int, $offset: Int, $onlyWithDifferences: Boolean) {
  getReportCommodityReceiptDetails(scheduleId: $scheduleId, limit: $limit, offset: $offset, onlyWithDifferences: $onlyWithDifferences) {
    rows {
      id
      item_code
      quantity
      open_quantity
      line_status
      LineProduct {
        item_name
      }
      LineCommodityReceipt {
        id
        receipt_quantity
      }
    }
    count
  }
}
`

export const GET_PURCHASE_ORDERS = gql`
query GetPurchaseOrders($sapPurchaseOrderId: Int, $limit: Int, $offset: Int, $searchQuery: String, $warehouseCode: String, $status: [String], $init: String, $end: String) {
  getPurchaseOrders(sapPurchaseOrderId: $sapPurchaseOrderId, limit: $limit, offset: $offset, searchQuery: $searchQuery, warehouseCode: $warehouseCode, status: $status, init: $init, end: $end) {
    count
    rows {
      id
      document_date
      document_entry
      document_number
      document_status
      PurchaseOrderCommodityReceipts {
        receipt_quantity
        CommodityReceiptUser {
          name
        }
      }
      OrdersDeleveryNotesRows {
        quantity
        WarehousePurchDelNotesRow {
          warehouse_name
        }
      }
      BusinessSapPurchasesPendingOrdersLines {
        quantity
      }
      business_partner {
        card_name
      }
    }
  }
}
`

export const GET_REPORT_DOC_RETURNS_DETAILS = gql`
query GetPurchaseOrderDetail($sapPurchaseOrderId: Int, $limit: Int, $offset: Int) {
  getPurchaseOrderDetail(sapPurchaseOrderId: $sapPurchaseOrderId, limit: $limit, offset: $offset) {
    count
    rows {
      id
      item_code
      item_name
      quantity
      line_status
      DeleveryNotesRowsOrder {
        BusinessSapPurchasesPendingOrdersLines {
          item_code
          quantity
          id
        }
        PurchaseOrderCommodityReceipts {
          receipt_quantity
          sap_purchases_orders_lines_id
        }
      }
    }
  }
}
`

export const GET_RECEIPT_REPORTS_PROVIDERS = gql`
query GetReceiptReportsProviders($limit: Int, $query: String) {
  getReceiptReportsProviders(limit: $limit, query: $query) {
    id
    card_name
  }
}
`