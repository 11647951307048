import React from 'react'

import OrderCol from '../../Global/OrderCol'
import Box from '../../Global/Box'
import { CameraPhoto } from '../../Global/CameraPhoto/CameraPhoto'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'

export const Step3 = ({ data, setData, previousStep, nextStep }) => {
  const handelRemoveImg = (id) => {
    const newList = data.photos.filter((item, index) => index !== id)
    setData({ ...data, photos: newList })
  }

  const handleOnCamera = (photo) => {
    setData({ ...data, photos: [...data.photos, photo] })
  }

  const handleNextStep = () => {
    // if (data.photos.length === 0) {
    //     return toastSweetAlert({
    //         mode: 'error',
    //         message: 'Por favor tome evidencia de la mercancía',
    //     })
    // }
    nextStep()
  }

  return (
    <>
      <Box
        title={'Toma de evidencias'}
        content={
          <div className="row">
            <OrderCol
              mediumColSize="12"
              content={
                <CameraPhoto
                  active={true}
                  fullScreen={false}
                  handleResult={handleOnCamera}
                />
              }
            />
            <OrderCol
              title="Galería"
              mediumColSize="12"
              content={
                <div className="gallery">
                  {data.photos.map((item, index) => (
                    <div key={index} className="col-3 gallery-item">
                      <i
                        className="fa fa-times position-absolute top-0 start-0 m-2 "
                        onClick={() => handelRemoveImg(index)}
                      />
                      <img src={item} alt="img" className="" />
                    </div>
                  ))}
                </div>
              }
            />
            <OrderCol
              mediumColSize="12"
              content={
                <div className="col-12 actions-buttons-scan pt-5">
                  <span
                    className="btn StandarModalCancelButtonLogOut"
                    onClick={previousStep}
                  >
                    {' '}
                    Regresar{' '}
                  </span>
                  <button
                    type="submit"
                    className="btn StandarModalConfirmButtonLogOut"
                    onClick={handleNextStep}
                  >
                    {' '}
                    Continuar{' '}
                  </button>
                </div>
              }
            />
          </div>
        }
      />
      <Box
        title={'Producto'}
        content={
          <div className="row">
            <OrderCol
              title="Sku"
              content={data.product.PurchasesLinesItemInfo.item_code}
              mediumColSize="3"
            />
            <OrderCol
              title="Nombre"
              content={data.product.PurchasesLinesItemInfo.item_name}
              mediumColSize="6"
            />
            <OrderCol
              title="Código de barras"
              content={data.product.PurchasesLinesItemInfo.item_code_bar}
              mediumColSize="3"
            />
            <OrderCol
              title="Cantidad esperada"
              content={data.product.quantity - data.product.open_quantity}
              mediumColSize="4"
            />
          </div>
        }
      />
    </>
  )
}
