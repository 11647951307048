import { gql } from '@apollo/client'

export const GET_ALL_GENERATED_REPORTS_BY_USER_ID = gql`
  query GetAllReportsByUserId($searchQuery: String, $limit: Int, $offset: Int) {
    getAllReportsByUserId(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        name
        user_id
        business_partner_file_types_id
        unlimited
        file_id
        part
        file_type
        is_active
        createdAt
        files {
          id
          url
          type
        }
        BusinessPartnerReportFilesToBusinessPartnerReportFileTypes {
          id
          name
        }
      }
    }
  }
`
