import moment from "moment"

/**validateCustomReport: validate form from ReportModalComponent */
export function validateCustomReport(reports = {
    reportsType: 0,
    reportsTypeRangeStart: '',
    reportsTypeRangeEnd: '',
    dataType: [],
    documentsType: [],
}){
    try {
        //Validate data before submit
        const { 
            reportsType,
            reportsTypeRangeStart,
            reportsTypeRangeEnd,
            dataType,
            documentsType,
        } = reports

        let today = moment().format('YYYY-MM-DD')

        //Validate that user select one kind of report
        if(reportsType == 0) return { error: true, msg: 'Seleccione un tipo de reporte' }

        //---- Dates validations start ----
        if(reportsType == 3 && (reportsTypeRangeStart.length == 0 || reportsTypeRangeEnd.length == 0)) 
            return { error: true, msg: 'Seleccione una fecha de inicio y final para el tipo de reporte' }

        if( reportsType == 3 && (!moment(reportsTypeRangeStart).format() || !moment(reportsTypeRangeEnd).format()) ) 
            return { error: true, msg: 'Seleccione una fecha de inicio y final correctos' }

        if( reportsType == 3 && ( moment(reportsTypeRangeStart).format() >= today )) 
            return { error: true, msg: 'Seleccione una fecha de inicio que no sea el día de hoy ni mayor' }

        if( reportsType == 3 && ( moment(reportsTypeRangeEnd).format() >= today )) 
            return { error: true, msg: 'Seleccione una fecha de final que no sea el día de hoy ni mayor' }

        //---- Dates validations end ----

        //validate if user select at least one data type
        if(dataType.length == 0) return { error: true, msg: 'Seleccione por lo menos un tipo de información' }

        //validate if user select at least one document type
        if(documentsType.length == 0) return { error: true, msg: 'Seleccione por lo menos un tipo de documento' }

        //All correct
        return { error: false, msg: '' }

    } catch (error) {
        return { error: true, msg: 'Error en el formulario' }
    }
} 