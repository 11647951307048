import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'

import {
  GET_PENDING_ORDERS,
  EXPORT_PENDING_ORDERS,
} from '../../../../../graphql/Catalog/Orders/pendingOrders'
import DatesOptions from '../../../../Global/DatesOptions'
import LayoutTable from '../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'
import { AuthContext } from '../../../../../Auth/AuthContext'

export const PendingOrders = () => {
  const { user } = useContext(AuthContext)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    platform: null,
    date: null,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_PENDING_ORDERS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      platform: pagePagination.platform,
      date: pagePagination.date,
    },
    fetchPolicy: 'no-cache',
  })
  const [dataToTable, setDataToTable] = useState([])
  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = data.getPendingOrders.rows.map((order) => {
        return {
          'No. de Venta': order.order_id,
          id: order.id,
          'Fecha y hora':
            moment(order?.order_date).format('L') +
              ', ' +
              moment(order?.order_date).format('LT') || '--',
          Plataforma: order.platform.name,
          Tipo: order.type?.name,
          'Mdo. de pago': order.payment?.platform,
          Cliente: `${order.shipping.first_name} ${order.shipping.last_name}`,
          // 'Tipo documento': order?.object_type_name
          //   ? order?.object_type_name
          //   : 'Orden de plataforma',
          Tienda: order?.store?.name || order?.warehouse?.name || '--',
        }
      })
      setTotal(data.getPendingOrders.count)
      setDataToTable(list)
    }
  }, [loading, error, data])

  const filterOnChange = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  return (
    <>
      {/* <ContentHeader
        title="Pedidos pendientes"
        breadcrumb="Pendientes"
        windowTitle="Pendientes"
      /> */}
      <LayoutTable
        data={dataToTable}
        hideId
        title="Pedidos pendientes"
        requery={GET_PENDING_ORDERS}
        editText={'Tomar Orden'}
        exportQuery={EXPORT_PENDING_ORDERS}
        platformSelector={true}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        dataToTable={dataToTable}
        dataToTableTitle="OrdenesPendientes"
        FilterBarPlaceholder="Buscar por Cliente, No. de Venta o Tienda"
        /** First filter */
        filter
        filterOnChange={filterOnChange}
        filterOptions={[<DatesOptions daysBefore={true} />]}
        filterLabelText="Por fecha"
        hideColumns={user.store ? ['Tipo'] : []}
      />
    </>
  )
}

export default PendingOrders
