import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  // GET_SAP_BUSSINESS_PARTNER,
  GET_SAP_BUSSINESS_PARTNER_SELLER_BY_ID,
  GET_SAP_BUSINESS_PARTNER_PENDING_ORDERS,
} from '../../../../graphql/Catalog/SAP/sapBusinessPartner'
import { GET_SAP_PURCHASES_ORDERS_BY_PROVIDER } from '../../../../graphql/Catalog/SAP/sapPurchasesOrders'
import LayoutTable from '../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from './../../../Global/Box'

const ProvidersQuotesStep1 = ({
  setStep,
  providerId,
  ordersReceivedStep1,
  ordersReceivedStep1Hook,
  setOrdersReceivedStep1Hook,
  setInfoProvider,
  flagEdit,
  idSelected,
  setIdSelected,
}) => {
  const errorMustSelect = 'Debes de seleccionar por lo menos una orden.'

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [pagePaginationOrder, setPagePaginationOrder] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [total, setTotal] = useState(0)
  const [totalPurchasesOrders, setTotalPurchasesOrders] = useState(0)
  const [providers, setProviders] = useState() //ALL PROVIDERS
  const [purchasesOrders, setPurchasesOrders] = useState() //ALL PROVIDERS ORDERS
  const [stepTable, setStepTable] = useState(1)
  const [loadingNext, setLoadingNext] = useState(false)

  const history = useHistory()
  const { id: _id } = useParams()

  const { loading, error, data } = useQuery(
    GET_SAP_BUSINESS_PARTNER_PENDING_ORDERS,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
    }
  )

  const [getPurchasesOrdersByProvider, { loading: loadingPurchasesOrders }] =
    useMutation(GET_SAP_PURCHASES_ORDERS_BY_PROVIDER)

  // const [getProvider] = useMutation(GET_SAP_BUSSINESS_PARTNER_SELLER_BY_ID)

  useEffect(() => {
    try {
      setLoadingNext(loading)
      if (!loading) {
        if (error) {
          return (
            toastSweetAlert({
              mode: 'error',
              message: error.message,
            }),
            history.push('/schedule/providers')
          )
        }
        // /* GET PURCHARSEs ORDERS */
        let listStatus = {
          O: 'Abierta',
          S: 'Vendedor',
        }
        // /* GET LIST OF PROVIDERS AND GET LIST OF ORDERS*/
        // let listProviders = [];
        // let listPurchasesOrders = [];
        const list = data.getSapBusinessPartnersPendingOrders.rows.map(
          (item) => {
            // console.log(item)
            return {
              id: item.business_partner_id,
              proveedor: item.business_partner.card_name,
              cardcode_omitField: item.business_partner.card_code,
              rfc_omitField: item.business_partner.lic_trad_number,
              phone1_omitField: item.business_partner.phone1,
              phone2_omitField: item.business_partner.phone2,
              email_omitField: item.business_partner.email,
            }
          }
        )

        setTotal(data.getSapBusinessPartnersPendingOrders.count)
        setProviders(list)
      }
    } catch (error) {
      return toastSweetAlert(
        toastSweetAlert({
          mode: 'error',
          message: error.message,
        }),
        history.push('/schedule/providers')
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  useEffect(() => {
    try {
      if (idSelected) {
        async function getDataOrder() {
          setLoadingNext(true)

          //GET DATA FROM BUSINESSPARTNER
          // const resProvider = await getProvider({
          //   variables: {
          //     idBusinessPartner: providerId ? providerId : idSelected,
          //   },
          // })

          //GET DATA FROM ORDERS BY PROVIDER
          const res = await getPurchasesOrdersByProvider({
            variables: {
              searchQuery: pagePaginationOrder.searchQuery,
              limit: pagePaginationOrder.limit,
              offset: pagePaginationOrder.offset,
              idBusinessPartner: idSelected,
            },
          })
          let list = res.data.getSapPurchasesOrdersByProvider.rows.map(
            (item) => {
              return {
                id: item.id,
                Orden: item.document_number,
                Comentarios: item.comments,
                fecha: item.document_date,
              }
            }
          )

          setTotalPurchasesOrders(
            res.data.getSapPurchasesOrdersByProvider.count
          )
          setPurchasesOrders(list)
          // setInfoProvider(resProvider.data.getSapBusinessPartnerSellerById)
          setStepTable(2)
          setLoadingNext(false)
        }

        getDataOrder()
        setOrdersReceivedStep1Hook([])
      }
    } catch (error) {
      setLoadingNext(false)
      return toastSweetAlert(
        toastSweetAlert({
          mode: 'error',
          message: error.message,
        }),
        history.push('/schedule/providers')
      )
    }
  }, [idSelected, pagePaginationOrder])

  const handleSelectProvider = async (id) => {
    setLoadingNext(true)
    const findProvider = providers.find((provider) => provider.id === id)
    setInfoProvider({
      id: findProvider.id,
      phone1: findProvider.phone1_omitField,
      phone2: findProvider.phone2_omitField,
      lic_trad_number: findProvider.rfc_omitField,
      card_name: findProvider.proveedor,
      card_code: findProvider.cardcode_omitField,
      email: findProvider.email_omitField,
    })
    setIdSelected(id)
    setLoadingNext(false)
  }

  useEffect(() => {
    if (_id && !flagEdit) setStep(2)
  }, [])

  const handleSelectPurchasesOrders = (id) => {
    setLoadingNext(true)
    //Push id of each purchases Orders Lines in array ordersReceivedStep1
    var index = ordersReceivedStep1Hook.indexOf(id)
    if (index > -1) ordersReceivedStep1Hook.splice(index, 1)
    else ordersReceivedStep1Hook.push(id)

    setOrdersReceivedStep1Hook(ordersReceivedStep1Hook)
    setLoadingNext(false)
  }

  // const handlePagination = async () => {
  //   try {
  //     await getPurchasesOrdersByProvider({
  //       variables: {
  //         offset: parseInt(localStorage.getItem('offset')),
  //         limit: parseInt(localStorage.getItem('limit')),
  //       },
  //     })
  //   } catch (e) {
  //     return toastSweetAlert({
  //       mode: 'error',
  //       message: e.message,
  //     })
  //   }
  // }

  const handleSave = () => {
    if (ordersReceivedStep1Hook.length === 0)
      return toastSweetAlert({
        mode: 'error',
        message: errorMustSelect,
      })

    setStep(2)
  }

  const handleReset = () => {
    setTotalPurchasesOrders(null)
    setPurchasesOrders(null)
    setStepTable(1)
    setIdSelected(0)
  }

  return (
    <>
      {stepTable === 1 ? (
        <>
          {/* TABLE TO SHOW PROVIDERS */}
          {/* <ContentHeader
            title="Crear cita"
            breadcrumb="CREAR CITAS PROVEEDORES"
            windowTitle="Crear una cita"
          /> */}
          <LayoutTable
            hideId
            data={providers}
            title="Elige un proveedor"
            handleButton={handleSelectProvider}
            handleButtonTitle="Seleccionar"
            totalCount={total}
            loadingData={loadingNext}
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}
            // hideFilterBar
            FilterBarPlaceholder="Buscar por nombre del proveedor."
            titleActions=""
          />
        </>
      ) : (
        <>
          {/* TABLE TO SHOW PURCHARSES ORDERS */}
          {purchasesOrders && (
            <>
              {/* <ContentHeader
                title="Crear cita"
                breadcrumb="CREAR CITAS DE PROVEEDORES"
                windowTitle="Crear una cita"
              /> */}
              <Box
                title="Órdenes encontradas"
                // withHeader={false}
                btnFunction={purchasesOrders.length > 0 && handleSave}
                btnSecondFunction={handleReset}
                btnFunctionTitle="Agendar"
                btnState={loading}
                content={
                  <LayoutTable
                    hideId
                    data={purchasesOrders}
                    handleButton={handleSelectPurchasesOrders}
                    handleButtonType="checkbox"
                    handleButtonTitle={'Elegir'}
                    totalCount={totalPurchasesOrders}
                    loadingData={loadingPurchasesOrders}
                    // mutationAction={handlePagination}
                    // hideFilterBar
                    pagePagination={pagePaginationOrder}
                    setPagePagination={setPagePaginationOrder}
                    FilterBarPlaceholder="Buscar por Orden."
                    titleActions="Seleccionar"
                    // withCard={false}
                  />
                }
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default ProvidersQuotesStep1
