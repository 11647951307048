import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_PACKING_LIST_BOXES_BY_PALLET_ID } from '../../../../graphql/Catalog/Transfers/Transfers'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import LayoutTable from '../../../Global/LayoutTable'

const BoxesByPallet = ({ palletId }) => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [dataBoxesBottles, setDataBoxesBottles] = useState(false)
  const [dataDetails, setDataDetails] = useState(false)
  const { data, error, loading } = useQuery(
    GET_PACKING_LIST_BOXES_BY_PALLET_ID,
    {
      variables: {
        palletId: +palletId,
        // limit: pagePagination.limit,
        // offset: pagePagination.offset,
        // searchQuery: pagePagination.searchQuery
      },
    }
  )
  useEffect(() => {
    if (palletId && !loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let boxesLines = []
      let list = []
      const newPackingListBoxes = data?.getPackingListBoxesByPalletId?.rows
      const dataTreated = dataTreatment(newPackingListBoxes)
      // console.log(dataTreated, "DATA TRATADA")
      newPackingListBoxes?.map((item) => {
        item?.PackingListBoxesLines.map((line) => {
          boxesLines.push(line)
          const foundItem = list.find(
            (product) => product?.sku == line?.item_code
          )

          const isValidated = verifyIfValidate(
            line?.item_code,
            dataTreated.newPackingListBoxes
          )

          const complement =
            dataTreated?.newPackingListBoxesLines.boxes?.filter((boxLine) => {
              return boxLine.item_code === line?.item_code
            })

          const filterTotalBoxes =
            dataTreated?.newPackingListBoxesLines.boxes?.filter((boxLine) => {
              return boxLine?.item_code === line?.item_code
            })

          const totalBoxes =
            dataTreated.newPackingListBoxesLines?.boxes?.length > 0
              ? complement[0]?.quantity / filterTotalBoxes[0]?.bottlesByBox
              : // dataTreated.newPackingListBoxesLines?.boxes[0]?.bottlesByBox
                0

          const totalBottles =
            dataTreated?.newPackingListBoxesLines.bottles?.filter((boxLine) => {
              return boxLine?.item_code === line?.item_code
            })

          const grandTotal =
            dataTreated.newPackingListBoxesLines.boxes.length > 0
              ? complement[0]?.quantity
                ? complement[0]?.quantity
                : 0
              : 0

          if (!foundItem) {
            list.push({
              id: line?.id,
              sku: line?.item_code,
              nombre: line.SapItem?.item_name,
              // Solicitado: line?.quantity,
              // Aprobado:
              //     status === 4 || status === 5
              //         ? line?.quantity - line?.open_quantity
              //         : 'Pendiente',
              // Faltante:
              //     status === 4 || status === 5
              //         ? line?.open_quantity
              //         : 'Pendiente',
              Cajas: `${
                totalBoxes
                  ? totalBoxes +
                    ' X ' +
                    filterTotalBoxes[0]?.bottlesByBox +
                    'U c/u'
                  : 'N/A'
              }`,
              Botellas:
                totalBottles?.length > 0 ? totalBottles[0]?.quantity : 'N/A',
              Total:
                grandTotal +
                (totalBottles?.length > 0 ? totalBottles[0].quantity : 0),
              conditional_status: isValidated ? 0 : 1,
              bottlesByBox_omitField: filterTotalBoxes[0]?.bottlesByBox,
            })
          }
        })
      })
      // console.log(boxesLines, "boxes lines")
      // const filterList = list.filter(item => item.sku == item_code)

      setDataToTable(list)
      setTotal(data?.getPackingListBoxesByPalletId?.count)
    }
  }, [data, error, loading, palletId])

  const dataTreatment = (data) => {
    const packingList = data
    let newPackingListBoxes = []
    let newPackingListBoxesLines = []

    //MAKE GROUPS OF DATA BY SKU, SEPARATE BY BOXES, BOTTLES AND INFO
    packingList?.map((boxesLines) => {
      // console.log(boxesLines, 'BOX LINES ')
      if (!boxesLines.is_virtual) {
        newPackingListBoxes.push({
          is_virtual: boxesLines?.is_virtual,
          packing_list_id: boxesLines?.packing_list_id,
          security_checked: boxesLines?.security_checked,
          validated: boxesLines?.validated,
          box_id: boxesLines?.PackingListBoxesLines[0]?.box_id,
          id: boxesLines?.PackingListBoxesLines[0]?.id,
          item_code: boxesLines?.PackingListBoxesLines[0]?.item_code,
          quantity: boxesLines?.PackingListBoxesLines[0]?.quantity,
          is_active: boxesLines?.is_active,
        })
      } else {
        newPackingListBoxesLines.bottles = boxesLines.PackingListBoxesLines
        newPackingListBoxesLines.packing_list_id = boxesLines.packing_list_id
      }
      return null
    })

    // REMOVE AND SUM QUANTITIES FROM DUPLICATES
    const boxes = Object.values(
      newPackingListBoxes.reduce((acc, item) => {
        acc[item.item_code] = acc[item.item_code]
          ? {
              ...item,
              quantity: item.quantity + acc[item.item_code].quantity,
              bottlesByBox: item.quantity,
            }
          : { ...item, bottlesByBox: item.quantity }
        return acc
      }, {})
    )

    newPackingListBoxes.bottles = newPackingListBoxesLines.bottles
    newPackingListBoxesLines.boxes = boxes
    newPackingListBoxesLines.boxesDetails = newPackingListBoxes
    setDataBoxesBottles(newPackingListBoxesLines)
    setDataDetails(newPackingListBoxes)

    return { newPackingListBoxesLines, newPackingListBoxes }
  }

  const verifyIfValidate = (sku, data) => {
    const products = groupBySku(sku, data)

    const isValidated = products.some((product) => {
      return product.validated === false
    })
    return isValidated
  }

  const groupBySku = (sku, data = false) => {
    let skuLines = []
    let skuLinesBottles = []

    if (data) {
      skuLines = data?.filter((line) => {
        return line.item_code === sku
      })
    } else {
      skuLines = dataDetails?.filter((line) => {
        return line.item_code === sku
      })
    }

    if (dataDetails.bottles) {
      skuLinesBottles = dataDetails.bottles.filter((line) => {
        return line.item_code === sku
      })
      skuLines.push(...skuLinesBottles)
    }

    if (data.bottles) {
      skuLinesBottles = data.bottles.filter((line) => {
        return line.item_code === sku
      })
      skuLines.push(...skuLinesBottles)
    }

    return skuLines
  }

  const ExpandedComponent = ({ data }) => {
    let skuLines = []
    let skuLinesBottles = []

    // console.log(dataBoxesBottles, 'DETALLES')
    dataBoxesBottles?.boxesDetails?.forEach((line) => {
      if (line.item_code === data.sku) {
        skuLines.push({
          ...line,
          // is_virtual: true,
        })
      }
    })
    dataBoxesBottles?.bottles?.forEach((line) => {
      if (line.item_code === data.sku) {
        skuLinesBottles.push({
          ...line,
          is_virtual: true,
        })
      }
    })
    // console.log(skuLines, "SKU LINES")
    // console.log(skuLinesBottles, "SKU BOTTLES LINES")
    let boxesDetails = []
    if (skuLines.length > 0 && skuLinesBottles.length > 0) {
      skuLines.push(...skuLinesBottles)
      boxesDetails = skuLines
    } else if (skuLines.length > 0) {
      boxesDetails = skuLines
    } else {
      boxesDetails = skuLinesBottles
    }

    // const boxesDetails = skuLines.length > 0 ? skuLines : skuLinesBottles
    const list = boxesDetails?.map((line, index) => {
      // console.log(line, 'LINEA')
      return {
        id: line?.id,
        packingListID_omitField: line?.packing_list_id,
        box_id: line?.box_id,
        cantidad: line?.quantity,
        Tipo: line?.is_virtual ? 'Botellas' : 'Caja',
        Estado: !line.is_active ? (
          <span className="badge-danger badge-conf">
            &nbsp;&nbsp;Partida eliminada&nbsp;&nbsp;
          </span>
        ) : line.validated ? (
          <span className="badge-success badge-conf">
            &nbsp;&nbsp;Validado&nbsp;&nbsp;
          </span>
        ) : (
          <span className="badge-danger badge-conf">
            &nbsp;&nbsp;Sin validar&nbsp;&nbsp;
          </span>
        ),
      }
    })
    return (
      <LayoutTable
        hideId
        hidePagination
        title="Detalles"
        // title={`${
        //   list.length < 2
        //     ? 'Linea individual'
        //     : `Linea dividida en ${list.length} partes`
        // }`}
        data={list}
        paginationServer={false}
        hideActions={true}
        handleButtonRowCondition
        hideFilterBar
        hideAddNew
        searchByProperty="box_id"
        exportImportButton={false}
      />
    )
  }

  return (
    <>
      <LayoutTable
        data={dataToTable}
        loadingData={loading}
        // totalCount={total}
        // pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        paginationServer={false}
        exportImportButton={false}
        hideAddNew
        withCard={false}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        alertColor="#fff"
        alertSecondaryColor="#fff"
        alertTextColor="#559615"
        alertSecondaryTextColor="#AF2525"
        alertThirdTextColor="#bab8b8"
        textFontWeight="bold"
        hideActions
        searchByProperty="sku"
      />
    </>
  )
}

export default BoxesByPallet
