import React, { useEffect, useState } from 'react'

const BarProgress = ({ title, total, num }) => {
    const h = 15
    const styles = {
        bgBar: {
            background: '#FFEFD5',
            height: h,
        },
    }
    let percentage = 0
    if (total > 0) {
        percentage = parseInt((num * 100) / total)
    }

    return (
        <div className="w-100 mb-2">
            <div className="row d-flex align-items-center">
                <div className="col-8">
                    <div className="w-100" style={styles.bgBar}>
                        <div
                            style={{
                                height: h,
                                background: '#FFD186',
                                width: percentage + '%',
                            }}
                        ></div>
                    </div>
                </div>
                <div className="col-4 pb-1">
                    <span className="badge bg-danger rounded-0 p-1 percentage">
                        <span style={{ color: '#fff' }}>{percentage}%</span>
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">

                    {title && title !== '' && <p className="mb-0 pb-0 text-center">
                        <small>
                            <strong>{title}</strong>
                        </small>
                    </p>}
                    
                </div>
            </div>
        </div>
    )
}

export default BarProgress