import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { PROVIDERS_BUSINESS_PARTNER_BY_USER_ID } from '../../../graphql/Catalog/SAP/ProvidersBusinessPartner'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import StoreWarehouseAssignationDetails from './storeWarehouseAssignationDetails'

const BusinessPartnerDetails = ({ show, _id }) => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const {
    data: businessPartnerByIdData,
    error: businessPartnerByIdError,
    loading: businessPartnerByIdLoading,
  } = useQuery(PROVIDERS_BUSINESS_PARTNER_BY_USER_ID, {
    variables: {
      id: +_id,
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    try {
      if (_id && !businessPartnerByIdLoading) {
        if (businessPartnerByIdError)
          return toastSweetAlert({
            mode: 'error',
            message: businessPartnerByIdError.message,
          })
        // console.log(businessPartnerByIdData.getAllBusinessPartnerByUserId.rows)
        const list =
          businessPartnerByIdData.getAllBusinessPartnerByUserId.rows.map(
            (item) => {
              return {
                id: item.id,
                nombre:
                  item.SapBusinessPartnerToCatalogUserBusinessPartner.card_name,
              }
            }
          )

        setDataToTable(list)
        setTotal(businessPartnerByIdData.getAllBusinessPartnerByUserId.count)
        return
      }
    } catch (error) {
      return toastSweetAlert({
        message: error.message,
        mode: 'error',
      })
    }
  }, [
    businessPartnerByIdData,
    businessPartnerByIdError,
    businessPartnerByIdLoading,
    _id,
  ])

  return (
    <>
      <Box
        title="Socios asignados"
        whiteHeader={false}
        btnRedTxt="Cancelar"
        btnRedPath="/businesspartner"
        content={
          <>
            <LayoutTable
              withCard={false}
              data={dataToTable}
              hideActions={show ? true : false}
              setPagePagination={setPagePagination}
              pagePagination={pagePagination}
              totalCount={total}
              loadingData={businessPartnerByIdLoading}
            />
            <StoreWarehouseAssignationDetails show={show} _id={_id} />
          </>
        }
      />
    </>
  )
}

export default BusinessPartnerDetails
