export const estatusArr = [
  {
    value: -1,
    label: 'Todos los estatus',
    isActive: true,
  },
  /* {
        value: 'A',
        label: 'Agregado',
        isActive: false,
    },
    {
        value: 'U',
        label: 'Actualizado',
        isActive: false,
    },
    {
        value: 'D',
        label: 'Borrado',
        isActive: false,
    }, */
  {
    value: 'O',
    label: 'Abierta',
    isActive: true,
  },
  {
    value: 'C',
    label: 'Cerrada',
    isActive: true,
  },
  {
    value: 'L',
    label: 'Cerrada',
    isActive: false,
  },
  /* {
        value: 'L',
        label: 'Cerrada',
        isActive: true,
    }, */
]

export const getEstatus = (status) => {
  let estatus = null

  switch (status) {
    /* case 'A':
            estatus = 'Agregado'
            break
        
        case 'U':
            estatus = 'Actualizado'
            break
        
        case 'D':
            estatus = 'Borrada'
            break
        
        case 'S':
            estatus = 'En cita'
            break;
        
        case '2':
            estatus = 'Recibió cita'
            break */

    case 'O':
      estatus = 'Abierta'
      break

    case 'C':
      estatus = 'Cerrada'
      break

    /* case 'L':
            estatus = 'Cerrada'
            break */

    default:
      estatus = status
      break
  }
  return estatus
}

export const getActiveStatus = () => {
  let actives = []

  for (const item of estatusArr) {
    if (item.isActive) {
      actives.push(item)
    }
  }
  return actives
}

export const canCloseOrder = (status) => {
  let resp = false
  if (status === 'O' && status !== 'C') {
    resp = true
  }
  return resp
}
