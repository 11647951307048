import LayoutTable from '../../../Global/LayoutTable'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import { useState, useEffect, useContext, useRef } from 'react'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  DELETE_NOTES,
  EXPORT_ALL_NOTES,
  GET_NOTES,
  GET_ONE_NOTE,
  IMPORT_PAYMENTS,
} from '../../../../graphql/Catalog/Notes/notes'
import ShowTableBills from './ShowTableBills/ShowTableBills'
import { AuthContext } from '../../../../Auth/AuthContext'
import { generateTicketCounterCharges } from '../../../Helpers/pdfGenerator'
import { GET_BILLS } from '../../../../graphql/Catalog/Notes/bills'
import { GET_STORES } from '../../../../graphql/Catalog/Stores/stores'
import ExportExcel from '../../../Helpers/ExportExcel'
import {
  GET_ALL_INFO_FOR_EXPORT_TO_EXCEL,
  GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE,
} from '../../../../graphql/Catalog/Notes/payments'
import PrintCheckCoverages from './GeneratePayments/PrintCheckCoverages/PrintCheckCoverages'
import ReactToPrint from 'react-to-print'
import PrintFirstTicket from './PrintTicketsNotes/PrintFirstTicket'
import { useHistory } from 'react-router-dom'
import { GET_ROLES } from '../../../../graphql/Catalog/Roles/roles'
import moment from 'moment'
import ShowBillsToggle from './ShowTableBills/ShowBillsToggle'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'
import { GET_ALL_RETENTION_PARTNERS } from '../../../../graphql/Catalog/Notes/BusinessRetention/businessretention'
import ModalContent from '../../../Global/ModalContent'
import VerifyCashedChecks from './VerifyCashedChecks/VerifyCashedChecks'
import PrintSecondTicket from './PrintTicketsNotes/PrintSecondTicket'
import { GENERATE_REPORT_COUNTERCHARGES } from '../../../../graphql/Catalog/Reports/counterchargesreport'
// import Select from 'react-select/dist/declarations/src/Select'

export const Payments = () => {
  const { user } = useContext(AuthContext)
  const componentRef = useRef()
  const componentRefCoverage = useRef()
  const refModalVerifyCashedChecks = useRef()
  const componentRefSecondTicket = useRef()
  const history = useHistory()
  const [total, setTotal] = useState(0)
  const [step, setStep] = useState(1)
  const [dataNoteTable, setDataNoteTable] = useState([])
  const [dataNoteExport, setDataNoteExport] = useState([])
  const [infoPrintCoverage, setInfoPrintCoverage] = useState([])
  const [idRole, setIdRole] = useState(null)
  const [idStore, setIdStore] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [dataNote, setDataNote] = useState(null)
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [valueSecondTicket, setValueSecondTicket] = useState(null)
  const [response, setResponse] = useState(null)
  const [options, setOptions] = useState([])
  const [ids, setIds] = useState([])
  const [clearSelected, setClearSelected] = useState(false)
  const [optionsProviders, setOptionsProviders] = useState([])
  const [informationNote, setInformationNote] = useState([])
  const [idNote, setIdNote] = useState(null)
  const [totalRetention, setTotalRetention] = useState(null)
  const [consecutiveNumber, setConsecutiveNumber] = useState(null)
  const [chkBoxValueWithoutComments, setChkBoxValueWithoutComments] =
    useState(null)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 30,
    offset: 0,
    notesForContability: true,
    searchForChecks: chkBoxValueWithoutComments,
  })

  const optionsStatusNote = [
    {
      value: true,
      label: 'Contrarecibos sin pago',
    },
    {
      value: true,
      label: 'Contrarecibos con pago',
    },
  ]

  // const { loading, error, data, refetch } = useQuery(GET_NOTES, {
  //   variables: {
  //     searchQuery: pagePagination.searchQuery,
  //     limit: pagePagination.limit,
  //     offset: pagePagination.offset,
  //     // date: pagePagination.date,
  //     dateFrom: pagePagination.dateFrom,
  //     dateTo: pagePagination.dateTo,
  //     fullPayed: pagePagination.fullPayed,
  //     withOutPay: pagePagination.withOutPay,
  //     shopRegister: pagePagination.shopRegister,
  //     idProvider: pagePagination.idProvider,
  //     notesForContability: pagePagination.notesForContability,
  //     searchForChecks: pagePagination.searchForChecks,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const [getOneNote] = useLazyQuery(GET_ONE_NOTE)
  const [getInfoForPrintCoverage] = useLazyQuery(
    GET_INFO_PAYMENTS_FOR_PRINT_COVERAGE
  )
  const [getAllInfoForExportToExcel] = useLazyQuery(
    GET_ALL_INFO_FOR_EXPORT_TO_EXCEL
  )
  const [getRoles] = useLazyQuery(GET_ROLES)

  const [getAllNotesExport] = useMutation(EXPORT_ALL_NOTES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      // limit: pagePagination.limit,
      // offset: pagePagination.offset,
      // date: pagePagination.date,
      dateFrom: pagePagination.dateFrom,
      dateTo: pagePagination.dateTo,
      fullPayed: pagePagination.fullPayed,
      withOutPay: pagePagination.withOutPay,
      shopRegister: pagePagination.shopRegister,
      idProvider: pagePagination.idProvider,
      notesForContability: pagePagination.notesForContability,
      searchForChecks: pagePagination.searchForChecks,
      isFinances: false,
    },
    fetchPolicy: 'no-cache',
  })
  const {
    loading: loadingBills,
    error: errorBills,
    data: dataBills,
    refetch: refetchBills,
  } = useQuery(GET_BILLS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      dateFrom: pagePagination.dateFrom,
      dateTo: pagePagination.dateTo,
      fullPayed: pagePagination.fullPayed,
      withOutPay: pagePagination.withOutPay,
      shopRegister: pagePagination.shopRegister,
      idProvider: pagePagination.idProvider,
      notesForContability: pagePagination.notesForContability,
      searchForChecks: pagePagination.searchForChecks,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingStores,
    error: errorStores,
    data: dataStores,
  } = useQuery(GET_STORES, {
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingProviders,
    error: errorProviders,
    data: dataProviders,
  } = useQuery(GET_ALL_RETENTION_PARTNERS, {
    fetchPolicy: 'no-cache',
  })

  const [generateReport] = useMutation(GENERATE_REPORT_COUNTERCHARGES)

  const formmatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      signDisplay: 'negative',
      maximumFractionDigits: 4,
    }).format(number)

    return numberFormat
  }

  const handleChkBoxNext = (valueBox) => {
    if (valueBox === true) {
      setStep(step + 1)
    }
  }
  const handleChkBoxPrevious = (valueBox) => {
    if (!valueBox === true) {
      setStep(step - 1)
    }
  }

  useEffect(() => {
    if (!loadingBills) {
      if (errorBills)
        return toastSweetAlert({
          mode: 'error',
          message: errorBills?.message,
        })
      let listBills = []
      // console.log('dataBills', dataBills?.getBills?.rows)
      dataBills?.getBills?.rows?.map((item) => {
        return listBills.push({
          id: item?.id_note,
          id_bill: item?.id,
          consecutivo: item?.notes?.consecutive_number,
          tienda: item?.notes?.storesRegister?.alias,
          proveedor:
            item?.notes?.id_partner === 0
              ? item?.provider_name_nm
              : item?.notes?.partnerNotes?.provider_name,
          factura: item?.invoice_bill,
          retail: item?.retail ? item?.retail : item?.description,
          fecha: moment(item?.notes?.date_note, 'YYYY-MM-DD hh:mm:ss a').format(
            'DD/MM/YYYY hh:mm:ss a'
          ),
          'mon. fact.': formmatterCurrency(item?.amount_bill),
          monto: formmatterCurrency(item?.notes?.amount_note),
          retención: formmatterCurrency(item?.notes?.retention_note),
          pagado: formmatterCurrency(item?.notes?.pay_note),
          alert: item?.notes?.full_payed ? true : false,
          // hideColumns: 'id_bill',
          // omitField: true,
        })
      })
      // console.log('listBills', listBills)
      setDataNoteTable(listBills)
      setTotal(dataBills?.getBills?.count)
    }
  }, [loadingBills, errorBills, dataBills])

  // useEffect(() => {
  //   if (!loading) {
  //     if (error)
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: error?.message,
  //       })
  //     if (!chkBoxValueWithoutComments) {
  //       let listNote = []
  //       //document.getElementById('Print').click()
  //       data?.getNotes?.rows?.map((item) => {
  //         return listNote.push({
  //           id: item?.id,
  //           consecutivo: item?.consecutive_number,
  //           tienda: item?.storesRegister?.alias,
  //           // concepto: item?.conceptNotes?.name,
  //           fecha: moment(item?.date_note, 'YYYY-MM-DD hh:mm:ss a').format(
  //             'DD/MM/YYYY hh:mm:ss a'
  //           ),
  //           // rfc:
  //           //   item?.id_partner !== 0
  //           //     ? item?.partnerNotes?.provider_rfc
  //           //     : 'XAXX010101000',
  //           proveedor:
  //             item?.id_partner !== 0
  //               ? item?.partnerNotes?.provider_name
  //               : 'Nota Manual - Sin Proveedor',
  //           monto: formmatterCurrency(item?.amount_note),
  //           retención:
  //             item?.id_partner !== 0
  //               ? formmatterCurrency(item?.retention_note)
  //               : formmatterCurrency(0),
  //           abonado: formmatterCurrency(item?.pay_note),
  //           'A pagar':
  //             parseFloat(item?.amount_note) - parseFloat(item?.pay_note) === 0
  //               ? 'Pagada'
  //               : formmatterCurrency(item?.amount_note - item?.pay_note),
  //           alert: item?.full_payed ? true : false,
  //           // conditional_hide_button: item?.full_payed ? false : true,
  //           // facturas: item?.bills?.length,
  //         })
  //       })
  //       let listWithOutDuplicates = []

  //       listNote.forEach((item) => {
  //         let x = listWithOutDuplicates.find((i) => i.id === item.id)
  //         if (!x) {
  //           listWithOutDuplicates.push(item)
  //         }
  //       })

  //       setDataNoteTable(listWithOutDuplicates)
  //       console.log(listNote)
  //       setTotal(data?.getNotes?.count)
  //     }
  //     if (chkBoxValueWithoutComments) {
  //       let listNoteWithOutCom = []
  //       data?.getNotes?.rows?.map((item) => {
  //         return listNoteWithOutCom.push({
  //           id: item?.id,
  //           consecutivo: item?.consecutive_number,
  //           fecha: moment(item?.date_note, 'YYYY-MM-DD hh:mm:ss a').format(
  //             'DD/MM/YYYY hh:mm:ss a'
  //           ),
  //           monto: formmatterCurrency(item?.amount_note),
  //           pagado: formmatterCurrency(item?.pay_note),
  //           retención:
  //             item?.id_partner !== 0
  //               ? formmatterCurrency(item?.retention_note)
  //               : formmatterCurrency(0),
  //           // 'A pagar': item.full_payed
  //           //   ? 'Pagada'
  //           //   : formmatterCurrency(item?.amount_note - item?.pay_note),
  //           // facturas: item?.bills?.length,
  //         })
  //       })
  //       let listWithOutDuplicates = []

  //       listNoteWithOutCom.forEach((item) => {
  //         let x = listWithOutDuplicates.find((i) => i?.id === item.id)
  //         if (!x) {
  //           listWithOutDuplicates.push(item)
  //         }
  //       })

  //       setDataNoteTable(listWithOutDuplicates)
  //       // setDataNoteTable(listWithOutDuplicates)
  //       // setTotalNote(data.getNotes.count)
  //     }
  //   }
  // }, [loading, error, data, chkBoxValueInvoices, chkBoxValueWithoutComments])

  useEffect(async () => {
    if (!loadingStores) {
      if (errorStores)
        return toastSweetAlert({
          mode: 'error',
          message: errorStores.message,
        })

      let shops = []
      if (user.store === null) {
        shops = dataStores?.getAllStores?.rows?.map((item) => {
          return {
            value: item.id,
            label: item.alias,
          }
        })
      }
      // await handleRoleForStore(shops)
      setOptions(shops)
    }
  }, [loadingStores, errorStores, dataStores])

  useEffect(() => {
    if (!loadingProviders) {
      if (errorProviders)
        return toastSweetAlert({
          mode: 'error',
          message: errorProviders.message,
        })

      let providers = []

      providers = dataProviders?.getAllRetentionPartners?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.provider_name,
        }
      })
      setOptionsProviders(providers)
    }
  }, [dataProviders, loadingProviders, errorProviders])

  // const handleRoleForStore = async (shops) => {
  //   const { data: roles } = await getRoles({
  //     variables: {},
  //     fetchPolicy: 'no-cache',
  //   })

  //   console.log('roles', roles)

  //   const dataRoles = roles?.getAllRoles?.rows

  //   const findRoleShop = await dataRoles?.find(
  //     (item) => item?.role_name === 'Tienda'
  //   )

  //   const findRoleAdmin = await dataRoles?.find(
  //     (item) => item?.role_name === 'Administrador'
  //   )

  //   console.log('findRoleAdmin', findRoleAdmin)
  //   console.log('user.role', user.role)

  //   if (parseInt(findRoleAdmin?.id) !== parseInt(user.role)) {
  //     if (parseInt(findRoleShop?.id) !== parseInt(user.role)) {
  //       const findShopMatriz = await shops?.find(
  //         (item) => item.label === 'Matriz'
  //       )
  //       setIdStore(findShopMatriz?.value)
  //       console.log('findRoleShop', findRoleShop)
  //     }
  //   }
  //   setIdRole(findRoleAdmin?.id)
  // }

  useEffect(async () => {
    if (idStore) {
      filterOnChangeShopRegister(idStore)
    }
  }, [idStore])

  const handlePrintTicket = async (id) => {
    const { data } = await getOneNote({
      variables: {
        getOneNoteId: parseInt(id),
      },
      fetchPolicy: 'no-cache',
    })

    const dataNote = data?.getOneNote

    const secondTicketStatus = data?.getOneNote?.partnerNotes?.second_ticket
    const totalSecondTicket = Number(
      parseFloat(dataNote?.amount_note) - parseFloat(dataNote?.retention_note)
    )
    const consecutiveNumber = dataNote?.consecutive_number

    // console.log('totalSecondTicket', totalSecondTicket.toFixed(2))
    // console.log('consecutiveNumber', consecutiveNumber)

    setDataNote(data?.getOneNote)
    setValueSecondTicket(secondTicketStatus)
    setConsecutiveNumber(consecutiveNumber)
    setTotalRetention(totalSecondTicket)
    setIdNote(id)
    document.getElementById('PrintTicket').click()
  }

  const handlePrintSecondTicket = async () => {
    if (valueSecondTicket) {
      document.getElementById('PrintSecondTicket').click()
    } else {
      handleRePrintTicket()
    }
  }

  const handleRePrintTicket = async () => {
    // console.log('idNote', idNote)
    const reponseRePrint = await toastSweetAlert({
      mode: 'modalConfirmCancel',
      icon: 'info',
      message: '¿Desea re-imprimir el contrarecibo?',
    })

    if (reponseRePrint.isConfirmed) {
      handlePrintTicket(idNote)
    }
  }

  const filterSearchForChecks = (e) => {
    const checked = e.target.checked
    setChkBoxValueWithoutComments(checked)
    // console.log(checked)
    // console.log(chkBoxValueWithoutComments)
    setPagePagination({
      ...pagePagination,
      searchForChecks: checked,
    })
  }

  const filterOnChangeRangeDateFrom = (date) => {
    // console.log(date)
    const valueDate = date ? date : moment().format('YYYY-MM-DD')
    setDateFrom(date)
    setPagePagination({
      ...pagePagination,
      dateFrom: valueDate,
    })
  }

  const filterOnChangeRangeDateTo = (date) => {
    // console.log(date)
    const valueDate = date ? date : moment().format('YYYY-MM-DD')
    setDateTo(date)
    setPagePagination({
      ...pagePagination,
      dateTo: valueDate,
    })
  }

  const filterOnChangeShopRegister = (id) => {
    // console.log(id)
    setPagePagination({
      ...pagePagination,
      shopRegister: parseInt(id),
    })
  }

  const filterOnChangeBox3 = (e) => {
    const checked = e.target.checked
    setPagePagination({
      ...pagePagination,
      withOutPay: checked,
      fullPayed: false,
    })
  }

  const filterOnChangeBox4 = (e) => {
    const checked = e.target.checked
    setPagePagination({
      ...pagePagination,
      fullPayed: checked,
      withOutPay: false,
    })
  }

  const filterOnChangeForProviders = (value) => {
    const provider = value
    // console.log('provider', provider)
    setPagePagination({
      ...pagePagination,
      idProvider: parseInt(provider),
    })
  }

  // const handlePrintReport = async () => {
  //   try {
  //     setLoadingBtn(true)
  //     let dataExcel = []
  //     const { data } = await getAllNotesExport()
  //     // console.log(data?.getAllNotesExport)

  //     const dataExport = data?.getAllNotesExport

  //     // console.log('dataExport', dataExport)
  //     // console.log('dataExport Bills', dataExport?.bills)
  //     for (let notes of dataExport) {
  //       // console.log('notes', notes)
  //       let referencesPay = ''
  //       let referencesPayCheck = ''
  //       for (let payCheck of notes?.payNoteChecks) {
  //         // console.log('payCheck', payCheck)
  //         if (payCheck?.is_active) {
  //           referencesPayCheck += `${payCheck?.reference_pay}, `
  //         }
  //       }
  //       for (let bills of notes?.bills) {
  //         // console.log('bills', bills)
  //         for (let billPayments of bills?.billsPayments) {
  //           // console.log('billPayments', billPayments)
  //           for (let referencePay of billPayments?.paymentsBillsToChecks) {
  //             if (billPayments?.is_active) {
  //               // console.log('referencePay', referencePay)
  //               referencesPay += `${referencePay?.reference_pay}, `
  //             }
  //           }
  //         }
  //         // console.log(
  //         //   'referencesPay',
  //         //   referencesPay !== '' ? referencesPay : 'No'
  //         // )
  //         dataExcel.push({
  //           finances: notes?.status_note >= 3 ? 'Si' : 'No',
  //           consecutive_number: notes?.consecutive_number,
  //           invoice_bill: bills?.invoice_bill,
  //           uuid: bills?.id_bill,
  //           provider_name: notes?.partnerNotes?.provider_name
  //             ? notes?.partnerNotes?.provider_name
  //             : bills?.provider_name_nm,
  //           date_bill: moment(bills?.date_bill, 'YYYY-MM-DD hh:mm:ss a').format(
  //             'DD/MM/YYYY'
  //           ),
  //           date_note: moment(notes?.date_note, 'YYYY-MM-DD hh:mm:ss a').format(
  //             'DD/MM/YYYY'
  //           ),
  //           date_stamp: notes?.date_stamp,
  //           date_programmed_to_pay: notes?.date_programmed_to_pay,
  //           amount_bill: formmatterCurrency(bills?.amount_bill),
  //           amount_note: formmatterCurrency(notes?.amount_note),
  //           amount_programmed_to_pay_note: formmatterCurrency(
  //             notes?.amount_programmed_to_pay_note
  //           ),
  //           userRegister: notes?.userRegisterNotes?.name,
  //           user: notes?.userUpdateNotes?.name,
  //           retail: bills?.retail,
  //           comments: bills?.description,
  //           references:
  //             referencesPay !== '' ? referencesPay : referencesPayCheck,
  //         })
  //       }
  //     }

  //     // console.log('dataExcel', dataExcel)
  //     setLoadingBtn(false)
  //     setDataNoteExport(dataExcel)
  //     // console.log('dataExport', dataExport)
  //     document.getElementById('ExportExcel').click()
  //   } catch (e) {
  //     setLoadingBtn(false)
  //     return toastSweetAlertNotifications({
  //       mode: 'error',
  //       message: `Error al generar el reporte: ${e}`,
  //     })
  //   }
  // }

  // useEffect(() => {
  //   if (dataNoteTable) {
  //     handlePrintReport()
  //   }
  // }, [dataNoteTable])

  const handlePrintReport = async () => {
    try {
      console.log('pagePagination', pagePagination)

      const { data } = await generateReport({
        variables: {
          searchInput: pagePagination.searchQuery,
          startDate: pagePagination.dateFrom,
          endDate: pagePagination.dateTo,
          fullPayed: pagePagination.fullPayed,
          withOutPay: pagePagination.withOutPay,
          shopRegister: pagePagination.shopRegister,
          idProvider: pagePagination.idProvider,
          notesForContability: pagePagination.notesForContability,
          searchForChecks: pagePagination.searchForChecks,
          mode: 1,
        },
        fetchPolicy: 'no-cache',
      })

      return toastSweetAlert({
        mode: 'ok',
        message: data?.createCounterchargesReports?.message,
      })
    } catch (error) {
      console.log(error)
      return toastSweetAlert({
        mode: 'error',
        message: error?.message,
      })
    }
  }

  useEffect(() => {
    if (response === null || response === undefined) return
    if (response) {
      return toastSweetAlertNotifications({
        mode: 'ok',
        message: `Reporte generado correctamente`,
      })
    }

    if (!response) {
      return toastSweetAlertNotifications({
        mode: 'error',
        message: `Error al generar el reporte`,
      })
    }
  }, [response])

  const ExpandableRows = ({ data }) => {
    // console.log(data)
    return (
      <div>
        <ShowBillsToggle idNote={data?.id} />
      </div>
    )
  }

  const handlePrintCoverage = async (idNote) => {
    // console.log('idNote', idNote)
    let id = idNote.id
    const { data: dataCoverage } = await getInfoForPrintCoverage({
      variables: {
        idNote: idNote.id,
      },
      fetchPolicy: 'no-cache',
    })

    let infoCoverage = []

    const data = dataCoverage?.getInfoPaymentForPrintCoverage

    // console.log('dataCoverage', data)

    infoCoverage.push({
      checkNumber: data?.check_number,
      invoicePay: data?.invoice_number_payments,
      dateCheck: moment(data?.date_check, 'YYYY-MM-DD hh:mm:ss a').format(
        'DD/MM/YYYY'
      ),
      total: data?.amount_check_payment,
      provider: data?.payment_nominative
        ? data?.checksToNotes?.partnerNotes?.provider_name
          ? data?.checksToNotes?.partnerNotes?.provider_name
          : data?.checksToNotes?.bills[0]?.provider_name_nm
        : 'Al portador',
      store: data?.storesPayment?.name,
    })

    setInfoPrintCoverage(infoCoverage)
    document.getElementById('PrintCoverage').click()
  }

  const handleSelectedRows = async (e) => {
    setLoadingBtn(true)
    // setClearSelected(false)
    // console.log('e', e)
    // console.log('selected', e.selectedRows)
    let arrayIds = []
    for (let id of e.selectedRows) {
      const { data: dataGetOneNote } = await getOneNote({
        variables: {
          getOneNoteId: id.id,
        },
        fetchPolicy: 'no-cache',
      })

      arrayIds.push(id.id)
    }
    // console.log('arrayIds', arrayIds)
    setIds(arrayIds)
    setTimeout(() => {
      setLoadingBtn(false)
    }, 200)
  }

  const handleVerifyChecks = async () => {
    try {
      if (ids.length === 0)
        return toastSweetAlertNotifications(
          {
            mode: 'error',
            message: 'No hay registros seleccionados',
          },
          setClearSelected(!clearSelected),
          setIds([])
        )

      const result = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message: '¿Estas seguro de marcar los contrarecibos ?',
      })
      let listNote = []

      if (result.isConfirmed) {
        for (let id of ids) {
          const { data: dataGetOneNote } = await getOneNote({
            variables: {
              getOneNoteId: id,
            },
            fetchPolicy: 'no-cache',
          })

          let fullPayed = dataGetOneNote?.getOneNote?.full_payed
          if (!fullPayed) {
            return toastSweetAlert(
              {
                mode: 'error',
                message:
                  'No se puede marcar el contrarecibo, ya que no esta pagado',
              },
              setClearSelected(!clearSelected),
              setIds([])
            )
          }
          listNote.push(dataGetOneNote?.getOneNote)
        }
        // console.log('listNote', listNote)
        setInformationNote(listNote)
        setClearSelected(!clearSelected)
        document.getElementById('btnModalVerifyCashedChecks').click()
        setIds([])
      }
    } catch (e) {
      return toastSweetAlert({
        mode: 'error',
        message: 'Error al traer información de contrarecibo',
      })
    }
  }

  return (
    <>
      {step === 1 && (
        // <Box
        //   title="Listado de contrarecibos"
        //   // btnFunction={(e) => handlePrintReport(e)}
        //   btnFunctionTitle="Generar reporte"
        //   btnFunt
        //   content={
        <>
          <ContentHeader
            title="Historial de contrarecibos"
            windowTitle="Historial de contrarecibos"
          />
          <LayoutTable
            // withCard={false}
            // title="Historial"
            data={dataNoteTable}
            loadingData={loadingBills}
            hideAddNew={true}
            keyField="id_bill"
            hideColumns={['id_bill']}
            hideId
            gql={DELETE_NOTES}
            actionsInFirstColumn
            extraHeaderButtonFunction={loadingBtn ? false : handlePrintReport}
            extraHeaderButtonFunctionText="Descargar Reporte"
            refetchFunctionQueries={async () => await refetchBills()}
            importCustomVariables={[
              {
                searchQuery: pagePagination.searchQuery,
                limit: pagePagination.limit,
                offset: pagePagination.offset,
                dateFrom: pagePagination.dateFrom,
                dateTo: pagePagination.dateTo,
                fullPayed: pagePagination.fullPayed,
                withOutPayment: pagePagination.withOutPayment,
                shopRegister: pagePagination.shopRegister,
                notesForContability: pagePagination.notesForContability,
              },
            ]}
            exportQuery={EXPORT_ALL_NOTES}
            exportQueryCustom={getAllNotesExport}
            importQuery={IMPORT_PAYMENTS}
            totalCount={total}
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}
            // hideId={true}
            editText={
              <label>
                <i
                  className="fas fa-print"
                  // style={{ fontSize: '1rem', background: 'none' }}
                ></i>
              </label>
            }
            actionBtnEdit={(e) => handlePrintTicket(e)}
            detailsText={
              <label>
                <i
                  // style={{ color: 'black', }}
                  className="fas fa-search-plus"
                ></i>{' '}
              </label>
            }
            deleteText={
              <label>
                <i
                  // style={{ color: 'black', }}
                  className="fas fa-trash-alt"
                ></i>{' '}
              </label>
            }
            convertActionsToIcons
            FilterBarPlaceholder="Buscar..."
            filterCheckBox
            filterCheckBox1
            // filterCheckBox2
            filterCheckBox3
            filterCheckBox4
            valueChkBox={chkBoxValueWithoutComments}
            lblChkBox="Buscar por pagos"
            lblChkBox2="Todas las facturas"
            lblChkBox3="Facturas sin pago"
            lblChkBox4="Facturas pagadas"
            filterOnChangeBox={(e) => filterSearchForChecks(e)}
            filterOnChangeBox2={(e) => handleChkBoxNext(e.target.checked)}
            filterOnChangeBox3={(e) => filterOnChangeBox3(e)}
            filterOnChangeBox4={(e) => filterOnChangeBox4(e)}
            filterRangeDateFrom={true}
            filterRangeDateTo={true}
            idFilterRangeDateFrom="dateFrom"
            idFilterRangeDateTo="dateTo"
            filterOnChangeRangeDateFrom={(e) =>
              filterOnChangeRangeDateFrom(e.target.value)
            }
            filterOnChangeRangeDateTo={(e) =>
              filterOnChangeRangeDateTo(e.target.value)
            }
            // actionBtnDetail={(id) => history.push(`/shownotes/edit/${id}`)}
            componentAction={(row) => {
              return (
                <>
                  <button
                    className="icon-actions-layout-table"
                    onClick={() => handlePrintCoverage(row)}
                    disabled={row?.alert ? false : true}
                  >
                    {/* {console.log('row', row.alert)} */}
                    <i className="fas fa-file-invoice-dollar"></i>
                  </button>
                </>
              )
            }}
            widthCustomActions="200px"
            filterChoosen={[
              {
                condition: user.store === null ? false : true,
                id: 'stores',
                label: 'Por tienda',
                name: 'stores',
                className: 'position-searchInput',
                options: options,
                placeholder: 'Selecciona una tienda',
                onChange: (e) => filterOnChangeShopRegister(e?.value),
                isClearable: true,
              },
              {
                id: 'providers',
                label: 'Por proveedor',
                name: 'providers',
                className: 'position-searchInput',
                options: optionsProviders,
                placeholder: 'Selecciona un proveedor',
                onChange: (e) => filterOnChangeForProviders(e?.value),
                isClearable: true,
              },
            ]}
            // selectableRowsClick
            selectableRowsClick={(e) => handleSelectedRows(e)}
            clearSelectedRows={clearSelected}
            // customFilterButtonInput={
            //   <>
            //     <br />
            //     <br />
            //     <div
            //       className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ${
            //         loadingBtn ? 'processing-file' : ''
            //       } `}
            //     >
            //       <label htmlFor="">Acciones: </label>
            //       <span
            //         className="btn btn-sm btn-actions-bulk-counter-charges"
            //         onClick={handleVerifyChecks}
            //       >
            //         Marcar como cheque cobrado
            //       </span>
            //     </div>
            //   </>
            // }
          />

          <ExportExcel
            id="ExportExcel"
            excelData={dataNoteExport}
            fileName={'Historial Contra-recibos'}
            setResponse={setResponse}
            response={response}
            headers={[
              'Recibido Finanzas',
              'Consecutivo',
              'Factura',
              'UUID',
              'Nombre Proveedor',
              'Fecha Factura',
              'Fecha CR',
              'Fecha Sello',
              'Fecha Pago',
              'Monto Factura',
              'Monto Contra-recibo',
              'Monto a pagar',
              'Usuario que registro',
              'Usuario que modifico',
              'Retail',
              'Comentarios',
              'Referencias de Pago',
            ]}
            skipHeader={true}
            stylePayments
            colPayments={1}
            stylesToData={{
              font: { bold: false, sz: 10 },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true,
              },
              // border: { top: { style: 'thin' } },
            }}
          />
          <div className="hide">
            <PrintFirstTicket
              componentRef={componentRef}
              dataNote={dataNote}
              secondTicket={valueSecondTicket}
            />
            <ReactToPrint
              trigger={() => <button id="PrintTicket">Print this out!</button>}
              content={() => componentRef.current}
              copyStyles={false}
              pageStyle={`@page { size: 80mm auto; margin-top: 0mm; margin-bottom: 0mm; margin-left: 0mm; margin-right: 0mm; }`}
              // bodyClass="first-ticket"
              // pageStyle={`{@media print{
              //   @page{
              //     size: 30mm 20mm;
              //   }
              // }}`}
              // bodyClass="first-ticket"
              // pageStyle={`@page { size: 302px 900; margin-top: 0px; margin-bottom: 0mm; margin-left: 0px; margin-right: 0px; }`}
              // children={
              //   <PrintSecondTicket
              //     TotalRetention={totalRetention}
              //     consecutiveNumber={consecutiveNumber}
              //   />
              // }
              onAfterPrint={() => handlePrintSecondTicket()}
            />
          </div>

          <div className="hide">
            <PrintSecondTicket
              componentRef={componentRefSecondTicket}
              TotalRetention={totalRetention}
              consecutiveNumber={consecutiveNumber}
            />
            <ReactToPrint
              trigger={() => (
                <button id="PrintSecondTicket">Print this out!</button>
              )}
              content={() => componentRefSecondTicket.current}
              pageStyle="@page { size: 80mm auto; page-break-after: always; }"
              onAfterPrint={() => handleRePrintTicket()}
            />
          </div>

          <div className="hide">
            <PrintCheckCoverages
              infoCoverage={infoPrintCoverage}
              componentRef={componentRefCoverage}
            />

            <ReactToPrint
              documentTitle={`PolizaDeCheque-${infoPrintCoverage?.checkNumber}`}
              trigger={() => <button id="PrintCoverage"></button>}
              content={() => componentRefCoverage.current}
              pageStyle="@page { size: A4; margin: 10mm;}"
            />
          </div>

          <ModalContent
            idModal="modalVerifyCashedChecks"
            title="Verificar Cheques"
            size="large"
            content={
              <>
                <VerifyCashedChecks
                  dataNote={informationNote}
                  refModal={refModalVerifyCashedChecks}
                />
              </>
            }
          />
          <span
            id="btnModalVerifyCashedChecks"
            ref={refModalVerifyCashedChecks}
            className="btn btn-sm invisible"
            data-toggle="modal"
            data-target="#modalVerifyCashedChecks"
            data-backdrop="static"
            data-keyboard="false"
            data-dismiss="modal"
          />
        </>

        //   }
        // />
      )}
      {step === 2 && (
        <>
          <ContentHeader
            title="Historial"
            windowTitle="Historial"
            breadcrumb="Historial"
            btnRedPath="/catalog/notes/notesdashboard"
            btnRedTxt="Atras"
          />
          <ShowTableBills handleChkBoxPrevious={handleChkBoxPrevious} />
        </>
      )}
    </>
  )
}

export default Payments
