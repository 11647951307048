import React, { useEffect, useState } from 'react'
import ContentHeader from '../../Layout/ContentHeader'
import Box from '../../Global/Box'
import OrderCol from '../../Global/OrderCol'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import {
  GET_TOKEN_INTEGRATION,
  GET_LOGS,
  CREATE_TOKEN_INTEGRATION,
  UPDATE_TOKEN_INTEGRATION,
} from '../../../graphql/Integrations/tokens'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import LayoutTable from '../../Global/LayoutTable'
import DatesOptions from '../../Global/DatesOptions'

export const AuthML = () => {
  const [response, setResponse] = useState(null)
  const [secondStep, setSecondStep] = useState(null)
  const [flagNoToken, setFlagNoToken] = useState(null)
  const [flagExpiration, setFlagExpiration] = useState(null)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    dateSend: null,
    dateReceived: null,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_LOGS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      dateSend: pagePagination.dateSend,
      dateReceived: pagePagination.dateReceived,
    },
    fetchPolicy: 'no-cache',
  })
  const [dataToTable, setDataToTable] = useState([])
  const history = useHistory()

  const {
    data: dataToken,
    loading: loadingToken,
    error: errorToken,
  } = useQuery(GET_TOKEN_INTEGRATION, {
    variables: {
      name: 'Mercadolibre',
    },
    fetchPolicy: 'no-cache',
  })

  const [createToken] = useMutation(CREATE_TOKEN_INTEGRATION)
  const [updateToken] = useMutation(UPDATE_TOKEN_INTEGRATION)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    const error = urlParams.get('error')
    if (error) {
      toastSweetAlert({ mode: 'error', message: error })
      return setResponse({ status: false, data: error })
    }
    if (code) {
      setSecondStep(true)
      return setResponse({ status: true, data: code })
    }
  }, [])

  useEffect(() => {
    if (!loadingToken) {
      if (errorToken)
        return toastSweetAlert({ mode: 'error', message: errorToken.message })
      if (!dataToken?.getTokenIntegration) {
        setFlagNoToken(true)
        return toastSweetAlert({
          mode: 'error',
          message: 'Aún no se ha generado un token',
        })
      }
      const data = dataToken?.getTokenIntegration
      const now = moment().format()
      const expire = moment(data.createdAt).add(6, 'hours').format()
      setFlagNoToken(false)
      setResponse({ status: true, data: data })
      data.expiration = expire
      data.now = now
      if (moment(now).isAfter(expire)) {
        setResponse({ ...response, status: false })
        setFlagExpiration(true)
        if (!secondStep) {
          return toastSweetAlert({
            mode: 'error',
            message: 'El token ha expirado',
          })
        }
      }
    }
  }, [dataToken, errorToken, loadingToken])

  useEffect(() => {
    if (!loading) {
      if (error)
        toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getLogIncomingTopics.rows.map((item) => {
        return list.push({
          id: item.id,
          Tópico: item.topic,
          Enviado: item.sent,
          Recibido: item.received,
          Intentos: item.attempts,
          Resultado: item.result,
          Json: item.jsonTopic,
        })
      })
      setDataToTable(list)
      setTotal(data.getLogIncomingTopics.count)
    }
  }, [loading, error, data])

  const handleCreate = async () => {
    try {
      if (flagExpiration) {
        await updateToken({
          variables: {
            name: 'Mercadolibre',
            code: response.data,
          },
        })
        return toastSweetAlert(
          { mode: 'ok', message: 'Token generado correctamente' },
          history.push(`/auth/mercado`)
        )
      }
      await createToken({
        variables: {
          code: response.data,
        },
      })
      return toastSweetAlert(
        { mode: 'ok', message: 'Token generado correctamente' },
        history.push(`/auth/mercado`)
      )
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const filterOnChange = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      dateSend: parseInt(filterSelected),
    })
  }

  const filterOnChangeSecond = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      dateReceived: parseInt(filterSelected),
    })
  }

  return (
    <>
      <ContentHeader
        title="Control de acceso"
        breadcrumb="Mercadolibre"
        windowTitle="Acceso ML"
      />

      <Box
        title={`Generar token Mercadolibre`}
        content={
          <>
            {response && !flagNoToken && response?.status ? (
              <div className="p-3 row one-centered">
                <div className="col-12 box-separator">
                  <div className="row">
                    <OrderCol
                      title="Token"
                      content={response.data.token}
                      mediumColSize="6"
                    />
                    <OrderCol
                      title="Fecha creación"
                      content={moment(response.data.createdAt).format(
                        'DD/MM/YYYY hh:mm a'
                      )}
                      mediumColSize="3"
                    />
                    <OrderCol
                      title="Expiración:"
                      content={moment(response.data.expiration).format(
                        'DD/MM/YYYY hh:mm a'
                      )}
                      mediumColSize="3"
                    />
                    <OrderCol
                      title="Refresh token:"
                      content={response.data.refresh_token}
                      mediumColSize="3"
                    />
                  </div>
                </div>

                <div className="col-6">
                  <label
                    className="text-one text-center"
                    style={{ color: 'green' }}
                  >
                    El token esta activo y es válido. No hay nada más que hacer
                  </label>
                </div>
              </div>
            ) : !response?.status && !secondStep ? (
              <div
                className={`row one-centered ${
                  loadingBtn ? 'processing-file' : ''
                }`}
              >
                <div className="col-6">
                  <a
                    onClick={() => setLoadingBtn(true)}
                    className="btn btn-accept btn-block text-one"
                    href="https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=3251064999247120&redirect_uri=https://newsuite.vinosamerica.com/auth/mercado"
                  >
                    {loadingBtn
                      ? 'Solicitando...'
                      : 'Solicitar código de autorización'}
                  </a>
                </div>
              </div>
            ) : (
              <div
                className={`row one-centered ${
                  loadingBtn ? 'processing-file' : ''
                }`}
              >
                <div className="col-6">
                  <button
                    onClick={() => setLoadingBtn(true)}
                    className="btn btn-accept btn-block text-one"
                    onClickCapture={() => handleCreate()}
                  >
                    {loadingBtn ? 'Generando...' : 'Generar nuevo token'}
                  </button>
                </div>
              </div>
            )}
            <LayoutTable
              data={dataToTable}
              title="Histórico de notificaciones"
              totalCount={total}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              loadingData={loading}
              hideActions
              FilterBarPlaceholder= "Buscar por Tópico o Resultados"

              /** First selector */
              filter
              filterOnChange={filterOnChange}
              filterOptions={[<DatesOptions daysBefore={true} />]}
              filterLabelText="Por enviado"

              filterSecond
              filterOnChangeSecond={filterOnChangeSecond}
              filterOptionsSecond={[<DatesOptions daysBefore={true} />]}
              filterLabelTextSecond="Por recibido"
            />
          </>
        }
      />
    </>
  )
}

export default AuthML
