import { gql } from '@apollo/client'

export const GET_ALL_INVENTORY_ACTIVITIES_HISTORIC_SCHEDULE = gql`
  query getAllInventoryActivitiesHistoricSchedule(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $status: [Int]
    $typeSchedule: Int
  ) {
    getAllInventoryActivitiesHistoricSchedule(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      status: $status
      typeSchedule: $typeSchedule
    ) {
      rows {
        id
        start_date
        warehouse_id
        ending_date
        status
        status_sync
        reason
        type_schedule
        InventoryActivitiesWarehouseSchedule {
          warehouse_code
          warehouse_name
        }
        ubications_or_skus
      }
      count
    }
  }
`

export const GET_ALL_INVENTORY_ACTIVITIES_DATES = gql`
  query getAllInventoryActivitiesDatesSchedule {
    getAllInventoryActivitiesDatesSchedule {
      rows {
        id
        warehouse_id
        status
        start_date
        ending_date
        type_schedule
        InventoryActivitiesWarehouseSchedule {
          warehouse_code
          warehouse_name
        }
        ubications_or_skus
      }
      count
    }
  }
`

export const CREATE_NEW_INVENTORY_ACTIVITY_SCHEDULE = gql`
  mutation createInventoryActivitySchedule(
    $input: InventoryActivityScheduleInput!
  ) {
    createInventoryActivitySchedule(input: $input)
  }
`

export const UPDATE_INVENTORY_ACTIVITY_SCHEDULE = gql`
  mutation updateInventoryActivitySchedule(
    $id: Int!
    $input: InventoryActivityScheduleInput!
  ) {
    updateInventoryActivitySchedule(id: $id, input: $input)
  }
`

export const CANCEL_INVENTORY_ACTIVITY_SCHEDULE = gql`
  mutation CancelInventoryActivitySchedule($id: Int!, $reason: String!) {
    cancelInventoryActivitySchedule(id: $id, reason: $reason)
  }
`

export const CHANGE_INVENTORY_ACTIVITIES_STATUS_SCHEDULE = gql`
  mutation ChangeInventoryActivitiesStatusSchedule(
    $id: Int!
    $status: Int!
    $ending_date: String
  ) {
    changeInventoryActivitiesStatusSchedule(
      id: $id
      status: $status
      ending_date: $ending_date
    )
  }
`

export const GET_INVENTORY_ACTIVITY_INFO_BY_ID = gql`
  query GetInventoryActivityInfoById($scheduleId: Int!) {
    GetInventoryActivityInfoById(scheduleId: $scheduleId) {
      InventoryActivitiesWarehouseSchedule {
        warehouse_name
        warehouse_code
      }
      ending_date
      id
      status
      start_date
      reason
      status_sync
      type_schedule
      warehouse_id
      ubications_or_skus
      schedule_id_cycle_count_sku
    }
  }
`

// NEED TO CHECK
export const GET_TFI_PROGRESSBAR = gql`
  query getTfiProgressbar($idCalendar: Int!) {
    getTfiProgressbar(id_calendar: $idCalendar) {
      completed
      total
      percentage
      status_sync
    }
  }
`

export const GET_TFI_SYNC_ERRORS = gql`
  query GetTfiDataSyncErrors(
    $getTfiDataSyncErrorsId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getTfiDataSyncErrors(
      id: $getTfiDataSyncErrorsId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        is_active
        message_error
        tfi_schedule_id
      }
    }
  }
`

export const UPDATE_TFI_SCHEDULE_SYNC_STATUS = gql`
  mutation UpdateTfiScheduleSyncStatus($updateTfiScheduleSyncStatusId: Int) {
    updateTfiScheduleSyncStatus(id: $updateTfiScheduleSyncStatusId)
  }
`

export const REOPEN_TFI = gql`
  mutation ReOpenTFI($reOpenTfiId: Int!) {
    reOpenTFI(id: $reOpenTfiId)
  }
`
