import { useEffect, useState } from 'react'
import LayoutTable from '../../../Global/LayoutTable'
import { useQuery } from '@apollo/client'
import { GET_PRODUCTS_ORDER_PICKING_STORE_BY_ID_ORDER_WAREHOUSE } from '../../../../graphql/Catalog/Orders/pickingOrdersStore'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'

const DetailsProducts = ({ idOrderWarehouse, setCloseModal, closeModal }) => {
  const [dataProductsTable, setDataProductsTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    limit: 10,
    offset: 0,
    searchQuery: null,
  })

  const {
    data: dataProducts,
    loading: loadingProducts,
    error: errorProducts,
  } = useQuery(GET_PRODUCTS_ORDER_PICKING_STORE_BY_ID_ORDER_WAREHOUSE, {
    variables: {
      idOrderWarehouse: parseInt(idOrderWarehouse),
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (idOrderWarehouse) {
      if (!loadingProducts) {
        if (errorProducts) {
          return toastSweetAlert({
            mode: 'error',
            message: errorProducts.message,
          })
        }
        const products =
          dataProducts.getProductsForPickingOrdersStoreByidOrderWarehouse

        let list = []

        products.rows.map((product) => {
          return list.push({
            id: product.id,
            código: product.productToOrdersWarehouseProducts.sku,
            nombre: product.productToOrdersWarehouseProducts.name,
            cantidad: product.quantity,
            surtido: product.picked,
            cajas: product.boxes,
            tipo: product.type,
          })
        })
        setDataProductsTable(list)
        setTotal(products.count)
      }
    }
  }, [dataProducts, loadingProducts, errorProducts, idOrderWarehouse])

  return (
    <div>
      <LayoutTable
        hideId
        hideActions
        data={dataProductsTable}
        loading={loadingProducts}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
      />
    </div>
  )
}

export default DetailsProducts
