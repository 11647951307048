export const groupBtnEditDisableOrEnable = async ({ type }) => {
  var elements = document.querySelectorAll('button.btn-actionBtnEdit')
  if (!elements) return

  for await (const element of elements) {
    if (type === 'disabled') element.setAttribute('disabled', '1')
    else if (type === 'enabled') element.removeAttribute('disabled')
  }
  return
}
