import { gql } from '@apollo/client'

export const GET_ALL_ROUTES = gql`
  query GetAllRoutes($searchQuery: String, $limit: Int, $offset: Int) {
    getAllRoutes(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        name
      }
      count
    }
  }
`

export const GET_ROUTE = gql`
  query GetOneRoute($getOneRouteId: Int!) {
    getOneRoute(id: $getOneRouteId) {
      name
      description
    }
  }
`
export const EXPORT_ROUTES = gql`
  mutation GetAllRoutesExport {
    getAllRoutesExport {
      name
      description
    }
  }
`

export const CREATE_ROUTE = gql`
  mutation CreateRoute($input: RouteInput!) {
    createRoute(input: $input) {
      name
      description
    }
  }
`

export const UPDATE_ROUTE = gql`
  mutation UpdateRoute($routeId: Int!, $input: RouteInput!) {
    updateRoute(routeId: $routeId, input: $input)
  }
`
export const GET_ONE_SHIPMENT_ROUTE_FOR_EDITION = gql`
  mutation GetShipmentRouteForEdition($getShipmentRouteForEditionId: Int!) {
    getShipmentRouteForEdition(id: $getShipmentRouteForEditionId) {
      route_id
      operator_id
      name
      start_date
      start_time
      vehicle_id
    }
  }
`

export const DELETE_ROUTE = gql`
  mutation DeleteRoute($id: Int!) {
    deleteRoute(id: $id)
  }
`

// SHIPMENTS ROUTES
export const GET_ALL_SHIPMENTS_ROUTES = gql`
  query GetAllShipmentsRoutes($offset: Int, $limit: Int, $searchQuery: String) {
    getAllShipmentsRoutes(
      offset: $offset
      limit: $limit
      searchQuery: $searchQuery
    ) {
      count
      rows {
        id
        name
        subRoutes {
          id
          name
        }
      }
    }
  }
`

export const GET_ONE_SHIPMENT_ROUTE = gql`
  query GetOneShimpentRoute(
    $getOneShimpentRouteId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getOneShimpentRoute(
      id: $getOneShimpentRouteId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        doc_entry
        doc_num
        route_id
        operator_name
        vehicle
        warehouse_destiny_name {
          warehouse_name
        }
        warehouse_origin_name {
          warehouse_name
        }
        to_whs_code
        from_whs_code
      }
      count
    }
  }
`
export const GET_SHIPMENT_ROUTE_PACKING_LIST_DETAILS = gql`
  query GetShipmentRoutePackingListDetails(
    $getShipmentRoutePackingListDetailsId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getShipmentRoutePackingListDetails(
      id: $getShipmentRoutePackingListDetailsId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        route_id
        packing_list_id
        packing_list_box_id
        PackingListBoxesToRoutePakingBoxLoaded {
          PackingListBoxesLines {
            box_id
            item_code
            quantity
            SapItem {
              item_name
            }
          }
        }
      }
      count
    }
  }
`

export const GET_ALL_TRANSFER_REQUEST_BY_STORE_ID = gql`
  query GetAllTransferRequestByStoreId(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllTransferRequestByStoreId(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        doc_entry
        id
        status
        warehouse_origin_name {
          warehouse_name
          id
        }
        warehouse_destiny_name {
          warehouse_name
          id
        }
        from_whs_code
        to_whs_code
      }
    }
  }
`

export const CREATE_TRANSFER_REQUEST_SHIPMENT_ASSIGNATION = gql`
  mutation CreateTrasferRequestShipmentAssignation(
    $routeId: Int!
    $transferRequest: [Int]
  ) {
    createTrasferRequestShipmentAssignation(
      route_id: $routeId
      transferRequest: $transferRequest
    )
  }
`
export const GET_PENDING_BOXES_TO_LOAD = gql`
  query GetAllPendingBoxesToLoad(
    $getAllPendingBoxesToLoadId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllPendingBoxesToLoad(
      id: $getAllPendingBoxesToLoadId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        item_code
        id
        PackingListBoxesLines {
          quantity
          item_code
          box_id
          SapItem {
            item_name
            item_code
          }
        }
      }
    }
  }
`
export const GET_SHIPMENT_REPORT = gql`
  mutation GetRouteShipmentReport($getRouteShipmentReportId: Int!) {
    getRouteShipmentReport(id: $getRouteShipmentReportId) {
      name
      id
      createdAt
      updatedAt
      routesToRouteAppShipments {
        id
        name
      }
      RoutePointsAppShipmentsToRouteAppShipments {
        id
        packing_list_id
      }
      userToRouteAppShipments {
        first_name
        id
        name
        last_name
      }
      RouteAppShipmentsToRoutePakingBoxLoadedModel {
        id
        PackingListBoxesToRoutePakingBoxLoaded {
          id
          RoutePakingBoxLoadedToPackingListBoxLine {
            box_id
            quantity
            item_code
            SapItem {
              item_name
              item_code
            }
          }
        }
      }
    }
  }
`
export const CREATE_SHIPMENT_ROUTE = gql`
  mutation CreateShipmentRoute($input: ShipmentRouteInput!) {
    createShipmentRoute(input: $input)
  }
`
export const UPDATE_SHIPMENT_ROUTE = gql`
  mutation UpdateShipmentRoute(
    $input: ShipmentRouteEditInput!
    $updateShipmentRouteId: Int!
  ) {
    updateShipmentRoute(input: $input, id: $updateShipmentRouteId)
  }
`

export const GET_SHIPMENT_ROUTE_CHOOSEN_OPTS = gql`
  query GetShipmentRoutesChoosenOptions {
    getShipmentRoutesChoosenOptions {
      users {
        id
        name
        first_name
        last_name
      }
      routes {
        id
        name
      }
    }
  }
`
export const UPDATE_VEHICLE_ASSIGNATION = gql`
  mutation UpdateVehicleAssignation($routeId: Int!, $vehicleId: Int!) {
    updateVehicleAssignation(routeId: $routeId, vehicleId: $vehicleId)
  }
`

export const UPDATE_VEHICLE_VERIFICATION = gql`
  mutation UpdateVehicleVerification($routeId: Int!) {
    updateVehicleVerification(routeId: $routeId)
  }
`
