import { useEffect } from 'react'

export const SimpleTable = ({
  data = [],
  noDataComponentText,
  title = false,
  sameSize = true,
  firstColor = '#559615',
  secondaryColor = '#AF2525',
  omitFields = [],
}) => {
  const headers = data.length > 0 ? Object?.entries(data[0]) : []
  const dataColumns = Object.values(data)
  const omitFieldsArray = omitFields

  return (
    <div
      className={`container border-top ${sameSize ? 'providersTableBox' : ''}`}
    >
      {title && <h4 className="mt-2 mb-2">{title}</h4>}
      {data.length > 0 ? (
        <table className="table table-hover">
          <thead>
            <tr>
              {headers.map((header) => {
                return (
                  !omitFieldsArray.includes(header[0]) && (
                    <th scope="col">{header[0]}</th>
                  )
                )
              })}
            </tr>
          </thead>
          <tbody>
            {dataColumns.map((column, index) => {
              const findStatus = Object.entries(column).find((col) => {
                if (col[0] === 'conditionalStatus') return col
              })
              const status = findStatus[1]
              return (
                <tr
                  key={`${index}-simpleTable`}
                  style={{
                    color:
                      status === 1
                        ? firstColor
                        : status === 2
                        ? secondaryColor
                        : '',
                    fontWeight: status ? 'bold' : '',
                  }}
                >
                  {Object.values(column)
                    .slice(0, -omitFieldsArray.length)
                    .map((value, innerIndex) => {
                      return (
                        <td key={`${index}-${innerIndex}simpleTableTD`}>
                          {value}
                        </td>
                      )
                    })}
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="">
          <h5 className="one-centered">{noDataComponentText}</h5>
        </div>
      )}
    </div>
  )
}

export default SimpleTable
