import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, set, useForm } from 'react-hook-form'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import ContentHeader from '../Layout/ContentHeader'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import InputController from '../Global/InputController'
import Box from '../Global/Box'
import { generatePriceTags } from '../Helpers/pdfGenerator'
// apollo
import {
  GET_ITEMS_IN_STORE,
  GET_ITEMS_SELECTED_FOR_PRINT_LABELS,
  GET_ITEMS_WITH_DIFFERENCE_PRICE_FOR_PRINT_LABELS,
  GET_ITEM_BY_ITEM_CODE_BARCODE_ITEM_NAME,
} from '../../graphql/Catalog/SAP/sapItems'
import { GET_ALL_ITEM_GROUPS } from '../../graphql/Catalog/SAP/sapItems'
import LayoutTable from '../Global/LayoutTable'
import moment from 'moment'
import PrintLabels from './PrintLabels/PrintLabels'
import ReactToPrint from 'react-to-print'
import { useBarcode } from 'next-barcode'
import ModalContent from '../Global/ModalContent'
import ModalQuantityLabels from './ModalQuantityLabels/ModalQuantityLabels'
import OrderCol from '../Global/OrderCol'
import { toastSweetAlertNotifications } from '../Helpers/ToastSweetAlertNotifications'
import Swal from 'sweetalert2'
import {
  FRIDGE_STYLES_TAGS,
  STYLES_TAGS_20,
  STYLES_TAGS_24,
} from '../Helpers/StylesToTagsForStores'

export const PriceTags = () => {
  const componentRef = useRef()
  const refModalQuantity = useRef()
  const onlyComponent = useRef()

  const [total, setTotal] = useState(0)
  const [printCount, setPrintCount] = useState(0)
  const [listOfItems, setListOfItems] = useState([])
  const [itemGroups, setItemGroups] = useState([])
  const [items, setItems] = useState([])
  const [listOfStoreItems, setListOfStoreItems] = useState([])
  const [skusSelected, setSkusSelected] = useState([])
  const [skuToSearch, setSkuToSearch] = useState('')
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [barcodeValue, setBarcodeValue] = useState('1')
  const [isPrinting, setIsPrinting] = useState(false)
  const [printLabels, setPrintLabels] = useState(false)
  const [printingLabels, setPrintingLabels] = useState(false)
  const [labelsInformation, setLabelsInformation] = useState([])
  const [closeModal, setCloseModal] = useState(false)
  const [countSelected, setCountSelected] = useState(0)
  const [skuDelete, setSkuDelete] = useState(false)
  const [printLabelsWithDifferentPrice, setPrintLabelsWithDifferentPrice] =
    useState(false)
  const [typeLabel, setTypeLabel] = useState('')
  const [clickComponent, setClickComponent] = useState(true)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { inputRef } = useBarcode({
    value: barcodeValue,
  })

  const { data: itemGroupsData } = useQuery(GET_ALL_ITEM_GROUPS)
  const [getItemInStore] = useLazyQuery(GET_ITEMS_IN_STORE)
  const [getItemsWithDifferentPrice] = useMutation(
    GET_ITEMS_WITH_DIFFERENCE_PRICE_FOR_PRINT_LABELS
  )
  const [getItemsSelectedForPrintLabel] = useMutation(
    GET_ITEMS_SELECTED_FOR_PRINT_LABELS
  )

  const [getItemByItemCodeBarcodeItemName] = useMutation(
    GET_ITEM_BY_ITEM_CODE_BARCODE_ITEM_NAME
  )
  // const { loading, error, data } = useQuery(GET_ITEMS_IN_STORE, {
  //   variables: {
  //     searchQuery: pagePagination.searchQuery,
  //     limit: pagePagination.limit,
  //     offset: pagePagination.offset,
  //   },
  //   fetchPolicy: 'network-only',
  // })

  const {
    methods,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm()

  const formatterCurrency = (number) => {
    let numberFormat = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 2,
    }).format(number)

    return numberFormat
  }

  // const handleClick = (event) => {
  //   if (onlyComponent.current && onlyComponent.current.contains(event.target)) {
  //     const searchQuery = document.getElementById('idSearchQuery')
  //     if (searchQuery) {
  //       searchQuery.focus()
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (clickComponent) {
  //     document.addEventListener('click', handleClick)
  //     return () => {
  //       document.removeEventListener('click', null)
  //     }
  //   }
  // }, [clickComponent])

  useEffect(() => {
    // if (skuToSearch === '') {
    document.getElementById('idSearchQuery').focus()
    // }
  }, [skuToSearch])

  useEffect(() => {
    if (itemGroupsData?.getAllSapItemGroups) {
      const list = itemGroupsData.getAllSapItemGroups.map((item) => ({
        value: item.items_group_code,
        label: item.items_group_name,
      }))
      setItemGroups(list)
    }
  }, [itemGroupsData])

  useEffect(() => {
    if (closeModal) {
      const list = labelsInformation.map((item) => {
        return {
          id: item.id,
          SKU: item.SKU,
          Descripcion: item.Descripcion,
          Codigo: item.Codigo,
          Precio: item.Precio,
          inputRef: item.inputRef,
          quantity: 1,
        }
      })

      setTimeout(() => {
        setLabelsInformation(list)
        setCloseModal(false)
        setSkuToSearch('')
        setValue('searchQuery', '')
        document.getElementById('idSearchQuery').focus()
      }, 500)
    }
  }, [closeModal])

  useEffect(() => {
    try {
      const deleteItem = async () => {
        const result = await toastSweetAlert({
          mode: 'modalConfirmCancel',
          icon: 'warning',
          title: '¿Estas seguro de eliminar este producto de la lista?',
          message: 'No podras recuperar la informacion despues de eliminarla',
        })

        if (result.isConfirmed) {
          const list = labelsInformation.filter(
            (item) => item.SKU !== skuDelete
          )
          const listTable = listOfItems.filter((item) => item.SKU !== skuDelete)

          const listLabels = list.map((item, index) => {
            return {
              id: index + 1,
              SKU: item.SKU,
              Descripcion: item.Descripcion,
              Codigo: item.Codigo,
              Precio: item.Precio,
              inputRef: item.inputRef,
              quantity: 1,
            }
          })

          setTimeout(() => {
            setLabelsInformation(listLabels)
            setListOfItems(listTable)
            setSkuDelete(false)
            document.getElementById('idSearchQuery').focus()
            return toastSweetAlertNotifications(
              {
                mode: 'ok',
                message: 'Articulo eliminado con exito',
                timer: 2000,
              },
              setSkuToSearch(''),
              setValue('searchQuery', '')
            )
          }, 200)
        }

        document.getElementById('idSearchQuery').focus()
        setSkuDelete(false)
      }

      if (skuDelete) {
        deleteItem()
        setSkuDelete(false)
      }
    } catch (error) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: error.message,
        },
        setSkuDelete(false),
        setSkuToSearch(''),
        setValue('searchQuery', '')
      )
    }
  }, [skuDelete])

  // useEffect(() => {
  //   if (!loading) {
  //     if (error)
  //       toastSweetAlert({
  //         mode: 'error',
  //         message: error.message,
  //       })

  //     let list = []
  //     // console.log('data', data)
  //     data.getItemsInStore.rows.map((item) => {
  //       let btnCheck = (
  //         <input
  //           id={item.id + '-checkBoxLay'}
  //           type="checkbox"
  //           onClick={(e) => checkSelectedItem(e, item)}
  //         />
  //       )
  //       const priceList =
  //         item.ItemPrices.length === 1
  //           ? item.ItemPrices[0].price_list
  //           : item.ItemPrices.reduce((list1, list2) => {
  //               return list2.price >= list1.price ||
  //                 (list2.price <= list1.price && list2.price !== 0)
  //                 ? list2.price_list
  //                 : list1.price_list
  //             })

  //       const price = item.ItemPrices.find(
  //         (element) => element.price_list == priceList
  //       )

  //       // console.log('ITEM CODE', item.item_code)
  //       // console.log('priceList', priceList)
  //       // console.log('price', price)
  //       // console.log('END ITEM CODE', item.item_code)

  //       return list.push({
  //         id: item.id,
  //         ['SKU']: item.item_code,
  //         ['Descripcion']: item.item_name,
  //         ['Codigo']: item.item_code_bar,
  //         // [`Precio L${listPrice1}`]: `${formatterCurrency(
  //         //   item.ItemPrices[0].price
  //         // )}`,
  //         // [`Precio L${listPrice2}`]: `${formatterCurrency(
  //         //   item.ItemPrices[1].price
  //         // )}`,
  //         // [`Precio`]: `${formatterCurrency(
  //         //   salesPrice(item, priceList).toFixed(2)
  //         // )}`,
  //         [`Precio`]: `${formatterCurrency(
  //           calculatePriceWithTax(price.price, item)
  //         )}`,
  //         // [`Precio${listPrice2}`]: `${formatterCurrency(
  //         //   salesPrice(item, listPrice2).toFixed(2)
  //         // )}`,
  //         // ['Descuento']: `${(
  //         //   ((salesPrice(item, 5).toFixed(2) - salesPrice(item, 2).toFixed(2)) /
  //         //     salesPrice(item, 2).toFixed(2)) *
  //         //   100
  //         // ).toFixed(2)} %`,
  //         // ['Precio l5']: `${salesPrice(item, 5).toFixed(2)} $`,
  //         ['En Stock']: item.SapItemsToStockOne.on_hand,
  //         // ['Seleccionar']: btnCheck,
  //       })
  //     })
  //     // setListOfItems(list)
  //     // setTotal(data.getItemsInStore.count)
  //   }
  // }, [loading, error, data])

  useEffect(() => {
    try {
      const getItemForPrint = async () => {
        const existsItemBySKU = listOfItems.find(
          (item) => item.SKU === skuToSearch
        )
        const existsItemByBarcode = listOfItems.find(
          (item) => item.Codigo === skuToSearch
        )

        if (existsItemBySKU || existsItemByBarcode) {
          return setTimeout(() => {
            return toastSweetAlertNotifications(
              {
                mode: 'error',
                message: 'El articulo ya se encuentra en la lista',
              },
              setSkuToSearch(''),
              setValue('searchQuery', ''),
              document.getElementById('idSearchQuery').focus()
            )
          })
          // const promiseExists = new Promise((resolve) => {
          //   console.log('resolve', resolve)
          //   resolve()
          // })

          // promiseExists.then(() => {
          //   return toastSweetAlertNotifications(
          //     {
          //       mode: 'error',
          //       message: 'El articulo ya se encuentra en la lista',
          //     },
          //     setSkuToSearch(''),
          //     setValue('searchQuery', '')
          //   )
          // })

          // return
        }

        const result = await getItemByItemCodeBarcodeItemName({
          variables: {
            value: skuToSearch,
          },
          fetchPolicy: 'no-cache',
        })

        // console.log('result', result)

        let barcode = '1'
        const barcodeIs1 = barcodeValue === '1'
        const item = result.data.getSapItemsByItemCodeBarcodeItemName

        if (item === null || item === undefined) {
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message: 'No se encontro el articulo',
            },
            setSkuToSearch(''),
            setValue('searchQuery', '')
          )
        }

        const existsItemCode = listOfItems.find(
          (itemSearch) => itemSearch.SKU === item.item_code
        )

        if (existsItemCode) {
          return toastSweetAlertNotifications(
            {
              mode: 'error',
              message: 'El articulo ya se encuentra en la lista',
            },
            setSkuToSearch(''),
            setValue('searchQuery', ''),
            document.getElementById('idSearchQuery').focus()
          )
        }

        let list = labelsInformation.map((itemList) => {
          return {
            id: itemList.id,
            SKU: itemList.SKU,
            Descripcion: itemList.Descripcion,
            Codigo: itemList.Codigo,
            Precio: itemList.Precio,
            inputRef: itemList.inputRef,
            quantity: itemList.quantity,
          }
        })

        let listTable = listOfItems.map((itemList) => {
          return {
            id: itemList.id,
            SKU: itemList.SKU,
            Descripcion: itemList.Descripcion,
            Codigo: itemList.Codigo,
            Precio: itemList.Precio,
          }
        })

        // result.data.getSapItemsByItemCodeBarcodeItemName.map(async (item) => {

        if (item.SapItemsToSapBarCodeData && barcode === '1') {
          setBarcodeValue(item.SapItemsToSapBarCodeData.barcode)
          barcode = item.SapItemsToSapBarCodeData.barcode
        }

        if (item.item_code_bar !== '' && barcode === '1') {
          setBarcodeValue(item.item_code_bar)
          barcode = item.item_code_bar
        }

        if (barcode === '1') {
          setBarcodeValue('1')
        }

        const barcodeIMG = await handleGetImageFromBarcode(barcode)
        // console.log('item.ItemPrices', item.ItemPrices[0])

        item.ItemPrices.sort((a, b) => {
          return a.price_list - b.price_list
        })

        const priceList =
          item.ItemPrices.length === 1
            ? item.ItemPrices[0].price_list
            : item.ItemPrices.reduce((list1, list2) => {
                return list2.price >= list1.price ||
                  (list2.price <= list1.price && list2.price !== 0)
                  ? list2.price_list
                  : list1.price_list
              })

        const price = item.ItemPrices.find(
          (element) => element.price_list == priceList
        )

        // console.log('listPrice', listPrice)

        // const listPrice = item.
        // console.log('barcode', item.item_code_bar)

        listTable.push({
          id: item.item_code,
          ['SKU']: item.item_code,
          ['Descripcion']: item.item_name,
          ['Codigo']: barcode,
          Precio: formatterCurrency(price.price),
          // ['Precio']: formatterCurrency(
          //   calculatePriceWithTax(price.price, item)
          // ),
          // [`Precio L${listPrice1}`]: `${formatterCurrency(
        })

        list.push({
          ['id']: list.length + 1,
          ['SKU']: item.item_code,
          ['Descripcion']: item.item_name,
          ['Codigo']: barcode,
          Precio: price.price,
          // ['Precio']: calculatePriceWithTax(price.price, item),
          // ['Precio']: salesPrice(item, priceList).toFixed(2),
          inputRef: barcodeIMG,
          quantity: 1,
        })

        setListOfItems(listTable)
        setLabelsInformation(list)
        setSkuToSearch('')
        setValue('searchQuery', '')
        setBarcodeValue('1')
        return toastSweetAlertNotifications({
          mode: 'ok',
          message: 'Articulo consultado con exito',
        })
        // })
      }
      if (skuToSearch !== '') {
        getItemForPrint()
      }
    } catch (error) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: error.message,
        },
        setSkuToSearch(''),
        setValue('searchQuery', ''),
        setBarcodeValue('1')
      )
    }
  }, [skuToSearch])

  useEffect(() => {
    try {
      const getItems = async () => {
        //  toastSweetAlert({
        //    mode: 'loading',
        //    message: 'Imprimiendo etiquetas...',
        //  })
        let list = []
        let listTable = []
        if (printLabelsWithDifferentPrice) {
          const result = await getItemsWithDifferentPrice({
            variables: {},
            fetchPolicy: 'no-cache',
          })

          // console.log('result', result)

          if (result.data.getSapItemsWithDifferentPrices.length === 0) {
            return toastSweetAlert(
              {
                mode: 'error',
                message: 'No se encontraron articulos con precio diferente',
              },
              setPrintLabelsWithDifferentPrice(false),
              setPrintLabels(false),
              setSkusSelected([])
            )
          }

          const barcodeIs1 = barcodeValue === '1'

          result.data.getSapItemsWithDifferentPrices.map(async (item) => {
            let barcode = '1'
            // if (barcodeIs1) {
            if (item.SapItemsToSapBarCodeData && barcode === '1') {
              setBarcodeValue(item.SapItemsToSapBarCodeData.barcode)
              barcode = item.SapItemsToSapBarCodeData.barcode
            }

            if (item.item_code_bar !== '' && barcode === '1') {
              setBarcodeValue(item.item_code_bar)
              barcode = item.item_code_bar
            }
            // }

            if (barcode === '1') {
              setBarcodeValue('1')
            }

            const barcodeIMG = await handleGetImageFromBarcode(barcode)

            const priceList =
              item.ItemPrices.length === 1
                ? item.ItemPrices[0].price_list
                : item.ItemPrices.reduce((list1, list2) => {
                    return list2.price >= list1.price ||
                      (list2.price <= list1.price && list2.price !== 0)
                      ? list2.price_list
                      : list1.price_list
                  })

            const price = item.ItemPrices.find(
              (element) => element.price_list == priceList
            )

            // console.log('price', price)

            // console.log(
            //   'Es 8 o 9 ',
            //   item.ItemPrices[0].price_list === 8 ||
            //     item.ItemPrices[0].price_list === 9
            //     ? item.ItemPrices[0].price_list
            //     : item.ItemPrices[1].price_list
            // )
            // console.log('listPrice', listPrice)

            listTable.push({
              id: item.item_code,
              SKU: item.item_code,
              Descripcion: item.item_name,
              Codigo: barcode,
              Precio: formatterCurrency(price.price),
              // Precio: formatterCurrency(
              //   calculatePriceWithTax(price.price, item)
              // ),
            })

            return list.push({
              ['id']: list.length + 1,
              ['SKU']: item.item_code,
              ['Descripcion']: item.item_name,
              ['Codigo']: barcode,
              ['Precio']: price.price,
              // ['Precio']: calculatePriceWithTax(price.price, item),
              // ['Precio']: salesPrice(item, listPrice).toFixed(2),
              inputRef: barcodeIMG,
              quantity: 1,
            })
          })
          toastSweetAlertNotifications(
            {
              mode: 'ok',
              message: 'Articulos consultados con exito',
            },
            setSkuToSearch(''),
            setValue('searchQuery', '')
          )
        }
        setBarcodeValue('1')
        setPrintLabels(false)
        document.getElementById('idSearchQuery').click()
        setTimeout(() => {
          setLabelsInformation(list)
          setListOfItems(listTable)
          // document.getElementById('spanModalQuantity').click()
        }, 200)
      }

      if (printLabels && !printingLabels) {
        setLabelsInformation([])
        setListOfItems([])
        getItems()
        setSkuToSearch('')
        setValue('searchQuery', '')
        setPrintCount(0)
      }
    } catch (e) {
      console.log(e)
      return toastSweetAlert(
        {
          mode: 'error',
          message: e.message,
        },
        setPrintLabels(false),
        setSkusSelected([]),
        setSkuToSearch(''),
        setValue('searchQuery', '')
      )
    }
  }, [printLabels])

  useEffect(() => {
    if (printingLabels) {
      console.log('IMPRIMIENDO')
      document.getElementById('printPageLabels').click()
      setIsPrinting(true)
      setClearSelectedRows(true)
      setCountSelected(0)
      setPrintCount(printCount + 1)
    }

    if (isPrinting && printingLabels) {
      return toastSweetAlert({
        mode: 'loading',
        message: 'Imprimiendo etiquetas...',
      })
    }

    if (!isPrinting && printingLabels) {
      const list = labelsInformation.map((item) => {
        return {
          id: item.id,
          SKU: item.SKU,
          Descripcion: item.Descripcion,
          Codigo: item.Codigo,
          Precio: item.Precio,
          inputRef: item.inputRef,
          quantity: 1,
        }
      })

      return setTimeout(() => {
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Etiquetas impresas con exito',
          },
          setPrintingLabels(false),
          setIsPrinting(false),
          setClearSelectedRows(false),
          setPrintLabelsWithDifferentPrice(false),
          setLabelsInformation(list),
          setSkusSelected([]),
          document.getElementById('idSearchQuery').focus()
        )
      }, 1000)
    }
  }, [printingLabels, isPrinting])

  const exportPDFWithDifferentPrice = async () => {
    return toastSweetAlert(
      {
        mode: 'loading',
        message: 'Espere un momento. Consultando articulos...',
      },
      setPrintLabelsWithDifferentPrice(true),
      setPrintLabels(true)
    )
  }

  const handleRePrintLabels = async () => {
    // console.log('ESTOY EN REPRINT LABELS')
    document.getElementById('printPageLabels').click()
    setPrintingLabels(true)
    setIsPrinting(true)
    setClearSelectedRows(true)
    setCountSelected(0)
    setPrintCount(printCount + 1)
  }

  const calculatePriceWithTax = (price, tax) => {
    if (!tax.SapItemsToSalesTaxCodes) return price

    return price * (1 + tax.SapItemsToSalesTaxCodes.rate / 100)
  }

  const exportPDF = async () => {
    try {
      // if (printCount > 0) {
      // const response = await toastSweetAlert({
      //   mode: 'modalConfirmCancel',
      //   message:
      //     '¿Desea exportar una nueva lista de etiquetas?. Ya no podra imprimir las etiquetas anteriores',
      // })

      // if (response.isConfirmed) {
      if (labelsInformation.length === 0) {
        return toastSweetAlert({
          mode: 'error',
          message: 'Debes agregar al menos un articulo',
        })
      }

      const validatorOptionSelected = () => {
        const tags20 = document.getElementById('20tags').checked
        const tags24 = document.getElementById('24tags').checked
        const fridgeValue = document.getElementById('fridge').checked

        if (!tags20 && !tags24 && !fridgeValue) {
          Swal.showValidationMessage(`Debe seleccionar un tipo de etiqueta`)
        }
        return true
      }

      const response = await toastSweetAlert({
        mode: 'modalConfirmContent',
        icon: 'info',
        validator: validatorOptionSelected,
        content: (
          <>
            <label>Seleccione el tipo de etiqueta:</label>
            <div className="row">
              <div className="col-lg-12 ml-1">
                <input id="20tags" type="radio" name="typeLabel" />
                <label htmlFor="20tags"> &nbsp; 20 etiquetas</label>
              </div>
              <div className="col-lg-12">
                <input id="24tags" type="radio" name="typeLabel" />
                <label htmlFor="24tags"> &nbsp;24 etiquetas</label>
              </div>
              <div className="col-lg-12 ml-1">
                <input id="fridge" type="radio" name="typeLabel" />
                <label htmlFor="fridge"> &nbsp;Refrigerador</label>
              </div>
            </div>
          </>
        ),
      })

      const verticalLabel = document.getElementById('20tags').checked
      const horizontalLabel = document.getElementById('24tags').checked
      const fridgeLabel = document.getElementById('fridge').checked

      if (response.isConfirmed) {
        if (verticalLabel) {
          setTypeLabel('20tags')
        }

        if (horizontalLabel) {
          setTypeLabel('24tags')
        }

        if (fridgeLabel) {
          setTypeLabel('fridge')
        }

        setTimeout(() => {
          // setLabelsInformation(list)
          // document.getElementById('idSearchQuery').focus()
          document.getElementById('spanModalQuantity').click()
        }, 200)
      }

      document.getElementById('idSearchQuery').focus()
    } catch (e) {
      console.log(e)
      document.getElementById('idSearchQuery').focus()
    }
  }

  const handleGetImageFromBarcode = (barcode) => {
    setBarcodeValue(barcode)
    return inputRef.current.src
  }

  const handleCleanLists = () => {
    setListOfItems([])
    setLabelsInformation([])
    setSkusSelected([])
    document.getElementById('idSearchQuery').focus()
  }

  const handleDeleteItem = async (id) => {
    try {
      setSkuDelete(id)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleFilterChange = (groups) => {
    const groupsCode = groups.map((group) => {
      return parseInt(group.value)
    })
    setPagePagination({
      ...pagePagination,
      groupItem: groupsCode,
    })
  }
  const checkSelectedItem = (e, itemSel) => {
    const check = e.target.checked ? true : false
    if (check) {
      setItems((old) => [...old, itemSel])
    } else {
      setItems((old) =>
        old.filter((item) => item.item_code !== itemSel.item_code)
      )
    }
  }

  const handleSelectedRows = (value) => {
    let arraySkus = []
    for (let row of value.selectedRows) {
      arraySkus.push(row.SKU)
    }
    setSkusSelected(arraySkus)
    setCountSelected(value.selectedCount)
  }

  // console.log('labelsInformation', labelsInformation)
  // console.log('listOfItems', listOfItems)
  return (
    <>
      <ContentHeader
        title="Etiquetas"
        breadcrumb="Lista de precios"
        windowTitle="Etiquetas"
      />
      <Box
        // title={`Escaner de etiquetas`}
        btnFunction={() => exportPDF()}
        btnFunctionTitle={'Exportar'}
        // btnSecondFunction={
        //   printCount > 0 && labelsInformation.length > 0
        //     ? () => handleRePrintLabels()
        //     : null
        // }
        // btnSecondFunctionTitle="Re imprimir etiquetas"
        content={
          <>
            <div className="container-fluid">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <InputController
                  id="idSearchQuery"
                  // autoFocus={skuToSearch === ''}
                  // ref={inputFocusRef}
                  name="searchQuery"
                  control={control}
                  label="Buscar producto"
                  placeHolder="Buscar por SKU o Descripcion"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      if (event.target.value === '') {
                        return toastSweetAlert(
                          {
                            mode: 'error',
                            message: 'Debe ingresar un valor para buscar',
                          },
                          setValue('searchQuery', ''),
                          setSkuToSearch('')
                        )
                      }
                      toastSweetAlert({
                        mode: 'loading',
                        message: 'Consultando articulo...',
                      })
                      setSkuToSearch(event.target.value.trim())
                    }
                  }}
                  // autoFocus
                />
              </div>
              <div>
                <LayoutTable
                  hideId
                  withCard={false}
                  // hideActions={true}
                  // title="Lista de articulos"
                  data={listOfItems}
                  // totalCount={total}
                  // loadingData={loading}
                  headersButtons={false}
                  // pagePagination={pagePagination}
                  // setPagePagination={setPagePagination}
                  paginationServer={false}
                  // hideFilterBar
                  hideBtnEdit
                  hideDetails
                  actionBtnErase={handleDeleteItem}
                  searchByProperty="SKU"
                  // customFilterButtonInput={
                  //   <>
                  //     <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                  //       {/* <InputController
                  //         name="id_warehouse"
                  //         isMulti
                  //         control={control}
                  //         inputType="choosen"
                  //         options={itemGroups}
                  //         placeHolderChoosen="Seleccione uno o varios grupos"
                  //         changeAction={(e) => handleFilterChange(e)}
                  //       /> */}
                  //       <div>
                  //         <OrderCol
                  //           mediumColSize="12"
                  //           content={
                  //             <>
                  //               <label>Seleccionados: {countSelected}</label>
                  //             </>
                  //           }
                  //         />
                  //       </div>
                  //     </div>
                  //   </>
                  // }
                  // selectableRows
                  // selectableRowsClick={(e) => handleSelectedRows(e)}
                  // clearSelectedRows={clearSelectedRows}
                  headerButtonsNoPermissions={
                    <>
                      <button
                        onClick={() => exportPDFWithDifferentPrice()}
                        type="button"
                        className="btn btn-accept btn-sm"
                      >
                        Imprimir etiquetas con precio diferente
                      </button>
                      <button
                        onClick={() => handleCleanLists()}
                        type="button"
                        className="btn btn-danger btn-sm"
                      >
                        Limpiar lista
                      </button>
                    </>
                  }
                />
              </div>
            </div>
          </>
        }
      />
      {barcodeValue && (
        <img className="invisible" id="barcode" ref={inputRef} />
      )}
      <div className="hide">
        <PrintLabels
          componentRef={componentRef}
          labelsInformation={labelsInformation}
          printingLabels={printingLabels}
          setPrintingLabels={setPrintingLabels}
          setIsPrinting={setIsPrinting}
          tagsForFridge={typeLabel === 'fridge' ? true : false}
          pageBreakQuantity={typeLabel === '20tags' ? 20 : 24}
        />
        <ReactToPrint
          trigger={() => <button id="printPageLabels"></button>}
          content={() => componentRef.current}
          documentTitle={`Etiquetas-${
            typeLabel === '20tags'
              ? '20 etiquetas'
              : typeLabel === '24tags'
              ? '24 etiquetas'
              : typeLabel
              ? 'refrigerador'
              : ''
          } ${moment().format('DD-MM-YYYY HH:mm:ss')}`}
          copyStyles={false}
          // onAfterPrint={() => {
          //   setTimeout(async () => {
          //     return new Promise((resolve) => {
          //       resolve()
          //     }).then(() => {
          //       setIsPrinting(false)
          //     })
          //   }, 1000)
          // }}
          pageStyle={
            typeLabel === '20tags'
              ? STYLES_TAGS_20
              : typeLabel === '24tags'
              ? STYLES_TAGS_24
              : typeLabel === 'fridge'
              ? FRIDGE_STYLES_TAGS
              : null
          }
          // bodyClass="page-print-labels"
        />
      </div>
      <span
        id="spanModalQuantity"
        ref={refModalQuantity}
        className="invsible"
        data-toggle="modal"
        data-target="#modalQuantity"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      />
      <ModalContent
        idModal="modalQuantity"
        title="Cantidad de etiquetas"
        onClose={() => setCloseModal(true)}
        size="large"
        content={
          <ModalQuantityLabels
            refModalQuantity={refModalQuantity}
            labelsInformation={labelsInformation}
            setLabelsInformation={setLabelsInformation}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
            setPrintLabels={setPrintLabels}
            setPrintingLabels={setPrintingLabels}
            setPrintLabelsWithDifferentPrice={setPrintLabelsWithDifferentPrice}
          />
        }
      />
    </>
  )
}

export default PriceTags
