import { gql } from '@apollo/client'

export const FIND_ITEM_BARCODES = gql`
  mutation FindSapItemBarcodes($query: String!) {
    findSapItemBarcodes(query: $query) {
      id
      barcode
      barcode_name
      item_code
      SapBarcodeSapItem {
        item_code
        item_name
      }
    }
  }
`
export const FIND_ITEM_BY_BARCODE = gql`
  mutation GetSapItemByCodeBar($codebar: String!) {
    getSapItemByCodeBar(codebar: $codebar) {
      id
      barcode
      item_code
      item_name
    }
  }
`
