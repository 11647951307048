import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  DELETE_VEHICLE,
  GET_VEHICLES,
  EXPORT_VEHICLES,
} from '../../../../graphql/Catalog/Vehicles/vehicles'
import LayoutTable from '../../../Global/LayoutTable'
import ContentHeader from '../../../Layout/ContentHeader'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'

export const Vehicles = () => {
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const [total, setTotal] = useState(0)
  const { loading, error, data } = useQuery(GET_VEHICLES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
  })
  const [dataToTable, setDataToTable] = useState([])

  useEffect(() => {
    if (!loading) {
      if (error)
        toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getAllVehicles.rows.map((item) => {
        return list.push({
          id: item.id,
          Marca: item.brand,
          Modelo: item.model,
          Placas: item.plates_number,
          'No. Motor': item.motor_number,
        })
      })
      setDataToTable(list)
      setTotal(data.getAllVehicles.count)
    }
  }, [loading, error, data])

  return (
    <>
      {/* <ContentHeader
        title="Vehículos"
        breadcrumb="Vehículos"
        windowTitle="Vehículos"
      /> */}
      <LayoutTable
        data={dataToTable}
        title="Vehículos"
        gql={DELETE_VEHICLE}
        requery={GET_VEHICLES}
        exportQuery={EXPORT_VEHICLES}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
      />
    </>
  )
}

export default Vehicles
