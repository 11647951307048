import { gql } from '@apollo/client'

export const GET_INVENTORY_ACTIVITY_COUNT_ASSIGNMENT_BY_USER = gql`
  query GetInventoryActivityCountByUser {
    getInventoryActivityCountByUser {
      scheduleInfo {
        id
        InventoryActivitiesWarehouseSchedule {
          warehouse_name
        }
        ubications_or_skus
        type_schedule
      }
      nextElement {
        skuOrRackId
        labelId
        countNumber
        elementAssigned
        hasHarvest
        expires
        itemName
      }
      pendingElements
    }
  }
`

export const INVENTORY_ACTIVITY_COUNTINGS_ASSIGNMENT = gql`
  query InventoryActivityCountingsAssignment(
    $limit: Int
    $offset: Int
    $searchQuery: String
    $labelId: Int!
    $scheduleId: Int!
    $countNumber: Int!
    $isCycleCountSKUS: String
  ) {
    InventoryActivityCountingsAssignment(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      labelId: $labelId
      scheduleId: $scheduleId
      countNumber: $countNumber
      isCycleCountSKUS: $isCycleCountSKUS
    ) {
      count
      rows {
        id
        inventory_activity_label_id
        vintage
        element_found
        barcode
        expiration
        quantity
        count_number
        InventoryActivitiesCountsSapItems {
          item_code
          item_name
        }
        InventoryActivitiesCountsRacks {
          code
          name
          id
        }
      }
    }
  }
`

export const INVENTORY_ACTIVITY_VALIDATE_START_ASSIGNMENT = gql`
  mutation InventoryActivityValidateStartAssignment(
    $scheduleId: Int!
    $scanElement: String!
  ) {
    InventoryActivityValidateStartAssignment(
      scheduleId: $scheduleId
      scanElement: $scanElement
    )
  }
`

export const FIND_INVENTORY_ACTIVITY_PRODUCTS_BY_BARCODE_OR_ITEM_CODE = gql`
  mutation FindInventoryActivityProductByBarcodes(
    $query: String!
    $isCycleCountSKUSItemCode: String
  ) {
    findInventoryActivityProductByBarcodes(
      query: $query
      isCycleCountSKUSItemCode: $isCycleCountSKUSItemCode
    ) {
      hasHarvest
      id
      item_code
      item_code_bar
      item_name
      expires
    }
  }
`

export const FIND_INVENTORY_ACTIVITY_RACK = gql`
  mutation FindInventoryActivityRack($query: String!) {
    findInventoryActivityRack(query: $query) {
      name
      id
    }
  }
`

export const CREATE_INVENTORY_ACTIVITY_COUNT = gql`
  mutation CreateInventoryActivityCount(
    $input: InventoryActivityCountInput!
    $skuConfigurationInput: SkuConfigurationInput!
    $scheduleId: Int!
  ) {
    createInventoryActivityCount(
      input: $input
      skuConfigurationInput: $skuConfigurationInput
      scheduleId: $scheduleId
    )
  }
`

export const DELETE_INVENTORY_ACTIVITY_COUNT = gql`
  mutation DeleteInventoryActivityCount($id: Int!, $scheduleId: Int!) {
    deleteInventoryActivityCount(id: $id, scheduleId: $scheduleId)
  }
`

export const END_INVENTORY_ACTIVITY_COUNT = gql`
  mutation EndInventoryActivityCount(
    $labelId: Int!
    $scheduleId: Int!
    $isCycleCountSKUS: String
  ) {
    endInventoryActivityCount(
      labelId: $labelId
      scheduleId: $scheduleId
      isCycleCountSKUS: $isCycleCountSKUS
    )
  }
`
export const LAUNCH_INVENTORY_ACTIVITY_CYCLE_COUNT_SKUS = gql`
  mutation LaunchInventoryActivityCycleCountSkus(
    $scheduleId: Int!
    $userIdCounters: [Int!]!
  ) {
    launchInventoryActivityCycleCountSkus(
      scheduleId: $scheduleId
      userIdCounters: $userIdCounters
    )
  }
`

// //////////////////////////////////

export const GET_COUNTS_DEVIATIONS = gql`
  query GetAllTfiCountDeviations(
    $scheduleId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
    $status: Int!
    $onlyDeviation: Int
  ) {
    getAllTfiCountDeviations(
      scheduleId: $scheduleId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      status: $status
      onlyDeviation: $onlyDeviation
    ) {
      count
      rows {
        count_id
        label_id
        item_code
        item_name
        rack_id
        rack_name
        count_number
        quantity_counted
        user
        closed
      }
    }
  }
`
/** POSIBLEMENTE NO SE VA A USAR */
export const GET_LABEL_COUNTS = gql`
  query GetAllLabelCounts(
    $labelId: Int!
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllLabelCounts(
      labelId: $labelId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        assignment_id
        rack_id
        count_number
        completed
        racksTFILabelsAccountant {
          id
          user_id
          full_name
        }
        TFILabelCounts {
          label_id
          item_code
          quantity_counted
          closed
          count_number
          TfiCountSapItem {
            item_name
          }
        }
        racksTFILabels {
          code
          name
        }
      }
      count
    }
  }
`

//DELETE BUT FIRST CHECK CYCLECOUNTREQUEST
export const GET_ITEM_MASTER_DATA = gql`
  query GetOneSapItem($itemCode: String!) {
    getOneSapItem(item_code: $itemCode) {
      id
      item_code
      item_name
    }
  }
`

export const ASSIGN_THIRD_COUNT = gql`
  mutation assignThirdCount(
    $idRacks: [Int!]!
    $idUser: Int!
    $scheduleId: Int!
  ) {
    assignThirdCount(
      idRacks: $idRacks
      idUser: $idUser
      scheduleId: $scheduleId
    )
  }
`
