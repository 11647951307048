import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  GET_ALL_ROUTES,
  GET_ALL_SHIPMENTS_ROUTES,
  GET_ONE_SHIPMENT_ROUTE,
  GET_SHIPMENT_REPORT,
  UPDATE_VEHICLE_VERIFICATION,
} from '../../../graphql/Catalog/Routes/routes'
import {
  ADD_ROUTE_ADDRESS,
  CLEAN_ROUTE_ADDRESSES,
  CREATE_DETAIL,
  DISPATCH_ROUTE,
  GET_OPTIMIZED_ROUTE,
  GET_ROUTE_ASSIGNATION,
  GET_SHIPPINGS_INFO,
  REMOVE_ROUTE_ADDRESS,
} from '../../../graphql/Catalog/Routes/routesAssignation'
import { GET_TRANSFER_REQUEST } from '../../../graphql/Catalog/Transfers/Transfers'
import { GET_ALL_USERS_OPERATORS_APP } from '../../../graphql/Catalog/UsersOperatorsApp/usersOperatosApp'
import { GET_VEHICLES } from '../../../graphql/Catalog/Vehicles/vehicles'
import Box from '../../Global/Box'
import MenuPagination from '../../Global/MenuPagination'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import {
  addOrder,
  closeRoute,
  deleteOrder,
  dispatchOrder,
  filterOnChange,
  getOrdersInfo,
  getRouteDetails,
} from '../../Helpers/functionsRoutes'
import RouteDetails from './RouteDetails'
import RoutesPendingOrders from './RoutesPendingOrders'
import Swal from 'sweetalert2'
import moment from 'moment'
import LayoutTable from '../../Global/LayoutTable'
import ControlDeskRoutesDetails from './Details/ControlDeskRoutesDetails'
import ControlDeskRoutesPackingListDetails from './Details/ControlDeskRoutesPackingListDetails'
import ShipmentsRoutesPointsModalAssignation from './ShipmentsRoutesPointsModalAssignation'
import HelperGenerateExcel from '../../../helpers/HelperGenerateExcel'
import RoteShipmentCrationModal from './create/RouteShipmentCreationModal'
import UpdateVehicleAssignation from './Details/UpdateVehicleAssignation'

export const ControlDeskRoutes = () => {
  const history = useHistory()
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [disabledHeaderButton, setDisabledHeaderButton] = useState(false)
  const [vehicleVerification, setVehicleVerification] = useState(false)
  const [routeId, setRouteId] = useState(null)
  const [shipmentRoute, setShipmentRoute] = useState([])
  const [shipmentRoutePackingListDetails, setShipmentRoutePackingListDetails] =
    useState([])
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [editId, setEditId] = useState(null);
  const [shipmentRouteTotal, setShipmentRouteTotal] = useState(0)
  const [routePointId, setRoutePointId] = useState(0)
  const [downloadReport, setDownloadReport] = useState([])
  const refModal = useRef()
  const refModalShipmentRoute = useRef()
  const refVehicleModal = useRef()

  //GET ALL ROUTES
  const [routes, setRoutes] = useState([])
  const [totalRoutes, setTotalRoutes] = useState(0)

  const { loading, error, data } = useQuery(GET_ALL_SHIPMENTS_ROUTES, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
  })

  //GET SHIMPENT ROUTE DETAILS
  const [
    getOneShimpentRoute,
    {
      data: dataShipmentRouteId,
      loading: loadingShipmentRouteId,
      error: errorShipmentRouteId,
      refetch: shipmentDetailsRefetch
    },
  ] = useLazyQuery(GET_ONE_SHIPMENT_ROUTE, {
    fetchPolicy: 'no-cache',
  })

  const [updateVehicleVerification] = useMutation(UPDATE_VEHICLE_VERIFICATION)

  //GET REPORT
  const [getReport] = useMutation(GET_SHIPMENT_REPORT)

  //USE EFFECT ROUTES
  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      const list = data?.getAllShipmentsRoutes?.rows.map((item) => {
        let count = 0

        if (!item?.subRoutes.length == 0) {
          count = item?.subRoutes.length
        }

        return {
          id: item?.id,
          main: item?.name,
          count:
            item?.subRoutes && item?.subRoutes.length > 0
              ? item?.subRoutes.length
              : 0,
          subRoutes: item?.subRoutes,
        }
      })

      setRoutes(list)
      setTotalRoutes(data?.getAllShipmentsRoutes?.count)
    }
  }, [loading, error, data])

  useEffect(() => {
    if (!loadingShipmentRouteId && routeId) {
      if (errorShipmentRouteId)
        return toastSweetAlert({
          mode: 'error',
          message: errorShipmentRouteId.message,
        })
      // console.log(dataShipmentRouteId, "detalles de ruta")
      const list = dataShipmentRouteId?.getOneShimpentRoute?.rows.map(
        (item) => {
          setVehicleDetails({
            vehicle: item?.vehicle || "",
            operator: item?.operator_name || ""
          })
          return {
            id: item?.id,
            doc_entry: item?.doc_entry,
            doc_num: item?.doc_num,
            origen: item?.warehouse_origin_name?.warehouse_name,
            destino: item?.warehouse_destiny_name?.warehouse_name,
            operador: item?.operator_name,
            route_id_omitField: item?.route_id
          }
        }
      )
      setShipmentRoutePackingListDetails([])
      setShipmentRoute(list)
      setShipmentRouteTotal(dataShipmentRouteId?.getOneShimpentRoute?.count)
      // setRoutes(list)
      // setTotalRoutes(data?.getAllShipmentsRoutes?.count)
    }
    setRoutePointId(0)
  }, [
    dataShipmentRouteId,
    loadingShipmentRouteId,
    errorShipmentRouteId,
    routeId,
  ])

  const handleChangeRoute = async (row) => {
    try {
      setRouteId(row)
      setShipmentRoute([])
      setVehicleDetails(null)

      await getOneShimpentRoute({
        variables: {
          getOneShimpentRouteId: +row,
        },
      })
      const { data } = await updateVehicleVerification({
        variables: {
          routeId: +row
        }
      })
      if (data) {
        setVehicleVerification(data?.updateVehicleVerification)

      }

    } catch (error) {
      return toastSweetAlert({ mode: 'error', message: error.message })
    }
  }

  const handleClickDetails = (id) => {
    // console.log(id, 'DETALLES ROUTES ID')

    setRoutePointId(+id) // este id
  }
  const handleVehicleAssignation = () => {
    refVehicleModal.current.click()
  }

  const handleHeaderButton = async () => {
    setDisabledHeaderButton(true)
    setLoadingBtn(true)
    toastSweetAlert({
      mode: 'loading',
      message: 'Procesando reporte, espere por favor.',
    })
    try {
      const { data } = await getReport({
        variables: {
          getRouteShipmentReportId: 8,
          // getRouteShipmentReportId: +routeId,
        },
      })
      // console.log(data, 'reporte')
      const list = []
      data?.getRouteShipmentReport?.map((shipment) => {
        const boxes = shipment?.RouteAppShipmentsToRoutePakingBoxLoadedModel
        const operatorInfo = shipment?.userToRouteAppShipments
        const routeInfo = shipment?.routesToRouteAppShipments

        boxes.map((item) => {
          const box =
            item?.PackingListBoxesToRoutePakingBoxLoaded
              ?.RoutePakingBoxLoadedToPackingListBoxLine
          list.push({
            operador: `${operatorInfo?.name} ${operatorInfo?.first_name} ${operatorInfo?.last_name}`,
            ['nombre de ruta']: shipment?.name,
            'fecha de partida': moment(+shipment?.createdAt).format(
              'DD/MM/YYYY  HH:MM:SS'
            ),
            'ruta principal': routeInfo?.name,
            'fecha de entrega': moment(+shipment?.updatedAt).format(
              'DD/MM/YYYY  HH:MM:SS'
            ),
            box_id: box?.box_id,
            sku: box?.item_code,
            'nombre del producto': box?.SapItem?.item_name,
            cantidad: box?.quantity,
          })
          return null
        })
      })

      setDownloadReport(list)
      document.getElementById('ExportExcel').click()
      setDisabledHeaderButton(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Reporte generado correctamente',
        },
        setLoadingBtn(false)
      )
    } catch (error) {
      setLoadingBtn(false)
      setDisabledHeaderButton(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleRouteShipmentButton = (id) => {
    if (id) {
      setEditId(id)
      refModalShipmentRoute.current.click()

    } else {
      setEditId(null)
      refModalShipmentRoute.current.click()

    }
  }
  return (
    <>
      {/* <ContentHeader
        title="Mesa de control de rutas"
        breadcrumb="Mesa control rutas"
        windowTitle="Mesa control rutas"
      /> */}
      <div className={`${loadingBtn ? 'processing-file' : ''}`}>
        <HelperGenerateExcel
          id="ExportExcel"
          excelData={[
            {
              bookName: 'Reporte de Embarques',
              data: downloadReport,
              columnsWidth: [25, 25, 25, 25, 25, 25, 25, 25, 25],
            },
          ]}
          fileName="Reporte de embarques"
        />
        <Box
          title="Mesa de control de rutas"
          // headerBtn={true}
          // headerBtnText="Descargar reporte"
          // headerBtnHandler={handleHeaderButton}
          // headerBtnDisabled={disabledHeaderButton}
          whiteHeader={false}
          content={
            <div className="container-desk-routes">
              <MenuPagination
                title="Rutas activas"
                data={routes}
                total={totalRoutes}
                handleClick={handleChangeRoute}
                pagePagination={pagePagination}
                setPagePagination={setPagePagination}
                dropdownMenu={true}
                // handleButton={() => history.push('routes/new')}
                handleButtonText="Crear ruta"
                handleButton={() => handleRouteShipmentButton(null)}
                hide
              />

              <div className="right-container-routes">
                <div className="details-routes-container ">
                  <Box
                    headerBtn={routeId > 0 ? true : false}
                    headerBtnText="editar ruta"
                    headerBtnHandler={() => handleRouteShipmentButton(routeId)}
                    title={`Detalles de ruta`}
                    // headerBtn={true}
                    // headerBtnText="Asignar vehículo"
                    // headerBtnHandler={handleVehicleAssignation}
                    content={
                      <>
                        {shipmentRoute && (
                          <>
                            {vehicleDetails !== null && (
                              <div className="row d-flex justify-content-around">
                                <h5 className='fw-bold'>Unidad: {vehicleDetails?.vehicle}</h5>
                                <h5 className='fw-bold'>Operador: {vehicleDetails?.operator}</h5>
                              </div>
                            )}


                            <ControlDeskRoutesDetails
                              data={shipmentRoute}
                              handleClickDetails={handleClickDetails}
                              total={shipmentRouteTotal}
                              loading={loadingShipmentRouteId}
                            />
                          </>
                        )}
                      </>
                    }
                  />
                </div>
                <div className="routes-shipping">
                  <ControlDeskRoutesPackingListDetails
                    routeId={routePointId}
                    dataToTable={shipmentRoutePackingListDetails}
                    setdataToTable={setShipmentRoutePackingListDetails}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>

      <ShipmentsRoutesPointsModalAssignation
        refModal={refModal}
        routeId={routeId}
      />

      <RoteShipmentCrationModal
        refModal={refModalShipmentRoute}
        editId={editId}
        setEditId={setEditId}
        refetch={shipmentDetailsRefetch}
      />
      <UpdateVehicleAssignation
        refModal={refVehicleModal}
        routeId={routeId}
      />

    </>
  )
}

export default ControlDeskRoutes
