import React, { useRef } from 'react'
import _logo from '../../../../../../Assets/Images/vinos-america-logo.png'
import { NumerosALetras } from 'numero-a-letras'

const PrintCheckCoverages = React.forwardRef(
  ({ componentRef, infoCoverage }) => {
    // console.log('Por posicion', infoCoverage[0])
    const convertTotalToLetters = NumerosALetras(
      parseFloat(infoCoverage[0]?.total)
    )
    // console.log('InfoCoverage', infoCoverage)
    const newLogo = _logo.split(',')

    const formmatterCurrency = (number) => {
      let numberFormat = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 4,
      }).format(number)

      return numberFormat
    }

    const rectangleCoverages = {
      width: '100%',
      height: '100%',
      border: '3px',
      marginTop: '70px',
    }

    // const marginTop = {
    //   marginTop: '50px',
    // }

    const rectangleDisplayInfoCoverages = {
      width: '95%',
      height: '500px',
      border: '3px solid #555',
      margin: '100px 50px 100px',
      marginTop: '30px',
      marginBottom: '0px',
      marginTop: '0px',
      borderRadius: '40px',
    }

    const wrapShopRectangleAndDateCoverages = {
      width: '90%',
      height: '12%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      // alignItems: 'center',
      // border: '3px solid #555',
      marginTop: '40px',
      marginLeft: '50px',
      marginBottom: '0px',
    }

    const rectangleDisplayInfoInsideShopCoverages = {
      width: '70%',
      height: '100%',
      // border: '3px solid #555',
      marginRight: '100px',
    }

    const rectangleDisplayInfoInsideDateCoverages = {
      width: '20%',
      height: '100%',
      // border: '3px solid #555',
      // margin: '-60px 1100px',
      // marginBottom: '259px',
      // marginTop: '0px',
      textAlign: 'center',
      // marginRight: '0px',
      // verticalAlign: 'middle',
    }

    const wrapPayAndNumberToLettersCoverages = {
      width: '90%',
      height: '12%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      // alignItems: 'center',
      // border: '3px solid #555',
      marginTop: '50px',
      marginLeft: '50px',
      marginBottom: '60px',
    }

    const rectangleDisplayInfoInsideToPartnerCoverages = {
      width: '70%',
      height: '100%',
      // border: '3px solid #555',
      // margin: '30px 50px',
      // marginBottom: '0px',
      marginRight: '100px',
      textAlign: 'center',
      // verticalAlign: 'middle',
    }

    const rectangleDisplayInfoInsideToPayCoverages = {
      width: '20%',
      height: '100%',
      // border: '3px solid #555',
      // margin: '70px 50px',
      // marginRight: '80px',
      // marginBottom: '0px',
      textAlign: 'center',
      marginBottom: '0px',
      // verticalAlign: 'middle',
    }

    const rectangleDisplayInfoInsideNumberToLettersCoverages = {
      width: '70%',
      height: '12%',
      // border: '3px solid #555',
      // margin: '-140px 400px',
      // marginBottom: '0px',
      // marginTop: '0px',
      marginBottom: '71px',
      marginLeft: '150px',
      textAlign: 'center',
      // verticalAlign: 'middle',
    }

    const rectangleDisplayInfoInsideCheckCoverages = {
      width: '30%',
      height: '20%',
      // border: '3px solid #555',
      margin: '20px 1000px',
      textAlign: 'center',
      // verticalAlign: 'middle',
    }

    const textBottom = {
      verticalAlign: 'bottom',
    }

    const wrapConceptandSingInfoCoverages = {
      width: '100%',
      height: '200px',
      // border: '5px solid red',
      display: 'flex',
      flexDirection: 'row',
      // alignItems: 'flex-end',
      whiteSpace: 'normal !important',
    }

    const rectangleConceptInfoCoverages = {
      width: '60%',
      height: '200px',
      border: '3px solid #555',
      margin: '100px 0px',
      marginLeft: '50px',
      marginRight: '5px',
      marginTop: '0px',
      marginBottom: '0px',
      borderRadius: '18px',
    }
    const rectangleSignInfoCoverages = {
      width: '40%',
      height: '200px',
      border: '3px solid #555',
      margin: '100px 0px',
      marginLeft: '5px',
      marginRight: '50px',
      marginTop: '0px',
      marginBottom: '0px',
      borderRadius: '18px',
    }

    const textNamePersonCoverages = {
      width: '60%',
      height: '30px',
      border: '3px',
      margin: '2px 50px',
      borderRadius: '18px',
      fontSize: '1.3em',
      fontFamily: 'sans-serif',
    }

    //TABLE

    const wrapTableCoverates = {
      width: '100%',
      height: '1150px',
      marginBottom: '10px',
    }

    const tableCoverages = {
      width: '95%',
      // margin: '100px 50px',
      // marginTop: '0px',
      // marginBottom: '0px',
      marginLeft: '50px',
      marginRight: '50px',
      fontSize: '1.5em',
      fontFamily: 'sans-serif',
      border: '1px solid #000',
      borderSpacing: '2px',
      borderCollapse: 'collapse',
      // minWidth: '450px',
    }

    const tableRowsHeadersCoverages = {
      // backgroundColor: '#980081',
      // color: '#ffffff',
      border: '1px solid #000',
      textAlign: 'center',
    }

    const tableHeadersCoverages = {
      border: '1px solid #000',
      padding: '12px 15px',
    }

    const logoVACoverages = {
      //border: '3px solid #555',
      margin: '100px 50px',
      marginTop: '0px',
      marginBottom: '0px',
    }

    //BALANCE RESULT

    const wrapBalanceCoverages = {
      width: '100%',
      height: '100px',
      //border: '5px solid red',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginLeft: '862px',
      //whiteSpace: 'normal !important',
    }

    const rectangleRightBalanceCoverages = {
      width: '15.1%',
      height: '40px',
      margin: '10px 0px',
      border: '1px solid #555',
      marginRight: '-10px',
    }
    const rectangleLeftBalanceCoverages = {
      width: '15.1%',
      height: '40px',
      margin: '10px 0px',
      marginLeft: '40px',
      border: '1px solid #555',
    }

    const rectanglesTextBalanceCovergaes = {
      width: '10%',
      margin: '15px 10px',
      marginRight: '0px',
    }

    //FOOTER

    const wrapFooterCoverages = {
      width: '100%',
      height: '100px',
      // border: '5px solid red',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      //whiteSpace: 'normal !important',
    }

    const rectangleAsocCoverages = {
      border: '1px solid #555',
      margin: '-11px 47px',
      marginRight: '0px',
      width: '10%',
      height: '100px',
      textAlign: 'center',
    }

    const rectanglePayCoverages = {
      border: '1px solid #555',
      margin: '-11px 47px',
      marginLeft: '0px',
      marginRight: '0px',
      width: '10%',
      height: '100px',
      textAlign: 'center',
    }

    const rectangleCompleteCoverages = {
      border: '1px solid #555',
      margin: '-11px 47px',
      marginLeft: '0px',
      marginRight: '0px',
      width: '37%',
      height: '100px',
      textAlign: 'center',
    }

    const rectangleTableCoverages = {
      border: '1px solid #555',
      margin: '-11px 47px',
      marginLeft: '0px',
      width: '42%',
      height: '100px',
      textAlign: 'center',
    }

    const wrapTableFooterCoverages = {
      width: '100%',
      height: '100px',
      // marginBottom: '25px',
    }

    const tableFooterCoverages = {
      width: '100%',
      // margin: '100px 50px',
      // marginTop: '0px',
      // marginBottom: '0px',
      marginLeft: '50px',
      marginRight: '50px',
      fontSize: '0.9em',
      fontFamily: 'sans-serif',
      border: '1px solid #000',
      borderSpacing: '2px',
      borderCollapse: 'collapse',
      // minWidth: '450px',
    }

    return (
      <div ref={componentRef}>
        {/* <div style={marginTop}></div> */}
        <div style={rectangleCoverages}>
          <div style={rectangleDisplayInfoCoverages}>
            <div style={wrapShopRectangleAndDateCoverages}>
              <div style={rectangleDisplayInfoInsideShopCoverages}>
                <h1>{`CHEQUE POLIZA TDA ${infoCoverage[0]?.store}`}</h1>
              </div>
              <div style={rectangleDisplayInfoInsideDateCoverages}>
                <h2>{`${infoCoverage[0]?.dateCheck}`}</h2>
              </div>
            </div>

            <div style={wrapPayAndNumberToLettersCoverages}>
              <div style={rectangleDisplayInfoInsideToPartnerCoverages}>
                <h2>{`${infoCoverage[0]?.provider}`}</h2>
              </div>
              <div style={rectangleDisplayInfoInsideToPayCoverages}>
                <h2>{`${formmatterCurrency(infoCoverage[0]?.total)}`}</h2>
              </div>
            </div>

            <div style={rectangleDisplayInfoInsideNumberToLettersCoverages}>
              <h2>{`(${convertTotalToLetters})`}</h2>
            </div>

            <div style={rectangleDisplayInfoInsideCheckCoverages}>
              <br />
              <h2 style={textBottom}>{`CH-${infoCoverage[0]?.checkNumber}`}</h2>
            </div>
          </div>
        </div>

        <div style={logoVACoverages}>
          <img src={newLogo} alt="logo" />
        </div>

        <div style={wrapConceptandSingInfoCoverages}>
          <div style={rectangleConceptInfoCoverages}>
            <h3>
              <strong>CONCEPTO DEL CHEQUE</strong>
            </h3>
          </div>
          <div style={rectangleSignInfoCoverages}>
            <h3>
              <strong>&nbsp;FIRMA DEL CHEQUE RECIBIDO</strong>
            </h3>
          </div>
        </div>

        <div style={textNamePersonCoverages}>
          <strong>NOMBRE DE QUIEN RECIBE EL CHEQUE</strong>
          <br />
          <h3></h3>
        </div>

        <div style={wrapTableCoverates}>
          <table
            // className="table table-bordered nowrap"
            style={tableCoverages}
            border="5px"
          >
            <thead style={tableHeadersCoverages}>
              <tr style={tableRowsHeadersCoverages}>
                <th style={{ width: '90px' }}>CUENTA</th>
                {/* <th style={{ width: '90px' }}>SUB-CUENTA</th> */}
                <th style={{ height: '60px', width: '400px' }}>NOMBRE</th>
                <th style={{ width: '150px' }}>PARCIAL</th>
                <th style={{ width: '150px' }}>DEBE</th>
                <th style={{ width: '150px' }}>HABER</th>
              </tr>
            </thead>
            <tbody>
              <tr style={tableRowsHeadersCoverages}>
                <td style={{ height: '1150px' }}></td>
                {/* <td></td> */}
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={wrapBalanceCoverages}>
          <div style={rectanglesTextBalanceCovergaes}>SUMAS IGUALES</div>
          <div style={rectangleLeftBalanceCoverages}></div>
          <div style={rectangleRightBalanceCoverages}></div>
        </div>

        <div style={wrapFooterCoverages}>
          <div style={rectangleAsocCoverages}>ASOC</div>
          <div style={rectanglePayCoverages}>
            PAGO
            <br />
            <br />
            {/* <h4>{`${formmatterCurrency(infoCoverage[0]?.total)}`}</h4> */}
          </div>
          <div style={rectangleCompleteCoverages}>
            COMPLETO
            <br />
            <br />
            {/* <h4>{`${convertTotalToLetters}`}</h4> */}
          </div>

          <div style={rectangleTableCoverages}>
            <table style={wrapTableFooterCoverages} border="1">
              <thead style={tableFooterCoverages}>
                <tr style={tableRowsHeadersCoverages}>
                  <th style={{ width: '400px' }}>
                    FOLIO PAGO CONTROL DE PAGOS
                  </th>
                  <th style={{ width: '450px' }}>Folio Sap</th>
                  <th style={{ width: '350px' }}>Poliza NO.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h3>{`${infoCoverage[0]?.invoicePay}`}</h3>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
)

export default PrintCheckCoverages
