import { Link } from 'react-router-dom'
export const CardButton = ({title, icon, link}) => {
    
  return (
    <>
      <div className="d-inline-flex p-2">
        <div className="row">
          <div className="col">
            <Link to={link}>
            <div className="card" style={{width: '400px', height: '120px'}}>

                <div className="card-body d-flex 
                align-items-center"
                >
                  <div className="">
                    <h3><i className={`${icon}`}></i> &nbsp;{title}</h3>
                  </div>
                  <div className="ml-auto">
                   <h3><i className="fas fa-chevron-circle-right"></i></h3>
                  </div>

                </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )

}


export default CardButton