import { gql } from '@apollo/client'

export const GET_ROUTES_ASSIGNATIONS = gql`
  query GetAllRoutesAssignations($searchQuery: String) {
    getAllRoutesAssignations(searchQuery: $searchQuery) {
      rows {
        name
        id
        is_open
        operator_id
        vehicle_id
        start_date
        start_time
        Vehicle {
          brand
        }
        Operator {
          name
          first_name
          last_name
        }
      }
      count
    }
  }
`

export const GET_SHIPPINGS_INFO = gql`
  query GetShippingInfoOrders($searchQuery: String, $limit: Int, $offset: Int) {
    getShippingInfoOrders(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        platform_id
        platform {
          name
          id
        }
        type {
          name
        }
        payment {
          platform
          payment_id
        }
        store {
          name
        }
        warehouse {
          name
        }
        shipping {
          id
          order_id
          first_name
          last_name
          address_1
          address_2
          city
          state
          postcode
          country
        }
        order_id
        order_date
        object_type
        object_type_name
      }
      count
    }
  }
`

export const GET_ROUTE_ASSIGNATION = gql`
  query GetOneRouteAssignation(
    $getOneRouteAssignationId: Int!
    $timeBlock: Int!
  ) {
    getOneRouteAssignation(
      id: $getOneRouteAssignationId
      timeBlock: $timeBlock
    ) {
      id
      name
      description
      RoutesDetails {
        final_staging
        operator_id
        vehicle_id
        start_date
        time_block_id
        route_type
        is_optimized
        is_open
        RouteVehicle {
          id
          brand
        }
        RouteOperator {
          id
          name
          first_name
          last_name
        }
        RouteTimeBlock {
          id
          time
        }
        RouteAddresses {
          id
          route_id
          order_id
          shipping_order_id
          ShippingInfo {
            id
            first_name
            last_name
            order_id
            address_1
            city
            state
            postcode
            latitude
            longitude
          }
          InventoryTransferRequest {
            id
            doc_date
            status
            doc_entry
            warehouse_origin_name {
              id
              warehouse_name
            }
            warehouse_destiny_name {
              id
              warehouse_name
            }
            stage_status
          }
          orders_shipping {
            id
            first_name
            last_name
            order_id
            address_1
            state
            postcode
            country
            latitude
            longitude
            city
          }
        }
      }
    }
  }
`

export const GET_ORDERS_WITHOUT_ASSIGNING_ROUTE = gql`
  query getAllPendingAssignationOrders($searchQuery: String) {
    getAllPendingAssignationOrders(searchQuery: $searchQuery) {
      rows {
        platform {
          name
        }
        order_id
        order_date
        status {
          name
        }
        shipping {
          first_name
          last_name
          address_1
          address_2
          city
          state
          postcode
          country
        }
      }
      count
    }
  }
`

export const EXPORT_ROUTES_ASSIGNATIONS = gql`
  mutation GetAllRoutesAssignationsExport {
    getAllRoutesAssignationsExport {
      id
      name
      operator_id
      vehicle_id
    }
  }
`

export const UPDATE_ROUTE_ASSIGNATION = gql`
  mutation UpdateRouteAssignation(
    $routeId: Int!
    $input: RouteAssignationInput!
  ) {
    updateRouteAssignation(routeId: $routeId, input: $input)
  }
`

export const DELETE_ROUTE_ASSIGNATION = gql`
  mutation DeleteRouteAssignation($id: Int!) {
    deleteRouteAssignation(id: $id)
  }
`

// export const ASSIGN_OPERATOR = gql`
//   mutation AssignOperator($routeId: Int!, $operatorId: Int!) {
//     assignOperator(routeId: $routeId, operatorId: $operatorId)
//   }
// `

// export const ASSIGN_VEHICLE = gql`
//   mutation AssignVehicle($routeId: Int!, $vehicleId: Int!) {
//     assignVehicle(routeId: $routeId, vehicleId: $vehicleId)
//   }
// `

export const GET_OPTIMIZED_ROUTE = gql`
  mutation RouteOptimization($idRoute: Int!, $timeBlock: Int!) {
    routeOptimization(idRoute: $idRoute, timeBlock: $timeBlock) {
      waypoints {
        id
        lat
        lng
        sequence
        estimatedArrival
        estimatedDeparture
      }
      distance
      time
      description
      interconnections {
        fromWaypoint
        toWaypoint
        distance
        time
        rest
        waiting
      }
    }
  }
`

export const REMOVE_ROUTE_ADDRESS = gql`
  mutation RemoveRouteAddress(
    $idRoute: Int!
    $idRouteAddress: Int!
    $timeBlock: Int!
  ) {
    removeRouteAddress(
      idRoute: $idRoute
      idRouteAddress: $idRouteAddress
      timeBlock: $timeBlock
    )
  }
`

export const ADD_ROUTE_ADDRESS = gql`
  mutation AddRouteAddress($idRoute: Int!, $input: inputRoutesAddresses!) {
    addRouteAddress(idRoute: $idRoute, input: $input)
  }
`

export const CLEAN_ROUTE_ADDRESSES = gql`
  mutation CleanRouteAddresses($idRoute: Int!, $timeBlock: Int!) {
    cleanRouteAddresses(idRoute: $idRoute, timeBlock: $timeBlock)
  }
`

export const CREATE_DETAIL = gql`
  mutation CreateNewDetail($idRoute: Int!, $timeBlock: Int!) {
    createNewDetail(idRoute: $idRoute, timeBlock: $timeBlock)
  }
`

export const DISPATCH_ROUTE = gql`
  mutation DispatchRoute(
    $idRoute: Int!
    $operatorId: Int!
    $vehicleId: Int!
    $timeBlock: Int!
    $startDate: String!
    $finalStaging: String!
  ) {
    dispatchRoute(
      idRoute: $idRoute
      operatorId: $operatorId
      vehicleId: $vehicleId
      timeBlock: $timeBlock
      startDate: $startDate
      finalStaging: $finalStaging
    )
  }
`
