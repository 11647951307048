import { gql } from '@apollo/client'

export const GET_BILLS = gql`
  query GetBills(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $idNote: Int
    $date: Int
    $dateFrom: String
    $dateTo: String
    $fullPayed: Boolean
    $withOutPay: Boolean
    $shopRegister: Int
    $statusNote: Int
    $idProvider: Int
    $notesForContability: Boolean
    $notesForFinances: Boolean
    $searchForChecks: Boolean
  ) {
    getBills(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      idNote: $idNote
      date: $date
      dateFrom: $dateFrom
      dateTo: $dateTo
      fullPayed: $fullPayed
      withOutPay: $withOutPay
      shopRegister: $shopRegister
      statusNote: $statusNote
      idProvider: $idProvider
      notesForContability: $notesForContability
      notesForFinances: $notesForFinances
      searchForChecks: $searchForChecks
    ) {
      rows {
        id
        id_note
        id_bill
        id_partner
        id_concept
        invoice_bill
        amount_bill
        retention_bill
        pay_bill
        full_payed
        date_bill
        note_credit
        description
        retail
        provider_name_nm
        notes {
          id
          consecutive_number
          amount_note
          retention_note
          pay_note
          date_note
          full_payed
          id_partner
          status_note
          date_stamp
          date_programmed_to_pay
          amount_programmed_to_pay_note
          storesRegister {
            alias
          }
          partnerNotes {
            provider_name
          }
        }
        billsToBillsPaymentsFinances {
          billsPaymentsToPaymentsFinances {
            payment_amount
          }
        }
        conceptBills {
          name
        }
      }
      count
    }
  }
`

export const GET_ALL_BILLS_BY_ID_NOTE = gql`
  query GetAllBillsByIdNote($limit: Int, $offset: Int, $idNote: Int!) {
    getAllBillsByIdNote(limit: $limit, offset: $offset, idNote: $idNote) {
      rows {
        id
        id_note
        id_bill
        invoice_bill
        amount_bill
        retention_bill
        date_bill
        pay_bill
        note_credit
      }
      count
    }
  }
`

export const VALIDATE_BILL_BY_UUID = gql`
  query ValidateBillByUUID($uuid: String!) {
    validateBillByUUID(uuid: $uuid) {
      id_note
      id_bill
      invoice_bill
      retention_bill
      pay_bill
      full_payed
      amount_bill
      date_bill
      note_credit
      notes {
        consecutive_number
        storesRegister {
          alias
          name
        }
        payNoteChecks {
          reference_pay
          invoice_number_payments
          is_active
        }
        partnerNotes {
          provider_rfc
          provider_name
        }
      }
      billsToBillsPaymentsFinances {
        billsPaymentsToPaymentsFinances {
          consecutive_number_of_payments
        }
      }
    }
  }
`
