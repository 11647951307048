import React, { useEffect, useState } from 'react'
import Quagga from 'quagga';

const config = {
    "inputStream": {
        "type": "LiveStream",
        "constraints": {
            "width": { "min": 450 },
            "height": { "min": 300 },
            "facingMode": "environment",
            "aspectRatio": { "min": 1, "max": 2 }
        }
    },
    "locator": {
        "patchSize": "medium",
        "halfSample": true
    },
    "numOfWorkers": 2,
    "frequency": 10,
    "decoder": {
        "readers": [{
            "format": "ean_reader",
            "config": {
                "supplements": [
                    "ean_5_reader", "ean_2_reader"
                ]
            }
        }]
    },
    "locate": true
}

export const BarCodeScannerV2 = ({ active = false, fullScreen = false, handleResult }) => {

    useEffect(() => QuaggaMethod(), [])

    const QuaggaMethod = async () => {

        Quagga.init(
            config, 
            (err) => {
                if (err) {
                    return console.log(err);
                }
                Quagga.start();
                return () => {
                    Quagga.stop()
                }
            }
        );

        Quagga.onProcessed(result => {
            const drawingCtx = Quagga.canvas.ctx.overlay;
            const drawingCanvas = Quagga.canvas.dom.overlay;
            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0,
                        0,
                        Number(drawingCanvas.getAttribute("width")),
                        Number(drawingCanvas.getAttribute("height"))
                    );
                    result.boxes
                    .filter(function(box) {
                        return box !== result.box;
                    })
                    .forEach(function(box) {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                        color: "green",
                        lineWidth: 2
                        });
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                        color: "#00F",
                        lineWidth: 2
                    });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(
                        result.line,
                        { x: "x", y: "y" },
                        drawingCtx,
                        { color: "red", lineWidth: 3 }
                    );
                }
            }
        });

        Quagga.onDetected((success) => {
            const { code } = success.codeResult;
            if(success){
                console.log(code);
                handleResult(code);
                // Quagga.stop()
            }
        });
    }

    return (
        <>
            { active 
                ? <div id="interactive" className="viewport scannerComp"/>
                : null
            }
        </>
    )
}
