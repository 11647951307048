import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { FormProvider, useForm } from 'react-hook-form'

import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import Loader from '../../../Global/Spinner'
import { CREATE_POSITION_ORDER } from '../../../../graphql/Catalog/Stowing/refill'
import {
  GET_ITEM_BY_ID,
  GET_NEXT_RACK_PRODUCT,
} from '../../../../graphql/Catalog/Stowing/items'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import InputController from '../../../Global/InputController'
import DynamicForm from '../../../Global/DynamicForm'
import { GET_RACK_DETAILS } from '../../../../graphql/Catalog/Stowing/racks'

export const Refill = () => {
  const { id: _id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [rackValues, setRackValues] = useState([])
  const [formValues, setFormValues] = useState([])
  const [positionId, setPositionId] = useState(null)
  const [rackCapacity, setRackCapacity] = useState(0)
  const [getNextProductRack] = useMutation(GET_NEXT_RACK_PRODUCT)
  const [createPositionOrder] = useMutation(CREATE_POSITION_ORDER)

  const {
    data: dataItem,
    loading: loadingItem,
    error: errorItem,
  } = useQuery(GET_ITEM_BY_ID, {
    variables: {
      getOneItemByIdId: parseInt(_id),
    },
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  useEffect(() => {
    const dataInit = async () => {
      if (!loadingItem) {
        if (errorItem)
          return toastSweetAlert({
            mode: 'error',
            message: errorItem.message,
          })
        if (dataItem) {
          setLoading(true)
          try {
            setRackCapacity(dataItem.getOneItemById.RackPosition.capacity)
            const { data: nextRackValue } = await getNextProductRack({
              variables: {
                itemCode: dataItem.getOneItemById.item_code,
                zoneId: dataItem.getOneItemById.RackPosition.zone_id,
                index: null,
              },
            })
            if (
              nextRackValue.getNextRackForProduct &&
              nextRackValue.getNextRackForProduct.on_hand -
                nextRackValue.getNextRackForProduct.on_commit >
                0
            ) {
              setValue(
                'position_id',
                nextRackValue.getNextRackForProduct.RackPosition.id
              )
              setPositionId(nextRackValue.getNextRackForProduct.id)
              setRackValues([
                {
                  id: nextRackValue.getNextRackForProduct.RackPosition.id,
                  rack: nextRackValue.getNextRackForProduct.RackPosition,
                  available:
                    nextRackValue.getNextRackForProduct.on_hand -
                    nextRackValue.getNextRackForProduct.on_commit,
                  lote: nextRackValue.getNextRackForProduct.lote || '--',
                },
              ])
            } else {
              toastSweetAlert({
                mode: 'error',
                message: 'No hay racks disponibles para rellenar este producto',
              }).then(() => {
                history.push('/stowing/reorder')
              })
              setLoading(false)
            }
            setLoading(false)
          } catch (e) {
            toastSweetAlert({
              mode: 'error',
              message: 'No hay racks disponibles para rellenar este producto',
            }).then(() => {
              history.push('/stowing/reorder')
            })
          }
        }
      }
    }
    dataInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataItem, loadingItem])

  const onSubmit = async (data) => {
    setLoading(true)
    const lastRack = rackValues[rackValues.length - 1]
    const { id, available } = lastRack
    if (formValues.length > 0) {
      const formValue = formValues.find((item) => parseInt(item.rack_id) === id)
      const { quantity } = formValue

      if (quantity > available || !quantity || quantity <= 1) {
        setLoading(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'Por favor ingrese una cantidad valida en el ultimo rack',
        })
      }
      setLoading(false)
    } else {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: 'Por favor ingrese una cantidad en el ultimo rack',
      })
    }
    const itemsToAddTotal = formValues.reduce((acc, item) => {
      return acc + parseInt(item.quantity)
    }, 0)
    const newFormValues = formValues.map((item) => ({ ...item }))
    if (itemsToAddTotal + dataItem?.getOneItemById.on_hand > rackCapacity) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: 'El total de productos a agregar supera la capacidad del rack',
      })
    }
    try {
      const { data: createdPositionOrder } = await createPositionOrder({
        variables: {
          orderInput: {
            item_code: dataItem.getOneItemById.item_code,
            type_id: 1,
            rack_destination_id: dataItem.getOneItemById.rack_id,
            total_products: itemsToAddTotal,
          },
          productsInput: newFormValues,
        },
      })
      if (createdPositionOrder.createPositionOrder) {
        setLoading(false)
        toastSweetAlert({
          mode: 'success',
          message: 'Orden de stowing creada con exito',
        }).then(() => {
          history.push('/stowing/reorder')
        })
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleAddRack = async () => {
    setLoading(true)
    const totalItems = formValues.reduce((acc, item) => {
      return acc + parseInt(item.quantity)
    }, 0)
    const lastRack = rackValues[rackValues.length - 1]
    const { id, available } = lastRack
    const formValue = formValues.find((item) => parseInt(item.rack_id) === id)
    if (!formValue) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: 'Por favor utlice el stock disponible',
      })
    }
    const { quantity } = formValue

    if (quantity > available || !quantity) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: 'Por favor ingrese una cantidad valida',
      })
    } else if (quantity < available) {
      setLoading(false)

      return toastSweetAlert({
        mode: 'error',
        message: 'Por favor utilice todo el stock disponible',
      })
    } else if (
      parseInt(totalItems) >
      parseInt(rackCapacity) - parseInt(dataItem?.getOneItemById.on_hand)
    ) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: 'El total de productos a agregar supera la capacidad del rack',
      })
    } else if (
      parseInt(totalItems) ===
      parseInt(rackCapacity) - parseInt(dataItem?.getOneItemById.on_hand)
    ) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: 'El total de productos a agregar llena el rack',
      })
    }
    try {
      setRackCapacity(dataItem.getOneItemById.RackPosition.capacity)
      const { data: nextRackValue } = await getNextProductRack({
        variables: {
          itemCode: dataItem.getOneItemById.item_code,
          zoneId: dataItem.getOneItemById.RackPosition.zone_id,
          index: rackValues.length,
        },
      })
      if (nextRackValue.getNextRackForProduct) {
        setLoading(false)
        setPositionId(nextRackValue.getNextRackForProduct.id)
        setRackValues([
          ...rackValues,
          {
            id: nextRackValue.getNextRackForProduct.RackPosition.id,
            rack: nextRackValue.getNextRackForProduct.RackPosition,
            available:
              nextRackValue.getNextRackForProduct.on_hand -
              nextRackValue.getNextRackForProduct.on_commit,
            lote: nextRackValue.getNextRackForProduct.lote || '--',
          },
        ])
      } else {
        setLoading(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'No hay mas racks disponibles',
        })
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleInputChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const rackId = name.split('_')[1]
    const rackIndex = formValues.findIndex(
      (rack) => rack.rack_id === parseInt(rackId)
    )
    if (rackIndex === -1) {
      setFormValues([
        ...formValues,
        {
          position_id: positionId,
          rack_id: parseInt(rackId),
          quantity: parseInt(value),
          item_code: dataItem.getOneItemById.item_code,
        },
      ])
    } else {
      const newFormValues = [...formValues]
      newFormValues[rackIndex].quantity = parseInt(value)
      setFormValues(newFormValues)
    }
  }

  return (
    <>
      <ContentHeader
        title="Orden de Relleno"
        breadcrumb="Rellenado"
        windowTitle={`Crear orden de relleno`}
      />
      <FormProvider {...methods}>
        <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`Rack: ${dataItem?.getOneItemById.RackPosition.name} - Producto: ${dataItem?.getOneItemById.SapItem?.item_name}`}
            btnRedPath="/stowing/reorder"
            btnRedTxt="Cancelar"
            btnFunctionTitle={`Crear orden de relleno`}
            btnSubmit={true}
            btnState={loading}
            errors={errors}
            btnSubmitText={'Crear orden de relleno'}
            content={
              loading || loadingItem ? (
                <div className="row justify-content-center align-items-center h-100">
                  <Loader />
                </div>
              ) : (
                rackValues.length > 0 &&
                rackValues.map((rack, index) => {
                  return (
                    <>
                      {index === 0 && (
                        <div className="row">
                          <div className="mb-3 col-md-12 col-lg-4">
                            <label className="form-label">
                              {`Actualmente en rack: ${dataItem?.getOneItemById.on_hand}`}
                            </label>
                          </div>
                          <div className="mb-3 col-md-12 col-lg-4">
                            <label className="form-label">
                              {`Capacidad maxima del rack: ${rackCapacity}`}
                            </label>
                          </div>
                          <div className="mb-3 col-md-12 col-lg-4">
                            <label className="form-label">
                              {`Maxima cantidad a rellenar: ${
                                rackCapacity - dataItem?.getOneItemById.on_hand
                              }`}
                            </label>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="mb-3 col-md-12 col-lg-4">
                          <label className="form-label">Rack</label>
                          <input
                            type="text"
                            name={`rack_${rack.id}`}
                            defaultValue={rack.rack.name}
                            disabled={index + 1 !== rackValues.length}
                            className="form-control rounded-2"
                          />
                        </div>
                        <div className="mb-3 col-md-12 col-lg-4">
                          <label className="form-label">Cantidad</label>
                          <input
                            type="number"
                            name={`rack_${rack.id}`}
                            value={formValues[index]?.quantity || ''}
                            onChange={(e) => {
                              handleInputChange(e)
                            }}
                            disabled={index + 1 !== rackValues.length}
                            placeholder={`Maximo: ${rack.available} pzas`}
                            className="form-control rounded-2"
                          />
                        </div>
                        <div className="mb-3 col-md-12 col-lg-4">
                          <label className="form-label">Lote</label>
                          <p className="form-control rounded-2"> {rack.lote}</p>
                        </div>
                        <div className="mb-3 col-md-12 col-lg-4 align-self-end">
                          {index + 1 === rackValues.length && (
                            <button
                              type="button"
                              className="btn btn-accept"
                              onClick={handleAddRack}
                              disabled={loading}
                            >
                              + Agregar rack
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )
                })
              )
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default Refill
