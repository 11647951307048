import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  GET_ALL_WAREHOUSE_ASSIGNATION_BY_USER_ID,
  GET_ALL_WARHEOUSES_TO_BE_ASSSIGNED,
  UPDATE_CREATE_WAREHOUSE_ASSIGNATION,
} from '../../../graphql/Catalog/SAP/ProvidersBusinessPartner'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
const StoreWarehouseAssignation = ({ submitBusinessPartner }) => {
  const [dataToTable, setDataToTable] = useState([])
  const [warehousesIds, setWarehousesIds] = useState([])
  const [warehouseByUserID, setWarehouseByUserID] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const { id: _id } = useParams()
  const history = useHistory()

  const { data, error, loading } = useQuery(
    GET_ALL_WARHEOUSES_TO_BE_ASSSIGNED,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )
  const [wareohuseAssignation] = useMutation(
    UPDATE_CREATE_WAREHOUSE_ASSIGNATION
  )
  const {
    data: warehouseData,
    error: warehouseError,
    loading: warehouseLoading,
  } = useQuery(GET_ALL_WAREHOUSE_ASSIGNATION_BY_USER_ID, {
    variables: {
      getAllWarehouseAssignationByUserIdId: +_id,
    },
  })

  useEffect(() => {
    if (!loading && !warehouseLoading) {
      if (error) {
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      }
      if (warehouseError) {
        return toastSweetAlert({
          mode: 'error',
          message: warehouseError.message,
        })
      }
      const warehouseUserList =
        warehouseData.getAllWarehouseAssignationByUserId.rows.map((item) => ({
          id: item.id,
          warehouse_id: item.sap_warehouses_id,
        }))
      const list = data.getAllStoreWarehouses.rows.map((item) => ({
        id: item.sap_warehouses_id,
        Almacen: item.SapWarehousesToCatalogWarehouseReport.warehouse_name,
        Codigo: item.SapWarehousesToCatalogWarehouseReport.warehouse_code,
      }))
      setWarehouseByUserID(warehouseUserList)
      setDataToTable(list)
      setTotal(data.getAllStoreWarehouses.count)
    }
  }, [data, error, loading, warehouseData, warehouseError, warehouseLoading])

  const handleSubmit = async () => {
    try {
      const { data } = await wareohuseAssignation({
        variables: {
          userId: +_id,
          sapWarehousesId: warehousesIds,
        },
        refetchQueries: [
          {
            query: GET_ALL_WAREHOUSE_ASSIGNATION_BY_USER_ID,
            variables: {
              getAllWarehouseAssignationByUserIdId: +_id,
            },
            fetchPolicy: 'no-cache',
          },
          {
            query: GET_ALL_WARHEOUSES_TO_BE_ASSSIGNED,
            fetchPolicy: 'no-cache',
          },
        ],
      })
      submitBusinessPartner()

      history.push('/businesspartner')
    } catch (error) {
      console.log(error)
      return toastSweetAlert({
        message: error.message,
        mode: 'error',
      })
    }
  }
  const handleCheckBox = async (id) => {
    setWarehousesIds((prevState) => {
      const findItem = prevState.find((item) => item == id)

      if (findItem) {
        const list = prevState.filter((item) => item !== id)
        setWarehousesIds(list)
      } else {
        return [...prevState, id]
      }
    })
  }
  const handleChecked = (id) => {
    const warehouseAssigned = warehouseByUserID.find(
      (item) => item.warehouse_id == id
    )
    const storeWarehouseChecked = warehousesIds.find(
      (warehouseId) => warehouseId == id
    )

    return warehouseAssigned || storeWarehouseChecked ? true : false
  }
  return (
    <>
      <Box
        withCard={false}
        title="Listado de Almacenes"
        btnRedPath="/businesspartner"
        btnRedTxt="Cancelar"
        btnSubmit={handleSubmit}
        btnSubmitText="Actualizar Registros"
        content={
          <>
            <div>
              <h2 className=" font-weight-bold text-dark business-partner-mb-title">
                Listado de almacenes
              </h2>
              <LayoutTable
                data={dataToTable}
                hideDetails
                checkbox
                handleCheckbox={handleCheckBox}
                handleCheckedInputProperty={handleChecked}
                setPagePagination={setPagePagination}
                pagePagination={pagePagination}
                totalCount={total}
                withCard={false}
                loadingData={loading}
              />
            </div>
          </>
        }
      />
    </>
  )
}

export default StoreWarehouseAssignation
