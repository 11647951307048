const Accordion = ({
  idCollapse = 'headingOne',
  idAccordion = 'accordion',
  idContent = 'collapseOne',
  content = <></>,
  title = '',
  containerCssClass = '',
}) => {
  return (
    <>
      <div id={idAccordion} className={containerCssClass}>
        <div className="card">
          <div className="card-header" id={idCollapse}>
            <h5 className="mb-0 ">
              <button
                className="btn "
                data-toggle="collapse"
                data-target={`#${idContent}`}
                aria-expanded="true"
                aria-controls={idContent}
              >
                {title}
              </button>
            </h5>
          </div>

          <div
            id={idContent}
            className="collapse"
            aria-labelledby={idCollapse}
            data-parent={`#${idAccordion}`}
          >
            <div className="card-body">{content}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Accordion
