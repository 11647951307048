import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { DELETE_RECEIPT_VEHICLE_TYPE, GET_ALL_RECEIPT_VEHICLES_TYPES } from "../../../graphql/Catalog/ReceiptRegisters/vehiclesTypes"
import LayoutTable from "../../Global/LayoutTable"
import { toastSweetAlert } from "../../Helpers/ToastSweetAlert"
import ContentHeader from "../../Layout/ContentHeader"

const ReceiptVehiclesRegisters =()=> {    
    //---------- Hooks
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })

    //Get Vehicles
    const [vehicles,setVehicles] = useState([])
    //Get total count
    const [total,setTotal] = useState(0)

    //Queries
    const { data, loading, error } = useQuery(GET_ALL_RECEIPT_VEHICLES_TYPES, {
        variables: {
          searchQuery: pagePagination.searchQuery,
          limit: pagePagination.limit,
          offset: pagePagination.offset,
        },
    })

    //---------- useEffects
    useEffect(()=> {
        if(!loading){
            if(error) return toastSweetAlert({
                mode: 'error',
                message: error,
            })

            //Get Vehicles data
            let list = []
            data.getAllVehiclesTypes.rows.map((itemVehicle) => {
                list.push({
                    id: itemVehicle.id,
                    nombre: itemVehicle.name,
                })
            })

            //Set data
            setVehicles(list)
            //Set total
            setTotal(data.getAllVehiclesTypes.count)
        }
    },[loading,data,error])

    return (
        <>
            {/* <ContentHeader 
                title="Vehiculos de recibo" 
                breadcrumb="Vehiculos" 
                windowTitle="Vehiculos" 
            /> */}
            <LayoutTable
                data={vehicles}
                title="Vehiculos de recibo"
                gql={DELETE_RECEIPT_VEHICLE_TYPE}
                requery={GET_ALL_RECEIPT_VEHICLES_TYPES}
                // exportQuery={EXPORT_ROLES}
                totalCount={total}
                pagePagination={pagePagination}
                setPagePagination={setPagePagination}
                loadingData={loading}
                hideDetails={true}
                // addBtnTxt="Agregar nuevo vehiculo"
                FilterBarPlaceholder="Buscar por Nombre"
            />
        </>
    )
}

export default ReceiptVehiclesRegisters