import { useEffect, useState } from 'react'
import {
  DELETE_CONCEPT_COUNTER_CHARGE,
  GET_ALL_CONCEPT_COUNTER_CHARGES,
} from '../../../../../graphql/Catalog/Notes/ConceptCounterCharges/ConceptCounterCharges'
import LayoutTable from '../../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../../Layout/ContentHeader'
import { useQuery } from '@apollo/client'

export const ConceptCounterCharges = () => {
  const [dataToTable, setDataToTable] = useState([])
  const [total, setTotal] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_CONCEPT_COUNTER_CHARGES,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []

      data.getAllConceptsCounterCharges.rows.map((item) => {
        return list.push({
          id: item.id,
          Concepto: item.name,
        })
      })
      setDataToTable(list)
      setTotal(data.getAllConceptsCounterCharges.count)
    }
  }, [loading, error, data])

  return (
    <>
      <ContentHeader
        title="Conceptos nota manual"
        windowTitle="Conceptos nota manual"
        breadcrumb="Conceptos nota manual"
      />
      <LayoutTable
        // title="Lista de conceptos nota manual"
        data={dataToTable}
        loadingData={loading}
        total={total}
        gql={DELETE_CONCEPT_COUNTER_CHARGE}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        refetchFunctionQueries={async () => refetch()}
        FilterBarPlaceholder="Buscar concepto"
        exportImportButton={false}
      />
    </>
  )
}

export default ConceptCounterCharges
