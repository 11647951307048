import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  GET_TRANSFER_REQUEST,
  EXPORT_TRANSFERS,
  IMPORT_TRANSFERS,
  PROCESS_TRANSFERS_PICKING_SCHEDULES,
  CREATE_TRANSFER_REQUEST_ERASER,
  VALID_PROCESS_TRANSFERS_EXISTS_PACKING_LIST,
} from '../../../graphql/Catalog/Transfers/Transfers'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import {
  document_statuses_esp,
  // status_transfers,
} from '../../Helpers/StatusesLanguages'
import { useHistory } from 'react-router-dom'
// import { GET_DOCUMENT_STATUS } from '../../../graphql/Documents/Status/status'
import DatesOptions from '../../Global/DatesOptions'
import { groupBtnEditDisableOrEnable } from '../../Helpers/functionTransfers'
import StatusBox from '../../Global/StatusBox/StatusBox'

export const TransfersOrders = () => {
  /**Get all status enable */
  // const {
  //   data: dataStatus,
  //   loading: loadingStatus,
  //   error: errorStatus,
  // } = useQuery(GET_DOCUMENT_STATUS)
  // const [allStatus, setAllStatus] = useState(null)

  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })
  const history = useHistory()
  const [total, setTotal] = useState(0)
  const [loadingProcess, setLoadingProcess] = useState(false)
  const { loading, error, data } = useQuery(GET_TRANSFER_REQUEST, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      onlyFromWhs: null,
      onlyToWhs: null,
      statuses: null,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      type: pagePagination.type,
      date: pagePagination.date,
    },
    fetchPolicy: 'no-cache',
  })
  const [processPickingScheduleTransfer] = useMutation(
    PROCESS_TRANSFERS_PICKING_SCHEDULES
  )
  const [createTransferRequestEraser] = useMutation(
    CREATE_TRANSFER_REQUEST_ERASER
  )
  const [validExistsInPackingList] = useMutation(
    VALID_PROCESS_TRANSFERS_EXISTS_PACKING_LIST
  )
  const [dataToTable, setDataToTable] = useState([])
  // const transfersStatus = status_transfers
  const statusDictionary = document_statuses_esp

  useEffect(() => {
    if (!loading) {
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data.getAllTransferRequest.rows.map((transfer) => {
        return list.push({
          id: transfer.id,
          'Doc Num': transfer.doc_num ? transfer.doc_num : 'Sin número',
          'Doc Entry': transfer?.doc_entry ? transfer?.doc_entry : 'Sin número',
          Creación: moment(+transfer.createdAt).format('DD/MMMM/YYYY'),
          // Vencimiento: moment(transfer.doc_due_date).format('DD/MMMM/YYYY'),
          Estatus: <StatusBox mode={transfer.status}/>,
          //Ubicación: transfersStatus[transfer.stage_status],
          'Origen-Destino': `${
            transfer?.warehouse_origin_name?.warehouse_name ?? 'Por Definir'
          } - ${
            transfer?.warehouse_destiny_name?.warehouse_name ?? 'Por Definir'
          }`,
          TransferStatus: transfer?.status,
          // Destino: transfer?.warehouse_destiny_name?.warehouse_name,
        })
      })
      setDataToTable(list)
      setTotal(data.getAllTransferRequest.count)
    }
    // eslint-disable-next-liconsole.log(e)ne react-hooks/exhaustive-deps
  }, [loading, error, data, total, pagePagination])

  // useEffect(() => {
  //   if (!loadingStatus) {
  //     if (errorStatus)
  //       return toastSweetAlert({
  //         mode: 'error',
  //         message: error.message,
  //       })

  //     /**Set data to hook */
  //     let list = []
  //     dataStatus.getDocumentStatus.map((item) => {
  //       list.push({
  //         id: item.id,
  //         name: document_statuses_esp[item.id]
  //           ? document_statuses_esp[item.id]
  //           : '',
  //       })
  //     })
  //     setAllStatus(list)
  //   }
  // }, [dataStatus, loadingStatus, errorStatus])

  const handleProcessTransfer = async (e) => {
    try {
      setLoadingProcess(true)
      await groupBtnEditDisableOrEnable({ type: 'disabled' })
      toastSweetAlert({
        mode: 'loading',
        message:
          'Procesando, espere por favor. Esto puede llevar unos minutos.',
      })

      await validExistsInPackingList({ variables: { transferId: e } })

      await processPickingScheduleTransfer({
        variables: {
          transferId: e,
        },
        refetchQueries: [
          {
            query: GET_TRANSFER_REQUEST,
            variables: {
              limit: pagePagination.limit,
              offset: pagePagination.offset,
              searchQuery: null,
              status: pagePagination.status,
              onlyFromWhs: null,
              onlyToWhs: null,
            },
          },
        ],
      })
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'Transferencia procesada correctamente',
        },
        setLoadingProcess(false),
        history.push('/transfers')
      )
    } catch (error) {
      setLoadingProcess(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    } finally {
      await groupBtnEditDisableOrEnable({ type: 'enabled' })
    }
  }

  // const filterOnChange = (e) => {
  //   const filterSelected = e.target.value
  //   setPagePagination({
  //     ...pagePagination,
  //     type: parseInt(filterSelected),
  //   })
  // }

  const filterOnChangeSecond = (e) => {
    const filterSelected = e.target.value
    setPagePagination({
      ...pagePagination,
      date: parseInt(filterSelected),
    })
  }

  const createTransferRequest = async () => {
    const { data } = await createTransferRequestEraser({
      variables: {
        input: {
          comments: '',
          from_whs_code: '',
          to_whs_code: '',
          branch_code: '',
        },
      },
    })

    history.push(`/transfers/edit/${data.createTransferRequest}`)
  }

  return (
    <div className={`${loadingProcess ? 'processing-file' : ''}`}>
      {/* <ContentHeader
        title="Solicitudes de traspasos"
        breadcrumb="Traspasos órdenes"
        windowTitle="Traspasos"
      /> */}
      <LayoutTable
        data={dataToTable}
        title="Solicitudes de traspasos"
        // addBtnTxt="Nuevo traspaso"
        extraHeaderButtonFunction={createTransferRequest}
        extraHeaderButtonFunctionText={
          <>
            <i className="fa fa-plus"></i> Nuevo traspaso
          </>
        }
        hideAddNew
        // actionBtnEdit={handleProcessTransfer}
        editText="Retomar solicitud"
        // handleButton={(e) => history.push(`/transfers/edit/${e}`)}
        // handleButtonTitle="Retomar solicitud"
        totalCount={total}
        exportQuery={EXPORT_TRANSFERS}
        importQuery={IMPORT_TRANSFERS}
        maximumFilesImport={2}
        importMessageValidation="Catálogo de órdenes traspasos y Catálogo de líneas de productos de traspasos"
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        // hideFilterBar
        FilterBarPlaceholder="Buscar por Origen o Destino"
        /** First filter */
        /* filter
        filterOnChange={filterOnChange}
        filterOptions={[
          allStatus
            ? allStatus.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                )
              })
            : null,
        ]}
        filterLabelText="Por estatus" */
        /** Second filter */
        /* filterSecond
        filterOnChangeSecond={filterOnChangeSecond}
        filterOptionsSecond={[<DatesOptions daysBefore={true} />]}
        filterLabelTextSecond="Por fecha" */
        filter
        filterOnChange={filterOnChangeSecond}
        filterOptions={[<DatesOptions daysBefore={true} />]}
        filterLabelText="Por fecha"
      />
    </div>
  )
}

export default TransfersOrders
