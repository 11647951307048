import React from 'react'

export const DetailsPrices = ({
    orderTotals
}) => {
    var currencyFormat = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
    })

    return(
        <div className='row justify-content-end mt-5'>
            <div className="col-5">
            <div className="row justify-content-between">
                <p className="cost-header">Producto</p>
                <p className="cost-text">{`${currencyFormat.format(
                    orderTotals.total_products - orderTotals.total_shipping
                )}`}</p>
            </div>
            <div className="row justify-content-between">
                <p className="cost-header">Envío</p>
                <p className="cost-text">{`${currencyFormat.format(
                    orderTotals.total_shipping
                )}`}</p>
            </div>
            <hr/>
            <div className="row justify-content-between">
                <p className="cost-header text-bold">
                    Total
                </p>
                <p className="cost-text text-bold">{`${currencyFormat.format(orderTotals.total)}`}</p>
            </div>
            </div>
        </div>
    )
}
export default DetailsPrices