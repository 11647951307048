import { gql } from '@apollo/client'

export const GET_SAP_PURCHASES_ORDERS = gql`
  query GetSapPurchasesOrders($limit: Int, $offset: Int) {
    getSapPurchasesOrders(limit: $limit, offset: $offset) {
      id
      business_partner_id
      document_date
      document_due_date
      number_at_card
      document_total
      comments
      document_status
      document_number
      document_entry
      series
      disc_prcnt
    }
  }
`

export const GET_SAP_PURCHASES_ORDERS_BY_PROVIDER = gql`
  mutation GetSapPurchasesOrdersByProvider(
    $idBusinessPartner: Int
    $limit: Int
    $offset: Int
    $searchQuery: String
  ) {
    getSapPurchasesOrdersByProvider(
      idBusinessPartner: $idBusinessPartner
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
    ) {
      rows {
        id
        document_date
        comments
        document_status
        document_number
      }
      count
    }
  }
`
