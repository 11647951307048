import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import Box from '../../Global/Box'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { GET_INVENTORY_ACTIVITY_INFO_BY_ID } from '../../../graphql/InventoryActivities/InventoryActivitiesSchedule'
import InventoryActivityReportCycleCountSku from './Components/InventoryActivityReportCycleCountSku'
import InventoryActivityReportTFI from './Components/InventoryActivityReportTFI'
import LiveCycleCountsUbications from './Components/InventoryActivityReportCycleCountsUbications'
import HelperGenerateExcel from '../../Helpers/HelperGenerateExcel'
import _ from 'lodash'
import InventorySyncSapErrorsModal from './InventorySyncSapErrorsModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const InventoryReportsNew = () => {
  const { id } = useParams() //scheduleId
  const [loadingBtn, setLoading] = useState(false)
  const [statusSync, setStatusSync] = useState(null)
  const [open, setOpen] = useState(false)
  const [progressbarPercentage, setProgressBarPercentage] = useState(0)

  const [progressbarTitle, setProgressBarTitle] = useState(
    'Productos Recibidos'
  )
  const refModal = useRef()

  const [dataAssign, setDataAssign] = useState({
    warehouse: null,
    date: null,
  })

  const [dataReport, setDataReport] = useState({ items: [], total: 0 })
  const [downloadDataReport, setDownloadDataReport] = useState([])

  const [pagePagination, setPagePagination] = useState({
    limit: 30,
    offset: 0,
    searchQuery: null,
  })

  /* USEQUERY & USEMUTATION */
  const assignment = useQuery(GET_INVENTORY_ACTIVITY_INFO_BY_ID, {
    variables: {
      scheduleId: parseInt(id) > 0 ? parseInt(id) : 0,
    },
  })

  const [disabledButton, setDisabledButton] = useState(false)
  const history = useHistory()

  /* USEEFFECT */
  useEffect(() => {
    if (!assignment?.loading) {
      if (assignment.error) {
        return toastSweetAlert({
          mode: 'error',
          message: assignment.error.message,
        })
      }

      const data = assignment.data.GetInventoryActivityInfoById
      setStatusSync(data?.status_sync)
      setDataAssign({
        warehouse: data?.InventoryActivitiesWarehouseSchedule?.warehouse_name,
        date: moment(data?.start_date).format('lll'),
        ...data,
      })
    }
  }, [assignment?.loading, assignment?.data, assignment?.error, id])

  /* MODES */

  return (
    <>
      <div className={`${loadingBtn ? 'processing-file' : ''}`}>
        <Box
          title={`${
            dataAssign.ubications_or_skus === 1
              ? 'Reporte conteo cíclico / Ubicaciones'
              : dataAssign.ubications_or_skus === 2
              ? 'Reporte conteo cíclico / SKUS'
              : 'Reporte TFI'
          }`}
          content={
            <>
              {statusSync === 2 && (
                <>
                  <h3>{progressbarTitle}</h3>
                  <div className="progress mt-2 mb-5 row">
                    <div
                      className="progress-bar  bg-primary progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progressbarPercentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </>
              )}
              {statusSync === 3 && (
                <>
                  <h3>
                    {progressbarTitle} -{' '}
                    <b>
                      La sincronización se ha detenido debido a que se detecto
                      un error
                    </b>
                  </h3>
                  <div className="progress mt-2 mb-5 row">
                    <div
                      className="progress-bar  bg-danger progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progressbarPercentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </>
              )}
              {statusSync === 4 && (
                <>
                  <h3>
                    {progressbarTitle} - La sincronización ha finalizado
                    correctamente
                  </h3>
                  <div className="progress mt-2 mb-5 row">
                    <div
                      className="progress-bar  bg-success progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progressbarPercentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </>
              )}
              {/* <div className="row">
                <div className="col-12 col-md-3">
                  <h6 className="lead">ALMACEN</h6>
                </div>
                <div className="col-12 col-md-3">
                  <h6 className="lead">FECHA DE INICIO</h6>
                </div>
                <div className="col-12 col-md-3">
                  <h6 className="lead">CANTIDAD TOTAL DE DESVIACIÓN</h6>
                </div>
                <div className="col-12 col-md-3">
                  <h6 className="lead">TOTAL DE DESVIACIÓN</h6>
                </div>
              </div>
              <div className="row bg-gray-light pt-1 pb-0 mb-4">
                <div className="col-12 col-md-3 pt-1 pl-2">
                  <p>{dataAssign.warehouse}</p>
                </div>
                <div className="col-12 col-md-3 pt-1">
                  <p>{dataAssign.date}</p>
                </div>
                <div className="col-12 col-md-3 pt-1">
                  <p>{dataReport.quantityDeviation} pzas.</p>
                </div>
                <div className="col-12 col-md-3 pt-1">
                  <p>$ {dataReport.totalDeviation}</p>
                </div>
              </div> */}

              {dataAssign.type_schedule === 2 ? (
                <InventoryActivityReportTFI dataAssignment={dataAssign} />
              ) : dataAssign.ubications_or_skus === 1 ? (
                <LiveCycleCountsUbications dataAssignment={dataAssign} />
              ) : (
                <InventoryActivityReportCycleCountSku
                  dataAssignment={dataAssign}
                />
              )}
            </>
          }
        />
      </div>
      {statusSync === 3 && (
        <InventorySyncSapErrorsModal
          refModal={refModal}
          idCalendar={id}
          isOpen={open}
          setOpen={setOpen}
        />
      )}
    </>
  )
}

export default InventoryReportsNew
