/**
 * Component to display fixe options to the select filter using dates
 * 
 * daysBefore: If is true, display different text
 * */
const DatesOptions =({daysBefore = false})=>{
  return(
    <>
      <option value="5" key="5">
        {daysBefore ? 'Hace 5 días' : '5 días'}
      </option>,
      <option value="10" key="10">
        {daysBefore ? 'Hace 10 días' : '10 días'}
      </option>,
      <option value="15" key="15">
        {daysBefore ? 'Hace 15 días' : '15 días'}
      </option>,
      <option value="30" key="30">
        {daysBefore ? 'Hace 30 días' : '30 días'}
      </option>,
      <option value="31" key="31">
        {daysBefore ? 'Hace mas de 30 días' : 'Mas de 30 días'}
      </option>,
    </>
  )
}

export default DatesOptions;