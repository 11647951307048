import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_ITEMS } from '../../../../graphql/Catalog/Stowing/items'
import React, { useEffect, useState } from 'react'
import {
  GET_ALL_UNUSED_RACKS,
  GET_RACK_DETAILS,
} from '../../../../graphql/Catalog/Stowing/racks'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import Loader from '../../../Global/Spinner'
import InputController from '../../../Global/InputController'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { CREATE_POSITION_ORDER } from '../../../../graphql/Catalog/Stowing/refill'
import { useHistory } from 'react-router-dom'

const NewPallet = () => {
  const history = useHistory()
  const {
    data: itemsData,
    loading: itemsLoading,
    error: itemsError,
  } = useQuery(GET_ALL_ITEMS, {
    variables: {
      zoneId: 4,
    },
  })
  const {
    data: unusedRacksData,
    loading: unusedRacksLoading,
    error: unusedRacksError,
  } = useQuery(GET_ALL_UNUSED_RACKS, {
    variables: {
      zoneId: 9,
    },
  })

  const [createPositionOrder] = useMutation(CREATE_POSITION_ORDER)

  const [itemOptions, setItemOptions] = useState([])
  const [rackOptions, setRackOptions] = useState([])
  const [itemCodeSelected, setItemCodeSelected] = useState('')
  const [itemsAvailable, setItemsAvailable] = useState(null)
  const [optionType, setOptionType] = useState('')
  const [loading, setLoading] = useState(false)
  const [totalSetted, setTotalSetted] = useState(false)
  const [disabledDetails, setDisabledDetails] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (!itemsLoading && !itemsError) {
      const itemOptions = itemsData.getAllItems.rows.map((item) => {
        return {
          value: item.id,
          label: item.SapItem.item_name,
        }
      })
      setItemOptions(itemOptions)
      setLoading(false)
    }
  }, [itemsLoading, itemsData])

  useEffect(() => {
    if (unusedRacksData) {
      const rackOptions = unusedRacksData.getAllUnusedRacks.map((rack) => {
        return {
          value: rack.id,
          label: rack.name,
        }
      })
      setRackOptions(rackOptions)
    }
  }, [unusedRacksData])

  const validationSchema = yup.object({
    item_code: yup.string().required('Item code is required'),
  })
  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (data) => {
    if (data.bottles > itemsAvailable) {
      toastSweetAlert({
        mode: 'error',
        message: 'No puedes usar mas botellas de las disponibles',
      })
      return
    }
    try {
      const { data: createdPositionOrder } = await createPositionOrder({
        variables: {
          orderInput: {
            item_code: data.product_sku,
            type_id: parseInt(data.position_type),
            rack_destination_id: data.rack_id,
            total_products: parseInt(data.bottles),
          },
          productsInput: [
            {
              rack_id: 7441212,
              item_code: data.product_sku,
              position_id: parseInt(data.product_id),
              quantity: parseInt(data.bottles),
            },
          ],
        },
      })
      if (createdPositionOrder.createPositionOrder) {
        toastSweetAlert({
          mode: 'success',
          message: 'Orden de stowing creada con exito',
        }).then(() => {
          history.push('/stowing/reorder')
        })
      }
    } catch (error) {
      console.log(error)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const calculateItems = () => {
    const beds = getValues('beds')
    const boxes = getValues('boxes')
    const items = getValues('items')
    if (beds && boxes && items) {
      const total = items * boxes * beds
      setValue('bottles', total)
      setTotalSetted(true)
      return
    }
    setValue('bottles', '--')
    setTotalSetted(false)
    //DELETE TO SET MANUALLY
    setOptionType('2')
  }

  const productSelected = (selected) => {
    if (selected.value !== null) {
      const product = itemsData.getAllItems.rows.find((item) => {
        return item.id === selected.value
      })
      setValue('product_id', product.id)
      setValue('product_sku', product.SapItem.item_code)
      setItemCodeSelected(product.SapItem.item_code)
      setItemsAvailable(product.on_hand - product.on_commit)
      setDisabledDetails(false)
    }
  }

  //DELETE TO SET MANUALLY
  useEffect(() => {
    setValue('position_type', '2')
  }, [])

  return (
    <>
      <ContentHeader
        title="Creacion de tarima"
        breadcrumb="Creacion de tarima"
        windowTitle={`Creacion de tarima `}
      />
      <FormProvider {...methods}>
        <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`Creacion de tarima`}
            errors={errors}
            content={
              loading || itemsLoading ? (
                <div className="row justify-content-center align-items-center h-100">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="mb-3 col-md-12 col-lg-6">
                      {
                        <InputController
                          label="Producto"
                          type="text"
                          inputType="choosen"
                          name="item_code"
                          control={control}
                          options={itemOptions}
                          changeAction={productSelected}
                        />
                      }
                    </div>
                    {itemsAvailable && (
                      <div className="mb-3 col-md-12 col-lg-6">
                        <label>Cantidad disponible</label>
                        <p>{itemsAvailable}</p>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12 col-lg-3">
                      <InputController
                        label="Botellas por caja"
                        type="number"
                        name="items"
                        placeholder="Ingrese cantidad"
                        control={control}
                        blurAction={calculateItems}
                        disabled={disabledDetails}
                      />
                    </div>
                    <div className="mb-3 col-md-12 col-lg-3">
                      <InputController
                        label="Cajas por cama"
                        type="number"
                        name="boxes"
                        placeholder="Ingrese cantidad"
                        control={control}
                        blurAction={calculateItems}
                        disabled={disabledDetails}
                      />
                    </div>
                    <div className="mb-3 col-md-12 col-lg-3">
                      <InputController
                        label="Camas"
                        type="number"
                        name="beds"
                        placeholder="Ingrese cantidad"
                        control={control}
                        blurAction={calculateItems}
                        disabled={disabledDetails}
                      />
                    </div>
                    <div className="mb-3 col-md-12 col-lg-3">
                      <InputController
                        label="Botellas totales"
                        type="number"
                        name="bottles"
                        placeholder="--"
                        disabled
                        control={control}
                      />
                    </div>
                  </div>
                </>
              )
            }
          />
          {totalSetted && (
            <Box
              title={`Posicionar tarima`}
              errors={errors}
              content={
                <>
                  <div className="row">
                    <div className="mb-3 col-md-12 col-lg-6">
                      {
                        <InputController
                          label="Tipo de orden"
                          type="number"
                          inputType="choosen"
                          name="position_type"
                          control={control}
                          disabled //DELETE TO SET MANUALLY
                          options={[
                            { label: 'Relleno', value: '1' },
                            {
                              label: 'Posicionado',
                              value: '2',
                            },
                          ]}
                          changeAction={(e) => {
                            setOptionType(e.value)
                          }}
                        />
                      }
                    </div>
                    <div className="mb-3 col-md-12 col-lg-6">
                      {optionType !== '' && (
                        <InputController
                          label="Ubicaciones disponibles"
                          type="number"
                          inputType="choosen"
                          name="rack_id"
                          control={control}
                          options={
                            optionType === '2'
                              ? rackOptions
                              : [
                                  {
                                    label: 'xd',
                                    value: '1',
                                  },
                                ]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="control-btn-box">
                    <button
                      type="submit"
                      className="btn btn-accept btn-sm mt-2 mb-2"
                      disabled={loading}
                    >
                      {loading
                        ? ' Procesando...'
                        : `Crear orden de ${
                            optionType === '1' ? 'relleno' : 'posicionado'
                          }`}
                    </button>
                  </div>
                </>
              }
            />
          )}
        </form>
      </FormProvider>
    </>
  )
}

export default NewPallet
