import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import DetailsCustomer from '../../Shared/DetailsCustomer'
import DetailsProducts from '../../Shared/DetailsProducts'
import DetailsPaymentDelivery from '../../Shared/DetailsPaymentDelivery'

export const NewOrder = ({
    getDataOrder,
    comesFromSummary = false,
    paymentMethod,
    deliveryDate,
    deliveryTime,
}) => {
  const { state } = useLocation()

  const [showProductsCollapse, setShowProductsCollapse] = useState(true)
  const [showPaymentDeliveryCollapse, setShowPaymentDeliveryCollapse] = useState(false)

  //get date selected for delivery
  const [deliveryDateSelected, setDeliveryDateSelected] = useState(null)

  //get delivery time selected for delivery
  const [deliveryTimeSelected, setDeliveryTimeSelected] = useState('')

  //save data order
  const [dataCart, setDataCart] = useState([])

  //save total order
  const [totalCart, setTotalCart] = useState(0);

  const validationSchema = Yup.object().shape({
    payment_method: Yup.string().required('El método de pago es requerido'),
    delivery_date: Yup.string().required('La fecha de entrega es requerida'),
    delivery_time: Yup.string().required('La ventana de entrega es requerida').test('required', 'La ventana de entrega es requerida', 
    () => deliveryTimeSelected != "" ? true : false),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    //Know if come from order summary
    setShowPaymentDeliveryCollapse(comesFromSummary ? true : false)
    setShowProductsCollapse(comesFromSummary ? false : true)
    //when comes from summary order, load the data of payment and delivery
    if(comesFromSummary){
      setDeliveryDateSelected(deliveryDate)
      setDeliveryTimeSelected(deliveryTime)
    }
  },[])

  const onSubmit = async(data) => {
      getDataOrder({
          idCustomer: state?.id,
          dataCart: localStorage.getItem("dataOrder") ?? dataCart,
          totalCart: localStorage.getItem("totalOrder") ?? totalCart,
          paymentMethodSelected: data.paymentMethodSelected,
          deliveryDateSelected: data.deliveryDateSelected,
          deliveryTimeSelected: data.deliveryTimeSelected
      })
  }

  const handleCancelPaymentDelivery = () => {
    setShowProductsCollapse(true)
    setShowPaymentDeliveryCollapse(false)
  }

  const handleShowProductsCollapse = (value) => {
    setShowProductsCollapse(value)
  }

  const handleShowPaymentDeliveryCollapse = (value) => {
    setShowPaymentDeliveryCollapse(value)
  }

  const handleCart = (data) => {
    setDataCart(data.dataCart)
    setTotalCart(data.totalCart)
  }

  return(
    <div id="accordion">
      <DetailsCustomer 
        key={1}
        _id={state?.id}
        headers={["Nombre", "Apellidos", "Teléfono", "Correo electrónico", "Dirección", "Referencia", "Entre", "Código postal", "Colonia", "Estado", "RFC"]} 
      />

      <DetailsProducts 
        key={2}
        title={"Agregar productos"}
        showProductsCollapse={showProductsCollapse}
        showPaymentDeliveryCollapse={showPaymentDeliveryCollapse}
        handleShowProductsCollapse={handleShowProductsCollapse}
        handleShowPaymentDeliveryCollapse={handleShowPaymentDeliveryCollapse}
        handleCart={handleCart}
      />
      
      {
      showPaymentDeliveryCollapse &&
        <DetailsPaymentDelivery
          key={3}
          comesFromSummary={comesFromSummary}
          paymentMethodSelected={paymentMethod}
          deliveryDateSelectedDefault={deliveryDateSelected}
          deliveryTimeSelectedDefault={deliveryTimeSelected}
          submitDataOrder={onSubmit}
          handleCancelPaymentDelivery={handleCancelPaymentDelivery}
        />
      }
    </div>
  )
}
export default NewOrder