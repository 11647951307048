import { gql } from '@apollo/client'

export const GET_ALL_BANKS_FINANCES_COUNTER_CHARGES = gql`
  query GetAllBanksFinancesCounterCharges(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllBanksFinancesCounterCharges(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        bank_name
        bank_invoice_number
        bank_nomenclature
      }
      count
    }
  }
`

export const GET_ONE_BANK_FINANCES_COUNTER_CHARGE = gql`
  query GetOneBankFinancesCounterCharges(
    $getOneBankFinancesCounterChargesId: Int!
  ) {
    getOneBankFinancesCounterCharges(id: $getOneBankFinancesCounterChargesId) {
      id
      bank_name
      bank_nomenclature
      bank_invoice_number
    }
  }
`

export const CREATE_BANK_FINANCES_COUNTER_CHARGE = gql`
  mutation CreateBankFinancesCounterCharges(
    $bankFinancesCounterChargesInput: bankFinancesCounterChargesInput!
  ) {
    createBankFinancesCounterCharges(
      bankFinancesCounterChargesInput: $bankFinancesCounterChargesInput
    ) {
      bank_name
      bank_nomenclature
      bank_invoice_number
    }
  }
`

export const UPDATE_BANK_FINANCES_COUNTER_CHARGE = gql`
  mutation UpdateBankFinancesCounterCharges(
    $updateBankFinancesCounterChargesId: Int!
    $bankFinancesCounterChargesInput: bankFinancesCounterChargesInput!
  ) {
    updateBankFinancesCounterCharges(
      id: $updateBankFinancesCounterChargesId
      bankFinancesCounterChargesInput: $bankFinancesCounterChargesInput
    )
  }
`

export const DELETE_BANK_FINANCES_COUNTER_CHARGE = gql`
  mutation DeleteBankFinancesCounterCharges($id: Int!) {
    deleteBankFinancesCounterCharges(id: $id)
  }
`
