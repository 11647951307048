import { gql } from '@apollo/client'

export const GET_BILLING_ORDERS = gql`
  query GetBillingOrders(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $platform: Int
    $date: Int
  ) {
    getBillingOrders(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      platform: $platform
      date: $date
    ) {
      rows {
        id
        platform_id
        platform {
          name
          id
        }
        type {
          name
        }
        payment {
          platform
          payment_id
        }
        store {
          name
        }
        warehouse {
          name
        }
        shipping {
          first_name
          last_name
        }
        order_id
        order_date
        object_type
        object_type_name
      }
      count
    }
  }
`

export const EXPORT_BILLING_ORDERS = gql`
  mutation GetAllBillingExport {
    getAllBillingExport {
      id
      order_date
      platform_name
      type_name
      id_payment
      platform
      shipping_first_name
      shipping_last_name
      order_id
      store_name
      warehouse_name
    }
  }
`
