import React from 'react';

function OrderCol({
    mediumColSize='3', 
    title, 
    content, 
    footer,
    switchSize=false, 
    customColSize=false, 
    onlyTitle=false,
    noPaddingParagraph,
}) {
    return (
        <div className={ 
            customColSize ? `col-12 col-sm-12 col-md-12 col-lg-${customColSize} col-xl-${customColSize} ${noPaddingParagraph && 'noPaddingParagraph'}`
            :`col-12 col-sm-8 col-md-8 col-lg-${mediumColSize} col-xl-${mediumColSize} ${noPaddingParagraph && 'noPaddingParagraph'}`}
        >
            <b className={switchSize ? "cardTitle2":"cardTitle"}>{title}</b>
            {!onlyTitle && <p className={switchSize ? "cardContent2":"cardContent"}>{content}</p>}
            {footer && <footer>{footer}</footer>}
        </div>
    );
}

export default OrderCol;